import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const InsertTableRowAbove: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="14"
      y="10"
      width="3"
      height="12"
      rx="0.5"
      transform="rotate(90 14 10)"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.0025L10 10.0025"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 13.0025L6 10.0025"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="10.6675"
      y="3.00003"
      width="5.33556"
      height="5.33556"
      rx="1.5"
      transform="rotate(90 10.6675 3.00003)"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0024 8.33553L14.0024 6.3347C14.0024 5.96636 13.7038 5.66776 13.3355 5.66776L10.6677 5.66776"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33203 5.66776L2.66425 5.66776C2.29591 5.66776 1.99731 5.96636 1.99731 6.3347L1.99731 8.33553"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00002 6.5015L8.00002 4.83414"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.8335 5.66773L7.16613 5.66773"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

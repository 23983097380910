import React from 'react';

import { SvgProps } from '../enums';

export const FlagSH: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55072"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55072)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55072"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55072)">
        <mask
          id="mask2_270_55072"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="9"
          height="7"
        >
          <rect width="9" height="7" fill="white" />
        </mask>
        <g mask="url(#mask2_270_55072)">
          <path
            // eslint-disable-next-line max-len
            d="M-1.00214 6.4999L0.978339 7.3685L9.04494 0.944361L10.0896 -0.346405L7.97176 -0.636717L4.68159 2.13163L2.03334 3.99685L-1.00214 6.4999Z"
            fill="#F7FCFF"
          />
          <path
            d="M-0.731018 7.10845L0.277943 7.61252L9.71445 -0.466339H8.29775L-0.731018 7.10845Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M10.0021 6.4999L8.02166 7.3685L-0.0449371 0.944361L-1.08959 -0.346405L1.02824 -0.636717L4.31841 2.13163L6.96666 3.99685L10.0021 6.4999Z"
            fill="#F7FCFF"
          />
          <path
            // eslint-disable-next-line max-len
            d="M9.93457 6.93667L8.92561 7.44073L4.90745 3.98176L3.71615 3.59529L-1.1901 -0.341973H0.226597L5.13009 3.50184L6.43256 3.96525L9.93457 6.93667Z"
            fill="#F50100"
          />
          <mask
            id="path-9-outside-1_270_55072"
            maskUnits="userSpaceOnUse"
            x="-1"
            y="-1"
            width="11"
            height="9"
            fill="black"
          >
            <rect fill="white" x="-1" y="-1" width="11" height="9" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M3.99226 0V-0.75H3.24226V0H3.99226ZM4.99226 0H5.74226V-0.75H4.99226V0ZM3.99226 3V3.75H4.74226V3H3.99226ZM0 3V2.25H-0.75V3H0ZM0 4H-0.75V4.75H0V4ZM3.99226 4H4.74226V3.25H3.99226V4ZM3.99226 7H3.24226V7.75H3.99226V7ZM4.99226 7V7.75H5.74226V7H4.99226ZM4.99226 4V3.25H4.24226V4H4.99226ZM9 4V4.75H9.75V4H9ZM9 3H9.75V2.25H9V3ZM4.99226 3H4.24226V3.75H4.99226V3ZM3.99226 0.75H4.99226V-0.75H3.99226V0.75ZM4.74226 3V0H3.24226V3H4.74226ZM0 3.75H3.99226V2.25H0V3.75ZM0.75 4V3H-0.75V4H0.75ZM3.99226 3.25H0V4.75H3.99226V3.25ZM4.74226 7V4H3.24226V7H4.74226ZM4.99226 6.25H3.99226V7.75H4.99226V6.25ZM4.24226 4V7H5.74226V4H4.24226ZM9 3.25H4.99226V4.75H9V3.25ZM8.25 3V4H9.75V3H8.25ZM4.99226 3.75H9V2.25H4.99226V3.75ZM4.24226 0V3H5.74226V0H4.24226Z"
            fill="#F7FCFF"
            mask="url(#path-9-outside-1_270_55072)"
          />
        </g>
        <path
          // eslint-disable-next-line max-len
          d="M10.0228 4.89462H9.89777V5.01962C9.89777 5.27982 9.89658 5.53461 9.89543 5.78322C9.89268 6.3754 9.89009 6.93254 9.90405 7.44418C9.92388 8.17072 9.97717 8.81643 10.1139 9.35432C10.2507 9.89294 10.474 10.3345 10.8419 10.6402C11.2109 10.9468 11.7094 11.1034 12.3701 11.1034C13.0372 11.1034 13.5495 10.9087 13.9339 10.5587C14.3156 10.2111 14.5598 9.72068 14.713 9.14862C15.0183 8.00878 14.9736 6.49886 14.8888 5.0125L14.8821 4.89462H14.764H10.0228Z"
          fill="#B7E1FF"
          stroke="#F7FCFF"
          strokeWidth="0.25"
        />
        <mask
          id="mask3_270_55072"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="4"
          width="7"
          height="8"
        >
          <path
            // eslint-disable-next-line max-len
            d="M10.0228 4.89462H9.89777V5.01962C9.89777 5.27982 9.89658 5.53461 9.89543 5.78322C9.89268 6.3754 9.89009 6.93254 9.90405 7.44418C9.92388 8.17072 9.97717 8.81643 10.1139 9.35432C10.2507 9.89294 10.474 10.3345 10.8419 10.6402C11.2109 10.9468 11.7094 11.1034 12.3701 11.1034C13.0372 11.1034 13.5495 10.9087 13.9339 10.5587C14.3156 10.2111 14.5598 9.72068 14.713 9.14862C15.0183 8.00878 14.9736 6.49886 14.8888 5.0125L14.8821 4.89462H14.764H10.0228Z"
            fill="white"
            stroke="white"
            strokeWidth="0.25"
          />
        </mask>
        <g mask="url(#mask3_270_55072)">
          <rect x="11.2" y="9.80002" width="3.6" height="1.2" fill="#2E42A5" />
          <g filter="url(#filter0_d_270_55072)">
            <rect
              x="8.79999"
              y="3.80002"
              width="7.2"
              height="3.6"
              fill="#FDFF00"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.4 7.04002C12.8639 7.04002 13.24 6.66394 13.24 6.20002C13.24 5.7361 12.8639 5.36002 12.4 5.36002C11.9361 5.36002 11.56 5.7361 11.56 6.20002C11.56 6.66394 11.9361 7.04002 12.4 7.04002Z"
            fill="#272727"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10.0706 8.41184L10.6065 7.91817C10.6065 7.91817 11.3305 7.84537 11.4159 8.00519C11.5012 8.165 11.4505 7.93137 11.5868 8.29825C11.723 8.66513 11.7769 8.14063 11.8518 8.66513C11.9268 9.18963 11.9902 9.37061 11.9902 9.49262C11.9902 9.61463 12.6725 9.97815 12.4108 10.2285C12.1491 10.4789 12.1375 11.0557 12.1375 11.2209C12.1375 11.3862 12.1169 11.4603 11.8518 11.4233C11.5868 11.3862 11.2644 11.5182 11.2 11.5182C11.1356 11.5182 10.3584 11.3598 10.3584 11.0338C10.3584 10.7078 10.0706 8.41184 10.0706 8.41184Z"
            fill="#CE6201"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_270_55072"
        x="8.79999"
        y="3.80002"
        width="7.20001"
        height="3.85001"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.25" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_270_55072"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_270_55072"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

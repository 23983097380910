/**
 * RGB Colors
 */
export interface RGB {
  /** Red bit */
  r: number;
  /** Green bit */
  g: number;
  /** Blue bit */
  b: number;
}

/**
 * converts a hex string to a set of RGB values
 *
 * @param hexString - the hex string to parse
 * @returns the RGB components for the specified hex string
 */
export function hexToRgb(hexString = ''): RGB {
  const hex = hexString.replace('#', '');
  return {
    r: parseInt(
      hex.length === 6
        ? hex.substring(0, 2)
        : hex.substring(0, 1) + hex.substring(0, 1),
      16,
    ),
    g: parseInt(
      hex.length === 6
        ? hex.substring(2, 4)
        : hex.substring(1, 2) + hex.substring(1, 2),
      16,
    ),
    b: parseInt(
      hex.length === 6
        ? hex.substring(4, 6)
        : hex.substring(2, 3) + hex.substring(2, 3),
      16,
    ),
  };
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagST: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55093"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55093)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#FBCD17"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4H16V0H0Z"
        fill="#73BE4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V12H16V8H0Z"
        fill="#73BE4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12L5 6L0 0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.5009 6.93534L7.56556 7.5L7.77891 6.39812L7 5.57347L8.05468 5.52888L8.5009 4.5L8.94711 5.52888H10L9.22289 6.39812L9.45685 7.5L8.5009 6.93534Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12.5009 6.93534L11.5656 7.5L11.7789 6.39812L11 5.57347L12.0547 5.52888L12.5009 4.5L12.9471 5.52888H14L13.2229 6.39812L13.4568 7.5L12.5009 6.93534Z"
        fill="#272727"
      />
    </g>
  </svg>
);

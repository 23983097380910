import { useLocation } from 'react-router-dom';

import { apply } from '@transcend-io/type-utils';

import { getQueryParams } from '../utils/location';

/**
 * Get an object containing the current query parameters
 *
 * @returns Query params
 */
export const useQueryParams = (): { [k in string]: string | undefined } => {
  const location = useLocation();
  return apply(getQueryParams(location.search), (s) =>
    Array.isArray(s) ? s[0] : s,
  );
};

import React, { ReactNode, useMemo } from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { Placement } from 'react-bootstrap/esm/types';

import { Badge } from '../Badge';
import { Popover, PopoverProps } from '../Popover';
import {
  FlexColumn,
  FlexRowCenterVertical,
  StyleUtils,
} from '../StyledWrappers';
import { IWithClassName, IWithStyle } from '../types';
import { useListOverflow } from './hooks';
import { UseListOverflowProps } from './types';

export interface ItemsWithOverflowProps<T>
  extends IWithClassName,
    IWithStyle,
    Pick<UseListOverflowProps<T>, 'countAndMoreAsSegment'>,
    Pick<PopoverProps, 'isExpanded' | 'setIsExpanded'> {
  /** List of items to render */
  items: T[];
  /** Number of items to display before putting the rest into an overflow container */
  limit?: number;
  /** Callback to render each item. */
  renderElement: (item: T, index: number, items: T[]) => React.ReactElement;
  /** Callback to render each overflowing item. If not provided, `renderElement` will be used */
  renderOverflowElement?: (
    item: T,
    index: number,
    items: T[],
  ) => React.ReactElement;
  /** props to pass to the button */
  buttonProps?: ButtonProps;
  /** Whether to stop propagation & prevent default for onMouseDown and onClick */
  disableParentClickOnOverflow?: boolean;
  /** Link to display at the bottom of the popover */
  link?: ReactNode;
  /** Display the popover when any item is clicked, as opposed to just the overflow button */
  popoverOnAll?: boolean;
  /** Include the already visible items in the overflow as well */
  includeAllInOverflow?: boolean;
  /** Custom indicator to render as popover trigger */
  renderOverflowIndicator?: (totalCount: number) => JSX.Element;
  /**  Make component visible */
  visible?: boolean;
  /** Height of popover */
  popoverHeight?: string;
  /** Placement of popover */
  placement?: Placement;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const ItemsWithOverflow = <T extends any>({
  items,
  limit = items.length,
  renderElement,
  renderOverflowElement,
  style,
  className,
  disableParentClickOnOverflow = true,
  link,
  popoverOnAll,
  includeAllInOverflow,
  countAndMoreAsSegment = false,
  renderOverflowIndicator,
  popoverHeight = '280px',
  isExpanded,
  setIsExpanded,
  placement = 'top',
}: ItemsWithOverflowProps<T>): JSX.Element => {
  const { listBeforeOverflow, listAfterOverflow } = useListOverflow({
    list: items,
    countAndMoreAsSegment,
    limit,
  });

  const visibleList = useMemo(
    () => listBeforeOverflow.map(renderElement),
    [listBeforeOverflow, renderElement],
  );

  return (
    <FlexRowCenterVertical
      style={{ gap: StyleUtils.Spacing.sm, ...(style ?? {}) }}
      className={className}
    >
      {!popoverOnAll && visibleList}

      {(listAfterOverflow.length > 0 || link) && (
        <Popover
          key="popover"
          placement={placement}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          contents={
            <FlexColumn style={{ gap: StyleUtils.Spacing.sm }}>
              <FlexColumn
                style={{
                  maxHeight: popoverHeight,
                  gap: StyleUtils.Spacing.sm,
                  overflowX: 'hidden',
                }}
              >
                {((includeAllInOverflow ? items : listAfterOverflow) || []).map(
                  renderOverflowElement || renderElement,
                )}
              </FlexColumn>
              <div style={{ textAlign: 'center' }}>{link}</div>
            </FlexColumn>
          }
        >
          <Button
            style={{
              padding: 0,
              background: 'transparent',
              border: 0,
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
              gap: StyleUtils.Spacing.sm,
            }}
            // Used for preventing the menu from opening when this component is inside a react-select
            onMouseDown={
              disableParentClickOnOverflow
                ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                : undefined
            }
            onClick={
              disableParentClickOnOverflow
                ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                : undefined
            }
          >
            {popoverOnAll && visibleList}

            {listAfterOverflow.length > 0 &&
              (renderOverflowIndicator ? (
                renderOverflowIndicator(listAfterOverflow.length)
              ) : (
                <Badge color="gray4" round style={{ display: 'block' }}>
                  +{listAfterOverflow.length}
                </Badge>
              ))}
          </Button>
        </Popover>
      )}
    </FlexRowCenterVertical>
  );
};

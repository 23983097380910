import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CheckmarkGear: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.2122 8.19807L12.2448 8.18992C12.6901 8.07856 13.0025 7.67841 13.0025 7.21937V6.78039C13.0025 6.32134 12.6901 5.92119 12.2447 5.80984L12.2121 5.80166C11.8725 5.71659 11.5934 5.47571 11.4595 5.1523C11.3257 4.82889 11.353 4.46118 11.5331 4.16106L11.5468 4.13817C11.786 3.74387 11.7252 3.23724 11.3995 2.91071L11.0891 2.6003C10.7645 2.2757 10.2607 2.21366 9.86704 2.44983L9.83815 2.46717C9.53804 2.64722 9.17037 2.67443 8.84704 2.5405C8.5237 2.40657 8.28295 2.12735 8.19806 1.78783L8.18991 1.75523C8.07856 1.3099 7.67841 0.997498 7.21936 0.997498H6.78038C6.32133 0.997498 5.92118 1.3099 5.80983 1.75525L5.80165 1.78793C5.71658 2.12745 5.4757 2.4066 5.15229 2.54046C4.82888 2.67432 4.46117 2.64705 4.16104 2.46696L4.13814 2.45322C3.74385 2.21402 3.23722 2.2748 2.91069 2.60047L2.60028 2.91087C2.27567 3.23542 2.2136 3.73925 2.44976 4.13287L2.46684 4.16135C2.64694 4.46148 2.67421 4.82918 2.54036 5.1526C2.4065 5.47601 2.12735 5.7169 1.78783 5.80197L1.75525 5.81012C1.30993 5.92147 0.997498 6.32159 0.997498 6.78063V7.21967C0.997498 7.67863 1.30975 8.07874 1.75501 8.19007L1.78737 8.19817C2.12694 8.28304 2.40623 8.52377 2.54027 8.8471C2.67431 9.17044 2.64725 9.53816 2.46733 9.83839L2.45004 9.86719C2.21387 10.2608 2.2759 10.7647 2.60047 11.0893L2.91091 11.3997C3.23538 11.7243 3.73914 11.7864 4.13271 11.5503L4.16132 11.5331C4.46144 11.353 4.82915 11.3257 5.15257 11.4596C5.47599 11.5934 5.71688 11.8726 5.80195 12.2121L5.81011 12.2447C5.92144 12.69 6.32157 13.0025 6.78061 13.0025H7.21965C7.67862 13.0025 8.07872 12.6902 8.19005 12.2449L8.19814 12.2126C8.28301 11.873 8.52373 11.5937 8.84707 11.4597C9.17041 11.3257 9.53813 11.3527 9.83835 11.5327L9.86718 11.55C10.2608 11.7861 10.7646 11.7241 11.0892 11.3995L11.3997 11.0891C11.7243 10.7646 11.7864 10.2608 11.5503 9.86727L11.5331 9.83867C11.353 9.53854 11.3257 9.17083 11.4596 8.84742C11.5935 8.524 11.8726 8.28313 12.2122 8.19807Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.36235 6.14902L6.65941 7.85195L5.63855 6.83019"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import * as t from 'io-ts';

import { ActionItemCode } from '@transcend-io/privacy-types';
import { valuesOf } from '@transcend-io/type-utils';

import { dbModelId } from '@main/schema-utils';

import { ActionItemSubscriptionNotificationMethod } from './schema/enums';

/**
 * The expected query parameters from RelayState
 */
export const RelayState = t.intersection([
  t.type({
    /** The email of the user */
    email: t.string,
    /** The diffie-hellman public key to forward to sombra */
    publicKey: t.string,
    /** Redirect to this page */
    redirectTo: t.string,
  }),
  t.partial({
    /** The id of the user logging in */
    userId: dbModelId('user'),
  }),
]);

/** Type override */
export type RelayState = t.TypeOf<typeof RelayState>;

/**
 * A mapping of action item subscription methods to the whitelisted destinations
 */
export const ActionItemSubscriptionWhitelist = t.union([
  t.undefined,
  t.array(
    t.type({
      /** The display name */
      name: t.string,
      /** The whitelisted id/email */
      identifier: t.string,
      /** The notification method */
      notificationMethod: valuesOf(ActionItemSubscriptionNotificationMethod),
    }),
  ),
]);

/** Type override */
export type ActionItemSubscriptionWhitelist = t.TypeOf<
  typeof ActionItemSubscriptionWhitelist
>;

export const ActionItemCodeConfig = t.union([
  t.literal('none'),
  dbModelId('organization'),
]);

/**
 * Type override
 */
export type ActionItemCodeConfig = t.TypeOf<typeof ActionItemCodeConfig>;

/**
 * A mapping of action item codes to their configuration
 * -- if the value is 'none', then NO organizations are allowed
 * -- if the value is an array of strings, then only organizations with those IDs are allowed
 * -- if the code is missing from the config override, then it is allowed for ALL orgs
 *
 * @example
 * { 'DATA_POINT_MISSING_FIELDS': 'none', 'COOKIE_NEEDS_REVIEW': ['org1', 'org2'] }
 */
export const ActionItemConfigurationOverride = t.union([
  t.partial({}),
  t.record(valuesOf(ActionItemCode), ActionItemCodeConfig),
]);

/**
 * Type override
 */
export type ActionItemConfigurationOverride = t.TypeOf<
  typeof ActionItemConfigurationOverride
>;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DsrTypeSaleOptOut: React.FC<SvgProps> = ({
  color,
  color2,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M18.3333 26.5H6.5C4.75 26.5 3.33334 25.0833 3.33334 23.3333V8.16667C3.33334 6.41667 4.75 5 6.5 5H31.8333C33.5833 5 35 6.41667 35 8.16667V16.6667"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M21.965 13.285C23.51 14.83 23.51 17.3367 21.965 18.8833C20.42 20.4283 17.9133 20.4283 16.3667 18.8833C14.8217 17.3383 14.8217 14.8317 16.3667 13.285C17.9133 11.7383 20.42 11.7383 21.965 13.285"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M36.6667 28.3334C36.6667 32.0234 33.69 35.0001 30 35.0001C26.31 35.0001 23.3333 32.0234 23.3333 28.3334C23.3333 24.6434 26.31 21.6667 30 21.6667C33.69 21.6667 36.6667 24.6434 36.6667 28.3334Z"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 21.6666H8.33334"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 10.0001H28.3333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.2883 33.0451L34.7133 23.6201"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

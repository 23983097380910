import { defineMessages } from '@transcend-io/internationalization';

export const partnershipLogoMessages = defineMessages(
  'core-ui.PartnershipLogo.partnershipLogo',
  {
    transcendLogoAlt: {
      defaultMessage: 'Transcend logo',
    },
    organizationLogoAlt: {
      defaultMessage: '{companyName} logo',
    },
    fallbackLogoAlt: {
      defaultMessage: 'Your organization logo',
    },
  },
);

/**
 * SubDataPoint related codecs
 */
import * as t from 'io-ts';

import { dbModelId } from '@main/schema-utils';

import { DataSubCategoryForSombra, LLMFeatures } from './codecs';

export const SubDataPointForSombra = t.intersection([
  t.type({
    /** ID of subDataPoint */
    id: dbModelId('subDataPoint'),
    /** Name of subDataPoint */
    name: t.string,
    /** Data silo ID that subDataPoint originate from */
    dataSiloId: dbModelId('dataSilo'),
    /** The fully qualified ID */
    subDataPointFQId: t.string,
  }),
  t.partial({
    /** Encrypted samples kept in S3 */
    encryptedSamplesS3Key: t.string,
    /** Description of subDataPoint */
    description: t.string,
    /** Samples */
    samples: t.array(t.string),
  }),
]);

/** Override type. */
export type SubDataPointForSombra = t.TypeOf<typeof SubDataPointForSombra>;

export const SubDataPointsForSombra = t.array(SubDataPointForSombra);

/** Override type. */
export type SubDataPointsForSombra = t.TypeOf<typeof SubDataPointsForSombra>;

export const ConfirmedSubDataPoint = t.type({
  /** SubDataPoint name */
  subDataPoint: SubDataPointForSombra,
  /** Category name */
  category: DataSubCategoryForSombra,
});

/** Override type. */
export type ConfirmedSubDataPoint = t.TypeOf<typeof ConfirmedSubDataPoint>;

export const ConfirmedSubDataPoints = t.array(ConfirmedSubDataPoint);

/** Override type. */
export type ConfirmedSubDataPoints = t.TypeOf<typeof ConfirmedSubDataPoints>;

export const ResetConfirmedSubDataPointsCache = t.partial({
  /** Reset cache */
  resetCache: t.boolean,
});

/** Override type. */
export type ResetConfirmedSubDataPointsCache = t.TypeOf<
  typeof ResetConfirmedSubDataPointsCache
>;

export const OptionalConfirmedSubDataPoints = t.partial({
  /** SubDataPoints to be evaluated against (optional) */
  confirmedSubDataPoints: ConfirmedSubDataPoints,
});

/** Override type. */
export type OptionalConfirmedSubDataPoints = t.TypeOf<
  typeof OptionalConfirmedSubDataPoints
>;

export const ConfirmedSubDataPointsKeyArguments = t.partial({
  /** Categories to be classified to */
  categories: t.array(t.string),
  /** Limit per category for testing */
  limitPerCategory: t.number,
});

/** Override type. */
export type ConfirmedSubDataPointsKeyArguments = t.TypeOf<
  typeof ConfirmedSubDataPointsKeyArguments
>;

export const GetConfirmedSubDataPointsInput = t.intersection([
  ConfirmedSubDataPointsKeyArguments,
  t.partial({
    classificationFeatures: LLMFeatures,
  }),
  OptionalConfirmedSubDataPoints,
]);

/** Override type. */
export type GetConfirmedSubDataPointsInput = t.TypeOf<
  typeof GetConfirmedSubDataPointsInput
>;

export const GetSubDataPointSampleInput = t.intersection([
  ConfirmedSubDataPointsKeyArguments,
  t.partial({
    /** Whether to update ConfirmedSubDataPointsCache, default is true */
    updateCacheAfterSampling: t.boolean,
    /** Features to enable/disable */
    classificationFeatures: LLMFeatures,
  }),
  OptionalConfirmedSubDataPoints,
]);

/** Override type. */
export type GetSubDataPointSampleInput = t.TypeOf<
  typeof GetSubDataPointSampleInput
>;

export const ConfirmedSubDataPointsInput = t.type({
  /** Specific date categories to lookup for */
  dataCategories: t.array(t.string),
  /** Limit of subdatapoint for each data category found */
  subDataPointLimit: t.number,
});

/** Override type. */
export type ConfirmedSubDataPointsInput = t.TypeOf<
  typeof ConfirmedSubDataPointsInput
>;

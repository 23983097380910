import mime from 'mime';

import {
  FOLDER_FILE_EXTENSION,
  FOLDER_MIME_TYPE,
  UNKNOWN_FILE_EXTENSION,
  UNKNOWN_MIME_TYPE,
} from './constants';

// Define the mime types for the file extensions that are not recognized by the mime library
mime.define({
  [UNKNOWN_MIME_TYPE]: [UNKNOWN_FILE_EXTENSION],
  'application/vnd.google-apps.form': ['gform'],
  'binary/octet-stream': ['binary'],
  [FOLDER_MIME_TYPE]: [FOLDER_FILE_EXTENSION],
});

export default mime;

/* eslint-disable max-len */
import React from 'react';

import { SvgProps } from '../enums';

export const HamburgerMenu: React.FC<SvgProps> = (props) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.794067 1.5953C0.794067 1.16745 1.13828 0.823242 1.56613 0.823242H14.4338C14.8616 0.823242 15.2058 1.16745 15.2058 1.5953C15.2058 2.02315 14.8616 2.36736 14.4338 2.36736H1.56613C1.13828 2.36736 0.794067 2.02315 0.794067 1.5953ZM0.794067 6.74236C0.794067 6.31451 1.13828 5.9703 1.56613 5.9703H14.4338C14.8616 5.9703 15.2058 6.31451 15.2058 6.74236C15.2058 7.17021 14.8616 7.51442 14.4338 7.51442H1.56613C1.13828 7.51442 0.794067 7.17021 0.794067 6.74236ZM15.2058 11.8894C15.2058 12.3173 14.8616 12.6615 14.4338 12.6615H1.56613C1.13828 12.6615 0.794067 12.3173 0.794067 11.8894C0.794067 11.4616 1.13828 11.1174 1.56613 11.1174H14.4338C14.8616 11.1174 15.2058 11.4616 15.2058 11.8894Z"
      fill={props.color}
    />
  </svg>
);

/* eslint-enable max-len */

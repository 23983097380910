import React from 'react';

import { SvgProps } from '../enums';

export const FlagMP: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55182"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55182)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H17V12H0V0Z"
        fill="#2993EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3.10638 5.79167C3.10638 8.43803 5.29733 10.5833 8 10.5833C10.7027 10.5833 12.8936 8.43803 12.8936 5.79167C12.8936 3.1453 10.7027 1 8 1C5.29733 1 3.10638 3.1453 3.10638 5.79167ZM11.8298 5.79167C11.8298 7.86273 10.1151 9.54167 8 9.54167C5.88487 9.54167 4.17021 7.86273 4.17021 5.79167C4.17021 3.7206 5.88487 2.04167 8 2.04167C10.1151 2.04167 11.8298 3.7206 11.8298 5.79167Z"
        fill="#F1F9FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3.53192 5.79167C3.53192 8.20792 5.53235 10.1667 8.00001 10.1667C10.4677 10.1667 12.4681 8.20792 12.4681 5.79167C12.4681 3.37543 10.4677 1.41667 8.00001 1.41667C5.53235 1.41667 3.53192 3.37543 3.53192 5.79167ZM12.2553 5.79167C12.2553 8.09286 10.3502 9.95834 8.00001 9.95834C5.64986 9.95834 3.74469 8.09286 3.74469 5.79167C3.74469 3.49048 5.64986 1.62501 8.00001 1.62501C10.3502 1.62501 12.2553 3.49048 12.2553 5.79167Z"
        fill="#E31D1C"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.00001 10.0917C5.57228 10.0917 3.60692 8.16502 3.60692 5.79167H3.45692C3.45692 8.25082 5.49243 10.2417 8.00001 10.2417V10.0917ZM12.3931 5.79167C12.3931 8.16502 10.4277 10.0917 8.00001 10.0917V10.2417C10.5076 10.2417 12.5431 8.25082 12.5431 5.79167H12.3931ZM8.00001 1.49167C10.4277 1.49167 12.3931 3.41833 12.3931 5.79167H12.5431C12.5431 3.33252 10.5076 1.34167 8.00001 1.34167V1.49167ZM3.60692 5.79167C3.60692 3.41833 5.57228 1.49167 8.00001 1.49167V1.34167C5.49243 1.34167 3.45692 3.33252 3.45692 5.79167H3.60692ZM8.00001 10.0333C10.3901 10.0333 12.3303 8.13576 12.3303 5.79167H12.1803C12.1803 8.04996 10.3102 9.88334 8.00001 9.88334V10.0333ZM3.66969 5.79167C3.66969 8.13576 5.60993 10.0333 8.00001 10.0333V9.88334C5.68978 9.88334 3.81969 8.04996 3.81969 5.79167H3.66969ZM8.00001 1.55001C5.60993 1.55001 3.66969 3.44758 3.66969 5.79167H3.81969C3.81969 3.53339 5.68978 1.70001 8.00001 1.70001V1.55001ZM12.3303 5.79167C12.3303 3.44758 10.3901 1.55001 8.00001 1.55001V1.70001C10.3102 1.70001 12.1803 3.53339 12.1803 5.79167H12.3303Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.29308 2.875C7.1097 2.875 6.97328 3.0445 7.01248 3.22364L7.20591 4.10751C7.22291 4.18516 7.27039 4.24967 7.33413 4.28959C7.27535 4.3353 7.23456 4.40354 7.22516 4.48242L6.53138 10.3052C6.51865 10.412 6.56645 10.5167 6.65985 10.5701C6.92096 10.7194 7.48708 11 8.05321 11C8.61933 11 9.18545 10.7194 9.44657 10.5701C9.53997 10.5167 9.58776 10.412 9.57503 10.3052L8.88125 4.48242C8.87186 4.40354 8.83107 4.3353 8.77228 4.28959C8.83602 4.24967 8.88351 4.18516 8.9005 4.10751L9.09393 3.22364C9.13313 3.0445 8.99672 2.875 8.81334 2.875H7.29308Z"
        fill="#A7A7A7"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.25964 6.0064C3.28543 5.92388 3.37465 5.87746 3.45892 5.90271C3.5432 5.92796 3.59061 6.01532 3.56482 6.09784C3.5202 6.24061 3.5202 6.45149 3.56943 6.72738C3.58831 6.83321 3.61075 6.91491 3.63836 6.98709C3.64935 7.01585 3.66073 7.04217 3.67592 7.07483C3.66916 7.06028 3.70625 7.13906 3.71527 7.15948C3.76296 7.26742 3.78405 7.36203 3.78405 7.50062C3.78405 7.58692 3.71261 7.65687 3.62448 7.65687C3.53635 7.65687 3.4649 7.58692 3.4649 7.50062C3.4649 7.40591 3.45318 7.35333 3.42235 7.28356C3.41509 7.26712 3.38045 7.19353 3.38549 7.20438C3.36748 7.16567 3.35338 7.13304 3.33948 7.09669C3.30484 7.00612 3.27726 6.90573 3.25504 6.78115C3.19793 6.46108 3.19793 6.20386 3.25964 6.0064Z"
        fill="#73BE4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.10638 8.08959L6.04914 9.49132L6.77491 7.14237L4.77769 5.65973L7.28348 5.60973L8.10638 3.29167L8.92928 5.60973L11.4351 5.65973L9.43786 7.14237L10.1636 9.49132L8.10638 8.08959Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.7797 6.21474C12.7539 6.13223 12.6647 6.0858 12.5804 6.11105C12.4962 6.1363 12.4488 6.22367 12.4745 6.30618C12.5192 6.44895 12.5192 6.65983 12.4699 6.93572C12.4511 7.04155 12.4286 7.12326 12.401 7.19544C12.39 7.22419 12.3786 7.25051 12.3634 7.28317C12.3702 7.26863 12.3331 7.3474 12.3241 7.36782C12.2764 7.47577 12.2553 7.57037 12.2553 7.70897C12.2553 7.79526 12.3268 7.86522 12.4149 7.86522C12.503 7.86522 12.5745 7.79526 12.5745 7.70897C12.5745 7.61425 12.5862 7.56167 12.617 7.4919C12.6243 7.47546 12.6589 7.40188 12.6539 7.41272C12.6719 7.37402 12.686 7.34138 12.6999 7.30503C12.7345 7.21446 12.7621 7.11407 12.7843 6.9895C12.8414 6.66942 12.8414 6.4122 12.7797 6.21474Z"
        fill="#73BE4A"
      />
      <path
        d="M8.0304 1.57381C8.0304 1.57381 8.61259 1.83317 8.36115 2.24155"
        stroke="#73BE4A"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.45593 1.57381C8.45593 1.57381 9.03812 1.83317 8.78669 2.24155"
        stroke="#73BE4A"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M4.6299 3.17838C4.67211 3.10531 4.76077 3.08332 4.82792 3.12924C4.89507 3.17516 4.91529 3.27162 4.87308 3.34468C4.7921 3.48485 4.74994 3.67717 4.74994 3.92377C4.74994 4.01007 4.68564 4.08002 4.60632 4.08002C4.52701 4.08002 4.46271 4.01007 4.46271 3.92377C4.46271 3.6224 4.51733 3.37322 4.6299 3.17838Z"
        fill="#73BE4A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M4.40424 4.60627C4.40424 4.69257 4.33994 4.76252 4.26062 4.76252C4.1813 4.76252 4.117 4.69257 4.117 4.60627V4.16119C4.117 3.84626 4.1664 3.59824 4.27057 3.41713C4.31264 3.34397 4.40125 3.32177 4.46849 3.36755C4.53573 3.41332 4.55614 3.50973 4.51406 3.58289C4.44263 3.70708 4.40424 3.89986 4.40424 4.16119V4.60627Z"
        fill="#73BE4A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.7421 4.39793C11.7421 4.48423 11.8064 4.55418 11.8857 4.55418C11.965 4.55418 12.0293 4.48423 12.0293 4.39793V3.95285C12.0293 3.63791 11.9799 3.3899 11.8757 3.20878C11.8337 3.13563 11.745 3.11343 11.6778 3.1592C11.6106 3.20498 11.5902 3.30139 11.6322 3.37454C11.7037 3.49874 11.7421 3.69151 11.7421 3.95285V4.39793Z"
        fill="#73BE4A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.1676 5.1271C12.1676 5.2134 12.2319 5.28335 12.3112 5.28335C12.3905 5.28335 12.4548 5.2134 12.4548 5.1271V4.68202C12.4548 4.36709 12.4054 4.11907 12.3013 3.93795C12.2592 3.8648 12.1706 3.8426 12.1033 3.88838C12.0361 3.93415 12.0157 4.03056 12.0578 4.10371C12.1292 4.22791 12.1676 4.42068 12.1676 4.68202V5.1271Z"
        fill="#73BE4A"
      />
    </g>
  </svg>
);

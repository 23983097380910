import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Lock: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.3333 14H4.66667C3.93 14 3.33333 13.4033 3.33333 12.6667V7.33333C3.33333 6.59667 3.93 6 4.66667 6H11.3333C12.07 6 12.6667 6.59667 12.6667 7.33333V12.6667C12.6667 13.4033 12.07 14 11.3333 14Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11.3933V9.66667"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M8.35353 8.81313C8.5488 9.0084 8.5488 9.32498 8.35353 9.52024C8.15827 9.7155 7.84169 9.7155 7.64643 9.52024C7.45117 9.32498 7.45117 9.0084 7.64643 8.81313C7.84169 8.61787 8.15827 8.61787 8.35353 8.81313"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33333 6V4.66667V4.66667C5.33333 3.194 6.52733 2 8 2V2C9.47267 2 10.6667 3.194 10.6667 4.66667V4.66667V6"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/**
 * Input parameters for all paginated graphql endpoints
 */
export const PAGINATION_INPUT_PARAMS = {
  first: {
    type: 'int',
    isOptional: true,
  },
  offset: {
    type: 'int',
    isOptional: true,
    default: 0,
  },
  last: {
    type: 'int',
    isOptional: true,
  },
} as const;

/**
 * Input params for setting database reading and writing preferences
 */
export const DATABASE_PREFERENCE_PARAMS = {
  useMaster: {
    comment: 'Whether to read from the master DB',
    type: 'boolean',
    isOptional: true,
  },
  isExportCsv: {
    comment: `Whether the query is being triggered as part of an export to CSV request.
      This optimizes the query by not grouping and ordering by unnecessary fields.`,
    type: 'boolean',
    isOptional: true,
  },
} as const;

import { CenteredDiv, Container, logger } from '@main/core-ui';
import React from 'react';

import { simpleErrorBoundaryMessages } from './messages';

/**
 * ErrorBoundaryProps
 */
export interface SimpleErrorBoundaryProps {
  /** The child contents */
  children: React.ReactNode;
}

/**
 * ErrorBoundary state
 */
export interface SimpleErrorBoundaryState {
  /** Indicates if an error has occurred */
  hasError: boolean;
}

/**
 * Error boundary used outside of app
 */
export class SimpleErrorBoundary extends React.Component<
  SimpleErrorBoundaryProps,
  SimpleErrorBoundaryState
> {
  /** Component state */
  public state = { hasError: false };

  /**
   * Catch the unexpected error and set the state to hasError:true
   *
   * @param error - The error that occurred
   * @param info - The information associated with the error
   */
  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ hasError: true });
    logger.error(error, info);
    localStorage.clear();
  }

  /**
   * Render the SimpleErrorBoundary container
   *
   * @returns The JSX for the SimpleErrorBoundary
   */
  public render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fall-back UI
      return (
        <Container spacing={300}>
          <CenteredDiv>
            {simpleErrorBoundaryMessages.error.defaultMessage}
          </CenteredDiv>
        </Container>
      );
    }
    return children;
  }
}

export default SimpleErrorBoundary;

import { ConsentManagerIntlMessageType, IntlMessageType } from './enums';
import type { LocalizedMessage } from './schema';

/**
 * Asserts that value is of type LocalizedMessage.
 *
 * @param message - The message instance to test
 * @returns Boolean regarding whether or not type assertion is true
 */
export const isLocalizedMessage = (message: any): message is LocalizedMessage =>
  typeof message === 'object' && !!message.id && message.localizedMessage;

/**
 * Asserts that value is of type ConsentManagerIntlMessageType.
 *
 * @param messageType - Any message type
 * @returns ConsentMessageType
 */
export const isConsentMessageType = (
  messageType: IntlMessageType,
): messageType is ConsentManagerIntlMessageType =>
  [
    IntlMessageType.ConsentManagerMessage,
    IntlMessageType.ConsentManagerTcfMessage,
  ].includes(messageType as any);

/**
 * Asserts that value is of type Privacy Center and Consent Manager IntlMessageType.
 *
 * @param messageType - message type
 * @returns IntlMessageType
 * */
export const isPrivacyCenterAndConsentMessageType = (
  messageType: IntlMessageType,
): messageType is IntlMessageType =>
  [
    IntlMessageType.ConsentPurposeTitle,
    IntlMessageType.ConsentPurposeDescription,
  ].includes(messageType as any);

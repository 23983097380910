import styled from 'styled-components';

import { responsive, sp } from '@main/theme';

/**
 * The Company Logo Wrapper
 */
export const LogoWrapper = styled.img`
  width: auto;
  max-width: ${sp(17)};
  object-fit: contain;
  height: 100%;

  ${responsive.atLeastTablet} {
    max-width: ${sp(19)};
  }

  ${responsive.atLeastDesktop} {
    max-width: ${sp(28)};
  }
`;

/**
 * The Plus Wrapper
 */
export const PlusWrapper = styled.h1`
  font-family: neue-haas-grotesk-display, sans-serif;
  color: ${({ theme }) => theme.colors.transcendNavy3};
  font-size: ${sp(3.5)};
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: ${sp(5)};

  ${responsive.atLeastTablet} {
    width: ${sp(6.5)};
  }
`;

/**
 * Wrapper props
 */
export interface WrapperProps {
  /** height css attribute */
  height?: string;
}

/**
 * The PartnershipLogo wrapper
 */
export const PartnershipLogoWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 0;
  height: ${({ height = '100%' }) => height};
`;

import * as t from 'io-ts';

import {
  DataCategoryType,
  IsoCountryCode,
  IsoCountrySubdivisionCode,
} from '@transcend-io/privacy-types';
import { apply, valuesOf } from '@transcend-io/type-utils';

import { dbModelId } from '@main/schema-utils';

import { RetentionType } from './schema/enums';

export const CDataSubCategory = t.intersection([
  t.type({
    /** Data sub category identifier. */
    name: t.string,
    /** Description. */
    description: t.union([t.string, t.null]),
    /** Category for this sub category. */
    category: valuesOf(DataCategoryType),
    /**
     * Whether the subcategory is a default subcategory.
     * Default subcategories are created for all organizations.
     */
    isDefault: t.boolean,
  }),
  t.partial({
    identifier: t.type({
      /** ID of linked identifier */
      id: dbModelId('identifier'),
      /** Name of identifier */
      name: t.string,
      /** Type of identifier */
      type: t.string,
    }),
  }),
]);

/** Override types. */
export type CDataSubCategory = t.TypeOf<typeof CDataSubCategory>;

/**
 * List of regions
 */
export const RegionList = t.array(
  valuesOf({
    ...IsoCountryCode,
    ...IsoCountrySubdivisionCode,
  }),
);

/** Override type. */
export type RegionList = t.TypeOf<typeof RegionList>;

/**
 * Map from regions to their expiry time
 */
export const RegionExpiryMap = t.intersection([
  t.type({ default: t.number }),
  t.partial(
    apply(
      {
        ...IsoCountryCode,
        ...IsoCountrySubdivisionCode,
      },
      () => t.number,
    ),
  ),
]);

/** Override type. */
export type RegionExpiryMap = t.TypeOf<typeof RegionExpiryMap>;

// The type of RetentionType answer to an assessment question
export const AssessmentRetentionType = t.intersection([
  t.type({
    retentionType: t.union([
      t.literal(RetentionType.Indefinite),
      t.literal(RetentionType.Limited),
      t.literal(RetentionType.Other),
      t.literal(RetentionType.StatedPeriod),
      t.literal(RetentionType.Unspecified),
    ]),
  }),
  t.partial({
    retentionPeriod: t.number,
  }),
]);

/** Override type. */
export type AssessmentRetentionType = t.TypeOf<typeof AssessmentRetentionType>;

export const SubDataPointCodecForSubDataPointClassificationInput = t.type({
  dataSiloId: dbModelId('dataSilo'),
  subDataPointFQId: t.string,
  encryptedSamplesS3Key: t.union([t.string, t.undefined]),
});

/** Override type. */
export type SubDataPointCodecForSubDataPointClassificationInput = t.TypeOf<
  typeof SubDataPointCodecForSubDataPointClassificationInput
>;

/* eslint-disable max-lines */
import { UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';
import { CommunicationMessage, DecryptionContext } from '@main/sombra-types';

export const CommunicationInput = mkInput({
  name: 'CommunicationInput',
  comment: 'Input for sending a message to the data subject',
  fields: {
    requestId: {
      comment: 'The id of the request to send to',
      modelName: 'request',
      type: 'id',
    },
    template: {
      comment: 'The HTML email template to send',
      type: 'string',
      optional: true,
    },
    subject: {
      comment: 'The subject of the email',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type CommunicationInput = SchemaToType<typeof CommunicationInput>;

export const DecryptedEmailAttachment = mkType({
  name: 'DecryptedEmailAttachment',
  comment:
    'The contents of an email that are only revealed to the browser or sombra.',
  fields: {
    filename: {
      comment: 'The filename of the attachment',
      type: 'string',
    },
    content: {
      comment: 'The decryption context for the email attachment contents',
      type: DecryptionContext,
    },
    contentType: {
      comment: 'The MIME content type',
      type: 'string',
    },
    size: {
      comment: 'The size in bytes of the attachment',
      type: 'int',
    },
  },
});

/** Override type */
export type DecryptedEmailAttachment = SchemaToType<
  typeof DecryptedEmailAttachment
>;
export const DecryptedEmailContents = mkType({
  name: 'DecryptedEmailContents',
  comment:
    'The contents of an email that are only revealed to the browser or sombra.',
  fields: {
    id: {
      comment: 'ID of the email communication',
      modelName: 'communication',
      type: 'id',
    },
    subject: {
      comment: 'The decryption email subject',
      type: DecryptionContext,
      optional: true,
    },
    template: {
      comment: 'The decryption email body',
      type: DecryptionContext,
    },
    attachments: {
      comment: 'The decryption contents for all email attachments',
      type: DecryptedEmailAttachment,
      list: true,
    },
  },
});

/** Override type */
export type DecryptedEmailContents = SchemaToType<
  typeof DecryptedEmailContents
>;

export const DecryptCommunicationInput = mkInput({
  name: 'DecryptCommunicationInput',
  comment: 'Input to decrypt a list of emails',
  fields: {
    communicationIds: {
      comment: 'List of communication Ids to decrypt',
      modelName: 'communication',
      type: 'id',
      list: true,
    },
    unwrapAttachments: {
      comment: 'Whether or not to unwrap attachments',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type DecryptCommunicationInput = SchemaToType<
  typeof DecryptCommunicationInput
>;

export const ToggleCommunicationReadInput = mkInput({
  name: 'ToggleCommunicationReadInput',
  comment: 'Input for toggling a message as read',
  fields: {
    id: {
      comment: 'The id of the communication message',
      modelName: 'communication',
      type: 'id',
    },
    read: {
      comment: 'Indicates if read or not',
      type: 'boolean',
    },
  },
});

/** Override type */
export type ToggleCommunicationReadInput = SchemaToType<
  typeof ToggleCommunicationReadInput
>;

export const AdditionalTimeInput = mkInput({
  name: 'AdditionalTimeInput',
  comment: 'Input for requesting additional time',
  fields: {
    requestId: {
      comment: 'The id of the request to send to',
      modelName: 'request',
      type: 'id',
    },
    template: {
      comment: 'The HTML email template to send',
      type: 'string',
      optional: true,
    },
    subject: {
      comment: 'The subject of the email',
      type: 'string',
      optional: true,
    },
    additionalTime: {
      comment: 'The amount of time (in days) to extend by',
      type: 'int',
    },
  },
});

/** Override type */
export type AdditionalTimeInput = SchemaToType<typeof AdditionalTimeInput>;

export const SombraInput = mkInput({
  name: 'getSombraInput',
  comment: 'relevant fields to get a sombra host',
  fields: {
    inReplyTo: {
      comment: 'The "In-Reply-To" field of an email',
      type: 'string',
    },
  },
});

/** Override type */
export type SombraInput = SchemaToType<typeof SombraInput>;

export const DataSiloCommunicationFilterInput = mkInput({
  name: 'DataSiloCommunicationFilterInput',
  comment:
    'Input for filtering the communications for a particular request data silo',
  fields: {
    requestId: {
      comment: 'The ID of the request to get messages for',
      modelName: 'request',
      type: 'id',
    },
    requestDataSiloId: {
      comment: 'The ID of the request data silo to filter to',
      type: 'id',
      modelName: 'RequestDataSilo',
    },
  },
});

/** Override type */
export type DataSiloCommunicationFilterInput = SchemaToType<
  typeof DataSiloCommunicationFilterInput
>;

export const SendMessageInput = mkInput({
  name: 'SendMessageInput',
  comment: 'Input for sending a message to the data subject',
  fields: {
    requestId: {
      comment: 'The id of the request to send to',
      modelName: 'request',
      type: 'id',
    },
    template: {
      comment: 'The HTML email template to send',
      type: 'string',
    },
    subject: {
      comment: 'The subject of the email',
      type: 'string',
    },
    requestDataSiloId: {
      comment: 'The id of the RequestDataSilo to send the email to',
      modelName: 'RequestDataSilo',
      type: 'id',
      optional: true,
    },
  },
});

/** Override type */
export type SendMessageInput = SchemaToType<typeof SendMessageInput>;

export const Communication = mkType({
  name: 'Communication',
  comment: 'A message that was sent on behalf of a data subject request',
  fields: {
    id: {
      comment: 'The unique ID of the communication',
      modelName: 'communication',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the message was created at',
      type: 'Date',
    },
    template: {
      comment: 'The HTML template to send',
      type: 'string',
    },
    subject: {
      comment: 'The subject of the message',
      type: 'string',
    },
    from: {
      comment: 'Who sent the message',
      type: 'string',
    },
    to: {
      comment: 'Who received the message',
      type: 'string',
    },
    bcc: {
      comment: 'Hidden recipient',
      type: 'string',
      optional: true,
    },
    avatarUrl: {
      comment: 'The url of the profile picture to display as avatar',
      type: 'string',
    },
    canToggleIsRead: {
      comment: 'Indicates if the communication can be toggled read/unread',
      type: 'boolean',
    },
    bounced: {
      comment: 'Indicates that the email bounced and was not received',
      type: 'boolean',
    },
    read: {
      comment: 'Indicates if the message is unread',
      type: 'boolean',
    },
    type: {
      comment: 'The type of communication',
      type: { CommunicationMessage },
    },
    user: {
      comment: 'The user that sent the message when not automated',
      optional: true,
      type: () => UserPreview,
    },
    isEncrypted: {
      comment: 'Indicates if the message is unread',
      type: 'boolean',
      optional: true,
    },
    RequestDataSiloId: {
      comment: 'The data silo ID of the communication',
      modelName: 'RequestDataSilo',
      type: 'id',
      optional: true,
    },
    attachmentFilenames: {
      comment: 'The list of attachment file names for the email',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type Communication = SchemaToType<typeof Communication>;

export const VendorCommunicationsMetadataInput = mkInput({
  name: 'VendorCommunicationsMetadataInput',
  comment:
    'Inputs for fetching the reduced list of communications for a data silo',
  fields: {
    dataSiloId: {
      comment: 'data silo id',
      optional: true,
      type: 'string',
    },
    token: {
      comment: 'data silo token',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type VendorCommunicationsMetadataInput = SchemaToType<
  typeof VendorCommunicationsMetadataInput
>;

export const CommunicationMetadata = mkType({
  name: 'CommunicationMetadata',
  comment:
    'A message that was sent on behalf of a data subject request with a ' +
    'reduced set of fields for the bulk requests UI',
  fields: {
    id: {
      comment: 'The unique ID of the communication',
      modelName: 'communication',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the message was created at',
      type: 'Date',
    },
  },
});

/** Override type */
export type CommunicationMetadata = SchemaToType<typeof CommunicationMetadata>;
/* eslint-enable max-lines */

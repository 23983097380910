import { view } from './view';

/**
 * Validate URL host - DO NOT USE FOR THE BACKEND/NODE.JS
 *
 * This supports domain names, IDN domain names, IPv4, and IPv6 addresses.
 *
 * Intentional spec incompatibilities:
 * - Blank hosts ('') and blank FQDN hosts ('.') are considered invalid.
 *
 * @param host - Host to validate
 * @returns true if host is valid and doesn't need additional encoding
 */
export const isValidHost = (host: string): boolean => {
  const url = new view.URL('https://-');
  url.host = host;
  const parsed = url.host; // this validates IP addresses and converts IDN to punycode
  // (?:.?(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+ - matches subdomains with matching (eg. example., .example., prod-env.example.)
  // (?:(?!-)[a-zA-Z0-9-]{1,63}(?<!-)) - matches TLD (eg. com, xn--mgba3a4f16a)
  // (?:\d{1,3}\.){3}\d{1,3} - matches IPv4 (eg. 12.42.233.2)
  // \[?[a-fA-F0-9:]{2,39}\]? - matches IPv6 (eg. 2001:db8:85a3::8a2e:0370:7334, [::1], [2001:0db8::1])
  // (?::\d{1,5})? - matches port
  const regex =
    // eslint-disable-next-line max-len
    /^(?:(?:\.?(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+(?:(?!-)[a-zA-Z0-9-]{1,63}(?<!-))|(?:localhost)|(?:\d{1,3}\.){3}\d{1,3}|\[?[a-fA-F0-9:]{2,39}\]?)(?::\d{1,5})?$/;
  return regex.test(parsed);
};

/**
 * Validate URL host for CSP entries (supporting wildcard syntax)
 *
 * This supports domain names, IDN domain names, IPv4, and IPv6 addresses.
 *
 * Intentional spec incompatibilities:
 * - We consider blank hosts ('') and blank FQDN hosts ('.') invalid.
 *
 * @param host - Host to validate
 * @returns true if host is valid and doesn't need additional encoding
 */
export const isValidCSPEntryHost = (host: string): boolean => {
  const url = new URL('https://-');
  url.host = host;
  const parsed = url.host.replace(/%2a/gi, '*');
  // If input host value is empty or contains [/\], or the processed
  // host contains more '%' characters, then the input is an invalid
  // or an improperly-encoded host.
  return (
    host.length > 0 &&
    parsed.length > 0 &&
    // host includes at least one 'letter' or 0-9 digit character
    // (required reading: https://unicode.org/reports/tr18/#property_syntax )
    /[\p{L}\d]/u.test(host) &&
    !/[/\\]/.test(host) &&
    (parsed === host.toLowerCase() ||
      (parsed.length !== host.length &&
        [...parsed.matchAll(/%/g)].length === [...host.matchAll(/%/g)].length))
  );
};

import { SchemaFields } from './types';

/**
 * Utility to make schema fields optional. Useful for types that are a superset
 * of another type but don't necessarily need its fields
 *
 * @param fields - fields you want to recursively make optional
 * @returns graphql type with all its fields and subtype fields made optional
 */
export const optionalizeFields = <T extends SchemaFields>(fields: T): T => {
  const optionalizedFields = {};
  Object.entries(fields).forEach(([key, field]) => {
    const optionalizedField = field;
    // If the type is another graphql type, we need to recurse on it
    if (typeof optionalizedField.type === 'object') {
      // Only optionalize if the fields are a SchemaFields object, not a Record<string, string>
      const keys = Object.keys(optionalizedField.type.fields);
      const val = optionalizedField.type.fields[keys[0]];
      if (typeof val === 'object') {
        let optionalizedSubFields = optionalizedField.type
          .fields as SchemaFields;
        optionalizedSubFields = optionalizeFields(optionalizedSubFields);
        optionalizedField.type.fields = optionalizedSubFields;
        optionalizedField.type.isOptional = true;
      }
    }
    optionalizedFields[key] = { ...optionalizedField, optional: true };
  });
  return optionalizedFields as T;
};

import React from 'react';

import { SvgProps } from '../enums';

export const FlagLI: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55041"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55041)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#E31D1C"
      />
      <mask
        id="mask1_270_55041"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55041)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V6H16V0H0Z"
          fill="#2E42A5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M2.39923 5.52659C2.39923 5.52659 3.00256 5.90213 4.40065 5.90213C5.79873 5.90213 6.54083 5.52659 6.54083 5.52659C6.54083 5.52659 5.44442 4.88922 4.46407 4.88922C3.48373 4.88922 2.39923 5.52659 2.39923 5.52659Z"
          fill="black"
        />
        <path
          // eslint-disable-next-line max-len
          d="M2.43528 3.3711L2.32724 3.43397L2.36339 3.4961H2.43528H6.5979H6.68949L6.71709 3.40877L6.5979 3.3711L6.71711 3.40868L6.71715 3.40858L6.71724 3.40829L6.7175 3.40745L6.71836 3.40465L6.72131 3.39478C6.72379 3.38634 6.72725 3.37425 6.73138 3.35889C6.73965 3.32819 6.75067 3.28431 6.76208 3.23037C6.78482 3.1228 6.80943 2.97355 6.81629 2.80795C6.8297 2.48384 6.7762 2.06127 6.46161 1.78779C6.16553 1.5304 5.75265 1.40238 5.36271 1.33677C4.97003 1.2707 4.58326 1.26526 4.32702 1.26526C3.79761 1.26526 3.31174 1.32798 2.52532 1.77336C2.30583 1.89767 2.18033 2.07072 2.12091 2.26036C2.06265 2.44628 2.06996 2.64094 2.10097 2.81159C2.13212 2.98296 2.1886 3.13788 2.23652 3.24895C2.26061 3.30479 2.28286 3.3503 2.29926 3.38214C2.30746 3.39807 2.31423 3.41063 2.31905 3.4194L2.32478 3.42967L2.32644 3.43258L2.32695 3.43347L2.32712 3.43377L2.32719 3.43388C2.32721 3.43393 2.32724 3.43397 2.43528 3.3711Z"
          fill="#FFD83D"
          stroke="black"
          strokeWidth="0.25"
        />
        <path
          // eslint-disable-next-line max-len
          d="M1.94307 3.453L2.00134 3.56358C1.94307 3.453 1.94311 3.45297 1.94317 3.45295L1.94329 3.45288L1.94358 3.45273L1.9444 3.4523L1.94693 3.451L1.95561 3.44665C1.963 3.44301 1.97361 3.4379 1.98744 3.43154C2.0151 3.41883 2.05565 3.40111 2.10917 3.38008C2.21622 3.33803 2.37512 3.28274 2.58646 3.22772C3.00925 3.11765 3.64139 3.00879 4.48758 3.00879C5.33377 3.00879 5.96591 3.11765 6.38869 3.22772C6.60004 3.28274 6.75894 3.33803 6.86599 3.38008C6.91951 3.40111 6.96006 3.41883 6.98772 3.43154C7.00155 3.4379 7.01216 3.44301 7.01955 3.44665L7.02823 3.451L7.03076 3.4523L7.03158 3.45273L7.03187 3.45288L7.03199 3.45295C7.03204 3.45297 7.03209 3.453 6.97381 3.56358L7.03209 3.453L7.12944 3.5043L7.09089 3.60737L6.49844 5.19155L6.45715 5.30197L1.94307 3.453ZM1.94307 3.453L1.84658 3.50384L1.88388 3.60633L2.46036 5.19051L2.50061 5.30113L2.61342 5.26759L1.94307 3.453ZM6.34451 5.26721L6.34453 5.26721L2.61345 5.26758L2.61349 5.26757L2.61388 5.26745L2.61569 5.26692L2.62334 5.26471L2.65414 5.25602C2.68134 5.24848 2.72155 5.23762 2.77292 5.22455C2.8757 5.19839 3.02297 5.16343 3.20004 5.12845C3.55498 5.05833 4.02605 4.98888 4.49705 4.98888C4.96793 4.98888 5.43014 5.0583 5.77602 5.12833C5.94861 5.16328 6.0914 5.1982 6.19079 5.22431C6.24047 5.23736 6.27926 5.24819 6.30546 5.25571L6.33508 5.26437L6.3424 5.26656L6.34411 5.26709L6.34448 5.2672L6.34451 5.26721Z"
          fill="#FFD83D"
          stroke="black"
          strokeWidth="0.25"
        />
        <mask
          id="path-8-outside-1_270_55041"
          maskUnits="userSpaceOnUse"
          x="3.05878"
          y="-1"
          width="3"
          height="3"
          fill="black"
        >
          <rect fill="white" x="3.05878" y="-1" width="3" height="3" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.56298 0H4.31088L4.34333 0.461361L4.05878 0.422642V0.739623L4.3477 0.699826L4.31088 1.42642H4.56298L4.52566 0.698603L4.81508 0.739623V0.422642L4.5309 0.463433L4.56298 0Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.56298 0H4.31088L4.34333 0.461361L4.05878 0.422642V0.739623L4.3477 0.699826L4.31088 1.42642H4.56298L4.52566 0.698603L4.81508 0.739623V0.422642L4.5309 0.463433L4.56298 0Z"
          fill="#FFD83D"
        />
        <path
          // eslint-disable-next-line max-len
          d="M4.31088 0V-0.25H4.04268L4.06149 0.0175417L4.31088 0ZM4.56298 0L4.81238 0.0172624L4.83088 -0.25H4.56298V0ZM4.34333 0.461361L4.30962 0.709078L4.61429 0.750534L4.59271 0.443819L4.34333 0.461361ZM4.05878 0.422642L4.09248 0.174924L3.80878 0.13632V0.422642H4.05878ZM4.05878 0.739623H3.80878V1.02642L4.09289 0.987284L4.05878 0.739623ZM4.3477 0.699826L4.59738 0.712479L4.61266 0.410969L4.31359 0.452164L4.3477 0.699826ZM4.31088 1.42642L4.0612 1.41376L4.04789 1.67642H4.31088V1.42642ZM4.56298 1.42642V1.67642H4.82613L4.81265 1.41361L4.56298 1.42642ZM4.52566 0.698603L4.56074 0.451077L4.26045 0.408518L4.27598 0.711406L4.52566 0.698603ZM4.81508 0.739623L4.78 0.987149L5.06508 1.02755V0.739623H4.81508ZM4.81508 0.422642H5.06508V0.134194L4.77956 0.175178L4.81508 0.422642ZM4.5309 0.463433L4.2815 0.446171L4.26013 0.754862L4.56642 0.710897L4.5309 0.463433ZM4.31088 0.25H4.56298V-0.25H4.31088V0.25ZM4.59271 0.443819L4.56026 -0.0175417L4.06149 0.0175417L4.09395 0.478902L4.59271 0.443819ZM4.02507 0.670359L4.30962 0.709078L4.37704 0.213643L4.09248 0.174924L4.02507 0.670359ZM4.30878 0.739623V0.422642H3.80878V0.739623H4.30878ZM4.31359 0.452164L4.02466 0.491961L4.09289 0.987284L4.38181 0.947487L4.31359 0.452164ZM4.56056 1.43907L4.59738 0.712479L4.09802 0.687172L4.0612 1.41376L4.56056 1.43907ZM4.56298 1.17642H4.31088V1.67642H4.56298V1.17642ZM4.27598 0.711406L4.31331 1.43922L4.81265 1.41361L4.77533 0.6858L4.27598 0.711406ZM4.85016 0.492096L4.56074 0.451077L4.49058 0.94613L4.78 0.987149L4.85016 0.492096ZM4.56508 0.422642V0.739623H5.06508V0.422642H4.56508ZM4.56642 0.710897L4.8506 0.670105L4.77956 0.175178L4.49538 0.215969L4.56642 0.710897ZM4.31358 -0.0172624L4.2815 0.446171L4.78031 0.480695L4.81238 0.0172624L4.31358 -0.0172624Z"
          fill="black"
          mask="url(#path-8-outside-1_270_55041)"
        />
      </g>
    </g>
  </svg>
);

/**
 * A secondary classification for attribute values of type "ASSESSMENT"
 */
export enum AssessmentSecondaryType {
  /** The attribute name is a value representing a URL where the assessment can be found */
  Url = 'URL',
  /** The attribute is linked to an actual assessment */
  Assessment = 'ASSESSMENT',
  /** Whether the resource should have an assessment assigned */
  Status = 'STATUS',
}

/**
 * Statuses for Assessment attributes that have no assessment assigned
 */
export enum AssessmentAttributeStatus {
  /** An assessment is not needed for this resource */
  NotNeeded = 'NOT_NEEDED',
  /** An assessment is needed, but not yet assigned, for this resource */
  Needed = 'NEEDED',
}

/**
 * Fields to order an attribute value by when paginating
 */
export enum AttributeValueOrderField {
  /** The name of the attribute value */
  Name = 'name',
  /** The created at date of the attribute value */
  CreatedAt = 'createdAt',
  /** The time the attribute value was updated */
  UpdatedAt = 'updatedAt',
}

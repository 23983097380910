import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DocumentSearch: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.49683 15.8358C2.49683 16.7566 3.24333 17.5031 4.16419 17.5031"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49692 4.16423V15.8358"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.49903 17.5031H4.16431"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5031 4.16423C17.5031 3.24337 16.7566 2.49687 15.8357 2.49687"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.503 6.66527V4.16423"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16431 2.49687H15.8358"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49683 4.16423C2.49683 3.24337 3.24333 2.49687 4.16419 2.49687"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M7.08206 14.2201V14.2201C6.39142 14.2201 5.83154 13.6602 5.83154 12.9696V12.9696C5.83154 12.2789 6.39142 11.7191 7.08206 11.7191V11.7191C7.77271 11.7191 8.33258 12.2789 8.33258 12.9696V12.9696C8.33258 13.6602 7.77271 14.2201 7.08206 14.2201V14.2201Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83154 5.88329H12.501"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83154 8.80116H9.99995"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.2505 17.0863V16.7478C11.2532 15.7848 12.0332 15.0048 12.9962 15.0021H15.7574C16.7204 15.0048 17.5003 15.7848 17.5031 16.7478V17.0863C17.5031 17.3165 17.3165 17.5031 17.0863 17.5031H11.6673C11.4371 17.5031 11.2505 17.3165 11.2505 17.0863Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.7095 11.2439C12.7132 10.3239 13.4613 9.58072 14.3813 9.58317C15.3013 9.58562 16.0454 10.3328 16.0442 11.2527C16.043 12.1727 15.2968 12.9179 14.3769 12.9179C13.9335 12.9179 13.5084 12.7413 13.1955 12.4272C12.8826 12.113 12.7077 11.6872 12.7095 11.2439V11.2439Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

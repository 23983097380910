import { transparentize } from 'polished';
import styled from 'styled-components';

import { responsive } from '@main/theme';

import { FlexRowCenterVertical } from '../StyledWrappers';
import {
  Menu,
  MenuProps,
  SubMenu as AntSubMenu,
  SubMenuProps as AntSubMenuProps,
} from './Menu';

/**
 * Props
 */
export interface FakeAProps {
  /** Display as selected */
  selected?: boolean;
  /** Underline content */
  underline?: boolean;
}

/**
 * The fake a div
 */
export const StyledFakeA = styled.div<FakeAProps>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: ${({ selected, underline }) =>
    selected || underline ? 'underline' : ''};

  &:hover {
    text-decoration: underline;
  }
`;

/**
 * Wrapper for a menu item tab
 */
export const TabWrapper = styled(FlexRowCenterVertical)`
  padding-left: 10px;
  padding-right: 10px;
  gap: 0.5em;
  color: ${({ theme }) => theme.colors.transcendNavy};
  line-height: 18px;
  height: 100%;
`;

/**
 * Props
 */
export interface SubMenuWrapperProps extends AntSubMenuProps {
  /** Margin bottom padding */
  marginbottom?: string;
}

/**
 * The sub menu wrapper
 */
export const SubMenu = styled(AntSubMenu)<SubMenuWrapperProps>`
  ${({ marginbottom }) => marginbottom && `margin-bottom: ${marginbottom};`}
`;

/**
 * Props
 */
export interface SideMenuWrapperProps extends MenuProps {
  /** Min height */
  minheight: string;
  /** Max height */
  maxheight?: string;
  /** Whether to include the gradient ::after element or not */
  havegradient: string;
}

/**
 * The SideMenu wrapper
 */
export const SideMenuWrapper = styled(Menu)<SideMenuWrapperProps>`
  height: 100%;
  min-height: ${({ minheight }) => minheight};
  ${({ maxheight, mode }) =>
    maxheight
      ? `
    max-height: ${maxheight};
    ${mode === 'inline' ? 'overflow-y: auto;' : ''}
  `
      : ''};

  ${responsive.atLeastTablet} {
    ::after {
      background: linear-gradient(
        ${({ theme }) => transparentize(1, theme.colors.sidebarNavBg)},
        ${({ theme }) => theme.colors.sidebarNavBg} 75%
      );
      content: '';
      display: block;
      height: ${({ havegradient }) =>
        havegradient === 'true' ? '230px' : '0'};
      position: absolute;
      bottom: 25%;
      width: 100%;
      pointer-events: none;
    }
  }
`;

/**
 * Restrict text to a max length
 */
export const MaxLengthWrapper = styled.span`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

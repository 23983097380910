import { transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Failure icon
 */
export const RequestFailure: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="RequestStatusIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          // eslint-disable-next-line max-len
          d="M3 8.376L12 14 21 8.376 21 6.376 12 12 3 6.376zM16.132 13.472l-5.259 9.015c-.389.667.092 1.504.864 1.504h10.518c.772 0 1.253-.837.864-1.504l-5.259-9.015C17.474 12.81 16.518 12.81 16.132 13.472zM17.996 22.991h-2v-2h2V22.991zM15.996 19.991v-4h2v4H15.996z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.176,18H4V6h16v7.171l2,3.428V6c0-1.105-0.895-2-2-2H4C2.895,4,2,4.895,2,6v12c0,1.105,0.895,2,2,2h6.009L11.176,18z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          // eslint-disable-next-line max-len
          d="M14.405,12.465c0.536-0.919,1.529-1.489,2.592-1.489s2.056,0.571,2.592,1.489L21,14.885V7l-9,6L3,7v12 h7.592L14.405,12.465z"
          fill={transparentize(0.7, theme.colors.secondary)}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

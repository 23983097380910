/**
 * The global scope object
 */

export const view = ((typeof globalThis !== 'undefined' &&
  (globalThis as unknown)) ||
  // eslint-disable-next-line no-restricted-globals
  (typeof self !== 'undefined' && self))! as Window & {
  /** Fix mocha type conflict */ URL: typeof URL;
};

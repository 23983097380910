import React from 'react';

import { SvgProps } from '../enums';

export const FlagNP: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="12" fill="white" />
    <path
      d="M5.61589 6.32009L9.93248 11.5H0.5V0.842271L9.03917 5.5H6H4.93248L5.61589 6.32009Z"
      fill="#C51918"
      stroke="#4857A1"
    />
    <mask
      id="mask0_270_55107"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="11"
      height="12"
    >
      <path
        d="M5.61589 6.32009L9.93248 11.5H0.5V0.842271L9.03917 5.5H6H4.93248L5.61589 6.32009Z"
        fill="white"
        stroke="white"
      />
    </mask>
    <g mask="url(#mask0_270_55107)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.91481 10.0051L2.30223 10.8002L2.27395 9.79689L1.31105 10.08L1.87788 9.25174L0.932453 8.91481L1.87788 8.57789L1.31105 7.74961L2.27395 8.03274L2.30223 7.02948L2.91481 7.82452L3.5274 7.02948L3.55568 8.03274L4.51858 7.74961L3.95175 8.57789L4.89718 8.91481L3.95175 9.25174L4.51858 10.08L3.55568 9.79689L3.5274 10.8002L2.91481 10.0051Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.90002 4.0694L2.58011 4.48461L2.56534 3.96066L2.06247 4.10852L2.35849 3.67595L1.86475 3.5L2.35849 3.32405L2.06247 2.89148L2.56534 3.03934L2.58011 2.51539L2.90002 2.9306L3.21994 2.51539L3.23471 3.03934L3.73758 2.89148L3.44156 3.32405L3.9353 3.5L3.44156 3.67595L3.73758 4.10852L3.23471 3.96066L3.21994 4.48461L2.90002 4.0694Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.83303 3.94704C3.94619 3.95149 4.53969 3.31999 4.53969 3.31999C4.6571 4.04057 3.75059 4.51999 2.84388 4.51999C1.93717 4.51999 1.3882 3.86648 1.04999 3.31999C1.04999 3.31999 1.71987 3.94259 2.83303 3.94704Z"
        fill="#F9FAFA"
      />
    </g>
  </svg>
);

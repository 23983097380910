import React, { useMemo } from 'react';
import type {
  FormatOptionLabelMeta,
  GroupTypeBase,
  OptionTypeBase,
} from 'react-select';
import { components, MultiValueProps } from 'react-select';
import { CSSObject } from 'styled-components';

import { ItemsWithOverflow } from '../ItemsWithOverflow';
import { RawOptionLabel } from './OptionLabel';
import { ReadOnlyMultiValueWrapper } from './wrappers';

/**
 * Our own custom multi-value with a counter for overflow values
 */
export function MultiValue<
  T extends OptionTypeBase,
  G extends GroupTypeBase<T>,
>(props: MultiValueProps<T, G>): JSX.Element | null {
  const { overflowLimit = 1 } = props.selectProps;
  // The "index" prop exists, just isn't defined in the type
  const { index } = props as any;
  // only render the overflow component for the last item before the overflow limit is hit
  const shouldRenderOverflow = index === overflowLimit - 1;

  const value = useMemo(() => props.getValue(), [props]);

  const options = useMemo(
    () =>
      // mapping values to options is unnecessary if we're not overflowing
      !shouldRenderOverflow
        ? []
        : value.map((data) => {
            const style: CSSObject = props.getStyles('multiValue', {
              ...props,
              data,
            });

            return {
              style: {
                color: style.color,
                backgroundColor: style.backgroundColor,
                borderColor: style.borderColor,
              },
              optionPill: props.selectProps?.formatOptionLabel?.(
                data,
                {} as FormatOptionLabelMeta<T, true>,
              ),
              label: props.selectProps.getOptionLabel
                ? props.selectProps.getOptionLabel(data)
                : data.label,
              logo: props.selectProps.getOptionLogo
                ? props.selectProps.getOptionLogo(data)
                : data.logo,
              wrapperStyle: {
                ...(props.children &&
                // 'props' is not a recognized property on 'children', but does in fact exist
                // and has the info we need to determine the wrapper styling to use
                'props' in (props.children as any) &&
                (props.children as any).props?.formattedOptionPill
                  ? { padding: 0 }
                  : { backgroundColor: style.backgroundColor }),
              },
            };
          }),
    [props, value, shouldRenderOverflow],
  );

  const itemsForOverflow = useMemo(
    () => [...options].splice(overflowLimit - 1),
    [options, overflowLimit],
  );

  // The current item is over the overflow limit - do nothing because we already displayed it in the overflow popover
  if (index > overflowLimit - 1) return null;

  // The current item is below the overflow limit
  if (index < overflowLimit - 1) {
    return <components.MultiValue {...props} />;
  }

  // The current item is the last before the rest of the items overflow. It displays itself AND the popover with the remaining items
  return (
    <ItemsWithOverflow
      items={itemsForOverflow}
      limit={1}
      renderElement={(_, i) => <components.MultiValue key={i} {...props} />}
      renderOverflowElement={({ wrapperStyle, ...option }, i) => (
        <ReadOnlyMultiValueWrapper key={i} style={wrapperStyle}>
          <RawOptionLabel {...option} />
        </ReadOnlyMultiValueWrapper>
      )}
    />
  );
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const EyeCheckmark: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9.41477 6.58674C10.1954 7.36741 10.1954 8.63474 9.41477 9.41674C8.6341 10.1974 7.36677 10.1974 6.58477 9.41674C5.8041 8.63608 5.8041 7.36874 6.58477 6.58674C7.36677 5.80474 8.63343 5.80474 9.41477 6.58674"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0001 10.6667L12.0001 12.6667L10.6667 11.3334"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M8 12.6666C5.53933 12.6666 3.30733 11.3393 2.29733 9.27459V9.27459C2.10133 8.87392 2 8.43925 2 7.99992C2 7.56059 2.10133 7.12592 2.29733 6.72525V6.72525C3.30733 4.66059 5.53933 3.33325 8 3.33325C10.4607 3.33325 12.6927 4.66059 13.7027 6.72525V6.72525C13.8987 7.12592 14 7.56059 14 7.99992"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

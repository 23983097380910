/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const UserCard: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9569 21.0038H5.04307C3.93761 21.0038 3.04224 20.1084 3.04224 19.003V7.99839C3.04224 6.89293 3.93761 5.99756 5.04307 5.99756H18.9569C20.0623 5.99756 20.9577 6.89293 20.9577 7.99839V19.003C20.9577 20.1084 20.0613 21.0038 18.9569 21.0038Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 8.99854C13.3816 8.99854 14.5011 10.118 14.5011 11.4996C14.5011 12.8812 13.3816 14.0006 12.0001 14.0006C10.6185 14.0006 9.49902 12.8812 9.49902 11.4996C9.49902 10.118 10.6185 8.99854 12.0001 8.99854"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5018 18.0026C16.3707 17.6734 16.1677 17.3773 15.9075 17.1362V17.1362C15.4684 16.728 14.8931 16.502 14.2929 16.502C13.2925 16.502 10.7074 16.502 9.70697 16.502C9.10672 16.502 8.53248 16.729 8.09229 17.1362V17.1362C7.83219 17.3773 7.6291 17.6734 7.49805 18.0026"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.77905 5.99734V4.99693C4.77905 3.89147 5.67443 2.99609 6.77989 2.99609H17.2192C18.3247 2.99609 19.2201 3.89147 19.2201 4.99693V5.99734"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

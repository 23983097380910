/* eslint-disable max-lines */
import {
  ActionItemCode,
  ActionItemPriorityOverride,
  ScopeName,
} from '@transcend-io/privacy-types';

import { DatabaseModelName } from '@main/schema-utils';

import { ProductArea } from './schema/enums';

/**
 * Locations where action items may be rendered
 * We can filter down action items to relevant values
 */
export enum ActionItemLocation {
  DataSilo = 'DATA_SILO',
  Request = 'REQUEST',
}

/*
 * This helper function generates a list of action items for which the given
 * property is true (or exists).
 */
export const getActionItemCodesWithProperty = <
  T extends keyof ActionItemMappingItem,
>(
  property: T,
  options?: {
    /** the value to compare against */
    value?: ActionItemMappingItem[T];
  },
): ActionItemCode[] => {
  const test = Object.entries(ACTION_ITEM_CONFIG_MAPPING)
    .filter(([, actionItem]) =>
      options && 'value' in options
        ? actionItem[property] === options.value
        : !!actionItem[property],
    )
    .map(([key]) => key as ActionItemCode);
  return test;
};

export interface ActionItemMappingItem {
  /** The config for the daemon */
  daemonConfig: {
    /** processing group determines which action items will be scheduled to run together every 2 minutes */
    processGroup: 1 | 2 | 3 | 4 | 5;
    /** scheduler lambda batch size: how many orgs do we run at a time when compiling action items, empty is all */
    batchSize?: number;
    /** is disabled at a lambda level -- this is for things that should NEVER EVER run on the lambda, like "ONBOARDING" action item */
    disabled?: boolean;
  };
  /** The optional default priority with which to initialize the action item */
  defaultPriority: ActionItemPriorityOverride | undefined;
  /** The base-model for the itemId */
  model?: DatabaseModelName;
  /** Scopes needed to view this action item type globally */
  globalScopes?: ScopeName[] | boolean;
  /** Scopes needed to view this action item type row-level */
  rowLevelScopes?: ScopeName[] | boolean;
  /** For action items that should not be rolled up */
  shouldNotRollup?: boolean;
  /** whether this action item type can be created or deleted. Default false */
  isCreatableDeletable?: boolean;
  /** whether this action item type should be deleted when it has no containing collections. Default false */
  deleteWhenCollectionOrphaned?: boolean;
  /** the areas of the product to which this action item relates */
  productAreas: ProductArea[];
  /** the locations where this action item may be rendered */
  locations: ActionItemLocation[];
}

export const ACTION_ITEM_CONFIG_MAPPING: Record<
  ActionItemCode,
  ActionItemMappingItem
> = {
  [ActionItemCode.ActionMissingSilos]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
      ScopeName.ManageDataSubjectRequestSettings,
      ScopeName.ViewDataSubjectRequestSettings,
      ScopeName.ConnectDataSilos,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [],
  },
  [ActionItemCode.ApiKeyStale]: {
    daemonConfig: {
      processGroup: 2,
    },
    defaultPriority: ActionItemPriorityOverride.Low,
    globalScopes: [ScopeName.ManageApiKeys, ScopeName.ViewApiKeys],
    rowLevelScopes: false,
    productAreas: [ProductArea.Administration],
    locations: [],
  },
  [ActionItemCode.AssessmentFormAssigned]: {
    daemonConfig: {
      processGroup: 4,
    },
    globalScopes: [ScopeName.ManageAssessments, ScopeName.ViewAssessments],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.Assessments],
    locations: [],
  },
  [ActionItemCode.AssessmentFormNeedsReview]: {
    daemonConfig: {
      processGroup: 2,
    },
    globalScopes: [ScopeName.ManageAssessments, ScopeName.ViewAssessments],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.Assessments],
    locations: [],
  },
  [ActionItemCode.BusinessEntityNeedsDocumentation]: {
    daemonConfig: {
      disabled: true, // TODO: https://transcend.height.app/T-39151 - enable
      processGroup: 4,
    },
    globalScopes: [
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
      ScopeName.ManageDataInventory,
      ScopeName.ViewDataInventory,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedDataInventory,
      ScopeName.ViewAssignedDataInventory,
    ],
    defaultPriority: undefined,
    productAreas: [ProductArea.DataMapping],
    locations: [],
  },
  [ActionItemCode.CommunicationUnread]: {
    daemonConfig: {
      processGroup: 5,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedRequests,
      ScopeName.ViewAssignedRequests,
    ],
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.Request],
  },
  [ActionItemCode.DataSiloCommunicationUnread]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedRequests,
      ScopeName.ViewAssignedRequests,
    ],
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo, ActionItemLocation.Request],
  },
  [ActionItemCode.ConsentManagerVersionUpgrade]: {
    daemonConfig: {
      processGroup: 2,
    },
    defaultPriority: ActionItemPriorityOverride.High,
    globalScopes: [ScopeName.ManageConsentManagerDeveloperSettings],
    rowLevelScopes: false,
    productAreas: [ProductArea.Consent],
    locations: [],
  },
  [ActionItemCode.CookieAssignedForReview]: {
    daemonConfig: {
      disabled: true, // TODO: https://transcend.height.app/T-39150 - re-enable
      processGroup: 3,
    },
    defaultPriority: ActionItemPriorityOverride.Medium,
    globalScopes: [ScopeName.ManageDataFlow, ScopeName.ViewDataFlow],
    rowLevelScopes: [
      ScopeName.ManageAssignedConsentManager,
      ScopeName.ViewAssignedConsentManager,
    ],
    productAreas: [ProductArea.Consent],
    locations: [],
  },
  [ActionItemCode.CookieNeedsReview]: {
    daemonConfig: {
      batchSize: 3,
      processGroup: 4,
    },
    globalScopes: [ScopeName.ManageDataFlow, ScopeName.ViewDataFlow],
    rowLevelScopes: [
      ScopeName.ManageAssignedConsentManager,
      ScopeName.ViewAssignedConsentManager,
    ],
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.Consent],
    locations: [],
  },
  [ActionItemCode.DataFlowAssignedForReview]: {
    daemonConfig: {
      disabled: true, // TODO: https://transcend.height.app/T-39150 - re-enable
      processGroup: 5,
    },
    defaultPriority: ActionItemPriorityOverride.Medium,
    globalScopes: [ScopeName.ManageDataFlow, ScopeName.ViewDataFlow],
    rowLevelScopes: [
      ScopeName.ManageAssignedConsentManager,
      ScopeName.ViewAssignedConsentManager,
    ],
    productAreas: [ProductArea.Consent],
    locations: [],
  },
  [ActionItemCode.DataFlowNeedsReview]: {
    daemonConfig: {
      batchSize: 5,
      processGroup: 4,
    },
    defaultPriority: ActionItemPriorityOverride.Medium,
    globalScopes: [ScopeName.ManageDataFlow, ScopeName.ViewDataFlow],
    rowLevelScopes: [
      ScopeName.ManageAssignedConsentManager,
      ScopeName.ViewAssignedConsentManager,
    ],
    productAreas: [ProductArea.Consent],
    locations: [],
  },
  [ActionItemCode.DataPoint]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
    ],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation, ProductArea.PrivacyCenter],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.DataPointMissingFields]: {
    daemonConfig: {
      batchSize: 1,
      processGroup: 3,
    },
    globalScopes: [ScopeName.ManageDataMap, ScopeName.ViewDataMap],
    rowLevelScopes: [
      ScopeName.ManageAssignedDataInventory,
      ScopeName.ViewAssignedDataInventory,
    ],
    defaultPriority: ActionItemPriorityOverride.Low,
    productAreas: [ProductArea.DataMapping],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.DataSiloNeedsTraining]: {
    daemonConfig: {
      disabled: true, // TODO: https://transcend.height.app/T-24561 - re-enable
      processGroup: 2,
    },
    globalScopes: [
      ScopeName.ManageDataInventory,
      ScopeName.ViewDataInventory,
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedDataInventory,
      ScopeName.ViewAssignedDataInventory,
    ],
    defaultPriority: ActionItemPriorityOverride.Low,
    productAreas: [ProductArea.DataMapping],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.DataSiloMissingIdentifiers]: {
    daemonConfig: {
      processGroup: 3,
    },
    globalScopes: [
      ScopeName.ManageDataInventory,
      ScopeName.ViewDataInventory,
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.DataSiloNeedsReconnectAssigned]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [ScopeName.ConnectDataSilos],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.DataSiloNeedsReconnect]: {
    daemonConfig: {
      processGroup: 5,
    },
    globalScopes: [ScopeName.ConnectDataSilos],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.DataSiloNotConfiguredAssigned]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [
      ScopeName.ManageDataInventory,
      ScopeName.ViewDataInventory,
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
    ],
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.DataSiloNotConfigured]: {
    daemonConfig: {
      processGroup: 2,
    },
    globalScopes: [
      ScopeName.ManageDataInventory,
      ScopeName.ViewDataInventory,
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
    ],
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.DataSilosNeedingManualEntry]: {
    daemonConfig: {
      batchSize: 5,
      processGroup: 1,
    },
    globalScopes: [
      ScopeName.ManageDataInventory,
      ScopeName.ViewDataInventory,
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo, ActionItemLocation.Request],
  },
  [ActionItemCode.LookupProcessesWithErrors]: {
    daemonConfig: {
      processGroup: 3,
    },
    globalScopes: [ScopeName.ManageDataMap, ScopeName.ViewDataMap],
    rowLevelScopes: [
      ScopeName.ManageAssignedDataInventory,
      ScopeName.ViewAssignedDataInventory,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.Onboarding]: {
    // No handler
    daemonConfig: {
      disabled: true,
      processGroup: 1,
    },
    defaultPriority: ActionItemPriorityOverride.Medium,
    globalScopes: true,
    rowLevelScopes: false,
    shouldNotRollup: true,
    isCreatableDeletable: true,
    deleteWhenCollectionOrphaned: true,
    productAreas: [ProductArea.Administration],
    locations: [],
  },
  [ActionItemCode.PluginsWithErrors]: {
    daemonConfig: {
      processGroup: 4,
    },
    globalScopes: [ScopeName.ManageDataMap, ScopeName.ViewDataMap],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DataMapping],
    locations: [ActionItemLocation.DataSilo],
  },
  [ActionItemCode.ProfileDataPointStatus]: {
    daemonConfig: {
      batchSize: 1,
      processGroup: 5,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
    ],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo, ActionItemLocation.Request],
  },
  [ActionItemCode.RequestActionableStatus]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedRequests,
      ScopeName.ViewAssignedRequests,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.Request],
  },
  [ActionItemCode.RequestOnHold]: {
    daemonConfig: {
      processGroup: 2,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedRequests,
      ScopeName.ViewAssignedRequests,
    ],
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.Request],
  },
  [ActionItemCode.RequestAssignedToUser]: {
    daemonConfig: {
      processGroup: 3,
    },
    defaultPriority: ActionItemPriorityOverride.Medium,
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedRequests,
      ScopeName.ViewAssignedRequests,
    ],
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.Request],
  },
  [ActionItemCode.RequestDataSiloError]: {
    daemonConfig: {
      processGroup: 4,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
      ScopeName.ManageDataInventory,
      ScopeName.ViewDataInventory,
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
    ],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo, ActionItemLocation.Request],
  },
  [ActionItemCode.RequestDataSiloFilesNeedReview]: {
    daemonConfig: {
      processGroup: 5,
    },
    globalScopes: [
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
      ScopeName.ManageAssignedRequests,
      ScopeName.ViewAssignedRequests,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo, ActionItemLocation.Request],
  },
  [ActionItemCode.RequestDataSiloMarkedActionable]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [
      ScopeName.ManageDataMap,
      ScopeName.ViewDataMap,
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
    ],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo, ActionItemLocation.Request],
  },
  [ActionItemCode.RequestEnricherError]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
      ScopeName.ManageRequestIdentities,
    ],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.Request],
  },
  [ActionItemCode.RequestEnricherPersonNeedsManualEntry]: {
    daemonConfig: {
      processGroup: 2,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
      ScopeName.ManageRequestIdentities,
    ],
    rowLevelScopes: [
      ScopeName.ManageAssignedRequests,
      ScopeName.ViewAssignedRequests,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.Request],
  },
  [ActionItemCode.RequestExpiry]: {
    daemonConfig: {
      batchSize: 2,
      processGroup: 4,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
    ],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.Critical,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.Request],
  },
  [ActionItemCode.RequestIdentifierNeedsVerification]: {
    daemonConfig: {
      processGroup: 4,
    },
    globalScopes: [
      ScopeName.ManageRequestCompilation,
      ScopeName.ViewRequestCompilation,
      ScopeName.RequestApproval,
      ScopeName.ManageRequestIdentities,
    ],
    rowLevelScopes: false,
    defaultPriority: undefined,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.Request],
  },
  [ActionItemCode.SombraNeedsKeyRotation]: {
    daemonConfig: {
      processGroup: 1,
    },
    globalScopes: [ScopeName.ManageSombraRootKeys],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.Low,
    productAreas: [ProductArea.Administration, ProductArea.DsrAutomation],
    locations: [],
  },
  [ActionItemCode.SombraVersionUpgrade]: {
    daemonConfig: {
      processGroup: 2,
    },
    globalScopes: [ScopeName.ManageSombraRootKeys],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.Administration, ProductArea.DsrAutomation],
    locations: [],
  },
  [ActionItemCode.UserAwaitingNotification]: {
    daemonConfig: {
      processGroup: 3,
    },
    globalScopes: [ScopeName.ManageAccessControl],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.Administration],
    locations: [],
  },
  [ActionItemCode.UserNeedsConfiguration]: {
    daemonConfig: {
      processGroup: 4,
    },
    globalScopes: [ScopeName.ManageAccessControl],
    rowLevelScopes: false,
    defaultPriority: ActionItemPriorityOverride.Medium,
    productAreas: [ProductArea.Administration],
    locations: [],
  },
  [ActionItemCode.DataPointDatabaseQueryNeedsApproval]: {
    daemonConfig: {
      processGroup: 5,
    },
    globalScopes: [ScopeName.ManageDataMap, ScopeName.ViewDataMap],
    rowLevelScopes: [
      ScopeName.ManageAssignedIntegrations,
      ScopeName.ViewAssignedIntegrations,
    ],
    defaultPriority: ActionItemPriorityOverride.High,
    productAreas: [ProductArea.DsrAutomation],
    locations: [ActionItemLocation.DataSilo],
  },
};

/* eslint-enable max-lines */

import { useEffect, useState } from 'react';

export interface UseWindowSize {
  /** window width */
  width: number;
  /** window height */
  height: number;
}

/**
 * A hook that listens for window resize and gives you the updated size
 *
 * Shamelessly stolen from https://usehooks.com/useWindowSize/
 *
 * @returns UseWindowSize
 */
export function useWindowSize(): UseWindowSize {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<UseWindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', () =>
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    );

    // Call handler right away so state gets updated with initial window size
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Remove event listener on cleanup
    return () =>
      window.removeEventListener('resize', () =>
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      );
  }, []);

  return windowSize;
}

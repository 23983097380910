/* eslint-disable max-lines */
import {
  AssessmentFormStatus,
  AssessmentSyncModel,
} from '@transcend-io/privacy-types';

import { TokenInput, UserPreview } from '@main/access-control-types';
import { AttributeValue } from '@main/attribute-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { AssessmentEvent } from './assessmentEvent';
import { AssessmentGroupPreview } from './assessmentGroup';
import { AssessmentSection, AssessmentSectionRaw } from './assessmentSection';
import { AttributeResourceInterface } from './attributeResource';
import { RetentionSchedule } from './retentionSchedule';

export const AssessmentFormExternalAssignee = mkType({
  name: 'AssessmentFormExternalAssignee',
  comment: 'The external users assigned to fill out an assessment form.',
  fields: {
    id: {
      comment: 'The id of the external assignee.',
      type: 'id',
      modelName: 'assessmentFormExternalAssignee',
    },
    email: {
      comment: 'The email of the external assignee.',
      type: 'string',
    },
    isFormCreator: {
      comment: 'Whether the external user created the form.',
      type: 'boolean',
    },
    isFormCreatorAssigned: {
      comment:
        'Whether the external user who created the form is still assigned to it.',
      type: 'boolean',
    },
  },
});

/** Override type */
export type AssessmentFormExternalAssignee = SchemaToType<
  typeof AssessmentFormExternalAssignee
>;

export const AssessmentFormRaw = mkType({
  name: 'AssessmentFormRaw',
  comment: 'Assessment form that can be filled out by users',
  fields: {
    id: {
      comment: 'The id of the assessment form',
      type: 'id',
      modelName: 'assessmentForm',
    },
    creator: {
      comment: 'The user who created the Assessment Form',
      type: UserPreview,
      optional: true,
    },
    lastEditor: {
      comment: 'The user who last edited the Assessment Form',
      type: UserPreview,
      optional: true,
    },
    title: {
      comment: 'The internal label of the assessment form',
      type: 'string',
    },
    description: {
      comment: 'The description of the assessment form',
      type: 'string',
    },
    status: {
      comment: 'The status of the assessment form',
      type: { AssessmentFormStatus },
    },
    assignees: {
      comment: 'The users assigned to provide answers for this assessment form',
      type: UserPreview,
      list: true,
    },
    externalAssignees: {
      comment:
        'The external users assigned to provide answers for this assessment form',
      type: AssessmentFormExternalAssignee,
      list: true,
    },
    reviewers: {
      comment:
        'The users responsible for reviewing the answers to this assessment form',
      type: UserPreview,
      list: true,
    },
    isLocked: {
      comment: 'Whether the form is locked',
      type: 'boolean',
    },
    isArchived: {
      comment: 'Whether the form is archived',
      type: 'boolean',
    },
    isExternallyCreated: {
      comment: 'Whether the form is created by an external user',
      type: 'boolean',
    },
    dueDate: {
      comment: 'The date by which the assessment form should be completed',
      type: 'Date',
      optional: true,
    },
    createdAt: {
      comment: 'The date the assessmentForm was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the assessmentForm was last updated',
      type: 'Date',
    },
    assignedAt: {
      comment: 'The date the assessmentForm was assigned to a respondent',
      type: 'Date',
      optional: true,
    },
    submittedAt: {
      comment: 'The date the assessmentForm was submitted by the respondent',
      type: 'Date',
      optional: true,
    },
    approvedAt: {
      comment: 'The date the assessmentForm responses were approved',
      type: 'Date',
      optional: true,
    },
    rejectedAt: {
      comment: 'The date the assessmentForm responses were rejected',
      type: 'Date',
      optional: true,
    },
    sections: {
      comment: 'The Assessment sections under this form',
      type: AssessmentSectionRaw,
      list: true,
    },
    assessmentGroup: {
      comment: 'The assessment group to which this form belongs',
      type: AssessmentGroupPreview,
    },
    resources: {
      comment:
        'Resources that have this assessment form assigned as an attribute',
      type: AttributeResourceInterface,
      list: true,
    },
    syncedRows: {
      comment: 'Rows that answers to this assessment form will be synced to',
      type: AttributeResourceInterface,
      list: true,
    },
    latestEvent: {
      comment: 'The latest event associated with the assessment form',
      type: AssessmentEvent,
    },
    titleIsInternal: {
      comment:
        // eslint-disable-next-line max-len
        'Whether the form title is an internal label only, and the group title should be used in communications with assignees',
      type: 'boolean',
    },
    retentionSchedule: {
      comment: 'The retention schedule for the Assessment Form',
      type: RetentionSchedule,
      optional: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentFormRaw = SchemaToType<typeof AssessmentFormRaw>;

/** Type of the full, parsed AssessmentForm; raw and full types are helpful when using `buildUseTransformQuery` */
export type AssessmentForm = Omit<AssessmentFormRaw, 'sections'> & {
  /** sections with questions with parsed `displayLogic` */
  sections: AssessmentSection[];
};

export const DeleteAssessmentFormsInput = mkInput({
  name: 'DeleteAssessmentFormsInput',
  comment: 'Input for deleting multiple assessment forms',
  fields: {
    ids: {
      comment: 'The id of the assessment forms to delete',
      type: 'id',
      modelName: 'assessmentForm',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentFormsInput = SchemaToType<
  typeof DeleteAssessmentFormsInput
>;

export const AssessmentFormSectionAssigneesInput = mkInput({
  name: 'AssessmentFormSectionAssigneesInput',
  comment: 'The users assigned to fill out an assessment form.',
  fields: {
    index: {
      comment: 'The index of the section',
      type: 'int',
    },
    assigneeIds: {
      comment:
        'The IDs of the users assigned to fill out this assessment section',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    externalAssigneeEmails: {
      comment:
        'The emails of the external users assigned to review this assessment section',
      type: 'string',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentFormSectionAssigneesInput = SchemaToType<
  typeof AssessmentFormSectionAssigneesInput
>;

export const OneTrustAssessmentColumnInput = mkInput({
  name: 'OneTrustAssessmentColumnInput',
  comment: 'The columns of a row of a OneTrust csv assessment file.',
  fields: {
    title: {
      comment: 'The title of the column',
      type: 'string',
    },
    value: {
      comment: 'The value of the column.',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type OneTrustAssessmentColumnInput = SchemaToType<
  typeof OneTrustAssessmentColumnInput
>;

export const OneTrustAssessmentRowInput = mkInput({
  name: 'OneTrustAssessmentRowInput',
  comment: 'Input for importing an assessment form from Onetrust',
  fields: {
    columns: {
      comment: 'A list of columns within this row.',
      type: OneTrustAssessmentColumnInput,
      optional: false,
      list: true,
    },
  },
});

/** Override type */
export type OneTrustAssessmentRowInput = SchemaToType<
  typeof OneTrustAssessmentRowInput
>;

export const ImportOnetrustAssessmentsInput = mkInput({
  name: 'ImportOnetrustAssessmentsInput',
  comment: 'Input for importing multiple Onetrust assessment forms',
  fields: {
    rows: {
      comment: 'The rows of the CSV file.',
      type: OneTrustAssessmentRowInput,
      list: true,
      optional: true,
    },
    json: {
      comment:
        'A stringified list of OneTrustEnrichedAssessment assessment objects.',
      type: 'string',
      list: false,
      optional: true,
    },
  },
});

/** Override type */
export type ImportOnetrustAssessmentsInput = SchemaToType<
  typeof ImportOnetrustAssessmentsInput
>;

export const AssessmentFormSyncRowsInput = mkInput({
  name: 'AssessmentFormSyncRowsInput',
  comment:
    'Input for setting all the syncing row ids of questions in an assessment form',
  fields: {
    syncModel: {
      comment: 'The model the sync row ids belong to',
      type: { AssessmentSyncModel },
    },
    syncRowIds: {
      comment:
        'The ids of the rows that the assessment answers will be synced back to',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type AssessmentFormSyncRowsInput = SchemaToType<
  typeof AssessmentFormSyncRowsInput
>;

export const CreateAssessmentFormInput = mkInput({
  name: 'CreateAssessmentFormInput',
  comment: 'Input for creating an assessment form',
  fields: {
    assessmentGroupId: {
      comment: 'The id of the group this assessment form belongs to',
      type: 'id',
      modelName: 'assessmentGroup',
    },
    title: {
      comment: 'The internal label of the assessment form',
      type: 'string',
      optional: true,
    },
    assigneeIds: {
      comment: 'The IDs of the users assigned to fill out this assessmentForm',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    externalAssigneeEmails: {
      comment:
        'The emails of the external users assigned to review this assessmentForm',
      type: 'string',
      optional: true,
      list: true,
    },
    sectionAssignees: {
      comment:
        'A list of internal and external users to assign to the form sections.',
      type: AssessmentFormSectionAssigneesInput,
      list: true,
      optional: true,
    },
    syncRows: {
      comment: 'The rows to sync assessment answers back to',
      type: AssessmentFormSyncRowsInput,
      list: true,
      optional: true,
    },
    titleIsInternal: {
      comment:
        // eslint-disable-next-line max-len
        'Whether the form title is an internal label only, and the group title should be used in communications with assignees',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type CreateAssessmentFormInput = SchemaToType<
  typeof CreateAssessmentFormInput
>;

export const CreateAssessmentFormsInput = mkInput({
  name: 'CreateAssessmentFormsInput',
  comment: 'Input for creating multiple assessment forms',
  fields: {
    assessmentForms: {
      comment: 'The assessment forms to create',
      type: CreateAssessmentFormInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateAssessmentFormsInput = SchemaToType<
  typeof CreateAssessmentFormsInput
>;

export const CreateAssessmentFormTokenInput = mkInput({
  name: 'CreateAssessmentFormTokenInput',
  comment:
    'Input that external users can creating an assessment form with an auth token.',
  fields: {
    email: {
      comment: 'The email of the external user creating the form.',
      type: 'string',
    },
    // the assessment group related token to authenticate the external user
    token: TokenInput.fields.token,
  },
});

/** Override type */
export type CreateAssessmentFormTokenInput = SchemaToType<
  typeof CreateAssessmentFormTokenInput
>;

export const UpdateAssessmentFormInput = mkInput({
  name: 'UpdateAssessmentFormInput',
  comment: 'Input for updating an assessment form',
  fields: {
    id: {
      comment: 'The id of the assessment form',
      modelName: 'assessmentForm',
      type: 'id',
    },
    title: CreateAssessmentFormInput.fields.title,
    description: {
      comment: 'The description of the assessment form',
      type: 'string',
      optional: true,
    },
    status: {
      comment: 'The status of the assessment form',
      type: { AssessmentFormStatus },
      optional: true,
    },
    assigneeIds: CreateAssessmentFormInput.fields.assigneeIds,
    externalAssigneeEmails:
      CreateAssessmentFormInput.fields.externalAssigneeEmails,
    reviewerIds: {
      comment: 'The IDs of the users assigned to review this assessmentForm',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    isArchived: {
      comment: 'Whether the form to archive or unarchive this form',
      type: 'boolean',
      optional: true,
    },
    dueDate: {
      comment: 'The new due date for filling out the form',
      type: 'Date',
      optional: true,
    },
    sendNotification: {
      comment: 'Send email notification on approval/rejection',
      type: 'boolean',
      optional: true,
    },
    comment: {
      comment: 'Comment to be added when updating the status',
      type: 'string',
      optional: true,
    },
    sectionIdsToNotify: {
      comment:
        'The ids of sections with assignees to notify when requesting changes',
      type: 'id',
      modelName: 'assessmentSection',
      list: true,
      optional: true,
    },
    titleIsInternal: CreateAssessmentFormInput.fields.titleIsInternal,
  },
});

/** Override type */
export type UpdateAssessmentFormInput = SchemaToType<
  typeof UpdateAssessmentFormInput
>;

export const AssessmentFormFiltersInput = mkInput({
  name: 'AssessmentFormFiltersInput',
  comment: 'Inputs for filtering a list of assessment forms',
  fields: {
    ids: {
      comment: 'The ids of assessment forms',
      type: 'id',
      modelName: 'assessmentForm',
      optional: true,
      list: true,
    },
    assigneeIds: {
      comment:
        'The ids of users assigned to provide answers for assessment forms',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    externalAssigneeEmails: {
      comment: 'The emails of the external users assigned to the form',
      type: 'string',
      optional: true,
      list: true,
    },
    reviewerIds: {
      comment: 'The ids of the users assigned to review assessment forms',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    text: {
      comment: 'The labels or descriptions of assessment forms',
      optional: true,
      type: 'string',
    },
    templateIds: {
      comment: 'The IDs of templates from which assessment forms were created',
      type: 'id',
      modelName: 'assessmentFormTemplate',
      optional: true,
      list: true,
    },
    assessmentGroupIds: {
      comment: 'The IDs of groups to which assessment forms belong',
      type: 'id',
      modelName: 'assessmentGroup',
      optional: true,
      list: true,
    },
    includeAssignedSectionsOnly: {
      comment: 'Whether to include only sections assigned to the user',
      type: 'boolean',
      optional: true,
    },
    // TODO: filter by creatorIds and lastEditorIds - https://transcend.height.app/T-31258
  },
});

/** Override type */
export type AssessmentFormFiltersInput = SchemaToType<
  typeof AssessmentFormFiltersInput
>;
/* eslint-enable max-lines */

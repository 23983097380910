/* eslint-disable max-lines, max-len */
import { defineMessages } from '@transcend-io/internationalization';
import {
  DataCategoryType,
  ProcessingPurpose,
} from '@transcend-io/privacy-types';

import { DataCollectionStaticType } from './schema/enums';

/**
 * The default footer for the DSR report template
 */
export const DEFAULT_REPORT_FOOTER = `
  <br />
  <p>Kind regards,</p>
  <p><b>{{ organizationName }}</b></p>
`;

/**
 * Default header for a DSR report
 */
export const DEFAULT_REPORT_HEADER = `
  <h2>Hi there!</h2>
`;

/**
 * Messages for `ACCESS` requests
 */
export const accessMessages = defineMessages('datamap-types.src.access', {
  adminTitle: {
    defaultMessage: 'Access',
  },
  description: {
    defaultMessage: 'Request your data for browsing and download',
  },
  secondaryDescription: {
    defaultMessage: `We will provide you with a copy of the personal data we have about you.
        You will be able to view your data on this Privacy Center, as well as download
        a copy of it to your device. This request is usually fulfilled right away,
        but may take up to 30 days. You will receive an email confirming receipt of this request,
        and an email with a link to your data download report when it is ready.`,
  },
  subjectTitle: {
    defaultMessage: 'Download all my data',
  },
  report: {
    defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p> Thank you for your request to access your personal data. We have done some digging for your personal data records, and here is a full report of what we found. The data download report will remain available to you for {{ downloadAvailabilityDuration }} days.</p>
    <br />
    <h3>Does {{ organizationName }} process my personal data?</h3>
    <p>Yes. We checked and confirmed that we process your personal data after receiving your request.</p>
    <br />
    ${DEFAULT_REPORT_FOOTER}
  `,
  },
});

/**
 * Messages for `TRACKING_OPT_OUT` requests
 */
export const trackingOptOutMessages = defineMessages(
  'datamap-types.src.trackingOptOut',
  {
    adminTitle: {
      defaultMessage: 'Opt Out of Tracking',
    },
    description: {
      defaultMessage:
        'Explore your options for opting out of our tracking services',
    },
    secondaryDescription: {
      defaultMessage: `Opting out of tracking will halt your data from
    being sent to and used by many of our analytics scripts.`,
    },
    subjectTitle: {
      defaultMessage: 'Do not track',
    },
    report: {
      defaultMessage: `
      ${DEFAULT_REPORT_HEADER}
      <p>We have successfully honored your request to stop tracking. You will no longer be tracked by our analytics tools.</p>
      ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `TRACKING_OPT_IN` requests
 */
export const trackingOptInMessages = defineMessages(
  'datamap-types.src.trackingOptIn',
  {
    adminTitle: {
      defaultMessage: 'Opt In to Tracking',
    },
    description: {
      defaultMessage: 'Opt back into tracking and analytics',
    },
    secondaryDescription: {
      defaultMessage: `Opting back into tracking and analytics allows our team to improve the product experience.`,
    },
    subjectTitle: {
      defaultMessage: 'Opt into tracking & analytics',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your request to opt in to tracking & analytics.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `AUTOMATED_DECISION_MAKING_OPT_OUT` requests
 */
export const automatedDecisionMakingOptOutMessages = defineMessages(
  'datamap-types.src.automatedDecisionMakingOptOut',
  {
    adminTitle: {
      defaultMessage: 'Opt Out of Automated Decision-Making',
    },
    description: {
      defaultMessage:
        'Explore your options for opting out of automated decision-making',
    },
    secondaryDescription: {
      defaultMessage: `Opting out of automated decision may remove our ability to
    provide certain services on your behalf.  Please make sure that you know the
    consequences of this action.`,
    },
    subjectTitle: {
      defaultMessage: 'Stop automated decision-making',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your request to stop automated decision-making.</p>
    ${DEFAULT_REPORT_FOOTER}
`,
    },
  },
);

/**
 * Messages for `AUTOMATED_DECISION_MAKING_OPT_IN` requests
 */
export const automatedDecisionMakingOptInMessages = defineMessages(
  'datamap-types.src.automatedDecisionMakingOptIn',
  {
    adminTitle: {
      defaultMessage: 'Opt In to Automated Decision Making',
    },
    description: {
      defaultMessage: 'Opt back into automated decision making',
    },
    secondaryDescription: {
      defaultMessage: `Opting back into automated decision making allows us to serve you a faster experience.`,
    },
    subjectTitle: {
      defaultMessage: 'Opt into automated decision making',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your request to opt in to automated decision making.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `CUSTOM_OPT_OUT` requests
 */
export const customOptOutMessages = defineMessages(
  'datamap-types.src.customOptOut',
  {
    adminTitle: {
      defaultMessage: 'Custom Opt Out',
    },
    description: {
      defaultMessage: 'Make an opt out',
    },
    secondaryDescription: {
      defaultMessage: `Opting out of a custom action.`,
    },
    subjectTitle: {
      defaultMessage: 'Custom opt out',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your opt out.</p>
    ${DEFAULT_REPORT_FOOTER}
`,
    },
  },
);

/**
 * Messages for `CUSTOM_OPT_IN` requests
 */
export const customOptInMessages = defineMessages(
  'datamap-types.src.customOptIn',
  {
    adminTitle: {
      defaultMessage: 'Custom Opt In',
    },
    description: {
      defaultMessage: 'Make an opt in',
    },
    secondaryDescription: {
      defaultMessage: `Opting into a custom action.`,
    },
    subjectTitle: {
      defaultMessage: 'Custom opt in',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your opt in.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `USE_OF_SENSITIVE_INFORMATION_OPT_OUT` requests
 */
export const useOfSensitiveInformationOptOutMessages = defineMessages(
  'datamap-types.src.useOfSensitiveInformationOptOut',
  {
    adminTitle: {
      defaultMessage: 'Sensitive Information Opt Out',
    },
    description: {
      defaultMessage:
        'Make an opt out to limit our usage of your sensitive information.',
    },
    secondaryDescription: {
      defaultMessage: `Opting out will limit the collection and usage of your sensitive information.`,
    },
    subjectTitle: {
      defaultMessage: 'Sensitive information opt out',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your opt out of sensitive information request.</p>
    ${DEFAULT_REPORT_FOOTER}
`,
    },
  },
);

/**
 * Messages for `USE_OF_SENSITIVE_INFORMATION_OPT_IN` requests
 */
export const useOfSensitiveInformationOptInMessages = defineMessages(
  'datamap-types.src.useOfSensitiveInformationOptIn',
  {
    adminTitle: {
      defaultMessage: 'Sensitive Information Opt In',
    },
    description: {
      defaultMessage:
        'Opt back into the ability for us to use your sensitive information.',
    },
    secondaryDescription: {
      defaultMessage: `Opting in will allow us to start using your sensitive information again. This will enable additional functionality in the product.`,
    },
    subjectTitle: {
      defaultMessage: 'Sensitive information opt in',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your opt in to the usage of sensitive information.</p>
    ${DEFAULT_REPORT_FOOTER}
`,
    },
  },
);

/**
 * Messages for `CONTACT_OPT_OUT` requests
 */
export const contactOptOutMessages = defineMessages(
  'datamap-types.src.contactOptOut',
  {
    adminTitle: {
      defaultMessage: 'Communication Opt-Out',
    },
    description: {
      defaultMessage:
        'Explore your options for opting out of communications from us',
    },
    secondaryDescription: {
      defaultMessage: `We will opt you out of our communications.  There may be some exceptions in cases
      where superseding regulations require we contact you. We will notify you by email
      regarding updates to the status of this request.`,
    },
    subjectTitle: {
      defaultMessage: 'Do not contact',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your contact opt out request and added you to our "Do Not Contact" list. If you would like to start receiving our messages again, visit the Privacy Center ({{ baseUrl }}) to be removed from this list.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `CONTACT_OPT_IN` requests
 */
export const contactOptInMessages = defineMessages(
  'datamap-types.src.contactOptIn',
  {
    adminTitle: {
      defaultMessage: 'Communication Opt-In',
    },
    description: {
      defaultMessage: 'Opt back into marketing communications',
    },
    secondaryDescription: {
      defaultMessage: `Opting back into the marketing communications will allow us to send you updates.`,
    },
    subjectTitle: {
      defaultMessage: 'Marketing opt in',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your request to opt in to marketing.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `ERASURE` requests
 */
export const erasureMessages = defineMessages('datamap-types.src.erasure', {
  adminTitle: {
    defaultMessage: 'Erasure',
  },
  description: {
    defaultMessage: 'Request your user data to be deleted from our systems',
  },
  secondaryDescription: {
    defaultMessage: `We will delete all personal data we have about you. There may be some exceptions.
      For example, we may be required to keep some data in order to comply with anti-fraud regulations.
      Note that by erasing your data we will no longer provide our services to you.
      For security reasons, we have a wait period on data erasure: we will give you the
      opportunity to cancel this erasure request for 14 days. After that, this erasure operation
       is final and cannot be reversed. We will notify you by email regarding updates to the status
       of this request.`,
  },
  subjectTitle: {
    defaultMessage: 'Delete all my data',
  },
  report: {
    defaultMessage: `${DEFAULT_REPORT_HEADER}
<p>We have begun the process of deleting your {{ organizationName }} account. If you would like to cancel this account deletion, please take action in the Privacy Center ({{ baseUrl }}).</p>
<br />
<p>You have the opportunity to back up your {{ organizationName }} data at the provided link below. The export will remain available to two weeks, after which your account will be permanently deleted.</p>
<br />
<h3>Does {{ organizationName }} process my personal data?</h3>
<p>Yes. We checked and confirmed that we process your personal data after receiving your request. We removed all of your data from our records, and will continue to do so if it they show up again.</p>
${DEFAULT_REPORT_FOOTER}
`,
  },
  secondaryReport: {
    defaultMessage: `${DEFAULT_REPORT_HEADER}
<p>We have successfully honored your request to delete your account, and your data has been removed from our records.</p>
<br />
${DEFAULT_REPORT_FOOTER}
`,
  },
});

/**
 * Messages for `RECTIFICATION` requests
 */
export const rectificationMessages = defineMessages(
  'datamap-types.src.rectification',
  {
    adminTitle: {
      defaultMessage: 'Rectification',
    },
    description: {
      defaultMessage:
        'We will correct your information if it needs to be updated',
    },
    secondaryDescription: {
      defaultMessage: `We will do as much as we are able and legally required to do to make sure
    that your information is rectified.`,
    },
    subjectTitle: {
      defaultMessage: 'Update inaccuracies',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have received your request and successfully updated our records with the information you provided us. We did some digging for your personal data records, and here is a full report of what we found.</p>
    <br />
    <h3>Does {{ organizationName }} process my personal data?</h3>
    <p>Yes. We checked and confirmed that we process your personal data after receiving your request.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
    details: {
      defaultMessage:
        'Please describe what inaccuracy you would like to update',
      description:
        'The description text for the freeform details form item for rectification requests.',
    },
  },
);

/**
 * Messages for `RESTRICTION` requests
 */
export const restrictionMessages = defineMessages(
  'datamap-types.src.restriction',
  {
    adminTitle: {
      defaultMessage: 'Restriction',
    },
    description: {
      defaultMessage: 'Ask us to push pause on our data processing activities',
    },
    secondaryDescription: {
      defaultMessage: `We will, to the extent specified in our data policies,
      restrict the data processing operations that may touch your data.`,
    },
    subjectTitle: {
      defaultMessage: 'Restrict Processing',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have received and successfully processed your restriction request.</p>
    <br />
    <h3>Does {{ organizationName }} process my personal data?</h3>
    <p>Yes. We checked and confirmed that we process your personal data after receiving your request.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
    details: {
      defaultMessage:
        'Please describe what you need restricted and for what reason?',
    },
  },
);

/**
 * Messages for `BUSINESS_PURPOSE` requests
 */
export const businessPurposeMessages = defineMessages(
  'datamap-types.src.businessPurpose',
  {
    adminTitle: {
      defaultMessage: 'Business Purpose',
    },
    description: {
      defaultMessage:
        'Ask us for a report that describes which of your data we collect and for what purposes we use that data',
    },
    secondaryDescription: {
      defaultMessage: `We will send you a report that describes the different types of data we collect about you. We will break down how we use your data and who we share it with.`,
    },
    subjectTitle: {
      defaultMessage: 'Business purpose',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have received and successfully processed your business process request.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `SALE_OPT_OUT` requests
 */
export const saleOptOutMessages = defineMessages(
  'datamap-types.src.saleOptOut',
  {
    adminTitle: {
      defaultMessage: 'Opt Out of Sale',
    },
    description: {
      defaultMessage:
        'Explore your options for opting out of the sale of your personal data',
    },
    secondaryDescription: {
      defaultMessage: `Opting out of the sale of your personal data prevents
    your data from being sold, even in anonymized form.`,
    },
    subjectTitle: {
      defaultMessage: 'Do not sell my personal information',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your request to not sell your personal data.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `SALE_OPT_IN` requests
 */
export const saleOptInMessages = defineMessages('datamap-types.src.saleOptIn', {
  adminTitle: {
    defaultMessage: 'Opt In to Sale',
  },
  description: {
    defaultMessage: 'Opt back into the sale of your personal data',
  },
  secondaryDescription: {
    defaultMessage: `Opting back into the sale of your personal data allows us to improve the advertisements we share with you.`,
  },
  subjectTitle: {
    defaultMessage: 'Opt into the sale of personal data',
  },
  report: {
    defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully honored your request to opt in to the sale of your personal data.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
  },
});

/**
 * Messages for `PLACE_ON_LEGAL_HOLD` requests
 */
export const placeOnLegalHoldMessages = defineMessages(
  'datamap-types.src.placeOnLegalHold',
  {
    adminTitle: {
      defaultMessage: 'Place on Legal Hold',
    },
    description: {
      defaultMessage:
        'Place a user on legal hold, and freeze access to their accounts.',
    },
    secondaryDescription: {
      defaultMessage: `By clicking this request, your accounts will be frozen.`,
    },
    subjectTitle: {
      defaultMessage: 'Place on Legal Hold',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully placed you on hold.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

/**
 * Messages for `REMOVE_FROM_LEGAL_HOLD` requests
 */
export const removeFromLegalHoldMessages = defineMessages(
  'datamap-types.src.removeFromLegalHold',
  {
    adminTitle: {
      defaultMessage: 'Remove from Legal Hold',
    },
    description: {
      defaultMessage:
        'Remove a user on legal hold, and unfreeze access to their accounts.',
    },
    secondaryDescription: {
      defaultMessage: `Unfreeze your accounts.`,
    },
    subjectTitle: {
      defaultMessage: 'Remove from Legal Hold',
    },
    report: {
      defaultMessage: `
    ${DEFAULT_REPORT_HEADER}
    <p>We have successfully removed the hold on you.</p>
    ${DEFAULT_REPORT_FOOTER}
  `,
    },
  },
);

export const subjectMessages = defineMessages('datamap-types.src.subject', {
  emailLogin: {
    defaultMessage:
      'Please enter your email address so we can send you a link to verify your identity and begin processing your request.',
    description:
      'The message displayed when prompting the user to plug in their email address for verification',
  },
  customerTitle: {
    defaultMessage: 'Customer',
  },
});

export const dataCategoryTypeMessages = defineMessages<DataCategoryType>(
  'datamap-types.src.dataCategoryType',
  {
    [DataCategoryType.Financial]: { defaultMessage: 'Financial' },
    [DataCategoryType.Health]: { defaultMessage: 'Health' },
    [DataCategoryType.Contact]: { defaultMessage: 'Contact' },
    [DataCategoryType.Location]: { defaultMessage: 'Location' },
    [DataCategoryType.Demographic]: {
      defaultMessage: 'Generic Personal Information',
    },
    [DataCategoryType.Id]: { defaultMessage: 'Personal Identifier' },
    [DataCategoryType.OnlineActivity]: {
      defaultMessage: 'User Online Activity',
    },
    [DataCategoryType.UserProfile]: { defaultMessage: 'User Profile' },
    [DataCategoryType.SocialMedia]: { defaultMessage: 'Social Media Data' },
    [DataCategoryType.Connection]: { defaultMessage: 'IP Address/Device IDs' },
    [DataCategoryType.Tracking]: {
      defaultMessage: 'Cookies/Tracking Elements',
    },
    [DataCategoryType.Device]: { defaultMessage: 'Computer Information' },
    [DataCategoryType.Survey]: { defaultMessage: 'Survey Data' },
    [DataCategoryType.Other]: { defaultMessage: 'Other' },
    [DataCategoryType.Unspecified]: { defaultMessage: 'Unspecified' },
    [DataCategoryType.NotPersonalData]: { defaultMessage: 'Not Personal Data' },
    [DataCategoryType.IntegrationIdentifier]: {
      defaultMessage: 'Integration-related Identifier',
    },
  },
);

export const dataCategoryTypeDescriptionMessages =
  defineMessages<DataCategoryType>(
    'datamap-types.src.dataCategoryTypeDescription',
    {
      [DataCategoryType.Financial]: {
        defaultMessage:
          'Financial information, such as credit/debit card data, other payment information, credit scores, etc.',
      },
      [DataCategoryType.Health]: {
        defaultMessage:
          'Health information, such as information about health conditions, prescriptions, medication, as well as health monitoring data, e.g., heart rate, step count, activity level, etc.',
      },
      [DataCategoryType.Contact]: {
        defaultMessage:
          'Contact information, such as name, email address, phone number, street address, etc.',
      },
      [DataCategoryType.Location]: {
        defaultMessage:
          "Geo-location information (e.g., user's current location) regardless of granularity, i.e., could be exact location, ZIP code, city-level.",
      },
      [DataCategoryType.Demographic]: {
        defaultMessage:
          'Demographic information, e.g., gender, age, occupation, education, etc.',
      },
      [DataCategoryType.Id]: {
        defaultMessage:
          "Identifiers that uniquely identify a person, e.g., SSN, driver's license number, etc.",
      },
      [DataCategoryType.OnlineActivity]: {
        defaultMessage:
          "The user's online activities on the first party website/app or other websites/apps, e.g., pages visited, time spent on pages, general user behavior online, etc.",
      },
      [DataCategoryType.UserProfile]: {
        defaultMessage:
          "The user's profile on the first-party website/app and its contents, e.g., data in user profile, data that user uploaded to website, user comments, user profile preferences, etc. This is common for websites/apps where users can create an account or profile, e.g., on twitter, youtube, Facebook, Amazon, etc.",
      },
      [DataCategoryType.SocialMedia]: {
        defaultMessage:
          'User profile and data from a social media website/app or other third party service to which the user gave the first party access, e.g., by connecting with Facebook, twitter, or other services. Exchanged data may include user profile, photos, comments, friends, etc.',
      },
      [DataCategoryType.Connection]: {
        defaultMessage:
          'Permanent (e.g., device IDs, MAC address) or temporary (e.g., IP address) identifiers needed to establish a connection for the current browsing session.',
      },
      [DataCategoryType.Tracking]: {
        defaultMessage:
          "Identifiers locally stored on user's device by the company/organization or third-parties including cookies, beacons, or similar that are commonly used to uniquely identify users, but that are not essential to establish a connection with the user's device or to provide a service.",
      },
      [DataCategoryType.Device]: {
        defaultMessage:
          'The type of operating system (OS) or web browser that the user uses, or similar computer or device information.',
      },
      [DataCategoryType.Survey]: {
        defaultMessage: 'Any data that is collected through surveys',
      },
      [DataCategoryType.Other]: {
        defaultMessage:
          'A specific type of information not covered by the above categories.',
      },
      [DataCategoryType.Unspecified]: {
        defaultMessage:
          'The type of information is not explicitly stated or unclear (e.g., refers to "information" very generically).',
      },
      [DataCategoryType.NotPersonalData]: {
        defaultMessage: 'Information that is explicitly not personal data',
      },
      [DataCategoryType.IntegrationIdentifier]: {
        defaultMessage:
          'Information that is related to an identifying an object in an integration. For example, Salesforce Object Ids.',
      },
    },
  );

export const processingPurposeMessages = defineMessages<ProcessingPurpose>(
  'datamap-types.src.processingPurpose',
  {
    [ProcessingPurpose.Essential]: { defaultMessage: 'Basic Service/Feature' },
    [ProcessingPurpose.AdditionalFunctionality]: {
      defaultMessage: 'Additional Service/Feature',
    },
    [ProcessingPurpose.Advertising]: { defaultMessage: 'Advertising' },
    [ProcessingPurpose.Marketing]: { defaultMessage: 'Marketing' },
    [ProcessingPurpose.Analytics]: { defaultMessage: 'Analytics/Research' },
    [ProcessingPurpose.Personalization]: {
      defaultMessage: 'Personalization/Customization',
    },
    [ProcessingPurpose.OperationSecurity]: {
      defaultMessage: 'Service Operation/Security',
    },
    [ProcessingPurpose.Legal]: { defaultMessage: 'Legal' },
    [ProcessingPurpose.Transfer]: { defaultMessage: 'Merger & Acquisition' },
    [ProcessingPurpose.Sale]: { defaultMessage: 'Third-party Data Sale' },
    [ProcessingPurpose.HR]: { defaultMessage: 'HR' },
    [ProcessingPurpose.Other]: { defaultMessage: 'Other' },
    [ProcessingPurpose.Unspecified]: { defaultMessage: 'Unspecified' },
  },
);

export const processingPurposeDescriptionMessages =
  defineMessages<ProcessingPurpose>(
    'datamap-types.src.processingPurposeDescription',
    {
      [ProcessingPurpose.Essential]: {
        defaultMessage:
          "To provide a service that the user explicitly requests and that is part of the product's basic service or functionality",
      },
      [ProcessingPurpose.AdditionalFunctionality]: {
        defaultMessage:
          "Provide a service that the user explicitly requests but that is not a necessary part of the product's basic service",
      },
      [ProcessingPurpose.Advertising]: {
        defaultMessage:
          'To show ads that are either targeted to the specific user or not targeted',
      },
      [ProcessingPurpose.Marketing]: {
        defaultMessage:
          'To contact the user to offer products, services, or other promotions',
      },
      [ProcessingPurpose.Analytics]: {
        defaultMessage:
          "For understanding the product's audience, improving the product, inform company strategy, or general research",
      },
      [ProcessingPurpose.Personalization]: {
        defaultMessage: 'For providing user with a personalized experience',
      },
      [ProcessingPurpose.OperationSecurity]: {
        defaultMessage:
          'For product operation and security, enforcement of terms of service, fraud prevention, protecting users and property, etc.',
      },
      [ProcessingPurpose.Legal]: {
        defaultMessage: 'For compliance with legal obligations',
      },
      [ProcessingPurpose.Transfer]: {
        defaultMessage:
          'For data that was transferred as part of a change in circumstance (e.g. a merger or acquisition)',
      },
      [ProcessingPurpose.Sale]: {
        defaultMessage: 'For selling the data to third parties',
      },
      [ProcessingPurpose.HR]: {
        defaultMessage:
          'For personnel training, recruitment, payroll, management, etc.',
      },
      [ProcessingPurpose.Other]: {
        defaultMessage: 'For some other specific purpose not covered above',
      },
      [ProcessingPurpose.Unspecified]: {
        defaultMessage: 'The purpose is not explicitly stated or is unclear',
      },
    },
  );

// DataCollectionStaticType messages
export const dataCollectionStaticTypeTitle =
  defineMessages<DataCollectionStaticType>(
    'datamap-types.src.dataCollectionStaticTypeTitle',
    {
      [DataCollectionStaticType.AppUsage]: {
        defaultMessage: 'App Usage',
      },
      [DataCollectionStaticType.CallHistory]: {
        defaultMessage: 'Call History',
      },
      [DataCollectionStaticType.ContactInfo]: {
        defaultMessage: 'Contact Info',
      },
      [DataCollectionStaticType.DeviceInformation]: {
        defaultMessage: 'Device Information',
      },
      [DataCollectionStaticType.Interests]: {
        defaultMessage: 'Interests',
      },
      [DataCollectionStaticType.IpAddress]: {
        defaultMessage: 'IP Address',
      },
      [DataCollectionStaticType.Location]: {
        defaultMessage: 'Location',
      },
      [DataCollectionStaticType.Messages]: {
        defaultMessage: 'Messages',
      },
      [DataCollectionStaticType.ProfilePicture]: {
        defaultMessage: 'Profile Picture',
      },
      [DataCollectionStaticType.SearchHistory]: {
        defaultMessage: 'Search History',
      },
      [DataCollectionStaticType.Sites]: {
        defaultMessage: 'Sites You Visit (Cookies)',
      },
      [DataCollectionStaticType.StoreVisits]: {
        defaultMessage: 'Store Visits',
      },
      [DataCollectionStaticType.UserProfile]:
        dataCategoryTypeMessages.USER_PROFILE,
      [DataCollectionStaticType.PersonalIdentifier]:
        dataCategoryTypeMessages.ID,
      [DataCollectionStaticType.Connection]:
        dataCategoryTypeMessages.CONNECTION,
      [DataCollectionStaticType.Demographic]:
        dataCategoryTypeMessages.DEMOGRAPHIC,
      [DataCollectionStaticType.Financial]: dataCategoryTypeMessages.FINANCIAL,
      [DataCollectionStaticType.Health]: dataCategoryTypeMessages.HEALTH,
      [DataCollectionStaticType.OnlineActivity]:
        dataCategoryTypeMessages.ONLINE_ACTIVITY,
      [DataCollectionStaticType.SocialMedia]:
        dataCategoryTypeMessages.SOCIAL_MEDIA,
      [DataCollectionStaticType.Survey]: dataCategoryTypeMessages.SURVEY,
      [DataCollectionStaticType.Tracking]: dataCategoryTypeMessages.TRACKING,
      [DataCollectionStaticType.Unassigned]: {
        defaultMessage: 'Unassigned',
      },
    },
  );

/* eslint-enable max-lines, max-len */

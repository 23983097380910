import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const RiskLevel = mkType({
  name: 'RiskLevel',
  comment: 'A representation of overall risk incurred',
  fields: {
    id: {
      comment: 'The id of the risk level',
      type: 'id',
      modelName: 'riskLevel',
    },
    title: {
      comment: 'The display name of the risk level',
      type: 'string',
    },
    color: {
      comment: 'The color associated with risk level',
      type: 'string',
    },
  },
});

/** Override type */
export type RiskLevel = SchemaToType<typeof RiskLevel>;

export const RiskLevelsFiltersInput = mkInput({
  name: 'RiskLevelsFiltersInput',
  comment: 'Inputs for filtering a list of risk levels',
  fields: {
    ids: {
      comment: 'The ids of risk levels',
      type: 'id',
      modelName: 'riskLevel',
      optional: true,
      list: true,
    },
    text: {
      comment: 'The titles or descriptions of risk levels',
      optional: true,
      type: 'string',
    },
    riskFrameworkId: {
      comment: 'The id of the risk framework this risk level belongs to',
      type: 'id',
      modelName: 'riskFramework',
      optional: true,
    },
  },
});

/** Override type */
export type RiskLevelsFiltersInput = SchemaToType<
  typeof RiskLevelsFiltersInput
>;

export const CreateRiskLevelInput = mkInput({
  name: 'CreateRiskLevelInput',
  comment: 'Input for creating a risk level',
  fields: {
    title: RiskLevel.fields.title,
    color: RiskLevel.fields.color,
  },
});

/** Override type */
export type CreateRiskLevelInput = SchemaToType<typeof CreateRiskLevelInput>;

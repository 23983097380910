/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const LinkUnlink: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.23682 3.23663L4.18054 4.18036"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.72654 1.9975V3.33139"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33145 6.72678H1.99756"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7631 12.7633L11.8193 11.8196"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.27293 14.0025V12.6686"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6685 9.27317H14.0023"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00005 11.3347L6.99963 12.3351C6.55747 12.7774 5.95768 13.0259 5.33227 13.0259C4.70686 13.0259 4.10708 12.7774 3.66491 12.3351V12.3351C3.22261 11.893 2.97412 11.2932 2.97412 10.6678C2.97412 10.0424 3.22261 9.44258 3.66491 9.00042L4.66533 8"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 4.66527L9.00042 3.66485C9.44258 3.22255 10.0424 2.97406 10.6678 2.97406C11.2932 2.97406 11.893 3.22255 12.3351 3.66485V3.66485C12.7774 4.10702 13.0259 4.7068 13.0259 5.33221C13.0259 5.95762 12.7774 6.55741 12.3351 6.99957L11.3347 7.99999"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const DemoInput = mkInput({
  name: 'DemoInput',
  comment:
    'Submit a demo request. This is not our real demo form and is used for testing.',
  fields: {
    email: {
      comment: 'The email of the person requesting the demo',
      type: 'string',
    },
    companyName: {
      comment: 'The name of the company',
      type: 'string',
    },
    pathname: {
      comment:
        'The page they were on when requesting a demo (this indicates which banner they saw)',
      type: 'string',
      optional: true,
    },
    dataSubjects: {
      comment: 'The number of european data subjects',
      type: 'int',
      optional: true,
    },
    name: {
      comment: 'The name of the person requesting the demo',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type DemoInput = SchemaToType<typeof DemoInput>;

export const Demo = mkType({
  name: 'Demo',
  comment:
    'Submit a demo request. This is not our real demo form and is used for testing.',
  fields: {
    id: {
      comment: 'The unique id of the demo request',
      modelName: 'demo',
      type: 'id',
    },
    email: DemoInput.fields.email,
    name: DemoInput.fields.name,
    pathname: DemoInput.fields.pathname,
  },
});

/** Override type */
export type Demo = SchemaToType<typeof Demo>;

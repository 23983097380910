import React from 'react';

import { SvgProps } from '../enums';

export const FlagHM: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55014"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55014)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55014"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="9"
        height="7"
      >
        <rect width="9" height="7" fill="white" />
      </mask>
      <g mask="url(#mask1_270_55014)">
        <path
          // eslint-disable-next-line max-len
          d="M-1.00208 6.49991L0.9784 7.36852L9.045 0.944376L10.0897 -0.34639L7.97182 -0.636702L4.68165 2.13164L2.0334 3.99686L-1.00208 6.49991Z"
          fill="#F7FCFF"
        />
        <path
          d="M-0.730957 7.10847L0.278004 7.61253L9.71451 -0.466324H8.29782L-0.730957 7.10847Z"
          fill="#F50100"
        />
        <path
          // eslint-disable-next-line max-len
          d="M10.0022 6.49991L8.02172 7.36852L-0.0448761 0.944376L-1.08953 -0.34639L1.0283 -0.636702L4.31847 2.13164L6.96673 3.99686L10.0022 6.49991Z"
          fill="#F7FCFF"
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.93457 6.93667L8.92561 7.44073L4.90745 3.98176L3.71615 3.59529L-1.1901 -0.341973H0.226597L5.13009 3.50184L6.43256 3.96525L9.93457 6.93667Z"
          fill="#F50100"
        />
        <mask
          id="path-8-outside-1_270_55014"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="-1"
          width="11"
          height="9"
          fill="black"
        >
          <rect fill="white" x="-1" y="-1" width="11" height="9" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
          fill="#F50100"
        />
        <path
          // eslint-disable-next-line max-len
          d="M3.99226 0V-0.75H3.24226V0H3.99226ZM4.99226 0H5.74226V-0.75H4.99226V0ZM3.99226 3V3.75H4.74226V3H3.99226ZM0 3V2.25H-0.75V3H0ZM0 4H-0.75V4.75H0V4ZM3.99226 4H4.74226V3.25H3.99226V4ZM3.99226 7H3.24226V7.75H3.99226V7ZM4.99226 7V7.75H5.74226V7H4.99226ZM4.99226 4V3.25H4.24226V4H4.99226ZM9 4V4.75H9.75V4H9ZM9 3H9.75V2.25H9V3ZM4.99226 3H4.24226V3.75H4.99226V3ZM3.99226 0.75H4.99226V-0.75H3.99226V0.75ZM4.74226 3V0H3.24226V3H4.74226ZM0 3.75H3.99226V2.25H0V3.75ZM0.75 4V3H-0.75V4H0.75ZM3.99226 3.25H0V4.75H3.99226V3.25ZM4.74226 7V4H3.24226V7H4.74226ZM4.99226 6.25H3.99226V7.75H4.99226V6.25ZM4.24226 4V7H5.74226V4H4.24226ZM9 3.25H4.99226V4.75H9V3.25ZM8.25 3V4H9.75V3H8.25ZM4.99226 3.75H9V2.25H4.99226V3.75ZM4.24226 0V3H5.74226V0H4.24226Z"
          fill="#F7FCFF"
          mask="url(#path-8-outside-1_270_55014)"
        />
      </g>
      <g clipPath="url(#clip0_270_55014)">
        <path
          // eslint-disable-next-line max-len
          d="M4.40804 9.83424L3.81761 10.3799L3.87608 9.57807L3.08136 9.45665L3.7447 9.00244L3.34412 8.3054L4.11283 8.54083L4.40804 7.79305L4.70326 8.54083L5.47196 8.3054L5.07138 9.00244L5.73472 9.45665L4.94 9.57807L4.99847 10.3799L4.40804 9.83424Z"
          fill="#F7FCFF"
        />
        <path
          // eslint-disable-next-line max-len
          d="M10.776 6.06899L10.3824 6.43275L10.4213 5.89821L9.89152 5.81726L10.3337 5.51446L10.0667 5.04976L10.5792 5.20671L10.776 4.70819L10.9728 5.20671L11.4853 5.04976L11.2182 5.51446L11.6604 5.81726L11.1306 5.89821L11.1696 6.43275L10.776 6.06899Z"
          fill="#F7FCFF"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.7147 2.37668L11.3211 2.74044L11.3601 2.2059L10.8302 2.12496L11.2725 1.82215L11.0054 1.35745L11.5179 1.5144L11.7147 1.01588L11.9115 1.5144L12.424 1.35745L12.1569 1.82215L12.5991 2.12496L12.0693 2.2059L12.1083 2.74044L11.7147 2.37668Z"
          fill="#F7FCFF"
        />
        <path
          // eslint-disable-next-line max-len
          d="M14.0614 4.22283L13.6678 4.58659L13.7067 4.05204L13.1769 3.9711L13.6191 3.66829L13.3521 3.2036L13.8646 3.36055L14.0614 2.86203L14.2582 3.36055L14.7707 3.2036L14.5036 3.66829L14.9458 3.9711L14.416 4.05204L14.455 4.58659L14.0614 4.22283Z"
          fill="#F7FCFF"
        />
        <path
          // eslint-disable-next-line max-len
          d="M12.1841 9.53052L11.7904 9.89428L11.8294 9.35974L11.2996 9.27879L11.7418 8.97599L11.4748 8.51129L11.9872 8.66824L12.1841 8.16972L12.3809 8.66824L12.8933 8.51129L12.6263 8.97599L13.0685 9.27879L12.5387 9.35974L12.5777 9.89428L12.1841 9.53052Z"
          fill="#F7FCFF"
        />
        <path
          // eslint-disable-next-line max-len
          d="M13.8266 6.64789L13.4267 6.85814L13.5031 6.41282L13.1795 6.09743L13.6267 6.03246L13.8266 5.62729L14.0266 6.03246L14.4737 6.09743L14.1502 6.41282L14.2266 6.85814L13.8266 6.64789Z"
          fill="#F7FCFF"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_270_55014">
        <rect width="12" height="10" fill="white" transform="translate(3 1)" />
      </clipPath>
    </defs>
  </svg>
);

import { ScopeName } from '@transcend-io/privacy-types';

import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';

import { ApiKeyOrderField, ApiKeyStatus } from './enums';
import { Resource } from './resource';
import { ScopePreview } from './scopePreview';
import { UserPreview } from './userPreview';

export const ApiKeyFiltersInput = mkInput({
  name: 'ApiKeyFiltersInput',
  comment: 'The API key filters',
  fields: {
    text: {
      comment: 'Filter by the title',
      type: 'string',
      optional: true,
    },
    ids: {
      comment: 'Filter by a list of API key ids',
      type: 'id',
      modelName: 'apiKey',
      list: true,
      optional: true,
    },
    titles: {
      comment: 'Fetch API keys by title',
      type: 'string',
      list: true,
      optional: true,
    },
    status: {
      comment: 'Filter by the status of the API key (need for refresh)',
      list: true,
      type: { ApiKeyStatus },
      optional: true,
    },
  },
});

/** Override type */
export type ApiKeyFiltersInput = SchemaToType<typeof ApiKeyFiltersInput>;

export const ApiKeyInput = mkInput({
  name: 'ApiKeyInput',
  comment: 'Inputs for creating a new API key',
  fields: {
    title: {
      comment: 'The title used to identify the API key',
      type: 'string',
    },
    scopes: {
      comment: 'The names of the scopes to add',
      list: true,
      type: { ScopeName },
      optional: true,
    },
    dataSilos: {
      comment: 'The ids of the data silos to assign to',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type ApiKeyInput = SchemaToType<typeof ApiKeyInput>;

export const UpdateApiKeyInput = mkInput({
  name: 'UpdateApiKeyInput',
  comment: 'Input for updating an API key',
  fields: {
    id: {
      comment: 'The id of the API key to update',
      modelName: 'apiKey',
      type: 'id',
    },
    title: {
      comment: 'The title of the API key',
      type: 'string',
      optional: true,
    },
    scopes: {
      comment: 'The new scopes that the API key should have',
      list: true,
      type: { ScopeName },
      optional: true,
    },
    dataSilos: {
      comment: 'The ids of the data silos to assign to',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateApiKeyInput = SchemaToType<typeof UpdateApiKeyInput>;

export const ApiKeyPreview = mkType({
  name: 'ApiKeyPreview',
  comment: 'Preview of an api key',
  fields: {
    id: {
      comment: 'The unique id of the api key',
      modelName: 'apiKey',
      type: 'id',
    },
    title: {
      comment: 'The title of the api key',
      type: 'string',
    },
    preview: {
      comment: 'The api key preview',
      type: 'string',
    },
  },
});

/** Override type */
export type ApiKeyPreview = SchemaToType<typeof ApiKeyPreview>;

export const ApiKeyInterface = mkInterface({
  name: 'ApiKeyInterface',
  comment:
    'An API key that can be used to programmatically access a Transcend account',
  fields: {
    ...ApiKeyPreview.fields,
    createdAt: {
      comment: 'The time the api key was created',
      type: 'Date',
    },
    lastUsedAt: {
      comment: 'The time the api key was last used',
      optional: true,
      type: 'Date',
    },
    age: {
      comment: 'The age of the api key in days',
      type: 'int',
    },
    status: {
      comment:
        'The cycle status of the api key (for indicating need to refresh)',
      type: { ApiKeyStatus },
    },
    user: {
      comment: 'The user that created the API key',
      optional: true,
      type: UserPreview,
    },
    scopes: {
      comment: 'The scopes associated with the api key',
      list: true,
      type: ScopePreview,
    },
    dataSilos: {
      comment: 'The data silos connected to the API Key',
      list: true,
      type: Resource,
    },
  },
});

/** Override type */
export type ApiKeyInterface = SchemaToType<typeof ApiKeyInterface>;

export const ApiKey = mkType({
  name: 'ApiKey',
  comment:
    'An API key that can be used to programmatically access a Transcend account',
  interfaces: [ApiKeyInterface],
  fields: {
    ...ApiKeyInterface.fields,
  },
});

/** Override type */
export type ApiKey = SchemaToType<typeof ApiKey>;

export const CreatedApiKey = mkType({
  name: 'CreatedApiKey',
  comment: 'A newly created api key that exposes the key for one time only',
  interfaces: [ApiKeyInterface],
  fields: {
    apiKey: {
      comment: 'The plain text api key',
      type: 'string',
    },
    ...ApiKeyInterface.fields,
  },
});

/** Override type */
export type CreatedApiKey = SchemaToType<typeof CreatedApiKey>;

/**
 * Order for a apiKeys query
 */
export const ApiKeyOrder = mkOrder(ApiKey.name, ApiKeyOrderField);

/** Override type */
export type ApiKeyOrder = SchemaToType<typeof ApiKeyOrder>;

import { lighten } from 'polished';
import styled from 'styled-components';

import { sp } from '@main/theme';

/**
 * The ContentErrorBoundary wrapper
 */
export const ContentErrorBoundaryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10%;
  border: ${({ theme }) => `solid 1px ${lighten(0.3, theme.colors.error)}`};
  border-radius: ${sp(0.5)};
  background: ${({ theme }) => lighten(0.4, theme.colors.error)};
  color: ${({ theme }) => theme.colors.error};
`;

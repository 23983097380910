import { defineMessages } from '@transcend-io/internationalization';

export const selectRegionMessages = defineMessages(
  'core-ui.SelectRegion.selectRegion',
  {
    placeholder: {
      defaultMessage: 'Select regions...',
    },
  },
);

export const regionMessages = defineMessages('core-ui.SelectRegion.region', {
  countryAndSubDivision: {
    defaultMessage:
      '{country}{ hasBoth, select, true{:} false{} other{} }{countrySubDivision}',
  },
});

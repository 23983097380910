import { LanguageKey } from '@transcend-io/internationalization';

/**
 * Native language names, used to render options to users
 * Language options for end-users should be written in own language
 */
export const NATIVE_LANGUAGE_NAMES: Record<LanguageKey, string> = {
  /* English */
  [LanguageKey.En]: 'English',
  /* Arabic */
  [LanguageKey.Ar]: 'العربية',
  /* French */
  [LanguageKey.Fr]: 'Français',
  /* German */
  [LanguageKey.De]: 'Deutsch',
  /* Spanish */
  [LanguageKey.Es]: 'Español',
  /* Italian */
  [LanguageKey.It]: 'Italiano',
  /* Japanese */
  [LanguageKey.Ja]: '日本語',
  /* Russian */
  [LanguageKey.Ru]: 'Русский язык',
  /* Arabic UAE */
  [LanguageKey.ArAe]: 'العربية (الإمارات العربية المتحدة)',
  /* French */
  [LanguageKey.FrFr]: 'Français (France)',
  /* German */
  [LanguageKey.DeDe]: 'Deutsch (Deutschland)',
  /* Italian */
  [LanguageKey.ItIt]: 'Italiano (Italia)',
  /* Afrikaans */
  [LanguageKey.AfZz]: 'Afrikaans',
  /* Bulgarian */
  [LanguageKey.BgBg]: 'български',
  /* Chinese Simplified */
  [LanguageKey.ZhCn]: '汉语',
  /* Croatian */
  [LanguageKey.HrHr]: 'Hrvatski',
  /* Czech */
  [LanguageKey.CsCz]: 'Čeština',
  /* Danish */
  [LanguageKey.DaDk]: 'Dansk',
  /* English (UK) */
  [LanguageKey.EnGb]: 'English (UK)',
  /* English (Canada) */
  [LanguageKey.EnCa]: 'English (Canada)',
  /* English (UAE) */
  [LanguageKey.EnAe]: 'English (UAE)',
  /* Finnish */
  [LanguageKey.FiFi]: 'Suomi',
  /* Greek */
  [LanguageKey.ElGr]: 'ελληνικά',
  /* Hindi */
  [LanguageKey.HiIn]: 'हिन्दी',
  /* Hungarian */
  [LanguageKey.HuHu]: 'Magyar',
  /* Indonesian */
  [LanguageKey.IdId]: 'Bahasa Indonesia',
  /* Japanese */
  [LanguageKey.JaJp]: '日本語',
  /* Korean */
  [LanguageKey.KoKr]: '한국어',
  /* Lithuanian */
  [LanguageKey.LtLt]: 'Lietuvių',
  /* Malay */
  [LanguageKey.MsMy]: 'Bahasa Melayu',
  /* Marathi */
  [LanguageKey.MrIn]: 'मराठी',
  /* Norwegian Bokmål */
  [LanguageKey.NbNi]: 'Bokmål',
  /* Polish */
  [LanguageKey.PlPl]: 'Polski',
  /* Portuguese (Brazil) */
  [LanguageKey.PtBr]: 'Português (Brasil)',
  /* Portuguese (Portugal) */
  [LanguageKey.PtPt]: 'Português (Portugal)',
  /* Romanian */
  [LanguageKey.RoRo]: 'Română',
  /* Russian */
  [LanguageKey.RuRu]: 'Русский язык',
  /* Latn-Serbian (Latin) */
  [LanguageKey.SrLatnRs]: 'Srpski',
  /* Swedish */
  [LanguageKey.SvSe]: 'Svenska',
  /* Tamil */
  [LanguageKey.TaIn]: 'தமிழ்',
  /* Thai */
  [LanguageKey.ThTh]: 'ภาษาไทย',
  /* Turkish */
  [LanguageKey.TrTr]: 'Türkçe',
  /* Ukrainian */
  [LanguageKey.UkUa]: 'українська мова',
  /* Vietnamese */
  [LanguageKey.ViVn]: 'Tiếng Việt',
  /* Zulu */
  [LanguageKey.ZuZa]: 'isiZulu',
  /* USA */
  [LanguageKey.EnUS]: 'English (US)',
  /* Australia */
  [LanguageKey.EnAu]: 'English (Australia)',
  /* Belgium */
  [LanguageKey.FrBe]: 'Français (Belgique)',
  /* Quebec */
  [LanguageKey.FrCa]: 'Français (Québec)',
  /* Ireland */
  [LanguageKey.EnIe]: 'English (Ireland)',
  /* Dutch */
  [LanguageKey.NlNl]: 'Nederlands',
  /* Dutch (Belgium) */
  [LanguageKey.NlBe]: 'Nederlands (België)',
  /* Spanish (Castilian) */
  [LanguageKey.EsEs]: 'Español (Castellano)',
  /* Spanish (Latin America & Caribbean) */
  [LanguageKey.Es419]: 'Español (América Latina)',
  /* Chinese (Traditional) */
  [LanguageKey.ZhHk]: '漢語',
  /** Austrian German */
  [LanguageKey.DeAt]: 'Österreichisches Standarddeutsch,',
  /** Swiss German */
  [LanguageKey.DeCh]: 'Schwiizerdütsch',
  /** Swiss Italian */
  [LanguageKey.ItCh]: 'Dialetto Svizzero',
  /** French (Switzerland) */
  [LanguageKey.FrCh]: 'Français de Suisse',
  /** Hebrew (Israel) */
  [LanguageKey.HeIl]: 'עִבְרִית',
  /* New Zealand */
  [LanguageKey.EnNz]: 'English (New Zealand)',
  /** Estonian (Estonia) */
  [LanguageKey.EtEe]: 'Eesti',
  /** Icelandic (Iceland) */
  [LanguageKey.IsIs]: 'Íslenska',
  /** Latvian (Latvia) */
  [LanguageKey.LvLv]: 'Latviešu',
  /** Maltese (Malta) */
  [LanguageKey.MtMt]: 'Malti',
  /** Slovak (Slovakia) */
  [LanguageKey.SkSk]: 'Slovenčina',
  /** Slovenian (Slovenia) */
  [LanguageKey.SlSl]: 'Slovenščina',
};

/**
 * The default locale
 */
export const DEFAULT_LOCALE = LanguageKey.En;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagDJ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54983"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54983)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#73BE4A"
      />
      <mask
        id="mask1_270_54983"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54983)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 -1V6H16V-1H0Z"
          fill="#6699FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12L9 6L0 0Z"
        fill="white"
      />
      <mask
        id="mask2_270_54983"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="9"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12L9 6L0 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_270_54983)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.5012 6.94713L2.25408 7.70001L2.53854 6.23084L1.5 5.13131L2.90624 5.07186L3.5012 3.70001L4.09615 5.07186H5.5L4.46385 6.23084L4.77579 7.70001L3.5012 6.94713Z"
          fill="#E31D1C"
        />
      </g>
    </g>
  </svg>
);

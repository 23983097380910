import { mkInput, mkType, SchemaToType } from '@main/schema-utils';
import { PluginType } from '@main/sombra-types';

import { DataSiloPreview } from './dataSiloPreview';
import { PluginQueueStatus, ScanObject } from './enums';

export const Plugin = mkType({
  name: 'Plugin',
  comment: 'A plugin belonging to a data silo',
  fields: {
    id: {
      comment: 'The ID of the plugin that found this recommendation',
      type: 'id',
      modelName: 'plugin',
    },
    dataSilo: {
      comment: 'The data silo this plugin belongs to',
      type: DataSiloPreview,
    },
    isRunning: {
      comment: 'Whether the plugin is currently running',
      type: 'boolean',
    },
    enabled: {
      comment: 'If the plugin is enabled.',
      type: 'boolean',
    },
    type: {
      comment: 'Type of plugin to toggle',
      type: { PluginType },
    },
    scheduledAt: {
      comment: 'When the next plugin run is scheduled',
      type: 'Date',
    },
    lastRunAt: {
      comment: 'The date at which this data silo was last run',
      optional: true,
      type: 'Date',
    },
    lastEnabledAt: {
      comment: 'The date at which this data silo was last enabled',
      optional: true,
      type: 'Date',
    },
    scheduleStartAt: {
      comment: 'When to start scheduling the plugin',
      type: 'Date',
    },
    scheduleFrequency: {
      comment:
        'How frequently the plugin should be run. Needs to be a string because ' +
        'the number can be larger than the MAX_INT',
      type: 'string',
    },
    error: {
      comment:
        'The current error message for the most recent run of the plugin',
      type: 'string',
      optional: true,
    },
    indexedThrough: {
      comment:
        'Timestamp for until when a source has been indexed through, if applicable.',
      type: 'Date',
      optional: true,
    },
    ignoreResultsBeforeAt: {
      comment:
        "Timestamp representing when we started picking results that are valid for the plugins' current configuration",
      type: 'Date',
      optional: true,
    },
    status: {
      comment: 'The current status of the plugin run',
      type: { PluginQueueStatus },
    },
    configuration: {
      comment: 'The configuration for the plugin in stringified JSON',
      type: 'string',
    },
    context: {
      comment: 'The context for the plugin in stringified JSON',
      type: 'string',
    },
  },
});

/** Override type */
export type Plugin = SchemaToType<typeof Plugin>;

export const UpdatePluginInput = mkInput({
  name: 'UpdatePluginInput',
  comment: 'Update a data silo plugin',
  fields: {
    dataSiloId: {
      comment: 'The data silo this plugin belongs to',
      type: 'id',
      modelName: 'dataSilo',
    },
    pluginId: {
      comment: 'The plugin to toggle',
      type: 'id',
      modelName: 'plugin',
    },
    enabled: {
      comment: 'State to toggle plugin to',
      type: 'boolean',
      optional: true,
    },
    scheduleFrequency: {
      comment:
        'The updated frequency with which we should schedule this plugin, in milliseconds. ' +
        'Needs to be a string because the number can be larger than the MAX_INT',
      type: 'string',
      optional: true,
    },
    scheduleStartAt: {
      comment:
        'The updated start time when we should start scheduling this plugin, in ISO format.',
      type: 'Date',
      optional: true,
    },
    scheduleNow: {
      comment:
        'Whether we should schedule a run immediately after this request',
      type: 'boolean',
      optional: true,
    },
    configuration: {
      comment: 'Configuration for the plugin',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdatePluginInput = SchemaToType<typeof UpdatePluginInput>;

export const DataSiloPluginRecommendationInput = mkInput({
  name: 'DataSiloPluginRecommendationInput',
  comment: 'DataSiloPluginRecommendation Input',
  fields: {
    pluginId: {
      comment: 'The plugin to toggle',
      type: 'id',
      modelName: 'plugin',
    },
    type: {
      comment: 'The dataSilo type',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloPluginRecommendationInput = SchemaToType<
  typeof DataSiloPluginRecommendationInput
>;

export const SiloDiscoveryRawResultInput = mkInput({
  name: 'SiloDiscoveryRawResultInput',
  comment: 'A remote resource discovered by a data silo discovery run',
  fields: {
    resourceId: {
      comment: 'Unique identifier',
      type: 'string',
    },
    name: {
      comment: 'The resource name',
      type: 'string',
    },
    title: {
      comment: 'The resource title',
      type: 'string',
      optional: true,
    },
    host: {
      comment: 'Host associated with the resource',
      type: 'string',
      optional: true,
    },
    plaintextContext: {
      comment: 'Plaintext associated with the resource',
      type: 'string',
      optional: true,
    },
    type: {
      comment: 'Type of data silo',
      type: 'string',
      optional: true,
    },
    useStrictClassifier: {
      type: 'boolean',
      optional: true,
      comment:
        'Whether or not to only classify these results using "strict" mode.' +
        ' This means the service classifier will only surface exact matches against our' +
        ' integration catalog, so you may have fewer entries classifier, but the quality' +
        ' of the classification may be higher.',
    },
  },
});

/** Override type */
export type SiloDiscoveryRawResultInput = SchemaToType<
  typeof SiloDiscoveryRawResultInput
>;

export const AddSiloDiscoveryResultInput = mkInput({
  name: 'AddSiloDiscoveryResultInput',
  comment: 'Input for bulk create-or-update silo discovery results',
  fields: {
    pluginId: {
      comment: 'The plugin to associate with these results',
      type: 'id',
      modelName: 'plugin',
    },
    rawResults: {
      comment: 'The resources that can be classified as data silos',
      type: SiloDiscoveryRawResultInput,
      list: true,
    },
  },
});

/** Override type */
export type AddSiloDiscoveryResultInput = SchemaToType<
  typeof AddSiloDiscoveryResultInput
>;

export const PluginsFiltersInput = mkInput({
  name: 'PluginsFiltersInput',
  comment: 'Inputs for determining what plugins to filter',
  fields: {
    dataSiloId: {
      comment: 'The data silo this plugin belongs to',
      type: 'string',
      optional: true,
    },
    enabled: {
      ...Plugin.fields.enabled,
      optional: true,
    },
    type: {
      ...Plugin.fields.type,
      optional: true,
    },
    ids: {
      ...Plugin.fields.id,
      optional: true,
      list: true,
    },
    text: {
      comment: 'Search plugins by name',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type PluginsFiltersInput = SchemaToType<typeof PluginsFiltersInput>;

export const SchemaDiscoveryPluginConfigurationSchema = mkType({
  name: 'SchemaDiscoveryPluginConfigurationSchema',
  comment: 'The configuration for schema discovery plugins',
  fields: {
    scanObjectsToFilter: {
      comment:
        'The scan objects (e.g., schema, database, etc) the integration supports filtering during Schema Discovery.',
      type: { ScanObject },
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type SchemaDiscoveryPluginConfigurationSchema = SchemaToType<
  typeof SchemaDiscoveryPluginConfigurationSchema
>;

export const ContentClassificationPluginConfigurationSchema = mkType({
  name: 'ContentClassificationPluginConfigurationSchema',
  comment: 'The configuration for content classification plugins',
  fields: {
    nonNullSampling: {
      comment: 'Whether to support non-null sampling configuration.',
      type: 'boolean',
    },
  },
});

/** Override type */
export type ContentClassificationPluginConfigurationSchema = SchemaToType<
  typeof ContentClassificationPluginConfigurationSchema
>;

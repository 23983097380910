import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { TableId } from './enums';

export const TablePreferencesInterface = mkInterface({
  name: 'TablePreferencesInterface',
  comment:
    'An interface for creating or updating preferences for a given table',
  fields: {
    orderedColumns: {
      comment:
        'The names of columns in the order they should be shown in the table',
      type: 'string',
      list: true,
      optional: true,
    },
    hiddenColumns: {
      comment: 'The names of columns to hide',
      type: 'string',
      list: true,
      optional: true,
    },
    lastSelected: {
      comment:
        'The last time this set of preferences was selected for this table',
      type: 'Date',
      optional: true,
    },
    isPreferred: {
      comment:
        'Whether this preference is preferred for this table. ONLY set on user preferences.',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type TablePreferencesInterface = SchemaToType<
  typeof TablePreferencesInterface
>;

export const TablePreferencesInput = mkInput({
  name: 'TablePreferencesInput',
  interfaces: [TablePreferencesInterface],
  comment: 'Input for creating or updating preferences for a given table',
  fields: {
    ...TablePreferencesInterface.fields,
  },
});

/** Override type */
export type TablePreferencesInput = SchemaToType<typeof TablePreferencesInput>;

export const AssessmentsPreferencesInput = mkInput({
  name: 'AssessmentsPreferencesInput',
  interfaces: [TablePreferencesInterface],
  comment: 'Input for creating or updating preferences for a given table',
  fields: {
    ...TablePreferencesInterface.fields,
    assessmentGroupId: {
      comment: 'The ID of the assessment group whose preferences are being set',
      type: 'id',
      modelName: 'assessmentGroup',
    },
  },
});

/** Override type */
export type AssessmentsPreferencesInput = SchemaToType<
  typeof AssessmentsPreferencesInput
>;

export const TablesPreferencesInput = mkInput({
  name: 'TablesPreferencesInput',
  comment: 'Input for creating or updating preferences for all tables',
  fields: {
    [TableId.DataInventoryDataSilos]: {
      comment: 'Preferences for the data silos table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.DataInventoryDataPoints]: {
      comment: 'Preferences for the subdatapoints table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.DataInventoryVendors]: {
      comment: 'Preferences for the vendors table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.DataInventoryDataCategories]: {
      comment: 'Preferences for the data subcategories table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.DataInventoryPurposes]: {
      comment: 'Preferences for the processing purpose table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.DataInventoryBusinessEntities]: {
      comment: 'Preferences for the business entities table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.ConsentDataFlowsTriage]: {
      comment: 'Preferences for the triage data flows table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.ConsentDataFlowsApproved]: {
      comment: 'Preferences for the approved data flows table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.ConsentDataFlowsJunk]: {
      comment: 'Preferences for the junk data flows table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.ConsentCookiesTriage]: {
      comment: 'Preferences for the triage cookies',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.ConsentCookiesApproved]: {
      comment: 'Preferences for the approved cookies',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.ConsentCookiesJunk]: {
      comment: 'Preferences for the junk cookies',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.PreferenceStore]: {
      comment: 'Preferences for the preference store table',
      type: TablePreferencesInput,
      optional: true,
    },
    [TableId.RiskIntelligenceAssessments]: {
      comment: 'Preferences for the junk cookies',
      type: AssessmentsPreferencesInput,
      list: true,
      optional: true,
    },
    [TableId.DsrAutomationIncomingRequests]: {
      comment: 'Preferences for incoming requests',
      type: TablePreferencesInput,
      optional: true,
    },
  },
});

/** Override type */
export type TablesPreferencesInput = SchemaToType<
  typeof TablesPreferencesInput
>;

export const CreateOrUpdatePreferenceInput = mkInput({
  name: 'CreateOrUpdatePreferenceInput',
  comment: 'Input for creating or updating a preference',
  fields: {
    tables: {
      comment: 'The preferences for all tables',
      type: TablesPreferencesInput,
    },
  },
});

/** Override type */
export type CreateOrUpdatePreferenceInput = SchemaToType<
  typeof CreateOrUpdatePreferenceInput
>;

export const PreferenceRaw = mkType({
  name: 'PreferenceRaw',
  comment: 'A preference for a user or organization',
  fields: {
    id: {
      comment: 'A unique identifier for the preference',
      modelName: 'preference',
      type: 'id',
    },
    organizationId: {
      comment: 'The ID of the organization this preference belongs to',
      modelName: 'organization',
      type: 'id',
    },
    userId: {
      comment: 'The ID of the user this preference belongs to',
      modelName: 'user',
      type: 'id',
      optional: true,
    },
    tables: {
      comment: 'A JSON blob of the preferences for tables',
      type: 'string',
    },
  },
});

/** Override type */
export type PreferenceRaw = SchemaToType<typeof PreferenceRaw>;

/** Type of the full, parsed Preference */
export type Preference = Omit<PreferenceRaw, 'tables'> &
  Pick<CreateOrUpdatePreferenceInput, 'tables'>;

export const PreferenceFiltersInput = mkInput({
  name: 'PreferenceFiltersInput',
  comment: 'Inputs to get preferences for a user or organization',
  fields: {
    id: {
      comment: 'The id of the preference',
      type: 'id',
      modelName: 'preference',
      optional: true,
    },
    userId: {
      comment: 'The id of the user for whom get preferences',
      type: 'id',
      modelName: 'user',
      optional: true,
    },
    organizationId: {
      comment: 'The id of the organization for which get preferences',
      type: 'id',
      modelName: 'organization',
      optional: true,
    },
  },
});

/** Override type */
export type PreferenceFiltersInput = SchemaToType<
  typeof PreferenceFiltersInput
>;

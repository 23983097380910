import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DsrTypeAutomatedDecisionMakingOptOut: React.FC<SvgProps> = ({
  color,
  color2,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.16667"
      y="28.3333"
      width="6.66667"
      height="6.66667"
      rx="2"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="29.1667"
      y="28.3333"
      width="6.66667"
      height="6.66667"
      rx="2"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 20H10.8333C8.99238 20 7.5 21.4924 7.5 23.3333V28.3333"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6667 20H29.1667C31.0076 20 32.5 21.4924 32.5 23.3333V28.3333"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="20"
      cy="31.6666"
      rx="3.33333"
      ry="3.33333"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="20"
      cy="8.33333"
      rx="3.33333"
      ry="3.33333"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 11.6667V14.1667"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 28.3333V25.8333"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5 17.5024L17.5 22.4975"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5024 17.5L22.4975 22.5"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/**
 * The status of ant.design steps
 */
export enum StepStatus {
  /** Waiting */
  Wait = 'wait',
  /** Default -- processing */
  Process = 'process',
  /** Finished */
  Finish = 'finish',
  /** An error occurred at current step */
  Error = 'error',
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagHK: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55013"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55013)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#EA1A1A"
      />
      <mask
        id="mask1_270_55013"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55013)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.93474 5.78739C7.93474 5.78739 5.03989 3.20576 8.49432 1.70157C8.49432 1.70157 9.49893 2.80988 8.74528 4.05411C8.49802 4.46219 8.28948 4.7536 8.13277 4.97259C7.81169 5.42126 7.70813 5.56597 7.93474 5.78739ZM3.66235 3.93376C3.46354 7.69618 7.06701 6.26104 7.06701 6.26104C6.76777 6.36469 6.68462 6.20737 6.42691 5.71977L6.42691 5.71976C6.30107 5.48167 6.13361 5.16482 5.87904 4.76116C5.10307 3.53068 3.66235 3.93376 3.66235 3.93376ZM9.11905 5.91353C9.11905 5.91353 12.835 7.02599 10.343 9.85181C10.343 9.85181 8.96333 9.27322 9.11905 7.827C9.17016 7.35279 9.23558 7.00057 9.28476 6.73584C9.38555 6.19324 9.41807 6.01818 9.11905 5.91353ZM8.14235 6.78086C8.14235 6.78086 8.80852 10.602 5.16682 9.63635C5.16682 9.63635 5.06767 8.14366 6.43091 7.63642C6.87807 7.47001 7.22244 7.37103 7.48123 7.29664L7.48124 7.29664L7.48127 7.29663L7.48127 7.29663C8.01147 7.14423 8.18248 7.09508 8.14235 6.78086ZM12.7393 5.13766C10.4616 2.13657 8.63689 5.55924 8.63689 5.55924C8.79851 5.28683 8.96358 5.35326 9.47512 5.55912C9.72499 5.65967 10.0575 5.79349 10.5127 5.93734C11.8997 6.37569 12.7393 5.13766 12.7393 5.13766Z"
          fill="white"
        />
        <path
          d="M5.08057 5.10403C5.08057 5.10403 5.80847 6.24961 7.00052 6.24961"
          stroke="#EA1A1A"
          strokeWidth="0.5"
        />
        <path
          d="M7.93018 3.52512C7.93018 3.52512 7.27694 4.73024 7.83657 5.78275"
          stroke="#EA1A1A"
          strokeWidth="0.5"
        />
        <path
          d="M11.0549 4.88078C11.0549 4.88078 9.52984 4.64958 8.7322 5.53544"
          stroke="#EA1A1A"
          strokeWidth="0.5"
        />
        <path
          d="M10.1642 7.67247C10.1642 7.67247 9.85983 6.16027 8.75459 5.71373"
          stroke="#EA1A1A"
          strokeWidth="0.5"
        />
        <path
          d="M6.96667 8.49333C6.96667 8.49333 8.28358 7.69013 8.32518 6.49881"
          stroke="#EA1A1A"
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagLB: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55066"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55066)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0H0V3H16V0ZM16 9H0V12H16V9Z"
        fill="#F50101"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3H16V9H0V3Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.7574 6.25661C10.7947 6.69028 9.19432 6.07668 9.19432 6.25032C9.19432 6.42396 10.9088 6.72884 10.9059 7.11755C10.903 7.50626 8.87302 7.00664 9.32825 7.18144C9.78347 7.35623 10.7609 7.77471 10.6498 8.03541C10.4125 8.59218 8.83675 7.64915 8.70569 7.83507C8.49417 8.13493 9.8685 8.83441 9.85689 8.90125C9.83448 9.02942 8.91823 8.69979 8.70569 8.96595C8.53362 8.91742 8.45409 8.68571 8.40065 8.65498L8.06249 4.29329C8.06249 4.29329 8.36612 8.4 7.97816 8.51811C7.59019 8.63622 6.51247 8.99532 6.48111 8.81597C6.46943 8.74913 7.77876 8.13493 7.56725 7.83507C7.41147 7.61408 5.83138 8.39773 5.73056 7.94396C5.28148 7.62023 7.18493 7.3401 7.18493 7.00905C7.18493 6.67795 5.44364 7.40089 5.65195 6.94003C5.86026 6.47916 7.2543 6.31415 7.28031 6.25032C7.32786 6.13359 5.73056 6.43204 5.73056 6.25032C5.73056 5.90647 6.68665 5.83881 7.28031 5.52938C7.31539 5.17627 5.84015 5.87701 5.73056 5.59395C5.62098 5.31089 7.61614 4.57757 7.56725 4.52044C7.49004 4.43022 5.88208 4.83731 6.14726 4.62353C6.66191 4.20863 7.66648 3.64444 7.89762 3.49395C7.89762 3.49395 7.92546 3.43717 7.95522 3.45644C7.98504 3.43717 8.01782 3.41606 8.01782 3.41606C8.01782 3.41606 8.01782 3.77108 8.01288 3.49395C8.00794 3.21682 10.3677 4.42945 10.3945 4.73685C10.4257 5.09597 8.93642 4.46612 8.97144 4.81923C9.27417 5.00679 10.1352 5.36977 10.491 5.52938C10.6523 5.60177 9.30209 5.37615 9.3306 5.48692C9.40328 5.76925 10.7186 5.80431 10.7574 6.25661Z"
        fill="#52AB0B"
      />
    </g>
  </svg>
);

import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { ContentErrorBoundary } from '../ContentErrorBoundary';
import {
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '../GenericFormattedMessage';
import { Icon } from '../Icon';
import { BootstrapModal, BootstrapModalProps } from './BootstrapModal';
import { modalMessages } from './messages';
import { BootstrapModalWrapper, ModalHeader, StyledButton } from './wrappers';

/**
 * Props for Modal.
 */
export interface ModalRawProps
  extends Omit<BootstrapModalProps, 'onExit' | 'onBackdropClicked'> {
  /** Display the close icon? */
  closable?: boolean;
  /** The modal footer. */
  footer?: React.ReactNode;
  /** Extra modal children outside of the body */
  extraChildren?: React.ReactNode;
  /** The header, required for accessibility if aria-labelledby not provided */
  header?: GenericMessageDescriptor;
  /** The width of the modal */
  width?: string | number;
  /** Disable default padding? */
  noPadding?: boolean;
  /** show the modal label */
  hideHeader?: boolean;
}

/**
 * @deprecated Deprecated in favor of ad and pc core component wrappers
 */
export const ModalRaw: React.FC<ModalRawProps> = ({
  children, // the modal contents
  closable = true,
  footer,
  header,
  onHide,
  width = 600,
  noPadding = false,
  hideHeader,
  extraChildren,
  centerHeader,
  ...bsModalProps
}) => {
  const { formatMessage } = useIntl();
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const theme = useTheme();

  const CloseButton = (
    <StyledButton
      aria-label={formatMessage(modalMessages.closeLabel)}
      onClick={onHide}
      variant="link"
      className="modal-close"
    >
      <Icon type="close" size={14} color={theme.colors.transcendNavy2} />
    </StyledButton>
  );

  return (
    <BootstrapModalWrapper
      // this is above bsModalProps so that it can be overridden
      aria-labelledby={header ? 'tr-modal-label' : undefined}
      {...bsModalProps}
      onHide={onHide}
      // $ denotes props for styled components, not passed through.
      $width={width}
      $noPadding={noPadding}
      $footer={footer}
    >
      <ContentErrorBoundary>
        {closable && CloseButton}
        <BootstrapModal.Body>
          {header && (
            <ModalHeader
              centerHeader={centerHeader}
              id="tr-modal-label"
              className={hideHeader ? 'visually-hidden' : undefined}
            >
              {formatMessageGeneric(header)}
            </ModalHeader>
          )}
          {children}
        </BootstrapModal.Body>
        {footer && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
        {extraChildren}
      </ContentErrorBoundary>
    </BootstrapModalWrapper>
  );
};

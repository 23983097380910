import { ScopeName, ScopeType } from '@transcend-io/privacy-types';

import { mkType, SchemaToType } from '@main/schema-utils';

export const ScopePreview = mkType({
  name: 'ScopePreview',
  comment: 'Preview of a scope',
  fields: {
    id: {
      comment: 'The unique id of the scope',
      modelName: 'scope',
      type: 'id',
    },
    color: {
      comment: 'The color associated with the scope',
      type: 'string',
    },
    name: {
      comment: 'The name of the scope',
      type: { ScopeName },
    },
    type: {
      comment: 'The general category the scope falls into',
      type: { ScopeType },
    },
    title: {
      comment: 'The display title of the scope',
      type: 'string',
    },
    description: {
      comment: 'The description of the scope',
      type: 'string',
    },
  },
});

/** Override type */
export type ScopePreview = SchemaToType<typeof ScopePreview>;

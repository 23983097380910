import styled, { css, keyframes } from 'styled-components';

const SKELETON_BACKGROUND_COLOR = '#f2f2f2';
const SKELETON_ANIMATION_BACKGROUND_COLOR = '#e6e6e6';

export const AvatarContainer = styled.div`
  height: 100%;
  padding-right: 16px;
`;

// Define the keyframe animation
const skeletonLoadingKeyframes = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

const skeletonLoadingAnimation = css`
  background: linear-gradient(
    90deg,
    ${SKELETON_BACKGROUND_COLOR} 25%,
    ${SKELETON_ANIMATION_BACKGROUND_COLOR} 37%,
    ${SKELETON_BACKGROUND_COLOR} 63%
  );
  background-size: 400% 100%;
  animation: ${skeletonLoadingKeyframes} 1.4s ease infinite;
`;

export const AnimatedAvatarSkeleton = styled.div<{
  /** animation active */
  active: boolean;
}>`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${SKELETON_BACKGROUND_COLOR};
  ${(props) => props.active && skeletonLoadingAnimation}
`;

export const SkeletonContainer = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
`;

export const SkeletonContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AnimatedSkeletonBar = styled.div<{
  /** animation active */
  active: boolean;
}>`
  height: 16px;
  background-color: ${SKELETON_BACKGROUND_COLOR};
  ${(props) => props.active && skeletonLoadingAnimation}
`;

export const SkeletonParagraph = styled.ul<{
  /** if the paragraph has a title, there needs to be a top margin */
  hasTitle: boolean;
}>`
  list-style: none;
  padding: 0;
  margin-top: ${({ hasTitle }) => (hasTitle ? '24px' : 0)};
`;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Filter: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9.66699 9.00008L13.1383 5.52875C13.2637 5.40341 13.3337 5.23408 13.3337 5.05741V3.33341C13.3337 2.96541 13.035 2.66675 12.667 2.66675H3.33366C2.96566 2.66675 2.66699 2.96541 2.66699 3.33341V5.05741C2.66699 5.23408 2.73699 5.40408 2.86233 5.52875L6.33366 9.00008"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M6.33398 9V13.166C6.33398 13.708 6.84332 14.106 7.36932 13.9747L9.03598 13.558C9.40665 13.4653 9.66732 13.132 9.66732 12.7493V9"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

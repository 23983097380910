import React from 'react';

import { SvgProps } from '../enums';

export const FlagSG: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55106"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55106)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55106"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55106)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V6H16V0H0Z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.4342 5.29481C4.4342 5.29481 3.02136 4.72656 3.02136 3.18703C3.02136 1.64749 4.4342 1.0976 4.4342 1.0976C3.74753 0.923767 1.92548 1.07925 1.92548 3.18703C1.92548 5.29481 3.72 5.69231 4.4342 5.29481ZM4.79619 5.04033L5.2015 4.79565L5.61574 5.04033L5.51436 4.56285L5.85111 4.18618H5.39486L5.2015 3.74033L5.00814 4.18618L4.55111 4.20551L4.88864 4.56285L4.79619 5.04033ZM6.67465 4.76832L6.26934 5.01301L6.36179 4.53553L6.02426 4.17818L6.48129 4.15886L6.67465 3.71301L6.86801 4.15886H7.32426L6.98751 4.53553L7.08889 5.01301L6.67465 4.76832ZM5.64358 2.88496L6.04889 2.64027L6.46313 2.88496L6.36175 2.40747L6.6985 2.03081H6.24225L6.04889 1.58496L5.85553 2.03081L5.3985 2.05013L5.73602 2.40747L5.64358 2.88496ZM4.68489 3.61135L4.27958 3.85604L4.37203 3.37856L4.0345 3.02121L4.49153 3.00189L4.68489 2.55604L4.87825 3.00189H5.3345L4.99776 3.37856L5.09914 3.85604L4.68489 3.61135ZM6.95879 3.83411L7.3641 3.58942L7.77834 3.83411L7.67696 3.35663L8.01371 2.97996H7.55746L7.3641 2.53411L7.17074 2.97996L6.71371 2.99928L7.05124 3.35663L6.95879 3.83411Z"
          fill="#F1F9FF"
        />
      </g>
    </g>
  </svg>
);

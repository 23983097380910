import styled, { css } from 'styled-components';

/**
 * Image props
 */
export interface ImgWrapperProps {
  /** Height of image */
  height?: string;
}

/**
 * The Img wrapper
 */
export const ImgWrapper = styled.div<ImgWrapperProps>`
  display: inline-block;
  ${({ height }) =>
    height
      ? css`
          height: ${height};
        `
      : ''};
  vertical-align: center;
  text-align: center;
`;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagNZ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55154"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55154)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55154"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55154)">
        <mask
          id="mask2_270_55154"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="9"
          height="7"
        >
          <rect width="9" height="7" fill="white" />
        </mask>
        <g mask="url(#mask2_270_55154)">
          <path
            // eslint-disable-next-line max-len
            d="M-1.00214 6.4999L0.978339 7.3685L9.04494 0.944361L10.0896 -0.346405L7.97176 -0.636717L4.68159 2.13163L2.03334 3.99685L-1.00214 6.4999Z"
            fill="#F7FCFF"
          />
          <path
            d="M-0.731018 7.10845L0.277943 7.61252L9.71445 -0.466339H8.29775L-0.731018 7.10845Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M10.0021 6.4999L8.02166 7.3685L-0.0449371 0.944361L-1.08959 -0.346405L1.02824 -0.636717L4.31841 2.13163L6.96666 3.99685L10.0021 6.4999Z"
            fill="#F7FCFF"
          />
          <path
            // eslint-disable-next-line max-len
            d="M9.93457 6.93667L8.92561 7.44073L4.90745 3.98176L3.71615 3.59529L-1.1901 -0.341973H0.226597L5.13009 3.50184L6.43256 3.96525L9.93457 6.93667Z"
            fill="#F50100"
          />
          <mask
            id="path-9-outside-1_270_55154"
            maskUnits="userSpaceOnUse"
            x="-1"
            y="-1"
            width="11"
            height="9"
            fill="black"
          >
            <rect fill="white" x="-1" y="-1" width="11" height="9" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M3.99226 0V-0.75H3.24226V0H3.99226ZM4.99226 0H5.74226V-0.75H4.99226V0ZM3.99226 3V3.75H4.74226V3H3.99226ZM0 3V2.25H-0.75V3H0ZM0 4H-0.75V4.75H0V4ZM3.99226 4H4.74226V3.25H3.99226V4ZM3.99226 7H3.24226V7.75H3.99226V7ZM4.99226 7V7.75H5.74226V7H4.99226ZM4.99226 4V3.25H4.24226V4H4.99226ZM9 4V4.75H9.75V4H9ZM9 3H9.75V2.25H9V3ZM4.99226 3H4.24226V3.75H4.99226V3ZM3.99226 0.75H4.99226V-0.75H3.99226V0.75ZM4.74226 3V0H3.24226V3H4.74226ZM0 3.75H3.99226V2.25H0V3.75ZM0.75 4V3H-0.75V4H0.75ZM3.99226 3.25H0V4.75H3.99226V3.25ZM4.74226 7V4H3.24226V7H4.74226ZM4.99226 6.25H3.99226V7.75H4.99226V6.25ZM4.24226 4V7H5.74226V4H4.24226ZM9 3.25H4.99226V4.75H9V3.25ZM8.25 3V4H9.75V3H8.25ZM4.99226 3.75H9V2.25H4.99226V3.75ZM4.24226 0V3H5.74226V0H4.24226Z"
            fill="#F7FCFF"
            mask="url(#path-9-outside-1_270_55154)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M12.4266 10.25L11.5449 10.7135L11.7133 9.73176L11 9.03647L11.9857 8.89324L12.4266 8L12.8674 8.89324L13.8532 9.03647L13.1399 9.73176L13.3083 10.7135L12.4266 10.25Z"
          fill="#F50100"
          stroke="#F7FCFF"
          strokeWidth="0.35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M10.6 6.93492L9.92016 7.29234L10.05 6.53533L9.5 5.99921L10.2601 5.88876L10.6 5.20001L10.9399 5.88876L11.7 5.99921L11.15 6.53533L11.2798 7.29234L10.6 6.93492Z"
          fill="#F50100"
          stroke="#F7FCFF"
          strokeWidth="0.35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M14.0988 6.933L13.4197 7.29001L13.5494 6.53384L13 5.99832L13.7592 5.888L14.0988 5.20001L14.4383 5.888L15.1976 5.99832L14.6482 6.53384L14.7779 7.29001L14.0988 6.933Z"
          fill="#F50100"
          stroke="#F7FCFF"
          strokeWidth="0.35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M12.6 4.13491L11.9202 4.49232L12.05 3.73531L11.5 3.19919L12.2601 3.08874L12.6 2.39999L12.9399 3.08874L13.7 3.19919L13.15 3.73531L13.2798 4.49232L12.6 4.13491Z"
          fill="#F50100"
          stroke="#F7FCFF"
          strokeWidth="0.35"
        />
      </g>
    </g>
  </svg>
);

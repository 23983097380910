import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { OrganizationPreview } from './organization';

export const LoginDetailsInput = mkInput({
  name: 'LoginDetailsInput',
  comment: 'Input for determining the login method for a particular email.',
  fields: {
    email: {
      comment: 'The email to login with',
      type: 'string',
    },
    userId: {
      comment: 'The id of the user to login as',
      modelName: 'user',
      type: 'id',
      optional: true,
    },
  },
});

/** Override type */
export type LoginDetailsInput = SchemaToType<typeof LoginDetailsInput>;

export const LoginInput = mkInput({
  name: 'LoginInput',
  comment: 'Input for logging in via username/password',
  fields: {
    email: {
      comment: 'The email of the user to login as',
      type: 'string',
    },
    password: {
      comment: 'The password of the user',
      type: 'string',
    },
  },
});

/** Override type */
export type LoginInput = SchemaToType<typeof LoginInput>;

export const LoginMethod = mkType({
  name: 'LoginMethod',
  comment: 'Information about how that email can login',
  fields: {
    email: LoginDetailsInput.fields.email,
    // TODO: https://transcend.height.app/T-19592 - remove this in the future
    // once we have a central authentication service and don't need to check multiple
    // backends to log a user in
    userCanLogin: {
      comment:
        'When true, a user account & resulting organization was found matching the email.',
      type: 'boolean',
    },
    ssoUrl: {
      comment: 'The SSO url to redirect to in order to perform login',
      type: 'string',
      optional: true,
    },
    sombraPublicKey: {
      comment: 'The public key of the sombra gateway to communicate with',
      type: 'string',
    },
    terms: {
      comment: 'The terms of service to be accepted',
      type: 'string',
    },
    organizationName: {
      comment:
        'The name of the organization logging into. Only returned when ssoUrl is returned.',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type LoginMethod = SchemaToType<typeof LoginMethod>;

export const Role = mkType({
  name: 'Role',
  comment: 'A role that a user can take on in another organization',
  fields: {
    id: {
      comment: 'The id of the user role in the other organization',
      modelName: 'user',
      type: 'id',
    },
    organization: {
      comment: 'The organization that the role can assume into',
      type: OrganizationPreview,
    },
  },
});

/** Override type */
export type Role = SchemaToType<typeof Role>;

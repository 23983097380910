import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const FileLoad: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8.2761 8.60939L7.39049 7.72378C7.14044 7.47373 6.8013 7.33325 6.44767 7.33325H3.66663C3.11434 7.33325 2.66663 7.78097 2.66663 8.33325V12.9999C2.66663 13.5522 3.11434 13.9999 3.66663 13.9999H7.66663C8.21891 13.9999 8.66663 13.5522 8.66663 12.9999V9.55221C8.66663 9.19858 8.52615 8.85944 8.2761 8.60939Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 5.66667H11C10.2636 5.66667 9.66663 5.06971 9.66663 4.33333V2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.6666 14H11.3333C12.4379 14 13.3333 13.1046 13.3333 12V5.49509C13.3333 4.96466 13.1226 4.45595 12.7475 4.08088L11.2524 2.58579C10.8773 2.21071 10.3686 2 9.8382 2H4.66663C3.56206 2 2.66663 2.89543 2.66663 4V5.33333"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CheckmarkBubble: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0003 10.4989L11.5018 13.0004L10.0012 11.4998"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12 3.99667C7.02793 3.99667 2.99625 7.35675 2.99625 11.4969C3.0714 13.9952 4.44925 16.2718 6.62776 17.4971C6.41136 18.0851 6.11046 18.6385 5.73453 19.1398C5.52703 19.4428 5.53217 19.8434 5.74735 20.1409C5.96254 20.4385 6.3414 20.5688 6.69409 20.4666C7.89692 20.1164 9.02907 19.5581 10.0392 18.8171C10.6857 18.9383 11.3422 18.9986 12 18.9971C16.9721 18.9971 21.0037 15.637 21.0037 11.4969C21.0037 7.35676 16.9721 3.99667 12 3.99667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ServerDatabasesCheckmark: React.FC<SvgProps> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 11.6666C5.11167 11.6666 2.5 10.7341 2.5 9.58325"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1667 5.41675C14.1667 6.56758 11.555 7.50008 8.33333 7.50008C5.11167 7.50008 2.5 6.56758 2.5 5.41675"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="14.1666"
      cy="14.1666"
      r="3.33333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1667 8.33341V3.75008C14.1667 2.59925 11.555 1.66675 8.33333 1.66675C5.11167 1.66675 2.5 2.59925 2.5 3.75008V13.7501C2.5 14.9009 5.11167 15.8334 8.33333 15.8334"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2875 13.5066L13.8875 14.9058L13.0458 14.0666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';
import {
  OnPremiseSombraCreationMethod,
  Sombra,
  SombraInterface,
  SombraPreview,
} from '@main/sombra-types';

import { SombraOrderField } from './enums';
import { Resource } from './resource';

export const ConfiguredSombra = mkType({
  name: 'ConfiguredSombra',
  comment: 'The sombra configuration with associated models',
  interfaces: [SombraInterface],
  fields: {
    ...SombraInterface.fields,
    dataSilos: {
      comment: 'The data silos associated ot the gateway',
      type: Resource,
      list: true,
    },
  },
});

/** Override type */
export type ConfiguredSombra = SchemaToType<typeof ConfiguredSombra>;

export const UpdateSombraInput = mkInput({
  name: 'UpdateSombraInput',
  comment: 'Input for updating a sombra gateway',
  fields: {
    customerIngress: {
      comment: 'The customer ingress domain',
      type: 'string',
      optional: true,
    },
    title: {
      comment: 'The title of the sombra gateway',
      type: 'string',
      optional: true,
    },
    dataSiloIds: {
      comment: 'The data silos to associate with the sombra gateway',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateSombraInput = SchemaToType<typeof UpdateSombraInput>;

export const OnPremiseSombraReverseTunnelInput = mkInput({
  name: 'OnPremiseSombraReverseTunnelInput',
  comment:
    'Input for creating a new on premise sombra gateway, using the Piko cluster',
  fields: {
    sombraId: {
      comment: 'Sombra ID to use',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type OnPremiseSombraReverseTunnelInput = SchemaToType<
  typeof OnPremiseSombraReverseTunnelInput
>;

export const OnPremiseSombraDirectConnectionInput = mkInput({
  name: 'OnPremiseSombraDirectConnectionInput',
  comment:
    'Input for creating a new on premise sombra gateway, using the legacy method',
  fields: {
    customerIngress: {
      comment: 'The customer ingress domain',
      type: 'string',
    },
    transcendIngress: {
      comment: 'The transcend ingress domain',
      type: 'string',
    },
  },
});

/** Override type */
export type OnPremiseSombraDirectConnectionInput = SchemaToType<
  typeof OnPremiseSombraDirectConnectionInput
>;

export const OnPremiseSombraInput = mkInput({
  name: 'OnPremiseSombraInput',
  comment: 'Input for creating a new on premise sombra gateway',
  fields: {
    method: {
      comment: 'Creation method',
      type: { OnPremiseSombraCreationMethod },
    },
    reverseTunnel: {
      comment: 'Reverse tunnel input',
      type: OnPremiseSombraReverseTunnelInput,
      optional: true,
    },
    directConnection: {
      comment: 'Direct connection input',
      type: OnPremiseSombraDirectConnectionInput,
      optional: true,
    },
  },
});

/** Override type */
export type OnPremiseSombraInput = SchemaToType<typeof OnPremiseSombraInput>;

export const SombraFilterInput = mkInput({
  name: 'SombraFilterInput',
  comment: 'Input for filtering sombras',
  fields: {
    ids: {
      comment: 'Filter by sombra id',
      type: 'id',
      modelName: 'sombra',
      list: true,
      optional: true,
    },
    urls: {
      comment: 'Filter by sombra url',
      type: 'string',
      list: true,
      optional: true,
    },
    text: {
      comment: 'Filter by text on title, url, publicUrl',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SombraFilterInput = SchemaToType<typeof SombraFilterInput>;

/**
 * Order for a sombras query
 */
export const SombraOrder = mkOrder(Sombra.name, SombraOrderField);

/** Override type */
export type SombraOrder = SchemaToType<typeof SombraOrder>;

export const SombraChangelogEntry = mkType({
  name: 'SombraChangelogEntry',
  comment: 'A change in the changelog',
  fields: {
    version: {
      comment: 'The semantic version of the update',
      type: 'string',
    },
    description: {
      comment: 'A description of the changes brought by this version',
      type: 'string',
    },
    sombras: {
      comment: 'The sombra gateways that have this version',
      type: SombraPreview,
      list: true,
    },
  },
});

/** Override type */
export type SombraChangelogEntry = SchemaToType<typeof SombraChangelogEntry>;

export const SombraApiKeyEntry = mkType({
  name: 'SombraApiKeyEntry',
  comment: 'An API key belonging to a Sombra',
  fields: {
    id: {
      type: 'id',
      modelName: 'sombraApiKey',
      comment: 'Model ID',
    },
    sombra: {
      comment: 'The sombra gateway this key belongs to',
      type: SombraPreview,
    },
    hash: {
      type: 'string',
      comment: 'API key hash',
    },
    preview: {
      type: 'string',
      comment: 'API key preview',
    },
    name: {
      type: 'string',
      comment: 'API key name',
    },
    createdAt: {
      type: 'Date',
      comment: 'API key creation date',
      optional: true,
    },
    expiredAt: {
      type: 'Date',
      comment: 'API key expiration, if any',
      optional: true,
    },
  },
});

/** Override type */
export type SombraApiKeyEntry = SchemaToType<typeof SombraApiKeyEntry>;

export const SombraApiKeysFilterInput = mkInput({
  name: 'SombraApiKeysFilterInput',
  comment: 'Input to filter Sombra API keys',
  fields: {
    text: {
      comment: 'Filter by API key name',
      type: 'string',
      optional: true,
    },
    sombraId: {
      comment: 'Filter by Sombra ID',
      type: 'id',
      modelName: 'sombra',
      optional: true,
    },
  },
});

/** Override type */
export type SombraApiKeysFilterInput = SchemaToType<
  typeof SombraApiKeysFilterInput
>;

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Key: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.031 9.34202C15.031 9.13502 14.863 8.96802 14.656 8.96802C14.449 8.96902 14.281 9.13702 14.281 9.34402C14.281 9.55102 14.449 9.71902 14.656 9.71802C14.863 9.71802 15.031 9.55002 15.031 9.34302"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.499 14.785L11.469 15.817H9.637V17.286H8.172V19.122L6.798 20.5H3.5V17.195L9.223 11.459C8.417 9.36499 8.849 6.90299 10.534 5.21399C12.814 2.92899 16.511 2.92899 18.791 5.21399C21.071 7.49899 21.071 11.204 18.791 13.49C17.089 15.194 14.601 15.624 12.499 14.785Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

/**
 * The fields that business entity can be ordered by
 */
export enum BusinessEntityOrderField {
  /** The title of the business entities */
  Title = 'title',
  /** The time the business entity was created */
  CreatedAt = 'createdAt',
  /** The time the business entity was updated */
  UpdatedAt = 'updatedAt',
}

import './styles.css';
import 'antd/lib/menu/style/index';

import AntMenu, { MenuProps as AntMenuProps } from 'antd/lib/menu';
import { MenuItemProps as AntMenuItemProps } from 'antd/lib/menu/MenuItem';
import { SubMenuProps as AntSubMenuProps } from 'antd/lib/menu/SubMenu';
import React from 'react';

/**
 * Menu props
 */
export interface MenuProps extends AntMenuProps {
  /** The id of the menu */
  id?: string;
  /** Menu items */
  children?: React.ReactNode;
}

/**
 * MenuItem props
 */
export interface MenuItemProps extends AntMenuItemProps {
  /** The id of the menu item */
  id?: string;
}

/**
 * Add extra props
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 * @param props - Menu item props
 */
export function MenuItem(props: MenuItemProps): JSX.Element {
  return <AntMenu.Item {...props} />;
}

/**
 * MenuItemGroup props
 */
export interface MenuItemGroupProps
  extends React.ComponentProps<typeof AntMenu.ItemGroup> {
  /** Allow element */
  title: any;
  /** Menu items */
  children?: React.ReactNode;
}

/**
 * Add extra props
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 * @param props - Menu item group props
 */
export function MenuItemGroup(props: MenuItemGroupProps): JSX.Element {
  return <AntMenu.ItemGroup {...props} />;
}

/**
 * SubMenuProps props
 */
export interface SubMenuProps extends AntSubMenuProps {
  /** The id of the sub menu */
  id?: string;
  /** Menu items */
  children: React.ReactNode;
}

/**
 * Add extra props
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 */
export function SubMenu({ ...props }: SubMenuProps): JSX.Element {
  return <AntMenu.SubMenu {...props} />;
}

/**
 * The Menu component
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 */
export function Menu({ ...props }: MenuProps): JSX.Element {
  return <AntMenu {...props} />;
}

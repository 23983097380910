import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CreateFile: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9.33329 14H11.3333C12.4379 14 13.3333 13.1046 13.3333 12V5.67287C13.3333 5.14244 13.1226 4.63374 12.7475 4.25867L11.0746 2.58579C10.6996 2.21072 10.1908 2 9.66042 2H4.66663C3.56206 2 2.66663 2.89543 2.66663 4V6.66667"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2"
      y="8.66666"
      width="5.33333"
      height="5.33333"
      rx="1.92"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.58329 11.25H3.91663"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.74996 10.4167V12.0833"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 5.66667H11C10.2636 5.66667 9.66663 5.06971 9.66663 4.33333V2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

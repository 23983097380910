import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation as useApolloMutation,
} from '@apollo/client';

import { Gql } from '@transcend-io/type-utils';

import { logger } from '@main/core-ui/src/logger';
import {
  EndpointParams,
  GqlObject,
  GraphQLResponse,
  mkQueryString,
  MutationEndpoint,
  ParamsToType,
  ResponseToType,
} from '@main/schema-utils';

/**
 * Create a GraphQL mutation hook from an endpoint definition
 * When called, this hook returns a mutation function that can
 * subsequently be used to initiate a mutation (one could argue
 * this is 'buildUseLazyMutation')
 *
 * This function exists as a convenience to:
 *   1. Construct query strings so they are correct
 *      https://www.apollographql.com/docs/react/data/queries/
 *   2. Add types to query responses
 *      Vanilla Apollo payloads have type `any`
 *
 * @param endpoint - The GraphQL mutation endpoint definition
 * @param operationName - The GraphQL operation name
 * @param responseFields - Return a partial response
 * @returns A hook that calls useApolloMutation and adds type enforcements. The returned hook has these options:
 * https://www.apollographql.com/docs/react/data/mutations/#usemutation-api
 */
export function buildUseMutation<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse,
>(
  endpoint: MutationEndpoint<TName, TParams, TResult>,
  operationName?: string,
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>,
  // TODO: https://github.com/transcend-io/main/issues/6322 - this response type should be partial if responseFields is provided
): (
  options?: MutationHookOptions<ResponseToType<TResult>, ParamsToType<TParams>>,
) => MutationTuple<ResponseToType<TResult>, ParamsToType<TParams>> {
  // any is because MutationEndpoint as typed with current generic usage cannot cast to generic Endpoint
  const gqlTagInput = gql`
    ${mkQueryString(endpoint as any, operationName, responseFields as any)}
  `;

  // Returns the hooks
  return (options) => {
    // Same as useApolloMutation except we index into the endpoint name
    // of the data response
    const [mutate, { data, ...response }] = useApolloMutation(
      gqlTagInput,
      options,
    );

    // Return modified tuple
    return [
      (mutationOptions) =>
        mutate(mutationOptions)
          .then((result: any) => {
            if (result.errors) {
              logger.error(result.errors);
            }
            const { data: dataInner, ...responseInner } = result;
            return {
              ...responseInner,
              data: (dataInner as any)?.[endpoint.name],
            };
          })
          .catch((err) => {
            logger.error(err);
            throw err;
          }),
      // it is quite difficult to have the type signature compatible
      { ...response, data: (data as any)?.[endpoint.name] },
    ];
  };
}

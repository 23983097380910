import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const RiskMatrixRow = mkType({
  name: 'RiskMatrixRow',
  comment: 'A representation of how severe a risk would be if it were to occur',
  fields: {
    id: {
      comment: 'The id of the risk matrix row',
      type: 'id',
      modelName: 'riskMatrixRow',
    },
    title: {
      comment: 'The display name of the risk matrix row',
      type: 'string',
    },
  },
});

/** Override type */
export type RiskMatrixRow = SchemaToType<typeof RiskMatrixRow>;

export const CreateRiskMatrixRowInput = mkInput({
  name: 'CreateRiskMatrixRowInput',
  comment: 'Input for creating a risk matrix row',
  fields: {
    title: RiskMatrixRow.fields.title,
  },
});

/** Override type */
export type CreateRiskMatrixRowInput = SchemaToType<
  typeof CreateRiskMatrixRowInput
>;

/**
 * Classification related codecs. This includes body and response for LLM and Regex classification
 */
import * as t from 'io-ts';

import { ConfidenceLabel } from '@transcend-io/privacy-types';
import { valuesOf } from '@transcend-io/type-utils';

import { dbModelId } from '@main/schema-utils';

import {
  ClassificationMethod,
  PromptedLLMType,
  SelfHostedLLMType,
} from '../enums';
import {
  ClassifyTextRequestWithLookBehind,
  DataSubCategoryForSombra,
  EncryptedClassifierInput,
  EncryptedClassifyTextResult,
} from './codecs';
import {
  GetConfirmedSubDataPointsInput,
  GetSubDataPointSampleInput,
  ResetConfirmedSubDataPointsCache,
  SubDataPointForSombra,
} from './subdatapoints';

export const CategoryGuess = t.partial({
  /**
   * ENUM representing one of the default data categories -- should be UNDEFINED for custom categories
   * LLM and regex classifiers shouldn't output this ENUM
   */
  type: t.union([t.string, t.null]),
  /** ID of a dataSubCategory */
  id: t.string,
  /** the actual name of the category */
  name: t.string,
  /** the parent category */
  category: t.string,
});

/** Type override */
export type CategoryGuess = t.TypeOf<typeof CategoryGuess>;

export const CategoryGuessWithConfidence = t.intersection([
  CategoryGuess,
  t.type({
    /** confidence of sub-category guess */
    confidence: t.number,
    /** version of the classifier model used (e.g. 1, 2, etc.) */
    classifierVersion: t.union([t.number, t.null]),
    /** The confidence label of the guess (High, Medium, Low) */
    confidenceLabel: valuesOf(ConfidenceLabel),
    /** The classification method for this guess */
    classificationMethod: valuesOf(ClassificationMethod),
  }),
  t.partial({
    llmFeatures: t.record(t.string, t.any),
  }),
]);

/** Type override */
export type CategoryGuessWithConfidence = t.TypeOf<
  typeof CategoryGuessWithConfidence
>;

export const CategoryGuessesWithConfidenceArray = t.array(
  CategoryGuessWithConfidence,
);

/** Type override */
export type CategoryGuessesWithConfidenceArray = t.TypeOf<
  typeof CategoryGuessesWithConfidenceArray
>;

/** Array of arrays of guesses for a an array of samples */
export const TypeGuessesWithConfidenceArrayOfArrays = t.array(
  CategoryGuessesWithConfidenceArray,
);

/** type overload */
export type TypeGuessesWithConfidenceArrayOfArrays = t.TypeOf<
  typeof TypeGuessesWithConfidenceArrayOfArrays
>;

export const UnstructuredCategoryGuessWithConfidence = t.partial({
  /** the type of data (i.e. category label) */
  type: t.string,
  /** confidence of sub-category guess */
  confidence: t.number,
  /** version of the classifier model used (e.g. 1, 2, etc.) */
  classifierVersion: t.union([t.number, t.null]),
  /** The value that was classified */
  value: t.string,
  /** The context snippet (associated with the value that was classified) */
  snippet: t.string,
  /** The method that identified this unstructured subDataPoint entity */
  classificationMethod: valuesOf(ClassificationMethod),
  /** The model type for this guess */
  modelType: valuesOf(SelfHostedLLMType),
});

export const UnstructuredCategoryGuessWithConfidenceArray = t.array(
  UnstructuredCategoryGuessWithConfidence,
);

export const UnstructuredCategoryGuessWithConfidenceArrayOfArrays = t.array(
  UnstructuredCategoryGuessWithConfidenceArray,
);

/** type overload */
export type UnstructuredCategoryGuessWithConfidenceArrayOfArrays = t.TypeOf<
  typeof UnstructuredCategoryGuessWithConfidenceArrayOfArrays
>;

const EntityClassification = t.type({
  text: t.string,
  label: t.string,
  score: t.number,
  start: t.number,
  end: t.number,
});

const EntityClassifications = t.array(EntityClassification);

export const EntityClassificationsArray = t.array(EntityClassifications);

/** Overrides type */
export type EntityClassificationsArray = t.TypeOf<
  typeof EntityClassificationsArray
>;

export const DataSubCategoryHeaders = t.type({
  /** Organization Id. */
  'x-sombra-organization-id': dbModelId('organization'),
});

/** Override type. */
export type DataSubCategoryHeaders = t.TypeOf<typeof DataSubCategoryHeaders>;

export const ClassifyTextOrTextArraysWithRegexHeaders = t.intersection([
  DataSubCategoryHeaders,
  t.type({
    /** Data silo Id. */
    'x-data-silo-id': dbModelId('dataSilo'),
  }),
]);

/** Override type. */
export type ClassifyTextOrTextArraysWithRegexHeaders = t.TypeOf<
  typeof ClassifyTextOrTextArraysWithRegexHeaders
>;

export const LLMGuesses = t.type({
  guesses: CategoryGuessesWithConfidenceArray,
});

/** Override type. */
export type LLMGuesses = t.TypeOf<typeof LLMGuesses>;

export const UpdateDataSubCategoriesRegexCache = t.type({
  updateRegexCache: t.boolean,
});

/** Override type. */
export type UpdateDataSubCategoriesRegexCache = t.TypeOf<
  typeof UpdateDataSubCategoriesRegexCache
>;

export const ClassifyFreeTextArrays = t.type({
  /** encrypted and decrypted arrays which contains optional lookBehind parameter. */
  encryptedArray: t.array(ClassifyTextRequestWithLookBehind),
  decryptedArray: t.array(ClassifyTextRequestWithLookBehind),
});

/** Override type. */
export type ClassifyFreeTextArrays = t.TypeOf<typeof ClassifyFreeTextArrays>;

/**
 * Body for ClassifyTextArraysWithRegex
 */
export const ClassifyTextArraysWithRegexBody = t.intersection([
  ClassifyFreeTextArrays,
  UpdateDataSubCategoriesRegexCache,
]);

/** Override type. */
export type ClassifyTextArraysWithRegexBody = t.TypeOf<
  typeof ClassifyTextArraysWithRegexBody
>;

/**
 * Individual result from classifying a text with regex
 */
export const RegexClassificationResult = t.type({
  /** classification result for encrypted input text array */
  encryptedArrayClassifyResult: t.array(EncryptedClassifyTextResult),
  /** classification result for decrypted input text array */
  decryptedArrayClassifyResult: t.array(EncryptedClassifyTextResult),
});

/** Override type. */
export type RegexClassificationResult = t.TypeOf<
  typeof RegexClassificationResult
>;

/**
 * Response set for ClassifyTextArraysWithRegex Endpoint
 */
export const EncryptedClassifyTextResultSet = t.type({
  /** classification result for encrypted input text array */
  encryptedArrayClassifyResult: t.array(t.array(EncryptedClassifyTextResult)),
  /** classification result for decrypted input text array */
  decryptedArrayClassifyResult: t.array(t.array(EncryptedClassifyTextResult)),
});

/** Override type. */
export type EncryptedClassifyTextResultSet = t.TypeOf<
  typeof EncryptedClassifyTextResultSet
>;

/**
 * Body for ClassifyTextWithLLM
 */
export const LLMClassifierInput = t.intersection([
  t.type({
    /** encrypted and decrypted arrays which contains optional lookBehind parameter. */
    classifierInput: t.array(EncryptedClassifierInput),
  }),
  t.partial({ model_type: t.string }),
]);

/** Override type. */
export type LLMClassifierInput = t.TypeOf<typeof LLMClassifierInput>;

/**
 * Input for evaluate classifier endpoint
 */
export const EvaluateClassifierInput = t.intersection([
  ResetConfirmedSubDataPointsCache,
  GetConfirmedSubDataPointsInput,
  GetSubDataPointSampleInput,
  t.type({
    /** Classifier input */
    classifier: valuesOf(ClassificationMethod),
  }),
]);

/** Override type. */
export type EvaluateClassifierInput = t.TypeOf<typeof EvaluateClassifierInput>;

export const EvaluateClassifierSubDataPoint = t.type({
  /** SubDataPoint */
  subDataPoint: SubDataPointForSombra,
  /** Expected category */
  expectedCategory: DataSubCategoryForSombra,
  /** Actual category */
  actualCategories: CategoryGuessesWithConfidenceArray,
});

/** Override type. */
export type EvaluateClassifierSubDataPoint = t.TypeOf<
  typeof EvaluateClassifierSubDataPoint
>;

/**
 * Output for evaluate classifier endpoint
 */
export const EvaluateClassifierOutput = t.array(EvaluateClassifierSubDataPoint);

/** Override type. */
export type EvaluateClassifierOutput = t.TypeOf<
  typeof EvaluateClassifierOutput
>;

/** Sombra LLM Classifier template request body */
export const SombraClassifierTemplateBody = t.type({
  modelType: valuesOf(PromptedLLMType),
});

/** Override type. */
export type SombraClassifierTemplateBody = t.TypeOf<
  typeof SombraClassifierTemplateBody
>;

const LLMTemplatePart = t.type({
  withSamples: t.string,
  withoutSamples: t.string,
});

/** Sombra LLM Classifier template response body */
export const LLMClassifierTemplate = t.type({
  introductionTemplate: t.string,
  inputTemplate: LLMTemplatePart,
  descriptionTemplate: t.type({
    prefix: t.string,
    template: t.string,
  }),
  exampleTemplate: t.type({
    examples: t.array(t.record(t.string, t.string)),
    prefix: t.string,
    redactedSample: t.string,
    suffix: LLMTemplatePart,
    template: LLMTemplatePart,
  }),
});

/** Override type. */
export type LLMClassifierTemplate = t.TypeOf<typeof LLMClassifierTemplate>;

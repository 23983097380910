/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { defineMessages } from '@transcend-io/internationalization';

export const isoCountryCodeMessages = defineMessages(
  'core-ui.SelectRegion.isoCountryCode',
  {
    AF: { defaultMessage: 'Afghanistan' },
    AX: { defaultMessage: 'Åland Islands' },
    AL: { defaultMessage: 'Albania' },
    DZ: { defaultMessage: 'Algeria' },
    AS: { defaultMessage: 'American Samoa' },
    AD: { defaultMessage: 'Andorra' },
    AO: { defaultMessage: 'Angola' },
    AI: { defaultMessage: 'Anguilla' },
    AQ: { defaultMessage: 'Antarctica' },
    AG: { defaultMessage: 'Antigua & Barbuda' },
    AR: { defaultMessage: 'Argentina' },
    AM: { defaultMessage: 'Armenia' },
    AW: { defaultMessage: 'Aruba' },
    AU: { defaultMessage: 'Australia' },
    AT: { defaultMessage: 'Austria' },
    AZ: { defaultMessage: 'Azerbaijan' },
    BS: { defaultMessage: 'Bahamas' },
    BH: { defaultMessage: 'Bahrain' },
    BD: { defaultMessage: 'Bangladesh' },
    BB: { defaultMessage: 'Barbados' },
    BY: { defaultMessage: 'Belarus' },
    BE: { defaultMessage: 'Belgium' },
    BZ: { defaultMessage: 'Belize' },
    BJ: { defaultMessage: 'Benin' },
    BM: { defaultMessage: 'Bermuda' },
    BT: { defaultMessage: 'Bhutan' },
    BO: { defaultMessage: 'Bolivia' },
    BA: { defaultMessage: 'Bosnia & Herzegovina' },
    BW: { defaultMessage: 'Botswana' },
    BV: { defaultMessage: 'Bouvet Island' },
    BR: { defaultMessage: 'Brazil' },
    IO: { defaultMessage: 'British Indian Ocean Territory' },
    VG: { defaultMessage: 'British Virgin Islands' },
    BN: { defaultMessage: 'Brunei' },
    BG: { defaultMessage: 'Bulgaria' },
    BF: { defaultMessage: 'Burkina Faso' },
    BI: { defaultMessage: 'Burundi' },
    KH: { defaultMessage: 'Cambodia' },
    CM: { defaultMessage: 'Cameroon' },
    CA: { defaultMessage: 'Canada' },
    CV: { defaultMessage: 'Cape Verde' },
    BQ: { defaultMessage: 'Caribbean Netherlands' },
    KY: { defaultMessage: 'Cayman Islands' },
    CF: { defaultMessage: 'Central African Republic' },
    TD: { defaultMessage: 'Chad' },
    CL: { defaultMessage: 'Chile' },
    CN: { defaultMessage: 'China' },
    CX: { defaultMessage: 'Christmas Island' },
    CC: { defaultMessage: 'Cocos (Keeling) Islands' },
    CO: { defaultMessage: 'Colombia' },
    KM: { defaultMessage: 'Comoros' },
    CG: { defaultMessage: 'Congo - Brazzaville' },
    CD: { defaultMessage: 'Congo - Kinshasa' },
    CK: { defaultMessage: 'Cook Islands' },
    CR: { defaultMessage: 'Costa Rica' },
    CI: { defaultMessage: 'Côte d’Ivoire' },
    HR: { defaultMessage: 'Croatia' },
    CU: { defaultMessage: 'Cuba' },
    CW: { defaultMessage: 'Curaçao' },
    CY: { defaultMessage: 'Cyprus' },
    CZ: { defaultMessage: 'Czechia' },
    DK: { defaultMessage: 'Denmark' },
    DJ: { defaultMessage: 'Djibouti' },
    DM: { defaultMessage: 'Dominica' },
    DO: { defaultMessage: 'Dominican Republic' },
    EC: { defaultMessage: 'Ecuador' },
    EG: { defaultMessage: 'Egypt' },
    SV: { defaultMessage: 'El Salvador' },
    GQ: { defaultMessage: 'Equatorial Guinea' },
    ER: { defaultMessage: 'Eritrea' },
    EE: { defaultMessage: 'Estonia' },
    SZ: { defaultMessage: 'Eswatini' },
    ET: { defaultMessage: 'Ethiopia' },
    EU: { defaultMessage: 'European Union' },
    FK: { defaultMessage: 'Falkland Islands' },
    FO: { defaultMessage: 'Faroe Islands' },
    FJ: { defaultMessage: 'Fiji' },
    FI: { defaultMessage: 'Finland' },
    FR: { defaultMessage: 'France' },
    GF: { defaultMessage: 'French Guiana' },
    PF: { defaultMessage: 'French Polynesia' },
    TF: { defaultMessage: 'French Southern Territories' },
    GA: { defaultMessage: 'Gabon' },
    GM: { defaultMessage: 'Gambia' },
    GE: { defaultMessage: 'Georgia' },
    DE: { defaultMessage: 'Germany' },
    GH: { defaultMessage: 'Ghana' },
    GI: { defaultMessage: 'Gibraltar' },
    GR: { defaultMessage: 'Greece' },
    GL: { defaultMessage: 'Greenland' },
    GD: { defaultMessage: 'Grenada' },
    GP: { defaultMessage: 'Guadeloupe' },
    GU: { defaultMessage: 'Guam' },
    GT: { defaultMessage: 'Guatemala' },
    GG: { defaultMessage: 'Guernsey' },
    GN: { defaultMessage: 'Guinea' },
    GW: { defaultMessage: 'Guinea-Bissau' },
    GY: { defaultMessage: 'Guyana' },
    HT: { defaultMessage: 'Haiti' },
    HM: { defaultMessage: 'Heard & McDonald Islands' },
    HN: { defaultMessage: 'Honduras' },
    HK: { defaultMessage: 'Hong Kong SAR China' },
    HU: { defaultMessage: 'Hungary' },
    IS: { defaultMessage: 'Iceland' },
    IN: { defaultMessage: 'India' },
    ID: { defaultMessage: 'Indonesia' },
    IR: { defaultMessage: 'Iran' },
    IQ: { defaultMessage: 'Iraq' },
    IE: { defaultMessage: 'Ireland' },
    IM: { defaultMessage: 'Isle of Man' },
    IL: { defaultMessage: 'Israel' },
    IT: { defaultMessage: 'Italy' },
    JM: { defaultMessage: 'Jamaica' },
    JP: { defaultMessage: 'Japan' },
    JE: { defaultMessage: 'Jersey' },
    JO: { defaultMessage: 'Jordan' },
    KZ: { defaultMessage: 'Kazakhstan' },
    KE: { defaultMessage: 'Kenya' },
    KI: { defaultMessage: 'Kiribati' },
    KW: { defaultMessage: 'Kuwait' },
    KG: { defaultMessage: 'Kyrgyzstan' },
    LA: { defaultMessage: 'Laos' },
    LV: { defaultMessage: 'Latvia' },
    LB: { defaultMessage: 'Lebanon' },
    LS: { defaultMessage: 'Lesotho' },
    LR: { defaultMessage: 'Liberia' },
    LY: { defaultMessage: 'Libya' },
    LI: { defaultMessage: 'Liechtenstein' },
    LT: { defaultMessage: 'Lithuania' },
    LU: { defaultMessage: 'Luxembourg' },
    MO: { defaultMessage: 'Macao SAR China' },
    MG: { defaultMessage: 'Madagascar' },
    MW: { defaultMessage: 'Malawi' },
    MY: { defaultMessage: 'Malaysia' },
    MV: { defaultMessage: 'Maldives' },
    ML: { defaultMessage: 'Mali' },
    MT: { defaultMessage: 'Malta' },
    MH: { defaultMessage: 'Marshall Islands' },
    MQ: { defaultMessage: 'Martinique' },
    MR: { defaultMessage: 'Mauritania' },
    MU: { defaultMessage: 'Mauritius' },
    YT: { defaultMessage: 'Mayotte' },
    MX: { defaultMessage: 'Mexico' },
    FM: { defaultMessage: 'Micronesia' },
    MD: { defaultMessage: 'Moldova' },
    MC: { defaultMessage: 'Monaco' },
    MN: { defaultMessage: 'Mongolia' },
    ME: { defaultMessage: 'Montenegro' },
    MS: { defaultMessage: 'Montserrat' },
    MA: { defaultMessage: 'Morocco' },
    MZ: { defaultMessage: 'Mozambique' },
    MM: { defaultMessage: 'Myanmar (Burma)' },
    NA: { defaultMessage: 'Namibia' },
    NR: { defaultMessage: 'Nauru' },
    NP: { defaultMessage: 'Nepal' },
    NL: { defaultMessage: 'Netherlands' },
    NC: { defaultMessage: 'New Caledonia' },
    NZ: { defaultMessage: 'New Zealand' },
    NI: { defaultMessage: 'Nicaragua' },
    NE: { defaultMessage: 'Niger' },
    NG: { defaultMessage: 'Nigeria' },
    NU: { defaultMessage: 'Niue' },
    NF: { defaultMessage: 'Norfolk Island' },
    KP: { defaultMessage: 'North Korea' },
    MK: { defaultMessage: 'North Macedonia' },
    MP: { defaultMessage: 'Northern Mariana Islands' },
    NO: { defaultMessage: 'Norway' },
    OM: { defaultMessage: 'Oman' },
    PK: { defaultMessage: 'Pakistan' },
    PW: { defaultMessage: 'Palau' },
    PS: { defaultMessage: 'Palestinian Territories' },
    PA: { defaultMessage: 'Panama' },
    PG: { defaultMessage: 'Papua New Guinea' },
    PY: { defaultMessage: 'Paraguay' },
    PE: { defaultMessage: 'Peru' },
    PH: { defaultMessage: 'Philippines' },
    PN: { defaultMessage: 'Pitcairn Islands' },
    PL: { defaultMessage: 'Poland' },
    PT: { defaultMessage: 'Portugal' },
    PR: { defaultMessage: 'Puerto Rico' },
    QA: { defaultMessage: 'Qatar' },
    RE: { defaultMessage: 'Réunion' },
    RO: { defaultMessage: 'Romania' },
    RU: { defaultMessage: 'Russia' },
    RW: { defaultMessage: 'Rwanda' },
    WS: { defaultMessage: 'Samoa' },
    SM: { defaultMessage: 'San Marino' },
    ST: { defaultMessage: 'São Tomé & Príncipe' },
    SA: { defaultMessage: 'Saudi Arabia' },
    SN: { defaultMessage: 'Senegal' },
    RS: { defaultMessage: 'Serbia' },
    SC: { defaultMessage: 'Seychelles' },
    SL: { defaultMessage: 'Sierra Leone' },
    SG: { defaultMessage: 'Singapore' },
    SX: { defaultMessage: 'Sint Maarten' },
    SK: { defaultMessage: 'Slovakia' },
    SI: { defaultMessage: 'Slovenia' },
    SB: { defaultMessage: 'Solomon Islands' },
    SO: { defaultMessage: 'Somalia' },
    ZA: { defaultMessage: 'South Africa' },
    GS: { defaultMessage: 'South Georgia & South Sandwich Islands' },
    KR: { defaultMessage: 'South Korea' },
    SS: { defaultMessage: 'South Sudan' },
    ES: { defaultMessage: 'Spain' },
    LK: { defaultMessage: 'Sri Lanka' },
    BL: { defaultMessage: 'St. Barthélemy' },
    SH: { defaultMessage: 'St. Helena' },
    KN: { defaultMessage: 'St. Kitts & Nevis' },
    LC: { defaultMessage: 'St. Lucia' },
    MF: { defaultMessage: 'St. Martin' },
    PM: { defaultMessage: 'St. Pierre & Miquelon' },
    VC: { defaultMessage: 'St. Vincent & Grenadines' },
    SD: { defaultMessage: 'Sudan' },
    SR: { defaultMessage: 'Suriname' },
    SJ: { defaultMessage: 'Svalbard & Jan Mayen' },
    SE: { defaultMessage: 'Sweden' },
    CH: { defaultMessage: 'Switzerland' },
    SY: { defaultMessage: 'Syria' },
    TW: { defaultMessage: 'Taiwan' },
    TJ: { defaultMessage: 'Tajikistan' },
    TZ: { defaultMessage: 'Tanzania' },
    TH: { defaultMessage: 'Thailand' },
    TL: { defaultMessage: 'Timor-Leste' },
    TG: { defaultMessage: 'Togo' },
    TK: { defaultMessage: 'Tokelau' },
    TO: { defaultMessage: 'Tonga' },
    TT: { defaultMessage: 'Trinidad & Tobago' },
    TN: { defaultMessage: 'Tunisia' },
    TR: { defaultMessage: 'Turkey' },
    TM: { defaultMessage: 'Turkmenistan' },
    TC: { defaultMessage: 'Turks & Caicos Islands' },
    TV: { defaultMessage: 'Tuvalu' },
    UM: { defaultMessage: 'U.S. Outlying Islands' },
    VI: { defaultMessage: 'U.S. Virgin Islands' },
    UG: { defaultMessage: 'Uganda' },
    UA: { defaultMessage: 'Ukraine' },
    AE: { defaultMessage: 'United Arab Emirates' },
    GB: { defaultMessage: 'United Kingdom' },
    US: { defaultMessage: 'United States' },
    UY: { defaultMessage: 'Uruguay' },
    UZ: { defaultMessage: 'Uzbekistan' },
    VU: { defaultMessage: 'Vanuatu' },
    VA: { defaultMessage: 'Vatican City' },
    VE: { defaultMessage: 'Venezuela' },
    VN: { defaultMessage: 'Vietnam' },
    WF: { defaultMessage: 'Wallis & Futuna' },
    EH: { defaultMessage: 'Western Sahara' },
    YE: { defaultMessage: 'Yemen' },
    ZM: { defaultMessage: 'Zambia' },
    ZW: { defaultMessage: 'Zimbabwe' },
  },
);

export const iso31662Messages = defineMessages(
  'core-ui.SelectRegion.iso31662',
  {
    'AD-02': { defaultMessage: 'Canillo' },
    'AD-03': { defaultMessage: 'Encamp' },
    'AD-04': { defaultMessage: 'La Massana' },
    'AD-05': { defaultMessage: 'Ordino' },
    'AD-06': { defaultMessage: 'Sant Julià de Lòria' },
    'AD-07': { defaultMessage: 'Andorra la Vella' },
    'AD-08': { defaultMessage: 'Escaldes-Engordany' },
    'AE-AJ': { defaultMessage: '‘Ajmān' },
    'AE-AZ': { defaultMessage: 'Abū Z̧aby' },
    'AE-DU': { defaultMessage: 'Dubayy' },
    'AE-FU': { defaultMessage: 'Al Fujayrah' },
    'AE-RK': { defaultMessage: 'Ra’s al Khaymah' },
    'AE-SH': { defaultMessage: 'Ash Shāriqah' },
    'AE-UQ': { defaultMessage: 'Umm al Qaywayn' },
    'AF-BAL': { defaultMessage: 'Balkh' },
    'AF-BAM': { defaultMessage: 'Bāmyān' },
    'AF-BDG': { defaultMessage: 'Bādghīs' },
    'AF-BDS': { defaultMessage: 'Badakhshān' },
    'AF-BGL': { defaultMessage: 'Baghlān' },
    'AF-DAY': { defaultMessage: 'Dāykundī' },
    'AF-FRA': { defaultMessage: 'Farāh' },
    'AF-FYB': { defaultMessage: 'Fāryāb' },
    'AF-GHA': { defaultMessage: 'Ghaznī' },
    'AF-GHO': { defaultMessage: 'Ghōr' },
    'AF-HEL': { defaultMessage: 'Helmand' },
    'AF-HER': { defaultMessage: 'Herāt' },
    'AF-JOW': { defaultMessage: 'Jowzjān' },
    'AF-KAB': { defaultMessage: 'Kābul' },
    'AF-KAN': { defaultMessage: 'Kandahār' },
    'AF-KAP': { defaultMessage: 'Kāpīsā' },
    'AF-KDZ': { defaultMessage: 'Kunduz' },
    'AF-KHO': { defaultMessage: 'Khōst' },
    'AF-KNR': { defaultMessage: 'Kunaṟ' },
    'AF-LAG': { defaultMessage: 'Laghmān' },
    'AF-LOG': { defaultMessage: 'Lōgar' },
    'AF-NAN': { defaultMessage: 'Nangarhār' },
    'AF-NIM': { defaultMessage: 'Nīmrōz' },
    'AF-NUR': { defaultMessage: 'Nūristān' },
    'AF-PAN': { defaultMessage: 'Panjshayr' },
    'AF-PAR': { defaultMessage: 'Parwān' },
    'AF-PIA': { defaultMessage: 'Paktiyā' },
    'AF-PKA': { defaultMessage: 'Paktīkā' },
    'AF-SAM': { defaultMessage: 'Samangān' },
    'AF-SAR': { defaultMessage: 'Sar-e Pul' },
    'AF-TAK': { defaultMessage: 'Takhār' },
    'AF-URU': { defaultMessage: 'Uruzgān' },
    'AF-WAR': { defaultMessage: 'Wardak' },
    'AF-ZAB': { defaultMessage: 'Zābul' },
    'AG-03': { defaultMessage: 'Saint George' },
    'AG-04': { defaultMessage: 'Saint John' },
    'AG-05': { defaultMessage: 'Saint Mary' },
    'AG-06': { defaultMessage: 'Saint Paul' },
    'AG-07': { defaultMessage: 'Saint Peter' },
    'AG-08': { defaultMessage: 'Saint Philip' },
    'AG-10': { defaultMessage: 'Barbuda' },
    'AG-11': { defaultMessage: 'Redonda' },
    'AL-01': { defaultMessage: 'Berat' },
    'AL-02': { defaultMessage: 'Durrës' },
    'AL-03': { defaultMessage: 'Elbasan' },
    'AL-04': { defaultMessage: 'Fier' },
    'AL-05': { defaultMessage: 'Gjirokastër' },
    'AL-06': { defaultMessage: 'Korçë' },
    'AL-07': { defaultMessage: 'Kukës' },
    'AL-08': { defaultMessage: 'Lezhë' },
    'AL-09': { defaultMessage: 'Dibër' },
    'AL-10': { defaultMessage: 'Shkodër' },
    'AL-11': { defaultMessage: 'Tiranë' },
    'AL-12': { defaultMessage: 'Vlorë' },
    'AM-AG': { defaultMessage: 'Aragac̣otn' },
    'AM-AR': { defaultMessage: 'Ararat' },
    'AM-AV': { defaultMessage: 'Armavir' },
    'AM-ER': { defaultMessage: 'Erevan' },
    'AM-GR': { defaultMessage: "Geġark'unik'" },
    'AM-KT': { defaultMessage: "Kotayk'" },
    'AM-LO': { defaultMessage: 'Loṙi' },
    'AM-SH': { defaultMessage: 'Širak' },
    'AM-SU': { defaultMessage: "Syunik'" },
    'AM-TV': { defaultMessage: 'Tavuš' },
    'AM-VD': { defaultMessage: 'Vayoć Jor' },
    'AO-BGO': { defaultMessage: 'Bengo' },
    'AO-BGU': { defaultMessage: 'Benguela' },
    'AO-BIE': { defaultMessage: 'Bié' },
    'AO-CAB': { defaultMessage: 'Cabinda' },
    'AO-CCU': { defaultMessage: 'Cuando Cubango' },
    'AO-CNN': { defaultMessage: 'Cunene' },
    'AO-CNO': { defaultMessage: 'Cuanza-Norte' },
    'AO-CUS': { defaultMessage: 'Cuanza-Sul' },
    'AO-HUA': { defaultMessage: 'Huambo' },
    'AO-HUI': { defaultMessage: 'Huíla' },
    'AO-LNO': { defaultMessage: 'Lunda-Norte' },
    'AO-LSU': { defaultMessage: 'Lunda-Sul' },
    'AO-LUA': { defaultMessage: 'Luanda' },
    'AO-MAL': { defaultMessage: 'Malange' },
    'AO-MOX': { defaultMessage: 'Moxico' },
    'AO-NAM': { defaultMessage: 'Namibe' },
    'AO-UIG': { defaultMessage: 'Uíge' },
    'AO-ZAI': { defaultMessage: 'Zaire' },
    'AR-A': { defaultMessage: 'Salta' },
    'AR-B': { defaultMessage: 'Buenos Aires' },
    'AR-C': { defaultMessage: 'Ciudad Autónoma de Buenos Aires' },
    'AR-D': { defaultMessage: 'San Luis' },
    'AR-E': { defaultMessage: 'Entre Ríos' },
    'AR-F': { defaultMessage: 'La Rioja' },
    'AR-G': { defaultMessage: 'Santiago del Estero' },
    'AR-H': { defaultMessage: 'Chaco' },
    'AR-J': { defaultMessage: 'San Juan' },
    'AR-K': { defaultMessage: 'Catamarca' },
    'AR-L': { defaultMessage: 'La Pampa' },
    'AR-M': { defaultMessage: 'Mendoza' },
    'AR-N': { defaultMessage: 'Misiones' },
    'AR-P': { defaultMessage: 'Formosa' },
    'AR-Q': { defaultMessage: 'Neuquén' },
    'AR-R': { defaultMessage: 'Río Negro' },
    'AR-S': { defaultMessage: 'Santa Fe' },
    'AR-T': { defaultMessage: 'Tucumán' },
    'AR-U': { defaultMessage: 'Chubut' },
    'AR-V': { defaultMessage: 'Tierra del Fuego' },
    'AR-W': { defaultMessage: 'Corrientes' },
    'AR-X': { defaultMessage: 'Córdoba' },
    'AR-Y': { defaultMessage: 'Jujuy' },
    'AR-Z': { defaultMessage: 'Santa Cruz' },
    'AT-1': { defaultMessage: 'Burgenland' },
    'AT-2': { defaultMessage: 'Kärnten' },
    'AT-3': { defaultMessage: 'Niederösterreich' },
    'AT-4': { defaultMessage: 'Oberösterreich' },
    'AT-5': { defaultMessage: 'Salzburg' },
    'AT-6': { defaultMessage: 'Steiermark' },
    'AT-7': { defaultMessage: 'Tirol' },
    'AT-8': { defaultMessage: 'Vorarlberg' },
    'AT-9': { defaultMessage: 'Wien' },
    'AU-ACT': { defaultMessage: 'Australian Capital Territory' },
    'AU-NSW': { defaultMessage: 'New South Wales' },
    'AU-NT': { defaultMessage: 'Northern Territory' },
    'AU-QLD': { defaultMessage: 'Queensland' },
    'AU-SA': { defaultMessage: 'South Australia' },
    'AU-TAS': { defaultMessage: 'Tasmania' },
    'AU-VIC': { defaultMessage: 'Victoria' },
    'AU-WA': { defaultMessage: 'Western Australia' },
    'AZ-ABS': { defaultMessage: 'Abşeron' },
    'AZ-AGA': { defaultMessage: 'Ağstafa' },
    'AZ-AGC': { defaultMessage: 'Ağcabədi' },
    'AZ-AGM': { defaultMessage: 'Ağdam' },
    'AZ-AGS': { defaultMessage: 'Ağdaş' },
    'AZ-AGU': { defaultMessage: 'Ağsu' },
    'AZ-AST': { defaultMessage: 'Astara' },
    'AZ-BA': { defaultMessage: 'Bakı' },
    'AZ-BAB': { defaultMessage: 'Babək' },
    'AZ-BAL': { defaultMessage: 'Balakən' },
    'AZ-BAR': { defaultMessage: 'Bərdə' },
    'AZ-BEY': { defaultMessage: 'Beyləqan' },
    'AZ-BIL': { defaultMessage: 'Biləsuvar' },
    'AZ-CAB': { defaultMessage: 'Cəbrayıl' },
    'AZ-CAL': { defaultMessage: 'Cəlilabad' },
    'AZ-CUL': { defaultMessage: 'Culfa' },
    'AZ-DAS': { defaultMessage: 'Daşkəsən' },
    'AZ-FUZ': { defaultMessage: 'Füzuli' },
    'AZ-GA': { defaultMessage: 'Gəncə' },
    'AZ-GAD': { defaultMessage: 'Gədəbəy' },
    'AZ-GOR': { defaultMessage: 'Goranboy' },
    'AZ-GOY': { defaultMessage: 'Göyçay' },
    'AZ-GYG': { defaultMessage: 'Göygöl' },
    'AZ-HAC': { defaultMessage: 'Hacıqabul' },
    'AZ-IMI': { defaultMessage: 'İmişli' },
    'AZ-ISM': { defaultMessage: 'İsmayıllı' },
    'AZ-KAL': { defaultMessage: 'Kəlbəcər' },
    'AZ-KAN': { defaultMessage: 'Kǝngǝrli' },
    'AZ-KUR': { defaultMessage: 'Kürdəmir' },
    'AZ-LA': { defaultMessage: 'Lənkəran' },
    'AZ-LAC': { defaultMessage: 'Laçın' },
    'AZ-LAN': { defaultMessage: 'Lənkəran' },
    'AZ-LER': { defaultMessage: 'Lerik' },
    'AZ-MAS': { defaultMessage: 'Masallı' },
    'AZ-MI': { defaultMessage: 'Mingəçevir' },
    'AZ-NA': { defaultMessage: 'Naftalan' },
    'AZ-NEF': { defaultMessage: 'Neftçala' },
    'AZ-NV': { defaultMessage: 'Naxçıvan' },
    'AZ-NX': { defaultMessage: 'Naxçıvan' },
    'AZ-OGU': { defaultMessage: 'Oğuz' },
    'AZ-ORD': { defaultMessage: 'Ordubad' },
    'AZ-QAB': { defaultMessage: 'Qəbələ' },
    'AZ-QAX': { defaultMessage: 'Qax' },
    'AZ-QAZ': { defaultMessage: 'Qazax' },
    'AZ-QBA': { defaultMessage: 'Quba' },
    'AZ-QBI': { defaultMessage: 'Qubadlı' },
    'AZ-QOB': { defaultMessage: 'Qobustan' },
    'AZ-QUS': { defaultMessage: 'Qusar' },
    'AZ-SA': { defaultMessage: 'Şəki' },
    'AZ-SAB': { defaultMessage: 'Sabirabad' },
    'AZ-SAD': { defaultMessage: 'Sədərək' },
    'AZ-SAH': { defaultMessage: 'Şahbuz' },
    'AZ-SAK': { defaultMessage: 'Şəki' },
    'AZ-SAL': { defaultMessage: 'Salyan' },
    'AZ-SAR': { defaultMessage: 'Şərur' },
    'AZ-SAT': { defaultMessage: 'Saatlı' },
    'AZ-SBN': { defaultMessage: 'Şabran' },
    'AZ-SIY': { defaultMessage: 'Siyəzən' },
    'AZ-SKR': { defaultMessage: 'Şəmkir' },
    'AZ-SM': { defaultMessage: 'Sumqayıt' },
    'AZ-SMI': { defaultMessage: 'Şamaxı' },
    'AZ-SMX': { defaultMessage: 'Samux' },
    'AZ-SR': { defaultMessage: 'Şirvan' },
    'AZ-SUS': { defaultMessage: 'Şuşa' },
    'AZ-TAR': { defaultMessage: 'Tərtər' },
    'AZ-TOV': { defaultMessage: 'Tovuz' },
    'AZ-UCA': { defaultMessage: 'Ucar' },
    'AZ-XA': { defaultMessage: 'Xankəndi' },
    'AZ-XAC': { defaultMessage: 'Xaçmaz' },
    'AZ-XCI': { defaultMessage: 'Xocalı' },
    'AZ-XIZ': { defaultMessage: 'Xızı' },
    'AZ-XVD': { defaultMessage: 'Xocavənd' },
    'AZ-YAR': { defaultMessage: 'Yardımlı' },
    'AZ-YE': { defaultMessage: 'Yevlax' },
    'AZ-YEV': { defaultMessage: 'Yevlax' },
    'AZ-ZAN': { defaultMessage: 'Zəngilan' },
    'AZ-ZAQ': { defaultMessage: 'Zaqatala' },
    'AZ-ZAR': { defaultMessage: 'Zərdab' },
    'BA-BIH': { defaultMessage: 'Federacija Bosne i Hercegovine' },
    'BA-BRC': { defaultMessage: 'Brčko distrikt' },
    'BA-SRP': { defaultMessage: 'Republika Srpska' },
    'BB-01': { defaultMessage: 'Christ Church' },
    'BB-02': { defaultMessage: 'Saint Andrew' },
    'BB-03': { defaultMessage: 'Saint George' },
    'BB-04': { defaultMessage: 'Saint James' },
    'BB-05': { defaultMessage: 'Saint John' },
    'BB-06': { defaultMessage: 'Saint Joseph' },
    'BB-07': { defaultMessage: 'Saint Lucy' },
    'BB-08': { defaultMessage: 'Saint Michael' },
    'BB-09': { defaultMessage: 'Saint Peter' },
    'BB-10': { defaultMessage: 'Saint Philip' },
    'BB-11': { defaultMessage: 'Saint Thomas' },
    'BD-01': { defaultMessage: 'Bandarban' },
    'BD-02': { defaultMessage: 'Barguna' },
    'BD-03': { defaultMessage: 'Bogura' },
    'BD-04': { defaultMessage: 'Brahmanbaria' },
    'BD-05': { defaultMessage: 'Bagerhat' },
    'BD-06': { defaultMessage: 'Barishal' },
    'BD-07': { defaultMessage: 'Bhola' },
    'BD-08': { defaultMessage: 'Cumilla' },
    'BD-09': { defaultMessage: 'Chandpur' },
    'BD-10': { defaultMessage: 'Chattogram' },
    'BD-11': { defaultMessage: "Cox's Bazar" },
    'BD-12': { defaultMessage: 'Chuadanga' },
    'BD-13': { defaultMessage: 'Dhaka' },
    'BD-14': { defaultMessage: 'Dinajpur' },
    'BD-15': { defaultMessage: 'Faridpur' },
    'BD-16': { defaultMessage: 'Feni' },
    'BD-17': { defaultMessage: 'Gopalganj' },
    'BD-18': { defaultMessage: 'Gazipur' },
    'BD-19': { defaultMessage: 'Gaibandha' },
    'BD-20': { defaultMessage: 'Habiganj' },
    'BD-21': { defaultMessage: 'Jamalpur' },
    'BD-22': { defaultMessage: 'Jashore' },
    'BD-23': { defaultMessage: 'Jhenaidah' },
    'BD-24': { defaultMessage: 'Joypurhat' },
    'BD-25': { defaultMessage: 'Jhalakathi' },
    'BD-26': { defaultMessage: 'Kishoreganj' },
    'BD-27': { defaultMessage: 'Khulna' },
    'BD-28': { defaultMessage: 'Kurigram' },
    'BD-29': { defaultMessage: 'Khagrachhari' },
    'BD-30': { defaultMessage: 'Kushtia' },
    'BD-31': { defaultMessage: 'Lakshmipur' },
    'BD-32': { defaultMessage: 'Lalmonirhat' },
    'BD-33': { defaultMessage: 'Manikganj' },
    'BD-34': { defaultMessage: 'Mymensingh' },
    'BD-35': { defaultMessage: 'Munshiganj' },
    'BD-36': { defaultMessage: 'Madaripur' },
    'BD-37': { defaultMessage: 'Magura' },
    'BD-38': { defaultMessage: 'Moulvibazar' },
    'BD-39': { defaultMessage: 'Meherpur' },
    'BD-40': { defaultMessage: 'Narayanganj' },
    'BD-41': { defaultMessage: 'Netrakona' },
    'BD-42': { defaultMessage: 'Narsingdi' },
    'BD-43': { defaultMessage: 'Narail' },
    'BD-44': { defaultMessage: 'Natore' },
    'BD-45': { defaultMessage: 'Chapai Nawabganj' },
    'BD-46': { defaultMessage: 'Nilphamari' },
    'BD-47': { defaultMessage: 'Noakhali' },
    'BD-48': { defaultMessage: 'Naogaon' },
    'BD-49': { defaultMessage: 'Pabna' },
    'BD-50': { defaultMessage: 'Pirojpur' },
    'BD-51': { defaultMessage: 'Patuakhali' },
    'BD-52': { defaultMessage: 'Panchagarh' },
    'BD-53': { defaultMessage: 'Rajbari' },
    'BD-54': { defaultMessage: 'Rajshahi' },
    'BD-55': { defaultMessage: 'Rangpur' },
    'BD-56': { defaultMessage: 'Rangamati' },
    'BD-57': { defaultMessage: 'Sherpur' },
    'BD-58': { defaultMessage: 'Satkhira' },
    'BD-59': { defaultMessage: 'Sirajganj' },
    'BD-60': { defaultMessage: 'Sylhet' },
    'BD-61': { defaultMessage: 'Sunamganj' },
    'BD-62': { defaultMessage: 'Shariatpur' },
    'BD-63': { defaultMessage: 'Tangail' },
    'BD-64': { defaultMessage: 'Thakurgaon' },
    'BD-A': { defaultMessage: 'Barishal' },
    'BD-B': { defaultMessage: 'Chattogram' },
    'BD-C': { defaultMessage: 'Dhaka' },
    'BD-D': { defaultMessage: 'Khulna' },
    'BD-E': { defaultMessage: 'Rajshahi' },
    'BD-F': { defaultMessage: 'Rangpur' },
    'BD-G': { defaultMessage: 'Sylhet' },
    'BD-H': { defaultMessage: 'Mymensingh' },
    'BE-BRU': { defaultMessage: 'Brussels Hoofdstedelijk Gewest' },
    'BE-VAN': { defaultMessage: 'Antwerpen' },
    'BE-VBR': { defaultMessage: 'Vlaams-Brabant' },
    'BE-VLG': { defaultMessage: 'Vlaams Gewest' },
    'BE-VLI': { defaultMessage: 'Limburg' },
    'BE-VOV': { defaultMessage: 'Oost-Vlaanderen' },
    'BE-VWV': { defaultMessage: 'West-Vlaanderen' },
    'BE-WAL': { defaultMessage: 'Waals Gewest' },
    'BE-WBR': { defaultMessage: 'Brabant wallon' },
    'BE-WHT': { defaultMessage: 'Hainaut' },
    'BE-WLG': { defaultMessage: 'Liège' },
    'BE-WLX': { defaultMessage: 'Luxembourg' },
    'BE-WNA': { defaultMessage: 'Namur' },
    'BF-01': { defaultMessage: 'Boucle du Mouhoun' },
    'BF-02': { defaultMessage: 'Cascades' },
    'BF-03': { defaultMessage: 'Centre' },
    'BF-04': { defaultMessage: 'Centre-Est' },
    'BF-05': { defaultMessage: 'Centre-Nord' },
    'BF-06': { defaultMessage: 'Centre-Ouest' },
    'BF-07': { defaultMessage: 'Centre-Sud' },
    'BF-08': { defaultMessage: 'Est' },
    'BF-09': { defaultMessage: 'Hauts-Bassins' },
    'BF-10': { defaultMessage: 'Nord' },
    'BF-11': { defaultMessage: 'Plateau-Central' },
    'BF-12': { defaultMessage: 'Sahel' },
    'BF-13': { defaultMessage: 'Sud-Ouest' },
    'BF-BAL': { defaultMessage: 'Balé' },
    'BF-BAM': { defaultMessage: 'Bam' },
    'BF-BAN': { defaultMessage: 'Banwa' },
    'BF-BAZ': { defaultMessage: 'Bazèga' },
    'BF-BGR': { defaultMessage: 'Bougouriba' },
    'BF-BLG': { defaultMessage: 'Boulgou' },
    'BF-BLK': { defaultMessage: 'Boulkiemdé' },
    'BF-COM': { defaultMessage: 'Comoé' },
    'BF-GAN': { defaultMessage: 'Ganzourgou' },
    'BF-GNA': { defaultMessage: 'Gnagna' },
    'BF-GOU': { defaultMessage: 'Gourma' },
    'BF-HOU': { defaultMessage: 'Houet' },
    'BF-IOB': { defaultMessage: 'Ioba' },
    'BF-KAD': { defaultMessage: 'Kadiogo' },
    'BF-KEN': { defaultMessage: 'Kénédougou' },
    'BF-KMD': { defaultMessage: 'Komondjari' },
    'BF-KMP': { defaultMessage: 'Kompienga' },
    'BF-KOP': { defaultMessage: 'Koulpélogo' },
    'BF-KOS': { defaultMessage: 'Kossi' },
    'BF-KOT': { defaultMessage: 'Kouritenga' },
    'BF-KOW': { defaultMessage: 'Kourwéogo' },
    'BF-LER': { defaultMessage: 'Léraba' },
    'BF-LOR': { defaultMessage: 'Loroum' },
    'BF-MOU': { defaultMessage: 'Mouhoun' },
    'BF-NAM': { defaultMessage: 'Namentenga' },
    'BF-NAO': { defaultMessage: 'Nahouri' },
    'BF-NAY': { defaultMessage: 'Nayala' },
    'BF-NOU': { defaultMessage: 'Noumbiel' },
    'BF-OUB': { defaultMessage: 'Oubritenga' },
    'BF-OUD': { defaultMessage: 'Oudalan' },
    'BF-PAS': { defaultMessage: 'Passoré' },
    'BF-PON': { defaultMessage: 'Poni' },
    'BF-SEN': { defaultMessage: 'Séno' },
    'BF-SIS': { defaultMessage: 'Sissili' },
    'BF-SMT': { defaultMessage: 'Sanmatenga' },
    'BF-SNG': { defaultMessage: 'Sanguié' },
    'BF-SOM': { defaultMessage: 'Soum' },
    'BF-SOR': { defaultMessage: 'Sourou' },
    'BF-TAP': { defaultMessage: 'Tapoa' },
    'BF-TUI': { defaultMessage: 'Tuy' },
    'BF-YAG': { defaultMessage: 'Yagha' },
    'BF-YAT': { defaultMessage: 'Yatenga' },
    'BF-ZIR': { defaultMessage: 'Ziro' },
    'BF-ZON': { defaultMessage: 'Zondoma' },
    'BF-ZOU': { defaultMessage: 'Zoundwéogo' },
    'BG-01': { defaultMessage: 'Blagoevgrad' },
    'BG-02': { defaultMessage: 'Burgas' },
    'BG-03': { defaultMessage: 'Varna' },
    'BG-04': { defaultMessage: 'Veliko Tarnovo' },
    'BG-05': { defaultMessage: 'Vidin' },
    'BG-06': { defaultMessage: 'Vratsa' },
    'BG-07': { defaultMessage: 'Gabrovo' },
    'BG-08': { defaultMessage: 'Dobrich' },
    'BG-09': { defaultMessage: 'Kardzhali' },
    'BG-10': { defaultMessage: 'Kyustendil' },
    'BG-11': { defaultMessage: 'Lovech' },
    'BG-12': { defaultMessage: 'Montana' },
    'BG-13': { defaultMessage: 'Pazardzhik' },
    'BG-14': { defaultMessage: 'Pernik' },
    'BG-15': { defaultMessage: 'Pleven' },
    'BG-16': { defaultMessage: 'Plovdiv' },
    'BG-17': { defaultMessage: 'Razgrad' },
    'BG-18': { defaultMessage: 'Ruse' },
    'BG-19': { defaultMessage: 'Silistra' },
    'BG-20': { defaultMessage: 'Sliven' },
    'BG-21': { defaultMessage: 'Smolyan' },
    'BG-22': { defaultMessage: 'Sofia (stolitsa)' },
    'BG-23': { defaultMessage: 'Sofia' },
    'BG-24': { defaultMessage: 'Stara Zagora' },
    'BG-25': { defaultMessage: 'Targovishte' },
    'BG-26': { defaultMessage: 'Haskovo' },
    'BG-27': { defaultMessage: 'Shumen' },
    'BG-28': { defaultMessage: 'Yambol' },
    'BH-13': { defaultMessage: 'Al ‘Āşimah' },
    'BH-14': { defaultMessage: 'Al Janūbīyah' },
    'BH-15': { defaultMessage: 'Al Muḩarraq' },
    'BH-17': { defaultMessage: 'Ash Shamālīyah' },
    'BI-BB': { defaultMessage: 'Bubanza' },
    'BI-BL': { defaultMessage: 'Bujumbura Rural' },
    'BI-BM': { defaultMessage: 'Bujumbura Mairie' },
    'BI-BR': { defaultMessage: 'Bururi' },
    'BI-CA': { defaultMessage: 'Cankuzo' },
    'BI-CI': { defaultMessage: 'Cibitoke' },
    'BI-GI': { defaultMessage: 'Gitega' },
    'BI-KI': { defaultMessage: 'Kirundo' },
    'BI-KR': { defaultMessage: 'Karuzi' },
    'BI-KY': { defaultMessage: 'Kayanza' },
    'BI-MA': { defaultMessage: 'Makamba' },
    'BI-MU': { defaultMessage: 'Muramvya' },
    'BI-MW': { defaultMessage: 'Mwaro' },
    'BI-MY': { defaultMessage: 'Muyinga' },
    'BI-NG': { defaultMessage: 'Ngozi' },
    'BI-RM': { defaultMessage: 'Rumonge' },
    'BI-RT': { defaultMessage: 'Rutana' },
    'BI-RY': { defaultMessage: 'Ruyigi' },
    'BJ-AK': { defaultMessage: 'Atacora' },
    'BJ-AL': { defaultMessage: 'Alibori' },
    'BJ-AQ': { defaultMessage: 'Atlantique' },
    'BJ-BO': { defaultMessage: 'Borgou' },
    'BJ-CO': { defaultMessage: 'Collines' },
    'BJ-DO': { defaultMessage: 'Donga' },
    'BJ-KO': { defaultMessage: 'Couffo' },
    'BJ-LI': { defaultMessage: 'Littoral' },
    'BJ-MO': { defaultMessage: 'Mono' },
    'BJ-OU': { defaultMessage: 'Ouémé' },
    'BJ-PL': { defaultMessage: 'Plateau' },
    'BJ-ZO': { defaultMessage: 'Zou' },
    'BN-BE': { defaultMessage: 'Belait' },
    'BN-BM': { defaultMessage: 'Brunei-Muara' },
    'BN-TE': { defaultMessage: 'Temburong' },
    'BN-TU': { defaultMessage: 'Tutong' },
    'BO-B': { defaultMessage: 'El Beni' },
    'BO-C': { defaultMessage: 'Cochabamba' },
    'BO-H': { defaultMessage: 'Chuquisaca' },
    'BO-L': { defaultMessage: 'La Paz' },
    'BO-N': { defaultMessage: 'Pando' },
    'BO-O': { defaultMessage: 'Oruro' },
    'BO-P': { defaultMessage: 'Potosí' },
    'BO-S': { defaultMessage: 'Santa Cruz' },
    'BO-T': { defaultMessage: 'Tarija' },
    'BQ-BO': { defaultMessage: 'Bonaire' },
    'BQ-SA': { defaultMessage: 'Saba' },
    'BQ-SE': { defaultMessage: 'Sint Eustatius' },
    'BR-AC': { defaultMessage: 'Acre' },
    'BR-AL': { defaultMessage: 'Alagoas' },
    'BR-AM': { defaultMessage: 'Amazonas' },
    'BR-AP': { defaultMessage: 'Amapá' },
    'BR-BA': { defaultMessage: 'Bahia' },
    'BR-CE': { defaultMessage: 'Ceará' },
    'BR-DF': { defaultMessage: 'Distrito Federal' },
    'BR-ES': { defaultMessage: 'Espírito Santo' },
    'BR-GO': { defaultMessage: 'Goiás' },
    'BR-MA': { defaultMessage: 'Maranhão' },
    'BR-MG': { defaultMessage: 'Minas Gerais' },
    'BR-MS': { defaultMessage: 'Mato Grosso do Sul' },
    'BR-MT': { defaultMessage: 'Mato Grosso' },
    'BR-PA': { defaultMessage: 'Pará' },
    'BR-PB': { defaultMessage: 'Paraíba' },
    'BR-PE': { defaultMessage: 'Pernambuco' },
    'BR-PI': { defaultMessage: 'Piauí' },
    'BR-PR': { defaultMessage: 'Paraná' },
    'BR-RJ': { defaultMessage: 'Rio de Janeiro' },
    'BR-RN': { defaultMessage: 'Rio Grande do Norte' },
    'BR-RO': { defaultMessage: 'Rondônia' },
    'BR-RR': { defaultMessage: 'Roraima' },
    'BR-RS': { defaultMessage: 'Rio Grande do Sul' },
    'BR-SC': { defaultMessage: 'Santa Catarina' },
    'BR-SE': { defaultMessage: 'Sergipe' },
    'BR-SP': { defaultMessage: 'São Paulo' },
    'BR-TO': { defaultMessage: 'Tocantins' },
    'BS-AK': { defaultMessage: 'Acklins' },
    'BS-BI': { defaultMessage: 'Bimini' },
    'BS-BP': { defaultMessage: 'Black Point' },
    'BS-BY': { defaultMessage: 'Berry Islands' },
    'BS-CE': { defaultMessage: 'Central Eleuthera' },
    'BS-CI': { defaultMessage: 'Cat Island' },
    'BS-CK': { defaultMessage: 'Crooked Island and Long Cay' },
    'BS-CO': { defaultMessage: 'Central Abaco' },
    'BS-CS': { defaultMessage: 'Central Andros' },
    'BS-EG': { defaultMessage: 'East Grand Bahama' },
    'BS-EX': { defaultMessage: 'Exuma' },
    'BS-FP': { defaultMessage: 'City of Freeport' },
    'BS-GC': { defaultMessage: 'Grand Cay' },
    'BS-HI': { defaultMessage: 'Harbour Island' },
    'BS-HT': { defaultMessage: 'Hope Town' },
    'BS-IN': { defaultMessage: 'Inagua' },
    'BS-LI': { defaultMessage: 'Long Island' },
    'BS-MC': { defaultMessage: 'Mangrove Cay' },
    'BS-MG': { defaultMessage: 'Mayaguana' },
    'BS-MI': { defaultMessage: "Moore's Island" },
    'BS-NE': { defaultMessage: 'North Eleuthera' },
    'BS-NO': { defaultMessage: 'North Abaco' },
    'BS-NP': { defaultMessage: 'New Providence' },
    'BS-NS': { defaultMessage: 'North Andros' },
    'BS-RC': { defaultMessage: 'Rum Cay' },
    'BS-RI': { defaultMessage: 'Ragged Island' },
    'BS-SA': { defaultMessage: 'South Andros' },
    'BS-SE': { defaultMessage: 'South Eleuthera' },
    'BS-SO': { defaultMessage: 'South Abaco' },
    'BS-SS': { defaultMessage: 'San Salvador' },
    'BS-SW': { defaultMessage: 'Spanish Wells' },
    'BS-WG': { defaultMessage: 'West Grand Bahama' },
    'BT-11': { defaultMessage: 'Paro' },
    'BT-12': { defaultMessage: 'Chhukha' },
    'BT-13': { defaultMessage: 'Haa' },
    'BT-14': { defaultMessage: 'Samtse' },
    'BT-15': { defaultMessage: 'Thimphu' },
    'BT-21': { defaultMessage: 'Tsirang' },
    'BT-22': { defaultMessage: 'Dagana' },
    'BT-23': { defaultMessage: 'Punakha' },
    'BT-24': { defaultMessage: 'Wangdue Phodrang' },
    'BT-31': { defaultMessage: 'Sarpang' },
    'BT-32': { defaultMessage: 'Trongsa' },
    'BT-33': { defaultMessage: 'Bumthang' },
    'BT-34': { defaultMessage: 'Zhemgang' },
    'BT-41': { defaultMessage: 'Trashigang' },
    'BT-42': { defaultMessage: 'Monggar' },
    'BT-43': { defaultMessage: 'Pema Gatshel' },
    'BT-44': { defaultMessage: 'Lhuentse' },
    'BT-45': { defaultMessage: 'Samdrup Jongkhar' },
    'BT-GA': { defaultMessage: 'Gasa' },
    'BT-TY': { defaultMessage: 'Trashi Yangtse' },
    'BW-CE': { defaultMessage: 'Central' },
    'BW-CH': { defaultMessage: 'Chobe' },
    'BW-FR': { defaultMessage: 'Francistown' },
    'BW-GA': { defaultMessage: 'Gaborone' },
    'BW-GH': { defaultMessage: 'Ghanzi' },
    'BW-JW': { defaultMessage: 'Jwaneng' },
    'BW-KG': { defaultMessage: 'Kgalagadi' },
    'BW-KL': { defaultMessage: 'Kgatleng' },
    'BW-KW': { defaultMessage: 'Kweneng' },
    'BW-LO': { defaultMessage: 'Lobatse' },
    'BW-NE': { defaultMessage: 'North East' },
    'BW-NW': { defaultMessage: 'North West' },
    'BW-SE': { defaultMessage: 'South East' },
    'BW-SO': { defaultMessage: 'Southern' },
    'BW-SP': { defaultMessage: 'Selibe Phikwe' },
    'BW-ST': { defaultMessage: 'Sowa Town' },
    'BY-BR': { defaultMessage: "Brestskaya voblasts'" },
    'BY-HM': { defaultMessage: 'Horad Minsk' },
    'BY-HO': { defaultMessage: "Homyel'skaya voblasts'" },
    'BY-HR': { defaultMessage: "Hrodzyenskaya voblasts'" },
    'BY-MA': { defaultMessage: "Mahilyowskaya voblasts'" },
    'BY-MI': { defaultMessage: "Minskaya voblasts'" },
    'BY-VI': { defaultMessage: "Vitsyebskaya voblasts'" },
    'BZ-BZ': { defaultMessage: 'Belize' },
    'BZ-CY': { defaultMessage: 'Cayo' },
    'BZ-CZL': { defaultMessage: 'Corozal' },
    'BZ-OW': { defaultMessage: 'Orange Walk' },
    'BZ-SC': { defaultMessage: 'Stann Creek' },
    'BZ-TOL': { defaultMessage: 'Toledo' },
    'CA-AB': { defaultMessage: 'Alberta' },
    'CA-BC': { defaultMessage: 'British Columbia' },
    'CA-MB': { defaultMessage: 'Manitoba' },
    'CA-NB': { defaultMessage: 'New Brunswick' },
    'CA-NL': { defaultMessage: 'Newfoundland and Labrador' },
    'CA-NS': { defaultMessage: 'Nova Scotia' },
    'CA-NT': { defaultMessage: 'Northwest Territories' },
    'CA-NU': { defaultMessage: 'Nunavut' },
    'CA-ON': { defaultMessage: 'Ontario' },
    'CA-PE': { defaultMessage: 'Prince Edward Island' },
    'CA-QC': { defaultMessage: 'Quebec' },
    'CA-SK': { defaultMessage: 'Saskatchewan' },
    'CA-YT': { defaultMessage: 'Yukon' },
    'CD-BC': { defaultMessage: 'Kongo Central' },
    'CD-BU': { defaultMessage: 'Bas-Uélé' },
    'CD-EQ': { defaultMessage: 'Équateur' },
    'CD-HK': { defaultMessage: 'Haut-Katanga' },
    'CD-HL': { defaultMessage: 'Haut-Lomami' },
    'CD-HU': { defaultMessage: 'Haut-Uélé' },
    'CD-IT': { defaultMessage: 'Ituri' },
    'CD-KC': { defaultMessage: 'Kasaï Central' },
    'CD-KE': { defaultMessage: 'Kasaï Oriental' },
    'CD-KG': { defaultMessage: 'Kwango' },
    'CD-KL': { defaultMessage: 'Kwilu' },
    'CD-KN': { defaultMessage: 'Kinshasa' },
    'CD-KS': { defaultMessage: 'Kasaï' },
    'CD-LO': { defaultMessage: 'Lomami' },
    'CD-LU': { defaultMessage: 'Lualaba' },
    'CD-MA': { defaultMessage: 'Maniema' },
    'CD-MN': { defaultMessage: 'Mai-Ndombe' },
    'CD-MO': { defaultMessage: 'Mongala' },
    'CD-NK': { defaultMessage: 'Nord-Kivu' },
    'CD-NU': { defaultMessage: 'Nord-Ubangi' },
    'CD-SA': { defaultMessage: 'Sankuru' },
    'CD-SK': { defaultMessage: 'Sud-Kivu' },
    'CD-SU': { defaultMessage: 'Sud-Ubangi' },
    'CD-TA': { defaultMessage: 'Tanganyika' },
    'CD-TO': { defaultMessage: 'Tshopo' },
    'CD-TU': { defaultMessage: 'Tshuapa' },
    'CF-AC': { defaultMessage: 'Ouham' },
    'CF-BB': { defaultMessage: 'Bamingui-Bangoran' },
    'CF-BGF': { defaultMessage: 'Bangui' },
    'CF-BK': { defaultMessage: 'Basse-Kotto' },
    'CF-HK': { defaultMessage: 'Haute-Kotto' },
    'CF-HM': { defaultMessage: 'Haut-Mbomou' },
    'CF-HS': { defaultMessage: 'Haute-Sangha/Mambéré-Kadéï' },
    'CF-KB': { defaultMessage: 'Gribingui' },
    'CF-KG': { defaultMessage: 'Kémo-Gribingui' },
    'CF-LB': { defaultMessage: 'Lobaye' },
    'CF-MB': { defaultMessage: 'Mbomou' },
    'CF-MP': { defaultMessage: 'Ombella-Mpoko' },
    'CF-NM': { defaultMessage: 'Nana-Mambéré' },
    'CF-OP': { defaultMessage: 'Ouham-Pendé' },
    'CF-SE': { defaultMessage: 'Sangha' },
    'CF-UK': { defaultMessage: 'Ouaka' },
    'CF-VK': { defaultMessage: 'Vakaga' },
    'CG-11': { defaultMessage: 'Bouenza' },
    'CG-12': { defaultMessage: 'Pool' },
    'CG-13': { defaultMessage: 'Sangha' },
    'CG-14': { defaultMessage: 'Plateaux' },
    'CG-15': { defaultMessage: 'Cuvette-Ouest' },
    'CG-16': { defaultMessage: 'Pointe-Noire' },
    'CG-2': { defaultMessage: 'Lékoumou' },
    'CG-5': { defaultMessage: 'Kouilou' },
    'CG-7': { defaultMessage: 'Likouala' },
    'CG-8': { defaultMessage: 'Cuvette' },
    'CG-9': { defaultMessage: 'Niari' },
    'CG-BZV': { defaultMessage: 'Brazzaville' },
    'CH-AG': { defaultMessage: 'Aargau' },
    'CH-AI': { defaultMessage: 'Appenzell Innerrhoden' },
    'CH-AR': { defaultMessage: 'Appenzell Ausserrhoden' },
    'CH-BE': { defaultMessage: 'Bern' },
    'CH-BL': { defaultMessage: 'Basel-Landschaft' },
    'CH-BS': { defaultMessage: 'Basel-Stadt' },
    'CH-FR': { defaultMessage: 'Fribourg' },
    'CH-GE': { defaultMessage: 'Genève' },
    'CH-GL': { defaultMessage: 'Glarus' },
    'CH-GR': { defaultMessage: 'Graubünden' },
    'CH-JU': { defaultMessage: 'Jura' },
    'CH-LU': { defaultMessage: 'Luzern' },
    'CH-NE': { defaultMessage: 'Neuchâtel' },
    'CH-NW': { defaultMessage: 'Nidwalden' },
    'CH-OW': { defaultMessage: 'Obwalden' },
    'CH-SG': { defaultMessage: 'Sankt Gallen' },
    'CH-SH': { defaultMessage: 'Schaffhausen' },
    'CH-SO': { defaultMessage: 'Solothurn' },
    'CH-SZ': { defaultMessage: 'Schwyz' },
    'CH-TG': { defaultMessage: 'Thurgau' },
    'CH-TI': { defaultMessage: 'Ticino' },
    'CH-UR': { defaultMessage: 'Uri' },
    'CH-VD': { defaultMessage: 'Vaud' },
    'CH-VS': { defaultMessage: 'Valais' },
    'CH-ZG': { defaultMessage: 'Zug' },
    'CH-ZH': { defaultMessage: 'Zürich' },
    'CI-AB': { defaultMessage: 'Abidjan' },
    'CI-BS': { defaultMessage: 'Bas-Sassandra' },
    'CI-CM': { defaultMessage: 'Comoé' },
    'CI-DN': { defaultMessage: 'Denguélé' },
    'CI-GD': { defaultMessage: 'Gôh-Djiboua' },
    'CI-LC': { defaultMessage: 'Lacs' },
    'CI-LG': { defaultMessage: 'Lagunes' },
    'CI-MG': { defaultMessage: 'Montagnes' },
    'CI-SM': { defaultMessage: 'Sassandra-Marahoué' },
    'CI-SV': { defaultMessage: 'Savanes' },
    'CI-VB': { defaultMessage: 'Vallée du Bandama' },
    'CI-WR': { defaultMessage: 'Woroba' },
    'CI-YM': { defaultMessage: 'Yamoussoukro' },
    'CI-ZZ': { defaultMessage: 'Zanzan' },
    'CL-AI': { defaultMessage: 'Aisén del General Carlos Ibañez del Campo' },
    'CL-AN': { defaultMessage: 'Antofagasta' },
    'CL-AP': { defaultMessage: 'Arica y Parinacota' },
    'CL-AR': { defaultMessage: 'La Araucanía' },
    'CL-AT': { defaultMessage: 'Atacama' },
    'CL-BI': { defaultMessage: 'Biobío' },
    'CL-CO': { defaultMessage: 'Coquimbo' },
    'CL-LI': { defaultMessage: "Libertador General Bernardo O'Higgins" },
    'CL-LL': { defaultMessage: 'Los Lagos' },
    'CL-LR': { defaultMessage: 'Los Ríos' },
    'CL-MA': { defaultMessage: 'Magallanes' },
    'CL-ML': { defaultMessage: 'Maule' },
    'CL-NB': { defaultMessage: 'Ñuble' },
    'CL-RM': { defaultMessage: 'Región Metropolitana de Santiago' },
    'CL-TA': { defaultMessage: 'Tarapacá' },
    'CL-VS': { defaultMessage: 'Valparaíso' },
    'CM-AD': { defaultMessage: 'Adamaoua' },
    'CM-CE': { defaultMessage: 'Centre' },
    'CM-EN': { defaultMessage: 'Far North' },
    'CM-ES': { defaultMessage: 'East' },
    'CM-LT': { defaultMessage: 'Littoral' },
    'CM-NO': { defaultMessage: 'North' },
    'CM-NW': { defaultMessage: 'North-West' },
    'CM-OU': { defaultMessage: 'West' },
    'CM-SU': { defaultMessage: 'South' },
    'CM-SW': { defaultMessage: 'South-West' },
    'CN-AH': { defaultMessage: 'Anhui Sheng' },
    'CN-BJ': { defaultMessage: 'Beijing Shi' },
    'CN-CQ': { defaultMessage: 'Chongqing Shi' },
    'CN-FJ': { defaultMessage: 'Fujian Sheng' },
    'CN-GD': { defaultMessage: 'Guangdong Sheng' },
    'CN-GS': { defaultMessage: 'Gansu Sheng' },
    'CN-GX': { defaultMessage: 'Guangxi Zhuangzu Zizhiqu' },
    'CN-GZ': { defaultMessage: 'Guizhou Sheng' },
    'CN-HA': { defaultMessage: 'Henan Sheng' },
    'CN-HB': { defaultMessage: 'Hubei Sheng' },
    'CN-HE': { defaultMessage: 'Hebei Sheng' },
    'CN-HI': { defaultMessage: 'Hainan Sheng' },
    'CN-HK': { defaultMessage: 'Hong Kong SAR' },
    'CN-HL': { defaultMessage: 'Heilongjiang Sheng' },
    'CN-HN': { defaultMessage: 'Hunan Sheng' },
    'CN-JL': { defaultMessage: 'Jilin Sheng' },
    'CN-JS': { defaultMessage: 'Jiangsu Sheng' },
    'CN-JX': { defaultMessage: 'Jiangxi Sheng' },
    'CN-LN': { defaultMessage: 'Liaoning Sheng' },
    'CN-MO': { defaultMessage: 'Macao SAR' },
    'CN-NM': { defaultMessage: 'Nei Mongol Zizhiqu' },
    'CN-NX': { defaultMessage: 'Ningxia Huizi Zizhiqu' },
    'CN-QH': { defaultMessage: 'Qinghai Sheng' },
    'CN-SC': { defaultMessage: 'Sichuan Sheng' },
    'CN-SD': { defaultMessage: 'Shandong Sheng' },
    'CN-SH': { defaultMessage: 'Shanghai Shi' },
    'CN-SN': { defaultMessage: 'Shaanxi Sheng' },
    'CN-SX': { defaultMessage: 'Shanxi Sheng' },
    'CN-TJ': { defaultMessage: 'Tianjin Shi' },
    'CN-TW': { defaultMessage: 'Taiwan Sheng' },
    'CN-XJ': { defaultMessage: 'Xinjiang Uygur Zizhiqu' },
    'CN-XZ': { defaultMessage: 'Xizang Zizhiqu' },
    'CN-YN': { defaultMessage: 'Yunnan Sheng' },
    'CN-ZJ': { defaultMessage: 'Zhejiang Sheng' },
    'CO-AMA': { defaultMessage: 'Amazonas' },
    'CO-ANT': { defaultMessage: 'Antioquia' },
    'CO-ARA': { defaultMessage: 'Arauca' },
    'CO-ATL': { defaultMessage: 'Atlántico' },
    'CO-BOL': { defaultMessage: 'Bolívar' },
    'CO-BOY': { defaultMessage: 'Boyacá' },
    'CO-CAL': { defaultMessage: 'Caldas' },
    'CO-CAQ': { defaultMessage: 'Caquetá' },
    'CO-CAS': { defaultMessage: 'Casanare' },
    'CO-CAU': { defaultMessage: 'Cauca' },
    'CO-CES': { defaultMessage: 'Cesar' },
    'CO-CHO': { defaultMessage: 'Chocó' },
    'CO-COR': { defaultMessage: 'Córdoba' },
    'CO-CUN': { defaultMessage: 'Cundinamarca' },
    'CO-DC': { defaultMessage: 'Distrito Capital de Bogotá' },
    'CO-GUA': { defaultMessage: 'Guainía' },
    'CO-GUV': { defaultMessage: 'Guaviare' },
    'CO-HUI': { defaultMessage: 'Huila' },
    'CO-LAG': { defaultMessage: 'La Guajira' },
    'CO-MAG': { defaultMessage: 'Magdalena' },
    'CO-MET': { defaultMessage: 'Meta' },
    'CO-NAR': { defaultMessage: 'Nariño' },
    'CO-NSA': { defaultMessage: 'Norte de Santander' },
    'CO-PUT': { defaultMessage: 'Putumayo' },
    'CO-QUI': { defaultMessage: 'Quindío' },
    'CO-RIS': { defaultMessage: 'Risaralda' },
    'CO-SAN': { defaultMessage: 'Santander' },
    'CO-SAP': { defaultMessage: 'San Andrés, Providencia y Santa Catalina' },
    'CO-SUC': { defaultMessage: 'Sucre' },
    'CO-TOL': { defaultMessage: 'Tolima' },
    'CO-VAC': { defaultMessage: 'Valle del Cauca' },
    'CO-VAU': { defaultMessage: 'Vaupés' },
    'CO-VID': { defaultMessage: 'Vichada' },
    'CR-A': { defaultMessage: 'Alajuela' },
    'CR-C': { defaultMessage: 'Cartago' },
    'CR-G': { defaultMessage: 'Guanacaste' },
    'CR-H': { defaultMessage: 'Heredia' },
    'CR-L': { defaultMessage: 'Limón' },
    'CR-P': { defaultMessage: 'Puntarenas' },
    'CR-SJ': { defaultMessage: 'San José' },
    'CU-01': { defaultMessage: 'Pinar del Río' },
    'CU-03': { defaultMessage: 'La Habana' },
    'CU-04': { defaultMessage: 'Matanzas' },
    'CU-05': { defaultMessage: 'Villa Clara' },
    'CU-06': { defaultMessage: 'Cienfuegos' },
    'CU-07': { defaultMessage: 'Sancti Spíritus' },
    'CU-08': { defaultMessage: 'Ciego de Ávila' },
    'CU-09': { defaultMessage: 'Camagüey' },
    'CU-10': { defaultMessage: 'Las Tunas' },
    'CU-11': { defaultMessage: 'Holguín' },
    'CU-12': { defaultMessage: 'Granma' },
    'CU-13': { defaultMessage: 'Santiago de Cuba' },
    'CU-14': { defaultMessage: 'Guantánamo' },
    'CU-15': { defaultMessage: 'Artemisa' },
    'CU-16': { defaultMessage: 'Mayabeque' },
    'CU-99': { defaultMessage: 'Isla de la Juventud' },
    'CV-B': { defaultMessage: 'Ilhas de Barlavento' },
    'CV-BR': { defaultMessage: 'Brava' },
    'CV-BV': { defaultMessage: 'Boa Vista' },
    'CV-CA': { defaultMessage: 'Santa Catarina' },
    'CV-CF': { defaultMessage: 'Santa Catarina do Fogo' },
    'CV-CR': { defaultMessage: 'Santa Cruz' },
    'CV-MA': { defaultMessage: 'Maio' },
    'CV-MO': { defaultMessage: 'Mosteiros' },
    'CV-PA': { defaultMessage: 'Paul' },
    'CV-PN': { defaultMessage: 'Porto Novo' },
    'CV-PR': { defaultMessage: 'Praia' },
    'CV-RB': { defaultMessage: 'Ribeira Brava' },
    'CV-RG': { defaultMessage: 'Ribeira Grande' },
    'CV-RS': { defaultMessage: 'Ribeira Grande de Santiago' },
    'CV-S': { defaultMessage: 'Ilhas de Sotavento' },
    'CV-SD': { defaultMessage: 'São Domingos' },
    'CV-SF': { defaultMessage: 'São Filipe' },
    'CV-SL': { defaultMessage: 'Sal' },
    'CV-SM': { defaultMessage: 'São Miguel' },
    'CV-SO': { defaultMessage: 'São Lourenço dos Órgãos' },
    'CV-SS': { defaultMessage: 'São Salvador do Mundo' },
    'CV-SV': { defaultMessage: 'São Vicente' },
    'CV-TA': { defaultMessage: 'Tarrafal' },
    'CV-TS': { defaultMessage: 'Tarrafal de São Nicolau' },
    'CY-01': { defaultMessage: 'Lefkosia' },
    'CY-02': { defaultMessage: 'Lemesos' },
    'CY-03': { defaultMessage: 'Larnaka' },
    'CY-04': { defaultMessage: 'Ammochostos' },
    'CY-05': { defaultMessage: 'Pafos' },
    'CY-06': { defaultMessage: 'Keryneia' },
    'CZ-10': { defaultMessage: 'Praha, Hlavní město' },
    'CZ-20': { defaultMessage: 'Středočeský kraj' },
    'CZ-201': { defaultMessage: 'Benešov' },
    'CZ-202': { defaultMessage: 'Beroun' },
    'CZ-203': { defaultMessage: 'Kladno' },
    'CZ-204': { defaultMessage: 'Kolín' },
    'CZ-205': { defaultMessage: 'Kutná Hora' },
    'CZ-206': { defaultMessage: 'Mělník' },
    'CZ-207': { defaultMessage: 'Mladá Boleslav' },
    'CZ-208': { defaultMessage: 'Nymburk' },
    'CZ-209': { defaultMessage: 'Praha-východ' },
    'CZ-20A': { defaultMessage: 'Praha-západ' },
    'CZ-20B': { defaultMessage: 'Příbram' },
    'CZ-20C': { defaultMessage: 'Rakovník' },
    'CZ-31': { defaultMessage: 'Jihočeský kraj' },
    'CZ-311': { defaultMessage: 'České Budějovice' },
    'CZ-312': { defaultMessage: 'Český Krumlov' },
    'CZ-313': { defaultMessage: 'Jindřichův Hradec' },
    'CZ-314': { defaultMessage: 'Písek' },
    'CZ-315': { defaultMessage: 'Prachatice' },
    'CZ-316': { defaultMessage: 'Strakonice' },
    'CZ-317': { defaultMessage: 'Tábor' },
    'CZ-32': { defaultMessage: 'Plzeňský kraj' },
    'CZ-321': { defaultMessage: 'Domažlice' },
    'CZ-322': { defaultMessage: 'Klatovy' },
    'CZ-323': { defaultMessage: 'Plzeň-město' },
    'CZ-324': { defaultMessage: 'Plzeň-jih' },
    'CZ-325': { defaultMessage: 'Plzeň-sever' },
    'CZ-326': { defaultMessage: 'Rokycany' },
    'CZ-327': { defaultMessage: 'Tachov' },
    'CZ-41': { defaultMessage: 'Karlovarský kraj' },
    'CZ-411': { defaultMessage: 'Cheb' },
    'CZ-412': { defaultMessage: 'Karlovy Vary' },
    'CZ-413': { defaultMessage: 'Sokolov' },
    'CZ-42': { defaultMessage: 'Ústecký kraj' },
    'CZ-421': { defaultMessage: 'Děčín' },
    'CZ-422': { defaultMessage: 'Chomutov' },
    'CZ-423': { defaultMessage: 'Litoměřice' },
    'CZ-424': { defaultMessage: 'Louny' },
    'CZ-425': { defaultMessage: 'Most' },
    'CZ-426': { defaultMessage: 'Teplice' },
    'CZ-427': { defaultMessage: 'Ústí nad Labem' },
    'CZ-51': { defaultMessage: 'Liberecký kraj' },
    'CZ-511': { defaultMessage: 'Česká Lípa' },
    'CZ-512': { defaultMessage: 'Jablonec nad Nisou' },
    'CZ-513': { defaultMessage: 'Liberec' },
    'CZ-514': { defaultMessage: 'Semily' },
    'CZ-52': { defaultMessage: 'Královéhradecký kraj' },
    'CZ-521': { defaultMessage: 'Hradec Králové' },
    'CZ-522': { defaultMessage: 'Jičín' },
    'CZ-523': { defaultMessage: 'Náchod' },
    'CZ-524': { defaultMessage: 'Rychnov nad Kněžnou' },
    'CZ-525': { defaultMessage: 'Trutnov' },
    'CZ-53': { defaultMessage: 'Pardubický kraj' },
    'CZ-531': { defaultMessage: 'Chrudim' },
    'CZ-532': { defaultMessage: 'Pardubice' },
    'CZ-533': { defaultMessage: 'Svitavy' },
    'CZ-534': { defaultMessage: 'Ústí nad Orlicí' },
    'CZ-63': { defaultMessage: 'Kraj Vysočina' },
    'CZ-631': { defaultMessage: 'Havlíčkův Brod' },
    'CZ-632': { defaultMessage: 'Jihlava' },
    'CZ-633': { defaultMessage: 'Pelhřimov' },
    'CZ-634': { defaultMessage: 'Třebíč' },
    'CZ-635': { defaultMessage: 'Žďár nad Sázavou' },
    'CZ-64': { defaultMessage: 'Jihomoravský kraj' },
    'CZ-641': { defaultMessage: 'Blansko' },
    'CZ-642': { defaultMessage: 'Brno-město' },
    'CZ-643': { defaultMessage: 'Brno-venkov' },
    'CZ-644': { defaultMessage: 'Břeclav' },
    'CZ-645': { defaultMessage: 'Hodonín' },
    'CZ-646': { defaultMessage: 'Vyškov' },
    'CZ-647': { defaultMessage: 'Znojmo' },
    'CZ-71': { defaultMessage: 'Olomoucký kraj' },
    'CZ-711': { defaultMessage: 'Jeseník' },
    'CZ-712': { defaultMessage: 'Olomouc' },
    'CZ-713': { defaultMessage: 'Prostějov' },
    'CZ-714': { defaultMessage: 'Přerov' },
    'CZ-715': { defaultMessage: 'Šumperk' },
    'CZ-72': { defaultMessage: 'Zlínský kraj' },
    'CZ-721': { defaultMessage: 'Kroměříž' },
    'CZ-722': { defaultMessage: 'Uherské Hradiště' },
    'CZ-723': { defaultMessage: 'Vsetín' },
    'CZ-724': { defaultMessage: 'Zlín' },
    'CZ-80': { defaultMessage: 'Moravskoslezský kraj' },
    'CZ-801': { defaultMessage: 'Bruntál' },
    'CZ-802': { defaultMessage: 'Frýdek-Místek' },
    'CZ-803': { defaultMessage: 'Karviná' },
    'CZ-804': { defaultMessage: 'Nový Jičín' },
    'CZ-805': { defaultMessage: 'Opava' },
    'CZ-806': { defaultMessage: 'Ostrava-město' },
    'DE-BB': { defaultMessage: 'Brandenburg' },
    'DE-BE': { defaultMessage: 'Berlin' },
    'DE-BW': { defaultMessage: 'Baden-Württemberg' },
    'DE-BY': { defaultMessage: 'Bayern' },
    'DE-HB': { defaultMessage: 'Bremen' },
    'DE-HE': { defaultMessage: 'Hessen' },
    'DE-HH': { defaultMessage: 'Hamburg' },
    'DE-MV': { defaultMessage: 'Mecklenburg-Vorpommern' },
    'DE-NI': { defaultMessage: 'Niedersachsen' },
    'DE-NW': { defaultMessage: 'Nordrhein-Westfalen' },
    'DE-RP': { defaultMessage: 'Rheinland-Pfalz' },
    'DE-SH': { defaultMessage: 'Schleswig-Holstein' },
    'DE-SL': { defaultMessage: 'Saarland' },
    'DE-SN': { defaultMessage: 'Sachsen' },
    'DE-ST': { defaultMessage: 'Sachsen-Anhalt' },
    'DE-TH': { defaultMessage: 'Thüringen' },
    'DJ-AR': { defaultMessage: 'Arta' },
    'DJ-AS': { defaultMessage: 'Ali Sabieh' },
    'DJ-DI': { defaultMessage: 'Dikhil' },
    'DJ-DJ': { defaultMessage: 'Djibouti' },
    'DJ-OB': { defaultMessage: 'Obock' },
    'DJ-TA': { defaultMessage: 'Tadjourah' },
    'DK-81': { defaultMessage: 'Nordjylland' },
    'DK-82': { defaultMessage: 'Midtjylland' },
    'DK-83': { defaultMessage: 'Syddanmark' },
    'DK-84': { defaultMessage: 'Hovedstaden' },
    'DK-85': { defaultMessage: 'Sjælland' },
    'DM-02': { defaultMessage: 'Saint Andrew' },
    'DM-03': { defaultMessage: 'Saint David' },
    'DM-04': { defaultMessage: 'Saint George' },
    'DM-05': { defaultMessage: 'Saint John' },
    'DM-06': { defaultMessage: 'Saint Joseph' },
    'DM-07': { defaultMessage: 'Saint Luke' },
    'DM-08': { defaultMessage: 'Saint Mark' },
    'DM-09': { defaultMessage: 'Saint Patrick' },
    'DM-10': { defaultMessage: 'Saint Paul' },
    'DM-11': { defaultMessage: 'Saint Peter' },
    'DO-01': { defaultMessage: 'Distrito Nacional (Santo Domingo)' },
    'DO-02': { defaultMessage: 'Azua' },
    'DO-03': { defaultMessage: 'Baoruco' },
    'DO-04': { defaultMessage: 'Barahona' },
    'DO-05': { defaultMessage: 'Dajabón' },
    'DO-06': { defaultMessage: 'Duarte' },
    'DO-07': { defaultMessage: 'Elías Piña' },
    'DO-08': { defaultMessage: 'El Seibo' },
    'DO-09': { defaultMessage: 'Espaillat' },
    'DO-10': { defaultMessage: 'Independencia' },
    'DO-11': { defaultMessage: 'La Altagracia' },
    'DO-12': { defaultMessage: 'La Romana' },
    'DO-13': { defaultMessage: 'La Vega' },
    'DO-14': { defaultMessage: 'María Trinidad Sánchez' },
    'DO-15': { defaultMessage: 'Monte Cristi' },
    'DO-16': { defaultMessage: 'Pedernales' },
    'DO-17': { defaultMessage: 'Peravia' },
    'DO-18': { defaultMessage: 'Puerto Plata' },
    'DO-19': { defaultMessage: 'Hermanas Mirabal' },
    'DO-20': { defaultMessage: 'Samaná' },
    'DO-21': { defaultMessage: 'San Cristóbal' },
    'DO-22': { defaultMessage: 'San Juan' },
    'DO-23': { defaultMessage: 'San Pedro de Macorís' },
    'DO-24': { defaultMessage: 'Sánchez Ramírez' },
    'DO-25': { defaultMessage: 'Santiago' },
    'DO-26': { defaultMessage: 'Santiago Rodríguez' },
    'DO-27': { defaultMessage: 'Valverde' },
    'DO-28': { defaultMessage: 'Monseñor Nouel' },
    'DO-29': { defaultMessage: 'Monte Plata' },
    'DO-30': { defaultMessage: 'Hato Mayor' },
    'DO-31': { defaultMessage: 'San José de Ocoa' },
    'DO-32': { defaultMessage: 'Santo Domingo' },
    'DO-33': { defaultMessage: 'Cibao Nordeste' },
    'DO-34': { defaultMessage: 'Cibao Noroeste' },
    'DO-35': { defaultMessage: 'Cibao Norte' },
    'DO-36': { defaultMessage: 'Cibao Sur' },
    'DO-37': { defaultMessage: 'El Valle' },
    'DO-38': { defaultMessage: 'Enriquillo' },
    'DO-39': { defaultMessage: 'Higuamo' },
    'DO-40': { defaultMessage: 'Ozama' },
    'DO-41': { defaultMessage: 'Valdesia' },
    'DO-42': { defaultMessage: 'Yuma' },
    'DZ-01': { defaultMessage: 'Adrar' },
    'DZ-02': { defaultMessage: 'Chlef' },
    'DZ-03': { defaultMessage: 'Laghouat' },
    'DZ-04': { defaultMessage: 'Oum el Bouaghi' },
    'DZ-05': { defaultMessage: 'Batna' },
    'DZ-06': { defaultMessage: 'Béjaïa' },
    'DZ-07': { defaultMessage: 'Biskra' },
    'DZ-08': { defaultMessage: 'Béchar' },
    'DZ-09': { defaultMessage: 'Blida' },
    'DZ-10': { defaultMessage: 'Bouira' },
    'DZ-11': { defaultMessage: 'Tamanrasset' },
    'DZ-12': { defaultMessage: 'Tébessa' },
    'DZ-13': { defaultMessage: 'Tlemcen' },
    'DZ-14': { defaultMessage: 'Tiaret' },
    'DZ-15': { defaultMessage: 'Tizi Ouzou' },
    'DZ-16': { defaultMessage: 'Alger' },
    'DZ-17': { defaultMessage: 'Djelfa' },
    'DZ-18': { defaultMessage: 'Jijel' },
    'DZ-19': { defaultMessage: 'Sétif' },
    'DZ-20': { defaultMessage: 'Saïda' },
    'DZ-21': { defaultMessage: 'Skikda' },
    'DZ-22': { defaultMessage: 'Sidi Bel Abbès' },
    'DZ-23': { defaultMessage: 'Annaba' },
    'DZ-24': { defaultMessage: 'Guelma' },
    'DZ-25': { defaultMessage: 'Constantine' },
    'DZ-26': { defaultMessage: 'Médéa' },
    'DZ-27': { defaultMessage: 'Mostaganem' },
    'DZ-28': { defaultMessage: "M'sila" },
    'DZ-29': { defaultMessage: 'Mascara' },
    'DZ-30': { defaultMessage: 'Ouargla' },
    'DZ-31': { defaultMessage: 'Oran' },
    'DZ-32': { defaultMessage: 'El Bayadh' },
    'DZ-33': { defaultMessage: 'Illizi' },
    'DZ-34': { defaultMessage: 'Bordj Bou Arréridj' },
    'DZ-35': { defaultMessage: 'Boumerdès' },
    'DZ-36': { defaultMessage: 'El Tarf' },
    'DZ-37': { defaultMessage: 'Tindouf' },
    'DZ-38': { defaultMessage: 'Tissemsilt' },
    'DZ-39': { defaultMessage: 'El Oued' },
    'DZ-40': { defaultMessage: 'Khenchela' },
    'DZ-41': { defaultMessage: 'Souk Ahras' },
    'DZ-42': { defaultMessage: 'Tipaza' },
    'DZ-43': { defaultMessage: 'Mila' },
    'DZ-44': { defaultMessage: 'Aïn Defla' },
    'DZ-45': { defaultMessage: 'Naama' },
    'DZ-46': { defaultMessage: 'Aïn Témouchent' },
    'DZ-47': { defaultMessage: 'Ghardaïa' },
    'DZ-48': { defaultMessage: 'Relizane' },
    'EC-A': { defaultMessage: 'Azuay' },
    'EC-B': { defaultMessage: 'Bolívar' },
    'EC-C': { defaultMessage: 'Carchi' },
    'EC-D': { defaultMessage: 'Orellana' },
    'EC-E': { defaultMessage: 'Esmeraldas' },
    'EC-F': { defaultMessage: 'Cañar' },
    'EC-G': { defaultMessage: 'Guayas' },
    'EC-H': { defaultMessage: 'Chimborazo' },
    'EC-I': { defaultMessage: 'Imbabura' },
    'EC-L': { defaultMessage: 'Loja' },
    'EC-M': { defaultMessage: 'Manabí' },
    'EC-N': { defaultMessage: 'Napo' },
    'EC-O': { defaultMessage: 'El Oro' },
    'EC-P': { defaultMessage: 'Pichincha' },
    'EC-R': { defaultMessage: 'Los Ríos' },
    'EC-S': { defaultMessage: 'Morona Santiago' },
    'EC-SD': { defaultMessage: 'Santo Domingo de los Tsáchilas' },
    'EC-SE': { defaultMessage: 'Santa Elena' },
    'EC-T': { defaultMessage: 'Tungurahua' },
    'EC-U': { defaultMessage: 'Sucumbíos' },
    'EC-W': { defaultMessage: 'Galápagos' },
    'EC-X': { defaultMessage: 'Cotopaxi' },
    'EC-Y': { defaultMessage: 'Pastaza' },
    'EC-Z': { defaultMessage: 'Zamora Chinchipe' },
    'EE-130': { defaultMessage: 'Alutaguse' },
    'EE-141': { defaultMessage: 'Anija' },
    'EE-142': { defaultMessage: 'Antsla' },
    'EE-171': { defaultMessage: 'Elva' },
    'EE-184': { defaultMessage: 'Haapsalu' },
    'EE-191': { defaultMessage: 'Haljala' },
    'EE-198': { defaultMessage: 'Harku' },
    'EE-205': { defaultMessage: 'Hiiumaa' },
    'EE-214': { defaultMessage: 'Häädemeeste' },
    'EE-245': { defaultMessage: 'Jõelähtme' },
    'EE-247': { defaultMessage: 'Jõgeva' },
    'EE-251': { defaultMessage: 'Jõhvi' },
    'EE-255': { defaultMessage: 'Järva' },
    'EE-272': { defaultMessage: 'Kadrina' },
    'EE-283': { defaultMessage: 'Kambja' },
    'EE-284': { defaultMessage: 'Kanepi' },
    'EE-291': { defaultMessage: 'Kastre' },
    'EE-293': { defaultMessage: 'Kehtna' },
    'EE-296': { defaultMessage: 'Keila' },
    'EE-303': { defaultMessage: 'Kihnu' },
    'EE-305': { defaultMessage: 'Kiili' },
    'EE-317': { defaultMessage: 'Kohila' },
    'EE-321': { defaultMessage: 'Kohtla-Järve' },
    'EE-338': { defaultMessage: 'Kose' },
    'EE-353': { defaultMessage: 'Kuusalu' },
    'EE-37': { defaultMessage: 'Harjumaa' },
    'EE-39': { defaultMessage: 'Hiiumaa' },
    'EE-424': { defaultMessage: 'Loksa' },
    'EE-430': { defaultMessage: 'Lääneranna' },
    'EE-431': { defaultMessage: 'Lääne-Harju' },
    'EE-432': { defaultMessage: 'Luunja' },
    'EE-441': { defaultMessage: 'Lääne-Nigula' },
    'EE-442': { defaultMessage: 'Lüganuse' },
    'EE-446': { defaultMessage: 'Maardu' },
    'EE-45': { defaultMessage: 'Ida-Virumaa' },
    'EE-478': { defaultMessage: 'Muhu' },
    'EE-480': { defaultMessage: 'Mulgi' },
    'EE-486': { defaultMessage: 'Mustvee' },
    'EE-50': { defaultMessage: 'Jõgevamaa' },
    'EE-503': { defaultMessage: 'Märjamaa' },
    'EE-511': { defaultMessage: 'Narva' },
    'EE-514': { defaultMessage: 'Narva-Jõesuu' },
    'EE-52': { defaultMessage: 'Järvamaa' },
    'EE-528': { defaultMessage: 'Nõo' },
    'EE-557': { defaultMessage: 'Otepää' },
    'EE-56': { defaultMessage: 'Läänemaa' },
    'EE-567': { defaultMessage: 'Paide' },
    'EE-586': { defaultMessage: 'Peipsiääre' },
    'EE-60': { defaultMessage: 'Lääne-Virumaa' },
    'EE-615': { defaultMessage: 'Põhja-Sakala' },
    'EE-618': { defaultMessage: 'Põltsamaa' },
    'EE-622': { defaultMessage: 'Põlva' },
    'EE-624': { defaultMessage: 'Pärnu' },
    'EE-638': { defaultMessage: 'Põhja-Pärnumaa' },
    'EE-64': { defaultMessage: 'Põlvamaa' },
    'EE-651': { defaultMessage: 'Raasiku' },
    'EE-653': { defaultMessage: 'Rae' },
    'EE-661': { defaultMessage: 'Rakvere' },
    'EE-663': { defaultMessage: 'Rakvere' },
    'EE-668': { defaultMessage: 'Rapla' },
    'EE-68': { defaultMessage: 'Pärnumaa' },
    'EE-689': { defaultMessage: 'Ruhnu' },
    'EE-698': { defaultMessage: 'Rõuge' },
    'EE-708': { defaultMessage: 'Räpina' },
    'EE-71': { defaultMessage: 'Raplamaa' },
    'EE-712': { defaultMessage: 'Saarde' },
    'EE-714': { defaultMessage: 'Saaremaa' },
    'EE-719': { defaultMessage: 'Saku' },
    'EE-726': { defaultMessage: 'Saue' },
    'EE-732': { defaultMessage: 'Setomaa' },
    'EE-735': { defaultMessage: 'Sillamäe' },
    'EE-74': { defaultMessage: 'Saaremaa' },
    'EE-784': { defaultMessage: 'Tallinn' },
    'EE-79': { defaultMessage: 'Tartumaa' },
    'EE-792': { defaultMessage: 'Tapa' },
    'EE-793': { defaultMessage: 'Tartu' },
    'EE-796': { defaultMessage: 'Tartu' },
    'EE-803': { defaultMessage: 'Toila' },
    'EE-809': { defaultMessage: 'Tori' },
    'EE-81': { defaultMessage: 'Valgamaa' },
    'EE-824': { defaultMessage: 'Tõrva' },
    'EE-834': { defaultMessage: 'Türi' },
    'EE-84': { defaultMessage: 'Viljandimaa' },
    'EE-855': { defaultMessage: 'Valga' },
    'EE-87': { defaultMessage: 'Võrumaa' },
    'EE-890': { defaultMessage: 'Viimsi' },
    'EE-897': { defaultMessage: 'Viljandi' },
    'EE-899': { defaultMessage: 'Viljandi' },
    'EE-901': { defaultMessage: 'Vinni' },
    'EE-903': { defaultMessage: 'Viru-Nigula' },
    'EE-907': { defaultMessage: 'Vormsi' },
    'EE-917': { defaultMessage: 'Võru' },
    'EE-919': { defaultMessage: 'Võru' },
    'EE-928': { defaultMessage: 'Väike-Maarja' },
    'EG-ALX': { defaultMessage: 'Al Iskandarīyah' },
    'EG-ASN': { defaultMessage: 'Aswān' },
    'EG-AST': { defaultMessage: 'Asyūţ' },
    'EG-BA': { defaultMessage: 'Al Baḩr al Aḩmar' },
    'EG-BH': { defaultMessage: 'Al Buḩayrah' },
    'EG-BNS': { defaultMessage: 'Banī Suwayf' },
    'EG-C': { defaultMessage: 'Al Qāhirah' },
    'EG-DK': { defaultMessage: 'Ad Daqahlīyah' },
    'EG-DT': { defaultMessage: 'Dumyāţ' },
    'EG-FYM': { defaultMessage: 'Al Fayyūm' },
    'EG-GH': { defaultMessage: 'Al Gharbīyah' },
    'EG-GZ': { defaultMessage: 'Al Jīzah' },
    'EG-IS': { defaultMessage: "Al Ismā'īlīyah" },
    'EG-JS': { defaultMessage: "Janūb Sīnā'" },
    'EG-KB': { defaultMessage: 'Al Qalyūbīyah' },
    'EG-KFS': { defaultMessage: 'Kafr ash Shaykh' },
    'EG-KN': { defaultMessage: 'Qinā' },
    'EG-LX': { defaultMessage: 'Al Uqşur' },
    'EG-MN': { defaultMessage: 'Al Minyā' },
    'EG-MNF': { defaultMessage: 'Al Minūfīyah' },
    'EG-MT': { defaultMessage: 'Maţrūḩ' },
    'EG-PTS': { defaultMessage: 'Būr Sa‘īd' },
    'EG-SHG': { defaultMessage: 'Sūhāj' },
    'EG-SHR': { defaultMessage: 'Ash Sharqīyah' },
    'EG-SIN': { defaultMessage: "Shamāl Sīnā'" },
    'EG-SUZ': { defaultMessage: 'As Suways' },
    'EG-WAD': { defaultMessage: 'Al Wādī al Jadīd' },
    'ER-AN': { defaultMessage: 'Ansabā' },
    'ER-DK': { defaultMessage: 'Janūbī al Baḩrī al Aḩmar' },
    'ER-DU': { defaultMessage: 'Al Janūbī' },
    'ER-GB': { defaultMessage: 'Qāsh-Barkah' },
    'ER-MA': { defaultMessage: 'Al Awsaţ' },
    'ER-SK': { defaultMessage: 'Shimālī al Baḩrī al Aḩmar' },
    'ES-A': { defaultMessage: 'AlicanteAlacant' },
    'ES-AB': { defaultMessage: 'Albacete' },
    'ES-AL': { defaultMessage: 'Almería' },
    'ES-AN': { defaultMessage: 'Andalucía' },
    'ES-AR': { defaultMessage: 'Aragón' },
    'ES-AS': { defaultMessage: 'Asturias, Principado de' },
    'ES-AV': { defaultMessage: 'Ávila' },
    'ES-B': { defaultMessage: 'Barcelona' },
    'ES-BA': { defaultMessage: 'Badajoz' },
    'ES-BI': { defaultMessage: 'Bizkaia' },
    'ES-BU': { defaultMessage: 'Burgos' },
    'ES-C': { defaultMessage: 'A Coruña' },
    'ES-CA': { defaultMessage: 'Cádiz' },
    'ES-CB': { defaultMessage: 'Cantabria' },
    'ES-CC': { defaultMessage: 'Cáceres' },
    'ES-CE': { defaultMessage: 'Ceuta' },
    'ES-CL': { defaultMessage: 'Castilla y León' },
    'ES-CM': { defaultMessage: 'Castilla-La Mancha' },
    'ES-CN': { defaultMessage: 'Canarias' },
    'ES-CO': { defaultMessage: 'Córdoba' },
    'ES-CR': { defaultMessage: 'Ciudad Real' },
    'ES-CS': { defaultMessage: 'CastellónCastelló' },
    'ES-CT': { defaultMessage: 'Catalunya' },
    'ES-CU': { defaultMessage: 'Cuenca' },
    'ES-EX': { defaultMessage: 'Extremadura' },
    'ES-GA': { defaultMessage: 'Galicia' },
    'ES-GC': { defaultMessage: 'Las Palmas' },
    'ES-GI': { defaultMessage: 'Girona' },
    'ES-GR': { defaultMessage: 'Granada' },
    'ES-GU': { defaultMessage: 'Guadalajara' },
    'ES-H': { defaultMessage: 'Huelva' },
    'ES-HU': { defaultMessage: 'Huesca' },
    'ES-IB': { defaultMessage: 'Illes Balears' },
    'ES-J': { defaultMessage: 'Jaén' },
    'ES-L': { defaultMessage: 'Lleida' },
    'ES-LE': { defaultMessage: 'León' },
    'ES-LO': { defaultMessage: 'La Rioja' },
    'ES-LU': { defaultMessage: 'Lugo' },
    'ES-M': { defaultMessage: 'Madrid' },
    'ES-MA': { defaultMessage: 'Málaga' },
    'ES-MC': { defaultMessage: 'Murcia, Región de' },
    'ES-MD': { defaultMessage: 'Madrid, Comunidad de' },
    'ES-ML': { defaultMessage: 'Melilla' },
    'ES-MU': { defaultMessage: 'Murcia' },
    'ES-NA': { defaultMessage: 'NavarraNafarroa' },
    'ES-NC': {
      defaultMessage: 'Navarra, Comunidad Foral deNafarroako Foru Komunitatea',
    },
    'ES-O': { defaultMessage: 'Asturias' },
    'ES-OR': { defaultMessage: 'Ourense' },
    'ES-P': { defaultMessage: 'Palencia' },
    'ES-PM': { defaultMessage: 'Illes Balears' },
    'ES-PO': { defaultMessage: 'Pontevedra' },
    'ES-PV': { defaultMessage: 'País VascoEuskal Herria' },
    'ES-RI': { defaultMessage: 'La Rioja' },
    'ES-S': { defaultMessage: 'Cantabria' },
    'ES-SA': { defaultMessage: 'Salamanca' },
    'ES-SE': { defaultMessage: 'Sevilla' },
    'ES-SG': { defaultMessage: 'Segovia' },
    'ES-SO': { defaultMessage: 'Soria' },
    'ES-SS': { defaultMessage: 'Gipuzkoa' },
    'ES-T': { defaultMessage: 'Tarragona' },
    'ES-TE': { defaultMessage: 'Teruel' },
    'ES-TF': { defaultMessage: 'Santa Cruz de Tenerife' },
    'ES-TO': { defaultMessage: 'Toledo' },
    'ES-V': { defaultMessage: 'València' },
    'ES-VA': { defaultMessage: 'Valladolid' },
    'ES-VC': { defaultMessage: 'Valenciana, Comunitat' },
    'ES-VI': { defaultMessage: 'ÁlavaAraba' },
    'ES-Z': { defaultMessage: 'Zaragoza' },
    'ES-ZA': { defaultMessage: 'Zamora' },
    'ET-AA': { defaultMessage: 'Ādīs Ābeba' },
    'ET-AF': { defaultMessage: 'Āfar' },
    'ET-AM': { defaultMessage: 'Āmara' },
    'ET-BE': { defaultMessage: 'Bīnshangul Gumuz' },
    'ET-DD': { defaultMessage: 'Dirē Dawa' },
    'ET-GA': { defaultMessage: 'Gambēla Hizboch' },
    'ET-HA': { defaultMessage: 'Hārerī Hizb' },
    'ET-OR': { defaultMessage: 'Oromīya' },
    'ET-SN': { defaultMessage: 'YeDebub Bihēroch Bihēreseboch na Hizboch' },
    'ET-SO': { defaultMessage: 'Sumalē' },
    'ET-TI': { defaultMessage: 'Tigray' },
    'FI-01': { defaultMessage: 'Ahvenanmaan maakunta' },
    'FI-02': { defaultMessage: 'Etelä-Karjala' },
    'FI-03': { defaultMessage: 'Etelä-Pohjanmaa' },
    'FI-04': { defaultMessage: 'Etelä-Savo' },
    'FI-05': { defaultMessage: 'Kainuu' },
    'FI-06': { defaultMessage: 'Kanta-Häme' },
    'FI-07': { defaultMessage: 'Keski-Pohjanmaa' },
    'FI-08': { defaultMessage: 'Keski-Suomi' },
    'FI-09': { defaultMessage: 'Kymenlaakso' },
    'FI-10': { defaultMessage: 'Lappi' },
    'FI-11': { defaultMessage: 'Pirkanmaa' },
    'FI-12': { defaultMessage: 'Pohjanmaa' },
    'FI-13': { defaultMessage: 'Pohjois-Karjala' },
    'FI-14': { defaultMessage: 'Pohjois-Pohjanmaa' },
    'FI-15': { defaultMessage: 'Pohjois-Savo' },
    'FI-16': { defaultMessage: 'Päijät-Häme' },
    'FI-17': { defaultMessage: 'Satakunta' },
    'FI-18': { defaultMessage: 'Uusimaa' },
    'FI-19': { defaultMessage: 'Varsinais-Suomi' },
    'FJ-01': { defaultMessage: 'Ba' },
    'FJ-02': { defaultMessage: 'Bua' },
    'FJ-03': { defaultMessage: 'Cakaudrove' },
    'FJ-04': { defaultMessage: 'Kadavu' },
    'FJ-05': { defaultMessage: 'Lau' },
    'FJ-06': { defaultMessage: 'Lomaiviti' },
    'FJ-07': { defaultMessage: 'Macuata' },
    'FJ-08': { defaultMessage: 'Nadroga and Navosa' },
    'FJ-09': { defaultMessage: 'Naitasiri' },
    'FJ-10': { defaultMessage: 'Namosi' },
    'FJ-11': { defaultMessage: 'Ra' },
    'FJ-12': { defaultMessage: 'Rewa' },
    'FJ-13': { defaultMessage: 'Serua' },
    'FJ-14': { defaultMessage: 'Tailevu' },
    'FJ-C': { defaultMessage: 'Central' },
    'FJ-E': { defaultMessage: 'Eastern' },
    'FJ-N': { defaultMessage: 'Northern' },
    'FJ-R': { defaultMessage: 'Rotuma' },
    'FJ-W': { defaultMessage: 'Western' },
    'FM-KSA': { defaultMessage: 'Kosrae' },
    'FM-PNI': { defaultMessage: 'Pohnpei' },
    'FM-TRK': { defaultMessage: 'Chuuk' },
    'FM-YAP': { defaultMessage: 'Yap' },
    'FR-01': { defaultMessage: 'Ain' },
    'FR-02': { defaultMessage: 'Aisne' },
    'FR-03': { defaultMessage: 'Allier' },
    'FR-04': { defaultMessage: 'Alpes-de-Haute-Provence' },
    'FR-05': { defaultMessage: 'Hautes-Alpes' },
    'FR-06': { defaultMessage: 'Alpes-Maritimes' },
    'FR-07': { defaultMessage: 'Ardèche' },
    'FR-08': { defaultMessage: 'Ardennes' },
    'FR-09': { defaultMessage: 'Ariège' },
    'FR-10': { defaultMessage: 'Aube' },
    'FR-11': { defaultMessage: 'Aude' },
    'FR-12': { defaultMessage: 'Aveyron' },
    'FR-13': { defaultMessage: 'Bouches-du-Rhône' },
    'FR-14': { defaultMessage: 'Calvados' },
    'FR-15': { defaultMessage: 'Cantal' },
    'FR-16': { defaultMessage: 'Charente' },
    'FR-17': { defaultMessage: 'Charente-Maritime' },
    'FR-18': { defaultMessage: 'Cher' },
    'FR-19': { defaultMessage: 'Corrèze' },
    'FR-20R': { defaultMessage: 'Corse' },
    'FR-21': { defaultMessage: "Côte-d'Or" },
    'FR-22': { defaultMessage: "Côtes-d'Armor" },
    'FR-23': { defaultMessage: 'Creuse' },
    'FR-24': { defaultMessage: 'Dordogne' },
    'FR-25': { defaultMessage: 'Doubs' },
    'FR-26': { defaultMessage: 'Drôme' },
    'FR-27': { defaultMessage: 'Eure' },
    'FR-28': { defaultMessage: 'Eure-et-Loir' },
    'FR-29': { defaultMessage: 'Finistère' },
    'FR-2A': { defaultMessage: 'Corse-du-Sud' },
    'FR-2B': { defaultMessage: 'Haute-Corse' },
    'FR-30': { defaultMessage: 'Gard' },
    'FR-31': { defaultMessage: 'Haute-Garonne' },
    'FR-32': { defaultMessage: 'Gers' },
    'FR-33': { defaultMessage: 'Gironde' },
    'FR-34': { defaultMessage: 'Hérault' },
    'FR-35': { defaultMessage: 'Ille-et-Vilaine' },
    'FR-36': { defaultMessage: 'Indre' },
    'FR-37': { defaultMessage: 'Indre-et-Loire' },
    'FR-38': { defaultMessage: 'Isère' },
    'FR-39': { defaultMessage: 'Jura' },
    'FR-40': { defaultMessage: 'Landes' },
    'FR-41': { defaultMessage: 'Loir-et-Cher' },
    'FR-42': { defaultMessage: 'Loire' },
    'FR-43': { defaultMessage: 'Haute-Loire' },
    'FR-44': { defaultMessage: 'Loire-Atlantique' },
    'FR-45': { defaultMessage: 'Loiret' },
    'FR-46': { defaultMessage: 'Lot' },
    'FR-47': { defaultMessage: 'Lot-et-Garonne' },
    'FR-48': { defaultMessage: 'Lozère' },
    'FR-49': { defaultMessage: 'Maine-et-Loire' },
    'FR-50': { defaultMessage: 'Manche' },
    'FR-51': { defaultMessage: 'Marne' },
    'FR-52': { defaultMessage: 'Haute-Marne' },
    'FR-53': { defaultMessage: 'Mayenne' },
    'FR-54': { defaultMessage: 'Meurthe-et-Moselle' },
    'FR-55': { defaultMessage: 'Meuse' },
    'FR-56': { defaultMessage: 'Morbihan' },
    'FR-57': { defaultMessage: 'Moselle' },
    'FR-58': { defaultMessage: 'Nièvre' },
    'FR-59': { defaultMessage: 'Nord' },
    'FR-60': { defaultMessage: 'Oise' },
    'FR-61': { defaultMessage: 'Orne' },
    'FR-62': { defaultMessage: 'Pas-de-Calais' },
    'FR-63': { defaultMessage: 'Puy-de-Dôme' },
    'FR-64': { defaultMessage: 'Pyrénées-Atlantiques' },
    'FR-65': { defaultMessage: 'Hautes-Pyrénées' },
    'FR-66': { defaultMessage: 'Pyrénées-Orientales' },
    'FR-67': { defaultMessage: 'Bas-Rhin' },
    'FR-68': { defaultMessage: 'Haut-Rhin' },
    'FR-69': { defaultMessage: 'Rhône' },
    'FR-70': { defaultMessage: 'Haute-Saône' },
    'FR-71': { defaultMessage: 'Saône-et-Loire' },
    'FR-72': { defaultMessage: 'Sarthe' },
    'FR-73': { defaultMessage: 'Savoie' },
    'FR-74': { defaultMessage: 'Haute-Savoie' },
    'FR-75': { defaultMessage: 'Paris' },
    'FR-76': { defaultMessage: 'Seine-Maritime' },
    'FR-77': { defaultMessage: 'Seine-et-Marne' },
    'FR-78': { defaultMessage: 'Yvelines' },
    'FR-79': { defaultMessage: 'Deux-Sèvres' },
    'FR-80': { defaultMessage: 'Somme' },
    'FR-81': { defaultMessage: 'Tarn' },
    'FR-82': { defaultMessage: 'Tarn-et-Garonne' },
    'FR-83': { defaultMessage: 'Var' },
    'FR-84': { defaultMessage: 'Vaucluse' },
    'FR-85': { defaultMessage: 'Vendée' },
    'FR-86': { defaultMessage: 'Vienne' },
    'FR-87': { defaultMessage: 'Haute-Vienne' },
    'FR-88': { defaultMessage: 'Vosges' },
    'FR-89': { defaultMessage: 'Yonne' },
    'FR-90': { defaultMessage: 'Territoire de Belfort' },
    'FR-91': { defaultMessage: 'Essonne' },
    'FR-92': { defaultMessage: 'Hauts-de-Seine' },
    'FR-93': { defaultMessage: 'Seine-Saint-Denis' },
    'FR-94': { defaultMessage: 'Val-de-Marne' },
    'FR-95': { defaultMessage: "Val-d'Oise" },
    'FR-971': { defaultMessage: 'Guadeloupe' },
    'FR-972': { defaultMessage: 'Martinique' },
    'FR-973': { defaultMessage: 'Guyane (française)' },
    'FR-974': { defaultMessage: 'La Réunion' },
    'FR-976': { defaultMessage: 'Mayotte' },
    'FR-ARA': { defaultMessage: 'Auvergne-Rhône-Alpes' },
    'FR-BFC': { defaultMessage: 'Bourgogne-Franche-Comté' },
    'FR-BL': { defaultMessage: 'Saint-Barthélemy' },
    'FR-BRE': { defaultMessage: 'Bretagne' },
    'FR-CP': { defaultMessage: 'Clipperton' },
    'FR-CVL': { defaultMessage: 'Centre-Val de Loire' },
    'FR-GES': { defaultMessage: 'Grand-Est' },
    'FR-GF': { defaultMessage: 'Guyane (française)' },
    'FR-GP': { defaultMessage: 'Guadeloupe' },
    'FR-HDF': { defaultMessage: 'Hauts-de-France' },
    'FR-IDF': { defaultMessage: 'Île-de-France' },
    'FR-MF': { defaultMessage: 'Saint-Martin' },
    'FR-MQ': { defaultMessage: 'Martinique' },
    'FR-NAQ': { defaultMessage: 'Nouvelle-Aquitaine' },
    'FR-NC': { defaultMessage: 'Nouvelle-Calédonie' },
    'FR-NOR': { defaultMessage: 'Normandie' },
    'FR-OCC': { defaultMessage: 'Occitanie' },
    'FR-PAC': { defaultMessage: 'Provence-Alpes-Côte-d’Azur' },
    'FR-PDL': { defaultMessage: 'Pays-de-la-Loire' },
    'FR-PF': { defaultMessage: 'Polynésie française' },
    'FR-PM': { defaultMessage: 'Saint-Pierre-et-Miquelon' },
    'FR-RE': { defaultMessage: 'La Réunion' },
    'FR-TF': { defaultMessage: 'Terres australes françaises' },
    'FR-WF': { defaultMessage: 'Wallis-et-Futuna' },
    'FR-YT': { defaultMessage: 'Mayotte' },
    'GA-1': { defaultMessage: 'Estuaire' },
    'GA-2': { defaultMessage: 'Haut-Ogooué' },
    'GA-3': { defaultMessage: 'Moyen-Ogooué' },
    'GA-4': { defaultMessage: 'Ngounié' },
    'GA-5': { defaultMessage: 'Nyanga' },
    'GA-6': { defaultMessage: 'Ogooué-Ivindo' },
    'GA-7': { defaultMessage: 'Ogooué-Lolo' },
    'GA-8': { defaultMessage: 'Ogooué-Maritime' },
    'GA-9': { defaultMessage: 'Woleu-Ntem' },
    'GB-ABC': { defaultMessage: 'Armagh City, Banbridge and Craigavon' },
    'GB-ABD': { defaultMessage: 'Aberdeenshire' },
    'GB-ABE': { defaultMessage: 'Aberdeen City' },
    'GB-AGB': { defaultMessage: 'Argyll and Bute' },
    'GB-AGY': { defaultMessage: 'Isle of Anglesey' },
    'GB-AND': { defaultMessage: 'Ards and North Down' },
    'GB-ANN': { defaultMessage: 'Antrim and Newtownabbey' },
    'GB-ANS': { defaultMessage: 'Angus' },
    'GB-BAS': { defaultMessage: 'Bath and North East Somerset' },
    'GB-BBD': { defaultMessage: 'Blackburn with Darwen' },
    'GB-BCP': { defaultMessage: 'Bournemouth, Christchurch and Poole' },
    'GB-BDF': { defaultMessage: 'Bedford' },
    'GB-BDG': { defaultMessage: 'Barking and Dagenham' },
    'GB-BEN': { defaultMessage: 'Brent' },
    'GB-BEX': { defaultMessage: 'Bexley' },
    'GB-BFS': { defaultMessage: 'Belfast City' },
    'GB-BGE': { defaultMessage: 'Bridgend' },
    'GB-BGW': { defaultMessage: 'Blaenau Gwent' },
    'GB-BIR': { defaultMessage: 'Birmingham' },
    'GB-BKM': { defaultMessage: 'Buckinghamshire' },
    'GB-BNE': { defaultMessage: 'Barnet' },
    'GB-BNH': { defaultMessage: 'Brighton and Hove' },
    'GB-BNS': { defaultMessage: 'Barnsley' },
    'GB-BOL': { defaultMessage: 'Bolton' },
    'GB-BPL': { defaultMessage: 'Blackpool' },
    'GB-BRC': { defaultMessage: 'Bracknell Forest' },
    'GB-BRD': { defaultMessage: 'Bradford' },
    'GB-BRY': { defaultMessage: 'Bromley' },
    'GB-BST': { defaultMessage: 'Bristol, City of' },
    'GB-BUR': { defaultMessage: 'Bury' },
    'GB-CAM': { defaultMessage: 'Cambridgeshire' },
    'GB-CAY': { defaultMessage: 'Caerphilly' },
    'GB-CBF': { defaultMessage: 'Central Bedfordshire' },
    'GB-CCG': { defaultMessage: 'Causeway Coast and Glens' },
    'GB-CGN': { defaultMessage: 'Ceredigion' },
    'GB-CHE': { defaultMessage: 'Cheshire East' },
    'GB-CHW': { defaultMessage: 'Cheshire West and Chester' },
    'GB-CLD': { defaultMessage: 'Calderdale' },
    'GB-CLK': { defaultMessage: 'Clackmannanshire' },
    'GB-CMA': { defaultMessage: 'Cumbria' },
    'GB-CMD': { defaultMessage: 'Camden' },
    'GB-CMN': { defaultMessage: 'Carmarthenshire' },
    'GB-CON': { defaultMessage: 'Cornwall' },
    'GB-COV': { defaultMessage: 'Coventry' },
    'GB-CRF': { defaultMessage: 'Cardiff' },
    'GB-CRY': { defaultMessage: 'Croydon' },
    'GB-CWY': { defaultMessage: 'Conwy' },
    'GB-DAL': { defaultMessage: 'Darlington' },
    'GB-DBY': { defaultMessage: 'Derbyshire' },
    'GB-DEN': { defaultMessage: 'Denbighshire' },
    'GB-DER': { defaultMessage: 'Derby' },
    'GB-DEV': { defaultMessage: 'Devon' },
    'GB-DGY': { defaultMessage: 'Dumfries and Galloway' },
    'GB-DNC': { defaultMessage: 'Doncaster' },
    'GB-DND': { defaultMessage: 'Dundee City' },
    'GB-DOR': { defaultMessage: 'Dorset' },
    'GB-DRS': { defaultMessage: 'Derry and Strabane' },
    'GB-DUD': { defaultMessage: 'Dudley' },
    'GB-DUR': { defaultMessage: 'Durham, County' },
    'GB-EAL': { defaultMessage: 'Ealing' },
    'GB-EAW': { defaultMessage: 'England and Wales' },
    'GB-EAY': { defaultMessage: 'East Ayrshire' },
    'GB-EDH': { defaultMessage: 'Edinburgh, City of' },
    'GB-EDU': { defaultMessage: 'East Dunbartonshire' },
    'GB-ELN': { defaultMessage: 'East Lothian' },
    'GB-ELS': { defaultMessage: 'Eilean Siar' },
    'GB-ENF': { defaultMessage: 'Enfield' },
    'GB-ENG': { defaultMessage: 'England' },
    'GB-ERW': { defaultMessage: 'East Renfrewshire' },
    'GB-ERY': { defaultMessage: 'East Riding of Yorkshire' },
    'GB-ESS': { defaultMessage: 'Essex' },
    'GB-ESX': { defaultMessage: 'East Sussex' },
    'GB-FAL': { defaultMessage: 'Falkirk' },
    'GB-FIF': { defaultMessage: 'Fife' },
    'GB-FLN': { defaultMessage: 'Flintshire' },
    'GB-FMO': { defaultMessage: 'Fermanagh and Omagh' },
    'GB-GAT': { defaultMessage: 'Gateshead' },
    'GB-GBN': { defaultMessage: 'Great Britain' },
    'GB-GLG': { defaultMessage: 'Glasgow City' },
    'GB-GLS': { defaultMessage: 'Gloucestershire' },
    'GB-GRE': { defaultMessage: 'Greenwich' },
    'GB-GWN': { defaultMessage: 'Gwynedd' },
    'GB-HAL': { defaultMessage: 'Halton' },
    'GB-HAM': { defaultMessage: 'Hampshire' },
    'GB-HAV': { defaultMessage: 'Havering' },
    'GB-HCK': { defaultMessage: 'Hackney' },
    'GB-HEF': { defaultMessage: 'Herefordshire' },
    'GB-HIL': { defaultMessage: 'Hillingdon' },
    'GB-HLD': { defaultMessage: 'Highland' },
    'GB-HMF': { defaultMessage: 'Hammersmith and Fulham' },
    'GB-HNS': { defaultMessage: 'Hounslow' },
    'GB-HPL': { defaultMessage: 'Hartlepool' },
    'GB-HRT': { defaultMessage: 'Hertfordshire' },
    'GB-HRW': { defaultMessage: 'Harrow' },
    'GB-HRY': { defaultMessage: 'Haringey' },
    'GB-IOS': { defaultMessage: 'Isles of Scilly' },
    'GB-IOW': { defaultMessage: 'Isle of Wight' },
    'GB-ISL': { defaultMessage: 'Islington' },
    'GB-IVC': { defaultMessage: 'Inverclyde' },
    'GB-KEC': { defaultMessage: 'Kensington and Chelsea' },
    'GB-KEN': { defaultMessage: 'Kent' },
    'GB-KHL': { defaultMessage: 'Kingston upon Hull' },
    'GB-KIR': { defaultMessage: 'Kirklees' },
    'GB-KTT': { defaultMessage: 'Kingston upon Thames' },
    'GB-KWL': { defaultMessage: 'Knowsley' },
    'GB-LAN': { defaultMessage: 'Lancashire' },
    'GB-LBC': { defaultMessage: 'Lisburn and Castlereagh' },
    'GB-LBH': { defaultMessage: 'Lambeth' },
    'GB-LCE': { defaultMessage: 'Leicester' },
    'GB-LDS': { defaultMessage: 'Leeds' },
    'GB-LEC': { defaultMessage: 'Leicestershire' },
    'GB-LEW': { defaultMessage: 'Lewisham' },
    'GB-LIN': { defaultMessage: 'Lincolnshire' },
    'GB-LIV': { defaultMessage: 'Liverpool' },
    'GB-LND': { defaultMessage: 'London, City of' },
    'GB-LUT': { defaultMessage: 'Luton' },
    'GB-MAN': { defaultMessage: 'Manchester' },
    'GB-MDB': { defaultMessage: 'Middlesbrough' },
    'GB-MDW': { defaultMessage: 'Medway' },
    'GB-MEA': { defaultMessage: 'Mid and East Antrim' },
    'GB-MIK': { defaultMessage: 'Milton Keynes' },
    'GB-MLN': { defaultMessage: 'Midlothian' },
    'GB-MON': { defaultMessage: 'Monmouthshire' },
    'GB-MRT': { defaultMessage: 'Merton' },
    'GB-MRY': { defaultMessage: 'Moray' },
    'GB-MTY': { defaultMessage: 'Merthyr Tydfil' },
    'GB-MUL': { defaultMessage: 'Mid-Ulster' },
    'GB-NAY': { defaultMessage: 'North Ayrshire' },
    'GB-NBL': { defaultMessage: 'Northumberland' },
    'GB-NEL': { defaultMessage: 'North East Lincolnshire' },
    'GB-NET': { defaultMessage: 'Newcastle upon Tyne' },
    'GB-NFK': { defaultMessage: 'Norfolk' },
    'GB-NGM': { defaultMessage: 'Nottingham' },
    'GB-NIR': { defaultMessage: 'Northern Ireland' },
    'GB-NLK': { defaultMessage: 'North Lanarkshire' },
    'GB-NLN': { defaultMessage: 'North Lincolnshire' },
    'GB-NMD': { defaultMessage: 'Newry, Mourne and Down' },
    'GB-NSM': { defaultMessage: 'North Somerset' },
    'GB-NTH': { defaultMessage: 'Northamptonshire' },
    'GB-NTL': { defaultMessage: 'Neath Port Talbot' },
    'GB-NTT': { defaultMessage: 'Nottinghamshire' },
    'GB-NTY': { defaultMessage: 'North Tyneside' },
    'GB-NWM': { defaultMessage: 'Newham' },
    'GB-NWP': { defaultMessage: 'Newport' },
    'GB-NYK': { defaultMessage: 'North Yorkshire' },
    'GB-OLD': { defaultMessage: 'Oldham' },
    'GB-ORK': { defaultMessage: 'Orkney Islands' },
    'GB-OXF': { defaultMessage: 'Oxfordshire' },
    'GB-PEM': { defaultMessage: 'Pembrokeshire' },
    'GB-PKN': { defaultMessage: 'Perth and Kinross' },
    'GB-PLY': { defaultMessage: 'Plymouth' },
    'GB-POR': { defaultMessage: 'Portsmouth' },
    'GB-POW': { defaultMessage: 'Powys' },
    'GB-PTE': { defaultMessage: 'Peterborough' },
    'GB-RCC': { defaultMessage: 'Redcar and Cleveland' },
    'GB-RCH': { defaultMessage: 'Rochdale' },
    'GB-RCT': { defaultMessage: 'Rhondda Cynon Taff' },
    'GB-RDB': { defaultMessage: 'Redbridge' },
    'GB-RDG': { defaultMessage: 'Reading' },
    'GB-RFW': { defaultMessage: 'Renfrewshire' },
    'GB-RIC': { defaultMessage: 'Richmond upon Thames' },
    'GB-ROT': { defaultMessage: 'Rotherham' },
    'GB-RUT': { defaultMessage: 'Rutland' },
    'GB-SAW': { defaultMessage: 'Sandwell' },
    'GB-SAY': { defaultMessage: 'South Ayrshire' },
    'GB-SCB': { defaultMessage: 'Scottish Borders' },
    'GB-SCT': { defaultMessage: 'Scotland' },
    'GB-SFK': { defaultMessage: 'Suffolk' },
    'GB-SFT': { defaultMessage: 'Sefton' },
    'GB-SGC': { defaultMessage: 'South Gloucestershire' },
    'GB-SHF': { defaultMessage: 'Sheffield' },
    'GB-SHN': { defaultMessage: 'St. Helens' },
    'GB-SHR': { defaultMessage: 'Shropshire' },
    'GB-SKP': { defaultMessage: 'Stockport' },
    'GB-SLF': { defaultMessage: 'Salford' },
    'GB-SLG': { defaultMessage: 'Slough' },
    'GB-SLK': { defaultMessage: 'South Lanarkshire' },
    'GB-SND': { defaultMessage: 'Sunderland' },
    'GB-SOL': { defaultMessage: 'Solihull' },
    'GB-SOM': { defaultMessage: 'Somerset' },
    'GB-SOS': { defaultMessage: 'Southend-on-Sea' },
    'GB-SRY': { defaultMessage: 'Surrey' },
    'GB-STE': { defaultMessage: 'Stoke-on-Trent' },
    'GB-STG': { defaultMessage: 'Stirling' },
    'GB-STH': { defaultMessage: 'Southampton' },
    'GB-STN': { defaultMessage: 'Sutton' },
    'GB-STS': { defaultMessage: 'Staffordshire' },
    'GB-STT': { defaultMessage: 'Stockton-on-Tees' },
    'GB-STY': { defaultMessage: 'South Tyneside' },
    'GB-SWA': { defaultMessage: 'Swansea' },
    'GB-SWD': { defaultMessage: 'Swindon' },
    'GB-SWK': { defaultMessage: 'Southwark' },
    'GB-TAM': { defaultMessage: 'Tameside' },
    'GB-TFW': { defaultMessage: 'Telford and Wrekin' },
    'GB-THR': { defaultMessage: 'Thurrock' },
    'GB-TOB': { defaultMessage: 'Torbay' },
    'GB-TOF': { defaultMessage: 'Torfaen' },
    'GB-TRF': { defaultMessage: 'Trafford' },
    'GB-TWH': { defaultMessage: 'Tower Hamlets' },
    'GB-UKM': { defaultMessage: 'United Kingdom' },
    'GB-VGL': { defaultMessage: 'Vale of Glamorgan, The' },
    'GB-WAR': { defaultMessage: 'Warwickshire' },
    'GB-WBK': { defaultMessage: 'West Berkshire' },
    'GB-WDU': { defaultMessage: 'West Dunbartonshire' },
    'GB-WFT': { defaultMessage: 'Waltham Forest' },
    'GB-WGN': { defaultMessage: 'Wigan' },
    'GB-WIL': { defaultMessage: 'Wiltshire' },
    'GB-WKF': { defaultMessage: 'Wakefield' },
    'GB-WLL': { defaultMessage: 'Walsall' },
    'GB-WLN': { defaultMessage: 'West Lothian' },
    'GB-WLS': { defaultMessage: 'Wales' },
    'GB-WLV': { defaultMessage: 'Wolverhampton' },
    'GB-WND': { defaultMessage: 'Wandsworth' },
    'GB-WNM': { defaultMessage: 'Windsor and Maidenhead' },
    'GB-WOK': { defaultMessage: 'Wokingham' },
    'GB-WOR': { defaultMessage: 'Worcestershire' },
    'GB-WRL': { defaultMessage: 'Wirral' },
    'GB-WRT': { defaultMessage: 'Warrington' },
    'GB-WRX': { defaultMessage: 'Wrexham' },
    'GB-WSM': { defaultMessage: 'Westminster' },
    'GB-WSX': { defaultMessage: 'West Sussex' },
    'GB-YOR': { defaultMessage: 'York' },
    'GB-ZET': { defaultMessage: 'Shetland Islands' },
    'GD-01': { defaultMessage: 'Saint Andrew' },
    'GD-02': { defaultMessage: 'Saint David' },
    'GD-03': { defaultMessage: 'Saint George' },
    'GD-04': { defaultMessage: 'Saint John' },
    'GD-05': { defaultMessage: 'Saint Mark' },
    'GD-06': { defaultMessage: 'Saint Patrick' },
    'GD-10': { defaultMessage: 'Southern Grenadine Islands' },
    'GE-AB': { defaultMessage: 'Abkhazia' },
    'GE-AJ': { defaultMessage: 'Ajaria' },
    'GE-GU': { defaultMessage: 'Guria' },
    'GE-IM': { defaultMessage: 'Imereti' },
    'GE-KA': { defaultMessage: "K'akheti" },
    'GE-KK': { defaultMessage: 'Kvemo Kartli' },
    'GE-MM': { defaultMessage: 'Mtskheta-Mtianeti' },
    'GE-RL': { defaultMessage: "Rach'a-Lechkhumi-Kvemo Svaneti" },
    'GE-SJ': { defaultMessage: 'Samtskhe-Javakheti' },
    'GE-SK': { defaultMessage: 'Shida Kartli' },
    'GE-SZ': { defaultMessage: 'Samegrelo-Zemo Svaneti' },
    'GE-TB': { defaultMessage: 'Tbilisi' },
    'GH-AA': { defaultMessage: 'Greater Accra' },
    'GH-AF': { defaultMessage: 'Ahafo' },
    'GH-AH': { defaultMessage: 'Ashanti' },
    'GH-BA': { defaultMessage: 'Brong-Ahafo' },
    'GH-BE': { defaultMessage: 'Bono East' },
    'GH-BO': { defaultMessage: 'Bono' },
    'GH-CP': { defaultMessage: 'Central' },
    'GH-EP': { defaultMessage: 'Eastern' },
    'GH-NE': { defaultMessage: 'North East' },
    'GH-NP': { defaultMessage: 'Northern' },
    'GH-OT': { defaultMessage: 'Oti' },
    'GH-SV': { defaultMessage: 'Savannah' },
    'GH-TV': { defaultMessage: 'Volta' },
    'GH-UE': { defaultMessage: 'Upper East' },
    'GH-UW': { defaultMessage: 'Upper West' },
    'GH-WN': { defaultMessage: 'Western North' },
    'GH-WP': { defaultMessage: 'Western' },
    'GL-AV': { defaultMessage: 'Avannaata Kommunia' },
    'GL-KU': { defaultMessage: 'Kommune Kujalleq' },
    'GL-QE': { defaultMessage: 'Qeqqata Kommunia' },
    'GL-QT': { defaultMessage: 'Kommune Qeqertalik' },
    'GL-SM': { defaultMessage: 'Kommuneqarfik Sermersooq' },
    'GM-B': { defaultMessage: 'Banjul' },
    'GM-L': { defaultMessage: 'Lower River' },
    'GM-M': { defaultMessage: 'Central River' },
    'GM-N': { defaultMessage: 'North Bank' },
    'GM-U': { defaultMessage: 'Upper River' },
    'GM-W': { defaultMessage: 'Western' },
    'GN-B': { defaultMessage: 'Boké' },
    'GN-BE': { defaultMessage: 'Beyla' },
    'GN-BF': { defaultMessage: 'Boffa' },
    'GN-BK': { defaultMessage: 'Boké' },
    'GN-C': { defaultMessage: 'Conakry' },
    'GN-CO': { defaultMessage: 'Coyah' },
    'GN-D': { defaultMessage: 'Kindia' },
    'GN-DB': { defaultMessage: 'Dabola' },
    'GN-DI': { defaultMessage: 'Dinguiraye' },
    'GN-DL': { defaultMessage: 'Dalaba' },
    'GN-DU': { defaultMessage: 'Dubréka' },
    'GN-F': { defaultMessage: 'Faranah' },
    'GN-FA': { defaultMessage: 'Faranah' },
    'GN-FO': { defaultMessage: 'Forécariah' },
    'GN-FR': { defaultMessage: 'Fria' },
    'GN-GA': { defaultMessage: 'Gaoual' },
    'GN-GU': { defaultMessage: 'Guékédou' },
    'GN-K': { defaultMessage: 'Kankan' },
    'GN-KA': { defaultMessage: 'Kankan' },
    'GN-KB': { defaultMessage: 'Koubia' },
    'GN-KD': { defaultMessage: 'Kindia' },
    'GN-KE': { defaultMessage: 'Kérouané' },
    'GN-KN': { defaultMessage: 'Koundara' },
    'GN-KO': { defaultMessage: 'Kouroussa' },
    'GN-KS': { defaultMessage: 'Kissidougou' },
    'GN-L': { defaultMessage: 'Labé' },
    'GN-LA': { defaultMessage: 'Labé' },
    'GN-LE': { defaultMessage: 'Lélouma' },
    'GN-LO': { defaultMessage: 'Lola' },
    'GN-M': { defaultMessage: 'Mamou' },
    'GN-MC': { defaultMessage: 'Macenta' },
    'GN-MD': { defaultMessage: 'Mandiana' },
    'GN-ML': { defaultMessage: 'Mali' },
    'GN-MM': { defaultMessage: 'Mamou' },
    'GN-N': { defaultMessage: 'Nzérékoré' },
    'GN-NZ': { defaultMessage: 'Nzérékoré' },
    'GN-PI': { defaultMessage: 'Pita' },
    'GN-SI': { defaultMessage: 'Siguiri' },
    'GN-TE': { defaultMessage: 'Télimélé' },
    'GN-TO': { defaultMessage: 'Tougué' },
    'GN-YO': { defaultMessage: 'Yomou' },
    'GQ-AN': { defaultMessage: 'Annobón' },
    'GQ-BN': { defaultMessage: 'Bioko Norte' },
    'GQ-BS': { defaultMessage: 'Bioko Sur' },
    'GQ-C': { defaultMessage: 'Región Continental' },
    'GQ-CS': { defaultMessage: 'Centro Sur' },
    'GQ-DJ': { defaultMessage: 'Djibloho' },
    'GQ-I': { defaultMessage: 'Región Insular' },
    'GQ-KN': { defaultMessage: 'Kié-Ntem' },
    'GQ-LI': { defaultMessage: 'Litoral' },
    'GQ-WN': { defaultMessage: 'Wele-Nzas' },
    'GR-69': { defaultMessage: 'Ágion Óros' },
    'GR-A': { defaultMessage: 'Anatolikí Makedonía kai Thráki' },
    'GR-B': { defaultMessage: 'Kentrikí Makedonía' },
    'GR-C': { defaultMessage: 'Dytikí Makedonía' },
    'GR-D': { defaultMessage: 'Ípeiros' },
    'GR-E': { defaultMessage: 'Thessalía' },
    'GR-F': { defaultMessage: 'Ionía Nísia' },
    'GR-G': { defaultMessage: 'Dytikí Elláda' },
    'GR-H': { defaultMessage: 'Stereá Elláda' },
    'GR-I': { defaultMessage: 'Attikí' },
    'GR-J': { defaultMessage: 'Pelopónnisos' },
    'GR-K': { defaultMessage: 'Vóreio Aigaío' },
    'GR-L': { defaultMessage: 'Nótio Aigaío' },
    'GR-M': { defaultMessage: 'Kríti' },
    'GT-AV': { defaultMessage: 'Alta Verapaz' },
    'GT-BV': { defaultMessage: 'Baja Verapaz' },
    'GT-CM': { defaultMessage: 'Chimaltenango' },
    'GT-CQ': { defaultMessage: 'Chiquimula' },
    'GT-ES': { defaultMessage: 'Escuintla' },
    'GT-GU': { defaultMessage: 'Guatemala' },
    'GT-HU': { defaultMessage: 'Huehuetenango' },
    'GT-IZ': { defaultMessage: 'Izabal' },
    'GT-JA': { defaultMessage: 'Jalapa' },
    'GT-JU': { defaultMessage: 'Jutiapa' },
    'GT-PE': { defaultMessage: 'Petén' },
    'GT-PR': { defaultMessage: 'El Progreso' },
    'GT-QC': { defaultMessage: 'Quiché' },
    'GT-QZ': { defaultMessage: 'Quetzaltenango' },
    'GT-RE': { defaultMessage: 'Retalhuleu' },
    'GT-SA': { defaultMessage: 'Sacatepéquez' },
    'GT-SM': { defaultMessage: 'San Marcos' },
    'GT-SO': { defaultMessage: 'Sololá' },
    'GT-SR': { defaultMessage: 'Santa Rosa' },
    'GT-SU': { defaultMessage: 'Suchitepéquez' },
    'GT-TO': { defaultMessage: 'Totonicapán' },
    'GT-ZA': { defaultMessage: 'Zacapa' },
    'GW-BA': { defaultMessage: 'Bafatá' },
    'GW-BL': { defaultMessage: 'Bolama / Bijagós' },
    'GW-BM': { defaultMessage: 'Biombo' },
    'GW-BS': { defaultMessage: 'Bissau' },
    'GW-CA': { defaultMessage: 'Cacheu' },
    'GW-GA': { defaultMessage: 'Gabú' },
    'GW-L': { defaultMessage: 'Leste' },
    'GW-N': { defaultMessage: 'Norte' },
    'GW-OI': { defaultMessage: 'Oio' },
    'GW-QU': { defaultMessage: 'Quinara' },
    'GW-S': { defaultMessage: 'Sul' },
    'GW-TO': { defaultMessage: 'Tombali' },
    'GY-BA': { defaultMessage: 'Barima-Waini' },
    'GY-CU': { defaultMessage: 'Cuyuni-Mazaruni' },
    'GY-DE': { defaultMessage: 'Demerara-Mahaica' },
    'GY-EB': { defaultMessage: 'East Berbice-Corentyne' },
    'GY-ES': { defaultMessage: 'Essequibo Islands-West Demerara' },
    'GY-MA': { defaultMessage: 'Mahaica-Berbice' },
    'GY-PM': { defaultMessage: 'Pomeroon-Supenaam' },
    'GY-PT': { defaultMessage: 'Potaro-Siparuni' },
    'GY-UD': { defaultMessage: 'Upper Demerara-Berbice' },
    'GY-UT': { defaultMessage: 'Upper Takutu-Upper Essequibo' },
    'HN-AT': { defaultMessage: 'Atlántida' },
    'HN-CH': { defaultMessage: 'Choluteca' },
    'HN-CL': { defaultMessage: 'Colón' },
    'HN-CM': { defaultMessage: 'Comayagua' },
    'HN-CP': { defaultMessage: 'Copán' },
    'HN-CR': { defaultMessage: 'Cortés' },
    'HN-EP': { defaultMessage: 'El Paraíso' },
    'HN-FM': { defaultMessage: 'Francisco Morazán' },
    'HN-GD': { defaultMessage: 'Gracias a Dios' },
    'HN-IB': { defaultMessage: 'Islas de la Bahía' },
    'HN-IN': { defaultMessage: 'Intibucá' },
    'HN-LE': { defaultMessage: 'Lempira' },
    'HN-LP': { defaultMessage: 'La Paz' },
    'HN-OC': { defaultMessage: 'Ocotepeque' },
    'HN-OL': { defaultMessage: 'Olancho' },
    'HN-SB': { defaultMessage: 'Santa Bárbara' },
    'HN-VA': { defaultMessage: 'Valle' },
    'HN-YO': { defaultMessage: 'Yoro' },
    'HR-01': { defaultMessage: 'Zagrebačka županija' },
    'HR-02': { defaultMessage: 'Krapinsko-zagorska županija' },
    'HR-03': { defaultMessage: 'Sisačko-moslavačka županija' },
    'HR-04': { defaultMessage: 'Karlovačka županija' },
    'HR-05': { defaultMessage: 'Varaždinska županija' },
    'HR-06': { defaultMessage: 'Koprivničko-križevačka županija' },
    'HR-07': { defaultMessage: 'Bjelovarsko-bilogorska županija' },
    'HR-08': { defaultMessage: 'Primorsko-goranska županija' },
    'HR-09': { defaultMessage: 'Ličko-senjska županija' },
    'HR-10': { defaultMessage: 'Virovitičko-podravska županija' },
    'HR-11': { defaultMessage: 'Požeško-slavonska županija' },
    'HR-12': { defaultMessage: 'Brodsko-posavska županija' },
    'HR-13': { defaultMessage: 'Zadarska županija' },
    'HR-14': { defaultMessage: 'Osječko-baranjska županija' },
    'HR-15': { defaultMessage: 'Šibensko-kninska županija' },
    'HR-16': { defaultMessage: 'Vukovarsko-srijemska županija' },
    'HR-17': { defaultMessage: 'Splitsko-dalmatinska županija' },
    'HR-18': { defaultMessage: 'Istarska županija' },
    'HR-19': { defaultMessage: 'Dubrovačko-neretvanska županija' },
    'HR-20': { defaultMessage: 'Međimurska županija' },
    'HR-21': { defaultMessage: 'Grad Zagreb' },
    'HT-AR': { defaultMessage: 'Artibonite' },
    'HT-CE': { defaultMessage: 'Centre' },
    'HT-GA': { defaultMessage: 'Grande’Anse' },
    'HT-ND': { defaultMessage: 'Nord' },
    'HT-NE': { defaultMessage: 'Nord-Est' },
    'HT-NI': { defaultMessage: 'Nippes' },
    'HT-NO': { defaultMessage: 'Nord-Ouest' },
    'HT-OU': { defaultMessage: 'Ouest' },
    'HT-SD': { defaultMessage: 'Sud' },
    'HT-SE': { defaultMessage: 'Sud-Est' },
    'HU-BA': { defaultMessage: 'Baranya' },
    'HU-BC': { defaultMessage: 'Békéscsaba' },
    'HU-BE': { defaultMessage: 'Békés' },
    'HU-BK': { defaultMessage: 'Bács-Kiskun' },
    'HU-BU': { defaultMessage: 'Budapest' },
    'HU-BZ': { defaultMessage: 'Borsod-Abaúj-Zemplén' },
    'HU-CS': { defaultMessage: 'Csongrád' },
    'HU-DE': { defaultMessage: 'Debrecen' },
    'HU-DU': { defaultMessage: 'Dunaújváros' },
    'HU-EG': { defaultMessage: 'Eger' },
    'HU-ER': { defaultMessage: 'Érd' },
    'HU-FE': { defaultMessage: 'Fejér' },
    'HU-GS': { defaultMessage: 'Győr-Moson-Sopron' },
    'HU-GY': { defaultMessage: 'Győr' },
    'HU-HB': { defaultMessage: 'Hajdú-Bihar' },
    'HU-HE': { defaultMessage: 'Heves' },
    'HU-HV': { defaultMessage: 'Hódmezővásárhely' },
    'HU-JN': { defaultMessage: 'Jász-Nagykun-Szolnok' },
    'HU-KE': { defaultMessage: 'Komárom-Esztergom' },
    'HU-KM': { defaultMessage: 'Kecskemét' },
    'HU-KV': { defaultMessage: 'Kaposvár' },
    'HU-MI': { defaultMessage: 'Miskolc' },
    'HU-NK': { defaultMessage: 'Nagykanizsa' },
    'HU-NO': { defaultMessage: 'Nógrád' },
    'HU-NY': { defaultMessage: 'Nyíregyháza' },
    'HU-PE': { defaultMessage: 'Pest' },
    'HU-PS': { defaultMessage: 'Pécs' },
    'HU-SD': { defaultMessage: 'Szeged' },
    'HU-SF': { defaultMessage: 'Székesfehérvár' },
    'HU-SH': { defaultMessage: 'Szombathely' },
    'HU-SK': { defaultMessage: 'Szolnok' },
    'HU-SN': { defaultMessage: 'Sopron' },
    'HU-SO': { defaultMessage: 'Somogy' },
    'HU-SS': { defaultMessage: 'Szekszárd' },
    'HU-ST': { defaultMessage: 'Salgótarján' },
    'HU-SZ': { defaultMessage: 'Szabolcs-Szatmár-Bereg' },
    'HU-TB': { defaultMessage: 'Tatabánya' },
    'HU-TO': { defaultMessage: 'Tolna' },
    'HU-VA': { defaultMessage: 'Vas' },
    'HU-VE': { defaultMessage: 'Veszprém' },
    'HU-VM': { defaultMessage: 'Veszprém' },
    'HU-ZA': { defaultMessage: 'Zala' },
    'HU-ZE': { defaultMessage: 'Zalaegerszeg' },
    'ID-AC': { defaultMessage: 'Aceh' },
    'ID-BA': { defaultMessage: 'Bali' },
    'ID-BB': { defaultMessage: 'Kepulauan Bangka Belitung' },
    'ID-BE': { defaultMessage: 'Bengkulu' },
    'ID-BT': { defaultMessage: 'Banten' },
    'ID-GO': { defaultMessage: 'Gorontalo' },
    'ID-JA': { defaultMessage: 'Jambi' },
    'ID-JB': { defaultMessage: 'Jawa Barat' },
    'ID-JI': { defaultMessage: 'Jawa Timur' },
    'ID-JK': { defaultMessage: 'Jakarta Raya' },
    'ID-JT': { defaultMessage: 'Jawa Tengah' },
    'ID-JW': { defaultMessage: 'Jawa' },
    'ID-KA': { defaultMessage: 'Kalimantan' },
    'ID-KB': { defaultMessage: 'Kalimantan Barat' },
    'ID-KI': { defaultMessage: 'Kalimantan Timur' },
    'ID-KR': { defaultMessage: 'Kepulauan Riau' },
    'ID-KS': { defaultMessage: 'Kalimantan Selatan' },
    'ID-KT': { defaultMessage: 'Kalimantan Tengah' },
    'ID-KU': { defaultMessage: 'Kalimantan Utara' },
    'ID-LA': { defaultMessage: 'Lampung' },
    'ID-MA': { defaultMessage: 'Maluku' },
    'ID-ML': { defaultMessage: 'Maluku' },
    'ID-MU': { defaultMessage: 'Maluku Utara' },
    'ID-NB': { defaultMessage: 'Nusa Tenggara Barat' },
    'ID-NT': { defaultMessage: 'Nusa Tenggara Timur' },
    'ID-NU': { defaultMessage: 'Nusa Tenggara' },
    'ID-PA': { defaultMessage: 'Papua' },
    'ID-PB': { defaultMessage: 'Papua Barat' },
    'ID-PP': { defaultMessage: 'Papua' },
    'ID-RI': { defaultMessage: 'Riau' },
    'ID-SA': { defaultMessage: 'Sulawesi Utara' },
    'ID-SB': { defaultMessage: 'Sumatera Barat' },
    'ID-SG': { defaultMessage: 'Sulawesi Tenggara' },
    'ID-SL': { defaultMessage: 'Sulawesi' },
    'ID-SM': { defaultMessage: 'Sumatera' },
    'ID-SN': { defaultMessage: 'Sulawesi Selatan' },
    'ID-SR': { defaultMessage: 'Sulawesi Barat' },
    'ID-SS': { defaultMessage: 'Sumatera Selatan' },
    'ID-ST': { defaultMessage: 'Sulawesi Tengah' },
    'ID-SU': { defaultMessage: 'Sumatera Utara' },
    'ID-YO': { defaultMessage: 'Yogyakarta' },
    'IE-C': { defaultMessage: 'Connaught' },
    'IE-CE': { defaultMessage: 'Clare' },
    'IE-CN': { defaultMessage: 'Cavan' },
    'IE-CO': { defaultMessage: 'Cork' },
    'IE-CW': { defaultMessage: 'Carlow' },
    'IE-D': { defaultMessage: 'Dublin' },
    'IE-DL': { defaultMessage: 'Donegal' },
    'IE-G': { defaultMessage: 'Galway' },
    'IE-KE': { defaultMessage: 'Kildare' },
    'IE-KK': { defaultMessage: 'Kilkenny' },
    'IE-KY': { defaultMessage: 'Kerry' },
    'IE-L': { defaultMessage: 'Leinster' },
    'IE-LD': { defaultMessage: 'Longford' },
    'IE-LH': { defaultMessage: 'Louth' },
    'IE-LK': { defaultMessage: 'Limerick' },
    'IE-LM': { defaultMessage: 'Leitrim' },
    'IE-LS': { defaultMessage: 'Laois' },
    'IE-M': { defaultMessage: 'Munster' },
    'IE-MH': { defaultMessage: 'Meath' },
    'IE-MN': { defaultMessage: 'Monaghan' },
    'IE-MO': { defaultMessage: 'Mayo' },
    'IE-OY': { defaultMessage: 'Offaly' },
    'IE-RN': { defaultMessage: 'Roscommon' },
    'IE-SO': { defaultMessage: 'Sligo' },
    'IE-TA': { defaultMessage: 'Tipperary' },
    'IE-U': { defaultMessage: 'Ulster' },
    'IE-WD': { defaultMessage: 'Waterford' },
    'IE-WH': { defaultMessage: 'Westmeath' },
    'IE-WW': { defaultMessage: 'Wicklow' },
    'IE-WX': { defaultMessage: 'Wexford' },
    'IL-D': { defaultMessage: 'HaDarom' },
    'IL-HA': { defaultMessage: 'H̱efa' },
    'IL-JM': { defaultMessage: 'Yerushalayim' },
    'IL-M': { defaultMessage: 'HaMerkaz' },
    'IL-TA': { defaultMessage: 'Tel Aviv' },
    'IL-Z': { defaultMessage: 'HaTsafon' },
    'IN-AN': { defaultMessage: 'Andaman and Nicobar Islands' },
    'IN-AP': { defaultMessage: 'Andhra Pradesh' },
    'IN-AR': { defaultMessage: 'Arunāchal Pradesh' },
    'IN-AS': { defaultMessage: 'Assam' },
    'IN-BR': { defaultMessage: 'Bihār' },
    'IN-CH': { defaultMessage: 'Chandigarh' },
    'IN-CT': { defaultMessage: 'Chhattīsgarh' },
    'IN-DH': { defaultMessage: 'Dādra and Nagar Haveli and Damān and Diu' },
    'IN-DL': { defaultMessage: 'Delhi' },
    'IN-GA': { defaultMessage: 'Goa' },
    'IN-GJ': { defaultMessage: 'Gujarāt' },
    'IN-HP': { defaultMessage: 'Himāchal Pradesh' },
    'IN-HR': { defaultMessage: 'Haryāna' },
    'IN-JH': { defaultMessage: 'Jhārkhand' },
    'IN-JK': { defaultMessage: 'Jammu and Kashmīr' },
    'IN-KA': { defaultMessage: 'Karnātaka' },
    'IN-KL': { defaultMessage: 'Kerala' },
    'IN-LA': { defaultMessage: 'Ladākh' },
    'IN-LD': { defaultMessage: 'Lakshadweep' },
    'IN-MH': { defaultMessage: 'Mahārāshtra' },
    'IN-ML': { defaultMessage: 'Meghālaya' },
    'IN-MN': { defaultMessage: 'Manipur' },
    'IN-MP': { defaultMessage: 'Madhya Pradesh' },
    'IN-MZ': { defaultMessage: 'Mizoram' },
    'IN-NL': { defaultMessage: 'Nāgāland' },
    'IN-OR': { defaultMessage: 'Odisha' },
    'IN-PB': { defaultMessage: 'Punjab' },
    'IN-PY': { defaultMessage: 'Puducherry' },
    'IN-RJ': { defaultMessage: 'Rājasthān' },
    'IN-SK': { defaultMessage: 'Sikkim' },
    'IN-TG': { defaultMessage: 'Telangāna' },
    'IN-TN': { defaultMessage: 'Tamil Nādu' },
    'IN-TR': { defaultMessage: 'Tripura' },
    'IN-UP': { defaultMessage: 'Uttar Pradesh' },
    'IN-UT': { defaultMessage: 'Uttarākhand' },
    'IN-WB': { defaultMessage: 'West Bengal' },
    'IQ-AN': { defaultMessage: 'Al Anbār' },
    'IQ-AR': { defaultMessage: 'Arbīl' },
    'IQ-BA': { defaultMessage: 'Al Başrah' },
    'IQ-BB': { defaultMessage: 'Bābil' },
    'IQ-BG': { defaultMessage: 'Baghdād' },
    'IQ-DA': { defaultMessage: 'Dahūk' },
    'IQ-DI': { defaultMessage: 'Diyālá' },
    'IQ-DQ': { defaultMessage: 'Dhī Qār' },
    'IQ-HA': { defaultMessage: 'Halabja' },
    'IQ-KA': { defaultMessage: 'Karbalā’' },
    'IQ-KI': { defaultMessage: 'Kirkūk' },
    'IQ-MA': { defaultMessage: 'Maysān' },
    'IQ-MU': { defaultMessage: 'Al Muthanná' },
    'IQ-NA': { defaultMessage: 'An Najaf' },
    'IQ-NI': { defaultMessage: 'Nīnawá' },
    'IQ-QA': { defaultMessage: 'Al Qādisīyah' },
    'IQ-SD': { defaultMessage: 'Şalāḩ ad Dīn' },
    'IQ-SU': { defaultMessage: 'As Sulaymānīyah' },
    'IQ-WA': { defaultMessage: 'Wāsiţ' },
    'IR-00': { defaultMessage: 'Markazī' },
    'IR-01': { defaultMessage: 'Gīlān' },
    'IR-02': { defaultMessage: 'Māzandarān' },
    'IR-03': { defaultMessage: 'Āz̄ārbāyjān-e Shārqī' },
    'IR-04': { defaultMessage: 'Āz̄ārbāyjān-e Ghārbī' },
    'IR-05': { defaultMessage: 'Kermānshāh' },
    'IR-06': { defaultMessage: 'Khūzestān' },
    'IR-07': { defaultMessage: 'Fārs' },
    'IR-08': { defaultMessage: 'Kermān' },
    'IR-09': { defaultMessage: 'Khorāsān-e Raẕavī' },
    'IR-10': { defaultMessage: 'Eşfahān' },
    'IR-11': { defaultMessage: 'Sīstān va Balūchestān' },
    'IR-12': { defaultMessage: 'Kordestān' },
    'IR-13': { defaultMessage: 'Hamadān' },
    'IR-14': { defaultMessage: 'Chahār Maḩāl va Bakhtīārī' },
    'IR-15': { defaultMessage: 'Lorestān' },
    'IR-16': { defaultMessage: 'Īlām' },
    'IR-17': { defaultMessage: 'Kohgīlūyeh va Bowyer Aḩmad' },
    'IR-18': { defaultMessage: 'Būshehr' },
    'IR-19': { defaultMessage: 'Zanjān' },
    'IR-20': { defaultMessage: 'Semnān' },
    'IR-21': { defaultMessage: 'Yazd' },
    'IR-22': { defaultMessage: 'Hormozgān' },
    'IR-23': { defaultMessage: 'Tehrān' },
    'IR-24': { defaultMessage: 'Ardabīl' },
    'IR-25': { defaultMessage: 'Qom' },
    'IR-26': { defaultMessage: 'Qazvīn' },
    'IR-27': { defaultMessage: 'Golestān' },
    'IR-28': { defaultMessage: 'Khorāsān-e Shomālī' },
    'IR-29': { defaultMessage: 'Khorāsān-e Jonūbī' },
    'IR-30': { defaultMessage: 'Alborz' },
    'IS-1': { defaultMessage: 'Höfuðborgarsvæði' },
    'IS-2': { defaultMessage: 'Suðurnes' },
    'IS-3': { defaultMessage: 'Vesturland' },
    'IS-4': { defaultMessage: 'Vestfirðir' },
    'IS-5': { defaultMessage: 'Norðurland vestra' },
    'IS-6': { defaultMessage: 'Norðurland eystra' },
    'IS-7': { defaultMessage: 'Austurland' },
    'IS-8': { defaultMessage: 'Suðurland' },
    'IS-AKH': { defaultMessage: 'Akrahreppur' },
    'IS-AKN': { defaultMessage: 'Akraneskaupstaður' },
    'IS-AKU': { defaultMessage: 'Akureyrarbær' },
    'IS-ARN': { defaultMessage: 'Árneshreppur' },
    'IS-ASA': { defaultMessage: 'Ásahreppur' },
    'IS-BFJ': { defaultMessage: 'Borgarfjarðarhreppur' },
    'IS-BLA': { defaultMessage: 'Bláskógabyggð' },
    'IS-BLO': { defaultMessage: 'Blönduósbær' },
    'IS-BOG': { defaultMessage: 'Borgarbyggð' },
    'IS-BOL': { defaultMessage: 'Bolungarvíkurkaupstaður' },
    'IS-DAB': { defaultMessage: 'Dalabyggð' },
    'IS-DAV': { defaultMessage: 'Dalvíkurbyggð' },
    'IS-DJU': { defaultMessage: 'Djúpavogshreppur' },
    'IS-EOM': { defaultMessage: 'Eyja- og Miklaholtshreppur' },
    'IS-EYF': { defaultMessage: 'Eyjafjarðarsveit' },
    'IS-FJD': { defaultMessage: 'Fjarðabyggð' },
    'IS-FJL': { defaultMessage: 'Fjallabyggð' },
    'IS-FLA': { defaultMessage: 'Flóahreppur' },
    'IS-FLD': { defaultMessage: 'Fljótsdalshérað' },
    'IS-FLR': { defaultMessage: 'Fljótsdalshreppur' },
    'IS-GAR': { defaultMessage: 'Garðabær' },
    'IS-GOG': { defaultMessage: 'Grímsnes- og Grafningshreppur' },
    'IS-GRN': { defaultMessage: 'Grindavíkurbær' },
    'IS-GRU': { defaultMessage: 'Grundarfjarðarbær' },
    'IS-GRY': { defaultMessage: 'Grýtubakkahreppur' },
    'IS-HAF': { defaultMessage: 'Hafnarfjarðarkaupstaður' },
    'IS-HEL': { defaultMessage: 'Helgafellssveit' },
    'IS-HRG': { defaultMessage: 'Hörgársveit' },
    'IS-HRU': { defaultMessage: 'Hrunamannahreppur' },
    'IS-HUT': { defaultMessage: 'Húnavatnshreppur' },
    'IS-HUV': { defaultMessage: 'Húnaþing vestra' },
    'IS-HVA': { defaultMessage: 'Hvalfjarðarsveit' },
    'IS-HVE': { defaultMessage: 'Hveragerðisbær' },
    'IS-ISA': { defaultMessage: 'Ísafjarðarbær' },
    'IS-KAL': { defaultMessage: 'Kaldrananeshreppur' },
    'IS-KJO': { defaultMessage: 'Kjósarhreppur' },
    'IS-KOP': { defaultMessage: 'Kópavogsbær' },
    'IS-LAN': { defaultMessage: 'Langanesbyggð' },
    'IS-MOS': { defaultMessage: 'Mosfellsbær' },
    'IS-MYR': { defaultMessage: 'Mýrdalshreppur' },
    'IS-NOR': { defaultMessage: 'Norðurþing' },
    'IS-RGE': { defaultMessage: 'Rangárþing eystra' },
    'IS-RGY': { defaultMessage: 'Rangárþing ytra' },
    'IS-RHH': { defaultMessage: 'Reykhólahreppur' },
    'IS-RKN': { defaultMessage: 'Reykjanesbær' },
    'IS-RKV': { defaultMessage: 'Reykjavíkurborg' },
    'IS-SBH': { defaultMessage: 'Svalbarðshreppur' },
    'IS-SBT': { defaultMessage: 'Svalbarðsstrandarhreppur' },
    'IS-SDN': { defaultMessage: 'Suðurnesjabær' },
    'IS-SDV': { defaultMessage: 'Súðavíkurhreppur' },
    'IS-SEL': { defaultMessage: 'Seltjarnarnesbær' },
    'IS-SEY': { defaultMessage: 'Seyðisfjarðarkaupstaður' },
    'IS-SFA': { defaultMessage: 'Sveitarfélagið Árborg' },
    'IS-SHF': { defaultMessage: 'Sveitarfélagið Hornafjörður' },
    'IS-SKF': { defaultMessage: 'Skaftárhreppur' },
    'IS-SKG': { defaultMessage: 'Skagabyggð' },
    'IS-SKO': { defaultMessage: 'Skorradalshreppur' },
    'IS-SKU': { defaultMessage: 'Skútustaðahreppur' },
    'IS-SNF': { defaultMessage: 'Snæfellsbær' },
    'IS-SOG': { defaultMessage: 'Skeiða- og Gnúpverjahreppur' },
    'IS-SOL': { defaultMessage: 'Sveitarfélagið Ölfus' },
    'IS-SSF': { defaultMessage: 'Sveitarfélagið Skagafjörður' },
    'IS-SSS': { defaultMessage: 'Sveitarfélagið Skagaströnd' },
    'IS-STR': { defaultMessage: 'Strandabyggð' },
    'IS-STY': { defaultMessage: 'Stykkishólmsbær' },
    'IS-SVG': { defaultMessage: 'Sveitarfélagið Vogar' },
    'IS-TAL': { defaultMessage: 'Tálknafjarðarhreppur' },
    'IS-THG': { defaultMessage: 'Þingeyjarsveit' },
    'IS-TJO': { defaultMessage: 'Tjörneshreppur' },
    'IS-VEM': { defaultMessage: 'Vestmannaeyjabær' },
    'IS-VER': { defaultMessage: 'Vesturbyggð' },
    'IS-VOP': { defaultMessage: 'Vopnafjarðarhreppur' },
    'IT-21': { defaultMessage: 'Piemonte' },
    'IT-23': { defaultMessage: "Valle d'Aosta, Val d'Aoste" },
    'IT-25': { defaultMessage: 'Lombardia' },
    'IT-32': { defaultMessage: 'Trentino-Alto Adige, Trentino-Südtirol' },
    'IT-34': { defaultMessage: 'Veneto' },
    'IT-36': { defaultMessage: 'Friuli Venezia Giulia' },
    'IT-42': { defaultMessage: 'Liguria' },
    'IT-45': { defaultMessage: 'Emilia-Romagna' },
    'IT-52': { defaultMessage: 'Toscana' },
    'IT-55': { defaultMessage: 'Umbria' },
    'IT-57': { defaultMessage: 'Marche' },
    'IT-62': { defaultMessage: 'Lazio' },
    'IT-65': { defaultMessage: 'Abruzzo' },
    'IT-67': { defaultMessage: 'Molise' },
    'IT-72': { defaultMessage: 'Campania' },
    'IT-75': { defaultMessage: 'Puglia' },
    'IT-77': { defaultMessage: 'Basilicata' },
    'IT-78': { defaultMessage: 'Calabria' },
    'IT-82': { defaultMessage: 'Sicilia' },
    'IT-88': { defaultMessage: 'Sardegna' },
    'IT-AG': { defaultMessage: 'Agrigento' },
    'IT-AL': { defaultMessage: 'Alessandria' },
    'IT-AN': { defaultMessage: 'Ancona' },
    'IT-AP': { defaultMessage: 'Ascoli Piceno' },
    'IT-AQ': { defaultMessage: "L'Aquila" },
    'IT-AR': { defaultMessage: 'Arezzo' },
    'IT-AT': { defaultMessage: 'Asti' },
    'IT-AV': { defaultMessage: 'Avellino' },
    'IT-BA': { defaultMessage: 'Bari' },
    'IT-BG': { defaultMessage: 'Bergamo' },
    'IT-BI': { defaultMessage: 'Biella' },
    'IT-BL': { defaultMessage: 'Belluno' },
    'IT-BN': { defaultMessage: 'Benevento' },
    'IT-BO': { defaultMessage: 'Bologna' },
    'IT-BR': { defaultMessage: 'Brindisi' },
    'IT-BS': { defaultMessage: 'Brescia' },
    'IT-BT': { defaultMessage: 'Barletta-Andria-Trani' },
    'IT-BZ': { defaultMessage: 'Bolzano, Bozen' },
    'IT-CA': { defaultMessage: 'Cagliari' },
    'IT-CB': { defaultMessage: 'Campobasso' },
    'IT-CE': { defaultMessage: 'Caserta' },
    'IT-CH': { defaultMessage: 'Chieti' },
    'IT-CL': { defaultMessage: 'Caltanissetta' },
    'IT-CN': { defaultMessage: 'Cuneo' },
    'IT-CO': { defaultMessage: 'Como' },
    'IT-CR': { defaultMessage: 'Cremona' },
    'IT-CS': { defaultMessage: 'Cosenza' },
    'IT-CT': { defaultMessage: 'Catania' },
    'IT-CZ': { defaultMessage: 'Catanzaro' },
    'IT-EN': { defaultMessage: 'Enna' },
    'IT-FC': { defaultMessage: 'Forlì-Cesena' },
    'IT-FE': { defaultMessage: 'Ferrara' },
    'IT-FG': { defaultMessage: 'Foggia' },
    'IT-FI': { defaultMessage: 'Firenze' },
    'IT-FM': { defaultMessage: 'Fermo' },
    'IT-FR': { defaultMessage: 'Frosinone' },
    'IT-GE': { defaultMessage: 'Genova' },
    'IT-GO': { defaultMessage: 'Gorizia' },
    'IT-GR': { defaultMessage: 'Grosseto' },
    'IT-IM': { defaultMessage: 'Imperia' },
    'IT-IS': { defaultMessage: 'Isernia' },
    'IT-KR': { defaultMessage: 'Crotone' },
    'IT-LC': { defaultMessage: 'Lecco' },
    'IT-LE': { defaultMessage: 'Lecce' },
    'IT-LI': { defaultMessage: 'Livorno' },
    'IT-LO': { defaultMessage: 'Lodi' },
    'IT-LT': { defaultMessage: 'Latina' },
    'IT-LU': { defaultMessage: 'Lucca' },
    'IT-MB': { defaultMessage: 'Monza e Brianza' },
    'IT-MC': { defaultMessage: 'Macerata' },
    'IT-ME': { defaultMessage: 'Messina' },
    'IT-MI': { defaultMessage: 'Milano' },
    'IT-MN': { defaultMessage: 'Mantova' },
    'IT-MO': { defaultMessage: 'Modena' },
    'IT-MS': { defaultMessage: 'Massa-Carrara' },
    'IT-MT': { defaultMessage: 'Matera' },
    'IT-NA': { defaultMessage: 'Napoli' },
    'IT-NO': { defaultMessage: 'Novara' },
    'IT-NU': { defaultMessage: 'Nuoro' },
    'IT-OR': { defaultMessage: 'Oristano' },
    'IT-PA': { defaultMessage: 'Palermo' },
    'IT-PC': { defaultMessage: 'Piacenza' },
    'IT-PD': { defaultMessage: 'Padova' },
    'IT-PE': { defaultMessage: 'Pescara' },
    'IT-PG': { defaultMessage: 'Perugia' },
    'IT-PI': { defaultMessage: 'Pisa' },
    'IT-PN': { defaultMessage: 'Pordenone' },
    'IT-PO': { defaultMessage: 'Prato' },
    'IT-PR': { defaultMessage: 'Parma' },
    'IT-PT': { defaultMessage: 'Pistoia' },
    'IT-PU': { defaultMessage: 'Pesaro e Urbino' },
    'IT-PV': { defaultMessage: 'Pavia' },
    'IT-PZ': { defaultMessage: 'Potenza' },
    'IT-RA': { defaultMessage: 'Ravenna' },
    'IT-RC': { defaultMessage: 'Reggio Calabria' },
    'IT-RE': { defaultMessage: 'Reggio Emilia' },
    'IT-RG': { defaultMessage: 'Ragusa' },
    'IT-RI': { defaultMessage: 'Rieti' },
    'IT-RM': { defaultMessage: 'Roma' },
    'IT-RN': { defaultMessage: 'Rimini' },
    'IT-RO': { defaultMessage: 'Rovigo' },
    'IT-SA': { defaultMessage: 'Salerno' },
    'IT-SI': { defaultMessage: 'Siena' },
    'IT-SO': { defaultMessage: 'Sondrio' },
    'IT-SP': { defaultMessage: 'La Spezia' },
    'IT-SR': { defaultMessage: 'Siracusa' },
    'IT-SS': { defaultMessage: 'Sassari' },
    'IT-SU': { defaultMessage: 'Sud Sardegna' },
    'IT-SV': { defaultMessage: 'Savona' },
    'IT-TA': { defaultMessage: 'Taranto' },
    'IT-TE': { defaultMessage: 'Teramo' },
    'IT-TN': { defaultMessage: 'Trento' },
    'IT-TO': { defaultMessage: 'Torino' },
    'IT-TP': { defaultMessage: 'Trapani' },
    'IT-TR': { defaultMessage: 'Terni' },
    'IT-TS': { defaultMessage: 'Trieste' },
    'IT-TV': { defaultMessage: 'Treviso' },
    'IT-UD': { defaultMessage: 'Udine' },
    'IT-VA': { defaultMessage: 'Varese' },
    'IT-VB': { defaultMessage: 'Verbano-Cusio-Ossola' },
    'IT-VC': { defaultMessage: 'Vercelli' },
    'IT-VE': { defaultMessage: 'Venezia' },
    'IT-VI': { defaultMessage: 'Vicenza' },
    'IT-VR': { defaultMessage: 'Verona' },
    'IT-VT': { defaultMessage: 'Viterbo' },
    'IT-VV': { defaultMessage: 'Vibo Valentia' },
    'JM-01': { defaultMessage: 'Kingston' },
    'JM-02': { defaultMessage: 'Saint Andrew' },
    'JM-03': { defaultMessage: 'Saint Thomas' },
    'JM-04': { defaultMessage: 'Portland' },
    'JM-05': { defaultMessage: 'Saint Mary' },
    'JM-06': { defaultMessage: 'Saint Ann' },
    'JM-07': { defaultMessage: 'Trelawny' },
    'JM-08': { defaultMessage: 'Saint James' },
    'JM-09': { defaultMessage: 'Hanover' },
    'JM-10': { defaultMessage: 'Westmoreland' },
    'JM-11': { defaultMessage: 'Saint Elizabeth' },
    'JM-12': { defaultMessage: 'Manchester' },
    'JM-13': { defaultMessage: 'Clarendon' },
    'JM-14': { defaultMessage: 'Saint Catherine' },
    'JO-AJ': { defaultMessage: '‘Ajlūn' },
    'JO-AM': { defaultMessage: 'Al ‘A̅şimah' },
    'JO-AQ': { defaultMessage: 'Al ‘Aqabah' },
    'JO-AT': { defaultMessage: 'Aţ Ţafīlah' },
    'JO-AZ': { defaultMessage: 'Az Zarqā’' },
    'JO-BA': { defaultMessage: 'Al Balqā’' },
    'JO-IR': { defaultMessage: 'Irbid' },
    'JO-JA': { defaultMessage: 'Jarash' },
    'JO-KA': { defaultMessage: 'Al Karak' },
    'JO-MA': { defaultMessage: 'Al Mafraq' },
    'JO-MD': { defaultMessage: 'Mādabā' },
    'JO-MN': { defaultMessage: 'Ma‘ān' },
    'JP-01': { defaultMessage: 'Hokkaidô' },
    'JP-02': { defaultMessage: 'Aomori' },
    'JP-03': { defaultMessage: 'Iwate' },
    'JP-04': { defaultMessage: 'Miyagi' },
    'JP-05': { defaultMessage: 'Akita' },
    'JP-06': { defaultMessage: 'Yamagata' },
    'JP-07': { defaultMessage: 'Hukusima' },
    'JP-08': { defaultMessage: 'Ibaraki' },
    'JP-09': { defaultMessage: 'Totigi' },
    'JP-10': { defaultMessage: 'Gunma' },
    'JP-11': { defaultMessage: 'Saitama' },
    'JP-12': { defaultMessage: 'Tiba' },
    'JP-13': { defaultMessage: 'Tôkyô' },
    'JP-14': { defaultMessage: 'Kanagawa' },
    'JP-15': { defaultMessage: 'Niigata' },
    'JP-16': { defaultMessage: 'Toyama' },
    'JP-17': { defaultMessage: 'Isikawa' },
    'JP-18': { defaultMessage: 'Hukui' },
    'JP-19': { defaultMessage: 'Yamanasi' },
    'JP-20': { defaultMessage: 'Nagano' },
    'JP-21': { defaultMessage: 'Gihu' },
    'JP-22': { defaultMessage: 'Sizuoka' },
    'JP-23': { defaultMessage: 'Aiti' },
    'JP-24': { defaultMessage: 'Mie' },
    'JP-25': { defaultMessage: 'Siga' },
    'JP-26': { defaultMessage: 'Kyôto' },
    'JP-27': { defaultMessage: 'Ôsaka' },
    'JP-28': { defaultMessage: 'Hyôgo' },
    'JP-29': { defaultMessage: 'Nara' },
    'JP-30': { defaultMessage: 'Wakayama' },
    'JP-31': { defaultMessage: 'Tottori' },
    'JP-32': { defaultMessage: 'Simane' },
    'JP-33': { defaultMessage: 'Okayama' },
    'JP-34': { defaultMessage: 'Hirosima' },
    'JP-35': { defaultMessage: 'Yamaguti' },
    'JP-36': { defaultMessage: 'Tokusima' },
    'JP-37': { defaultMessage: 'Kagawa' },
    'JP-38': { defaultMessage: 'Ehime' },
    'JP-39': { defaultMessage: 'Kôti' },
    'JP-40': { defaultMessage: 'Hukuoka' },
    'JP-41': { defaultMessage: 'Saga' },
    'JP-42': { defaultMessage: 'Nagasaki' },
    'JP-43': { defaultMessage: 'Kumamoto' },
    'JP-44': { defaultMessage: 'Ôita' },
    'JP-45': { defaultMessage: 'Miyazaki' },
    'JP-46': { defaultMessage: 'Kagosima' },
    'JP-47': { defaultMessage: 'Okinawa' },
    'KE-01': { defaultMessage: 'Baringo' },
    'KE-02': { defaultMessage: 'Bomet' },
    'KE-03': { defaultMessage: 'Bungoma' },
    'KE-04': { defaultMessage: 'Busia' },
    'KE-05': { defaultMessage: 'Elgeyo/Marakwet' },
    'KE-06': { defaultMessage: 'Embu' },
    'KE-07': { defaultMessage: 'Garissa' },
    'KE-08': { defaultMessage: 'Homa Bay' },
    'KE-09': { defaultMessage: 'Isiolo' },
    'KE-10': { defaultMessage: 'Kajiado' },
    'KE-11': { defaultMessage: 'Kakamega' },
    'KE-12': { defaultMessage: 'Kericho' },
    'KE-13': { defaultMessage: 'Kiambu' },
    'KE-14': { defaultMessage: 'Kilifi' },
    'KE-15': { defaultMessage: 'Kirinyaga' },
    'KE-16': { defaultMessage: 'Kisii' },
    'KE-17': { defaultMessage: 'Kisumu' },
    'KE-18': { defaultMessage: 'Kitui' },
    'KE-19': { defaultMessage: 'Kwale' },
    'KE-20': { defaultMessage: 'Laikipia' },
    'KE-21': { defaultMessage: 'Lamu' },
    'KE-22': { defaultMessage: 'Machakos' },
    'KE-23': { defaultMessage: 'Makueni' },
    'KE-24': { defaultMessage: 'Mandera' },
    'KE-25': { defaultMessage: 'Marsabit' },
    'KE-26': { defaultMessage: 'Meru' },
    'KE-27': { defaultMessage: 'Migori' },
    'KE-28': { defaultMessage: 'Mombasa' },
    'KE-29': { defaultMessage: "Murang'a" },
    'KE-30': { defaultMessage: 'Nairobi City' },
    'KE-31': { defaultMessage: 'Nakuru' },
    'KE-32': { defaultMessage: 'Nandi' },
    'KE-33': { defaultMessage: 'Narok' },
    'KE-34': { defaultMessage: 'Nyamira' },
    'KE-35': { defaultMessage: 'Nyandarua' },
    'KE-36': { defaultMessage: 'Nyeri' },
    'KE-37': { defaultMessage: 'Samburu' },
    'KE-38': { defaultMessage: 'Siaya' },
    'KE-39': { defaultMessage: 'Taita/Taveta' },
    'KE-40': { defaultMessage: 'Tana River' },
    'KE-41': { defaultMessage: 'Tharaka-Nithi' },
    'KE-42': { defaultMessage: 'Trans Nzoia' },
    'KE-43': { defaultMessage: 'Turkana' },
    'KE-44': { defaultMessage: 'Uasin Gishu' },
    'KE-45': { defaultMessage: 'Vihiga' },
    'KE-46': { defaultMessage: 'Wajir' },
    'KE-47': { defaultMessage: 'West Pokot' },
    'KG-B': { defaultMessage: 'Batken' },
    'KG-C': { defaultMessage: 'Chüy' },
    'KG-GB': { defaultMessage: 'Bishkek Shaary' },
    'KG-GO': { defaultMessage: 'Osh Shaary' },
    'KG-J': { defaultMessage: 'Jalal-Abad' },
    'KG-N': { defaultMessage: 'Naryn' },
    'KG-O': { defaultMessage: 'Osh' },
    'KG-T': { defaultMessage: 'Talas' },
    'KG-Y': { defaultMessage: 'Ysyk-Köl' },
    'KH-1': { defaultMessage: 'Banteay Mean Choăy' },
    'KH-10': { defaultMessage: 'Kracheh' },
    'KH-11': { defaultMessage: 'Mondol Kiri' },
    'KH-12': { defaultMessage: 'Phnom Penh' },
    'KH-13': { defaultMessage: 'Preah Vihear' },
    'KH-14': { defaultMessage: 'Prey Veaeng' },
    'KH-15': { defaultMessage: 'Pousaat' },
    'KH-16': { defaultMessage: 'Rotanak Kiri' },
    'KH-17': { defaultMessage: 'Siem Reab' },
    'KH-18': { defaultMessage: 'Preah Sihanouk' },
    'KH-19': { defaultMessage: 'Stueng Traeng' },
    'KH-2': { defaultMessage: 'Baat Dambang' },
    'KH-20': { defaultMessage: 'Svaay Rieng' },
    'KH-21': { defaultMessage: 'Taakaev' },
    'KH-22': { defaultMessage: 'Otdar Mean Chey' },
    'KH-23': { defaultMessage: 'Kaeb' },
    'KH-24': { defaultMessage: 'Pailin' },
    'KH-25': { defaultMessage: 'Tbong Khmum' },
    'KH-3': { defaultMessage: 'Kampong Chaam' },
    'KH-4': { defaultMessage: 'Kampong Chhnang' },
    'KH-5': { defaultMessage: 'Kampong Spueu' },
    'KH-6': { defaultMessage: 'Kampong Thum' },
    'KH-7': { defaultMessage: 'Kampot' },
    'KH-8': { defaultMessage: 'Kandaal' },
    'KH-9': { defaultMessage: 'Kaoh Kong' },
    'KI-G': { defaultMessage: 'Gilbert Islands' },
    'KI-L': { defaultMessage: 'Line Islands' },
    'KI-P': { defaultMessage: 'Phoenix Islands' },
    'KM-A': { defaultMessage: 'Anjouan' },
    'KM-G': { defaultMessage: 'Grande Comore' },
    'KM-M': { defaultMessage: 'Mohéli' },
    'KN-01': { defaultMessage: 'Christ Church Nichola Town' },
    'KN-02': { defaultMessage: 'Saint Anne Sandy Point' },
    'KN-03': { defaultMessage: 'Saint George Basseterre' },
    'KN-04': { defaultMessage: 'Saint George Gingerland' },
    'KN-05': { defaultMessage: 'Saint James Windward' },
    'KN-06': { defaultMessage: 'Saint John Capisterre' },
    'KN-07': { defaultMessage: 'Saint John Figtree' },
    'KN-08': { defaultMessage: 'Saint Mary Cayon' },
    'KN-09': { defaultMessage: 'Saint Paul Capisterre' },
    'KN-10': { defaultMessage: 'Saint Paul Charlestown' },
    'KN-11': { defaultMessage: 'Saint Peter Basseterre' },
    'KN-12': { defaultMessage: 'Saint Thomas Lowland' },
    'KN-13': { defaultMessage: 'Saint Thomas Middle Island' },
    'KN-15': { defaultMessage: 'Trinity Palmetto Point' },
    'KN-K': { defaultMessage: 'Saint Kitts' },
    'KN-N': { defaultMessage: 'Nevis' },
    'KP-01': { defaultMessage: 'Pyongyang' },
    'KP-02': { defaultMessage: 'South Pyongan' },
    'KP-03': { defaultMessage: 'North Pyongan' },
    'KP-04': { defaultMessage: 'Chagang' },
    'KP-05': { defaultMessage: 'South Hwanghae' },
    'KP-06': { defaultMessage: 'North Hwanghae' },
    'KP-07': { defaultMessage: 'Kangwon' },
    'KP-08': { defaultMessage: 'South Hamgyong' },
    'KP-09': { defaultMessage: 'North Hamgyong' },
    'KP-10': { defaultMessage: 'Ryanggang' },
    'KP-13': { defaultMessage: 'Rason' },
    'KP-14': { defaultMessage: 'Nampo' },
    'KR-11': { defaultMessage: 'Seoul' },
    'KR-26': { defaultMessage: 'Busan' },
    'KR-27': { defaultMessage: 'Daegu' },
    'KR-28': { defaultMessage: 'Incheon' },
    'KR-29': { defaultMessage: 'Gwangju' },
    'KR-30': { defaultMessage: 'Daejeon' },
    'KR-31': { defaultMessage: 'Ulsan' },
    'KR-41': { defaultMessage: 'Gyeonggi' },
    'KR-42': { defaultMessage: 'Gangwon' },
    'KR-43': { defaultMessage: 'North Chungcheong' },
    'KR-44': { defaultMessage: 'South Chungcheong' },
    'KR-45': { defaultMessage: 'North Jeolla' },
    'KR-46': { defaultMessage: 'South Jeolla' },
    'KR-47': { defaultMessage: 'North Gyeongsang' },
    'KR-48': { defaultMessage: 'South Gyeongsang' },
    'KR-49': { defaultMessage: 'Jeju' },
    'KR-50': { defaultMessage: 'Sejong' },
    'KW-AH': { defaultMessage: 'Al Aḩmadī' },
    'KW-FA': { defaultMessage: 'Al Farwānīyah' },
    'KW-HA': { defaultMessage: 'Ḩawallī' },
    'KW-JA': { defaultMessage: 'Al Jahrā’' },
    'KW-KU': { defaultMessage: 'Al ‘Āşimah' },
    'KW-MU': { defaultMessage: 'Mubārak al Kabīr' },
    'KZ-AKM': { defaultMessage: 'Aqmola oblysy' },
    'KZ-AKT': { defaultMessage: 'Aqtöbe oblysy' },
    'KZ-ALA': { defaultMessage: 'Almaty' },
    'KZ-ALM': { defaultMessage: 'Almaty oblysy' },
    'KZ-AST': { defaultMessage: 'Nur-Sultan' },
    'KZ-ATY': { defaultMessage: 'Atyraū oblysy' },
    'KZ-KAR': { defaultMessage: 'Qaraghandy oblysy' },
    'KZ-KUS': { defaultMessage: 'Qostanay oblysy' },
    'KZ-KZY': { defaultMessage: 'Qyzylorda oblysy' },
    'KZ-MAN': { defaultMessage: 'Mangghystaū oblysy' },
    'KZ-PAV': { defaultMessage: 'Pavlodar oblysy' },
    'KZ-SEV': { defaultMessage: 'Soltüstik Qazaqstan oblysy' },
    'KZ-SHY': { defaultMessage: 'Shymkent' },
    'KZ-VOS': { defaultMessage: 'Shyghys Qazaqstan oblysy' },
    'KZ-YUZ': { defaultMessage: 'Türkistan oblysy' },
    'KZ-ZAP': { defaultMessage: 'Batys Qazaqstan oblysy' },
    'KZ-ZHA': { defaultMessage: 'Zhambyl oblysy' },
    'LA-AT': { defaultMessage: 'Attapu' },
    'LA-BK': { defaultMessage: 'Bokèo' },
    'LA-BL': { defaultMessage: 'Bolikhamxai' },
    'LA-CH': { defaultMessage: 'Champasak' },
    'LA-HO': { defaultMessage: 'Houaphan' },
    'LA-KH': { defaultMessage: 'Khammouan' },
    'LA-LM': { defaultMessage: 'Louang Namtha' },
    'LA-LP': { defaultMessage: 'Louangphabang' },
    'LA-OU': { defaultMessage: 'Oudômxai' },
    'LA-PH': { defaultMessage: 'Phôngsali' },
    'LA-SL': { defaultMessage: 'Salavan' },
    'LA-SV': { defaultMessage: 'Savannakhét' },
    'LA-VI': { defaultMessage: 'Viangchan' },
    'LA-VT': { defaultMessage: 'Viangchan' },
    'LA-XA': { defaultMessage: 'Xaignabouli' },
    'LA-XE': { defaultMessage: 'Xékong' },
    'LA-XI': { defaultMessage: 'Xiangkhouang' },
    'LA-XS': { defaultMessage: 'Xaisômboun' },
    'LB-AK': { defaultMessage: 'Aakkâr' },
    'LB-AS': { defaultMessage: 'Liban-Nord' },
    'LB-BA': { defaultMessage: 'Beyrouth' },
    'LB-BH': { defaultMessage: 'Baalbek-Hermel' },
    'LB-BI': { defaultMessage: 'Béqaa' },
    'LB-JA': { defaultMessage: 'Liban-Sud' },
    'LB-JL': { defaultMessage: 'Mont-Liban' },
    'LB-NA': { defaultMessage: 'Nabatîyé' },
    'LC-01': { defaultMessage: 'Anse la Raye' },
    'LC-02': { defaultMessage: 'Castries' },
    'LC-03': { defaultMessage: 'Choiseul' },
    'LC-05': { defaultMessage: 'Dennery' },
    'LC-06': { defaultMessage: 'Gros Islet' },
    'LC-07': { defaultMessage: 'Laborie' },
    'LC-08': { defaultMessage: 'Micoud' },
    'LC-10': { defaultMessage: 'Soufrière' },
    'LC-11': { defaultMessage: 'Vieux Fort' },
    'LC-12': { defaultMessage: 'Canaries' },
    'LI-01': { defaultMessage: 'Balzers' },
    'LI-02': { defaultMessage: 'Eschen' },
    'LI-03': { defaultMessage: 'Gamprin' },
    'LI-04': { defaultMessage: 'Mauren' },
    'LI-05': { defaultMessage: 'Planken' },
    'LI-06': { defaultMessage: 'Ruggell' },
    'LI-07': { defaultMessage: 'Schaan' },
    'LI-08': { defaultMessage: 'Schellenberg' },
    'LI-09': { defaultMessage: 'Triesen' },
    'LI-10': { defaultMessage: 'Triesenberg' },
    'LI-11': { defaultMessage: 'Vaduz' },
    'LK-1': { defaultMessage: 'Western Province' },
    'LK-11': { defaultMessage: 'Colombo' },
    'LK-12': { defaultMessage: 'Gampaha' },
    'LK-13': { defaultMessage: 'Kalutara' },
    'LK-2': { defaultMessage: 'Central Province' },
    'LK-21': { defaultMessage: 'Kandy' },
    'LK-22': { defaultMessage: 'Matale' },
    'LK-23': { defaultMessage: 'Nuwara Eliya' },
    'LK-3': { defaultMessage: 'Southern Province' },
    'LK-31': { defaultMessage: 'Galle' },
    'LK-32': { defaultMessage: 'Matara' },
    'LK-33': { defaultMessage: 'Hambantota' },
    'LK-4': { defaultMessage: 'Northern Province' },
    'LK-41': { defaultMessage: 'Jaffna' },
    'LK-42': { defaultMessage: 'Kilinochchi' },
    'LK-43': { defaultMessage: 'Mannar' },
    'LK-44': { defaultMessage: 'Vavuniya' },
    'LK-45': { defaultMessage: 'Mullaittivu' },
    'LK-5': { defaultMessage: 'Eastern Province' },
    'LK-51': { defaultMessage: 'Batticaloa' },
    'LK-52': { defaultMessage: 'Ampara' },
    'LK-53': { defaultMessage: 'Trincomalee' },
    'LK-6': { defaultMessage: 'North Western Province' },
    'LK-61': { defaultMessage: 'Kurunegala' },
    'LK-62': { defaultMessage: 'Puttalam' },
    'LK-7': { defaultMessage: 'North Central Province' },
    'LK-71': { defaultMessage: 'Anuradhapura' },
    'LK-72': { defaultMessage: 'Polonnaruwa' },
    'LK-8': { defaultMessage: 'Uva Province' },
    'LK-81': { defaultMessage: 'Badulla' },
    'LK-82': { defaultMessage: 'Monaragala' },
    'LK-9': { defaultMessage: 'Sabaragamuwa Province' },
    'LK-91': { defaultMessage: 'Ratnapura' },
    'LK-92': { defaultMessage: 'Kegalla' },
    'LR-BG': { defaultMessage: 'Bong' },
    'LR-BM': { defaultMessage: 'Bomi' },
    'LR-CM': { defaultMessage: 'Grand Cape Mount' },
    'LR-GB': { defaultMessage: 'Grand Bassa' },
    'LR-GG': { defaultMessage: 'Grand Gedeh' },
    'LR-GK': { defaultMessage: 'Grand Kru' },
    'LR-GP': { defaultMessage: 'Gbarpolu' },
    'LR-LO': { defaultMessage: 'Lofa' },
    'LR-MG': { defaultMessage: 'Margibi' },
    'LR-MO': { defaultMessage: 'Montserrado' },
    'LR-MY': { defaultMessage: 'Maryland' },
    'LR-NI': { defaultMessage: 'Nimba' },
    'LR-RG': { defaultMessage: 'River Gee' },
    'LR-RI': { defaultMessage: 'River Cess(local variant is Rivercess)' },
    'LR-SI': { defaultMessage: 'Sinoe' },
    'LS-A': { defaultMessage: 'Maseru' },
    'LS-B': { defaultMessage: 'Botha-Bothe' },
    'LS-C': { defaultMessage: 'Leribe' },
    'LS-D': { defaultMessage: 'Berea' },
    'LS-E': { defaultMessage: 'Mafeteng' },
    'LS-F': { defaultMessage: "Mohale's Hoek" },
    'LS-G': { defaultMessage: 'Quthing' },
    'LS-H': { defaultMessage: "Qacha's Nek" },
    'LS-J': { defaultMessage: 'Mokhotlong' },
    'LS-K': { defaultMessage: 'Thaba-Tseka' },
    'LT-01': { defaultMessage: 'Akmenė' },
    'LT-02': { defaultMessage: 'Alytaus miestas' },
    'LT-03': { defaultMessage: 'Alytus' },
    'LT-04': { defaultMessage: 'Anykščiai' },
    'LT-05': { defaultMessage: 'Birštono' },
    'LT-06': { defaultMessage: 'Biržai' },
    'LT-07': { defaultMessage: 'Druskininkai' },
    'LT-08': { defaultMessage: 'Elektrėnai' },
    'LT-09': { defaultMessage: 'Ignalina' },
    'LT-10': { defaultMessage: 'Jonava' },
    'LT-11': { defaultMessage: 'Joniškis' },
    'LT-12': { defaultMessage: 'Jurbarkas' },
    'LT-13': { defaultMessage: 'Kaišiadorys' },
    'LT-14': { defaultMessage: 'Kalvarijos' },
    'LT-15': { defaultMessage: 'Kauno miestas' },
    'LT-16': { defaultMessage: 'Kaunas' },
    'LT-17': { defaultMessage: 'Kazlų Rūdos' },
    'LT-18': { defaultMessage: 'Kėdainiai' },
    'LT-19': { defaultMessage: 'Kelmė' },
    'LT-20': { defaultMessage: 'Klaipėdos miestas' },
    'LT-21': { defaultMessage: 'Klaipėda' },
    'LT-22': { defaultMessage: 'Kretinga' },
    'LT-23': { defaultMessage: 'Kupiškis' },
    'LT-24': { defaultMessage: 'Lazdijai' },
    'LT-25': { defaultMessage: 'Marijampolė' },
    'LT-26': { defaultMessage: 'Mažeikiai' },
    'LT-27': { defaultMessage: 'Molėtai' },
    'LT-28': { defaultMessage: 'Neringa' },
    'LT-29': { defaultMessage: 'Pagėgiai' },
    'LT-30': { defaultMessage: 'Pakruojis' },
    'LT-31': { defaultMessage: 'Palangos miestas' },
    'LT-32': { defaultMessage: 'Panevėžio miestas' },
    'LT-33': { defaultMessage: 'Panevėžys' },
    'LT-34': { defaultMessage: 'Pasvalys' },
    'LT-35': { defaultMessage: 'Plungė' },
    'LT-36': { defaultMessage: 'Prienai' },
    'LT-37': { defaultMessage: 'Radviliškis' },
    'LT-38': { defaultMessage: 'Raseiniai' },
    'LT-39': { defaultMessage: 'Rietavo' },
    'LT-40': { defaultMessage: 'Rokiškis' },
    'LT-41': { defaultMessage: 'Šakiai' },
    'LT-42': { defaultMessage: 'Šalčininkai' },
    'LT-43': { defaultMessage: 'Šiaulių miestas' },
    'LT-44': { defaultMessage: 'Šiauliai' },
    'LT-45': { defaultMessage: 'Šilalė' },
    'LT-46': { defaultMessage: 'Šilutė' },
    'LT-47': { defaultMessage: 'Širvintos' },
    'LT-48': { defaultMessage: 'Skuodas' },
    'LT-49': { defaultMessage: 'Švenčionys' },
    'LT-50': { defaultMessage: 'Tauragė' },
    'LT-51': { defaultMessage: 'Telšiai' },
    'LT-52': { defaultMessage: 'Trakai' },
    'LT-53': { defaultMessage: 'Ukmergė' },
    'LT-54': { defaultMessage: 'Utena' },
    'LT-55': { defaultMessage: 'Varėna' },
    'LT-56': { defaultMessage: 'Vilkaviškis' },
    'LT-57': { defaultMessage: 'Vilniaus miestas' },
    'LT-58': { defaultMessage: 'Vilnius' },
    'LT-59': { defaultMessage: 'Visaginas' },
    'LT-60': { defaultMessage: 'Zarasai' },
    'LT-AL': { defaultMessage: 'Alytaus apskritis' },
    'LT-KL': { defaultMessage: 'Klaipėdos apskritis' },
    'LT-KU': { defaultMessage: 'Kauno apskritis' },
    'LT-MR': { defaultMessage: 'Marijampolės apskritis' },
    'LT-PN': { defaultMessage: 'Panevėžio apskritis' },
    'LT-SA': { defaultMessage: 'Šiaulių apskritis' },
    'LT-TA': { defaultMessage: 'Tauragės apskritis' },
    'LT-TE': { defaultMessage: 'Telšių apskritis' },
    'LT-UT': { defaultMessage: 'Utenos apskritis' },
    'LT-VL': { defaultMessage: 'Vilniaus apskritis' },
    'LU-CA': { defaultMessage: 'Capellen' },
    'LU-CL': { defaultMessage: 'Clervaux' },
    'LU-DI': { defaultMessage: 'Diekirch' },
    'LU-EC': { defaultMessage: 'Echternach' },
    'LU-ES': { defaultMessage: 'Esch-sur-Alzette' },
    'LU-GR': { defaultMessage: 'Grevenmacher' },
    'LU-LU': { defaultMessage: 'Luxembourg' },
    'LU-ME': { defaultMessage: 'Mersch' },
    'LU-RD': { defaultMessage: 'Redange' },
    'LU-RM': { defaultMessage: 'Remich' },
    'LU-VD': { defaultMessage: 'Vianden' },
    'LU-WI': { defaultMessage: 'Wiltz' },
    'LV-001': { defaultMessage: 'Aglonas novads' },
    'LV-002': { defaultMessage: 'Aizkraukles novads' },
    'LV-003': { defaultMessage: 'Aizputes novads' },
    'LV-004': { defaultMessage: 'Aknīstes novads' },
    'LV-005': { defaultMessage: 'Alojas novads' },
    'LV-006': { defaultMessage: 'Alsungas novads' },
    'LV-007': { defaultMessage: 'Alūksnes novads' },
    'LV-008': { defaultMessage: 'Amatas novads' },
    'LV-009': { defaultMessage: 'Apes novads' },
    'LV-010': { defaultMessage: 'Auces novads' },
    'LV-011': { defaultMessage: 'Ādažu novads' },
    'LV-012': { defaultMessage: 'Babītes novads' },
    'LV-013': { defaultMessage: 'Baldones novads' },
    'LV-014': { defaultMessage: 'Baltinavas novads' },
    'LV-015': { defaultMessage: 'Balvu novads' },
    'LV-016': { defaultMessage: 'Bauskas novads' },
    'LV-017': { defaultMessage: 'Beverīnas novads' },
    'LV-018': { defaultMessage: 'Brocēnu novads' },
    'LV-019': { defaultMessage: 'Burtnieku novads' },
    'LV-020': { defaultMessage: 'Carnikavas novads' },
    'LV-021': { defaultMessage: 'Cesvaines novads' },
    'LV-022': { defaultMessage: 'Cēsu novads' },
    'LV-023': { defaultMessage: 'Ciblas novads' },
    'LV-024': { defaultMessage: 'Dagdas novads' },
    'LV-025': { defaultMessage: 'Daugavpils novads' },
    'LV-026': { defaultMessage: 'Dobeles novads' },
    'LV-027': { defaultMessage: 'Dundagas novads' },
    'LV-028': { defaultMessage: 'Durbes novads' },
    'LV-029': { defaultMessage: 'Engures novads' },
    'LV-030': { defaultMessage: 'Ērgļu novads' },
    'LV-031': { defaultMessage: 'Garkalnes novads' },
    'LV-032': { defaultMessage: 'Grobiņas novads' },
    'LV-033': { defaultMessage: 'Gulbenes novads' },
    'LV-034': { defaultMessage: 'Iecavas novads' },
    'LV-035': { defaultMessage: 'Ikšķiles novads' },
    'LV-036': { defaultMessage: 'Ilūkstes novads' },
    'LV-037': { defaultMessage: 'Inčukalna novads' },
    'LV-038': { defaultMessage: 'Jaunjelgavas novads' },
    'LV-039': { defaultMessage: 'Jaunpiebalgas novads' },
    'LV-040': { defaultMessage: 'Jaunpils novads' },
    'LV-041': { defaultMessage: 'Jelgavas novads' },
    'LV-042': { defaultMessage: 'Jēkabpils novads' },
    'LV-043': { defaultMessage: 'Kandavas novads' },
    'LV-044': { defaultMessage: 'Kārsavas novads' },
    'LV-045': { defaultMessage: 'Kocēnu novads' },
    'LV-046': { defaultMessage: 'Kokneses novads' },
    'LV-047': { defaultMessage: 'Krāslavas novads' },
    'LV-048': { defaultMessage: 'Krimuldas novads' },
    'LV-049': { defaultMessage: 'Krustpils novads' },
    'LV-050': { defaultMessage: 'Kuldīgas novads' },
    'LV-051': { defaultMessage: 'Ķeguma novads' },
    'LV-052': { defaultMessage: 'Ķekavas novads' },
    'LV-053': { defaultMessage: 'Lielvārdes novads' },
    'LV-054': { defaultMessage: 'Limbažu novads' },
    'LV-055': { defaultMessage: 'Līgatnes novads' },
    'LV-056': { defaultMessage: 'Līvānu novads' },
    'LV-057': { defaultMessage: 'Lubānas novads' },
    'LV-058': { defaultMessage: 'Ludzas novads' },
    'LV-059': { defaultMessage: 'Madonas novads' },
    'LV-060': { defaultMessage: 'Mazsalacas novads' },
    'LV-061': { defaultMessage: 'Mālpils novads' },
    'LV-062': { defaultMessage: 'Mārupes novads' },
    'LV-063': { defaultMessage: 'Mērsraga novads' },
    'LV-064': { defaultMessage: 'Naukšēnu novads' },
    'LV-065': { defaultMessage: 'Neretas novads' },
    'LV-066': { defaultMessage: 'Nīcas novads' },
    'LV-067': { defaultMessage: 'Ogres novads' },
    'LV-068': { defaultMessage: 'Olaines novads' },
    'LV-069': { defaultMessage: 'Ozolnieku novads' },
    'LV-070': { defaultMessage: 'Pārgaujas novads' },
    'LV-071': { defaultMessage: 'Pāvilostas novads' },
    'LV-072': { defaultMessage: 'Pļaviņu novads' },
    'LV-073': { defaultMessage: 'Preiļu novads' },
    'LV-074': { defaultMessage: 'Priekules novads' },
    'LV-075': { defaultMessage: 'Priekuļu novads' },
    'LV-076': { defaultMessage: 'Raunas novads' },
    'LV-077': { defaultMessage: 'Rēzeknes novads' },
    'LV-078': { defaultMessage: 'Riebiņu novads' },
    'LV-079': { defaultMessage: 'Rojas novads' },
    'LV-080': { defaultMessage: 'Ropažu novads' },
    'LV-081': { defaultMessage: 'Rucavas novads' },
    'LV-082': { defaultMessage: 'Rugāju novads' },
    'LV-083': { defaultMessage: 'Rundāles novads' },
    'LV-084': { defaultMessage: 'Rūjienas novads' },
    'LV-085': { defaultMessage: 'Salas novads' },
    'LV-086': { defaultMessage: 'Salacgrīvas novads' },
    'LV-087': { defaultMessage: 'Salaspils novads' },
    'LV-088': { defaultMessage: 'Saldus novads' },
    'LV-089': { defaultMessage: 'Saulkrastu novads' },
    'LV-090': { defaultMessage: 'Sējas novads' },
    'LV-091': { defaultMessage: 'Siguldas novads' },
    'LV-092': { defaultMessage: 'Skrīveru novads' },
    'LV-093': { defaultMessage: 'Skrundas novads' },
    'LV-094': { defaultMessage: 'Smiltenes novads' },
    'LV-095': { defaultMessage: 'Stopiņu novads' },
    'LV-096': { defaultMessage: 'Strenču novads' },
    'LV-097': { defaultMessage: 'Talsu novads' },
    'LV-098': { defaultMessage: 'Tērvetes novads' },
    'LV-099': { defaultMessage: 'Tukuma novads' },
    'LV-100': { defaultMessage: 'Vaiņodes novads' },
    'LV-101': { defaultMessage: 'Valkas novads' },
    'LV-102': { defaultMessage: 'Varakļānu novads' },
    'LV-103': { defaultMessage: 'Vārkavas novads' },
    'LV-104': { defaultMessage: 'Vecpiebalgas novads' },
    'LV-105': { defaultMessage: 'Vecumnieku novads' },
    'LV-106': { defaultMessage: 'Ventspils novads' },
    'LV-107': { defaultMessage: 'Viesītes novads' },
    'LV-108': { defaultMessage: 'Viļakas novads' },
    'LV-109': { defaultMessage: 'Viļānu novads' },
    'LV-110': { defaultMessage: 'Zilupes novads' },
    'LV-DGV': { defaultMessage: 'Daugavpils' },
    'LV-JEL': { defaultMessage: 'Jelgava' },
    'LV-JKB': { defaultMessage: 'Jēkabpils' },
    'LV-JUR': { defaultMessage: 'Jūrmala' },
    'LV-LPX': { defaultMessage: 'Liepāja' },
    'LV-REZ': { defaultMessage: 'Rēzekne' },
    'LV-RIX': { defaultMessage: 'Rīga' },
    'LV-VEN': { defaultMessage: 'Ventspils' },
    'LV-VMR': { defaultMessage: 'Valmiera' },
    'LY-BA': { defaultMessage: 'Banghāzī' },
    'LY-BU': { defaultMessage: 'Al Buţnān' },
    'LY-DR': { defaultMessage: 'Darnah' },
    'LY-GT': { defaultMessage: 'Ghāt' },
    'LY-JA': { defaultMessage: 'Al Jabal al Akhḑar' },
    'LY-JG': { defaultMessage: 'Al Jabal al Gharbī' },
    'LY-JI': { defaultMessage: 'Al Jafārah' },
    'LY-JU': { defaultMessage: 'Al Jufrah' },
    'LY-KF': { defaultMessage: 'Al Kufrah' },
    'LY-MB': { defaultMessage: 'Al Marqab' },
    'LY-MI': { defaultMessage: 'Mişrātah' },
    'LY-MJ': { defaultMessage: 'Al Marj' },
    'LY-MQ': { defaultMessage: 'Murzuq' },
    'LY-NL': { defaultMessage: 'Nālūt' },
    'LY-NQ': { defaultMessage: 'An Nuqāţ al Khams' },
    'LY-SB': { defaultMessage: 'Sabhā' },
    'LY-SR': { defaultMessage: 'Surt' },
    'LY-TB': { defaultMessage: 'Ţarābulus' },
    'LY-WA': { defaultMessage: 'Al Wāḩāt' },
    'LY-WD': { defaultMessage: 'Wādī al Ḩayāt' },
    'LY-WS': { defaultMessage: 'Wādī ash Shāţi’' },
    'LY-ZA': { defaultMessage: 'Az Zāwiyah' },
    'MA-01': { defaultMessage: 'Tanger-Tétouan-Al Hoceïma' },
    'MA-02': { defaultMessage: "L'Oriental" },
    'MA-03': { defaultMessage: 'Fès-Meknès' },
    'MA-04': { defaultMessage: 'Rabat-Salé-Kénitra' },
    'MA-05': { defaultMessage: 'Béni Mellal-Khénifra' },
    'MA-06': { defaultMessage: 'Casablanca-Settat' },
    'MA-07': { defaultMessage: 'Marrakech-Safi' },
    'MA-08': { defaultMessage: 'Drâa-Tafilalet' },
    'MA-09': { defaultMessage: 'Souss-Massa' },
    'MA-10': { defaultMessage: 'Guelmim-Oued Noun (EH-partial)' },
    'MA-11': { defaultMessage: 'Laâyoune-Sakia El Hamra (EH-partial)' },
    'MA-12': { defaultMessage: 'Dakhla-Oued Ed-Dahab (EH)' },
    'MA-AGD': { defaultMessage: 'Agadir-Ida-Ou-Tanane' },
    'MA-AOU': { defaultMessage: 'Aousserd (EH)' },
    'MA-ASZ': { defaultMessage: 'Assa-Zag (EH-partial)' },
    'MA-AZI': { defaultMessage: 'Azilal' },
    'MA-BEM': { defaultMessage: 'Béni Mellal' },
    'MA-BER': { defaultMessage: 'Berkane' },
    'MA-BES': { defaultMessage: 'Benslimane' },
    'MA-BOD': { defaultMessage: 'Boujdour (EH)' },
    'MA-BOM': { defaultMessage: 'Boulemane' },
    'MA-BRR': { defaultMessage: 'Berrechid' },
    'MA-CAS': { defaultMessage: 'Casablanca' },
    'MA-CHE': { defaultMessage: 'Chefchaouen' },
    'MA-CHI': { defaultMessage: 'Chichaoua' },
    'MA-CHT': { defaultMessage: 'Chtouka-Ait Baha' },
    'MA-DRI': { defaultMessage: 'Driouch' },
    'MA-ERR': { defaultMessage: 'Errachidia' },
    'MA-ESI': { defaultMessage: 'Essaouira' },
    'MA-ESM': { defaultMessage: 'Es-Semara (EH-partial)' },
    'MA-FAH': { defaultMessage: 'Fahs-Anjra' },
    'MA-FES': { defaultMessage: 'Fès' },
    'MA-FIG': { defaultMessage: 'Figuig' },
    'MA-FQH': { defaultMessage: 'Fquih Ben Salah' },
    'MA-GUE': { defaultMessage: 'Guelmim' },
    'MA-GUF': { defaultMessage: 'Guercif' },
    'MA-HAJ': { defaultMessage: 'El Hajeb' },
    'MA-HAO': { defaultMessage: 'Al Haouz' },
    'MA-HOC': { defaultMessage: 'Al Hoceïma' },
    'MA-IFR': { defaultMessage: 'Ifrane' },
    'MA-INE': { defaultMessage: 'Inezgane-Ait Melloul' },
    'MA-JDI': { defaultMessage: 'El Jadida' },
    'MA-JRA': { defaultMessage: 'Jerada' },
    'MA-KEN': { defaultMessage: 'Kénitra' },
    'MA-KES': { defaultMessage: 'El Kelâa des Sraghna' },
    'MA-KHE': { defaultMessage: 'Khémisset' },
    'MA-KHN': { defaultMessage: 'Khénifra' },
    'MA-KHO': { defaultMessage: 'Khouribga' },
    'MA-LAA': { defaultMessage: 'Laâyoune (EH)' },
    'MA-LAR': { defaultMessage: 'Larache' },
    'MA-MAR': { defaultMessage: 'Marrakech' },
    'MA-MDF': { defaultMessage: 'M’diq-Fnideq' },
    'MA-MED': { defaultMessage: 'Médiouna' },
    'MA-MEK': { defaultMessage: 'Meknès' },
    'MA-MID': { defaultMessage: 'Midelt' },
    'MA-MOH': { defaultMessage: 'Mohammadia' },
    'MA-MOU': { defaultMessage: 'Moulay Yacoub' },
    'MA-NAD': { defaultMessage: 'Nador' },
    'MA-NOU': { defaultMessage: 'Nouaceur' },
    'MA-OUA': { defaultMessage: 'Ouarzazate' },
    'MA-OUD': { defaultMessage: 'Oued Ed-Dahab (EH)' },
    'MA-OUJ': { defaultMessage: 'Oujda-Angad' },
    'MA-OUZ': { defaultMessage: 'Ouezzane' },
    'MA-RAB': { defaultMessage: 'Rabat' },
    'MA-REH': { defaultMessage: 'Rehamna' },
    'MA-SAF': { defaultMessage: 'Safi' },
    'MA-SAL': { defaultMessage: 'Salé' },
    'MA-SEF': { defaultMessage: 'Sefrou' },
    'MA-SET': { defaultMessage: 'Settat' },
    'MA-SIB': { defaultMessage: 'Sidi Bennour' },
    'MA-SIF': { defaultMessage: 'Sidi Ifni' },
    'MA-SIK': { defaultMessage: 'Sidi Kacem' },
    'MA-SIL': { defaultMessage: 'Sidi Slimane' },
    'MA-SKH': { defaultMessage: 'Skhirate-Témara' },
    'MA-TAF': { defaultMessage: 'Tarfaya (EH-partial)' },
    'MA-TAI': { defaultMessage: 'Taourirt' },
    'MA-TAO': { defaultMessage: 'Taounate' },
    'MA-TAR': { defaultMessage: 'Taroudannt' },
    'MA-TAT': { defaultMessage: 'Tata' },
    'MA-TAZ': { defaultMessage: 'Taza' },
    'MA-TET': { defaultMessage: 'Tétouan' },
    'MA-TIN': { defaultMessage: 'Tinghir' },
    'MA-TIZ': { defaultMessage: 'Tiznit' },
    'MA-TNG': { defaultMessage: 'Tanger-Assilah' },
    'MA-TNT': { defaultMessage: 'Tan-Tan (EH-partial)' },
    'MA-YUS': { defaultMessage: 'Youssoufia' },
    'MA-ZAG': { defaultMessage: 'Zagora' },
    'MC-CL': { defaultMessage: 'La Colle' },
    'MC-CO': { defaultMessage: 'La Condamine' },
    'MC-FO': { defaultMessage: 'Fontvieille' },
    'MC-GA': { defaultMessage: 'La Gare' },
    'MC-JE': { defaultMessage: 'Jardin Exotique' },
    'MC-LA': { defaultMessage: 'Larvotto' },
    'MC-MA': { defaultMessage: 'Malbousquet' },
    'MC-MC': { defaultMessage: 'Monte-Carlo' },
    'MC-MG': { defaultMessage: 'Moneghetti' },
    'MC-MO': { defaultMessage: 'Monaco-Ville' },
    'MC-MU': { defaultMessage: 'Moulins' },
    'MC-PH': { defaultMessage: 'Port-Hercule' },
    'MC-SD': { defaultMessage: 'Sainte-Dévote' },
    'MC-SO': { defaultMessage: 'La Source' },
    'MC-SP': { defaultMessage: 'Spélugues' },
    'MC-SR': { defaultMessage: 'Saint-Roman' },
    'MC-VR': { defaultMessage: 'Vallon de la Rousse' },
    'MD-AN': { defaultMessage: 'Anenii Noi' },
    'MD-BA': { defaultMessage: 'Bălți' },
    'MD-BD': { defaultMessage: 'Bender' },
    'MD-BR': { defaultMessage: 'Briceni' },
    'MD-BS': { defaultMessage: 'Basarabeasca' },
    'MD-CA': { defaultMessage: 'Cahul' },
    'MD-CL': { defaultMessage: 'Călărași' },
    'MD-CM': { defaultMessage: 'Cimișlia' },
    'MD-CR': { defaultMessage: 'Criuleni' },
    'MD-CS': { defaultMessage: 'Căușeni' },
    'MD-CT': { defaultMessage: 'Cantemir' },
    'MD-CU': { defaultMessage: 'Chișinău' },
    'MD-DO': { defaultMessage: 'Dondușeni' },
    'MD-DR': { defaultMessage: 'Drochia' },
    'MD-DU': { defaultMessage: 'Dubăsari' },
    'MD-ED': { defaultMessage: 'Edineț' },
    'MD-FA': { defaultMessage: 'Fălești' },
    'MD-FL': { defaultMessage: 'Florești' },
    'MD-GA': {
      defaultMessage: 'Găgăuzia, Unitatea teritorială autonomă (UTAG)',
    },
    'MD-GL': { defaultMessage: 'Glodeni' },
    'MD-HI': { defaultMessage: 'Hîncești' },
    'MD-IA': { defaultMessage: 'Ialoveni' },
    'MD-LE': { defaultMessage: 'Leova' },
    'MD-NI': { defaultMessage: 'Nisporeni' },
    'MD-OC': { defaultMessage: 'Ocnița' },
    'MD-OR': { defaultMessage: 'Orhei' },
    'MD-RE': { defaultMessage: 'Rezina' },
    'MD-RI': { defaultMessage: 'Rîșcani' },
    'MD-SD': { defaultMessage: 'Șoldănești' },
    'MD-SI': { defaultMessage: 'Sîngerei' },
    'MD-SN': { defaultMessage: 'Stînga Nistrului, unitatea teritorială din' },
    'MD-SO': { defaultMessage: 'Soroca' },
    'MD-ST': { defaultMessage: 'Strășeni' },
    'MD-SV': { defaultMessage: 'Ștefan Vodă' },
    'MD-TA': { defaultMessage: 'Taraclia' },
    'MD-TE': { defaultMessage: 'Telenești' },
    'MD-UN': { defaultMessage: 'Ungheni' },
    'ME-01': { defaultMessage: 'Andrijevica' },
    'ME-02': { defaultMessage: 'Bar' },
    'ME-03': { defaultMessage: 'Berane' },
    'ME-04': { defaultMessage: 'Bijelo Polje' },
    'ME-05': { defaultMessage: 'Budva' },
    'ME-06': { defaultMessage: 'Cetinje' },
    'ME-07': { defaultMessage: 'Danilovgrad' },
    'ME-08': { defaultMessage: 'Herceg-Novi' },
    'ME-09': { defaultMessage: 'Kolašin' },
    'ME-10': { defaultMessage: 'Kotor' },
    'ME-11': { defaultMessage: 'Mojkovac' },
    'ME-12': { defaultMessage: 'Nikšić' },
    'ME-13': { defaultMessage: 'Plav' },
    'ME-14': { defaultMessage: 'Pljevlja' },
    'ME-15': { defaultMessage: 'Plužine' },
    'ME-16': { defaultMessage: 'Podgorica' },
    'ME-17': { defaultMessage: 'Rožaje' },
    'ME-18': { defaultMessage: 'Šavnik' },
    'ME-19': { defaultMessage: 'Tivat' },
    'ME-20': { defaultMessage: 'Ulcinj' },
    'ME-21': { defaultMessage: 'Žabljak' },
    'ME-22': { defaultMessage: 'Gusinje' },
    'ME-23': { defaultMessage: 'Petnjica' },
    'ME-24': { defaultMessage: 'Tuzi' },
    'MG-A': { defaultMessage: 'Toamasina' },
    'MG-D': { defaultMessage: 'Antsiranana' },
    'MG-F': { defaultMessage: 'Fianarantsoa' },
    'MG-M': { defaultMessage: 'Mahajanga' },
    'MG-T': { defaultMessage: 'Antananarivo' },
    'MG-U': { defaultMessage: 'Toliara' },
    'MH-ALK': { defaultMessage: 'Ailuk' },
    'MH-ALL': { defaultMessage: 'Ailinglaplap' },
    'MH-ARN': { defaultMessage: 'Arno' },
    'MH-AUR': { defaultMessage: 'Aur' },
    'MH-EBO': { defaultMessage: 'Ebon' },
    'MH-ENI': { defaultMessage: 'Enewetak & Ujelang' },
    'MH-JAB': { defaultMessage: 'Jabat' },
    'MH-JAL': { defaultMessage: 'Jaluit' },
    'MH-KIL': { defaultMessage: 'Bikini & Kili' },
    'MH-KWA': { defaultMessage: 'Kwajalein' },
    'MH-L': { defaultMessage: 'Ralik chain' },
    'MH-LAE': { defaultMessage: 'Lae' },
    'MH-LIB': { defaultMessage: 'Lib' },
    'MH-LIK': { defaultMessage: 'Likiep' },
    'MH-MAJ': { defaultMessage: 'Majuro' },
    'MH-MAL': { defaultMessage: 'Maloelap' },
    'MH-MEJ': { defaultMessage: 'Mejit' },
    'MH-MIL': { defaultMessage: 'Mili' },
    'MH-NMK': { defaultMessage: 'Namdrik' },
    'MH-NMU': { defaultMessage: 'Namu' },
    'MH-RON': { defaultMessage: 'Rongelap' },
    'MH-T': { defaultMessage: 'Ratak chain' },
    'MH-UJA': { defaultMessage: 'Ujae' },
    'MH-UTI': { defaultMessage: 'Utrik' },
    'MH-WTH': { defaultMessage: 'Wotho' },
    'MH-WTJ': { defaultMessage: 'Wotje' },
    'MK-101': { defaultMessage: 'Veles' },
    'MK-102': { defaultMessage: 'Gradsko' },
    'MK-103': { defaultMessage: 'Demir Kapija' },
    'MK-104': { defaultMessage: 'Kavadarci' },
    'MK-105': { defaultMessage: 'Lozovo' },
    'MK-106': { defaultMessage: 'Negotino' },
    'MK-107': { defaultMessage: 'Rosoman' },
    'MK-108': { defaultMessage: 'Sveti Nikole' },
    'MK-109': { defaultMessage: 'Čaška' },
    'MK-201': { defaultMessage: 'Berovo' },
    'MK-202': { defaultMessage: 'Vinica' },
    'MK-203': { defaultMessage: 'Delčevo' },
    'MK-204': { defaultMessage: 'Zrnovci' },
    'MK-205': { defaultMessage: 'Karbinci' },
    'MK-206': { defaultMessage: 'Kočani' },
    'MK-207': { defaultMessage: 'Makedonska Kamenica' },
    'MK-208': { defaultMessage: 'Pehčevo' },
    'MK-209': { defaultMessage: 'Probištip' },
    'MK-210': { defaultMessage: 'Češinovo-Obleševo' },
    'MK-211': { defaultMessage: 'Štip' },
    'MK-301': { defaultMessage: 'Vevčani' },
    'MK-303': { defaultMessage: 'Debar' },
    'MK-304': { defaultMessage: 'Debarca' },
    'MK-307': { defaultMessage: 'Kičevo' },
    'MK-308': { defaultMessage: 'Makedonski Brod' },
    'MK-310': { defaultMessage: 'Ohrid' },
    'MK-311': { defaultMessage: 'Plasnica' },
    'MK-312': { defaultMessage: 'Struga' },
    'MK-313': { defaultMessage: 'Centar Župa' },
    'MK-401': { defaultMessage: 'Bogdanci' },
    'MK-402': { defaultMessage: 'Bosilovo' },
    'MK-403': { defaultMessage: 'Valandovo' },
    'MK-404': { defaultMessage: 'Vasilevo' },
    'MK-405': { defaultMessage: 'Gevgelija' },
    'MK-406': { defaultMessage: 'Dojran' },
    'MK-407': { defaultMessage: 'Konče' },
    'MK-408': { defaultMessage: 'Novo Selo' },
    'MK-409': { defaultMessage: 'Radoviš' },
    'MK-410': { defaultMessage: 'Strumica' },
    'MK-501': { defaultMessage: 'Bitola' },
    'MK-502': { defaultMessage: 'Demir Hisar' },
    'MK-503': { defaultMessage: 'Dolneni' },
    'MK-504': { defaultMessage: 'Krivogaštani' },
    'MK-505': { defaultMessage: 'Kruševo' },
    'MK-506': { defaultMessage: 'Mogila' },
    'MK-507': { defaultMessage: 'Novaci' },
    'MK-508': { defaultMessage: 'Prilep' },
    'MK-509': { defaultMessage: 'Resen' },
    'MK-601': { defaultMessage: 'Bogovinje' },
    'MK-602': { defaultMessage: 'Brvenica' },
    'MK-603': { defaultMessage: 'Vrapčište' },
    'MK-604': { defaultMessage: 'Gostivar' },
    'MK-605': { defaultMessage: 'Želino' },
    'MK-606': { defaultMessage: 'Jegunovce' },
    'MK-607': { defaultMessage: 'Mavrovo i Rostuše' },
    'MK-608': { defaultMessage: 'Tearce' },
    'MK-609': { defaultMessage: 'Tetovo' },
    'MK-701': { defaultMessage: 'Kratovo' },
    'MK-702': { defaultMessage: 'Kriva Palanka' },
    'MK-703': { defaultMessage: 'Kumanovo' },
    'MK-704': { defaultMessage: 'Lipkovo' },
    'MK-705': { defaultMessage: 'Rankovce' },
    'MK-706': { defaultMessage: 'Staro Nagoričane' },
    'MK-801': { defaultMessage: 'Aerodrom' },
    'MK-802': { defaultMessage: 'Aračinovo' },
    'MK-803': { defaultMessage: 'Butel' },
    'MK-804': { defaultMessage: 'Gazi Baba' },
    'MK-805': { defaultMessage: 'Gjorče Petrov' },
    'MK-806': { defaultMessage: 'Zelenikovo' },
    'MK-807': { defaultMessage: 'Ilinden' },
    'MK-808': { defaultMessage: 'Karpoš' },
    'MK-809': { defaultMessage: 'Kisela Voda' },
    'MK-810': { defaultMessage: 'Petrovec' },
    'MK-811': { defaultMessage: 'Saraj' },
    'MK-812': { defaultMessage: 'Sopište' },
    'MK-813': { defaultMessage: 'Studeničani' },
    'MK-814': { defaultMessage: 'Centar' },
    'MK-815': { defaultMessage: 'Čair' },
    'MK-816': { defaultMessage: 'Čučer-Sandevo' },
    'MK-817': { defaultMessage: 'Šuto Orizari' },
    'ML-1': { defaultMessage: 'Kayes' },
    'ML-10': {
      defaultMessage: 'Taoudénit(local variants are Taoudenni, Taoudéni)',
    },
    'ML-2': { defaultMessage: 'Koulikoro' },
    'ML-3': { defaultMessage: 'Sikasso' },
    'ML-4': { defaultMessage: 'Ségou' },
    'ML-5': { defaultMessage: 'Mopti' },
    'ML-6': { defaultMessage: 'Tombouctou' },
    'ML-7': { defaultMessage: 'Gao' },
    'ML-8': { defaultMessage: 'Kidal' },
    'ML-9': { defaultMessage: 'Ménaka' },
    'ML-BKO': { defaultMessage: 'Bamako' },
    'MM-01': { defaultMessage: 'Sagaing' },
    'MM-02': { defaultMessage: 'Bago' },
    'MM-03': { defaultMessage: 'Magway' },
    'MM-04': { defaultMessage: 'Mandalay' },
    'MM-05': { defaultMessage: 'Tanintharyi' },
    'MM-06': { defaultMessage: 'Yangon' },
    'MM-07': { defaultMessage: 'Ayeyarwady' },
    'MM-11': { defaultMessage: 'Kachin' },
    'MM-12': { defaultMessage: 'Kayah' },
    'MM-13': { defaultMessage: 'Kayin' },
    'MM-14': { defaultMessage: 'Chin' },
    'MM-15': { defaultMessage: 'Mon' },
    'MM-16': { defaultMessage: 'Rakhine' },
    'MM-17': { defaultMessage: 'Shan' },
    'MM-18': { defaultMessage: 'Nay Pyi Taw' },
    'MN-035': { defaultMessage: 'Orhon' },
    'MN-037': { defaultMessage: 'Darhan uul' },
    'MN-039': { defaultMessage: 'Hentiy' },
    'MN-041': { defaultMessage: 'Hövsgöl' },
    'MN-043': { defaultMessage: 'Hovd' },
    'MN-046': { defaultMessage: 'Uvs' },
    'MN-047': { defaultMessage: 'Töv' },
    'MN-049': { defaultMessage: 'Selenge' },
    'MN-051': { defaultMessage: 'Sühbaatar' },
    'MN-053': { defaultMessage: 'Ömnögovĭ' },
    'MN-055': { defaultMessage: 'Övörhangay' },
    'MN-057': { defaultMessage: 'Dzavhan' },
    'MN-059': { defaultMessage: 'Dundgovĭ' },
    'MN-061': { defaultMessage: 'Dornod' },
    'MN-063': { defaultMessage: 'Dornogovĭ' },
    'MN-064': { defaultMessage: 'Govĭ-Sümber' },
    'MN-065': { defaultMessage: 'Govĭ-Altay' },
    'MN-067': { defaultMessage: 'Bulgan' },
    'MN-069': { defaultMessage: 'Bayanhongor' },
    'MN-071': { defaultMessage: 'Bayan-Ölgiy' },
    'MN-073': { defaultMessage: 'Arhangay' },
    'MN-1': { defaultMessage: 'Ulaanbaatar' },
    'MR-01': { defaultMessage: 'Hodh ech Chargui' },
    'MR-02': { defaultMessage: 'Hodh el Gharbi' },
    'MR-03': { defaultMessage: 'Assaba' },
    'MR-04': { defaultMessage: 'Gorgol' },
    'MR-05': { defaultMessage: 'Brakna' },
    'MR-06': { defaultMessage: 'Trarza' },
    'MR-07': { defaultMessage: 'Adrar' },
    'MR-08': { defaultMessage: 'Dakhlet Nouâdhibou' },
    'MR-09': { defaultMessage: 'Tagant' },
    'MR-10': { defaultMessage: 'Guidimaka' },
    'MR-11': { defaultMessage: 'Tiris Zemmour' },
    'MR-12': { defaultMessage: 'Inchiri' },
    'MR-13': { defaultMessage: 'Nuwākshūţ al Gharbīyah' },
    'MR-14': { defaultMessage: 'Nuwākshūţ ash Shamālīyah' },
    'MR-15': { defaultMessage: 'Nuwākshūţ al Janūbīyah' },
    'MT-01': { defaultMessage: 'Attard' },
    'MT-02': { defaultMessage: 'Balzan' },
    'MT-03': { defaultMessage: 'Birgu' },
    'MT-04': { defaultMessage: 'Birkirkara' },
    'MT-05': { defaultMessage: 'Birżebbuġa' },
    'MT-06': { defaultMessage: 'Bormla' },
    'MT-07': { defaultMessage: 'Dingli' },
    'MT-08': { defaultMessage: 'Fgura' },
    'MT-09': { defaultMessage: 'Floriana' },
    'MT-10': { defaultMessage: 'Fontana' },
    'MT-11': { defaultMessage: 'Gudja' },
    'MT-12': { defaultMessage: 'Gżira' },
    'MT-13': { defaultMessage: 'Għajnsielem' },
    'MT-14': { defaultMessage: 'Għarb' },
    'MT-15': { defaultMessage: 'Għargħur' },
    'MT-16': { defaultMessage: 'Għasri' },
    'MT-17': { defaultMessage: 'Għaxaq' },
    'MT-18': { defaultMessage: 'Ħamrun' },
    'MT-19': { defaultMessage: 'Iklin' },
    'MT-20': { defaultMessage: 'Isla' },
    'MT-21': { defaultMessage: 'Kalkara' },
    'MT-22': { defaultMessage: 'Kerċem' },
    'MT-23': { defaultMessage: 'Kirkop' },
    'MT-24': { defaultMessage: 'Lija' },
    'MT-25': { defaultMessage: 'Luqa' },
    'MT-26': { defaultMessage: 'Marsa' },
    'MT-27': { defaultMessage: 'Marsaskala' },
    'MT-28': { defaultMessage: 'Marsaxlokk' },
    'MT-29': { defaultMessage: 'Mdina' },
    'MT-30': { defaultMessage: 'Mellieħa' },
    'MT-31': { defaultMessage: 'Mġarr' },
    'MT-32': { defaultMessage: 'Mosta' },
    'MT-33': { defaultMessage: 'Mqabba' },
    'MT-34': { defaultMessage: 'Msida' },
    'MT-35': { defaultMessage: 'Mtarfa' },
    'MT-36': { defaultMessage: 'Munxar' },
    'MT-37': { defaultMessage: 'Nadur' },
    'MT-38': { defaultMessage: 'Naxxar' },
    'MT-39': { defaultMessage: 'Paola' },
    'MT-40': { defaultMessage: 'Pembroke' },
    'MT-41': { defaultMessage: 'Pietà' },
    'MT-42': { defaultMessage: 'Qala' },
    'MT-43': { defaultMessage: 'Qormi' },
    'MT-44': { defaultMessage: 'Qrendi' },
    'MT-45': { defaultMessage: 'Rabat Gozo' },
    'MT-46': { defaultMessage: 'Rabat Malta' },
    'MT-47': { defaultMessage: 'Safi' },
    'MT-48': { defaultMessage: "Saint Julian's" },
    'MT-49': { defaultMessage: 'Saint John' },
    'MT-50': { defaultMessage: 'Saint Lawrence' },
    'MT-51': { defaultMessage: "Saint Paul's Bay" },
    'MT-52': { defaultMessage: 'Sannat' },
    'MT-53': { defaultMessage: "Saint Lucia's" },
    'MT-54': { defaultMessage: 'Santa Venera' },
    'MT-55': { defaultMessage: 'Siġġiewi' },
    'MT-56': { defaultMessage: 'Sliema' },
    'MT-57': { defaultMessage: 'Swieqi' },
    'MT-58': { defaultMessage: "Ta' Xbiex" },
    'MT-59': { defaultMessage: 'Tarxien' },
    'MT-60': { defaultMessage: 'Valletta' },
    'MT-61': { defaultMessage: 'Xagħra' },
    'MT-62': { defaultMessage: 'Xewkija' },
    'MT-63': { defaultMessage: 'Xgħajra' },
    'MT-64': { defaultMessage: 'Żabbar' },
    'MT-65': { defaultMessage: 'Żebbuġ Gozo' },
    'MT-66': { defaultMessage: 'Żebbuġ Malta' },
    'MT-67': { defaultMessage: 'Żejtun' },
    'MT-68': { defaultMessage: 'Żurrieq' },
    'MU-AG': { defaultMessage: 'Agalega Islands' },
    'MU-BL': { defaultMessage: 'Black River' },
    'MU-CC': {
      defaultMessage:
        'Cargados Carajos Shoals(local variant is Saint Brandon Islands)',
    },
    'MU-FL': { defaultMessage: 'Flacq' },
    'MU-GP': { defaultMessage: 'Grand Port' },
    'MU-MO': { defaultMessage: 'Moka' },
    'MU-PA': { defaultMessage: 'Pamplemousses' },
    'MU-PL': { defaultMessage: 'Port Louis' },
    'MU-PW': { defaultMessage: 'Plaines Wilhems' },
    'MU-RO': { defaultMessage: 'Rodrigues Island' },
    'MU-RR': { defaultMessage: 'Rivière du Rempart' },
    'MU-SA': { defaultMessage: 'Savanne' },
    'MV-00': { defaultMessage: 'South Ari Atoll' },
    'MV-01': { defaultMessage: 'Addu City' },
    'MV-02': { defaultMessage: 'North Ari Atoll' },
    'MV-03': { defaultMessage: 'Faadhippolhu' },
    'MV-04': { defaultMessage: 'Felidhu Atoll' },
    'MV-05': { defaultMessage: 'Hahdhunmathi' },
    'MV-07': { defaultMessage: 'North Thiladhunmathi' },
    'MV-08': { defaultMessage: 'Kolhumadulu' },
    'MV-12': { defaultMessage: 'Mulaku Atoll' },
    'MV-13': { defaultMessage: 'North Maalhosmadulu' },
    'MV-14': { defaultMessage: 'North Nilandhe Atoll' },
    'MV-17': { defaultMessage: 'South Nilandhe Atoll' },
    'MV-20': { defaultMessage: 'South Maalhosmadulu' },
    'MV-23': { defaultMessage: 'South Thiladhunmathi' },
    'MV-24': { defaultMessage: 'North Miladhunmadulu' },
    'MV-25': { defaultMessage: 'South Miladhunmadulu' },
    'MV-26': { defaultMessage: 'Male Atoll' },
    'MV-27': { defaultMessage: 'North Huvadhu Atoll' },
    'MV-28': { defaultMessage: 'South Huvadhu Atoll' },
    'MV-29': { defaultMessage: 'Fuvammulah' },
    'MV-MLE': { defaultMessage: 'Male' },
    'MW-BA': { defaultMessage: 'Balaka' },
    'MW-BL': { defaultMessage: 'Blantyre' },
    'MW-C': { defaultMessage: 'Central Region' },
    'MW-CK': { defaultMessage: 'Chikwawa' },
    'MW-CR': { defaultMessage: 'Chiradzulu' },
    'MW-CT': { defaultMessage: 'Chitipa' },
    'MW-DE': { defaultMessage: 'Dedza' },
    'MW-DO': { defaultMessage: 'Dowa' },
    'MW-KR': { defaultMessage: 'Karonga' },
    'MW-KS': { defaultMessage: 'Kasungu' },
    'MW-LI': { defaultMessage: 'Lilongwe' },
    'MW-LK': { defaultMessage: 'Likoma' },
    'MW-MC': { defaultMessage: 'Mchinji' },
    'MW-MG': { defaultMessage: 'Mangochi' },
    'MW-MH': { defaultMessage: 'Machinga' },
    'MW-MU': { defaultMessage: 'Mulanje' },
    'MW-MW': { defaultMessage: 'Mwanza' },
    'MW-MZ': { defaultMessage: 'Mzimba' },
    'MW-N': { defaultMessage: 'Northern Region' },
    'MW-NB': { defaultMessage: 'Nkhata Bay' },
    'MW-NE': { defaultMessage: 'Neno' },
    'MW-NI': { defaultMessage: 'Ntchisi' },
    'MW-NK': { defaultMessage: 'Nkhotakota' },
    'MW-NS': { defaultMessage: 'Nsanje' },
    'MW-NU': { defaultMessage: 'Ntcheu' },
    'MW-PH': { defaultMessage: 'Phalombe' },
    'MW-RU': { defaultMessage: 'Rumphi' },
    'MW-S': { defaultMessage: 'Southern Region' },
    'MW-SA': { defaultMessage: 'Salima' },
    'MW-TH': { defaultMessage: 'Thyolo' },
    'MW-ZO': { defaultMessage: 'Zomba' },
    'MX-AGU': { defaultMessage: 'Aguascalientes' },
    'MX-BCN': { defaultMessage: 'Baja California' },
    'MX-BCS': { defaultMessage: 'Baja California Sur' },
    'MX-CAM': { defaultMessage: 'Campeche' },
    'MX-CHH': { defaultMessage: 'Chihuahua' },
    'MX-CHP': { defaultMessage: 'Chiapas' },
    'MX-CMX': { defaultMessage: 'Ciudad de México' },
    'MX-COA': { defaultMessage: 'Coahuila de Zaragoza' },
    'MX-COL': { defaultMessage: 'Colima' },
    'MX-DUR': { defaultMessage: 'Durango' },
    'MX-GRO': { defaultMessage: 'Guerrero' },
    'MX-GUA': { defaultMessage: 'Guanajuato' },
    'MX-HID': { defaultMessage: 'Hidalgo' },
    'MX-JAL': { defaultMessage: 'Jalisco' },
    'MX-MEX': { defaultMessage: 'México' },
    'MX-MIC': { defaultMessage: 'Michoacán de Ocampo' },
    'MX-MOR': { defaultMessage: 'Morelos' },
    'MX-NAY': { defaultMessage: 'Nayarit' },
    'MX-NLE': { defaultMessage: 'Nuevo León' },
    'MX-OAX': { defaultMessage: 'Oaxaca' },
    'MX-PUE': { defaultMessage: 'Puebla' },
    'MX-QUE': { defaultMessage: 'Querétaro' },
    'MX-ROO': { defaultMessage: 'Quintana Roo' },
    'MX-SIN': { defaultMessage: 'Sinaloa' },
    'MX-SLP': { defaultMessage: 'San Luis Potosí' },
    'MX-SON': { defaultMessage: 'Sonora' },
    'MX-TAB': { defaultMessage: 'Tabasco' },
    'MX-TAM': { defaultMessage: 'Tamaulipas' },
    'MX-TLA': { defaultMessage: 'Tlaxcala' },
    'MX-VER': { defaultMessage: 'Veracruz de Ignacio de la Llave' },
    'MX-YUC': { defaultMessage: 'Yucatán' },
    'MX-ZAC': { defaultMessage: 'Zacatecas' },
    'MY-01': { defaultMessage: 'Johor' },
    'MY-02': { defaultMessage: 'Kedah' },
    'MY-03': { defaultMessage: 'Kelantan' },
    'MY-04': { defaultMessage: 'Melaka' },
    'MY-05': { defaultMessage: 'Negeri Sembilan' },
    'MY-06': { defaultMessage: 'Pahang' },
    'MY-07': { defaultMessage: 'Pulau Pinang' },
    'MY-08': { defaultMessage: 'Perak' },
    'MY-09': { defaultMessage: 'Perlis' },
    'MY-10': { defaultMessage: 'Selangor' },
    'MY-11': { defaultMessage: 'Terengganu' },
    'MY-12': { defaultMessage: 'Sabah' },
    'MY-13': { defaultMessage: 'Sarawak' },
    'MY-14': { defaultMessage: 'Wilayah Persekutuan Kuala Lumpur' },
    'MY-15': { defaultMessage: 'Wilayah Persekutuan Labuan' },
    'MY-16': { defaultMessage: 'Wilayah Persekutuan Putrajaya' },
    'MZ-A': { defaultMessage: 'Niassa' },
    'MZ-B': { defaultMessage: 'Manica' },
    'MZ-G': { defaultMessage: 'Gaza' },
    'MZ-I': { defaultMessage: 'Inhambane' },
    'MZ-L': { defaultMessage: 'Maputo' },
    'MZ-MPM': { defaultMessage: 'Maputo' },
    'MZ-N': { defaultMessage: 'Nampula' },
    'MZ-P': { defaultMessage: 'Cabo Delgado' },
    'MZ-Q': { defaultMessage: 'Zambézia' },
    'MZ-S': { defaultMessage: 'Sofala' },
    'MZ-T': { defaultMessage: 'Tete' },
    'NA-CA': { defaultMessage: 'Zambezi' },
    'NA-ER': { defaultMessage: 'Erongo' },
    'NA-HA': { defaultMessage: 'Hardap' },
    'NA-KA': { defaultMessage: '//Karas(local variants are Karas and !Karas)' },
    'NA-KE': { defaultMessage: 'Kavango East' },
    'NA-KH': { defaultMessage: 'Khomas' },
    'NA-KU': { defaultMessage: 'Kunene' },
    'NA-KW': { defaultMessage: 'Kavango West' },
    'NA-OD': { defaultMessage: 'Otjozondjupa' },
    'NA-OH': { defaultMessage: 'Omaheke' },
    'NA-ON': { defaultMessage: 'Oshana' },
    'NA-OS': { defaultMessage: 'Omusati' },
    'NA-OT': { defaultMessage: 'Oshikoto' },
    'NA-OW': { defaultMessage: 'Ohangwena' },
    'NE-1': { defaultMessage: 'Agadez' },
    'NE-2': { defaultMessage: 'Diffa' },
    'NE-3': { defaultMessage: 'Dosso' },
    'NE-4': { defaultMessage: 'Maradi' },
    'NE-5': { defaultMessage: 'Tahoua' },
    'NE-6': { defaultMessage: 'Tillabéri' },
    'NE-7': { defaultMessage: 'Zinder' },
    'NE-8': { defaultMessage: 'Niamey' },
    'NG-AB': { defaultMessage: 'Abia' },
    'NG-AD': { defaultMessage: 'Adamawa' },
    'NG-AK': { defaultMessage: 'Akwa Ibom' },
    'NG-AN': { defaultMessage: 'Anambra' },
    'NG-BA': { defaultMessage: 'Bauchi' },
    'NG-BE': { defaultMessage: 'Benue' },
    'NG-BO': { defaultMessage: 'Borno' },
    'NG-BY': { defaultMessage: 'Bayelsa' },
    'NG-CR': { defaultMessage: 'Cross River' },
    'NG-DE': { defaultMessage: 'Delta' },
    'NG-EB': { defaultMessage: 'Ebonyi' },
    'NG-ED': { defaultMessage: 'Edo' },
    'NG-EK': { defaultMessage: 'Ekiti' },
    'NG-EN': { defaultMessage: 'Enugu' },
    'NG-FC': { defaultMessage: 'Abuja Federal Capital Territory' },
    'NG-GO': { defaultMessage: 'Gombe' },
    'NG-IM': { defaultMessage: 'Imo' },
    'NG-JI': { defaultMessage: 'Jigawa' },
    'NG-KD': { defaultMessage: 'Kaduna' },
    'NG-KE': { defaultMessage: 'Kebbi' },
    'NG-KN': { defaultMessage: 'Kano' },
    'NG-KO': { defaultMessage: 'Kogi' },
    'NG-KT': { defaultMessage: 'Katsina' },
    'NG-KW': { defaultMessage: 'Kwara' },
    'NG-LA': { defaultMessage: 'Lagos' },
    'NG-NA': { defaultMessage: 'Nasarawa' },
    'NG-NI': { defaultMessage: 'Niger' },
    'NG-OG': { defaultMessage: 'Ogun' },
    'NG-ON': { defaultMessage: 'Ondo' },
    'NG-OS': { defaultMessage: 'Osun' },
    'NG-OY': { defaultMessage: 'Oyo' },
    'NG-PL': { defaultMessage: 'Plateau' },
    'NG-RI': { defaultMessage: 'Rivers' },
    'NG-SO': { defaultMessage: 'Sokoto' },
    'NG-TA': { defaultMessage: 'Taraba' },
    'NG-YO': { defaultMessage: 'Yobe' },
    'NG-ZA': { defaultMessage: 'Zamfara' },
    'NI-AN': { defaultMessage: 'Costa Caribe Norte' },
    'NI-AS': { defaultMessage: 'Costa Caribe Sur' },
    'NI-BO': { defaultMessage: 'Boaco' },
    'NI-CA': { defaultMessage: 'Carazo' },
    'NI-CI': { defaultMessage: 'Chinandega' },
    'NI-CO': { defaultMessage: 'Chontales' },
    'NI-ES': { defaultMessage: 'Estelí' },
    'NI-GR': { defaultMessage: 'Granada' },
    'NI-JI': { defaultMessage: 'Jinotega' },
    'NI-LE': { defaultMessage: 'León' },
    'NI-MD': { defaultMessage: 'Madriz' },
    'NI-MN': { defaultMessage: 'Managua' },
    'NI-MS': { defaultMessage: 'Masaya' },
    'NI-MT': { defaultMessage: 'Matagalpa' },
    'NI-NS': { defaultMessage: 'Nueva Segovia' },
    'NI-RI': { defaultMessage: 'Rivas' },
    'NI-SJ': { defaultMessage: 'Río San Juan' },
    'NL-AW': { defaultMessage: 'Aruba' },
    'NL-BQ1': { defaultMessage: 'Bonaire' },
    'NL-BQ2': { defaultMessage: 'Saba' },
    'NL-BQ3': { defaultMessage: 'Sint Eustatius' },
    'NL-CW': { defaultMessage: 'Curaçao' },
    'NL-DR': { defaultMessage: 'Drenthe' },
    'NL-FL': { defaultMessage: 'Flevoland' },
    'NL-FR': { defaultMessage: 'Fryslân' },
    'NL-GE': { defaultMessage: 'Gelderland' },
    'NL-GR': { defaultMessage: 'Groningen' },
    'NL-LI': { defaultMessage: 'Limburg' },
    'NL-NB': { defaultMessage: 'Noord-Brabant' },
    'NL-NH': { defaultMessage: 'Noord-Holland' },
    'NL-OV': { defaultMessage: 'Overijssel' },
    'NL-SX': { defaultMessage: 'Sint Maarten' },
    'NL-UT': { defaultMessage: 'Utrecht' },
    'NL-ZE': { defaultMessage: 'Zeeland' },
    'NL-ZH': { defaultMessage: 'Zuid-Holland' },
    'NO-03': { defaultMessage: 'Oslo' },
    'NO-11': { defaultMessage: 'Rogaland' },
    'NO-15': { defaultMessage: 'Møre og Romsdal' },
    'NO-18': { defaultMessage: 'Nordland' },
    'NO-21': { defaultMessage: 'Svalbard' },
    'NO-22': { defaultMessage: 'Jan Mayen' },
    'NO-30': { defaultMessage: 'Viken' },
    'NO-34': { defaultMessage: 'Innlandet' },
    'NO-38': { defaultMessage: 'Vestfold og Telemark' },
    'NO-42': { defaultMessage: 'Agder' },
    'NO-46': { defaultMessage: 'Vestland' },
    'NO-50': { defaultMessage: 'Trøndelag / Trööndelage (-)' },
    'NO-54': { defaultMessage: 'Troms og Finnmark / Romsa ja Finnmárku' },
    'NP-1': { defaultMessage: 'Madhyamanchal' },
    'NP-2': { defaultMessage: 'Madhya Pashchimanchal' },
    'NP-3': { defaultMessage: 'Pashchimanchal' },
    'NP-4': { defaultMessage: 'Purwanchal' },
    'NP-5': { defaultMessage: 'Sudur Pashchimanchal' },
    'NP-BA': { defaultMessage: 'Bagmati' },
    'NP-BH': { defaultMessage: 'Bheri' },
    'NP-DH': { defaultMessage: 'Dhawalagiri' },
    'NP-GA': { defaultMessage: 'Gandaki' },
    'NP-JA': { defaultMessage: 'Janakpur' },
    'NP-KA': { defaultMessage: 'Karnali' },
    'NP-KO': { defaultMessage: 'Kosi(local variant is Koshi)' },
    'NP-LU': { defaultMessage: 'Lumbini' },
    'NP-MA': { defaultMessage: 'Mahakali' },
    'NP-ME': { defaultMessage: 'Mechi' },
    'NP-NA': { defaultMessage: 'Narayani' },
    'NP-P1': { defaultMessage: 'Pradesh 1' },
    'NP-P2': { defaultMessage: 'Pradesh 2' },
    'NP-P3': { defaultMessage: 'Bāgmatī' },
    'NP-P4': { defaultMessage: 'Gandaki' },
    'NP-P5': { defaultMessage: 'Lumbini' },
    'NP-P6': { defaultMessage: 'Karnali' },
    'NP-P7': { defaultMessage: 'Sudūr Pashchim' },
    'NP-RA': { defaultMessage: 'Rapti' },
    'NP-SA': { defaultMessage: 'Sagarmatha' },
    'NP-SE': { defaultMessage: 'Seti' },
    'NR-01': { defaultMessage: 'Aiwo' },
    'NR-02': { defaultMessage: 'Anabar' },
    'NR-03': { defaultMessage: 'Anetan' },
    'NR-04': { defaultMessage: 'Anibare' },
    'NR-05': { defaultMessage: 'Baitsi (local variant is Baiti)' },
    'NR-06': { defaultMessage: 'Boe' },
    'NR-07': { defaultMessage: 'Buada' },
    'NR-08': { defaultMessage: 'Denigomodu' },
    'NR-09': { defaultMessage: 'Ewa' },
    'NR-10': { defaultMessage: 'Ijuw' },
    'NR-11': { defaultMessage: 'Meneng' },
    'NR-12': { defaultMessage: 'Nibok' },
    'NR-13': { defaultMessage: 'Uaboe' },
    'NR-14': { defaultMessage: 'Yaren' },
    'NZ-AUK': { defaultMessage: 'Auckland' },
    'NZ-BOP': { defaultMessage: 'Bay of Plenty' },
    'NZ-CAN': { defaultMessage: 'Canterbury' },
    'NZ-CIT': { defaultMessage: 'Chatham Islands Territory' },
    'NZ-GIS': { defaultMessage: 'Gisborne' },
    'NZ-HKB': { defaultMessage: "Hawke's Bay" },
    'NZ-MBH': { defaultMessage: 'Marlborough' },
    'NZ-MWT': { defaultMessage: 'Manawatu-Wanganui' },
    'NZ-NSN': { defaultMessage: 'Nelson' },
    'NZ-NTL': { defaultMessage: 'Northland' },
    'NZ-OTA': { defaultMessage: 'Otago' },
    'NZ-STL': { defaultMessage: 'Southland' },
    'NZ-TAS': { defaultMessage: 'Tasman' },
    'NZ-TKI': { defaultMessage: 'Taranaki' },
    'NZ-WGN': { defaultMessage: 'Wellington' },
    'NZ-WKO': { defaultMessage: 'Waikato' },
    'NZ-WTC': { defaultMessage: 'West Coast' },
    'OM-BJ': { defaultMessage: 'Janūb al Bāţinah' },
    'OM-BS': { defaultMessage: 'Shamāl al Bāţinah' },
    'OM-BU': { defaultMessage: 'Al Buraymī' },
    'OM-DA': { defaultMessage: 'Ad Dākhilīyah' },
    'OM-MA': { defaultMessage: 'Masqaţ' },
    'OM-MU': { defaultMessage: 'Musandam' },
    'OM-SJ': { defaultMessage: 'Janūb ash Sharqīyah' },
    'OM-SS': { defaultMessage: 'Shamāl ash Sharqīyah' },
    'OM-WU': { defaultMessage: 'Al Wusţá' },
    'OM-ZA': { defaultMessage: 'Az̧ Z̧āhirah' },
    'OM-ZU': { defaultMessage: 'Z̧ufār' },
    'PA-1': { defaultMessage: 'Bocas del Toro' },
    'PA-10': { defaultMessage: 'Panamá Oeste' },
    'PA-2': { defaultMessage: 'Coclé' },
    'PA-3': { defaultMessage: 'Colón' },
    'PA-4': { defaultMessage: 'Chiriquí' },
    'PA-5': { defaultMessage: 'Darién' },
    'PA-6': { defaultMessage: 'Herrera' },
    'PA-7': { defaultMessage: 'Los Santos' },
    'PA-8': { defaultMessage: 'Panamá' },
    'PA-9': { defaultMessage: 'Veraguas' },
    'PA-EM': { defaultMessage: 'Emberá' },
    'PA-KY': { defaultMessage: 'Guna Yala(local variant is Kuna Yala)' },
    'PA-NB': { defaultMessage: 'Ngöbe-Buglé' },
    'PE-AMA': { defaultMessage: 'Amazonas' },
    'PE-ANC': { defaultMessage: 'Ancash' },
    'PE-APU': { defaultMessage: 'Apurímac' },
    'PE-ARE': { defaultMessage: 'Arequipa' },
    'PE-AYA': { defaultMessage: 'Ayacucho' },
    'PE-CAJ': { defaultMessage: 'Cajamarca' },
    'PE-CAL': { defaultMessage: 'El Callao' },
    'PE-CUS': { defaultMessage: 'Cusco(local variant is Cuzco)' },
    'PE-HUC': { defaultMessage: 'Huánuco' },
    'PE-HUV': { defaultMessage: 'Huancavelica' },
    'PE-ICA': { defaultMessage: 'Ica' },
    'PE-JUN': { defaultMessage: 'Junín' },
    'PE-LAL': { defaultMessage: 'La Libertad' },
    'PE-LAM': { defaultMessage: 'Lambayeque' },
    'PE-LIM': { defaultMessage: 'Lima' },
    'PE-LMA': { defaultMessage: 'Municipalidad Metropolitana de Lima' },
    'PE-LOR': { defaultMessage: 'Loreto' },
    'PE-MDD': { defaultMessage: 'Madre de Dios' },
    'PE-MOQ': { defaultMessage: 'Moquegua' },
    'PE-PAS': { defaultMessage: 'Pasco' },
    'PE-PIU': { defaultMessage: 'Piura' },
    'PE-PUN': { defaultMessage: 'Puno' },
    'PE-SAM': { defaultMessage: 'San Martín' },
    'PE-TAC': { defaultMessage: 'Tacna' },
    'PE-TUM': { defaultMessage: 'Tumbes' },
    'PE-UCA': { defaultMessage: 'Ucayali' },
    'PG-CPK': { defaultMessage: 'Chimbu' },
    'PG-CPM': { defaultMessage: 'Central' },
    'PG-EBR': { defaultMessage: 'East New Britain' },
    'PG-EHG': { defaultMessage: 'Eastern Highlands' },
    'PG-EPW': { defaultMessage: 'Enga' },
    'PG-ESW': { defaultMessage: 'East Sepik' },
    'PG-GPK': { defaultMessage: 'Gulf' },
    'PG-HLA': { defaultMessage: 'Hela' },
    'PG-JWK': { defaultMessage: 'Jiwaka' },
    'PG-MBA': { defaultMessage: 'Milne Bay' },
    'PG-MPL': { defaultMessage: 'Morobe' },
    'PG-MPM': { defaultMessage: 'Madang' },
    'PG-MRL': { defaultMessage: 'Manus' },
    'PG-NCD': { defaultMessage: 'National Capital District (Port Moresby)' },
    'PG-NIK': { defaultMessage: 'New Ireland' },
    'PG-NPP': { defaultMessage: 'Northern' },
    'PG-NSB': { defaultMessage: 'Bougainville' },
    'PG-SAN': { defaultMessage: 'West Sepik' },
    'PG-SHM': { defaultMessage: 'Southern Highlands' },
    'PG-WBK': { defaultMessage: 'West New Britain' },
    'PG-WHM': { defaultMessage: 'Western Highlands' },
    'PG-WPD': { defaultMessage: 'Western' },
    'PH-00': { defaultMessage: 'National Capital Region' },
    'PH-01': { defaultMessage: 'Ilocos' },
    'PH-02': { defaultMessage: 'Cagayan Valley' },
    'PH-03': { defaultMessage: 'Central Luzon' },
    'PH-05': { defaultMessage: 'Bicol' },
    'PH-06': { defaultMessage: 'Western Visayas' },
    'PH-07': { defaultMessage: 'Central Visayas' },
    'PH-08': { defaultMessage: 'Eastern Visayas' },
    'PH-09': { defaultMessage: 'Zamboanga Peninsula' },
    'PH-10': { defaultMessage: 'Northern Mindanao' },
    'PH-11': { defaultMessage: 'Davao' },
    'PH-12': { defaultMessage: 'Soccsksargen' },
    'PH-13': { defaultMessage: 'Caraga' },
    'PH-14': { defaultMessage: 'Autonomous Region in Muslim Mindanao' },
    'PH-15': { defaultMessage: 'Cordillera Administrative Region' },
    'PH-40': { defaultMessage: 'Calabarzon' },
    'PH-41': { defaultMessage: 'Mimaropa' },
    'PH-ABR': { defaultMessage: 'Abra' },
    'PH-AGN': { defaultMessage: 'Agusan del Norte' },
    'PH-AGS': { defaultMessage: 'Agusan del Sur' },
    'PH-AKL': { defaultMessage: 'Aklan' },
    'PH-ALB': { defaultMessage: 'Albay' },
    'PH-ANT': { defaultMessage: 'Antique' },
    'PH-APA': { defaultMessage: 'Apayao' },
    'PH-AUR': { defaultMessage: 'Aurora' },
    'PH-BAN': { defaultMessage: 'Bataan' },
    'PH-BAS': { defaultMessage: 'Basilan' },
    'PH-BEN': { defaultMessage: 'Benguet' },
    'PH-BIL': { defaultMessage: 'Biliran' },
    'PH-BOH': { defaultMessage: 'Bohol' },
    'PH-BTG': { defaultMessage: 'Batangas' },
    'PH-BTN': { defaultMessage: 'Batanes' },
    'PH-BUK': { defaultMessage: 'Bukidnon' },
    'PH-BUL': { defaultMessage: 'Bulacan' },
    'PH-CAG': { defaultMessage: 'Cagayan' },
    'PH-CAM': { defaultMessage: 'Camiguin' },
    'PH-CAN': { defaultMessage: 'Camarines Norte' },
    'PH-CAP': { defaultMessage: 'Capiz' },
    'PH-CAS': { defaultMessage: 'Camarines Sur' },
    'PH-CAT': { defaultMessage: 'Catanduanes' },
    'PH-CAV': { defaultMessage: 'Cavite' },
    'PH-CEB': { defaultMessage: 'Cebu' },
    'PH-COM': { defaultMessage: 'Davao de Oro' },
    'PH-DAO': { defaultMessage: 'Davao Oriental' },
    'PH-DAS': { defaultMessage: 'Davao del Sur' },
    'PH-DAV': { defaultMessage: 'Davao del Norte' },
    'PH-DIN': { defaultMessage: 'Dinagat Islands' },
    'PH-DVO': { defaultMessage: 'Davao Occidental' },
    'PH-EAS': { defaultMessage: 'Eastern Samar' },
    'PH-GUI': { defaultMessage: 'Guimaras' },
    'PH-IFU': { defaultMessage: 'Ifugao' },
    'PH-ILI': { defaultMessage: 'Iloilo' },
    'PH-ILN': { defaultMessage: 'Ilocos Norte' },
    'PH-ILS': { defaultMessage: 'Ilocos Sur' },
    'PH-ISA': { defaultMessage: 'Isabela' },
    'PH-KAL': { defaultMessage: 'Kalinga' },
    'PH-LAG': { defaultMessage: 'Laguna' },
    'PH-LAN': { defaultMessage: 'Lanao del Norte' },
    'PH-LAS': { defaultMessage: 'Lanao del Sur' },
    'PH-LEY': { defaultMessage: 'Leyte' },
    'PH-LUN': { defaultMessage: 'La Union' },
    'PH-MAD': { defaultMessage: 'Marinduque' },
    'PH-MAG': { defaultMessage: 'Maguindanao' },
    'PH-MAS': { defaultMessage: 'Masbate' },
    'PH-MDC': { defaultMessage: 'Mindoro Occidental' },
    'PH-MDR': { defaultMessage: 'Mindoro Oriental' },
    'PH-MOU': { defaultMessage: 'Mountain Province' },
    'PH-MSC': { defaultMessage: 'Misamis Occidental' },
    'PH-MSR': { defaultMessage: 'Misamis Oriental' },
    'PH-NCO': { defaultMessage: 'Cotabato' },
    'PH-NEC': { defaultMessage: 'Negros Occidental' },
    'PH-NER': { defaultMessage: 'Negros Oriental' },
    'PH-NSA': { defaultMessage: 'Northern Samar' },
    'PH-NUE': { defaultMessage: 'Nueva Ecija' },
    'PH-NUV': { defaultMessage: 'Nueva Vizcaya' },
    'PH-PAM': { defaultMessage: 'Pampanga' },
    'PH-PAN': { defaultMessage: 'Pangasinan' },
    'PH-PLW': { defaultMessage: 'Palawan' },
    'PH-QUE': { defaultMessage: 'Quezon' },
    'PH-QUI': { defaultMessage: 'Quirino' },
    'PH-RIZ': { defaultMessage: 'Rizal' },
    'PH-ROM': { defaultMessage: 'Romblon' },
    'PH-SAR': { defaultMessage: 'Sarangani' },
    'PH-SCO': { defaultMessage: 'South Cotabato' },
    'PH-SIG': { defaultMessage: 'Siquijor' },
    'PH-SLE': { defaultMessage: 'Southern Leyte' },
    'PH-SLU': { defaultMessage: 'Sulu' },
    'PH-SOR': { defaultMessage: 'Sorsogon' },
    'PH-SUK': { defaultMessage: 'Sultan Kudarat' },
    'PH-SUN': { defaultMessage: 'Surigao del Norte' },
    'PH-SUR': { defaultMessage: 'Surigao del Sur' },
    'PH-TAR': { defaultMessage: 'Tarlac' },
    'PH-TAW': { defaultMessage: 'Tawi-Tawi' },
    'PH-WSA': { defaultMessage: 'Samar (Western Samar)' },
    'PH-ZAN': { defaultMessage: 'Zamboanga del Norte' },
    'PH-ZAS': { defaultMessage: 'Zamboanga del Sur' },
    'PH-ZMB': { defaultMessage: 'Zambales' },
    'PH-ZSI': { defaultMessage: 'Zamboanga Sibugay' },
    'PK-BA': { defaultMessage: 'Balochistan' },
    'PK-GB': { defaultMessage: 'Gilgit-Baltistan' },
    'PK-IS': { defaultMessage: 'Islamabad' },
    'PK-JK': {
      defaultMessage: 'Azad Jammu and Kashmir(local variant is AJ&K)',
    },
    'PK-KP': { defaultMessage: 'Khyber Pakhtunkhwa' },
    'PK-PB': { defaultMessage: 'Punjab' },
    'PK-SD': { defaultMessage: 'Sindh' },
    'PK-TA': { defaultMessage: 'Federally Administered Tribal Areas' },
    'PL-02': { defaultMessage: 'Dolnośląskie' },
    'PL-04': { defaultMessage: 'Kujawsko-pomorskie' },
    'PL-06': { defaultMessage: 'Lubelskie' },
    'PL-08': { defaultMessage: 'Lubuskie' },
    'PL-10': { defaultMessage: 'Łódzkie' },
    'PL-12': { defaultMessage: 'Małopolskie' },
    'PL-14': { defaultMessage: 'Mazowieckie' },
    'PL-16': { defaultMessage: 'Opolskie' },
    'PL-18': { defaultMessage: 'Podkarpackie' },
    'PL-20': { defaultMessage: 'Podlaskie' },
    'PL-22': { defaultMessage: 'Pomorskie' },
    'PL-24': { defaultMessage: 'Śląskie' },
    'PL-26': { defaultMessage: 'Świętokrzyskie' },
    'PL-28': { defaultMessage: 'Warmińsko-mazurskie' },
    'PL-30': { defaultMessage: 'Wielkopolskie' },
    'PL-32': { defaultMessage: 'Zachodniopomorskie' },
    'PS-BTH': { defaultMessage: 'Bethlehem' },
    'PS-DEB': { defaultMessage: 'Deir El Balah' },
    'PS-GZA': { defaultMessage: 'Gaza' },
    'PS-HBN': { defaultMessage: 'Hebron' },
    'PS-JEM': { defaultMessage: 'Jerusalem' },
    'PS-JEN': { defaultMessage: 'Jenin' },
    'PS-JRH': { defaultMessage: 'Jericho and Al Aghwar' },
    'PS-KYS': { defaultMessage: 'Khan Yunis' },
    'PS-NBS': { defaultMessage: 'Nablus' },
    'PS-NGZ': { defaultMessage: 'North Gaza' },
    'PS-QQA': { defaultMessage: 'Qalqilya' },
    'PS-RBH': { defaultMessage: 'Ramallah' },
    'PS-RFH': { defaultMessage: 'Rafah' },
    'PS-SLT': { defaultMessage: 'Salfit' },
    'PS-TBS': { defaultMessage: 'Tubas' },
    'PS-TKM': { defaultMessage: 'Tulkarm' },
    'PT-01': { defaultMessage: 'Aveiro' },
    'PT-02': { defaultMessage: 'Beja' },
    'PT-03': { defaultMessage: 'Braga' },
    'PT-04': { defaultMessage: 'Bragança' },
    'PT-05': { defaultMessage: 'Castelo Branco' },
    'PT-06': { defaultMessage: 'Coimbra' },
    'PT-07': { defaultMessage: 'Évora' },
    'PT-08': { defaultMessage: 'Faro' },
    'PT-09': { defaultMessage: 'Guarda' },
    'PT-10': { defaultMessage: 'Leiria' },
    'PT-11': { defaultMessage: 'Lisboa' },
    'PT-12': { defaultMessage: 'Portalegre' },
    'PT-13': { defaultMessage: 'Porto' },
    'PT-14': { defaultMessage: 'Santarém' },
    'PT-15': { defaultMessage: 'Setúbal' },
    'PT-16': { defaultMessage: 'Viana do Castelo' },
    'PT-17': { defaultMessage: 'Vila Real' },
    'PT-18': { defaultMessage: 'Viseu' },
    'PT-20': { defaultMessage: 'Região Autónoma dos Açores' },
    'PT-30': { defaultMessage: 'Região Autónoma da Madeira' },
    'PW-002': { defaultMessage: 'Aimeliik' },
    'PW-004': { defaultMessage: 'Airai' },
    'PW-010': { defaultMessage: 'Angaur' },
    'PW-050': { defaultMessage: 'Hatohobei' },
    'PW-100': { defaultMessage: 'Kayangel' },
    'PW-150': { defaultMessage: 'Koror' },
    'PW-212': { defaultMessage: 'Melekeok' },
    'PW-214': { defaultMessage: 'Ngaraard' },
    'PW-218': { defaultMessage: 'Ngarchelong' },
    'PW-222': { defaultMessage: 'Ngardmau' },
    'PW-224': { defaultMessage: 'Ngatpang' },
    'PW-226': { defaultMessage: 'Ngchesar' },
    'PW-227': { defaultMessage: 'Ngeremlengui' },
    'PW-228': { defaultMessage: 'Ngiwal' },
    'PW-350': { defaultMessage: 'Peleliu' },
    'PW-370': { defaultMessage: 'Sonsorol' },
    'PY-1': { defaultMessage: 'Concepción' },
    'PY-10': { defaultMessage: 'Alto Paraná' },
    'PY-11': { defaultMessage: 'Central' },
    'PY-12': { defaultMessage: 'Ñeembucú' },
    'PY-13': { defaultMessage: 'Amambay' },
    'PY-14': { defaultMessage: 'Canindeyú' },
    'PY-15': { defaultMessage: 'Presidente Hayes' },
    'PY-16': { defaultMessage: 'Alto Paraguay' },
    'PY-19': { defaultMessage: 'Boquerón' },
    'PY-2': { defaultMessage: 'San Pedro' },
    'PY-3': { defaultMessage: 'Cordillera' },
    'PY-4': { defaultMessage: 'Guairá' },
    'PY-5': { defaultMessage: 'Caaguazú' },
    'PY-6': { defaultMessage: 'Caazapá' },
    'PY-7': { defaultMessage: 'Itapúa' },
    'PY-8': { defaultMessage: 'Misiones' },
    'PY-9': { defaultMessage: 'Paraguarí' },
    'PY-ASU': { defaultMessage: 'Asunción' },
    'QA-DA': { defaultMessage: 'Ad Dawḩah' },
    'QA-KH': { defaultMessage: 'Al Khawr wa adh Dhakhīrah' },
    'QA-MS': { defaultMessage: 'Ash Shamāl' },
    'QA-RA': { defaultMessage: 'Ar Rayyān' },
    'QA-SH': { defaultMessage: 'Ash Shīḩānīyah' },
    'QA-US': { defaultMessage: 'Umm Şalāl' },
    'QA-WA': { defaultMessage: 'Al Wakrah' },
    'QA-ZA': { defaultMessage: 'Az̧ Z̧a‘āyin' },
    'RO-AB': { defaultMessage: 'Alba' },
    'RO-AG': { defaultMessage: 'Argeș' },
    'RO-AR': { defaultMessage: 'Arad' },
    'RO-B': { defaultMessage: 'București' },
    'RO-BC': { defaultMessage: 'Bacău' },
    'RO-BH': { defaultMessage: 'Bihor' },
    'RO-BN': { defaultMessage: 'Bistrița-Năsăud' },
    'RO-BR': { defaultMessage: 'Brăila' },
    'RO-BT': { defaultMessage: 'Botoșani' },
    'RO-BV': { defaultMessage: 'Brașov' },
    'RO-BZ': { defaultMessage: 'Buzău' },
    'RO-CJ': { defaultMessage: 'Cluj' },
    'RO-CL': { defaultMessage: 'Călărași' },
    'RO-CS': { defaultMessage: 'Caraș-Severin' },
    'RO-CT': { defaultMessage: 'Constanța' },
    'RO-CV': { defaultMessage: 'Covasna' },
    'RO-DB': { defaultMessage: 'Dâmbovița' },
    'RO-DJ': { defaultMessage: 'Dolj' },
    'RO-GJ': { defaultMessage: 'Gorj' },
    'RO-GL': { defaultMessage: 'Galați' },
    'RO-GR': { defaultMessage: 'Giurgiu' },
    'RO-HD': { defaultMessage: 'Hunedoara' },
    'RO-HR': { defaultMessage: 'Harghita' },
    'RO-IF': { defaultMessage: 'Ilfov' },
    'RO-IL': { defaultMessage: 'Ialomița' },
    'RO-IS': { defaultMessage: 'Iași' },
    'RO-MH': { defaultMessage: 'Mehedinți' },
    'RO-MM': { defaultMessage: 'Maramureș' },
    'RO-MS': { defaultMessage: 'Mureș' },
    'RO-NT': { defaultMessage: 'Neamț' },
    'RO-OT': { defaultMessage: 'Olt' },
    'RO-PH': { defaultMessage: 'Prahova' },
    'RO-SB': { defaultMessage: 'Sibiu' },
    'RO-SJ': { defaultMessage: 'Sălaj' },
    'RO-SM': { defaultMessage: 'Satu Mare' },
    'RO-SV': { defaultMessage: 'Suceava' },
    'RO-TL': { defaultMessage: 'Tulcea' },
    'RO-TM': { defaultMessage: 'Timiș' },
    'RO-TR': { defaultMessage: 'Teleorman' },
    'RO-VL': { defaultMessage: 'Vâlcea' },
    'RO-VN': { defaultMessage: 'Vrancea' },
    'RO-VS': { defaultMessage: 'Vaslui' },
    'RS-00': { defaultMessage: 'Beograd' },
    'RS-01': { defaultMessage: 'Severnobački okrug' },
    'RS-02': { defaultMessage: 'Srednjebanatski okrug' },
    'RS-03': { defaultMessage: 'Severnobanatski okrug' },
    'RS-04': { defaultMessage: 'Južnobanatski okrug' },
    'RS-05': { defaultMessage: 'Zapadnobački okrug' },
    'RS-06': { defaultMessage: 'Južnobački okrug' },
    'RS-07': { defaultMessage: 'Sremski okrug' },
    'RS-08': { defaultMessage: 'Mačvanski okrug' },
    'RS-09': { defaultMessage: 'Kolubarski okrug' },
    'RS-10': { defaultMessage: 'Podunavski okrug' },
    'RS-11': { defaultMessage: 'Braničevski okrug' },
    'RS-12': { defaultMessage: 'Šumadijski okrug' },
    'RS-13': { defaultMessage: 'Pomoravski okrug' },
    'RS-14': { defaultMessage: 'Borski okrug' },
    'RS-15': { defaultMessage: 'Zaječarski okrug' },
    'RS-16': { defaultMessage: 'Zlatiborski okrug' },
    'RS-17': { defaultMessage: 'Moravički okrug' },
    'RS-18': { defaultMessage: 'Raški okrug' },
    'RS-19': { defaultMessage: 'Rasinski okrug' },
    'RS-20': { defaultMessage: 'Nišavski okrug' },
    'RS-21': { defaultMessage: 'Toplički okrug' },
    'RS-22': { defaultMessage: 'Pirotski okrug' },
    'RS-23': { defaultMessage: 'Jablanički okrug' },
    'RS-24': { defaultMessage: 'Pčinjski okrug' },
    'RS-25': { defaultMessage: 'Kosovski okrug' },
    'RS-26': { defaultMessage: 'Pećki okrug' },
    'RS-27': { defaultMessage: 'Prizrenski okrug' },
    'RS-28': { defaultMessage: 'Kosovsko-Mitrovački okrug' },
    'RS-29': { defaultMessage: 'Kosovsko-Pomoravski okrug' },
    'RS-KM': { defaultMessage: 'Kosovo-Metohija' },
    'RS-VO': { defaultMessage: 'Vojvodina' },
    'RU-AD': { defaultMessage: 'Adygeya, Respublika' },
    'RU-AL': { defaultMessage: 'Altay, Respublika' },
    'RU-ALT': { defaultMessage: 'Altayskiy kray' },
    'RU-AMU': { defaultMessage: "Amurskaya oblast'" },
    'RU-ARK': { defaultMessage: "Arkhangel'skaya oblast'" },
    'RU-AST': { defaultMessage: "Astrakhanskaya oblast'" },
    'RU-BA': { defaultMessage: 'Bashkortostan, Respublika' },
    'RU-BEL': { defaultMessage: "Belgorodskaya oblast'" },
    'RU-BRY': { defaultMessage: "Bryanskaya oblast'" },
    'RU-BU': { defaultMessage: 'Buryatiya, Respublika' },
    'RU-CE': { defaultMessage: 'Chechenskaya Respublika' },
    'RU-CHE': { defaultMessage: "Chelyabinskaya oblast'" },
    'RU-CHU': { defaultMessage: 'Chukotskiy avtonomnyy okrug' },
    'RU-CU': { defaultMessage: 'Chuvashskaya Respublika' },
    'RU-DA': { defaultMessage: 'Dagestan, Respublika' },
    'RU-IN': { defaultMessage: 'Ingushetiya, Respublika' },
    'RU-IRK': { defaultMessage: "Irkutskaya oblast'" },
    'RU-IVA': { defaultMessage: "Ivanovskaya oblast'" },
    'RU-KAM': { defaultMessage: 'Kamchatskiy kray' },
    'RU-KB': { defaultMessage: 'Kabardino-Balkarskaya Respublika' },
    'RU-KC': { defaultMessage: 'Karachayevo-Cherkesskaya Respublika' },
    'RU-KDA': { defaultMessage: 'Krasnodarskiy kray' },
    'RU-KEM': { defaultMessage: "Kemerovskaya oblast'" },
    'RU-KGD': { defaultMessage: "Kaliningradskaya oblast'" },
    'RU-KGN': { defaultMessage: "Kurganskaya oblast'" },
    'RU-KHA': { defaultMessage: 'Khabarovskiy kray' },
    'RU-KHM': {
      defaultMessage:
        'Khanty-Mansiyskiy avtonomnyy okrug(local variant is Yugra)',
    },
    'RU-KIR': { defaultMessage: "Kirovskaya oblast'" },
    'RU-KK': { defaultMessage: 'Khakasiya, Respublika' },
    'RU-KL': { defaultMessage: 'Kalmykiya, Respublika' },
    'RU-KLU': { defaultMessage: "Kaluzhskaya oblast'" },
    'RU-KO': { defaultMessage: 'Komi, Respublika' },
    'RU-KOS': { defaultMessage: "Kostromskaya oblast'" },
    'RU-KR': { defaultMessage: 'Kareliya, Respublika' },
    'RU-KRS': { defaultMessage: "Kurskaya oblast'" },
    'RU-KYA': { defaultMessage: 'Krasnoyarskiy kray' },
    'RU-LEN': { defaultMessage: "Leningradskaya oblast'" },
    'RU-LIP': { defaultMessage: "Lipetskaya oblast'" },
    'RU-MAG': { defaultMessage: "Magadanskaya oblast'" },
    'RU-ME': { defaultMessage: 'Mariy El, Respublika' },
    'RU-MO': { defaultMessage: 'Mordoviya, Respublika' },
    'RU-MOS': { defaultMessage: "Moskovskaya oblast'" },
    'RU-MOW': { defaultMessage: 'Moskva' },
    'RU-MUR': { defaultMessage: "Murmanskaya oblast'" },
    'RU-NEN': { defaultMessage: 'Nenetskiy avtonomnyy okrug' },
    'RU-NGR': { defaultMessage: "Novgorodskaya oblast'" },
    'RU-NIZ': { defaultMessage: "Nizhegorodskaya oblast'" },
    'RU-NVS': { defaultMessage: "Novosibirskaya oblast'" },
    'RU-OMS': { defaultMessage: "Omskaya oblast'" },
    'RU-ORE': { defaultMessage: "Orenburgskaya oblast'" },
    'RU-ORL': { defaultMessage: "Orlovskaya oblast'" },
    'RU-PER': { defaultMessage: 'Permskiy kray' },
    'RU-PNZ': { defaultMessage: "Penzenskaya oblast'" },
    'RU-PRI': { defaultMessage: 'Primorskiy kray' },
    'RU-PSK': { defaultMessage: "Pskovskaya oblast'" },
    'RU-ROS': { defaultMessage: "Rostovskaya oblast'" },
    'RU-RYA': { defaultMessage: "Ryazanskaya oblast'" },
    'RU-SA': { defaultMessage: 'Saha, Respublika(local variant is Jakutija)' },
    'RU-SAK': { defaultMessage: "Sakhalinskaya oblast'" },
    'RU-SAM': { defaultMessage: "Samarskaya oblast'" },
    'RU-SAR': { defaultMessage: "Saratovskaya oblast'" },
    'RU-SE': {
      defaultMessage:
        'Severnaya Osetiya, Respublika(local variant is Alaniya )',
    },
    'RU-SMO': { defaultMessage: "Smolenskaya oblast'" },
    'RU-SPE': { defaultMessage: 'Sankt-Peterburg' },
    'RU-STA': { defaultMessage: "Stavropol'skiy kray" },
    'RU-SVE': { defaultMessage: "Sverdlovskaya oblast'" },
    'RU-TA': { defaultMessage: 'Tatarstan, Respublika' },
    'RU-TAM': { defaultMessage: "Tambovskaya oblast'" },
    'RU-TOM': { defaultMessage: "Tomskaya oblast'" },
    'RU-TUL': { defaultMessage: "Tul'skaya oblast'" },
    'RU-TVE': { defaultMessage: "Tverskaya oblast'" },
    'RU-TY': { defaultMessage: 'Tyva, Respublika(local variant is Tuva)' },
    'RU-TYU': { defaultMessage: "Tyumenskaya oblast'" },
    'RU-UD': { defaultMessage: 'Udmurtskaya Respublika' },
    'RU-ULY': { defaultMessage: "Ul'yanovskaya oblast'" },
    'RU-VGG': { defaultMessage: "Volgogradskaya oblast'" },
    'RU-VLA': { defaultMessage: "Vladimirskaya oblast'" },
    'RU-VLG': { defaultMessage: "Vologodskaya oblast'" },
    'RU-VOR': { defaultMessage: "Voronezhskaya oblast'" },
    'RU-YAN': { defaultMessage: 'Yamalo-Nenetskiy avtonomnyy okrug' },
    'RU-YAR': { defaultMessage: "Yaroslavskaya oblast'" },
    'RU-YEV': { defaultMessage: "Yevreyskaya avtonomnaya oblast'" },
    'RU-ZAB': { defaultMessage: "Zabaykal'skiy kray" },
    'RW-01': { defaultMessage: 'City of Kigali' },
    'RW-02': { defaultMessage: 'Eastern' },
    'RW-03': { defaultMessage: 'Northern' },
    'RW-04': { defaultMessage: 'Western' },
    'RW-05': { defaultMessage: 'Southern' },
    'SA-01': { defaultMessage: 'Ar Riyāḑ' },
    'SA-02': { defaultMessage: 'Makkah al Mukarramah' },
    'SA-03': { defaultMessage: 'Al Madīnah al Munawwarah' },
    'SA-04': { defaultMessage: 'Ash Sharqīyah' },
    'SA-05': { defaultMessage: 'Al Qaşīm' },
    'SA-06': { defaultMessage: "Ḩā'il" },
    'SA-07': { defaultMessage: 'Tabūk' },
    'SA-08': { defaultMessage: 'Al Ḩudūd ash Shamālīyah' },
    'SA-09': { defaultMessage: 'Jāzān' },
    'SA-10': { defaultMessage: 'Najrān' },
    'SA-11': { defaultMessage: 'Al Bāḩah' },
    'SA-12': { defaultMessage: 'Al Jawf' },
    'SA-14': { defaultMessage: "'Asīr" },
    'SB-CE': { defaultMessage: 'Central' },
    'SB-CH': { defaultMessage: 'Choiseul' },
    'SB-CT': { defaultMessage: 'Capital Territory (Honiara)' },
    'SB-GU': { defaultMessage: 'Guadalcanal' },
    'SB-IS': { defaultMessage: 'Isabel' },
    'SB-MK': { defaultMessage: 'Makira-Ulawa' },
    'SB-ML': { defaultMessage: 'Malaita' },
    'SB-RB': { defaultMessage: 'Rennell and Bellona' },
    'SB-TE': { defaultMessage: 'Temotu' },
    'SB-WE': { defaultMessage: 'Western' },
    'SC-01': { defaultMessage: 'Anse aux Pins' },
    'SC-02': { defaultMessage: 'Anse Boileau' },
    'SC-03': { defaultMessage: 'Anse Etoile' },
    'SC-04': { defaultMessage: 'Au Cap' },
    'SC-05': { defaultMessage: 'Anse Royale' },
    'SC-06': { defaultMessage: 'Baie Lazare' },
    'SC-07': { defaultMessage: 'Baie Sainte Anne' },
    'SC-08': { defaultMessage: 'Beau Vallon' },
    'SC-09': { defaultMessage: 'Bel Air' },
    'SC-10': { defaultMessage: 'Bel Ombre' },
    'SC-11': { defaultMessage: 'Cascade' },
    'SC-12': { defaultMessage: 'Glacis' },
    'SC-13': { defaultMessage: 'Grand Anse Mahe' },
    'SC-14': { defaultMessage: 'Grand Anse Praslin' },
    'SC-15': { defaultMessage: 'La Digue' },
    'SC-16': { defaultMessage: 'English River' },
    'SC-17': { defaultMessage: 'Mont Buxton' },
    'SC-18': { defaultMessage: 'Mont Fleuri' },
    'SC-19': { defaultMessage: 'Plaisance' },
    'SC-20': { defaultMessage: 'Pointe Larue' },
    'SC-21': { defaultMessage: 'Port Glaud' },
    'SC-22': { defaultMessage: 'Saint Louis' },
    'SC-23': { defaultMessage: 'Takamaka' },
    'SC-24': { defaultMessage: 'Les Mamelles' },
    'SC-25': { defaultMessage: 'Roche Caiman' },
    'SC-26': { defaultMessage: 'Ile Perseverance I' },
    'SC-27': { defaultMessage: 'Ile Perseverance II' },
    'SD-DC': { defaultMessage: 'Wasaţ Dārfūr(local variant is Zālinjay)' },
    'SD-DE': { defaultMessage: 'Sharq Dārfūr' },
    'SD-DN': { defaultMessage: 'Shamāl Dārfūr' },
    'SD-DS': { defaultMessage: 'Janūb Dārfūr' },
    'SD-DW': { defaultMessage: 'Gharb Dārfūr' },
    'SD-GD': { defaultMessage: 'Al Qaḑārif' },
    'SD-GK': { defaultMessage: 'Gharb Kurdufān' },
    'SD-GZ': { defaultMessage: 'Al Jazīrah' },
    'SD-KA': { defaultMessage: 'Kassalā' },
    'SD-KH': { defaultMessage: 'Al Kharţūm' },
    'SD-KN': { defaultMessage: 'Shamāl Kurdufān' },
    'SD-KS': { defaultMessage: 'Janūb Kurdufān' },
    'SD-NB': { defaultMessage: 'An Nīl al Azraq' },
    'SD-NO': { defaultMessage: 'Ash Shamālīyah' },
    'SD-NR': { defaultMessage: 'Nahr an Nīl' },
    'SD-NW': { defaultMessage: 'An Nīl al Abyaḑ' },
    'SD-RS': { defaultMessage: 'Al Baḩr al Aḩmar' },
    'SD-SI': { defaultMessage: 'Sinnār' },
    'SE-AB': { defaultMessage: 'Stockholms län' },
    'SE-AC': { defaultMessage: 'Västerbottens län' },
    'SE-BD': { defaultMessage: 'Norrbottens län' },
    'SE-C': { defaultMessage: 'Uppsala län' },
    'SE-D': { defaultMessage: 'Södermanlands län' },
    'SE-E': { defaultMessage: 'Östergötlands län' },
    'SE-F': { defaultMessage: 'Jönköpings län' },
    'SE-G': { defaultMessage: 'Kronobergs län' },
    'SE-H': { defaultMessage: 'Kalmar län' },
    'SE-I': { defaultMessage: 'Gotlands län' },
    'SE-K': { defaultMessage: 'Blekinge län' },
    'SE-M': { defaultMessage: 'Skåne län' },
    'SE-N': { defaultMessage: 'Hallands län' },
    'SE-O': { defaultMessage: 'Västra Götalands län' },
    'SE-S': { defaultMessage: 'Värmlands län' },
    'SE-T': { defaultMessage: 'Örebro län' },
    'SE-U': { defaultMessage: 'Västmanlands län' },
    'SE-W': { defaultMessage: 'Dalarnas län' },
    'SE-X': { defaultMessage: 'Gävleborgs län' },
    'SE-Y': { defaultMessage: 'Västernorrlands län' },
    'SE-Z': { defaultMessage: 'Jämtlands län' },
    'SG-01': { defaultMessage: 'Central Singapore' },
    'SG-02': { defaultMessage: 'North East' },
    'SG-03': { defaultMessage: 'North West' },
    'SG-04': { defaultMessage: 'South East' },
    'SG-05': { defaultMessage: 'South West' },
    'SH-AC': { defaultMessage: 'Ascension' },
    'SH-HL': { defaultMessage: 'Saint Helena' },
    'SH-TA': { defaultMessage: 'Tristan da Cunha' },
    'SI-001': { defaultMessage: 'Ajdovščina' },
    'SI-002': { defaultMessage: 'Beltinci' },
    'SI-003': { defaultMessage: 'Bled' },
    'SI-004': { defaultMessage: 'Bohinj' },
    'SI-005': { defaultMessage: 'Borovnica' },
    'SI-006': { defaultMessage: 'Bovec' },
    'SI-007': { defaultMessage: 'Brda' },
    'SI-008': { defaultMessage: 'Brezovica' },
    'SI-009': { defaultMessage: 'Brežice' },
    'SI-010': { defaultMessage: 'Tišina' },
    'SI-011': { defaultMessage: 'Celje' },
    'SI-012': { defaultMessage: 'Cerklje na Gorenjskem' },
    'SI-013': { defaultMessage: 'Cerknica' },
    'SI-014': { defaultMessage: 'Cerkno' },
    'SI-015': { defaultMessage: 'Črenšovci' },
    'SI-016': { defaultMessage: 'Črna na Koroškem' },
    'SI-017': { defaultMessage: 'Črnomelj' },
    'SI-018': { defaultMessage: 'Destrnik' },
    'SI-019': { defaultMessage: 'Divača' },
    'SI-020': { defaultMessage: 'Dobrepolje' },
    'SI-021': { defaultMessage: 'Dobrova-Polhov Gradec' },
    'SI-022': { defaultMessage: 'Dol pri Ljubljani' },
    'SI-023': { defaultMessage: 'Domžale' },
    'SI-024': { defaultMessage: 'Dornava' },
    'SI-025': { defaultMessage: 'Dravograd' },
    'SI-026': { defaultMessage: 'Duplek' },
    'SI-027': { defaultMessage: 'Gorenja vas-Poljane' },
    'SI-028': { defaultMessage: 'Gorišnica' },
    'SI-029': { defaultMessage: 'Gornja Radgona' },
    'SI-030': { defaultMessage: 'Gornji Grad' },
    'SI-031': { defaultMessage: 'Gornji Petrovci' },
    'SI-032': { defaultMessage: 'Grosuplje' },
    'SI-033': { defaultMessage: 'Šalovci' },
    'SI-034': { defaultMessage: 'Hrastnik' },
    'SI-035': { defaultMessage: 'Hrpelje-Kozina' },
    'SI-036': { defaultMessage: 'Idrija' },
    'SI-037': { defaultMessage: 'Ig' },
    'SI-038': { defaultMessage: 'Ilirska Bistrica' },
    'SI-039': { defaultMessage: 'Ivančna Gorica' },
    'SI-040': { defaultMessage: 'Izola' },
    'SI-041': { defaultMessage: 'Jesenice' },
    'SI-042': { defaultMessage: 'Juršinci' },
    'SI-043': { defaultMessage: 'Kamnik' },
    'SI-044': { defaultMessage: 'Kanal' },
    'SI-045': { defaultMessage: 'Kidričevo' },
    'SI-046': { defaultMessage: 'Kobarid' },
    'SI-047': { defaultMessage: 'Kobilje' },
    'SI-048': { defaultMessage: 'Kočevje' },
    'SI-049': { defaultMessage: 'Komen' },
    'SI-050': { defaultMessage: 'Koper' },
    'SI-051': { defaultMessage: 'Kozje' },
    'SI-052': { defaultMessage: 'Kranj' },
    'SI-053': { defaultMessage: 'Kranjska Gora' },
    'SI-054': { defaultMessage: 'Krško' },
    'SI-055': { defaultMessage: 'Kungota' },
    'SI-056': { defaultMessage: 'Kuzma' },
    'SI-057': { defaultMessage: 'Laško' },
    'SI-058': { defaultMessage: 'Lenart' },
    'SI-059': { defaultMessage: 'Lendava' },
    'SI-060': { defaultMessage: 'Litija' },
    'SI-061': { defaultMessage: 'Ljubljana' },
    'SI-062': { defaultMessage: 'Ljubno' },
    'SI-063': { defaultMessage: 'Ljutomer' },
    'SI-064': { defaultMessage: 'Logatec' },
    'SI-065': { defaultMessage: 'Loška dolina' },
    'SI-066': { defaultMessage: 'Loški Potok' },
    'SI-067': { defaultMessage: 'Luče' },
    'SI-068': { defaultMessage: 'Lukovica' },
    'SI-069': { defaultMessage: 'Majšperk' },
    'SI-070': { defaultMessage: 'Maribor' },
    'SI-071': { defaultMessage: 'Medvode' },
    'SI-072': { defaultMessage: 'Mengeš' },
    'SI-073': { defaultMessage: 'Metlika' },
    'SI-074': { defaultMessage: 'Mežica' },
    'SI-075': { defaultMessage: 'Miren-Kostanjevica' },
    'SI-076': { defaultMessage: 'Mislinja' },
    'SI-077': { defaultMessage: 'Moravče' },
    'SI-078': { defaultMessage: 'Moravske Toplice' },
    'SI-079': { defaultMessage: 'Mozirje' },
    'SI-080': { defaultMessage: 'Murska Sobota' },
    'SI-081': { defaultMessage: 'Muta' },
    'SI-082': { defaultMessage: 'Naklo' },
    'SI-083': { defaultMessage: 'Nazarje' },
    'SI-084': { defaultMessage: 'Nova Gorica' },
    'SI-085': { defaultMessage: 'Novo Mesto' },
    'SI-086': { defaultMessage: 'Odranci' },
    'SI-087': { defaultMessage: 'Ormož' },
    'SI-088': { defaultMessage: 'Osilnica' },
    'SI-089': { defaultMessage: 'Pesnica' },
    'SI-090': { defaultMessage: 'Piran' },
    'SI-091': { defaultMessage: 'Pivka' },
    'SI-092': { defaultMessage: 'Podčetrtek' },
    'SI-093': { defaultMessage: 'Podvelka' },
    'SI-094': { defaultMessage: 'Postojna' },
    'SI-095': { defaultMessage: 'Preddvor' },
    'SI-096': { defaultMessage: 'Ptuj' },
    'SI-097': { defaultMessage: 'Puconci' },
    'SI-098': { defaultMessage: 'Rače-Fram' },
    'SI-099': { defaultMessage: 'Radeče' },
    'SI-100': { defaultMessage: 'Radenci' },
    'SI-101': { defaultMessage: 'Radlje ob Dravi' },
    'SI-102': { defaultMessage: 'Radovljica' },
    'SI-103': { defaultMessage: 'Ravne na Koroškem' },
    'SI-104': { defaultMessage: 'Ribnica' },
    'SI-105': { defaultMessage: 'Rogašovci' },
    'SI-106': { defaultMessage: 'Rogaška Slatina' },
    'SI-107': { defaultMessage: 'Rogatec' },
    'SI-108': { defaultMessage: 'Ruše' },
    'SI-109': { defaultMessage: 'Semič' },
    'SI-110': { defaultMessage: 'Sevnica' },
    'SI-111': { defaultMessage: 'Sežana' },
    'SI-112': { defaultMessage: 'Slovenj Gradec' },
    'SI-113': { defaultMessage: 'Slovenska Bistrica' },
    'SI-114': { defaultMessage: 'Slovenske Konjice' },
    'SI-115': { defaultMessage: 'Starše' },
    'SI-116': { defaultMessage: 'Sveti Jurij ob Ščavnici' },
    'SI-117': { defaultMessage: 'Šenčur' },
    'SI-118': { defaultMessage: 'Šentilj' },
    'SI-119': { defaultMessage: 'Šentjernej' },
    'SI-120': { defaultMessage: 'Šentjur' },
    'SI-121': { defaultMessage: 'Škocjan' },
    'SI-122': { defaultMessage: 'Škofja Loka' },
    'SI-123': { defaultMessage: 'Škofljica' },
    'SI-124': { defaultMessage: 'Šmarje pri Jelšah' },
    'SI-125': { defaultMessage: 'Šmartno ob Paki' },
    'SI-126': { defaultMessage: 'Šoštanj' },
    'SI-127': { defaultMessage: 'Štore' },
    'SI-128': { defaultMessage: 'Tolmin' },
    'SI-129': { defaultMessage: 'Trbovlje' },
    'SI-130': { defaultMessage: 'Trebnje' },
    'SI-131': { defaultMessage: 'Tržič' },
    'SI-132': { defaultMessage: 'Turnišče' },
    'SI-133': { defaultMessage: 'Velenje' },
    'SI-134': { defaultMessage: 'Velike Lašče' },
    'SI-135': { defaultMessage: 'Videm' },
    'SI-136': { defaultMessage: 'Vipava' },
    'SI-137': { defaultMessage: 'Vitanje' },
    'SI-138': { defaultMessage: 'Vodice' },
    'SI-139': { defaultMessage: 'Vojnik' },
    'SI-140': { defaultMessage: 'Vrhnika' },
    'SI-141': { defaultMessage: 'Vuzenica' },
    'SI-142': { defaultMessage: 'Zagorje ob Savi' },
    'SI-143': { defaultMessage: 'Zavrč' },
    'SI-144': { defaultMessage: 'Zreče' },
    'SI-146': { defaultMessage: 'Železniki' },
    'SI-147': { defaultMessage: 'Žiri' },
    'SI-148': { defaultMessage: 'Benedikt' },
    'SI-149': { defaultMessage: 'Bistrica ob Sotli' },
    'SI-150': { defaultMessage: 'Bloke' },
    'SI-151': { defaultMessage: 'Braslovče' },
    'SI-152': { defaultMessage: 'Cankova' },
    'SI-153': { defaultMessage: 'Cerkvenjak' },
    'SI-154': { defaultMessage: 'Dobje' },
    'SI-155': { defaultMessage: 'Dobrna' },
    'SI-156': { defaultMessage: 'Dobrovnik' },
    'SI-157': { defaultMessage: 'Dolenjske Toplice' },
    'SI-158': { defaultMessage: 'Grad' },
    'SI-159': { defaultMessage: 'Hajdina' },
    'SI-160': { defaultMessage: 'Hoče-Slivnica' },
    'SI-161': { defaultMessage: 'Hodoš' },
    'SI-162': { defaultMessage: 'Horjul' },
    'SI-163': { defaultMessage: 'Jezersko' },
    'SI-164': { defaultMessage: 'Komenda' },
    'SI-165': { defaultMessage: 'Kostel' },
    'SI-166': { defaultMessage: 'Križevci' },
    'SI-167': { defaultMessage: 'Lovrenc na Pohorju' },
    'SI-168': { defaultMessage: 'Markovci' },
    'SI-169': { defaultMessage: 'Miklavž na Dravskem polju' },
    'SI-170': { defaultMessage: 'Mirna Peč' },
    'SI-171': { defaultMessage: 'Oplotnica' },
    'SI-172': { defaultMessage: 'Podlehnik' },
    'SI-173': { defaultMessage: 'Polzela' },
    'SI-174': { defaultMessage: 'Prebold' },
    'SI-175': { defaultMessage: 'Prevalje' },
    'SI-176': { defaultMessage: 'Razkrižje' },
    'SI-177': { defaultMessage: 'Ribnica na Pohorju' },
    'SI-178': { defaultMessage: 'Selnica ob Dravi' },
    'SI-179': { defaultMessage: 'Sodražica' },
    'SI-180': { defaultMessage: 'Solčava' },
    'SI-181': { defaultMessage: 'Sveta Ana' },
    'SI-182': { defaultMessage: 'Sveti Andraž v Slovenskih goricah' },
    'SI-183': { defaultMessage: 'Šempeter-Vrtojba' },
    'SI-184': { defaultMessage: 'Tabor' },
    'SI-185': { defaultMessage: 'Trnovska Vas' },
    'SI-186': { defaultMessage: 'Trzin' },
    'SI-187': { defaultMessage: 'Velika Polana' },
    'SI-188': { defaultMessage: 'Veržej' },
    'SI-189': { defaultMessage: 'Vransko' },
    'SI-190': { defaultMessage: 'Žalec' },
    'SI-191': { defaultMessage: 'Žetale' },
    'SI-192': { defaultMessage: 'Žirovnica' },
    'SI-193': { defaultMessage: 'Žužemberk' },
    'SI-194': { defaultMessage: 'Šmartno pri Litiji' },
    'SI-195': { defaultMessage: 'Apače' },
    'SI-196': { defaultMessage: 'Cirkulane' },
    'SI-197': { defaultMessage: 'Kosanjevica na Krki' },
    'SI-198': { defaultMessage: 'Makole' },
    'SI-199': { defaultMessage: 'Mokronog-Trebelno' },
    'SI-200': { defaultMessage: 'Poljčane' },
    'SI-201': { defaultMessage: 'Renče-Vogrsko' },
    'SI-202': { defaultMessage: 'Središče ob Dravi' },
    'SI-203': { defaultMessage: 'Straža' },
    'SI-204': { defaultMessage: 'Sveta Trojica v Slovenskih goricah' },
    'SI-205': { defaultMessage: 'Sveti Tomaž' },
    'SI-206': { defaultMessage: 'Šmarješke Toplice' },
    'SI-207': { defaultMessage: 'Gorje' },
    'SI-208': { defaultMessage: 'Log-Dragomer' },
    'SI-209': { defaultMessage: 'Rečica ob Savinji' },
    'SI-210': { defaultMessage: 'Sveti Jurij v Slovenskih goricah' },
    'SI-211': { defaultMessage: 'Šentrupert' },
    'SI-212': { defaultMessage: 'Mirna' },
    'SI-213': { defaultMessage: 'Ankaran' },
    'SK-BC': { defaultMessage: 'Banskobystrický kraj' },
    'SK-BL': { defaultMessage: 'Bratislavský kraj' },
    'SK-KI': { defaultMessage: 'Košický kraj' },
    'SK-NI': { defaultMessage: 'Nitriansky kraj' },
    'SK-PV': { defaultMessage: 'Prešovský kraj' },
    'SK-TA': { defaultMessage: 'Trnavský kraj' },
    'SK-TC': { defaultMessage: 'Trenčiansky kraj' },
    'SK-ZI': { defaultMessage: 'Žilinský kraj' },
    'SL-E': { defaultMessage: 'Eastern' },
    'SL-N': { defaultMessage: 'Northern' },
    'SL-NW': { defaultMessage: 'North Western' },
    'SL-S': { defaultMessage: 'Southern' },
    'SL-W': { defaultMessage: 'Western Area (Freetown)' },
    'SM-01': { defaultMessage: 'Acquaviva' },
    'SM-02': { defaultMessage: 'Chiesanuova' },
    'SM-03': { defaultMessage: 'Domagnano' },
    'SM-04': { defaultMessage: 'Faetano' },
    'SM-05': { defaultMessage: 'Fiorentino' },
    'SM-06': { defaultMessage: 'Borgo Maggiore' },
    'SM-07': {
      defaultMessage: 'Città di San Marino(local variant is San Marino)',
    },
    'SM-08': { defaultMessage: 'Montegiardino' },
    'SM-09': { defaultMessage: 'Serravalle' },
    'SN-DB': { defaultMessage: 'Diourbel' },
    'SN-DK': { defaultMessage: 'Dakar' },
    'SN-FK': { defaultMessage: 'Fatick' },
    'SN-KA': { defaultMessage: 'Kaffrine' },
    'SN-KD': { defaultMessage: 'Kolda' },
    'SN-KE': { defaultMessage: 'Kédougou' },
    'SN-KL': { defaultMessage: 'Kaolack' },
    'SN-LG': { defaultMessage: 'Louga' },
    'SN-MT': { defaultMessage: 'Matam' },
    'SN-SE': { defaultMessage: 'Sédhiou' },
    'SN-SL': { defaultMessage: 'Saint-Louis' },
    'SN-TC': { defaultMessage: 'Tambacounda' },
    'SN-TH': { defaultMessage: 'Thiès' },
    'SN-ZG': { defaultMessage: 'Ziguinchor' },
    'SO-AW': { defaultMessage: 'Awdal' },
    'SO-BK': { defaultMessage: 'Bakool' },
    'SO-BN': { defaultMessage: 'Banaadir' },
    'SO-BR': { defaultMessage: 'Bari' },
    'SO-BY': { defaultMessage: 'Bay' },
    'SO-GA': { defaultMessage: 'Galguduud' },
    'SO-GE': { defaultMessage: 'Gedo' },
    'SO-HI': { defaultMessage: 'Hiiraan' },
    'SO-JD': { defaultMessage: 'Jubbada Dhexe' },
    'SO-JH': { defaultMessage: 'Jubbada Hoose' },
    'SO-MU': { defaultMessage: 'Mudug' },
    'SO-NU': { defaultMessage: 'Nugaal' },
    'SO-SA': { defaultMessage: 'Sanaag' },
    'SO-SD': { defaultMessage: 'Shabeellaha Dhexe' },
    'SO-SH': { defaultMessage: 'Shabeellaha Hoose' },
    'SO-SO': { defaultMessage: 'Sool' },
    'SO-TO': { defaultMessage: 'Togdheer' },
    'SO-WO': { defaultMessage: 'Woqooyi Galbeed' },
    'SR-BR': { defaultMessage: 'Brokopondo' },
    'SR-CM': { defaultMessage: 'Commewijne' },
    'SR-CR': { defaultMessage: 'Coronie' },
    'SR-MA': { defaultMessage: 'Marowijne' },
    'SR-NI': { defaultMessage: 'Nickerie' },
    'SR-PM': { defaultMessage: 'Paramaribo' },
    'SR-PR': { defaultMessage: 'Para' },
    'SR-SA': { defaultMessage: 'Saramacca' },
    'SR-SI': { defaultMessage: 'Sipaliwini' },
    'SR-WA': { defaultMessage: 'Wanica' },
    'SS-BN': { defaultMessage: 'Northern Bahr el Ghazal' },
    'SS-BW': { defaultMessage: 'Western Bahr el Ghazal' },
    'SS-EC': { defaultMessage: 'Central Equatoria' },
    'SS-EE': { defaultMessage: 'Eastern Equatoria' },
    'SS-EW': { defaultMessage: 'Western Equatoria' },
    'SS-JG': { defaultMessage: 'Jonglei' },
    'SS-LK': { defaultMessage: 'Lakes' },
    'SS-NU': { defaultMessage: 'Upper Nile' },
    'SS-UY': { defaultMessage: 'Unity' },
    'SS-WR': { defaultMessage: 'Warrap' },
    'ST-01': { defaultMessage: 'Água Grande' },
    'ST-02': { defaultMessage: 'Cantagalo' },
    'ST-03': { defaultMessage: 'Caué' },
    'ST-04': { defaultMessage: 'Lembá' },
    'ST-05': { defaultMessage: 'Lobata' },
    'ST-06': { defaultMessage: 'Mé-Zóchi' },
    'ST-P': { defaultMessage: 'Príncipe' },
    'SV-AH': { defaultMessage: 'Ahuachapán' },
    'SV-CA': { defaultMessage: 'Cabañas' },
    'SV-CH': { defaultMessage: 'Chalatenango' },
    'SV-CU': { defaultMessage: 'Cuscatlán' },
    'SV-LI': { defaultMessage: 'La Libertad' },
    'SV-MO': { defaultMessage: 'Morazán' },
    'SV-PA': { defaultMessage: 'La Paz' },
    'SV-SA': { defaultMessage: 'Santa Ana' },
    'SV-SM': { defaultMessage: 'San Miguel' },
    'SV-SO': { defaultMessage: 'Sonsonate' },
    'SV-SS': { defaultMessage: 'San Salvador' },
    'SV-SV': { defaultMessage: 'San Vicente' },
    'SV-UN': { defaultMessage: 'La Unión' },
    'SV-US': { defaultMessage: 'Usulután' },
    'SY-DI': { defaultMessage: 'Dimashq' },
    'SY-DR': { defaultMessage: "Dar'ā" },
    'SY-DY': { defaultMessage: 'Dayr az Zawr' },
    'SY-HA': { defaultMessage: 'Al Ḩasakah' },
    'SY-HI': { defaultMessage: 'Ḩimş' },
    'SY-HL': { defaultMessage: 'Ḩalab' },
    'SY-HM': { defaultMessage: 'Ḩamāh' },
    'SY-ID': { defaultMessage: 'Idlib' },
    'SY-LA': { defaultMessage: 'Al Lādhiqīyah' },
    'SY-QU': { defaultMessage: 'Al Qunayţirah' },
    'SY-RA': { defaultMessage: 'Ar Raqqah' },
    'SY-RD': { defaultMessage: 'Rīf Dimashq' },
    'SY-SU': { defaultMessage: "As Suwaydā'" },
    'SY-TA': { defaultMessage: 'Ţarţūs' },
    'SZ-HH': { defaultMessage: 'Hhohho' },
    'SZ-LU': { defaultMessage: 'Lubombo' },
    'SZ-MA': { defaultMessage: 'Manzini' },
    'SZ-SH': { defaultMessage: 'Shiselweni' },
    'TD-BA': { defaultMessage: 'Al Baţḩā’' },
    'TD-BG': { defaultMessage: 'Baḩr al Ghazāl' },
    'TD-BO': { defaultMessage: 'Būrkū' },
    'TD-CB': { defaultMessage: 'Shārī Bāqirmī' },
    'TD-EE': { defaultMessage: 'Inīdī ash Sharqī' },
    'TD-EO': { defaultMessage: 'Inīdī al Gharbī' },
    'TD-GR': { defaultMessage: 'Qīrā' },
    'TD-HL': { defaultMessage: 'Ḩajjar Lamīs' },
    'TD-KA': { defaultMessage: 'Kānim' },
    'TD-LC': { defaultMessage: 'Al Buḩayrah' },
    'TD-LO': { defaultMessage: 'Lūghūn al Gharbī' },
    'TD-LR': { defaultMessage: 'Lūghūn ash Sharqī' },
    'TD-MA': { defaultMessage: 'Māndūl' },
    'TD-MC': { defaultMessage: 'Shārī al Awsaţ' },
    'TD-ME': { defaultMessage: 'Māyū Kībbī ash Sharqī' },
    'TD-MO': { defaultMessage: 'Māyū Kībbī al Gharbī' },
    'TD-ND': { defaultMessage: 'Madīnat Injamīnā' },
    'TD-OD': { defaultMessage: 'Waddāy' },
    'TD-SA': { defaultMessage: 'Salāmāt' },
    'TD-SI': { defaultMessage: 'Sīlā' },
    'TD-TA': { defaultMessage: 'Tānjīlī' },
    'TD-TI': { defaultMessage: 'Tibastī' },
    'TD-WF': { defaultMessage: 'Wādī Fīrā’' },
    'TG-C': { defaultMessage: 'Centrale' },
    'TG-K': { defaultMessage: 'Kara' },
    'TG-M': { defaultMessage: 'Maritime (Région)' },
    'TG-P': { defaultMessage: 'Plateaux' },
    'TG-S': { defaultMessage: 'Savanes' },
    'TH-10': {
      defaultMessage: 'Krung Thep Maha Nakhon(local variant is Bangkok)',
    },
    'TH-11': { defaultMessage: 'Samut Prakan' },
    'TH-12': { defaultMessage: 'Nonthaburi' },
    'TH-13': { defaultMessage: 'Pathum Thani' },
    'TH-14': { defaultMessage: 'Phra Nakhon Si Ayutthaya' },
    'TH-15': { defaultMessage: 'Ang Thong' },
    'TH-16': { defaultMessage: 'Lop Buri' },
    'TH-17': { defaultMessage: 'Sing Buri' },
    'TH-18': { defaultMessage: 'Chai Nat' },
    'TH-19': { defaultMessage: 'Saraburi' },
    'TH-20': { defaultMessage: 'Chon Buri' },
    'TH-21': { defaultMessage: 'Rayong' },
    'TH-22': { defaultMessage: 'Chanthaburi' },
    'TH-23': { defaultMessage: 'Trat' },
    'TH-24': { defaultMessage: 'Chachoengsao' },
    'TH-25': { defaultMessage: 'Prachin Buri' },
    'TH-26': { defaultMessage: 'Nakhon Nayok' },
    'TH-27': { defaultMessage: 'Sa Kaeo' },
    'TH-30': { defaultMessage: 'Nakhon Ratchasima' },
    'TH-31': { defaultMessage: 'Buri Ram' },
    'TH-32': { defaultMessage: 'Surin' },
    'TH-33': { defaultMessage: 'Si Sa Ket' },
    'TH-34': { defaultMessage: 'Ubon Ratchathani' },
    'TH-35': { defaultMessage: 'Yasothon' },
    'TH-36': { defaultMessage: 'Chaiyaphum' },
    'TH-37': { defaultMessage: 'Amnat Charoen' },
    'TH-38': { defaultMessage: 'Bueng Kan' },
    'TH-39': { defaultMessage: 'Nong Bua Lam Phu' },
    'TH-40': { defaultMessage: 'Khon Kaen' },
    'TH-41': { defaultMessage: 'Udon Thani' },
    'TH-42': { defaultMessage: 'Loei' },
    'TH-43': { defaultMessage: 'Nong Khai' },
    'TH-44': { defaultMessage: 'Maha Sarakham' },
    'TH-45': { defaultMessage: 'Roi Et' },
    'TH-46': { defaultMessage: 'Kalasin' },
    'TH-47': { defaultMessage: 'Sakon Nakhon' },
    'TH-48': { defaultMessage: 'Nakhon Phanom' },
    'TH-49': { defaultMessage: 'Mukdahan' },
    'TH-50': { defaultMessage: 'Chiang Mai' },
    'TH-51': { defaultMessage: 'Lamphun' },
    'TH-52': { defaultMessage: 'Lampang' },
    'TH-53': { defaultMessage: 'Uttaradit' },
    'TH-54': { defaultMessage: 'Phrae' },
    'TH-55': { defaultMessage: 'Nan' },
    'TH-56': { defaultMessage: 'Phayao' },
    'TH-57': { defaultMessage: 'Chiang Rai' },
    'TH-58': { defaultMessage: 'Mae Hong Son' },
    'TH-60': { defaultMessage: 'Nakhon Sawan' },
    'TH-61': { defaultMessage: 'Uthai Thani' },
    'TH-62': { defaultMessage: 'Kamphaeng Phet' },
    'TH-63': { defaultMessage: 'Tak' },
    'TH-64': { defaultMessage: 'Sukhothai' },
    'TH-65': { defaultMessage: 'Phitsanulok' },
    'TH-66': { defaultMessage: 'Phichit' },
    'TH-67': { defaultMessage: 'Phetchabun' },
    'TH-70': { defaultMessage: 'Ratchaburi' },
    'TH-71': { defaultMessage: 'Kanchanaburi' },
    'TH-72': { defaultMessage: 'Suphan Buri' },
    'TH-73': { defaultMessage: 'Nakhon Pathom' },
    'TH-74': { defaultMessage: 'Samut Sakhon' },
    'TH-75': { defaultMessage: 'Samut Songkhram' },
    'TH-76': { defaultMessage: 'Phetchaburi' },
    'TH-77': { defaultMessage: 'Prachuap Khiri Khan' },
    'TH-80': { defaultMessage: 'Nakhon Si Thammarat' },
    'TH-81': { defaultMessage: 'Krabi' },
    'TH-82': { defaultMessage: 'Phangnga' },
    'TH-83': { defaultMessage: 'Phuket' },
    'TH-84': { defaultMessage: 'Surat Thani' },
    'TH-85': { defaultMessage: 'Ranong' },
    'TH-86': { defaultMessage: 'Chumphon' },
    'TH-90': { defaultMessage: 'Songkhla' },
    'TH-91': { defaultMessage: 'Satun' },
    'TH-92': { defaultMessage: 'Trang' },
    'TH-93': { defaultMessage: 'Phatthalung' },
    'TH-94': { defaultMessage: 'Pattani' },
    'TH-95': { defaultMessage: 'Yala' },
    'TH-96': { defaultMessage: 'Narathiwat' },
    'TH-S': { defaultMessage: 'Phatthaya' },
    'TJ-DU': { defaultMessage: 'Dushanbe' },
    'TJ-GB': { defaultMessage: 'Kŭhistoni Badakhshon' },
    'TJ-KT': { defaultMessage: 'Khatlon' },
    'TJ-RA': { defaultMessage: 'nohiyahoi tobei jumhurí' },
    'TJ-SU': { defaultMessage: 'Sughd' },
    'TL-AL': { defaultMessage: 'Aileu' },
    'TL-AN': { defaultMessage: 'Ainaro' },
    'TL-BA': { defaultMessage: 'Baucau' },
    'TL-BO': { defaultMessage: 'Bobonaro' },
    'TL-CO': { defaultMessage: 'Cova Lima' },
    'TL-DI': { defaultMessage: 'Díli' },
    'TL-ER': { defaultMessage: 'Ermera' },
    'TL-LA': { defaultMessage: 'Lautém' },
    'TL-LI': { defaultMessage: 'Liquiça' },
    'TL-MF': { defaultMessage: 'Manufahi' },
    'TL-MT': { defaultMessage: 'Manatuto' },
    'TL-OE': { defaultMessage: 'Oé-Cusse Ambeno(local variant is Oecussi)' },
    'TL-VI': { defaultMessage: 'Viqueque' },
    'TM-A': { defaultMessage: 'Ahal' },
    'TM-B': { defaultMessage: 'Balkan' },
    'TM-D': { defaultMessage: 'Daşoguz' },
    'TM-L': { defaultMessage: 'Lebap' },
    'TM-M': { defaultMessage: 'Mary' },
    'TM-S': { defaultMessage: 'Aşgabat' },
    'TN-11': { defaultMessage: 'Tunis' },
    'TN-12': { defaultMessage: "L'Ariana" },
    'TN-13': { defaultMessage: 'Ben Arous' },
    'TN-14': { defaultMessage: 'La Manouba' },
    'TN-21': { defaultMessage: 'Nabeul' },
    'TN-22': { defaultMessage: 'Zaghouan' },
    'TN-23': { defaultMessage: 'Bizerte' },
    'TN-31': { defaultMessage: 'Béja' },
    'TN-32': { defaultMessage: 'Jendouba' },
    'TN-33': { defaultMessage: 'Le Kef' },
    'TN-34': { defaultMessage: 'Siliana' },
    'TN-41': { defaultMessage: 'Kairouan' },
    'TN-42': { defaultMessage: 'Kasserine' },
    'TN-43': { defaultMessage: 'Sidi Bouzid' },
    'TN-51': { defaultMessage: 'Sousse' },
    'TN-52': { defaultMessage: 'Monastir' },
    'TN-53': { defaultMessage: 'Mahdia' },
    'TN-61': { defaultMessage: 'Sfax' },
    'TN-71': { defaultMessage: 'Gafsa' },
    'TN-72': { defaultMessage: 'Tozeur' },
    'TN-73': { defaultMessage: 'Kébili' },
    'TN-81': { defaultMessage: 'Gabès' },
    'TN-82': { defaultMessage: 'Médenine' },
    'TN-83': { defaultMessage: 'Tataouine' },
    'TO-01': { defaultMessage: "'Eua" },
    'TO-02': { defaultMessage: "Ha'apai" },
    'TO-03': { defaultMessage: 'Niuas' },
    'TO-04': { defaultMessage: 'Tongatapu' },
    'TO-05': { defaultMessage: "Vava'u" },
    'TR-01': { defaultMessage: 'Adana' },
    'TR-02': { defaultMessage: 'Adıyaman' },
    'TR-03': { defaultMessage: 'Afyonkarahisar' },
    'TR-04': { defaultMessage: 'Ağrı' },
    'TR-05': { defaultMessage: 'Amasya' },
    'TR-06': { defaultMessage: 'Ankara' },
    'TR-07': { defaultMessage: 'Antalya' },
    'TR-08': { defaultMessage: 'Artvin' },
    'TR-09': { defaultMessage: 'Aydın' },
    'TR-10': { defaultMessage: 'Balıkesir' },
    'TR-11': { defaultMessage: 'Bilecik' },
    'TR-12': { defaultMessage: 'Bingöl' },
    'TR-13': { defaultMessage: 'Bitlis' },
    'TR-14': { defaultMessage: 'Bolu' },
    'TR-15': { defaultMessage: 'Burdur' },
    'TR-16': { defaultMessage: 'Bursa' },
    'TR-17': { defaultMessage: 'Çanakkale' },
    'TR-18': { defaultMessage: 'Çankırı' },
    'TR-19': { defaultMessage: 'Çorum' },
    'TR-20': { defaultMessage: 'Denizli' },
    'TR-21': { defaultMessage: 'Diyarbakır' },
    'TR-22': { defaultMessage: 'Edirne' },
    'TR-23': { defaultMessage: 'Elazığ' },
    'TR-24': { defaultMessage: 'Erzincan' },
    'TR-25': { defaultMessage: 'Erzurum' },
    'TR-26': { defaultMessage: 'Eskişehir' },
    'TR-27': { defaultMessage: 'Gaziantep' },
    'TR-28': { defaultMessage: 'Giresun' },
    'TR-29': { defaultMessage: 'Gümüşhane' },
    'TR-30': { defaultMessage: 'Hakkâri' },
    'TR-31': { defaultMessage: 'Hatay' },
    'TR-32': { defaultMessage: 'Isparta' },
    'TR-33': { defaultMessage: 'Mersin' },
    'TR-34': { defaultMessage: 'İstanbul' },
    'TR-35': { defaultMessage: 'İzmir' },
    'TR-36': { defaultMessage: 'Kars' },
    'TR-37': { defaultMessage: 'Kastamonu' },
    'TR-38': { defaultMessage: 'Kayseri' },
    'TR-39': { defaultMessage: 'Kırklareli' },
    'TR-40': { defaultMessage: 'Kırşehir' },
    'TR-41': { defaultMessage: 'Kocaeli' },
    'TR-42': { defaultMessage: 'Konya' },
    'TR-43': { defaultMessage: 'Kütahya' },
    'TR-44': { defaultMessage: 'Malatya' },
    'TR-45': { defaultMessage: 'Manisa' },
    'TR-46': { defaultMessage: 'Kahramanmaraş' },
    'TR-47': { defaultMessage: 'Mardin' },
    'TR-48': { defaultMessage: 'Muğla' },
    'TR-49': { defaultMessage: 'Muş' },
    'TR-50': { defaultMessage: 'Nevşehir' },
    'TR-51': { defaultMessage: 'Niğde' },
    'TR-52': { defaultMessage: 'Ordu' },
    'TR-53': { defaultMessage: 'Rize' },
    'TR-54': { defaultMessage: 'Sakarya' },
    'TR-55': { defaultMessage: 'Samsun' },
    'TR-56': { defaultMessage: 'Siirt' },
    'TR-57': { defaultMessage: 'Sinop' },
    'TR-58': { defaultMessage: 'Sivas' },
    'TR-59': { defaultMessage: 'Tekirdağ' },
    'TR-60': { defaultMessage: 'Tokat' },
    'TR-61': { defaultMessage: 'Trabzon' },
    'TR-62': { defaultMessage: 'Tunceli' },
    'TR-63': { defaultMessage: 'Şanlıurfa' },
    'TR-64': { defaultMessage: 'Uşak' },
    'TR-65': { defaultMessage: 'Van' },
    'TR-66': { defaultMessage: 'Yozgat' },
    'TR-67': { defaultMessage: 'Zonguldak' },
    'TR-68': { defaultMessage: 'Aksaray' },
    'TR-69': { defaultMessage: 'Bayburt' },
    'TR-70': { defaultMessage: 'Karaman' },
    'TR-71': { defaultMessage: 'Kırıkkale' },
    'TR-72': { defaultMessage: 'Batman' },
    'TR-73': { defaultMessage: 'Şırnak' },
    'TR-74': { defaultMessage: 'Bartın' },
    'TR-75': { defaultMessage: 'Ardahan' },
    'TR-76': { defaultMessage: 'Iğdır' },
    'TR-77': { defaultMessage: 'Yalova' },
    'TR-78': { defaultMessage: 'Karabük' },
    'TR-79': { defaultMessage: 'Kilis' },
    'TR-80': { defaultMessage: 'Osmaniye' },
    'TR-81': { defaultMessage: 'Düzce' },
    'TT-ARI': { defaultMessage: 'Arima' },
    'TT-CHA': { defaultMessage: 'Chaguanas' },
    'TT-CTT': { defaultMessage: 'Couva–Tabaquite–Talparo' },
    'TT-DMN': { defaultMessage: 'Diego Martin' },
    'TT-MRC': { defaultMessage: 'Mayaro-Rio Claro' },
    'TT-PED': { defaultMessage: 'Penal-Debe' },
    'TT-POS': { defaultMessage: 'Port of Spain' },
    'TT-PRT': { defaultMessage: 'Princes Town' },
    'TT-PTF': { defaultMessage: 'Point Fortin' },
    'TT-SFO': { defaultMessage: 'San Fernando' },
    'TT-SGE': { defaultMessage: 'Sangre Grande' },
    'TT-SIP': { defaultMessage: 'Siparia' },
    'TT-SJL': { defaultMessage: 'San Juan-Laventille' },
    'TT-TOB': { defaultMessage: 'Tobago' },
    'TT-TUP': { defaultMessage: 'Tunapuna-Piarco' },
    'TV-FUN': { defaultMessage: 'Funafuti' },
    'TV-NIT': { defaultMessage: 'Niutao' },
    'TV-NKF': { defaultMessage: 'Nukufetau' },
    'TV-NKL': { defaultMessage: 'Nukulaelae' },
    'TV-NMA': { defaultMessage: 'Nanumea' },
    'TV-NMG': { defaultMessage: 'Nanumaga' },
    'TV-NUI': { defaultMessage: 'Nui' },
    'TV-VAI': { defaultMessage: 'Vaitupu' },
    'TW-CHA': { defaultMessage: 'Changhua' },
    'TW-CYI': { defaultMessage: 'Chiayi' },
    'TW-CYQ': { defaultMessage: 'Chiayi' },
    'TW-HSQ': { defaultMessage: 'Hsinchu' },
    'TW-HSZ': { defaultMessage: 'Hsinchu' },
    'TW-HUA': { defaultMessage: 'Hualien' },
    'TW-ILA': { defaultMessage: 'Yilan' },
    'TW-KEE': { defaultMessage: 'Keelung' },
    'TW-KHH': { defaultMessage: 'Kaohsiung' },
    'TW-KIN': { defaultMessage: 'Kinmen' },
    'TW-LIE': { defaultMessage: 'Lienchiang' },
    'TW-MIA': { defaultMessage: 'Miaoli' },
    'TW-NAN': { defaultMessage: 'Nantou' },
    'TW-NWT': { defaultMessage: 'New Taipei' },
    'TW-PEN': { defaultMessage: 'Penghu' },
    'TW-PIF': { defaultMessage: 'Pingtung' },
    'TW-TAO': { defaultMessage: 'Taoyuan' },
    'TW-TNN': { defaultMessage: 'Tainan' },
    'TW-TPE': { defaultMessage: 'Taipei' },
    'TW-TTT': { defaultMessage: 'Taitung' },
    'TW-TXG': { defaultMessage: 'Taichung' },
    'TW-YUN': { defaultMessage: 'Yunlin' },
    'TZ-01': { defaultMessage: 'Arusha' },
    'TZ-02': { defaultMessage: 'Dar es Salaam' },
    'TZ-03': { defaultMessage: 'Dodoma' },
    'TZ-04': { defaultMessage: 'Iringa' },
    'TZ-05': { defaultMessage: 'Kagera' },
    'TZ-06': { defaultMessage: 'Kaskazini Pemba' },
    'TZ-07': { defaultMessage: 'Kaskazini Unguja' },
    'TZ-08': { defaultMessage: 'Kigoma' },
    'TZ-09': { defaultMessage: 'Kilimanjaro' },
    'TZ-10': { defaultMessage: 'Kusini Pemba' },
    'TZ-11': { defaultMessage: 'Kusini Unguja' },
    'TZ-12': { defaultMessage: 'Lindi' },
    'TZ-13': { defaultMessage: 'Mara' },
    'TZ-14': { defaultMessage: 'Mbeya' },
    'TZ-15': { defaultMessage: 'Mjini Magharibi' },
    'TZ-16': { defaultMessage: 'Morogoro' },
    'TZ-17': { defaultMessage: 'Mtwara' },
    'TZ-18': { defaultMessage: 'Mwanza' },
    'TZ-19': { defaultMessage: 'Pwani' },
    'TZ-20': { defaultMessage: 'Rukwa' },
    'TZ-21': { defaultMessage: 'Ruvuma' },
    'TZ-22': { defaultMessage: 'Shinyanga' },
    'TZ-23': { defaultMessage: 'Singida' },
    'TZ-24': { defaultMessage: 'Tabora' },
    'TZ-25': { defaultMessage: 'Tanga' },
    'TZ-26': { defaultMessage: 'Manyara' },
    'TZ-27': { defaultMessage: 'Geita' },
    'TZ-28': { defaultMessage: 'Katavi' },
    'TZ-29': { defaultMessage: 'Njombe' },
    'TZ-30': { defaultMessage: 'Simiyu' },
    'TZ-31': { defaultMessage: 'Songwe' },
    'UA-05': { defaultMessage: 'Vinnytska oblast' },
    'UA-07': { defaultMessage: 'Volynska oblast' },
    'UA-09': { defaultMessage: 'Luhanska oblast' },
    'UA-12': { defaultMessage: 'Dnipropetrovska oblast' },
    'UA-14': { defaultMessage: 'Donetska oblast' },
    'UA-18': { defaultMessage: 'Zhytomyrska oblast' },
    'UA-21': { defaultMessage: 'Zakarpatska oblast' },
    'UA-23': { defaultMessage: 'Zaporizka oblast' },
    'UA-26': { defaultMessage: 'Ivano-Frankivska oblast' },
    'UA-30': { defaultMessage: 'Kyiv' },
    'UA-32': { defaultMessage: 'Kyivska oblast' },
    'UA-35': { defaultMessage: 'Kirovohradska oblast' },
    'UA-40': { defaultMessage: 'Sevastopol' },
    'UA-43': { defaultMessage: 'Avtonomna Respublika Krym' },
    'UA-46': { defaultMessage: 'Lvivska oblast' },
    'UA-48': { defaultMessage: 'Mykolaivska oblast' },
    'UA-51': { defaultMessage: 'Odeska oblast' },
    'UA-53': { defaultMessage: 'Poltavska oblast' },
    'UA-56': { defaultMessage: 'Rivnenska oblast' },
    'UA-59': { defaultMessage: 'Sumska oblast' },
    'UA-61': { defaultMessage: 'Ternopilska oblast' },
    'UA-63': { defaultMessage: 'Kharkivska oblast' },
    'UA-65': { defaultMessage: 'Khersonska oblast' },
    'UA-68': { defaultMessage: 'Khmelnytska oblast' },
    'UA-71': { defaultMessage: 'Cherkaska oblast' },
    'UA-74': { defaultMessage: 'Chernihivska oblast' },
    'UA-77': { defaultMessage: 'Chernivetska oblast' },
    'UG-101': { defaultMessage: 'Kalangala' },
    'UG-102': { defaultMessage: 'Kampala' },
    'UG-103': { defaultMessage: 'Kiboga' },
    'UG-104': { defaultMessage: 'Luwero' },
    'UG-105': { defaultMessage: 'Masaka' },
    'UG-106': { defaultMessage: 'Mpigi' },
    'UG-107': { defaultMessage: 'Mubende' },
    'UG-108': { defaultMessage: 'Mukono' },
    'UG-109': { defaultMessage: 'Nakasongola' },
    'UG-110': { defaultMessage: 'Rakai' },
    'UG-111': { defaultMessage: 'Sembabule' },
    'UG-112': { defaultMessage: 'Kayunga' },
    'UG-113': { defaultMessage: 'Wakiso' },
    'UG-114': { defaultMessage: 'Lyantonde' },
    'UG-115': { defaultMessage: 'Mityana' },
    'UG-116': { defaultMessage: 'Nakaseke' },
    'UG-117': { defaultMessage: 'Buikwe' },
    'UG-118': { defaultMessage: 'Bukomansibi' },
    'UG-119': { defaultMessage: 'Butambala' },
    'UG-120': { defaultMessage: 'Buvuma' },
    'UG-121': { defaultMessage: 'Gomba' },
    'UG-122': { defaultMessage: 'Kalungu' },
    'UG-123': { defaultMessage: 'Kyankwanzi' },
    'UG-124': { defaultMessage: 'Lwengo' },
    'UG-125': { defaultMessage: 'Kyotera' },
    'UG-126': { defaultMessage: 'Kasanda' },
    'UG-201': { defaultMessage: 'Bugiri' },
    'UG-202': { defaultMessage: 'Busia' },
    'UG-203': { defaultMessage: 'Iganga' },
    'UG-204': { defaultMessage: 'Jinja' },
    'UG-205': { defaultMessage: 'Kamuli' },
    'UG-206': { defaultMessage: 'Kapchorwa' },
    'UG-207': { defaultMessage: 'Katakwi' },
    'UG-208': { defaultMessage: 'Kumi' },
    'UG-209': { defaultMessage: 'Mbale' },
    'UG-210': { defaultMessage: 'Pallisa' },
    'UG-211': { defaultMessage: 'Soroti' },
    'UG-212': { defaultMessage: 'Tororo' },
    'UG-213': { defaultMessage: 'Kaberamaido' },
    'UG-214': { defaultMessage: 'Mayuge' },
    'UG-215': { defaultMessage: 'Sironko' },
    'UG-216': { defaultMessage: 'Amuria' },
    'UG-217': { defaultMessage: 'Budaka' },
    'UG-218': { defaultMessage: 'Bududa' },
    'UG-219': { defaultMessage: 'Bukedea' },
    'UG-220': { defaultMessage: 'Bukwo' },
    'UG-221': { defaultMessage: 'Butaleja' },
    'UG-222': { defaultMessage: 'Kaliro' },
    'UG-223': { defaultMessage: 'Manafwa' },
    'UG-224': { defaultMessage: 'Namutumba' },
    'UG-225': { defaultMessage: 'Bulambuli' },
    'UG-226': { defaultMessage: 'Buyende' },
    'UG-227': { defaultMessage: 'Kibuku' },
    'UG-228': { defaultMessage: 'Kween' },
    'UG-229': { defaultMessage: 'Luuka' },
    'UG-230': { defaultMessage: 'Namayingo' },
    'UG-231': { defaultMessage: 'Ngora' },
    'UG-232': { defaultMessage: 'Serere' },
    'UG-233': { defaultMessage: 'Butebo' },
    'UG-234': { defaultMessage: 'Namisindwa' },
    'UG-235': { defaultMessage: 'Bugweri' },
    'UG-236': { defaultMessage: 'Kapelebyong' },
    'UG-237': { defaultMessage: 'Kalaki' },
    'UG-301': { defaultMessage: 'Adjumani' },
    'UG-302': { defaultMessage: 'Apac' },
    'UG-303': { defaultMessage: 'Arua' },
    'UG-304': { defaultMessage: 'Gulu' },
    'UG-305': { defaultMessage: 'Kitgum' },
    'UG-306': { defaultMessage: 'Kotido' },
    'UG-307': { defaultMessage: 'Lira' },
    'UG-308': { defaultMessage: 'Moroto' },
    'UG-309': { defaultMessage: 'Moyo' },
    'UG-310': { defaultMessage: 'Nebbi' },
    'UG-311': { defaultMessage: 'Nakapiripirit' },
    'UG-312': { defaultMessage: 'Pader' },
    'UG-313': { defaultMessage: 'Yumbe' },
    'UG-314': { defaultMessage: 'Abim' },
    'UG-315': { defaultMessage: 'Amolatar' },
    'UG-316': { defaultMessage: 'Amuru' },
    'UG-317': { defaultMessage: 'Dokolo' },
    'UG-318': { defaultMessage: 'Kaabong' },
    'UG-319': { defaultMessage: 'Koboko' },
    'UG-320': { defaultMessage: 'Maracha' },
    'UG-321': { defaultMessage: 'Oyam' },
    'UG-322': { defaultMessage: 'Agago' },
    'UG-323': { defaultMessage: 'Alebtong' },
    'UG-324': { defaultMessage: 'Amudat' },
    'UG-325': { defaultMessage: 'Kole' },
    'UG-326': { defaultMessage: 'Lamwo' },
    'UG-327': { defaultMessage: 'Napak' },
    'UG-328': { defaultMessage: 'Nwoya' },
    'UG-329': { defaultMessage: 'Otuke' },
    'UG-330': { defaultMessage: 'Zombo' },
    'UG-331': { defaultMessage: 'Omoro' },
    'UG-332': { defaultMessage: 'Pakwach' },
    'UG-333': { defaultMessage: 'Kwania' },
    'UG-334': { defaultMessage: 'Nabilatuk' },
    'UG-335': { defaultMessage: 'Karenga' },
    'UG-336': { defaultMessage: 'Madi-Okollo' },
    'UG-337': { defaultMessage: 'Obongi' },
    'UG-401': { defaultMessage: 'Bundibugyo' },
    'UG-402': { defaultMessage: 'Bushenyi' },
    'UG-403': { defaultMessage: 'Hoima' },
    'UG-404': { defaultMessage: 'Kabale' },
    'UG-405': { defaultMessage: 'Kabarole' },
    'UG-406': { defaultMessage: 'Kasese' },
    'UG-407': { defaultMessage: 'Kibaale' },
    'UG-408': { defaultMessage: 'Kisoro' },
    'UG-409': { defaultMessage: 'Masindi' },
    'UG-410': { defaultMessage: 'Mbarara' },
    'UG-411': { defaultMessage: 'Ntungamo' },
    'UG-412': { defaultMessage: 'Rukungiri' },
    'UG-413': { defaultMessage: 'Kamwenge' },
    'UG-414': { defaultMessage: 'Kanungu' },
    'UG-415': { defaultMessage: 'Kyenjojo' },
    'UG-416': { defaultMessage: 'Buliisa' },
    'UG-417': { defaultMessage: 'Ibanda' },
    'UG-418': { defaultMessage: 'Isingiro' },
    'UG-419': { defaultMessage: 'Kiruhura' },
    'UG-420': { defaultMessage: 'Buhweju' },
    'UG-421': { defaultMessage: 'Kiryandongo' },
    'UG-422': { defaultMessage: 'Kyegegwa' },
    'UG-423': { defaultMessage: 'Mitooma' },
    'UG-424': { defaultMessage: 'Ntoroko' },
    'UG-425': { defaultMessage: 'Rubirizi' },
    'UG-426': { defaultMessage: 'Sheema' },
    'UG-427': { defaultMessage: 'Kagadi' },
    'UG-428': { defaultMessage: 'Kakumiro' },
    'UG-429': { defaultMessage: 'Rubanda' },
    'UG-430': { defaultMessage: 'Bunyangabu' },
    'UG-431': { defaultMessage: 'Rukiga' },
    'UG-432': { defaultMessage: 'Kikuube' },
    'UG-433': { defaultMessage: 'Kazo' },
    'UG-434': { defaultMessage: 'Kitagwenda' },
    'UG-435': { defaultMessage: 'Rwampara' },
    'UG-C': { defaultMessage: 'Central' },
    'UG-E': { defaultMessage: 'Eastern' },
    'UG-N': { defaultMessage: 'Northern' },
    'UG-W': { defaultMessage: 'Western' },
    'UM-67': { defaultMessage: 'Johnston Atoll' },
    'UM-71': { defaultMessage: 'Midway Islands' },
    'UM-76': { defaultMessage: 'Navassa Island' },
    'UM-79': { defaultMessage: 'Wake Island' },
    'UM-81': { defaultMessage: 'Baker Island' },
    'UM-84': { defaultMessage: 'Howland Island' },
    'UM-86': { defaultMessage: 'Jarvis Island' },
    'UM-89': { defaultMessage: 'Kingman Reef' },
    'UM-95': { defaultMessage: 'Palmyra Atoll' },
    'US-AK': { defaultMessage: 'Alaska' },
    'US-AL': { defaultMessage: 'Alabama' },
    'US-AR': { defaultMessage: 'Arkansas' },
    'US-AS': { defaultMessage: 'American Samoa' },
    'US-AZ': { defaultMessage: 'Arizona' },
    'US-CA': { defaultMessage: 'California' },
    'US-CO': { defaultMessage: 'Colorado' },
    'US-CT': { defaultMessage: 'Connecticut' },
    'US-DC': { defaultMessage: 'District of Columbia' },
    'US-DE': { defaultMessage: 'Delaware' },
    'US-FL': { defaultMessage: 'Florida' },
    'US-GA': { defaultMessage: 'Georgia' },
    'US-GU': { defaultMessage: 'Guam' },
    'US-HI': { defaultMessage: 'Hawaii' },
    'US-IA': { defaultMessage: 'Iowa' },
    'US-ID': { defaultMessage: 'Idaho' },
    'US-IL': { defaultMessage: 'Illinois' },
    'US-IN': { defaultMessage: 'Indiana' },
    'US-KS': { defaultMessage: 'Kansas' },
    'US-KY': { defaultMessage: 'Kentucky' },
    'US-LA': { defaultMessage: 'Louisiana' },
    'US-MA': { defaultMessage: 'Massachusetts' },
    'US-MD': { defaultMessage: 'Maryland' },
    'US-ME': { defaultMessage: 'Maine' },
    'US-MI': { defaultMessage: 'Michigan' },
    'US-MN': { defaultMessage: 'Minnesota' },
    'US-MO': { defaultMessage: 'Missouri' },
    'US-MP': { defaultMessage: 'Northern Mariana Islands' },
    'US-MS': { defaultMessage: 'Mississippi' },
    'US-MT': { defaultMessage: 'Montana' },
    'US-NC': { defaultMessage: 'North Carolina' },
    'US-ND': { defaultMessage: 'North Dakota' },
    'US-NE': { defaultMessage: 'Nebraska' },
    'US-NH': { defaultMessage: 'New Hampshire' },
    'US-NJ': { defaultMessage: 'New Jersey' },
    'US-NM': { defaultMessage: 'New Mexico' },
    'US-NV': { defaultMessage: 'Nevada' },
    'US-NY': { defaultMessage: 'New York' },
    'US-OH': { defaultMessage: 'Ohio' },
    'US-OK': { defaultMessage: 'Oklahoma' },
    'US-OR': { defaultMessage: 'Oregon' },
    'US-PA': { defaultMessage: 'Pennsylvania' },
    'US-PR': { defaultMessage: 'Puerto Rico' },
    'US-RI': { defaultMessage: 'Rhode Island' },
    'US-SC': { defaultMessage: 'South Carolina' },
    'US-SD': { defaultMessage: 'South Dakota' },
    'US-TN': { defaultMessage: 'Tennessee' },
    'US-TX': { defaultMessage: 'Texas' },
    'US-UM': { defaultMessage: 'United States Minor Outlying Islands' },
    'US-UT': { defaultMessage: 'Utah' },
    'US-VA': { defaultMessage: 'Virginia' },
    'US-VI': { defaultMessage: 'U.S. Virgin Islands' },
    'US-VT': { defaultMessage: 'Vermont' },
    'US-WA': { defaultMessage: 'Washington' },
    'US-WI': { defaultMessage: 'Wisconsin' },
    'US-WV': { defaultMessage: 'West Virginia' },
    'US-WY': { defaultMessage: 'Wyoming' },
    'UY-AR': { defaultMessage: 'Artigas' },
    'UY-CA': { defaultMessage: 'Canelones' },
    'UY-CL': { defaultMessage: 'Cerro Largo' },
    'UY-CO': { defaultMessage: 'Colonia' },
    'UY-DU': { defaultMessage: 'Durazno' },
    'UY-FD': { defaultMessage: 'Florida' },
    'UY-FS': { defaultMessage: 'Flores' },
    'UY-LA': { defaultMessage: 'Lavalleja' },
    'UY-MA': { defaultMessage: 'Maldonado' },
    'UY-MO': { defaultMessage: 'Montevideo' },
    'UY-PA': { defaultMessage: 'Paysandú' },
    'UY-RN': { defaultMessage: 'Río Negro' },
    'UY-RO': { defaultMessage: 'Rocha' },
    'UY-RV': { defaultMessage: 'Rivera' },
    'UY-SA': { defaultMessage: 'Salto' },
    'UY-SJ': { defaultMessage: 'San José' },
    'UY-SO': { defaultMessage: 'Soriano' },
    'UY-TA': { defaultMessage: 'Tacuarembó' },
    'UY-TT': { defaultMessage: 'Treinta y Tres' },
    'UZ-AN': { defaultMessage: 'Andijon' },
    'UZ-BU': { defaultMessage: 'Buxoro' },
    'UZ-FA': { defaultMessage: 'Farg‘ona' },
    'UZ-JI': { defaultMessage: 'Jizzax' },
    'UZ-NG': { defaultMessage: 'Namangan' },
    'UZ-NW': { defaultMessage: 'Navoiy' },
    'UZ-QA': { defaultMessage: 'Qashqadaryo' },
    'UZ-QR': { defaultMessage: 'Qoraqalpog‘iston Respublikasi' },
    'UZ-SA': { defaultMessage: 'Samarqand' },
    'UZ-SI': { defaultMessage: 'Sirdaryo' },
    'UZ-SU': { defaultMessage: 'Surxondaryo' },
    'UZ-TK': { defaultMessage: 'Toshkent' },
    'UZ-TO': { defaultMessage: 'Toshkent' },
    'UZ-XO': { defaultMessage: 'Xorazm' },
    'VC-01': { defaultMessage: 'Charlotte' },
    'VC-02': { defaultMessage: 'Saint Andrew' },
    'VC-03': { defaultMessage: 'Saint David' },
    'VC-04': { defaultMessage: 'Saint George' },
    'VC-05': { defaultMessage: 'Saint Patrick' },
    'VC-06': { defaultMessage: 'Grenadines' },
    'VE-A': { defaultMessage: 'Distrito Capital' },
    'VE-B': { defaultMessage: 'Anzoátegui' },
    'VE-C': { defaultMessage: 'Apure' },
    'VE-D': { defaultMessage: 'Aragua' },
    'VE-E': { defaultMessage: 'Barinas' },
    'VE-F': { defaultMessage: 'Bolívar' },
    'VE-G': { defaultMessage: 'Carabobo' },
    'VE-H': { defaultMessage: 'Cojedes' },
    'VE-I': { defaultMessage: 'Falcón' },
    'VE-J': { defaultMessage: 'Guárico' },
    'VE-K': { defaultMessage: 'Lara' },
    'VE-L': { defaultMessage: 'Mérida' },
    'VE-M': { defaultMessage: 'Miranda' },
    'VE-N': { defaultMessage: 'Monagas' },
    'VE-O': { defaultMessage: 'Nueva Esparta' },
    'VE-P': { defaultMessage: 'Portuguesa' },
    'VE-R': { defaultMessage: 'Sucre' },
    'VE-S': { defaultMessage: 'Táchira' },
    'VE-T': { defaultMessage: 'Trujillo' },
    'VE-U': { defaultMessage: 'Yaracuy' },
    'VE-V': { defaultMessage: 'Zulia' },
    'VE-W': { defaultMessage: 'Dependencias Federales' },
    'VE-X': { defaultMessage: 'Vargas' },
    'VE-Y': { defaultMessage: 'Delta Amacuro' },
    'VE-Z': { defaultMessage: 'Amazonas' },
    'VN-01': { defaultMessage: 'Lai Châu' },
    'VN-02': { defaultMessage: 'Lào Cai' },
    'VN-03': { defaultMessage: 'Hà Giang' },
    'VN-04': { defaultMessage: 'Cao Bằng' },
    'VN-05': { defaultMessage: 'Sơn La' },
    'VN-06': { defaultMessage: 'Yên Bái' },
    'VN-07': { defaultMessage: 'Tuyên Quang' },
    'VN-09': { defaultMessage: 'Lạng Sơn' },
    'VN-13': { defaultMessage: 'Quảng Ninh' },
    'VN-14': { defaultMessage: 'Hòa Bình' },
    'VN-18': { defaultMessage: 'Ninh Bình' },
    'VN-20': { defaultMessage: 'Thái Bình' },
    'VN-21': { defaultMessage: 'Thanh Hóa' },
    'VN-22': { defaultMessage: 'Nghệ An' },
    'VN-23': { defaultMessage: 'Hà Tĩnh' },
    'VN-24': { defaultMessage: 'Quảng Bình' },
    'VN-25': { defaultMessage: 'Quảng Trị' },
    'VN-26': { defaultMessage: 'Thừa Thiên-Huế' },
    'VN-27': { defaultMessage: 'Quảng Nam' },
    'VN-28': { defaultMessage: 'Kon Tum' },
    'VN-29': { defaultMessage: 'Quảng Ngãi' },
    'VN-30': { defaultMessage: 'Gia Lai' },
    'VN-31': { defaultMessage: 'Bình Định' },
    'VN-32': { defaultMessage: 'Phú Yên' },
    'VN-33': { defaultMessage: 'Đắk Lắk' },
    'VN-34': { defaultMessage: 'Khánh Hòa' },
    'VN-35': { defaultMessage: 'Lâm Đồng' },
    'VN-36': { defaultMessage: 'Ninh Thuận' },
    'VN-37': { defaultMessage: 'Tây Ninh' },
    'VN-39': { defaultMessage: 'Đồng Nai' },
    'VN-40': { defaultMessage: 'Bình Thuận' },
    'VN-41': { defaultMessage: 'Long An' },
    'VN-43': { defaultMessage: 'Bà Rịa - Vũng Tàu' },
    'VN-44': { defaultMessage: 'An Giang' },
    'VN-45': { defaultMessage: 'Đồng Tháp' },
    'VN-46': { defaultMessage: 'Tiền Giang' },
    'VN-47': { defaultMessage: 'Kiến Giang' },
    'VN-49': { defaultMessage: 'Vĩnh Long' },
    'VN-50': { defaultMessage: 'Bến Tre' },
    'VN-51': { defaultMessage: 'Trà Vinh' },
    'VN-52': { defaultMessage: 'Sóc Trăng' },
    'VN-53': { defaultMessage: 'Bắc Kạn' },
    'VN-54': { defaultMessage: 'Bắc Giang' },
    'VN-55': { defaultMessage: 'Bạc Liêu' },
    'VN-56': { defaultMessage: 'Bắc Ninh' },
    'VN-57': { defaultMessage: 'Bình Dương' },
    'VN-58': { defaultMessage: 'Bình Phước' },
    'VN-59': { defaultMessage: 'Cà Mau' },
    'VN-61': { defaultMessage: 'Hải Dương' },
    'VN-63': { defaultMessage: 'Hà Nam' },
    'VN-66': { defaultMessage: 'Hưng Yên' },
    'VN-67': { defaultMessage: 'Nam Định' },
    'VN-68': { defaultMessage: 'Phú Thọ' },
    'VN-69': { defaultMessage: 'Thái Nguyên' },
    'VN-70': { defaultMessage: 'Vĩnh Phúc' },
    'VN-71': { defaultMessage: 'Điện Biên' },
    'VN-72': { defaultMessage: 'Đắk Nông' },
    'VN-73': { defaultMessage: 'Hậu Giang' },
    'VN-CT': { defaultMessage: 'Cần Thơ' },
    'VN-DN': { defaultMessage: 'Đà Nẵng' },
    'VN-HN': { defaultMessage: 'Hà Nội' },
    'VN-HP': { defaultMessage: 'Hải Phòng' },
    'VN-SG': { defaultMessage: 'Hồ Chí Minh(local variant is Sai Gon)' },
    'VU-MAP': { defaultMessage: 'Malampa' },
    'VU-PAM': { defaultMessage: 'Pénama' },
    'VU-SAM': { defaultMessage: 'Sanma' },
    'VU-SEE': { defaultMessage: 'Shéfa' },
    'VU-TAE': { defaultMessage: 'Taféa' },
    'VU-TOB': { defaultMessage: 'Torba' },
    'WF-AL': { defaultMessage: 'Alo' },
    'WF-SG': { defaultMessage: 'Sigave' },
    'WF-UV': { defaultMessage: 'Uvea' },
    'WS-AA': { defaultMessage: "A'ana" },
    'WS-AL': { defaultMessage: 'Aiga-i-le-Tai' },
    'WS-AT': { defaultMessage: 'Atua' },
    'WS-FA': { defaultMessage: "Fa'asaleleaga" },
    'WS-GE': { defaultMessage: "Gaga'emauga" },
    'WS-GI': { defaultMessage: 'Gagaifomauga' },
    'WS-PA': { defaultMessage: 'Palauli' },
    'WS-SA': { defaultMessage: "Satupa'itea" },
    'WS-TU': { defaultMessage: 'Tuamasaga' },
    'WS-VF': { defaultMessage: "Va'a-o-Fonoti" },
    'WS-VS': { defaultMessage: 'Vaisigano' },
    'YE-AB': { defaultMessage: 'Abyan' },
    'YE-AD': { defaultMessage: '‘Adan' },
    'YE-AM': { defaultMessage: '‘Amrān' },
    'YE-BA': { defaultMessage: 'Al Bayḑā’' },
    'YE-DA': { defaultMessage: 'Aḑ Ḑāli‘' },
    'YE-DH': { defaultMessage: 'Dhamār' },
    'YE-HD': { defaultMessage: 'Ḩaḑramawt' },
    'YE-HJ': { defaultMessage: 'Ḩajjah' },
    'YE-HU': { defaultMessage: 'Al Ḩudaydah' },
    'YE-IB': { defaultMessage: 'Ibb' },
    'YE-JA': { defaultMessage: 'Al Jawf' },
    'YE-LA': { defaultMessage: 'Laḩij' },
    'YE-MA': { defaultMessage: 'Ma’rib' },
    'YE-MR': { defaultMessage: 'Al Mahrah' },
    'YE-MW': { defaultMessage: 'Al Maḩwīt' },
    'YE-RA': { defaultMessage: 'Raymah' },
    'YE-SA': { defaultMessage: 'Amānat al ‘Āşimah' },
    'YE-SD': { defaultMessage: 'Şāʻdah' },
    'YE-SH': { defaultMessage: 'Shabwah' },
    'YE-SN': { defaultMessage: 'Şanʻā’' },
    'YE-SU': { defaultMessage: 'Arkhabīl Suquţrá' },
    'YE-TA': { defaultMessage: 'Tāʻizz' },
    'ZA-EC': { defaultMessage: 'Kapa-Vuxa' },
    'ZA-FS': { defaultMessage: 'Free State' },
    'ZA-GP': { defaultMessage: 'Gauteng' },
    'ZA-KZN': { defaultMessage: 'Kwazulu-Natal' },
    'ZA-LP': { defaultMessage: 'Limpopo' },
    'ZA-MP': { defaultMessage: 'Mpumalanga' },
    'ZA-NC': { defaultMessage: "Kapa-N'walungu" },
    'ZA-NW': { defaultMessage: "N'walungu-Vupeladyambu" },
    'ZA-WC': { defaultMessage: 'Kapa-Vupeladyambu' },
    'ZM-01': { defaultMessage: 'Western' },
    'ZM-02': { defaultMessage: 'Central' },
    'ZM-03': { defaultMessage: 'Eastern' },
    'ZM-04': { defaultMessage: 'Luapula' },
    'ZM-05': { defaultMessage: 'Northern' },
    'ZM-06': { defaultMessage: 'North-Western' },
    'ZM-07': { defaultMessage: 'Southern' },
    'ZM-08': { defaultMessage: 'Copperbelt' },
    'ZM-09': { defaultMessage: 'Lusaka' },
    'ZM-10': { defaultMessage: 'Muchinga' },
    'ZW-BU': { defaultMessage: 'Bulawayo' },
    'ZW-HA': { defaultMessage: 'Harare' },
    'ZW-MA': { defaultMessage: 'Manicaland' },
    'ZW-MC': { defaultMessage: 'Mashonaland Central' },
    'ZW-ME': { defaultMessage: 'Mashonaland East' },
    'ZW-MI': { defaultMessage: 'Midlands' },
    'ZW-MN': { defaultMessage: 'Matabeleland North' },
    'ZW-MS': { defaultMessage: 'Matabeleland South' },
    'ZW-MV': { defaultMessage: 'Masvingo' },
    'ZW-MW': { defaultMessage: 'Mashonaland West' },
  },
);
/* eslint-enable max-lines */
/* eslint-enable @typescript-eslint/ban-ts-comment */

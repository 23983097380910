import { mkInput, mkType, SchemaToType } from '@main/schema-utils';
import { PluginType } from '@main/sombra-types';

import { Catalog } from './catalog';
import { PlaintextContext } from './plaintextContext';
import { Plugin } from './plugin';

export const DataSiloDiscoPluginRecommendationKey = mkType({
  name: 'DataSiloDiscoPluginRecommendationKey',
  comment:
    'The key for a `DataSiloDiscoPluginRecommendation` document from DynamoDB',
  fields: {
    pluginId: {
      comment: 'The ID of the plugin that found this recommendation',
      type: 'id',
      modelName: 'plugin',
    },
    resourceId: {
      comment: 'The unique identifier for the resource',
      type: 'string',
    },
    organizationId: {
      comment: 'ID of the organization the resource belongs to',
      type: 'id',
      modelName: 'organization',
    },
    statusLatestRunTime: {
      comment:
        'The status of the recommendation, concatenated with the latest run time',
      type: 'string',
    },
    region: {
      comment: 'The AWS Region for data silo if applicable',
      type: 'string',
      optional: true,
    },
    country: {
      comment: 'The ISO country code for the AWS Region if applicable',
      type: 'string',
      optional: true,
    },
    countrySubDivision: {
      comment:
        'The ISO country subdivision code for the AWS Region if applicable',
      type: 'string',
      optional: true,
    },
    title: {
      comment: 'The custom title of the data silo recommendation.',
      type: 'string',
      optional: true,
    },
    plaintextContext: {
      comment: 'The plaintext for the resource.',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloDiscoPluginRecommendationKey = SchemaToType<
  typeof DataSiloDiscoPluginRecommendationKey
>;

export const SiloDiscoveryPendingConfirmationCountFiltersInput = mkInput({
  name: 'SiloDiscoveryPendingConfirmationCountFiltersInput',
  comment: 'Input for counting pending silo discovery recommendations',
  fields: {
    dataSiloIds: {
      comment: 'The data silo IDs to count pending recommendations for',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
    pluginIds: {
      comment: 'The plugin IDs to count pending recommendations for',
      type: 'id',
      modelName: 'plugin',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type SiloDiscoveryPendingConfirmationCountFiltersInput = SchemaToType<
  typeof SiloDiscoveryPendingConfirmationCountFiltersInput
>;

export const DataSiloDiscoPluginRecommendationKeyInput = mkInput({
  name: 'DataSiloDiscoPluginRecommendationKeyInput',
  comment:
    'The key for a `DataSiloDiscoPluginRecommendation` document from DynamoDB that can be passed as input',
  fields: DataSiloDiscoPluginRecommendationKey.fields,
});

/** Override type */
export type DataSiloDiscoPluginRecommendationKeyInput = SchemaToType<
  typeof DataSiloDiscoPluginRecommendationKeyInput
>;

export const DataSiloPluginConfiguration = mkType({
  name: 'DataSiloPluginConfiguration',
  comment: 'Configurations for a data silo plugin',
  fields: {
    defaultEnabled: {
      comment: 'Whether the plugin is enabled by default',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloPluginConfiguration = SchemaToType<
  typeof DataSiloPluginConfiguration
>;

export const DataSiloPluginConfigurations = mkType({
  name: 'DataSiloPluginConfigurations',
  comment: 'Configurations for each data silo plugins',
  fields: {
    [PluginType.DataSiloDiscovery]: {
      comment: 'Configuration for a data silo discovery plugin',
      type: DataSiloPluginConfiguration,
      optional: true,
    },
    [PluginType.SchemaDiscovery]: {
      comment: 'Configuration for a schema discovery plugin',
      type: DataSiloPluginConfiguration,
      optional: true,
    },
    [PluginType.ContentClassification]: {
      comment: 'Configuration for a content classification plugin',
      type: DataSiloPluginConfiguration,
      optional: true,
    },
    [PluginType.UnstructuredDataDiscovery]: {
      comment: 'Configuration for an unstructured data discovery plugin',
      type: DataSiloPluginConfiguration,
      optional: true,
    },
    [PluginType.UnstructuredDataSampling]: {
      comment: 'Configuration for an unstructured data sampling plugin',
      type: DataSiloPluginConfiguration,
      optional: true,
    },
  },
});

/** Override type */
export type DataSiloPluginConfigurations = SchemaToType<
  typeof DataSiloPluginConfigurations
>;

export const SiloDiscoveryRecommendation = mkType({
  name: 'SiloDiscoveryRecommendation',
  comment: 'A recommendation from a silo discovery plugin',
  fields: {
    pluginId: DataSiloDiscoPluginRecommendationKey.fields.pluginId,
    plugin: {
      comment: 'The plugin that found this recommendation',
      type: Plugin,
    },
    resourceId: DataSiloDiscoPluginRecommendationKey.fields.resourceId,
    title: DataSiloDiscoPluginRecommendationKey.fields.title,
    region: DataSiloDiscoPluginRecommendationKey.fields.region,
    country: DataSiloDiscoPluginRecommendationKey.fields.country,
    countrySubDivision:
      DataSiloDiscoPluginRecommendationKey.fields.countrySubDivision,
    suggestedCatalog: {
      comment: 'The suggested catalog for this recommendation',
      type: Catalog,
    },
    rawInputs: {
      comment:
        'The raw inputs that were provided by the plugin as additionalContext and fed into our classifier',
      type: PlaintextContext,
      list: true,
    },
    plaintextContext: {
      comment: 'The plaintext that we will pass into recommendation',
      type: 'string',
      optional: true,
    },
    lastDiscoveredAt: {
      comment:
        'Timestamp of the plugin run that found this silo recommendation',
      type: 'Date',
    },
    pluginConfigurations: {
      comment: 'The plugin configurations for the recommendation',
      type: DataSiloPluginConfigurations,
      optional: true,
    },
  },
});

/** Override type */
export type SiloDiscoveryRecommendation = SchemaToType<
  typeof SiloDiscoveryRecommendation
>;

export const SiloDiscoveryRecommendationsInput = mkInput({
  name: 'SiloDiscoveryRecommendationsInput',
  comment: 'Input for listing silo discovery recommendations',
  fields: {
    isPending: {
      comment:
        'Whether to list pending recommendations, otherwise ignored recommendations',
      type: 'boolean',
    },
    lastKey: {
      comment:
        'The `LastEvaluatedKey` received from DynamoDB for the previous page',
      type: DataSiloDiscoPluginRecommendationKeyInput,
      optional: true,
    },
  },
});

/** Override type */
export type SiloDiscoveryRecommendationsInput = SchemaToType<
  typeof SiloDiscoveryRecommendationsInput
>;

export const SiloDiscoveryRecommendationFiltersInput = mkInput({
  name: 'SiloDiscoveryRecommendationFiltersInput',
  comment: 'Input for filtering a list of silo discovery recommendations',
  fields: {
    // TODO: https://transcend.height.app/T-13511 - filter by catalog, not text
    text: {
      comment: 'Filter silo discovery recommendation by integration name',
      type: 'string',
      optional: true,
    },
    pluginIds: {
      comment:
        'Filter silo discovery recommendations by the plugin that discovered it',
      type: 'id',
      modelName: 'plugin',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type SiloDiscoveryRecommendationFiltersInput = SchemaToType<
  typeof SiloDiscoveryRecommendationFiltersInput
>;

export const SiloDiscoveryRecommendationCacheFields = mkType({
  name: 'SiloDiscoveryRecommendationCacheFields',
  comment: 'Fields used for apollo caching for our UI',
  fields: {
    isPending: {
      comment: 'Silo discovery recommendations were PENDING status',
      type: 'boolean',
      optional: true,
    },
    text: {
      comment:
        'Silo discovery recommendations were filtered by this integration name',
      type: 'string',
      optional: true,
    },
    pluginIds: {
      comment:
        'Silo discovery recommendations were filtered by the plugin that discovered it',
      type: 'id',
      modelName: 'plugin',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type SiloDiscoveryRecommendationCacheFields = SchemaToType<
  typeof SiloDiscoveryRecommendationCacheFields
>;

export const MarkSiloRecommendationsJunkInput = mkInput({
  name: 'MarkSiloRecommendationsJunkInput',
  comment: 'Input for marking data silo recommendations as junk',
  fields: {
    pluginId: DataSiloDiscoPluginRecommendationKey.fields.pluginId,
    resourceId: DataSiloDiscoPluginRecommendationKey.fields.resourceId,
  },
});

/** Override type */
export type MarkSiloRecommendationsJunkInput = SchemaToType<
  typeof MarkSiloRecommendationsJunkInput
>;

export const MarkSiloRecommendationsDuplicateInput = mkInput({
  name: 'MarkSiloRecommendationsDuplicateInput',
  comment: 'Input for marking data silo recommendations as duplicate',
  fields: {
    ...MarkSiloRecommendationsJunkInput.fields,
    dataSiloId: {
      comment: 'ID of an existing data silo',
      type: 'id',
      modelName: 'dataSilo',
    },
  },
});

/** Override type */
export type MarkSiloRecommendationsDuplicateInput = SchemaToType<
  typeof MarkSiloRecommendationsDuplicateInput
>;

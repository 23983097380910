/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const FileExclamation: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0019 9.99901V7.82611C18.0019 7.29588 17.7908 6.78667 17.4157 6.41152L14.5865 3.58234C14.2113 3.20718 13.7021 2.99609 13.1719 2.99609H5.99693C4.89147 2.99609 3.99609 3.89147 3.99609 4.99693V19.0028C3.99609 20.1082 4.89147 21.0036 5.99693 21.0036H9.99859"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0021 7.99818H14.0004C13.4482 7.99818 13 7.54999 13 6.99776V2.99609"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5019 22.0037C14.0158 22.0037 12 19.9879 12 17.5019C12 15.0158 14.0158 13 16.5019 13C18.9889 13 21.0037 15.0158 21.0037 17.5019C21.0037 19.9879 18.9889 22.0037 16.5019 22.0037"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5022 17.3353V15.6836"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5017 19.1565C16.4567 19.1565 16.4196 19.1935 16.4206 19.2385C16.4206 19.2835 16.4577 19.3205 16.5027 19.3205C16.5477 19.3205 16.5837 19.2835 16.5837 19.2385C16.5837 19.1935 16.5477 19.1565 16.5017 19.1565"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

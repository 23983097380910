/**
 * Hook to help us scroll to a given menu element
 *
 * @param id - The ID to scroll to
 * @param fallback - Fallback coordinates to scroll to [x,y]
 */
export function scrollToElement(
  id: string | undefined,
  fallback?: [number, number],
): void {
  const element = id ? document.getElementById(id) : undefined;
  if (element) {
    const yCoordinate =
      element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: yCoordinate - 110,
    });
  } else if (fallback) {
    window.scrollTo(fallback[0], fallback[1]);
  }
}

/* eslint-disable max-lines */
import * as t from 'io-ts';

import { RequestActionObjectResolver } from '@transcend-io/privacy-types';

import { TokenInput } from '@main/access-control-types';
import {
  DATABASE_PREFERENCE_PARAMS,
  dbModelId,
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';
import {
  ReSignSaaSContextsInput,
  SendEmailDhPayload,
  SignedIdentifierPath,
} from '@main/sombra-types';

import {
  ActionFiltersInput,
  ActionInternal,
  ActionOrder,
  AddDataSiloToOpenRequestsInput,
  AddSiloDiscoveryResultInput,
  AssessmentAnswer,
  AssessmentEmailSet,
  AssessmentEmailSetFiltersInput,
  AssessmentFile,
  AssessmentFileFiltersInput,
  AssessmentFormComment,
  AssessmentFormCommentFiltersInput,
  AssessmentFormFiltersInput,
  AssessmentFormRaw,
  AssessmentFormTemplateFiltersInput,
  AssessmentFormTemplateRaw,
  AssessmentGroup,
  AssessmentGroupFiltersInput,
  AssessmentQuestionComment,
  AssessmentQuestionCommentFiltersInput,
  AssessmentQuestionFiltersInput,
  AssessmentQuestionRaw,
  AssessmentQuestionSelectOptionRaw,
  AssessmentQuestionSelectOptionsFiltersInput,
  AssessmentQuestionSelectOptionsTokenFiltersInput,
  AssessmentSectionComment,
  AssessmentSectionCommentFiltersInput,
  AssessmentSectionRaw,
  BusinessEntitiesFiltersInput,
  BusinessEntity,
  BusinessEntityOrder,
  Catalog,
  CatalogFiltersInput,
  CatalogInput,
  CatalogOrder,
  CatalogSearchInput,
  Communication,
  CommunicationMetadata,
  ContentClassificationDataPointsFiltersInput,
  ContentClassificationDataPointsOrder,
  ContentClassificationDataSilo,
  ContentClassificationDataSiloFiltersInput,
  CreateAssessmentEmailSetInput,
  CreateAssessmentFormCommentsInput,
  CreateAssessmentFormsInput,
  CreateAssessmentFormTemplateInput,
  CreateAssessmentFormTokenInput,
  CreateAssessmentGroupInput,
  CreateAssessmentQuestionCommentsInput,
  CreateAssessmentQuestionInput,
  CreateAssessmentSectionCommentsInput,
  CreateAssessmentSectionInput,
  CreateBusinessEntityInput,
  CreateDataInventorySubCategoryInput,
  CreateDataReportInput,
  CreateDataSilosInput,
  CreateProcessingActivityInput,
  CreateProcessingPurposeCategoryInput,
  CreateRiskCategoriesInput,
  CreateRiskFrameworksInput,
  CreateSubDataPointInput,
  CreateSubjectInput,
  CreateVendorInput,
  DataCategoryFiltersInput,
  DataCategoryInterface,
  DataCollection,
  DataLineageDataSilo,
  DataPoint,
  DataPointFiltersInput,
  DataPointHierarchyFiltersInput,
  DataPointHierarchyItem,
  DataPointHierarchyPathFiltersInput,
  DataPointOrder,
  DataReportFiltersInput,
  DataReportRaw,
  DataReportRawOrder,
  DataSilo,
  DataSiloBulkPreview,
  DataSiloBulkPreviewOrder,
  DataSiloCommunicationFilterInput,
  DataSiloDiscoPluginRecommendationKey,
  DataSiloFiltersInput,
  DataSiloLineageFiltersInput,
  DataSiloPluginRecommendationInput,
  DataSiloReduced,
  DataSiloReducedQueryInput,
  DataSubCategory,
  DataSubCategoryFiltersInput,
  DataSubjectInternal,
  DefaultTemplates,
  DeleteAssessmentAnswerFilesInput,
  DeleteAssessmentAnswerFilesTokenInput,
  DeleteAssessmentEmailSetsInput,
  DeleteAssessmentFormCommentsInput,
  DeleteAssessmentFormsInput,
  DeleteAssessmentFormTemplatesInput,
  DeleteAssessmentGroupsInput,
  DeleteAssessmentQuestionCommentsInput,
  DeleteAssessmentQuestionsInput,
  DeleteAssessmentSectionCommentsInput,
  DeleteAssessmentSectionsInput,
  DeleteBusinessEntitiesInput,
  DeleteDataPointsInput,
  DeleteDataReportsInput,
  DeleteDataSilosInput,
  DeleteDataSubCategoriesInput,
  DeleteIdentifiersInput,
  DeleteProcessingActivitiesInput,
  DeletePurposeSubCategoriesInput,
  DeleteRiskCategoriesInput,
  DeleteRiskFrameworksInput,
  DeleteSubDataPointsInput,
  DeleteVendorsInput,
  Enricher,
  EnricherFiltersInput,
  EnricherInput,
  EnricherOrder,
  EnrichmentMetadata,
  FetchEnrichmentMetadataInput,
  FileSnippetDecryptionContext,
  GenerateSqlInput,
  Identifier,
  IdentifierFiltersInput,
  IdentifierInput,
  IdentifierOrder,
  ImportOnetrustAssessmentsInput,
  InspectLookupProcessesInput,
  InternalSubjectsFiltersInput,
  LookupProcess,
  LookupProcessFiltersInput,
  LookupProcessInput,
  LookupProcessOrder,
  LookupProcessResultCount,
  MarkSiloRecommendationsDuplicateInput,
  MarkSiloRecommendationsJunkInput,
  NewDataCollectionInput,
  NewIdentifierType,
  Plugin,
  PluginScansInput,
  PluginsFiltersInput,
  PossibleDataSiloDependencyFilterInput,
  ProcessingActivitiesFiltersInput,
  ProcessingActivity,
  ProcessingActivityOrder,
  ProcessingPurposeCategoryFiltersInput,
  ProcessingPurposeFiltersInput,
  ProcessingPurposeInterface,
  PurposeSubCategory,
  RateLimitConfig,
  RecommendedCatalogsInput,
  ReconnectDataSiloInput,
  RefetchSubDataPointSampleInput,
  RestartSiloClassificationInput,
  RestartSiloObjectClassificationsInput,
  RetentionSchedule,
  RetentionScheduleFiltersInput,
  RiskCategoriesFiltersInput,
  RiskCategory,
  RiskFramework,
  RiskFrameworksFiltersInput,
  RiskLevel,
  RiskLevelsFiltersInput,
  SaaSCategory,
  SaaSCategoryBase,
  SaaSCategoryFiltersInput,
  SaaSCategoryNamesFiltersInput,
  SaaSCategoryOrder,
  Scan,
  ScanKey,
  ScannedObjectFileTypesFiltersInput,
  ScannedObjectFileTypesOutput,
  ScannedObjectHierarchyFiltersInput,
  ScannedObjectHierarchyItem,
  ScannedObjectHierarchyPathFiltersInput,
  SchemaDiscoveryScanResult,
  Sdk,
  SdkFiltersInput,
  SdkOrder,
  SelectAssessmentQuestionAnswerInput,
  SelectAssessmentQuestionAnswersTokenInput,
  SendMessageInput,
  SiloDiscoveryPendingConfirmationCountFiltersInput,
  SiloDiscoveryRecommendation,
  SiloDiscoveryRecommendationCacheFields,
  SiloDiscoveryRecommendationFiltersInput,
  SiloDiscoveryRecommendationsInput,
  SubDataPoint,
  SubDataPointErrorRollup,
  SubDataPointErrorRollupFilterInput,
  SubDataPointFiltersInput,
  SubDataPointSampleDecryptionContext,
  Template,
  TemplateFiltersInput,
  TemplateInput,
  TemplateOrder,
  ToggleCommunicationReadInput,
  ToggleDataPointActionsInput,
  ToggleSubjectInput,
  UnstructuredDataDiscoveryFile,
  UnstructuredDataDiscoveryFilesFilterInput,
  UnstructuredDiscoveryDataSilo,
  UnstructuredSubDataPointRecommendation,
  UnstructuredSubDataPointRecommendationOrder,
  UnstructuredSubDataPointRecommendationsFilterInput,
  UnwrapFileSnippetFiltersInput,
  UnwrapSubDataPointSamplesFiltersInput,
  UpdateActionInput,
  UpdateAssessmentEmailSetInput,
  UpdateAssessmentFormCommentsInput,
  UpdateAssessmentFormInput,
  UpdateAssessmentFormQuestionInput,
  updateAssessmentFormSectionsInput,
  UpdateAssessmentFormTemplateInput,
  UpdateAssessmentGroupInput,
  UpdateAssessmentQuestionCommentInput,
  UpdateAssessmentSectionCommentInput,
  UpdateAssessmentTemplateQuestionInput,
  UpdateAssessmentTemplateSectionInput,
  UpdateBusinessEntitiesInput,
  UpdateDataPointLevelInput,
  UpdateDataReportsInput,
  UpdateDataSiloRateLimitsInput,
  UpdateDataSilosInput,
  UpdateDataSubCategoriesInput,
  UpdateEnricherInput,
  UpdateIdentifierInput,
  UpdateLookupProcessInput,
  UpdateOrCreateDataPointInput,
  UpdateOrCreateWorkflowDataPointInput,
  UpdatePluginInput,
  UpdateProcessingActivitiesInput,
  UpdateProcessingPurposeSubCategoriesInput,
  UpdateRetentionScheduleInput,
  UpdateRiskCategoriesInput,
  UpdateRiskFrameworksInput,
  UpdateSubDataPointsInput,
  UpdateSubjectInput,
  UpdateTemplateInput,
  UpdateUnstructuredSubDataPointRecommendationsInput,
  UpdateVendorsInput,
  Vendor,
  VendorCommunicationsMetadataInput,
  VendorOrder,
  VendorsFiltersInput,
} from './schema';

export const dataSilos = mkQuery({
  name: 'dataSilos',
  comment: 'List the data silos in the datamap',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: DataSiloFiltersInput.optional(),
    orderBy: DataSiloBulkPreviewOrder,
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: DataSiloBulkPreview.list(),
    totalCount: 'int',
  },
});

export const processingActivities = mkQuery({
  name: 'processingActivities',
  comment: 'List the processing activities in the datamap',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: ProcessingActivitiesFiltersInput.optional(),
    orderBy: ProcessingActivityOrder,
  },
  response: {
    nodes: ProcessingActivity.list(),
    totalCount: 'int',
  },
});

export const createProcessingActivity = mkMutation({
  name: 'createProcessingActivity',
  comment: 'Creates a new processing activity',
  params: {
    input: CreateProcessingActivityInput,
  },
  response: {
    processingActivity: ProcessingActivity,
  },
});

export const deleteProcessingActivities = mkMutation({
  name: 'deleteProcessingActivities',
  comment: 'Remove processing activities',
  params: {
    input: DeleteProcessingActivitiesInput,
  },
  response: {
    count: 'int',
  },
});

export const updateProcessingActivities = mkMutation({
  name: 'updateProcessingActivities',
  comment: 'Bulk update the configuration of one or many processing activities',
  params: {
    input: UpdateProcessingActivitiesInput,
  },
  response: {
    processingActivities: ProcessingActivity.list(),
  },
});

export const vendors = mkQuery({
  name: 'vendors',
  comment: 'List the vendors in the datamap',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: VendorsFiltersInput.optional(),
    orderBy: VendorOrder,
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: Vendor.list(),
    totalCount: 'int',
  },
});

export const deleteVendors = mkMutation({
  name: 'deleteVendors',
  comment: 'Remove vendors',
  params: {
    input: DeleteVendorsInput,
  },
  response: {
    count: 'int',
  },
});

export const createVendor = mkMutation({
  name: 'createVendor',
  comment: 'Create a new vendor',
  params: {
    input: CreateVendorInput,
  },
  response: {
    vendor: Vendor,
  },
});

export const updateVendors = mkMutation({
  name: 'updateVendors',
  comment: 'Bulk update the configuration of one or many vendors',
  params: {
    input: UpdateVendorsInput,
  },
  response: {
    vendors: Vendor.list(),
  },
});

export const businessEntities = mkQuery({
  name: 'businessEntities',
  comment: 'List the internal business entities in the datamap',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: BusinessEntitiesFiltersInput.optional(),
    orderBy: BusinessEntityOrder,
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: BusinessEntity.list(),
    totalCount: 'int',
  },
});

export const deleteBusinessEntities = mkMutation({
  name: 'deleteBusinessEntities',
  comment: 'Remove business entities',
  params: {
    input: DeleteBusinessEntitiesInput,
  },
  response: {
    count: 'int',
  },
});

export const createBusinessEntity = mkMutation({
  name: 'createBusinessEntity',
  comment: 'Create a new businessEntity',
  params: {
    input: CreateBusinessEntityInput,
  },
  response: {
    businessEntity: BusinessEntity,
  },
});

export const updateBusinessEntities = mkMutation({
  name: 'updateBusinessEntities',
  comment: 'Update the configuration of a business entity',
  params: {
    input: UpdateBusinessEntitiesInput,
  },
  response: {
    businessEntities: BusinessEntity.list(),
  },
});

export const contentClassificationSilos = mkQuery({
  name: 'contentClassificationSilos',
  comment:
    'The list of data silos with content classification plugin available',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ContentClassificationDataSiloFiltersInput.optional(),
    orderBy: DataSiloBulkPreviewOrder,
  },
  response: {
    nodes: ContentClassificationDataSilo.list(),
    totalCount: 'int',
  },
});

export const unstructuredDiscoverySilos = mkQuery({
  name: 'unstructuredDiscoverySilos',
  comment:
    'The list of data silos with unstructured discovery plugin available',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ContentClassificationDataSiloFiltersInput.optional(),
    orderBy: DataSiloBulkPreviewOrder,
  },
  response: {
    nodes: UnstructuredDiscoveryDataSilo.list(),
    totalCount: 'int',
  },
});

export const enricher = mkQuery({
  name: 'enricher',
  comment: 'Grab an enricher',
  params: {
    id: {
      modelName: 'enricher',
      type: 'id',
    },
  },
  response: Enricher,
});

export const enrichers = mkQuery({
  name: 'enrichers',
  comment: 'List of enrichers in an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: EnricherFiltersInput.optional(),
    orderBy: EnricherOrder,
  },
  response: {
    nodes: Enricher.list(),
    totalCount: 'int',
  },
});

export const dataSilo = mkQuery({
  name: 'dataSilo',
  comment: 'A data silo in the datamap',
  params: {
    id: {
      comment: 'data silo id',
      type: 'string',
    },
  },
  response: DataSilo,
});

// TODO: https://transcend.height.app/T-7619 - paginate
export const dataCollections = mkQuery({
  name: 'dataCollections',
  comment: 'List the data collections in an organization',
  params: {},
  response: DataCollection.list(),
});

export const createDataCollection = mkMutation({
  name: 'createDataCollection',
  comment: 'Create a new data collection',
  params: {
    input: NewDataCollectionInput,
  },
  response: {
    dataCollection: DataCollection,
  },
});

export const newIdentifierTypes = mkQuery({
  name: 'newIdentifierTypes',
  comment:
    'The types of identifiers that the organization can add. i.e. Email once but custom as many times as desired.',
  params: {},
  response: NewIdentifierType.list(),
});

export const dataSiloReduced = mkQuery({
  name: 'dataSiloReduced',
  comment: 'A data silo in the datamap with a minimal number of fields',
  params: { input: DataSiloReducedQueryInput },
  response: DataSiloReduced,
});

export const dataSiloLineage = mkQuery({
  name: 'dataSiloLineage',
  comment: 'A list of data silos that send and receive data from each other',
  params: {
    filterBy: DataSiloLineageFiltersInput.optional(),
  },
  response: {
    nodes: DataLineageDataSilo.list(),
  },
});

export const updateDataSilos = mkMutation({
  name: 'updateDataSilos',
  comment: 'Bulk update the settings for one or more existing data silos',
  params: {
    input: UpdateDataSilosInput,
  },
  response: {
    dataSilos: DataSilo.list(),
  },
});

export const deleteDataSilos = mkMutation({
  name: 'deleteDataSilos',
  comment: 'Delete multiple data silos for an organization',
  params: {
    input: DeleteDataSilosInput,
  },
  response: {
    success: 'boolean',
  },
});

export const reSignSaaSContexts = mkMutation({
  name: 'reSignSaaSContexts',
  comment: 'ReSign the SaaS contexts for each of the given data silos',
  params: {
    input: ReSignSaaSContextsInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({
        dataSiloIds: t.array(dbModelId('dataSilo')),
      }),
    },
  },
  response: {
    done: 'boolean',
  },
});

export const retryDataSiloErrors = mkMutation({
  name: 'retryDataSiloErrors',
  comment:
    'Retry all of the errors for a particular data silo, returning the updated data silo',
  params: {
    id: {
      type: 'id',
      modelName: 'dataSilo',
    },
  },
  response: {
    dataSilo: DataSilo,
  },
});

export const retryEnricherErrors = mkMutation({
  name: 'retryEnricherErrors',
  comment:
    'Retry all of the errors for a particular enricher, returning the updated enricher',
  params: {
    id: {
      type: 'id',
      modelName: 'enricher',
    },
  },
  response: {
    enricher: Enricher,
  },
});

export const generateDataSiloSqlStatements = mkMutation({
  name: 'generateDataSiloSqlStatements',
  comment:
    'Generate the SQL statements for a data silo based on the classifications',
  params: {
    input: GenerateSqlInput,
  },
  response: {
    dataSilo: DataSilo,
    count: 'int',
  },
});

export const internalSubjects = mkQuery({
  name: 'internalSubjects',
  comment: 'Returns all data subject configurations',
  params: {
    filterBy: InternalSubjectsFiltersInput.optional(),
  },
  response: DataSubjectInternal.list(),
});

export const createSubject = mkMutation({
  name: 'createSubject',
  comment: 'Create a new classification of data subject',
  params: {
    input: CreateSubjectInput,
  },
  response: {
    subject: DataSubjectInternal,
  },
});

export const updateSubject = mkMutation({
  name: 'updateSubject',
  comment: 'Update the configuration of a data subject class',
  params: {
    input: UpdateSubjectInput,
  },
  response: {
    subject: DataSubjectInternal,
  },
});

export const searchCatalogs = mkQuery({
  name: 'searchCatalogs',
  comment: 'Search catalogs by name (will return at most 100 matching results)',
  params: {
    input: CatalogSearchInput,
  },
  response: {
    catalogs: Catalog.list(),
  },
});

export const catalog = mkQuery({
  name: 'catalog',
  comment: 'Get the metadata for a single catalog in our registry',
  params: {
    input: CatalogInput,
  },
  response: {
    catalog: Catalog,
  },
});

export const reconnectDataSilo = mkMutation({
  name: 'reconnectDataSilo',
  comment: 'Reconnects a third party SaaS tool',
  params: {
    input: ReconnectDataSiloInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.partial({
        /** A JSON stringified payload of custom attributes related to that data silo */
        secretMap: t.string,
        /** Mapping of identifier name to JSON path for signing that identifier */
        identifierMapping: t.array(SignedIdentifierPath),
      }),
    },
  },
  response: {
    dataSilo: DataSilo,
  },
});

export const createDataSilos = mkMutation({
  name: 'createDataSilos',
  comment: 'Create shells for a list of data silos',
  params: {
    input: CreateDataSilosInput.list(),
  },
  response: {
    dataSilos: DataSilo.list(),
  },
});

export const plugins = mkQuery({
  name: 'plugins',
  comment: 'List the plugins associated with a data silo',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PluginsFiltersInput.optional(),
  },
  response: {
    plugins: Plugin.list(),
    totalCount: 'int',
  },
});

export const dataSiloPluginRecommendation = mkQuery({
  name: 'dataSiloPluginRecommendation',
  comment: 'List the plugins associated with a data silo',
  params: {
    input: DataSiloPluginRecommendationInput,
  },
  response: {
    nodes: SiloDiscoveryRecommendation.list(),
  },
});

export const updateDataSiloPlugin = mkMutation({
  name: 'updateDataSiloPlugin',
  comment: 'Toggle a data silo plugin',
  params: {
    input: UpdatePluginInput,
  },
  response: {
    plugin: Plugin,
  },
});

export const reScanPluginPermissions = mkMutation({
  name: 'reScanPluginPermissions',
  comment: 'Rescan Plugin Permissions',
  params: {
    input: UpdatePluginInput,
  },
  response: {
    plugin: Plugin,
  },
});

export const dataPoints = mkQuery({
  name: 'dataPoints',
  comment: 'List the datapoints for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: DataPointFiltersInput.optional(),
    orderBy: DataPointOrder,
  },
  response: {
    nodes: DataPoint.list(),
    totalCount: 'int',
  },
});

export const updateOrCreateDataPoint = mkMutation({
  name: 'updateOrCreateDataPoint',
  comment: 'Update a datapoint',
  params: {
    input: UpdateOrCreateDataPointInput,
    dhEncrypted: {
      isOptional: true, // only needed for database integration datapoints
      type: 'string',
      underlyingType: t.type(
        Object.values(RequestActionObjectResolver).reduce(
          (acc, action) => {
            acc[action] = t.union([t.string, t.undefined]);
            return acc;
          },
          {} as Record<
            RequestActionObjectResolver,
            t.UnionC<[t.StringC, t.UndefinedC]>
          >,
        ),
      ),
    },
  },
  response: {
    dataPoint: DataPoint,
  },
});

export const updateOrCreateWorkflowDataPoint = mkMutation({
  name: 'updateOrCreateWorkflowDataPoint',
  comment: 'Update or create a workflow data point',
  params: {
    input: UpdateOrCreateWorkflowDataPointInput,
  },
  response: {
    success: 'boolean',
  },
});

export const deleteDataPoints = mkMutation({
  name: 'deleteDataPoints',
  comment: 'Delete multiple datapoints from an organization',
  params: {
    input: DeleteDataPointsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const deleteSubDataPoints = mkMutation({
  name: 'deleteSubDataPoints',
  comment: 'Delete multiple subdatapoints from an organization',
  params: {
    input: DeleteSubDataPointsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const toggleDataPointActions = mkMutation({
  name: 'toggleDataPointActions',
  comment:
    'Toggle the active status of a datapoint action or a set of datapoint actions',
  params: {
    input: ToggleDataPointActionsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const scannedCatalogs = mkQuery({
  name: 'scannedCatalogs',
  comment: "Return catalogs that match scans of this organization's website",
  params: PAGINATION_INPUT_PARAMS,
  response: {
    nodes: Catalog.list(),
    totalCount: 'int',
  },
});

/**
 * @deprecated
 */
export const recommendedCatalogs = mkQuery({
  name: 'recommendedCatalogs',
  comment:
    "Return catalogs that match scans of this organization's website, and / or popular catalogs",
  params: {
    input: RecommendedCatalogsInput,
  },
  response: {
    catalogs: Catalog.list(),
  },
});

export const catalogs = mkQuery({
  name: 'catalogs',
  comment: 'Return a paginated view of our catalog',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: CatalogFiltersInput,
    orderBy: CatalogOrder,
  },
  response: {
    nodes: Catalog.list(),
    totalCount: 'int',
  },
});

export const sdks = mkQuery({
  name: 'sdks',
  comment: 'Return a paginated view of the sdks in our catalog',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: SdkFiltersInput,
    orderBy: SdkOrder,
  },
  response: {
    nodes: Sdk.list(),
    totalCount: 'int',
  },
});

export const updateAction = mkMutation({
  name: 'updateAction',
  comment: 'Update the configuration for a request action',
  params: {
    input: UpdateActionInput,
  },
  response: {
    action: ActionInternal,
  },
});

export const actions = mkQuery({
  name: 'actions',
  comment: 'A paginated list of preview actions',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: ActionFiltersInput.optional(),
    orderBy: ActionOrder,
  },
  response: {
    nodes: ActionInternal.list(),
    totalCount: 'int',
  },
});

export const templates = mkQuery({
  name: 'templates',
  comment:
    'Paginate over the list of templates associated with the organization.',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    orderBy: TemplateOrder,
    filterBy: TemplateFiltersInput.optional(),
  },
  response: {
    nodes: Template.list(),
    totalCount: 'int',
  },
});

export const defaultTemplates = mkQuery({
  name: 'defaultTemplates',
  comment: 'Display the default template configurations',
  params: {},
  response: DefaultTemplates,
});

export const manuallyTestAndRefreshDataSilo = mkMutation({
  name: 'manuallyTestAndRefreshDataSilo',
  comment:
    'Manually call dataSilo.refresh then dataSilo.test() and return response',
  params: {
    id: {
      type: 'id',
      modelName: 'dataSilo',
    },
  },
  response: {
    dataSilo: DataSilo,
  },
});

export const fetchEnrichmentMetadataForDataSilo = mkMutation({
  name: 'fetchEnrichmentMetadataForDataSilo',
  comment: 'Fetch metadata for configuring an enrichment check',
  params: {
    id: {
      type: 'id',
      modelName: 'dataSilo',
    },
    input: FetchEnrichmentMetadataInput,
  },
  response: {
    metadata: EnrichmentMetadata,
  },
});

export const addDataSiloToOpenRequests = mkMutation({
  name: 'addDataSiloToOpenRequests',
  comment: 'Add a data silo to open requests',
  params: {
    input: AddDataSiloToOpenRequestsInput,
  },
  response: {
    updatedRequestCount: 'int',
  },
});

export const createTemplate = mkMutation({
  name: 'createTemplate',
  comment: 'Create a new data subject communication template',
  params: { input: TemplateInput },
  response: { template: Template },
});

export const updateTemplate = mkMutation({
  name: 'updateTemplate',
  comment: 'Update an existing communication template',
  params: { input: UpdateTemplateInput },
  response: { template: Template },
});

export const deleteTemplate = mkMutation({
  name: 'deleteTemplate',
  comment: 'Delete an existing communication template',
  params: { id: { type: 'id', modelName: 'template' } },
  response: { success: 'boolean' },
});

export const initializer = mkQuery({
  name: 'initializer',
  comment: 'Display the current initializer',
  params: {},
  response: Enricher,
});

export const dataSiloCommunications = mkQuery({
  name: 'dataSiloCommunications',
  comment: 'List the emails that have been sent back and forth',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: DataSiloCommunicationFilterInput,
  },
  response: {
    nodes: Communication.list(),
    totalCount: 'int',
  },
});

export const dataSubjectCommunications = mkQuery({
  name: 'dataSubjectCommunications',
  comment: 'List the emails that have been sent back and forth',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    id: {
      comment: 'The ID of the request to get messages for',
      modelName: 'request',
      type: 'id',
    },
  },
  response: {
    nodes: Communication.list(),
    totalCount: 'int',
  },
});

export const toggleCommunicationRead = mkMutation({
  name: 'toggleCommunicationRead',
  comment: 'Make a communication email as read or unread',
  params: { input: ToggleCommunicationReadInput },
  response: { communication: Communication },
});

export const sendCommunication = mkMutation({
  name: 'sendCommunication',
  comment: 'Send an arbitrary message to the data subject',
  params: {
    input: SendMessageInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: SendEmailDhPayload,
    },
  },
  response: { success: 'boolean' },
});

export const lookupProcesses = mkQuery({
  name: 'lookupProcesses',
  comment: 'List lookup processes for a data silo',
  params: {
    input: LookupProcessInput,
    ...PAGINATION_INPUT_PARAMS,
    filterBy: LookupProcessFiltersInput.optional(),
    orderBy: LookupProcessOrder,
  },
  response: {
    nodes: LookupProcess.list(),
    totalCount: 'int',
  },
});

export const updateLookupProcess = mkMutation({
  name: 'updateLookupProcess',
  comment: 'Update a Lookup process',
  params: {
    input: UpdateLookupProcessInput,
  },
  response: {
    lookupProcess: LookupProcess,
    wasUpdated: 'boolean',
  },
});

export const inspectLookupProcesses = mkQuery({
  name: 'inspectLookupProcesses',
  comment: 'Inspect the results of a lookup process for a given identifier',
  params: {
    input: InspectLookupProcessesInput,
  },
  response: {
    nodes: LookupProcessResultCount.list(),
    totalIndexedCount: 'int',
    totalCount: 'int',
  },
});

export const possibleDataSiloDependencies = mkQuery({
  name: 'possibleDataSiloDependencies',
  comment:
    'List the data silos that could be dependencies of a given data silo',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: PossibleDataSiloDependencyFilterInput,
  },
  response: {
    nodes: DataSilo.list(),
    totalCount: 'int',
  },
});

export const identifiers = mkQuery({
  name: 'identifiers',
  comment:
    'Paginate over the list of identifiers configured for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: IdentifierFiltersInput.optional(),
    orderBy: IdentifierOrder,
  },
  response: {
    nodes: Identifier.list(),
    totalCount: 'int',
  },
});

export const saaSCategories = mkQuery({
  name: 'saaSCategories',
  comment:
    'Get a paginated list of saaSCategories and catalogs within those categories.',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: SaaSCategoryFiltersInput.optional(),
    orderBy: SaaSCategoryOrder,
  },
  response: {
    nodes: SaaSCategory.list(),
    totalCatalogs: 'int',
    totalCount: 'int',
  },
});

export const featuredCatalogs = mkQuery({
  name: 'featuredCatalogs',
  comment: 'Get featured Catalogs',
  params: {},
  response: {
    catalogs: Catalog.list(),
    totalCount: 'int',
  },
});

export const saaSCategoryNames = mkQuery({
  name: 'saaSCategoryNames',
  comment: 'Get a paginated list of saaSCategory names.',
  params: {
    filterBy: SaaSCategoryNamesFiltersInput.optional(),
  },
  response: {
    nodes: SaaSCategoryBase.list(),
  },
});

export const vendorCommunicationsMetadata = mkQuery({
  name: 'vendorCommunicationsMetadata',
  comment:
    'Gets a list of vendor communications that were sent for that data silo',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    input: VendorCommunicationsMetadataInput,
  },
  response: {
    nodes: CommunicationMetadata.list(),
    totalCount: 'int',
  },
});

export const createEnricher = mkMutation({
  name: 'createEnricher',
  comment:
    'Create a new enricher that can resolve identifiers from one type to another',
  params: {
    input: EnricherInput,
    dhEncrypted: {
      isOptional: true,
      type: 'string',
      underlyingType: t.type({
        sqlQuery: t.string,
      }),
    },
  },
  response: {
    enricher: Enricher,
  },
});

export const deleteEnricher = mkMutation({
  name: 'deleteEnricher',
  comment: 'Remove an existing enricher',
  params: {
    id: {
      type: 'id',
      modelName: 'enricher',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const updateEnricher = mkMutation({
  name: 'updateEnricher',
  comment: 'Update the configuration of an enricher',
  params: {
    input: UpdateEnricherInput,
    dhEncrypted: {
      isOptional: true,
      type: 'string',
      underlyingType: t.type({
        sqlQuery: t.string,
      }),
    },
  },
  response: {
    enricher: Enricher,
  },
});

export const createIdentifier = mkMutation({
  name: 'createIdentifier',
  comment:
    'Create a new identifier that the organization allows data to be keyed by',
  params: {
    input: IdentifierInput,
  },
  response: {
    identifier: Identifier,
  },
});

export const deleteIdentifiers = mkMutation({
  name: 'deleteIdentifiers',
  comment: 'Remove identifiers',
  params: {
    input: DeleteIdentifiersInput,
  },
  response: {
    count: 'int',
  },
});

export const updateIdentifier = mkMutation({
  name: 'updateIdentifier',
  comment: 'Update an existing identifier',
  params: {
    input: UpdateIdentifierInput,
  },
  response: {
    identifier: Identifier,
  },
});

export const deleteSubject = mkMutation({
  name: 'deleteSubject',
  comment: 'Delete a data subject',
  params: {
    id: {
      type: 'id',
      modelName: 'subject',
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      isOptional: true,
    },
  },
  response: {
    success: 'boolean',
  },
});

export const toggleSubject = mkMutation({
  name: 'toggleSubject',
  comment: 'Whether the subject type is turned on or not',
  params: {
    input: ToggleSubjectInput,
  },
  response: {
    dataSubject: DataSubjectInternal,
  },
});

export const subDataPoints = mkQuery({
  name: 'subDataPoints',
  comment: 'List the subdatapoints for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: SubDataPointFiltersInput.optional(),
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: SubDataPoint.list(),
    totalCount: 'int',
  },
});

export const createSubDataPoint = mkMutation({
  name: 'createSubDataPoint',
  comment: 'Create a single subdatapoint',
  params: {
    input: CreateSubDataPointInput,
  },
  response: {
    subDataPoint: SubDataPoint,
  },
});

export const updateSubDataPoints = mkMutation({
  name: 'updateSubDataPoints',
  comment: 'Bulk update data associated with multiple subdatapoints',
  params: {
    input: UpdateSubDataPointsInput,
  },
  response: { subDataPoints: SubDataPoint.list() },
});

export const refetchSubDataPointSample = mkMutation({
  name: 'refetchSubDataPointSample',
  comment: 'Forces the plugin to refetch samples for a list of subdatapoints',
  params: {
    input: RefetchSubDataPointSampleInput,
  },
  response: { subDataPoints: SubDataPoint.list() },
});

export const restartSiloObjectClassifications = mkMutation({
  name: 'restartSiloObjectClassifications',
  comment:
    'Re-classify the scanned objects of a data silo (that were discovered via Unstructured Discovery)',
  params: {
    input: RestartSiloObjectClassificationsInput,
  },
  response: {
    plugin: Plugin,
  },
});

export const restartSiloClassification = mkMutation({
  name: 'restartSiloClassification',
  comment:
    'Re-classify the subDataPoints of a data silo (that were discovered via Structured Discovery)',
  params: {
    input: RestartSiloClassificationInput,
  },
  response: {
    plugin: Plugin,
  },
});

export const dataCategories = mkQuery({
  name: 'dataCategories',
  comment: 'Get the data categories for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: DataCategoryFiltersInput.optional(),
  },
  response: {
    nodes: DataCategoryInterface.list(),
    totalCount: 'int',
  },
});

export const processingPurposes = mkQuery({
  name: 'processingPurposes',
  comment: 'List the purposes of processing for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ProcessingPurposeFiltersInput.optional(),
  },
  response: {
    totalCount: 'int',
    nodes: ProcessingPurposeInterface.list(),
  },
});

export const dataSubCategories = mkQuery({
  name: 'dataSubCategories',
  comment: 'Get the data categories and data subcategories for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: DataSubCategoryFiltersInput.optional(),
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: DataSubCategory.list(),
    totalCount: 'int',
  },
});

export const createDataSubCategory = mkMutation({
  name: 'createDataSubCategory',
  comment: 'Create a new data subcategory for an organization',
  params: {
    input: CreateDataInventorySubCategoryInput,
  },
  response: {
    dataSubCategory: DataSubCategory,
  },
});

export const deleteDataSubCategories = mkMutation({
  name: 'deleteDataSubCategories',
  comment: 'Delete data subcategories for an organization',
  params: {
    input: DeleteDataSubCategoriesInput,
  },
  response: { success: 'boolean' },
});

export const updateDataSubCategories = mkMutation({
  name: 'updateDataSubCategories',
  comment: 'Bulk update properties associated with multiple data subcategories',
  params: {
    input: UpdateDataSubCategoriesInput,
  },
  response: {
    dataSubCategories: DataSubCategory.list(),
  },
});

export const processingPurposeSubCategories = mkQuery({
  name: 'processingPurposeSubCategories',
  comment: 'List the purpose of processing sub categories for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ProcessingPurposeCategoryFiltersInput.optional(),
    ...DATABASE_PREFERENCE_PARAMS,
  },
  response: {
    nodes: PurposeSubCategory.list(),
    totalCount: 'int',
  },
});

export const createProcessingPurposeSubCategory = mkMutation({
  name: 'createProcessingPurposeSubCategory',
  comment: 'Create a new Purpose of Processing subcategory',
  params: {
    input: CreateProcessingPurposeCategoryInput,
  },
  response: {
    processingPurposeSubCategory: PurposeSubCategory,
  },
});

export const deleteProcessingPurposeSubCategories = mkMutation({
  name: 'deleteProcessingPurposeSubCategories',
  comment: 'Delete processing purpose subcategories for an organization',
  params: {
    input: DeletePurposeSubCategoriesInput,
  },
  response: { success: 'boolean' },
});

export const updateProcessingPurposeSubCategories = mkMutation({
  name: 'updateProcessingPurposeSubCategories',
  comment:
    'Bulk update properties associated with multiple purpose of processing subcategories',
  params: {
    input: UpdateProcessingPurposeSubCategoriesInput,
  },
  response: {
    processingPurposeSubCategories: PurposeSubCategory.list(),
  },
});

export const addSiloDiscoveryResults = mkMutation({
  name: 'addSiloDiscoveryResults',
  comment:
    'Create or update data silo discovery results, to be classified by Transcend',
  params: {
    input: AddSiloDiscoveryResultInput,
  },
  response: { success: 'boolean' },
});

export const siloDiscoveryRecommendations = mkQuery({
  name: 'siloDiscoveryRecommendations',
  comment: 'List the silo discovery recommendations for an organization',
  params: {
    first: PAGINATION_INPUT_PARAMS.first,
    input: SiloDiscoveryRecommendationsInput,
    filterBy: SiloDiscoveryRecommendationFiltersInput.optional(),
  },
  response: {
    nodes: SiloDiscoveryRecommendation.list(),
    lastKey: DataSiloDiscoPluginRecommendationKey.optional(),
    // This field is used for apollo caching
    cacheBy: SiloDiscoveryRecommendationCacheFields,
  },
});

export const siloDiscoveryPendingRecommendationCount = mkQuery({
  name: 'siloDiscoveryPendingRecommendationCount',
  comment: 'Count of the silo discovery recommendations for an organization',
  params: {
    filterBy: SiloDiscoveryPendingConfirmationCountFiltersInput.optional(),
  },
  response: {
    totalCount: 'int',
  },
});

export const markSiloRecommendationsJunk = mkMutation({
  name: 'markSiloRecommendationsJunk',
  comment:
    'Mark data silo recommendations as junk so they do not appear on your Data Inventory table',
  params: {
    input: MarkSiloRecommendationsJunkInput.list(),
  },
  response: {
    success: 'boolean',
  },
});

export const markSiloRecommendationsDuplicate = mkMutation({
  name: 'markSiloRecommendationsDuplicate',
  comment:
    'Mark data silo recommendations as a duplicate of some existing data silo entry',
  params: {
    input: MarkSiloRecommendationsDuplicateInput.list(),
  },
  response: {
    success: 'boolean',
  },
});

export const dataPointHierarchy = mkQuery({
  name: 'dataPointHierarchy',
  comment: 'List the datapoints for a silo at a given level in the hierarchy',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: DataPointHierarchyFiltersInput,
  },
  response: {
    nodes: DataPointHierarchyItem.list(),
    totalCount: 'int',
  },
});

export const contentClassificationDataPoints = mkQuery({
  name: 'contentClassificationDataPoints',
  comment:
    'List datapoints that are part of a data silo with the classification plugin enabled',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: ContentClassificationDataPointsFiltersInput.optional(),
    orderBy: ContentClassificationDataPointsOrder,
  },
  response: {
    // Reuse the resolvers from DataPointHierarchyItem
    nodes: DataPointHierarchyItem.list(),
    totalCount: 'int',
  },
});

export const updateDataPointLevel = mkMutation({
  name: 'updateDataPointLevel',
  comment: 'Update a dataPointLevel',
  params: {
    input: UpdateDataPointLevelInput,
  },
  response: {
    dataPointHierarchy: DataPointHierarchyItem,
  },
});

export const scannedObjectHierarchyPath = mkQuery({
  name: 'scannedObjectHierarchyPath',
  comment:
    'List the path segments at a given level in the scanned object hierarchy',
  params: {
    filterBy: ScannedObjectHierarchyPathFiltersInput.optional(),
  },
  response: {
    pathSegments: ScannedObjectHierarchyItem.list(),
  },
});

export const scannedObjectFileTypes = mkQuery({
  name: 'scannedObjectFileTypes',
  comment: 'Get unique file types for a given data silo',
  params: {
    filterBy: ScannedObjectFileTypesFiltersInput,
  },
  response: {
    response: ScannedObjectFileTypesOutput,
  },
});

export const dataPointHierarchyPath = mkQuery({
  name: 'dataPointHierarchyPath',
  comment: 'List the path segments at a given level in the hierarchy',
  params: {
    filterBy: DataPointHierarchyPathFiltersInput.optional(),
  },
  response: {
    pathSegments: DataPointHierarchyItem.list(),
  },
});

export const unwrapSubDataPointSamples = mkMutation({
  name: 'unwrapSubDataPointSamples',
  comment:
    'Unwraps sample data for the specified sub data points and returns their decryption contexts.',
  params: {
    input: UnwrapSubDataPointSamplesFiltersInput,
    dhEncrypted: 'string',
  },
  response: {
    decryptionContexts: SubDataPointSampleDecryptionContext.list(),
  },
});

export const unwrapFileSnippets = mkMutation({
  name: 'unwrapFileSnippets',
  comment:
    'Unwraps file snippets for the specified file recommendations and returns their decryption contexts.',
  params: {
    input: UnwrapFileSnippetFiltersInput,
    dhEncrypted: 'string',
  },
  response: {
    nameDecryptionContexts: FileSnippetDecryptionContext.list(),
    contextSnippetDecryptionContexts: FileSnippetDecryptionContext.list(),
  },
});

export const createAssessmentGroup = mkMutation({
  name: 'createAssessmentGroup',
  comment: 'Creates a new AssessmentGroup',
  params: {
    input: CreateAssessmentGroupInput,
  },
  response: { assessmentGroup: AssessmentGroup },
});

export const deleteAssessmentGroups = mkMutation({
  name: 'deleteAssessmentGroups',
  comment: 'Delete multiple assessmentGroups from an organization',
  params: {
    input: DeleteAssessmentGroupsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateAssessmentGroup = mkMutation({
  name: 'updateAssessmentGroup',
  comment: 'Updates an AssessmentGroup',
  params: {
    input: UpdateAssessmentGroupInput,
  },
  response: { assessmentGroup: AssessmentGroup },
});

export const assessmentGroups = mkQuery({
  name: 'assessmentGroups',
  comment: 'List the assessmentGroups',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentGroupFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentGroup.list(),
    totalCount: 'int',
  },
});

export const assessmentGroupToken = mkQuery({
  name: 'assessmentGroupToken',
  comment: 'Get the assessment group via a magic link token',
  params: {
    input: TokenInput,
  },
  response: { assessmentGroup: AssessmentGroup },
});

export const assessmentEmailSets = mkQuery({
  name: 'assessmentEmailSets',
  comment: 'List the assessmentEmailSets',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentEmailSetFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentEmailSet.list(),
    totalCount: 'int',
  },
});

export const createAssessmentEmailSet = mkMutation({
  name: 'createAssessmentEmailSet',
  comment: 'Creates a new AssessmentEmailSet',
  params: {
    input: CreateAssessmentEmailSetInput,
  },
  response: { assessmentEmailSet: AssessmentEmailSet },
});

export const deleteAssessmentEmailSets = mkMutation({
  name: 'deleteAssessmentEmailSets',
  comment: 'Delete multiple assessmentEmailSets from an organization',
  params: {
    input: DeleteAssessmentEmailSetsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateAssessmentEmailSet = mkMutation({
  name: 'updateAssessmentEmailSet',
  comment: 'Updates an AssessmentEmailSet',
  params: {
    input: UpdateAssessmentEmailSetInput,
  },
  response: { assessmentEmailSet: AssessmentEmailSet },
});

export const createAssessmentForms = mkMutation({
  name: 'createAssessmentForms',
  comment: 'Bulk create new assessment forms',
  params: {
    input: CreateAssessmentFormsInput,
  },
  response: { assessmentForms: AssessmentFormRaw.list() },
});

export const createAssessmentFormToken = mkMutation({
  name: 'createAssessmentFormToken',
  comment: 'Used by external users to create an assessment form.',
  params: {
    input: CreateAssessmentFormTokenInput,
  },
  response: { assessmentForm: AssessmentFormRaw },
});

export const importOneTrustAssessmentForms = mkMutation({
  name: 'importOneTrustAssessmentForms',
  comment: 'Bulk import Onetrust assessment forms',
  params: {
    input: ImportOnetrustAssessmentsInput,
  },
  response: { assessmentForms: AssessmentFormRaw.list() },
});

export const deleteAssessmentForms = mkMutation({
  name: 'deleteAssessmentForms',
  comment: 'Delete multiple assessmentForms from an organization',
  params: {
    input: DeleteAssessmentFormsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const submitAssessmentFormForReviewToken = mkMutation({
  name: 'submitAssessmentFormForReviewToken',
  comment:
    'Used by external users to submit an assessment form for review with an auth token.',
  params: {
    input: TokenInput,
  },
  response: { assessmentForm: AssessmentFormRaw },
});

export const updateAssessmentForm = mkMutation({
  name: 'updateAssessmentForm',
  comment: 'Updates an AssessmentForm',
  params: {
    input: UpdateAssessmentFormInput,
  },
  response: { assessmentForm: AssessmentFormRaw },
});

export const assessmentFormToken = mkQuery({
  name: 'assessmentFormToken',
  comment:
    'Get the assessment form to be filled out by an external user via a magic link token',
  params: {
    input: TokenInput,
  },
  response: { assessmentForm: AssessmentFormRaw },
});

export const assessmentForms = mkQuery({
  name: 'assessmentForms',
  comment: 'List the assessmentForms',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentFormFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentFormRaw.list(),
    totalCount: 'int',
  },
});

export const assessmentFormComments = mkQuery({
  name: 'assessmentFormComments',
  comment: 'List the assessmentFormComments',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentFormCommentFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentFormComment.list(),
    totalCount: 'int',
  },
});

export const createAssessmentFormComments = mkMutation({
  name: 'createAssessmentFormComments',
  comment: 'Bulk creates new assessmentFormComments',
  params: {
    input: CreateAssessmentFormCommentsInput,
  },
  response: { assessmentFormComments: AssessmentFormComment.list() },
});

export const updateAssessmentFormComments = mkMutation({
  name: 'updateAssessmentFormComments',
  comment: 'Bulk updates assessmentFormComments',
  params: {
    input: UpdateAssessmentFormCommentsInput,
  },
  response: { assessmentFormComments: AssessmentFormComment.list() },
});

export const deleteAssessmentFormComments = mkMutation({
  name: 'deleteAssessmentFormComments',
  comment: 'Delete multiple assessmentFormComments from an organization',
  params: {
    input: DeleteAssessmentFormCommentsInput,
  },
  response: { success: 'boolean' },
});

export const selectAssessmentQuestionAnswersToken = mkMutation({
  name: 'selectAssessmentQuestionAnswersToken',
  comment: 'Select the answers for an assessment question via an auth token',
  params: {
    input: SelectAssessmentQuestionAnswersTokenInput,
  },
  response: {
    selectedAnswers: AssessmentAnswer.list(),
  },
});

export const selectAssessmentQuestionAnswers = mkMutation({
  name: 'selectAssessmentQuestionAnswers',
  comment: 'Select the answers for an assessment question',
  params: {
    input: SelectAssessmentQuestionAnswerInput,
  },
  response: {
    selectedAnswers: AssessmentAnswer.list(),
  },
});

export const deleteAssessmentAnswerFilesToken = mkMutation({
  name: 'deleteAssessmentAnswerFilesToken',
  comment:
    'Delete multiple answer files from an organization via an auth token',
  params: {
    input: DeleteAssessmentAnswerFilesTokenInput,
  },
  response: {
    success: 'boolean',
  },
});

export const deleteAssessmentAnswerFiles = mkMutation({
  name: 'deleteAssessmentAnswerFiles',
  comment: 'Delete multiple answer files from an organization',
  params: {
    input: DeleteAssessmentAnswerFilesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const assessmentQuestions = mkQuery({
  name: 'assessmentQuestions',
  comment: 'List the assessmentQuestions',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentQuestionFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentQuestionRaw.list(),
    totalCount: 'int',
  },
});

export const assessmentQuestionSelectOptions = mkQuery({
  name: 'assessmentQuestionSelectOptions',
  comment:
    'Get the options for assessment select-type questions whose subtype requires dynamic values',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentQuestionSelectOptionsFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentQuestionSelectOptionRaw.list(),
    totalCount: 'int',
  },
});

export const assessmentQuestionSelectOptionsToken = mkQuery({
  name: 'assessmentQuestionSelectOptionsToken',
  comment:
    'Get the options for assessment select-type questions whose subtype requires dynamic values for an external user',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    input: TokenInput,
    filterBy: AssessmentQuestionSelectOptionsTokenFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentQuestionSelectOptionRaw.list(),
    totalCount: 'int',
  },
});

export const createAssessmentQuestions = mkMutation({
  name: 'createAssessmentQuestions',
  comment: 'Bulk creates new AssessmentQuestions',
  params: {
    input: CreateAssessmentQuestionInput.list(),
  },
  response: { assessmentQuestions: AssessmentQuestionRaw.list() },
});

export const updateAssessmentFormQuestion = mkMutation({
  name: 'updateAssessmentFormQuestion',
  comment: 'Updates and AssessmentQuestion within a form',
  params: {
    input: UpdateAssessmentFormQuestionInput,
  },
  response: { assessmentQuestion: AssessmentQuestionRaw },
});

export const updateAssessmentTemplateQuestion = mkMutation({
  name: 'updateAssessmentTemplateQuestion',
  comment: 'Updates and AssessmentQuestion',
  params: {
    input: UpdateAssessmentTemplateQuestionInput,
  },
  response: { assessmentQuestion: AssessmentQuestionRaw },
});

export const deleteAssessmentQuestions = mkMutation({
  name: 'deleteAssessmentQuestions',
  comment: 'Delete multiple assessmentQuestions from an organization',
  params: {
    input: DeleteAssessmentQuestionsInput,
  },
  response: { success: 'boolean' },
});

export const assessmentQuestionComments = mkQuery({
  name: 'assessmentQuestionComments',
  comment: 'List the assessmentQuestionComments',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentQuestionCommentFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentQuestionComment.list(),
    totalCount: 'int',
  },
});

export const createAssessmentQuestionComments = mkMutation({
  name: 'createAssessmentQuestionComments',
  comment: 'Bulk creates new assessmentQuestionComments',
  params: {
    input: CreateAssessmentQuestionCommentsInput,
  },
  response: { assessmentQuestionComments: AssessmentQuestionComment.list() },
});

export const updateAssessmentQuestionComments = mkMutation({
  name: 'updateAssessmentQuestionComments',
  comment: 'Bulk updates assessmentQuestionComments',
  params: {
    input: UpdateAssessmentQuestionCommentInput.list(),
  },
  response: { assessmentQuestionComments: AssessmentQuestionComment.list() },
});

export const deleteAssessmentQuestionComments = mkMutation({
  name: 'deleteAssessmentQuestionComments',
  comment: 'Delete multiple assessmentQuestionComments from an organization',
  params: {
    input: DeleteAssessmentQuestionCommentsInput,
  },
  response: { success: 'boolean' },
});

export const assessmentSectionComments = mkQuery({
  name: 'assessmentSectionComments',
  comment: 'List the assessmentSectionComments',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentSectionCommentFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentSectionComment.list(),
    totalCount: 'int',
  },
});

export const createAssessmentSectionComments = mkMutation({
  name: 'createAssessmentSectionComments',
  comment: 'Bulk creates new assessmentSectionComments',
  params: {
    input: CreateAssessmentSectionCommentsInput,
  },
  response: { assessmentSectionComments: AssessmentSectionComment.list() },
});

export const updateAssessmentSectionComments = mkMutation({
  name: 'updateAssessmentSectionComments',
  comment: 'Bulk updates assessmentSectionComments',
  params: {
    input: UpdateAssessmentSectionCommentInput.list(),
  },
  response: { assessmentSectionComments: AssessmentSectionComment.list() },
});

export const deleteAssessmentSectionComments = mkMutation({
  name: 'deleteAssessmentSectionComments',
  comment: 'Delete multiple assessmentSectionComments from an organization',
  params: {
    input: DeleteAssessmentSectionCommentsInput,
  },
  response: { success: 'boolean' },
});

export const createAssessmentSection = mkMutation({
  name: 'createAssessmentSection',
  comment: 'Creates a new AssessmentSection',
  params: {
    input: CreateAssessmentSectionInput,
  },
  response: { assessmentSection: AssessmentSectionRaw },
});

export const updateAssessmentFormSections = mkMutation({
  name: 'updateAssessmentFormSections',
  comment: 'Updates multiple assessment form sections',
  params: {
    input: updateAssessmentFormSectionsInput,
  },
  response: { assessmentSections: AssessmentSectionRaw.list() },
});
export const updateAssessmentTemplateSection = mkMutation({
  name: 'updateAssessmentTemplateSection',
  comment: 'Updates an assessment template section',
  params: {
    input: UpdateAssessmentTemplateSectionInput,
  },
  response: { assessmentSection: AssessmentSectionRaw },
});

export const deleteAssessmentSections = mkMutation({
  name: 'deleteAssessmentSections',
  comment: 'Delete multiple assessmentSections from an organization',
  params: {
    input: DeleteAssessmentSectionsInput,
  },
  response: { success: 'boolean' },
});

export const assessmentFiles = mkQuery({
  name: 'assessmentFiles',
  comment: 'Get a list of assessment files',
  params: {
    filterBy: AssessmentFileFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentFile.list(),
    totalCount: 'int',
  },
});

export const dataSiloRateLimits = mkQuery({
  name: 'dataSiloRateLimits',
  comment: 'List the rate limits in effect for a data silo',
  params: {
    id: {
      type: 'id',
      modelName: 'dataSilo',
      comment: 'Data silo to fetch rate limits for',
    },
  },
  response: {
    dataSiloRateLimits: RateLimitConfig.list(),
  },
});

export const updateDataSiloRateLimits = mkMutation({
  name: 'updateDataSiloRateLimits',
  comment: 'Update the rate limit values for a data silo',
  params: {
    input: UpdateDataSiloRateLimitsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const resetDataSiloRateLimitsToDefault = mkMutation({
  name: 'resetDataSiloRateLimitsToDefault',
  comment: 'Reset the rate limit values for a data silo',
  params: {
    id: {
      type: 'id',
      modelName: 'dataSilo',
      description: 'Data silo to reset rate limits to default for.',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const createDataReport = mkMutation({
  name: 'createDataReport',
  comment: 'Creates a new data report',
  params: {
    input: CreateDataReportInput,
  },
  response: {
    dataReport: DataReportRaw,
  },
});

export const updateDataReports = mkMutation({
  name: 'updateDataReports',
  comment: 'Bulk update properties associated with multiple data reports',
  params: {
    input: UpdateDataReportsInput,
  },
  response: {
    dataReports: DataReportRaw.list(),
  },
});

export const dataReports = mkQuery({
  name: 'dataReports',
  comment: 'List the data reports for an organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: DataReportFiltersInput.optional(),
    orderBy: DataReportRawOrder,
  },
  response: {
    nodes: DataReportRaw.list(),
    totalCount: 'int',
  },
});

export const deleteDataReports = mkMutation({
  name: 'deleteDataReports',
  comment: 'Delete multiple data reports for an organization',
  params: {
    input: DeleteDataReportsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const latestScan = mkQuery({
  name: 'latestScan',
  comment: 'Fetch the scan information for a plugin',
  params: {
    id: {
      type: 'id',
      modelName: 'plugin',
    },
  },
  response: {
    scan: Scan.optional(),
  },
});

export const latestSchemaDiscoveryScanProgress = mkQuery({
  name: 'latestSchemaDiscoveryScanProgress',
  comment:
    'Fetch the scan progress information for the latest schema discovery scan',
  params: {
    id: {
      type: 'id',
      modelName: 'plugin',
    },
  },
  response: {
    scanProgress: SchemaDiscoveryScanResult.optional(),
  },
});

export const unstructuredSubDataPointRecommendations = mkQuery({
  name: 'unstructuredSubDataPointRecommendations',
  comment:
    'Fetch all the subdatapoint recommendations from our Unstructured Data Discovery scans',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: UnstructuredSubDataPointRecommendationsFilterInput.optional(),
    orderBy: UnstructuredSubDataPointRecommendationOrder,
  },
  response: {
    nodes: UnstructuredSubDataPointRecommendation.list(),
    totalCount: 'int',
  },
});

export const updateUnstructuredSubDataPointRecommendations = mkMutation({
  name: 'updateUnstructuredSubDataPointRecommendations',
  comment: 'Update unstructured subdatapoints recommendations',
  params: {
    input: UpdateUnstructuredSubDataPointRecommendationsInput,
  },
  response: {
    unstructuredSubDataPointRecommendations:
      UnstructuredSubDataPointRecommendation.list(),
  },
});

export const unstructuredDataDiscoveryFiles = mkQuery({
  name: 'unstructuredDataDiscoveryFiles',
  comment: 'Files discovered during Unstructured Data Discovery plugin runs',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: UnstructuredDataDiscoveryFilesFilterInput,
  },
  response: {
    nodes: UnstructuredDataDiscoveryFile.list(),
    totalCount: 'int',
  },
});

export const scannedObjectHierarchy = mkQuery({
  name: 'scannedObjectHierarchy',
  comment:
    'List the scannedObjects and scannedObjectPaths for a silo at a given level in the hierarchy',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ScannedObjectHierarchyFiltersInput,
  },
  response: {
    nodes: ScannedObjectHierarchyItem.list(),
    totalCount: 'int',
  },
});

export const createAssessmentFormTemplate = mkMutation({
  name: 'createAssessmentFormTemplate',
  comment: 'Creates a new Assessment Form Template',
  params: {
    input: CreateAssessmentFormTemplateInput,
  },
  response: { assessmentFormTemplate: AssessmentFormTemplateRaw },
});

export const updateAssessmentFormTemplate = mkMutation({
  name: 'updateAssessmentFormTemplate',
  comment: 'Updates an Assessment Form Template',
  params: {
    input: UpdateAssessmentFormTemplateInput,
  },
  response: { assessmentFormTemplate: AssessmentFormTemplateRaw },
});

export const deleteAssessmentFormTemplates = mkMutation({
  name: 'deleteAssessmentFormTemplates',
  comment: 'Delete multiple assessmentFormTemplates from an organization',
  params: {
    input: DeleteAssessmentFormTemplatesInput,
  },
  response: {
    success: 'boolean',
  },
});

export const assessmentFormTemplates = mkQuery({
  name: 'assessmentFormTemplates',
  comment: 'List the assessmentFormTemplates',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AssessmentFormTemplateFiltersInput.optional(),
  },
  response: {
    nodes: AssessmentFormTemplateRaw.list(),
    totalCount: 'int',
  },
});

export const subDataPointErrorRollup = mkQuery({
  name: 'subDataPointErrorRollup',
  comment:
    'List out rolled-up errors encountered in scanning or classifying a property (aka subdatapoint)',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: SubDataPointErrorRollupFilterInput,
  },
  response: {
    nodes: SubDataPointErrorRollup.list(),
    totalCount: 'int',
  },
});

export const pluginScans = mkQuery({
  name: 'pluginScans',
  comment: 'List out past scan runs for a given plugin',
  params: {
    first: PAGINATION_INPUT_PARAMS.first,
    input: PluginScansInput,
  },
  response: {
    nodes: Scan.list(),
    lastKey: ScanKey.optional(),
  },
});

export const retentionSchedules = mkQuery({
  name: 'retentionSchedules',
  comment: 'Get retention schedules for the organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: RetentionScheduleFiltersInput.optional(),
    useMaster: DATABASE_PREFERENCE_PARAMS.useMaster,
  },
  response: {
    nodes: RetentionSchedule.list(),
    totalCount: 'int',
  },
});

/**
 * @deprecated - use `retentionSchedules` instead
 */
export const dataRetentionSchedulePolicy = mkQuery({
  name: 'dataRetentionSchedulePolicy',
  comment: 'Get the data retention schedule policy for the organization',
  params: {},
  response: {
    retentionSchedule: RetentionSchedule,
  },
});

export const updateDataRetentionSchedulePolicy = mkMutation({
  name: 'updateDataRetentionSchedulePolicy',
  comment: 'Update an organizations data retention policy',
  params: {
    input: UpdateRetentionScheduleInput,
  },
  response: {
    retentionSchedule: RetentionSchedule,
  },
});

export const riskFrameworks = mkQuery({
  name: 'riskFrameworks',
  comment: 'List the risk frameworks',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: RiskFrameworksFiltersInput.optional(),
  },
  response: {
    nodes: RiskFramework.list(),
    totalCount: 'int',
  },
});

export const createRiskFrameworks = mkMutation({
  name: 'createRiskFrameworks',
  comment: 'Create new risk frameworks',
  params: {
    input: CreateRiskFrameworksInput,
  },
  response: {
    riskFrameworks: RiskFramework.list(),
  },
});

export const updateRiskFrameworks = mkMutation({
  name: 'updateRiskFrameworks',
  comment: 'Update the risk frameworks',
  params: {
    input: UpdateRiskFrameworksInput,
  },
  response: {
    riskFrameworks: RiskFramework.list(),
  },
});

export const deleteRiskFrameworks = mkMutation({
  name: 'deleteRiskFrameworks',
  comment: 'Remove risk frameworks',
  params: {
    input: DeleteRiskFrameworksInput,
  },
  response: {
    count: 'int',
  },
});

export const riskCategories = mkQuery({
  name: 'riskCategories',
  comment: 'List the risk categories',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: RiskCategoriesFiltersInput.optional(),
  },
  response: {
    nodes: RiskCategory.list(),
    totalCount: 'int',
  },
});

export const createRiskCategories = mkMutation({
  name: 'createRiskCategories',
  comment: 'Create new risk categories',
  params: {
    input: CreateRiskCategoriesInput,
  },
  response: {
    riskCategories: RiskCategory.list(),
  },
});

export const updateRiskCategories = mkMutation({
  name: 'updateRiskCategories',
  comment: 'Update the risk categories',
  params: {
    input: UpdateRiskCategoriesInput,
  },
  response: {
    riskCategories: RiskCategory.list(),
  },
});

export const deleteRiskCategories = mkMutation({
  name: 'deleteRiskCategories',
  comment: 'Remove risk categories',
  params: {
    input: DeleteRiskCategoriesInput,
  },
  response: {
    count: 'int',
  },
});

export const riskLevels = mkQuery({
  name: 'riskLevels',
  comment: 'List the risk levels',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: RiskLevelsFiltersInput.optional(),
  },
  response: {
    nodes: RiskLevel.list(),
    totalCount: 'int',
  },
});

/* eslint-enable max-lines */

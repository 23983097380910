import React from 'react';

import { SvgProps } from '../enums';

export const FlagGW: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55146"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55146)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0H16V6H8V0Z"
        fill="#FBCD17"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6H16V12H8V6Z"
        fill="#0B9E7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H8V12H0V0Z"
        fill="#E11C1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.46533 7.30314L2.72251 8.51199L3.27941 6.44152L2 5.1189L3.73239 5.04739L4.46533 3L5.19827 5.04739H6.92771L5.65125 6.44152L6.29052 8.38979L4.46533 7.30314Z"
        fill="#1D1D1D"
      />
    </g>
  </svg>
);

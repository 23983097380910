import {
  AttributeKeyType,
  AttributeSupportedResourceType,
} from '@transcend-io/privacy-types';

/** number of AttributeKeys to fetch without overwhelming client */
export const MAX_ATTRIBUTE_KEYS = 50;

/** maximum number of AttributeKeys allowed per Organization */
export const MAX_ATTRIBUTE_KEYS_PER_ORG = 200;

/**
 * Default attribute key names for easier reference in the codebase
 */
export enum DefaultAttributeKeyName {
  SecurityMeasures = 'Security Measures',
  Tags = 'Tags',
  LegalBasisForProcessing = 'Legal Basis for Processing',
  TransferMechanism = 'Transfer Mechanism',
  SuperCategory = 'Super Category',
  SourceOfData = 'Source of Data',
}

/** Default attribute keys */
export const DEFAULT_ATTRIBUTE_KEYS = [
  {
    name: DefaultAttributeKeyName.SecurityMeasures,
    description:
      'The technical measurements taken by the organization to protect and secure the processing of personal information.',
    enabledOn: [
      AttributeSupportedResourceType.DataSilo,
      AttributeSupportedResourceType.ProcessingActivity,
    ],
    isCustom: false,
    type: AttributeKeyType.MultiSelect,
    defaultAttributeValues: [
      {
        name: 'Encrypted storage',
        canDelete: true,
        color: 'transcendNavy4',
      },
      {
        name: 'Encrypted transfer',
        canDelete: true,
        color: 'transcendNavy4',
      },
      {
        name: 'Access controls',
        canDelete: true,
        color: 'transcendNavy4',
      },
    ],
  },
  {
    name: DefaultAttributeKeyName.Tags,
    description:
      'Default tags that can be associated with different Transcend resources',
    enabledOn: Object.values(AttributeSupportedResourceType).filter(
      (type) => type !== AttributeSupportedResourceType.Request,
    ),
    isCustom: false,
    type: AttributeKeyType.MultiSelect,
    defaultAttributeValues: [],
  },
  {
    name: DefaultAttributeKeyName.LegalBasisForProcessing,
    description: "The legal basis for processing a subject's data",
    enabledOn: [
      AttributeSupportedResourceType.ProcessingActivity,
      AttributeSupportedResourceType.ProcessingPurposeSubCategory,
    ],
    isCustom: false,
    type: AttributeKeyType.SingleSelect,
    defaultAttributeValues: [
      {
        name: 'Performance of contract',
        canDelete: true,
        color: 'purple1',
      },
      {
        name: 'Legitimate interest',
        canDelete: true,
        color: 'mint1',
      },
      {
        name: 'Consent of the individual',
        canDelete: true,
        color: 'blue1',
      },
      {
        name: 'Legal obligation',
        canDelete: true,
        color: 'pink1',
      },
    ],
  },
  {
    name: DefaultAttributeKeyName.TransferMechanism,
    description:
      'These are the possible methods for transferring data to a third country or organization acceptable under GDPR',
    enabledOn: [
      AttributeSupportedResourceType.Vendor,
      AttributeSupportedResourceType.DataSilo,
      AttributeSupportedResourceType.ProcessingActivity,
    ],
    isCustom: false,
    type: AttributeKeyType.SingleSelect,
    defaultAttributeValues: [
      {
        name: 'Not applicable',
        canDelete: true,
        color: 'transcendNavy4',
      },
      {
        name: 'Adequacy',
        canDelete: true,
        color: 'transcendNavy4',
      },
      {
        name: 'Standard contractual clauses',
        canDelete: true,
        color: 'transcendNavy4',
      },
      {
        name: 'Privacy shield',
        canDelete: true,
        color: 'transcendNavy4',
      },
      {
        name: 'Processing occurs within EEA',
        canDelete: true,
        color: 'transcendNavy4',
      },
      {
        name: 'Binding corporate rules',
        canDelete: true,
        color: 'transcendNavy4',
      },
    ],
  },
  {
    name: DefaultAttributeKeyName.SuperCategory,
    description:
      'Overarching designation that covers various categories of personal data',
    enabledOn: [
      AttributeSupportedResourceType.DataSubCategory,
      AttributeSupportedResourceType.ProcessingActivity,
    ],
    isCustom: false,
    type: AttributeKeyType.MultiSelect,
    defaultAttributeValues: [
      {
        name: 'Sensitive Data (GDPR)',
        canDelete: true,
        color: 'blue1',
      },
      {
        name: 'Sensitive Data (CCPA)',
        canDelete: true,
        color: 'yellow1',
      },
    ],
  },
  {
    name: DefaultAttributeKeyName.SourceOfData,
    description: 'The origin of the personal data being processed',
    enabledOn: [
      AttributeSupportedResourceType.DataSilo,
      AttributeSupportedResourceType.ProcessingActivity,
    ],
    isCustom: false,
    type: AttributeKeyType.MultiSelect,
    defaultAttributeValues: [
      { name: 'Direct from Data Subjects', canDelete: true, color: 'blue1' },
      { name: 'Online Tracking Technologies', canDelete: true, color: 'red1' },
      { name: 'Third-Party Data Providers', canDelete: true, color: 'lime1' },
      {
        name: 'Internal Business Processes',
        canDelete: true,
        color: 'transcendNavy2',
      },
      { name: 'Partners and Affiliates', canDelete: true, color: 'purple1' },
      {
        name: 'Automated Systems or Sensors',
        canDelete: true,
        color: 'orange1',
      },
      {
        name: 'Customer Feedback and Surveys',
        canDelete: true,
        color: 'blue3',
      },
      { name: 'Device and Network Data', canDelete: true, color: 'mint1' },
      { name: 'Social Media Integrations', canDelete: true, color: 'pink1' },
      { name: 'User-Generated Content', canDelete: true, color: 'yellow2' },
    ],
  },
];

import { mkType, SchemaToType } from '@main/schema-utils';

export const SaaSCategoryBase = mkType({
  name: 'SaaSCategoryBase',
  comment:
    'A classification of integration. All integrations of this class are shown together',
  fields: {
    id: {
      comment: 'The SaaSCategory id, from the database (not contentfulId)',
      type: 'id',
      modelName: 'saaSCategory',
    },
    title: {
      comment: 'The integration category title',
      type: 'string',
    },
  },
});

/** Override type */
export type SaaSCategoryBase = SchemaToType<typeof SaaSCategoryBase>;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ArrowRightBackSquare: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.08209 7.49895L9.58313 10L7.08209 12.501"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9179 12.501L12.9179 7.49895"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M17.5031 6.66527L17.5031 13.3347C17.5031 15.6369 15.6368 17.5031 13.3346 17.5031L6.6652 17.5031C4.36306 17.5031 2.4968 15.6369 2.4968 13.3347L2.4968 6.66527C2.4968 4.36312 4.36306 2.49686 6.6652 2.49686L13.3346 2.49686C15.6368 2.49686 17.5031 4.36312 17.5031 6.66527Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

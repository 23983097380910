import qs from 'query-string';
import { Location, Params } from 'react-router-dom';

import { ObjByString } from '@transcend-io/type-utils';

import { filterObjectInverse } from '@main/utils';

/**
 * Convert an href to location object
 *
 * @param href - The href to add parameter values to i.e. `/test` `/test?branch=test`
 * @returns Location object
 */
export function hrefToLocation(href: string): Location {
  // Parse string and redirect locally
  const { pathname, search, hash } = new URL(href, window.location.origin);
  return {
    key: href,
    pathname,
    search,
    state: '',
    hash,
  };
}

/**
 * Convert a location object to a href
 *
 * @param location - The location to convert
 * @returns Href string
 */
export function locationToHref({
  pathname = '',
  search = '',
  hash = '',
}: Partial<Location>): string {
  return pathname + search + hash;
}

/**
 * Add new query parameters to the existing set, optionally only preserving a subset of the old parameters.
 *
 * @param location - The location to grab the search fields from
 * @param newParams - The new parameters to add
 * @returns The new page location with updated search parameters
 */
export function addQueryParams(
  location: Location,
  newParams: ObjByString,
): Location {
  // Grab the current query params
  const allParams = getQueryParams(location.search);

  // Set the modal to open
  return {
    ...location,
    search: `?${qs.stringify({ ...allParams, ...newParams })}`,
  };
}

/**
 * Get the current query params
 *
 * @param search - The query params to parse
 * @returns The query params in an object
 */
export function getQueryParams(search: string): ObjByString {
  return qs.parse((search || '').replace(/^\?/, ''));
}

/**
 * Remove a query parameter by name
 *
 * @param location - The location to grab the search fields from
 * @param paramNames - The names of params to remove
 * @returns The new location with updated search parameters
 */
export function removeQueryParams(
  location: Location,
  paramNames: string[],
): Location {
  // Grab the current query params
  const allParams = getQueryParams(location.search);

  // New params
  const newParams = filterObjectInverse(allParams, paramNames);

  // Handle case when no more params
  if (Object.keys(newParams).length === 0) {
    return { ...location, search: '' };
  }

  // Set the modal to open
  return { ...location, search: `?${qs.stringify(newParams)}` };
}

/**
 * Function converts path like /user/123 to /user/:id
 *
 * @param location - Current location
 * @param params - Current params
 * @returns Route path
 */
export const getRoutePath = (location: Location, params: Params): string => {
  const { pathname } = location;

  if (Object.keys(params).length === 0) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const SidebarAssessments: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M21.0033 9.99884V5.99717C21.0033 4.89214 20.1075 3.99634 19.0025 3.99634H5.99705C4.89202 3.99634 3.99622 4.89214 3.99622 5.99717V20.003C3.99622 21.108 4.89202 22.0038 5.99705 22.0038H10.9991"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99792 7.99801H17.0017"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99792 11.9997H13"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99792 16.0014H10.9992"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0037 22.0039L20.364 20.3643"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M17.7259 14.0005C19.7835 14.0005 21.4515 15.6685 21.4515 17.726C21.4515 19.7836 19.7835 21.4516 17.7259 21.4516C15.6684 21.4516 14.0004 19.7836 14.0004 17.726C14.0005 15.6685 15.6684 14.0006 17.7259 14.0005"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SubDataPointDataSubCategoryGuessStatus } from '@transcend-io/privacy-types';

import { mkType, type SchemaToType } from '@main/schema-utils';
import { ClassificationMethod } from '@main/sombra-types';

import { DataSubCategory } from './dataSubCategory';

export const SubDataPointDataSubCategoryGuess = mkType({
  name: 'SubDataPointDataSubCategoryGuess',
  comment: 'A subcategory guess for a subdatapoint',
  fields: {
    id: {
      comment: 'The ID of the subcategory guess',
      type: 'id',
      modelName: 'SubDataPointDataSubCategoryGuess',
    },
    confidence: {
      comment: 'The confidence value for this guess',
      type: 'float',
    },
    status: {
      comment: 'The status of this guess',
      type: { SubDataPointDataSubCategoryGuessStatus },
    },
    category: {
      comment: 'The guessed subcategory',
      type: DataSubCategory,
    },
    classifierVersion: {
      comment:
        'The model version of the LLM or ML classifier that generated this guess',
      type: 'int',
      optional: true,
    },
    classificationMethod: {
      comment: 'The classification method used to produce this guess',
      type: { ClassificationMethod },
      optional: true,
    },
  },
});

/** Override type */
export type SubDataPointDataSubCategoryGuess = SchemaToType<
  typeof SubDataPointDataSubCategoryGuess
>;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const EditFile: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M8.66665 14H11.6666C12.5871 14 13.3333 13.2538 13.3333 12.3333V5.58111C13.3333 5.05068 13.1226 4.54197 12.7475 4.1669L11.1664 2.58579C10.7913 2.21071 10.2826 2 9.7522 2H4.99998C4.07951 2 3.33331 2.74619 3.33331 3.66667V8.66667"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3176 5.33333H11C10.4477 5.33333 10 4.88562 10 4.33333V2.01575"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.52859 11.9842L5.88652 9.62627C6.07406 9.43873 6.32841 9.33337 6.59363 9.33337C6.85885 9.33337 7.1132 9.43873 7.30074 9.62627L7.70709 10.0326C8.09761 10.4232 8.09761 11.0563 7.70709 11.4468L5.34913 13.8048C5.22411 13.9298 5.05453 14 4.87772 14H3.66665C3.48255 14 3.33331 13.8508 3.33331 13.6667V12.4556C3.33331 12.2788 3.40355 12.1092 3.52859 11.9842Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

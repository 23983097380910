import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const MenubarArrowDown: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49896 7.49902L10 10.0001L12.501 7.49902"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4.99805V10.0001"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4.99792"
      y="12.501"
      width="10.0042"
      height="2.50104"
      rx="1"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2.49689"
      y="2.49683"
      width="15.0062"
      height="15.0062"
      rx="3"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

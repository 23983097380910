import React, { CSSProperties, useMemo } from 'react';

import {
  AnimatedAvatarSkeleton,
  AnimatedSkeletonBar,
  AvatarContainer,
  SkeletonContainer,
  SkeletonContent,
  SkeletonParagraph,
} from './wrappers';

export interface SkeletonProps {
  /** display a loading animation */
  active?: boolean;
  /** add a user icon placeholder to the skeleton */
  avatar?: boolean;
  /** show/hide a top bar with some spacing below */
  title?: boolean;
  /** add number of expected paragraph rows, or show/hide all rows */
  paragraph?:
    | boolean
    | {
        /** number of rows to display */
        rows: number;
      };
}

/**
 * reusable skeleton bar component
 */
function SkeletonBar(props: {
  /** width of bar */
  style: CSSProperties;
  /** animation active */
  active: boolean;
}): JSX.Element {
  return <AnimatedSkeletonBar active={props.active} style={props.style} />;
}

/**
 * title bar
 */
function TitleSkeletonBar(props: {
  /** animation active */
  active: boolean;
  /** has paragraph underneath */
  hasParagraph: boolean;
}): JSX.Element {
  return (
    <AnimatedSkeletonBar
      active={props.active}
      style={{ width: props.hasParagraph ? '38%' : '100%', marginTop: '16px' }}
    />
  );
}

/**
 * the avatar placeholder
 */
function AvatarSkeleton(props: {
  /** animation active */
  active: boolean;
}): JSX.Element {
  return <AnimatedAvatarSkeleton active={props.active} />;
}

/**
 * the new skeleton component to replace ants
 */
export function Skeleton({
  title = true,
  paragraph,
  ...props
}: SkeletonProps): JSX.Element {
  const numberOfRows = useMemo((): number => {
    if (typeof paragraph === 'object' && 'rows' in paragraph) {
      return paragraph.rows;
    }

    if (!props.avatar && title) {
      return 3;
    }

    return 2;
  }, [paragraph, props.avatar, title]);

  return (
    <SkeletonContainer>
      {props.avatar && (
        <AvatarContainer>
          <AvatarSkeleton active={!!props.active} />
        </AvatarContainer>
      )}
      <SkeletonContent>
        {title && (
          <TitleSkeletonBar
            active={!!props.active}
            hasParagraph={paragraph !== false}
          />
        )}
        {paragraph !== false && (
          <SkeletonParagraph hasTitle={title}>
            {new Array(numberOfRows).fill(null).map((_, index) => (
              <li key={index}>
                <SkeletonBar
                  style={{
                    width: index === numberOfRows - 1 ? '61%' : '100%',
                    marginTop: index !== 0 ? '16px' : '0',
                  }}
                  active={!!props.active}
                />
              </li>
            ))}
          </SkeletonParagraph>
        )}
      </SkeletonContent>
    </SkeletonContainer>
  );
}

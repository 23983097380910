import React from 'react';

import { SvgProps } from '../enums';

export const FlagAQ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54950"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54950)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#5196ED"
      />
      <mask
        id="mask1_270_54950"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54950)">
        <g filter="url(#filter0_d_270_54950)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.3587 3.94688C4.3587 3.94688 5.39076 4.51312 5.5343 4.66974C5.67777 4.82639 5.90775 5.40162 6.26639 5.07632C6.6251 4.75113 6.98374 5.00395 6.98374 4.48585C6.98374 3.96779 7.51926 2.76602 8.29631 3.06717C9.07343 3.36829 9.71235 3.17991 9.8916 3.3003C10.071 3.42077 10.5014 4.01901 10.8361 4.01901C11.171 4.01901 11.3382 4.36835 11.3622 4.87438C11.386 5.38045 11.2546 5.42878 11.5654 5.48911C11.8763 5.54929 11.9958 5.77831 11.8405 6.07943C11.6851 6.38066 11.6969 6.24811 11.7208 6.56138C11.7448 6.87465 11.4101 8.722 10.3817 8.90292C9.35344 9.08353 8.37879 8.98724 8.64194 8.63768C8.90495 8.28838 9.26904 7.88633 8.73114 7.802C8.19299 7.71756 7.85392 7.64577 7.31583 7.79031C6.77774 7.93489 6.21585 8.16372 5.88094 7.73009C5.54622 7.29632 5.61806 6.98305 5.34304 6.77821C5.0681 6.57342 4.73319 6.60953 5.00832 6.21193C5.28315 5.81434 5.51031 5.9628 5.28315 5.64957C5.05617 5.33638 4.1987 5.19991 4.1987 4.88676C4.1987 4.57337 3.6533 3.88662 4.3587 3.94688Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.3587 3.94688C4.3587 3.94688 5.39076 4.51312 5.5343 4.66974C5.67777 4.82639 5.90775 5.40162 6.26639 5.07632C6.6251 4.75113 6.98374 5.00395 6.98374 4.48585C6.98374 3.96779 7.51926 2.76602 8.29631 3.06717C9.07343 3.36829 9.71235 3.17991 9.8916 3.3003C10.071 3.42077 10.5014 4.01901 10.8361 4.01901C11.171 4.01901 11.3382 4.36835 11.3622 4.87438C11.386 5.38045 11.2546 5.42878 11.5654 5.48911C11.8763 5.54929 11.9958 5.77831 11.8405 6.07943C11.6851 6.38066 11.6969 6.24811 11.7208 6.56138C11.7448 6.87465 11.4101 8.722 10.3817 8.90292C9.35344 9.08353 8.37879 8.98724 8.64194 8.63768C8.90495 8.28838 9.26904 7.88633 8.73114 7.802C8.19299 7.71756 7.85392 7.64577 7.31583 7.79031C6.77774 7.93489 6.21585 8.16372 5.88094 7.73009C5.54622 7.29632 5.61806 6.98305 5.34304 6.77821C5.0681 6.57342 4.73319 6.60953 5.00832 6.21193C5.28315 5.81434 5.51031 5.9628 5.28315 5.64957C5.05617 5.33638 4.1987 5.19991 4.1987 4.88676C4.1987 4.57337 3.6533 3.88662 4.3587 3.94688Z"
            fill="#F5F8FB"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_270_54950"
        x="3.69756"
        y="2.71941"
        width="8.51077"
        height="6.57272"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0941176 0 0 0 0 0.32549 0 0 0 0 0.639216 0 0 0 0.43 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_270_54950"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_270_54950"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

import { isEqualTo } from './isEqualTo';

export const isNotEqualTo = ({
  questionToCheck,
  comparisonOperands,
}: Parameters<typeof isEqualTo>[0]): boolean =>
  !isEqualTo({
    questionToCheck,
    comparisonOperands,
  });

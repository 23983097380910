import React from 'react';

import { SvgProps } from '../enums';

export const FlagSI: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55112"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55112)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55112"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55112)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#C51918"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6.87012 2.51812C6.87012 2.51812 5.81775 2.02115 4.50885 2.02115C3.19995 2.02115 2.14758 2.51812 2.14758 2.51812C2.14758 2.51812 2.2647 4.51547 2.4498 5.57358C2.52883 6.02535 2.9087 6.68427 3.2526 6.99615C3.64458 7.35162 4.50885 7.77277 4.50885 7.77277C4.75896 7.60043 5.22659 7.52293 5.82431 6.99615C6.42202 6.46937 6.57059 5.57358 6.57059 5.57358L6.87012 2.51812Z"
          fill="#2E42A5"
        />
        <path
          // eslint-disable-next-line max-len
          d="M6.73102 2.45282L7.16928 2.67168L6.86842 5.68299C6.76305 6.32033 6.49939 6.82292 6.07775 7.19452C5.84902 7.3961 5.62576 7.54672 5.38639 7.67186C5.25128 7.74251 4.68611 8.03364 4.69103 8.03126C4.6344 8.05856 4.59146 8.08246 4.55658 8.10649L4.50192 8.14416L4.4429 8.1154C3.85752 7.83016 3.31137 7.44629 3.03996 7.20016C2.66947 6.86417 2.27883 6.17664 2.19555 5.70057C2.07102 4.98874 1.95935 4.00829 1.88098 2.67168L2.27468 2.46571C2.35246 3.79225 2.44635 4.84971 2.56863 5.54868C2.64097 5.96223 3.0012 6.59622 3.32982 6.89424C3.57268 7.11448 3.96395 7.3587 4.50192 7.6243C4.53401 7.6055 4.56957 7.58689 4.61026 7.56728C4.6114 7.56673 4.97736 7.40421 5.10454 7.33772C5.32761 7.22109 5.53496 7.08121 5.74844 6.89306C6.12273 6.5632 6.35633 6.11896 6.45081 5.55461L6.73102 2.45282Z"
          fill="#C51918"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M2.77106 5.51209C2.77106 5.51209 3.02892 6.301 3.48052 6.74134C3.93211 7.18167 4.49038 7.3975 4.49038 7.3975C4.49038 7.3975 5.15532 7.19629 5.5327 6.77267C5.91009 6.34904 6.29644 5.51209 6.29644 5.51209L5.41879 4.6698L5.06996 5.04833L4.49038 4.22205L4.00515 5.04833L3.63308 4.6698L2.77106 5.51209Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.57342 2.94308H3.22186L3.49453 2.68764L3.22186 2.41771H3.57342L3.72186 2.1557L3.8709 2.41771H4.22186L3.96283 2.67143L4.19284 2.94308H3.8709L3.72186 3.21958L3.57342 2.94308Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.23028 2.94308H4.87872L5.15139 2.68764L4.87872 2.41771H5.23028L5.37872 2.1557L5.52776 2.41771H5.87872L5.61969 2.67143L5.8497 2.94308H5.52776L5.37872 3.21958L5.23028 2.94308Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.29999 3.78375H3.94843L4.2211 3.52831L3.94843 3.25838H4.29999L4.44843 2.99637L4.59746 3.25838H4.94843L4.68939 3.5121L4.9194 3.78375H4.59746L4.44843 4.06025L4.29999 3.78375Z"
          fill="#FECA00"
        />
      </g>
    </g>
  </svg>
);

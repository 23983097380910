import React from 'react';

import { SvgProps } from '../enums';

export const FlagMW: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55068"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55068)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#E11C1B"
      />
      <mask
        id="mask1_270_55068"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55068)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V5H16V0H0Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#5EAA22"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M2.00077 6.97294V6.97173L1.99609 6.97253L2.00077 6.97294ZM2.315 5.0634H2.36649L2.28757 5.05132L2.315 5.0634ZM3.77876 5.0634C3.80494 5.01308 3.83227 4.96331 3.86073 4.9141L2.716 4.15635L4.06619 4.59556C4.1417 4.48997 4.22266 4.38759 4.30874 4.28872L3.31653 3.34406L4.58095 4.00387C4.6737 3.9153 4.77092 3.83029 4.8723 3.74909L4.06985 2.64057L5.20312 3.50752C5.30855 3.43744 5.41751 3.37114 5.52975 3.30885L4.95176 2.06848L5.90908 3.11933C6.02317 3.06832 6.13999 3.02123 6.25931 2.97827L5.93389 1.64618L6.67516 2.84816C6.7945 2.81626 6.91588 2.78837 7.03907 2.76465L6.9847 1.38725L7.47777 2.69895C7.59951 2.68583 7.72271 2.67673 7.84717 2.67181L8.07041 1.3L8.29371 2.67221C8.41802 2.67735 8.54106 2.68666 8.66266 2.69998L9.15611 1.38725L9.10166 2.76654C9.22441 2.79041 9.34535 2.81843 9.46425 2.85043L10.2069 1.64618L9.88071 2.98154C9.99932 3.0245 10.1155 3.07155 10.2289 3.12247L11.1891 2.06848L10.609 3.31321C10.7204 3.37527 10.8285 3.44128 10.9331 3.51102L12.071 2.64057L11.2649 3.75409C11.3653 3.8348 11.4616 3.91925 11.5535 4.0072L12.8243 3.34406L11.8267 4.29385C11.9118 4.39195 11.9919 4.49348 12.0666 4.59816L13.4248 4.15635L12.273 4.91882C12.3004 4.96649 12.3269 5.01469 12.3522 5.0634H11.3697C10.6927 4.1249 9.45523 3.49659 8.04086 3.49659C6.62649 3.49659 5.38903 4.1249 4.71198 5.0634H3.77876ZM5.27978 5.0634C5.92123 4.40181 6.92842 3.97553 8.06095 3.97553C9.19348 3.97553 10.2007 4.40181 10.8421 5.0634H5.27978ZM13.7743 5.0634H13.8258L13.8532 5.05132L13.7743 5.0634ZM14.1408 6.97185L14.1447 6.97253L14.1408 6.97288V6.97185Z"
          fill="#E11C1B"
        />
      </g>
    </g>
  </svg>
);

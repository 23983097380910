import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DsrTypeRestriction: React.FC<SvgProps> = ({
  color,
  color2,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M31.6667 11.5667H20.8817C20.33 11.5667 19.8133 11.2934 19.5033 10.8367L17.1617 7.39508C16.8517 6.94008 16.3367 6.66675 15.785 6.66675H8.33333C6.49167 6.66675 5 8.15841 5 10.0001V30.0001C5 31.8417 6.49167 33.3334 8.33333 33.3334H31.6667C33.5083 33.3334 35 31.8417 35 30.0001V14.9001C35 13.0601 33.5083 11.5667 31.6667 11.5667Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M20 16.6667V16.6667C16.7783 16.6667 14.1667 19.2784 14.1667 22.5001V22.5001C14.1667 25.7217 16.7783 28.3334 20 28.3334V28.3334C23.2217 28.3334 25.8333 25.7217 25.8333 22.5001V22.5001C25.8333 19.2784 23.2217 16.6667 20 16.6667Z"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1333 18.3667L15.8667 26.6334"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

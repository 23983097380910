import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { DataSubject } from '@main/datamap-types';

/** the active data subject state */
export interface ActiveDataSubjectState {
  /** The currently selected data subject */
  activeDataSubject?: DataSubject;
  /** The list of all data subjects */
  subjects: DataSubject[];
}

/**
 * Reducer slice the `ActiveDataSubject` state store
 */
export const activeDataSubjectSlice = createSlice({
  name: 'ActiveDataSubject',
  initialState: {
    subjects: [],
  } as ActiveDataSubjectState,
  reducers: {
    unsetActiveDataSubject: (state) => ({
      activeDataSubject: undefined,
      ...state,
    }),
    setActiveDataSubject: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** the active data subject selected in the UI */
        activeDataSubject: DataSubject;
        /** an array of possible data subjects */
        subjects?: DataSubject[];
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setActiveDataSubject, unsetActiveDataSubject } =
  activeDataSubjectSlice.actions;

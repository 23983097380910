import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const MenuBlockCheckmark: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.5"
      y="6.66675"
      width="15"
      height="6.66667"
      rx="2"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 17.4999C2.5 16.5794 3.24619 15.8333 4.16667 15.8333H15.8333C16.7538 15.8333 17.5 16.5794 17.5 17.4999"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 2.5C17.5 3.42047 16.7538 4.16667 15.8333 4.16667H4.16667C3.24619 4.16667 2.5 3.42047 2.5 2.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 9.99992H14.1667"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.69684 10.003L6.24998 10.5L7.36351 9.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagSO: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55129"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55129)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#56C6F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.9893 7.35869L6.10733 8.55525L6.73857 6.46058L5.39623 5.09203L7.24689 5.05161L8.06566 2.98368L8.81177 5.07892L10.658 5.11115L9.2706 6.50478L9.91847 8.49749L7.9893 7.35869Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

import React from 'react';
import toast, {
  Renderable,
  Toast,
  Toaster,
  ToasterProps,
  ToastOptions,
  ValueOrFunction,
} from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';

import { toastMessages } from './messages';

export const message = (
  message?: ValueOrFunction<Renderable, Toast>,
  options?: ToastOptions,
): void => {
  toast(message ?? <FormattedMessage {...toastMessages.fallback} />, options);
};

message.error = (
  message?: ValueOrFunction<Renderable, Toast>,
  options?: ToastOptions,
): void => {
  toast.error(
    message ?? <FormattedMessage {...toastMessages.fallbackUnknownError} />,
    { duration: 8000, ...options },
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc, func-names
message.promise = function <T>(
  promise: Promise<T>,
  messages?: {
    /** loading message */
    loading: Renderable;
    /** success message */
    success: ValueOrFunction<Renderable, T>;
    /** error message */
    error: ValueOrFunction<Renderable, any>;
  },
  options?: ToastOptions,
) {
  toast.promise(
    promise,
    {
      loading: messages?.loading ?? (
        <FormattedMessage {...toastMessages.fallbackLoading} />
      ),
      success: messages?.success ?? (
        <FormattedMessage {...toastMessages.fallbackSuccess} />
      ),
      error: messages?.error ?? (
        <FormattedMessage {...toastMessages.fallbackUnknownError} />
      ),
    },
    options,
  );
};

message.success = (
  message?: ValueOrFunction<Renderable, Toast>,
  options?: ToastOptions,
): void => {
  toast.success(
    message ?? <FormattedMessage {...toastMessages.fallbackSuccess} />,
    { duration: 5000, ...options },
  );
};

export const ToasterWithStyles = ({
  toastOptions,
  ...props
}: ToasterProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Toaster
      {...props}
      toastOptions={{
        ...toastOptions,
        style: {
          fontFamily: theme.textStyles.fontFamilyBody.name,
          color: theme.colors.transcendNavy2,
          ...(toastOptions?.style ?? {}),
        },
        success: {
          ...(toastOptions?.success ?? {}),
          iconTheme: {
            primary: theme.colors.mint1,
            secondary: theme.colors.white,
            ...(toastOptions?.success?.iconTheme ?? {}),
          },
        },
        error: {
          ...(toastOptions?.error ?? {}),
          iconTheme: {
            primary: theme.colors.red1,
            secondary: theme.colors.white,
            ...(toastOptions?.error?.iconTheme ?? {}),
          },
        },
      }}
    />
  );
};

export { ToasterWithStyles as Toaster };

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const InsertTableRowBelow: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="6.00003"
      width="3"
      height="12"
      rx="0.5"
      transform="rotate(-90 2 6.00003)"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 2.99759L6 5.99759"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 2.99759L10 5.99759"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="5.33252"
      y="13"
      width="5.33556"
      height="5.33556"
      rx="1.5"
      transform="rotate(-90 5.33252 13)"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.99756 7.66453L1.99756 9.66536C1.99756 10.0337 2.29616 10.3323 2.6645 10.3323L5.33228 10.3323"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.668 10.3323L13.3357 10.3323C13.7041 10.3323 14.0027 10.0337 14.0027 9.66536L14.0027 7.66453"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99998 9.49856L7.99998 11.1659"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.1665 10.3323L8.83387 10.3323"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

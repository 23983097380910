import React from 'react';

import { SvgProps } from '../enums';

export const FlagEH: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55088"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55088)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4H16V0H0Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V12H16V8H0Z"
        fill="#5EAA22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L8 6L0 12V0Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.8439 7.73855C10.8439 7.73855 9.70654 7.05117 9.70654 5.91526C9.70654 4.77936 10.8439 4.18178 10.8439 4.18178C10.3348 3.85877 8.56955 4.31977 8.56955 5.95227C8.56955 7.58477 10.3183 7.88639 10.8439 7.73855ZM11.7642 5.65627L11.1022 5.03605L11.3308 5.82787L10.6903 6.1313L11.4771 6.38739L11.8187 7.24451L11.9681 6.45109L12.7453 6.6227L12.1573 6.04848L12.3562 5.43583L11.7642 5.65627Z"
        fill="#E31D1C"
      />
    </g>
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DsrTypeBusinessPurpose: React.FC<SvgProps> = ({
  color,
  color2,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M33.3389 20.0001V13.0439C33.3389 12.1596 32.9872 11.3115 32.3618 10.6863L27.6465 5.97097C27.0213 5.34555 26.1732 4.9939 25.2889 4.9939H9.99582C8.15411 4.9939 6.6611 6.4869 6.6611 8.32862V31.6717C6.6611 33.5134 8.15411 35.0064 9.99582 35.0064H13.3305"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="18.3326"
      y="28.7537"
      width="5.00208"
      height="6.2526"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="23.3347"
      y="21.6675"
      width="5.00208"
      height="13.3389"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="28.3368"
      y="25.4189"
      width="5.00208"
      height="9.58732"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3306 13.3305H25.0021"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3306 19.1664H17.499"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

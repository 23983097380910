import { mkType, SchemaToType } from '@main/schema-utils';

export const AgentPreview = mkType({
  name: 'AgentPreview',
  comment: 'A preview of an agent',
  fields: {
    id: {
      comment: 'The id of the agent',
      modelName: 'agent',
      type: 'id',
    },
    name: {
      comment: 'The name of the agent',
      type: 'string',
    },
    agentId: {
      comment: 'The ID of the agent in the remote system',
      type: 'string',
    },
  },
});

/** Override type */
export type AgentPreview = SchemaToType<typeof AgentPreview>;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagAR: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54951"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54951)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_54951"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54951)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#58A5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#58A5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.27631 7.24358C8.27631 7.24358 7.76536 8.30294 7.31629 8.52013C7.51013 8.03425 7.58147 7.04288 7.58147 7.04288C7.58147 7.04288 6.41552 7.50515 6.00628 7.4209C6.49837 7.07703 7.18141 6.44012 7.18141 6.44012C7.18141 6.44012 5.6723 5.94675 5.70932 5.73678C6.39056 5.85898 7.32742 5.72577 7.32742 5.72577C7.32742 5.72577 6.26872 4.45615 6.37609 4.36173C6.53746 4.51859 7.80624 5.18135 7.80624 5.18135C7.80624 5.18135 7.89829 4.05175 8.17134 3.6709C8.20409 3.93974 8.58734 5.15692 8.58734 5.15692C8.58734 5.15692 9.35778 4.38603 9.7854 4.38603C9.59757 4.61892 9.15283 5.64189 9.15283 5.64189C9.15283 5.64189 10.2612 5.62444 10.6826 5.8319C10.1722 5.9045 9.27787 6.35346 9.27787 6.35346C9.27787 6.35346 10.4426 7.23697 10.3364 7.4209C9.71106 7.11499 8.98809 7.01254 8.98809 7.01254C8.98809 7.01254 9.18792 8.24207 9.02639 8.52013C8.86885 8.11082 8.27631 7.24358 8.27631 7.24358Z"
          fill="#FFD018"
          stroke="#F19900"
          strokeOpacity="0.98"
          strokeWidth="0.25"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.20935 6.6709C8.48549 6.6709 8.70935 6.44704 8.70935 6.1709C8.70935 5.89476 8.48549 5.6709 8.20935 5.6709C7.93321 5.6709 7.70935 5.89476 7.70935 6.1709C7.70935 6.44704 7.93321 6.6709 8.20935 6.6709Z"
          fill="#FFD018"
          stroke="#F19900"
          strokeOpacity="0.98"
          strokeWidth="0.25"
        />
      </g>
    </g>
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ArrowCurvedDownLeft: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9.34938 3.31738L10.604 3.31738V3.31738C12.4807 3.31738 14.016 4.81738 14.016 6.65072V6.65072C14.016 8.48405 12.4807 9.98405 10.604 9.98405L3.31738 9.98405"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.98405 7.31738L3.31738 9.98405L5.98405 12.6507"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { makeEnum } from '@transcend-io/type-utils';

/** Product area in the AD */
export const ProductArea = makeEnum({
  Administration: 'ADMINISTRATION',
  DataMapping: 'DATA_MAPPING',
  Consent: 'CONSENT',
  DsrAutomation: 'DSR_AUTOMATION',
  PrivacyCenter: 'PRIVACY_CENTER',
  Assessments: 'ASSESSMENTS',
});

/** Override type */
export type ProductArea = (typeof ProductArea)[keyof typeof ProductArea];

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const BuildingHomeHouseRealEstate: React.FC<SvgProps> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00002 7.5H8.33335C8.79359 7.5 9.16669 7.8731 9.16669 8.33333V16.6667H4.16669V8.33333C4.16669 7.8731 4.53978 7.5 5.00002 7.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.33325H15C15.4603 3.33325 15.8334 3.70635 15.8334 4.16659V16.6666H9.16669V4.16659C9.16669 3.70635 9.53978 3.33325 10 3.33325Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 16.6667H2.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

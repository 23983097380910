import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LanguageKey } from '@transcend-io/internationalization';

/**
 *localized router state
 */
export interface LocalizedRouterState {
  /** The translations for the current language */
  translations: { [k in string]: string };
  /** The language that is currently cached */
  locale?: LanguageKey;
}

export const localizedRouterSlice = createSlice({
  name: 'LocalizedRouter',
  initialState: {
    translations: {},
  } as LocalizedRouterState,
  reducers: {
    setTranslations: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** The translations */
        translations: { [k in string]: string };
        /** The language being translated */
        locale: LanguageKey;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setTranslations } = localizedRouterSlice.actions;

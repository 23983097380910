import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { IntlMessageType } from './enums';

export const MessageTranslation = mkType({
  name: 'MessageTranslation',
  comment: 'A translation of an international message',
  fields: {
    locale: {
      comment: 'The locale for this translation',
      type: 'string',
    },
    value: {
      comment: 'The translated message',
      type: 'string',
    },
  },
});

/** Override type */
export type MessageTranslation = SchemaToType<typeof MessageTranslation>;

export const MessageTranslationInput = mkInput({
  name: 'MessageTranslationInput',
  comment: 'Define a translation of an international message',
  fields: {
    locale: {
      comment: 'The locale for this translation',
      type: 'string',
    },
    value: {
      comment: 'The translated message',
      type: 'string',
    },
  },
});

/** Override type */
export type MessageTranslationInput = SchemaToType<
  typeof MessageTranslationInput
>;

export const MessageInput = mkInput({
  name: 'MessageInput',
  comment: 'Define an international message',
  fields: {
    id: {
      comment:
        'The ID of the existing message being updated. ' +
        'This is normally required unless a valid "targetReactIntlId" is passed instead',
      modelName: 'message',
      optional: true,
      type: 'id',
    },
    defaultMessage: {
      comment: 'The default message when no translation exists',
      type: 'string',
    },
    translations: {
      comment: 'The translations for this message',
      list: true,
      type: MessageTranslationInput,
    },
    targetReactIntlId: {
      comment: 'The ID of the react-intl message that the message relates to',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type MessageInput = SchemaToType<typeof MessageInput>;

export const TranslatedMessagesInput = mkInput({
  name: 'TranslatedMessagesInput',
  comment: 'Input for fetching message translations',
  fields: {
    locale: {
      comment: 'The locale to grab translations for',
      type: 'string',
    },
  },
});

/** Override type */
export type TranslatedMessagesInput = SchemaToType<
  typeof TranslatedMessagesInput
>;

export const Message = mkType({
  name: 'Message',
  comment: 'An international message',
  fields: {
    id: {
      comment: 'The unique id of the message',
      modelName: 'message',
      type: 'id',
    },
    isTitle: {
      comment:
        'When true, the message is used as a title and thus should not contain rich text',
      type: 'boolean',
    },
    defaultValue: {
      comment:
        'The default value of the message when associated with some value on the Privacy Center',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'A description for the message',
      type: 'string',
    },
    defaultMessage: MessageInput.fields.defaultMessage,
    targetReactIntlId: MessageInput.fields.targetReactIntlId,
    translations: {
      comment: 'The translations for this message',
      list: true,
      type: MessageTranslation,
    },
    type: {
      comment: 'The type of this message',
      type: { IntlMessageType },
      optional: true,
    },
  },
});

/** Override type */
export type Message = SchemaToType<typeof Message>;

export const LocalizedMessage = mkType({
  name: 'LocalizedMessage',
  comment: 'An localized international message',
  fields: {
    id: {
      comment: 'The unique id of the message',
      type: 'string',
    },
    defaultMessage: Message.fields.defaultMessage,
    localizedMessage: {
      comment:
        'The message localized to the current locale of the person making the request',
      type: 'string',
      optional: true, // TODO: https://transcend.height.app/T-4553 - migrating off of this
    },
  },
});

/** Override type */
export type LocalizedMessage = SchemaToType<typeof LocalizedMessage>;

/**
 * Return results from definedMessages
 */
export const DefinedMessage = mkType({
  name: 'DefinedMessage',
  comment: 'The message definition',
  fields: {
    id: {
      comment: 'A unique identifier for the message',
      type: 'string',
    },
    defaultMessage: LocalizedMessage.fields.defaultMessage,
    description: {
      ...Message.fields.description,
      optional: true,
    },
  },
});

/** Override type */
export type DefinedMessage = SchemaToType<typeof DefinedMessage>;

// TODO: https://github.com/transcend-io/main/issues/1297 - ideally we don't need this
// type. We should use `Message` on the admin dashboard when translating, and then
// LocalizedMessage on the privacy center. For now, keeping this type allow for
// our existing workflow
export const MessageWithLocalized = mkType({
  name: 'MessageWithLocalized',
  comment: 'An international message that is also localized',
  fields: {
    ...LocalizedMessage.fields,
    translations: Message.fields.translations,
  },
});

/** Override type */
export type MessageWithLocalized = SchemaToType<typeof MessageWithLocalized>;

export const MenuTabInterface = mkInterface({
  name: 'MenuTabInterface',
  comment: 'A menu item on the side menu of the privacy center',
  fields: {
    id: {
      comment: 'The unique tab identifier',
      modelName: 'any',
      type: 'id',
    },
    title: {
      comment: 'The display title of the menu tab',
      type: LocalizedMessage,
    },
    visualId: {
      comment: 'The visual id of the tab, used as the hashbang',
      type: 'string',
    },
    logo: {
      comment: 'The tab logo or icon',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type MenuTabInterface = SchemaToType<typeof MenuTabInterface>;

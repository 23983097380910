import { StaticTeamType } from '@transcend-io/privacy-types';

import { mkType, SchemaToType } from '@main/schema-utils';

export const TeamPreview = mkType({
  name: 'TeamPreview',
  comment: 'A preview of a team, used during joins',
  fields: {
    id: {
      comment: 'The unique ID of the team',
      type: 'id',
      modelName: 'team',
    },
    name: {
      comment: 'The name of the team',
      type: 'string',
    },
    description: {
      comment: 'The internal description of the team',
      type: 'string',
    },
    staticType: {
      comment: 'The static type of the team',
      type: { StaticTeamType },
      optional: true,
    },
    organizationName: {
      comment: 'The organization name the team relates to',
      type: 'string',
    },
  },
});

/** Override type */
export type TeamPreview = SchemaToType<typeof TeamPreview>;

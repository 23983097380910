import React from 'react';
import type { GroupTypeBase, OptionTypeBase } from 'react-select';
import { components, IndicatorProps } from 'react-select';

import { Icon } from '../Icon';

/**
 * Our own drop-down icon
 */
export function DropdownIndicator<
  T extends OptionTypeBase,
  IsMulti extends boolean = false,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
>(props: IndicatorProps<T, IsMulti, G>): JSX.Element {
  return (
    <components.DropdownIndicator {...props}>
      <Icon type="caret-down" />
    </components.DropdownIndicator>
  );
}

/* eslint-disable max-lines */
import React from 'react';

import { SvgProps } from '../enums';

export const FlagIR: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55090"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55090)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55090"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55090)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#5EAA22"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#E31D1C"
        />
        <path
          d="M0.186342 8.68347H-0.950684"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M-0.962402 9.13364V8.87264H-0.592269V9.12488H-0.218255V8.87292H-0.412299"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M0.169059 9.12305H-0.0194092V8.87814H0.377406V8.67751V9.12305H0.556974V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M0.759024 8.704C0.759024 8.72863 0.759024 9.12492 0.759024 9.12492H0.572632"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M2.13751 8.68347H1.00049" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M0.98877 9.13364V8.87264H1.3589V9.12488H1.73292V8.87292H1.53887"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M2.15514 9.12305H1.96667V8.87814H2.36349V8.67751V9.12305H2.54306V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M2.74511 8.704C2.74511 8.72863 2.74511 9.12492 2.74511 9.12492H2.55872"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M4.1236 8.68347H2.98657" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M2.97485 9.13364V8.87264H3.34499V9.12488H3.719V8.87292H3.52496"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M4.10644 9.12305H3.91797V8.87814H4.31478V8.67751V9.12305H4.49435V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M4.6964 8.704C4.6964 8.72863 4.6964 9.12492 4.6964 9.12492H4.51001"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M6.07489 8.68347H4.93787" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M4.92615 9.13364V8.87264H5.29628V9.12488H5.67029V8.87292H5.47625"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M6.05761 9.12305H5.86914V8.87814H6.26596V8.67751V9.12305H6.44552V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M6.64757 8.704C6.64757 8.72863 6.64757 9.12492 6.64757 9.12492H6.46118"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M8.02606 8.68347H6.88904" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M6.87732 9.13364V8.87264H7.24745V9.12488H7.62147V8.87292H7.42742"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M8.04369 9.12305H7.85522V8.87814H8.25204V8.67751V9.12305H8.43161V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M8.63366 8.704C8.63366 8.72863 8.63366 9.12492 8.63366 9.12492H8.44727"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M10.0121 8.68347H8.87512" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M8.8634 9.13364V8.87264H9.23354V9.12488H9.60755V8.87292H9.41351"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M10.0298 9.12305H9.84131V8.87814H10.2381V8.67751V9.12305H10.4177V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M10.6197 8.704C10.6197 8.72863 10.6197 9.12492 10.6197 9.12492H10.4333"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M11.9982 8.68347H10.8612" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M10.8495 9.13364V8.87264H11.2196V9.12488H11.5936V8.87292H11.3996"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M12.0157 9.12305H11.8273V8.87814H12.2241V8.67751V9.12305H12.4037V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M12.6057 8.704C12.6057 8.72863 12.6057 9.12492 12.6057 9.12492H12.4193"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M13.9842 8.68347H12.8472" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M12.8354 9.13364V8.87264H13.2056V9.12488H13.5796V8.87292H13.3856"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M13.967 9.12305H13.7786V8.87814H14.1754V8.67751V9.12305H14.3549V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M14.557 8.704C14.557 8.72863 14.557 9.12492 14.557 9.12492H14.3706"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M15.9355 8.68347H14.7985" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M14.7867 9.13364V8.87264H15.1569V9.12488H15.5309V8.87292H15.3368"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M16.0228 9.12305H15.8344V8.87814H16.2312V8.67751V9.12305H16.4107V8.67751"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M0.186342 2.58346H-0.950684"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M-0.962402 3.03364V2.77264H-0.592269V3.02488H-0.218255V2.77292H-0.412299"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M0.169059 3.02304H-0.0194092V2.77813H0.377406V2.5775V3.02304H0.556974V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M0.759024 2.604C0.759024 2.62862 0.759024 3.02492 0.759024 3.02492H0.572632"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M2.13751 2.58346H1.00049" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M0.98877 3.03364V2.77264H1.3589V3.02488H1.73292V2.77292H1.53887"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M2.15514 3.02304H1.96667V2.77813H2.36349V2.5775V3.02304H2.54306V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M2.74511 2.604C2.74511 2.62862 2.74511 3.02492 2.74511 3.02492H2.55872"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M4.1236 2.58346H2.98657" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M2.97485 3.03364V2.77264H3.34499V3.02488H3.719V2.77292H3.52496"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M4.10644 3.02304H3.91797V2.77813H4.31478V2.5775V3.02304H4.49435V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M4.6964 2.604C4.6964 2.62862 4.6964 3.02492 4.6964 3.02492H4.51001"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M6.07489 2.58346H4.93787" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M4.92615 3.03364V2.77264H5.29628V3.02488H5.67029V2.77292H5.47625"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M6.05761 3.02304H5.86914V2.77813H6.26596V2.5775V3.02304H6.44552V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M6.64757 2.604C6.64757 2.62862 6.64757 3.02492 6.64757 3.02492H6.46118"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M8.02606 2.58346H6.88904" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M6.87732 3.03364V2.77264H7.24745V3.02488H7.62147V2.77292H7.42742"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M8.04369 3.02304H7.85522V2.77813H8.25204V2.5775V3.02304H8.43161V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M8.63366 2.604C8.63366 2.62862 8.63366 3.02492 8.63366 3.02492H8.44727"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M10.0121 2.58346H8.87512" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M8.8634 3.03364V2.77264H9.23354V3.02488H9.60755V2.77292H9.41351"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M10.0298 3.02304H9.84131V2.77813H10.2381V2.5775V3.02304H10.4177V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M10.6197 2.604C10.6197 2.62862 10.6197 3.02492 10.6197 3.02492H10.4333"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M11.9982 2.58346H10.8612" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M10.8495 3.03364V2.77264H11.2196V3.02488H11.5936V2.77292H11.3996"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M12.0157 3.02304H11.8273V2.77813H12.2241V2.5775V3.02304H12.4037V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M12.6057 2.604C12.6057 2.62862 12.6057 3.02492 12.6057 3.02492H12.4193"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M13.9842 2.58346H12.8472" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M12.8354 3.03364V2.77264H13.2056V3.02488H13.5796V2.77292H13.3856"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M13.967 3.02304H13.7786V2.77813H14.1754V2.5775V3.02304H14.3549V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M14.557 2.604C14.557 2.62862 14.557 3.02492 14.557 3.02492H14.3706"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path d="M15.9355 2.58346H14.7985" stroke="#F7FCFF" strokeWidth="0.5" />
        <path
          d="M14.7867 3.03364V2.77264H15.1569V3.02488H15.5309V2.77292H15.3368"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          d="M16.0228 3.02304H15.8344V2.77813H16.2312V2.5775V3.02304H16.4107V2.5775"
          stroke="#F7FCFF"
          strokeWidth="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.30309 4.125C7.30309 4.125 7.51676 4.2375 7.73545 4.2375C7.95414 4.2375 8.17417 4.125 8.17417 4.125V4.275C8.17417 4.275 7.93396 4.3875 7.72121 4.3875C7.50846 4.3875 7.30309 4.275 7.30309 4.275V4.125ZM5.72815 5.8953C5.72815 5.0063 6.94914 4.425 6.94914 4.425C6.94914 4.425 6.24343 4.8031 6.24343 5.85804C6.24343 6.91299 6.60978 7.36983 6.60978 7.36983C6.60978 7.36983 5.72815 6.7843 5.72815 5.8953ZM9.84699 5.84624C9.84699 4.95724 8.62714 4.425 8.62714 4.425C8.62714 4.425 9.33285 4.8031 9.33285 5.85804C9.33285 6.91299 8.9665 7.36983 8.9665 7.36983C8.9665 7.36983 9.84699 6.73524 9.84699 5.84624ZM6.5617 6.08879C6.51518 5.20101 7.25371 4.40526 7.25371 4.40526C7.25371 4.40526 6.98491 4.77092 7.04012 5.82442C7.05907 6.18614 7.20155 6.72431 7.38106 7.04159V4.4625H8.13106V7.06396C8.3108 6.76758 8.4493 6.26228 8.46853 5.8953C8.52374 4.8418 8.26783 4.40526 8.26783 4.40526C8.26783 4.40526 9.00636 5.20101 8.95984 6.08879C8.93213 6.61753 8.52528 7.17671 8.20073 7.54253C8.35843 7.5777 8.56981 7.62057 8.86038 7.67793C8.86658 7.71008 8.80183 7.70859 8.67317 7.70563C8.53112 7.70236 8.31116 7.6973 8.02277 7.73375C7.89018 7.86964 7.7979 7.95019 7.7979 7.95019C7.7979 7.95019 7.78331 7.93048 7.76077 7.90321C7.73823 7.93048 7.72364 7.95019 7.72364 7.95019C7.72364 7.95019 7.63136 7.86964 7.49877 7.73375C7.21038 7.6973 6.99042 7.70236 6.84837 7.70563C6.71971 7.70859 6.65496 7.71008 6.66116 7.67793C6.95173 7.62057 7.16311 7.5777 7.32081 7.54253C6.99626 7.17671 6.58941 6.61753 6.5617 6.08879Z"
          fill="#E31D1C"
        />
      </g>
    </g>
  </svg>
);
/* eslint-enable max-lines */

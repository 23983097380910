import { mkType, SchemaToType } from '@main/schema-utils';

/** Defined separately to avoid circular dependency between attributeKeys and assessmentFormTemplates */
export const AssessmentFormTemplateAttributePreview = mkType({
  name: 'AssessmentFormTemplateAttributePreview',
  comment:
    'Subset of fields for an editable template from which assessment forms can be created',
  fields: {
    id: {
      comment: 'The id of the assessment form template',
      type: 'id',
      modelName: 'assessmentFormTemplate',
    },
    title: {
      comment: 'The title of the assessment form template',
      type: 'string',
    },
  },
});

/** Override type */
export type AssessmentFormTemplateAttributePreview = SchemaToType<
  typeof AssessmentFormTemplateAttributePreview
>;

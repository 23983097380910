/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Apple: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5922 9.1667C10.9362 9.1667 9.91416 8.4027 8.85216 8.4027C7.44016 8.4027 6.14816 9.2657 5.42016 10.5317C3.95616 13.0737 5.04316 16.8297 6.47116 18.8957C7.17216 19.9017 7.99816 21.0337 9.09416 20.9977C10.1452 20.9527 10.5402 20.3147 11.8162 20.3147C13.0832 20.3147 13.4422 20.9977 14.5562 20.9707C15.6882 20.9527 16.4072 19.9467 17.0982 18.9317C17.8982 17.7637 18.2302 16.6317 18.2482 16.5687C18.2212 16.5597 16.0472 15.7247 16.0202 13.2087C16.0022 11.1067 17.7362 10.1007 17.8172 10.0557C16.8292 8.6097 15.3112 8.4477 14.7812 8.4117C13.3972 8.3037 12.2392 9.1667 11.5922 9.1667V9.1667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.65 2.95396C14.519 2.63044 15.0874 3.10744 14.9196 4.01938C14.7519 4.93131 13.9114 5.93285 13.0424 6.25637C12.1735 6.57989 11.605 6.10289 11.7728 5.19095C11.9406 4.27902 12.7811 3.27748 13.65 2.95396"
      stroke={color}
      strokeWidth="1.49996"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

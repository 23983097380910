/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const FileFolderStack: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9.27232V8C3.99985 7.46971 4.21037 6.96108 4.58523 6.58601C4.9601 6.21094 5.46861 6.00014 5.9989 6H18C18.5303 5.99985 19.0389 6.21037 19.414 6.58523C19.7891 6.9601 19.9999 7.46861 20 7.9989V12.2707"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 6.271V5C4.99985 4.46971 5.21037 3.96108 5.58523 3.58601C5.9601 3.21094 6.46861 3.00014 6.9989 3H17C17.5303 2.99985 18.0389 3.21037 18.414 3.58523C18.7891 3.9601 18.9999 4.46861 19 4.9989V6.271"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 19V14C21 12.8954 20.1046 12 19 12H12.5352C12.2008 12 11.8886 11.8329 11.7031 11.5547L10.2969 9.4453C10.1114 9.1671 9.79918 9 9.46483 9H5C3.89543 9 3 9.89543 3 11V19C2.99985 19.5305 3.21052 20.0393 3.58563 20.4144C3.96073 20.7895 4.46952 21.0001 5 21H19C19.5305 21.0001 20.0393 20.7895 20.4144 20.4144C20.7895 20.0393 21.0001 19.5305 21 19Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

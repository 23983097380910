import {
  RetentionScheduleOperation,
  RetentionScheduleType,
} from '@transcend-io/privacy-types';

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const RetentionSchedule = mkType({
  name: 'RetentionSchedule',
  comment: 'Configurable retention policy to manage an organizations data',
  fields: {
    id: {
      comment: 'The unique ID of the RetentionSchedule',
      modelName: 'retentionSchedule',
      type: 'id',
    },
    organizationId: {
      comment:
        'The ID of the organization that the retention schedule belongs to',
      modelName: 'organization',
      type: 'id',
    },
    type: {
      comment: 'The type of the retention schedule',
      type: { RetentionScheduleType },
    },
    durationDays: {
      comment: 'The duration of the retention schedule in days',
      type: 'int',
    },
    operation: {
      comment: 'The operation to perform on the retention schedule',
      type: { RetentionScheduleOperation },
    },
    createdAt: {
      comment: 'The time the retention schedule was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The time the retention schedule was last updated',
      type: 'Date',
    },
  },
});

/** Override type */
export type RetentionSchedule = SchemaToType<typeof RetentionSchedule>;

export const CreateRetentionScheduleInput = mkInput({
  name: 'CreateRetentionScheduleInput',
  comment: 'Create a retention schedule policy',
  fields: {
    durationDays: {
      comment: 'The duration of the retention schedule in days',
      type: RetentionSchedule.fields.durationDays.type,
    },
    operation: {
      comment: 'The operation to perform on the retention schedule',
      type: RetentionSchedule.fields.operation.type,
    },
    type: {
      comment: 'The type of the retention schedule',
      type: RetentionSchedule.fields.type.type,
    },
  },
});

/** Override type */
export type CreateRetentionScheduleInput = SchemaToType<
  typeof CreateRetentionScheduleInput
>;

export const UpdateRetentionScheduleInput = mkInput({
  name: 'UpdateRetentionScheduleInput',
  comment: 'Update a retention schedule policy',
  fields: {
    id: {
      comment: 'The id of the retention schedule to update',
      type: 'id',
      modelName: 'retentionSchedule',
    },
    durationDays: {
      ...CreateRetentionScheduleInput.fields.durationDays,
      optional: true,
    },
    operation: {
      ...CreateRetentionScheduleInput.fields.operation,
      optional: true,
    },
    type: { ...CreateRetentionScheduleInput.fields.type, optional: true },
  },
});

/** Override type */
export type UpdateRetentionScheduleInput = SchemaToType<
  typeof UpdateRetentionScheduleInput
>;

export const RetentionScheduleFiltersInput = mkInput({
  name: 'RetentionScheduleFiltersInput',
  comment: 'Filters for querying retention schedules',
  fields: {
    ids: {
      comment: 'Filter by the ids of the retention schedules',
      type: 'id',
      modelName: 'retentionSchedule',
      optional: true,
      list: true,
    },
    operations: {
      comment: 'Filter by the operation types of the retention schedule',
      type: RetentionSchedule.fields.operation.type,
      optional: true,
      list: true,
    },
    types: {
      comment: 'Filter by the types of the retention schedules',
      type: RetentionSchedule.fields.type.type,
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type RetentionScheduleFiltersInput = SchemaToType<
  typeof RetentionScheduleFiltersInput
>;

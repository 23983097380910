import { makeEnum } from '@transcend-io/type-utils';

import { TableId } from '@main/access-control-types';
/**
 * The types of tables that can be exported as Data Reports
 */
export const DataReportExportableTables = makeEnum({
  /** DataSilos table */
  DataSilos: TableId.DataInventoryDataSilos,
  /** SubDataPoints table */
  SubDataPoints: TableId.DataInventoryDataPoints,
  /** Vendors table */
  Vendors: TableId.DataInventoryVendors,
  /** DataSubCategories table */
  DataSubCategories: TableId.DataInventoryDataCategories,
  /** ProcessingPurposeSubCategories table */
  ProcessingPurposeSubCategories: TableId.DataInventoryPurposes,
  /** BusinessEntities table */
  BusinessEntities: TableId.DataInventoryBusinessEntities,
});

/** Type overrides */
export type DataReportExportableTables =
  (typeof DataReportExportableTables)[keyof typeof DataReportExportableTables];

/**
 * The fields that data reports can be ordered by
 */
export const DataReportRawOrderField = makeEnum({
  /** The title of the data report */
  Title: 'title',
});

/** Type overrides */
export type DataReportRawOrderField =
  (typeof DataReportRawOrderField)[keyof typeof DataReportRawOrderField];

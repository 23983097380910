import React from 'react';

import { SvgProps } from '../enums';

export const FlagCW: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55184"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55184)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55184"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55184)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M2.12721 3.07461L1.13279 3.59946L1.61292 2.66526L1 1.98157L1.76167 1.95346L2.12721 1L2.40617 1.95346L3.29748 1.98157L2.65574 2.66526L3.06286 3.59946L2.12721 3.07461Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.67619 5.53829L4.44888 6.0524L4.93037 4.76409L3.86328 3.89965H5.14193L5.67619 2.5061L6.0839 3.89965H7.36452L6.45451 4.76409L6.90637 6.0524L5.67619 5.53829Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 7V9H16V7H0Z"
          fill="#F9E813"
        />
      </g>
    </g>
  </svg>
);

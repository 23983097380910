import 'antd/lib/col/style/index';

import AntCol, { ColProps as AntColProps } from 'antd/lib/col';
import React from 'react';

/**
 * Column props
 */
export interface ColProps extends AntColProps {
  /** Align contents of the column */
  align?: 'left' | 'center' | 'right';
}

/**
 * An ant color
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 * @param props - Col props
 */
export function Col(props: ColProps): JSX.Element {
  return <AntCol {...(props as any)} />; // eslint-disable-line @typescript-eslint/no-explicit-any
}

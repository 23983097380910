import React from 'react';

import { SvgProps } from '../enums';

export const FlagMN: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55156"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" rx="-1" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55156)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H11V12H5V0Z"
        fill="#4C67E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H16V12H11V0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H6V12H0V0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3.00694 3.44211C2.50026 3.44211 2.56677 2.94844 2.56677 2.94844L2.807 2.42964V2.63863V2.94844C2.807 3.01517 2.89805 2.88171 2.89805 2.72106C2.89805 2.5604 3.00694 2.31983 3.00694 2.31983C3.00694 2.31983 3.01131 2.23939 3.01432 2.15996C3.03175 2.20083 3.06668 2.23621 3.11972 2.28993C3.12897 2.2993 3.13877 2.30923 3.14912 2.31983C3.21172 2.38398 3.20327 2.55391 3.19597 2.70093C3.18935 2.83408 3.18367 2.94844 3.23253 2.94844C3.33535 2.94844 3.3277 2.48906 3.3277 2.48906L3.50027 2.94844C3.50027 2.94844 3.51361 3.44211 3.00694 3.44211ZM3.00694 2.03227C3.01983 1.9569 3.0182 2.05759 3.01432 2.15996C2.99977 2.12584 2.99742 2.0879 3.00694 2.03227ZM3.76923 4.3419C3.76923 4.74325 3.43344 5.0686 3.01923 5.0686C2.60502 5.0686 2.26923 4.74325 2.26923 4.3419C2.26923 3.94056 2.60502 3.6152 3.01923 3.6152C3.43344 3.6152 3.76923 3.94056 3.76923 4.3419ZM1.88462 5.70214H1V9.98782H1.88462V5.70214ZM5 5.70214H4.11538V9.98782H5V5.70214ZM2.11538 5.73941L2.97775 6.27333L3.94969 5.73941H2.11538ZM2.97775 10L2.11538 9.46608H3.94969L2.97775 10ZM2.11538 6.44747H3.92308V6.78287H2.11538V6.44747ZM3.92308 8.94435H2.11538V9.27975H3.92308V8.94435ZM3.03846 8.75801C3.54826 8.75801 3.96154 8.35757 3.96154 7.86361C3.96154 7.36965 3.54826 6.96921 3.03846 6.96921C2.52866 6.96921 2.11538 7.36965 2.11538 7.86361C2.11538 8.35757 2.52866 8.75801 3.03846 8.75801ZM2.0016 4.6081C2.0016 4.6081 2.03268 5.54177 2.95293 5.54177C3.87319 5.54177 4.02172 4.6081 4.02172 4.6081C4.02172 4.6081 3.66552 5.18004 3.01166 5.18004C2.3578 5.18004 2.0016 4.6081 2.0016 4.6081Z"
        fill="#F8D000"
      />
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagDO: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55119"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55119)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#C51918"
      />
      <mask
        id="mask1_270_55119"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55119)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 0H9V5H16V7H9V12H7V7H0V5H7V0Z"
          fill="#F7FCFF"
        />
        <rect width="7" height="5" fill="#4257BF" />
        <rect x="9" y="7" width="7" height="5" fill="#4257BF" />
        <path
          // eslint-disable-next-line max-len
          d="M6.81841 5.55536L7.31613 5.603C7.23094 6.49306 7.34165 6.86809 7.52155 6.86809V7.36809C6.89935 7.36809 6.70721 6.71718 6.81841 5.55536Z"
          fill="#309404"
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.21333 5.55536L8.7156 5.603C8.80079 6.49306 8.69009 6.86809 8.51019 6.86809V7.36809C9.13239 7.36809 9.32453 6.71718 9.21333 5.55536Z"
          fill="#309404"
        />
        <rect
          x="7.36365"
          y="5.34616"
          width="0.472727"
          height="0.738462"
          fill="#003994"
        />
        <path
          d="M8.09082 6.22308H8.56355V6.59791C8.56355 6.79874 8.40074 6.96155 8.19991 6.96155H8.09082V6.22308Z"
          fill="#003994"
        />
        <rect
          x="8.05444"
          y="5.30002"
          width="0.472727"
          height="0.738462"
          fill="#DE2110"
        />
        <path
          d="M7.36365 6.22308H7.83637V6.96155H7.72728C7.52645 6.96155 7.36365 6.79874 7.36365 6.59791V6.22308Z"
          fill="#DE2110"
        />
        <path
          // eslint-disable-next-line max-len
          d="M7.1672 5.30469L6.83276 4.93301C7.18594 4.61523 7.55401 4.45001 7.93309 4.45001C8.31217 4.45001 8.68025 4.61523 9.03342 4.93301L8.69898 5.30469C8.43151 5.06402 8.17751 4.95001 7.93309 4.95001C7.68868 4.95001 7.43468 5.06402 7.1672 5.30469Z"
          fill="#00319C"
        />
        <path
          // eslint-disable-next-line max-len
          d="M7.20358 7.797L6.86914 7.42532C7.22231 7.10754 7.59039 6.94232 7.96947 6.94232C8.34855 6.94232 8.71662 7.10754 9.0698 7.42532L8.73536 7.797C8.46788 7.55633 8.21389 7.44232 7.96947 7.44232C7.72505 7.44232 7.47106 7.55633 7.20358 7.797Z"
          fill="#DE2110"
        />
      </g>
    </g>
  </svg>
);

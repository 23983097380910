import React from 'react';

import { ContainerWrapper } from './wrappers';

/**
 * Props
 */
export interface ContainerProps {
  /** ID of element */
  id?: string;
  /** The container contents */
  children?: React.ReactNode;
  /** Whether to ignore the container constraint */
  ignore?: boolean;
  /** The extra spacing to add between the header */
  spacing?: number;
  /** CSS */
  style?: React.CSSProperties;
}

/**
 * Containerize the contents to fit the screen
 */
export const Container: React.FC<ContainerProps> = ({
  children = 0,
  ...containerProps
}) => <ContainerWrapper {...containerProps}>{children}</ContainerWrapper>;

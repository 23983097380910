import React from 'react';

import { SvgProps } from '../enums';

export const FlagKI: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55033"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55033)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#E31D1C"
      />
      <mask
        id="mask1_270_55033"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55033)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.66165 8.58849L7.89055 8.0921L8.11946 8.58849L8.25533 8.05954L8.56992 8.50741L8.60838 7.9629L8.99854 8.34785L8.93837 7.80528L9.39155 8.11494L9.23467 7.59175L9.73632 7.81618L9.48777 7.32918L10.0218 7.46115L9.68954 7.026L10.2387 7.06128L9.83349 6.69195L10.3802 6.62941L9.91498 6.33779L10.4416 6.17943L9.93142 5.97488L10.4211 5.72579L9.88225 5.61489L10.3192 5.28308L9.76907 5.2694L10.1393 4.86553L9.59552 4.9495L9.88708 4.48655L9.36717 4.66548L9.5707 4.15834L9.09135 4.42646L9.20032 3.89143L8.77694 4.24014L8.78784 3.6944L8.43405 4.1125L8.34653 3.5736L8.07368 4.04763L7.89055 3.53289L7.70743 4.04763L7.43458 3.5736L7.34706 4.1125L6.99327 3.6944L7.00417 4.24014L6.58079 3.89143L6.68976 4.42646L6.21041 4.15834L6.41394 4.66548L5.89403 4.48655L6.18559 4.9495L5.64182 4.86553L6.01203 5.2694L5.46189 5.28308L5.89886 5.61489L5.36002 5.72579L5.84969 5.97488L5.33948 6.17943L5.86612 6.33779L5.40093 6.62941L5.94762 6.69195L5.5424 7.06128L6.09157 7.026L5.75935 7.46115L6.29334 7.32918L6.04479 7.81618L6.54644 7.59175L6.38956 8.11494L6.84274 7.80528L6.78257 8.34785L7.17272 7.9629L7.21119 8.50741L7.52578 8.05954L7.66165 8.58849Z"
          fill="#FFC84A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M-0.396066 6.96005L0.911357 6.42946C1.10193 6.35211 1.31532 6.35306 1.5052 6.43208L3.32058 7.18755C3.52598 7.27303 3.75808 7.26686 3.95865 7.17061L5.42295 6.46788C5.63297 6.36709 5.87702 6.3653 6.0885 6.463L7.62222 7.17153C7.82884 7.26699 8.06682 7.26757 8.27391 7.17313L9.84328 6.45745C10.0503 6.36304 10.2882 6.36359 10.4948 6.45896L12.0442 7.17426C12.2476 7.26813 12.4814 7.2702 12.6864 7.17994L14.3794 6.43445C14.5627 6.35374 14.77 6.34652 14.9585 6.41429L16.4764 6.96005V11.9669H-0.440552L-0.396066 6.96005Z"
          fill="#F7FCFF"
        />
        <mask
          id="mask2_270_55033"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="-1"
          y="6"
          width="18"
          height="6"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M-0.396066 6.96005L0.911357 6.42946C1.10193 6.35211 1.31532 6.35306 1.5052 6.43208L3.32058 7.18755C3.52598 7.27303 3.75808 7.26686 3.95865 7.17061L5.42295 6.46788C5.63297 6.36709 5.87702 6.3653 6.0885 6.463L7.62222 7.17153C7.82884 7.26699 8.06682 7.26757 8.27391 7.17313L9.84328 6.45745C10.0503 6.36304 10.2882 6.36359 10.4948 6.45896L12.0442 7.17426C12.2476 7.26813 12.4814 7.2702 12.6864 7.17994L14.3794 6.43445C14.5627 6.35374 14.77 6.34652 14.9585 6.41429L16.4764 6.96005V11.9669H-0.440552L-0.396066 6.96005Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_270_55033)">
          <path
            // eslint-disable-next-line max-len
            d="M-0.396118 8.12287L0.911305 7.59227C1.10188 7.51493 1.31527 7.51587 1.50515 7.59489L3.32053 8.35037C3.52593 8.43584 3.75803 8.42968 3.9586 8.33342L5.4229 7.6307C5.63292 7.52991 5.87697 7.52812 6.08845 7.62581L7.62217 8.33435C7.82879 8.4298 8.06677 8.43039 8.27386 8.33595L9.84323 7.62026C10.0503 7.52586 10.2881 7.52641 10.4947 7.62178L12.0442 8.33707C12.2475 8.43095 12.4814 8.43302 12.6863 8.34276L14.3794 7.59727C14.5627 7.51656 14.77 7.50934 14.9585 7.5771L16.4764 8.12287"
            stroke="#015989"
          />
          <path
            // eslint-disable-next-line max-len
            d="M-0.396118 9.78734L0.911305 9.25675C1.10188 9.1794 1.31527 9.18035 1.50515 9.25937L3.32053 10.0148C3.52593 10.1003 3.75803 10.0942 3.9586 9.9979L5.4229 9.29517C5.63292 9.19438 5.87697 9.19259 6.08845 9.29029L7.62217 9.99882C7.82879 10.0943 8.06677 10.0949 8.27386 10.0004L9.84323 9.28474C10.0503 9.19033 10.2881 9.19088 10.4947 9.28625L12.0442 10.0015C12.2475 10.0954 12.4814 10.0975 12.6863 10.0072L14.3794 9.26174C14.5627 9.18103 14.77 9.17381 14.9585 9.24158L16.4764 9.78734"
            stroke="#015989"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M-0.396118 11.1137L0.149714 10.8921C0.828216 10.6168 1.58794 10.6201 2.26399 10.9015L3.32053 11.3411C3.52593 11.4266 3.75803 11.4205 3.9586 11.3242L4.57385 11.0289C5.32159 10.6701 6.19046 10.6637 6.94338 11.0116L7.62217 11.3251C7.82879 11.4206 8.06677 11.4212 8.27386 11.3267L9.00832 10.9918C9.74538 10.6557 10.5924 10.6576 11.3279 10.9972L12.0442 11.3279C12.2475 11.4217 12.4814 11.4238 12.6863 11.3335L13.6484 10.9099C14.301 10.6226 15.0391 10.5969 15.7101 10.8381L16.4764 11.1137V12.7711H-0.30431L-0.396118 11.1137Z"
            fill="#015989"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.80066 1.19903C4.80066 1.19903 5.51301 1.34151 5.74732 1.34151C5.98162 1.34151 6.51022 0.983251 6.81596 1.10267C7.1217 1.22209 7.70976 1.78683 7.70976 1.78683C7.70976 1.78683 8.54267 1.1621 9.0495 1.1621C9.55634 1.1621 10.4869 1.22999 10.4869 1.22999C10.4869 1.22999 10.2724 1.78673 9.66917 1.88103C9.06589 1.97534 8.34412 2.4372 8.34412 2.4372C8.34412 2.4372 9.17515 2.4372 9.32463 2.4372C9.47411 2.4372 9.96876 2.71367 9.96876 2.71367C9.96876 2.71367 9.26676 2.93602 9.06589 2.93602C8.86503 2.93602 8.64314 2.93602 8.64314 2.93602C8.64314 2.93602 8.21606 3.3025 7.68846 3.22453C7.16086 3.14655 6.79177 2.80809 6.79177 2.80809C6.79177 2.80809 6.04707 2.75919 6.04707 2.67957C6.04707 2.67957 5.76263 2.53379 5.67701 2.67957C5.59139 2.82535 6.04707 2.48435 6.04707 2.48435C6.04707 2.48435 6.45863 2.15022 6.59808 2.15022C6.73754 2.15022 7.20324 2.4372 7.20324 2.4372L7.31444 2.32159L6.73754 1.88103C5.56083 2.00432 4.80066 1.19903 4.80066 1.19903Z"
          fill="#FFC84A"
        />
      </g>
    </g>
  </svg>
);

import { AttributeSupportedResourceType } from '@transcend-io/privacy-types';

import { mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { BusinessEntityPreview } from './businessEntityPreview';
import { DataSiloInterface } from './dataSiloPreview';
import { DataSubCategory } from './dataSubCategory';
import { PurposeSubCategory } from './processingPurpose';
import { SubDataPoint } from './subDataPoint';
import { VendorPreview } from './vendorPreview';

export const AirgapCookieOrDataFlowPreview = mkType({
  name: 'AirgapCookieOrDataFlowPreview',
  comment: 'A preview of an airgap cookie or data flow',
  fields: {
    id: {
      comment: 'The ID of this cookie or data flow',
      type: 'string',
    },
    description: {
      comment: 'The description for the cookie or data flow',
      type: 'string',
      optional: true,
    },
    name: {
      comment: 'Name or regex of cookie or data flow',
      type: 'string',
    },
  },
});

/** Override type */
export type AirgapCookieOrDataFlowPreview = SchemaToType<
  typeof AirgapCookieOrDataFlowPreview
>;

export const AttributeResourceInterface = mkInterface({
  name: 'AttributeResourceInterface',
  comment:
    'A preview of a generic resource, such as a row associated with an attribute value.',
  fields: {
    resourceType: {
      comment: 'The resource type',
      type: { AttributeSupportedResourceType },
    },
  },
});

/** Override type */
export type AttributeResourceInterface = SchemaToType<
  typeof AttributeResourceInterface
>;

export const AttributeResource = mkType({
  name: 'AttributeResource',
  interfaces: [AttributeResourceInterface],
  comment: 'Default type for AttributeResourceInterface',
  fields: {
    ...AttributeResourceInterface.fields,
  },
});

/** Override type */
export type AttributeResource = SchemaToType<typeof AttributeResource>;

export const AttributeBusinessEntityResource = mkType({
  name: 'AttributeBusinessEntityResource',
  comment: 'A business entity with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    ...BusinessEntityPreview.fields,
  },
});

/** Override type */
export type AttributeBusinessEntityResource = SchemaToType<
  typeof AttributeBusinessEntityResource
>;

export const AttributeAirgapCookieResource = mkType({
  name: 'AttributeAirgapCookieResource',
  comment: 'An airgap cookie with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    ...AirgapCookieOrDataFlowPreview.fields,
  },
});

/** Override type */
export type AttributeAirgapCookieResource = SchemaToType<
  typeof AttributeAirgapCookieResource
>;

export const AttributeAirgapDataFlowResource = mkType({
  name: 'AttributeAirgapDataFlowResource',
  comment: 'An airgap data flow with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    ...AirgapCookieOrDataFlowPreview.fields,
  },
});

/** Override type */
export type AttributeAirgapDataFlowResource = SchemaToType<
  typeof AttributeAirgapDataFlowResource
>;

export const AttributeDataSiloResource = mkType({
  name: 'AttributeDataSiloResource',
  comment: 'A data silo with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface, DataSiloInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    ...DataSiloInterface.fields,
  },
});

/** Override type */
export type AttributeDataSiloResource = SchemaToType<
  typeof AttributeDataSiloResource
>;

export const AttributeDataSubCategoryResource = mkType({
  name: 'AttributeDataSubCategoryResource',
  comment: 'A data subcategory with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    ...DataSubCategory.fields,
  },
});

/** Override type */
export type AttributeDataSubCategoryResource = SchemaToType<
  typeof AttributeDataSubCategoryResource
>;

export const AttributeProcessingPurposeSubCategoryResource = mkType({
  name: 'AttributeProcessingPurposeSubCategoryResource',
  comment: 'A processing purpose with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    ...PurposeSubCategory.fields,
  },
});

/** Override type */
export type AttributeProcessingPurposeSubCategoryResource = SchemaToType<
  typeof AttributeProcessingPurposeSubCategoryResource
>;

// TODO: https://transcend.height.app/T-19329 - Would be better to import this from dsr-types,
// but it results in a circular dependency
export const AttributeRequestResource = mkType({
  name: 'AttributeRequestResource',
  interfaces: [AttributeResourceInterface],
  comment: 'A request with an attribute value assigned to it',
  fields: {
    ...AttributeResourceInterface.fields,
    id: {
      comment: 'The unique ID of the request',
      modelName: 'request',
      type: 'id',
    },
    type: {
      comment: 'The type of request being made',
      type: 'string',
    },
    status: {
      comment: 'The status of request being made',
      type: 'string',
    },
  },
});

/** Override type */
export type AttributeRequestResource = SchemaToType<
  typeof AttributeRequestResource
>;

export const AttributeSubDataPointResource = mkType({
  name: 'AttributeSubDataPointResource',
  comment: 'A sub-datapoint with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    ...SubDataPoint.fields,
  },
});

/** Override type */
export type AttributeSubDataPointResource = SchemaToType<
  typeof AttributeSubDataPointResource
>;

export const AttributeVendorResource = mkType({
  name: 'AttributeVendorResource',
  comment: 'A vendor with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    ...VendorPreview.fields,
  },
});

/** Override type */
export type AttributeVendorResource = SchemaToType<
  typeof AttributeVendorResource
>;

export const AttributePromptResource = mkType({
  name: 'AttributePromptResource',
  comment: 'A prompt with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    id: {
      comment: 'The unique ID of the prompt',
      modelName: 'prompt',
      type: 'id',
    },
    title: {
      comment: 'The title of the prompt',
      type: 'string',
    },
  },
});

/** Override type */
export type AttributePromptResource = SchemaToType<
  typeof AttributePromptResource
>;

export const AttributePromptRunResource = mkType({
  name: 'AttributePromptRunResource',
  comment: 'A prompt run with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    id: {
      comment: 'The unique ID of the prompt run',
      modelName: 'promptRun',
      type: 'id',
    },
    title: {
      comment: 'The title of the prompt run',
      type: 'string',
    },
  },
});

/** Override type */
export type AttributePromptRunResource = SchemaToType<
  typeof AttributePromptRunResource
>;

export const AttributePromptGroupResource = mkType({
  name: 'AttributePromptGroupResource',
  comment: 'A prompt group with an attribute value assigned to it',
  interfaces: [AttributeResourceInterface],
  fields: {
    ...AttributeResourceInterface.fields,
    id: {
      comment: 'The unique ID of the prompt group',
      modelName: 'promptGroup',
      type: 'id',
    },
    title: {
      comment: 'The title of the prompt group',
      type: 'string',
    },
  },
});

/** Override type */
export type AttributePromptGroupResource = SchemaToType<
  typeof AttributePromptGroupResource
>;

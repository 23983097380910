import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ThumbsUp: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4.62966 12.6668H3.37099C2.98233 12.6668 2.66699 12.3515 2.66699 11.9628V7.0375C2.66699 6.64883 2.98233 6.3335 3.37099 6.3335H4.62966C5.01833 6.3335 5.33366 6.64883 5.33366 7.0375V11.9628C5.33366 12.3515 5.01833 12.6668 4.62966 12.6668V12.6668Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.33301 7.04804L7.76567 3.88071C8.21834 3.29071 9.10234 3.27604 9.57501 3.85004V3.85004C9.75167 4.06404 9.84767 4.33338 9.84767 4.61071V6.79138H11.9117C12.3123 6.79138 12.6863 6.99138 12.909 7.32404L13.1283 7.65138C13.325 7.94538 13.3823 8.31138 13.2843 8.65071L12.3783 11.7987C12.2303 12.3127 11.7603 12.6667 11.2257 12.6667H7.03301C6.69967 12.6667 6.38101 12.528 6.15434 12.284L5.33301 11.4"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

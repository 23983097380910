import './styles.css';
import 'highlight.js/styles/default.css';

import hljs from 'highlight.js';
import React from 'react';

/**
 * Props for HighlightText
 */
export interface HighlightTextProps {
  /** The json text */
  children: React.ReactNode;
  /** Class name */
  className?: string;
  /** Style to apply to the pre */
  style?: React.CSSProperties;
}

/**
 * Highlight text
 */
export class HighlightText extends React.Component<HighlightTextProps> {
  /**
   * The saved HTML element
   */
  private el?: any;

  /**
   * Highlight on mount
   */
  public componentDidMount(): void {
    this.highlightCode();
  }

  /**
   * Highlight on updated
   */
  public componentDidUpdate(): void {
    this.highlightCode();
  }

  /**
   * Highlight using highlight-js
   */
  public highlightCode(): void {
    if (!this.el) {
      return;
    }
    this.el
      .querySelectorAll('pre code')
      .forEach((node: any) => hljs.highlightBlock(node));
  }

  /**
   * Set the HTML element
   *
   * @param el - The element to set
   */
  public setEl = (el: any): void => {
    this.el = el;
  };

  /**
   * Render
   */
  public render(): JSX.Element {
    const { children, className = 'json', ...rest } = this.props;
    return (
      <pre ref={this.setEl} {...rest}>
        <code className={className}>{children}</code>
      </pre>
    );
  }
}

/* eslint-disable max-lines */
import {
  DataCategoryType,
  SubDataPointDataSubCategoryGuessStatus,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import {
  mkInput,
  mkInterface,
  mkType,
  type SchemaToType,
} from '@main/schema-utils';

import { DiscoveredByDataSiloPreview } from './dataSiloPreview';
import { RetentionType } from './enums';
import { WiredIdentifier } from './identifier';
import { SaaSCategoryBase } from './saaSCategoryBase';

export const DataSubCategoryPreview = mkType({
  name: 'DataSubCategoryPreview',
  comment: 'Subset of fields for a data subcategory',
  fields: {
    id: {
      comment: 'The ID for the subcategory',
      type: 'id',
      modelName: 'dataSubCategory',
    },
    name: {
      comment: 'The name of the subcategory',
      type: 'string',
      optional: true,
    },
    category: {
      comment: 'The category of personal data',
      type: { DataCategoryType },
    },
    slug: {
      comment: 'The slug of the category for templating',
      type: 'string',
    },
    regex: {
      comment: 'The regex used to identify this data subcategory',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type DataSubCategoryPreview = SchemaToType<
  typeof DataSubCategoryPreview
>;

export const DataCategoryInterface = mkInterface({
  name: 'DataCategoryInterface',
  comment: 'Interface for a data category, either top-level or subcategory',
  fields: {
    ...DataSubCategoryPreview.fields,
    description: {
      comment: 'Description of (sub)category',
      type: 'string',
      optional: true,
    },
    dataPointCount: {
      comment: 'Number of data points within (sub)category',
      type: 'int',
    },
    attributeValues: {
      comment: 'The attribute values used to label this subcategory',
      type: AttributeValue,
      list: true,
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this data sub category',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this data sub category',
      list: true,
      type: TeamPreview,
    },
  },
});

/** Override type */
export type DataCategoryInterface = SchemaToType<typeof DataCategoryInterface>;

export const DataInventoryCategory = mkType({
  name: 'DataInventoryCategory',
  comment: 'Metadata on a top-level data processing category',
  interfaces: [DataCategoryInterface],
  fields: DataCategoryInterface.fields,
});

/** Override type */
export type DataInventoryCategory = SchemaToType<typeof DataInventoryCategory>;

export const DataCategoryFiltersInput = mkInput({
  name: 'DataCategoryFiltersInput',
  comment: 'Input for filtering the list of data categories',
  fields: {
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
    dataSiloId: {
      comment: 'Filter by dataSilo',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    includeFallback: {
      comment: 'Include fallback sub-categories (default: false)',
      type: 'boolean',
      optional: true,
    },
    ownerIds: {
      comment:
        'The unique ids of the users assigned as owners of this data category',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this data category',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    saaSCategoryIds: {
      comment:
        // eslint-disable-next-line max-len
        'Filter by IDs of recipient categories associated with the catalogs of silos whose subdatapoints have this data subcategory',
      optional: true,
      type: 'id',
      modelName: 'saaSCategory',
      list: true,
    },
    retentionTypes: {
      comment:
        'Filter by the type of retention schedule for subdatapoints with this data subcategory',
      optional: true,
      type: { RetentionType },
      list: true,
    },
    attributeValueIds: {
      comment: 'The attribute values used to label the data category',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type DataCategoryFiltersInput = SchemaToType<
  typeof DataCategoryFiltersInput
>;

export const DataSubCategory = mkType({
  name: 'DataSubCategory',
  comment: 'Detailed info about a data processing subcategory',
  interfaces: [DataCategoryInterface],
  fields: {
    ...DataCategoryInterface.fields,
    id: {
      comment: 'The ID for the subcategory',
      type: 'id',
      modelName: 'dataSubCategory',
    },
    name: {
      comment: 'The name of the subcategory',
      type: 'string',
    },
    isDefault: {
      comment: 'Whether the subcategory is a default subcategory',
      type: 'boolean',
    },
    vendorHeadquarterLocations: {
      comment:
        'The headquarter regions assigned to vendors that have subdatapoints with this data subcategory',
      list: true,
      type: 'string',
    },
    dataSiloRegions: {
      comment:
        'The regions assigned to data silos that have subdatapoints with this data subcategory',
      list: true,
      type: 'string',
    },
    subDataPointRetentionSchedules: {
      comment:
        // eslint-disable-next-line max-len
        'The type of retention schedule for subdatapoints with this data subcategory, or the number of days data is retained for, if type is stated period',
      list: true,
      type: 'string',
    },
    catalogRecipients: {
      comment:
        // eslint-disable-next-line max-len
        'The pre-labeled recipient categories for catalogs associated with data silos whose subdatapoints have this data subcategory',
      type: SaaSCategoryBase,
      list: true,
    },
    dataSilos: {
      comment: 'The data silos related to the data subcategory',
      type: DiscoveredByDataSiloPreview,
      list: true,
    },
    identifier: {
      comment: 'The identifier that is linked to this data category',
      type: WiredIdentifier,
      optional: true,
    },
  },
});

/** Override type */
export type DataSubCategory = SchemaToType<typeof DataSubCategory>;

export const DataSubCategoryInput = mkInput({
  name: 'DataSubCategoryInput',
  comment: 'Detailed info about a data processing subcategory',
  fields: {
    name: DataSubCategory.fields.name,
    category: DataSubCategory.fields.category,
  },
});

/** Override type */
export type DataSubCategoryInput = SchemaToType<typeof DataSubCategoryInput>;

export const DataSubCategoryFiltersInput = mkInput({
  name: 'DataSubCategoryFiltersInput',
  comment: 'Input for filtering the list of data categories',
  fields: {
    ...DataCategoryFiltersInput.fields,
    ids: {
      comment: 'Filter by list of dataSubCategory ids',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    status: {
      comment: 'Filter by status of guess',
      type: { SubDataPointDataSubCategoryGuessStatus },
      optional: true,
    },
    ownerIds: {
      comment:
        'The unique ids of the users assigned as owners of this data subcategory',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this data subcategory',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    dataSiloIds: {
      comment:
        'Filter by data silos whose subdatapoints have this data subcategory',
      optional: true,
      type: 'id',
      modelName: 'dataSilo',
      list: true,
    },
    attributeValueIds: {
      comment: 'The attribute values used to label the data subcategory',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type DataSubCategoryFiltersInput = SchemaToType<
  typeof DataSubCategoryFiltersInput
>;

export const CreateDataInventorySubCategoryInput = mkInput({
  name: 'CreateDataInventorySubCategoryInput',
  comment: 'Input for creating a new data processing subcategory',
  fields: {
    name: {
      comment: 'The name of the subcategory',
      type: 'string',
    },
    category: {
      comment: 'The category of personal data subcategory is nested in',
      type: { DataCategoryType },
    },
    description: {
      comment: 'Description of category',
      type: 'string',
      optional: true,
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this data sub category',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment:
        'The emails of the users to assign as owners of this data sub category',
      type: 'string',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this data sub category',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this data sub category',
      type: 'string',
      list: true,
      optional: true,
    },
    identifierId: {
      comment: 'The identifier that is linked to this data category',
      type: 'id',
      modelName: 'identifier',
      optional: true,
    },
    identifierName: {
      comment:
        'The name of the identifier that is linked to this data category',
      type: 'string',
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this data sub category',
      type: AttributeInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateDataInventorySubCategoryInput = SchemaToType<
  typeof CreateDataInventorySubCategoryInput
>;

export const DeleteDataSubCategoriesInput = mkInput({
  name: 'DeleteDataSubCategoriesInput',
  comment:
    "Input for deleting Data Subcategories from an organization's Data Inventory",
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'dataSubCategory',
      list: true,
    },
  },
});

/** Override type */
export type DeleteDataSubCategoriesInput = SchemaToType<
  typeof DeleteDataSubCategoriesInput
>;

export const UpdateDataSubCategoryInput = mkInput({
  name: 'UpdateDataSubCategoryInput',
  comment: 'Input for creating a new data subcategory',
  fields: {
    id: {
      comment: 'The ID of the data subcategory to update',
      type: 'id',
      modelName: 'dataSubCategory',
    },
    description: {
      comment: 'The text to use as the new description of the data subcategory',
      type: 'string',
      optional: true,
    },
    regex: {
      comment: 'The regex used to identify this data subcategory',
      type: 'string',
      optional: true,
    },
    ownerIds: CreateDataInventorySubCategoryInput.fields.ownerIds,
    ownerEmails: CreateDataInventorySubCategoryInput.fields.ownerEmails,
    teamIds: CreateDataInventorySubCategoryInput.fields.teamIds,
    teamNames: CreateDataInventorySubCategoryInput.fields.teamNames,
    attributes: CreateDataInventorySubCategoryInput.fields.attributes,
    identifierId: CreateDataInventorySubCategoryInput.fields.identifierId,
    identifierName: CreateDataInventorySubCategoryInput.fields.identifierName,
  },
});

/** Override type */
export type UpdateDataSubCategoryInput = SchemaToType<
  typeof UpdateDataSubCategoryInput
>;

export const UpdateDataSubCategoriesInput = mkInput({
  name: 'UpdateDataSubCategoriesInput',
  comment: 'Input for bulk updating one or more data subcategories',
  fields: {
    dataSubCategories: {
      comment:
        'List of data subcategories with the properties that should be updated for each',
      type: UpdateDataSubCategoryInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateDataSubCategoriesInput = SchemaToType<
  typeof UpdateDataSubCategoriesInput
>;
/* eslint-enable max-lines */

import React from 'react';
import {
  Location,
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

export interface RouteComponentProps {
  /** Location */
  location: Location;
  /** Navigate */
  navigate: NavigateFunction;
  /** Params */
  params: Params;
}

/**
 * HOC for old class components. Can remove once componentDidCatch can be done in a hook
 *
 * @param Component - Component to wrap
 */
export function withRouter<TProps extends RouteComponentProps>(
  Component: any,
): React.FunctionComponent<Omit<TProps, keyof RouteComponentProps>> {
  /**
   * HOC mapper for class component
   */
  const ComponentWithRouterProp: React.FunctionComponent<
    Omit<TProps, keyof RouteComponentProps>
  > = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
  };

  return ComponentWithRouterProp;
}

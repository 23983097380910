/* eslint-disable max-lines */
import { AssessmentFormStatus } from '@transcend-io/privacy-types';

/**
 * Map from a target form status to another map from section statuses to section
 * status.
 *
 * It tells us:
 * 1) which statuses the sections of a form can have if we want to update
 *    the form to have the target status.
 * 2) For each allowed section status within the form, how that status should
 *    be updated.
 *
 * The 'shared' status is special. When we check if a form can transition to shared
 * may actually be verifying whether assignees can be added to the form. The status
 * only actually updates to 'shared' if the previous status was 'draft'.
 */
export const VALID_ASSESSMENT_SECTION_STATUSES_BY_FORM_STATUS: {
  // the target assessment form status
  [status in AssessmentFormStatus]: Record<
    // the current assessment section status
    AssessmentFormStatus,
    /**
     * the status to update the assessment section to when updating the form status
     * or undefined if the current section status forbids updating the form status
     */
    AssessmentFormStatus | undefined
  >;
} = {
  [AssessmentFormStatus.Draft]: {
    /**
     * A form can be updated to draft if its sections are draft.
     * The draft section should remain draft.
     */
    [AssessmentFormStatus.Draft]: AssessmentFormStatus.Draft,
    /** A form cannot be updated to draft if a section is shared */
    [AssessmentFormStatus.Shared]: undefined,
    /** A form cannot be updated to draft if a section is in progress */
    [AssessmentFormStatus.InProgress]: undefined,
    /** A form cannot be updated to draft if a section is in review */
    [AssessmentFormStatus.InReview]: undefined,
    /** A form cannot be updated to draft if a section has changes requested */
    [AssessmentFormStatus.ChangesRequested]: undefined,
    /** A form cannot be updated to draft if a section is rejected */
    [AssessmentFormStatus.Rejected]: undefined,
    /** A form cannot be updated to draft if a section is approved */
    [AssessmentFormStatus.Approved]: undefined,
  },
  [AssessmentFormStatus.Shared]: {
    /*
     * A form can be shared if it has draft sections.
     * The draft section should also be shared.
     */
    [AssessmentFormStatus.Draft]: AssessmentFormStatus.Shared,
    /**
     * A form can be shared if its sections are already shared.
     * The section should remain shared.
     */
    [AssessmentFormStatus.Shared]: AssessmentFormStatus.Shared,
    /**
     * A form can be shared with more people if its sections are already in progress.
     * The sections should remain in progress.
     */
    [AssessmentFormStatus.InProgress]: AssessmentFormStatus.InProgress,
    /**
     * A form can be shared to more people if the sections have changes requested.
     * We may want to invite more people to respond to these sections, for instance.
     * The sections remain with changes requested.
     */
    [AssessmentFormStatus.ChangesRequested]:
      AssessmentFormStatus.ChangesRequested,
    /** A form cannot be updated to shared if a section is in review */
    [AssessmentFormStatus.InReview]: undefined,
    /** A form cannot be updated to shared if a section is rejected */
    [AssessmentFormStatus.Rejected]: undefined,
    /** A form cannot be updated to shared if a section is approved */
    [AssessmentFormStatus.Approved]: undefined,
  },
  [AssessmentFormStatus.InProgress]: {
    /**
     * A form can start being responded if the sections have been shared.
     * Only the section whose question is being answered should be put in progress.
     * Every other section should remain shared.
     */
    [AssessmentFormStatus.Shared]: AssessmentFormStatus.Shared,
    /**
     * A form can start being responded if the sections are already being responded.
     * This keeps the sections in progress.
     */
    [AssessmentFormStatus.InProgress]: AssessmentFormStatus.InProgress,
    /**
     * A form can start being responded again if the sections have changes requested.
     * This sets only the section being responded to In Progress. Every other section
     * should remain with changes requested.
     */
    [AssessmentFormStatus.ChangesRequested]:
      AssessmentFormStatus.ChangesRequested,
    /**
     * A form can still be responded if some section is already In Review.
     * A User A assigned to a Section 1 could have been submitted for review
     * before a User B assigned to Section 2 started responding his section.
     * The In Review sections should remain In Review.
     *
     */
    [AssessmentFormStatus.InReview]: AssessmentFormStatus.InReview,

    /**
     * A form can start being responded again if some sections have already been
     * approved in a previous review cycle. These sections remain approved.
     */
    [AssessmentFormStatus.Approved]: AssessmentFormStatus.Approved,
    /** A form cannot be updated to in progress if a section is draft */
    [AssessmentFormStatus.Draft]: undefined,
    /** A form cannot be updated to in progress if a section is rejected */
    [AssessmentFormStatus.Rejected]: undefined,
  },
  [AssessmentFormStatus.InReview]: {
    /**
     * Can start reviewing the form if the sections have been responded.
     * This updates every in progress section to be in review as well.
     */
    [AssessmentFormStatus.InProgress]: AssessmentFormStatus.InReview,
    /**
     * Can start reviewing the form if the sections are already being reviewed.
     * This leaves the sections in review.
     */
    [AssessmentFormStatus.InReview]: AssessmentFormStatus.InReview,
    /**
     * Can review the form even if some of its sections have already been approved
     * in a previous review cycle. These sections remain approved.
     */
    [AssessmentFormStatus.Approved]: AssessmentFormStatus.Approved,
    /** A form cannot be updated to in review if a section is draft */
    [AssessmentFormStatus.Draft]: undefined,
    /** A form can be set to in review if a section is shared and has no required questions */
    [AssessmentFormStatus.Shared]: AssessmentFormStatus.InReview,
    /**
     * A form can be updated to in review if the form is in progress but
     * some sections are in changes requested
     */
    [AssessmentFormStatus.ChangesRequested]: AssessmentFormStatus.InReview,
    /** A form cannot be updated to in review if a section is rejected */
    [AssessmentFormStatus.Rejected]: undefined,
  },
  [AssessmentFormStatus.ChangesRequested]: {
    /**
     * Can request changes from the form when the sections are in review.
     * By default, every section in review should also be requested changes.
     * If some sections do not need further changes and can be approved,
     * that should be done manually by the endpoint.
     */
    [AssessmentFormStatus.InReview]: AssessmentFormStatus.ChangesRequested,
    /**
     * Can request changes from the form when the sections already have changes
     * requested. Such sections remain with requested changes.
     */
    [AssessmentFormStatus.ChangesRequested]:
      AssessmentFormStatus.ChangesRequested,
    /**
     * Can request changes to the form when some of the questions have already been
     * approved in a previous change request. Sections will have changes requested.
     */
    [AssessmentFormStatus.Approved]: AssessmentFormStatus.ChangesRequested,
    /** A form cannot be updated to changes requested if a section is draft */
    [AssessmentFormStatus.Draft]: undefined,
    /** A form cannot be updated to changes requested if a section is shared */
    [AssessmentFormStatus.Shared]: undefined,
    /** A form cannot be updated to changes requested if a section is in progress */
    [AssessmentFormStatus.InProgress]: undefined,
    /**
     * Can request changes to the form when the form has been rejected previously.
     * These sections will change to Changes Requested.
     */
    [AssessmentFormStatus.Rejected]: AssessmentFormStatus.ChangesRequested,
  },
  [AssessmentFormStatus.Rejected]: {
    /**
     * Can reject a form with sections in review.
     * These sections transition to rejected.
     */
    [AssessmentFormStatus.InReview]: AssessmentFormStatus.Rejected,
    /**
     * Can reject a form with sections with changes requested.
     * These sections transition to rejected.
     */
    [AssessmentFormStatus.ChangesRequested]: AssessmentFormStatus.Rejected,
    /**
     * Can reject a form whose sections have already been rejected.
     * These sections remain rejected.
     */
    [AssessmentFormStatus.Rejected]: AssessmentFormStatus.Rejected,
    /**
     * Can reject a form with sections approved.
     * These sections transition to rejected.
     */
    [AssessmentFormStatus.Approved]: AssessmentFormStatus.Rejected,
    /** A form cannot be updated to rejected if a section is draft */
    [AssessmentFormStatus.Draft]: undefined,
    /** A form cannot be updated to rejected if a section is shared */
    [AssessmentFormStatus.Shared]: undefined,
    /** A form cannot be updated to rejected if a section is in progress */
    [AssessmentFormStatus.InProgress]: undefined,
  },
  [AssessmentFormStatus.Approved]: {
    /**
     * Can approve a form with sections in review.
     * These sections transition to approved.
     */
    [AssessmentFormStatus.InReview]: AssessmentFormStatus.Approved,
    /**
     * Can approve a form with sections with changes requested. Maybe the
     * reviewer changed his mind. These sections transition to approved.
     */
    [AssessmentFormStatus.ChangesRequested]: AssessmentFormStatus.Approved,
    /**
     * Can reject a form whose sections have already been rejected. Maybe
     * the reviewer changed his mind. These sections transition to approved.
     */
    [AssessmentFormStatus.Rejected]: AssessmentFormStatus.Approved,
    /**
     * Can approve a form with sections already approved.
     * These sections remain approved.
     */
    [AssessmentFormStatus.Approved]: AssessmentFormStatus.Approved,
    /** A form cannot be updated to approved if a section is draft */
    [AssessmentFormStatus.Draft]: undefined,
    /** A form cannot be updated to approved if a section is shared */
    [AssessmentFormStatus.Shared]: undefined,
    /** A form cannot be updated to approved if a section is in progress */
    [AssessmentFormStatus.InProgress]: undefined,
  },
};

/** Map from a target assessment form status to the source statuses that can transition to it */
export const VALID_ASSESSMENT_FORM_STATUS_TRANSITION: {
  [status in AssessmentFormStatus]: AssessmentFormStatus[];
} = {
  [AssessmentFormStatus.Draft]: [
    /** A draft form can remain in draft */
    AssessmentFormStatus.Draft,
    /** A shared form can go back to draft if all assignees are removed */
    AssessmentFormStatus.Shared,
  ],
  [AssessmentFormStatus.Shared]: [
    /** A draft form can be shared with assignees */
    AssessmentFormStatus.Draft,
    /** A shared form can remain shared */
    AssessmentFormStatus.Shared,
    /** We can still add assignees to a form in progress */
    AssessmentFormStatus.InProgress,
    /** We can still add assignees to a form in review */
    AssessmentFormStatus.InReview,
    /** We can still add assignees to a form with changes requested */
    AssessmentFormStatus.ChangesRequested,
  ],
  [AssessmentFormStatus.InProgress]: [
    /** An form in progress can remain in progress */
    AssessmentFormStatus.InProgress,
    /** A shared form is put in progress when an assignee starts filling it */
    AssessmentFormStatus.Shared,
    /** A form with changes requested is put in progress when the assignee starts making changes */
    AssessmentFormStatus.ChangesRequested,
  ],
  [AssessmentFormStatus.InReview]: [
    /** A form in review can remain in review */
    AssessmentFormStatus.InReview,
    /** A form in progress is put in review when the assignee is done filling it */
    AssessmentFormStatus.InProgress,
  ],
  [AssessmentFormStatus.ChangesRequested]: [
    /** A form with changes requested can remain with changes requested */
    AssessmentFormStatus.ChangesRequested,
    /** A form being reviewed can have changes requested by the reviewer */
    AssessmentFormStatus.InReview,
    /** A reviewer can approve the submission but then change their mind and request changes */
    AssessmentFormStatus.Approved,
    /** A reviewer can reject the submission but then change their mind and request changes */
    AssessmentFormStatus.Rejected,
  ],
  [AssessmentFormStatus.Rejected]: [
    /** A rejected form can remain rejected */
    AssessmentFormStatus.Rejected,
    /** A reviewer can reject the submission */
    AssessmentFormStatus.InReview,
    /** A reviewer can approve the submission but then change their mind and reject it */
    AssessmentFormStatus.Approved,
    /** A reviewer can request changes but then change their mind and reject the form */
    AssessmentFormStatus.ChangesRequested,
  ],
  [AssessmentFormStatus.Approved]: [
    /** An approved form can remain approved */
    AssessmentFormStatus.Approved,
    /** A reviewer can approve the submission */
    AssessmentFormStatus.InReview,
    /** A reviewer can reject the submission but then change their mind and approve it */
    AssessmentFormStatus.Rejected,
    /** A reviewer can request changes but then change their mind and approve the form */
    AssessmentFormStatus.ChangesRequested,
  ],
};

/**
 * Given a form status, returns the list of statuses that sections within a
 * form cannot have if we want to transition the form to have the 'formStatus'.
 *
 * @param formStatus - the status of the assessment form
 * @returns a list of invalid assessment section statuses
 */
export const getInvalidSectionStatusesByFormStatus = (
  formStatus: AssessmentFormStatus,
): AssessmentFormStatus[] => {
  /**
   * get the map from current section status to target section status
   * This latter is defined if and only if sections are allowed to
   * have the current status when the form has 'formStatus'
   */
  const sectionStatusMap =
    VALID_ASSESSMENT_SECTION_STATUSES_BY_FORM_STATUS[formStatus];

  // invalid section statuses are those that cannot transition to a target section status
  return Object.keys(sectionStatusMap).filter(
    (k) => sectionStatusMap[k] === undefined,
  ) as AssessmentFormStatus[];
};

/**
 * Given a form status and a list of section statuses, filters the section statuses
 * in the list that have invalid values if we want to transition the form to 'formStatus'.
 *
 * @param param - the target form status and the section statuses to evaluate
 * @returns the invalid section statuses in the list
 */
export const filterInvalidSectionStatusesByFormStatus = ({
  formStatus,
  sectionStatuses,
}: {
  /** the status of the assessment form */
  formStatus: AssessmentFormStatus;
  /** the statuses of the sections to be checked */
  sectionStatuses: AssessmentFormStatus[];
}): AssessmentFormStatus[] => {
  const allInvalidSectionStatuses =
    getInvalidSectionStatusesByFormStatus(formStatus);
  return sectionStatuses.filter((sectionStatus) =>
    allInvalidSectionStatuses.includes(sectionStatus),
  );
};

/**
 * Checks whether the status can transition from the 'before' to the 'after' state
 *
 * @param params - the before and after statuses
 * @returns true if the status transition is valid
 */
export const isStatusTransitionValid = ({
  beforeStatus,
  afterStatus,
}: {
  /** the status before */
  beforeStatus: AssessmentFormStatus;
  /** the status after */
  afterStatus: AssessmentFormStatus;
}): boolean =>
  VALID_ASSESSMENT_FORM_STATUS_TRANSITION[afterStatus].includes(beforeStatus);

/**
 * Whether an AssessmentFormStatus transition is valid, taking into
 * consideration the before status and the statuses of sections
 * within the form, if provided.
 *
 * @param params - the arguments
 * @returns true if the transition is valid
 */
export const isAssessmentFormStatusTransitionValid = ({
  beforeStatus,
  afterStatus,
  sectionStatuses = [],
}: {
  /** the status before */
  beforeStatus: AssessmentFormStatus;
  /** the status after */
  afterStatus: AssessmentFormStatus;
  /** the current status of the relevant sections within the form */
  sectionStatuses?: AssessmentFormStatus[];
}): {
  /** The reason for the failure */
  error: string;
  /** Whether the transition is valid */
  success: boolean;
  /** The section status that caused error */
  invalidSectionStatuses?: AssessmentFormStatus[];
} => {
  // the form should be allowed to transition from the before to after status
  if (
    !isStatusTransitionValid({
      beforeStatus,
      afterStatus,
    })
  ) {
    return {
      error: `Cannot update the assessment form status from ${beforeStatus} to ${afterStatus}.`,
      success: false,
    };
  }

  // the provided sections within the form should have a valid status
  const invalidSectionStatuses = filterInvalidSectionStatusesByFormStatus({
    formStatus: afterStatus,
    sectionStatuses,
  });
  if (invalidSectionStatuses.length > 0) {
    return {
      // eslint-disable-next-line max-len
      error: `Cannot update the assessment form status to ${afterStatus} if it has sections with status ${invalidSectionStatuses[0]}.`,
      success: false,
      invalidSectionStatuses,
    };
  }

  return {
    error: '',
    success: true,
  };
};

/**
 * Whether an assessment section can transition from the before status
 * to the after status.
 *
 * @param params - the arguments
 * @returns true if the transition is valid
 */
export const isAssessmentSectionStatusTransitionValid = ({
  beforeStatus,
  afterStatus,
}: {
  /** the status before */
  beforeStatus: AssessmentFormStatus;
  /** the status after */
  afterStatus: AssessmentFormStatus;
}): {
  /** The reason for the failure */
  error: string;
  /** Whether the transition is valid */
  success: boolean;
} => {
  // the section should be allowed to transition from the before to after status
  if (
    !isStatusTransitionValid({
      beforeStatus,
      afterStatus,
    })
  ) {
    return {
      error: `Cannot update the assessment section status from ${beforeStatus} to ${afterStatus}.`,
      success: false,
    };
  }

  return {
    error: '',
    success: true,
  };
};

/* eslint-enable max-lines */

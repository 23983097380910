import AntRow from 'antd/lib/row';
import styled from 'styled-components';

/**
 * Props
 */
export interface RowWrapperProps {
  /** Row padding */
  padding?: string;
  /** Mobile padding */
  mobilePadding?: string;
}

/**
 * The Row wrapper
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 */
export const RowWrapper = styled(AntRow)<RowWrapperProps>`
  padding: ${({ mobilePadding }) => mobilePadding};

  @media (min-width: 768px) {
    padding: ${({ padding }) => padding};
  }
`;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagTR: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55040"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55040)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#E31D1C"
      />
      <mask
        id="mask1_270_55040"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55040)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.01267 8.05676C6.94201 7.793 6.10345 6.89684 6.11318 5.58316C6.12229 4.35346 6.86682 3.31403 8.04379 3.03265C9.22077 2.75127 10.257 3.34858 10.257 3.34858C9.93171 2.58067 8.8016 2.04119 7.8751 2.04275C6.15049 2.04563 4.31205 3.44426 4.29621 5.58316C4.27978 7.80066 6.2649 9.06353 8.01081 9.06061C9.41044 9.05826 10.0743 8.10069 10.2118 7.69334C10.2118 7.69334 9.08333 8.32052 8.01267 8.05676ZM10.4516 5.16329L9.38487 5.55512L10.5885 5.97972L10.568 7.24841L11.361 6.29661L12.6711 6.3918L11.6329 5.49943L12.3145 4.54753L11.2047 4.92102L10.4116 4.03922L10.4516 5.16329Z"
          fill="#F7FCFF"
        />
      </g>
    </g>
  </svg>
);

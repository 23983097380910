import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const BulletedList: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M4.07543 2.66669C4.39721 2.66669 4.65608 2.9251 4.65608 3.24361V4.39746C4.65608 4.71717 4.39721 4.97438 4.07543 4.97438H2.91414C2.59358 4.97438 2.3335 4.71717 2.3335 4.39746V3.24361C2.3335 2.9251 2.59358 2.66669 2.91414 2.66669H4.07543ZM13.5593 3.0513C13.9875 3.0513 14.3335 3.39577 14.3335 3.82053C14.3335 4.24601 13.9875 4.58976 13.5593 4.58976H6.59156C6.16333 4.58976 5.81737 4.24601 5.81737 3.82053C5.81737 3.39577 6.16333 3.0513 6.59156 3.0513H13.5593ZM13.5593 6.89746C13.9875 6.89746 14.3335 7.24121 14.3335 7.66669C14.3335 8.09217 13.9875 8.43592 13.5593 8.43592H6.59156C6.16333 8.43592 5.81737 8.09217 5.81737 7.66669C5.81737 7.24121 6.16333 6.89746 6.59156 6.89746H13.5593ZM13.5593 10.7436C13.9875 10.7436 14.3335 11.0874 14.3335 11.5128C14.3335 11.9383 13.9875 12.2821 13.5593 12.2821H6.59156C6.16333 12.2821 5.81737 11.9383 5.81737 11.5128C5.81737 11.0874 6.16333 10.7436 6.59156 10.7436H13.5593ZM2.3335 7.08976C2.3335 6.77005 2.59358 6.51284 2.91414 6.51284H4.07543C4.39721 6.51284 4.65608 6.77005 4.65608 7.08976V8.24361C4.65608 8.56332 4.39721 8.82053 4.07543 8.82053H2.91414C2.59358 8.82053 2.3335 8.56332 2.3335 8.24361V7.08976ZM4.07543 10.359C4.39721 10.359 4.65608 10.6162 4.65608 10.9359V12.0898C4.65608 12.4095 4.39721 12.6667 4.07543 12.6667H2.91414C2.59358 12.6667 2.3335 12.4095 2.3335 12.0898V10.9359C2.3335 10.6162 2.59358 10.359 2.91414 10.359H4.07543Z"
      fill={color}
    />
  </svg>
);

import { defineMessages } from '@transcend-io/internationalization';

export const contentErrorBoundaryMessages = defineMessages(
  'core-ui.ContentErrorBoundary.contentErrorBoundary',
  {
    error: {
      defaultMessage:
        'An error occurred! Try reloading the page. If the issue persists, please contact support.',
    },
  },
);

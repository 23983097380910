import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const LightningMovingFast: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      // eslint-disable-next-line max-len
      d="M22.82 8.511v6.23h5.317a.846.846 0 01.721 1.316l-6.757 9.901a.87.87 0 01-1.588-.47v-6.232H15.2a.846.846 0 01-.721-1.317l6.755-9.897a.87.87 0 011.587.47v0z"
      clipRule="evenodd"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12.998 25.003H5.995M9.497 17.667H3.66M12.998 10.33H5.995"
    />
  </svg>
);

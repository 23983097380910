import {
  apply,
  applyEnum,
  ObjByString,
  StringKeys,
} from '@transcend-io/type-utils';

import { GraphQLMethod, OrderDirection } from './enums';
import type {
  AnySchema,
  EndpointParams,
  GraphQLResponse,
  MutationEndpoint,
  MutationEndpointInput,
  OrderFields,
  QueryEndpoint,
  QueryEndpointInput,
  Schema,
  SchemaField,
  SchemaFields,
  SchemaInput,
  SchemaType,
} from './types';

/**
 * Makes the function to convert the schema to optional
 *
 * @param schema - The schema to make optional
 * @returns A function to convert the schema to optional
 */
function makeSchemaOptional<
  TName extends string,
  TFields extends SchemaFields,
  TType extends SchemaType,
>(
  schema: SchemaInput<TName, TFields, TType> & {
    /** The schema type */
    type: TType;
  },
): () => Schema<TName, TFields, TType, true, false> {
  const list = (): Schema<TName, TFields, TType, true, true> => ({
    ...schema,
    isOptional: true as const,
    optional: makeSchemaOptional(schema),
    isList: true as const,
    list,
  });
  return () => ({
    ...schema,
    isOptional: true as const,
    optional: makeSchemaOptional(schema),
    list,
  });
}

/**
 * Makes the function to convert the schema to a list
 *
 * @param schema - The schema ot convert into a list
 * @returns A function to convert the schema to a list
 */
function makeSchemaList<
  TName extends string,
  TFields extends SchemaFields,
  TType extends SchemaType,
>(
  schema: SchemaInput<TName, TFields, TType> & {
    /** The schema type */
    type: TType;
  },
): () => Schema<TName, TFields, TType, false, true> {
  const list = (): Schema<TName, TFields, TType, false, true> => ({
    ...schema,
    isOptional: false as const,
    optional: makeSchemaOptional(schema),
    isList: true as const,
    list,
  });
  return list;
}

/**
 * Create a GraphQL input schema
 *
 * @param schema - The schema definition input input
 * @returns The schema definition
 */
export function mkInput<TName extends string, TFields extends SchemaFields>(
  schema: SchemaInput<TName, TFields, 'input'>,
): Schema<TName, TFields, 'input', false, false> {
  if (!schema.name.endsWith('Input')) {
    throw new Error('Expected input schemas to end with suffix "Input"');
  }

  // set input defaults
  const inputSchema = {
    ...schema,
    isOptional: false as const,
    type: 'input' as const,
  };

  // return configured schema
  return {
    ...inputSchema,
    optional: makeSchemaOptional(inputSchema),
    list: makeSchemaList(inputSchema),
  };
}

/**
 * Create a GraphQL type schema
 *
 * @param schema - The schema definition type input
 * @returns The schema definition
 */
export function mkType<TName extends string, TFields extends SchemaFields>(
  schema: SchemaInput<TName, TFields, 'type'>,
): Schema<TName, TFields, 'type', false, false> {
  // set type defaults
  const typeSchema = {
    ...schema,
    isOptional: false as const,
    type: 'type' as const,
  };

  // return configured schema
  return {
    ...typeSchema,
    optional: makeSchemaOptional(typeSchema),
    list: makeSchemaList(typeSchema),
  };
}

/**
 * Create a GraphQL interface schema
 *
 * @param schema - The interface schema definition
 * @returns The schema definition
 */
export function mkInterface<TName extends string, TFields extends SchemaFields>(
  schema: SchemaInput<TName, TFields, 'interface'>,
): Schema<TName, TFields, 'interface', false, false> {
  if (!schema.name.endsWith('Interface')) {
    throw new Error(
      `Expected interface schemas to end with suffix "Interface" got ${schema.name}`,
    );
  }

  // set interface defaults
  const interfaceSchema = {
    ...schema,
    isOptional: false as const,
    type: 'interface' as const,
  };

  // return configured schema
  return {
    ...interfaceSchema,
    optional: makeSchemaOptional(interfaceSchema),
    list: makeSchemaList(interfaceSchema),
  };
}

/**
 * Creates the order object by which a query can be ordered
 *
 * @param name - The name of the node
 * @param orderFieldEnum - An enum of the fields that can be ordered
 * @param modelEnum - The associated models that can be ordered by
 * @returns The schema for the order object
 */
export function mkOrder<TName extends string, TEnum extends string>(
  name: TName,
  orderFieldEnum: { [k in string]: TEnum },
  modelEnum?: { [k in string]: string },
): Schema<string, OrderFields, 'input', true, true> {
  const schema = {
    name: `${name}Order`,
    comment: `The order for a ${name} query`,
    type: 'input',
    fields: {
      field: {
        comment: `The field that the ${name} nodes should be ordered by`,
        type: { [`${name}OrderField`]: orderFieldEnum },
      },
      direction: {
        comment: `The direction in which to order the ${name} nodes by the specified field`,
        type: { OrderDirection },
      },
      ...(modelEnum
        ? {
            model: {
              comment: 'The associated model whose field to order by',
              type: { [`${name}SortAssociation`]: modelEnum },
              optional: true,
            },
          }
        : {}),
    },
    isOptional: true,
    isList: true,
  } as const;

  // return configured schema
  const responseAny = {
    ...schema,
    default: [
      {
        field: Object.values(orderFieldEnum)[0],
        direction: OrderDirection.DESC,
      },
    ],
    optional: makeSchemaOptional(schema as any),
  } as any;
  const response: Omit<
    Schema<string, OrderFields, 'input', true, true>,
    'list'
  > = responseAny;
  const list = (): Schema<string, OrderFields, 'input', true, true> => ({
    ...response,
    list,
  });
  return {
    ...response,
    list,
  };
}

/**
 * Define a GraphQL mutation schema definition that can be used for type inference
 *
 * @param input - The GraphQL mutation route input
 * @returns The mutation configuration
 */
export function mkMutation<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse,
>(
  input: MutationEndpointInput<TName, TParams, TResult>,
): MutationEndpoint<TName, TParams, TResult> {
  return {
    ...input,
    method: GraphQLMethod.Mutation,
  };
}

/**
 * Define a GraphQL query schema definition that can be used for type inference
 *
 * @param input - The GraphQL query route input
 * @returns The query configuration
 */
export function mkQuery<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
>(
  input: QueryEndpointInput<TName, TParams, TResult>,
): QueryEndpoint<TName, TParams, TResult> {
  return {
    ...input,
    method: GraphQLMethod.Query,
  };
}

/**
 * Creates a new index, main purpose is to override @types/sequelize
 *
 * @param enm - The object to apply the function to
 * @param applyFunc - The function to apply
 * @returns A set of apply functions that will enforce association types without casting their underlying values
 */
export function applyFieldsEnum<
  TEnum extends string,
  TOutput extends SchemaField,
>(
  enm: { [k in string]: TEnum },
  applyFunc: (
    value: TEnum,
    key: TEnum,
    fullObj: typeof enm,
    index: number,
  ) => TOutput,
): { [key in TEnum]: TOutput } {
  return applyEnum(enm, applyFunc);
}

/**
 * Creates a new index, main purpose is to override @types/sequelize
 *
 * @param obj - The object to apply the function to
 * @param applyFunc - The function to apply
 * @returns A set of apply functions that will enforce association types without casting their underlying values
 */
export function applyFields<
  TInput extends ObjByString,
  TOutput extends SchemaField,
>(
  obj: TInput,
  applyFunc: (
    value: TInput[keyof TInput],
    key: StringKeys<TInput>,
    fullObj: typeof obj,
    index: number,
  ) => TOutput,
): { [key in keyof TInput]: TOutput } {
  return apply(obj, applyFunc);
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CaretDouble: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 9.3 4.3 L 5.8 0.8 L 2.3 4.3"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M 2.3 7.7 L 5.8 11.2 L 9.3 7.7"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

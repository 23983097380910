/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const NodeTriangle: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2251 6.45176V6.45176C9.2251 4.91976 10.4671 3.67676 12.0001 3.67676V3.67676C13.5321 3.67676 14.7751 4.91876 14.7751 6.45176V6.45176C14.7751 7.98376 13.5331 9.22676 12.0001 9.22676V9.22676C10.4671 9.22476 9.2251 7.98276 9.2251 6.45176Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 17.5479V17.5479C2.5 16.0159 3.742 14.7729 5.275 14.7729V14.7729C6.807 14.7729 8.05 16.0149 8.05 17.5479V17.5479C8.049 19.0799 6.808 20.3229 5.275 20.3229V20.3229C3.742 20.3229 2.5 19.0809 2.5 17.5479Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9509 17.5479V17.5479C15.9509 16.0159 17.1929 14.7729 18.7259 14.7729V14.7729C20.2579 14.7729 21.5009 16.0149 21.5009 17.5479V17.5479C21.5009 19.0799 20.2589 20.3229 18.7259 20.3229V20.3229C17.1919 20.3229 15.9509 19.0809 15.9509 17.5479H15.9509Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.48998 11.9199L6.47998 15.0599"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 8.77979L15.51 11.9198"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.95 17.5498H12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import { ObjByString } from '@transcend-io/type-utils';

import { TokenInput } from '@main/access-control-types';
import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

export const AssessmentAnswerInterface = mkInterface({
  name: 'AssessmentAnswerInterface',
  comment: 'Base fields for answers to an assessment question.',
  fields: {
    id: {
      comment: 'The id of the assessment answer',
      type: 'id',
      modelName: 'assessmentAnswer',
    },
    index: {
      comment: 'The index of the assessment answer',
      type: 'int',
    },
    value: {
      comment: 'The value to be displayed for this assessment answer',
      type: 'string',
    },
    presignedUrl: {
      comment: 'The presigned AWS url for where the document answer is stored',
      type: 'string',
      optional: true,
    },
    isUserCreated: {
      comment: 'Whether the answer option was manually created by the user',
      type: 'boolean',
    },
  },
});

/** Override type */
export type AssessmentAnswerInterface = SchemaToType<
  typeof AssessmentAnswerInterface
>;

export const AssessmentAnswer = mkType({
  name: 'AssessmentAnswer',
  comment: 'A possible answer to an assessment question.',
  interfaces: [AssessmentAnswerInterface],
  fields: AssessmentAnswerInterface.fields,
});

/** Override type */
export type AssessmentAnswer = SchemaToType<typeof AssessmentAnswer>;

export const SelectedAssessmentAnswerRaw = mkType({
  name: 'SelectedAssessmentAnswerRaw',
  comment: 'A selected answer to an assessment question.',
  interfaces: [AssessmentAnswerInterface],
  fields: {
    ...AssessmentAnswerInterface.fields,
    additionalContext: {
      comment:
        'Additional context necessary to display answers that were dynamically populated from the database',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SelectedAssessmentAnswerRaw = SchemaToType<
  typeof SelectedAssessmentAnswerRaw
>;

/** Type of the full, parsed AssessmentAnswer; raw and full types are helpful when using `buildUseTransformQuery` */
export type SelectedAssessmentAnswer = Omit<
  SelectedAssessmentAnswerRaw,
  'additionalContext'
> & {
  /**
   Additional context necessary to display answers that were dynamically populated from the database.
   Technically it can be null not undefined, but using undefined matches how other optional GQL fields are typed
   as undefined but will be populated as null.
   */
  additionalContext?: ObjByString;
};

export const AssessmentAnswerOptionInput = mkInput({
  name: 'AssessmentAnswerOptionInput',
  comment: 'Input for creating an assessment answer option in a template',
  fields: {
    value: AssessmentAnswer.fields.value,
  },
});

/** Override type */
export type AssessmentAnswerOptionInput = SchemaToType<
  typeof AssessmentAnswerOptionInput
>;

export const AssessmentAnswerInput = mkInput({
  name: 'AssessmentAnswerInput',
  comment: 'Input for creating an assessment answer',
  fields: {
    value: AssessmentAnswer.fields.value,
    isUserCreated: AssessmentAnswer.fields.isUserCreated,
  },
});

/** Override type */
export type AssessmentAnswerInput = SchemaToType<typeof AssessmentAnswerInput>;

export const SelectAssessmentQuestionAnswerInput = mkInput({
  name: 'SelectAssessmentQuestionAnswerInput',
  comment: 'Input for selecting assessment answers within a question',
  fields: {
    assessmentQuestionId: {
      comment:
        'The id of the assessment question whose answers we want to select',
      type: 'id',
      modelName: 'assessmentQuestion',
    },
    assessmentAnswerIds: {
      comment: 'The id of the assessment answers to select',
      type: 'id',
      modelName: 'assessmentAnswer',
      list: true,
      optional: true,
    },
    assessmentAnswerValues: {
      comment: 'The values of the assessment answer to create and select',
      type: AssessmentAnswerInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type SelectAssessmentQuestionAnswerInput = SchemaToType<
  typeof SelectAssessmentQuestionAnswerInput
>;

export const DeleteAssessmentAnswerFilesInput = mkInput({
  name: 'DeleteAssessmentAnswerFilesInput',
  comment: 'Input for deleting multiple assessment answer files',
  fields: {
    ids: {
      comment: 'The ids of the assessment answers to delete',
      type: 'id',
      modelName: 'assessmentAnswer',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentAnswerFilesInput = SchemaToType<
  typeof DeleteAssessmentAnswerFilesInput
>;

export const DeleteAssessmentAnswerFilesTokenInput = mkInput({
  name: 'DeleteAssessmentAnswerFilesTokenInput',
  comment:
    'Input for external users to delete multiple assessment answer files via an auth token',
  fields: {
    ids: DeleteAssessmentAnswerFilesInput.fields.ids,
    token: TokenInput.fields.token,
  },
});

/** Override type */
export type DeleteAssessmentAnswerFilesTokenInput = SchemaToType<
  typeof DeleteAssessmentAnswerFilesTokenInput
>;

export const SelectAssessmentQuestionAnswersTokenInput = mkInput({
  name: 'SelectAssessmentQuestionAnswersTokenInput',
  comment: 'Input for selecting assessment answers within a question',
  fields: {
    assessmentQuestionId:
      SelectAssessmentQuestionAnswerInput.fields.assessmentQuestionId,
    assessmentAnswerIds:
      SelectAssessmentQuestionAnswerInput.fields.assessmentAnswerIds,
    assessmentAnswerValues:
      SelectAssessmentQuestionAnswerInput.fields.assessmentAnswerValues,
    token: TokenInput.fields.token,
  },
});

/** Override type */
export type SelectAssessmentQuestionAnswersTokenInput = SchemaToType<
  typeof SelectAssessmentQuestionAnswersTokenInput
>;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const OrderedList: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M2.33958 3.23817C2.33958 2.92242 2.60133 2.66669 2.87926 2.66669H3.59884C3.91815 2.66669 4.13852 2.92242 4.13852 3.23817V6.09461H4.49831C4.81762 6.09461 5.03799 6.35178 5.03799 6.66609C5.03799 6.98279 4.81762 7.23757 4.49831 7.23757H2.69937C2.42144 7.23757 2.15969 6.98279 2.15969 6.66609C2.15969 6.35178 2.42144 6.09461 2.69937 6.09461H3.05916V3.80965H2.87926C2.60133 3.80965 2.33958 3.55367 2.33958 3.23817ZM3.76974 10.0283C3.62133 9.85209 3.35823 9.864 3.22893 10.0569L2.97888 10.426C2.80573 10.6831 2.46866 10.7426 2.22603 10.5593C1.98339 10.3759 1.9274 10.0188 2.10055 9.76399L2.3506 9.39253C2.88444 8.5996 3.97662 8.54245 4.58151 9.27585C5.06048 9.83542 5.04923 10.726 4.55677 11.2927L3.77199 12.1904H4.51855C4.81762 12.1904 5.05823 12.4452 5.05823 12.7619C5.05823 13.0786 4.81762 13.3334 4.51855 13.3334H2.53972C2.32542 13.3334 2.13136 13.2 2.04546 12.9905C1.95933 12.7833 1.99779 12.5404 2.14305 12.3737L3.76299 10.5164C3.88217 10.3783 3.88442 10.1688 3.76974 10.0283ZM12.6138 3.42771C13.0118 3.42771 13.3333 3.76893 13.3333 4.18968C13.3333 4.61115 13.0118 4.95165 12.6138 4.95165H6.85716C6.45915 4.95165 6.13759 4.61115 6.13759 4.18968C6.13759 3.76893 6.45915 3.42771 6.85716 3.42771H12.6138ZM12.6138 7.23757C13.0118 7.23757 13.3333 7.57808 13.3333 7.99954C13.3333 8.42101 13.0118 8.76152 12.6138 8.76152H6.85716C6.45915 8.76152 6.13759 8.42101 6.13759 7.99954C6.13759 7.57808 6.45915 7.23757 6.85716 7.23757H12.6138ZM12.6138 11.0474C13.0118 11.0474 13.3333 11.3879 13.3333 11.8094C13.3333 12.2309 13.0118 12.5714 12.6138 12.5714H6.85716C6.45915 12.5714 6.13759 12.2309 6.13759 11.8094C6.13759 11.3879 6.45915 11.0474 6.85716 11.0474H12.6138Z"
      fill={color}
    />
  </svg>
);

import styled from 'styled-components';

import { responsive } from '@main/theme';

/**
 * Error text wrapper props
 */
export interface ErrorTextWrapperProps {
  /** font size of wrapper */
  fontSize?: number;
  /** Display styles */
  display?: string;
  /** is the text bold */
  isBold?: boolean;
  /** should the text have a margin */
  hasMargin?: boolean;
  /** should the text inherit its color */
  inheritColor?: boolean;
}

/**
 * The ErrorText wrapper
 */
export const FormattedErrorWrapper = styled.div<ErrorTextWrapperProps>`
  color: ${({ theme, inheritColor }) =>
    inheritColor ? 'inherit' : theme.colors.error};
  word-break: break-word;
  white-space: pre-wrap;

  ${({ isBold }) => isBold && 'font-weight: 700;'}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ display }) => display && `display: ${display};`}
  ${({ hasMargin }) =>
    hasMargin &&
    `margin: 40px;
  ${responsive.atLeastTablet} {
    margin: 60px;
  }`}
`;

export const StyledExpandButton = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 12px;
  margin: 0.5em 0;
  color: inherit;
  cursor: pointer;
`;

import React from 'react';
import ProgressBar, {
  ProgressBarProps as BootstrapProgressBarProps,
} from 'react-bootstrap/ProgressBar';
import { MessageDescriptor, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Icon, IconType } from '../Icon';
import { FlexRowCenterVertical, StyleUtils } from '../StyledWrappers';
import { StyledValue } from './wrappers';

export interface ProgressProps
  extends Partial<Pick<BootstrapProgressBarProps, 'variant'>> {
  /** the current value (defaults to 0) */
  value?: number;
  /** the min value (defaults to 0) */
  min?: number;
  /** the max value (defaults to 100) */
  max?: number;
  /** should we show a fraction (2/5) instead of a percent (40%)? */
  showFraction?: boolean;
  /** hide success/failure icon */
  hideIcon?: boolean;
  /** unit of measure used in fraction representation */
  unit?: MessageDescriptor;
  /** invert progress visualization */
  invertVisualization?: boolean;
}

const StyledProgressBar = styled(ProgressBar)`
  border-radius: 10em;
`;

/**
 * Progress variant
 */
export enum ProgressVariant {
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Default = 'default',
}

const VARIANT_ICONS: Record<ProgressVariant, IconType | undefined> = {
  success: 'checkmark',
  danger: 'close',
  warning: undefined,
  info: undefined,
  default: undefined,
};

/**
 * Progress
 */
export const Progress: React.FC<ProgressProps> = ({
  value,
  max,
  min,
  showFraction,
  variant,
  hideIcon,
  unit,
  invertVisualization = false,
}) => {
  const { formatNumber, formatMessage } = useIntl();
  const maxOrDefault = max || 100;
  const valueOrDefault = value ?? 0;
  const minOrDefault = min ?? 0;
  const isComplete = valueOrDefault === maxOrDefault;

  const icon = VARIANT_ICONS[variant ?? ProgressVariant.Default];

  return (
    <>
      <StyledProgressBar
        min={min}
        max={max}
        now={invertVisualization ? maxOrDefault - valueOrDefault : value}
        variant={
          variant === ProgressVariant.Default
            ? undefined
            : variant ?? (isComplete ? 'success' : undefined)
        }
      />
      <FlexRowCenterVertical
        style={{ color: 'inherit', gap: StyleUtils.Spacing.sm }}
      >
        {showFraction ? (
          <StyledValue style={{ fontSize: 12 }}>
            {`${valueOrDefault}/${maxOrDefault}${
              unit ? ` ${formatMessage(unit)}` : ''
            }`}
          </StyledValue>
        ) : (
          <StyledValue>
            {formatNumber(
              Math.round(
                ((valueOrDefault - minOrDefault) /
                  (maxOrDefault - minOrDefault)) *
                  100,
              ) / 100,
              { style: 'percent' },
            )}
          </StyledValue>
        )}
        {!hideIcon && icon && (
          <Icon type={icon} {...(icon === 'close' ? { size: 10 } : {})} />
        )}
      </FlexRowCenterVertical>
    </>
  );
};

import { mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { UserLoginMethod } from './enums';

export const UserInterface = mkInterface({
  name: 'UserInterface',
  comment: 'An interface for a user',
  fields: {
    id: {
      comment: 'The unique ID of the user',
      modelName: 'user',
      type: 'id',
    },
    email: {
      comment: 'The email address of the user',
      type: 'string',
    },
    name: {
      comment: 'The name of the user',
      type: 'string',
    },
    createdAt: {
      comment: 'The time the user was created',
      type: 'Date',
    },
    loginMethod: {
      comment: 'The method by which that user logs in',
      type: { UserLoginMethod },
    },
    profilePicture: {
      comment: 'The profile picture of the user',
      type: 'string',
    },
    canRevealMultiTenantSombraSecret: {
      comment: 'Indicates if user can reveal multi tenant sombra secret',
      type: 'boolean',
    },
  },
});

/** Override type */
export type UserInterface = SchemaToType<typeof UserInterface>;

export const UserPreview = mkType({
  name: 'UserPreview',
  comment: 'A preview of a user, used during joins',
  interfaces: [UserInterface],
  fields: {
    ...UserInterface.fields,
  },
});

/** Override type */
export type UserPreview = SchemaToType<typeof UserPreview>;

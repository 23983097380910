import React from 'react';

import { SvgProps } from '../enums';

export const FlagCV: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54980"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54980)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#4141DB"
      />
      <mask
        id="mask1_270_54980"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54980)">
        <path
          d="M0 6.5H-0.5V7V8V8.5H0H16H16.5V8V7V6.5H16H0Z"
          fill="#F90000"
          stroke="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 11C7.433 11 9 9.433 9 7.5C9 5.567 7.433 4 5.5 4C3.567 4 2 5.567 2 7.5C2 9.433 3.567 11 5.5 11Z"
          stroke="#FFDE00"
        />
      </g>
    </g>
  </svg>
);

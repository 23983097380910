import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ChangesRequested: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M14 7.14198V12.6593C14 13.4 13.4193 14 12.7027 14H3.29733C2.58067 14 2 13.4 2 12.66V4.67331C2 3.93331 2.58067 3.33331 3.29733 3.33331H6.86467"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.0413 2.33668C12.4893 1.88868 13.216 1.88801 13.664 2.33668V2.33668C14.112 2.78468 14.112 3.51134 13.6633 3.95934L9.814 7.80534C9.68933 7.93001 9.52 8.00001 9.34333 8.00001H8V6.65668C8 6.48001 8.07 6.31068 8.19533 6.18534L12.0413 2.33668V2.33668Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33333 8.00002H4"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 10.6666H12"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9535 4.66669L11.3335 3.04669"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-disable max-lines */
import {
  AssessmentQuestionSubType,
  AssessmentQuestionType,
  AssessmentSyncModel,
  BusinessEntityAttributeSyncColumn,
  DataSiloAttributeSyncColumn,
  DataSubCategoryAttributeSyncColumn,
  ProcessingPurposeSubCategoryAttributeSyncColumn,
  SubDataPointAttributeSyncColumn,
  VendorAttributeSyncColumn,
} from '@transcend-io/privacy-types';

import {
  DynamicAssessmentQuestionSubType,
  StaticAssessmentQuestionSubType,
} from '../schema/enums';

export const SYNC_MODEL_TO_SUB_TYPE: Record<
  AssessmentSyncModel,
  AssessmentQuestionSubType | null
> = {
  [AssessmentSyncModel.DataSilo]: AssessmentQuestionSubType.DataSilo,
  [AssessmentSyncModel.BusinessEntity]:
    AssessmentQuestionSubType.BusinessEntity,
  [AssessmentSyncModel.DataSubCategory]:
    AssessmentQuestionSubType.DataSubCategory,
  [AssessmentSyncModel.ProcessingPurposeSubCategory]:
    AssessmentQuestionSubType.ProcessingPurposeSubCategory,
  [AssessmentSyncModel.Vendor]: AssessmentQuestionSubType.Vendor,
  [AssessmentSyncModel.SubDataPoint]: null,
};

interface AssessmentSyncModelToSyncColumn {
  /** The columns of a BusinessEntity that a question can sync to */
  [AssessmentSyncModel.BusinessEntity]: BusinessEntityAttributeSyncColumn[];
  /** The columns of a DataSilo that a question can sync to */
  [AssessmentSyncModel.DataSilo]: DataSiloAttributeSyncColumn[];
  /** The columns of a DataSubCategory that a question can sync to */
  [AssessmentSyncModel.DataSubCategory]: DataSubCategoryAttributeSyncColumn[];
  /** The columns of a ProcessingPurposeSubCategory that a question can sync to */
  [AssessmentSyncModel.ProcessingPurposeSubCategory]: ProcessingPurposeSubCategoryAttributeSyncColumn[];
  /** The columns of a SubDataPoint that a question can sync to */
  [AssessmentSyncModel.SubDataPoint]: SubDataPointAttributeSyncColumn[];
  /** The columns of a Vendor that a question can sync to */
  [AssessmentSyncModel.Vendor]: VendorAttributeSyncColumn[];
}

/**
 * A map from an assessment text question type to the columns of a
 * data inventory table that the question can sync back to.
 */
export const ASSESSMENT_TEXT_QUESTION_TYPE_TO_SYNC_COLUMNS: Record<
  'SHORT_ANSWER_TEXT' | 'LONG_ANSWER_TEXT',
  AssessmentSyncModelToSyncColumn
> = {
  [AssessmentQuestionType.ShortAnswer]: {
    [AssessmentSyncModel.BusinessEntity]: [
      BusinessEntityAttributeSyncColumn.title,
      BusinessEntityAttributeSyncColumn.description,
      BusinessEntityAttributeSyncColumn.dataProtectionOfficerName,
      BusinessEntityAttributeSyncColumn.address,
    ],
    [AssessmentSyncModel.DataSilo]: [
      DataSiloAttributeSyncColumn.title,
      DataSiloAttributeSyncColumn.description,
      DataSiloAttributeSyncColumn.contactName,
      DataSiloAttributeSyncColumn.notes,
      DataSiloAttributeSyncColumn.subdomain,
    ],
    [AssessmentSyncModel.DataSubCategory]: [
      DataSubCategoryAttributeSyncColumn.name,
      DataSubCategoryAttributeSyncColumn.description,
    ],
    [AssessmentSyncModel.ProcessingPurposeSubCategory]: [
      ProcessingPurposeSubCategoryAttributeSyncColumn.name,
      ProcessingPurposeSubCategoryAttributeSyncColumn.description,
    ],
    [AssessmentSyncModel.SubDataPoint]: [
      SubDataPointAttributeSyncColumn.name,
      SubDataPointAttributeSyncColumn.description,
    ],
    [AssessmentSyncModel.Vendor]: [
      VendorAttributeSyncColumn.title,
      VendorAttributeSyncColumn.description,
      VendorAttributeSyncColumn.contactName,
      VendorAttributeSyncColumn.address,
    ],
  },
  [AssessmentQuestionType.LongAnswer]: {
    [AssessmentSyncModel.BusinessEntity]: [
      BusinessEntityAttributeSyncColumn.description,
      BusinessEntityAttributeSyncColumn.address,
    ],
    [AssessmentSyncModel.DataSilo]: [
      DataSiloAttributeSyncColumn.description,
      DataSiloAttributeSyncColumn.notes,
    ],
    [AssessmentSyncModel.DataSubCategory]: [
      DataSubCategoryAttributeSyncColumn.description,
    ],
    [AssessmentSyncModel.ProcessingPurposeSubCategory]: [
      ProcessingPurposeSubCategoryAttributeSyncColumn.description,
    ],
    [AssessmentSyncModel.SubDataPoint]: [
      SubDataPointAttributeSyncColumn.description,
    ],
    [AssessmentSyncModel.Vendor]: [
      VendorAttributeSyncColumn.description,
      VendorAttributeSyncColumn.address,
    ],
  },
};

/**
 * A map from an assessment multi-select and single-select question types
 * to the data inventory columns that the question can sync back to. This
 * is used for the StaticAssessmentQuestionSubTypes that require specifying
 * a column in the data inventory model to sync back to.
 */
export const ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_COLUMNS: Record<
  'MULTI_SELECT' | 'SINGLE_SELECT',
  Record<
    StaticAssessmentQuestionSubType,
    Partial<AssessmentSyncModelToSyncColumn>
  >
> = {
  [AssessmentQuestionType.MultiSelect]: {
    // Important: never allow entries where the syncColumn is not a list
    // of subTypes since multi-select mean choosing many subTypes.
    [AssessmentQuestionSubType.Region]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.transferRegions,
      ],
    },
    [AssessmentQuestionSubType.DataProcessingAgreementStatus]: {},
    [AssessmentQuestionSubType.DeprecationState]: {},
    [AssessmentQuestionSubType.RecommendedForConsent]: {},
    [AssessmentQuestionSubType.RecommendedForPrivacy]: {},
    [AssessmentQuestionSubType.RetentionType]: {},
    [AssessmentQuestionSubType.Controllership]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.controllerships,
      ],
    },
    [AssessmentQuestionSubType.HasPersonalData]: {},
  },
  [AssessmentQuestionType.SingleSelect]: {
    [AssessmentQuestionSubType.Region]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.transferRegions,
        DataSiloAttributeSyncColumn.country,
      ],
      [AssessmentSyncModel.Vendor]: [
        VendorAttributeSyncColumn.headquarterCountry,
      ],
    },
    [AssessmentQuestionSubType.DataProcessingAgreementStatus]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.dataProcessingAgreementStatus,
      ],
      [AssessmentSyncModel.Vendor]: [
        VendorAttributeSyncColumn.dataProcessingAgreementStatus,
      ],
    },
    [AssessmentQuestionSubType.DeprecationState]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.deprecationState,
      ],
    },
    [AssessmentQuestionSubType.RecommendedForConsent]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.recommendedForConsent,
      ],
    },
    [AssessmentQuestionSubType.RecommendedForPrivacy]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.recommendedForPrivacy,
      ],
    },
    [AssessmentQuestionSubType.RetentionType]: {
      [AssessmentSyncModel.SubDataPoint]: [
        SubDataPointAttributeSyncColumn.retentionType,
      ],
    },
    [AssessmentQuestionSubType.Controllership]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.controllerships,
      ],
      [AssessmentSyncModel.SubDataPoint]: [
        SubDataPointAttributeSyncColumn.controllership,
      ],
    },
    [AssessmentQuestionSubType.HasPersonalData]: {
      [AssessmentSyncModel.DataSilo]: [
        DataSiloAttributeSyncColumn.hasPersonalData,
      ],
    },
  },
};

/**
 * A map from an assessment multi-select and single-select question types
 * to the data inventory models that the question can sync back to. This
 * is used for the DynamicAssessmentQuestionSubType that do require specifying
 * a column in the data inventory model to sync back to.
 */
export const ASSESSMENT_SELECT_QUESTION_TYPE_TO_SYNC_MODELS: Record<
  'MULTI_SELECT' | 'SINGLE_SELECT',
  Record<DynamicAssessmentQuestionSubType, AssessmentSyncModel[]>
> = {
  [AssessmentQuestionType.MultiSelect]: {
    // Important: never allow entries where the syncModel belongs to the subType,
    // as there can be at most one subType per sycModel, but multi-select means
    // choosing many subTypes.
    [AssessmentQuestionSubType.DataSubCategory]: [
      // the dataSilo belongs to many dataSubCategories
      AssessmentSyncModel.DataSilo,
      // the subDataPoint belongs to many dataSubCategories
      AssessmentSyncModel.SubDataPoint,
    ],
    [AssessmentQuestionSubType.User]: [
      // a dataSilo belongs to many users
      AssessmentSyncModel.DataSilo,
      // the vendor belongs to many users
      AssessmentSyncModel.Vendor,
      // the data sub categories belong to many users
      AssessmentSyncModel.DataSubCategory,
      // the processing purpose sub categories belong to many users
      AssessmentSyncModel.ProcessingPurposeSubCategory,
      // the business entities belong to many users
      AssessmentSyncModel.BusinessEntity,
    ],
    [AssessmentQuestionSubType.Team]: [
      // a dataSilo belongs to many teams
      AssessmentSyncModel.DataSilo,
      // the vendor belongs to many teams
      AssessmentSyncModel.Vendor,
      // the data sub categories belong to many users
      AssessmentSyncModel.DataSubCategory,
      // the processing purpose sub categories belong to many teams
      AssessmentSyncModel.ProcessingPurposeSubCategory,
      // the business entities belong to many teams
      AssessmentSyncModel.BusinessEntity,
    ],
    [AssessmentQuestionSubType.ProcessingPurposeSubCategory]: [
      // a dataSilo belongs to many processing purpose subCategories
      AssessmentSyncModel.DataSilo,
      // a sub-dataPoint belongs to many processing purpose subCategories
      AssessmentSyncModel.SubDataPoint,
    ],
    [AssessmentQuestionSubType.BusinessEntity]: [
      // a dataSilo belongs to many business entities
      AssessmentSyncModel.DataSilo,
    ],
    [AssessmentQuestionSubType.Vendor]: [
      // a business entity has many vendors
      AssessmentSyncModel.BusinessEntity,
    ],
    [AssessmentQuestionSubType.SaaSCategory]: [
      // a dataSilo belongs to many SaaSCategories
      AssessmentSyncModel.DataSilo,
    ],
    [AssessmentQuestionSubType.Identifier]: [
      // a dataSilo belongs to many identifiers
      AssessmentSyncModel.DataSilo,
    ],
    [AssessmentQuestionSubType.DataSilo]: [
      /**
       * Although a dataSilo is related to dataPoints and subDataPoints
       * we do not allow syncing them to the silo table via assessments
       * to avoid bugs where a subDataPoint is linked to the silo but the
       * parent dataPoint is not and vice-versa.
       */
      // the vendor has many dataSilos
      AssessmentSyncModel.Vendor,
      // the dataSubCategory belongs to many dataSilos
      AssessmentSyncModel.DataSubCategory,
      // the processingPurposeSubCategory belongs to many dataSilos
      AssessmentSyncModel.ProcessingPurposeSubCategory,
      // the businessEntity belongs to many dataSilos
      AssessmentSyncModel.BusinessEntity,
      // TODO: https://transcend.height.app/T-36965 - support senderDataSilo and receiverDataSilo
    ],
    [AssessmentQuestionSubType.Subject]: [
      // the dataSilo belongs to many subjects
      AssessmentSyncModel.DataSilo,
      // the subDataPoint belongs to many subjects
      AssessmentSyncModel.SubDataPoint,
    ],
    [AssessmentQuestionSubType.AttributeKey]: [
      /** The attributeKey can be enabled on a dataSilo */
      AssessmentSyncModel.DataSilo,
      /** The attributeKey can be enabled on a subDataPoint */
      AssessmentSyncModel.SubDataPoint,
      /** The attributeKey can be enabled on a vendor */
      AssessmentSyncModel.Vendor,
      /** The attributeKey can be enabled on a businessEntity */
      AssessmentSyncModel.BusinessEntity,
      /** The attributeKey can be enabled on a dataSubCategory */
      AssessmentSyncModel.DataSubCategory,
      /** The attributeKey can be enabled on a processingPurposeSubCategory */
      AssessmentSyncModel.ProcessingPurposeSubCategory,
    ],
  },
  [AssessmentQuestionType.SingleSelect]: {
    [AssessmentQuestionSubType.DataSubCategory]: [
      // the dataSilo belongs to many dataSubCategories
      AssessmentSyncModel.DataSilo,
      // the subDataPoint belongs to many dataSubCategories
      AssessmentSyncModel.SubDataPoint,
    ],
    [AssessmentQuestionSubType.User]: [
      // a dataSilo belongs to many users
      AssessmentSyncModel.DataSilo,
      // the vendor belongs to many users
      AssessmentSyncModel.Vendor,
      // the data sub categories belong to many users
      AssessmentSyncModel.DataSubCategory,
      // the processing purpose sub categories belong to many users
      AssessmentSyncModel.ProcessingPurposeSubCategory,
      // the business entities belong to many users
      AssessmentSyncModel.BusinessEntity,
    ],
    [AssessmentQuestionSubType.Team]: [
      // a dataSilo belongs to many teams
      AssessmentSyncModel.DataSilo,
      // the vendor belongs to many teams
      AssessmentSyncModel.Vendor,
      // the data sub categories belong to many users
      AssessmentSyncModel.DataSubCategory,
      // the processing purpose sub categories belong to many teams
      AssessmentSyncModel.ProcessingPurposeSubCategory,
      // the business entities belong to many teams
      AssessmentSyncModel.BusinessEntity,
    ],
    [AssessmentQuestionSubType.ProcessingPurposeSubCategory]: [
      // a dataSilo belongs to many processing purpose subCategories
      AssessmentSyncModel.DataSilo,
      // a sub-dataPoint belongs to many processing purpose subCategories
      AssessmentSyncModel.SubDataPoint,
    ],
    [AssessmentQuestionSubType.BusinessEntity]: [
      // a dataSilo belongs to many business entities
      AssessmentSyncModel.DataSilo,
      // a vendor belongs to one business entity
      AssessmentSyncModel.Vendor,
    ],
    [AssessmentQuestionSubType.Vendor]: [
      // the dataSilo belongs to one vendor
      AssessmentSyncModel.DataSilo,
      // a business entity has many vendors
      AssessmentSyncModel.BusinessEntity,
    ],
    [AssessmentQuestionSubType.SaaSCategory]: [
      // a dataSilo belongs to many SaaSCategories
      AssessmentSyncModel.DataSilo,
    ],
    [AssessmentQuestionSubType.Identifier]: [
      // a dataSilo belongs to many identifiers
      AssessmentSyncModel.DataSilo,
      // the data sub categories belongs to identifiers
      AssessmentSyncModel.DataSubCategory,
    ],
    [AssessmentQuestionSubType.DataSilo]: [
      /**
       * Although a dataSilo is related to dataPoints and subDataPoints
       * we do not allow syncing them to the silo table via assessments
       * to avoid bugs where a subDataPoint is linked to the silo but the
       * parent dataPoint is not and vice-versa.
       */
      // the vendor has many dataSilos
      AssessmentSyncModel.Vendor,
      // the dataSubCategory belongs to many dataSilos
      AssessmentSyncModel.DataSubCategory,
      // the processingPurposeSubCategory belongs to many dataSilos
      AssessmentSyncModel.ProcessingPurposeSubCategory,
      // the businessEntity belongs to many dataSilos
      AssessmentSyncModel.BusinessEntity,
      // TODO: https://transcend.height.app/T-36965 - support senderDataSilo and receiverDataSilo
    ],
    [AssessmentQuestionSubType.Subject]: [
      // the dataSilo belongs to many subjects
      AssessmentSyncModel.DataSilo,
      // the subDataPoint belongs to many subjects
      AssessmentSyncModel.SubDataPoint,
    ],
    [AssessmentQuestionSubType.AttributeKey]: [
      /** The attributeKey can be enabled on a dataSilo */
      AssessmentSyncModel.DataSilo,
      /** The attributeKey can be enabled on a subDataPoint */
      AssessmentSyncModel.SubDataPoint,
      /** The attributeKey can be enabled on a vendor */
      AssessmentSyncModel.Vendor,
      /** The attributeKey can be enabled on a businessEntity */
      AssessmentSyncModel.BusinessEntity,
      /** The attributeKey can be enabled on a dataSubCategory */
      AssessmentSyncModel.DataSubCategory,
      /** The attributeKey can be enabled on a processingPurposeSubCategory */
      AssessmentSyncModel.ProcessingPurposeSubCategory,
    ],
  },
};
/* eslint-enable max-lines */

import React from 'react';

import { SvgProps } from '../enums';

export const FlagBI: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54956"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54956)">
      <rect width="16" height="12" rx="1" fill="#5EAA22" />
      <mask
        id="path-3-outside-1_270_54956"
        maskUnits="userSpaceOnUse"
        x="-4"
        y="-2"
        width="24"
        height="16"
        fill="black"
      >
        <rect fill="white" x="-4" y="-2" width="24" height="16" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 6L16 0H0L8 6ZM8 6L0 12H16L8 6Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6L16 0H0L8 6ZM8 6L0 12H16L8 6Z"
        fill="#DD2C2B"
      />
      <path
        // eslint-disable-next-line max-len
        d="M16 0L16.75 1L19.75 -1.25H16V0ZM0 0V-1.25H-3.75L-0.75 1L0 0ZM0 12L-0.75 11L-3.75 13.25H0V12ZM16 12V13.25H19.75L16.75 11L16 12ZM15.25 -1L7.25 5L8.75 7L16.75 1L15.25 -1ZM0 1.25H16V-1.25H0V1.25ZM8.75 5L0.75 -1L-0.75 1L7.25 7L8.75 5ZM7.25 5L-0.75 11L0.75 13L8.75 7L7.25 5ZM0 13.25H16V10.75H0V13.25ZM16.75 11L8.75 5L7.25 7L15.25 13L16.75 11Z"
        fill="white"
        mask="url(#path-3-outside-1_270_54956)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.0835 5.21436L7.49572 5.52337L7.60797 4.86886L7.13245 4.40534H7.78961L8.0835 3.71436L8.3774 4.40534H9.03456L8.55903 4.86886L8.67129 5.52337L8.0835 5.21436Z"
        fill="#DD2C2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.68104 7.21436L6.09325 7.52337L6.20551 6.86886L5.72998 6.40534H6.38714L6.68104 5.71436L6.97493 6.40534H7.63209L7.15657 6.86886L7.26882 7.52337L6.68104 7.21436Z"
        fill="#DD2C2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9.48109 7.21436L8.8933 7.52337L9.00556 6.86886L8.53003 6.40534H9.18719L9.48109 5.71436L9.77498 6.40534H10.4321L9.95661 6.86886L10.0689 7.52337L9.48109 7.21436Z"
        fill="#DD2C2B"
      />
    </g>
  </svg>
);

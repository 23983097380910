import React from 'react';

import { SvgProps } from '../enums';

export const FlagBR: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54984"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54984)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#009933"
      />
      <mask
        id="mask1_270_54984"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54984)">
        <g filter="url(#filter0_d_270_54984)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.96339 1.85202L14.0643 6.10375L7.8804 10.0856L1.90446 6.02134L7.96339 1.85202Z"
            fill="#FFD221"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.96339 1.85202L14.0643 6.10375L7.8804 10.0856L1.90446 6.02134L7.96339 1.85202Z"
            fill="url(#paint0_linear_270_54984)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8 8.59998C9.38071 8.59998 10.5 7.48069 10.5 6.09998C10.5 4.71926 9.38071 3.59998 8 3.59998C6.61929 3.59998 5.5 4.71926 5.5 6.09998C5.5 7.48069 6.61929 8.59998 8 8.59998Z"
          fill="#2E42A5"
        />
        <mask
          id="mask2_270_54984"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="6"
          height="6"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 8.59998C9.38071 8.59998 10.5 7.48069 10.5 6.09998C10.5 4.71926 9.38071 3.59998 8 3.59998C6.61929 3.59998 5.5 4.71926 5.5 6.09998C5.5 7.48069 6.61929 8.59998 8 8.59998Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_270_54984)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.19 7.285L7.07832 7.34371L7.09965 7.21936L7.0093 7.13129L7.13416 7.11314L7.19 7L7.24584 7.11314L7.3707 7.13129L7.28035 7.21936L7.30168 7.34371L7.19 7.285Z"
            fill="#F7FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.19 7.285L8.07832 7.34371L8.09965 7.21936L8.0093 7.13129L8.13416 7.11314L8.19 7L8.24584 7.11314L8.3707 7.13129L8.28035 7.21936L8.30168 7.34371L8.19 7.285Z"
            fill="#F7FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.19 7.88498L8.07832 7.94369L8.09965 7.81933L8.0093 7.73126L8.13416 7.71312L8.19 7.59998L8.24584 7.71312L8.3707 7.73126L8.28035 7.81933L8.30168 7.94369L8.19 7.88498Z"
            fill="#F7FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.69 5.785L7.57832 5.84371L7.59965 5.71936L7.5093 5.63129L7.63416 5.61314L7.69 5.5L7.74584 5.61314L7.8707 5.63129L7.78035 5.71936L7.80168 5.84371L7.69 5.785Z"
            fill="#F7FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.69 6.785L7.57832 6.84371L7.59965 6.71936L7.5093 6.63129L7.63416 6.61314L7.69 6.5L7.74584 6.61314L7.8707 6.63129L7.78035 6.71936L7.80168 6.84371L7.69 6.785Z"
            fill="#F7FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.99005 6.285L6.87837 6.34371L6.8997 6.21936L6.80935 6.13129L6.93421 6.11314L6.99005 6L7.04589 6.11314L7.17075 6.13129L7.0804 6.21936L7.10173 6.34371L6.99005 6.285Z"
            fill="#F7FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.28998 6.68502L6.1783 6.74374L6.19963 6.61938L6.10927 6.53131L6.23414 6.51317L6.28998 6.40002L6.34582 6.51317L6.47068 6.53131L6.38033 6.61938L6.40165 6.74374L6.28998 6.68502Z"
            fill="#F7FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.59002 4.98501L8.47835 5.04373L8.49967 4.91937L8.40932 4.8313L8.53418 4.81316L8.59002 4.70001L8.64586 4.81316L8.77073 4.8313L8.68037 4.91937L8.7017 5.04373L8.59002 4.98501Z"
            fill="#F7FCFF"
          />
          <path
            // eslint-disable-next-line max-len
            d="M4.9624 5.49857L5.03759 4.5014C7.43656 4.6823 9.33001 5.47108 10.6941 6.87419L9.9771 7.57126C8.79481 6.35515 7.13119 5.66211 4.9624 5.49857Z"
            fill="#F7FCFF"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_270_54984"
        x="1.90442"
        y="1.85199"
        width="12.1599"
        height="8.23364"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0313726 0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_270_54984"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_270_54984"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_270_54984"
        x1="16"
        y1="12"
        x2="16"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC600" />
        <stop offset="1" stopColor="#FFDE42" />
      </linearGradient>
    </defs>
  </svg>
);

import type { ColorPalette } from '@main/theme';
import React from 'react';
import { useTheme } from 'styled-components';

import { IWithClassName } from '../types';
import { determineOptimalTextColorForBackground } from '../utils';

export interface BadgeProps extends IWithClassName {
  /** The badge color, restricted to our theme's color palette (or hex for backwards compat) */
  color?: keyof ColorPalette | string;
  /** Ignore the accessible text color default to use light text instead */
  ignoreAccessibilityAndUseLightText?: boolean;
  /** Custom inline styles, i.e. margins */
  style?: React.CSSProperties;
  /** flag to make badge round */
  round?: boolean;
  /** flag to enable overflow */
  overflow?: boolean;
}

/**
 * Badge component, usually used as a tag/pill label
 */
export const Badge: React.FC<BadgeProps> = ({
  color,
  ignoreAccessibilityAndUseLightText,
  style,
  round = false,
  children,
  className,
  overflow = true,
}) => {
  const theme = useTheme();

  // Default to `transcend` blue if no color is provided or if the provided color is not in our theme
  const backgroundColor = color?.startsWith('#')
    ? color
    : theme.colors[color || 'transcend'] || theme.colors.transcend;

  const { optimalColor } = determineOptimalTextColorForBackground({
    backgroundHex: backgroundColor,
    lightTextHex: theme.colors.white,
    darkTextHex: theme.colors.transcendNavy,
  });

  return (
    <span
      className={className}
      style={{
        fontSize: 12,
        padding: '2px 8px',
        minWidth: 'min-content',
        ...(overflow && { overflow: 'hidden' }),
        textAlign: 'center',
        // Place user styles before defaults for more consistent styling
        ...style,
        borderRadius: round ? 200 : 4,
        fontWeight: 600,
        backgroundColor,
        color: ignoreAccessibilityAndUseLightText
          ? theme.colors.white
          : optimalColor,
      }}
    >
      {children}
    </span>
  );
};

import { IsoCountryCode } from '@transcend-io/privacy-types';

import { mkInterface, mkType, SchemaToType } from '@main/schema-utils';

export const BusinessEntityInterface = mkInterface({
  name: 'BusinessEntityInterface',
  comment: 'An interface for a business entity',
  fields: {
    id: {
      comment: 'The unique ID of the business entity',
      modelName: 'businessEntity',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the business entity was created',
      type: 'Date',
    },
    title: {
      comment: 'The title of the business entity',
      type: 'string',
    },
    slug: {
      comment: 'The slug of the business entity for templating',
      type: 'string',
    },
    description: {
      comment: 'The description of the business entity',
      type: 'string',
    },
    dataProtectionOfficerEmail: {
      comment: 'The email address of the DPO',
      optional: true,
      type: 'string',
    },
    dataProtectionOfficerName: {
      comment: 'The name of the DPO',
      optional: true,
      type: 'string',
    },
    address: {
      comment: 'Address of entity',
      type: 'string',
    },
    headquarterCountry: {
      comment: 'The country code of the headquarters location of the entity',
      optional: true,
      type: { IsoCountryCode },
    },
    headquarterSubDivision: {
      comment: 'ISO country where business entity headquarters is located',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type BusinessEntityInterface = SchemaToType<
  typeof BusinessEntityInterface
>;

export const BusinessEntityPreview = mkType({
  name: 'BusinessEntityPreview',
  comment: 'A preview of a business entity',
  interfaces: [BusinessEntityInterface],
  fields: {
    ...BusinessEntityInterface.fields,
  },
});

/** Override type */
export type BusinessEntityPreview = SchemaToType<typeof BusinessEntityPreview>;

import uniq from 'lodash/uniq';

const EMAIL_EXTRA_WEIRD_CHARACTERS = /([/])/g;

/**
 * Gets the extra weird characters in an email address. Almost everything is fine
 * apparently, there's really just a few characters that are widely unsupported
 *
 * @param email - the email to validate
 * @returns the list of invalid characters in the email address
 */
export const getInvalidEmailCharacters = (email: string): string[] =>
  uniq(
    Array.from(email.matchAll(EMAIL_EXTRA_WEIRD_CHARACTERS)).map(
      ([char]) => char,
    ),
  );

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ExclamationCircle: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 5V5C28.285 5 35 11.715 35 20V20C35 28.285 28.285 35 20 35V35C11.715 35 5 28.285 5 20V20C5 11.715 11.715 5 20 5Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9998 20.8333V12.5"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M19.9982 26.6667C19.7682 26.6667 19.5815 26.8533 19.5832 27.0833C19.5832 27.3133 19.7698 27.5 19.9998 27.5C20.2298 27.5 20.4165 27.3133 20.4165 27.0833C20.4165 26.8533 20.2298 26.6667 19.9982 26.6667"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { FIXED_COLORS } from '@main/theme';
import React, { useEffect, useRef, useState } from 'react';
import { animated, easings, useSpring, useSpringRef } from 'react-spring';
import UAParser from 'ua-parser-js';

import { SpinnerContainer } from './wrappers';

const MAX_SPINNER_PATH_LENGTH = Math.PI * 100; // radius is half of svg canvas
const LOOP_DURATION = 750;
const isSafari = new UAParser().getResult().browser.name === 'Safari';

export interface LoadingSpinnerProps {
  /**  when true spinner will be 15x15 otherwise 40x40 */
  small?: boolean;
  /** used to determine if spinner should be dark or light */
  colorVariant?: 'dark' | 'light';
  /** override to a specific pixel size */
  size?: number;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  small,
  colorVariant = 'dark',
  size,
}) => {
  const isMounted = useRef(true);
  const [firstIsGrowing, setFirstIsGrowing] = useState(true);
  const [secondIsGrowing, setSecondIsGrowing] = useState(true);

  // this function exists so we don't call set functions after
  // component has dismounted which throws errors
  const safelySetIsGrowing = (
    setter: React.Dispatch<React.SetStateAction<boolean>>,
    newValue: boolean,
  ): void => {
    if (isMounted.current) {
      setter(newValue);
    }
  };

  const firstPathGrowRef = useSpringRef();
  const secondPathGrowRef = useSpringRef();
  const firstPathShrinkRef = useSpringRef();
  const secondPathShrinkRef = useSpringRef();

  const firstPathGrow = useSpring({
    ref: firstPathGrowRef,
    loop: true,
    config: {
      duration: LOOP_DURATION,
      easing: easings.easeOutSine,
    },
    from: {
      strokeDasharray: MAX_SPINNER_PATH_LENGTH,
      strokeDashoffset: -MAX_SPINNER_PATH_LENGTH,
    },
    to: {
      strokeDasharray: MAX_SPINNER_PATH_LENGTH,
      strokeDashoffset: 0,
    },
    onRest: () => {
      safelySetIsGrowing(setSecondIsGrowing, true);
      firstPathGrowRef.pause();
      secondPathGrowRef.resume();
    },
  });

  const secondPathGrow = useSpring({
    ref: secondPathGrowRef,
    loop: true,
    config: {
      duration: LOOP_DURATION,
      easing: easings.easeInOutExpo,
    },
    from: {
      strokeDasharray: MAX_SPINNER_PATH_LENGTH,
      strokeDashoffset: MAX_SPINNER_PATH_LENGTH,
    },
    to: {
      strokeDasharray: MAX_SPINNER_PATH_LENGTH,
      strokeDashoffset: 0,
    },
    onRest: () => {
      safelySetIsGrowing(setFirstIsGrowing, false);
      secondPathGrowRef.pause();
      firstPathShrinkRef.resume();
    },
  });

  const firstPathShrink = useSpring({
    ref: firstPathShrinkRef,
    loop: true,
    config: {
      duration: LOOP_DURATION,
      easing: easings.easeInQuart,
    },
    from: {
      strokeDasharray: MAX_SPINNER_PATH_LENGTH,
      strokeDashoffset: 0,
    },
    to: {
      strokeDasharray: MAX_SPINNER_PATH_LENGTH,
      strokeDashoffset: MAX_SPINNER_PATH_LENGTH,
    },
    onRest: () => {
      safelySetIsGrowing(setSecondIsGrowing, false);
      firstPathShrinkRef.pause();
      secondPathShrinkRef.resume();
    },
  });

  const secondPathShrink = useSpring({
    ref: secondPathShrinkRef,
    loop: true,
    config: {
      duration: LOOP_DURATION,
      easing: easings.easeOutQuart,
    },
    from: {
      strokeDasharray: MAX_SPINNER_PATH_LENGTH,
      strokeDashoffset: 0,
    },
    to: {
      strokeDasharray: MAX_SPINNER_PATH_LENGTH,
      strokeDashoffset: -MAX_SPINNER_PATH_LENGTH,
    },
    onRest: () => {
      secondPathShrinkRef.pause();
      firstPathGrowRef.resume();
      safelySetIsGrowing(setFirstIsGrowing, true);
    },
  });

  // all of this shit is because react-spring doesn't support looping with their
  // useChain hook
  useEffect(() => {
    firstPathGrowRef.start();
    secondPathGrowRef.start();
    firstPathShrinkRef.start();
    secondPathShrinkRef.start();
    secondPathGrowRef.pause();
    firstPathShrinkRef.pause();
    secondPathShrinkRef.pause();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const firstGradient =
    colorVariant === 'light'
      ? 'url(#gradient-1-light)'
      : 'url(#gradient-1-dark)';
  const secondGradient =
    colorVariant === 'light'
      ? 'url(#gradient-2-light)'
      : 'url(#gradient-2-dark)';

  return (
    <SpinnerContainer
      xmlns="http://www.w3.org/2000/svg"
      width={size || (small ? '20px' : '40px')}
      height={size || (small ? '20px' : '40px')}
      fill="none"
      viewBox="0 0 216 216"
      id="loadingIndicator"
    >
      <defs>
        <linearGradient id="gradient-1-light">
          <stop offset="0%" stopColor="white" stopOpacity="0" />
          <stop offset="100%" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient id="gradient-2-light">
          <stop offset="0%" stopColor="white" />
          <stop offset="100%" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient id="gradient-1-dark">
          <stop
            offset="0%"
            stopColor={FIXED_COLORS.transcendNavy2}
            stopOpacity="0"
          />
          <stop
            offset="100%"
            stopColor={FIXED_COLORS.transcendNavy2}
            stopOpacity="0.5"
          />
        </linearGradient>
        <linearGradient id="gradient-2-dark">
          <stop offset="0%" stopColor={FIXED_COLORS.transcendNavy2} />
          <stop
            offset="100%"
            stopColor={FIXED_COLORS.transcendNavy2}
            stopOpacity="0.5"
          />
        </linearGradient>
      </defs>
      <g strokeWidth="14">
        <animated.path
          style={
            !isSafari ? (firstIsGrowing ? firstPathGrow : firstPathShrink) : {}
          }
          stroke={firstGradient}
          d="M208 108C208 52.7715 163.228 8 108 8C52.7715 8 8 52.7715 8 108"
        />
        <animated.path
          style={
            !isSafari
              ? secondIsGrowing
                ? secondPathGrow
                : secondPathShrink
              : {}
          }
          stroke={secondGradient}
          d="M208 108C208 163.228 163.228 208 108 208C52.7715 208 8 163.228 8 108"
        />
      </g>
    </SpinnerContainer>
  );
};

import { SchemaFieldTypes } from './enums';
import type {
  AnySchema,
  DhEncryptedParam,
  EndpointParam,
  GraphQLParameter,
  Schema,
  SchemaFields,
  SchemaFieldType,
  SchemaFieldTypeOptional,
  SchemaId,
  SchemaType,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSchema = (type: any): type is AnySchema =>
  !!type.name && !!type.comment && !!type.fields;

export const isSchemaId = (param: GraphQLParameter): param is SchemaId =>
  typeof param === 'object' && 'modelName' in param;

export const isSchemaList = (
  param: GraphQLParameter,
): param is Schema<string, SchemaFields, SchemaType, boolean, true> =>
  typeof param === 'object' && 'isList' in param;

export const isSchemaFieldTypeOptional = (
  param: EndpointParam,
): param is SchemaFieldTypeOptional<SchemaFieldType> =>
  typeof param === 'object' &&
  Object.keys(SchemaFieldTypes).includes(param.type);

export const isDhEncryptedParam = (
  param: EndpointParam,
): param is DhEncryptedParam<any> =>
  typeof param === 'object' &&
  param.type === 'string' &&
  'underlyingType' in param;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagMS: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55062"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55062)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55062"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55062)">
        <mask
          id="mask2_270_55062"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="9"
          height="7"
        >
          <rect width="9" height="7" fill="white" />
        </mask>
        <g mask="url(#mask2_270_55062)">
          <path
            // eslint-disable-next-line max-len
            d="M-1.00214 6.49991L0.978339 7.36852L9.04494 0.944376L10.0896 -0.34639L7.97176 -0.636702L4.68159 2.13164L2.03334 3.99686L-1.00214 6.49991Z"
            fill="#F7FCFF"
          />
          <path
            d="M-0.731018 7.10847L0.277943 7.61253L9.71445 -0.466324H8.29775L-0.731018 7.10847Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M10.0021 6.49991L8.02166 7.36852L-0.0449371 0.944376L-1.08959 -0.34639L1.02824 -0.636702L4.31841 2.13164L6.96666 3.99686L10.0021 6.49991Z"
            fill="#F7FCFF"
          />
          <path
            // eslint-disable-next-line max-len
            d="M9.93457 6.93667L8.92561 7.44073L4.90745 3.98176L3.71615 3.59529L-1.1901 -0.341973H0.226597L5.13009 3.50184L6.43256 3.96525L9.93457 6.93667Z"
            fill="#F50100"
          />
          <mask
            id="path-9-outside-1_270_55062"
            maskUnits="userSpaceOnUse"
            x="-1"
            y="-1"
            width="11"
            height="9"
            fill="black"
          >
            <rect fill="white" x="-1" y="-1" width="11" height="9" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M3.99226 0V-0.75H3.24226V0H3.99226ZM4.99226 0H5.74226V-0.75H4.99226V0ZM3.99226 3V3.75H4.74226V3H3.99226ZM0 3V2.25H-0.75V3H0ZM0 4H-0.75V4.75H0V4ZM3.99226 4H4.74226V3.25H3.99226V4ZM3.99226 7H3.24226V7.75H3.99226V7ZM4.99226 7V7.75H5.74226V7H4.99226ZM4.99226 4V3.25H4.24226V4H4.99226ZM9 4V4.75H9.75V4H9ZM9 3H9.75V2.25H9V3ZM4.99226 3H4.24226V3.75H4.99226V3ZM3.99226 0.75H4.99226V-0.75H3.99226V0.75ZM4.74226 3V0H3.24226V3H4.74226ZM0 3.75H3.99226V2.25H0V3.75ZM0.75 4V3H-0.75V4H0.75ZM3.99226 3.25H0V4.75H3.99226V3.25ZM4.74226 7V4H3.24226V7H4.74226ZM4.99226 6.25H3.99226V7.75H4.99226V6.25ZM4.24226 4V7H5.74226V4H4.24226ZM9 3.25H4.99226V4.75H9V3.25ZM8.25 3V4H9.75V3H8.25ZM4.99226 3.75H9V2.25H4.99226V3.75ZM4.24226 0V3H5.74226V0H4.24226Z"
            fill="#F7FCFF"
            mask="url(#path-9-outside-1_270_55062)"
          />
        </g>
        <path
          // eslint-disable-next-line max-len
          d="M12.4643 10.9567C11.817 10.9567 11.3621 10.807 11.037 10.5426C10.7111 10.2776 10.4996 9.88437 10.365 9.3659C10.2302 8.84652 10.1752 8.2122 10.1548 7.47747C10.1403 6.96098 10.143 6.40036 10.1459 5.79963C10.1469 5.58534 10.1479 5.36594 10.1482 5.14162H14.8356C14.9194 6.62058 14.9515 8.06796 14.6545 9.15337C14.5027 9.70815 14.2677 10.157 13.9197 10.4672C13.5741 10.7752 13.1054 10.9567 12.4643 10.9567Z"
          fill="#6DC2FF"
          stroke="#F7FCFF"
          strokeWidth="0.25"
        />
        <mask
          id="mask3_270_55062"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="5"
          width="6"
          height="7"
        >
          <path
            // eslint-disable-next-line max-len
            d="M12.4643 10.9567C11.817 10.9567 11.3621 10.807 11.037 10.5426C10.7111 10.2776 10.4996 9.88437 10.365 9.3659C10.2302 8.84652 10.1752 8.2122 10.1548 7.47747C10.1403 6.96098 10.143 6.40036 10.1459 5.79963C10.1469 5.58534 10.1479 5.36594 10.1482 5.14162H14.8356C14.9194 6.62058 14.9515 8.06796 14.6545 9.15337C14.5027 9.70815 14.2677 10.157 13.9197 10.4672C13.5741 10.7752 13.1054 10.9567 12.4643 10.9567Z"
            fill="white"
            stroke="white"
            strokeWidth="0.25"
          />
        </mask>
        <g mask="url(#mask3_270_55062)">
          <rect
            x="9.96625"
            y="5"
            width="5.035"
            height="4.05568"
            fill="#2EBED7"
          />
          <rect
            x="9.90002"
            y="9.02271"
            width="5.3"
            height="2.0773"
            fill="#A95601"
          />
          <path
            // eslint-disable-next-line max-len
            d="M12.8729 6.76079C12.8729 6.76079 11.9394 6.97606 12.0296 6.76079C12.1199 6.54551 12.3613 5.76926 12.3613 5.76926"
            stroke="#FFC6B5"
            strokeWidth="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M13.1536 6.91428C13.1536 6.91428 12.9833 6.80818 12.9833 6.61495C12.9833 6.42171 12.568 6.34942 12.8608 6.0475C13.1536 5.74559 13.28 5.87855 13.28 6.0475C13.28 6.21646 13.5154 6.61495 13.5154 6.61495L13.1536 6.91428Z"
            fill="#FFC6B5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.6825 5.16487H12.2188V5.62649H11.2913V6.08811H12.2188V10.1108H12.6825V6.08811H13.61V5.62649H12.6825V5.16487Z"
            fill="#272727"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M13.681 10.2466C13.681 10.2466 13.5123 9.45284 13.5123 8.90948C13.5123 8.36613 13.2296 7.97749 13.3104 7.69015C13.3912 7.40281 13.5857 7.08507 13.4885 6.8884C13.3912 6.69172 13.5834 6.59596 13.3104 6.69172C13.0374 6.78748 12.9641 6.59596 12.7005 6.69172C12.437 6.78748 12.4036 7.23959 12.4036 7.36857C12.4036 7.49755 12.5208 8.36715 12.5208 8.63831C12.5208 8.90948 12.4243 10.5769 12.7005 10.5769C12.9768 10.5769 13.278 10.143 13.3832 10.143C13.4885 10.143 13.681 10.2466 13.681 10.2466Z"
            fill="#0C7C38"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.0149 8.12187C11.8465 8.12187 12.0014 8.51959 11.5729 8.69398C11.1444 8.86836 11.0602 9.11394 11.1952 9.11394C11.3302 9.11394 12.3976 10.3332 12.3976 10.3332L12.6593 9.92181V8.62274C12.6593 8.62274 12.1833 8.12187 12.0149 8.12187Z"
            stroke="#FF9A06"
            strokeWidth="0.5"
          />
          <path
            d="M13.2716 10.1635C13.2716 10.1635 13.4716 10.5927 13.2716 10.5927"
            stroke="#979797"
            strokeWidth="0.5"
          />
          <path
            // eslint-disable-next-line max-len
            d="M13.2072 6.92671C13.2072 6.92671 13.1194 7.41616 12.9521 7.51863C12.7848 7.62109 12.0976 7.81947 12.0976 7.98885C12.0976 8.15824 12.0294 8.21879 12.0294 8.21879"
            stroke="#FFC6B5"
            strokeWidth="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.874 5.91629C12.874 5.91629 13.0421 6.11947 13.1363 6.4544C13.2304 6.78933 13.5032 6.9034 13.5032 6.74665C13.5032 6.58989 13.7048 5.91629 13.5032 5.91629C13.3016 5.91629 12.874 5.91629 12.874 5.91629Z"
            fill="#FF9A06"
          />
        </g>
      </g>
    </g>
  </svg>
);

/**
 * Pick a color in the colors array based on the string provided.
 *
 * @param colors - the array of colors to pick from
 * @param str - the string to hash
 * @returns a color in the colors array
 */
export function getColorFromString(
  colors: string[],
  str?: string,
): string | undefined {
  if (!str) return undefined;
  const index =
    str.split('').reduce((acc, c) => acc + c.charCodeAt(0), 0) % colors.length;
  return colors[index];
}

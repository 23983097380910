import React from 'react';

import { SvgProps } from '../enums';

export const FlagGH: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55006"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55006)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8H16V12H0V8Z"
        fill="#5EAA22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4H16V8H0V4Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V4H0V0Z"
        fill="#E11C1B"
      />
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.03771 7.24519L6.2949 8.45404L6.85179 6.38357L5.57239 5.06095L7.30478 4.98944L8.03771 2.94205L8.77065 4.98944H10.5001L9.22364 6.38357L9.86291 8.33184L8.03771 7.24519Z"
        fill="#1D1D1D"
      />
    </g>
  </svg>
);

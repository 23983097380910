/* eslint-disable max-lines */
import { makeEnum } from '@transcend-io/type-utils';

/**
 * Models that are an edge table between two other models
 */
export const JoinModelName = makeEnum({
  /** The action items associated to an attribute value */
  ActionItemAttributeValue: 'ActionItemAttributeValue',
  /** The action items assigned to a specific collection */
  ActionItemCollectionActionItem: 'ActionItemCollectionActionItem',
  /** The applications that the agent runs as a part of */
  AgentApplication: 'AgentApplication',
  /** The users that have interacted with an agent */
  AgentAppUser: 'AgentAppUser',
  /** The users that have interacted with an application */
  ApplicationAppUser: 'ApplicationAppUser',
  /** Prompts that were run against an application */
  ApplicationPrompt: 'ApplicationPrompt',
  /** The files that an agent is capable of using in its retrieval context */
  AgentAgentFile: 'AgentAgentFile',
  /** The function that an agent is capable of calling */
  AgentAgentFunction: 'AgentAgentFunction',
  /** The teams that are in charge of managing an agent */
  AgentTeam: 'AgentTeam',
  /** The users that are in charge of managing an agent */
  AgentUser: 'AgentUser',
  /** The teams that are in charge of managing an application */
  ApplicationTeam: 'ApplicationTeam',
  /** The users that are in charge of managing an application */
  ApplicationUser: 'ApplicationUser',
  /** Mapping from action items to teams */
  ActionItemTeam: 'ActionItemTeam',
  /** Mapping from action items to users */
  ActionItemUser: 'ActionItemUser',
  /** The users assigned to specific cookies */
  AirgapCookieUser: 'AirgapCookieUser',
  /** The teams assigned to specific cookies */
  AirgapCookieTeam: 'AirgapCookieTeam',
  /** The users assigned to specific cookies */
  AirgapDataFlowUser: 'AirgapDataFlowUser',
  /** The users assigned to specific cookies */
  AirgapDataFlowTeam: 'AirgapDataFlowTeam',
  /** The users assigned to specific request */
  RequestUser: 'RequestUser',
  /** The users assigned to specific request */
  RequestTeam: 'RequestTeam',
  /** The users assigned to view requests for a particular data subject type */
  SubjectUser: 'SubjectUser',
  /** The teams assigned to view requests for a particular data subject type */
  SubjectTeam: 'SubjectTeam',
  /** The scopes that an api key has */
  ApiKeyScope: 'ApiKeyScope',
  /** The SaaS Categories that an integration Catalog entry belongs to */
  CatalogSaaSCategory: 'CatalogSaaSCategory',
  /** The transcend users that own a data point level */
  DataPointLevelUser: 'DataPointLevelUser',
  /** The subjects for which the subDataPoint is allowed */
  SubDataPointSubject: 'SubDataPointSubject',
  /** The transcend users that own a data point */
  DataPointUser: 'DataPointUser',
  /** API keys that authenticate a data silo */
  DataSiloApiKey: 'DataSiloApiKey',
  /** The attribute values that are used to label a data silo */
  DataSiloAttributeValue: 'DataSiloAttributeValue',
  /** The SaaS Categories that a data silo belongs to */
  DataSiloSaaSCategory: 'DataSiloSaaSCategory',
  /** The attribute values that are used to label an airgap cookie */
  AirgapCookieAttributeValue: 'AirgapCookieAttributeValue',
  /** The attribute values that are used to label an airgap data flow */
  AirgapDataFlowAttributeValue: 'AirgapDataFlowAttributeValue',
  /** the purposes associated with an airgap data flow */
  AirgapDataFlowPurpose: 'AirgapDataFlowPurpose',
  /** the purposes associated with an airgap cookie */
  AirgapCookiePurpose: 'AirgapCookiePurpose',
  /** The contracts that were scanned in a contract scan */
  ContractScanContract: 'ContractScanContract',
  /** The prompts that were scanned in a contract scan */
  ContractScanPrompt: 'ContractScanPrompt',
  /** The plugins that found a data silo */
  DataSiloPlugin: 'DataSiloPlugin',
  /** The transcend users that should be notified when the data silo requires action */
  DataSiloUser: 'DataSiloUser',
  /** Sombra gateways configured for an organization */
  OrganizationSombra: 'OrganizationSombra',
  /** The teams of users that should be notified when the data silo requires action */
  DataSiloTeam: 'DataSiloTeam',
  /** The data silos that send and receive data */
  DataSiloDataSilo: 'DataSiloDataSilo',
  /** The data subcategories assigned to data silos */
  DataSiloDataSubCategory: 'DataSiloDataSubCategory',
  /** The processing purpose subcategories assigned to data silos */
  DataSiloProcessingPurposeSubCategory: 'DataSiloProcessingPurposeSubCategory',
  /** The teams that a datapoint is assign to */
  DataPointTeam: 'DataPointTeam',
  /** The teams that a datapoint level is assign to */
  DataPointLevelTeam: 'DataPointLevelTeam',
  /** The teams that a processing purpose sub category is assign to */
  ProcessingPurposeSubCategoryTeam: 'ProcessingPurposeSubCategoryTeam',
  /** The teams that a data sub category is assign to */
  DataSubCategoryTeam: 'DataSubCategoryTeam',
  /** The users that a processing purpose sub category is assign to */
  ProcessingPurposeSubCategoryUser: 'ProcessingPurposeSubCategoryUser',
  /** The users that a data sub category is assign to */
  DataSubCategoryUser: 'DataSubCategoryUser',
  /** The internal users of the company that are overseeing the vendor */
  VendorUser: 'VendorUser',
  /** The teams that are the main point of contact for that vendor */
  VendorTeam: 'VendorTeam',
  /** The internal users of the company that are overseeing the risk category */
  RiskCategoryUser: 'RiskCategoryUser',
  /** The teams that are the main point of contact for that risk category */
  RiskCategoryTeam: 'RiskCategoryTeam',
  /** The subjects where the data silo does not run */
  DataSiloSubjectDeny: 'DataSiloSubjectDeny',
  /** The actions a datapoint is turned on for */
  DataPointAction: 'DataPointAction',
  /** The attribute values used to label a datapoint */
  DataPointAttributeValue: 'DataPointAttributeValue',
  /** Dependencies where certain Data Silos depend on others completing before running */
  DataSiloDependentDataSilo: 'DataSiloDependentDataSilo',
  /** The identifiers that an enricher can return */
  EnricherIdentifier: 'EnricherIdentifier',
  /** The enricher should run on these DSR action types */
  EnricherAction: 'EnricherAction',
  /** Enricher details about how to cross reference an identifier to a completed verification */
  EnricherCrossReferenceIdentifier: 'EnricherCrossReferenceIdentifier',
  /** The enricher should run only for these types of data subjects */
  EnricherSubject: 'EnricherSubject',
  /** The identifier should only be shown in the privacy center form for these data subjects */
  IdentifierSubject: 'IdentifierSubject',
  /** The identifiers that can be looked up in a specific data silo. */
  IdentifierDataSilo: 'IdentifierDataSilo',
  /** The identifiers that should be shown on the privacy center for a certain action */
  IdentifierAction: 'IdentifierAction',
  /** The attribute values for a processing activity */
  ProcessingActivityAttributeValue: 'ProcessingActivityAttributeValue',
  /** The data silos for a processing activity */
  ProcessingActivityDataSilo: 'ProcessingActivityDataSilo',
  /** The data sub categories for a processing activity */
  ProcessingActivityDataSubCategory: 'ProcessingActivityDataSubCategory',
  /** The processing purposes for a processing activity */
  ProcessingActivityProcessingPurposeSubCategory:
    'ProcessingActivityProcessingPurposeSubCategory',
  /** The recipient categories for a processing activity */
  ProcessingActivitySaaSCategory: 'ProcessingActivitySaaSCategory',
  /** The sub data points for a processing activity */
  ProcessingActivitySubDataPoint: 'ProcessingActivitySubDataPoint',
  /** The subjects for a processing activity */
  ProcessingActivitySubject: 'ProcessingActivitySubject',
  /** The team(s) assigned to a processing activity */
  ProcessingActivityTeam: 'ProcessingActivityTeam',
  /** The user(s) who own a processing activity */
  ProcessingActivityUser: 'ProcessingActivityUser',
  /**
   * These are the datapoints that were attempted for a profile, in the context of a specific request.
   * This table holds the status of that attempted action, and if any data is returned, it is stored as requestFiles.
   */
  ProfileDataPoint: 'ProfileDataPoint',
  /** Users that are in charge of a repository */
  RepositoryUser: 'RepositoryUser',
  /** Teams that are in charge of a repository */
  RepositoryTeam: 'RepositoryTeam',
  /** User that created the pull request */
  PullRequestUser: 'PullRequestUser',
  /** Users that are in charge of a code package */
  CodePackageUser: 'CodePackageUser',
  /** Teams that are in charge of a code package */
  CodePackageTeam: 'CodePackageTeam',
  /**
   * When a request is submitted, it can be tagged with a set of attribute values
   * that provide additional context about the privacy request. These attributes
   * are passed through to each webhook, and can be used to filter the request.
   */
  RequestAttributeValue: 'RequestAttributeValue',
  /**
   * The many to many relationship between a request and a data silo.
   * Each edge here represents an attempt to process data in a data silo for a specific request.
   */
  RequestDataSilo: 'RequestDataSilo',
  /**
   * Join model capturing the many-to-many relationship between email-communications
   * and Request Data Silos.
   */
  RequestDataSiloCommunication: 'RequestDataSiloCommunication',
  /** Join model capturing the many-to-many relationship between RequestDataSilo and RequestIdentifier */
  RequestDataSiloRequestIdentifier: 'RequestDataSiloRequestIdentifier',
  /** The status of each enricher in the context of a single request */
  RequestEnricher: 'RequestEnricher',
  /** Request identifiers that have been created by the output of a request enricher */
  RequestEnricherRequestIdentifier: 'RequestEnricherRequestIdentifier',
  /** The identifiers that are used to look up data for a request */
  RequestIdentifier: 'RequestIdentifier',
  /** The request identifiers that resolve to a specific profileId in a request/data silo pairing */
  RequestIdentifierProfile: 'RequestIdentifierProfile',
  /** The subcategories for a subdatapoint */
  SubDataPointDataSubCategory: 'SubDataPointDataSubCategory',
  /** A subcategory guess for a subdatapoint from our classifier */
  SubDataPointDataSubCategoryGuess: 'SubDataPointDataSubCategoryGuess',
  /** The purpose subcategories for a subdatapoint */
  SubDataPointProcessingPurposeSubCategory:
    'SubDataPointProcessingPurposeSubCategory',
  /** The attribute values that are used to label a subdatapoint */
  SubDataPointAttributeValue: 'SubDataPointAttributeValue',
  /** The attribute values that are used to label a vendor */
  VendorAttributeValue: 'VendorAttributeValue',
  /** The teams that are assigned to a business entity */
  BusinessEntityTeam: 'BusinessEntityTeam',
  /** The attribute values that are used to label a business entity */
  BusinessEntityAttributeValue: 'BusinessEntityAttributeValue',
  /** The attribute values that are used to label a business entity */
  BusinessEntityUser: 'BusinessEntityUser',
  /** The attribute values that are used to label a data sub-category */
  DataSubCategoryAttributeValue: 'DataSubCategoryAttributeValue',
  /** PathfinderPolicyDataSubCategory */
  PathfinderPathfinderPolicy: 'PathfinderPathfinderPolicy',
  /** Large language models that the pathfinder instance has access to */
  PathfinderLargeLanguageModel: 'PathfinderLargeLanguageModel',
  /** The data sub categories included in a pathfinder policy */
  PathfinderPolicyDataSubCategory: 'PathfinderPolicyDataSubCategory',
  /** Applications that are calling the pathfinder instance */
  PathfinderApplication: 'PathfinderApplication',
  /** The attribute values that are used to label a processing purpose */
  ProcessingPurposeSubCategoryAttributeValue:
    'ProcessingPurposeSubCategoryAttributeValue',
  /** The DSR request types allowed for data subject classes */
  SubjectAction: 'SubjectAction',
  /** Teams can be assigned many scopes */
  TeamScope: 'TeamScope',
  /** Users can be assigned many scopes */
  UserScope: 'UserScope',
  /** Users can be members of multiple teams */
  UserTeam: 'UserTeam',
  /** Tracking purposes associated with usage of a SDK */
  ConsentSdkPurpose: 'ConsentSdkPurpose',
  /** Purposes available for a users within a organization defined experience */
  ExperiencePurpose: 'ExperiencePurpose',
  /** Purposes declared as performed on the legal basis of consent (joins with tcfPurposes) */
  TcfVendorPurpose: 'TcfVendorPurpose',
  /** Purposes declared as performed on the legal basis of legitimate interest (joins with tcfPurposes) */
  TcfVendorFlexiblePurpose: 'TcfVendorFlexiblePurpose',
  /** Purposes where the vendor is flexible regarding the legal basis */
  TcfVendorLegIntPurpose: 'TcfVendorLegIntPurpose',
  /** Special Purposes declared as performed on the legal basis of a legitimate interest */
  TcfVendorSpecialPurpose: 'TcfVendorSpecialPurpose',
  /** Features the Vendor may utilize when performing some declared Purposes processing */
  TcfVendorFeature: 'TcfVendorFeature',
  /** Special Features the Vendor may utilize when performing some declared Purposes processing */
  TcfVendorSpecialFeature: 'TcfVendorSpecialFeature',
  /** Categories of data TCF vendors may collect and utilize */
  TcfVendorDataCategory: 'TcfVendorDataCategory',
  /** Purposes summarized by a TCF Stack */
  TcfPurposeStack: 'TcfPurposeStack',
  /** Special features summarized by a TCF Stack */
  TcfSpecialFeatureStack: 'TcfSpecialFeatureStack',
  /** TCF Stacks used by Airgap Bundles to describe data usage */
  AirgapBundleTcfStack: 'AirgapBundleTcfStack',
  /** Mapping of TCF Purposes to airgap tracking purposes */
  PurposeOrgTcfPurpose: 'PurposeOrgTcfPurpose',
  /** Mapping of TCF Special Features to airgap tracking purposes */
  PurposeOrgTcfSpecialFeature: 'PurposeOrgTcfSpecialFeature',
  /** The prompts that a user has interacted with */
  PromptAppUser: 'PromptAppUser',
  /** Join table between prompts and prompt groups */
  PromptGroupPrompt: 'PromptGroupPrompt',
  /** The teams assigned to the prompt */
  PromptTeam: 'PromptTeam',
  /** The users assigned to the prompt */
  PromptUser: 'PromptUser',
  /** The teams assigned to the prompt group */
  PromptGroupTeam: 'PromptGroupTeam',
  /** The users assigned to the prompt group */
  PromptGroupUser: 'PromptGroupUser',
  /** The teams assigned to the partial prompt */
  PromptPartialTeam: 'PromptPartialTeam',
  /** The users assigned to the partial prompt */
  PromptPartialUser: 'PromptPartialUser',
  /** The various prompts that are assigned to execute on a specific large language model */
  PromptLargeLanguageModel: 'PromptLargeLanguageModel',
  /** The user assigned to fill out the assessment form */
  AssessmentFormAssignee: 'AssessmentFormAssignee',
  /** The user assigned to fill out the assessment section */
  AssessmentSectionAssignee: 'AssessmentSectionAssignee',
  /** The selected answers for a question */
  AssessmentQuestionAnswer: 'AssessmentQuestionAnswer',
  /** The user assigned to review the assessment form */
  AssessmentFormReviewer: 'AssessmentFormReviewer',
  /** The team assigned to the assessment form */
  AssessmentFormTeam: 'AssessmentFormTeam',
  /** The user who will be assigned to review an assessment form created in this group */
  AssessmentGroupReviewer: 'AssessmentGroupReviewer',
  /** The email templates in an assessment email template set */
  AssessmentEmailSetTemplate: 'AssessmentEmailSetTemplate',
  /** The assignees to add to the secondary assessment triggered */
  AssessmentTriggerUser: 'AssessmentTriggerUser',
  /** The attribute values that are used to label a prompt */
  PromptAttributeValue: 'PromptAttributeValue',
  /** The attribute values that are used to label a prompt group */
  PromptGroupAttributeValue: 'PromptGroupAttributeValue',
  /** The attribute values that are used to label a prompt run */
  PromptRunAttributeValue: 'PromptRunAttributeValue',
  /** The data sub categories discovered in the prompt run message */
  PromptRunMessageDataSubCategory: 'PromptRunMessageDataSubCategory',
  /** The software development kits found in a code package */
  CodePackageSoftwareDevelopmentKit: 'CodePackageSoftwareDevelopmentKit',
  /** The users assigned to a specific software development kit */
  SoftwareDevelopmentKitUser: 'SoftwareDevelopmentKitUser',
  /** The teams assigned to a specific software development kit */
  SoftwareDevelopmentKitTeam: 'SoftwareDevelopmentKitTeam',
  /** The actions a scannedObject is turned on for */
  ScannedObjectAction: 'ScannedObjectAction',
  /** The attribute values used to label a scannedObject */
  ScannedObjectAttributeValue: 'ScannedObjectAttributeValue',
  /** The attribute values used to label a scannedObjectPath */
  ScannedObjectPathAttributeValue: 'ScannedObjectPathAttributeValue',
  /** The teams that a scannedObject is assign to */
  ScannedObjectTeam: 'ScannedObjectTeam',
  /** The transcend users that own a scannedObject */
  ScannedObjectUser: 'ScannedObjectUser',
  /** The teams that a scannedObjectPath is assign to */
  ScannedObjectPathTeam: 'ScannedObjectPathTeam',
  /** The transcend users that own a scannedObjectPath */
  ScannedObjectPathUser: 'ScannedObjectPathUser',
  /**
   * A key-value pair representing an attribute and its value related to a contract, such as a contract term, a date, or a party's information. This
   * data structure is used to enrich contract records with flexible, structured attributes that can aid in organizing, searching, or filtering
   * contracts within the system.
   */
  ContractAttributeValue: 'ContractAttributeValue',
  /**
   * This model represents the association of specific attribute values with a user's account. These attributes might include additional descriptors
   * such as profile preferences, account statuses or flags, roles, or any other metadata pertinent to the user within the system. The
   * `UserAttributeValue` model serves as a flexible means to extend user-related information beyond basic fields like name, email, etc., by
   * connecting users to a dynamic set of attributes that can evolve with the application's requirements.
   */
  UserAttributeValue: 'UserAttributeValue',
  /**
   * This model represents the association between a team and customizable attribute values within the system. The TeamAttributeValue model enables
   * the definition of team-specific metadata, which can be utilized to capture additional information about teams such as roles, responsibilities, or
   * any other contextual data relevant to the team's function or identity in the platform. This allows for a more dynamic and descriptive
   * representation of teams in relation to various aspects of the system's operations or business logic.
   */
  TeamAttributeValue: 'TeamAttributeValue',
  /**
   * The `ScopeAttributeValue` model represents the association between a `scope` and its corresponding `attributeValue` within the system. It is used
   * to define and store metadata for scopes, facilitating the extension of scope-related information with customizable and structured attributes.
   * This linkage enables refined access control mechanisms and contextual understanding of each scope's purpose and characteristics within the
   * platform, streamlining both user management and system operations.
   */
  ScopeAttributeValue: 'ScopeAttributeValue',
  /**
   * This model captures the association between agents (AI or user-driven interfaces) and specific attributes related to their function or identity
   * within the system. `AgentAttributeValue` serves as a flexible key-value pairing that enriches agent profiles with additional, structured data,
   * allowing for more nuanced configuration, reporting, and behavioral logic to be implemented based on these attributes.
   */
  AgentAttributeValue: 'AgentAttributeValue',
  /**
   * The `ApplicationAttributeValue` model represents the connection between an application and specific attribute values assigned to it. This model
   * enables the association of metadata with applications in the system, allowing for the addition of descriptive information, such as configuration
   * settings, custom tags, or categories, which might be essential for categorizing, searching, and managing applications within the platform. It
   * provides a flexible way to extend the attributes associated with applications dynamically as per business requirements.
   */
  ApplicationAttributeValue: 'ApplicationAttributeValue',
  /**
   * This model represents the association between a `CodePackage` and specific `attributeValues` that describe it. The `CodePackageAttributeValue`
   * serves as a means to encapsulate metadata like version numbers, dependencies, or other relevant characteristics that pertain to a package of code
   * within a repository. Utilizing these key-value pairs allows developers, maintainers, and tools to more efficiently manage, search, and understand
   * the structure and properties of the codebase.
   */
  CodePackageAttributeValue: 'CodePackageAttributeValue',
  /**
   * The `PathfinderPolicyAttributeValue` model acts as a means to map specific attribute values to policies associated with a pathfinder instance.
   * This allows for customization and fine-tuning of the pathfinder's behavior based on the attributes applied to it, which can include
   * configurations, thresholds, or other policy-related data. Effectively, it enriches a pathfinder policy by adding additional context or control
   * parameters, which can be critical in tailoring the pathfinder's decision-making process to specific operational requirements or business rules.
   */
  PathfinderPolicyAttributeValue: 'PathfinderPolicyAttributeValue',
  /**
   * The `PathfinderAttributeValue` model serves as a key-value mapping specifically designed for the `pathfinder` model. It associates
   * `attributeValue` records with a `pathfinder` instance, enabling the inclusion of additional metadata, settings, or descriptors that are pertinent
   * to the pathfinder's operation. By leveraging this model, developers can dynamically tailor the functionality and behavior of a pathfinder by
   * enriching it with customizable and structured data attributes. This could include configuration tweaks, operational parameters, or
   * context-specific information that enhances the pathfinder's decision-making logic or its interaction with other system components.
   */
  PathfinderAttributeValue: 'PathfinderAttributeValue',
  /**
   * The `LargeLanguageModelAttributeValue` model is designed to link attribute values with instances of a large language model, providing a
   * structured method to store additional metadata or characteristics specific to that model instance. This could include information such as model
   * version details, performance metrics, training parameters, or domain-specific attributes that assist in tracking, managing, or enhancing the
   * applications of the language model within various workflows or AI-driven features of the system.
   */
  LargeLanguageModelAttributeValue: 'LargeLanguageModelAttributeValue',
  /**
   * The `AgentFunctionAttributeValue` model is designed to encapsulate the relationship between a function that an AI agent can perform and any
   * additional attribute values that describe or modify that function. This association allows for the detailed contextualization and customization
   * of agent functions, which can include tagging functions with specific categories, priority levels, parameters, or any other relevant metadata
   * that enhances the system’s decision-making capabilities and operational management of AI agents.
   */
  AgentFunctionAttributeValue: 'AgentFunctionAttributeValue',
  /**
   * The `ConsentApplicationAttributeValue` model establishes a relationship between a consent application and various attributes that characterize
   * it. This model allows for the association of metadata with consent applications, such as compliance requirements, privacy settings, and user
   * preferences, adding a layer of customization and control that is critical for managing consent flows within different applications. It
   * effectively provides a mechanism to detail and differentiate the consent-related aspects of each application, ensuring that they align with legal
   * standards and user expectations.
   */
  ConsentApplicationAttributeValue: 'ConsentApplicationAttributeValue',
  /**
   * This model represents the association between a `ConsentService` and specific `attributeValues` that describe it. The
   * `ConsentServiceAttributeValue` serves as a means to encapsulate metadata such as service categories, privacy settings, or user preferences that
   * pertain to a consent service. This structured relationship enables more granular control and management of services within the consent module,
   * ensuring proper alignment with regulatory requirements and user consent preferences.
   */
  ConsentServiceAttributeValue: 'ConsentServiceAttributeValue',
  /**
   * The `ConsentSdkAttributeValue` model is used to associate specific attribute values with an instance of a `ConsentSdk`. These attribute values
   * provide additional context and metadata, such as configuration details, categorization tags, or consent statuses related to the SDK. By
   * attributing these key-value pairs to a `ConsentSdk`, the model allows for a more nuanced and detailed management of SDK consent records across
   * different applications and services, facilitating compliance with consent-based regulations and user preferences.
   */
  ConsentSdkAttributeValue: 'ConsentSdkAttributeValue',
  /**
   * This model captures the set of attribute values linked to a specific instance of a software development kit (SDK) within the system. The
   * `SoftwareDevelopmentKitAttributeValue` model enables the association of metadata or characteristics specific to the SDK, such as platform
   * compatibility, versioning details, or licensing information. By pairing these key-value attributes with an SDK, developers and system integrators
   * can effectively organize, filter, and understand the nuances of different SDKs in use, facilitating better management and oversight of the tools
   * and libraries that support software development projects.
   */
  SoftwareDevelopmentKitAttributeValue: 'SoftwareDevelopmentKitAttributeValue',
  /**
   * This model represents the association between a Pull Request and specific attribute values within the code-scanning context.
   * `PullRequestAttributeValue` is utilized to tag pull requests with customizable key-value data, enriching the pull request records with additional
   * metadata that might be relevant for code review, tracking changes, categorization, or any other process where supplementary data about the pull
   * request could provide value. This flexible association facilitates more intricate queries and analyses of pull request attributes in the
   * codebase.
   */
  PullRequestAttributeValue: 'PullRequestAttributeValue',
  /**
   * This model represents a many-to-many association between `Repository` and `AttributeValue` within the code-scanning domain.
   * `RepositoryAttributeValue` is used to tag repositories with customizable key-value pairs, adding specific metadata that might be relevant for
   * repository categorization, security analysis, or tracking repository configurations over time. This additional layer of information allows
   * developers, security engineers, and tools to query and operate on repositories with an enhanced understanding of their attributes, such as
   * licensing details, repository status, or compliance markers.
   */
  RepositoryAttributeValue: 'RepositoryAttributeValue',
  /**
   * The `AgentFileAttributeValue` model represents a mapping between an `AgentFile` and specific `attributeValues` that provide metadata or
   * characteristics associated with the file. It is used to annotate files within the retrieval context of an AI agent, facilitating the
   * organization, categorization, and filtering of these files based on the attributed key-value pairs. This information could include details like
   * confidentiality levels, usage permissions, or content types, which are essential for managing the AI agent's file access and processing logic in
   * a structured and secure manner.
   */
  AgentFileAttributeValue: 'AgentFileAttributeValue',
  /**
   * The `AppUserAttributeValue` model is a junction model that bridges `AppUser` entities with their configurable `AttributeValues`, featuring a
   * many-to-many relationship. It is used to assign a diverse range of attributes to app users, such as roles, statuses, or custom profile tags to
   * extend the user's profile with flexible, structured data. These associations help in personalizing the user experience, segmenting users for
   * analysis, implementing access controls, or any other process that can benefit from user-specific attributes.
   */
  AppUserAttributeValue: 'AppUserAttributeValue',
  /**
   * A combined model representing both Pathfinder and Agent entities, used for tracking and managing interactions between pathfinding logic and agent
   * operations within the system.
   */
  PathfinderAgent: 'PathfinderAgent',
  /**
   * A model used to track the interactions between a repository and a large language model.
   */
  LargeLanguageModelRepository: 'LargeLanguageModelRepository',
  /**
   * A model used to track the interactions between a code package and a large language model.
   */
  LargeLanguageModelCodePackage: 'LargeLanguageModelCodePackage',
  /**
   * Interactions between code packages and partially LLM prompts.
   */
  PromptCodePackage: 'PromptCodePackage',
  /**
   * A model representing the interactions between user prompts and the underlying repository logic, facilitating the creation, retrieval, and
   * management of prompt-related data within the system.
   */
  PromptRepository: 'PromptRepository',
  /**
   * A composite model that represents a relationship between Data Silo and Business Entity, facilitating the mapping and management of data across
   * different business domains.
   */
  DataSiloBusinessEntity: 'DataSiloBusinessEntity',
  /**
   * This model represents the association between a consent workflow trigger and a data silo. The `ConsentWorkflowTriggerDataSilo` model is used to
   * manage and track the relationship between specific consent triggers and the data silos they affect. This enables the system to enforce
   * consent-related workflows based on the data silo involved, ensuring compliance with regulatory requirements and user preferences.
   */
  ConsentWorkflowTriggerDataSilo: 'ConsentWorkflowTriggerDataSilo',
  /**
   * This model represents which consent purpose triggered this DSR to run.
   */
  PurposeRequest: 'PurposeRequest',
  /**
   * This model represents the partitions that a datapoint should be scoped to. In the case where a partition is a data controller and the datapoint
   * is a marketing list, this is the mapping between which controllers are for which marketing lists.
   */
  DataPointAirgapPartition: 'DataPointAirgapPartition',
  /**
   * This join table represents the association between a preference topic and a preference option value.
   */
  PreferenceTopicOptionValue: 'PreferenceTopicOptionValue',
  /**
   * The `DataPointLevelAttributeValue` model represents a key-value pair association between a `DataPointLevel` and its specific attribute values.
   * This model is used to tag data point levels with customizable metadata, enabling more refined categorization, organization, and management of
   * data within the system. By associating attribute values with data point levels, developers and data managers can enhance the understanding and
   * utilization of data structures, facilitating better insights and operational efficiencies.
   */
  DataPointLevelAttributeValue: 'DataPointLevelAttributeValue',
  /**
   * This model represents the association between an assessment group and its corresponding attribute values within the system. The
   * `AssessmentGroupAttributeValue` model is used to assign metadata or characteristics to assessment groups, such as categories, tags, or other
   * relevant attributes that can aid in organizing, searching, or filtering assessments within the platform. This additional layer of information
   * allows users to manage and analyze assessment groups more effectively, enhancing the overall assessment process.
   */
  AssessmentGroupAttributeValue: 'AssessmentGroupAttributeValue',
  /**
   * A model representing the association between an assessment form and its specific attribute values within the system. The
   * `AssessmentFormAttributeValue` model is used to tag assessment forms with customizable metadata, enabling more refined categorization,
   * organization, and management of assessments. By associating attribute values with assessment forms, developers and users can enhance the
   * understanding and utilization of assessment structures, facilitating better insights and operational efficiencies.
   */
  AssessmentFormAttributeValue: 'AssessmentFormAttributeValue',
  /**
   * A model representing the association between a legal hold and its corresponding attribute values within the system. The `LegalHoldAttributeValue`
   * model is used to assign metadata or characteristics to legal holds, such as reasons, dates, or other relevant attributes that can aid in
   * organizing, searching, or filtering legal holds within the platform. This additional layer of information allows users to manage and analyze
   * legal holds more effectively, enhancing the overall legal compliance process.
   */
  LegalHoldAttributeValue: 'LegalHoldAttributeValue',
  /**
   * A model representing the association between a legal matter and its specific attribute values within the system. The `LegalMatterAttributeValue`
   * model is used to tag legal matters with customizable metadata, enabling more refined categorization, organization, and management of legal
   * matters. By associating attribute values with legal matters, developers and legal teams can enhance the understanding and utilization of legal
   * structures, facilitating better insights and operational efficiencies.
   */
  LegalMatterAttributeValue: 'LegalMatterAttributeValue',
  /**
   * This model, IdentifierAttributeValue, represents a key-value pair association between an identifier and its specific attribute values. It is used
   * to tag identifiers with customizable metadata, enabling more refined categorization, organization, and management of identifiers within the
   * system. By associating attribute values with identifiers, developers and users can enhance the understanding and utilization of identifier
   * structures, facilitating better insights and operational efficiencies.
   */
  IdentifierAttributeValue: 'IdentifierAttributeValue',
  /**
   * The `SubjectAttributeValue` model represents a key-value pair association between a data subject and its specific attribute values. It is used to
   * tag data subjects with customizable metadata, enabling more refined categorization, organization, and management of data subjects within the
   * system. By associating attribute values with data subjects, developers and users can enhance the understanding and utilization of data subject
   * structures, facilitating better insights and operational efficiencies.
   */
  SubjectAttributeValue: 'SubjectAttributeValue',
  /**
   * The `EnricherAttributeValue` model represents the association between an enricher and its specific attribute values within the system. This model
   * is used to tag enrichers with customizable metadata, enabling more refined categorization, organization, and management of enrichers. By
   * associating attribute values with enrichers, developers and users can enhance the understanding and utilization of enricher structures,
   * facilitating better insights and operational efficiencies.
   */
  EnricherAttributeValue: 'EnricherAttributeValue',
  /**
   * A model representing the association between an auditor run and its specific attribute values within the system. The `AuditorRunAttributeValue`
   * model is used to tag auditor runs with customizable metadata, enabling more refined categorization, organization, and management of auditor runs.
   * By associating attribute values with auditor runs, developers and users can enhance the understanding and utilization of auditor run structures,
   * facilitating better insights and operational efficiencies.
   */
  AuditorRunAttributeValue: 'AuditorRunAttributeValue',
  /**
   * This model represents the association between an auditor schedule and its specific attribute values within the system. The
   * `AuditorScheduleAttributeValue` model is used to tag auditor schedules with customizable metadata, enabling more refined categorization,
   * organization, and management of auditor schedules. By associating attribute values with auditor schedules, developers and users can enhance the
   * understanding and utilization of auditor schedule structures, facilitating better insights and operational efficiencies.
   */
  AuditorScheduleAttributeValue: 'AuditorScheduleAttributeValue',
  /** Join between a workflow config and a data silos */
  WorkflowConfigDataSilo: 'WorkflowConfigDataSilo',
  /** Join between a workflow config and identifier types */
  WorkflowConfigIdentifier: 'WorkflowConfigIdentifier',
  /** Workflow Config Data Point */
  WorkflowConfigDataPoint: 'WorkflowConfigDataPoint',
  /** Join between a workflow config and templates to handle communication */
  WorkflowConfigCommunication: 'WorkflowConfigCommunication',
  /** Relationship between workflow and enrichers for preflight/identifier sourcing */
  WorkflowConfigEnricher: 'WorkflowConfigEnricher',
  /** Self join on workflowJob to represent the workflow job execution dependency graph */
  WorkflowJobWorkflowJob: 'WorkflowJobWorkflowJob',
  /** Join between privacy center and organizations to represent the child organizations displayed in a parent multi-brand privacy center */
  PrivacyCenterChildOrganization: 'PrivacyCenterChildOrganization',
});

/**
 * Overload type for join db model names
 */
export type JoinModelName = (typeof JoinModelName)[keyof typeof JoinModelName];
/* eslint-enable max-lines */

import {
  DataCategoryType,
  IsoCountryCode,
  ProcessingPurpose,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { BusinessEntityInterface } from './businessEntityPreview';
import { DiscoveredByDataSiloPreview } from './dataSiloPreview';
import { DataInventoryCategory } from './dataSubCategory';
import { BusinessEntityOrderField } from './enums';
import { PurposeSubCategoryPreview } from './processingPurpose';
import { SaaSCategoryBase } from './saaSCategoryBase';
import { VendorPreview } from './vendorPreview';

export const DeleteBusinessEntitiesInput = mkInput({
  name: 'DeleteBusinessEntitiesInput',
  comment: 'Input for deleting business entities',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'businessEntity',
      list: true,
    },
  },
});

/** Override type */
export type DeleteBusinessEntitiesInput = SchemaToType<
  typeof DeleteBusinessEntitiesInput
>;

export const BusinessEntitiesFiltersInput = mkInput({
  name: 'BusinessEntitiesFiltersInput',
  comment: 'Filters for business entities',
  fields: {
    ids: {
      comment: 'The ids of the business entities to return',
      type: 'id',
      modelName: 'businessEntity',
      list: true,
      optional: true,
    },
    text: {
      comment: 'Find any business entities are iLike a text string',
      optional: true,
      type: 'string',
    },
    dataCategories: {
      comment: 'Filter by categories of personal data',
      type: { DataCategoryType },
      list: true,
      optional: true,
    },
    dataSubCategoryIds: {
      comment: 'Filter by specific subcategories of personal data',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    purposes: {
      comment: 'Filter by purpose of processing',
      type: { ProcessingPurpose },
      list: true,
      optional: true,
    },
    processingPurposeSubCategoryIds: {
      comment: 'Filter by specific subcategory of processing purpose',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      optional: true,
      list: true,
    },
    headquarterCountry: {
      comment:
        'Filter by ISO country code where the business entity is headquartered',
      type: { IsoCountryCode },
      optional: true,
      list: true,
    },
    headquarterSubDivision: {
      comment:
        'Filter by ISO country subdivision where the business entity is headquartered',
      type: 'string',
      optional: true,
      list: true,
    },
    saaSCategoryIds: {
      comment:
        'Filter by IDs of recipient categories associated with the catalogs of silos belonging to this business entity',
      optional: true,
      type: 'id',
      modelName: 'saaSCategory',
      list: true,
    },
    dataSiloIds: {
      comment:
        "Filter by data silos belonging to the business entity's vendors",
      optional: true,
      type: 'id',
      modelName: 'dataSilo',
      list: true,
    },
    ownerIds: {
      comment: 'The ids of the users that own the business entity',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    attributeValueIds: {
      comment: 'The attribute values used to label the business entity',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this business entity',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type BusinessEntitiesFiltersInput = SchemaToType<
  typeof BusinessEntitiesFiltersInput
>;

export const CreateBusinessEntityInput = mkInput({
  name: 'CreateBusinessEntityInput',
  comment: 'Input for creating a business entity',
  fields: {
    title: {
      comment: 'The title of the business entity',
      type: 'string',
    },
    description: {
      comment: 'The description of the business entity',
      type: 'string',
      optional: true,
    },
    address: {
      comment: 'Address of entity',
      optional: true,
      type: 'string',
    },
    dataProtectionOfficerName: {
      comment: 'The name of the DPO',
      optional: true,
      type: 'string',
    },
    dataProtectionOfficerEmail: {
      comment: 'The email address of the DPO',
      optional: true,
      type: 'string',
    },
    headquarterCountry: {
      comment: 'The country code of the headquarters location of the entity',
      optional: true,
      type: { IsoCountryCode },
    },
    headquarterSubDivision: {
      comment: 'ISO country where business entity headquarters is located',
      type: 'string',
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this business entity',
      type: AttributeInput,
      list: true,
      optional: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this business entity',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this business entity',
      type: 'string',
      list: true,
      optional: true,
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this business entity',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment:
        'The email addresses of the users to assign as owners of this business entity',
      type: 'string',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type CreateBusinessEntityInput = SchemaToType<
  typeof CreateBusinessEntityInput
>;

export const UpdateBusinessEntityInput = mkInput({
  name: 'UpdateBusinessEntityInput',
  comment: 'Input for updating a business entity',
  fields: {
    id: {
      comment: 'The business entity ID to update',
      type: 'id',
      modelName: 'businessEntity',
    },
    title: {
      ...CreateBusinessEntityInput.fields.title,
      optional: true,
    },
    description: {
      ...CreateBusinessEntityInput.fields.description,
      optional: true,
    },
    address: CreateBusinessEntityInput.fields.address,
    dataProtectionOfficerName:
      CreateBusinessEntityInput.fields.dataProtectionOfficerName,
    dataProtectionOfficerEmail:
      CreateBusinessEntityInput.fields.dataProtectionOfficerEmail,
    headquarterCountry: CreateBusinessEntityInput.fields.headquarterCountry,
    headquarterSubDivision:
      CreateBusinessEntityInput.fields.headquarterSubDivision,
    attributes: CreateBusinessEntityInput.fields.attributes,
    teamIds: CreateBusinessEntityInput.fields.teamIds,
    teamNames: CreateBusinessEntityInput.fields.teamNames,
    ownerIds: CreateBusinessEntityInput.fields.ownerIds,
    ownerEmails: CreateBusinessEntityInput.fields.ownerEmails,
  },
});

/** Override type */
export type UpdateBusinessEntityInput = SchemaToType<
  typeof UpdateBusinessEntityInput
>;

export const UpdateBusinessEntitiesInput = mkInput({
  name: 'UpdateBusinessEntitiesInput',
  comment: 'Input for bulk updating one or more business entities',
  fields: {
    businessEntities: {
      comment:
        'List of data silos with the properties that should be updated for each',
      type: UpdateBusinessEntityInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateBusinessEntitiesInput = SchemaToType<
  typeof UpdateBusinessEntitiesInput
>;

export const BusinessEntity = mkType({
  name: 'BusinessEntity',
  comment:
    'A business entity is an affiliate organization or organization unit within a company.',
  interfaces: [BusinessEntityInterface],
  fields: {
    ...BusinessEntityInterface.fields,
    dataSubCategories: {
      comment:
        'The set of data categories assigned to the sub-datapoints of this business entity',
      type: DataInventoryCategory,
      list: true,
    },
    processingPurposeSubCategories: {
      comment:
        'The set of processing purposes assigned to the sub-datapoints of this business entity',
      type: PurposeSubCategoryPreview,
      list: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this business entity',
      type: AttributeValue,
      list: true,
    },
    vendors: {
      comment: 'The vendors related to the business entity',
      type: VendorPreview,
      list: true,
    },
    dataSilos: {
      comment: 'The data silos related to the business entity',
      type: DiscoveredByDataSiloPreview,
      list: true,
    },
    catalogRecipients: {
      comment:
        "The pre-labeled recipient categories for catalogs associated with the business entity's data silos",
      type: SaaSCategoryBase,
      list: true,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this business entity',
      list: true,
      type: TeamPreview,
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this business entity',
      list: true,
      type: UserPreview,
    },
  },
});

/** Override type */
export type BusinessEntity = SchemaToType<typeof BusinessEntity>;

/** Order for a business entities query */
export const BusinessEntityOrder = mkOrder(
  BusinessEntity.name,
  BusinessEntityOrderField,
);

/** Override type */
export type BusinessEntityOrder = SchemaToType<typeof BusinessEntityOrder>;

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const EmailLetterMailNotification: React.FC<SvgProps> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 9.16663V13.3333C17.5 15.1742 16.0076 16.6666 14.1667 16.6666H5.83333C3.99238 16.6666 2.5 15.1742 2.5 13.3333V7.49996C2.5 5.65901 3.99238 4.16663 5.83333 4.16663H12.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="16.875"
      cy="4.79163"
      r="1.875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 8.2789L10.6648 9.43278C10.2411 9.61601 9.76035 9.61529 9.33719 9.43079L5.83331 7.90308"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Profile: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9.00375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M16.0016 16.0017C15.8853 15.7089 15.7048 15.4461 15.4734 15.2323V15.2323C15.0835 14.87 14.5711 14.6684 14.0388 14.6681H9.96211C9.42955 14.6684 8.91678 14.87 8.52651 15.2323V15.2323C8.29566 15.4465 8.11524 15.7093 7.99829 16.0017"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="10.2493"
      r="2.25094"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagDM: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54985"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54985)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#279E19"
      />
      <mask
        id="mask1_270_54985"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54985)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0H9V7H16V8H9V12H8V8H0V7H8V0Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 0H8V6H16V7H8V12H7V7H0V6H7V0Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0H7V5H16V6H7V12H6V6H0V5H6V0Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 10C10.2091 10 12 8.20914 12 6C12 3.79086 10.2091 2 8 2C5.79086 2 4 3.79086 4 6C4 8.20914 5.79086 10 8 10Z"
          fill="#C51918"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.36296 4.20646C7.36296 4.20646 7.55293 3.59 7.99424 3.90847C8.68627 4.24932 8.91453 4.64091 9.1817 5.08436C9.44887 5.52782 8.52665 5.20304 8.35461 4.95346C8.18256 4.70387 7.22824 4.62294 7.36296 4.4147C7.49768 4.20646 7.36296 4.20646 7.36296 4.20646Z"
          fill="#804BFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.2066 7.3627C7.2066 7.3627 6.73676 8.00545 7.2066 8.00545C7.67645 8.00545 9.5813 7.86717 9.35298 7.68407C9.12467 7.50098 7.43236 7.68407 7.43236 7.68407L7.2066 7.3627Z"
          fill="#A95601"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.9091 7.34247C7.86038 7.28752 7.0043 5.34491 7.40233 4.94116C7.80036 4.53741 8.03361 4.77465 8.03361 5.38007C8.03361 5.98549 8.05754 7.49207 7.9091 7.34247Z"
          fill="#804BFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.85874 7.02721C7.85874 7.02721 7.80934 7.49941 8.05217 8.05064C8.29501 8.60187 8.7855 8.59125 8.7855 8.14007C8.7855 7.6889 9.14349 8.11231 9.28093 8.41342C9.41837 8.71453 9.06243 5.73957 8.5573 5.07401C8.05217 4.40845 7.5648 4.36572 7.71177 4.79581C7.85874 5.22591 7.85874 7.02721 7.85874 7.02721Z"
          fill="#279E19"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.56366 3.79999C7.56366 3.79999 7.34998 3.93701 7.34998 4.31441C7.34998 4.69182 7.8559 4.19756 7.8559 4.19756L7.56366 3.79999Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.60918 3.65675L8.05002 3.42499L8.49086 3.65675L8.40667 3.16587L8.76332 2.81823L8.27044 2.74661L8.05002 2.29999L7.8296 2.74661L7.33673 2.81823L7.69338 3.16587L7.60918 3.65675ZM7.60918 9.55674L8.05002 9.32498L8.49086 9.55674L8.40667 9.06586L8.76332 8.71822L8.27044 8.6466L8.05002 8.19998L7.8296 8.6466L7.33673 8.71822L7.69338 9.06586L7.60918 9.55674ZM10.4092 6.75674L10.4934 6.26586L10.1367 5.91822L10.6296 5.8466L10.85 5.39998L11.0704 5.8466L11.5633 5.91822L11.2067 6.26586L11.2909 6.75674L10.85 6.52498L10.4092 6.75674ZM4.70922 6.75674L5.15006 6.52498L5.5909 6.75674L5.50671 6.26586L5.86335 5.91822L5.37048 5.8466L5.15006 5.39998L4.92964 5.8466L4.43677 5.91822L4.79341 6.26586L4.70922 6.75674ZM6.23398 4.35098L6.09446 4.82909L5.79984 4.42753L5.30202 4.44259L5.59288 4.03829L5.42472 3.56949L5.89911 3.72118L6.29301 3.41639L6.29533 3.91443L6.70693 4.19486L6.23398 4.35098ZM10.3386 8.73L10.4781 8.25189L10.951 8.09578L10.5394 7.81535L10.5371 7.3173L10.1432 7.6221L9.66882 7.4704L9.83698 7.93921L9.54612 8.3435L10.0439 8.32845L10.3386 8.73ZM10.409 4.29271L10.2694 4.77082L9.97482 4.36926L9.477 4.38431L9.76786 3.98002L9.5997 3.51122L10.0741 3.66291L10.468 3.35811L10.4703 3.85616L10.8819 4.13659L10.409 4.29271ZM6.21079 8.87102L6.35031 8.39291L6.82325 8.23679L6.41166 7.95636L6.40933 7.45832L6.01544 7.76311L5.54105 7.61142L5.7092 8.08022L5.41834 8.48452L5.91616 8.46946L6.21079 8.87102Z"
          fill="#FECA00"
        />
      </g>
    </g>
  </svg>
);

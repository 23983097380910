import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Rejected: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4.94333 13.61L2.39067 11.0573C2.14067 10.8073 2 10.468 2 10.1147V5.88533C2 5.532 2.14067 5.19267 2.39 4.94333L4.94267 2.39067C5.19267 2.14067 5.532 2 5.88533 2H10.1133C10.4673 2 10.8067 2.14067 11.0567 2.39067L13.6087 4.94267C13.8593 5.19333 14 5.53333 14 5.88733V10.114C14 10.4673 13.8593 10.806 13.61 11.056L11.0573 13.6093C10.8073 13.8593 10.468 14 10.114 14H5.88533C5.532 14 5.19267 13.8593 4.94333 13.61V13.61Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.83398 12.166L12.166 3.83398"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ListTree: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H16V16H0V0Z"
      fill="white"
    />
    <path
      d="M5.99999 4.66671H2.66666"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.99999 12H3.99999C3.26361 12 2.66666 11.403 2.66666 10.6667V2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8.95955 2.40937L9.74266 1.62627C9.93019 1.43873 10.1845 1.33338 10.4498 1.33337H12.3333C12.8856 1.33337 13.3333 1.78109 13.3333 2.33337V5.66671C13.3333 6.21899 12.8856 6.66671 12.3333 6.66671H9.66666C9.11437 6.66671 8.66666 6.21899 8.66666 5.66671V3.11647C8.66666 2.85126 8.77202 2.59691 8.95955 2.40937Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8.95955 9.74263L9.74266 8.95952C9.93019 8.77198 10.1845 8.66663 10.4498 8.66663H12.3333C12.8856 8.66663 13.3333 9.11434 13.3333 9.66663V13C13.3333 13.5522 12.8856 14 12.3333 14H9.66666C9.11437 14 8.66666 13.5522 8.66666 13V10.4497C8.66666 10.1845 8.77202 9.93016 8.95955 9.74263V9.74263Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-disable max-lines */
import {
  EnricherType,
  PreflightRequestStatus,
  RequestAction,
} from '@transcend-io/privacy-types';

import { UserPreview } from '@main/access-control-types';
import { AttributeValue } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { CustomHeader, CustomHeaderInput } from './customHeader';
import { DataSiloPreview } from './dataSiloPreview';
import { DataSubject } from './dataSubject';
import { EnricherCrossReferenceIdentifier } from './EnricherCrossReferenceIdentifier';
import { EnricherPreview } from './enricherPreview';
import { EnricherOrderField } from './enums';
import { WiredIdentifier } from './identifier';
import { Template } from './template';

export const SignedIdentifierPathInput = mkInput({
  name: 'SignedIdentifierPathInput',
  comment: 'An identifier name mapped to a JSON path for enrichment purposes',
  fields: {
    identifierName: {
      comment: 'The name of the identifier',
      type: 'string',
    },
    paths: {
      comment: 'JSON paths to resolve identifier',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type SignedIdentifierPathInput = SchemaToType<
  typeof SignedIdentifierPathInput
>;

export const SignedIdentifierPathSchema = mkType({
  name: 'SignedIdentifierPath',
  comment: 'An identifier name mapped to a JSON path for enrichment purposes',
  fields: {
    identifierName: SignedIdentifierPathInput.fields.identifierName,
    paths: SignedIdentifierPathInput.fields.paths,
  },
});

/** Override type */
export type SignedIdentifierPathSchema = SchemaToType<
  typeof SignedIdentifierPathSchema
>;

export const EnricherFiltersInput = mkInput({
  name: 'EnricherFiltersInput',
  comment: 'Filters for the paginated enrichers list',
  fields: {
    text: {
      comment: 'Search text',
      optional: true,
      type: 'string',
    },
    type: {
      comment: 'The type of enrichers',
      optional: true,
      list: true,
      type: { EnricherType },
    },
    actionTypes: {
      comment: 'The actions that this enricher runs for',
      optional: true,
      list: true,
      type: { RequestAction },
    },
    dataSubjectIds: {
      comment:
        'The IDs of the explicit data subjects that the enricher should run for',
      optional: true,
      modelName: 'subject',
      list: true,
      type: 'id',
    },
  },
});

/** Override type */
export type EnricherFiltersInput = SchemaToType<typeof EnricherFiltersInput>;

export const EnricherVerificationMapInput = mkInput({
  name: 'EnricherVerificationMapInput',
  comment: 'Map of Stripe verification output keys to identifier ids',
  fields: {
    crossReferenceKey: {
      comment: 'The key of the verification output',
      type: 'string',
    },
    identifierId: {
      comment:
        'The identifier id that the verification output should be mapped to',
      modelName: 'identifier',
      type: 'id',
    },
  },
});

/** Override type */
export type EnricherVerificationMapInput = SchemaToType<
  typeof EnricherVerificationMapInput
>;

export const UpdateEnricherInput = mkInput({
  name: 'UpdateEnricherInput',
  comment: 'Input for updating an enricher',
  fields: {
    id: {
      comment: 'The id of the enricher configuration',
      modelName: 'enricher',
      type: 'id',
    },
    title: {
      comment: "The enricher's title",
      optional: true,
      type: 'string',
    },
    description: {
      comment: "The enricher's description",
      optional: true,
      type: 'string',
    },
    userId: {
      comment: 'The id of the user responsible',
      optional: true,
      modelName: 'user',
      type: 'id',
    },
    dataSiloId: {
      comment:
        'The ID of the related data silo (i.e. looker or twilio integration',
      optional: true,
      modelName: 'dataSilo',
      type: 'id',
    },
    dataSubjectIds: {
      comment:
        'The IDs of the explicit data subjects that the enricher should run for',
      optional: true,
      modelName: 'subject',
      list: true,
      type: 'id',
    },
    signedIdentifierInputs: {
      comment:
        'The mapping between identifier name and JSON path for identifier',
      type: SignedIdentifierPathInput,
      list: true,
      optional: true,
    },
    inputIdentifier: {
      comment:
        'The ID of the identifier that will be the input to the enricher',
      modelName: 'identifier',
      optional: true,
      type: 'id',
    },
    headers: {
      comment: 'Custom headers to include in outbound webhook',
      list: true,
      optional: true,
      type: CustomHeaderInput,
    },
    identifiers: {
      comment:
        'The ids of the identifiers that can possibly be output from the enricher',
      optional: true,
      list: true,
      modelName: 'identifier',
      type: 'id',
    },
    url: {
      comment: 'The url that the enricher should post to when a server',
      optional: true,
      type: 'string',
    },
    testRegex: {
      comment:
        'A regular expression that can be used to match on for cancelation',
      optional: true,
      type: 'string',
    },
    regionList: {
      comment:
        'The list of regions that should trigger the enrichment condition',
      list: true,
      optional: true,
      type: 'string',
    },
    expirationDuration: {
      comment: 'The duration (in ms) that the enricher should take to execute.',
      optional: true,
      type: 'string',
    },
    reminderTemplate1Duration: {
      comment:
        'The duration (in ms) to wait between sending verification and first reminder.',
      optional: true,
      type: 'string',
    },
    reminderTemplate2Duration: {
      comment:
        'The duration (in ms) to wait between sending first reminder and second reminder.',
      optional: true,
      type: 'string',
    },
    reminderTemplate3Duration: {
      comment:
        'The duration (in ms) to wait between sending second reminder and third reminder.',
      optional: true,
      type: 'string',
    },
    transitionRequestStatus: {
      comment:
        'The status that the enricher should transfer to when condition is met.',
      optional: true,
      type: { PreflightRequestStatus },
    },
    templateId: {
      comment: 'The ID of template to use for that identifier verification',
      optional: true,
      modelName: 'template',
      type: 'id',
    },
    continuationTemplateId: {
      comment:
        'The ID of template to use for notifying data subject about continuing request without an identifier',
      optional: true,
      modelName: 'template',
      type: 'id',
    },
    requestVerificationFailedTemplateId: {
      comment:
        'The ID of template to use for notifying data subject about their request being canceled',
      optional: true,
      modelName: 'template',
      type: 'id',
    },
    actions: {
      comment: 'The action types that the enricher should run for',
      list: true,
      optional: true,
      type: { RequestAction },
    },
    phoneNumbers: {
      comment: 'The twilio phone number to send from',
      optional: true,
      type: 'string',
      list: true,
    },
    lookerQueryTitle: {
      comment: 'Title of the looker query to run for this enricher',
      optional: true,
      type: 'string',
    },
    lookerQuerySlug: {
      comment: 'Slug of the looker query to run for this enricher',
      optional: true,
      type: 'string',
    },
    enricherDependencyIds: {
      comment: 'IDs of the enricher dependency',
      list: true,
      optional: true,
      type: 'id',
      modelName: 'enricher',
    },
    verificationMap: {
      comment: 'Map of verification output keys to identifier ids',
      optional: true,
      list: true,
      type: EnricherVerificationMapInput,
    },
  },
});

/** Override type */
export type UpdateEnricherInput = SchemaToType<typeof UpdateEnricherInput>;

export const EnricherInput = mkInput({
  name: 'EnricherInput',
  comment: 'Input for creating a new enricher',
  fields: {
    type: {
      comment: 'The type of enricher',
      type: { EnricherType },
    },
    title: {
      comment: 'The title of the enricher for reference',
      type: 'string',
    },
    description: {
      comment: 'The description of the enricher',
      type: 'string',
    },
    dataSiloId: UpdateEnricherInput.fields.dataSiloId,
    url: UpdateEnricherInput.fields.url,
    dataSubjectIds: UpdateEnricherInput.fields.dataSubjectIds,
    signedIdentifierInputs: UpdateEnricherInput.fields.signedIdentifierInputs,
    testRegex: UpdateEnricherInput.fields.testRegex,
    regionList: UpdateEnricherInput.fields.regionList,
    expirationDuration: UpdateEnricherInput.fields.expirationDuration,
    reminderTemplate1Duration:
      UpdateEnricherInput.fields.reminderTemplate1Duration,
    reminderTemplate2Duration:
      UpdateEnricherInput.fields.reminderTemplate2Duration,
    reminderTemplate3Duration:
      UpdateEnricherInput.fields.reminderTemplate3Duration,
    transitionRequestStatus: UpdateEnricherInput.fields.transitionRequestStatus,
    templateId: UpdateEnricherInput.fields.templateId,
    continuationTemplateId: UpdateEnricherInput.fields.continuationTemplateId,
    requestVerificationFailedTemplateId:
      UpdateEnricherInput.fields.requestVerificationFailedTemplateId,
    userId: {
      comment:
        'The id of the user that is responsible for the enricher when a person enricher',
      optional: true,
      modelName: 'user',
      type: 'id',
    },
    inputIdentifier: {
      comment:
        'The ID of the identifier that will be the input to the enricher',
      modelName: 'identifier',
      type: 'id',
    },
    headers: UpdateEnricherInput.fields.headers,
    identifiers: {
      comment:
        'The IDs of the identifiers that can possibly be output from the enricher',
      list: true,
      modelName: 'identifier',
      type: 'id',
    },
    actions: {
      comment: 'The action types that the enricher should run for',
      list: true,
      type: { RequestAction },
    },
    phoneNumbers: {
      comment: 'Phone numbers from twilio',
      optional: true,
      type: 'string',
      list: true,
    },
    lookerQueryTitle: {
      comment: 'Title of the looker query to run for this enricher',
      optional: true,
      type: 'string',
    },
    lookerQuerySlug: {
      comment: 'Slug of the looker query to run for this enricher',
      optional: true,
      type: 'string',
    },
    enricherDependencyIds: {
      comment: 'IDs of the enricher dependency',
      list: true,
      optional: true,
      type: 'id',
      modelName: 'enricher',
    },
    verificationMap: UpdateEnricherInput.fields.verificationMap,
  },
});

/** Override type */
export type EnricherInput = SchemaToType<typeof EnricherInput>;

export const Enricher = mkType({
  name: 'Enricher',
  comment:
    'An enricher is takes in an identifier, and resolves additional identifiers keyed to the input identifier.',
  fields: {
    id: {
      comment: 'The unique id of the enricher',
      modelName: 'enricher',
      type: 'id',
    },
    title: {
      comment: 'The identifying title of the enricher',
      type: 'string',
    },
    description: {
      comment: 'The description of the enricher',
      type: 'string',
    },
    logo: {
      comment: 'The logo icon display',
      type: 'string',
    },
    user: {
      comment: 'The user that is responsible for resolving the enricher',
      optional: true,
      type: UserPreview,
    },
    identifiers: {
      comment: 'The identifier that can be output from the enricher',
      list: true,
      type: WiredIdentifier,
    },
    headers: {
      comment: 'Custom headers to include in outbound webhook',
      list: true,
      type: CustomHeader,
    },
    signedIdentifierPaths: {
      comment: 'The mapping of identifier name to identifier JSON path',
      list: true,
      type: SignedIdentifierPathSchema,
    },
    type: {
      comment: 'The type of enricher',
      type: { EnricherType },
    },
    url: {
      comment: 'The url of the enricher (when an api request)',
      optional: true,
      type: 'string',
    },
    inputIdentifier: {
      comment:
        'The identifier that is the input to the enricher, null if initializer',
      optional: true,
      type: WiredIdentifier,
    },
    phoneNumbers: {
      comment: 'The twilio phone number to send from',
      type: 'string',
      list: true,
    },
    lookerQueryTitle: EnricherInput.fields.lookerQueryTitle,
    lookerQuerySlug: EnricherInput.fields.lookerQuerySlug,
    dataSilo: {
      comment: 'The related data silo',
      optional: true,
      type: DataSiloPreview,
    },
    testRegex: EnricherInput.fields.testRegex,
    regionList: {
      comment:
        'The list of regions that should trigger the enrichment condition',
      list: true,
      type: 'string',
    },
    expirationDuration: {
      comment: 'The duration (in ms) that the enricher should take to execute.',
      type: 'string',
    },
    reminderTemplate1Duration: {
      comment:
        'The duration (in ms) to wait between sending verification and first reminder.',
      type: 'string',
    },
    reminderTemplate2Duration: {
      comment:
        'The duration (in ms) to wait between sending first reminder and second reminder.',
      type: 'string',
    },
    reminderTemplate3Duration: {
      comment:
        'The duration (in ms) to wait between sending second reminder and third reminder. ' +
        'As well as any other reminder until the expiration occurs.',
      type: 'string',
    },
    transitionRequestStatus: EnricherInput.fields.transitionRequestStatus,
    template: {
      comment:
        'The communication template that is used to verify the identifier, or run cancelation/on hold',
      optional: true,
      type: Template,
    },
    continuationTemplate: {
      comment:
        'The communication template that is used when continuing the request after failure to verify',
      optional: true,
      type: Template,
    },
    requestVerificationFailedTemplate: {
      comment:
        'The communication template that is used when canceling the request after failure to verify',
      optional: true,
      type: Template,
    },
    dataSubjects: {
      comment: 'The data subjects explicitly configured for this enricher',
      type: DataSubject,
      list: true,
    },
    actions: {
      comment: 'The actions that this enricher runs for',
      list: true,
      type: { RequestAction },
    },
    errorCount: {
      comment: 'The number of open errors with the enricher',
      type: 'int',
    },
    enricherDependencies: {
      comment: 'The enricher dependencies for the enricher',
      list: true,
      optional: true,
      type: EnricherPreview,
    },
    sqlQuery: {
      comment: 'SQL query to run for this enricher',
      optional: true,
      type: 'string',
    },
    EnricherCrossReferenceIdentifiers: {
      comment: 'Test the enricher',
      type: EnricherCrossReferenceIdentifier,
      optional: true,
      list: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type Enricher = SchemaToType<typeof Enricher>;

/**
 * Order for a enrichers query
 */
export const EnricherOrder = mkOrder(Enricher.name, EnricherOrderField);

/** Override type */
export type EnricherOrder = SchemaToType<typeof EnricherOrder>;

export const FetchEnrichmentMetadataInput = mkInput({
  name: 'FetchEnrichmentMetadataInput',
  comment: 'Input for fetching metadata for an enricher',
  fields: {
    inputIdentifierName: {
      comment: 'Name of input identifier to fetch metadata for',
      type: 'string',
    },
    lookerQuerySlug: {
      comment: 'Slug of the looker query',
      type: 'string',
    },
  },
});

/** Override type */
export type FetchEnrichmentMetadataInput = SchemaToType<
  typeof FetchEnrichmentMetadataInput
>;

export const IdentifierJsonPath = mkType({
  name: 'IdentifierJsonPath',
  comment: 'JSON path metadata for enrichment redaction',
  fields: {
    id: {
      comment: 'Unique identifier for resource with JSON path',
      type: 'string',
    },
    jsonPaths: {
      comment: 'JSON paths that are needed to fetch that resource',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type IdentifierJsonPath = SchemaToType<typeof IdentifierJsonPath>;

export const EnrichmentMetadata = mkType({
  name: 'EnrichmentMetadata',
  comment: 'Metadata used for configuring an enricher',
  fields: {
    identifierJsonPaths: {
      comment: 'The JSON paths that can be configured for that enricher',
      list: true,
      type: IdentifierJsonPath,
    },
  },
});

/** Override type */
export type EnrichmentMetadata = SchemaToType<typeof EnrichmentMetadata>;
/* eslint-enable max-lines */

import { Message } from '@main/internationalization';
import {
  applyFieldsEnum,
  mkInput,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';

import {
  AssessmentActionType,
  DefaultTemplateType,
  TemplateOrderField,
  TemplateProduct,
  TemplateType,
} from './enums';

export const TemplateFiltersInput = mkInput({
  name: 'TemplateFiltersInput',
  comment: 'Filter the custom message templates',
  fields: {
    text: {
      comment:
        'Filter for text matches in the title, subject, or message template',
      optional: true,
      type: 'string',
    },
    product: {
      comment: 'Filter for the product this template is used for',
      optional: true,
      type: { TemplateProduct },
    },
    assessmentActionType: {
      comment: 'Filter for the assessment action the template is used for',
      optional: true,
      type: { AssessmentActionType },
    },
  },
});

/** Override type */
export type TemplateFiltersInput = SchemaToType<typeof TemplateFiltersInput>;

export const TemplateInput = mkInput({
  name: 'TemplateInput',
  comment: 'Input for creating a new message/email template',
  fields: {
    template: {
      comment: 'The actual template contents.',
      type: 'string',
    },
    title: {
      comment: 'The title of the template for lookup purposes',
      type: 'string',
    },
    subject: {
      comment: 'The subject message for the email communication',
      type: 'string',
    },
    type: {
      comment: 'The title of the template for lookup purposes',
      type: { TemplateType },
    },
    product: {
      comment: 'The product this template is used for',
      type: { TemplateProduct },
    },
    assessmentActionType: {
      optional: true,
      comment: 'The assessment action the template is used for',
      type: { AssessmentActionType },
    },
  },
});

/** Override type */
export type TemplateInput = SchemaToType<typeof TemplateInput>;

export const UpdateTemplateInput = mkInput({
  name: 'UpdateTemplateInput',
  comment: 'Input for updating an existing message template',
  fields: {
    id: {
      comment: 'The id of the template to update',
      modelName: 'template',
      type: 'id',
    },
    template: {
      optional: true,
      comment: TemplateInput.fields.template.comment,
      type: 'string',
    },
    title: {
      optional: true,
      comment: TemplateInput.fields.title.comment,
      type: 'string',
    },
    subject: {
      comment: TemplateInput.fields.subject.comment,
      optional: true,
      type: 'string',
    },
    type: {
      optional: true,
      comment: TemplateInput.fields.template.comment,
      type: { TemplateType },
    },
  },
});

/** Override type */
export type UpdateTemplateInput = SchemaToType<typeof UpdateTemplateInput>;

export const Template = mkType({
  name: 'Template',
  comment:
    'An email template to send and receive communication with the data subject',
  fields: {
    id: {
      comment: 'The unique id of the template',
      modelName: 'template',
      type: 'id',
    },
    title: {
      comment: 'The title of the template for lookup purposes',
      type: 'string',
    },
    template: {
      comment: 'The actual template contents.',
      type: Message,
    },
    subject: {
      comment: 'The subject message for the email communication',
      type: Message,
    },
    isRequired: {
      comment:
        'Indicates if the template is in use, and thus cannot be destroyed',
      type: 'boolean',
    },
    type: {
      comment: 'The title of the template for lookup purposes',
      type: { TemplateType },
    },
    assessmentActionType: {
      comment: 'The assessment action the template is used for',
      optional: true,
      type: { AssessmentActionType },
    },
  },
});

/** Override type */
export type Template = SchemaToType<typeof Template>;

export const LocalizedTemplate = mkType({
  name: 'LocalizedTemplate',
  comment: 'An email template localized',
  fields: {
    id: {
      comment: 'The unique id of the template',
      modelName: 'template',
      type: 'id',
    },
    title: {
      comment: 'The title of the template for lookup purposes',
      type: 'string',
    },
    template: {
      comment: 'The actual template contents.',
      type: 'string',
    },
    subject: {
      comment: 'The subject message for the email communication',
      type: 'string',
    },
  },
});

/** Override type */
export type LocalizedTemplate = SchemaToType<typeof LocalizedTemplate>;

export const DefaultTemplates = mkType({
  name: 'DefaultTemplates',
  comment:
    'Special email templates used in specific parts of the DSR lifecycle',
  fields: applyFieldsEnum(DefaultTemplateType, (type) => ({
    comment: {
      cancelationTemplate: 'The template to use when canceling a request',
      promptAVendor:
        'The template to use when prompting vendors for DSR actions',
      externalBulkRequestVendorCoordination:
        'The template to use when prompting vendors for DSR actions',
      notFoundTemplate:
        'The response to use when a request finds no data or performs no action',
      onHoldTemplate: 'The response to use when a request is placed on hold',
      resumeTemplate: 'The response to use when a request is resumed',
      failedVerificationTemplate:
        'The response to use when a request fails verification',
      failedToOpenTemplate:
        'The email to send when a download request expires un-opened',
      additionalTimeTemplate: `The default template to use when the data subject needs to be notified
      that more time is needed to fulfill their request`,
      revokedTemplate:
        'The template to send to the data subject when they cancel their request',
      receiptTemplate:
        'The message to send the data subject when they submit a request',
      emailLoginTemplate: 'The email to send to login',
      governmentIdMessageTemplate:
        'The email to send for government ID verification',
    }[type],
    type: Template,
  })),
});

/** Override type */
export type DefaultTemplates = SchemaToType<typeof DefaultTemplates>;

/**
 * Order for a Template query
 */
export const TemplateOrder = mkOrder(Template.name, TemplateOrderField);

/** Override type */
export type TemplateOrder = SchemaToType<typeof TemplateOrder>;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DatabaseX: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 58.825C16.6667 64.9166 30.725 69.8541 48.0667 69.8541"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.1667 23.5291C79.1667 29.6207 65.175 34.5582 47.9167 34.5582C30.6583 34.5582 16.6667 29.6207 16.6667 23.5291"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.1667 41.175C79.1667 47.2667 65.175 52.2042 47.9167 52.2042C30.6583 52.2042 16.6667 47.2709 16.6667 41.175"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M48.0667 87.5C30.725 87.5 16.6667 82.5625 16.6667 76.4708V23.5292C16.6667 17.4375 30.6583 12.5 47.9167 12.5C65.175 12.5 79.1667 17.4375 79.1667 23.5292V54.1667"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.3333 70.8333L66.6667 87.4999"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.3333 87.4999L66.6667 70.8333"
      stroke={color}
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagAS: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54988"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54988)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        d="M0 -0.5H-0.5V0V5.6535L-1.424 6L-0.5 6.3465V12V12.5H0H16L16.1756 11.5318L1.424 6L16.1756 0.468165L16 -0.5H0Z"
        fill="#3547A0"
        stroke="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M14.0447 3.01361C14.0447 3.01361 10.0131 4.0604 10.0131 4.22147C10.0131 4.38254 9.65035 4.89289 9.72463 5.02611C9.79891 5.15933 9.99182 5.33589 10.1502 5.24761C10.3086 5.15933 10.6849 4.80461 10.5587 5.02611C10.4324 5.24761 11.2376 5.50515 10.8981 5.99432C10.8981 5.99432 11.306 5.75923 11.1085 7.09615L12.0794 7.37284C12.0794 7.37284 13.1636 7.08856 12.6687 6.27502H13.2434C13.2434 6.27502 13.0053 5.22077 13.3572 5.02611C13.7091 4.83145 12.9985 4.6835 12.9985 4.6835C14.5009 3.82824 14.0447 3.01361 14.0447 3.01361Z"
        fill="#C44800"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.3773 7.48524L10.9436 7.07394L10.6248 6.64728L9.24316 6.92548L10.4561 7.61259L10.8638 7.39315L13.3773 7.79444V7.48524Z"
        fill="#FFC221"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.7512 7.85939V7.61829L11.0225 7.45226C11.0225 7.45226 10.858 7.29999 10.4522 7.29999C10.0463 7.29999 10 7.61829 10 7.61829C10 7.61829 10.2327 8.08217 10.6839 8.08217C11.1352 8.08217 11.8369 8.19056 12.0269 8.41367C12.217 8.63679 13.0045 8.29905 13.0045 8.29905C13.0045 8.29905 12.668 7.72025 12.2362 7.90121C11.8045 8.08217 11.0225 7.81356 11.0225 7.71592C11.0225 7.61829 13.7512 7.85939 13.7512 7.85939Z"
        fill="#FFC221"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9.14267 5.9071C9.14267 5.9071 8.87815 6.47404 8.97419 6.68841C9.39668 7.12961 10.1647 6.42678 10.1647 6.42678L9.14267 5.9071Z"
        fill="#FFC221"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.3138 6.17753C13.5944 6.03316 13.7376 5.76578 13.7376 5.41699C13.7376 5.1485 13.6737 4.93638 13.5324 4.8008V4.61776L13.2847 4.61549C11.0653 4.59512 9.81577 4.81473 9.45362 5.36415C9.08739 5.91976 9.11935 6.39436 9.61782 6.62472L9.83834 6.61943C10.9158 6.05725 12.0122 5.91769 13.1391 6.19783L13.3138 6.17753ZM9.66236 5.50173C9.95478 5.0581 11.1614 4.84601 13.2824 4.86548V4.92853C13.4192 4.99419 13.4876 5.15701 13.4876 5.41699C13.4876 5.67698 13.3916 5.85639 13.1994 5.95521C12.0126 5.66017 10.8536 5.80769 9.72269 6.39778C9.39005 6.24405 9.36994 5.94537 9.66236 5.50173Z"
        fill="#979797"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.2824 4.86549C11.1614 4.84602 9.95478 5.05811 9.66236 5.50174C9.36993 5.94538 9.39005 6.24406 9.72269 6.39779C10.8536 5.8077 12.0126 5.66018 13.1994 5.95522C13.3916 5.8564 13.4876 5.67699 13.4876 5.417C13.4876 5.15702 13.4192 4.99419 13.2824 4.92854V4.86549Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

/**
 * The types of valid form types
 */
export enum FormType {
  /** A checkbox */
  Boolean = 'boolean',
  /** A group of form items */
  Group = 'group',
  /** A string input */
  String = 'string',
  /** An array */
  Array = 'array',
  /** A select of options */
  Select = 'select',
  /** A text area */
  TextArea = 'textArea',
  /** Password */
  Password = 'password',
  /** Select API Keys */
  SelectApiKeys = 'SelectApiKeys',
  /** Select Users */
  SelectUsers = 'SelectUsers',
  /** Select Teams */
  SelectTeams = 'SelectTeams',
  // TODO: https://transcend.height.app/T-29466 - remove this enum that is no longer supported by any Form component
  /** Select Template */
  SelectTemplate = 'SelectTemplate',
}

/**
 * The types of connect silo forms
 */
export enum SiloFormType {
  /** Any OAuth flow */
  OAuth = 'OAUTH',
  /** All other forms (standard forms like API key) */
  Standard = 'STANDARD',
}

/**
 * The computer languages that are supported by code snippets in form items
 */
export enum SnippetLanguage {
  /** Javascript */
  Javascript = 'js',
  /** JSON */
  Json = 'json',
}

/**
 * The mode for select boxes
 */
export enum ModeOption {
  /** Have tags */
  Tags = 'tags',
  /** Multiple */
  Multiple = 'multiple',
  /** Combobox' */
  Combobox = 'combobox',
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagEU: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_865_1279"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_865_1279)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#3D57DB"
      />
      <mask
        id="mask1_865_1279"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_865_1279)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.54835 2.10021L7.43726 2.72394L8.0189 2.42946L8.60063 2.72394L8.48956 2.10021L8.96014 1.61298H8.30977L8.0189 1L7.7281 1.61298H7.07776L7.54835 2.10021ZM7.54835 10.3762L7.43726 11L8.0189 10.7055L8.60063 11L8.48956 10.3762L8.96014 9.88899H8.30977L8.0189 9.27603L7.7281 9.88899H7.07776L7.54835 10.3762ZM2.8595 6.90389L2.97059 6.28016L2.5 5.79293H3.15034L3.44118 5.17995L3.73202 5.79293H4.38235L3.91176 6.28016L4.02285 6.90389L3.44118 6.60941L2.8595 6.90389ZM3.9295 8.2533L3.81841 8.87708L4.40008 8.58257L4.98176 8.87708L4.87067 8.2533L5.34126 7.76614H4.69093L4.40008 7.15312L4.10925 7.76614H3.45891L3.9295 8.2533ZM11.0617 8.87708L11.1728 8.2533L10.7022 7.76614H11.3525L11.6433 7.15312L11.9342 7.76614H12.5845L12.1139 8.2533L12.2251 8.87708L11.6433 8.58257L11.0617 8.87708ZM3.9295 4.25564L3.81841 4.87938L4.40008 4.58489L4.98176 4.87938L4.87067 4.25564L5.34126 3.76842H4.69093L4.40008 3.15543L4.10925 3.76842H3.45891L3.9295 4.25564ZM11.0617 4.87938L11.1728 4.25564L10.7022 3.76842H11.3525L11.6433 3.15543L11.9342 3.76842H12.5845L12.1139 4.25564L12.2251 4.87938L11.6433 4.58489L11.0617 4.87938ZM5.37477 9.84088L5.26368 10.4647L5.84536 10.1702L6.42704 10.4647L6.31595 9.84088L6.78653 9.35364H6.1362L5.84536 8.74069L5.55452 9.35364H4.90418L5.37477 9.84088ZM9.66363 10.4647L9.77471 9.84088L9.30413 9.35364H9.95442L10.2453 8.74069L10.5361 9.35364H11.1864L10.7159 9.84088L10.8269 10.4647L10.2453 10.1702L9.66363 10.4647ZM5.26368 3.26645L5.84536 2.97197L6.42704 3.26645L6.31595 2.64273L6.78653 2.1555H6.1362L5.84536 1.54251L5.55452 2.1555H4.90418L5.37477 2.64273L5.26368 3.26645ZM9.66363 3.26645L9.77471 2.64273L9.30413 2.1555H9.95442L10.2453 1.54251L10.5361 2.1555H11.1864L10.7159 2.64273L10.8269 3.26645L10.2453 2.97197L9.66363 3.26645ZM12.0883 6.22383L11.9772 6.84756L12.5588 6.55307L13.1406 6.84756L13.0294 6.22383L13.5 5.7366H12.8497L12.5588 5.12361L12.2681 5.7366H11.6177L12.0883 6.22383Z"
          fill="#FFD018"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagGE: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54994"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54994)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_54994"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54994)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 0H9V5H16V7H9V12H7V7H0V5H7V0Z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.99932 8.61098L6.09998 8.5V9.5C6.09998 9.5 4.99932 9.43108 4.99932 9.45098C4.99932 9.47088 5.09998 10.5 5.09998 10.5H4.09998L4.18009 9.5H3.09998V8.5L4.18009 8.61098L4.09998 7.5H5.09998L4.99932 8.61098Z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.99932 2.61098L6.09998 2.5V3.5C6.09998 3.5 4.99932 3.43108 4.99932 3.45098C4.99932 3.47088 5.09998 4.5 5.09998 4.5H4.09998L4.18009 3.5H3.09998V2.5L4.18009 2.61098L4.09998 1.5H5.09998L4.99932 2.61098Z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M11.9993 2.61098L13.1 2.5V3.5C13.1 3.5 11.9993 3.43108 11.9993 3.45098C11.9993 3.47088 12.1 4.5 12.1 4.5H11.1L11.1801 3.5H10.1V2.5L11.1801 2.61098L11.1 1.5H12.1L11.9993 2.61098Z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M11.9993 8.61098L13.1 8.5V9.5C13.1 9.5 11.9993 9.43108 11.9993 9.45098C11.9993 9.47088 12.1 10.5 12.1 10.5H11.1L11.1801 9.5H10.1V8.5L11.1801 8.61098L11.1 7.5H12.1L11.9993 8.61098Z"
          fill="#E31D1C"
        />
      </g>
    </g>
  </svg>
);

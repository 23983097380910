/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const NotificationsMuted: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0442 7.92133C15.5017 8.54955 15.7492 9.30611 15.7515 10.0832V12.6143L16.6039 13.4666C16.8595 13.7209 17.0035 14.0665 17.0041 14.427V14.427C17.0043 14.7672 16.8695 15.0935 16.6291 15.3342C16.3888 15.5749 16.0627 15.7103 15.7225 15.7106H7.255"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.13818 17.8274L17.8285 5.13715"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.0037V21.0037C7.02733 21.0037 2.99622 16.9726 2.99622 12V12C2.99622 7.02733 7.02733 2.99622 12 2.99622V2.99622C16.9726 2.99622 21.0037 7.02733 21.0037 12V12C21.0037 16.9726 16.9726 21.0037 12 21.0037V21.0037Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9996 17.8365H13.0005"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6052 6.39769C11.5172 6.19621 10.3963 6.49435 9.55215 7.20972C8.70803 7.92508 8.23008 8.98193 8.25038 10.0882V10.9996"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import React from 'react';

import { SvgProps } from '../enums';

export const FlagZA: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55162"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55162)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4H16V0H0Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V12H16V8H0Z"
        fill="#3D58DB"
      />
      <mask
        id="path-5-outside-1_270_55162"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-4"
        width="18"
        height="20"
        fill="black"
      >
        <rect fill="white" x="-1" y="-4" width="18" height="20" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.71429 5L0 -1V13L7.71429 7H16V5H7.71429Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71429 5L0 -1V13L7.71429 7H16V5H7.71429Z"
        fill="#5EAA22"
      />
      <path
        // eslint-disable-next-line max-len
        d="M0 -1L0.613941 -1.78935L-1 -3.04464V-1H0ZM7.71429 5L7.10035 5.78935L7.37118 6H7.71429V5ZM0 13H-1V15.0446L0.613941 13.7894L0 13ZM7.71429 7V6H7.37118L7.10035 6.21065L7.71429 7ZM16 7V8H17V7H16ZM16 5H17V4H16V5ZM-0.613941 -0.210648L7.10035 5.78935L8.32823 4.21065L0.613941 -1.78935L-0.613941 -0.210648ZM1 13V-1H-1V13H1ZM7.10035 6.21065L-0.613941 12.2106L0.613941 13.7894L8.32823 7.78935L7.10035 6.21065ZM16 6H7.71429V8H16V6ZM15 5V7H17V5H15ZM7.71429 6H16V4H7.71429V6Z"
        fill="#F7FCFF"
        mask="url(#path-5-outside-1_270_55162)"
      />
      <path
        d="M0.3 2.6L-0.5 2V3V9V10L0.3 9.4L4.3 6.4L4.83333 6L4.3 5.6L0.3 2.6Z"
        fill="#272727"
        stroke="#FECA00"
      />
    </g>
  </svg>
);

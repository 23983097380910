/**
 * Fields that the list of users can be filtered by
 */
export enum UserOrderField {
  /** In alphabetical order */
  Name = 'name',
  /** When the user was added */
  CreatedAt = 'createdAt',
  /** The time the user was updated */
  UpdatedAt = 'updatedAt',
}

/**
 * The manner in which a user can login
 */
export enum UserLoginMethod {
  /** Local passport strategy */
  Local = 'LOCAL',
  /** Via organization's SSO Provider */
  Sso = 'SSO',
  /** The user is the role of another. Similar to AWS roles */
  Role = 'ROLE',
}

/* eslint-disable max-len */
import React from 'react';

import { SvgProps } from '../enums';

export const HighRiseBuilding: React.FC<SvgProps> = (props) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33221 14.003V2.66499C5.33221 2.29665 5.63082 1.99805 5.99916 1.99805H10.0008C10.3692 1.99805 10.6678 2.29665 10.6678 2.66499V14.003"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0025 14.0032V5.27494C14.0025 4.93864 13.7299 4.66602 13.3936 4.66602H10.6678"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33222 4.66602H2.60642C2.27012 4.66602 1.9975 4.93864 1.9975 5.27494V14.0032"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6678 6.66648H12.3351"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6678 8.66746H12.3351"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6678 10.6684H12.3351"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66486 6.66648H5.33222"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66486 8.66746H5.33222"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.66486 10.6684H5.33222"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9996 8.66746H9.00044"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9996 10.6684H9.00044"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9996 6.66648H9.00044"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9996 4.6655H9.00044"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00001 12.6689V14.0028"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6695 14.0024H1.33057"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-enable max-len */

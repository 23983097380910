import React from 'react';
import { Link } from 'react-router-dom';

import {
  GenericFormattedMessage,
  GenericMessageDescriptor,
} from '../GenericFormattedMessage';
import { Icon } from '../Icon';
import {
  BootstrapNav,
  BootstrapNavProps,
  NavItem,
  NavLink,
} from './BootstrapNav';
import { Tab, TabBarStyleVariant } from './types';
import { AddTabButton, RemoveTabButton, TabBarWrapper } from './wrappers';

export interface TabBarProps extends BootstrapNavProps {
  /** HTML id for the tab bar */
  id?: string;
  /** The path of the active tab */
  activePath?: string;
  /** The tabs to render */
  tabs: Tab[];
  /** The default tab color */
  defaultTabColor?: string;
  /** The active tab color */
  activeTabColor?: string;
  /** The default nav border color */
  defaultBorderColor?: string;
  /** The active tab border highlight color */
  activeBorderColor?: string;
  /** Whether the links should be navigational or unlinked */
  unlinked?: boolean;
  /** Which styling to use for the tabs */
  styleVariant?: TabBarStyleVariant;
  /** Optional text to display alongside the "+" icon on the "add tab" button */
  onAddTabMessage?: GenericMessageDescriptor;
  /** Callback to handle the addition of a new tab */
  onAddTab?: () => void;
  /** Callback to handle the removal of a tab */
  onRemoveTab?: (targetKey: string) => void;
}

export const TabBar = ({
  id,
  activePath,
  tabs,
  defaultTabColor,
  activeTabColor,
  defaultBorderColor,
  activeBorderColor,
  unlinked,
  styleVariant = TabBarStyleVariant.Links,
  className,
  onAddTab,
  onRemoveTab,
  onAddTabMessage,
  ...props
}: TabBarProps): JSX.Element => (
  <TabBarWrapper
    id={id}
    $defaultTabColor={defaultTabColor}
    $activeTabColor={activeTabColor}
    $defaultBorderColor={defaultBorderColor}
    $activeBorderColor={activeBorderColor}
  >
    <BootstrapNav
      as="ul"
      activeKey={activePath}
      className={`${
        styleVariant === TabBarStyleVariant.Tabs ? 'nav-tabs' : ''
      } ${className ?? ''}`}
      {...props}
    >
      {tabs.map(({ path, title }) => (
        <NavItem as="li" key={path}>
          <NavLink
            eventKey={path}
            {...(!unlinked ? { as: Link, to: path } : ({} as object))}
          >
            <GenericFormattedMessage message={title} />
            {/* Holds space for the remove button */}
            {onRemoveTab && <span style={{ width: 10 }} />}
          </NavLink>
          {/* Remove button is outside the nav link to avoid having a button inside another button or link */}
          {onRemoveTab ? (
            <RemoveTabButton onClick={() => onRemoveTab(path)}>
              <Icon type="close" size={10} />
            </RemoveTabButton>
          ) : null}
        </NavItem>
      ))}
      {onAddTab && (
        <AddTabButton onClick={onAddTab} variant="link" size="sm">
          <Icon type="plus" size={14} />
          {onAddTabMessage && (
            <GenericFormattedMessage message={onAddTabMessage} />
          )}
        </AddTabButton>
      )}
    </BootstrapNav>
  </TabBarWrapper>
);

import { Location, useNavigate } from 'react-router-dom';

import { hrefToLocation } from '@main/core-ui/src/utils/location';
import { ImmutableUrl } from '@main/immutable-url';

/**
 * Get a function to redirect to another page.
 *
 * This will do a true redirect if the page is remote, else a
 * react-router local redirect
 *
 * @param options - Hook options
 * @returns The function to redirect
 */
export function useRedirect({
  push = true,
}: {
  /** If true, pushes the redirect URL onto the history stack. Otherwise, will replace the current URL. */
  push?: boolean;
} = {}): (
  url: string | Location,
  newTab?: boolean,
  pushOverride?: boolean,
) => void {
  const navigate = useNavigate();
  return (url: string | Location, newTab = false, pushOverride = push) => {
    // Open in a new tab
    if (newTab) {
      const newLocationStr =
        typeof url === 'string'
          ? url
          : `${url.pathname}${url.search}${url.hash ? `#${url.hash}` : ''}`;
      window.open(newLocationStr, '_blank');
    } else if (typeof url === 'string' && ImmutableUrl.isCompleteUrl(url)) {
      window.location.href = url;
    } else if (typeof url === 'object') {
      // Redirect locally
      navigate(url, { replace: !pushOverride });
    } else {
      navigate(hrefToLocation(url), { replace: !pushOverride });
    }
  };
}

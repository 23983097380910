import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { LookupProcessOrderField, LookupProcessStatus } from './enums';

export const LookupProcess = mkType({
  name: 'LookupProcess',
  comment: 'A long-running process, that is used to index an API',
  fields: {
    id: {
      type: 'id',
      comment: 'Unique ID of the lookup process',
      modelName: 'lookupProcess',
    },
    name: {
      type: 'string',
      comment: "Lookup process's name canonical name",
    },
    processConfigName: {
      type: 'string',
      comment:
        "Lookup process's name in the config file, i.e. the configuration object it uses to index",
    },
    algorithm: {
      type: 'string',
      comment: 'Indexing algorithm',
    },
    startDate: {
      type: 'Date',
      comment: 'Indexing start date',
      optional: true,
    },
    indexedThrough: {
      type: 'Date',
      comment: 'Last indexed run until?',
      optional: true,
    },
    tolerance: {
      type: 'int',
      comment: 'Number of indexing runs in one invocation',
    },
    status: {
      type: { LookupProcessStatus },
      comment: 'Current status',
    },
    errorCount: {
      type: 'int',
      comment: 'Number of errors encountered',
    },
    error: {
      type: 'string',
      comment: 'Error description, if in an erroneous state',
      optional: true,
    },
    contextKeys: {
      type: 'string',
      list: true,
      comment: 'Keys for the context object',
      optional: true,
    },
    contextValues: {
      type: 'string',
      list: true,
      comment: 'Values for the context object',
      optional: true,
    },
    currentPageKeys: {
      type: 'string',
      list: true,
      comment: 'Keys for the currentPage object',
      optional: true,
    },
    currentPageValues: {
      type: 'string',
      list: true,
      comment: 'Values for the currentPage object',
      optional: true,
    },
  },
});

/** Override type */
export type LookupProcess = SchemaToType<typeof LookupProcess>;

export const LookupProcessInput = mkInput({
  name: 'LookupProcessInput',
  comment: "Inputs for determining which silo's lookup processes to query",
  fields: {
    dataSiloId: {
      type: 'id',
      modelName: 'dataSilo',
      comment: 'Data Silo ID',
    },
    showUnindexedUntilLatestRequest: {
      type: 'boolean',
      optional: true,
      comment:
        'Show all lookup processes that have not yet indexed until the latest request',
    },
  },
});

/** Override type */
export type LookupProcessInput = SchemaToType<typeof LookupProcessInput>;

export const LookupProcessFiltersInput = mkInput({
  name: 'LookupProcessFiltersInput',
  comment: 'Inputs for determining what lookup processes to filter',
  fields: {
    text: {
      comment:
        'Filter by name, process config name, or status of the lookup process',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type LookupProcessFiltersInput = SchemaToType<
  typeof LookupProcessFiltersInput
>;

/**
 * Order for a assetFile query
 */
export const LookupProcessOrder = mkOrder(
  LookupProcess.name,
  LookupProcessOrderField,
);

/** Override type */
export type LookupProcessOrder = SchemaToType<typeof LookupProcessOrder>;

export const UpdateLookupProcessInput = mkInput({
  name: 'UpdateLookupProcessInput',
  comment: 'Input to update a Lookup process',
  fields: {
    id: {
      type: 'id',
      modelName: 'lookupProcess',
      comment: 'Lookup Process ID',
    },
    status: {
      type: 'string',
      comment: 'New status',
      optional: true,
    },
    tolerance: {
      type: 'int',
      comment: 'New tolerance',
      optional: true,
    },
    errorCount: {
      type: 'int',
      comment: 'New error count',
      optional: true,
    },
    scanNow: {
      type: 'boolean',
      comment: 'Schedule scan immediately',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateLookupProcessInput = SchemaToType<
  typeof UpdateLookupProcessInput
>;

export const InspectLookupProcessesInput = mkInput({
  name: 'InspectLookupProcessesInput',
  comment: 'Inspect a lookup process for information about a given identifier',
  fields: {
    dataSiloId: {
      type: 'id',
      modelName: 'dataSilo',
      comment: 'Data Silo ID',
    },
    identifier: {
      type: 'string',
      comment: 'The identifier to inspect',
    },
  },
});

/** Override type */
export type InspectLookupProcessesInput = SchemaToType<
  typeof InspectLookupProcessesInput
>;

export const LookupProcessResultCount = mkType({
  name: 'LookupProcessResultCount',
  comment: "The number of 'hits' for the given identifier",
  fields: {
    id: {
      type: 'id',
      modelName: 'lookupProcess',
      comment: 'The lookup process id',
    },
    count: {
      type: 'int',
      comment: 'The number of results for an identifier',
    },
    name: {
      type: 'string',
      comment: 'The name of the Lookup Process',
    },
    lastSuccessfulRun: {
      type: 'Date',
      comment: 'The time of the last successfull run for the LP',
    },
  },
});

/** Override type */
export type LookupProcessResultCount = SchemaToType<
  typeof LookupProcessResultCount
>;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagTN: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55022"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55022)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#E31D1C"
      />
      <mask
        id="mask1_270_55022"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55022)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 10C10.2091 10 12 8.20914 12 6C12 3.79086 10.2091 2 8 2C5.79086 2 4 3.79086 4 6C4 8.20914 5.79086 10 8 10Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.70138 8.8254C8.70138 8.8254 6.59319 8.25023 6.59319 5.98674C6.59319 3.72325 8.70138 3.09878 8.70138 3.09878C7.82963 2.76138 5.27845 3.27907 5.27845 5.98674C5.27845 8.6944 7.9008 9.18205 8.70138 8.8254ZM8.58532 5.49419L7.53939 5.87635L8.66353 6.2693L8.70138 7.32176L9.38552 6.50413L10.5136 6.5842L9.70082 5.92112L10.1903 4.96357L9.23417 5.28539L8.57177 4.45744L8.58532 5.49419Z"
          fill="#E31D1C"
        />
      </g>
    </g>
  </svg>
);

import { makeEnum } from '@transcend-io/type-utils';

/**
 * Fields to order an action by when paginating
 */
export enum ActionOrderField {
  /** The created at date of the action */
  CreatedAt = 'createdAt',
  /** The last time the action was modified */
  UpdatedAt = 'updatedAt',
  /** The type of action */
  Type = 'type',
}

/**
 * The post-compile status of whether a request's results are downloadable, or just has its categories visible
 */
export const PostCompileStatus = makeEnum({
  /** The dsr results can only have its categories visible */
  ViewCategories: 'VIEW_CATEGORIES',
  /** The dsr results are viewable and downloadable */
  Downloadable: 'DOWNLOADABLE',
});

/** Type override */
export type PostCompileStatus =
  (typeof PostCompileStatus)[keyof typeof PostCompileStatus];

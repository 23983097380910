import { ApolloError } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

export interface RestQueryHookData<T> {
  /** the data returned from the request */
  data?: T;
  /** is the request still loading */
  loading: boolean;
  /** the raw fetch response if an error is returned */
  error?: Response;
  /** the parsed gql error or rest error transformed into a gql error */
  gqlError?: ApolloError;
}

interface RestQueryHookProps {
  /** the url to query */
  url: string;
  /** the extra options passed to fetch */
  fetchOptions?: Parameters<typeof fetch>[1];
  /** should we not execute the fetch yet? changing from false to true executes the request */
  skip?: boolean;
  /** is the request a gql request? */
  isGql?: boolean;
  /** should we avoid trying to parse json and just return text */
  isPlainText?: boolean;
}

export const useRestQuery = <T>({
  url,
  skip,
  fetchOptions,
  isGql,
  isPlainText,
}: RestQueryHookProps): RestQueryHookData<T> => {
  const [data, setData] = useState<T>();
  const [lastProps, setLastProps] =
    useState<Pick<RestQueryHookProps, 'url' | 'fetchOptions'>>();
  const [error, setError] = useState<{
    /** the raw fetch response */
    response: Response;
    /** the parsed gql error if applicable */
    gqlError?: ApolloError;
  }>();
  const [loading, setLoading] = useState(!skip);

  useEffect(() => {
    if (!skip && !isEqual(lastProps, { url, fetchOptions })) {
      setLoading(true);
      setLastProps({ url, fetchOptions });
      fetch(url, fetchOptions).then(async (res) => {
        // status code is 2XX
        let jsonBody: any;
        let isError = !res.ok;
        try {
          jsonBody = await (isPlainText ? res.text() : res.json());
          if (isGql && jsonBody.errors) {
            isError = true;
          }
        } catch (e) {
          jsonBody = e.message;
          isError = true;
        }
        if (!isError) {
          setData(jsonBody);
        } else {
          setError({
            response: res,
            gqlError: new ApolloError({
              graphQLErrors: jsonBody.errors,
              errorMessage: jsonBody.errors
                ? undefined
                : JSON.stringify(jsonBody),
            }),
          });
        }
        setLoading(false);
      });
    }
  }, [fetchOptions, skip]);

  return {
    data,
    loading,
    error: error?.response,
    gqlError: error?.gqlError,
  };
};

import { makeEnum } from '@transcend-io/type-utils';

/**
 * Data silo (integration instance) connection states that are not connected
 */
export enum DisconnectedSiloState {
  /** The integration access token expired */
  Expired = 'EXPIRED',
  /** The integration has not yet been configured */
  NotConfigured = 'NOT_CONFIGURED',
}

/**
 * Data silo (integration instance) connection states that are not disconnected
 */
export enum NotDisconnectedSiloState {
  /** The integration is connected */
  Connected = 'CONNECTED',
  /** The plaintext paths have been updated, and integration must be reconnected */
  PermissionsUpdated = 'PERMISSIONS_UPDATED',
  /** The integration is partially connected */
  Partial = 'PARTIAL',
  /** The integration has LPs in the midst of historical indexing - not yet able to process inbound DSRs */
  Indexing = 'INDEXING',
}

/** Integration connection states */
export const DataSiloConnectionState = makeEnum({
  ...DisconnectedSiloState,
  ...NotDisconnectedSiloState,
});

/**
 * The status of a classified silo discovery plugin result (actually stored in dynamo)
 */
export const DataSiloDiscoPluginRecommendationStatus = makeEnum({
  /** The recommendation is pending review */
  Pending: 'PENDING',
  /** The recommendation has been ignored and will not show up for future review */
  Ignored: 'IGNORED',
});

/** Override type */
export type DataSiloDiscoPluginRecommendationStatus =
  (typeof DataSiloDiscoPluginRecommendationStatus)[keyof typeof DataSiloDiscoPluginRecommendationStatus];

/**
 * Type overload
 */
export type DataSiloConnectionState =
  (typeof DataSiloConnectionState)[keyof typeof DataSiloConnectionState];

/**
 * Fields that a data silo can be ordered by
 */
export enum DataSiloBulkPreviewOrderField {
  /** The title of the data silo */
  Title = 'title',
  /** When the data silo was created */
  CreatedAt = 'createdAt',
  /** The time the data silo was updated */
  UpdatedAt = 'updatedAt',
}

/**
 * Track whether a data silo is in use, is deprecated, or in the process of being deprecated
 */
export const DataSiloDeprecationState = makeEnum({
  /** The data silo is actively being used and there are no plans to deprecate the tool */
  InUse: 'IN_USE',
  /** The data silo is currently in use, but the tool is being deprecated */
  Deprecated: 'DEPRECATED',
  /** The data silo is currently being configured, with plans to launch this data silo into use soon */
  Implementation: 'IMPLEMENTATION',
});

/** Type override */
export type DataSiloDeprecationState =
  (typeof DataSiloDeprecationState)[keyof typeof DataSiloDeprecationState];

/**
 * Filter fields that can be filtered on a null value
 */
export const DataSiloNullableFilters = makeEnum({
  Owners: 'OWNERS',
});

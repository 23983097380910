import React from 'react';

import { SvgProps } from '../enums';

export const FlagPF: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55167"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55167)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55167"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55167)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V3H16V0H0Z"
          fill="#BF2714"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9V12H16V9H0Z"
          fill="#BF2714"
        />
        <mask
          id="mask2_270_55167"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="6"
          height="6"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_270_55167)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.019 6.6876L7.23875 3.0745C5.95054 3.33894 5 4.31376 5 6.10202C5 7.04537 5.627 6.93306 6.55266 6.76726C6.7009 6.7407 6.85679 6.71278 7.019 6.6876ZM8.44716 3.02692L8.95816 6.69933C9.14281 6.73303 9.31941 6.77146 9.48601 6.80771C10.3904 7.0045 11 7.13714 11 6.10202C11 4.25665 9.89193 3.20441 8.44716 3.02692Z"
            fill="#FA8F21"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 9C9.65685 9 11 8.10457 11 7C11 5.89543 9.65685 5 8 5C6.34315 5 5 5.89543 5 7C5 8.10457 6.34315 9 8 9Z"
            fill="#5277B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.27437 6.88238C5.247 6.79665 5.3396 6.72222 5.41744 6.76739L5.60429 6.87582C5.63216 6.89199 5.66618 6.89349 5.69536 6.87983L6.15659 6.66387C6.18892 6.64873 6.22692 6.6523 6.25587 6.67319L6.75411 7.03279L7.44284 6.66197C7.46938 6.64768 7.50101 6.64635 7.52866 6.65836L8.04737 6.8837C8.07359 6.89509 8.10347 6.89451 8.12923 6.88211L8.59457 6.65811C8.61941 6.64615 8.64812 6.64516 8.67372 6.65539L9.24944 6.88536C9.27539 6.89572 9.30451 6.89456 9.32955 6.88218L9.77551 6.66155C9.80371 6.6476 9.83688 6.64798 9.86475 6.66257L10.2738 6.87678C10.3034 6.89227 10.3388 6.89169 10.3679 6.87522L10.5806 6.75472C10.6565 6.71176 10.7466 6.78134 10.7242 6.86556L10.6797 7.03279H5.32239L5.27437 6.88238Z"
            fill="#5277B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.27249 7.47531C5.24592 7.38905 5.33985 7.31555 5.41719 7.36207L5.58718 7.46431C5.61568 7.48145 5.65091 7.48303 5.68082 7.46851L6.11944 7.25565C6.15254 7.23958 6.19184 7.24334 6.2213 7.26538L6.69928 7.62293L7.36111 7.2536C7.38829 7.23843 7.42105 7.23703 7.44943 7.2498L7.9443 7.47263C7.97123 7.48475 8.00218 7.48414 8.0286 7.47096L8.47243 7.24951C8.49792 7.23679 8.52766 7.23575 8.55397 7.24664L9.10406 7.47439C9.13072 7.48543 9.16089 7.4842 9.18656 7.47103L9.61142 7.25317C9.64032 7.23835 9.67468 7.23876 9.70322 7.25425L10.092 7.46529C10.1223 7.48173 10.159 7.48112 10.1888 7.46366L10.3838 7.34914C10.4592 7.30485 10.5506 7.37366 10.5289 7.45839L10.4867 7.62293H5.31796L5.27249 7.47531Z"
            fill="#5277B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.37088 7.86877C5.34431 7.78251 5.43824 7.70901 5.51558 7.75553L5.68557 7.85777C5.71407 7.87491 5.7493 7.87649 5.77921 7.86197L6.21783 7.64911C6.25093 7.63305 6.29023 7.6368 6.31969 7.65884L6.79767 8.0164L7.4595 7.64706C7.48668 7.6319 7.51943 7.63049 7.54782 7.64327L8.04269 7.86609C8.06962 7.87822 8.10056 7.8776 8.12699 7.86442L8.57082 7.64297C8.59631 7.63026 8.62605 7.62921 8.65236 7.64011L9.20245 7.86785C9.22911 7.87889 9.25927 7.87766 9.28495 7.86449L9.70981 7.64664C9.73871 7.63182 9.77307 7.63222 9.80161 7.64771L10.1904 7.85875C10.2207 7.8752 10.2574 7.87458 10.2871 7.85712L10.4822 7.7426C10.5576 7.69832 10.649 7.76713 10.6273 7.85185L10.5851 8.0164H5.41635L5.37088 7.86877Z"
            fill="#5277B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.96097 8.26221C5.9344 8.17595 6.02832 8.10244 6.10567 8.14896L6.27566 8.25121C6.30415 8.26835 6.33938 8.26993 6.3693 8.25541L6.80792 8.04254C6.84102 8.02648 6.88032 8.03023 6.90978 8.05227L7.38776 8.40983L8.04959 8.0405C8.07677 8.02533 8.10952 8.02392 8.1379 8.0367L8.63278 8.25953C8.6597 8.27165 8.69065 8.27104 8.71708 8.25785L9.16091 8.0364C9.18639 8.02369 9.21614 8.02264 9.24245 8.03354L9.79253 8.26128C9.8192 8.27232 9.84936 8.27109 9.87504 8.25793L10.2999 8.04007C10.3288 8.02525 10.3632 8.02565 10.3917 8.04115L10.7805 8.25218C10.8108 8.26863 10.8475 8.26801 10.8772 8.25056L11.0723 8.13603C11.1477 8.09175 11.2391 8.16056 11.2174 8.24528L11.1752 8.40983H6.00644L5.96097 8.26221Z"
            fill="#5277B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.1741 8.75403C5.14754 8.66777 5.24146 8.59426 5.3188 8.64078L5.48879 8.74303C5.51729 8.76017 5.55252 8.76175 5.58244 8.74723L6.02105 8.53436C6.05415 8.5183 6.09345 8.52205 6.12291 8.54409L6.60089 8.90165L7.26272 8.53232C7.2899 8.51715 7.32266 8.51574 7.35104 8.52852L7.84591 8.75135C7.87284 8.76347 7.90379 8.76286 7.93021 8.74967L8.37405 8.52823C8.39953 8.51551 8.42927 8.51446 8.45559 8.52536L9.00567 8.7531C9.03233 8.76414 9.0625 8.76292 9.08818 8.74975L9.51303 8.53189C9.54193 8.51707 9.57629 8.51747 9.60483 8.53297L9.99365 8.74401C10.0239 8.76045 10.0606 8.75983 10.0904 8.74238L10.2854 8.62786C10.3608 8.58357 10.4522 8.65238 10.4305 8.73711L10.3883 8.90165H5.21958L5.1741 8.75403Z"
            fill="#5277B9"
          />
          <path
            // eslint-disable-next-line max-len
            d="M6.33362 4.72293H6.83362L6.82203 6.69898C6.90597 6.96504 7.02573 7.06899 7.21127 7.06899V7.56899C6.78911 7.56899 6.49043 7.30975 6.33362 6.7742V4.72293Z"
            fill="#FF0000"
          />
          <path
            // eslint-disable-next-line max-len
            d="M9.59595 4.72293H9.09595L9.10753 6.69898C9.02359 6.96504 8.90383 7.06899 8.71829 7.06899V7.56899C9.14045 7.56899 9.43913 7.30975 9.59595 6.7742V4.72293Z"
            fill="#FF0000"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.3624 3.45432C8.3624 3.45432 7.53296 3.23743 7.53296 3.7874C7.53296 4.33737 7.53296 5.51847 7.53296 5.51847L8.49994 5.80564V4.39074C8.49994 4.39074 8.01645 4.09531 8.01645 3.86428C8.01645 3.63324 8.3624 3.45432 8.3624 3.45432Z"
            fill="#FF0000"
          />
          <path
            d="M6.96692 6.44533L7.28226 6.04236"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M7.25443 6.43204L6.95227 6.02667"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M7.41931 6.44533L7.73466 6.04236"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M7.70682 6.43204L7.40466 6.02667"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M7.85217 6.44533L8.16752 6.04236"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M8.13968 6.43204L7.83752 6.02667"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M8.30457 6.44533L8.61991 6.04236"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M8.59207 6.43204L8.28992 6.02667"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M8.75708 6.44533L9.07243 6.04236"
            stroke="black"
            strokeWidth="0.5"
          />
          <path
            d="M9.04459 6.43204L8.74243 6.02667"
            stroke="black"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

/* eslint-disable max-lines */
import {
  DataCategoryType,
  IsoCountryCode,
  ProcessingPurpose,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { BusinessEntityPreview } from './businessEntityPreview';
import { DiscoveredByDataSiloPreview } from './dataSiloPreview';
import { DataInventoryCategory } from './dataSubCategory';
import {
  Controllership,
  DataProcessingAgreementStatus,
  VendorOrderField,
} from './enums';
import { PurposeSubCategoryPreview } from './processingPurpose';
import { SaaSCategoryBase } from './saaSCategoryBase';
import { VendorInterface } from './vendorPreview';

export const CreateVendorInput = mkInput({
  name: 'CreateVendorInput',
  comment: 'Input for creating a vendor',
  fields: {
    title: {
      comment: 'The title of the vendor',
      type: 'string',
    },
    description: {
      comment: 'The description of the vendor',
      type: 'string',
    },
    dataProcessingAgreementLink: {
      comment: 'A link to the DPA for this vendor or a note about the status',
      optional: true,
      type: 'string',
    },
    contactName: {
      comment: 'Contact name of person related to email',
      optional: true,
      type: 'string',
    },
    contactEmail: {
      comment:
        'Contact email to use for this vendor (often a third party contact)',
      optional: true,
      type: 'string',
    },
    websiteUrl: {
      comment: 'The website URL of the vendor, if applicable',
      optional: true,
      type: 'string',
    },
    dataProcessingAgreementStatus: {
      comment: 'The status of a DPA with that vendor',
      optional: true,
      type: { DataProcessingAgreementStatus },
    },
    headquarterCountry: {
      comment: 'The ISO country where the vendor is headquartered',
      type: { IsoCountryCode },
      optional: true,
    },
    headquarterSubDivision: {
      comment: 'The ISO country subdivision where the vendor is headquartered',
      type: 'string',
      optional: true,
    },
    contactPhone: {
      comment: 'A phone number for the vendor',
      type: 'string',
      optional: true,
    },
    address: {
      comment: 'The address of the vendor',
      type: 'string',
      optional: true,
    },
    ownerIds: {
      comment: 'The unique ids of the users to assign as owners of this vendor',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment: 'The emails of the users to assign as owners of this vendor',
      type: 'string',
      optional: true,
      list: true,
    },
    businessEntityId: {
      comment: 'The business entity that the vendor is assigned to',
      modelName: 'businessEntity',
      type: 'id',
      optional: true,
    },
    businessEntityTitle: {
      comment: 'The business entity that the vendor is assigned to',
      type: 'string',
      optional: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this vendor',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this vendor',
      type: 'string',
      list: true,
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this vendor',
      type: AttributeInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateVendorInput = SchemaToType<typeof CreateVendorInput>;

export const UpdateVendorInput = mkInput({
  name: 'UpdateVendorInput',
  comment: 'Input for updating a vendor',
  fields: {
    id: {
      comment: 'The vendor ID to update',
      type: 'id',
      modelName: 'vendor',
    },
    title: {
      ...CreateVendorInput.fields.title,
      optional: true,
    },
    description: {
      ...CreateVendorInput.fields.description,
      optional: true,
    },
    dataProcessingAgreementLink:
      CreateVendorInput.fields.dataProcessingAgreementLink,
    contactName: CreateVendorInput.fields.contactName,
    contactEmail: CreateVendorInput.fields.contactEmail,
    websiteUrl: CreateVendorInput.fields.websiteUrl,
    dataProcessingAgreementStatus:
      CreateVendorInput.fields.dataProcessingAgreementStatus,
    headquarterCountry: CreateVendorInput.fields.headquarterCountry,
    headquarterSubDivision: CreateVendorInput.fields.headquarterSubDivision,
    contactPhone: CreateVendorInput.fields.contactPhone,
    address: CreateVendorInput.fields.address,
    ownerIds: CreateVendorInput.fields.ownerIds,
    ownerEmails: CreateVendorInput.fields.ownerEmails,
    businessEntityId: CreateVendorInput.fields.businessEntityId,
    businessEntityTitle: CreateVendorInput.fields.businessEntityTitle,
    teamIds: CreateVendorInput.fields.teamIds,
    teamNames: CreateVendorInput.fields.teamNames,
    attributes: CreateVendorInput.fields.attributes,
  },
});

/** Override type */
export type UpdateVendorInput = SchemaToType<typeof UpdateVendorInput>;

export const UpdateVendorsInput = mkInput({
  name: 'UpdateVendorsInput',
  comment: 'Input for bulk updating one or more vendors',
  fields: {
    vendors: {
      comment:
        'List of vendors with the properties that should be updated for each',
      type: UpdateVendorInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateVendorsInput = SchemaToType<typeof UpdateVendorsInput>;

export const DeleteVendorsInput = mkInput({
  name: 'DeleteVendorsInput',
  comment: 'Input for deleting vendors',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'vendor',
      list: true,
    },
  },
});

/** Override type */
export type DeleteVendorsInput = SchemaToType<typeof DeleteVendorsInput>;

export const VendorsFiltersInput = mkInput({
  name: 'VendorsFiltersInput',
  comment: 'Filters for vendors',
  fields: {
    text: {
      comment: 'Find any vendors are iLike a text string',
      optional: true,
      type: 'string',
    },
    ids: {
      comment: 'The ids of the vendors',
      type: 'id',
      modelName: 'vendor',
      list: true,
      optional: true,
    },
    headquarterCountry: {
      comment: 'Filter by ISO country where the vendor is headquartered',
      type: { IsoCountryCode },
      optional: true,
      list: true,
    },
    headquarterSubDivision: {
      comment:
        'Filter by ISO country subdivision where the vendor is headquartered',
      type: 'string',
      optional: true,
      list: true,
    },
    dataSiloCountry: {
      comment:
        "Filter by ISO country where any of this vendors's data silos are hosted",
      type: { IsoCountryCode },
      optional: true,
      list: true,
    },
    dataSiloCountrySubDivision: {
      comment:
        "Filter by ISO country subdivision where any of this vendors's data silos are hosted",
      type: 'string',
      optional: true,
      list: true,
    },
    teamIds: {
      comment: 'The ids of the teams that own the vendors',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    ownerIds: {
      comment: 'The ids of the users that own the Vendor',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    attributeValueIds: {
      comment: 'The attribute values used to label the vendors',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    businessEntityIds: {
      comment: 'The vendors this business entity uses',
      type: 'id',
      modelName: 'businessEntity',
      list: true,
      optional: true,
    },
    dataCategories: {
      comment: 'Filter by categories of personal data',
      type: { DataCategoryType },
      list: true,
      optional: true,
    },
    dataSubCategoryIds: {
      comment: 'Filter by specific subcategories of personal data',
      type: 'id',
      modelName: 'dataSubCategory',
      optional: true,
      list: true,
    },
    purposes: {
      comment: 'Filter by purpose of processing',
      type: { ProcessingPurpose },
      list: true,
      optional: true,
    },
    processingPurposeSubCategoryIds: {
      comment: 'Filter by specific subcategory of processing purpose',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      optional: true,
      list: true,
    },
    controllerships: {
      comment:
        "Filter by controllerships assigned to subdatapoints of this vendor's data silos",
      type: { Controllership },
      list: true,
      optional: true,
    },
    saaSCategoryIds: {
      comment:
        'Filter by IDs of recipient categories associated with the catalogs of silos belonging to this vendor',
      optional: true,
      type: 'id',
      modelName: 'saaSCategory',
      list: true,
    },
    dataSiloIds: {
      comment: 'Filter by data silos belonging to this vendor',
      optional: true,
      type: 'id',
      modelName: 'dataSilo',
      list: true,
    },
    dataProcessingAgreementStatus: {
      comment: 'Filter by the status of a DPA',
      list: true,
      optional: true,
      type: { DataProcessingAgreementStatus },
    },
  },
});

/** Override type */
export type VendorsFiltersInput = SchemaToType<typeof VendorsFiltersInput>;

export const Vendor = mkType({
  name: 'Vendor',
  comment: 'A vendor of the organization',
  interfaces: [VendorInterface],
  fields: {
    ...VendorInterface.fields,
    dataSilos: {
      comment: 'The data silos related to the vendor',
      type: DiscoveredByDataSiloPreview,
      list: true,
    },
    dataSiloBusinessEntities: {
      comment: 'The business entities that are inherited from the data silos',
      optional: true,
      list: true,
      type: BusinessEntityPreview,
    },
    subDataPointCount: {
      comment: 'The number of sub-datapoints related to the vendor',
      type: 'int',
    },
    dataSubCategories: {
      comment:
        'The set of data categories assigned to the sub-datapoints of this vendor',
      type: DataInventoryCategory,
      list: true,
    },
    processingPurposeSubCategories: {
      comment:
        'The set of processing purposes assigned to the sub-datapoints of this vendor',
      type: PurposeSubCategoryPreview,
      list: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this vendor',
      type: AttributeValue,
      list: true,
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this vendor',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment: 'The list of teams who are responsible for managing this vendor',
      list: true,
      type: TeamPreview,
    },
    controllerships: {
      comment:
        'The controllership values assigned to the sub-datapoints of this vendor',
      type: { Controllership },
      list: true,
    },
    dataSiloRegions: {
      comment: "The hosted regions assigned this vendor's data silos",
      type: 'string',
      list: true,
    },
    catalogRecipients: {
      comment:
        "The pre-labeled recipient categories for catalogs associated with the vendor's data silos",
      type: SaaSCategoryBase,
      list: true,
    },
  },
});

/** Override type */
export type Vendor = SchemaToType<typeof Vendor>;

/** Order for a vendors query */
export const VendorOrder = mkOrder(Vendor.name, VendorOrderField);

/** Override type */
export type VendorOrder = SchemaToType<typeof VendorOrder>;
/* eslint-enable max-lines */

import {
  AttributeKeyType,
  AttributeSupportedResourceType,
} from '@transcend-io/privacy-types';

import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { AssessmentFormTemplateAttributePreview } from './assessmentFormTemplatePreview';
import { AttributeKeyOrderField } from './enums';

export const AttributeInput = mkInput({
  name: 'AttributeInput',
  comment: 'Input for attributeKey and attributeValue creation',
  fields: {
    key: {
      comment: 'The attribute key that houses the attribute values',
      type: 'string',
    },
    values: {
      comment: 'The attribute values used to label resources',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type AttributeInput = SchemaToType<typeof AttributeInput>;

export const AttributeKeyPreview = mkType({
  name: 'AttributeKeyPreview',
  comment: 'A preview of an attribute key to house attribute values',
  fields: {
    id: {
      comment: 'The id for the attribute key',
      modelName: 'attributeKey',
      type: 'id',
    },
    name: {
      comment: 'The name for the attribute key',
      type: 'string',
    },
    type: {
      comment: 'The type of the the attribute key',
      type: { AttributeKeyType },
    },
    enabledOn: {
      comment: 'The views this attribute key is enabled on',
      type: { AttributeSupportedResourceType },
      list: true,
    },
    slug: {
      comment: 'The slug of the vendor for templating',
      type: 'string',
    },
  },
});

/** Override type */
export type AttributeKeyPreview = SchemaToType<typeof AttributeKeyPreview>;

export const AttributeKey = mkType({
  name: 'AttributeKey',
  comment: 'An attribute key to house attribute values',
  fields: {
    ...AttributeKeyPreview.fields,
    description: {
      comment: 'The description for the attribute key',
      type: 'string',
      optional: true,
    },
    isCustom: {
      comment: 'Whether this attribute key is custom created by users',
      type: 'boolean',
    },
    type: {
      comment: 'The type of this attribute key',
      type: { AttributeKeyType },
    },
    assessmentFormTemplate: {
      comment:
        'The associated assessment form template for this attribute key, if it is of type "Assessment"',
      type: AssessmentFormTemplateAttributePreview,
      optional: true,
    },
  },
});

/** Override type */
export type AttributeKey = SchemaToType<typeof AttributeKey>;

export const AttributeKeysFiltersInput = mkInput({
  name: 'AttributeKeysFiltersInput',
  comment: 'Input for filtering the list of attribute keys',
  fields: {
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
    disabledOn: {
      comment: 'Filter for the model(s) the attribute key is not enabled on',
      type: { AttributeSupportedResourceType },
      list: true,
      optional: true,
    },
    enabledOn: {
      comment: 'Filter for the model(s) the attribute key is enabled on',
      type: { AttributeSupportedResourceType },
      list: true,
      optional: true,
    },
    isCustom: {
      comment: 'Filter for keys that are custom created by users',
      type: 'boolean',
      optional: true,
    },
    type: {
      comment: 'Filter for keys of a specific type',
      type: { AttributeKeyType },
      optional: true,
    },
  },
});

/** Override type */
export type AttributeKeysFiltersInput = SchemaToType<
  typeof AttributeKeysFiltersInput
>;

export const CreateAttributeKeyInput = mkInput({
  name: 'CreateAttributeKeyInput',
  comment: 'Input for creating a new attribute key',
  fields: {
    name: {
      comment: 'Name of the attributeKey',
      type: 'string',
    },
    description: {
      comment: 'Description of the attribute key',
      type: 'string',
      optional: true,
    },
    enabledOn: {
      comment: 'The views this attribute key is enabled on',
      type: { AttributeSupportedResourceType },
      list: true,
      optional: true,
    },
    type: {
      comment: 'The type of this attribute key',
      type: { AttributeKeyType },
    },
    assessmentFormTemplateId: {
      comment:
        'The associated assessment form template for this attribute key, if it is of type "Assessment"',
      type: 'id',
      modelName: 'assessmentFormTemplate',
      optional: true,
    },
  },
});

/** Override type */
export type CreateAttributeKeyInput = SchemaToType<
  typeof CreateAttributeKeyInput
>;

export const DeleteAttributeKeyInput = mkInput({
  name: 'DeleteAttributeKeyInput',
  comment: 'Input for deleting an AttributeKey',
  fields: {
    id: {
      comment: 'The id of the attribute key to delete',
      type: 'id',
      modelName: 'attributeKey',
    },
  },
});

/** Override type */
export type DeleteAttributeKeyInput = SchemaToType<
  typeof DeleteAttributeKeyInput
>;

export const UpdateAttributeKeyInput = mkInput({
  name: 'UpdateAttributeKeyInput',
  comment: 'Input for updating an attribute key',
  fields: {
    id: {
      comment: 'The id of the attribute key to update',
      modelName: 'attributeKey',
      type: 'id',
    },
    name: {
      comment: 'Name of the attributeKey',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'Description of the attribute key',
      type: 'string',
      optional: true,
    },
    enabledOn: {
      comment: 'The views this attribute key is enabled on',
      type: { AttributeSupportedResourceType },
      list: true,
      optional: true,
    },
    type: {
      comment: 'The type of this attribute key',
      type: { AttributeKeyType },
      optional: true,
    },
  },
});

/** Override type */
export type UpdateAttributeKeyInput = SchemaToType<
  typeof UpdateAttributeKeyInput
>;

/**
 * Order for a attributeKeys query
 */
export const AttributeKeyOrder = mkOrder(
  AttributeKey.name,
  AttributeKeyOrderField,
);

/** Override type */
export type AttributeKeyOrder = SchemaToType<typeof AttributeKeyOrder>;

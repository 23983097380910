import React from 'react';

import { SvgProps } from '../enums';

export const FlagQA: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55157"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55157)">
      <rect width="16" height="12" fill="#B61C49" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H3.39999L6 1L3.39999 2L6 3L3.39999 4L6 5L3.39999 6L6 7L3.39999 8L6 9L3.39999 10L6 11L3.39999 12H0V0Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

import { makeEnum } from '@transcend-io/type-utils';

/**
 * Integrations that are currently supported (and customers can use) have one of these status
 */
export const IntegrationStatus = makeEnum({
  /** The integration is built but in beta trial */
  Beta: 'BETA',
  /** The integration is fully online and ready */
  Online: 'ONLINE',
});

/** Type override */
export type IntegrationStatus =
  (typeof IntegrationStatus)[keyof typeof IntegrationStatus];

/**
 * The reason that we need to include a particular plaintextPath
 */
export enum PlaintextReasonStaticType {
  /**
   * Paths used for retrieving metadata about accessed/erased data for:
   * - getting intermediate information (e.g. location_id for Square)
   * - getting an id for a piece of data (e.g. id of deletion request)
   * - filtering out duplicates before sending to the end user (usually by id)
   */
  RetrieveQueryMetadata = 'RETRIEVE_QUERY_METADATA',
  /** Paths that are used just for transcend seeding.  Filtered out in prod. */
  Seeding = 'SEEDING',
  /** Paths for testing.  Not to be confused with seeding. */
  Testing = 'TESTING',
  /** Paths for resolving and catching errors */
  ErrorHandling = 'ERROR_HANDLING',
  /** Paths for resolving identifiers TODO: https://transcend.height.app/T-1782 - remove when encrypted identifiers */
  ResolveIdentifiers = 'RESOLVE_IDENTIFIERS',
  /** Paths for retrieving pagination information */
  Pagination = 'PAGINATION',
  /** Paths that confirm whether an operation was successful */
  ConfirmSuccess = 'CONFIRM_SUCCESS',
  /** Paths for OAuth tokens TODO: https://transcend.height.app/T-4301 - Remove */
  RefreshAccessToken = 'REFRESH_ACCESS_TOKEN',
  /** Paths for DataSilo Discovery identifiers */
  DataSiloDiscoveryIdentifiers = 'DISCO_IDENTIFIERS',
  /**
   * Paths for DataSilo Discovery metadata, used for
   * - getting name of the application
   * - any URL which can identify the application
   * - or any other data which can be used for DataSilo suggestion
   */
  DataSiloDiscoveryMetadata = 'DISCO_METADATA',
  /** Paths used for retrieving metadata about schema discovery */
  SchemaDiscoveryMetadata = 'SCHEMA_DISCOVERY_METADATA',
  /** Paths used for retrieving metadata about unstructured data discovery */
  UnstructuredDataDiscoveryMetadata = 'UNSTRUCTURED_DATA_DISCOVERY_METADATA',
  /** Paths used for retrieving metadata about unstructured data sampling */
  UnstructuredDataSamplingMetadata = 'UNSTRUCTURED_DATA_SAMPLING_METADATA',
}

/**
 * The status of a particular data silo as it relates to connecting that
 * data silo for privacy request purposes
 */
export const PrivacyRequestConnectionStatus = makeEnum({
  /** The data silo has been successfully configured for privacy request purposes */
  Connected: 'CONNECTED',
  /** The data silo has been marked as needing to be configured for privacy requests  */
  PendingConnection: 'PENDING_CONNECTION',
  /** Almost always, this data silo needs to be configured for privacy request purposes */
  VeryLikely: 'VERY_LIKELY',
  /**
   * In most situations, this data silo needs to be configured for privacy request purposes.
   * There may be a few common situations where companies disclude this tool, for one reason
   * or another. (i.e. maintain security, financial records)
   */
  Likely: 'LIKELY',
  /**
   * More than 50% of the time, this data silo may be commonly discluded.
   * The inclusion of this data silo in privacy requests may be dependent
   * on how the tool is used in that business context.
   */
  Possible: 'POSSIBLE',
  /**
   * Almost always, this tool is discluded from privacy requests.
   * This may be a result of not storing personal data in the tool at all,
   * or the tool has some other exception (like data retention policy)
   * that prevents the tool from commonly being included in privacy requests.
   */
  NotRecommended: 'NOT_RECOMMENDED',
  /** Transcend has not yet determined the status for this type of data silo */
  Undetermined: 'UNDETERMINED',
});

/** Type override */
export type PrivacyRequestConnectionStatus =
  (typeof PrivacyRequestConnectionStatus)[keyof typeof PrivacyRequestConnectionStatus];

/**
 * The status of a particular data silo as it relates to connecting that
 * data silo for consent manager purposes
 */
export const ConsentManagerConnectionStatus = makeEnum({
  /** The consent manager has approved all data flows and/or cookies that map back to this data silo. */
  Regulated: 'REGULATED',
  /** The consent manager has approved some data flows and/or cookies that map back to this data silo. */
  PartiallyRegulated: 'PARTIALLY_REGULATED',
  /** There are data flows and/or cookies related to this data detected in the consent manager */
  Detected: 'DETECTED',
  /** The data silo has been marked as needing to be configured for consent manager */
  PendingRegulation: 'PENDING_REGULATION',
  /** Almost always, this data silo needs to be configured for consent management purposes */
  VeryLikely: 'VERY_LIKELY',
  /**
   * More than 50% of the time, this data silo may be commonly discluded.
   * One example of this could be that the vendor may have multiple different
   * features that can be enabled, and it may not be common to have this tool
   * appear on a website.
   */
  Possible: 'POSSIBLE',
  /**
   * Almost always, this tool is discluded from consent management purposes.
   * This is likely because that vendor does not have anything that may be related
   * to consent or running on the website of our customers.
   */
  NotRecommended: 'NOT_RECOMMENDED',
  /** Transcend has not yet determined the status for this type of data silo */
  Undetermined: 'UNDETERMINED',
});

/** Type override */
export type ConsentManagerConnectionStatus =
  (typeof ConsentManagerConnectionStatus)[keyof typeof ConsentManagerConnectionStatus];

/**
 * The status of whether a particular data holds personal data
 */
export const HasPersonalDataStatus = makeEnum({
  /** The tool does hold personal data */
  Yes: 'YES',
  /** There is a likely personal data in this tool */
  Likely: 'LIKELY',
  /** It is unlikely that this tool has personal data  */
  Unlikely: 'UNLIKELY',
  /** There is a no personal data in this tool */
  No: 'NO',
  /** Unspecified whether there is personal data or not */
  Unspecified: 'UNSPECIFIED',
});

/** Type override */
export type HasPersonalDataStatus =
  (typeof HasPersonalDataStatus)[keyof typeof HasPersonalDataStatus];

/**
 * The status of whether a particular data holds personal data
 */
export const ConnectorType = makeEnum({
  /** Supports avc functionality */
  Avc: 'AVC',
  /** Supports api functionality */
  Api: 'API',
});

/** Type override */
export type ConnectorType = (typeof ConnectorType)[keyof typeof ConnectorType];

/**
 * Fields to order an catalog by when paginating
 */
export enum CatalogOrderField {
  /** The title of the catalog */
  Title = 'title',
  /** The created at date of the catalog */
  CreatedAt = 'createdAt',
  /** The time the catalog was updated */
  UpdatedAt = 'updatedAt',
  /** The integration name of the catalog */
  IntegrationName = 'integrationName',
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagVU: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55139"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55139)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4H16V0H0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V12H16V8H0Z"
        fill="#5EAA22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 -1L8.33479 6L0 13V-1Z"
        fill="#272727"
      />
      <mask
        id="path-6-outside-1_270_55139"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-4.67749"
        width="18"
        height="21"
        fill="black"
      >
        <rect fill="white" x="-1" y="-4.67749" width="18" height="21" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 -1.67749L8.70415 4.99998H16V6.99998H8.58469L0 13.4057V11.1701L7 5.99998L0 0.767236V-1.67749Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 -1.67749L8.70415 4.99998H16V6.99998H8.58469L0 13.4057V11.1701L7 5.99998L0 0.767236V-1.67749Z"
        fill="#FFD018"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.70415 4.99998L8.09547 5.7934L8.36475 5.99998H8.70415V4.99998ZM0 -1.67749L0.608678 -2.47091L-1 -3.70502V-1.67749H0ZM16 4.99998H17V3.99998H16V4.99998ZM16 6.99998V7.99998H17V6.99998H16ZM8.58469 6.99998V5.99998H8.25272L7.98665 6.19852L8.58469 6.99998ZM0 13.4057H-1V15.3996L0.598041 14.2072L0 13.4057ZM0 11.1701L-0.594107 10.3657L-1 10.6655V11.1701H0ZM7 5.99998L7.59411 6.80437L8.67665 6.00482L7.59874 5.19904L7 5.99998ZM0 0.767236H-1V1.26822L-0.598736 1.56818L0 0.767236ZM9.31283 4.20657L0.608678 -2.47091L-0.608678 -0.884073L8.09547 5.7934L9.31283 4.20657ZM16 3.99998H8.70415V5.99998H16V3.99998ZM17 6.99998V4.99998H15V6.99998H17ZM8.58469 7.99998H16V5.99998H8.58469V7.99998ZM0.598041 14.2072L9.18273 7.80145L7.98665 6.19852L-0.598041 12.6043L0.598041 14.2072ZM-1 11.1701V13.4057H1V11.1701H-1ZM6.40589 5.1956L-0.594107 10.3657L0.594107 11.9745L7.59411 6.80437L6.40589 5.1956ZM-0.598736 1.56818L6.40126 6.80093L7.59874 5.19904L0.598736 -0.0337108L-0.598736 1.56818ZM-1 -1.67749V0.767236H1V-1.67749H-1Z"
        fill="#272727"
        mask="url(#path-6-outside-1_270_55139)"
      />
      <path
        // eslint-disable-next-line max-len
        d="M2.43681 8.38402L2.43781 7.59384C3.14197 7.4385 3.60845 7.23643 3.83551 6.99343C4.39463 6.30435 4.02294 4.68992 2.87426 4.70047C2.30517 4.70047 1.71667 4.97166 1.71667 5.60993C1.71667 6.40265 1.87981 6.74152 2.43781 6.74152C2.99234 6.74152 3.19564 6.77117 3.56091 6.27331L3.95731 6.39579C3.56588 6.92929 3.03826 7.35072 2.43681 7.35072C1.44604 7.35072 0.90686 6.60369 0.90686 5.73973C0.90686 4.67318 1.7727 3.92621 2.81667 3.92621C3.59563 3.92621 4.8277 4.88031 4.8277 5.73973C4.8277 6.59916 4.8277 6.99343 4.15212 7.59384C3.88 7.83568 3.20578 8.26757 2.43681 8.38402Z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.49572 4.93988C2.45758 4.92981 2.41849 4.95257 2.40842 4.99071L2.38112 5.09412C2.37975 5.09235 2.37838 5.09058 2.37701 5.0888C2.3529 5.05758 2.30804 5.05182 2.27682 5.07593C2.2456 5.10004 2.23983 5.1449 2.26394 5.17612C2.28049 5.19755 2.29682 5.21873 2.31293 5.23965L2.23061 5.31566C2.20163 5.34242 2.19983 5.38761 2.22659 5.4166C2.25336 5.44558 2.29855 5.44738 2.32753 5.42062L2.40027 5.35345C2.44369 5.41022 2.48531 5.46489 2.52507 5.51741L2.38814 5.59092C2.35338 5.60958 2.34034 5.65288 2.35899 5.68764C2.37765 5.72239 2.42096 5.73544 2.45571 5.71679L2.6121 5.63283C2.64962 5.6828 2.6852 5.73049 2.71878 5.77582L2.531 5.87663C2.49624 5.89529 2.48319 5.9386 2.50185 5.97335C2.52051 6.00811 2.56381 6.02116 2.59857 6.0025L2.80434 5.89203C2.85906 5.9669 2.90704 6.03367 2.948 6.09186L2.81671 6.16235C2.78196 6.18101 2.76891 6.22431 2.78757 6.25907C2.80623 6.29382 2.84953 6.30687 2.88428 6.28821L3.02959 6.2102C3.09003 6.30026 3.12132 6.35429 3.12132 6.36851C3.12132 6.40796 3.1533 6.43994 3.19275 6.43994C3.2322 6.43994 3.26418 6.40796 3.26418 6.36851C3.26418 6.33235 3.22657 6.25631 3.14798 6.13445C3.14911 6.13247 3.15016 6.13044 3.15111 6.12835L3.2568 5.89563C3.27312 5.85971 3.25722 5.81737 3.22131 5.80105C3.18539 5.78474 3.14305 5.80063 3.12673 5.83655L3.05452 5.99554C3.00941 5.93061 2.95675 5.85711 2.89629 5.7746L2.97109 5.60991C2.9874 5.57399 2.97151 5.53165 2.93559 5.51534C2.89967 5.49903 2.85733 5.51492 2.84102 5.55084L2.79909 5.64316L2.72704 5.54701L2.82823 5.3242C2.84455 5.28828 2.82865 5.24594 2.79273 5.22963C2.75682 5.21331 2.71448 5.22921 2.69816 5.26512L2.62899 5.41742C2.58543 5.3601 2.53949 5.29999 2.49114 5.23701L2.54654 5.02718C2.55662 4.98904 2.53386 4.94996 2.49572 4.93988Z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.97608 4.93988C3.01422 4.92981 3.05331 4.95257 3.06338 4.99071L3.09069 5.09412C3.09205 5.09235 3.09342 5.09058 3.09479 5.0888C3.1189 5.05758 3.16376 5.05182 3.19498 5.07593C3.22621 5.10004 3.23197 5.1449 3.20786 5.17612C3.19131 5.19755 3.17498 5.21873 3.15887 5.23965L3.24119 5.31566C3.27017 5.34242 3.27197 5.38761 3.24521 5.4166C3.21845 5.44558 3.17326 5.44738 3.14427 5.42062L3.07154 5.35345C3.02811 5.41022 2.98649 5.46489 2.94673 5.51741L3.08366 5.59092C3.11842 5.60958 3.13147 5.65288 3.11281 5.68764C3.09415 5.72239 3.05085 5.73544 3.01609 5.71679L2.8597 5.63283C2.82218 5.6828 2.78661 5.73049 2.75302 5.77582L2.9408 5.87663C2.97556 5.89529 2.98861 5.9386 2.96995 5.97335C2.95129 6.00811 2.90799 6.02116 2.87323 6.0025L2.66746 5.89203C2.61274 5.9669 2.56476 6.03367 2.5238 6.09186L2.65509 6.16235C2.68985 6.18101 2.7029 6.22431 2.68424 6.25907C2.66558 6.29382 2.62227 6.30687 2.58752 6.28821L2.44221 6.2102C2.38177 6.30026 2.35048 6.35429 2.35048 6.36851C2.35048 6.40796 2.3185 6.43994 2.27905 6.43994C2.2396 6.43994 2.20762 6.40796 2.20762 6.36851C2.20762 6.33235 2.24523 6.25631 2.32382 6.13445C2.32269 6.13247 2.32164 6.13044 2.32069 6.12835L2.215 5.89563C2.19868 5.85971 2.21458 5.81737 2.2505 5.80105C2.28641 5.78474 2.32876 5.80063 2.34507 5.83655L2.41728 5.99554C2.46239 5.93061 2.51505 5.85711 2.57551 5.7746L2.50071 5.60991C2.4844 5.57399 2.50029 5.53165 2.53621 5.51534C2.57213 5.49903 2.61447 5.51492 2.63078 5.55084L2.67271 5.64316L2.74476 5.54701L2.64357 5.3242C2.62726 5.28828 2.64315 5.24594 2.67907 5.22963C2.71499 5.21331 2.75733 5.22921 2.77364 5.26512L2.84281 5.41742C2.88637 5.3601 2.93231 5.29999 2.98066 5.23701L2.92526 5.02718C2.91519 4.98904 2.93794 4.94996 2.97608 4.93988Z"
        fill="#FFD018"
      />
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagTV: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55136"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55136)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#00B4FF"
      />
      <mask
        id="mask1_270_55136"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55136)">
        <rect width="9" height="7" fill="#2E42A5" />
        <mask
          id="mask2_270_55136"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="9"
          height="7"
        >
          <rect width="9" height="7" fill="white" />
        </mask>
        <g mask="url(#mask2_270_55136)">
          <path
            // eslint-disable-next-line max-len
            d="M-1.00214 6.49987L0.978339 7.36847L9.04494 0.94433L10.0896 -0.346436L7.97176 -0.636747L4.68159 2.1316L2.03334 3.99682L-1.00214 6.49987Z"
            fill="#F7FCFF"
          />
          <path
            d="M-0.731018 7.10849L0.277943 7.61255L9.71445 -0.466309H8.29775L-0.731018 7.10849Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M10.0021 6.49987L8.02166 7.36847L-0.0449371 0.94433L-1.08959 -0.346436L1.02824 -0.636747L4.31841 2.1316L6.96666 3.99682L10.0021 6.49987Z"
            fill="#F7FCFF"
          />
          <path
            // eslint-disable-next-line max-len
            d="M9.93457 6.93667L8.92561 7.44073L4.90745 3.98176L3.71615 3.59529L-1.1901 -0.341973H0.226597L5.13009 3.50184L6.43256 3.96525L9.93457 6.93667Z"
            fill="#F50100"
          />
          <mask
            id="path-10-outside-1_270_55136"
            maskUnits="userSpaceOnUse"
            x="-1"
            y="-1"
            width="11"
            height="9"
            fill="black"
          >
            <rect fill="white" x="-1" y="-1" width="11" height="9" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M3.99226 0V-0.75H3.24226V0H3.99226ZM4.99226 0H5.74226V-0.75H4.99226V0ZM3.99226 3V3.75H4.74226V3H3.99226ZM0 3V2.25H-0.75V3H0ZM0 4H-0.75V4.75H0V4ZM3.99226 4H4.74226V3.25H3.99226V4ZM3.99226 7H3.24226V7.75H3.99226V7ZM4.99226 7V7.75H5.74226V7H4.99226ZM4.99226 4V3.25H4.24226V4H4.99226ZM9 4V4.75H9.75V4H9ZM9 3H9.75V2.25H9V3ZM4.99226 3H4.24226V3.75H4.99226V3ZM3.99226 0.75H4.99226V-0.75H3.99226V0.75ZM4.74226 3V0H3.24226V3H4.74226ZM0 3.75H3.99226V2.25H0V3.75ZM0.75 4V3H-0.75V4H0.75ZM3.99226 3.25H0V4.75H3.99226V3.25ZM4.74226 7V4H3.24226V7H4.74226ZM4.99226 6.25H3.99226V7.75H4.99226V6.25ZM4.24226 4V7H5.74226V4H4.24226ZM9 3.25H4.99226V4.75H9V3.25ZM8.25 3V4H9.75V3H8.25ZM4.99226 3.75H9V2.25H4.99226V3.75ZM4.24226 0V3H5.74226V0H4.24226Z"
            fill="#F7FCFF"
            mask="url(#path-10-outside-1_270_55136)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M13.4284 5.3424L13.732 4.77968L14.398 4.75978L13.8823 4.32908L14.0601 3.74778L13.6156 4.0149L13.0536 3.67407L13.108 4.3199L12.6044 4.62246L13.2581 4.70417L13.4284 5.3424ZM11.732 5.77968L11.4284 6.3424L11.2581 5.70417L10.6044 5.62246L11.108 5.3199L11.0536 4.67407L11.6156 5.0149L12.0601 4.74778L11.8823 5.32908L12.398 5.75978L11.732 5.77968ZM10.4284 7.3424L10.732 6.77968L11.398 6.75978L10.8823 6.32908L11.0601 5.74778L10.6156 6.0149L10.0536 5.67407L10.108 6.3199L9.60442 6.62246L10.2581 6.70417L10.4284 7.3424ZM8.42838 9.3424L8.73198 8.77968L9.39803 8.75978L8.88234 8.32908L9.06013 7.74778L8.61558 8.0149L8.05355 7.67407L8.10797 8.3199L7.60442 8.62246L8.25809 8.70417L8.42838 9.3424ZM6.4871 10.8678L5.93704 11.1938L6.11978 10.559L5.60156 10.1523H6.18901L6.475 9.57067L6.78121 10.1523H7.29984L6.84805 10.559L7.06826 11.1938L6.4871 10.8678ZM8.73198 10.7797L8.42838 11.3424L8.25809 10.7042L7.60442 10.6225L8.10797 10.3199L8.05355 9.67407L8.61558 10.0149L9.06013 9.74778L8.88234 10.3291L9.39803 10.7598L8.73198 10.7797ZM10.732 9.77968L10.4284 10.3424L10.2581 9.70417L9.60442 9.62246L10.108 9.3199L10.0536 8.67407L10.6156 9.0149L11.0601 8.74778L10.8823 9.32908L11.398 9.75978L10.732 9.77968ZM12.732 8.77968L12.4284 9.3424L12.2581 8.70417L11.6044 8.62246L12.108 8.3199L12.0536 7.67407L12.6156 8.0149L13.0601 7.74778L12.8823 8.32908L13.398 8.75978L12.732 8.77968ZM13.4284 8.3424L13.732 7.77968L14.398 7.75978L13.8823 7.32908L14.0601 6.74778L13.6156 7.0149L13.0536 6.67407L13.108 7.3199L12.6044 7.62246L13.2581 7.70417L13.4284 8.3424Z"
          fill="#FFD018"
        />
      </g>
    </g>
  </svg>
);

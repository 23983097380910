import { mkInterface, mkType, SchemaToType } from '@main/schema-utils';

export const EnricherInterface = mkInterface({
  name: 'EnricherInterface',
  comment: 'The interface for identifying data silo details',
  fields: {
    id: {
      comment: 'The id of the enricher configuration',
      modelName: 'enricher',
      type: 'id',
    },
    title: {
      comment: "The enricher's title",
      type: 'string',
    },
    description: {
      comment: "The enricher's description",
      optional: true,
      type: 'string',
    },
    logo: {
      comment: 'The logo icon display',
      type: 'string',
    },
  },
});

/** Override type */
export type EnricherInterface = SchemaToType<typeof EnricherInterface>;

export const EnricherPreview = mkType({
  name: 'EnricherPreview',
  comment: 'Preview of an enricher',
  interfaces: [EnricherInterface],
  fields: {
    ...EnricherInterface.fields,
  },
});

/** Override type */
export type EnricherPreview = SchemaToType<typeof EnricherPreview>;

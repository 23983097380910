import React from 'react';

import { SvgProps } from '../enums';

export const FlagMD: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55045"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55045)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H16V12H11V0Z"
        fill="#D9071E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H6V12H0V0Z"
        fill="#3D58DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H11V12H5V0Z"
        fill="#FBCD17"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.8592 9.80708L10.3327 10.0948L8.58743 6.90036L9.11396 6.61269L10.8592 9.80708ZM5.25677 9.88389L5.78331 10.1716L7.52859 6.97717L7.00205 6.6895L5.25677 9.88389Z"
        fill="#FD1900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5.68903 4.69451V7.47017C5.68903 8.08677 5.1581 8.89033 4.89034 9.18927C4.62257 9.48822 4.09076 10.1731 4.09076 10.1731V2.70416C4.09076 1.86637 4.89034 1.57858 4.89034 1.57858C4.89034 1.57858 5.55425 1.70993 5.68903 2.70416V3.67314C5.89452 3.83496 6.5807 4.33125 7.02397 4.12175C7.55028 3.873 7.73207 3.15952 7.73207 3.15952C7.73207 3.15952 7.68286 2.43957 7.46683 2.43957C7.25081 2.43957 7.23355 1.42544 8.06085 1.42544C8.88816 1.42544 8.9846 1.81635 8.9846 2.12796C8.9846 2.29131 8.85367 2.55345 8.72906 2.80291C8.61596 3.02935 8.50808 3.24534 8.50808 3.3675C8.50808 3.62425 8.70153 4.12175 8.9846 4.12175C9.18313 4.12175 10.1381 3.75075 10.6908 3.52915V2.70416C10.8255 1.70993 11.4895 1.57858 11.4895 1.57858C11.4895 1.57858 12.289 1.86637 12.289 2.70416V10.1731C12.289 10.1731 11.7572 9.48822 11.4895 9.18927C11.2217 8.89033 10.6908 8.08677 10.6908 7.47017V4.69451H5.68903ZM6.57509 9.91609C7.11589 9.56397 7.90696 7.98507 7.90696 7.98507L8.16961 8.04608C8.16961 8.04608 8.57065 9.09191 9.74982 9.91609C8.49292 10.2515 8.16961 10.9251 8.16961 10.9251C8.16961 10.3862 6.57509 9.91609 6.57509 9.91609ZM6.488 8.91666C6.85251 8.91666 7.148 8.63684 7.148 8.29166C7.148 7.94649 6.85251 7.66666 6.488 7.66666C6.12349 7.66666 5.828 7.94649 5.828 8.29166C5.828 8.63684 6.12349 8.91666 6.488 8.91666ZM10.36 8.29166C10.36 8.63684 10.0645 8.91666 9.69999 8.91666C9.33549 8.91666 9.03999 8.63684 9.03999 8.29166C9.03999 7.94649 9.33549 7.66666 9.69999 7.66666C10.0645 7.66666 10.36 7.94649 10.36 8.29166Z"
        fill="#A77B3B"
      />
      <path
        d="M12.57 5.20383L13.029 5.4022L11.1592 9.72816L10.7003 9.52978L12.57 5.20383Z"
        fill="#FDFF00"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5.52002 3.91667H10.8V7.73612C10.8 7.73612 9.45648 8.14079 8.16002 8.80486C7.203 8.09491 5.52002 7.73612 5.52002 7.73612V3.91667Z"
        fill="#E1E5E8"
      />
      <mask
        id="mask1_270_55045"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="3"
        width="6"
        height="6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.52002 3.91667H10.8V7.73612C10.8 7.73612 9.45648 8.14079 8.16002 8.80486C7.203 8.09491 5.52002 7.73612 5.52002 7.73612V3.91667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55045)">
        <rect
          x="5.52002"
          y="6.41667"
          width="5.28"
          height="2.5"
          fill="#3D58DB"
        />
        <rect
          x="5.52002"
          y="3.91667"
          width="5.28"
          height="2.5"
          fill="#FD1900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.89335 5.04107L7.49318 5.07419L7.72398 4.76286L7.47802 4.44044L7.88023 4.49223L8.03445 4.13669L8.20585 4.4998L8.60602 4.46669L8.37523 4.77802L8.62118 5.10044L8.21898 5.04865L8.06476 5.40419L7.89335 5.04107Z"
          fill="#FDFF00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.17505 4.33585C7.17505 4.33585 6.1001 4.98985 6.45842 5.45931C6.81673 5.92878 7.26778 5.98888 7.26778 5.98888C7.26778 5.98888 6.16818 7.63853 8.10352 8.58097C10.1755 7.67117 9.08584 5.98888 9.08584 5.98888C9.08584 5.98888 9.72366 5.84777 9.85136 5.34016C9.97906 4.83255 9.01767 4.25034 9.01767 4.25034C9.01767 4.25034 9.61749 5.11974 9.48056 5.34016C9.34362 5.56058 8.61007 5.78499 8.10352 5.78499C7.59697 5.78499 6.81477 5.64276 6.68853 5.25759C6.56229 4.87242 7.17505 4.33585 7.17505 4.33585Z"
          fill="#FDFF00"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.36835 5.76449C2.36835 5.76449 2.89541 6.3876 3.39169 6.18387C3.88797 5.98014 3.76765 5.24973 3.76765 5.24973L3.39169 5.59079H3.07166V5.76449L2.82171 5.59079V5.76449H2.36835ZM2.72481 6.72202C2.72481 6.72202 3.3672 7.28229 3.8 7.12396C4.23279 6.96564 4.12411 6.20726 4.12411 6.20726L3.74815 6.54832H3.42812V6.72202L3.17817 6.54832V6.72202H2.72481ZM4.45471 7.99346C4.02192 8.15179 3.37953 7.59152 3.37953 7.59152H3.83289V7.41782L4.08284 7.59152V7.41782H4.40287L4.77883 7.07676C4.77883 7.07676 4.88751 7.83514 4.45471 7.99346Z"
        fill="#048F02"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98967 1.12424H6.48967V1.42204H6.25433V1.92204H6.48967V2.79435H6.98967V1.92204H7.27575V1.42204H6.98967V1.12424Z"
        fill="#FDFF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.57909 1.69228L6.42102 1.9906L7.57909 2.35654V1.69228Z"
        fill="#DB4400"
      />
    </g>
  </svg>
);

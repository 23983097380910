import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Bucket: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M18.0021 7.99854H3.99708C3.6922 7.99854 3.40395 8.13754 3.21415 8.37613C3.02434 8.61471 2.95369 8.92683 3.02224 9.22391L5.56176 20.2285C5.66651 20.6824 6.07073 21.004 6.53659 21.004H15.4625C15.9284 21.004 16.3326 20.6824 16.4373 20.2285L18.9768 9.22391C19.0454 8.92684 18.9748 8.61473 18.785 8.37614C18.5952 8.13755 18.3069 7.99854 18.0021 7.99854Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M4.99706 7.99852V6.69029C4.99207 5.02019 6.11152 3.5558 7.72444 3.12252C9.33736 2.68925 11.0399 3.39557 11.8725 4.84336H14.2317C15.7617 4.84336 17.0021 6.0837 17.0021 7.61374V7.99852"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.9996 12L17.6831 15.7199C18.216 16.016 18.8466 16.0815 19.429 15.9013C20.0113 15.7212 20.4948 15.311 20.7674 14.7658V14.7658C21.3025 13.6956 20.8998 12.3938 19.8538 11.8127L18.5483 11.0806"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

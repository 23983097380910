import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DataSiloAdd: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M11.3333 6.33333V3.66667C11.3333 2.746 9.244 2 6.66667 2C4.08933 2 2 2.746 2 3.66667V9C2 9.92067 4.08933 10.6667 6.66667 10.6667C7.13267 10.6667 7.582 10.6413 8.00733 10.596"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0864 10.6666H12.5864"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 11.92V9.41333"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M11.3338 7.33325C9.49316 7.33325 8.00049 8.82592 8.00049 10.6666C8.00049 12.5073 9.49316 13.9999 11.3338 13.9999C13.1752 13.9999 14.6672 12.5073 14.6672 10.6666C14.6672 8.82592 13.1752 7.33325 11.3338 7.33325"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 3.66675C11.3333 4.58741 9.244 5.33341 6.66667 5.33341C4.08933 5.33341 2 4.58741 2 3.66675"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 6.33325C11.3333 7.25392 9.244 7.99992 6.66667 7.99992C4.08933 7.99992 2 7.25392 2 6.33325"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

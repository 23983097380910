import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { StyleUtils } from '../StyledWrappers';

export const NAV_LINK_VERTICAL_PADDING = 8;

export interface TabBarWrapperProps {
  /** The default tab color */
  $defaultTabColor?: string;
  /** The active tab color */
  $activeTabColor?: string;
  /** The default nav border color */
  $defaultBorderColor?: string;
  /** The active tab border highlight color */
  $activeBorderColor?: string;
}

export const AddTabButton = styled(Button)`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 4px;
  align-self: center;
  text-decoration: none;
`;

export const RemoveTabButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 10px;
  background: transparent;
  padding: 0;
  border-width: 0;
  transform: translateY(-50%);
  cursor: pointer;

  :hover,
  :active,
  :focus {
    background: transparent;
  }
`;

export const TabBarWrapper = styled.div<TabBarWrapperProps>`
  .nav {
    position: relative;

    $:not(.nav-tabs) {
      // space for the underline. Keeps wrapper spacing consistent
      padding-bottom: 2px;
    }
  }

  .nav:not(.nav-tabs)::after {
    position: absolute;
    height: 1px;
    content: '';
    box-sizing: border-box;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: ${({ theme, $defaultBorderColor }) =>
      $defaultBorderColor || theme.colors.gray3};
    border-radius: 2px;
  }

  .nav-item {
    position: relative;
  }

  .nav-link {
    position: relative;

    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${StyleUtils.Spacing.xs};
    padding: ${NAV_LINK_VERTICAL_PADDING}px 12px;
  }

  .nav.nav-tabs {
    .nav-item {
      margin-left: 2px;
    }

    .nav-item:first-child {
      margin-left: 0px;
    }

    .nav-link,
    ${RemoveTabButton} {
      color: ${({ theme, $defaultTabColor }) =>
        $defaultTabColor || theme.colors.transcendNavy2};
    }

    .nav-link.active {
      border-color: ${({ theme }) =>
        `${theme.colors.gray4} ${theme.colors.gray4} ${theme.colors.white}`};
    }

    .nav-link:not(.active) {
      border-color: ${({ theme }) => theme.colors.gray4};
      background: ${({ theme }) => theme.colors.gray1};
    }
  }

  .nav:not(.nav-tabs) {
    .nav-item {
      line-height: 1;
      margin-left: 24px;
    }

    .nav-item:first-child {
      margin-left: 0;
    }

    .nav-link,
    ${RemoveTabButton} {
      font-size: 14px;
      font-weight: 600;
      padding-left: 0;
      padding-right: 0;
      color: ${({ theme, $defaultTabColor }) =>
        $defaultTabColor || theme.colors.transcendNavy3};
    }

    .nav-link.active {
      color: ${({ theme, $activeTabColor }) =>
        $activeTabColor || theme.colors.transcend};
    }

    .nav-link.active::after {
      position: absolute;
      left: 0;
      right: 0;
      content: '';
      box-sizing: border-box;
      background-color: ${({ theme, $activeBorderColor }) =>
        $activeBorderColor || theme.colors.transcend};
      height: 2px;
      bottom: -2px;
      border-radius: 2px;
      z-index: 1;
    }
  }
`;

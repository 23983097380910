import { LogicOperator } from '@transcend-io/privacy-types';

import { mkInput, SchemaToType } from '@main/schema-utils';

import { AssessmentRuleInput } from './assessmentRule';

export const AssessmentLogicInput = mkInput({
  name: 'AssessmentLogicInput',
  comment: 'Input for adding logic to an assessment trigger configuration',
  fields: {
    logicOperator: {
      comment: 'The operator to use when comparing the rules',
      type: { LogicOperator },
    },
    rules: {
      comment:
        'The rules to evaluate and be compared with each other using the logic operator',
      type: AssessmentRuleInput,
      list: true,
      optional: true,
    },
    nestedLogics: {
      comment:
        'The nested logics to add one more level of nesting to the logic.',
      type: () => AssessmentLogicInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentLogicInput = SchemaToType<typeof AssessmentLogicInput>;

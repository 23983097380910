/**
 * Webhook for a uri.
 * URIs must be at least 2 characters long, composed of alphanumeric characters separated by [.-]
 */
export const URI_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9.-]*[a-zA-Z0-9]$/;

/**
 * Validate a url
 */
export const URL_REGEX = /^(https|http):\/\/.*$/;

/**
 * Pull out inner content from a quoted string
 */
export const QUOTED_STRING = /^"(.+)"$/;

/**
 * Validate a TLS url
 */
export const TLS_URL_REGEX = /^https:\/\/.*$/;

/**
 * The host name of an organization
 *
 * @deprecated use `isValidHost()` from @main/utils instead
 */
export const HOST_REGEX = /^\[?[\p{L}\d\\.:_-]+\]?(?::\d*)?$/u;

/**
 * Regex useful for finding special characters that need to
 * be replaced in a string
 */
export const NO_SPECIAL_CHARACTERS_REGEX = /[^a-zA-Z0-9]+/g;

/**
 * A domain name without port
 */
export const DOMAIN_WITHOUT_PORT_REGEX =
  /^\s*((?:[\w.-]+)(?:\.(?:[\w.-]+))+)\s*$/;

/**
 * @deprecated - Don't use regexps to validate email addresses. Use a library.
 * On the frontend you can use isValidEmail() from frontend-support
 *
 * Validate an email (doesn't support escaped whitespace; can be improved)
 *
 * TODO: https://transcend.height.app/T-15530
 * Port a high quality open source email validator for our backend
 */
export const EMAIL_REGEX = /^\S+@\S+$/;

/** Email error message for db validation */
export const EMAIL_ERROR_MESSAGE = 'Email address must be valid';

/**
 * Telephone string verification
 */
export const TELEPHONE_REGEX = /^\+[1-9]\d{1,14}$/;

/**
 * Telephone string verification
 * TODO: https://transcend.height.app/T-18559
 *
 * @deprecated
 */
export const TELEPHONE_REGEX_OLD = /^\+?[1-9]\d{1,14}$/;

/**
 * IDFA and GAID string verification
 */
export const IDFA_GAID_REGEX = /^[a-zA-Z0-9-]+$/;

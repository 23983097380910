import styled from 'styled-components';

import { resolveColor, ResolveColorOptions } from '@main/theme';

/**
 * Props
 */
export interface LogoWrappersProps {
  /** Shift the logo in vertical direction */
  shiftY?: string;
}

/**
 * The Logo wrapper
 */
export const LogoWrapper = styled.span<LogoWrappersProps>`
  margin: auto;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(${({ shiftY }) => shiftY});
`;

/**
 * Styled I props
 */
export interface StyledIProps extends ResolveColorOptions {
  /** Make opaque on hover */
  hoverable?: string;
}

/**
 * The italic wrapper
 */
export const StyledI = styled.i<StyledIProps>`
  color: ${resolveColor()};
  ${({ hoverable }) =>
    hoverable &&
    `
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  `}
`;

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const CustomHeaderInput = mkInput({
  name: 'CustomHeaderInput',
  comment: 'Custom headers to include in outbound webhook',
  fields: {
    name: {
      comment: 'The name of the header to set',
      type: 'string',
    },
    value: {
      comment: 'The value of the header',
      type: 'string',
    },
    isSecret: {
      comment: 'The header contains a secret',
      type: 'boolean',
    },
  },
});

/** Override type */
export type CustomHeaderInput = SchemaToType<typeof CustomHeaderInput>;

export const CustomHeader = mkType({
  name: 'CustomHeader',
  comment: 'Custom headers to include in outbound webhook',
  fields: {
    name: CustomHeaderInput.fields.name,
    value: CustomHeaderInput.fields.value,
    isSecret: CustomHeaderInput.fields.isSecret,
  },
});

/** Override type */
export type CustomHeader = SchemaToType<typeof CustomHeader>;

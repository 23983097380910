import React from 'react';

import { SvgProps } from '../enums';

export const FlagAG: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54945"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54945)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8 7L6.8874 8.89971L6.91529 6.80194L4.88255 8.12733L6.04542 6.24698L3.49516 6.73554L5.56268 5.44504L3 5L5.56268 4.55496L3.49516 3.26447L6.04542 3.75302L4.88255 1.87267L6.91529 3.19806L6.8874 1.10029L8 3L9.1126 1.10029L9.08471 3.19806L11.1174 1.87267L9.95458 3.75302L12.5048 3.26447L10.4373 4.55496L13 5L10.4373 5.44504L12.5048 6.73554L9.95458 6.24698L11.1174 8.12733L9.08471 6.80194L9.1126 8.89971L8 7Z"
        fill="#F9D313"
      />
      <rect x="3" y="7" width="10" height="5" fill="#F1F9FF" />
      <rect x="1" y="5" width="14" height="2" fill="#4A80E8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3L8 12L16 3V12H0V3Z"
        fill="#E31D1C"
      />
    </g>
  </svg>
);

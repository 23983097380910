import React from 'react';

import { SvgProps } from '../enums';

export const FlagBQ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55188"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55188)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#00268D"
      />
      <path d="M0.5 0.5H15.5V11.5H0.5V0.5Z" fill="#00268D" stroke="#E31D1C" />
      <rect x="7.5" width="1" height="12" fill="#E31D1C" />
      <rect
        y="6.5"
        width="1"
        height="16"
        transform="rotate(-90 0 6.5)"
        fill="#E31D1C"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.25725 2.57125L8 2.4169L7.74275 2.57125L2.74275 5.57125L2.02817 6L2.74275 6.42875L7.74275 9.42875L8 9.5831L8.25725 9.42875L13.2572 6.42875L13.9718 6L13.2572 5.57125L8.25725 2.57125Z"
        fill="white"
        stroke="#E31D1C"
      />
      <mask
        id="mask1_270_55188"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="14"
        height="10"
      >
        <path
          // eslint-disable-next-line max-len
          d="M8.25725 2.57125L8 2.4169L7.74275 2.57125L2.74275 5.57125L2.02817 6L2.74275 6.42875L7.74275 9.42875L8 9.5831L8.25725 9.42875L13.2572 6.42875L13.9718 6L13.2572 5.57125L8.25725 2.57125Z"
          fill="white"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask1_270_55188)">
        <path
          // eslint-disable-next-line max-len
          d="M4.82256 7.79999C5.00189 7.79999 13.75 7.79999 13.75 7.79999L12.8061 6.84288C12.8061 6.84288 10.9185 4.95038 10.7971 4.86364C10.6756 4.77691 10.4749 4.73948 10.2466 5.01514C10.0182 5.2908 9.88725 4.86364 9.69713 4.86364C9.50701 4.86364 9.42662 4.86364 9.15076 5.21733C8.8749 5.57101 7.88153 6.84288 7.88153 6.84288H6.42168C6.42168 6.84288 6.17542 6.63395 6.03507 6.7085C5.89472 6.78304 5.10415 5.88392 4.82256 5.81674C4.54096 5.74957 4.35568 6.06047 4.35568 6.30787C4.35568 6.55527 4.22317 6.08117 3.95374 6.22033C3.68431 6.3595 3.77118 6.7085 3.77118 6.7085C3.77118 6.7085 4.64322 7.79999 4.82256 7.79999Z"
          fill="#059334"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.00318 5.53415L6.38564 6L6.61224 5.23048L6 4.75943H6.76276L7.00318 4L7.25801 4.75943H8L7.39309 5.23048L7.62378 6L7.00318 5.53415Z"
          fill="#FEDA00"
        />
      </g>
    </g>
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const SystemStatus: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M21.0037 12V12C21.0037 16.9726 16.9726 21.0037 12 21.0037V21.0037C7.02733 21.0037 2.99622 16.9726 2.99622 12V12C2.99622 7.02736 7.02733 2.99625 12 2.99625V2.99625C14.3879 2.99625 16.678 3.94485 18.3666 5.63338C20.0551 7.32191 21.0037 9.61205 21.0037 12V12Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.99622 12H6.88283L8.89867 8.98575L11.5778 15.0143L13.5826 12H16.2617"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagGU: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55121"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55121)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2H14V10H2V2Z"
        fill="#4564F9"
      />
      <path
        d="M2.5 2.5H13.5V9.5H2.5V2.5Z"
        stroke="#CBDAFF"
        strokeOpacity="0.1"
      />
      <mask
        id="mask1_270_55121"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="12"
        height="8"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2H14V10H2V2Z"
          fill="white"
        />
        <path d="M2.5 2.5H13.5V9.5H2.5V2.5Z" stroke="white" />
      </mask>
      <g mask="url(#mask1_270_55121)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8 1.99995C8 1.99995 11 3.44772 11 5.85881C11 8.2699 8 9.99995 8 9.99995C8 9.99995 5 8.20211 5 5.85881C5 3.5155 8 1.99995 8 1.99995Z"
          fill="#CCE0E5"
        />
        <path
          // eslint-disable-next-line max-len
          d="M8 1.99995L8.3151 1.34701L7.99266 1.1914L7.67309 1.35284L8 1.99995ZM8 1.99995C8.3151 1.34701 8.31532 1.34712 8.31555 1.34723L8.31607 1.34748L8.31732 1.34809L8.32064 1.3497L8.33052 1.35457L8.36276 1.37078C8.38961 1.38446 8.42698 1.40388 8.47339 1.42894C8.5661 1.479 8.69543 1.55188 8.84924 1.64671C9.15534 1.83543 9.56619 2.11589 9.98031 2.48215C10.7866 3.19528 11.725 4.34221 11.725 5.85881C11.725 7.36186 10.8004 8.57545 10.0071 9.35173C9.59807 9.75194 9.19183 10.0675 8.88872 10.2829C8.73651 10.3911 8.60863 10.4752 8.51715 10.5332C8.47137 10.5622 8.43458 10.5848 8.40829 10.6007L8.37685 10.6194L8.36735 10.625L8.36422 10.6268L8.36307 10.6275L8.36259 10.6278C8.36238 10.6279 8.36219 10.628 8 9.99995M8 1.99995C7.67309 1.35284 7.67288 1.35295 7.67266 1.35306L7.67216 1.35331L7.67096 1.35392L7.66773 1.35557L7.65804 1.36056L7.62619 1.37728C7.5996 1.3914 7.5625 1.41148 7.51639 1.43735C7.42425 1.48903 7.29557 1.56416 7.14245 1.66144C6.83769 1.85507 6.42861 2.14127 6.01627 2.51061C5.21743 3.22616 4.275 4.3732 4.275 5.85881C4.275 7.33046 5.2032 8.54461 5.98997 9.32339C6.3974 9.72667 6.80209 10.0479 7.10399 10.2682C7.25557 10.3787 7.38287 10.465 7.47382 10.5246C7.51933 10.5544 7.55587 10.5776 7.58192 10.5939L7.613 10.6131L7.62232 10.6188L7.62537 10.6207L7.62648 10.6213L7.62694 10.6216C7.62713 10.6217 7.62732 10.6218 8 9.99995M8 9.99995L8.36219 10.628L7.99294 10.8409L7.62732 10.6218L8 9.99995Z"
          stroke="#C1C9F1"
          strokeOpacity="0.1482"
          strokeWidth="1.45"
        />
        <path
          // eslint-disable-next-line max-len
          d="M8.20001 3.01839L8.54327 2.3798L8.19202 2.19099L7.84448 2.38655L8.20001 3.01839ZM8.20001 3.01839C8.54327 2.3798 8.54349 2.37992 8.54373 2.38004L8.54423 2.38031L8.54538 2.38093L8.54822 2.38248L8.5561 2.3868L8.58024 2.40032C8.59994 2.4115 8.62674 2.42702 8.65963 2.4468C8.72532 2.48631 8.81583 2.54314 8.92293 2.61669C9.13584 2.7629 9.4211 2.9798 9.70881 3.26323C10.2707 3.81675 10.925 4.7085 10.925 5.88381C10.925 7.04723 10.2819 7.98595 9.73423 8.58287C9.45202 8.89046 9.17186 9.1329 8.96247 9.29863C8.85723 9.38193 8.76842 9.447 8.7042 9.49236C8.67205 9.51506 8.64596 9.5329 8.62692 9.5457L8.60375 9.56111L8.59634 9.56595L8.59372 9.56765L8.59268 9.56831L8.59224 9.5686C8.59203 9.56873 8.59184 9.56886 8.20001 8.95886M8.20001 3.01839C7.84448 2.38655 7.84427 2.38667 7.84405 2.38679L7.84357 2.38706L7.84248 2.38768L7.83973 2.38924L7.83207 2.39363L7.80836 2.40749C7.78896 2.41897 7.76247 2.43495 7.7299 2.4553C7.66485 2.49594 7.57505 2.55436 7.4687 2.62962C7.25728 2.77925 6.97386 3.00009 6.68795 3.28534C6.13296 3.83906 5.47501 4.73069 5.47501 5.88381C5.47501 7.0246 6.12129 7.96368 6.663 8.56093C6.94367 8.87038 7.22226 9.1167 7.43033 9.28576C7.53491 9.37073 7.6231 9.43733 7.68674 9.48377C7.7186 9.50702 7.74442 9.52529 7.76319 9.53836L7.78597 9.55407L7.79319 9.55897L7.79572 9.56067L7.7967 9.56133L7.79713 9.56161C7.79732 9.56174 7.79751 9.56186 8.20001 8.95886M8.20001 8.95886L8.59184 9.56886L8.19238 9.82544L7.79751 9.56186L8.20001 8.95886Z"
          fill="#CCE0E5"
        />
        <path
          // eslint-disable-next-line max-len
          d="M8.20001 3.01839L8.54327 2.3798L8.19202 2.19099L7.84448 2.38655L8.20001 3.01839ZM8.20001 3.01839C8.54327 2.3798 8.54349 2.37992 8.54373 2.38004L8.54423 2.38031L8.54538 2.38093L8.54822 2.38248L8.5561 2.3868L8.58024 2.40032C8.59994 2.4115 8.62674 2.42702 8.65963 2.4468C8.72532 2.48631 8.81583 2.54314 8.92293 2.61669C9.13584 2.7629 9.4211 2.9798 9.70881 3.26323C10.2707 3.81675 10.925 4.7085 10.925 5.88381C10.925 7.04723 10.2819 7.98595 9.73423 8.58287C9.45202 8.89046 9.17186 9.1329 8.96247 9.29863C8.85723 9.38193 8.76842 9.447 8.7042 9.49236C8.67205 9.51506 8.64596 9.5329 8.62692 9.5457L8.60375 9.56111L8.59634 9.56595L8.59372 9.56765L8.59268 9.56831L8.59224 9.5686C8.59203 9.56873 8.59184 9.56886 8.20001 8.95886M8.20001 3.01839C7.84448 2.38655 7.84427 2.38667 7.84405 2.38679L7.84357 2.38706L7.84248 2.38768L7.83973 2.38924L7.83207 2.39363L7.80836 2.40749C7.78896 2.41897 7.76247 2.43495 7.7299 2.4553C7.66485 2.49594 7.57505 2.55436 7.4687 2.62962C7.25728 2.77925 6.97386 3.00009 6.68795 3.28534C6.13296 3.83906 5.47501 4.73069 5.47501 5.88381C5.47501 7.0246 6.12129 7.96368 6.663 8.56093C6.94367 8.87038 7.22226 9.1167 7.43033 9.28576C7.53491 9.37073 7.6231 9.43733 7.68674 9.48377C7.7186 9.50702 7.74442 9.52529 7.76319 9.53836L7.78597 9.55407L7.79319 9.55897L7.79572 9.56067L7.7967 9.56133L7.79713 9.56161C7.79732 9.56174 7.79751 9.56186 8.20001 8.95886M8.20001 8.95886L8.59184 9.56886L8.19238 9.82544L7.79751 9.56186L8.20001 8.95886Z"
          stroke="#C1C9F1"
          strokeOpacity="0.748217"
          strokeWidth="1.45"
        />
        <path
          // eslint-disable-next-line max-len
          d="M8.20001 3.01839L8.54327 2.3798L8.19202 2.19099L7.84448 2.38655L8.20001 3.01839ZM8.20001 3.01839C8.54327 2.3798 8.54349 2.37992 8.54373 2.38004L8.54423 2.38031L8.54538 2.38093L8.54822 2.38248L8.5561 2.3868L8.58024 2.40032C8.59994 2.4115 8.62674 2.42702 8.65963 2.4468C8.72532 2.48631 8.81583 2.54314 8.92293 2.61669C9.13584 2.7629 9.4211 2.9798 9.70881 3.26323C10.2707 3.81675 10.925 4.7085 10.925 5.88381C10.925 7.04723 10.2819 7.98595 9.73423 8.58287C9.45202 8.89046 9.17186 9.1329 8.96247 9.29863C8.85723 9.38193 8.76842 9.447 8.7042 9.49236C8.67205 9.51506 8.64596 9.5329 8.62692 9.5457L8.60375 9.56111L8.59634 9.56595L8.59372 9.56765L8.59268 9.56831L8.59224 9.5686C8.59203 9.56873 8.59184 9.56886 8.20001 8.95886M8.20001 3.01839C7.84448 2.38655 7.84427 2.38667 7.84405 2.38679L7.84357 2.38706L7.84248 2.38768L7.83973 2.38924L7.83207 2.39363L7.80836 2.40749C7.78896 2.41897 7.76247 2.43495 7.7299 2.4553C7.66485 2.49594 7.57505 2.55436 7.4687 2.62962C7.25728 2.77925 6.97386 3.00009 6.68795 3.28534C6.13296 3.83906 5.47501 4.73069 5.47501 5.88381C5.47501 7.0246 6.12129 7.96368 6.663 8.56093C6.94367 8.87038 7.22226 9.1167 7.43033 9.28576C7.53491 9.37073 7.6231 9.43733 7.68674 9.48377C7.7186 9.50702 7.74442 9.52529 7.76319 9.53836L7.78597 9.55407L7.79319 9.55897L7.79572 9.56067L7.7967 9.56133L7.79713 9.56161C7.79732 9.56174 7.79751 9.56186 8.20001 8.95886M8.20001 8.95886L8.59184 9.56886L8.19238 9.82544L7.79751 9.56186L8.20001 8.95886Z"
          stroke="#C51918"
          strokeWidth="1.45"
        />
        <mask
          id="mask2_270_55121"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="3"
          width="5"
          height="6"
        >
          <path
            // eslint-disable-next-line max-len
            d="M8.6658 7.60259C8.50626 7.77647 8.34563 7.92527 8.20601 8.04396C8.06399 7.92081 7.89993 7.76638 7.73702 7.58677C7.27874 7.08151 6.92501 6.48308 6.92501 5.88381C6.92501 5.2969 7.26707 4.75581 7.71207 4.31183C7.88498 4.13931 8.05951 3.99573 8.20643 3.88589C8.35094 3.99139 8.52186 4.12935 8.69122 4.29619C9.12933 4.72778 9.47501 5.26875 9.47501 5.88381C9.47501 6.51078 9.11812 7.10959 8.6658 7.60259Z"
            fill="white"
          />
          <path
            // eslint-disable-next-line max-len
            d="M8.6658 7.60259C8.50626 7.77647 8.34563 7.92527 8.20601 8.04396C8.06399 7.92081 7.89993 7.76638 7.73702 7.58677C7.27874 7.08151 6.92501 6.48308 6.92501 5.88381C6.92501 5.2969 7.26707 4.75581 7.71207 4.31183C7.88498 4.13931 8.05951 3.99573 8.20643 3.88589C8.35094 3.99139 8.52186 4.12935 8.69122 4.29619C9.12933 4.72778 9.47501 5.26875 9.47501 5.88381C9.47501 6.51078 9.11812 7.10959 8.6658 7.60259Z"
            stroke="white"
            strokeWidth="1.45"
          />
        </mask>
        <g mask="url(#mask2_270_55121)">
          <rect
            x="6.46667"
            y="7"
            width="3.73333"
            height="0.75"
            fill="#91CBCB"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.8009 7.59896C6.8009 7.59896 9.44793 7.59991 9.69121 7.50856C9.9345 7.41722 9.23448 9.26234 8.34006 9.26234C7.44564 9.26234 6.8009 7.59896 6.8009 7.59896Z"
            fill="#FDF9A1"
          />
          <path
            // eslint-disable-next-line max-len
            d="M9.29681 7.37755L8.82129 7.74346C8.07185 6.76952 7.75748 5.76199 7.89166 4.73372L8.48662 4.81135C8.37423 5.67262 8.6398 6.52374 9.29681 7.37755Z"
            fill="#A68861"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8.19684 4.81836L7.74318 5.34994C7.72616 5.36988 7.69382 5.3535 7.69983 5.32798L7.85588 4.6652H7.14622C7.11914 4.6652 7.11138 4.62817 7.13618 4.6173L7.74258 4.35145L7.31217 3.78401C7.29693 3.76391 7.31767 3.73644 7.34117 3.74561L8.018 4.00945L8.17251 3.38774C8.1788 3.36243 8.21479 3.36245 8.22105 3.38776L8.37475 4.00945L9.04571 3.74821C9.06971 3.73887 9.09046 3.76753 9.07408 3.78741L8.60927 4.35145L9.25063 4.6171C9.2759 4.62757 9.26842 4.6652 9.24106 4.6652H8.60927L8.70542 5.34327C8.70893 5.36805 8.6779 5.38205 8.66165 5.36301L8.19684 4.81836Z"
            fill="#239E46"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.79999 6.5H10.2V7.025H7.79999V6.5Z"
            fill="#239E46"
          />
        </g>
      </g>
    </g>
  </svg>
);

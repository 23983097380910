import React from 'react';

import { SvgProps } from '../enums';

export const FlagMO: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55058"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55058)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#1C9975"
      />
      <mask
        id="mask1_270_55058"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55058)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.01086 3.42687L7.194 3.92L7.38032 2.9577L6.70007 2.2375L7.62116 2.19856L8.01086 1.3L8.40055 2.19856H9.32007L8.6414 2.9577L8.84572 3.92L8.01086 3.42687Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.69134 3.79224L5.18626 4.09716L5.30147 3.50214L4.88086 3.05683L5.45039 3.03275L5.69134 2.47716L5.9323 3.03275H6.50086L6.08122 3.50214L6.20756 4.09716L5.69134 3.79224Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M10.2906 3.79224L9.78551 4.09716L9.90071 3.50214L9.4801 3.05683L10.0496 3.03275L10.2906 2.47716L10.5315 3.03275H11.1001L10.6805 3.50214L10.8068 4.09716L10.2906 3.79224Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.3833 4.80531L3.97799 5.05L4.07044 4.57252L3.73291 4.21517L4.18994 4.19585L4.3833 3.75L4.57666 4.19585H5.03291L4.69616 4.57252L4.79754 5.05L4.3833 4.80531Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M11.7804 4.80531L11.3751 5.05L11.4675 4.57252L11.13 4.21517L11.587 4.19585L11.7804 3.75L11.9738 4.19585H12.43L12.0933 4.57252L12.1946 5.05L11.7804 4.80531Z"
          fill="#FECA00"
        />
        <mask
          id="mask2_270_55058"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="3"
          width="8"
          height="8"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 11C10.2091 11 12 9.20914 12 7C12 4.79086 10.2091 3 8 3C5.79086 3 4 4.79086 4 7C4 9.20914 5.79086 11 8 11Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_270_55058)">
          <path
            // eslint-disable-next-line max-len
            d="M6.31014 11.6392H4.35779V9.01029L6.30663 9.01032C6.99698 9.00061 7.50144 8.80343 7.83802 8.42408L8.00574 8.23505L8.19295 8.40479C8.64248 8.81238 9.0915 9.01029 9.54574 9.01029H11.6979V11.6392H9.54574C9.02389 11.6392 8.52009 11.4429 8.03835 11.0585C7.61171 11.4367 7.03241 11.629 6.31014 11.6392Z"
            fill="#F7FCFF"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.0174 3.80389C8.0174 3.80389 7.16302 4.64789 7.16302 5.80618C7.16302 6.05406 7.20215 6.28823 7.26365 6.50322C6.98839 5.89315 6.96932 5.23162 7.20688 4.51002L6.88353 4.40356C6.64186 5.13764 6.64082 5.8278 6.88074 6.46742C6.38443 6.16839 5.81342 5.96747 5.22732 5.90268L5.18992 6.24105C5.70213 6.29767 6.20206 6.46846 6.64099 6.72128C6.53262 6.67759 6.41899 6.63958 6.30045 6.60932C5.21897 6.3333 4.15796 6.72469 4.15796 6.72469C4.15796 6.72469 4.77 8.0978 5.95545 8.30028C6.01455 8.31038 6.07298 8.31824 6.13065 8.32406L6.1217 8.32408C5.5565 8.32408 5.03089 8.093 4.5387 7.62352L4.30374 7.86986C4.85657 8.39718 5.46461 8.66451 6.1217 8.66451C6.76432 8.66451 7.36114 8.40882 7.90648 7.90429C7.99396 7.85146 8.04514 7.81366 8.05334 7.80752L8.0748 7.82576C8.06355 7.84507 8.05791 7.85561 8.05791 7.85561C8.05791 7.85561 8.11168 7.88815 8.20863 7.934C8.74708 8.35135 9.42078 8.66451 9.9538 8.66451C10.6109 8.66451 11.2189 8.39718 11.7718 7.86986L11.5368 7.62352C11.0446 8.093 10.519 8.32408 9.9538 8.32408C9.74731 8.32408 9.54497 8.29091 9.34649 8.22415C9.62442 8.23911 9.93059 8.21407 10.2475 8.11718C11.4152 7.76017 11.902 6.68035 11.902 6.68035C11.902 6.68035 11.2807 6.3451 10.4915 6.34133C10.6683 6.29573 10.8529 6.26231 11.0452 6.24105L11.0078 5.90268C10.2677 5.98449 9.6274 6.2343 9.09027 6.65114C9.40433 5.96057 9.42864 5.20846 9.16366 4.40356L8.8403 4.51002C9.05683 5.16774 9.06016 5.77555 8.85061 6.33998C8.8865 6.17308 8.90772 5.99469 8.90772 5.80618C8.90772 4.58511 8.0174 3.80389 8.0174 3.80389ZM8.39325 7.37771L8.38058 7.39596L8.39604 7.37754L8.39325 7.37771ZM7.56747 7.29798L7.60093 7.26719C7.63136 7.31774 7.66171 7.36533 7.69138 7.40974C7.6529 7.37351 7.61157 7.33606 7.56747 7.29798Z"
          fill="#F7FCFF"
        />
      </g>
    </g>
  </svg>
);

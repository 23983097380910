/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const StopHand: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.82624 11.5383C7.38824 11.0773 8.20824 11.1183 8.72124 11.6323L9.58924 12.4993V9.00129C9.58924 7.63629 10.9262 6.67229 12.2212 7.10429L15.0242 8.03829C15.8412 8.31029 16.3922 9.07529 16.3922 9.93529V14.0333C16.3922 15.9383 14.8472 17.4833 12.9422 17.4833H11.3162C10.2292 17.4833 9.20624 16.9713 8.55424 16.1013L6.59024 13.4783C6.13724 12.8723 6.24124 12.0173 6.82624 11.5383V11.5383V11.5383Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.415 20.415L3.586 16.586C3.211 16.211 3 15.702 3 15.171V8.828C3 8.298 3.211 7.789 3.585 7.415L7.414 3.586C7.789 3.211 8.298 3 8.828 3H15.17C15.701 3 16.21 3.211 16.585 3.586L20.413 7.414C20.789 7.79 21 8.3 21 8.831V15.171C21 15.701 20.789 16.209 20.415 16.584L16.586 20.414C16.211 20.789 15.702 21 15.171 21H8.828C8.298 21 7.789 20.789 7.415 20.415V20.415Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

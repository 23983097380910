import { defineMessages } from '@transcend-io/internationalization';

export const simpleErrorBoundaryMessages = defineMessages(
  'privacy-center.containers.SimpleErrorBoundary.simpleErrorBoundary',
  {
    error: {
      defaultMessage: 'A build error occurred! Check the console for issues',
    },
  },
);

import { mkType, SchemaToType } from '@main/schema-utils';

export const SaaSVendorPreview = mkType({
  name: 'SaaSVendorPreview',
  comment: 'A preview of the static SaaS vendor that a vendor is tied to',
  fields: {
    id: {
      comment: 'The SaaSVendor id, from the database (not contentfulId)',
      type: 'id',
      modelName: 'saaSVendor',
    },
    logo: {
      comment: 'The url for the full logo image',
      type: 'string',
      optional: true,
    },
    logoSquare: {
      comment: 'The url for the square logo image',
      type: 'string',
    },
  },
});

/** Override type */
export type SaaSVendorPreview = SchemaToType<typeof SaaSVendorPreview>;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagKR: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55039"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55039)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55039"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55039)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.8933 9.28571C9.60142 9.28571 10.9861 7.86769 10.9861 6.11846C10.9861 4.36924 9.60142 2.95122 7.8933 2.95122C6.18518 2.95122 4.80048 4.36924 4.80048 6.11846C4.80048 7.86769 6.18518 9.28571 7.8933 9.28571Z"
          fill="#3D58DB"
        />
        <mask
          id="mask2_270_55039"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="2"
          width="7"
          height="8"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.8933 9.28571C9.60142 9.28571 10.9861 7.86769 10.9861 6.11846C10.9861 4.36924 9.60142 2.95122 7.8933 2.95122C6.18518 2.95122 4.80048 4.36924 4.80048 6.11846C4.80048 7.86769 6.18518 9.28571 7.8933 9.28571Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_270_55039)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M11.0943 6.36108C11.0943 6.36108 10.9207 4.86762 9.63502 4.78655C8.34933 4.70548 8.00266 5.88177 7.92847 6.19341C7.85428 6.50505 7.6665 7.3364 6.31282 7.3364C4.95914 7.3364 4.83704 5.22246 4.83704 5.22246V2.7298H11.0943V6.36108Z"
            fill="#E31D1C"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.64967 1.02608L4.10715 1.43985L1.98702 3.9158L1.52954 3.50204L3.64967 1.02608ZM4.40416 1.69566L4.86165 2.10943L2.81751 4.54951L2.36003 4.13575L4.40416 1.69566ZM5.61268 2.78726L5.1552 2.37349L3.10539 4.83209L3.56287 5.24586L5.61268 2.78726Z"
          fill="#272727"
        />
        <path
          // eslint-disable-next-line max-len
          d="M4.10715 1.43985L4.16412 1.48863L4.21159 1.43319L4.15746 1.38422L4.10715 1.43985ZM3.64967 1.02608L3.69998 0.970461L3.64282 0.918769L3.5927 0.977304L3.64967 1.02608ZM1.98702 3.9158L1.93671 3.97142L1.99387 4.02312L2.04399 3.96458L1.98702 3.9158ZM1.52954 3.50204L1.47257 3.45326L1.4251 3.5087L1.47923 3.55766L1.52954 3.50204ZM4.86165 2.10943L4.91914 2.15759L4.96551 2.10224L4.91195 2.0538L4.86165 2.10943ZM4.40416 1.69566L4.45447 1.64004L4.3967 1.58779L4.34667 1.6475L4.40416 1.69566ZM2.81751 4.54951L2.76721 4.60513L2.82498 4.65739L2.87501 4.59767L2.81751 4.54951ZM2.36003 4.13575L2.30254 4.08758L2.25617 4.14294L2.30973 4.19137L2.36003 4.13575ZM5.1552 2.37349L5.20551 2.31787L5.1476 2.26549L5.0976 2.32547L5.1552 2.37349ZM5.61268 2.78726L5.67029 2.83528L5.71642 2.77995L5.66299 2.73163L5.61268 2.78726ZM3.10539 4.83209L3.04779 4.78407L3.00166 4.8394L3.05508 4.88772L3.10539 4.83209ZM3.56287 5.24586L3.51256 5.30148L3.57048 5.35386L3.62048 5.29388L3.56287 5.24586ZM4.15746 1.38422L3.69998 0.970461L3.59936 1.08171L4.05684 1.49547L4.15746 1.38422ZM2.04399 3.96458L4.16412 1.48863L4.05018 1.39107L1.93005 3.86702L2.04399 3.96458ZM1.47923 3.55766L1.93671 3.97142L2.03733 3.86018L1.57985 3.44641L1.47923 3.55766ZM3.5927 0.977304L1.47257 3.45326L1.58651 3.55082L3.70664 1.07487L3.5927 0.977304ZM4.91195 2.0538L4.45447 1.64004L4.35386 1.75129L4.81134 2.16505L4.91195 2.0538ZM2.87501 4.59767L4.91914 2.15759L4.80415 2.06126L2.76002 4.50135L2.87501 4.59767ZM2.30973 4.19137L2.76721 4.60513L2.86782 4.49389L2.41034 4.08012L2.30973 4.19137ZM4.34667 1.6475L2.30254 4.08758L2.41753 4.18391L4.46166 1.74383L4.34667 1.6475ZM5.1049 2.42912L5.56238 2.84288L5.66299 2.73163L5.20551 2.31787L5.1049 2.42912ZM3.163 4.88012L5.21281 2.42152L5.0976 2.32547L3.04779 4.78407L3.163 4.88012ZM3.61318 5.19023L3.1557 4.77647L3.05508 4.88772L3.51256 5.30148L3.61318 5.19023ZM5.55508 2.73923L3.50527 5.19783L3.62048 5.29388L5.67029 2.83528L5.55508 2.73923Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M12.2925 1.02608L11.835 1.43985L13.9552 3.9158L14.4127 3.50204L12.2925 1.02608ZM11.538 1.69566L11.0806 2.10943L13.1247 4.54951L13.5822 4.13575L11.538 1.69566ZM10.3295 2.78726L10.787 2.37349L12.8368 4.83209L12.3793 5.24586L10.3295 2.78726Z"
          fill="#272727"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.835 1.43985L11.7781 1.48863L11.7306 1.43319L11.7847 1.38422L11.835 1.43985ZM12.2925 1.02608L12.2422 0.970461L12.2994 0.918769L12.3495 0.977304L12.2925 1.02608ZM13.9552 3.9158L14.0055 3.97142L13.9483 4.02312L13.8982 3.96458L13.9552 3.9158ZM14.4127 3.50204L14.4696 3.45326L14.5171 3.5087L14.463 3.55766L14.4127 3.50204ZM11.0806 2.10943L11.0231 2.15759L10.9767 2.10224L11.0302 2.0538L11.0806 2.10943ZM11.538 1.69566L11.4877 1.64004L11.5455 1.58779L11.5955 1.6475L11.538 1.69566ZM13.1247 4.54951L13.175 4.60513L13.1172 4.65739L13.0672 4.59767L13.1247 4.54951ZM13.5822 4.13575L13.6397 4.08758L13.686 4.14294L13.6325 4.19137L13.5822 4.13575ZM10.787 2.37349L10.7367 2.31787L10.7946 2.26549L10.8446 2.32547L10.787 2.37349ZM10.3295 2.78726L10.2719 2.83528L10.2258 2.77995L10.2792 2.73163L10.3295 2.78726ZM12.8368 4.83209L12.8944 4.78407L12.9405 4.8394L12.8871 4.88772L12.8368 4.83209ZM12.3793 5.24586L12.4296 5.30148L12.3717 5.35386L12.3217 5.29388L12.3793 5.24586ZM11.7847 1.38422L12.2422 0.970461L12.3428 1.08171L11.8854 1.49547L11.7847 1.38422ZM13.8982 3.96458L11.7781 1.48863L11.892 1.39107L14.0121 3.86702L13.8982 3.96458ZM14.463 3.55766L14.0055 3.97142L13.9049 3.86018L14.3624 3.44641L14.463 3.55766ZM12.3495 0.977304L14.4696 3.45326L14.3557 3.55082L12.2356 1.07487L12.3495 0.977304ZM11.0302 2.0538L11.4877 1.64004L11.5883 1.75129L11.1309 2.16505L11.0302 2.0538ZM13.0672 4.59767L11.0231 2.15759L11.138 2.06126L13.1822 4.50135L13.0672 4.59767ZM13.6325 4.19137L13.175 4.60513L13.0744 4.49389L13.5319 4.08012L13.6325 4.19137ZM11.5955 1.6475L13.6397 4.08758L13.5247 4.18391L11.4805 1.74383L11.5955 1.6475ZM10.8373 2.42912L10.3798 2.84288L10.2792 2.73163L10.7367 2.31787L10.8373 2.42912ZM12.7792 4.88012L10.7294 2.42152L10.8446 2.32547L12.8944 4.78407L12.7792 4.88012ZM12.329 5.19023L12.7865 4.77647L12.8871 4.88772L12.4296 5.30148L12.329 5.19023ZM10.3871 2.73923L12.4369 5.19783L12.3217 5.29388L10.2719 2.83528L10.3871 2.73923Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.64967 11.2459L4.10715 10.8321L1.98702 8.35614L1.52954 8.76991L3.64967 11.2459ZM4.40416 10.5763L4.86165 10.1625L2.81751 7.72243L2.36003 8.1362L4.40416 10.5763ZM5.61268 9.48469L5.1552 9.89845L3.10539 7.43985L3.56287 7.02609L5.61268 9.48469Z"
          fill="#272727"
        />
        <path
          // eslint-disable-next-line max-len
          d="M4.10715 10.8321L4.16412 10.7833L4.21159 10.8388L4.15746 10.8877L4.10715 10.8321ZM3.64967 11.2459L3.69998 11.3015L3.64282 11.3532L3.5927 11.2946L3.64967 11.2459ZM1.98702 8.35614L1.93671 8.30052L1.99387 8.24883L2.04399 8.30736L1.98702 8.35614ZM1.52954 8.76991L1.47257 8.81869L1.4251 8.76324L1.47923 8.71428L1.52954 8.76991ZM4.86165 10.1625L4.91914 10.1144L4.96551 10.1697L4.91195 10.2181L4.86165 10.1625ZM4.40416 10.5763L4.45447 10.6319L4.3967 10.6842L4.34667 10.6244L4.40416 10.5763ZM2.81751 7.72243L2.76721 7.66681L2.82498 7.61455L2.87501 7.67427L2.81751 7.72243ZM2.36003 8.1362L2.30254 8.18436L2.25617 8.12901L2.30973 8.08057L2.36003 8.1362ZM5.1552 9.89845L5.20551 9.95407L5.1476 10.0065L5.0976 9.94648L5.1552 9.89845ZM5.61268 9.48469L5.67029 9.43666L5.71642 9.49199L5.66299 9.54031L5.61268 9.48469ZM3.10539 7.43985L3.04779 7.48788L3.00166 7.43255L3.05508 7.38423L3.10539 7.43985ZM3.56287 7.02609L3.51256 6.97046L3.57048 6.91808L3.62048 6.97806L3.56287 7.02609ZM4.15746 10.8877L3.69998 11.3015L3.59936 11.1902L4.05684 10.7765L4.15746 10.8877ZM2.04399 8.30736L4.16412 10.7833L4.05018 10.8809L1.93005 8.40492L2.04399 8.30736ZM1.47923 8.71428L1.93671 8.30052L2.03733 8.41177L1.57985 8.82553L1.47923 8.71428ZM3.5927 11.2946L1.47257 8.81869L1.58651 8.72112L3.70664 11.1971L3.5927 11.2946ZM4.91195 10.2181L4.45447 10.6319L4.35386 10.5207L4.81134 10.1069L4.91195 10.2181ZM2.87501 7.67427L4.91914 10.1144L4.80415 10.2107L2.76002 7.7706L2.87501 7.67427ZM2.30973 8.08057L2.76721 7.66681L2.86782 7.77806L2.41034 8.19182L2.30973 8.08057ZM4.34667 10.6244L2.30254 8.18436L2.41753 8.08803L4.46166 10.5281L4.34667 10.6244ZM5.1049 9.84283L5.56238 9.42906L5.66299 9.54031L5.20551 9.95407L5.1049 9.84283ZM3.163 7.39182L5.21281 9.85042L5.0976 9.94648L3.04779 7.48788L3.163 7.39182ZM3.61318 7.08171L3.1557 7.49547L3.05508 7.38423L3.51256 6.97046L3.61318 7.08171ZM5.55508 9.53271L3.50527 7.07411L3.62048 6.97806L5.67029 9.43666L5.55508 9.53271Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M12.2925 11.2459L11.835 10.8321L13.9552 8.35614L14.4127 8.76991L12.2925 11.2459ZM11.538 10.5763L11.0806 10.1625L13.1247 7.72243L13.5822 8.1362L11.538 10.5763ZM10.3295 9.48469L10.787 9.89845L12.8368 7.43985L12.3793 7.02609L10.3295 9.48469Z"
          fill="#272727"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.835 10.8321L11.7781 10.7833L11.7306 10.8388L11.7847 10.8877L11.835 10.8321ZM12.2925 11.2459L12.2422 11.3015L12.2994 11.3532L12.3495 11.2946L12.2925 11.2459ZM13.9552 8.35614L14.0055 8.30052L13.9483 8.24883L13.8982 8.30736L13.9552 8.35614ZM14.4127 8.76991L14.4696 8.81869L14.5171 8.76324L14.463 8.71428L14.4127 8.76991ZM11.0806 10.1625L11.0231 10.1144L10.9767 10.1697L11.0302 10.2181L11.0806 10.1625ZM11.538 10.5763L11.4877 10.6319L11.5455 10.6842L11.5955 10.6244L11.538 10.5763ZM13.1247 7.72243L13.175 7.66681L13.1172 7.61455L13.0672 7.67427L13.1247 7.72243ZM13.5822 8.1362L13.6397 8.18436L13.686 8.12901L13.6325 8.08057L13.5822 8.1362ZM10.787 9.89845L10.7367 9.95407L10.7946 10.0065L10.8446 9.94648L10.787 9.89845ZM10.3295 9.48469L10.2719 9.43666L10.2258 9.49199L10.2792 9.54031L10.3295 9.48469ZM12.8368 7.43985L12.8944 7.48788L12.9405 7.43255L12.8871 7.38423L12.8368 7.43985ZM12.3793 7.02609L12.4296 6.97046L12.3717 6.91808L12.3217 6.97806L12.3793 7.02609ZM11.7847 10.8877L12.2422 11.3015L12.3428 11.1902L11.8854 10.7765L11.7847 10.8877ZM13.8982 8.30736L11.7781 10.7833L11.892 10.8809L14.0121 8.40492L13.8982 8.30736ZM14.463 8.71428L14.0055 8.30052L13.9049 8.41177L14.3624 8.82553L14.463 8.71428ZM12.3495 11.2946L14.4696 8.81869L14.3557 8.72112L12.2356 11.1971L12.3495 11.2946ZM11.0302 10.2181L11.4877 10.6319L11.5883 10.5207L11.1309 10.1069L11.0302 10.2181ZM13.0672 7.67427L11.0231 10.1144L11.138 10.2107L13.1822 7.7706L13.0672 7.67427ZM13.6325 8.08057L13.175 7.66681L13.0744 7.77806L13.5319 8.19182L13.6325 8.08057ZM11.5955 10.6244L13.6397 8.18436L13.5247 8.08803L11.4805 10.5281L11.5955 10.6244ZM10.8373 9.84283L10.3798 9.42906L10.2792 9.54031L10.7367 9.95407L10.8373 9.84283ZM12.7792 7.39182L10.7294 9.85042L10.8446 9.94648L12.8944 7.48788L12.7792 7.39182ZM12.329 7.08171L12.7865 7.49547L12.8871 7.38423L12.4296 6.97046L12.329 7.08171ZM10.3871 9.53271L12.4369 7.07411L12.3217 6.97806L10.2719 9.43666L10.3871 9.53271Z"
          fill="#272727"
        />
      </g>
    </g>
  </svg>
);

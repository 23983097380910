/**
 * Tests if a string is a double byte
 *
 * @example <caption>Example usage of isDoubleByte</caption>
 * // The str to test
 * const str = 'test';
 * // Returns
 * isDoubleByte(str);
 * @param str - The str to test
 * @returns True if double byte
 */
export function isDoubleByte(str: string): boolean {
  if (!str) {
    return false;
  }
  for (let i = 0, n = str.length; i < n; i += 1) {
    if (str.charCodeAt(i) > 255) {
      return true;
    }
  }
  return false;
}

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ChatConversationQuestion: React.FC<SvgProps> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.083 21.813C6.345 21.813 5.646 21.65 5.018 21.359L2 22L2.631 18.976C2.338 18.346 2.174 17.644 2.174 16.904C2.174 14.196 4.369 12 7.078 12C9.787 12 11.982 14.196 11.982 16.904C11.982 19.612 9.786 21.808 7.078 21.808"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.612 12.019C6.219 11.164 6 10.227 6 9.24C6 5.223 9.598 2 14 2C18.402 2 22 5.223 22 9.24C22 11.614 20.738 13.704 18.801 15.022C18.802 15.778 18.8 16.797 18.8 17.867L15.661 16.321C15.124 16.424 14.569 16.48 14 16.48C13.289 16.48 12.602 16.388 11.944 16.23"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.79537 16.9646C4.81489 16.9842 4.81489 17.0158 4.79537 17.0354C4.77584 17.0549 4.74418 17.0549 4.72465 17.0354C4.70513 17.0158 4.70513 16.9842 4.72465 16.9646C4.74418 16.9451 4.77584 16.9451 4.79537 16.9646"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.04537 16.9646C7.06489 16.9842 7.06489 17.0158 7.04537 17.0354C7.02584 17.0549 6.99418 17.0549 6.97465 17.0354C6.95513 17.0158 6.95513 16.9842 6.97465 16.9646C6.99418 16.9451 7.02584 16.9451 7.04537 16.9646"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.29537 16.9646C9.31489 16.9842 9.31489 17.0158 9.29537 17.0354C9.27584 17.0549 9.24418 17.0549 9.22465 17.0354C9.20513 17.0158 9.20513 16.9842 9.22465 16.9646C9.24418 16.9451 9.27584 16.9451 9.29537 16.9646"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0001 10.0442C14.0001 9.38718 14.4061 9.03118 14.8131 8.75718C15.2111 8.48918 15.6091 8.14018 15.6091 7.49718C15.6091 6.60818 14.8891 5.88818 14.0001 5.88818C13.1111 5.88818 12.3911 6.60818 12.3911 7.49718"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 12.4581C13.945 12.4581 13.9 12.5031 13.9 12.5581C13.9 12.6131 13.945 12.6581 14 12.6581C14.055 12.6581 14.1 12.6131 14.1 12.5581C14.1 12.5031 14.055 12.4581 14 12.4581"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

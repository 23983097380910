import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const StreetSigns: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00033 8.66675V6.66675"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00033 3.33333V2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4.33366 3.33325H11.457C11.6497 3.33325 11.8323 3.41659 11.959 3.56125L12.837 4.56725C13.0537 4.81525 13.0537 5.18459 12.837 5.43259L11.959 6.43859C11.8323 6.58325 11.6497 6.66659 11.457 6.66659H4.33366C3.96566 6.66659 3.66699 6.36792 3.66699 5.99992V3.99992C3.66699 3.63192 3.96566 3.33325 4.33366 3.33325Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.6658 12.0001H4.5425C4.34983 12.0001 4.16717 11.9167 4.0405 11.7721L3.1625 10.7661C2.94583 10.5181 2.94583 10.1487 3.1625 9.90075L4.0405 8.89475C4.1665 8.75008 4.34983 8.66675 4.5425 8.66675H11.6658C12.0338 8.66675 12.3325 8.96541 12.3325 9.33341V11.3334C12.3325 11.7014 12.0338 12.0001 11.6658 12.0001Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00033 14V12"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

// stroke-Linecap is not registering
/* eslint-disable react/no-unknown-property */
export const InsertTableColumnRight: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="4.66861"
      height="12.005"
      rx="1"
      transform="matrix(-1 0 0 1 6.66602 1.9975)"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.9974 4.99876H6.66602"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.9974 8.00001H6.66602"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.9974 11.0013H6.66602"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
    <rect
      width="5.33556"
      height="5.33556"
      rx="1.5"
      transform="matrix(-1 0 0 1 14.0024 5.33221)"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66718 1.9975H10.668C11.0364 1.9975 11.335 2.2961 11.335 2.66444V5.33222"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.335 10.6678V13.3356C11.335 13.7039 11.0364 14.0025 10.668 14.0025H8.66718"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5011 8.00001H12.1685"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.335 7.16632V8.83368"
      stroke={color}
      strokeWidth="1.2"
      stroke-Linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable react/no-unknown-property */

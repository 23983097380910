import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const SpeechBubbleSlash: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M1.06109 0.139607C0.776716 -0.0846119 0.363826 -0.0326588 0.139607 0.251716C-0.0846119 0.536091 -0.0326588 0.948982 0.251716 1.1732L16.4392 13.8607C16.7236 14.0849 17.1365 14.033 17.3607 13.7486C17.5849 13.4642 17.533 13.0513 17.2486 12.8271L14.0248 10.3005C15.0994 9.3025 15.7502 7.99273 15.7502 6.56265C15.7502 3.42086 12.6166 0.875154 8.75015 0.875154C6.89898 0.875154 5.21461 1.46031 3.96226 2.41461L1.06109 0.139607ZM5.03687 3.25406C5.99664 2.60328 7.26539 2.18765 8.75015 2.18765C12.1599 2.18765 14.4377 4.38883 14.4377 6.56265C14.4377 7.61265 13.9072 8.66812 12.9748 9.4775L5.03687 3.25406ZM11.5966 11.7607L10.3388 10.7681C9.8439 10.8775 9.31343 10.9377 8.75289 10.9377C7.88883 10.9377 7.07672 10.7873 6.35211 10.5248C5.94195 10.3771 5.48531 10.44 5.13258 10.6998C4.90836 10.8638 4.52281 11.1045 4.05523 11.3205C4.20836 10.9185 4.32593 10.4646 4.36422 9.96968C4.39156 9.61695 4.27398 9.26695 4.04156 8.99898C3.40172 8.27984 3.06265 7.43765 3.06265 6.56265C3.06265 6.11968 3.15562 5.67672 3.33609 5.25289L2.26969 4.4107C1.93336 5.07515 1.75015 5.8025 1.75015 6.56265C1.75015 7.79586 2.23414 8.93609 3.05445 9.86851C3.0025 10.5384 2.74273 11.1345 2.46929 11.5884C2.42554 11.6623 2.37906 11.7361 2.32984 11.8099L2.32164 11.8236C2.27789 11.8865 2.23414 11.9494 2.19039 12.0123C2.09469 12.1408 1.99078 12.2666 1.8814 12.3814C1.75562 12.5072 1.72008 12.6931 1.78843 12.8572C1.85679 13.0212 2.01539 13.1279 2.19312 13.1279C2.33258 13.1279 2.47203 13.1197 2.61148 13.106L2.63062 13.1033C2.75093 13.0896 2.87125 13.0732 2.99156 13.0513C3.01344 13.0486 3.03531 13.0431 3.05719 13.0377C3.5439 12.9419 4.01148 12.7779 4.42711 12.5974C5.05328 12.324 5.58648 11.9986 5.91187 11.7607C6.7814 12.0752 7.7439 12.2502 8.75836 12.2502C9.77007 12.2502 10.7353 12.0752 11.6048 11.7607H11.5966Z"
      fill={color}
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagUY: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55180"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55180)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55180"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55180)">
        <rect y="2" width="16" height="1" fill="#2E42A5" />
        <rect y="4" width="16" height="1" fill="#2E42A5" />
        <rect y="6" width="16" height="1" fill="#2E42A5" />
        <rect y="8" width="16" height="1" fill="#2E42A5" />
        <rect y="10" width="16" height="1" fill="#2E42A5" />
        <rect width="8" height="7" fill="#F7FCFF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.61377 5.5186C4.61377 5.5186 4.10283 6.57796 3.65375 6.79515C3.84759 6.30927 3.91894 5.3179 3.91894 5.3179C3.91894 5.3179 2.75298 5.78017 2.34374 5.69592C2.83583 5.35205 3.51888 4.71514 3.51888 4.71514C3.51888 4.71514 2.00976 4.22178 2.04679 4.0118C2.72802 4.134 3.66488 4.00079 3.66488 4.00079C3.66488 4.00079 2.60618 2.73117 2.71355 2.63676C2.87492 2.79361 4.1437 3.45638 4.1437 3.45638C4.1437 3.45638 4.23575 2.32677 4.5088 1.94592C4.54156 2.21476 4.92481 3.43194 4.92481 3.43194C4.92481 3.43194 5.69525 2.66106 6.12287 2.66106C5.93504 2.89394 5.49029 3.91691 5.49029 3.91691C5.49029 3.91691 6.59869 3.89947 7.02006 4.10692C6.50961 4.17952 5.61533 4.62848 5.61533 4.62848C5.61533 4.62848 6.78006 5.51199 6.67387 5.69592C6.04852 5.39001 5.32555 5.28756 5.32555 5.28756C5.32555 5.28756 5.52539 6.51709 5.36385 6.79515C5.20631 6.38585 4.61377 5.5186 4.61377 5.5186Z"
          fill="#FFD018"
          stroke="#F19900"
          strokeOpacity="0.98"
          strokeWidth="0.25"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.54675 4.94592C4.8229 4.94592 5.04675 4.72207 5.04675 4.44592C5.04675 4.16978 4.8229 3.94592 4.54675 3.94592C4.27061 3.94592 4.04675 4.16978 4.04675 4.44592C4.04675 4.72207 4.27061 4.94592 4.54675 4.94592Z"
          fill="#FFD018"
          stroke="#F19900"
          strokeOpacity="0.98"
          strokeWidth="0.25"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagMV: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55152"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55152)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V11C16 11.5523 15.5523 12 15 12H1C0.447715 12 0 11.5523 0 11V0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H13V9H3V3Z"
        fill="#579D20"
      />
      <path
        d="M3.5 3.5H12.5V8.5H3.5V3.5Z"
        stroke="#B6EB9A"
        strokeOpacity="0.238111"
      />
      <mask
        id="mask1_270_55152"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="10"
        height="6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3H13V9H3V3Z"
          fill="white"
        />
        <path d="M3.5 3.5H12.5V8.5H3.5V3.5Z" stroke="white" />
      </mask>
      <g mask="url(#mask1_270_55152)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.01649 6.23125C8.00799 7.7641 9.21472 8.58136 9.21472 8.58136C7.83776 8.74304 6.92163 7.49475 6.92163 6.24619C6.92163 4.99763 8.17043 3.96626 9.21472 3.50053C9.21472 3.50053 8.02498 4.6984 8.01649 6.23125Z"
          fill="#F9FAFA"
        />
      </g>
    </g>
  </svg>
);

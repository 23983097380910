/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Skip: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.514 6.28377L13.612 11.1428C14.259 11.5308 14.259 12.4698 13.612 12.8578L5.514 17.7168C4.848 18.1158 4 17.6358 4 16.8588V7.14077C4 6.36377 4.848 5.88377 5.514 6.28377Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 18V18C17.672 18 17 17.328 17 16.5V7.5C17 6.672 17.672 6 18.5 6V6C19.328 6 20 6.672 20 7.5V16.5C20 17.328 19.328 18 18.5 18Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

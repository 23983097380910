import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Policy } from '@main/pc-types/src/schema';

/**
 *policies state
 */
export interface PoliciesState {
  /** The privacyCenter policies configuration */
  policies?: Policy[];
}

export const policiesSlice = createSlice({
  name: 'Policies',
  initialState: {} as PoliciesState,
  reducers: {
    setPrivacyCenterPolicies: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** Updated policies */
        policies: Policy[];
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setPrivacyCenterPolicies } = policiesSlice.actions;

import React, { ReactNode } from 'react';
import type { OptionTypeBase } from 'react-select';
import { CSSObject } from 'styled-components';

import {
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '../GenericFormattedMessage';
import { Icon, IconType } from '../Icon';
import { Logo } from '../Logo';
import { FlexRowCenterVertical } from '../StyledWrappers';
import { GetColorFunc } from './styles';

export interface OptionLabelProps<T extends OptionTypeBase> {
  /** The data (option) */
  data: T;
  /** Function to get the relevant colors for this component */
  getColors: GetColorFunc<T>;
  /** Resolves option data to a string to be displayed as the label by components */
  getOptionLabel?: (data: T) => string;
  /** Resolves option data to a string to compare options and specify value attributes */
  getOptionLogo?: (data: T) => JSX.Element | string;
  /** Resolves option data to an icon type */
  getOptionIcon?: (data: T) => IconType;
  /** What to render inside the option label pill wrapper */
  formattedOptionPill?: ReactNode;
}

/**
 * The option labels (styled as pills)
 */
export function OptionLabel<T extends OptionTypeBase>({
  data,
  getColors,
  getOptionLabel = (data) => data.label,
  getOptionLogo = (data) => data.logo,
  getOptionIcon = (data) => data.icon,
  formattedOptionPill,
}: OptionLabelProps<T>): JSX.Element {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const style = getColors(data);
  const label = getOptionLabel(data);
  const logo = getOptionLogo(data);
  const icon = getOptionIcon(data);
  return (
    <RawOptionLabel
      id={data?.id}
      style={style}
      label={formatMessageGeneric(label)}
      logo={logo}
      icon={icon}
      optionPill={formattedOptionPill}
    />
  );
}

export interface RawOptionLabelProps {
  /** The id for this option */
  id?: string;
  /** The label for this option */
  label?: GenericMessageDescriptor;
  /** (Optional) a string or element to render as the option logo */
  logo?: JSX.Element | string;
  /** (Optional) a non-Antd icon type */
  icon?: IconType;
  /** Specify this option if you want to completely customize the label/logo component */
  optionPill?: ReactNode;
  /** The color, background-color, and border color for the option */
  style: Pick<CSSObject, 'borderColor' | 'backgroundColor' | 'color'>;
}

/**
 * The option labels (styled as pills)
 */
export function RawOptionLabel({
  style,
  id,
  label,
  logo,
  icon,
  optionPill,
}: RawOptionLabelProps): JSX.Element {
  return (
    <FlexRowCenterVertical
      className="styled-option-label"
      style={{
        color: 'inherit',
        fontWeight: 600,
        fontSize: '100%',
        width: 'fit-content',
        maxWidth: '100%',
        ...style,
      }}
    >
      {optionPill || (
        <>
          {icon ? (
            <Icon type={icon} size={20} />
          ) : typeof logo === 'string' ? (
            <Logo logo={logo} height="20px" />
          ) : (
            logo
          )}
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              minWidth: 0,
              display: 'block',
              whiteSpace: 'normal',
              ...(logo || icon ? { marginLeft: '5px' } : {}),
            }}
            {...(id && { id })}
          >
            {label}
          </span>
        </>
      )}
    </FlexRowCenterVertical>
  );
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CircleQuestion: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M8.00033 8.83326V8.66659C8.00033 8.12192 8.33699 7.82659 8.67433 7.59992C9.00366 7.37792 9.33366 7.08859 9.33366 6.55526C9.33366 5.81859 8.73699 5.22192 8.00033 5.22192C7.26366 5.22192 6.66699 5.81859 6.66699 6.55526"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M7.99966 10.6667C7.90766 10.6667 7.83299 10.7413 7.83366 10.8333C7.83366 10.9253 7.90833 11 8.00033 11C8.09233 11 8.167 10.9253 8.167 10.8333C8.167 10.7413 8.09233 10.6667 7.99966 10.6667"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

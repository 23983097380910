import { ScopeName } from '@transcend-io/privacy-types';

import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { TeamOrderField } from './enums';
import { Resource } from './resource';
import { ScopePreview } from './scopePreview';
import { TeamPreview } from './teamPreview';
import { UserPreview } from './userPreview';

export const TeamFiltersInput = mkInput({
  name: 'TeamFiltersInput',
  comment: 'Input for determining what teams to filter',
  fields: {
    ids: {
      comment: 'Filter by team ids',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    text: {
      comment: 'Filter by text (name of team)',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type TeamFiltersInput = SchemaToType<typeof TeamFiltersInput>;

export const ParentTeamFiltersInput = mkInput({
  name: 'ParentTeamFiltersInput',
  comment: 'Input for determining what teams to filter',
  fields: {
    ids: TeamFiltersInput.fields.ids,
    text: TeamFiltersInput.fields.text,
  },
});

/** Override type */
export type ParentTeamFiltersInput = SchemaToType<
  typeof ParentTeamFiltersInput
>;

export const TeamInput = mkInput({
  name: 'TeamInput',
  comment: `
    Input for defining a new team. A team is a group of users in the organization.
    The team can be assigned scopes and all users in that team will inherit the scopes
  `,
  fields: {
    name: {
      comment: 'The name of the team',
      type: 'string',
    },
    description: {
      comment: 'The internal description of the team',
      type: 'string',
      optional: true,
    },
    ssoTitle: {
      comment:
        'The SSO titles that should be auto assigned to this team on account creation.',
      type: 'string',
      optional: true,
    },
    ssoDepartment: {
      comment:
        'The SSO departments that should be auto assigned to this team on account creation.',
      type: 'string',
      optional: true,
    },
    ssoGroup: {
      comment:
        'The SSO groups that should be auto assigned to this team on account creation.',
      type: 'string',
      optional: true,
    },
    scopes: {
      comment: 'The scopes that the team should get',
      type: { ScopeName },
      list: true,
      optional: true,
    },
    users: {
      comment: 'The ids of the users that should be in that team',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    userEmails: {
      comment: 'The emails of the users that should be in that team',
      type: 'string',
      list: true,
      optional: true,
    },
    dataSilos: {
      comment: 'The ids of the data silos to assign to',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
    parentTeam: {
      comment:
        'The id of the team in the parent organization to link to this team for auto-provisioning purposes',
      type: 'id',
      modelName: 'team',
      optional: true,
    },
  },
});

/** Override type */
export type TeamInput = SchemaToType<typeof TeamInput>;

export const UpdateTeamInput = mkInput({
  name: 'UpdateTeamInput',
  comment: 'Input for updating a team',
  fields: {
    id: {
      comment: 'The id of the team to update',
      type: 'id',
      modelName: 'team',
    },
    name: {
      comment: 'The new name of the team',
      type: 'string',
      optional: true,
    },
    description: TeamInput.fields.description,
    ssoTitle: TeamInput.fields.ssoTitle,
    ssoDepartment: TeamInput.fields.ssoDepartment,
    ssoGroup: TeamInput.fields.ssoGroup,
    users: {
      comment:
        'The ids of the users in the organization the team should contain',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    userEmails: {
      comment: 'The emails of the users that should be in that team',
      type: 'string',
      list: true,
      optional: true,
    },
    scopes: {
      comment: 'The scopes the team should have',
      type: { ScopeName },
      list: true,
      optional: true,
    },
    dataSilos: {
      comment: 'The ids of the data silos to assign to',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
    parentTeam: TeamInput.fields.parentTeam,
  },
});

/** Override type */
export type UpdateTeamInput = SchemaToType<typeof UpdateTeamInput>;

export const Team = mkType({
  name: 'Team',
  comment: `
    An existing team of users.
    The team can be assigned scopes and all users in that team will inherit the scopes
  `,
  fields: {
    ...TeamPreview.fields,
    ssoTitle: TeamInput.fields.ssoTitle,
    ssoDepartment: TeamInput.fields.ssoDepartment,
    ssoGroup: TeamInput.fields.ssoGroup,
    users: {
      comment: 'The users that belong to the team',
      type: () => UserPreview,
      list: true,
    },
    linkedTeams: {
      comment:
        'The teams that are linked to this team in other Transcend instances',
      type: () => TeamPreview,
      list: true,
    },
    parentTeam: {
      comment:
        'The parent team that is linked to this team in the parent Transcend instance',
      type: () => TeamPreview,
      optional: true,
    },
    scopes: {
      comment: 'The scopes that the team is assigned directly',
      type: () => ScopePreview,
      list: true,
    },
    allScopes: {
      comment:
        'All calculated scopes that the users in the team will get. This includes dependent scopes',
      type: () => ScopePreview,
      list: true,
    },
    dataSilos: {
      comment: 'The data silos connected to the API key',
      type: Resource,
      list: true,
    },
  },
});

/** Override type */
export type Team = SchemaToType<typeof Team>;

/**
 * Order for a teams query
 */
export const TeamOrder = mkOrder(Team.name, TeamOrderField);

/** Override type */
export type TeamOrder = SchemaToType<typeof TeamOrder>;

/**
 * The fields that an api key can be ordered on
 */
export enum ApiKeyOrderField {
  /** The title of the api key */
  Title = 'title',
  /** The age of the api key */
  CreatedAt = 'createdAt',
  /** The last updated time */
  UpdatedAt = 'updatedAt',
  /** Last used time */
  LastUsedAt = 'lastUsedAt',
}

/**
 * The status of an api key. This is used to indicate the need for an api key to be cycled
 */
export enum ApiKeyStatus {
  /** The api key is fresh (fewer than 100 days old) */
  Fresh = 'fresh',
  /** The api key needs to be refreshed soon (>100 <150 days) */
  Warning = 'warning',
  /** The api key is old and should be refreshed immediately */
  Stale = 'stale',
}

import { RequestAction } from '@transcend-io/privacy-types';
import { createEnum, filterEnum } from '@transcend-io/type-utils';

import { ACTIONS } from '../actions';

/**
 * The action types that have secondary actions
 */
export const REQUEST_ACTIONS_WITH_SECONDARY = filterEnum(
  ACTIONS,
  (action) => !!action.delay,
);

/**
 * The actions that have secondary actions, keyed by secondary action name
 */
export const REQUEST_ACTION_WITH_SECONDARY = createEnum(
  REQUEST_ACTIONS_WITH_SECONDARY,
);

/**
 * Check if an action configuration has a primary and secondary action
 *
 * @param type - The action type has a secondary action
 * @returns True if the action has a secondary action
 */
export function hasSecondaryAction(type: RequestAction): boolean {
  return type in REQUEST_ACTION_WITH_SECONDARY;
}

/**
 * The action types that have download reports
 */
export const REQUEST_ACTIONS_WITH_DOWNLOAD = filterEnum(
  ACTIONS,
  (action, type) =>
    type === RequestAction.Access ||
    (!!action.delay && action.delay.previewAction === RequestAction.Access),
);

/**
 * The actions that have download reports, keyed by action name
 */
export const REQUEST_ACTION_WITH_DOWNLOAD = createEnum(
  REQUEST_ACTIONS_WITH_DOWNLOAD,
);

/**
 * Check if an action has a download report
 *
 * @param type - The action type has a secondary action
 * @returns True if the action has a download report
 */
export function hasDownloadReport(type: RequestAction): boolean {
  return type in REQUEST_ACTION_WITH_DOWNLOAD;
}

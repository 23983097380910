import { ApolloError } from '@apollo/client';
import { debounce, ONE_SECOND } from '@main/utils';
import isEqual from 'lodash/isEqual';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { OptionTypeBase, ValueType } from 'react-select';

import { paginatedSelectMessages } from './messages';
import { ReactSelect } from './ReactSelect';
import { ReactCreatableSelectExtendedProps } from './types';

/**
 * the type of the select options, including both grouped and ungrouped values
 */
type SelectOptions<TNode extends OptionTypeBase> =
  // ungrouped
  | TNode[]
  // grouped
  | {
      /** the group label */
      label: any;
      /** the group options */
      options: TNode[];
    }[];

/**
 * Base props for PaginatedSelect
 */
export interface BasePaginatedSelectProps<
  TNode extends OptionTypeBase,
  IsMulti extends boolean,
> extends ReactCreatableSelectExtendedProps<IsMulti, TNode> {
  /** Whether we should allow the user to create new options */
  isCreatable?: boolean;
  /** The options for this select component */
  options: SelectOptions<TNode>;
  /** True if the pagination/new request is in progress */
  isQueryLoading: boolean;
  /** Any error that comes from the query */
  queryError?: ApolloError;
  /** A listener for when a user stops typing in the search bar */
  onEndsTyping?: (searchText: string) => void;
  /** The amount of time to wait after the user finishes typing to debounce new requests */
  searchTextDebounceDelay?: number;
  /** Message to display when there is no content */
  notFoundDescriptor?: MessageDescriptor;
  /** The placeholder message */
  placeholderDescriptor?: MessageDescriptor;
  /** The message when a query is loading */
  loadingDescriptor?: MessageDescriptor;
  /** A message to display when a query failed */
  errorDescriptor?: MessageDescriptor;
}

export interface RawPaginatedSelectProps<
  TNode extends OptionTypeBase,
  IsMulti extends boolean,
> extends BasePaginatedSelectProps<TNode, IsMulti> {
  /** A function to grab more values when the user scrolls to the bottom of the dropdown */
  getNextPage: () => void;
  /** selector ID */
  id?: string;
}

/**
 * A React component for displaying paginated queries in dropdowns.
 *
 * This Select component does not need to be inside a form. It has custom props
 * to make pagination using hooks easy, but otherwise takes in the same props
 * as the default react-select component.
 *
 * This component is written as a function, and does not use React.FC, because
 * it uses generic parameters for its props.
 *
 */
export function RawPaginatedSelect<
  TNode extends OptionTypeBase,
  IsMulti extends boolean,
>({
  isCreatable = false,
  isQueryLoading,
  queryError,
  onEndsTyping,
  onChange,
  value,
  isLoading,
  options,
  searchTextDebounceDelay = ONE_SECOND / 2,
  placeholderDescriptor = paginatedSelectMessages.placeholder,
  notFoundDescriptor = paginatedSelectMessages.notFound,
  loadingDescriptor = paginatedSelectMessages.loading,
  errorDescriptor = paginatedSelectMessages.error,
  onBlur,
  getOptionValue,
  getNextPage,
  ...selectProps
}: PropsWithChildren<RawPaginatedSelectProps<TNode, IsMulti>>): ReactElement {
  const [selected, setSelected] = useState<ValueType<TNode, IsMulti>>(
    value as any,
  );
  const [isWaitingForTypingToEnd, setIsWaitingForTypingToEnd] = useState(false);
  const getValueFunc = useCallback(
    (o: TNode): any => (getOptionValue ? getOptionValue(o) : o?.id),
    [getOptionValue],
  );

  const { formatMessage } = useIntl();

  const setSearchTextDebounced = useCallback(
    debounce((searchText) => {
      if (onEndsTyping) {
        onEndsTyping(searchText);
      }
      setIsWaitingForTypingToEnd(false);
    }, searchTextDebounceDelay),
    [searchTextDebounceDelay],
  );

  // If the value has changed externally, sync it up with our state
  useEffect(() => {
    setSelected(value as any);
  }, [value]);

  return (
    <ReactSelect<IsMulti, TNode>
      options={queryError ? [] : options}
      closeMenuOnSelect={false}
      backspaceRemovesValue={false}
      isSearchable
      captureMenuScroll
      filterOption={null} // Ignore the default filter settings, which is covered by the search functionality
      isLoading={isWaitingForTypingToEnd || isQueryLoading || isLoading}
      loadingMessage={() => formatMessage(loadingDescriptor)}
      getOptionValue={getValueFunc}
      isCreatable={isCreatable}
      isOptionSelected={(option, selectedVal): boolean => {
        const optionValue = getValueFunc(option);
        const selectedOptions: TNode[] = Array.isArray(selectedVal)
          ? selectedVal
          : [selectedVal];
        return !!selectedOptions.find((val) =>
          isEqual(getValueFunc(val), optionValue),
        );
      }}
      // React-select does not have a concept of errors, so we update the noOptionsMessage on error cases
      noOptionsMessage={() =>
        queryError
          ? formatMessage(errorDescriptor)
          : isCreatable
            ? null
            : formatMessage(notFoundDescriptor)
      }
      placeholder={
        !selectProps.isDisabled &&
        placeholderDescriptor &&
        formatMessage(placeholderDescriptor)
      }
      onInputChange={(newSearchText, { action }) => {
        if (action === 'input-change') {
          setIsWaitingForTypingToEnd(true);
          setSearchTextDebounced(newSearchText);
        }
      }}
      onMenuScrollToBottom={() => {
        if (getNextPage) {
          getNextPage();
        }
      }}
      onChange={(selectedValue, action) => {
        // gross logic, but when the action is remove-value, selectedValue is empty
        // as a workaround we have to keep track of what's been selected so far in local state
        if (
          action.action === 'remove-value' &&
          selectProps.isMulti &&
          Array.isArray(selected)
        ) {
          const remainingSelectedValues = selected.filter(
            (option) =>
              getValueFunc(option) !== getValueFunc(action.removedValue),
          ) as unknown as ValueType<TNode, IsMulti>;
          setSelected(remainingSelectedValues);
          if (onChange) {
            onChange(remainingSelectedValues, action);
          }
        } else {
          setSelected(selectedValue);
          if (onEndsTyping) {
            onEndsTyping('');
          }
          if (onChange) {
            onChange(selectedValue, action);
          }
        }
      }}
      onBlur={
        onEndsTyping
          ? (e) => {
              onBlur?.(e);
              onEndsTyping('');
            }
          : onBlur
      }
      value={selected}
      {...selectProps}
    />
  );
}

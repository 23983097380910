import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { EmailDomainVerificationStatus } from './enums/emailDomain';

/**
 * Input for adding an email domain
 */
export const EmailDomainInput = mkInput({
  name: 'CreateEmailDomainInput',
  comment: 'Input for adding an email domain',
  fields: {
    domain: {
      comment: 'the customer domain name',
      type: 'string',
    },
  },
});

/** Override type */
export type EmailDomainInput = SchemaToType<typeof EmailDomainInput>;

/**
 * Customer Domains from which Transcend can send emails
 */
export const EmailDomain = mkType({
  name: 'EmailDomain',
  comment: 'Domains from which Transcend can send emails',
  fields: {
    id: {
      comment: 'The domain id',
      modelName: 'emailDomain',
      type: 'id',
    },
    domain: {
      comment: 'The customer domain name',
      type: 'string',
    },
    domainVerificationStatus: {
      comment: 'The current status of the domain verification process',
      type: { EmailDomainVerificationStatus },
    },
    dkimVerificationStatus: {
      comment: 'The current status of the DKIM settings verification process',
      type: { EmailDomainVerificationStatus },
    },
    receiveVerificationStatus: {
      comment:
        'The current status of the receive MX settings verification process',
      type: { EmailDomainVerificationStatus },
    },
    domainToken: {
      comment: 'The verification token for the domain',
      type: 'string',
      optional: true,
    },
    dkimTokens: {
      comment: 'The verification tokens for dkim validation',
      type: 'string',
      list: true,
      optional: true,
    },
    receiveMx: {
      comment: 'The MX record value to set in order to receive email',
      type: 'string',
    },
  },
});

/** Override type */
export type EmailDomain = SchemaToType<typeof EmailDomain>;

export const CurrentEmailSenderAddressesInput = mkInput({
  name: 'CurrentEmailSenderAddressesInput',
  comment: 'Input to the current email sender addresses endpoint',
  fields: {
    privacyCenterId: {
      comment: 'The id of the privacy center for which to fetch',
      type: 'id',
      modelName: 'privacyCenter',
      optional: true,
    },
  },
});

/** Override type */
export type CurrentEmailSenderAddressesInput = SchemaToType<
  typeof CurrentEmailSenderAddressesInput
>;

/**
 * Customer Domains from which Transcend can send emails
 */
export const EmailSenderAddress = mkType({
  name: 'EmailSenderAddress',
  comment: 'Email addresses from which Transcend can send emails',
  fields: {
    id: {
      comment: 'The address id',
      modelName: 'emailSenderAddress',
      type: 'id',
    },
    name: {
      comment: 'The internal name of this email address',
      type: 'string',
    },
    priority: {
      comment: 'The priority of this email address',
      type: 'int',
    },
    fromPrefix: {
      comment: 'The email prefix',
      type: 'string',
      optional: true,
    },
    replyTo: {
      comment: 'The reply-to email',
      type: 'string',
      optional: true,
    },
    alias: {
      comment: 'The pretty email alias',
      type: 'string',
      optional: true,
    },
    displayName: {
      comment: 'The pretty display name for inside the email body',
      type: 'string',
      optional: true,
    },
    attributeValues: {
      comment:
        'The request attribute values corresponding to this email address',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
    },
  },
});

/** Override type */
export type EmailSenderAddress = SchemaToType<typeof EmailSenderAddress>;

export const CreateEmailSenderAddressInput = mkInput({
  name: 'CreateEmailSenderAddressInput',
  comment: 'Input to update email sender addresses',
  fields: {
    name: {
      comment: 'The internal name of this email address',
      type: 'string',
    },
    priority: {
      comment: 'The priority of this email address',
      type: 'int',
    },
    fromPrefix: {
      comment: 'The customer domain name',
      type: 'string',
      optional: true,
    },
    replyTo: {
      comment: 'The customer domain name',
      type: 'string',
      optional: true,
    },
    alias: {
      comment: 'The customer domain name',
      type: 'string',
      optional: true,
    },
    displayName: {
      comment: 'The customer domain name',
      type: 'string',
      optional: true,
    },
    attributeValues: {
      comment:
        'The request attribute value ids corresponding to this email address',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    privacyCenters: {
      comment: 'The privacy centers to which this email address is assigned',
      type: 'id',
      modelName: 'privacyCenter',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateEmailSenderAddressInput = SchemaToType<
  typeof CreateEmailSenderAddressInput
>;

export const UpdateEmailSenderAddressInput = mkInput({
  name: 'UpdateEmailSenderAddressInput',
  comment: 'Input to update email sender addresses',
  fields: {
    id: {
      comment: 'The address id',
      modelName: 'emailSenderAddress',
      type: 'id',
    },
    ...CreateEmailSenderAddressInput.fields,
    name: {
      ...CreateEmailSenderAddressInput.fields.name,
      optional: true,
    },
    priority: {
      ...CreateEmailSenderAddressInput.fields.priority,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateEmailSenderAddressInput = SchemaToType<
  typeof UpdateEmailSenderAddressInput
>;

export const DeleteEmailSenderAddressInput = mkInput({
  name: 'DeleteEmailSenderAddressInput',
  comment: 'Input to update email sender addresses',
  fields: {
    id: {
      comment: 'The address id',
      modelName: 'emailSenderAddress',
      type: 'id',
    },
  },
});

/** Override type */
export type DeleteEmailSenderAddressInput = SchemaToType<
  typeof DeleteEmailSenderAddressInput
>;

/**
 * Fields to order an sdk by when paginating
 */
export enum SdkOrderField {
  /** The name of the sdk */
  Name = 'name',
  /** The created at date of the sdk */
  CreatedAt = 'createdAt',
  /** The time the sdk was updated */
  UpdatedAt = 'updatedAt',
}

import { makeEnum } from '@transcend-io/type-utils';

/**
 * Onboarding status for Consent Manager
 */
export const ConsentManagerOnboardingStatus = makeEnum({
  /** The user has created a consent manager and deployed it, but hasn't put the script on their site yet */
  Started: 'STARTED',
  /** Waiting for telemetry data to populate the purposeMap */
  AwaitingTelemetry: 'AWAITING_TELEMETRY',
  /** The user is ready to launch their consent manager and have it regulate requests */
  ReadyToLaunch: 'READY_TO_LAUNCH',
  /** Done - the user has launched their consent manager */
  Done: 'DONE',
});

/** Override type */
export type ConsentManagerOnboardingStatus =
  (typeof ConsentManagerOnboardingStatus)[keyof typeof ConsentManagerOnboardingStatus];

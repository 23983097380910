import { createFrontendUuidv4 } from '@main/utils';
import React, { useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import {
  CenterDot,
  HiddenRadio,
  LabelWrapper,
  RadioInputCircle,
  RadioInputContainer,
} from './wrappers';

/**
 * represents the checked state of the radio input
 */
export interface RadioInputProps
  extends Omit<
    React.ComponentPropsWithRef<'input'>,
    // TS error if enterKeyHint is not included here
    'children' | 'onChange' | 'type' | 'enterKeyHint' | 'name'
  > {
  /** Text for enter key on virtual keyboards */
  enterKeyHint?:
    | 'enter'
    | 'done'
    | 'go'
    | 'next'
    | 'previous'
    | 'search'
    | 'send'
    | undefined;
  /** label element */
  children: React.ReactNode;
  /** callback invoked when input state changes */
  onChange?: () => void;
  /** message for input name */
  name?: MessageDescriptor;
  /** value for radio button */
  value?: string;
}

export const RadioInput = React.forwardRef<HTMLInputElement, RadioInputProps>(
  (
    {
      children,
      disabled = false,
      checked = false,
      onChange,
      name,
      value,
      ...props
    },
    ref,
  ) => {
    const id = useMemo(() => createFrontendUuidv4(), []);
    const { formatMessage } = useIntl();

    return (
      <RadioInputContainer
        onMouseUp={() => {
          if (!checked) onChange?.();
        }}
        radioInputValue={checked ? 'checked' : 'unchecked'}
        disabled={disabled}
      >
        <HiddenRadio
          ref={ref}
          id={id}
          type="radio"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name ? formatMessage(name) : ''}
          value={value || ''}
          {...props}
        />
        <LabelWrapper style={{ cursor: 'pointer' }} htmlFor={id}>
          <RadioInputCircle>{checked && <CenterDot />}</RadioInputCircle>
          {children}
        </LabelWrapper>
      </RadioInputContainer>
    );
  },
);

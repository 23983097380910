import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Columns: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M0.5 2.625C0.5 1.6584 1.2834 0.875 2.25 0.875H16.25C17.2152 0.875 18 1.6584 18 2.625V11.375C18 12.3402 17.2152 13.125 16.25 13.125H2.25C1.2834 13.125 0.5 12.3402 0.5 11.375V2.625ZM12.75 11.8125H16.25C16.4906 11.8125 16.6875 11.6156 16.6875 11.375V2.625C16.6875 2.38328 16.4906 2.1875 16.25 2.1875H12.75V11.8125ZM11.4375 2.1875H7.0625V11.8125H11.4375V2.1875ZM5.75 11.8125V2.1875H2.25C2.00828 2.1875 1.8125 2.38328 1.8125 2.625V11.375C1.8125 11.6156 2.00828 11.8125 2.25 11.8125H5.75Z"
      fill={color}
    />
  </svg>
);

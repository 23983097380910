import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Switch: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4 9.59933H5.59998L7.19931 11.2"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.80064 4.79933L10.4 6.40066H5.59998"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M10.6667 14H5.33333C3.49267 14 2 12.5073 2 10.6667V5.33333C2 3.49267 3.49267 2 5.33333 2H10.6667C12.5073 2 14 3.49267 14 5.33333V10.6667C14 12.5073 12.5073 14 10.6667 14Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

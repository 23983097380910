import React from 'react';

import { SvgProps } from '../enums';

export const FlagMX: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55118"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55118)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H16V12H11V0Z"
        fill="#D9071E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H6V12H0V0Z"
        fill="#006923"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H11V12H5V0Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.19213 3.9567C7.19213 3.9567 6.84873 4.22302 6.91295 4.50696C6.97717 4.7909 7.65392 4.50696 7.57211 4.23183C7.4903 3.9567 7.19213 3.9567 7.19213 3.9567Z"
        fill="#FCCA3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.66779 4.63405C6.41085 4.63405 6.30373 4.41993 6.35688 4.19235C6.38528 4.07073 6.45905 3.91644 6.57941 3.71597L7.00808 3.97336C6.96219 4.04979 6.92561 4.11613 6.89848 4.1709C6.98835 4.19744 7.07694 4.23688 7.14904 4.28436C7.3825 4.43809 7.46843 4.71201 7.21814 4.91502C7.1697 4.95431 7.0911 5.08827 7.04771 5.21692C7.16713 5.22357 7.25249 5.24153 7.33118 5.28808C7.55217 5.4188 7.53138 5.65721 7.36309 5.84131C7.24381 5.9718 7.08454 6.07765 6.9345 6.13187C6.68487 6.22208 6.41779 6.18753 6.41779 5.86795L6.41773 5.86561C6.41724 5.8526 6.4164 5.83035 6.52788 5.82783L6.54959 5.8276L6.54199 5.82755L6.52788 5.82783C6.33766 5.82513 6.29695 5.72443 6.27323 5.54512C6.26722 5.4997 6.2648 5.45647 6.26146 5.36318L6.25923 5.30247L6.25656 5.24335L6.25575 5.2302C6.25199 5.17248 6.24939 5.13251 6.37878 5.13251H6.08875V4.63251H6.37878C6.54328 4.63251 6.63419 4.68806 6.68628 4.80291C6.71528 4.75083 6.74683 4.7018 6.78003 4.65784C6.73821 4.64337 6.69579 4.63405 6.66779 4.63405ZM6.98626 5.51229L6.99404 5.50397C6.99319 5.5049 6.99235 5.50609 6.99154 5.50751L6.98626 5.51229ZM6.76793 5.66053C6.76738 5.66065 6.76709 5.66071 6.7672 5.66067L6.76803 5.66051L6.76793 5.66053ZM6.76788 5.47205L6.76891 5.47954L6.76729 5.46507L6.76788 5.47205Z"
        fill="#A8AC71"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9.63328 6.20145C9.63328 6.20145 10.0958 4.83077 9.36059 4.12126C8.6254 3.41174 7.77585 3.45922 7.77585 3.45922C7.77585 3.45922 7.5312 3.64099 7.77585 3.77962C8.0205 3.91825 7.918 4.05117 7.918 4.05117C7.918 4.05117 7.50828 3.63515 7.23629 3.8782C6.9643 4.12126 7.49019 4.08426 7.43452 4.26727C7.37885 4.45028 7.143 5.26281 7.48763 5.67303C7.83227 6.08326 7.16179 5.99423 7.29816 5.99423C7.43452 5.99423 7.918 6.08972 7.918 5.99423C7.918 5.89874 8.08608 6.36547 8.22993 6.36547C8.37377 6.36547 8.4848 6.20145 8.4848 6.20145C8.4848 6.20145 8.68644 6.36547 8.8093 6.36547C8.93216 6.36547 9.36059 6.25813 9.36059 6.25813L8.55085 5.57587C8.55085 5.57587 8.59967 5.27494 8.4848 5.2098C8.36993 5.14467 9.40569 5.78702 9.4868 5.99423C9.56791 6.20145 9.63328 6.20145 9.63328 6.20145Z"
        fill="#8F4620"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.53329 5.86329C5.53329 5.86329 5.60836 5.62401 5.68874 5.6074C5.75838 5.59301 5.90481 5.72207 5.90481 5.72207C6.16493 7.167 6.79389 7.73136 7.83752 7.73136C8.89343 7.73136 9.53339 7.30252 9.90685 5.96989C9.90685 5.96989 10.1054 5.76714 10.1754 5.78968C10.2517 5.81422 10.3473 6.08246 10.3473 6.08246C10.2517 7.64077 9.12665 8.56448 7.85482 8.56448C6.57072 8.56448 5.52226 7.52947 5.53329 5.86329Z"
        fill="#9FAB2F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.66711 6.82822C6.66711 6.82822 8.16929 7.14689 8.76221 7.14689C9.35513 7.14689 8.24198 7.63079 7.80765 7.63079C7.37331 7.63079 6.66711 6.82822 6.66711 6.82822Z"
        fill="#2FC2DC"
      />
      <rect
        x="7.33337"
        y="7.05"
        width="1"
        height="0.535714"
        rx="0.0833333"
        fill="#F9AA51"
      />
      <path
        // eslint-disable-next-line max-len
        d="M6.08777 6.06794L6.49708 5.78078C7.0298 6.54009 7.82645 6.78989 8.94066 6.52917L9.05458 7.01602C7.75207 7.3208 6.74526 7.0051 6.08777 6.06794Z"
        fill="#259485"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.2957 6.17513C8.2957 6.17513 7.68887 6.49456 7.89115 6.49456C8.09342 6.49456 8.88507 6.65428 8.73103 6.49456C8.57699 6.33484 8.2957 6.17513 8.2957 6.17513Z"
        fill="#FCCA3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.57826 5.79412C7.57826 5.79412 7.46296 5.46109 7.22302 5.46109C6.98307 5.46109 7.0508 5.73428 6.93542 5.73428C6.82003 5.73428 7.04293 5.92652 7.14228 5.92652C7.24163 5.92652 7.57826 5.79412 7.57826 5.79412Z"
        fill="#FCCA3D"
      />
    </g>
  </svg>
);

import { ContentWrapper } from '@main/core-ui/src/ContentWrapper';
import { Skeleton } from '@main/core-ui/src/Skeleton';
import React from 'react';

/**
 * The skeleton component to display while loading
 */
export function PageSkeleton(): JSX.Element {
  return (
    <ContentWrapper>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </ContentWrapper>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

import { NODE_ENV } from '@main/core-ui';

import { privacyCenterPersistedReducer } from './reducer';

export const privacyCenterStore = configureStore({
  devTools:
    NODE_ENV === 'development'
      ? {
          trace: true,
          traceLimit: 25,
        }
      : false,
  reducer: privacyCenterPersistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  preloadedState: {},
});

/** Shape of dispatch */
export type PrivacyCenterDispatch = typeof privacyCenterStore.dispatch;

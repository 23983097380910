import { LanguageKey } from '@transcend-io/internationalization';
import { invertSafe } from '@transcend-io/type-utils';

/**
 * Mapping of browser locale to AWS base translation key
 *
 * TODO: https://transcend.height.app/T-39777
 * This is here for a quick fix of our CM UI translation logic. Copied from the monorepo's extract_intl.ts
 * This should be removed ASAP (it should be in the intl repo and imported, not in here or extract_intl.ts)
 */
export const TRANSLATE_LOCALE = {
  [LanguageKey.EsEs]: 'es',
  [LanguageKey.NlNl]: 'nl',
  [LanguageKey.NlBe]: 'nl',
  [LanguageKey.Es419]: 'es-MX',
  // This is a hack to fix the fact that we don't have a LanguageKey -> BrowserLanguageKey mapping
  'es-MX': 'es-MX',
  [LanguageKey.ZhHk]: 'zh-TW',
  [LanguageKey.AfZz]: 'af',
  [LanguageKey.Ar]: 'ar',
  [LanguageKey.En]: 'en',
  [LanguageKey.Fr]: 'fr',
  [LanguageKey.Es]: 'es',
  [LanguageKey.De]: 'de',
  [LanguageKey.It]: 'it',
  [LanguageKey.Ja]: 'ja',
  [LanguageKey.Ru]: 'ru',
  [LanguageKey.ArAe]: 'ar',
  [LanguageKey.FrFr]: 'fr',
  [LanguageKey.DeDe]: 'de',
  [LanguageKey.ItIt]: 'it',
  [LanguageKey.BgBg]: 'bg',
  [LanguageKey.ZhCn]: 'zh',
  [LanguageKey.HrHr]: 'hr',
  [LanguageKey.CsCz]: 'cs',
  [LanguageKey.DaDk]: 'da',
  [LanguageKey.EnGb]: 'en',
  [LanguageKey.FiFi]: 'fi',
  [LanguageKey.ElGr]: 'el',
  [LanguageKey.HiIn]: 'hi',
  [LanguageKey.HuHu]: 'hu',
  [LanguageKey.IdId]: 'id',
  [LanguageKey.JaJp]: 'ja',
  [LanguageKey.KoKr]: 'ko',
  [LanguageKey.LtLt]: 'lt',
  [LanguageKey.MsMy]: 'ms',
  [LanguageKey.NbNi]: 'no',
  [LanguageKey.PlPl]: 'pl',
  [LanguageKey.PtBr]: 'pt',
  [LanguageKey.PtPt]: 'pt',
  [LanguageKey.RoRo]: 'ro',
  [LanguageKey.RuRu]: 'ru',
  [LanguageKey.SrLatnRs]: 'sr',
  [LanguageKey.SvSe]: 'sv',
  [LanguageKey.TaIn]: 'ta',
  [LanguageKey.ThTh]: 'th',
  [LanguageKey.TrTr]: 'tr',
  [LanguageKey.UkUa]: 'uk',
  [LanguageKey.ViVn]: 'vi',
  [LanguageKey.EnUS]: 'en',
  [LanguageKey.EnAu]: 'en',
  [LanguageKey.FrBe]: 'fr',
  [LanguageKey.EnIe]: 'en',
  [LanguageKey.EnCa]: 'en',
  [LanguageKey.EnAe]: 'en',
  [LanguageKey.DeAt]: 'de',
  [LanguageKey.DeCh]: 'de',
  [LanguageKey.ItCh]: 'it',
  [LanguageKey.FrCh]: 'fr',
  [LanguageKey.HeIl]: 'he',
  [LanguageKey.EnNz]: 'en',
  [LanguageKey.EtEe]: 'et',
  [LanguageKey.IsIs]: 'is',
  [LanguageKey.LvLv]: 'lv',
  [LanguageKey.MtMt]: 'mt',
  [LanguageKey.SkSk]: 'sk',
  [LanguageKey.SlSl]: 'sl',
  [LanguageKey.MrIn]: 'mr',
  [LanguageKey.ZuZa]: 'en',
} as unknown as { [k in LanguageKey]: string };
/** Mapping of AWS base translation keys to list of browser locales that should use them */
export const INVERTED_TRANSLATE_LOCALE = invertSafe(TRANSLATE_LOCALE);

/**
 * Get all potential sub-languages from a given ISO 639-1 language code
 *
 * @param langCode - ISO 639-1 format language code
 * @returns Array of potential sub-languages, sorted by most to least specific
 */
const getAllSubLanguages = (langCode: string): string[] =>
  langCode
    .split('-')
    .flatMap((_, i, tags) => tags.slice(0, i + 1).join('-'))
    .reverse();

/**
 * Match preferred language list against a supported languages list
 *
 * @param preferred - Sorted language list in order of most preferable to least preferable
 * @param supported - List of supported languages to match from
 * @returns Preferred languages (including sub-language matches) that match supported languages list
 */
export const matchLanguages = (
  preferred: string[] | readonly string[],
  supported: string[],
): string[] => {
  const matches = new Set<string>();
  const allSupportedLanguages = supported.flatMap(getAllSubLanguages);
  const nearestTranslations = preferred
    .flatMap(getAllSubLanguages)
    .filter((language) => {
      if (!allSupportedLanguages.includes(language)) {
        return false;
      }
      const unique = !matches.has(language);
      if (unique) {
        matches.add(language);
      }
      return unique;
    });
  if (
    nearestTranslations.length > 0 &&
    !supported.includes(nearestTranslations[0])
  ) {
    nearestTranslations[0] = INVERTED_TRANSLATE_LOCALE[
      nearestTranslations[0]
    ].find((lang) => supported.includes(lang)) as LanguageKey;
  }
  return nearestTranslations;
};

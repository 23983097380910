import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CaretDown: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 4.25L6 7.75L9.5 4.25"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';
import type { GroupTypeBase, OptionTypeBase } from 'react-select';
import { components, OptionProps } from 'react-select';
import { useTheme } from 'styled-components';

import { Icon } from '../Icon';
import { OptionWrapper } from './wrappers';

/**
 * Our custom Option component with a helpful checkmark to show what's currently selected
 */
export function Option<
  T extends OptionTypeBase,
  IsMulti extends boolean,
  G extends GroupTypeBase<T>,
>({ children, ...props }: OptionProps<T, IsMulti, G>): JSX.Element {
  const theme = useTheme();

  return (
    <components.Option {...props}>
      <OptionWrapper className="paginated-select-option">
        {children}
        {props.isSelected && (
          <Icon color={theme.colors.transcend} type="checkmark" />
        )}
      </OptionWrapper>
    </components.Option>
  );
}

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const ChangePasswordInput = mkInput({
  name: 'ChangePasswordInput',
  comment: 'Input for changing the password when not logged in',
  fields: {
    newPassword: {
      comment: 'The new password',
      type: 'string',
    },
    token: {
      comment: 'The token that was email to the forgot password email',
      type: 'string',
    },
  },
});

/** Override type */
export type ChangePasswordInput = SchemaToType<typeof ChangePasswordInput>;

export const ResetPasswordInput = mkInput({
  name: 'ResetPasswordInput',
  comment: 'Input for resetting a password',
  fields: {
    email: {
      comment: 'The email to reset',
      type: 'string',
    },
    captcha: {
      comment: 'The captcha value to verify that a user is making the request',
      type: 'string',
    },
  },
});

/** Override type */
export type ResetPasswordInput = SchemaToType<typeof ResetPasswordInput>;

export const UpdatePasswordInput = mkInput({
  name: 'UpdatePasswordInput',
  comment: "Input for updating a user's password",
  fields: {
    newPassword: {
      comment: 'The new password',
      type: 'string',
    },
    password: {
      comment: 'The old password',
      type: 'string',
    },
  },
});

/** Override type */
export type UpdatePasswordInput = SchemaToType<typeof UpdatePasswordInput>;

export const ChangePasswordResult = mkType({
  name: 'ChangePasswordResult',
  comment: 'Information provided with a valid reset password token',
  fields: {
    id: {
      comment: 'The id of the user',
      type: 'id',
      modelName: 'user',
    },
    email: {
      comment: 'The email of the user',
      type: 'string',
    },
    mustAcceptTerms: {
      comment:
        'When true, the user must check the terms and conditions before creating the account.',
      type: 'boolean',
    },
    isOnlyOrganizationUser: {
      comment: 'When true, this is the only user in the organization.',
      type: 'boolean',
    },
    terms: {
      comment: 'Terms and conditions',
      type: 'string',
    },
    organizationName: {
      comment: 'The name of the organization',
      type: 'string',
    },
    onboarded: {
      comment: 'True if user is onboarded',
      type: 'boolean',
    },
  },
});

/** Override type */
export type ChangePasswordResult = SchemaToType<typeof ChangePasswordResult>;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Image: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M5.61926 4.76192C4.98807 4.76192 4.47641 5.27359 4.47641 5.90478C4.47641 6.53597 4.98807 7.04764 5.61926 7.04764C6.25045 7.04764 6.76212 6.53597 6.76212 5.90478C6.76212 5.27359 6.25022 4.76192 5.61926 4.76192ZM12.6455 2.66669H3.5026C2.68236 2.66669 2 3.34883 2 4.1905V11.8095C2 12.6512 2.68214 13.3334 3.50238 13.3334H12.6452C13.4869 13.3334 14.169 12.6512 14.169 11.8095V4.1905C14.1693 3.34883 13.5074 2.66669 12.6455 2.66669ZM13.0264 11.65L9.76926 7.22383C9.70974 7.11431 9.57403 7.04764 9.42879 7.04764C9.28324 7.04764 9.14736 7.11354 9.06664 7.22333L6.52855 10.6543L5.64545 9.55666C5.56353 9.45478 5.43274 9.39454 5.29379 9.39454C5.15469 9.39454 5.02403 9.45476 4.94188 9.55666L3.14379 11.7902C3.14379 11.7895 3.14379 11.7909 3.14379 11.7902L3.14284 4.1905C3.14284 3.98045 3.31374 3.80954 3.52379 3.80954H12.6666C12.8767 3.80954 13.0476 3.98045 13.0476 4.1905V11.65H13.0264Z"
      fill={color}
    />
  </svg>
);

import {
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '@main/core-ui/src/GenericFormattedMessage';
import React, { ReactNode, useState } from 'react';

import { formatErrorMessage } from './formatErrorMessage';
import { errorTextMessages } from './messages';
import {
  ErrorTextWrapperProps,
  FormattedErrorWrapper,
  StyledExpandButton,
} from './wrappers';

/**
 * Props
 */
export interface ErrorTextProps extends ErrorTextWrapperProps {
  /** The error message */
  children?: React.ReactNode;
  /** Graphql error object */
  error?: Partial<Error> | GenericMessageDescriptor;
  /** Style */
  style?: React.CSSProperties;
  /** should we truncate the message? */
  shouldTruncate?: boolean;
  /** max length of error message before truncating */
  maxErrorLengthTruncated?: number;
}

/**
 * Display error text in error color
 */
export const ErrorText: React.FC<ErrorTextProps> = ({
  children,
  error,
  display,
  fontSize,
  isBold = true,
  hasMargin = true,
  inheritColor,
  maxErrorLengthTruncated = 500,
  style,
  shouldTruncate,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const [expanded, setExpanded] = useState(false);

  let errorToDisplay: ReactNode = children;
  if (error) {
    if (typeof error === 'string' || (error as Partial<Error>).message) {
      errorToDisplay = formatErrorMessage(
        (error as Partial<Error>).message ?? (error as string),
      );
    } else {
      errorToDisplay = formatMessageGeneric(error);
    }
  }
  let showExpandToggle = false;
  if (
    errorToDisplay &&
    shouldTruncate &&
    typeof errorToDisplay === 'string' &&
    errorToDisplay.length > maxErrorLengthTruncated
  ) {
    showExpandToggle = true;
    errorToDisplay = expanded
      ? errorToDisplay
      : `${errorToDisplay.substring(0, maxErrorLengthTruncated)}...`;
  }
  return (
    <FormattedErrorWrapper
      fontSize={fontSize}
      display={display}
      isBold={isBold}
      hasMargin={hasMargin}
      inheritColor={inheritColor}
      style={style}
    >
      {errorToDisplay}
      {showExpandToggle && (
        <StyledExpandButton onClick={() => setExpanded(!expanded)}>
          {formatMessageGeneric(
            expanded
              ? errorTextMessages.lessDetails
              : errorTextMessages.moreDetails,
          )}
        </StyledExpandButton>
      )}
    </FormattedErrorWrapper>
  );
};

import React from 'react';

import { SvgProps } from '../enums';

export const FlagDZ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0H16V12H8V0Z"
      fill="#F7FCFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H8V12H0V0Z"
      fill="#36A400"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.31349 2.81594C10.0885 2.81594 10.8028 3.07534 11.3745 3.51204C10.593 2.51961 9.38056 1.88239 8.01931 1.88239C5.66197 1.88239 3.75098 3.79338 3.75098 6.15072C3.75098 8.50805 5.66197 10.4191 8.01931 10.4191C9.22909 10.4191 10.3213 9.91574 11.098 9.1071C10.5794 9.42774 9.96804 9.61279 9.31349 9.61279C7.43659 9.61279 5.91506 8.09126 5.91506 6.21437C5.91506 4.33747 7.43659 2.81594 9.31349 2.81594ZM10.7448 4.0682L9.67484 5.28082L8.13808 4.85739L8.99006 6.19001L8.13808 7.6086L9.75012 7.02827L10.6314 8.40388V6.8073L12.0715 6.19001L10.6314 5.67416L10.7448 4.0682Z"
      fill="#FF0000"
    />
  </svg>
);

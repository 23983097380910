import { mkInput, mkType, SchemaToType } from '@main/schema-utils';
import { EnvComparison } from '@main/sombra-types';

import { TeamPreview } from './teamPreview';

export const SsoProviderInput = mkInput({
  name: 'SsoProviderInput',
  comment: 'Input to create or update the SSO provider information',
  fields: {
    issuer: {
      comment:
        'This is a label telling us the name of the issuer application (AKA the "Entity ID")',
      type: 'string',
    },
    entryPoint: {
      comment: 'The redirect URL provided by the SSO issuer',
      type: 'string',
    },
    cert: {
      comment: 'The public key of the SSO provider',
      type: 'string',
    },
    domains: {
      comment:
        "When user's have an email address from any domain in this list, " +
        'they are authenticated against this SSO configuration',
      type: 'string',
      list: true,
    },
    defaultTeamId: {
      optional: true,
      comment:
        'The default team that users should be added to when auto provisioning. ' +
        'If the user is auto provisioned to other teams using groups or attributes, ' +
        'that team will be assigned instead of this team.',
      type: 'id',
      modelName: 'team',
    },
    shouldCreateUser: {
      comment:
        'When true, should auto-provision accounts for unrecognized emails',
      type: 'boolean',
    },
  },
});

/** Override type */
export type SsoProviderInput = SchemaToType<typeof SsoProviderInput>;

export const SsoProvider = mkType({
  name: 'SsoProvider',
  comment: 'The configuration settings for an SSO provider',
  fields: {
    issuer: SsoProviderInput.fields.issuer,
    entryPoint: SsoProviderInput.fields.entryPoint,
    cert: SsoProviderInput.fields.cert,
    domains: SsoProviderInput.fields.domains,
    shouldCreateUser: SsoProviderInput.fields.shouldCreateUser,
    id: {
      comment: 'The unique id of the sso provider configuration',
      modelName: 'ssoProvider',
      type: 'id',
    },
    invalidEnvs: {
      comment:
        'The environment variables that are different between sombra and transcend db',
      type: EnvComparison,
      list: true,
    },
    defaultTeam: {
      comment:
        'The default team that users should be added to when auto provisioning. ' +
        'If the user is auto provisioned to other teams using groups or attributes, ' +
        'that team will be assigned instead of this team.',
      type: TeamPreview,
      optional: true,
    },
    customAudience: {
      comment:
        'The custom audience for the SSO provider to validate. ' +
        'If null, the default audience `transcend` is used. ' +
        'This is only updatable by Transcend internally.',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SsoProvider = SchemaToType<typeof SsoProvider>;

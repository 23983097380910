import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { DataPointTypeDisplayName, ScanLambdaStatus, ScanType } from './enums';

export const SchemaDiscoveryCurrentScanProgressResult = mkType({
  name: 'SchemaDiscoveryCurrentScanProgressResult',
  comment: "A schema discovery plugin's scan result for a datapoint",
  fields: {
    displayName: {
      type: { DataPointTypeDisplayName },
      comment: 'The mapped terminology for this type of datapoint',
    },
    totalExpectedProgress: {
      type: 'int',
      comment: 'The total expected progress for this scan',
      optional: true,
    },
    currentProgress: {
      type: 'int',
      comment: 'How much progress have we made so far',
      optional: true,
    },
  },
});

/** Override type */
export type SchemaDiscoveryCurrentScanProgressResult = SchemaToType<
  typeof SchemaDiscoveryCurrentScanProgressResult
>;

export const SchemaDiscoveryScanResult = mkType({
  name: 'SchemaDiscoveryScanResult',
  comment: `A schema discovery plugin's scan result`,
  fields: {
    subDataPointResult: {
      comment: 'Subdatapoints found and mapped to the appropriate terminology',
      type: SchemaDiscoveryCurrentScanProgressResult,
    },
    dataPointResult: {
      comment: 'Datapoints found and mapped to the appropriate terminology',
      type: SchemaDiscoveryCurrentScanProgressResult,
    },
    dataPointLevelResults: {
      comment:
        'Datapoint levels found and mapped to the appropriate terminology',
      type: SchemaDiscoveryCurrentScanProgressResult,
      list: true,
    },
  },
});

/** Override type */
export type SchemaDiscoveryScanResult = SchemaToType<
  typeof SchemaDiscoveryScanResult
>;

export const ScanResult = mkType({
  name: 'ScanResult',
  comment: 'Result from a scan',
  fields: {
    schemaDiscovery: {
      comment: "A schema discovery plugin's scan result",
      type: SchemaDiscoveryScanResult,
      optional: true,
    },
  },
});

/** Override type */
export type ScanResult = SchemaToType<typeof ScanResult>;

export const Scan = mkType({
  name: 'Scan',
  comment: 'Scan metadata',
  fields: {
    id: {
      comment: 'ID of scan',
      type: 'string',
    },
    startEpoch: {
      comment: 'Unix epoch representing when this scan began',
      type: 'string',
    },
    status: {
      comment: 'Status of this scan.',
      type: { ScanLambdaStatus },
    },
    type: {
      comment: 'Scan type',
      type: { ScanType },
    },
    expiresAt: {
      comment: 'Unix epoch in SECONDS to allow for automatic DynamoDB cleanup',
      type: 'int',
    },
    pluginId: {
      comment: 'The ID of the plugin that contains this scan',
      type: 'id',
      modelName: 'plugin',
    },
    stack: {
      comment: 'Error / warning message',
      type: 'string',
      optional: true,
    },
    currentScanRunId: {
      comment: "The Scan Run ID that's running at the moment",
      type: 'string',
      optional: true,
    },
    lastSuccessfulScanRunId: {
      comment: "The most recently successful Scan Run's ID",
      type: 'string',
      optional: true,
    },
    lastScanRunId: {
      comment: "The most recent Scan Run's ID",
      type: 'string',
      optional: true,
    },
    endEpoch: {
      comment: 'Unix epoch representing when this scan run ended',
      type: 'string',
      optional: true,
    },
    scanResult: {
      comment: 'A Plugin Scan Result',
      type: ScanResult,
      optional: true,
    },
  },
});

/** Override type */
export type Scan = SchemaToType<typeof Scan>;

export const ScanRunId = mkType({
  name: 'ScanRunId',
  comment: 'Scan Run Id',
  fields: {
    scanId: {
      type: 'string',
      comment: 'Scan Id',
    },
    startEpoch: {
      type: 'string',
      comment: 'Start epoch for the scan run',
    },
  },
});

/** Override type */
export type ScanRunId = SchemaToType<typeof ScanRunId>;

export const ScanKey = mkType({
  name: 'ScanKey',
  comment: 'Key used for paginating scans',
  fields: {
    id: Scan.fields.id,
    startEpoch: Scan.fields.startEpoch,
  },
});

/** Override type */
export type ScanKey = SchemaToType<typeof ScanKey>;

export const ScanKeyInput = mkInput({
  name: 'ScanKeyInput',
  comment: 'Input for paginating scans',
  fields: ScanKey.fields,
});

/** Override type */
export type ScanKeyInput = SchemaToType<typeof ScanKeyInput>;

export const PluginScansInput = mkInput({
  name: 'PluginScansInput',
  comment: 'Input for listing past scan runs',
  fields: {
    pluginId: {
      comment: 'The plugin ID',
      type: 'id',
      modelName: 'plugin',
    },
    lastKey: {
      comment:
        'The `LastEvaluatedKey` received from DynamoDB for the previous page',
      type: ScanKeyInput,
      optional: true,
    },
  },
});

/** Override type */
export type PluginScansInput = SchemaToType<typeof PluginScansInput>;

/**
 * Fields that a saaSCategory can be ordered by
 */
export enum SaaSCategoryOrderField {
  /** The title of the saaSCategory */
  Title = 'title',
  /** The time the saas category was created */
  CreatedAt = 'createdAt',
  /** The time the saas category was updated */
  UpdatedAt = 'updatedAt',
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ClipboardCheck: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H15C16.1046 3 17 3.89543 17 5V10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="17"
      cy="17"
      r="4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 16.8872L16.75 17.8872L18.25 16.3872"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 3V5C13.5 5.27614 13.2761 5.5 13 5.5H9C8.72386 5.5 8.5 5.27614 8.5 5V3"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

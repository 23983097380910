/* eslint-disable max-lines */
import { IdentifierType } from '@transcend-io/privacy-types';
import { applyEnum, HttpMethod } from '@transcend-io/type-utils';

import { AttributeInput } from '@main/attribute-types';
import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import {
  AllowedEnricherTransition,
  SombraDataSubjectAuthMethod,
  SombraEmployeeAuthMethod,
  SombraHostedMethod,
  SombraKMSCanaryValueType,
  SombraKMSKeySource,
  SombraKMSKeyType,
} from './enums';

export const TextCategoryMatch = mkType({
  name: 'TextCategoryMatch',
  comment: 'A data category that is classified in a set of unstructured text',
  fields: {
    category: {
      comment: 'Category that was matched',
      type: 'string',
    },
    snippet: {
      comment: 'Snippet of value from text',
      type: 'string',
    },
  },
});

/** Override type */
export type TextCategoryMatch = SchemaToType<typeof TextCategoryMatch>;

export const TextCategory = mkType({
  name: 'TextCategory',
  comment: 'A data category that is classified in a set of unstructured text',
  fields: {
    value: {
      comment: 'Value from classification',
      type: 'string',
    },
    ...TextCategoryMatch.fields,
  },
});

/** Override type */
export type TextCategory = SchemaToType<typeof TextCategory>;

/**
 * TODO: https://transcend.height.app/T-13576 - remove this
 *
 * @deprecated
 */
export const DeprecatedRequestIdentifierInput = mkInput({
  name: 'DeprecatedRequestIdentifierInput',
  comment: 'Input for creating a new request identifier',
  fields: {
    name: {
      comment: 'The name of the custom identifier',
      optional: true,
      type: 'string',
    },
    value: {
      comment: 'The value of the identifier',
      type: 'string',
    },
  },
});

/** Override type */
export type DeprecatedRequestIdentifierInput = SchemaToType<
  typeof DeprecatedRequestIdentifierInput
>;

/**
 * TODO: https://transcend.height.app/T-13576 - remove this
 *
 * @deprecated
 */
export const DeprecatedRequestIdentifiersInput = mkInput({
  name: 'DeprecatedRequestIdentifiersInput',
  comment:
    'Deprecated: The identifiers associated with the request (list version of `RequestIdentifierValue`)',
  fields: applyEnum(IdentifierType, (type) => ({
    comment:
      type === IdentifierType.CoreIdentifier
        ? 'The core identifier (list but always 0 or 1)'
        : type === IdentifierType.Custom
          ? 'Custom defined identifiers for the data subject'
          : `The ${type} identifiers for the data subject. This identifier type is natively supported by Transcend.`,
    type: DeprecatedRequestIdentifierInput,
    list: true,
    optional: true,
  })),
});

/** Override type */
export type DeprecatedRequestIdentifiersInput = SchemaToType<
  typeof DeprecatedRequestIdentifiersInput
>;

export const DecryptionContext = mkType({
  name: 'DecryptionContext',
  comment: 'The items needed to decrypt data on the privacy center',
  fields: {
    wrappedKey: {
      comment: 'The wrapped decryption key',
      type: 'string',
    },
    authTag: {
      comment: 'The auth tag from oauth',
      type: 'string',
    },
    iv: {
      comment: 'The iv seeded randomness',
      type: 'string',
    },
  },
});

/** Override type */
export type DecryptionContext = SchemaToType<typeof DecryptionContext>;

export const EnvComparison = mkType({
  name: 'EnvComparison',
  comment: 'Compare environment variables between sombra and backend',
  fields: {
    sombra: {
      comment: 'The value on sombra',
      type: 'string',
    },
    transcend: {
      comment: 'The value on the backend',
      type: 'string',
    },
    name: {
      comment: 'The name of the env var',
      type: 'string',
    },
  },
});

/** Override type */
export type EnvComparison = SchemaToType<typeof EnvComparison>;

export const SAMLSchema = mkType({
  name: 'SAMLSchema',
  comment: 'SAML Configuration, for the employees',
  fields: {
    entryPoint: {
      comment:
        'Identity provider entrypoint (is required to be spec-compliant when the request is signed)',
      type: 'string',
    },
    cert: {
      comment:
        "The IDP's public signing certificate used to validate the signatures of the incoming SAML Responses",
      type: 'string',
    },
    issuer: {
      comment: 'Issuer string to supply to identity provider',
      type: 'string',
      optional: true,
    },
    audience: {
      comment:
        "Expected saml response Audience (if not provided, Audience won't be verified)",
      type: 'string',
      optional: true,
    },
    acceptedClockSkewMs: {
      comment: 'Allow some variability when verifying the claim expiration',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type SAMLSchema = SchemaToType<typeof SAMLSchema>;

export const SAMLSchemaInput = mkInput({
  name: 'SAMLSchemaInput',
  comment: 'Input for defining SAML configuration',
  fields: {
    entryPoint: {
      ...SAMLSchema.fields.entryPoint,
      optional: true,
    },
    cert: {
      ...SAMLSchema.fields.cert,
      optional: true,
    },
    issuer: SAMLSchema.fields.issuer,
    audience: SAMLSchema.fields.audience,
    acceptedClockSkewMs: SAMLSchema.fields.acceptedClockSkewMs,
  },
});

/** Override type */
export type SAMLSchemaInput = SchemaToType<typeof SAMLSchemaInput>;

export const OAuthParametersGetTokenBodySchema = mkType({
  name: 'OAuthParametersGetTokenBodySchema',
  comment:
    'Configuration for the body of the request that fetches the OAuth token',
  fields: {
    REDIRECT_URI: {
      comment: 'The redirect URI',
      type: 'string',
    },
    GRANT_TYPE: {
      comment: 'The OAuth token grant type',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type OAuthParametersGetTokenBodySchema = SchemaToType<
  typeof OAuthParametersGetTokenBodySchema
>;

export const OAuthParametersGetTokenSchema = mkType({
  name: 'OAuthParametersGetTokenSchema',
  comment: 'Configuration related to retrieving the Oauth Token',
  fields: {
    URL: {
      comment:
        'The token URL for your OAuth API (authorization_code => access_token)',
      type: 'string',
    },
    BODY: {
      comment:
        'Configuration for the body of the request that fetches the OAuth token',
      type: OAuthParametersGetTokenBodySchema,
    },
    METHOD: {
      comment:
        'The HTTP method for fetching the OAuth token from the OAuth API',
      type: { HttpMethod },
      optional: true,
    },
    HEADERS: {
      comment:
        'The headers to include when fetching the OAuth token as a stringified JSON object',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type OAuthParametersGetTokenSchema = SchemaToType<
  typeof OAuthParametersGetTokenSchema
>;

export const OAuthParametersGetCoreIdSchema = mkType({
  name: 'OAuthParametersGetCoreIdSchema',
  comment: 'Configuration related to fetching the core ID for the data subject',
  fields: {
    URL: {
      comment:
        'The API endpoint where we can find a user ID or other similar core identifier',
      type: 'string',
    },
    PATH: {
      comment:
        'The path to extract the coreIdentifier from the JSON response body',
      type: 'string',
      list: true,
    },
    // TODO: https://transcend.height.app/T-22613 - make required
    DATA_SUBJECT_TYPE: {
      comment: 'The type of Data Subject that the oauth login maps to',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type OAuthParametersGetCoreIdSchema = SchemaToType<
  typeof OAuthParametersGetCoreIdSchema
>;

export const OAuthParametersGetCoreIdSchemaInput = mkInput({
  name: 'OAuthParametersGetCoreIdSchemaInput',
  comment:
    'Input for configuration related to fetching the core ID for the data subject',
  fields: {
    URL: {
      ...OAuthParametersGetCoreIdSchema.fields.URL,
      optional: true,
    },
    PATH: {
      ...OAuthParametersGetCoreIdSchema.fields.PATH,
      optional: true,
    },
  },
});

/** Override type */
export type OAuthParametersGetCoreIdSchemaInput = SchemaToType<
  typeof OAuthParametersGetCoreIdSchemaInput
>;

export const OAuthParametersGetEmailSchema = mkType({
  name: 'OAuthParametersGetEmailSchema',
  comment: 'Configuration related to fetching the email for the data subject',
  fields: {
    URL: {
      comment: 'The API endpoint where we can find a user email',
      type: 'string',
      optional: true, // TODO: https://transcend.height.app/T-12565 - make required
    },
    PATH: {
      comment: 'The path to extract the email from the JSON response body',
      type: 'string',
      list: true,
    },
    IS_VERIFIED: {
      comment:
        'Whether all OAuth emails are already verified by default, attested by the organization',
      type: 'boolean',
      optional: true,
    },
    IS_VERIFIED_PATH: {
      comment: 'JSON path to the is_verified field on the Oauth profile',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type OAuthParametersGetEmailSchema = SchemaToType<
  typeof OAuthParametersGetEmailSchema
>;

export const OAuthParametersGetEmailSchemaInput = mkInput({
  name: 'OAuthParametersGetEmailSchemaInput',
  comment:
    'Input configuration related to fetching the email for the data subject',
  fields: {
    PATH: {
      ...OAuthParametersGetEmailSchema.fields.PATH,
      optional: true,
    },
    IS_VERIFIED: OAuthParametersGetEmailSchema.fields.IS_VERIFIED,
    IS_VERIFIED_PATH: OAuthParametersGetEmailSchema.fields.IS_VERIFIED_PATH,
  },
});

/** Override type */
export type OAuthParametersGetEmailSchemaInput = SchemaToType<
  typeof OAuthParametersGetEmailSchemaInput
>;

export const OAuthParametersGetProfilePictureSchema = mkType({
  name: 'OAuthParametersGetProfilePictureSchema',
  comment: 'Configuration related to fetching the data subject profile picture',
  fields: {
    URL: {
      comment:
        'Configuration to retrieve additional profile data for the data subject (e.g. nickname, picture)',
      type: 'string',
    },
    PATH: {
      comment:
        'A path to the additional profile data (can be to a full JSON object).',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type OAuthParametersGetProfilePictureSchema = SchemaToType<
  typeof OAuthParametersGetProfilePictureSchema
>;

export const OAuthParametersGetProfilePictureSchemaInput = mkInput({
  name: 'OAuthParametersGetProfilePictureSchemaInput',
  comment:
    'Input configuration related to fetching the profile picture for the data subject',
  fields: {
    PATH: OAuthParametersGetProfilePictureSchema.fields.PATH,
    URL: OAuthParametersGetProfilePictureSchema.fields.URL,
  },
});

/** Override type */
export type OAuthParametersGetProfilePictureSchemaInput = SchemaToType<
  typeof OAuthParametersGetProfilePictureSchemaInput
>;

export const OAuthParametersInputSchema = mkType({
  name: 'OAuthParametersInputSchema',
  comment: 'Schema for an object of type `OAuthParametersInput`',
  fields: {
    CLIENT_ID: {
      comment: "The Client ID of your privacy center's OAuth 2 application",
      type: 'string',
      list: true,
    },
    GET_TOKEN: {
      comment: 'Configuration related to retrieving the Oauth Token',
      type: OAuthParametersGetTokenSchema,
    },
    GET_CORE_ID: {
      comment:
        'Configuration related to fetching the core ID for the data subject',
      type: OAuthParametersGetCoreIdSchema,
    },
    GET_EMAIL: {
      comment:
        'Configuration related to fetching the email for the data subject',
      type: OAuthParametersGetEmailSchema,
    },
    GET_PROFILE_PICTURE: {
      comment:
        'Configuration related to fetching the data subject profile picture',
      type: OAuthParametersGetProfilePictureSchema,
      optional: true,
    },
  },
});

/** Override type */
export type OAuthParametersInputSchema = SchemaToType<
  typeof OAuthParametersInputSchema
>;

export const TenantParamsInputSchema = mkType({
  name: 'TenantParamsInputSchema',
  comment: 'Schema for an object of type `TenantParamsInput`',
  fields: {
    ORGANIZATION_URI: {
      comment: 'The tenant organization URI',
      type: 'string',
    },
    DATA_SUBJECT_AUTHENTICATION_METHODS: {
      comment: 'Supported data subject authentication methods',
      type: { SombraDataSubjectAuthMethod },
      list: true,
    },
    EMPLOYEE_AUTHENTICATION_METHODS: {
      comment: 'Supported employee authentication methods',
      type: { SombraEmployeeAuthMethod },
      list: true,
    },
    ALLOW_EMPLOYEE_CEK_ACCESS: {
      comment:
        "Allows customer employees to generate and download the data subject's CEK",
      type: 'boolean',
      optional: true,
    },
    JWT_AUTHENTICATION_PUBLIC_KEY: {
      comment: "Company's data subject authentication via JWT public key",
      type: 'string',
      list: true,
      optional: true,
    },
    SAML: {
      comment: 'SAML Configuration, for the employees',
      type: SAMLSchema,
      optional: true,
    },
    OAUTH_PARAMETERS: {
      comment: 'OAuth configuration, for data subject authentication',
      type: OAuthParametersInputSchema,
      optional: true,
    },
    ALLOW_UNAUTHENTICATED_PREFERENCE_UPDATES: {
      comment:
        'Allows unauthenticated data subjects to update preference store.',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type TenantParamsInputSchema = SchemaToType<
  typeof TenantParamsInputSchema
>;

export const SombraPreview = mkType({
  name: 'SombraPreview',
  comment: 'The sombra configuration previewed',
  fields: {
    id: {
      comment: 'The id of the sombra',
      type: 'id',
      modelName: 'sombra',
    },
    url: {
      comment:
        'The url of the Sombra instance that Transcend uses (transcend-ingress)',
      type: 'string',
    },
    title: {
      comment:
        'The title of the gateway, useful for a human-readable name when there are multiple gateways in an organization',
      type: 'string',
      optional: true,
    },
    hostedMethod: {
      comment: 'The Sombra instance type',
      type: { SombraHostedMethod },
    },
    publicKeyECDH: {
      comment:
        'The Elliptic Curve Diffie-Hellman public key for encrypted communication with this Sombra instance',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SombraPreview = SchemaToType<typeof SombraPreview>;

export const SombraInterface = mkInterface({
  name: 'SombraInterface',
  comment: 'The sombra configuration interface',
  fields: {
    id: {
      comment: 'The id of the sombra',
      type: 'id',
      modelName: 'sombra',
    },
    publicKeyECDH: {
      comment:
        'The Elliptic Curve Diffie-Hellman public key for encrypted communication with this Sombra instance',
      type: 'string',
      optional: true,
    },
    url: {
      comment:
        'The url of the Sombra instance that Transcend uses (transcend-ingress)',
      type: 'string',
    },
    title: {
      comment:
        'The title of the gateway, useful for a human-readable name when there are multiple gateways in an organization',
      type: 'string',
      optional: true,
    },
    publicUrl: {
      comment:
        'The url of the Sombra instance that the client posts to when responding to a server silo',
      type: 'string',
    },
    isPrimarySombra: {
      comment:
        'When true, the sombra gateway is the primary instance in the organization',
      type: 'boolean',
    },
    customerUrl: {
      comment:
        'The url of the Sombra instance that the customer should use (customer-ingress)',
      type: 'string',
    },
    ipAddresses: {
      comment: 'The ip addresses of the sombra instance',
      type: 'string',
      list: true,
    },
    hostedMethod: {
      comment: 'The Sombra instance type',
      type: { SombraHostedMethod },
    },
    mostRecentSombra: {
      comment: 'The most recent version of Sombra available',
      type: 'string',
    },
    version: {
      comment: 'The semantic version that the sombra is on',
      type: 'string',
      optional: true,
    },
    tenantParameters: {
      comment: 'The tenant parameters config',
      type: TenantParamsInputSchema,
      optional: true,
    },
    audience: {
      comment: 'The sombra audience',
      type: 'string',
    },
    employeeAuthenticationMethods: {
      comment: 'The employee authentication methods',
      type: { SombraEmployeeAuthMethod },
      list: true,
      optional: true,
    },
    dataSubjectAuthenticationMethods: {
      comment: 'The data subject authentication methods',
      type: { SombraDataSubjectAuthMethod },
      list: true,
      optional: true,
    },
    isReverseTunneledSombra: {
      comment: "If the Sombra is accessed through Transcend's Reverse Tunnel",
      type: 'boolean',
    },
  },
});

/** Override type */
export type SombraInterface = SchemaToType<typeof SombraInterface>;

export const Sombra = mkType({
  name: 'Sombra',
  comment: 'The sombra configuration',
  interfaces: [SombraInterface],
  fields: {
    ...SombraInterface.fields,
  },
});

/** Override type */
export type Sombra = SchemaToType<typeof Sombra>;

export const SombraInternalUseOnly = mkType({
  name: 'SombraInternalUseOnly',
  comment: `The sombra configuration needed to make a request to external side of sombra.
  This is the input to createSombraService, a function which constructs an instance capable of sending a request
  to the external side of sombra.

  An example usage here would be from email-receiver -> sombra.
  In order to authenticate to the sombra gateway it must first ask the backend
  for the sombra configuration necessary to authenticate.`,
  fields: {
    id: Sombra.fields.id,
    url: Sombra.fields.url,
    publicUrl: Sombra.fields.publicUrl,
    version: Sombra.fields.version,
    audience: {
      comment: 'JWT audience for the gateway',
      type: 'string',
    },
    hostedMethod: Sombra.fields.hostedMethod,
    tenantSecrets: {
      comment:
        'Encrypted tenant secrets, used to configure gateway when multi tenant',
      type: 'string',
      optional: true,
    },
    tenantParameters: {
      comment:
        'JWT signed tenant parameters, used to configure gateway when multi tenant',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SombraInternalUseOnly = SchemaToType<typeof SombraInternalUseOnly>;

export const SombraProfile = mkType({
  name: 'SombraProfile',
  comment: 'The profile for a logged in user on privacy center',
  fields: {
    picture: {
      comment: 'A URL to the profile picture of the logged in user',
      type: 'string',
      optional: true,
    },
    nickname: {
      comment: 'The nickname of the logged in user',
      type: 'string',
      optional: true,
    },
    email: {
      comment: 'The email of the logged in user',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SombraProfile = SchemaToType<typeof SombraProfile>;

export const SombraCoreIdentifier = mkType({
  name: 'SombraCoreIdentifier',
  comment: 'The core identifier signed by sombra',
  fields: {
    value: {
      comment: 'The value of the core identifier',
      type: 'string',
    },
    initialAuthMethod: {
      comment: 'The method that the core identifier was attested by',
      type: { SombraDataSubjectAuthMethod },
      optional: true,
    },
    subjectType: {
      comment: 'The type of data subject logged in as',
      type: 'string',
      // TODO: https://transcend.height.app/T-4346 - make required
      optional: true,
    },
  },
});

/** Override type */
export type SombraCoreIdentifier = SchemaToType<typeof SombraCoreIdentifier>;

export const SombraSession = mkType({
  name: 'SombraSession',
  comment: `The session information returned from sombra about the logged in user
This is calculated from a dhEncrypted and can only be deconstructed by the privacy center`,
  fields: {
    profile: {
      comment: 'The profile information for that logged in user',
      type: SombraProfile,
    },
    coreIdentifier: {
      comment: 'The core identifier for the logged in user',
      type: SombraCoreIdentifier,
    },
    decryptionContext: {
      comment:
        'The decryption context containing the wrapped long-lived session',
      type: DecryptionContext,
    },
  },
});

/** Override type */
export type SombraSession = SchemaToType<typeof SombraSession>;

export const ReSignEncryptedCEKContextsInput = mkInput({
  name: 'ReSignEncryptedCEKContextsInput',
  comment: 'Input to re-sign multiple Encrypted CEK contexts',
  fields: {
    requestIds: {
      comment: 'List of request Ids',
      modelName: 'request',
      type: 'id',
      list: true,
    },
  },
});

/** Override type.  */
export type ReSignEncryptedCEKContextsInput = SchemaToType<
  typeof ReSignEncryptedCEKContextsInput
>;

export const ReSignSaaSContextsInput = mkInput({
  name: 'ReSignSaaSContextsInput',
  comment: 'Input to re-sign multiple SaaS contexts',
  fields: {
    dataSiloIds: {
      comment: 'List of data silo Ids',
      modelName: 'dataSilo',
      type: 'id',
      list: true,
    },
  },
});

export const UpdateRequestInput = mkInput({
  name: 'UpdateRequestInput',
  comment: 'Input to update existing DSRs',
  fields: {
    id: {
      comment: 'The id of the request',
      type: 'id',
      modelName: 'request',
    },
    isTest: {
      comment: 'Whether to make the request a test request',
      type: 'boolean',
      optional: true,
    },
    isSilent: {
      comment: 'Whether to make the request a silent request',
      type: 'boolean',
      optional: true,
    },
    details: {
      comment: 'What to set the details of the request to',
      type: 'string',
      optional: true,
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this request',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this request',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
  },
});

/** Override type.  */
export type ReSignSaaSContextsInput = SchemaToType<
  typeof ReSignSaaSContextsInput
>;

export const SombraKMSKeyEntryInput = mkInput({
  name: 'SombraKMSKeyEntryInput',
  comment: 'A Sombra key entry -- used to track key versions',
  fields: {
    keySource: {
      comment: 'The keys source',
      type: { SombraKMSKeySource },
    },
    canaryValue: {
      comment: 'The canary value for the key',
      type: 'string',
    },
    canaryType: {
      comment: 'The canary key type -- JWT, hash, encrypted value, etc.',
      type: { SombraKMSCanaryValueType },
    },
    index: {
      comment:
        'Indices start from zero. Represents the order of keys  during a key rotation, with zero being the current key set.',
      type: 'int',
    },
  },
});

/** Override type */
export type SombraKMSKeyEntryInput = SchemaToType<
  typeof SombraKMSKeyEntryInput
>;

export const SombraKMSKeySetInput = mkInput({
  name: 'SombraKMSKeySetInput',
  comment: 'A Sombra key set for a key type.',
  fields: {
    keyType: {
      comment: 'The key type',
      type: { SombraKMSKeyType },
    },
    canaries: {
      comment: 'The canary values for the keys used in this key set',
      type: SombraKMSKeyEntryInput,
      list: true,
    },
  },
});

/** Override type */
export type SombraKMSKeySetInput = SchemaToType<typeof SombraKMSKeySetInput>;

export const SombraSingleTenantConfigSyncInput = mkInput({
  name: 'SombraSingleTenantConfigSyncInput',
  comment: 'Sombra Single-Tenant Config sync GraphQL mutation input.',
  fields: {
    id: {
      type: 'string',
      comment:
        'The unique ID for your self-hosted sombra instance (useful if there are multiple instances)',
      optional: true,
    },
    uri: {
      type: 'string',
      comment: 'The organization URI.',
    },
    configJwt: {
      type: 'string',
      comment: 'Sombra instance configuration as a JWT.',
    },
    kmsProvider: {
      type: 'string',
      comment: 'KMS type the Sombra is using',
      optional: true,
    },
    keySets: {
      type: SombraKMSKeySetInput,
      comment: 'Sombra KMS key entries to be used for key tracking',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type SombraSingleTenantConfigSyncInput = SchemaToType<
  typeof SombraSingleTenantConfigSyncInput
>;

export const SombraKMSKeyEntryOutput = mkType({
  name: 'SombraKMSKeyEntryOutput',
  comment:
    'A Sombra key entry output returned by the backend -- used to track key versions',
  fields: {
    ...SombraKMSKeyEntryInput.fields,
    id: {
      type: 'string',
      comment: 'The key ID assigned by the backend',
    },
  },
});

/** Override type */
export type SombraKMSKeyEntryOutput = SchemaToType<
  typeof SombraKMSKeyEntryOutput
>;

export const SombraKMSKeySetOutput = mkType({
  name: 'SombraKMSKeySetOutput',
  comment: 'A Sombra key set for a key type.',
  fields: {
    ...SombraKMSKeySetInput.fields,
    canaries: {
      comment:
        'The canary values for the keys used in this key set, along with their assigned key IDs',
      type: SombraKMSKeyEntryOutput,
      list: true,
    },
  },
});

/** Override type */
export type SombraKMSKeySetOutput = SchemaToType<typeof SombraKMSKeySetOutput>;

export const SombraKMSKeysMetadata = mkType({
  name: 'SombraKMSKeysMetadata',
  comment: 'The metadata for the Sombra key sets',
  fields: {
    keySets: {
      type: SombraKMSKeySetOutput,
      list: true,
      comment: 'List of key sets along with their assigned Key IDs.',
    },
  },
});

/** Override type */
export type SombraKMSKeysMetadata = SchemaToType<typeof SombraKMSKeysMetadata>;

export const UpdateSombraJWTConfigInput = mkInput({
  name: 'UpdateSombraJWTConfigInput',
  comment: 'Input to update the JWT authentication public key',
  fields: {
    publicKey: {
      type: 'string',
      comment:
        "The public key used to verify the data subject's JWT auth token.",
    },
  },
});

/** Override type */
export type UpdateSombraJWTConfigInput = SchemaToType<
  typeof UpdateSombraJWTConfigInput
>;

export const UpdateSombraOAuthConfigInput = mkInput({
  name: 'UpdateSombraOAuthConfigInput',
  comment: 'Input to update the OAuth config parameters',
  fields: {
    clientId: {
      type: 'string',
      comment: "The Client ID of the privacy center's OAuth 2 application",
    },
    getTokenUrl: {
      type: 'string',
      comment:
        'The token URL for the OAuth API to exchange an authorization code to an access token',
    },
    getTokenBodyRedirectUri: {
      type: 'string',
      comment: 'The redirect URL for the OAuth application',
    },
    getTokenBodyGrantType: {
      type: 'string',
      comment: 'The OAuth token grant type',
      optional: true,
    },
    getTokenMethod: {
      type: { HttpMethod },
      comment:
        'The HTTP method for fetching the OAuth token from the OAuth API',
      optional: true,
    },
    getTokenHeadersKeys: {
      type: 'string',
      comment:
        'The keys of the headers to include when fetching the OAuth token',
      list: true,
      optional: true,
    },
    getTokenHeadersValues: {
      type: 'string',
      comment:
        'The values of the headers to include when fetching the OAuth token',
      list: true,
      optional: true,
    },
    getCoreIdUrl: {
      type: 'string',
      comment:
        'The API endpoint that returns a user ID or other similar core identifier',
    },
    getCoreIdDataSubjectType: {
      type: 'string',
      comment: 'The data subject type that the oauth config corresponds to',
    },
    getCoreIdPath: {
      type: 'string',
      comment:
        'The path to extract the core identifier from the JSON response body',
    },
    getEmailUrl: {
      type: 'string',
      comment: 'The API endpoint that returns a user email',
    },
    getEmailPath: {
      type: 'string',
      comment: 'The path to extract the email from the JSON response body',
    },
    getEmailIsVerified: {
      type: 'boolean',
      comment:
        'Whether all OAuth emails are already verified by default, attested by the organization',
      optional: true,
    },
    getEmailIsVerifiedPath: {
      type: 'string',
      comment:
        'The path to extract whether the email is verified for the OAuth profile',
      optional: true,
    },
    getProfilePictureUrl: {
      type: 'string',
      comment: 'An endpoint to retrieve a profile picture for the data subject',
      optional: true,
    },
    getProfilePicturePath: {
      type: 'string',
      comment:
        'The path to extract the profile picture from the JSON response body',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateSombraOAuthConfigInput = SchemaToType<
  typeof UpdateSombraOAuthConfigInput
>;

/** The type of the input to log a successful send of a request email */
export const LogSentRequestEmailInput = mkInput({
  name: 'LogSentRequestEmailInput',
  comment: 'Input to log metadata surrounding a request email',
  fields: {
    sombraContextJwt: {
      type: 'string',
      comment: 'The JWT containing email contents that are encrypted by sombra',
      // underlyingType: SendEmailInput,
    },
    emailSenderContextJwt: {
      type: 'string',
      comment:
        'a JWT encoding the email context that initiated the email to send',
      // underlyingType: SendEmailMetadata,
    },
    emailSenderResponseJwt: {
      type: 'string',
      comment: 'a JWT encoding the response from the email-sender',
      // underlyingType: SendEmailSignedResponse,
    },
  },
});

/** The type of the input to log a successful send of a request email */
export type LogSentRequestEmailInput = SchemaToType<
  typeof LogSentRequestEmailInput
>;

/** The input type for the LogEmailReply route */
export const LogEmailReplyInput = mkInput({
  name: 'LogEmailReplyInput',
  comment: 'The input for logging the reply to a request email',
  fields: {
    sombraContextJwt: {
      type: 'string',
      comment: 'a JWT encoding the encrypted email contents from sombra',
      // underlying type is LogEmailReplySombraInput
    },
    emailReceiverContextJwt: {
      type: 'string',
      comment:
        'a JWT encoding the parsed email contents from the email-receiver',
      // underlying type is EmailReceiverNewCommunication
    },
  },
});

export const SignedIdentifierInput = mkInput({
  name: 'SignedIdentifierInput',
  comment: 'The JWT containing the enriched identifier.',
  fields: {
    name: {
      comment: 'The name of this identifier',
      type: 'string',
    },
    jwts: {
      comment: 'The JWT containing the enriched information',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type SignedIdentifierInput = SchemaToType<typeof SignedIdentifierInput>;

export const SignedIdentifiersInput = mkInput({
  name: 'SignedIdentifiersInput',
  comment: 'Input to add signed & enriched identifiers',
  fields: {
    requestEnricherId: {
      comment:
        'The RequestEnricher ID - if specified takes precedence over requestId and enricherId',
      type: 'id',
      modelName: 'RequestEnricher',
      optional: true,
    },
    requestId: {
      comment:
        'The request ID - can be specified in addition to enricherId as an alternative to requestEnricherId',
      type: 'id',
      modelName: 'request',
      optional: true,
    },
    enricherId: {
      comment:
        'The enricher ID - can be specified in addition to enricherId as an alternative to requestEnricherId',
      type: 'id',
      modelName: 'enricher',
      optional: true,
    },
    templateId: {
      comment: 'The template ID to send',
      type: 'id',
      modelName: 'template',
      optional: true,
    },
    templateTitle: {
      comment: 'The template title to send',
      type: 'string',
      optional: true,
    },
    status: {
      comment: 'The status of this enricher request',
      type: { AllowedEnricherTransition },
      optional: true,
    },
    // TODO: https://transcend.height.app/T-36538 - remove plaintext identifiers
    signedIdentifiers: {
      comment: 'The signed identifiers JWTs.',
      type: SignedIdentifierInput,
      list: true,
      optional: true,
    },
    signedEncryptedIdentifiers: {
      comment: 'The signed and encrypted identifiers JWTs.',
      type: SignedIdentifierInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type SignedIdentifiersInput = SchemaToType<
  typeof SignedIdentifiersInput
>;

/** The input type for the LogEmailReply route */
export type LogEmailReplyInput = SchemaToType<typeof LogEmailReplyInput>;

export const ConsentLastKeyInput = mkInput({
  name: 'ConsentLastKeyInput',
  comment: 'Record of consent preferences',
  fields: {
    userId: {
      comment: 'user id',
      type: 'string',
    },
    partition: {
      comment:
        'The partition to lookup consent for (by default, this is the consent manager or airgap bundle id',
      type: 'string',
    },
    timestamp: {
      comment: 'The timestamp of the last record returned',
      type: 'string',
      optional: true,
    },
    updatedAt: {
      comment: 'The timestamp of when the record was last updated',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentLastKeyInput = SchemaToType<typeof ConsentLastKeyInput>;

/**
 * Preference store identifier input
 *  - Used in upsert request body
 */
export const PreferenceStoreIdentifierInput = mkInput({
  name: 'PreferenceStoreIdentifierInput',
  comment: 'Identifier for consent preferences',
  fields: {
    value: {
      comment: 'encrypted identifier value',
      type: 'string',
    },
    name: {
      comment: 'The name of the identifier (e.g. email, phone number, etc.)',
      type: 'string',
    },
  },
});

/** Override type */
export type PreferenceStoreIdentifierInput = SchemaToType<
  typeof PreferenceStoreIdentifierInput
>;

/**
 * Preference store identifier schema
 *  - Used in response body
 */
export const PreferenceStoreIdentifierSchema = mkType({
  name: 'PreferenceStoreIdentifierSchema',
  comment: 'Identifier for consent preferences',
  fields: { ...PreferenceStoreIdentifierInput.fields },
});

/** Override type */
export type PreferenceStoreIdentifierSchema = SchemaToType<
  typeof PreferenceStoreIdentifierSchema
>;

export const MCDConsentLookupInput = mkInput({
  name: 'MCDConsentLookupInput',
  comment: 'Lookup consent preferences',
  fields: {
    partition: {
      comment:
        'The partition to lookup consent for (by default, this is the consent manager or airgap bundle id',
      type: 'string',
    },
    encryptedIdentifiers: {
      comment: 'Specific encrypted identifiers to lookup',
      type: 'string',
      list: true,
      optional: true,
    },
    limit: {
      comment: "Limit the number of users' preferences to return",
      type: 'int',
      optional: true,
    },
    lastKey: {
      comment:
        'The last record returned from previous requests; Used for pagination',
      type: ConsentLastKeyInput,
      optional: true,
    },
    timestampBefore: {
      comment:
        'Only return records with consent events before this timestamp; default is now',
      type: 'string',
      optional: true,
    },
    timestampAfter: {
      comment: 'Only return records with consent events after this timestamp',
      type: 'string',
      optional: true,
    },
    updatedAfter: {
      comment: 'Only return records updated after this timestamp',
      type: 'string',
      optional: true,
    },
    updatedBefore: {
      comment: 'Only return records updated before this timestamp',
      type: 'string',
      optional: true,
    },
    identifiers: {
      comment: 'identifiers to lookup',
      type: PreferenceStoreIdentifierInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type MCDConsentLookupInput = SchemaToType<typeof MCDConsentLookupInput>;

export const PreferenceChoiceSchema = mkType({
  name: 'PreferenceChoiceSchema',
  comment: 'preference choice made by the user',
  fields: {
    booleanValue: {
      comment: 'The boolean value of the preference',
      type: 'boolean',
      optional: true,
    },
    selectValue: {
      comment: 'The select value(string) of the preference',
      type: 'string',
      optional: true,
    },
    selectValues: {
      comment: 'The select values(string[]) of the preference',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type PreferenceChoiceSchema = SchemaToType<
  typeof PreferenceChoiceSchema
>;

export const PreferenceChoiceInput = mkInput({
  name: 'PreferenceChoiceInput',
  comment: 'Input for preference choice',
  fields: PreferenceChoiceSchema.fields,
});

/** Override type */
export type PreferenceChoiceInput = SchemaToType<typeof PreferenceChoiceInput>;

export const PreferenceSchema = mkType({
  name: 'PreferenceSchema',
  comment: 'User preference',
  fields: {
    topic: {
      comment: 'The topic of the preference',
      type: 'string',
    },
    choice: {
      comment: 'The choice made by the user for this preference topic',
      type: PreferenceChoiceSchema,
    },
  },
});

/** Override type */
export type PreferenceSchema = SchemaToType<typeof PreferenceSchema>;

export const PreferenceInput = mkInput({
  name: 'PreferenceInput',
  comment: 'Input for preference',
  fields: {
    ...PreferenceSchema.fields,
    choice: {
      comment: 'The choice made by the user for this preference topic',
      type: PreferenceChoiceInput,
    },
  },
});

/** Override type */
export type PreferenceInput = SchemaToType<typeof PreferenceInput>;

export const PurposeInput = mkInput({
  name: 'PurposeInput',
  comment: 'Request for upserting consent record from the Privacy Center',
  fields: {
    purpose: {
      comment: 'Tracking purpose',
      type: 'string',
    },
    consent: {
      comment: 'Whether the user has given consent for this purpose',
      type: 'boolean',
    },
    preferences: {
      comment: 'User preferences for this purpose',
      type: PreferenceInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type PurposeInput = SchemaToType<typeof PurposeInput>;

export const PurposeSchema = mkType({
  name: 'PurposeSchema',
  comment: 'purpose',
  fields: {
    purpose: PurposeInput.fields.purpose,
    consent: PurposeInput.fields.consent,
    preferences: {
      comment: 'User preferences for this purpose',
      type: PreferenceSchema,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type PurposeSchema = SchemaToType<typeof PurposeSchema>;

export const ConsentRecordSchema = mkType({
  name: 'ConsentRecord',
  comment: 'Record of consent preferences',
  fields: {
    encryptedIdentifier: {
      comment: 'encrypted identifier for user',
      type: 'string',
    },
    partition: {
      comment:
        'The partition to lookup consent for (by default, this is the consent manager or airgap bundle id',
      type: 'string',
    },
    purposes: {
      comment: 'purposes',
      type: PurposeSchema,
      list: true,
    },
    timestamp: {
      comment: 'Most recent consent event timestamp (ISO 8601)',
      type: 'string',
    },
    usp: {
      comment: 'US IAB Privacy String',
      type: 'string',
      optional: true,
    },
    gpp: {
      comment: 'IAB GPP String, encoding both USP and USNAT',
      type: 'string',
      optional: true,
    },
    tcf: {
      comment: 'IAB TCF String',
      type: 'string',
      optional: true,
    },
    airgapVersion: {
      comment: 'Airgap Version',
      type: 'string',
      optional: true,
    },
    metadata: {
      comment: 'Metadata',
      type: 'string',
      optional: true,
    },
    metadataTimestamp: {
      comment: 'Last-modified for metadata timestamp (ISO 8601)',
      type: 'string',
      optional: true,
    },
    updatedAt: {
      comment: 'Last record modification timestamp (ISO 8601)',
      type: 'string',
      optional: true,
    },
    identifiers: {
      comment: 'The identifiers for the user',
      type: PreferenceStoreIdentifierSchema,
      list: true,
    },
  },
});

/** Override type */
export type ConsentRecordSchema = SchemaToType<typeof ConsentRecordSchema>;

export const ConsentLastKeySchema = mkType({
  name: 'ConsentLastKeySchema',
  comment: 'Last key used for pagination',
  fields: {
    userId: {
      comment: 'user id',
      type: 'string',
    },
    partition: {
      comment:
        'The partition to lookup consent for (by default, this is the consent manager or airgap bundle id)',
      type: 'string',
    },
    timestamp: {
      comment: 'The timestamp of the last record returned',
      type: 'string',
      optional: true,
    },
    updatedAt: {
      comment: 'The timestamp of when the record was last updated',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentLastKeySchema = SchemaToType<typeof ConsentLastKeySchema>;

export const ConsentPreferenceResponseSombra = mkType({
  name: 'ConsentPreferenceResponseSombra',
  comment: 'Response for consent preference request',
  fields: {
    nodes: {
      comment: 'Record of consent preferences',
      type: ConsentRecordSchema,
      list: true,
    },
    lastKey: {
      comment: 'Last key used for pagination for consent preferences',
      type: ConsentLastKeySchema,
      optional: true,
    },
    clientErrorMessage: {
      comment: 'Error message for client',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type ConsentPreferenceResponseSombra = SchemaToType<
  typeof ConsentPreferenceResponseSombra
>;

export const ConsentWorkflowSettingsInput = mkInput({
  name: 'ConsentWorkflowSettingsInput',
  comment: 'Settings for triggering a consent workflow',
  fields: {
    attributes: {
      comment:
        'Additional custom fields to include as metadata with the consent change event',
      type: AttributeInput,
      optional: true,
      list: true,
    },
    dataSiloIds: {
      comment: 'The data silos to process for this request',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
      list: true,
    },
    emailReceiptTemplateId: {
      comment: 'The ID of the email template to send',
      type: 'id',
      modelName: 'template',
      optional: true,
    },
    ignoreDataSiloIds: {
      comment:
        'The set of data silo IDs that should NOT be processed. ' +
        'When specified, the default set of data silos will be calculated ' +
        'and then these IDs will be subtracted from that list.',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
      list: true,
    },
    isSilent: {
      comment: 'When true, no emails should be sent to the data subject',
      type: 'boolean',
      optional: true,
    },
    isTest: {
      comment: 'When true, request is a test request',
      type: 'boolean',
      optional: true,
    },
    skipSendingReceipt: {
      comment: 'When true, do not send a receipt email',
      type: 'boolean',
      optional: true,
    },
    skipWaitingPeriod: {
      comment:
        'When true, skip any waiting period associated with the workflow.',
      type: 'boolean',
      optional: true,
    },
    skipWorkflowTrigger: {
      comment: 'Whether to skip workflow trigger associated for this purpose. ',
      type: 'boolean',
      optional: true,
    },
  },
});

export const AdminPurposeInput = mkInput({
  name: 'AdminPurposeInput',
  comment:
    'Request for upserting consent records from the Admin Dashboard and API',
  fields: {
    purpose: PurposeInput.fields.purpose,
    consent: PurposeInput.fields.consent,
    preferences: {
      comment: 'User preferences for this purpose',
      type: PreferenceInput,
      list: true,
      optional: true,
    },
    workflowSettings: {
      comment: 'Settings for triggering a consent workflow',
      type: ConsentWorkflowSettingsInput,
      optional: true,
    },
  },
});

/** Override type */
export type AdminPurposeInput = SchemaToType<typeof AdminPurposeInput>;

export const AdminUpsertPreferenceRecordInput = mkInput({
  name: 'AdminUpsertPreferenceRecordInput',
  comment: 'Request for upserting a single preference record',
  fields: {
    encryptedIdentifier: {
      ...ConsentRecordSchema.fields.encryptedIdentifier,
      optional: true,
    },
    partition: ConsentRecordSchema.fields.partition,
    timestamp: ConsentRecordSchema.fields.timestamp,
    usp: ConsentRecordSchema.fields.usp,
    gpp: ConsentRecordSchema.fields.gpp,
    tcf: ConsentRecordSchema.fields.gpp,
    airgapVersion: ConsentRecordSchema.fields.airgapVersion,
    metadata: ConsentRecordSchema.fields.metadata,
    metadataTimestamp: ConsentRecordSchema.fields.metadataTimestamp,
    updatedAt: ConsentRecordSchema.fields.updatedAt,
    locale: {
      comment:
        'When triggering a consent workflow, ' +
        'the locale to use for translation of email template and privacy center content.',
      optional: true,
      type: 'string', // Should be LanguageKey enum but GraphQL does not allow hyphens in enums
    },
    purposes: {
      comment: 'associated consent purposes',
      type: AdminPurposeInput,
      list: true,
    },
    confirmed: {
      comment: 'Whether the user has confirmed their consent choices',
      type: 'boolean',
      optional: true,
    },
    identifiers: {
      comment: 'The identifiers for the user',
      type: PreferenceStoreIdentifierInput,
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AdminUpsertPreferenceRecordInput = SchemaToType<
  typeof AdminUpsertPreferenceRecordInput
>;

export const AdminUpsertPreferenceRecordsInput = mkInput({
  name: 'AdminUpsertPreferenceRecordsInput',
  comment: 'Request for upserting preference records by admin',
  fields: {
    records: {
      comment: 'List of preference store records to upsert',
      type: AdminUpsertPreferenceRecordInput,
      list: true,
    },
    skipWorkflowTriggers: {
      comment:
        'Whether to skip workflow triggers for the updated preference records',
      type: 'boolean',
      optional: true,
    },
    forceTriggerWorkflows: {
      comment:
        'Whether to force trigger workflows regardless of the difference in the consent status',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AdminUpsertPreferenceRecordsInput = SchemaToType<
  typeof AdminUpsertPreferenceRecordsInput
>;

export const RecordOfConsentNextTokenInput = mkInput({
  name: 'RecordOfConsentNextTokenInput',
  comment: 'Token to continue fetching consent records',
  fields: {
    statementId: {
      type: 'string',
      comment: 'AWS statement ID',
    },
    nextToken: {
      type: 'string',
      comment: 'String token to continue fetching records',
    },
  },
});

/** Override type */
export type RecordOfConsentNextTokenInput = SchemaToType<
  typeof RecordOfConsentNextTokenInput
>;

export const RecordOfConsentNextToken = mkType({
  name: 'RecordOfConsentNextToken',
  comment: 'Token to continue fetching consent records',
  fields: {
    ...RecordOfConsentNextTokenInput.fields,
  },
});

/** Override type */
export type RecordOfConsentNextToken = SchemaToType<
  typeof RecordOfConsentNextToken
>;

export const SombraApiKey = mkType({
  name: 'SombraApiKey',
  comment: 'An API key to be used with a Sombra instance',
  fields: {
    id: {
      modelName: 'sombraApiKey',
      type: 'id',
      comment: 'Sombra API key ID',
    },
    key: {
      type: 'string',
      comment: 'The key itself',
    },
    expiredAt: {
      type: 'Date',
      comment: 'When the key expires',
      optional: true,
    },
  },
});
/** Override type */
export type SombraApiKey = SchemaToType<typeof SombraApiKey>;

export const CustomFunctionExecutionProfile = mkType({
  name: 'CustomFunctionExecutionProfile',
  comment: 'Profiling information for a custom function execution',
  fields: {
    timeMs: {
      type: 'float',
      comment: 'Execution time in milliseconds',
    },
    spawnArgs: {
      type: 'string',
      list: true,
      comment: 'List of spawn arguments',
    },
    start: {
      type: 'string',
      comment: 'Unix epoch in milliseconds representing start time',
    },
    end: {
      type: 'string',
      comment: 'Unix epoch in milliseconds representing end time',
    },
  },
});

export const CustomFunctionExecutionLog = mkType({
  name: 'CustomFunctionExecutionLog',
  comment: 'Log emitted for a custom function execution',
  fields: {
    message: {
      type: 'string',
      comment: 'Log message',
    },
    file: {
      comment: 'stdout or stderr',
      type: 'string',
    },
  },
});

export const CustomFunctionExecutionError = mkType({
  name: 'CustomFunctionExecutionError',
  comment: 'Error emitted for a custom function execution',
  fields: {
    message: {
      type: 'string',
      comment: 'Error message',
    },
  },
});

export const CustomFunctionExecutionResult = mkType({
  name: 'CustomFunctionExecutionResult',
  comment: 'Custom function execution result',
  fields: {
    profile: {
      comment: 'Profiling information',
      type: CustomFunctionExecutionProfile,
    },
    logs: {
      comment: 'Logs',
      list: true,
      type: CustomFunctionExecutionLog,
    },
    error: {
      comment: 'Error message, if any',
      optional: true,
      type: CustomFunctionExecutionError,
    },
  },
});

export const CustomFunctionExecutionOverridePayload = mkType({
  name: 'CustomFunctionExecutionOverridePayload',
  comment:
    'Payload that allows users to override specific parts of the custom function' +
    'definition without needing to persist changes.',
  fields: {
    environment: {
      type: 'string',
      optional: true,
      comment:
        'Base64 string representation of a stringified JSON with the overridden environment variables.',
    },
  },
});
/* eslint-enable max-lines */

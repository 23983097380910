import React from 'react';

import { SvgProps } from '../enums';

export const FlagIM: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55023"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55023)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#E31D1C"
      />
      <mask
        id="mask1_270_55023"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55023)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.04676 2.0705C3.04291 2.02424 3.09827 2.0018 3.12958 2.03608C3.35414 2.2819 3.94767 2.89389 4.29091 2.89389C4.72041 2.89389 6.40721 1.47257 6.90578 1.70411C7.40436 1.93564 8.41381 4.35646 8.41381 4.35646L7.75599 5.18159L6.90578 4.71778L6.67048 3.17156C6.67048 3.17156 5.10857 3.95421 4.29091 3.64868L3.83127 3.81633C3.83127 3.81633 3.38862 2.93983 3.21431 2.67174C3.09898 2.49436 3.05996 2.22945 3.04676 2.0705Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.00001 4.13763L3.41222 4.44664L3.52448 3.79214L3.04895 3.32861L3.70611 3.23312L4.00001 2.63763L4.2939 3.23312L4.95106 3.32861L4.47553 3.79214L4.58779 4.44664L4.00001 4.13763Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6.56575 1.76836C6.57327 1.67441 6.65171 1.602 6.74596 1.602C6.85148 1.602 6.93458 1.692 6.92616 1.79719L6.81558 3.17926C6.80807 3.27321 6.72963 3.34562 6.63538 3.34562C6.52986 3.34562 6.44676 3.25561 6.45518 3.15042L6.56575 1.76836Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M12.6101 2.55172C12.6513 2.57297 12.641 2.6318 12.5954 2.64019C12.2679 2.70037 11.4344 2.8794 11.2525 3.17049C11.0249 3.53473 11.3364 5.7184 10.8758 6.01852C10.4153 6.31864 7.82736 5.89186 7.82736 5.89186L7.4762 4.89675L8.32008 4.42151L9.80103 5.01154C9.80103 5.01154 9.94022 3.33287 10.6326 2.80137L10.7138 2.29187C10.7138 2.29187 11.6917 2.38096 12.0114 2.3752C12.2229 2.3714 12.4683 2.47868 12.6101 2.55172Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M10.2525 2.4282L10.3019 1.76597L10.7975 2.20801L11.4426 2.05037L11.1753 2.65828L11.5246 3.22308L10.8638 3.15675L10.4346 3.66345L10.2935 3.01455L9.67897 2.76291L10.2525 2.4282Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M10.9766 5.59919C11.0571 5.65785 11.0805 5.76772 11.0308 5.85405C10.9718 5.95667 10.8357 5.98346 10.7422 5.91089L9.78029 5.16438C9.71929 5.11704 9.69611 5.03557 9.72305 4.96322C9.76386 4.85358 9.89806 4.81319 9.99261 4.88209L10.9766 5.59919Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.43119 10.4918C7.39215 10.5169 7.34636 10.4786 7.36192 10.4349C7.47355 10.1212 7.73526 9.30981 7.57411 9.00674C7.37247 8.62751 5.32562 7.80543 5.29599 7.25652C5.26635 6.7076 6.9299 4.6798 6.9299 4.6798L7.96727 4.87325L7.9569 5.84168L6.61129 6.8276C6.61129 6.8276 8.12726 7.7788 8.24136 8.64419L8.60437 8.97952C8.60437 8.97952 8.03827 9.78185 7.8834 10.0616C7.78092 10.2467 7.56534 10.4055 7.43119 10.4918Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.94604 8.4571L9.49484 8.83101L8.86424 9.03916L8.67822 9.67664L8.28538 9.14123L7.62162 9.1613L8.00943 8.62225L7.78523 7.99717L8.41774 8.19943L8.94294 7.79304L8.94604 8.4571Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.55174 7.47817C5.44021 7.52095 5.31603 7.45872 5.28353 7.34377C5.25509 7.24317 5.30765 7.13742 5.40501 7.09934L6.41405 6.70468C6.50682 6.66839 6.61227 6.70344 6.66487 6.78803C6.73342 6.89828 6.68457 7.04368 6.56336 7.09017L5.55174 7.47817Z"
          fill="#FECA00"
        />
        <path
          // eslint-disable-next-line max-len
          d="M6.83126 4.88247C6.76903 4.86072 6.73579 4.79142 6.75702 4.72768C6.77824 4.66393 6.87794 4.55745 6.94016 4.5792L7.80806 4.88247C7.85284 4.89811 7.88432 4.93944 7.88826 4.98774L7.96587 5.93967C7.97134 6.00678 7.87358 6.06108 7.80806 6.06668C7.74254 6.07229 7.68499 6.02242 7.67952 5.95531L7.60765 5.13668L6.83126 4.88247Z"
          fill="#FECA00"
        />
        <path
          // eslint-disable-next-line max-len
          d="M8.34032 4.38377C8.39219 4.3424 8.48482 4.39036 8.52522 4.4435C8.56561 4.49664 8.5563 4.57326 8.50443 4.61464L7.90322 5.23085C7.85134 5.27223 7.73467 5.19152 7.69428 5.13837C7.65389 5.08523 7.6424 4.94047 7.69428 4.89909L8.34032 4.38377Z"
          fill="#FECA00"
        />
      </g>
    </g>
  </svg>
);

import lodashDebounce from 'lodash/debounce';

import { AnyFunction } from '@transcend-io/type-utils';

import { ONE_SECOND } from './constants';

/**
 * Debounce settings
 */
export interface DebounceSettings {
  /** Specify invoking on the leading edge of the timeout. */
  leading?: boolean;
  /** The maximum time func is allowed to be delayed before it’s invoked. */
  maxWait?: number;
  /** Specify invoking on the trailing edge of the timeout. */
  trailing?: boolean;
}

/**
 * Debounce a function so that is called after some amount of time
 *
 * @param func - The function to call
 * @param time - The amount of time to wait -- defaults to a third of a second which is a nice place to start for most use cases
 * @param options - Additional options
 * @returns A new function that is debounced before it is called
 */
export function debounce<TFunc extends AnyFunction>(
  func: TFunc,
  time = ONE_SECOND / 3,
  options: DebounceSettings = { trailing: true },
): TFunc {
  return lodashDebounce(func, time, options) as any;
}

import React, { cloneElement, useState } from 'react';
import OverlayTrigger, {
  OverlayTriggerProps,
} from 'react-bootstrap/OverlayTrigger';

import {
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '../GenericFormattedMessage';
import { StyledPopover } from './wrappers';

const DEFAULT_ID = 'transcend-popover';

/**
 * Other potentially useful props can be found here: https://react-bootstrap.github.io/components/overlays/#overlay-trigger-props
 * i.e. `placement` for positioning the popover contents (see `popover.stories.tsx`)
 *
 * The `overlay` prop is omitted here so that we can pass whatever `contents` we want into
 * this component. The `contents` is eventually passed through via <OverlayTrigger overlay={contents} ...></OverlayTrigger>
 */
export interface PopoverProps extends Omit<OverlayTriggerProps, 'overlay'> {
  /** The contents in the popover window that will appear */
  contents: React.ReactNode;
  /** Whether or not to show the arrow pointing from the popover contents to the CTA */
  hideArrow?: boolean;
  /** Id which associates the popover button with the popover contents, required when multiple popovers on same page */
  id?: string;
  /** Whether or not to have default padding in the popover contents window */
  noPadding?: boolean;
  /** The width of the popover contents window */
  width?: string;
  /** The max height of the popover contents window */
  height?: string;
  /** Whether to allow for scrolling if the content overflow - NOTE: The arrow WILL BE HIDDEN if you set this prop */
  scrollable?: boolean;
  /** Whether popover should ignore all pointer events */
  disablePointerEvents?: boolean;
  /** Whether or not to inject a11y props, may alter behavior */
  injectA11yProps?: boolean;
  /** Whether the popover is disabled */
  disabled?: boolean;
  /** Title */
  title?: GenericMessageDescriptor;
  /** Is expanded */
  isExpanded?: boolean;
  /** Set is expanded */
  setIsExpanded?: (expanded: boolean) => void;
}

export const Popover = ({
  children, // The button/CTA that will trigger the popover contents to appear
  contents,
  hideArrow,
  id = DEFAULT_ID,
  noPadding,
  width,
  height,
  placement,
  trigger = 'click',
  scrollable = false,
  disablePointerEvents = false,
  injectA11yProps = false,
  title,
  disabled,
  isExpanded: parentIsExpanded,
  setIsExpanded: setParentIsExpanded,
  show,
  ...props
}: PopoverProps): JSX.Element => {
  const [localIsExpanded, setLocalIsExpanded] = useState(false);
  const isExpanded = show ?? parentIsExpanded ?? localIsExpanded;
  const setIsExpanded = setParentIsExpanded ?? setLocalIsExpanded;

  const { formatMessageGeneric } = useFormatMessageGeneric();

  const popoverContents = (
    <StyledPopover
      // we hide the arrow for scrollable because setting overflow-y causes issue with the arrow placement.
      // Instead of having the arrow show up IN THE MIDDLE of the popover, we choose to hide it.
      $hideArrow={scrollable || hideArrow}
      width={width}
      height={height}
      $disablePointerEvents={disablePointerEvents}
      $noPadding={noPadding}
      $scrollable={scrollable}
      id={id}
      title={title ? (formatMessageGeneric(title) as string) : undefined}
    >
      {contents}
    </StyledPopover>
  );

  return (
    <OverlayTrigger
      {...props}
      show={isExpanded}
      onToggle={(show) => {
        setIsExpanded(show);
      }}
      trigger={disabled ? [] : trigger}
      overlay={popoverContents}
      placement={placement || 'bottom-start'}
      rootClose
    >
      {injectA11yProps
        ? (props) => {
            const newProps = {
              ...props,
              // a11y - https://techservicesillinois.github.io/accessibility/examples/popover.html
              tabIndex: 0,
              'aria-expanded': isExpanded,
              'aria-controls': id,
            };
            return typeof children === 'function'
              ? children(newProps)
              : cloneElement(children, newProps);
          }
        : children}
    </OverlayTrigger>
  );
};

export const StyledPopoverBody = StyledPopover;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DsrTypeErasure: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M25.9048 35.0063H14.0952C12.1348 35.0063 10.505 33.497 10.3546 31.5424L8.74531 10.6211H31.2547L29.6454 31.5424C29.495 33.497 27.8652 35.0063 25.9048 35.0063V35.0063Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.3389 10.621H6.66111"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M15.3105 4.9939H24.6894C25.7254 4.9939 26.5652 5.83371 26.5652 6.86968V10.6212H13.4347V6.86968C13.4347 5.83371 14.2746 4.9939 15.3105 4.9939V4.9939Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.499 20.2637L22.501 24.7385"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7626 25.0021L22.2374 20"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/** The actions of an Assessment Trigger configuration */
export enum AssessmentTriggerAction {
  /** Kicks off a secondary assessment and assigns it to some respondents */
  SendTargetAssessment = 'SEND_TARGET_ASSESSMENT',
}

/**
 * The events that trigger an action of an Assessment Trigger configuration
 */
export enum AssessmentTriggerEvent {
  // triggers the action when the form is submitted
  OnSubmission = 'ON_SUBMISSION',
  // triggers the action when the form is approved
  OnApproval = 'ON_APPROVAL',
}

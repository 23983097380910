import React from 'react';

import { SvgProps } from '../enums';

export const FlagGI: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55007"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55007)">
      <rect width="16" height="12" fill="#F7FCFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8H16V12H0V8Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.22896 0.633331H7.28159V1.48095H7.74921L7.71585 3.8119H6.33423V2.75238H6.80791V1.90476H5.86054V2.32857H5.38686V1.90476H4.43949V2.75238H4.91317V3.8119H3.91425V4.65952L4.38793 4.65952V7.20238H3.91425V8.05H12.9142V7.20238H11.9669V4.65952H12.9142V3.8119H11.9856L12.0091 2.75238H12.4921V1.90476H11.5448V2.32857H11.0711V1.90476H10.1237V2.75238H10.588L10.5646 3.8119H9.1369L9.17027 1.48095H9.65002V0.633331H8.70265V1.05714H8.22896V0.633331Z"
        fill="#DB000B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5.80792 6.84514C5.80792 6.53993 6.05534 6.29251 6.36055 6.29251C6.66576 6.29251 6.91319 6.53993 6.91319 6.84514V7.75918H5.80792V6.84514Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9.86053 6.84514C9.86053 6.53993 10.108 6.29251 10.4132 6.29251C10.7184 6.29251 10.9658 6.53993 10.9658 6.84514V7.75918H9.86053V6.84514Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.65002 6.66268C7.65002 6.25574 7.97992 5.92584 8.38687 5.92584C8.79381 5.92584 9.12371 6.25574 9.12371 6.66268V8.12584H7.65002V6.66268Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.66114 7.59863L8.57074 7.76461L8.40238 7.5134L7.02295 8.43787L8.56089 9.16978L9.91726 8.28282L8.66114 7.59863ZM8.03128 8.364L8.47502 8.06662L8.94382 8.32196L8.52223 8.59765L8.03128 8.364Z"
        fill="#E8AA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.57892 10.2667H8.31576V11H7.57892V10.2667Z"
        fill="#E8AA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9H8.87441V11H8V9Z"
        fill="#E8AA00"
      />
    </g>
  </svg>
);

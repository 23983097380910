import React from 'react';

import { LeftLabelTag } from './LeftLabelTag';
import { PartialRightTag } from './PartialRightTag';

/* eslint-disable no-param-reassign */
export const calculateVisibleItems = (
  leftLabelRef,
  buttonRef,
  textRefs,
  maxWidth,
): number => {
  let visible = 0;
  let rowWidth = leftLabelRef.getBoundingClientRect().width;
  let itemIndex = 0;
  const buttonWidth = buttonRef.getBoundingClientRect().width;
  while (itemIndex < textRefs.current.length) {
    const width = textRefs.current[itemIndex]!.children[0].clientWidth + 1;
    if (rowWidth + width < maxWidth) {
      visible += 1;
      rowWidth += width;
      textRefs.current[itemIndex]!.style.visibility = 'visible';
      textRefs.current[itemIndex]!.style.position = '';
    } else if (rowWidth + buttonWidth > maxWidth && visible > 0) {
      visible -= 1;
      textRefs.current[itemIndex - 1]!.style.visibility = 'hidden';
      textRefs.current[itemIndex - 1]!.style.position = 'absolute';
      break;
    } else {
      break;
    }
    itemIndex += 1;
  }
  return visible;
};

export const createWrappedTag = (
  { leftLabelRef, textRefs },
  { color, variant, leftLabel, leftIcon, rightLabel, rightIcons },
  maxWidth,
  {
    setJoinedLabel,
    setVisibleItems,
  }: {
    /** Setter for joint right label */
    setJoinedLabel: (wrappedItems: () => JSX.Element[]) => void;
    /** Setter for visible items */
    setVisibleItems: (visibleItems: number) => void;
  },
): void => {
  const wrappedItems = (): JSX.Element[] => {
    const rows: JSX.Element[] = [];
    let rowWidth = leftLabelRef.getBoundingClientRect().width;
    let itemIndex = 0;
    const currentRow = [
      <LeftLabelTag
        key={-1}
        color={color}
        variant={variant}
        label={leftLabel}
        icon={leftIcon}
        style={{ borderTopLeftRadius: '4px' }}
      />,
    ];
    while (itemIndex < textRefs.current.length) {
      const width = textRefs.current[itemIndex]!.children[0].clientWidth + 1;
      const copy = (
        <PartialRightTag
          key={itemIndex}
          label={rightLabel[itemIndex]}
          index={itemIndex}
          color={color}
          variant={variant}
          icon={rightIcons ? rightIcons[itemIndex] : undefined}
          last={itemIndex === rightLabel.length - 1}
          style={{}}
        />
      );
      if (rowWidth + width < maxWidth) {
        rowWidth += width;
        currentRow.push(copy);
      } else {
        const newRow = [...currentRow];
        const wrappedItems = (
          <span style={{ display: 'flex', marginBottom: '1px' }}>{newRow}</span>
        );
        rows.push(wrappedItems);
        rowWidth = width;
        currentRow.length = 0;
        currentRow.push(copy);
      }
      itemIndex += 1;
    }
    if (currentRow.length > 0) {
      const newRow = [...currentRow];
      const wrappedItems = (
        <span style={{ display: 'flex', marginBottom: '1px' }}>{newRow}</span>
      );
      rows.push(wrappedItems);
    }
    rows[0].props.children.slice(-1)[0].props.style.borderTopRightRadius =
      '4px';
    rows.slice(-1)[0].props.children[0].props.style.borderBottomLeftRadius =
      '4px';
    rows
      .slice(-1)[0]
      .props.children.slice(-1)[0].props.style.borderBottomRightRadius = '4px';

    return rows;
  };
  setJoinedLabel(wrappedItems);
  setVisibleItems(rightLabel.length);
};
/* eslint-enable no-param-reassign */

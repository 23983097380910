import {
  ISO_31661,
  ISO_31662,
  IsoCountryCode,
} from '@transcend-io/privacy-types';

import { RegionOption } from '.';

/**
 * Search through the list of region codes
 *
 * @param codes - Codes to search
 * @param searchText - Search text
 * @returns Filtered codes
 */
export function searchByCountyCountrySubDivision(
  codes: RegionOption[],
  searchText: string,
): RegionOption[] {
  const lowerSearchText = searchText.toLowerCase();

  return codes.filter(({ country, countrySubDivision }) => {
    const matches = [
      '',
      // search for country code and name
      country,
      country && ISO_31661[country],
      // search for country subdivision code and name
      countrySubDivision,
      ISO_31662[countrySubDivision as keyof typeof ISO_31662] &&
        ISO_31662[countrySubDivision as keyof typeof ISO_31662].name,
      // search for country code and name when only country subdivision is entered
      countrySubDivision && countrySubDivision.slice(0, 2),
      countrySubDivision &&
        ISO_31661[countrySubDivision.slice(0, 2) as IsoCountryCode],
    ].filter((val) => val && val.toLowerCase().includes(lowerSearchText));

    return matches.length > 0;
  });
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagNA: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55144"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55144)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#009933"
      />
      <mask
        id="mask1_270_55144"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55144)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12L16 0H0Z"
          fill="#3195F9"
        />
        <path
          // eslint-disable-next-line max-len
          d="M-0.506236 13.5468L-0.240958 14.0274L0.21286 13.7185L17.6288 1.86383L17.9798 1.62488L17.8009 1.23978L16.6299 -1.27981L16.3843 -1.80836L15.8993 -1.48519L-1.77858 10.2931L-2.16135 10.5481L-1.93909 10.9508L-0.506236 13.5468Z"
          fill="#E31D1C"
          stroke="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.11483 4.62238L2.46778 5.42964L2.31105 4.40701L1.34706 4.78259L1.72264 3.8186L0.700012 3.66187L1.50727 3.01482L0.700012 2.36777L1.72264 2.21104L1.34706 1.24705L2.31105 1.62263L2.46778 0.600006L3.11483 1.40726L3.76187 0.600006L3.91861 1.62263L4.88259 1.24705L4.50702 2.21104L5.52964 2.36777L4.72239 3.01482L5.52964 3.66187L4.50702 3.8186L4.88259 4.78259L3.91861 4.40701L3.76187 5.42964L3.11483 4.62238ZM3.11483 4.26482C3.80518 4.26482 4.36483 3.70518 4.36483 3.01482C4.36483 2.32446 3.80518 1.76482 3.11483 1.76482C2.42447 1.76482 1.86483 2.32446 1.86483 3.01482C1.86483 3.70518 2.42447 4.26482 3.11483 4.26482ZM4.11483 3.01482C4.11483 3.56711 3.66711 4.01482 3.11483 4.01482C2.56254 4.01482 2.11483 3.56711 2.11483 3.01482C2.11483 2.46254 2.56254 2.01482 3.11483 2.01482C3.66711 2.01482 4.11483 2.46254 4.11483 3.01482Z"
          fill="#FECA00"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagMA: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55132"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55132)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V11C16 11.5523 15.5523 12 15 12H1C0.447715 12 0 11.5523 0 11V0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M11.241 9.81308L8.08291 1.3503H7.94588L4.89758 9.81308L8.06327 7.9105L11.241 9.81308ZM7.7333 4.11101L8.07186 2.79183L8.4232 4.14879L9.43252 6.97703L10.0251 8.3463L8.74575 7.44497L8.06254 7.03591L7.39034 7.4399L6.14067 8.3463L6.71838 6.94898L7.7333 4.11101Z"
        fill="#579D20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.33127 6.73656L8.07924 7.89853L9.71032 6.73656L12.8082 4.11822H3.19263L6.33127 6.73656ZM6.36754 5.73705L5.17874 5.00957H6.62908H9.51095H10.7903L9.76986 5.65594L8.07164 6.94442L6.36754 5.73705Z"
        fill="#579D20"
      />
    </g>
  </svg>
);

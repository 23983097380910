import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const EnvelopeLeft: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 9L12.7968 10.8332C12.287 11.0556 11.7076 11.0556 11.1977 10.8332L7 9.00239"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 19H17C19.2091 19 21 17.2091 21 15V8C21 5.79086 19.2091 4 17 4H7C4.79086 4 3 5.79086 3 8V11.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 16.5L4 16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 18.5L4 16.5L6 14.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

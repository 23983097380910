import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { Template } from './template';

export const AssessmentEmailSet = mkType({
  name: 'AssessmentEmailSet',
  comment: 'An assessment email set',
  fields: {
    id: {
      comment: 'The id of the assessment email set',
      modelName: 'assessmentEmailSet',
      type: 'id',
    },
    title: {
      comment: 'The title of the assessment email set',
      type: 'string',
    },
    description: {
      comment: 'The description of the assessment email set',
      type: 'string',
    },
    isDefault: {
      comment: 'Whether this is the default email set',
      type: 'boolean',
    },
    templates: {
      comment: 'The email templates associated with the email set',
      type: Template,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentEmailSet = SchemaToType<typeof AssessmentEmailSet>;

export const CreateAssessmentEmailSetInput = mkInput({
  name: 'CreateAssessmentEmailSetInput',
  comment: 'Input for creating an assessment email set',
  fields: {
    title: {
      comment: 'The title of the assessment email set',
      type: 'string',
    },
    description: {
      comment: 'The description of the assessment email set',
      type: 'string',
      optional: true,
    },
    templateIds: {
      comment: 'The ids of the templates to associate with the email set',
      type: 'id',
      modelName: 'template',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateAssessmentEmailSetInput = SchemaToType<
  typeof CreateAssessmentEmailSetInput
>;

export const UpdateAssessmentEmailSetInput = mkInput({
  name: 'UpdateAssessmentEmailSetInput',
  comment: 'Input for updating an assessment email set',
  fields: {
    id: {
      comment: 'The id of the assessment email set',
      modelName: 'assessmentEmailSet',
      type: 'id',
    },
    title: {
      comment: 'The title of the assessment email set',
      type: 'string',
      optional: true,
    },
    description: {
      comment: 'The description of the assessment email set',
      type: 'string',
      optional: true,
    },
    templateIds: {
      comment: 'The ids of the templates to associate with the email set',
      type: 'id',
      modelName: 'template',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateAssessmentEmailSetInput = SchemaToType<
  typeof UpdateAssessmentEmailSetInput
>;

export const DeleteAssessmentEmailSetsInput = mkInput({
  name: 'DeleteAssessmentEmailSetsInput',
  comment: 'Input for deleting assessment email sets',
  fields: {
    ids: {
      comment: 'The ids of the assessment email sets to delete',
      type: 'id',
      modelName: 'assessmentEmailSet',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentEmailSetsInput = SchemaToType<
  typeof DeleteAssessmentEmailSetsInput
>;

export const AssessmentEmailSetFiltersInput = mkInput({
  name: 'AssessmentEmailSetFiltersInput',
  comment: 'Filters for querying assessment email sets',
  fields: {
    ids: {
      comment: 'The ids of the assessment email sets to query',
      type: 'id',
      modelName: 'assessmentEmailSet',
      list: true,
      optional: true,
    },
    text: {
      comment: 'The title or description of the assessment email sets',
      type: 'string',
      optional: true,
    },
    isDefault: {
      comment: 'Whether the email set is default',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentEmailSetFiltersInput = SchemaToType<
  typeof AssessmentEmailSetFiltersInput
>;

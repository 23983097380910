import { makeEnum } from '@transcend-io/type-utils';

/** Whether to display subdatapoints categorized as Not Personal Data */
export const IncludeNotPersonalData = makeEnum({
  /** Include the subdatapoint */
  Yes: 'YES',
  /** Do not include the subdatapoint */
  No: 'NO',
});

/** Type override */
export type IncludeNotPersonalData =
  (typeof IncludeNotPersonalData)[keyof typeof IncludeNotPersonalData];

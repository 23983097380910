/**
 * Fields that a team can be ordered by
 */
export enum TeamOrderField {
  /** The name of the team */
  Name = 'name',
  /** When the team was created */
  CreatedAt = 'createdAt',
  /** The time the team was updated */
  UpdatedAt = 'updatedAt',
}

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ThumbsUpCheck: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3343 6.58252L15.0428 8.87514L13.666 7.49957"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0018 12.9755C18.9626 12.7045 21.1723 10.1301 20.9913 7.16238C20.8103 4.1947 18.3041 1.90785 15.3323 1.99871C12.3605 2.08957 9.99864 4.52525 9.99927 7.49844C9.99927 7.66733 10.0096 7.83366 10.0245 7.99865"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2.99634"
      y="14.814"
      width="3.00125"
      height="6.19006"
      rx="0.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.09131 19.6759L7.30764 20.6805C7.56078 20.8896 7.87885 21.004 8.20718 21.004H12.2983C12.9683 21.004 13.5571 20.5598 13.7412 19.9156L14.4351 17.487C14.5806 16.9776 14.4786 16.4295 14.1596 16.0065C13.8406 15.5836 13.3416 15.335 12.8118 15.335H10.9996"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9996 15.3349V13.6705C10.9996 12.9242 10.5038 12.2688 9.78556 12.0659C9.06737 11.863 8.30184 12.162 7.91136 12.798L5.99756 15.915"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import isEqual from 'lodash/isEqual';

import { AssessmentQuestionType } from '@transcend-io/privacy-types';

import { ConditionalLogicHelperOptions, exhaustiveGuard } from './types';

export const isEqualTo = ({
  questionToCheck,
  comparisonOperands,
}: Pick<
  ConditionalLogicHelperOptions,
  'questionToCheck' | 'comparisonOperands'
>): boolean => {
  const { type, selectedAnswers } = questionToCheck;
  switch (type) {
    case AssessmentQuestionType.LongAnswer:
    case AssessmentQuestionType.ShortAnswer:
    case AssessmentQuestionType.SingleSelect:
      // Only one answer, which must be an exact match
      if (comparisonOperands[0] === selectedAnswers[0].value) {
        return true;
      }
      break;
    case AssessmentQuestionType.MultiSelect:
      // All answers must match the operands (order doesn't matter)
      if (
        isEqual(
          comparisonOperands,
          selectedAnswers.map(({ value }) => value),
        )
      ) {
        return true;
      }
      break;
    case AssessmentQuestionType.File:
    case AssessmentQuestionType.Description:
      // File and description questions do not support this operator
      break;
    default:
      // type should always fall into the cases above
      exhaustiveGuard(type);
      break;
  }
  return false;
};

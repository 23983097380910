import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Italic: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M12.6668 3.42859C12.6668 3.84978 12.319 4.1905 11.8891 4.1905H10.463L7.22311 11.8095H8.77794C9.20791 11.8095 9.55572 12.1503 9.55572 12.5714C9.55572 12.9926 9.20791 13.3334 8.77794 13.3334H4.11127C3.68131 13.3334 3.3335 12.9926 3.3335 12.5714C3.3335 12.1503 3.68131 11.8095 4.11127 11.8095H5.53728L8.77721 4.1905H7.22239C6.79242 4.1905 6.44461 3.84978 6.44461 3.42859C6.44461 3.0074 6.79242 2.66669 7.22239 2.66669H11.8891C12.3193 2.66669 12.6668 3.00764 12.6668 3.42859Z"
      fill={color}
    />
  </svg>
);

/* eslint-disable max-lines */
import {
  ActionItemCode,
  ActionItemPriorityOverride,
} from '@transcend-io/privacy-types';

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { GlobalActionItemFiltersInput } from './globalActionItem';

export const UpdateActionItemsRollupInput = mkInput({
  name: 'UpdateActionItemsRollupInput',
  comment: 'Input for bulk updating action item rollups',
  fields: {
    assigneesTeamIds: {
      comment: 'The ids of the teams assigned to this action item',
      modelName: 'team',
      type: 'id',
      optional: true,
      list: true,
    },
    type: {
      comment: 'The type of this action item',
      type: { ActionItemCode },
    },
    assigneesUserIds: {
      comment: 'The unique ids of the users to assign to this action item',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    resolved: {
      comment: 'Is the action item marked as resolved',
      optional: true,
      type: 'boolean',
    },
  },
});

/** Override type */
export type UpdateActionItemsRollupInput = SchemaToType<
  typeof UpdateActionItemsRollupInput
>;

export const UpdateActionItemsAttributesInput = mkInput({
  name: 'UpdateActionItemsAttributesInput',
  comment: 'Input for bulk updating action item attributes',
  fields: {
    attributeKeyId: {
      comment: 'The id of the attribute key to update',
      modelName: 'attributeKey',
      type: 'id',
    },
    attributeValueIds: {
      comment: 'The ids of the attribute values to update',
      modelName: 'attributeValue',
      type: 'id',
      list: true,
      optional: true,
    },
    attributeValueNames: {
      comment: 'The names of the attribute values to update',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateActionItemsAttributesInput = SchemaToType<
  typeof UpdateActionItemsAttributesInput
>;

export const UpdateActionItemsInput = mkInput({
  name: 'UpdateActionItemsInput',
  comment: 'Input for bulk updating one or more action items',
  fields: {
    ids: {
      comment: 'The ids of the action items to update',
      modelName: 'actionItem',
      type: 'id',
      list: true,
      optional: true,
    },
    rollupFilters: {
      comment: 'The filterable field values for the rollups to update',
      type: UpdateActionItemsRollupInput,
      list: true,
      optional: true,
    },
    queryFilters: {
      comment: 'The current filters used in the query',
      type: GlobalActionItemFiltersInput,
      optional: true,
    },
    priorityOverride: {
      comment: 'The priority override for this action item',
      type: { ActionItemPriorityOverride },
      optional: true,
    },
    resolved: {
      comment: 'Is this action item resolved?',
      type: 'boolean',
      optional: true,
    },
    assigneesTeamIds: {
      comment: 'The ids of the teams assigned to this action item',
      modelName: 'team',
      type: 'id',
      optional: true,
      list: true,
    },
    assigneesTeamNames: {
      comment:
        'The names of the teams that should be responsible for this action item',
      type: 'string',
      list: true,
      optional: true,
    },
    assigneesUserIds: {
      comment: 'The unique ids of the users to assign to this action item',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    assigneesUserEmails: {
      comment:
        'The emails of the users to assign to this action item. These emails must have matching users on Transcend.',
      type: 'string',
      optional: true,
      list: true,
    },
    attributes: {
      comment: 'The attributes to update',
      type: UpdateActionItemsAttributesInput,
      list: true,
      optional: true,
    },
    dueDate: {
      comment: 'The date this action item is due',
      type: 'Date',
      optional: true,
    },
    title: {
      comment: 'The title of the action item',
      type: 'string',
      optional: true,
    },
    notes: {
      comment: 'The user specified notes about the action item',
      type: 'string',
      optional: true,
    },
    link: {
      comment: 'The user specified link for the action item',
      type: 'string',
      optional: true,
    },
    customerExperienceActionItemId: {
      comment:
        'The id of the customer experience action item, used for syncing of onboarding checklists',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateActionItemsInput = SchemaToType<
  typeof UpdateActionItemsInput
>;

export const CreateActionItemsInput = mkInput({
  name: 'CreateActionItemsInput',
  comment: 'Input for bulk creating one or more action items',
  fields: {
    title: {
      comment: 'The title of this action item',
      type: 'string',
    },
    type: {
      comment: 'The type of this action item',
      type: { ActionItemCode },
    },
    itemId: {
      comment: 'The idemId for this action item',
      type: 'string',
      optional: true,
    },
    priorityOverride: {
      comment: 'The priority override for this action item',
      type: { ActionItemPriorityOverride },
      optional: true,
    },
    resolved: {
      comment: 'Is this action item resolved?',
      type: 'boolean',
      optional: true,
    },
    assigneesTeamIds: {
      comment: 'The ids of the teams assigned to this action item',
      modelName: 'team',
      type: 'id',
      optional: true,
      list: true,
    },
    assigneesTeamNames: {
      comment:
        'The names of the teams that should be responsible for this action item',
      type: 'string',
      list: true,
      optional: true,
    },
    assigneesUserIds: {
      comment: 'The unique ids of the users to assign to this action item',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    assigneesUserEmails: {
      comment:
        'The emails of the users to assign to this action item. These emails must have matching users on Transcend.',
      type: 'string',
      optional: true,
      list: true,
    },
    collectionIds: {
      comment: 'The list of collections to add this action item to',
      type: 'id',
      modelName: 'actionItemCollection',
      list: true,
      optional: true,
    },
    dueDate: UpdateActionItemsInput.fields.dueDate,
    notes: UpdateActionItemsInput.fields.notes,
    link: UpdateActionItemsInput.fields.link,
    customerExperienceActionItemId:
      UpdateActionItemsInput.fields.customerExperienceActionItemId,
  },
});

/** Override type */
export type CreateActionItemsInput = SchemaToType<
  typeof CreateActionItemsInput
>;

export const DeleteActionItemsInput = mkInput({
  name: 'DeleteActionItemsInput',
  comment: 'Input for bulk deleting one or more action items',
  fields: {
    ids: {
      comment: 'The ids of the action items',
      modelName: 'actionItem',
      type: 'id',
      list: true,
    },
  },
});

/** Override type */
export type DeleteActionItemsInput = SchemaToType<
  typeof DeleteActionItemsInput
>;

export const DeleteActionItemCollectionsInput = mkInput({
  name: 'DeleteActionItemCollectionsInput',
  comment: 'Input for bulk deleting one or more action item collections',
  fields: {
    ids: {
      comment: 'The ids of the action item collections',
      modelName: 'actionItemCollection',
      type: 'id',
      list: true,
    },
  },
});

/** Override type */
export type DeleteActionItemCollectionsInput = SchemaToType<
  typeof DeleteActionItemCollectionsInput
>;

export const ActionItemRunLocksInput = mkInput({
  name: 'ActionItemRunLocksInput',
  comment: 'Input action items run locks query',
  fields: {
    types: {
      comment: 'A list of action item types to check',
      type: { ActionItemCode },
      list: true,
    },
  },
});

/** Override type */
export type ActionItemRunLocksInput = SchemaToType<
  typeof ActionItemRunLocksInput
>;

export const ActionItemRunLock = mkType({
  name: 'ActionItemRunLock',
  comment: 'The subscription info for each type of action item',
  fields: {
    createdAt: {
      comment: 'When the run lock was created',
      type: 'Date',
    },
    id: {
      comment: 'The run lock id',
      modelName: 'actionItemRunLock',
      type: 'id',
    },
    updatedAt: {
      comment: 'When the run lock was updated',
      type: 'Date',
    },
    expiresAt: {
      comment: 'When the run lock expires',
      type: 'Date',
    },
    lastCompletedAt: {
      comment: 'When the action item was last completed',
      type: 'Date',
      optional: true,
    },
    lastQueuedAt: {
      comment: 'When the action item was last queued to be processed',
      type: 'Date',
      optional: true,
    },
    type: {
      comment: 'The type of action item',
      type: { ActionItemCode },
    },
  },
});

/** Override type */
export type ActionItemRunLock = SchemaToType<typeof ActionItemRunLock>;

export const ActionItemRescanInput = mkInput({
  name: 'ActionItemRescanInput',
  comment: 'Input for Action Items rescan mutation',
  fields: {
    types: {
      comment: 'A list of action item types to queue for rescan',
      type: { ActionItemCode },
      list: true,
    },
  },
});

/** Override type */
export type ActionItemRescanInput = SchemaToType<typeof ActionItemRescanInput>;
/* eslint-enable max-lines */

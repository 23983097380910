import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Ellipsis: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="1.5" fill={color} />
    <circle cx="3.5" cy="8" r="1.5" fill={color} />
    <circle cx="12.5" cy="8" r="1.5" fill={color} />
  </svg>
);

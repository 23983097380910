/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ServerDatabasesKeyProtection: React.FC<SvgProps> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.40623 12.177V13.0103"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.90623 12.177V13.0103"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8333 5.94792H15.0104"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41667 5.53125V6.36458"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91667 5.53125V6.36458"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2.5"
      y="2.60425"
      width="15"
      height="6.66667"
      rx="1.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16667 15.9374H3.75C3.05964 15.9374 2.5 15.3778 2.5 14.6874V10.5208C2.5 9.8304 3.05964 9.27075 3.75 9.27075"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0864 14.9695L11.8957 16.1635C11.7494 16.3111 11.6671 16.5103 11.6667 16.7181V17.549C11.6667 17.9822 12.0179 18.3334 12.4511 18.3334H13.2856C13.4938 18.3337 13.6935 18.2506 13.8401 18.1026L15.0267 16.9126"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0863 14.9693C12.7969 13.8063 13.319 12.593 14.3624 12.0035C15.4059 11.4139 16.7145 11.5929 17.5613 12.4409C18.4082 13.289 18.5853 14.5978 17.9943 15.6404C17.4033 16.6831 16.1893 17.2034 15.0267 16.9124"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7084 14.3335C15.7084 14.3565 15.6897 14.3751 15.6667 14.3751C15.6437 14.3751 15.6251 14.3564 15.6251 14.3334C15.6251 14.3104 15.6437 14.2918 15.6667 14.2917C15.6777 14.2917 15.6884 14.2961 15.6962 14.304C15.704 14.3118 15.7084 14.3224 15.7084 14.3335"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

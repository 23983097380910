import { TeamPreview, UserPreview } from '@main/access-control-types';
import { mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { Catalog, CatalogPreview } from './catalog';
import { CustomHeader } from './customHeader';
import { DataSiloConnectionState } from './enums';

export const SilosDiscoveredPreview = mkType({
  name: 'SilosDiscoveredPreview',
  comment: 'Data silo that was discovered by the plugin from this data silo',
  fields: {
    pluginId: {
      comment: 'plugin IDs of the Data Silo',
      type: 'id',
      modelName: 'plugin',
      optional: true,
    },
    value: {
      comment: 'Count of silos discovered',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SilosDiscoveredPreview = SchemaToType<
  typeof SilosDiscoveredPreview
>;

export const DiscoveredByDataSiloPreview = mkType({
  name: 'DiscoveredByDataSiloPreview',
  comment: 'A preview of the plugin that found the data silo',
  fields: {
    id: {
      comment: 'The unique ID of a data silo in the datamap',
      modelName: 'dataSilo',
      type: 'id',
    },
    title: {
      comment: 'The internal display title',
      type: 'string',
    },
    description: {
      comment: 'A brief internal description of the data silo',
      type: 'string',
    },
    type: {
      comment: 'The type of data silo',
      type: 'string',
    },
    outerType: {
      comment:
        'The catalog name responsible for the cosmetics of the integration (name, description, logo, email fields)',
      type: 'string',
      optional: true,
    },
    connectionState: {
      comment: 'The current state of the integration',
      type: { DataSiloConnectionState },
    },
    url: {
      comment: 'The URL to send POST request notifications to',
      optional: true,
      type: 'string',
    },
    isLive: {
      comment: 'Whether the data silo is live',
      type: 'boolean',
    },
    deletedAt: {
      comment: 'The date at which this data silo was deleted',
      optional: true,
      type: 'Date',
    },
    lastEnabledAt: {
      comment: 'The date at which this data silo was last enabled',
      optional: true,
      type: 'Date',
    },
    lastConnectedAt: {
      comment: 'The date at which this data silo was last deleted',
      optional: true,
      type: 'Date',
    },
    externalId: {
      comment: 'The external ID of the data silo',
      type: 'string',
    },
    catalog: {
      comment: 'A preview of the attached catalog',
      type: CatalogPreview,
    },
    pluginId: {
      comment:
        'Silo discovery recommendations were filtered by the plugin that discovered it',
      type: 'id',
      modelName: 'plugin',
      optional: true,
    },
  },
});

/** Override type */
export type DiscoveredByDataSiloPreview = SchemaToType<
  typeof DiscoveredByDataSiloPreview
>;

export const RequestFileDataSiloPreview = mkType({
  name: 'RequestFileDataSiloPreview',
  comment:
    'A preview of the data silo that the request file is associated with',
  fields: {
    id: DiscoveredByDataSiloPreview.fields.id,
    title: DiscoveredByDataSiloPreview.fields.title,
    description: DiscoveredByDataSiloPreview.fields.description,
    type: DiscoveredByDataSiloPreview.fields.type,
    outerType: DiscoveredByDataSiloPreview.fields.outerType,
  },
});

/** Override type */
export type RequestFileDataSiloPreview = SchemaToType<
  typeof RequestFileDataSiloPreview
>;

export const DataSiloInterface = mkInterface({
  name: 'DataSiloInterface',
  comment: 'The interface for identifying data silo details',
  fields: {
    discoveredBy: {
      comment: 'The data silos that discovered this particular data silo',
      list: true,
      type: DiscoveredByDataSiloPreview,
    },
    link: {
      comment: 'The link to the data silo on the admin dashboard',
      type: 'string',
    },
    headers: {
      comment: 'Custom headers to include in outbound webhook',
      list: true,
      type: CustomHeader,
    },
    subdomain: {
      comment: 'The subdomain that the integration lives on, if applicable',
      optional: true,
      type: 'string',
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this data silo',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this data silo',
      list: true,
      type: TeamPreview,
    },
    ...DiscoveredByDataSiloPreview.fields,
    slug: {
      comment: 'The slug of the data silo for templating',
      type: 'string',
    },
    catalog: {
      comment: 'The configuration for the attached catalog',
      type: () => Catalog,
    },
  },
});

/** Override type */
export type DataSiloInterface = SchemaToType<typeof DataSiloInterface>;

export const DataSiloPreview = mkType({
  name: 'DataSiloPreview',
  comment: 'Preview of a data silo',
  interfaces: [DataSiloInterface],
  fields: {
    ...DataSiloInterface.fields,
  },
});

/** Override type */
export type DataSiloPreview = SchemaToType<typeof DataSiloPreview>;

import { makeEnum } from '@transcend-io/type-utils';

/**
 * The types of default templates to assign in request settings
 */
export enum DefaultTemplateType {
  /** The email send to the data subject when the DSR is submitted */
  Receipt = 'receiptTemplate',
  /** The response to use when a request finds no data or performs no action */
  NotFound = 'notFoundTemplate',
  /** Notify the data subject that additional time is needed to respond to their request */
  AdditionalTime = 'additionalTimeTemplate',
  /** The data subject cancels an active request, they get notified with this template */
  Revoked = 'revokedTemplate',
  /** The template to use when cancel-ing a request */
  Cancelation = 'cancelationTemplate',
  /** The email to send to login */
  EmailLogin = 'emailLoginTemplate',
  /**
   * The email to send to an external vendor, about all the outstanding requests
   * they must fulfill.
   */
  ExternalBulkRequestVendorCoordination = 'externalBulkRequestVendorCoordination',
  /** The email sent for government ID verification */
  GovernmentIdMessage = 'governmentIdMessageTemplate',
}

/**
 * The fields that templates can be ordered by
 */
export enum TemplateOrderField {
  /** The title of the templates */
  Title = 'title',
  /** The created at date of the templates */
  CreatedAt = 'createdAt',
  /** The time the template was updated */
  UpdatedAt = 'updatedAt',
}

/**
 * The type of service this template is used for
 */
export const TemplateType = makeEnum({
  /** A template for sending an email */
  Email: 'EMAIL',
  /** A template for sending a text message, e.g. SMS */
  Text: 'TEXT',
});

/** Override type */
export type TemplateType = (typeof TemplateType)[keyof typeof TemplateType];

/**
 * The product this template is used for
 */
export const TemplateProduct = makeEnum({
  /** A template for DSR emails */
  Dsr: 'DSR',
  /** A template for assessment emails */
  Assessment: 'ASSESSMENT',
});

/** Override type */
export type TemplateProduct =
  (typeof TemplateProduct)[keyof typeof TemplateProduct];

/**
 * The assessment action this email is used for
 */
export const AssessmentActionType = makeEnum({
  /** Form assigned to users */
  FormAssigned: 'FORM_ASSIGNED',
  /** Sections assigned to users */
  SectionsAssigned: 'SECTIONS_ASSIGNED',
  /** Assessment submitted for review */
  InReview: 'IN_REVIEW',
  /** Assessment approved */
  Approved: 'APPROVED',
  /** Assessment rejected */
  Rejected: 'REJECTED',
  /** Changes requested on the entire form */
  FormChangesRequested: 'FORM_CHANGES_REQUESTED',
  /** Changes requested on specific sections */
  SectionsChangesRequested: 'SECTIONS_CHANGES_REQUESTED',
});

/** Override type */
export type AssessmentActionType =
  (typeof AssessmentActionType)[keyof typeof AssessmentActionType];

import { hexToRgb } from './hexToRgb';

/**
 * Determine if a color is light
 *
 * @param color - The color hex code
 * @returns True if a light color and needs a dark contrast
 */
export function colorIsLight(color: string): boolean {
  // YIQ equation from http://24ways.org/2010/calculating-color-contrast
  const { r, g, b } = hexToRgb(color);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq > 180;
}

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const AssessmentFile = mkType({
  name: 'AssessmentFile',
  comment:
    'An assessment file uploaded by an organization to be displayed within an assessment or assessment template',
  fields: {
    presignedUrl: {
      comment: 'The presigned AWS url for where the file is stored',
      type: 'string',
    },
    size: {
      comment: 'The file size in bytes',
      type: 'int',
    },
    mimetype: {
      comment: 'The mimetype of the file',
      type: 'string',
    },
    id: {
      comment: 'The unique id of the assessment file',
      modelName: 'assessmentFile',
      type: 'id',
    },
    src: {
      comment: 'The url in AWS where the assessment file can be downloaded',
      type: 'string',
    },
  },
});

/** Override type */
export type AssessmentFile = SchemaToType<typeof AssessmentFile>;

export const AssessmentFileFiltersInput = mkInput({
  name: 'AssessmentFileFiltersInput',
  comment: 'Filters for filtering a list of assessment files',
  fields: {
    ids: {
      comment: 'The ids of the assessment files to return',
      type: 'id',
      modelName: 'assessmentFile',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentFileFiltersInput = SchemaToType<
  typeof AssessmentFileFiltersInput
>;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const InReview: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6665 8.00862C7.40317 7.11996 8.5965 7.11996 9.33317 8.00862"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.88529 6.11436C6.92669 7.15576 6.92669 8.8442 5.88529 9.8856C4.84389 10.927 3.15545 10.927 2.11406 9.8856C1.07266 8.8442 1.07266 7.15576 2.11406 6.11436C3.15546 5.07296 4.8439 5.07296 5.88529 6.11436"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M13.8853 6.11436C14.9267 7.15576 14.9267 8.8442 13.8853 9.8856C12.8439 10.927 11.1555 10.927 10.1141 9.8856C9.07266 8.8442 9.07266 7.15576 10.1141 6.11436C11.1555 5.07296 12.8439 5.07296 13.8853 6.11436"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33317 7.99996H0.666504"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3332 7.99996H14.6665"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { makeEnum } from '@transcend-io/type-utils';

/**
 * The possible methods types that graphql supports
 */
export enum GraphQLMethod {
  /** Graphql `query` aka a get request */
  Query = 'query',
  /** Graphql `mutation` aka delete, post & put requests */
  Mutation = 'mutation',
}

/**
 * Possible directions in which to order a list of items when provided an orderBy argument.
 */
export enum OrderDirection {
  /** Specifies an ascending order for a given orderBy argument. */
  ASC = 'ASC',
  /** Specifies a descending order for a given orderBy argument. */
  DESC = 'DESC',
}

/**
 * The reversal of ORDER_DIRECTION
 */
export enum ReverseOrderDirection {
  /** DESC becomes ASC. */
  DESC = 'ASC',
  /** ASC becomes DESC. */
  ASC = 'DESC',
}

/**
 * Pagination types
 */
export enum PaginationType {
  /** An obscured base64 cursor is used to move forward and backwards through the items */
  Cursor = 'CURSOR',
  /** [DEFAULT] An offset can be provided to allow the client to jump through pages */
  Offset = 'OFFSET',
}

/**
 * The valid schema field types
 */
export const SchemaFieldTypes = makeEnum({
  /** String */
  string: 'string',
  /** Floating point */
  float: 'float',
  /** Integer */
  int: 'int',
  /** Boolean */
  boolean: 'boolean',
  /** Date */
  Date: 'Date',
});

/** Override Type */
export type SchemaFieldTypes =
  (typeof SchemaFieldTypes)[keyof typeof SchemaFieldTypes];

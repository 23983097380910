import * as t from 'io-ts';

/**
 * @see https://github.com/andydunstall/piko/blob/be32cf8787af0c406114ca7bec815261035cd3dc/server/cluster/node.go#L19
 */
export const SombraReverseTunnelStatusLiteral = t.union([
  t.literal('active'),
  t.literal('unreachable'),
  t.literal('left'),
]);

export const SombraReverseTunnelClusterNodeStatus = t.type({
  /** Piko's ID for this node */
  id: t.string,
  /** Status. */
  status: SombraReverseTunnelStatusLiteral,
  /** Proxy endpoint address -- usually is the private IP address. */
  proxy_addr: t.string,
  /** Admin endpoint address -- usually is the private IP address. */
  admin_addr: t.string,
  /** Number of unique endpoints connected to this node. */
  endpoints: t.number,
  /** Number of total upstreams connected to this node. */
  upstreams: t.number,
});

export const SombraReverseTunnelClusterStatus = t.array(
  SombraReverseTunnelClusterNodeStatus,
);

export const SombraReverseTunnelClusterNodeDetailedStatus = t.type({
  /** Piko's ID for this node */
  id: t.string,
  /** Status. */
  status: SombraReverseTunnelStatusLiteral,
  /** Proxy endpoint address -- usually is the private IP address. */
  proxy_addr: t.string,
  /** Admin endpoint address -- usually is the private IP address. */
  admin_addr: t.string,
  /** Map of endpoint ID to number of connected upstreams for endpoint. */
  endpoints: t.union([t.null, t.record(t.string, t.number)]),
});

import React from 'react';

import { SvgProps } from '../enums';

export const FlagVA: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55190"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55190)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H7V12H0V0Z"
        fill="#FFDC17"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.3909 6.37555L11.2183 6.38679C11.2595 6.97968 11.3869 7.43766 11.603 7.76086C11.9516 8.28208 12.4117 8.68508 12.7195 8.68508C12.7894 8.68508 12.8057 8.71105 12.7826 8.77515C12.7498 8.8663 12.6461 8.95595 12.5127 8.99035C12.2908 9.04757 11.7862 9.07719 11.0073 9.07719V9.24463C11.8018 9.24463 12.3155 9.21448 12.5572 9.15215C12.745 9.10374 12.8939 8.97499 12.946 8.83021C13.0062 8.66278 12.9153 8.51764 12.7195 8.51764C12.4909 8.51764 12.0667 8.14609 11.7482 7.66987C11.5503 7.37388 11.4303 6.9424 11.3909 6.37555Z"
        fill="#AF0100"
      />
      <path
        // eslint-disable-next-line max-len
        d="M10.5735 6.37555L10.7461 6.38679C10.7049 6.97968 10.5775 7.43766 10.3613 7.76086C10.0127 8.28208 9.55269 8.68508 9.24486 8.68508C9.17494 8.68508 9.15868 8.71105 9.18174 8.77515C9.21453 8.8663 9.31823 8.95595 9.45164 8.99035C9.67353 9.04757 10.1782 9.07719 10.957 9.07719V9.24463C10.1626 9.24463 9.64881 9.21448 9.40711 9.15215C9.2194 9.10374 9.07046 8.97499 9.01838 8.83021C8.95816 8.66278 9.04905 8.51764 9.24486 8.51764C9.47349 8.51764 9.89762 8.14609 10.2161 7.66987C10.4141 7.37388 10.5341 6.9424 10.5735 6.37555Z"
        fill="#AF0100"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M14.0335 3.39905L9.46278 6.96205C9.30274 6.90991 9.12802 6.88138 8.94602 6.88138C8.20446 6.88138 7.58386 7.35505 7.58386 7.96975C7.58386 8.58445 8.20446 9.05812 8.94602 9.05812C9.03107 9.05812 9.11453 9.05189 9.19564 9.03993C9.12061 8.98134 9.06457 8.90809 9.03655 8.83022C9.00285 8.73652 9.01647 8.6498 9.06933 8.59159C9.02914 8.59558 8.98798 8.59765 8.94602 8.59765C8.44569 8.59765 8.05954 8.30292 8.05954 7.96975C8.05954 7.63657 8.44569 7.34184 8.94602 7.34184C9.44636 7.34184 9.83251 7.63657 9.83251 7.96975C9.83251 8.07168 9.79637 8.17001 9.73187 8.25741C9.74401 8.24658 9.75619 8.23547 9.76841 8.22409C9.92682 8.0766 9.57086 8.68508 9.26303 8.68508C9.19311 8.68508 9.17685 8.71105 9.19991 8.77515C9.23062 8.86053 9.32355 8.94459 9.44484 8.98317C9.94622 8.8264 10.3082 8.4392 10.3082 7.96975C10.3082 7.77772 10.2476 7.59945 10.1417 7.44508L12.5982 5.36028L12.7626 5.53015L12.8809 5.42281L13.2913 5.72691L13.1197 5.85917L13.4699 6.28486L14.1764 5.7402L14.1884 5.73401L14.1863 5.73258L14.2301 5.69882L13.8799 5.27313L13.7106 5.40366L13.2518 5.08647L13.3055 5.03769L13.1579 4.88519L13.3799 4.6968L13.4977 4.81852L13.6242 4.70378L14.022 5.01866L13.8548 5.14755L14.205 5.57323L14.8102 5.10666L14.8796 5.06871L14.8695 5.06098L14.9652 4.98719L14.615 4.5615L14.4172 4.71399L13.9815 4.37972L14.0407 4.32606L13.9397 4.22171L14.3919 3.8379L14.0335 3.39905Z"
        fill="#F4B300"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M11.6919 7.96974C11.6919 7.69345 11.8173 7.44565 12.0227 7.25655L9.39793 5.22388L9.36801 5.24692L9.44452 5.31632L9.23755 5.53015L9.12858 5.43131L8.72681 5.74073L8.88044 5.85917L8.53023 6.28486L7.83126 5.74601L7.81176 5.734L7.81383 5.73257L7.77004 5.69881L8.12025 5.27312L8.28957 5.40366L8.74837 5.08646L8.69459 5.03769L8.89291 4.83278L8.66182 4.65382L8.50242 4.81852L8.38065 4.70808L7.98796 5.02624L8.14531 5.14754L7.7951 5.57323L7.20912 5.12149L7.12056 5.0687L7.13063 5.06098L7.0349 4.98718L7.38511 4.56149L7.58292 4.71398L8.01862 4.37972L7.95945 4.32606L8.07904 4.2025L7.60822 3.8379L7.90538 3.47833L12.4463 6.99488C12.6301 6.92199 12.8369 6.88137 13.0541 6.88137C13.7957 6.88137 14.4163 7.35504 14.4163 7.96974C14.4163 8.58444 13.7957 9.05811 13.0541 9.05811C12.9691 9.05811 12.8856 9.05188 12.8045 9.03993C12.8795 8.98133 12.9356 8.90809 12.9636 8.83021C12.9973 8.73651 12.9836 8.64979 12.9308 8.59158C12.971 8.59558 13.0121 8.59765 13.0541 8.59765C13.5544 8.59765 13.9406 8.30292 13.9406 7.96974C13.9406 7.63657 13.5544 7.34183 13.0541 7.34183C12.5538 7.34183 12.1676 7.63657 12.1676 7.96974C12.1676 8.07167 12.2038 8.17 12.2683 8.2574C12.2561 8.24657 12.2439 8.23546 12.2317 8.22408C12.0733 8.07659 12.4293 8.68508 12.7371 8.68508C12.807 8.68508 12.8233 8.71104 12.8002 8.77515C12.7695 8.86053 12.6766 8.94459 12.5553 8.98317C12.0539 8.8264 11.6919 8.4392 11.6919 7.96974Z"
        fill="#BFBFBF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.8704 5.89764C10.8346 5.89764 10.8055 5.92668 10.8055 5.96251V6.23253H10.5656C10.5309 6.23253 10.5028 6.26064 10.5028 6.29532C10.5028 6.33 10.5309 6.35811 10.5656 6.35811H10.8055V9.9351C10.8055 9.97093 10.8346 9.99997 10.8704 9.99997H11.0001C11.0359 9.99997 11.065 9.97093 11.065 9.9351V6.35811H11.4346C11.4693 6.35811 11.4974 6.33 11.4974 6.29532C11.4974 6.26064 11.4693 6.23253 11.4346 6.23253H11.065V5.96251C11.065 5.92669 11.0359 5.89764 11.0001 5.89764H10.8704Z"
        fill="#AF0100"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.9352 10.0083C11.0785 10.0083 11.1947 9.95022 11.1947 9.87857C11.1947 9.80692 11.0785 9.74884 10.9352 9.74884C10.7919 9.74884 10.6758 9.80692 10.6758 9.87857C10.6758 9.95022 10.7919 10.0083 10.9352 10.0083Z"
        fill="#AF0100"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.9C10 2.40294 10.4029 2 10.9 2C11.3971 2 11.8 2.40294 11.8 2.9V4.52174H10L10 2.9Z"
        fill="#F4B300"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.892 4.60971C11.3935 4.60971 11.8001 4.54195 11.8001 4.45836C11.8001 4.37477 11.3935 4.30701 10.892 4.30701C10.3905 4.30701 9.98389 4.37477 9.98389 4.45836C9.98389 4.54195 10.3905 4.60971 10.892 4.60971Z"
        fill="#AF0100"
      />
    </g>
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const EnvelopeRight: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6812 5.79688V12.5063"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6409 18.2691H14.8809"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7207 16.3477L20.6407 18.2677L18.7207 20.1877"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0413 16.3463H5.28133C4.22053 16.3463 3.36133 15.4871 3.36133 14.4263V5.79688"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M5.29189 3.86719H17.7517C18.8173 3.86719 19.6823 4.73119 19.6823 5.79775V5.79775C19.6823 6.43807 19.3645 7.03615 18.8346 7.39519L13.6919 10.8809C12.3815 11.7689 10.6612 11.7689 9.35077 10.8809L4.20901 7.39615C3.67909 7.03711 3.36133 6.43807 3.36133 5.79871V5.79775C3.36133 4.73119 4.22533 3.86719 5.29189 3.86719Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

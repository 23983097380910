import { ActionItemCode } from '@transcend-io/privacy-types';

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { ActionItemSubscriptionNotificationMethod } from './enums';

export const ActionItemSubscriptionContext = mkType({
  name: 'ActionItemSubscriptionContext',
  comment: 'additional context for an action item subscription',
  fields: {
    slackChannelName: {
      comment: 'name of the slack channel this subscription gets sent to',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type ActionItemSubscriptionContext = SchemaToType<
  typeof ActionItemSubscriptionContext
>;

export const ActionItemSubscriptionContextInput = mkInput({
  name: 'ActionItemSubscriptionContextInput',
  comment: 'additional context for an action item subscription',
  fields: {
    ...ActionItemSubscriptionContext.fields,
  },
});

/** Override type */
export type ActionItemSubscriptionContextInput = SchemaToType<
  typeof ActionItemSubscriptionContextInput
>;

export const ActionItemSubscription = mkType({
  name: 'ActionItemSubscription',
  comment: 'subscription to action item notifications',
  fields: {
    id: {
      comment: 'row id for this subscription',
      modelName: 'actionItemSubscription',
      type: 'id',
    },
    notificationMethod: {
      comment: 'method of notification for this subscription',
      type: { ActionItemSubscriptionNotificationMethod },
    },
    actionItemCode: {
      comment: 'The type of action item',
      type: { ActionItemCode },
    },
    destinationIdentifier: {
      comment:
        'Unique identifier of subscription destination, i.e. custom email or slack channel id',
      optional: true,
      type: 'string',
    },
    additionalContext: {
      comment: 'Additional context for the action item subscription',
      optional: true,
      type: ActionItemSubscriptionContext,
    },
  },
});

/** Override type */
export type ActionItemSubscription = SchemaToType<
  typeof ActionItemSubscription
>;

export const ActionItemSubscriptionInput = mkInput({
  name: 'ActionItemSubscriptionInput',
  comment: 'Input for creating actionItemSubscriptions',
  fields: {
    actionItemCode: {
      comment: 'The type of action items to subscribe to',
      type: { ActionItemCode },
      optional: false,
    },
    notificationMethod: {
      comment:
        'The method by which the user will be notified of this action item',
      type: { ActionItemSubscriptionNotificationMethod },
      optional: false,
    },
    destinationIdentifier: {
      comment:
        'Unique identifier of subscription destination, i.e. custom email or slack channel id',
      optional: true,
      type: 'string',
    },
    additionalContext: {
      comment: 'Additional context for the action item subscription',
      optional: true,
      type: ActionItemSubscriptionContextInput,
    },
  },
});

/** Override type */
export type ActionItemSubscriptionInput = SchemaToType<
  typeof ActionItemSubscriptionInput
>;

export const CreateActionItemSubscriptionsInput = mkInput({
  name: 'CreateActionItemSubscriptionsInput',
  comment: 'Input for creating user or org level actionItemSubscriptions',
  fields: {
    orgLevelSubscriptions: {
      comment:
        'Whether the subscriptions in this input are org level or user level',
      type: 'boolean',
      optional: true,
    },
    subscriptions: {
      comment: 'Subscriptions to create',
      type: ActionItemSubscriptionInput,
      list: true,
      optional: false,
    },
  },
});

/** Override type */
export type CreateActionItemSubscriptionsInput = SchemaToType<
  typeof CreateActionItemSubscriptionsInput
>;

export const QueryActionItemSubscriptionInput = mkInput({
  name: 'QueryActionItemSubscriptionInput',
  comment: 'Input for fetching actionItemSubscriptions',
  fields: {
    orgLevelSubscriptions: {
      comment: 'Whether query is fetching org or user level subscriptions',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type QueryActionItemSubscriptionInput = SchemaToType<
  typeof QueryActionItemSubscriptionInput
>;
export const DeleteActionItemSubscriptionInput = mkInput({
  name: 'DeleteActionItemSubscriptionInput',
  comment: 'Input for deleting an actionItemSubscription',
  fields: {
    orgLevelSubscriptions: {
      comment:
        'Whether the subscriptions in this input are org level or user level',
      type: 'boolean',
      optional: true,
    },
    ids: {
      comment:
        'The id of the action item subscription, pass no id to delete all',
      modelName: 'actionItemSubscription',
      type: 'id',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type DeleteActionItemSubscriptionInput = SchemaToType<
  typeof DeleteActionItemSubscriptionInput
>;

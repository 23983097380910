import styled, { css } from 'styled-components';

import { FlexColumn } from '../StyledWrappers';
import { INDICATORS_Z_INDEX, REMOVE_Z_INDEX } from './constants';
import { ReactSelectOverflowOptions } from './types';

export const menuPortalStyles = css`
  .hideable-select__menu {
    font-size: 12px;
    .hideable-select__option {
      padding: 4px 7px;

      &:not(.hideable-select__option--is-disabled) {
        &:hover {
          background-color: ${({ theme }) => theme.colors.gray1};
        }
      }
    }

    .hideable-select__option--is-focused {
      background-color: ${({ theme }) => theme.colors.gray1};
    }

    .hideable-select__option--is-selected {
      background-color: ${({ theme }) => theme.colors.transcend4};
    }
  }
`;

/**
 * Pill Wrapper
 */
const pillWrapper = css`
  .styled-option-label {
    border-width: 1px;
    border-style: solid;

    min-height: 25px;
    border-radius: 6px;
    padding: 2px 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`;

export const ReadOnlyMultiValueWrapper = styled.div`
  display: flex;
  padding: 4px 6px;
  border-radius: 6px;
  width: fit-content;
  font-weight: 600;
  border-width: 0px;
  font-size: 12px;
  overflow: hidden;
`;

export const PopoverContentWrapper = styled.div`
  overflow-wrap: anywhere;
`;

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MultiValueRemoveWrapper = styled.div<{
  /** Whether this item should be removable or not */
  isFixed: boolean;
  /** Whether the menu for the react-select containing this option is open  */
  menuIsOpen?: boolean;
}>`
  // keeps 'x' icon from overlapping pill borders
  padding: 1px;
  margin-left: -22px;
  bottom: 0px;
  right: 0px;
  ${({ isFixed }) => (isFixed ? 'display: none;' : '')}
  // Make the 'x' icon clickable in the display area when the menu is open; otherwise scroll wrapper covers it
  ${({ menuIsOpen }) => (menuIsOpen ? `z-index: ${REMOVE_Z_INDEX};` : '')}
  width: fit-content;
`;

export const MenuWrapper = styled.div``;

export const HiddenSelectWrapper = styled(FlexColumn)<{
  /** The over-flow type */
  overflow: ReactSelectOverflowOptions<true>;
  /** Whether the menu for the react-select this wrapper surrounds is open */
  menuIsOpen?: boolean;
  /** Override the minHeight of the select component */
  minHeight?: string;
}>`
  // default for react-select is 16px
  font-size: 12px;
  width: 100%;

  &:hover {
    .hidden-outline {
      .hideable-select__control {
        border-color: ${({ theme }) => theme.colors.gray3};
      }

      .hideable-select__clear-indicator,
      .hideable-select__dropdown-indicator,
      .hideable-select__indicator-separator {
        opacity: 1;
      }
    }
  }

  .hideable-select__placeholder {
    color: ${({ theme }) => theme.colors.transcendNavy4};
  }

  ${pillWrapper}
  .hideable-select__multi-value {
    border-width: 1px;
    border-style: solid;
    height: 28px;

    &:hover {
      .hideable-select__multi-value__remove {
        opacity: 1;
      }
    }

    .hideable-select__multi-value__label {
      padding: 0px;
      font-size: 100%;
      display: flex;
      align-items: center;
    }

    .hideable-select__multi-value__remove {
      svg {
        opacity: 0.7;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    .styled-option-label {
      border: none;
    }
  }

  .hideable-select__control {
    border-radius: 0.6em;
    min-height: unset;
    border-color: ${({ theme }) => theme.colors.gray3};
    background: transparent;

    &:hover:not(.hideable-select__control--is-disabled) {
      background: ${({ theme }) => theme.colors.white};
    }
    &.hideable-select__control--is-disabled:hover {
      border-color: ${({ theme }) => theme.colors.gray3};
    }
  }

  // normal 8px padding makes this a chonky boi
  .hideable-select__dropdown-indicator,
  .hideable-select__clear-indicator {
    padding: 4px 8px;
    // Make the indicators clickable in the display area when the menu is open; otherwise scroll wrapper covers them
    ${({ menuIsOpen }) => (menuIsOpen ? `z-index: ${INDICATORS_Z_INDEX};` : '')}
  }

  .hideable-select__dropdown-indicator,
  .hideable-select__indicator-separator,
  .hideable-select__clear-indicator,
  .hideable-select__control {
    transition:
      opacity 0.3s ease-in-out,
      border-color 0.3s ease-in-out;
  }

  .hideable-select__value-container {
    padding: 2px;
    min-height: ${({ minHeight }) => minHeight ?? '34px'};
    flex-wrap: wrap;
  }

  .hideable-select__menu {
    z-index: 3;
  }

  // only set the outlines and backgrounds as transparent if showOutline=false
  .hidden-outline {
    .hideable-select__dropdown-indicator,
    .hideable-select__indicator-separator,
    .hideable-select__clear-indicator {
      opacity: 0;
    }

    .hideable-select__control {
      border-color: transparent;
    }
  }

  .hideable-select__control--is-focused {
    background-color: ${({ theme }) => theme.colors.white};
  }

  .hidden-outline {
    .hideable-select__control--is-disabled {
      border: 0px;
    }
  }

  .hideable-select--is-disabled {
    // need to trigger popover on hover even for selects that are disabled
    pointer-events: auto;
  }

  .hideable-select__group-heading {
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.transcendNavy2};
    font-weight: 600;
  }

  .hideable-select {
    // select should never be larger than its container
    max-width: 100%;
  }
`;

// To be used whenever we want to use the SingleTag/DoubleTag components
// as the option pill instead of react-select's default pill styling
export const SelectTagWrapper = styled.div`
  width: 100%;

  .hideable-select__single-value,
  .hideable-select__multi-value {
    border: none;
    height: auto;
  }
  .styled-option-label {
    padding: 0;
    border-width: 0;
  }
`;

import styled, { DefaultTheme, keyframes } from 'styled-components';

import { hexToRgb } from '@main/theme-types';

interface RadioInputContainerProps {
  /** flag for whether radio input is disabled as divs don't have a disabled state in html */
  disabled: boolean;
  /** current checked state of radio input */
  radioInputValue: 'checked' | 'unchecked';
  /** styled-components theme set up by a theme provider in each app */
  theme: DefaultTheme;
}

const hexToHalfOpacity = (hexColor: string): string => {
  const { r, g, b } = hexToRgb(hexColor);
  return `rgba(${r}, ${g}, ${b}, 0.5)`;
};

/**
 * ui state of the radio input div
 */
type RadioInputUIState = 'hover' | 'active' | 'disabled';
/**
 * utility function to get a mapping of ui states to colors from a given theme
 */
type GetColorsMap = (theme: DefaultTheme) => {
  [key in RadioInputUIState]: { [key in 'checked' | 'unchecked']: string };
};
const getBorderColorsMap: GetColorsMap = ({ colors }: DefaultTheme) => ({
  hover: {
    unchecked: colors.transcend2,
    checked: colors.transcend2,
  },
  active: {
    unchecked: colors.transcend,
    checked: colors.transcendNavy2,
  },
  disabled: {
    unchecked: hexToHalfOpacity(colors.transcendNavy2),
    checked: hexToHalfOpacity(colors.transcendNavy2),
  },
});
const getBackgroundColorsMap: GetColorsMap = ({ colors }: DefaultTheme) => ({
  hover: {
    unchecked: colors.white,
    checked: colors.transcend2,
  },
  active: {
    unchecked: colors.white,
    checked: colors.transcendNavy2,
  },
  disabled: {
    unchecked: colors.white,
    checked: hexToHalfOpacity(colors.transcendNavy2),
  },
});
const getColor =
  (colorsMapGetter: GetColorsMap) =>
  (radioInputUIState: RadioInputUIState) =>
  ({ disabled, radioInputValue, theme }: RadioInputContainerProps) =>
    colorsMapGetter(theme)[disabled ? 'disabled' : radioInputUIState][
      radioInputValue
    ];
const getBorderColor = getColor(getBorderColorsMap);
const getBackgroundColor = getColor(getBackgroundColorsMap);

export const HiddenRadio = styled.input`
  position: absolute;
  width: 0;
  opacity: 0;

  + label > :first-child {
    border-color: ${({ theme }) => theme.colors.transcendNavy2};
  }

  :checked :focus-visible :focus + label > :first-child {
    outline: none;
    border-color: ${({ theme }) => theme.colors.transcend3};
    background: ${({ theme }) => theme.colors.transcend};
  }

  :focus-visible :focus + label > :first-child {
    outline: none;
    border-color: ${({ theme }) => theme.colors.transcend3};
    background: ${({ theme }) => theme.colors.gray1};
  }

  :checked + label > :first-child {
    background-size: 100%;
    border-color: ${({ theme }) => theme.colors.transcend};
    background: ${({ theme }) => theme.colors.transcend};
  }

  :disabled + label > :first-child {
    border-color: ${({ theme }) =>
      hexToHalfOpacity(theme.colors.transcendNavy2)};
  }

  :checked :disabled + label > :first-child {
    background-size: 100%;
    border-color: ${({ theme }) =>
      hexToHalfOpacity(theme.colors.transcendNavy2)};
    background: ${({ theme }) => hexToHalfOpacity(theme.colors.transcendNavy2)};
  }

  :disabled ~ label {
    color: ${({ theme }) => hexToHalfOpacity(theme.colors.transcendNavy2)};
  }
`;

export const RadioInputCircle = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  background-size: 0;
  transition: all 200ms ease-out;
`;

export const RadioInputContainer = styled.div`
  position: relative;

  :hover > input[type='radio'] + label > :first-child {
    border-color: ${getBorderColor('hover')};
    background: ${getBackgroundColor('hover')};
  }

  :active > input[type='radio'] :not(:disabled) + label > :first-child {
    transform: scale(90%);
    // transition is set on transform, but when we click we want
    // the scale to change immediately or it won't feel as responsive
    transition: transform 0ms;
    border-color: ${getBorderColor('active')};
    background: ${getBackgroundColor('active')};
  }
`;

const expand = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`;

export const CenterDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: white;
  animation: 200ms ${expand} ease-out;
`;

export const LabelWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.transcendNavy2};
`;

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Compass: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.63601 5.63576V5.63576C9.15101 2.12076 14.849 2.12076 18.364 5.63576V5.63576C21.879 9.15076 21.879 14.8488 18.364 18.3638V18.3638C14.849 21.8788 9.15101 21.8788 5.63601 18.3638V18.3638C2.12101 14.8488 2.12101 9.15076 5.63601 5.63576Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.90402 15.5065L9.04002 9.82454C9.12002 9.42854 9.42902 9.11954 9.82502 9.04054L15.507 7.90454C15.857 7.83454 16.165 8.14254 16.095 8.49254L14.959 14.1745C14.88 14.5705 14.57 14.8795 14.175 14.9585L8.49302 16.0945C8.14302 16.1655 7.83402 15.8565 7.90402 15.5065Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.999 11.75C11.861 11.75 11.749 11.862 11.75 12C11.75 12.138 11.862 12.25 12 12.25C12.138 12.25 12.25 12.138 12.25 12C12.25 11.862 12.138 11.75 11.999 11.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

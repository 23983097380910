import { ScopeType, TranscendProduct } from '@transcend-io/privacy-types';

import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { ApiKeyPreview } from './apiKey';
import { ScopeOrderField } from './enums';
import { ScopePreview } from './scopePreview';
import { TeamPreview } from './teamPreview';
import { UserPreview } from './userPreview';

export const ScopeFiltersInput = mkInput({
  name: 'ScopeFiltersInput',
  comment: 'Inputs for filtering the scopes',
  fields: {
    text: {
      comment: 'Filter scopes that match a text string (name for now)',
      optional: true,
      type: 'string',
    },
    types: {
      comment: 'The static type of scope',
      optional: true,
      list: true,
      type: { ScopeType },
    },
    routeNames: {
      comment:
        'Filter for specific routes to see what scopes have access to that route',
      optional: true,
      list: true,
      type: 'string',
    },
    productLines: {
      comment: 'The product lines that the scope relates to',
      optional: true,
      list: true,
      type: { TranscendProduct },
    },
    userIds: {
      comment: 'The IDs of the users that are directly assigned to this scope',
      optional: true,
      list: true,
      modelName: 'user',
      type: 'id',
    },
    teamIds: {
      comment: 'The IDs of the teams that are assigned to this scope',
      optional: true,
      list: true,
      modelName: 'team',
      type: 'id',
    },
    apiKeyIds: {
      comment: 'The IDs of the API keys that are assigned to this scope',
      optional: true,
      list: true,
      modelName: 'apiKey',
      type: 'id',
    },
  },
});

/** Override type */
export type ScopeFiltersInput = SchemaToType<typeof ScopeFiltersInput>;

export const UpdateScopeInput = mkInput({
  name: 'UpdateScopeInput',
  comment: 'Input for updating access to a scope',
  fields: {
    id: {
      comment: 'The unique ID of the scope',
      modelName: 'scope',
      type: 'id',
    },
    users: {
      comment:
        'The IDs of the users that should have direct access to the scope',
      optional: true,
      list: true,
      modelName: 'user',
      type: 'id',
    },
    teams: {
      comment: 'The IDs of the teams that should have the scope',
      optional: true,
      list: true,
      modelName: 'team',
      type: 'id',
    },
    apiKeys: {
      comment: 'The IDs of the API keys that should have the scope',
      optional: true,
      list: true,
      modelName: 'apiKey',
      type: 'id',
    },
  },
});

/** Override type */
export type UpdateScopeInput = SchemaToType<typeof UpdateScopeInput>;

export const Scope = mkType({
  name: 'Scope',
  comment: 'A scope that exists within an organization',
  fields: {
    ...ScopePreview.fields,
    users: {
      comment: 'The users that have been directly assigned to the scope',
      list: true,
      type: UserPreview,
    },
    allUsers: {
      comment: 'All users that have the scope (direct or team)',
      list: true,
      type: UserPreview,
    },
    /** The teams that have been assigned the scope */
    teams: {
      comment: 'The teams that have been assigned the scope',
      list: true,
      type: TeamPreview,
    },
    allTeams: {
      comment: 'All calculated teams that have the scope',
      list: true,
      type: TeamPreview,
    },
    apiKeys: {
      comment: 'All api keys that have the scope (direct or team)',
      list: true,
      type: ApiKeyPreview,
    },
  },
});

/** Override type */
export type Scope = SchemaToType<typeof Scope>;

/**
 * Order for a scopes query
 */
export const ScopeOrder = mkOrder(Scope.name, ScopeOrderField);

/** Override type */
export type ScopeOrder = SchemaToType<typeof ScopeOrder>;

import { mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { StepStatus } from './enums';

export const StepInterface = mkInterface({
  name: 'StepInterface',
  comment: 'A step in the lifecycle of the DSR',
  fields: {
    id: {
      comment: 'The id of the step is the request status',
      type: 'string',
    },
    description: {
      comment: 'A description of what is happening at this step',
      type: 'string',
    },
    title: {
      comment: 'The title message for the step',
      type: 'string',
    },
    icon: {
      comment: 'The icon to to display with the step',
      type: 'string',
    },
    status: {
      comment:
        'The status of the step as it relates to the ant.design component',
      optional: true,
      type: { StepStatus },
    },
    time: {
      comment: 'The time that the step occurred',
      optional: true,
      type: 'Date',
    },
  },
});

/** Override type */
export type StepInterface = SchemaToType<typeof StepInterface>;

export const ChildStep = mkType({
  name: 'ChildStep',
  comment: 'A sub-step in the lifecycle of the DSR',
  interfaces: [StepInterface],
  fields: {
    ...StepInterface.fields,
  },
});

/** Override type */
export type ChildStep = SchemaToType<typeof ChildStep>;

export const Step = mkType({
  name: 'Step',
  comment: 'A step in the lifecycle of the DSR, with sub-steps',
  interfaces: [StepInterface],
  fields: {
    ...StepInterface.fields,
    steps: {
      comment: 'The sub-steps of the request',
      list: true,
      type: ChildStep,
    },
  },
});

/** Override type */
export type Step = SchemaToType<typeof Step>;

export const RequestSteps = mkType({
  name: 'RequestSteps',
  comment:
    'Configuration to display the steps/workflow of a request (https://ant.design/components/steps/)',
  fields: {
    status: {
      comment: 'The state of the current step',
      type: { StepStatus },
    },
    current: {
      comment: 'The index of the step currently being run',
      type: 'int',
    },
    steps: {
      comment: 'The steps in the lifecycle of the DSR',
      list: true,
      type: Step,
    },
  },
});

/** Override type */
export type RequestSteps = SchemaToType<typeof RequestSteps>;

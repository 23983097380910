import { makeEnum } from '@transcend-io/type-utils';

/** Feature gating status */
export const FeatureGate = makeEnum({
  /** The feature is allowed */
  Allowed: 'ALLOWED',
  /** The trial for the feature is expiring */
  TrialExpiring: 'TRIAL_EXPIRING',
  /** The feature is blocked */
  Blocked: 'BLOCKED',
});

/** Override type */
export type FeatureGate = (typeof FeatureGate)[keyof typeof FeatureGate];

/** The features available on the admin dashboard */
export const FeatureName = makeEnum({
  /** The Web Consent feature */
  WebConsent: 'webConsent',
  /** The Data Mapping feature */
  DataMapping: 'dataMapping',
});

/** Override type */
export type FeatureName = (typeof FeatureName)[keyof typeof FeatureName];

/** The possible support tiers */
export const SupportTier = makeEnum({
  Essential: 'ESSENTIAL',
  Pro: 'PRO',
  Premier: 'PREMIER',
});

/** Override type */
export type SupportTier = (typeof SupportTier)[keyof typeof SupportTier];

import React from 'react';

import { SvgProps } from '../enums';

export const FlagTF: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55057"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55057)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55057"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55057)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M9.39444 3.09998H11.3986H11.4144H13.4186L13.0265 3.85199H11.8057V4.25592H12.7416L12.3604 4.95553H11.8057V6.35467L12.7193 4.91503L13.9016 6.96234H13.4186L13.2499 6.73818H12.1797L11.4272 8.12864L11.4274 8.20545L11.4065 8.16687L11.3856 8.20545L11.3858 8.12864L10.6333 6.73818H9.56314L9.39444 6.96234H8.91141L10.0938 4.91503L11.0074 6.35467V3.85199H9.78652L9.39444 3.09998ZM12.7428 5.75473L12.4802 6.15859H12.9773L12.7428 5.75473ZM10.0702 5.75473L10.3328 6.15859H9.8357L10.0702 5.75473ZM8.15683 5.3066L8.57235 5.00026L8.98786 5.3066L8.84127 4.79325L9.24467 4.46923L8.73855 4.4583L8.57235 3.9517L8.40614 4.4583L7.90002 4.46923L8.30342 4.79325L8.15683 5.3066ZM14.2277 5.00026L13.8122 5.3066L13.9588 4.79325L13.5554 4.46923L14.0615 4.4583L14.2277 3.9517L14.3939 4.4583L14.9 4.46923L14.4966 4.79325L14.6432 5.3066L14.2277 5.00026ZM12.5397 8.90163L12.9552 8.59529L13.3708 8.90163L13.2242 8.38828L13.6276 8.06426L13.1215 8.05333L12.9552 7.54674L12.789 8.05333L12.2829 8.06426L12.6863 8.38828L12.5397 8.90163ZM9.8448 8.59529L9.42928 8.90163L9.57587 8.38828L9.17248 8.06426L9.67859 8.05333L9.8448 7.54674L10.011 8.05333L10.5171 8.06426L10.1137 8.38828L10.2603 8.90163L9.8448 8.59529ZM10.9845 10.1L11.4 9.79364L11.8155 10.1L11.669 9.58663L12.0723 9.26261L11.5662 9.25168L11.4 8.74508L11.2338 9.25168L10.7277 9.26261L11.1311 9.58663L10.9845 10.1Z"
          fill="#F7FCFF"
        />
        <rect
          x="-0.25"
          y="-0.25"
          width="6.5"
          height="5.5"
          fill="#2E42A5"
          stroke="white"
          strokeWidth="0.5"
        />
        <mask
          id="mask2_270_55057"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="-1"
          y="-1"
          width="8"
          height="7"
        >
          <rect
            x="-0.25"
            y="-0.25"
            width="6.5"
            height="5.5"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
          />
        </mask>
        <g mask="url(#mask2_270_55057)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 0H6V5H4V0Z"
            fill="#F50100"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0H2V5H0V0Z"
            fill="#2E42A5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0H4V5H2V0Z"
            fill="#F7FCFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

import React from 'react';
import {
  OverlayTrigger,
  OverlayTriggerProps,
  Tooltip as BootstrapTooltip,
} from 'react-bootstrap';
import styled, { CSSProperties } from 'styled-components';

import {
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '../GenericFormattedMessage';

export interface TooltipProps {
  /** the contents of the tooltip */
  title: GenericMessageDescriptor;
  /** overlay trigger prop overrides */
  overlayProps?: Partial<OverlayTriggerProps>;
  /** style overrides */
  style?: CSSProperties;
  /** tooltip width override */
  width?: string;
}

const StyledTooltip = styled(BootstrapTooltip)<{
  /** Width of the tooltip */
  $width?: string;
}>`
  .tooltip-inner {
    border-radius: 0.7em;
    ${({ $width }) => ($width ? `min-width: ${$width};` : '')}
  }
`;

/**
 * Tooltip
 */
export const Tooltip: React.FC<TooltipProps> = ({
  title,
  children,
  overlayProps,
  style,
  width,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  // extra empty fragments needed for types
  return title ? (
    <OverlayTrigger
      flip
      trigger={['hover', 'focus']}
      overlay={
        <StyledTooltip $width={width}>
          {formatMessageGeneric(title)}
        </StyledTooltip>
      }
      delay={200}
      {...overlayProps}
    >
      <div
        style={{
          color: 'unset',
          fontWeight: 'unset',
          display: 'inline-block',
          ...style,
        }}
      >
        {children as any}
      </div>
    </OverlayTrigger>
  ) : (
    <>{children}</>
  );
};

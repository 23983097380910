import { RequestAction } from '@transcend-io/privacy-types';

import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';
import { PluginType } from '@main/sombra-types';

import { Catalog } from './catalog';
import {
  ConnectorType,
  ConsentManagerConnectionStatus,
  HasPersonalDataStatus,
  PrivacyRequestConnectionStatus,
  SaaSCategoryOrderField,
} from './enums';
import { SaaSCategoryBase } from './saaSCategoryBase';

export const SaaSCategory = mkType({
  name: 'SaaSCategory',
  comment:
    'A classification of integration. All integrations of this class are shown together',
  fields: {
    ...SaaSCategoryBase.fields,
    catalogs: {
      comment: 'The Catalogs that fall into the SaaSCategory',
      type: Catalog,
      list: true,
    },
  },
});

/** Override type */
export type SaaSCategory = SchemaToType<typeof SaaSCategory>;

export const SaaSCategoryFiltersInput = mkInput({
  name: 'SaaSCategoryFiltersInput',
  comment: 'Inputs for determining what saaSCategories to filter',
  fields: {
    text: {
      comment: 'Filter by text (title of integration)',
      type: 'string',
      optional: true,
    },
    categoryText: {
      comment: 'Filter by text at the category level (title of saas category)',
      type: 'string',
      optional: true,
    },
    saaSCategoryIds: {
      comment: 'Filter by category',
      type: 'id',
      modelName: 'saaSCategory',
      optional: true,
      list: true,
    },
    plugins: {
      comment: 'Filter by plugin type',
      optional: true,
      list: true,
      type: { PluginType },
    },
    connectorType: {
      comment: 'Filter by connector type',
      optional: true,
      type: { ConnectorType },
    },
    recommendedForConsent: {
      comment:
        'Filter by whether the integration is recommended for consent manager purposes',
      optional: true,
      type: { ConsentManagerConnectionStatus },
    },
    recommendedForPrivacy: {
      comment:
        'Filter by whether the integration is recommended for privacy request purposes',
      optional: true,
      type: { PrivacyRequestConnectionStatus },
    },
    hasPersonalData: {
      comment: 'Filter by whether the integration has personal data in it',
      optional: true,
      type: { HasPersonalDataStatus },
    },
    requestActions: {
      comment: 'Filter by the request action type supported',
      optional: true,
      type: { RequestAction },
      list: true,
    },
  },
});

/** Override type */
export type SaaSCategoryFiltersInput = SchemaToType<
  typeof SaaSCategoryFiltersInput
>;

export const SaaSCategoryNamesFiltersInput = mkInput({
  name: 'SaaSCategoryNamesFiltersInput',
  comment: 'Inputs for determining what saaSCategories to filter',
  fields: {
    text: SaaSCategoryFiltersInput.fields.text,
    plugins: SaaSCategoryFiltersInput.fields.plugins,
    connectorType: SaaSCategoryFiltersInput.fields.connectorType,
    recommendedForConsent:
      SaaSCategoryFiltersInput.fields.recommendedForConsent,
    recommendedForPrivacy:
      SaaSCategoryFiltersInput.fields.recommendedForPrivacy,
    hasPersonalData: SaaSCategoryFiltersInput.fields.hasPersonalData,
    requestActions: SaaSCategoryFiltersInput.fields.requestActions,
  },
});

/** Override type */
export type SaaSCategoryNamesFiltersInput = SchemaToType<
  typeof SaaSCategoryNamesFiltersInput
>;

/**
 * Order for a saaSCategories query
 */
export const SaaSCategoryOrder = mkOrder(
  SaaSCategory.name,
  SaaSCategoryOrderField,
);

/** Override type */
export type SaaSCategoryOrder = SchemaToType<typeof SaaSCategoryOrder>;

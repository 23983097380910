import React from 'react';

import { SvgProps } from '../enums';

export const FlagUG: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55087"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" rx="-1" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55087)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#FECA00"
      />
      <mask
        id="mask1_270_55087"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55087)">
        <rect width="16" height="2" fill="#272727" />
        <rect y="4" width="16" height="2" fill="#D74800" />
        <rect y="6" width="16" height="2" fill="#272727" />
        <rect y="10" width="16" height="2" fill="#D74800" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6.83981 4.16432L6.31082 4.33789L6.83981 4.38318C6.83981 4.38318 6.92533 4.59236 6.83981 4.75565C6.8181 4.79711 6.7621 4.85021 6.69062 4.918C6.48051 5.11725 6.13666 5.44333 6.13666 5.97312C6.13666 6.68315 7.50693 7.00509 7.50693 7.00509V7.34243C7.50693 7.34243 7.66598 7.44122 6.75857 7.44122C5.97165 7.44122 6.02087 7.73994 6.04781 7.90347C6.05194 7.92851 6.05554 7.95038 6.05554 7.96752C6.05554 8.09663 6.43376 7.83841 6.61081 7.58132C6.70401 7.44598 7.09429 7.45313 7.49485 7.49025C7.52647 7.49318 7.81853 8.11185 7.56498 8.43145C7.45846 8.56571 7.09062 8.5832 6.86081 8.59413C6.74362 8.5997 6.66233 8.60357 6.66987 8.62034C6.67552 8.63292 6.74483 8.62878 6.84557 8.62277C6.9713 8.61526 7.14599 8.60484 7.30699 8.62034C7.33143 8.62269 7.23667 8.70493 7.14086 8.78809C7.04219 8.87374 6.9424 8.96036 6.97052 8.96168C7.00213 8.96317 7.18692 8.86081 7.35479 8.76781C7.49368 8.69087 7.62099 8.62034 7.64041 8.62034C7.76709 8.62034 7.88148 8.63597 7.98181 8.64968C8.14951 8.67259 8.27793 8.69014 8.35879 8.62034C8.38368 8.59885 8.23933 8.56427 8.09216 8.52901C7.94054 8.49268 7.78591 8.45564 7.81028 8.43145C7.94965 8.29313 7.89143 8.00322 7.84557 7.77489C7.825 7.67246 7.80692 7.58241 7.81028 7.52401C7.82221 7.31662 8.04157 7.42541 8.21398 7.5109C8.28948 7.54834 8.35597 7.58132 8.3921 7.58132C8.71859 7.58132 8.31319 7.16837 8.09067 6.94171C8.07117 6.92185 8.05307 6.90341 8.037 6.88681C7.92384 6.76997 8.00322 6.78567 8.18263 6.82115C8.31955 6.84822 8.51471 6.88681 8.727 6.88681C9.21749 6.88681 9.57548 6.72747 9.45112 6.29601C9.36907 6.01135 8.91985 5.9145 8.45052 5.8133C8.20846 5.76111 7.96104 5.70777 7.7559 5.62691C7.25118 5.42797 7.27692 5.13792 7.29868 4.89267C7.30291 4.84497 7.30699 4.79896 7.30699 4.75565C7.30699 4.48965 7.7559 4.16432 7.7559 4.16432C7.7559 4.16432 7.55103 3.82104 7.21129 3.82104C6.87155 3.82104 6.83981 4.16432 6.83981 4.16432ZM7.76391 7.28824V7.03736C7.76391 7.03736 7.97369 7.03044 8.04726 7.20692C8.12083 7.3834 7.76391 7.28824 7.76391 7.28824Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.53845 3.35564L7.04523 3.95661L7.30768 3.89488L7.18182 3.28821L6.53845 3.35564Z"
          fill="#D74800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.19639 3.21032L7.22906 4.11385L7.6618 4.00737L7.84608 3.41561L7.19639 3.21032Z"
          fill="#FFD018"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6.78849 4.71946C6.88408 4.71946 6.96157 4.64391 6.96157 4.55071C6.96157 4.45751 6.88408 4.38196 6.78849 4.38196C6.69291 4.38196 6.61542 4.45751 6.61542 4.55071C6.61542 4.64391 6.69291 4.71946 6.78849 4.71946Z"
          fill="#D74800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.88824 3.37957L7.3844 3.98301L7.49077 4.23075L8.06637 4.00145L7.88824 3.37957Z"
          fill="#D74800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M9.05935 7.94654C9.05935 7.94654 9.94557 7.55065 9.94557 6.997C9.94557 6.44335 8.50656 5.84027 8.50656 5.84027C8.50656 5.84027 7.6601 6.034 8.04164 6.29325C8.42317 6.55251 8.93433 6.6521 8.93433 6.997C8.93433 7.3419 9.07437 7.67077 8.79047 7.67077C8.50656 7.67077 9.05935 7.94654 9.05935 7.94654Z"
          fill="#D74800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.78079 5.73907C7.78079 5.73907 7.46736 5.79413 7.48224 5.84504C7.52338 5.98578 7.95195 6.16909 8.13874 6.29601C8.52027 6.55526 8.882 6.52614 9.1511 6.35742C9.42021 6.1887 7.78079 5.73907 7.78079 5.73907Z"
          fill="#FFFDFF"
        />
      </g>
    </g>
  </svg>
);

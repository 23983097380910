/**
 * keys for the form validators
 */
export enum ValidatorKey {
  CustomRegex = 'CUSTOM_REGEX',
  DateStr = 'DATE_STR',
  Email = 'EMAIL',
  MultiEmail = 'MULTI_EMAIL',
  MaxLength = 'MAX_LENGTH',
  MinLength = 'MIN_LENGTH',
  Compare = 'COMPARE',
  NotOneOf = 'NOT_ONE_OF',
  OneOf = 'ONE_OF',
  Password = 'PASSWORD',
  PositiveInteger = 'POSITIVE_INTEGER',
  Integer = 'INTEGER',
  Required = 'REQUIRED',
  SocialSecurity = 'SOCIAL_SECURITY',
  Telephone = 'TELEPHONE',
  Uri = 'URI',
  Url = 'URL',
  AnyUrl = 'ANY_URL',
  Domain = 'DOMAIN',
  DomainWithoutPort = 'DOMAIN_WITHOUT_PORT',
  Host = 'HOST',
  HtmlStringHasContents = 'HTML_STRING_HAS_CONTENTS',
  Custom = 'CUSTOM',
  UUIDv4 = 'UUID_V4',
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Folder: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.6667 5.12675H8.35267C8.132 5.12675 7.92533 5.01741 7.80133 4.83475L6.86467 3.45808C6.74067 3.27608 6.53467 3.16675 6.314 3.16675H3.33333C2.59667 3.16675 2 3.76341 2 4.50008V12.5001C2 13.2367 2.59667 13.8334 3.33333 13.8334H12.6667C13.4033 13.8334 14 13.2367 14 12.5001V6.46008C14 5.72408 13.4033 5.12675 12.6667 5.12675Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

/**
 * Shapes icon
 */
export const Shapes: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="3.64584"
      cy="10.0624"
      r="2.47917"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="8.16667"
      y="7.72925"
      width="4.66667"
      height="4.66667"
      rx="1"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.478 6.1251H5.10534C4.84031 6.12511 4.59615 5.98131 4.46763 5.74953C4.33912 5.51774 4.3465 5.23448 4.4869 5.00969L6.67323 1.50964C6.80646 1.29634 7.04019 1.16675 7.29168 1.16675C7.54316 1.16675 7.7769 1.29634 7.91012 1.50964L10.0965 5.00969C10.2369 5.23448 10.2442 5.51775 10.1157 5.74953C9.9872 5.98132 9.74303 6.12512 9.478 6.1251Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

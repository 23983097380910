import React from 'react';

import { SvgProps } from '../enums';

export const FlagGR: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55037"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55037)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <rect x="0.0136108" y="2.75" width="16" height="1.5" fill="#4564F9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V1.5H0V0Z"
        fill="#4564F9"
      />
      <rect x="-0.0294189" y="5.5" width="16" height="1.5" fill="#4564F9" />
      <rect x="0.0558472" y="8.2" width="16" height="1.5" fill="#4564F9" />
      <rect x="0.0507812" y="10.75" width="16" height="1.5" fill="#4564F9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H8V7H0V0Z"
        fill="#4564F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23554 0H4.81769V2.75H8V4.64319H4.81769V7.5H3.23554V4.64319H0V2.75H3.23554V0Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagBN: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54960"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54960)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#FFD221"
      />
      <mask
        id="mask1_270_54960"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54960)">
        <path
          d="M-1.53351 5.30761L-0.529513 3.01807L16.8838 10.6541L15.8798 12.9436L-1.53351 5.30761Z"
          fill="#313131"
        />
        <path
          d="M-1.50861 2.78478L-0.505032 0.495056L17.7743 8.50677L16.7707 10.7965L-1.50861 2.78478Z"
          fill="#FAF9F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.00003 1.44366C7.72388 1.44366 7.50003 1.66752 7.50003 1.94366V3.758C7.32953 3.67471 7.10889 3.59999 6.86596 3.59999C6.31712 3.59999 5.70003 4.36797 5.70003 4.36797C5.70003 4.36797 6.71968 4.3957 7.50003 5.16988V7.94356C6.63697 7.71538 6.26627 6.83664 6.36367 5.04061L4.86587 4.95938C4.70949 7.84297 5.77451 9.5051 8.02088 9.5051C10.2557 9.5051 11.3576 7.8581 11.2831 4.98057L9.78357 5.01942C9.8311 6.8535 9.41256 7.74317 8.50003 7.95461V5.07578C9.26463 4.39341 10.2 4.36797 10.2 4.36797C10.2 4.36797 9.58293 3.59999 9.03409 3.59999C8.83628 3.59999 8.65324 3.64953 8.50003 3.7129V1.94366C8.50003 1.66752 8.27617 1.44366 8.00003 1.44366ZM2.91004 5.49183C2.6384 5.44213 2.45849 5.18164 2.50819 4.91C2.55789 4.63837 2.81838 4.45846 3.09002 4.50816C4.08899 4.69094 4.63205 5.3859 4.63205 6.44866V8.474C4.63205 8.75015 4.40819 8.974 4.13205 8.974C3.85591 8.974 3.63205 8.75015 3.63205 8.474V6.44866C3.63205 5.85603 3.42043 5.58522 2.91004 5.49183ZM13.5238 4.91C13.5735 5.18164 13.3936 5.44213 13.122 5.49183C12.6116 5.58522 12.3999 5.85603 12.3999 6.44866V8.474C12.3999 8.75015 12.1761 8.974 11.8999 8.974C11.6238 8.974 11.3999 8.75015 11.3999 8.474V6.44866C11.3999 5.3859 11.943 4.69094 12.942 4.50816C13.2136 4.45846 13.4741 4.63837 13.5238 4.91ZM4.87063 9.21974L5.69704 8.65667C6.16004 9.33621 6.90796 9.67745 8.00003 9.67745C9.09923 9.67745 9.79285 9.41179 10.1332 8.91879L10.9561 9.48688C10.3994 10.2934 9.39662 10.6775 8.00003 10.6775C6.59629 10.6775 5.53342 10.1925 4.87063 9.21974Z"
          fill="#CF1225"
        />
      </g>
    </g>
  </svg>
);

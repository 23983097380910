import { TeamPreview, UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const RiskCategory = mkType({
  name: 'RiskCategory',
  comment: 'A type of risk within a risk framework',
  fields: {
    id: {
      comment: 'The id of the risk category',
      type: 'id',
      modelName: 'riskCategory',
    },
    title: {
      comment: 'The display name of the risk category',
      type: 'string',
    },
    description: {
      comment:
        'A description of what kinds of risk this risk category represents',
      type: 'string',
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this risk category',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this risk category',
      list: true,
      type: TeamPreview,
    },
  },
});

/** Override type */
export type RiskCategory = SchemaToType<typeof RiskCategory>;

export const RiskCategoriesFiltersInput = mkInput({
  name: 'RiskCategoriesFiltersInput',
  comment: 'Inputs for filtering a list of risk categories',
  fields: {
    ids: {
      comment: 'The ids of risk categories',
      type: 'id',
      modelName: 'riskCategory',
      optional: true,
      list: true,
    },
    text: {
      comment: 'The titles or descriptions of risk categories',
      optional: true,
      type: 'string',
    },
    riskFrameworkId: {
      comment: 'The id of the risk framework this risk category belongs to',
      type: 'id',
      modelName: 'riskFramework',
      optional: true,
    },
  },
});

/** Override type */
export type RiskCategoriesFiltersInput = SchemaToType<
  typeof RiskCategoriesFiltersInput
>;

export const CreateRiskCategoryInput = mkInput({
  name: 'CreateRiskCategoryInput',
  comment: 'Input for creating a risk category',
  fields: {
    title: RiskCategory.fields.title,
    description: {
      ...RiskCategory.fields.description,
      optional: true,
    },
  },
});

/** Override type */
export type CreateRiskCategoryInput = SchemaToType<
  typeof CreateRiskCategoryInput
>;

export const CreateRiskCategoriesInput = mkInput({
  name: 'CreateRiskCategoriesInput',
  comment: 'Input for creating multiple risk categories',
  fields: {
    riskFrameworkId: {
      comment: 'The id of the risk framework to create the risk categories in',
      type: 'id',
      modelName: 'riskFramework',
    },
    riskCategories: {
      comment: 'The risk categories to create.',
      type: CreateRiskCategoryInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateRiskCategoriesInput = SchemaToType<
  typeof CreateRiskCategoriesInput
>;

export const UpdateRiskCategoryWithinFrameworkInput = mkInput({
  name: 'UpdateRiskCategoryWithinFrameworkInput',
  comment:
    'Input for updating a risk category within the context of a risk framework',
  fields: {
    id: {
      comment: 'The id of the risk category',
      type: 'id',
      modelName: 'riskCategory',
      // Must be optional because you can also create new ones as you update the whole list
      optional: true,
    },
    title: CreateRiskCategoryInput.fields.title,
    description: CreateRiskCategoryInput.fields.description,
  },
});

/** Override type */
export type UpdateRiskCategoryWithinFrameworkInput = SchemaToType<
  typeof UpdateRiskCategoryWithinFrameworkInput
>;

export const UpdateRiskCategoryInput = mkInput({
  name: 'UpdateRiskCategoryInput',
  comment: 'Input for updating a risk category',
  fields: {
    id: UpdateRiskCategoryWithinFrameworkInput.fields.id,
    title: {
      ...UpdateRiskCategoryWithinFrameworkInput.fields.title,
      optional: true,
    },
    description: {
      ...UpdateRiskCategoryWithinFrameworkInput.fields.description,
      optional: true,
    },
    ownerIds: {
      comment:
        'The list of individual users who are responsible for managing this risk category',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    teamIds: {
      comment:
        'The list of teams who are responsible for managing this risk category',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateRiskCategoryInput = SchemaToType<
  typeof UpdateRiskCategoryInput
>;

export const UpdateRiskCategoriesInput = mkInput({
  name: 'UpdateRiskCategoriesInput',
  comment: 'Input for updating risk categories',
  fields: {
    riskCategories: {
      comment: 'The list of risk categories to update',
      type: UpdateRiskCategoryInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateRiskCategoriesInput = SchemaToType<
  typeof UpdateRiskCategoriesInput
>;

export const DeleteRiskCategoriesInput = mkInput({
  name: 'DeleteRiskCategoriesInput',
  comment: 'Input for deleting risk categories',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'riskCategory',
      list: true,
    },
  },
});

/** Override type */
export type DeleteRiskCategoriesInput = SchemaToType<
  typeof DeleteRiskCategoriesInput
>;

import { darken, lighten, transparentize } from 'polished';

import { PrivacyCenterConfigurableColorPalette } from '@transcend-io/privacy-types';

import { colorIsLight, FIXED_COLOR_LIST } from '@main/theme-types';

import { createColorDefaults } from './createColorDefaults';
import type { ColorShades, FixedColorPalette } from './theme';

/**
 * These are colors that are always fixed and cannot be customized
 */
export const FIXED_COLORS: FixedColorPalette = FIXED_COLOR_LIST;

/**
 * These are optional colors that can be calculated based on a required configurable color.
 *
 * If the colors are defined, they will not inherit from the parent.
 */
export const OPTIONAL_COLOR_DEFAULTS = createColorDefaults({
  // /////////// //
  // Configurable //
  // /////////// //

  heroBg: 'primary',
  sidebarNavBg: 'primary',
  widgetBg: 'secondary',

  // //// //
  // Text //
  // //// //

  /**
   * The snippet highlight color
   *
   * @param colors - The required colors
   * @returns The highlight color
   */
  highlight: ({ primary }) => transparentize(0.8, primary),
  /**
   * The color to outline tables
   *
   * @param colors - The required colors
   * @returns The highlight color
   */
  tableOutline: ({ primary }) => lighten(0.37, primary),
  /**
   * The main text color to use when text is shown on an object colored primary
   *
   * @param colors - The required colors
   * @returns The textOnPrimary color
   */
  textOnPrimary: ({ primary }) =>
    colorIsLight(primary) ? '#000000' : '#ffffff',
  /**
   * The main text color to use on the hero
   *
   * @param colors - The required colors
   * @returns The textOnHero color
   */
  textOnHero: ({ heroBg, primary }) =>
    colorIsLight(heroBg || primary) ? '#000000' : '#ffffff',
  /**
   * The main text color to use on the SideMenu
   *
   * @param colors - The required colors
   * @returns The textOnSidebar color
   */
  textOnSidebar: ({ sidebarNavBg, primary }) =>
    colorIsLight(sidebarNavBg || primary) ? '#000000' : '#ffffff',
  /**
   * The nav bar accent color to use on the SideMenu
   *
   * @param colors - The required colors
   * @returns The accentOnSidebar color
   */
  accentOnSidebar: ({ sidebarNavBg, primary }) =>
    colorIsLight(sidebarNavBg || primary) ? '#000000' : '#ffffff',
  /**
   * The main text color to use on About Transcend sidebar widget
   *
   * @param colors - The required colors
   * @returns The textOnAboutTranscend color
   */
  textOnAboutTranscend: ({ secondary }) =>
    colorIsLight(secondary) ? '#000000' : '#ffffff',
});

/**
 * These are colors that are calculated as a transformation of another color
 */
export const SHADE_TRANSFORMATIONS: {
  [k in keyof ColorShades]: (
    colors: Required<PrivacyCenterConfigurableColorPalette> & FixedColorPalette,
  ) => string;
} = {
  darkPrimary: ({ primary }) => darken(0.15, primary),
  lightPrimary: ({ primary }) => lighten(0.05, primary),
  successLight: ({ success }) => lighten(0.1, success),
  successDark: ({ success }) => darken(0.1, success),
};

/**
 * These color should not be defined as their default is to inherit
 */
type OptionalColorKeys = keyof typeof OPTIONAL_COLOR_DEFAULTS;

/**
 * Transcend's configurable color palette
 */
export const DEFAULT_CONFIGURABLE_COLORS: PrivacyCenterConfigurableColorPalette =
  {
    /** Used everywhere */
    primary: '#3333ff',
    /** Secondary CTA */
    secondary: '#1f222c',
  };

/**
 * The default color palette to use for the organization
 */
export const DEFAULT_CUSTOMIZABLE_COLORS: Omit<
  Required<PrivacyCenterConfigurableColorPalette>,
  OptionalColorKeys
> = {
  ...DEFAULT_CONFIGURABLE_COLORS,
  bgAccent: '#FAFAFA',
  textOnBg: '#444444',
  textLightOnBg: '#9e9e9e',
  error: '#E00000',
};

/** The sidebar hover transition */
export const SIDEBAR_TRANSITION = {
  transitionTimingFunction: 'ease-in-out',
  transitionDuration: '150ms',
  transitionDelay: '250ms',
};

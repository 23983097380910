import styled, { css } from 'styled-components';

/**
 * Container props
 */
export interface ContainerWrapperProps {
  /** Number of margin-top px */
  spacing?: number;
  /** Skip CSS */
  ignore?: boolean;
}

const SPACING = css<ContainerWrapperProps>`
  margin-top: ${({ spacing }) => spacing || 0}px;
`;

/**
 * The Container wrapper
 */
export const ContainerWrapper = styled.div<ContainerWrapperProps>`
  ${SPACING}
  ${({ ignore }) =>
    !ignore &&
    css`
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      @media (min-width: 768px) {
        max-width: 750px;
      }
      @media (min-width: 992px) {
        max-width: 970px;
      }
      @media (min-width: 1200px) {
        max-width: 1170px;
      }
    `}
`;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagGD: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55003"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55003)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V11C16 11.5523 15.5523 12 15 12H1C0.447715 12 0 11.5523 0 11V0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H13V9H3V3Z"
        fill="#FECA00"
      />
      <mask
        id="mask1_270_55003"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="10"
        height="6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3H13V9H3V3Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55003)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3L8 6L3 9V3Z"
          fill="#23875F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 3L8 6L13 9V3Z"
          fill="#23875F"
        />
        <mask
          id="mask2_270_55003"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="3"
          width="5"
          height="6"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 3L8 6L13 9V3Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_270_55003)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z"
          fill="#C51918"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.96473 6.65664L6.92927 7.37486L7.26014 6.14472L6.5 5.35891L7.52927 5.31642L7.96473 4.10001L8.40019 5.31642H9.42771L8.66932 6.14472L9.04913 7.30225L7.96473 6.65664Z"
          fill="#FECA00"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.38839 2.221L4.95428 1.8701L5.54691 2.18553L5.33934 1.61999L5.7538 1.21531H5.19226L4.95428 0.621002L4.7163 1.21531L4.1538 1.23607L4.56922 1.61999L4.38839 2.221ZM7.38839 2.221L7.95428 1.8701L8.54691 2.18553L8.33934 1.61999L8.7538 1.21531H8.19226L7.95428 0.621002L7.7163 1.21531L7.1538 1.23607L7.56922 1.61999L7.38839 2.221ZM10.9543 1.8701L10.3884 2.221L10.5692 1.61999L10.1538 1.23607L10.7163 1.21531L10.9543 0.621002L11.1923 1.21531H11.7538L11.3393 1.61999L11.5469 2.18553L10.9543 1.8701ZM4.38792 11.0836L4.9538 10.7327L5.54643 11.0481L5.33886 10.4826L5.75332 10.0779H5.19178L4.9538 9.48357L4.71582 10.0779L4.15332 10.0986L4.56874 10.4826L4.38792 11.0836ZM7.9538 10.7327L7.38792 11.0836L7.56874 10.4826L7.15332 10.0986L7.71582 10.0779L7.9538 9.48357L8.19178 10.0779H8.75332L8.33886 10.4826L8.54643 11.0481L7.9538 10.7327ZM10.3879 11.0836L10.9538 10.7327L11.5464 11.0481L11.3389 10.4826L11.7533 10.0779H11.1918L10.9538 9.48357L10.7158 10.0779L10.1533 10.0986L10.5687 10.4826L10.3879 11.0836Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.31743 6.386C4.31743 6.386 4.58671 6.62572 4.73337 6.70734C4.78394 6.51568 4.67111 6.08146 4.67111 6.08146C4.63945 5.97766 4.29221 5.79156 4.29221 5.79156C4.29221 5.79156 4.19467 6.11924 4.31743 6.386Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.5979 6.96509C4.5979 6.96509 3.90628 6.64206 3.836 6.18516C3.76572 5.72826 3.91477 4.99988 3.91477 4.99988C3.91477 4.99988 5.03787 5.25546 5.11428 5.80306C5.19069 6.35067 4.93702 6.69468 4.93702 6.69468C4.93702 6.69468 4.67725 6.02714 4.43211 5.95309C4.43211 5.95309 4.43211 6.61467 4.5979 6.96509Z"
        fill="#FECA00"
      />
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagSK: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55054"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55054)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#3D58DB"
      />
      <mask
        id="mask1_270_55054"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55054)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#E31D1C"
        />
        <path
          // eslint-disable-next-line max-len
          d="M7.80202 3.0152L8.05062 2.98875L8.02683 2.7652H7.80202H3.17247H2.93877L2.92304 2.99838L3.17247 3.0152L2.92304 2.99842L2.92303 2.99853L2.923 2.99893L2.9229 3.00046L2.9225 3.00643L2.92098 3.02955L2.91531 3.11765C2.91048 3.19384 2.90371 3.30347 2.89592 3.43744C2.88033 3.70532 2.86064 4.07093 2.84423 4.46151C2.81189 5.23131 2.79045 6.1325 2.84645 6.55337C2.95574 7.3746 3.45581 9.21817 5.38997 10.0536L5.49084 10.0972L5.59115 10.0524C7.42826 9.23091 8.04269 7.5766 8.18442 6.55473C8.25595 6.03901 8.2196 5.13659 8.16939 4.38669C8.14394 4.00668 8.11428 3.65751 8.09099 3.40341C8.07933 3.27631 8.06927 3.17288 8.0621 3.10111L8.05369 3.0182L8.05142 2.99642L8.05083 2.99077L8.05067 2.9893L8.05063 2.98891L8.05062 2.9888L7.80202 3.0152Z"
          fill="#E31D1C"
          stroke="white"
          strokeWidth="0.5"
        />
        <mask
          id="mask2_270_55054"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="7"
          height="9"
        >
          <path
            // eslint-disable-next-line max-len
            d="M7.80202 3.0152L8.05062 2.98875L8.02683 2.7652H7.80202H3.17247H2.93877L2.92304 2.99838L3.17247 3.0152L2.92304 2.99842L2.92303 2.99853L2.923 2.99893L2.9229 3.00046L2.9225 3.00643L2.92098 3.02955L2.91531 3.11765C2.91048 3.19384 2.90371 3.30347 2.89592 3.43744C2.88033 3.70532 2.86064 4.07093 2.84423 4.46151C2.81189 5.23131 2.79045 6.1325 2.84645 6.55337C2.95574 7.3746 3.45581 9.21817 5.38997 10.0536L5.49084 10.0972L5.59115 10.0524C7.42826 9.23091 8.04269 7.5766 8.18442 6.55473C8.25595 6.03901 8.2196 5.13659 8.16939 4.38669C8.14394 4.00668 8.11428 3.65751 8.09099 3.40341C8.07933 3.27631 8.06927 3.17288 8.0621 3.10111L8.05369 3.0182L8.05142 2.99642L8.05083 2.99077L8.05067 2.9893L8.05063 2.98891L8.05062 2.9888L7.80202 3.0152Z"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
          />
        </mask>
        <g mask="url(#mask2_270_55054)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.93124 3.29492C4.93124 3.29492 5.03448 3.45223 5.0807 3.69277C5.12692 3.93332 5.13489 4.20695 5.13489 4.20695L4.35285 4.08636V4.97683L5.15677 4.8493L5.13273 5.78307C5.13273 5.78307 5.03057 5.81413 4.78488 5.78307C4.53919 5.75202 4.05518 5.62192 4.05518 5.62192V6.47211C4.05518 6.47211 4.54693 6.31479 4.78488 6.27869C5.02283 6.24259 5.13273 6.27869 5.13273 6.27869L5.1405 7.09395H5.93786L5.93009 6.27869C5.93009 6.27869 6.18103 6.24006 6.44611 6.28188C6.7112 6.3237 6.96265 6.47211 6.96265 6.47211V5.62192C6.96265 5.62192 6.7079 5.74884 6.46763 5.78042C6.22735 5.812 5.93009 5.78307 5.93009 5.78307L5.95414 4.8493C5.95414 4.8493 6.09331 4.81487 6.27992 4.8402C6.46652 4.86553 6.70008 4.97683 6.70008 4.97683V4.08636C6.70008 4.08636 6.47244 4.21023 6.29333 4.2327C6.11421 4.25517 5.93225 4.20695 5.93225 4.20695C5.93225 4.20695 5.93891 3.91984 5.97742 3.70806C6.01594 3.49628 6.125 3.29492 6.125 3.29492H4.93124Z"
            fill="#F7FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M3.44873 8.12268C3.44873 8.12268 3.66848 7.65105 4.02604 7.56137C4.38361 7.47169 4.75855 7.84203 4.75855 7.84203C4.75855 7.84203 4.9875 7.09381 5.54555 7.09381C6.10361 7.09381 6.29946 7.84203 6.29946 7.84203C6.29946 7.84203 6.53755 7.56137 6.87994 7.56137C7.22233 7.56137 7.53955 8.12268 7.53955 8.12268C7.53955 8.12268 6.51684 9.97939 5.49414 9.97939C4.47143 9.97939 3.44873 8.12268 3.44873 8.12268Z"
            fill="#2E42A5"
          />
        </g>
      </g>
    </g>
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const SidebarConsent: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0004 8.99874H17.0021"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M12 21.0037H7.99833C5.23576 21.0037 2.99625 18.7642 2.99625 16.0017V7.99833C2.99625 5.23576 5.23576 2.99625 7.99833 2.99625H16.0017C18.7642 2.99625 21.0037 5.23576 21.0037 7.99833V10.9996"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 8.72463L7.9173 9.55297L9.76807 7.88628"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 13.7267L7.9173 14.5551L9.76807 12.8884"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M14.0008 15.6969V16.6873C14.0008 18.5117 15.1326 20.1448 16.8408 20.7854L17.3486 20.9758C17.4477 21.013 17.5569 21.013 17.656 20.9758L18.1637 20.7854C19.872 20.1448 21.0037 18.5118 21.0037 16.6873V15.6969C21.0037 15.314 20.7549 14.9756 20.3895 14.8614L17.7634 14.0407C17.5934 13.9876 17.4112 13.9876 17.2412 14.0407L14.6151 14.8614C14.2497 14.9756 14.0008 15.314 14.0008 15.6969V15.6969Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagKE: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55036"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55036)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#0067C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4H16V0H0Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V12H16V8H0Z"
        fill="#4E8B1D"
      />
      <path
        d="M0 3.5H-0.5V4V8V8.5H0H16H16.5V8V4V3.5H16H0Z"
        fill="#E31D1C"
        stroke="#F7FCFF"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M9.81171 2.01525C9.92589 1.80988 10.2329 1.58563 10.7327 1.34247C10.7498 1.33412 10.7706 1.33364 10.7882 1.34119C10.8181 1.35397 10.8305 1.38558 10.8158 1.41171L5.63701 10.7258L5.36737 10.6104L9.93242 2.40014C9.7257 2.3706 9.68546 2.2423 9.81171 2.01525Z"
      fill="white"
    />
    <path
      // eslint-disable-next-line max-len
      d="M6.18277 2.01525C6.30901 2.2423 6.26877 2.3706 6.06205 2.40014L10.6271 10.6104L10.3575 10.7258L5.17869 1.41171C5.16396 1.38558 5.17637 1.35397 5.20624 1.34119C5.22389 1.33364 5.24463 1.33412 5.26181 1.34247C5.76159 1.58563 6.06858 1.80988 6.18277 2.01525Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8 10.4737C8.59803 10.4737 11 8.58857 11 6.26316C11 3.93775 8.59803 2.05263 8 2.05263C7.40197 2.05263 5 3.93775 5 6.26316C5 8.58857 7.40197 10.4737 8 10.4737Z"
      fill="#E31D1C"
    />
    <mask
      id="mask1_270_55036"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="2"
      width="6"
      height="9"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8 10.4737C8.59803 10.4737 11 8.58857 11 6.26316C11 3.93775 8.59803 2.05263 8 2.05263C7.40197 2.05263 5 3.93775 5 6.26316C5 8.58857 7.40197 10.4737 8 10.4737Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_270_55036)">
      <path
        // eslint-disable-next-line max-len
        d="M2.49999 9.94737C4.81958 9.94737 6.69999 8.29789 6.69999 6.26316C6.69999 4.22843 4.81958 2.57895 2.49999 2.57895C0.180392 2.57895 -1.70001 4.22843 -1.70001 6.26316C-1.70001 8.29789 0.180392 9.94737 2.49999 9.94737Z"
        fill="#272727"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.5 9.94737C15.8196 9.94737 17.7 8.29789 17.7 6.26316C17.7 4.22843 15.8196 2.57895 13.5 2.57895C11.1804 2.57895 9.29999 4.22843 9.29999 6.26316C9.29999 8.29789 11.1804 9.94737 13.5 9.94737Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.12002 5.69999C8.39389 5.52932 8.60002 4.78593 8.60002 3.89474C8.60002 3.00354 8.39389 2.26016 8.12002 2.08948V5.69999ZM7.82002 5.65251C7.5766 5.41778 7.40002 4.71957 7.40002 3.89474C7.40002 3.06991 7.5766 2.3717 7.82002 2.13696V5.65251ZM7.82002 6.87381V10.3894C7.5766 10.1546 7.40002 9.45641 7.40002 8.63158C7.40002 7.80675 7.5766 7.10854 7.82002 6.87381ZM8.12002 10.4368V6.82632C8.39389 6.997 8.60002 7.74039 8.60002 8.63158C8.60002 9.52278 8.39389 10.2662 8.12002 10.4368Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.00002 5.73684C8.3314 5.73684 8.60002 5.97248 8.60002 6.26316C8.60002 6.55383 8.3314 6.78947 8.00002 6.78947C7.66865 6.78947 7.40002 6.55383 7.40002 6.26316C7.40002 5.97248 7.66865 5.73684 8.00002 5.73684Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

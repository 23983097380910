import { mkType, SchemaToType } from '@main/schema-utils';

export const SlackWorkspaceMetadataChannel = mkType({
  name: 'SlackWorkspaceMetadataChannel',
  comment: 'A channel in a slack workspace',
  fields: {
    id: {
      comment: 'The channel id',
      type: 'string',
    },
    name: {
      comment: 'The channel name',
      type: 'string',
    },
  },
});

/** Override type */
export type SlackWorkspaceMetadataChannel = SchemaToType<
  typeof SlackWorkspaceMetadataChannel
>;

export const SlackWorkspaceMetadata = mkType({
  name: 'SlackWorkspaceMetadata',
  comment: 'Metadata about a connected slack workspace',
  fields: {
    team: {
      comment: 'The team name',
      type: 'string',
    },
    teamId: {
      comment: 'The id of the team',
      type: 'string',
    },
    channels: {
      comment: 'The channels in the workspace',
      type: SlackWorkspaceMetadataChannel,
      list: true,
    },
  },
});

/** Override type */
export type SlackWorkspaceMetadata = SchemaToType<
  typeof SlackWorkspaceMetadata
>;

export const SlackUserMetadata = mkType({
  name: 'SlackUserMetadata',
  comment: 'Metadata about a connected Slack user',
  fields: {
    username: {
      comment: 'The slack username',
      type: 'string',
    },
    fullName: {
      comment: "The user's full name",
      type: 'string',
    },
    profilePicture: {
      comment: "The URL of the user's profile picture",
      type: 'string',
    },
  },
});

/** Override type */
export type SlackUserMetadata = SchemaToType<typeof SlackUserMetadata>;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagIN: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55020"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55020)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55020"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55020)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#FF8C1A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#5EAA22"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6 6C6 7.10457 6.89543 8 8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6ZM9.5 6C9.5 6.82843 8.82843 7.5 8 7.5C7.17157 7.5 6.5 6.82843 6.5 6C6.5 5.17157 7.17157 4.5 8 4.5C8.82843 4.5 9.5 5.17157 9.5 6Z"
          fill="#3D58DB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.99722 6.43035L7.58025 7.96722L7.82443 6.39363L6.81841 7.62803L7.68151 6.28979L6.26039 7.00829L7.59318 6.1368L6.00269 6.21516L7.57472 5.96112L6.08986 5.38578L7.62931 5.79311L6.50683 4.66357L7.74751 5.66183L7.1815 4.17339L7.9089 5.58998L7.99722 4L8.08555 5.58998L8.81294 4.17339L8.24693 5.66183L9.48762 4.66357L8.36514 5.79311L9.90459 5.38578L8.41973 5.96112L9.99176 6.21516L8.40126 6.1368L9.73406 7.00829L8.31293 6.28979L9.17604 7.62803L8.17002 6.39363L8.41419 7.96722L7.99722 6.43035Z"
          fill="#3D58DB"
        />
      </g>
    </g>
  </svg>
);

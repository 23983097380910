import { endpoints, EnricherPreview } from '@main/datamap-types';
import { EnricherType } from '@transcend-io/privacy-types';
import React, { useMemo, useState } from 'react';

import { buildUseInfiniteScroll } from '../hooks';
import {
  RawPaginatedSelect,
  ReactSelectExtendedProps,
  SelectTagWrapper,
} from '../RawPaginatedSelect';
import { SELECT_ENRICHERS_FIELDS } from './constants';
import { selectEnrichersMessages } from './messages';

const usePossibleEnrichers = buildUseInfiniteScroll(
  endpoints.enrichers,
  'SelectEnrichers',
  {
    totalCount: null,
    nodes: SELECT_ENRICHERS_FIELDS,
  },
);

/** Props for the `SelectRegion` component */
export interface SelectEnrichersProps<IsMulti extends boolean>
  extends Omit<
    ReactSelectExtendedProps<IsMulti, EnricherPreview>,
    'isCreatable' | 'value' | 'onChange'
  > {
  /** The selected enrichers */
  value?: EnricherPreview[];
  /** Callback to fire when the enricher is updated */
  onChange: (selected: EnricherPreview[]) => void;
  /** Style */
  style?: React.CSSProperties;
  /** Current enricher ID, if applicable */
  enricherId?: string;
}

/**
 * Component to select one or more enrichers
 */
export function SelectEnrichers<IsMulti extends boolean>({
  value,
  isLoading,
  menuPosition,
  showOutline = false,
  onChange,
  style,
  enricherId,
  ...props
}: SelectEnrichersProps<IsMulti>): JSX.Element {
  const [searchText, setSearchText] = useState('');

  const { data, fetchMore } = usePossibleEnrichers({
    variables: {
      filterBy: {
        ...(searchText ? { text: searchText } : {}),
        type: Object.values(EnricherType).filter(
          (enricher) => enricher !== EnricherType.Sombra,
        ),
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const options = useMemo(() => {
    if (data) {
      return data.nodes.filter(({ id }) => id !== enricherId);
    }

    return [];
  }, [data, enricherId]);

  return (
    <SelectTagWrapper>
      <RawPaginatedSelect
        isCreatable={false}
        menuPosition={menuPosition}
        showOutline={showOutline}
        options={options}
        placeholderDescriptor={selectEnrichersMessages.enricherSelect}
        notFoundDescriptor={selectEnrichersMessages.enricherSelectNoMatches}
        loadingDescriptor={selectEnrichersMessages.enricherSelectLoading}
        isQueryLoading={isLoading || false}
        getNextPage={() => fetchMore({})}
        isMulti
        isClearable
        onEndsTyping={(searchText) => setSearchText(searchText)}
        getOptionValue={({ id }) => id}
        getOptionLabel={({ title }) => title}
        getOptionLogo={({ logo }) => logo}
        value={value && value.length > 0 ? value : undefined}
        style={style}
        onChange={(selected) =>
          !selected
            ? onChange([])
            : Array.isArray(selected)
              ? onChange([...selected])
              : onChange([selected] as unknown as EnricherPreview[])
        }
        {...props}
      />
    </SelectTagWrapper>
  );
}

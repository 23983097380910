import { createHandlebarsWithHelpers } from '@transcend-io/handlebars-utils';

import {
  DatabaseColumnType,
  DEFAULT_SQL_QUERY_TRANSFORMATIONS_BY_DATA_TYPE,
} from '@main/sombra-types';

export interface DataPointQueryInterface {
  /** Name of datapoint, aka the table name */
  name: string;
  /** Path to datapoint */
  path?: string[];
}

/**
 * Creates the handlebars instance for sending text codes
 */
export const identifierHandlebars = createHandlebarsWithHelpers();

/**
 * Create the SQL statement for selecting the database
 * table related to a certain datapoint.
 *
 * @param dataPoint - Datapoint
 * @returns Path to datapoint
 */
export function dataPointToSchemaPath({
  path = [],
  name,
}: DataPointQueryInterface): string {
  return (
    [...path, name]
      // handle invalid whitespace
      .map((x) => x.trim())
      .join('.')
  );
}

export interface DataPointAccessRequestQueryInterface
  extends DataPointQueryInterface {
  /** The total count of subdatapoints  */
  subDataPointCount: number;
}

/**
 * Create the default access request SQL statement
 *
 * @param dataPoint - The datapoint to generate SQL statement for
 * @param enabledSubDataPoints - Subdatapoints to return
 * @param identifierColumns - Columns to look up by
 * @returns The SQL statement
 */
export function createDefaultAccessRequestQuery(
  dataPoint: DataPointAccessRequestQueryInterface,
  enabledSubDataPoints: {
    /** Name of the sub datapoint */
    name: string;
    /** Datatype */
    dataType: DatabaseColumnType;
  }[],
  identifierColumns: {
    /** Name of column to key by */
    name: string;
    /** The name of the identifier */
    identifierName: string;
    /** The SQL variable transformation */
    sqlVariable: string;
    /** The SQL lookup variable */
    sqlLookupVariable: string;
  }[],
): string {
  // Grab the path to the table
  const path = dataPointToSchemaPath(dataPoint);
  const sortedSubDataPoints = enabledSubDataPoints.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const sortedIdentifiers = identifierColumns.sort((a, b) =>
    a.identifierName.localeCompare(b.identifierName),
  );

  // determine the fields selected
  const hasTransformations = sortedSubDataPoints.some(
    ({ dataType }) => DEFAULT_SQL_QUERY_TRANSFORMATIONS_BY_DATA_TYPE[dataType],
  );
  const selected =
    sortedSubDataPoints.length === dataPoint.subDataPointCount &&
    !hasTransformations
      ? '*'
      : sortedSubDataPoints
          .map(
            ({ name, dataType }) =>
              // transform column if needed
              DEFAULT_SQL_QUERY_TRANSFORMATIONS_BY_DATA_TYPE[dataType]?.(
                name,
              ) || name,
          )
          .join(',');
  return `SELECT ${selected} FROM ${path} WHERE ${sortedIdentifiers
    .map(
      ({ name, sqlVariable, sqlLookupVariable }) =>
        `${identifierHandlebars.compile(sqlVariable)({
          column: name,
        })} = ${sqlLookupVariable}`,
    )
    .join(' OR ')}`;
}

export interface DataPointErasureRequestQueryInterface
  extends DataPointQueryInterface {
  /** The total count of subdatapoints  */
  subDataPointCount: number;
}

/**
 * Create the default erasure request SQL statement
 *
 * @param dataPoint - The datapoint to generate SQL statement for
 * @param redactedSubDataPoints - Subdatapoints to redact
 * @param identifierColumns - Columns to look up by
 * @returns The SQL statement
 */
export function createDefaultErasureRequestQuery(
  dataPoint: DataPointErasureRequestQueryInterface,
  redactedSubDataPoints: string[],
  identifierColumns: {
    /** Name of column to key by */
    name: string;
    /** The name of the identifier */
    identifierName: string;
    /** SQL variable */
    sqlVariable: string;
    /** SQL lookup variable */
    sqlLookupVariable: string;
  }[],
): string {
  const path = dataPointToSchemaPath(dataPoint);
  const sortedSubDataPoints = redactedSubDataPoints.sort();
  const sortedIdentifiers = identifierColumns.sort((a, b) =>
    a.identifierName.localeCompare(b.identifierName),
  );
  const shouldHardDelete = sortedSubDataPoints.length === 0;
  const whereClause = sortedIdentifiers
    .map(
      ({ name, sqlLookupVariable, sqlVariable }) =>
        `${identifierHandlebars.compile(sqlVariable)({
          column: name,
        })} = ${sqlLookupVariable}`,
    )
    .join(' OR ');
  if (shouldHardDelete) {
    return `DELETE FROM ${path} WHERE ${whereClause}`;
  }

  const redactions = sortedSubDataPoints
    .map((name) => `${name}=null`)
    .join(', ');
  return `UPDATE ${path} SET ${redactions} WHERE ${whereClause}`;
}

import { makeEnum } from '@transcend-io/type-utils';

/** Status of whether a subdatapoint sample can be refetched */
export const RefetchSampleMethod = makeEnum({
  /** Regular refetch where no filter needed  */
  Default: 'DEFAULT',
  /** Refetch where not-null filter needed */
  FilterNonNull: 'FILTER_NON_NULL',
});

/** Type override */
export type RefetchSampleMethod =
  (typeof RefetchSampleMethod)[keyof typeof RefetchSampleMethod];

export const Controllership = makeEnum({
  /** The current organization is a controller of the data */
  Controller: 'CONTROLLER',
  /** The current organization is a processor of the data */
  Processor: 'PROCESSOR',
  /** The current organization is a joint controller of the data */
  JointController: 'JOINT_CONTROLLER',
});

/** Type override */
export type Controllership =
  (typeof Controllership)[keyof typeof Controllership];

/** The type of retention schedule for personal data */
export const RetentionType = makeEnum({
  /** Collected user information is deleted, anonymized or aggregated after a specific time period */
  StatedPeriod: 'STATED_PERIOD',
  /** Data is deleted, anonymized, or aggregated at some point, but no specific retention period is stated */
  Limited: 'LIMITED',
  /** Collected user information is retained indefinitely */
  Indefinite: 'INDEFINITE',
  /** A specific retention type not covered above */
  Other: 'OTHER',
  /** Retention period is not stated or unclear */
  Unspecified: 'UNSPECIFIED',
});

/** Type override */
export type RetentionType = (typeof RetentionType)[keyof typeof RetentionType];

/** Summary states describing whether a subdatapoint has been classified, categorized, or neither */
export const SubDataPointClassificationStatus = makeEnum({
  /**
   * The subdatapoint has a category assigned - could be a confirmed classification,
   * a default categorization from contentful, or a category assigned by a user
   */
  Confirmed: 'CONFIRMED',
  /** The subdatapoint has at least one guess made for its categorization, but the guess has not been confirmed */
  Unconfirmed: 'UNCONFIRMED',
  /**
   * The subdatapoint has no categories (guesses or otherwise) assigned. This could be because the classifier has
   * not run, there is no classifier is enabled, or guesses were cleared with no new category chosen
   */
  Uncategorized: 'UNCATEGORIZED',
});

/** Type override */
export type SubDataPointClassificationStatus =
  (typeof SubDataPointClassificationStatus)[keyof typeof SubDataPointClassificationStatus];

import { DefinedMessage } from '@main/internationalization';
import { mkType, SchemaToType } from '@main/schema-utils';
import { FormAutoComplete, FormFormat } from '@main/utils';

import { FormType, ModeOption, SiloFormType, SnippetLanguage } from './enums';

/**
 * Form validation rule
 */
export const FormRule = mkType({
  name: 'FormRule',
  comment: 'A form item rule',
  fields: {
    message: {
      comment: 'The message to display when the rule is triggered',
      type: 'string',
    },
    pattern: {
      comment:
        'The match pattern RegExp as a string.  Will be converted to RegExp on frontend',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type FormRule = SchemaToType<typeof FormRule>;

/**
 * Configuration to show a snippet as a helper with forms on dashboard
 */
export const SnippetConfig = mkType({
  name: 'SnippetConfig',
  comment: 'A code snippet that is shown as form item helper text',
  fields: {
    language: {
      comment: 'The code language the snippet is in',
      type: {
        SnippetLanguage,
      },
    },
    snippet: {
      comment: 'The code snippet to show with the form item',
      type: 'string',
    },
  },
});

/** Override type */
export type SnippetConfig = SchemaToType<typeof SnippetConfig>;

/**
 * A select option
 */
export const SelectOption = mkType({
  name: 'SelectOption',
  comment: 'An option in a select form',
  fields: {
    name: {
      comment: 'The name of the form item value',
      type: 'string',
    },
    label: {
      comment: 'The form label message',
      type: DefinedMessage,
    },
  },
});

/** Override type */
export type SelectOption = SchemaToType<typeof SelectOption>;

/**
 * A form item
 */
export const FormItem = mkType({
  name: 'FormItem',
  comment:
    'A form item for the data subject to submit a DSR on the privacy center',
  fields: {
    id: {
      comment: 'The unique ID of the form item',
      modelName: 'formItem',
      type: 'id',
      optional: true,
    },
    name: {
      comment: 'The name of the form item value',
      type: 'string',
    },
    label: {
      comment: 'The form label message',
      type: DefinedMessage,
    },
    type: {
      comment: 'The HTML form type',
      optional: true,
      type: { FormType },
    },
    placeholder: {
      comment: 'The placeholder value for the form item',
      optional: true,
      type: DefinedMessage,
    },
    info: {
      comment: 'Additional info regarding the form item',
      optional: true,
      type: DefinedMessage,
    },
    isRequired: {
      comment: 'True if the form item is required input',
      type: 'boolean',
    },
    disabled: {
      comment: 'True if the form item is disabled',
      type: 'boolean',
      optional: true,
    },
    validator: {
      comment: 'The validator configuration for the form item input',
      optional: true,
      type: FormRule,
    },
    snippet: {
      comment: 'The code snippet configuration for displayed code',
      type: SnippetConfig,
      optional: true,
    },
    format: {
      comment: 'The format type of the form item i.e. email',
      optional: true,
      type: { FormFormat },
    },
    minLength: {
      comment: 'The minimum length required of the input when a number',
      optional: true,
      type: 'int',
    },
    multiline: {
      comment: 'When an input, make it a text area with this many lines',
      optional: true,
      type: 'int',
    },
    autoComplete: {
      comment: 'The browser autocomplete parameter for the form item',
      optional: true,
      type: { FormAutoComplete },
    },
    value: {
      comment: 'The value for the form item',
      optional: true,
      type: 'string',
    },
    initialValue: {
      comment: 'The initial value for the form item',
      optional: true,
      type: 'string',
    },
    initialBooleanValue: {
      comment: 'The initial boolean value for the form boolean item',
      optional: true,
      type: 'boolean',
    },
    // TODO: https://transcend.height.app/T-17126 - can be removed when workflows table exists
    dataSubjectIds: {
      comment:
        'The set of data subject IDs that this form item should show for',
      optional: true,
      type: 'id',
      modelName: 'subject',
      list: true,
    },
    sendUnencrypted: {
      comment:
        'Whether the form item value should be sent unencrypted to the backend',
      optional: true,
      type: 'boolean',
    },
    isPlaintext: {
      comment: 'Whether the form item value is plaintext',
      optional: true,
      type: 'boolean',
    },
    options: {
      comment: 'The options for a select form',
      type: SelectOption,
      list: true,
      optional: true,
    },
    mode: {
      comment: 'The mode of the select',
      optional: true,
      type: { ModeOption },
    },
    parseNewlines: {
      comment: 'Parse newline characters to actual newlines',
      optional: true,
      type: 'boolean',
    },
    isMulti: {
      comment: 'Whether the select is a multi select',
      optional: true,
      type: 'boolean',
    },
  },
});

/** Override type */
export type FormItem = SchemaToType<typeof FormItem>;

export const FormConfig = mkType({
  name: 'FormConfig',
  comment: 'Specifies a form required to connect a data silo',
  fields: {
    type: {
      comment: 'The type of silo form',
      type: { SiloFormType },
    },
    formItems: {
      comment: 'The items associated with this form',
      list: true,
      type: FormItem,
    },
    passportName: {
      comment: 'The passport name aka the name of the callback',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type FormConfig = SchemaToType<typeof FormConfig>;

import * as t from 'io-ts';

import { RecursiveObjectReplace } from '@transcend-io/type-utils';

import { createFrontendUuidv4, isUuid } from '@main/utils';

import { DatabaseModelName } from './enums';

/**
 * The codec for a nominally typed database model ID
 *
 * @see https://github.com/gcanti/io-ts/blob/master/index.md#branded-types--refinements
 * @see https://michalzalecki.com/nominal-typing-in-typescript/
 */
export type IDCodec<TModelName extends DatabaseModelName> = t.IntersectionC<
  [t.StringC, t.BrandC<t.StringC, { readonly [k in TModelName]: never }>]
>;

/**
 * A UUID for a db model.
 *
 * Uses nominal typing to enforce that an ID of one model will never be assigned to the id of another model.
 */
export type ID<TModelName extends DatabaseModelName> = t.TypeOf<
  IDCodec<TModelName>
>;

/**
 * The prefix to add to ids of new datamap nodes in order to differentiate newly created from updated items
 */
export const NEW_INDICATOR = '__NEW__'; // TODO this is weird and not always helpful

/**
 * Create a new temporary id for a model, before it is applied on the backend.
 *
 * @returns A new id cast to proper type
 */
export function createNewId<
  TModelName extends DatabaseModelName,
>(): ID<TModelName> {
  return `${NEW_INDICATOR}-${createFrontendUuidv4()}` as ID<TModelName>;
}

/**
 * Use branded typing to create io-ts compatibility with `ID` type
 *
 * Also validates that ids are proper uuids
 *
 * @param modelName - The name of the model that the id is for
 * @returns The io-ts codec
 */
export function dbModelId<TModelName extends DatabaseModelName>(
  modelName: TModelName,
): IDCodec<TModelName> {
  return t.intersection([
    t.string,
    t.brand(
      t.string,
      (x): x is t.Branded<string, { readonly [k in TModelName]: never }> =>
        isUuid(x),
      modelName,
    ),
  ]);
}

/**
 * Nominal typing-> string
 */
type TrulyAnyId = ID<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * Allow for an ids to be inputted as strings and outputted as their desired types
 */
export type WithStringIds<T> = RecursiveObjectReplace<T, TrulyAnyId, string>;

/**
 * Make id constraints optional on any type
 */
export type ValueWithoutIds<TData> =
  | TData
  | (TData extends (infer ArrT)[]
      ? WithStringIds<ArrT>[]
      : TData extends object
        ? WithStringIds<TData>
        : TData);

/**
 * Remove the ID constraint on a series of values (useful for testing). This is the identity function with a enforcement.
 *
 * @param modelInstance - The model instance without ids enforced
 * @returns The model instance with ids enforced
 */
export function withIds<T extends object>(modelInstance: WithStringIds<T>): T {
  return modelInstance as T;
}

/**
 * Apply to a list
 *
 * @param instances - The model instances without ids enforced
 * @returns The model instances with ids enforced
 */
export function fromList<T extends object>(instances: WithStringIds<T>[]): T[] {
  return instances as T[];
}

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ObjByString } from '@transcend-io/type-utils';

import {
  filtersSelector,
  persistedFiltersSlice,
  RESTORE_FILTERS_URL_PARAM,
} from './usePersistFiltersToUrl';

interface UseQueryParamArgs<T = string> {
  /** the param name */
  name: string;
  /** the onChange handler */
  onChange?: (newValue?: T) => void;
  /** whether to skip persisting */
  skip?: boolean;
}

interface UseQueryParamJsonArgs<T = string> extends UseQueryParamArgs<T> {
  /** the default value to return when nothing is parsed */
  defaultValue?: T;
  /** The key to use in localStorage for the cached filters */
  filterCacheKey?: string;
  /** option to filter out top-level empty strings and arrays from the persisted URL */
  filterOutEmptyStringsAndArrays?: boolean;
}

interface PersistQueryParamsOptions {
  /** the params to clear from the url when navigating */
  paramsToClear?: string[];
  /** whether to replace the last history item when navigating (hide from back button) */
  replace?: boolean;
}

interface UseQueryParamResults<T = string> {
  /** the save function */
  persistQueryParam: (
    newValue?: T,
    options?: PersistQueryParamsOptions,
  ) => void;
  /** the value */
  value?: T;
}

/**
 * set up a query param and load from/persist to it
 *
 * @param args - args
 * @returns the synced value
 */
export function useQueryParam<T extends string = string>({
  name,
  onChange = () => {
    // do nothing
  },
  skip,
}: UseQueryParamArgs): UseQueryParamResults<T> {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<string | undefined>(
    new URLSearchParams(location.search).get(name) ?? undefined,
  );

  // on first render & location change
  useEffect(() => {
    if (!skip) {
      // parse the url query params
      const params = new URLSearchParams(location.search);
      const parsedValue = params.get(name);

      // if either state has changed, call the change handler
      if (parsedValue !== value) {
        onChange(parsedValue ?? undefined);
        setValue(parsedValue ?? undefined);
      }
    }
  }, [location]);

  return {
    value: value as T,
    persistQueryParam: (newValue, { paramsToClear, replace } = {}) => {
      if (!skip) {
        // parse the url query params
        const params = new URLSearchParams(location.search);
        const parsedValue = params.get(name);

        if (newValue !== parsedValue) {
          if (!newValue) {
            params.delete(name);
          } else {
            params.set(name, newValue);
          }
          paramsToClear?.forEach((paramName) => params.delete(paramName));
          setValue(newValue);
          const newUrl = `${location.pathname}?${params.toString()}${
            location.hash
          }`;
          navigate(newUrl, { replace });
        }
      }
    },
  };
}

/**
 * Helper to remove empty strings and empty arrays from the filter object
 *
 * @param filters - The parsed filter object (may be undefined)
 * @returns Non-empty filter object
 */
function removeEmptyFilters<T>(filters: T): T {
  return Object.fromEntries(
    Object.entries(filters ?? {})
      .map(([key, val]) => [
        key,
        Array.isArray(val)
          ? // if array has any non-undefined elements
            val.filter((item) => item !== undefined).length > 0
            ? // return the array
              val
            : undefined
          : val === ''
            ? // else replace empty string with undefined
              undefined
            : val,
      ])
      // filter out the undefined props
      .filter(([, val]) => val !== undefined),
  );
}

/**
 * wrap useQueryParam in some helper code to persist objects
 *
 * @param arg - arg
 * @returns useQueryParam methods wrapped with a parse/stringify
 */
export function useQueryParamJson<T>({
  defaultValue,
  filterCacheKey,
  filterOutEmptyStringsAndArrays,
  ...args
}: UseQueryParamJsonArgs<T>): UseQueryParamResults<T> {
  const cachedFilters = useSelector(filtersSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentParams = new URLSearchParams(location.search);
  const areUrlFiltersEmpty = !currentParams.has(args.name);
  // load last filters if present and url has the restore filters flag
  const hasRestoreParam = currentParams.has(RESTORE_FILTERS_URL_PARAM);
  const shouldRestoreFilters =
    areUrlFiltersEmpty &&
    filterCacheKey &&
    cachedFilters[filterCacheKey] &&
    hasRestoreParam;
  const cachedOrDefaultValue = shouldRestoreFilters
    ? cachedFilters[filterCacheKey!]
    : defaultValue;
  // function to attempt to parse the string and return a default if parse fails
  const tryParseValue = (paramString?: string): T | undefined => {
    try {
      const parsed = paramString
        ? (JSON.parse(paramString) as T)
        : cachedOrDefaultValue;

      return removeEmptyFilters(parsed);
    } catch (e) {
      return cachedOrDefaultValue;
    }
  };

  const { persistQueryParam: setParamString, value: paramString } =
    useQueryParam<string>({
      ...args,
      onChange: (changedString?: string) =>
        // wrap the onChange in a parse
        args.onChange?.(tryParseValue(changedString)),
    });

  // wrap the useQueryParam helpers in a parse/stringify
  const parsedValue = useMemo(() => tryParseValue(paramString), [paramString]);
  const setObjectValue = (
    newFilters?: T,
    { replace, paramsToClear }: PersistQueryParamsOptions = {},
  ): void => {
    // rebuild the filters without any empty field values
    const removed = removeEmptyFilters(newFilters);
    const paramsWithoutEmptyValues = removed || {};
    const filtersToSet = filterOutEmptyStringsAndArrays
      ? paramsWithoutEmptyValues
      : newFilters ?? {};
    setParamString(
      // if any non-undefined fields are specified
      Object.values(filtersToSet).filter((val) => val !== undefined).length > 0
        ? JSON.stringify(filtersToSet)
        : undefined,
      // clear the restore filters param on first load
      {
        paramsToClear: [
          ...(paramsToClear ?? []),
          ...(shouldRestoreFilters ? [RESTORE_FILTERS_URL_PARAM] : []),
        ],
        replace,
      },
    );
    if (filterCacheKey) {
      // update cached copy
      dispatch(
        persistedFiltersSlice.actions.setFilters({
          newFilters: newFilters as ObjByString,
          filterKey: filterCacheKey,
        }),
      );
    }
  };

  // clear the restore filters param on first load
  useEffect(() => {
    if (shouldRestoreFilters || areUrlFiltersEmpty) {
      setObjectValue(cachedOrDefaultValue, { replace: true });
    }
  }, [areUrlFiltersEmpty, hasRestoreParam]);

  return { value: parsedValue, persistQueryParam: setObjectValue };
}

import {
  ISO_31662,
  IsoCountryCode,
  IsoCountrySubdivisionCode,
} from '@transcend-io/privacy-types';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  RawPaginatedSelect,
  ReactSelectExtendedProps,
  SelectTagWrapper,
} from '../RawPaginatedSelect';
import { REGION_COLORS } from './constants';
import { selectRegionMessages } from './messages';
import { RegionTitle } from './RegionTitle';
import { searchByCountyCountrySubDivision } from './searchByCountyCountrySubDivision';

export interface RegionOption {
  /** ISO country code */
  country?: IsoCountryCode;
  /** ISO country subdivision code */
  countrySubDivision?: keyof typeof ISO_31662;
}

/** Props for the `SelectRegion` component */
export interface SelectRegionProps<IsMulti extends boolean>
  extends Omit<
    ReactSelectExtendedProps<IsMulti, RegionOption>,
    'isCreatable' | 'value' | 'onChange'
  > {
  /** The selected regions for this data silo */
  value?: RegionOption[];
  /** Callback to fire when the region is updated */
  onChange: (selected: RegionOption[]) => void;
  /** Style */
  style?: React.CSSProperties;
  /** Allow list of regions to select from */
  allowedRegions?: string[];
}

/**
 * Component to select one or more regions
 */
export function SelectRegion<IsMulti extends boolean>({
  value,
  isLoading,
  menuPosition,
  showOutline = false,
  allowedRegions,
  onChange,
  style,
  ...props
}: SelectRegionProps<IsMulti>): JSX.Element {
  const ALL_REGION_CODES = useMemo(
    () => [
      ...Object.values(IsoCountryCode)
        .filter(
          (code) =>
            !allowedRegions ||
            allowedRegions.length === 0 ||
            allowedRegions.includes(code),
        )
        .map((code) => ({
          country: code,
          countrySubDivision: undefined,
        })),
      ...Object.values(IsoCountrySubdivisionCode)
        .filter(
          (code) =>
            !allowedRegions ||
            allowedRegions.length === 0 ||
            allowedRegions.includes(code),
        )
        .map((code) => ({
          country: code.slice(0, 2) as IsoCountryCode,
          countrySubDivision: code,
        })),
    ],
    [allowedRegions],
  );
  const pageNumber = 1;
  const limit = 10;
  const initialOptions = [] as RegionOption[];

  const [searchText, setSearchText] = useState('');
  // Cache options for infinite scroll
  const [cachedOptions, setCachedOptions] = useState(initialOptions);

  const [page, setPage] = useState(pageNumber);
  const pageNumberRef = useRef(pageNumber);
  const optionsRef = useRef(initialOptions);

  // Resets cached options and page when searchText updates
  useEffect(() => {
    setCachedOptions(initialOptions);
    pageNumberRef.current = 1;
    setPage(pageNumberRef.current);
  }, [searchText]);

  const options = useMemo(() => {
    const offset = (page - 1) * limit;
    const matches = searchByCountyCountrySubDivision(
      ALL_REGION_CODES,
      searchText,
    );
    return matches.slice(offset, offset + limit);
  }, [searchText, page]);

  // Update the cached options when new options are retrieved
  useEffect(() => {
    if (page === 1) {
      optionsRef.current = options;
      setCachedOptions(optionsRef.current);
    } else {
      setCachedOptions([...cachedOptions, ...options]);
    }
  }, [options, page]);

  const selectedOptions = useMemo(
    () =>
      value
        ? Array.isArray(value)
          ? value.map((val) => options.find((opt) => isEqual(opt, val)) ?? val)
          : options.find((opt) => isEqual(opt, value)) ?? value
        : value,
    [options, value],
  );

  return (
    <SelectTagWrapper>
      <RawPaginatedSelect
        isCreatable={false}
        menuPosition={menuPosition}
        showOutline={showOutline}
        options={cachedOptions}
        placeholderDescriptor={selectRegionMessages.placeholder}
        isQueryLoading={isLoading || false}
        getNextPage={() => {
          pageNumberRef.current += 1;
          setPage(pageNumberRef.current);
        }}
        onEndsTyping={(searchText) => setSearchText(searchText)}
        formatOptionPill={(option: RegionOption) => (
          <RegionTitle
            country={
              option.country ??
              (option.countrySubDivision?.slice(0, 2) as IsoCountryCode)
            }
            countrySubDivision={option.countrySubDivision}
          />
        )}
        getOptionValue={({ country, countrySubDivision }: RegionOption) =>
          countrySubDivision || country || ''
        }
        value={
          selectedOptions &&
          (!Array.isArray(selectedOptions) ||
            (selectedOptions.length > 0 &&
              (selectedOptions[0]?.country ||
                selectedOptions[0]?.countrySubDivision)))
            ? selectedOptions
            : undefined
        }
        style={style}
        onChange={(selected) =>
          !selected
            ? onChange([])
            : Array.isArray(selected)
              ? onChange([...selected])
              : onChange([selected] as RegionOption[])
        }
        colorMap={REGION_COLORS}
        {...props}
      />
    </SelectTagWrapper>
  );
}

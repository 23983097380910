import React from 'react';

import { SvgProps } from '../enums';

export const FlagGF: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55114"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55114)">
      <rect width="16" height="12" rx="1" fill="#5EAA22" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L16 12H0V0Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.96533 7.20314L6.22251 8.41199L6.77941 6.34152L5.5 5.0189L7.23239 4.94738L7.96533 2.89999L8.69827 4.94738H10.4277L9.15125 6.34152L9.79052 8.28978L7.96533 7.20314Z"
        fill="#E21835"
      />
    </g>
  </svg>
);

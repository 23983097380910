import { FileType } from './enums';

/**
 * One second of time in ms
 */
export const ONE_SECOND = 1000;

/**
 * One minute of time in ms
 */
export const ONE_MINUTE = 60 * ONE_SECOND;

/**
 * One hour of time in ms
 */
export const ONE_HOUR = 60 * ONE_MINUTE;

/**
 * Half a day of time in ms
 */
export const HALF_DAY = 12 * ONE_HOUR;

/**
 * A days worth of time in ms
 */
export const ONE_DAY = 24 * ONE_HOUR;

/**
 * A weeks worth of time in ms
 */
export const ONE_WEEK = 7 * ONE_DAY;

/**
 * A months worth of time in ms
 */
export const ONE_MONTH = 30.5 * ONE_DAY;

/**
 * A years worth of time in ms
 */
export const ONE_YEAR = 365 * ONE_DAY;

/**
 * Number of bytes in a kilobyte
 */
export const ONE_KB = 1024;

/**
 * Number of bytes in a megabyte
 */
export const ONE_MB = ONE_KB * ONE_KB;

/**
 * Where transcend assets are hosted
 */
export const ASSET_BASE_URL =
  'https://misc-assets-transcend-io.s3.amazonaws.com';

/** String to denote something isn't available. */
export const NOT_AVAILABLE_STRING = 'NA';

/** All passwords must be this many or more characters */
export const MINIMUM_PASSWORD_LENGTH = 10;

/** All passwords must be this many or fewer characters */
export const MAXIMUM_PASSWORD_LENGTH = 128;

/** Error message throw when sombra JWT auth fails */
export const SOMBRA_AUTHENTICATION_VERIFICATION_FAILED_ERROR_MESSAGE =
  'Failed to verify authentication JWT!';

/** Casted error message */
export const TRANSCEND_AUTHENTICATION_VERIFICATION_FAILED_ERROR_MESSAGE =
  'Your need to re-authenticate in order to perform this operation.';

export const UNKNOWN_MIME_TYPE = 'unknown/mime-type';

export const UNKNOWN_FILE_EXTENSION = 'unknown';

export const FOLDER_MIME_TYPE = 'application/x-directory';

export const FOLDER_FILE_EXTENSION = 'folder';

/**
 * Known mime type to file extension mapping for Unstructured Discovery
 */
export const KNOWN_MIME_TYPES_FROM_FILE_EXTENSIONS: Record<FileType, string> = {
  [FileType.Binary]: 'binary/octet-stream',
  [FileType.Pdf]: 'application/pdf',
  [FileType.Txt]: 'text/plain',
  [FileType.Csv]: 'text/csv',
  [FileType.Json]: 'application/json',
  [FileType.Gdoc]: 'application/vnd.google-apps.document',
  [FileType.Gslides]: 'application/vnd.google-apps.presentation',
  [FileType.Gsheet]: 'application/vnd.google-apps.spreadsheet',
  [FileType.Gform]: 'application/vnd.google-apps.form',
  [FileType.Docx]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [FileType.Xlsx]:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [FileType.Pptx]:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  [FileType.Zip]: 'application/zip',
};

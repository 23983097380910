/* eslint-disable max-len */
import React from 'react';

import { SvgProps } from '../enums';

export const EmployeeBadge: React.FC<SvgProps> = (props) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2.66699C12.7364 2.66699 13.3333 3.26395 13.3333 4.00033V12.667C13.3333 13.4034 12.7364 14.0003 12 14.0003H3.99999C3.26361 14.0003 2.66666 13.4034 2.66666 12.667V4.00033C2.66666 3.26395 3.26361 2.66699 3.99999 2.66699"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 2.66634H6.33333"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.66666 2.66634H12"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00001 1.33301H9.00001C9.3682 1.33301 9.66668 1.63148 9.66668 1.99967V3.99967H6.33334V1.99967C6.33334 1.63148 6.63182 1.33301 7.00001 1.33301Z"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="7.99999"
      cy="7.33333"
      rx="1.33333"
      ry="1.33333"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6C8.73638 6 9.33333 6.59695 9.33333 7.33333C9.33333 8.06971 8.73638 8.66667 8 8.66667"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3333 11.6663C10.3333 10.93 9.73637 10.333 8.99999 10.333H6.99999C6.26361 10.333 5.66666 10.93 5.66666 11.6663"
      stroke={props.color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import { ColorPalette } from '@main/theme';
import React from 'react';
import { useTheme } from 'styled-components';

import { FlexRow } from '../StyledWrappers';
import { SHADE_MAP } from './constants';
import { StyledTagSection } from './wrappers';

export interface DoubleTagProps {
  /** Main label of the tag (left) */
  leftLabel: string | JSX.Element;
  /** Sub label of the tag (right) */
  rightLabel: string | JSX.Element;
  /** The main color of the tag, used for background or border */
  color: keyof ColorPalette;
  /** The tag variant, either filled or outline */
  variant: 'filled' | 'outline';
  /** style */
  style?: React.CSSProperties;
  /** the icon to appear at the rightmost point in the tag (usually an X) */
  rightIcon?: JSX.Element;
  /** the icon to appear at the leftmost point in the tag  */
  leftIcon?: JSX.Element;
  /** style of left label */
  leftLabelStyle?: React.CSSProperties;
  /** style of right label */
  rightLabelStyle?: React.CSSProperties;
}

/**
 * The two-part tag component that provides a main, higher-level label and a more specific, detailed label to a respective unit.
 * Default instances will be the same shade with lighter on the left and darker on the right, with white text.
 * Outlined versions will have white/no background with uniform border and text color.
 */
export const DoubleTag: React.FC<DoubleTagProps> = ({
  leftLabel,
  rightLabel,
  color,
  variant,
  style = {},
  rightIcon,
  leftIcon,
  leftLabelStyle,
  rightLabelStyle,
}) => {
  const theme = useTheme();

  return (
    <span style={{ display: 'inline-flex', ...style }}>
      <StyledTagSection
        data-testid="double-tag-left"
        style={{
          color:
            variant === 'outline' ? theme.colors[color] : theme.colors.white,
          background:
            variant === 'outline' ? 'unset' : theme.colors[SHADE_MAP[color]],
          borderRadius: '4px 0 0 4px',
          borderWidth: 1,
          whiteSpace: 'normal',
          borderStyle: 'solid',
          borderColor:
            variant === 'outline'
              ? theme.colors[color]
              : theme.colors[SHADE_MAP[color]],
          ...leftLabelStyle,
        }}
      >
        {leftIcon && (
          <FlexRow as="span" style={{ marginRight: 7 }}>
            {leftIcon}
          </FlexRow>
        )}
        {leftLabel}
      </StyledTagSection>
      <StyledTagSection
        data-testid="double-tag-right"
        style={{
          color:
            variant === 'outline' ? theme.colors[color] : theme.colors.white,
          background: variant === 'outline' ? 'unset' : theme.colors[color],
          borderRadius: '0 4px 4px 0',
          borderTop: `1px solid ${theme.colors[color]}`,
          whiteSpace: 'pre-wrap',
          borderRight: `1px solid ${theme.colors[color]}`,
          borderBottom: `1px solid ${theme.colors[color]}`,
          ...rightLabelStyle,
        }}
      >
        {rightLabel}
        {rightIcon}
      </StyledTagSection>
    </span>
  );
};

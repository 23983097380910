import styled from 'styled-components';

interface LogoIconProps {
  /** The size (width & height) of the icon */
  size?: number;
}

export const LogoIcon = styled.img<LogoIconProps>`
  height: ${({ size }) => size ?? 32}px;
  min-width: ${({ size }) => size ?? 32}px;
  max-width: ${({ size }) => size ?? 32}px;
  // use em for consistent styling relative to font size
  border-radius: 0.5em;
  // when no image is present
  color: transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

export interface IconPlaceholderProps {
  /** The background color of the placeholder */
  bgColor?: string;
  /** The size (width & height) of the placeholder */
  size?: number;
}

export const IconPlaceholder = styled.div<IconPlaceholderProps>`
  width: ${({ size }) => size ?? 32}px;
  height: ${({ size }) => size ?? 32}px;
  border-radius: 0.5em;
  font-size: ${({ size }) => Math.round((size ?? 32) / 2)}px;
  background-color: ${({ bgColor, theme }) =>
    bgColor ?? theme.colors.transcendNavy3};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

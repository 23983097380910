/**
 * An adjacency matrix
 */
type Matrix = { [from in string]: { [to in string]: boolean } };

/**
 * Convert an adjacency list graphql representation to a matrix
 *
 * @param adjacencyList - The adjacency list representation of the graph
 * @returns The matrix representation of the graph where result[from][to] indicates the presence or absence of an edge
 */
export function adjacencyToMatrix(adjacencyList: {
  [key in string]: string[];
}): Matrix {
  // The matrix to return
  const matrix: Matrix = {};

  // All nodes
  const nodes = Object.keys(adjacencyList);

  // Initialize with false on every edge
  nodes.forEach((from) => {
    matrix[from] = {};
    nodes.forEach((to) => {
      matrix[from][to] = false;
    });
  });

  // Set all the edges to true
  Object.entries(adjacencyList).forEach(([from, values]) =>
    values.forEach((to) => {
      matrix[from][to] = true;
    }),
  );

  // Return the matrix
  return matrix;
}

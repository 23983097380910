import React from 'react';

import { SvgProps } from '../enums';

export const FlagBA: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54953"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="17"
      height="12"
    >
      <rect width="17" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54953)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#2E42A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H15V12L5 0Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3.55402 1.39691L2.96624 1.70593L3.07849 1.05142L2.60297 0.587895L3.26013 0.492403L3.55402 -0.103088L3.84792 0.492403L4.50508 0.587895L4.02955 1.05142L4.14181 1.70593L3.55402 1.39691Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5.29737 3.53699L4.70958 3.846L4.82184 3.1915L4.34631 2.72797L5.00348 2.63248L5.29737 2.03699L5.59126 2.63248L6.24843 2.72797L5.7729 3.1915L5.88516 3.846L5.29737 3.53699Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.93171 5.61926L6.34392 5.92828L6.45618 5.27377L5.98065 4.81025L6.63782 4.71475L6.93171 4.11926L7.2256 4.71475L7.88276 4.81025L7.40724 5.27377L7.51949 5.92828L6.93171 5.61926Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.53266 7.61462L7.94488 7.92364L8.05713 7.26913L7.5816 6.80561L8.23877 6.71012L8.53266 6.11462L8.82655 6.71012L9.48372 6.80561L9.00819 7.26913L9.12045 7.92364L8.53266 7.61462Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.2572 9.6684L9.66942 9.97741L9.78168 9.3229L9.30615 8.85938L9.96332 8.76389L10.2572 8.1684L10.5511 8.76389L11.2083 8.85938L10.7327 9.3229L10.845 9.97741L10.2572 9.6684Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12.1635 11.6555L11.5757 11.9645L11.6879 11.31L11.2124 10.8465L11.8696 10.751L12.1635 10.1555L12.4574 10.751L13.1145 10.8465L12.639 11.31L12.7512 11.9645L12.1635 11.6555Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

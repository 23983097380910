/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Link: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.74543 5.16683C5.97216 3.90586 8.01083 3.90586 9.23756 5.16683C10.3407 6.301 10.504 8.10886 9.59719 9.41315L9.57292 9.44945C9.34567 9.77382 8.9044 9.85094 8.58889 9.6173C8.27118 9.38367 8.19616 8.93 8.42342 8.60563L8.44769 8.56933C8.95294 7.8412 8.87351 6.8454 8.25794 6.21253C7.56515 5.50027 6.4377 5.50027 5.7427 6.21253L3.26718 8.75987C2.57196 9.45398 2.57196 10.6313 3.26718 11.3458C3.88054 11.9786 4.85134 12.0603 5.55737 11.5409L5.59267 11.4955C5.91038 11.2823 6.35165 11.3571 6.5767 11.6838C6.80396 12.0104 6.73115 12.4641 6.41343 12.6977L6.37813 12.7227C5.08962 13.6549 3.37088 13.5052 2.26792 12.3733C1.02202 11.0917 1.02202 9.01393 2.26792 7.73458L4.74543 5.16683ZM11.2542 10.8127C10.0076 12.0943 7.98876 12.0943 6.74218 10.8127C5.639 9.67855 5.49559 7.8911 6.4024 6.58681L6.42667 6.55051C6.63407 6.22614 7.07534 6.14902 7.4107 6.38266C7.72842 6.59588 7.80343 7.04955 7.57618 7.39433L7.55191 7.43063C7.04665 8.13835 7.12608 9.15456 7.74165 9.78743C8.43445 10.4997 9.56189 10.4997 10.2569 9.78743L12.7324 7.24009C13.4274 6.52556 13.4274 5.3483 12.7324 4.65418C12.119 4.022 11.1483 3.93943 10.4422 4.45911L10.4069 4.48406C10.0892 4.7177 9.64794 4.62243 9.42289 4.31575C9.19564 3.98956 9.26845 3.53612 9.58616 3.30316L9.62146 3.27776C10.8901 2.34572 12.6287 2.49359 13.7319 3.62754C14.9785 4.90824 14.9785 6.98603 13.7319 8.26538L11.2542 10.8127Z"
      fill={color}
    />
  </svg>
);
/* eslint-enable max-len */

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const XNoPadding: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M0.182016 9.91688C-0.0572419 10.1561 -0.0640779 10.5731 0.182016 10.8124C0.42811 11.0516 0.838266 11.0516 1.07752 10.8124L5.15858 6.73133L9.2328 10.8124C9.47889 11.0516 9.88905 11.0585 10.1283 10.8124C10.3676 10.5663 10.3676 10.1561 10.1283 9.91688L6.04725 5.83582L10.1283 1.76161C10.3676 1.51551 10.3744 1.10536 10.1283 0.866098C9.88221 0.62684 9.47889 0.62684 9.2328 0.866098L5.15858 4.94715L1.07752 0.866098C0.838266 0.62684 0.421274 0.620004 0.182016 0.866098C-0.0572419 1.11219 -0.0572419 1.52235 0.182016 1.75477L4.26307 5.83582L0.182016 9.91688Z"
      fill={color}
    />
  </svg>
);

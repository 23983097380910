import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ToggleTableHeaderRow: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M3.70369 1.33334L7.3333 4.96295L5.31021 6.98604C4.84746 7.44898 4.09705 7.44913 3.63411 6.98638L3.63377 6.98604L2.3473 5.69954C1.88437 5.23679 1.88421 4.48638 2.34696 4.02344L2.3473 4.0231L4.37036 2.00001"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33358 4.96294H2.00684"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 11L2 14L14 14L14 11L2 11Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 11L6 14"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11L10 14"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const FlagCheckmark: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3306 3.78833V17.5032"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33057 13.7514C5.16495 12.7032 7.3888 12.5909 9.31946 13.449L10.6805 14.0539C12.6112 14.912 14.8351 14.7997 16.6695 13.7514V3.78812C14.8351 4.83635 12.6112 4.94866 10.6806 4.09059L9.31947 3.48566C7.3888 2.62759 5.16496 2.7399 3.33057 3.78812"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.459 7.83765L9.63626 9.66132L8.54108 8.56712"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import React from 'react';

import { SvgProps } from '../enums';

export const FlagCX: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54981"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54981)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#5EAA22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L16 12H0V0Z"
        fill="#4141DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3 4.5L2.5 4.86603L2.56699 4.25L2 4L2.56699 3.75L2.5 3.13397L3 3.5L3.5 3.13397L3.43301 3.75L4 4L3.43301 4.25L3.5 4.86603L3 4.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2 7.5L1.5 7.86603L1.56699 7.25L1 7L1.56699 6.75L1.5 6.13397L2 6.5L2.5 6.13397L2.43301 6.75L3 7L2.43301 7.25L2.5 7.86603L2 7.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4 10.5L3.5 10.866L3.56699 10.25L3 10L3.56699 9.75L3.5 9.13397L4 9.5L4.5 9.13397L4.43301 9.75L5 10L4.43301 10.25L4.5 10.866L4 10.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.5 5.75L4.25 5.93301L4.28349 5.625L4 5.5L4.28349 5.375L4.25 5.06699L4.5 5.25L4.75 5.06699L4.71651 5.375L5 5.5L4.71651 5.625L4.75 5.93301L4.5 5.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.7805 6.11196C10.5936 5.91697 12.2757 5.45882 12.45 4.86805C12.681 4.35371 12.5267 3.93693 11.6536 3.5709C10.7805 3.20488 9.89818 2.49714 10.9128 2.49714C11.9274 2.49714 11.9274 2.64181 12.2 3.06856C12.4726 3.49531 13.0617 3.58672 13.0742 3.06856C13.0742 2.18566 13.1576 2.06691 12.4448 1.29933C11.7321 0.531743 14.2321 1.61454 14.0254 2.93044C13.8188 4.24634 13.57 3.82173 13.728 4.04262C13.8859 4.26352 14.8361 3.57781 14.7175 4.49122C14.385 5.01654 13.7257 5.79817 10.7805 6.11196Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.47647 5.08102C6.76004 5.01779 7.1478 5.77714 7.84767 5.49234C8.54753 5.20754 8.69369 4.6638 9.02622 4.86281C9.35875 5.06181 9.40239 5.54095 9.18088 5.78177C8.95936 6.02259 8.58346 6.07956 8.58346 6.38394C8.58346 6.68832 8.50975 7.77376 8.36609 7.45919C7.9353 7.10966 8.19813 6.55294 7.6675 6.38394C7.13687 6.21494 6.81459 6.2206 6.42316 6.29191C6.03172 6.36323 6.68612 6.09601 6.82491 5.8423C7.10749 5.57045 6.3468 5.17141 6.47647 5.08102Z"
        fill="#548650"
      />
    </g>
  </svg>
);

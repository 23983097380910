import { PromptStatus } from '@transcend-io/privacy-types';

import { mkType, SchemaToType } from '@main/schema-utils';

export const PromptPreview = mkType({
  name: 'PromptPreview',
  comment: 'A preview of an AI prompt definition',
  fields: {
    id: {
      comment: 'The id of the prompt',
      modelName: 'prompt',
      type: 'id',
    },
    title: {
      comment: 'The title of the prompt',
      type: 'string',
    },
    status: {
      comment: 'The status of the prompt',
      type: { PromptStatus },
    },
    temperature: {
      comment:
        'Amount of randomness injected into the response. ' +
        'Defaults to 1. Ranges from 0 to 1. ' +
        'Use temp closer to 0 for analytical / multiple choice, and closer to 1 for creative and generative tasks.',
      type: 'float',
      optional: true,
    },
    topP: {
      comment:
        'An alternative to sampling with temperature, called nucleus sampling, where the ' +
        'model considers the results of the tokens with top_p probability mass. So 0.1 ' +
        'means only the tokens comprising the top 10% probability mass are considered. ' +
        'We generally recommend altering this or `temperature` but not both.',
      type: 'float',
      optional: true,
    },
    maxTokensToSample: {
      comment: 'The maximum number of tokens to sample in the response',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type PromptPreview = SchemaToType<typeof PromptPreview>;

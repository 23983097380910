import React from 'react';

import { SvgProps } from '../enums';

export const FlagMZ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55079"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55079)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55079"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55079)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#009933"
        />
        <path
          d="M0 3.5H-0.5V4V8V8.5H0H16H16.5V8V4V3.5H16H0Z"
          fill="#272727"
          stroke="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12L9 6L0 0Z"
        fill="#F50100"
      />
      <mask
        id="mask2_270_55079"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="9"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12L9 6L0 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_270_55079)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.03439 7.17661L2.14492 8.70184L3.01161 6.66353L1.5224 5.22942H3.2802L4.0015 3.5L4.76759 5.22942H6.5224L4.95452 6.66353L5.7392 8.70184L4.03439 7.17661Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.07796 5.9921L2.75751 7.06232C2.75751 7.06232 3.83933 7.05162 3.96926 7.16093C4.36076 6.91681 5.16674 7.06232 5.16674 7.06232L4.73805 5.91696C4.73805 5.91696 4.19453 5.67067 3.96926 5.80571C3.41712 5.68099 3.07796 5.9921 3.07796 5.9921Z"
          fill="#F7FCFF"
        />
        <path
          // eslint-disable-next-line max-len
          d="M5.7819 7.60813C5.86495 7.71843 5.84287 7.87517 5.73257 7.95823C5.62227 8.04128 5.46553 8.0192 5.38247 7.9089L3.29243 5.13328C3.20937 5.02298 3.23146 4.86624 3.34176 4.78318C3.45206 4.70013 3.6088 4.72221 3.69185 4.83251L5.7819 7.60813Z"
          fill="black"
        />
        <path
          d="M3.48699 4.91987L3.28699 4.67987"
          stroke="black"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.80231 4.68127L2.19806 5.64894L2.50019 6.17229L3.19128 5.14517L3.31558 4.82757L3.19128 4.68127H2.80231Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.01947 7.55427L2.48387 8.09353L5.99806 4.0215L4.40069 5.01734L2.01947 7.55427Z"
          fill="black"
        />
      </g>
    </g>
  </svg>
);

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const PasswordKey: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.97911 6.5177C6.03877 6.57735 6.05661 6.66706 6.02433 6.745C5.99205 6.82293 5.91599 6.87375 5.83163 6.87375C5.74728 6.87375 5.67122 6.82293 5.63894 6.745C5.60666 6.66706 5.6245 6.57735 5.68416 6.5177C5.76569 6.43644 5.89758 6.43644 5.97911 6.5177"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1471 6.5177C10.2067 6.57735 10.2246 6.66706 10.1923 6.745C10.16 6.82293 10.084 6.87375 9.9996 6.87375C9.91524 6.87375 9.83919 6.82293 9.80691 6.745C9.77463 6.66706 9.79247 6.57735 9.85213 6.5177C9.93366 6.43644 10.0656 6.43644 10.1471 6.5177"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3159 6.5177C14.3756 6.57735 14.3934 6.66706 14.3611 6.745C14.3288 6.82293 14.2528 6.87375 14.1684 6.87375C14.0841 6.87375 14.008 6.82293 13.9757 6.745C13.9435 6.66706 13.9613 6.57735 14.0209 6.5177C14.1025 6.43644 14.2344 6.43644 14.3159 6.5177"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33265 10.8338H4.16425C3.722 10.8339 3.29783 10.6582 2.98511 10.3455C2.67239 10.0328 2.49689 9.60864 2.49689 9.16639V4.16431C2.49689 3.72206 2.67239 3.29789 2.98511 2.98517C3.29783 2.67246 3.722 2.49683 4.16425 2.49695H15.8358C16.278 2.49683 16.7022 2.67246 17.0149 2.98517C17.3276 3.29789 17.5031 3.72206 17.5031 4.16431V6.66535"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.37 13.9043L9.42883 15.8495C9.26111 16.0186 9.16679 16.247 9.16632 16.4852V17.4377C9.16632 17.9343 9.56889 18.3369 10.0655 18.3369H11.022C11.2607 18.3372 11.4897 18.2419 11.6577 18.0723L13.5948 16.1311C13.9496 16.2554 14.3223 16.3209 14.6982 16.3253C16.2593 16.351 17.657 15.362 18.1521 13.8812C18.6472 12.4005 18.1255 10.7697 16.8629 9.85126C15.6003 8.93282 13.8881 8.93863 12.6318 9.86562C11.3755 10.7926 10.8649 12.4269 11.37 13.9043V13.9043Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6982 12.7007C14.6407 12.7007 14.594 12.7473 14.594 12.8049C14.594 12.8625 14.6407 12.9091 14.6982 12.9091C14.7558 12.9091 14.8024 12.8625 14.8024 12.8049C14.8024 12.7473 14.7558 12.7007 14.6982 12.7007V12.7007"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

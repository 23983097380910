import React, { useEffect, useState } from 'react';
import type {
  FormatOptionLabelMeta,
  GroupTypeBase,
  OptionTypeBase,
} from 'react-select';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { OptionLabel } from './OptionLabel';
import { useStyles } from './styles';
import {
  DefaultOptionType,
  ReactAsyncSelectExtendedProps,
  ReactCreatableSelectExtendedProps,
  ReactSelectExtendedProps,
  ReactSelectOverflowOptions,
} from './types';
import { useDefaultComponents } from './useDefaultComponents';
import { HiddenSelectWrapper } from './wrappers';

/**
 * Reusable react-select with our own styling
 */
export function ReactSelect<
  IsMulti extends boolean,
  T extends OptionTypeBase = DefaultOptionType,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
>({
  isMulti,
  overflow = (isMulti ? 'badge' : 'none') as ReactSelectOverflowOptions,
  overflowLimit = 1,
  showOutline = true,
  variant = 'primary',
  getVariant = () => variant,
  colorMap,
  defaultOptionColor,
  formatOptionPill,
  components,
  value: initialValue,
  isCreatable,
  menuPosition = 'fixed',
  selectRef,
  isClearable,
  style,
  menuZIndex,
  ...options
}: ReactSelectExtendedProps<IsMulti, T, G>): JSX.Element {
  const [value, setValue] = useState(initialValue);
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const { getColors, styles } = useStyles<IsMulti, T>({
    hovered,
    focused,
    variant,
    getVariant,
    colorMap,
    defaultOptionColor,
    showOutline,
    overflow,
    menuZIndex,
  });
  const componentsOverride = useDefaultComponents({
    overflow,
    overflowLimit,
    hideIndicator: !showOutline,
    isDisabled: !!options.isDisabled,
    components,
  });

  // If the value is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue ?? null);
  }, [initialValue]);

  const SelectComponent = (isCreatable
    ? CreatableSelect
    : Select) as any as React.FC;
  return (
    <HiddenSelectWrapper
      menuIsOpen={options.menuIsOpen}
      minHeight={options.minHeight}
      overflow={overflow}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => {
        setFocused(false);
        setHovered(false);
      }}
    >
      <SelectComponent
        ref={selectRef}
        value={value}
        menuPosition={menuPosition}
        menuShouldBlockScroll
        classNamePrefix="hideable-select"
        className={showOutline ? undefined : 'hidden-outline'}
        components={componentsOverride}
        styles={{
          ...styles,
          ...style,
        }}
        // react-select should not be clearable when it is a multi-select
        isClearable={isClearable ?? !isMulti}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        // always include the current selected item in the dropdown list
        hideSelectedOptions={false}
        overflowLimit={overflowLimit}
        {...(options as any)}
        formatOptionLabel={(
          data: T,
          metadata: FormatOptionLabelMeta<T, IsMulti>,
        ) => (
          <OptionLabel<T>
            data={data}
            getColors={getColors}
            formattedOptionPill={formatOptionPill?.(data, metadata)}
            getOptionLabel={options.getOptionLabel}
            getOptionLogo={options.getOptionLogo}
            getOptionIcon={options.getOptionIcon}
          />
        )}
      />
    </HiddenSelectWrapper>
  );
}

/**
 * Reusable react-async-select with our own styling, simple wrapper for improved types
 */
export function ReactAsyncSelect<
  IsMulti extends boolean,
  T extends OptionTypeBase = DefaultOptionType,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
>(options: ReactAsyncSelectExtendedProps<IsMulti, T, G>): JSX.Element {
  return <ReactSelect {...options} />;
}

/**
 * Reusable react-creatable-select with our own styling, simple wrapper for improved types
 */
export function ReactCreatableSelect<
  IsMulti extends boolean,
  T extends OptionTypeBase = DefaultOptionType,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
>(options: ReactCreatableSelectExtendedProps<IsMulti, T, G>): JSX.Element {
  return <ReactSelect isCreatable {...options} />;
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ReScan: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M12.6225 7.64463C12.6225 10.394 10.3938 12.6226 7.6445 12.6226C4.89517 12.6226 2.6665 10.394 2.6665 7.64463C2.6665 4.89529 4.89517 2.66663 7.6445 2.66663C10.3938 2.66663 12.6225 4.89529 12.6225 7.64463"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3332 13.3333L11.1665 11.1666"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M8.79343 7.16663V6.31529C8.79343 5.71263 8.27943 5.16663 7.64476 5.16663C7.01009 5.16663 6.49609 5.68063 6.49609 6.31529V7.16663"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8.9952 9.83329H6.29386C5.9352 9.83329 5.64453 9.54263 5.64453 9.18396V7.81596C5.64453 7.45729 5.9352 7.16663 6.29386 7.16663H8.9952C9.35386 7.16663 9.64453 7.45729 9.64453 7.81596V9.18396C9.64453 9.54263 9.35386 9.83329 8.9952 9.83329Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

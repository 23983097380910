import { calculateColorPalette } from './calculateColorPalette';
import { DEFAULT_CONFIGURABLE_COLORS } from './constants';
import type { PartialTheme, PrivacyCenterTheme } from './theme';

export const OLD_DEFAULT_FONT_URL = 'TODO';
export const DEFAULT_FONT_URL = '-';

/**
 * Create a new theme definition
 *
 * @param theme - The theme input
 * @returns The configured theme
 */
export function createTheme({
  name,
  colors = DEFAULT_CONFIGURABLE_COLORS,
  textStyles = {},
  componentStyles = {},
  GlobalStyles = () => '',
}: PartialTheme): PrivacyCenterTheme {
  return {
    name,
    colors: calculateColorPalette(colors),
    textStyles: {
      fontFamilyHeader: {
        name: 'neue-haas-grotesk-display',
        url: DEFAULT_FONT_URL,
        assets: [],
      },
      fontFamilyBody: {
        name: 'neue-haas-grotesk-display',
        url: DEFAULT_FONT_URL,
        assets: [],
      },
      ...textStyles,
    },
    componentStyles: {
      primaryButton: '',
      secondaryButton: '',
      emailButton: '',
      emailContainer: '',
      sideMenuText: '',
      hero: '',
      h1: '',
      h2: '',
      h3: '',
      body: '',
      heroBackground: '',
      takeControlConfirmation: '',
      ...componentStyles,
    },
    GlobalStyles,
  };
}

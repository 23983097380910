import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DsrTypeTrackingOptOut: React.FC<SvgProps> = ({
  color,
  color2,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M14.4977 23.6683C16.9987 26.3361 21.1671 26.3361 23.6681 23.835C26.1692 21.334 26.3359 17.1656 23.8349 14.6645L23.6681 14.4978"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1696 27.1697L23.8349 23.835"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M20 4.9939V4.9939C28.3368 4.9939 35.0063 11.6633 35.0063 20.0001V20.0001C35.0063 28.337 28.3368 35.0064 20 35.0064V35.0064C11.6632 35.0064 4.99375 28.337 4.99375 20.0001V20.0001C4.99375 11.6633 11.6632 4.9939 20 4.9939Z"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49521 29.6708L29.6707 8.49536"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M12.6636 18.9998C12.6636 17.3324 13.3306 15.6651 14.4977 14.4979V14.4979C15.6649 13.3308 17.3322 12.6638 18.9996 12.6638"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/**
 * This is the entry file for the application, only setup and boilerplate code.
 */

/* eslint-disable import/no-import-module-exports */
import './images/favicon.png';
import '@main/core-ui/bootstrap-theme.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { datadogLogs } from '@datadog/browser-logs';
import {
  ApolloProvider,
  DATADOG_LOGS_CLIENT_TOKEN,
  DATADOG_SAMPLE_RATE,
  DEPLOY_ENV,
} from '@main/core-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { DATADOG_SERVICE } from './args';
import SimpleErrorBoundary from './containers/SimpleErrorBoundary';
import { privacyCenterApolloClient } from './graphql';
import { PrivacyCenterRoutes } from './routes/Loadable';
import { privacyCenterStore } from './store';

const MOUNT_NODE: HTMLElement = document.getElementById('app') as HTMLElement;

// Module with hot reload
const persistor = persistStore(privacyCenterStore);

// polyfill IE11 & Safari 12-
if (!Intl.PluralRules) {
  require('intl-pluralrules'); // eslint-disable-line global-require
}

const render = (): void => {
  ReactDOM.render(
    <ApolloProvider client={privacyCenterApolloClient}>
      <BrowserRouter>
        <Provider store={privacyCenterStore}>
          <PersistGate loading={null} persistor={persistor}>
            <SimpleErrorBoundary>
              <PrivacyCenterRoutes />
            </SimpleErrorBoundary>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ApolloProvider>,
    MOUNT_NODE,
  );
};

// Enable log forwarding
if (DATADOG_LOGS_CLIENT_TOKEN) {
  datadogLogs.init({
    site: 'datadoghq.com',
    sampleRate: DATADOG_SAMPLE_RATE,
    env: DEPLOY_ENV,
    service: DATADOG_SERVICE,
    clientToken: DATADOG_LOGS_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
  });
}

if (module.hot) {
  // Hot reload-able React components and translation json files
  // Modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

// Load aXe tools for React in development only
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require, import/no-extraneous-dependencies
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js' as any)])) // eslint-disable-line @typescript-eslint/no-explicit-any,max-len
    .then(() => render())
    .catch((err) => {
      throw err;
    });
} else {
  render();
}
/* eslint-enable import/no-import-module-exports */

import { Img } from '@main/core-ui/src/Img';
import { resolveColor } from '@main/theme';
import { isDoubleByte } from '@main/utils';
import React, { MouseEventHandler } from 'react';
import { useTheme } from 'styled-components';

import { Icon, IconProps, IconType } from '../Icon';
import { LoadingSpinner } from '../LoadingSpinner';
import { FlexRowCenterVertical } from '../StyledWrappers';
import { LogoWrapper, StyledI } from './wrappers';

export { LogoWrapper };

/**
 * Props
 */
export interface LogoProps extends Omit<IconProps, 'type'> {
  /** The location the logo should link to, or the type of icon */
  logo: string;
  /** The SVG element to be used for the logo.  If present, overrides logo prop */
  svg?: JSX.Element;
  /** Strict flag to decide if space between children is to be rendered */
  extraSpace?: boolean;
  /** Children of the logo display */
  children?: React.ReactNode;
  /** The height of the image */
  height?: string;
  /** Specify the font size for an icon (falls back to height prop) */
  iconFontSize?: string;
  /** Shift in y the children */
  shiftY?: string;
  /** click handler */
  onClick?: MouseEventHandler<HTMLImageElement>;
  /** style overrides */
  style?: React.CSSProperties;
  /** is the logo loading */
  loading?: boolean;
}

/**
 * The main page logo in the top left corner
 */
export function Logo({
  children,
  logo,
  svg,
  style,
  extraSpace,
  iconFontSize,
  shiftY,
  color,
  loading,
  height = '30px',
  ...logoProps
}: LogoProps): JSX.Element {
  const theme = useTheme();
  const fontSize = iconFontSize || height;
  // backup to icon
  return (
    <FlexRowCenterVertical
      style={{
        color: color ? resolveColor()({ color, theme }) : 'inherit',
        display: 'inline-flex',
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        svg ||
        (logo && logo.includes('://') ? (
          <Img
            height={height}
            src={logo}
            alt={`${logo}-logo`}
            style={style}
            {...logoProps}
            onClick={logoProps.onClick}
          />
        ) : isDoubleByte(logo) ? (
          <StyledI className="fa" {...logoProps}>
            {logo}
          </StyledI>
        ) : logo && logo.indexOf('fa-') === 0 ? (
          <StyledI
            color={color}
            className={`fa fa-1x ${logo}`}
            {...logoProps}
          />
        ) : (
          <FlexRowCenterVertical
            style={{
              display: 'inline-flex',
              color: 'inherit',
              fontSize,
              ...style,
            }}
          >
            {logo && <Icon type={logo as IconType} {...logoProps} />}
          </FlexRowCenterVertical>
        ))
      )}
      {extraSpace && children && (
        <span style={{ marginLeft: 4 }}>
          <LogoWrapper shiftY={shiftY}>{children}</LogoWrapper>
        </span>
      )}
    </FlexRowCenterVertical>
  );
}

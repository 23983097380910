import React from 'react';

import { SvgProps } from '../enums';

export const FlagUA: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55178"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55178)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#3195F9"
      />
      <mask
        id="mask1_270_55178"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55178)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 6V12H16V6H0Z"
          fill="#FECA00"
        />
      </g>
    </g>
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Submitted: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 6.00002H2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 10H2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66683 8.00002H1.3335"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M3.75732 3.75735C5.47331 2.04136 8.05402 1.52803 10.2961 2.45671C12.5381 3.3854 14 5.57322 14 7.99999C14 10.4268 12.5381 12.6146 10.2961 13.5433C8.05402 14.472 5.47331 13.9586 3.75732 12.2426"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M6.3335 7.00002V5.66776C6.3335 5.42446 6.46605 5.20047 6.67932 5.08338C6.8926 4.96629 7.15273 4.97468 7.35802 5.10528L11.0247 7.43804C11.217 7.56041 11.3335 7.7726 11.3335 8.00058C11.3335 8.22856 11.217 8.44072 11.0246 8.56306L7.35791 10.8948C7.15262 11.0254 6.89251 11.0337 6.67927 10.9166C6.46602 10.7995 6.33349 10.5756 6.3335 10.3323V9.00002"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.3335 9L5.3335 8L6.3335 7"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

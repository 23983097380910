import React from 'react';

import { SvgProps } from '../enums';

export const FlagJM: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55025"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55025)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#009933"
      />
      <path
        // eslint-disable-next-line max-len
        d="M-0.0703066 -0.520566L-1.175 -1.43242V0V12V13.4324L-0.0703066 12.5206L7.19858 6.52057L7.82924 6L7.19858 5.47943L-0.0703066 -0.520566Z"
        fill="#272727"
        stroke="#FECA00"
        strokeWidth="1.35"
      />
      <path
        // eslint-disable-next-line max-len
        d="M16.0821 -0.530048L17.175 -1.39183V0V12V13.3918L16.0821 12.53L8.47264 6.53005L7.80041 6L8.47264 5.46995L16.0821 -0.530048Z"
        fill="#272727"
        stroke="#FECA00"
        strokeWidth="1.35"
      />
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagJE: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55024"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55024)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M-1.35074 0.622384L-2.37501 0.0030334V1.2V11.2V12.397L-1.35074 11.7776L6.91815 6.77761L7.87339 6.2L6.91815 5.62238L-1.35074 0.622384Z"
        fill="#F7FCFF"
        stroke="#E31D1C"
        strokeWidth="1.35"
      />
      <path
        // eslint-disable-next-line max-len
        d="M17.4604 0.616293L18.4744 0.0274086V1.2V11.2V12.3726L17.4604 11.7837L8.85103 6.7837L7.84596 6.2L8.85103 5.61629L17.4604 0.616293Z"
        fill="#F7FCFF"
        stroke="#E31D1C"
        strokeWidth="1.35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.83738 2.3515C6.83738 2.3515 6.40806 4.25066 7.89043 4.98006C7.89043 4.98006 9.31353 4.2044 8.99389 2.3515C8.99389 2.3515 8.38271 2.14706 7.89674 2.14706C7.41077 2.14706 6.83738 2.3515 6.83738 2.3515Z"
        fill="#E31D1C"
      />
      <path
        // eslint-disable-next-line max-len
        d="M6.71063 2.54761L6.38605 1.60175C6.99123 1.39407 7.51391 1.28793 7.96038 1.28793C8.41804 1.28793 8.89521 1.39922 9.39087 1.61687L8.98882 2.53248C8.61378 2.3678 8.27132 2.28793 7.96038 2.28793C7.63826 2.28793 7.21957 2.37296 6.71063 2.54761Z"
        fill="#FECA00"
      />
      <path
        // eslint-disable-next-line max-len
        d="M7.92499 3.95C8.27017 3.95 8.54999 3.67017 8.54999 3.325C8.54999 2.97982 8.27017 2.7 7.92499 2.7C7.57981 2.7 7.29999 2.97982 7.29999 3.325C7.29999 3.67017 7.57981 3.95 7.92499 3.95Z"
        fill="#FECA00"
      />
    </g>
  </svg>
);

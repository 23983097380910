import { transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Erased icon
 */
export const RequestErased: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="RequestStatusIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M17 5L14 2 10 2 7 5z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M11 18H9V7h2V18zM15 18h-2V7h2V18zM4 3H20V5H4z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M17,22H7c-1.1,0-2-0.9-2-2V3h14v17C19,21.1,18.1,22,17,22z M7,5v15h10V5H7z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M6 4H18V21H6z"
          fill={transparentize(0.7, theme.colors.secondary)}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

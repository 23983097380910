import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { OrganizationTier } from './enums';

export const SignupCodeInput = mkInput({
  name: 'SignupCodeInput',
  comment: 'Input to look up a signup code',
  fields: {
    code: {
      comment: 'The signup code',
      type: 'string',
    },
  },
});

/** Override type */
export type SignupCodeInput = SchemaToType<typeof SignupCodeInput>;

export const SignupCode = mkType({
  name: 'SignupCode',
  comment: 'A signup code for registering a new organization',
  fields: {
    id: {
      comment: 'The id of the signup code',
      type: 'id',
      modelName: 'signupCode',
    },
    code: {
      comment: 'The signup code',
      type: 'string',
    },
    tier: {
      comment: 'The tier the token is for',
      type: { OrganizationTier },
    },
    terms: {
      comment: 'The link to the custom terms and conditions',
      type: 'string',
      optional: true,
    },
    integrationNames: {
      comment:
        'The list of integrations that the token is being created in partnership with',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type SignupCode = SchemaToType<typeof SignupCode>;

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Phone: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.04665 10.9533C8.07165 9.97831 7.33665 8.88664 6.84831 7.77914C6.74498 7.54497 6.80581 7.27081 6.98665 7.08997L7.66915 6.40831C8.22831 5.84914 8.22831 5.05831 7.73998 4.56997L6.76165 3.59164C6.11081 2.94081 5.05581 2.94081 4.40498 3.59164L3.86165 4.13497C3.24415 4.75247 2.98665 5.64331 3.15331 6.52664C3.56498 8.70414 4.82998 11.0883 6.87081 13.1291C8.91165 15.17 11.2958 16.435 13.4733 16.8466C14.3566 17.0133 15.2475 16.7558 15.865 16.1383L16.4075 15.5958C17.0583 14.945 17.0583 13.89 16.4075 13.2391L15.43 12.2616C14.9416 11.7733 14.15 11.7733 13.6625 12.2616L12.91 13.015C12.7291 13.1958 12.455 13.2566 12.2208 13.1533C11.1133 12.6641 10.0216 11.9283 9.04665 10.9533Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

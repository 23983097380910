/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const StoreModernStyle: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.4999H2.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.92413 5.42563L3.28105 3.27679C3.5864 2.79323 4.11837 2.5 4.69027 2.5H15.3098C15.8817 2.5 16.4137 2.79324 16.7191 3.27682L18.0759 5.42564C18.2441 5.69197 18.3334 6.00051 18.3334 6.31549V8.33333C18.3334 8.79357 17.9603 9.16667 17.5 9.16667H2.50002C2.03978 9.16667 1.66669 8.79357 1.66669 8.33333V6.31551C1.66669 6.00052 1.75595 5.69197 1.92413 5.42563Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33331 13.3334H16.6666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33337 17.5001L3.33333 9.16675"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6666 17.5001L16.6666 9.16675"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33335 17.5001V9.16675"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import { QueueStatus } from '@transcend-io/privacy-types';
import { makeEnum } from '@transcend-io/type-utils';

/**
 * The status of the plugin run.
 */
export const PluginQueueStatus = makeEnum({
  ...QueueStatus,
});

/**
 * Overload type
 */
export type PluginQueueStatus =
  (typeof PluginQueueStatus)[keyof typeof PluginQueueStatus];

/**
 * The statuses that indicate a successful completion
 */
export const PluginQueueStatusSuccess = makeEnum({
  /** Successfully resolved */
  Resolved: 'RESOLVED',
  /** Skipped processing because already completed */
  Skipped: 'SKIPPED',
});

/**
 * Overload type
 */
export type PluginQueueStatusSuccess =
  (typeof PluginQueueStatusSuccess)[keyof typeof PluginQueueStatusSuccess];

/**
 * The classification status of the sub-datapoint.
 */
export const ContentClassificationStatus = makeEnum({
  /** The sub-datapoint is not yet sampled and classified */
  Unclassified: 'UNCLASSIFIED',
  /** Re-fetch samples  */
  RefetchSample: 'REFETCH_SAMPLES',
  /** Re-fetch samples with not null filter  */
  RefetchSampleFilterNonNull: 'REFETCH_SAMPLES_FILTER_NOT_NULL',
  /** An error occurred */
  Error: 'ERROR',
  /** An error occurred which can't be fixed by retry */
  NonRetryableError: 'NON_RETRYABLE_ERROR',
  /**
   * The Item has been successfully ingested by our SQS task queue,
   * and is currently in line to being processed.
   */
  RemoteProcessing: 'REMOTE_PROCESSING',
  /** No sample data found for sub-datapoint */
  NoSampleData: 'NO_SAMPLE_DATA',
  /** The sub-datapoint is sampled and classified */
  Classified: 'CLASSIFIED',
});

/**
 * Overload type
 */
export type ContentClassificationStatus =
  (typeof ContentClassificationStatus)[keyof typeof ContentClassificationStatus];

/**
 * The sampling status of file datapoint.
 */
export const UnstructuredDataSamplingStatus = makeEnum({
  /** The datapoint is not yet sampled */
  UnSampled: 'UNSAMPLED',
  /** Re-fetch samples  */
  RefetchSample: 'REFETCH_SAMPLES',
  /** An error occurred */
  Error: 'ERROR',
  /** An error occurred which can't be fixed by retry */
  NonRetryableError: 'NON_RETRYABLE_ERROR',
  /**
   * The Item has been successfully ingested by our SQS task queue,
   * and is currently in line to being processed.
   */
  RemoteProcessing: 'REMOTE_PROCESSING',
  /** No sample data found for datapoint */
  NoSampleData: 'NO_SAMPLE_DATA',
  /** The datapoint is sampled */
  Sampled: 'SAMPLED',
  /** Skip sampling like for non File datapoint */
  SkipSampling: 'SKIP_SAMPLING',
  /** File is not supported for sampling */
  NotSupported: 'NOT_SUPPORTED',
});

/**
 * Overload type
 */
export type UnstructuredDataSamplingStatus =
  (typeof UnstructuredDataSamplingStatus)[keyof typeof UnstructuredDataSamplingStatus];

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const RemoveTable: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7236 11.0571L13.6093 12.9427"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M11.7241 12.9428L13.6097 11.0572"
      stroke={color}
      strokeLinecap="round"
    />
    <ellipse
      cx="12.6667"
      cy="12"
      rx="2.66667"
      ry="2.66667"
      stroke={color}
      strokeWidth="1.2"
    />
    <path
      d="M14 7.33333V4C14 2.89543 13.1046 2 12 2H4C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H8"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99984 2V8"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.99984 2V14"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.96001H8"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 5.96001H14"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { calculateColorPalette } from './calculateColorPalette';
import { DEFAULT_CONFIGURABLE_COLORS } from './constants';
import { ColorPaletteColor } from './theme';

const DEFAULT_COLOR_PALETTE = calculateColorPalette(
  DEFAULT_CONFIGURABLE_COLORS,
);

/**
 * Check if a color is a known theme color
 *
 * @param color - The color to check
 * @returns True if the color is in the color palette
 */
export function isKnownColor(color?: string): color is ColorPaletteColor {
  return !!color && color in DEFAULT_COLOR_PALETTE;
}

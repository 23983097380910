import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CopySimple: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.08212"
      y="3.08203"
      width="8.17007"
      height="8.17007"
      rx="2"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M3.08212 8.91787H1.91497C1.27037 8.91787 0.747818 8.39532 0.747818 7.75072V1.91496C0.747818 1.27035 1.27037 0.747803 1.91497 0.747803H7.75073C8.39534 0.747803 8.91789 1.27035 8.91789 1.91496V3.08211"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { Location, useLocation } from 'react-router-dom';

import { useQueryParams } from './useQueryParams';
import { useRedirect } from './useRedirect';

/**
 * Routing props
 */
export interface UseRouterProps {
  /** The current page location */
  location: Location;
  /** The parsed query params, enforced to be strings */
  queryParams: { [queryParamName in string]: string | undefined };
  /** Callback to redirect to new page */
  redirect: (location: Location | string, newTab?: boolean) => void;
}

/**
 * The useRouter hook provides values useful for routing
 *
 * @returns UseRouterProps
 */
export function useRouter(): UseRouterProps {
  const location = useLocation();
  const queryParams = useQueryParams();
  const redirect = useRedirect();
  return {
    location,
    redirect,
    queryParams,
  };
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ClockAndDataSilo: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M29.3389 22.6694V18.6677C29.3389 17.5615 27.098 16.6669 24.3368 16.6669C21.5757 16.6669 19.3361 17.563 19.3347 18.6677V26.671C19.3361 27.7758 21.5742 28.6719 24.3368 28.6719C27.0994 28.6719 29.3375 27.7758 29.3389 26.671V18.6677"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1709 18.489L15.0476 15.5158V9.31189"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M26.3634 11.9983C24.9655 5.8751 19.083 1.86523 12.8725 2.80209C6.66204 3.73895 2.22422 9.30565 2.69484 15.5688C3.16546 21.8318 8.38534 26.6729 14.6661 26.6711"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3362 18.6677C19.3362 19.7725 21.5757 20.6686 24.3383 20.6686C27.1008 20.6686 29.3389 19.7725 29.3389 18.6677"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3362 22.6694C19.3362 23.7742 21.5757 24.6703 24.3383 24.6703C27.1008 24.6703 29.3403 23.7742 29.3403 22.6694"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

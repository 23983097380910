import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ExternalLink: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0833 4.24984V1.9165H7.75"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.58333 5.4165L10.0833 1.9165"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.6667 6.58325V7.74992C10.6667 9.36075 9.36083 10.6666 7.75 10.6666H4.25C2.63916 10.6666 1.33333 9.36075 1.33333 7.74992V4.24992C1.33333 2.63909 2.63916 1.33325 4.25 1.33325H5.41666"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { parse, ParsedQuery, stringify } from 'query-string';

/**
 * The fields of a URL that can be changed
 */
export interface TransformOptions<T = any> {
  /** The hash of the url */
  hash?: string;

  /** The hostname of the url */
  hostname?: string;

  /** The pathname of the url */
  pathname?: string;

  /** The port of the url */
  port?: string;

  /** The protocol of the url */
  protocol?: string;

  /** The username for authentication to the url */
  username?: string;

  /** The password for authentication to the url */
  password?: string;

  /** A function that updates the old query parameters */
  searchTransform?: (oldSearch: ParsedQuery<T>) => ParsedQuery<T>;
}

/**
 * A variant of the standard library URL from node that has additional
 * update methods that return new, Immutable objects.
 */
export class ImmutableUrl {
  /** The URL object that cannot be changed */
  private url: Readonly<URL>;

  /**
   * Constructor
   *
   * @param url - The url to store
   * @param base - Base of URL
   */
  constructor(url: URL | string, base?: string | URL) {
    this.url = typeof url === 'string' ? new URL(url, base) : url;
  }

  /**
   * Tests if a string is a valid url.
   *
   * "https://www.transcend.io" is a complete url
   * "/login" is not
   *
   * @param url - A string that will be tested for if it is a URL
   * @returns true or false, depending on if the input is a full URL or not
   */
  public static isCompleteUrl(url: string): boolean {
    try {
      // eslint-disable-next-line no-new
      new ImmutableUrl(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Getter for the origin of the url
   *
   * @returns the origin
   */
  public get origin(): string {
    return this.url.origin;
  }

  /**
   * Getter for the hash of the url
   *
   * @returns the hash
   */
  public get hash(): string {
    return this.url.hash;
  }

  /**
   * Getter for the host of the url
   *
   * @returns the host
   */
  public get host(): string {
    return this.url.host;
  }

  /**
   * Getter for the hostname of the url
   *
   * @returns the hostname
   */
  public get hostname(): string {
    return this.url.hostname;
  }

  /**
   * Getter for the href of the url
   *
   * @returns the href
   */
  public get href(): string {
    return this.url.href;
  }

  /**
   * Getter for the pathname of the url
   *
   * @returns the pathname
   */
  public get pathname(): string {
    return this.url.pathname;
  }

  /**
   * Getter for the port of the url
   *
   * @returns the port
   */
  public get port(): string {
    return this.url.port;
  }

  /**
   * Getter for the protocol of the url
   *
   * @returns the protocol
   */
  public get protocol(): string {
    return this.url.protocol;
  }

  /**
   * Getter for the search parameters of the url
   *
   * @returns the search parameters as a string
   */
  public get search(): string {
    return this.url.search;
  }

  /**
   * Getter for the parsed search parameters
   *
   * @returns the search parameters as an object
   */
  public get searchMap(): ParsedQuery {
    return parse(this.search.replace(/^\?/, ''));
  }

  /**
   * Getter for the username of the url
   *
   * @returns the username
   */
  public get username(): string {
    return this.url.username;
  }

  /**
   * Getter for the password of the url
   *
   * @returns the password
   */
  public get password(): string {
    return this.url.password;
  }

  /**
   * Getter for the protocol and the url
   *
   * @returns the password
   */
  public get protocolHost(): string {
    return `${this.protocol}//${this.host}`;
  }

  /**
   * Converts the url to JSON
   *
   * @returns a JSON string representation of the url
   */
  public toJSON(): string {
    return this.url.toJSON();
  }

  /**
   * Converts the url to a string
   *
   * @returns a string representation of the url
   */
  public toString(): string {
    return this.url.toString();
  }

  /**
   * Takes in a string of URL components, adding each of them to the current URL.
   *
   * It is equivalent to use something like:
   * url.transform({ pathname: '/login', hash: '#someHash'})
   * and
   * url.addUrlParts('/login#someHash')
   *
   * You should use this method when you have a string like `/login?redirect=/some/url` and want
   * to update an existing ImmutableUrl. If you have the option to separate that string out to
   * a pathname (/login) and a search parameter map ({ redirect: '/some/url' }), you should use `transform`
   * instead.
   *
   * @param parts - The URL parts to append to the current URL
   * @returns an new ImmutableUrl
   */
  public addUrlParts(parts: string): ImmutableUrl {
    // In order to parse the URL parts, we add them to a dummy URL and let the URL library
    // handle the rest of the parsing for us
    let url: ImmutableUrl;
    try {
      url = new ImmutableUrl(`${this.origin}${parts}`);
    } catch (error) {
      throw Error(`Could not parse the URL parts: ${parts}`);
    }

    return this.transform({
      hash: url.hash || this.hash,
      pathname: url.pathname || this.pathname,
      searchTransform: (oldParams) => ({ ...oldParams, ...url.searchMap }),
    });
  }

  /**
   * Returns a new ImmutableURL with the given fields set
   *
   * @param fields - New fields to update into a new object
   * @returns a new ImmutableURL
   */
  public transform(fields: TransformOptions): ImmutableUrl {
    const newUrl = new URL(this.url.href);
    newUrl.hash = fields.hash || this.hash;
    newUrl.hostname = fields.hostname || this.hostname;
    newUrl.pathname = fields.pathname || this.pathname;
    newUrl.port = fields.port || this.port;
    newUrl.protocol = fields.protocol || this.protocol;
    newUrl.search = fields.searchTransform
      ? stringify(fields.searchTransform(this.searchMap))
      : this.search;
    return new ImmutableUrl(newUrl);
  }
}

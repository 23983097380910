import {
  CommunicationIdentifierType,
  IdentifierType,
  RequestAction,
} from '@transcend-io/privacy-types';
import type { Requirize } from '@transcend-io/type-utils';
import { createEnum } from '@transcend-io/type-utils';

import { DataPointMetadata, DataPointPreview, DataSubject } from './schema';

/**
 * Previews for RequestsRedactionPreview component which would require subjectivePaths into the contents object
 */
export type RedactionDataPointPreview = Omit<DataPointPreview, 'metadata'> & {
  /** Datapoint metadata */
  metadata: Requirize<DataPointMetadata, 'subjectivePaths'>;
};

/**
 * Determines whether datapoint is redaction-relevant
 *
 * @param dataPoint - The datapoint to check
 * @returns Whether the datapoint is a redaction datapoint (has subjective paths in its metadata)
 */
export function isRedactionDataPoint(
  dataPoint: DataPointPreview | RedactionDataPointPreview,
): dataPoint is RedactionDataPointPreview {
  return !!dataPoint.metadata.subjectivePaths;
}

/**
 * The identifier models that can be used to communicate with the data subject
 */
export const IDENTIFIER_COMMUNICATION_MODELS = Object.values(
  CommunicationIdentifierType,
);

/**
 * The communication models keyed by model name
 */
export const IDENTIFIER_COMMUNICATION_MODEL = createEnum(
  IDENTIFIER_COMMUNICATION_MODELS,
);

/**
 * Checks if an identifier can be used by Transcend to communicate with the data subject
 *
 * @param name - The name of the identifier to check
 * @returns True if that identifier model can be used to communicate with the data subject
 */
export function isCommunicationModel(
  name: IdentifierType,
): name is CommunicationIdentifierType {
  return name in IDENTIFIER_COMMUNICATION_MODEL;
}

/**
 * A data subject that has OAuth enabled. It must implement all OAuth keys
 */
export type OAuthDataSubject = Requirize<
  DataSubject,
  'redirectUrl' | 'oauthClientId' | 'responseType'
>;

/**
 * Check if a data subject is oauth compatible
 *
 * @param subject - The subject to test
 * @returns Boolean regarding whether subject has oauth setup
 */
export const isOauthSubject = (
  subject: DataSubject,
): subject is OAuthDataSubject =>
  !!subject.redirectUrl && !!subject.oauthClientId && !!subject.responseType;

/**
 * A data subject that has JWT enabled. It must implement all JWT keys
 */
export type JWTDataSubject = Requirize<DataSubject, 'redirectUrl'>;

/**
 * Check if a data subject is JWT compatible
 *
 * @param subject - The subject to test
 * @returns True if the subject is using JWT authentication
 */
export const isJwtSubject = (subject: DataSubject): subject is JWTDataSubject =>
  !!subject.redirectUrl && !subject.oauthClientId && !subject.oauthScope;

// The valid actions indexed by type
const VALID_ACTIONS = createEnum(Object.values(RequestAction));

/**
 * Validate that the webhook DSR action type is valid
 *
 * @param type - The type to validate
 * @returns True if a valid request action
 */
export function isValidActionType(type: string): type is RequestAction {
  return type in VALID_ACTIONS;
}

/**
 * Check if the identifier is a known identifier type
 *
 * @param identifier - The identifier to check
 * @returns True if the identifier is in IdentifierType
 */
export const isIdentifierType = (
  identifier: string,
): identifier is IdentifierType =>
  Object.values(IdentifierType).includes(identifier as IdentifierType);

import { AssessmentQuestionType } from '@transcend-io/privacy-types';

import { ConditionalLogicHelperOptions, exhaustiveGuard } from './types';

export const contains = ({
  questionToCheck,
  comparisonOperands,
}: Pick<
  ConditionalLogicHelperOptions,
  'questionToCheck' | 'comparisonOperands'
>): boolean => {
  const { type, selectedAnswers } = questionToCheck;
  switch (type) {
    case AssessmentQuestionType.LongAnswer:
    case AssessmentQuestionType.ShortAnswer:
      // Operand should exist in the selected answer
      if (selectedAnswers[0].value?.includes(comparisonOperands[0])) {
        return true;
      }
      break;
    case AssessmentQuestionType.SingleSelect:
    case AssessmentQuestionType.MultiSelect:
    case AssessmentQuestionType.File:
    case AssessmentQuestionType.Description:
      // SingleSelect, MultiSelect, File, and Description questions do not support this operator
      break;
    default:
      // type should always fall into the cases above
      exhaustiveGuard(type);
      break;
  }
  return false;
};

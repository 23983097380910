import difference from 'lodash/difference';

import { TypescriptEnum } from '@transcend-io/type-utils';

/**
 * The default iterable instance that can be indexed
 */
export type IndexableInstance<TIndexKey extends string> = {
  [iterableKey in TIndexKey]?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

/**
 * An object that can be indexed
 */
export type Indexable<
  TIndexKey extends string,
  TIndexItem extends IndexableInstance<TIndexKey>,
> = TIndexItem;

/**
 * Extract the result
 */
export type Getter<T, I> = (instance: I, ind: number) => T | I;

/**
 * A function can be provided to determine the key
 */
export type GetKey<
  TIndexKey extends string,
  I extends IndexableInstance<TIndexKey>,
> = (obj: Indexable<TIndexKey, I>, index: number) => string;

/**
 * Options for injectByJS
 */
export interface IndexByOptions<TLookup, TIndexItem> {
  /** When true, convert the key to lower case */
  lower?: boolean;
  /** Any an arbitrary reduce step */
  getter?: Getter<TLookup, TIndexItem>;
  /** Throw an error when two items have the same key */
  errOnDup?: boolean;
  /**
   * Enforce that the resulting index has keys equal to an enum.
   * This will set `errOnDup = true` no matter what
   */
  matchesEnum?: TypescriptEnum;
  /** Add an increment when the key has a duplicate */
  incOnDup?: boolean;
}

/**
 * The indexed results
 */
export type IndexResult<TLookupKey extends string, TLookup> = {
  [key in TLookupKey]: TLookup;
};

/**
 * Index an object by an attribute
 *
 * ```typescript
 * // The items to index
 * const items = [];
 * // The name to index by
 * const key = 'id';
 * // Returns {}
 * indexBy(items, key);
 * ```
 *
 * Once can specify an enum as the RK value and the resulting index will be enforced to only have lookups
 * by that enum type, however this is not logically typed, so be careful. May want to remove this...
 *
 * @param iterable - The list of items to index
 * @param searchKey - The name to key by or the key by function
 * @param options - Additional options
 * @throws {Error} If `errOnDup=true` and there is a duplicate key that is indexed
 * @returns A new object/dictionary indexed by the `searchKey` parameter
 */
export function indexBy<
  // The resulting index should have keys of this type
  TLookupKey extends string,
  // The search key type is an attribute in I
  TIndexKey extends string,
  // The input type indexBy(x) -> where x is an array where each element is type I
  TIndexItem extends IndexableInstance<TIndexKey>,
  // The type that is ultimately returned
  TLookup = TIndexItem,
>(
  iterable: Indexable<TIndexKey, TIndexItem>[],
  searchKey: TIndexKey | GetKey<TIndexKey, TIndexItem>,
  options: IndexByOptions<TLookup, TIndexItem> = {},
): IndexResult<TLookupKey, TLookup> {
  // Deconstruct the options
  const {
    lower = false,
    getter,
    errOnDup = false,
    incOnDup = false,
    matchesEnum,
  } = options;

  // Throw an error always if a matchesEnum is provided
  const throwErrorOnDuplicate = errOnDup || !!matchesEnum;

  // Get the value that is indexed
  const getValue = getter || ((x) => x);

  // Get the key to use
  const getKey =
    typeof searchKey === 'string'
      ? (obj: IndexableInstance<TIndexKey>): string => {
          const resultKey = obj[searchKey];
          return resultKey;
        }
      : searchKey;

  // Keep track of duplicates
  const duplicateCount: { [lookupKey in string]: number } = {};

  // Reduce to an object
  const result = iterable.reduce<IndexResult<TLookupKey, TLookup>>(
    (acc, cur, ind) => {
      // They index key
      const rawKey = getKey(cur, ind);
      let key = lower ? rawKey.toLowerCase() : rawKey;

      // Determine the number of times this key has been seen
      duplicateCount[key] = duplicateCount[key] ? duplicateCount[key] + 1 : 1;

      // Throw an error on duplicate key
      if (throwErrorOnDuplicate && key in acc) {
        throw new Error(`Duplicate key error: ${key}`);
      }

      // Increment the key if setting is on
      if (incOnDup && key in acc) {
        key = `${key}-${duplicateCount[key] - 1}`;
      }

      // Lower key if option set, apply getter
      return Object.assign(acc, { [key]: getValue(cur, ind) });
    },
    {} as any as IndexResult<TLookupKey, TLookup>, // eslint-disable-line @typescript-eslint/no-explicit-any
  );

  // Enforce that the enums match
  if (matchesEnum) {
    const expectedKeys = Object.values(matchesEnum);
    const gotKey = Object.keys(result);
    const missing = difference(expectedKeys, gotKey);
    const extra = difference(gotKey, expectedKeys);
    if (missing.length > 0 || extra.length > 0) {
      throw new Error(
        `Failed to enforce matchesEnum: extra -- "${extra.join(
          '", "',
        )}" -- missing -- "${missing.join('", "')}"`,
      );
    }
  }

  return result;
}

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const PlaintextContextInput = mkInput({
  name: 'PlaintextContextInput',
  comment: 'Form input plaintext',
  fields: {
    name: {
      comment: 'The name of the form input.',
      type: 'string',
    },
    value: {
      comment: 'The value of the form input',
      type: 'string',
    },
  },
});

/** Override type */
export type PlaintextContextInput = SchemaToType<typeof PlaintextContextInput>;

export const PlaintextContext = mkType({
  name: 'PlaintextContext',
  comment: 'Custom headers to include in outbound webhook',
  fields: {
    name: PlaintextContextInput.fields.name,
    value: PlaintextContextInput.fields.value,
  },
});

/** Override type */
export type PlaintextContext = SchemaToType<typeof PlaintextContext>;

/**
 * Fields that a enricher can be ordered by
 */
export enum EnricherOrderField {
  /** The title of the enricher */
  Title = 'title',
  /** When the enricher was created */
  CreatedAt = 'createdAt',
  /** The time the enricher was updated */
  UpdatedAt = 'updatedAt',
}

import { useEffect } from 'react';

/**
 * List of focusable HTML elements
 * Note: This hook assumes that the component has valid HTML markup,
 * and invalid HTML may lead to unpredictable behavior.
 *
 * Ex: Labels and inputs should be properly associated, have name attributes, etc.
 */
const FOCUSABLE = `
  a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]),
  iframe, object, embed, video, audio,
  [tabindex]:not([tabindex^="-"])
`;

export interface UseFocusTrapProps {
  /** The element in which to trap focus */
  element?: HTMLElement;
  /**  Callback once focus is trapped */
  onTrapped?: () => void;
}

/**
 * Hook to trap focus within a given element
 *
 * @param args - UseFocusTrapProps
 */
export const useFocusTrap = ({
  element,
  onTrapped,
}: UseFocusTrapProps): void => {
  const keydownHandler = (e: KeyboardEvent): void => {
    if (!element) {
      return;
    }

    if (e.code === 'Tab') {
      /**
       * Re-query the DOM for focusable nodes on each tab.
       * Do not memoize the `NodeList` because `querySelectorAll` is static
       * and will not detect when nodes are added to or removed from the DOM.
       */
      const nodes: HTMLElement[] = Array.from(
        element.querySelectorAll(FOCUSABLE),
      );
      if (nodes.length > 0) {
        const firstFocusableEl = nodes[0];
        const lastFocusableEl = nodes[nodes.length - 1];

        if (
          e.shiftKey &&
          firstFocusableEl.isEqualNode(document.activeElement)
        ) {
          e.preventDefault();
          lastFocusableEl.focus();
        } else if (
          !e.shiftKey &&
          lastFocusableEl.isEqualNode(document.activeElement)
        ) {
          e.preventDefault();
          firstFocusableEl.focus();
        }
      }
    }
  };

  useEffect(() => {
    if (element) {
      element.addEventListener('keydown', keydownHandler);

      if (onTrapped) {
        onTrapped();
      }
    }

    return () => {
      if (element) {
        element.removeEventListener('keydown', keydownHandler);
      }
    };
    // keydownHandler is a constant so it will never change, and onTrapped should not change either
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);
};

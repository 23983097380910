import React from 'react';

import { SvgProps } from '../enums';

export const FlagPT: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54997"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54997)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0H16V12H4V0Z"
        fill="#EF0000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H6V12H0V0Z"
        fill="#2F8D00"
      />
      <mask
        id="mask1_270_54997"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="8"
        height="8"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.95236 9.9036C3.82212 9.9036 2.09521 8.1559 2.09521 5.99999C2.09521 3.84408 3.82212 2.09637 5.95236 2.09637C8.0826 2.09637 9.8095 3.84408 9.8095 5.99999C9.8095 8.1559 8.0826 9.9036 5.95236 9.9036Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54997)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.71423 2H6.21423V3.3494H5.71423V2Z"
          fill="#FFE017"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.71423 8.74698H6.21423V9.99999H5.71423V8.74698Z"
          fill="#FFE017"
        />
        <path
          // eslint-disable-next-line max-len
          d="M2.15725 6.20189L2.01746 5.84324C3.56005 5.2274 4.72433 4.83025 5.51432 4.65126C6.67338 4.38866 7.94692 4.19437 9.33502 4.06824L9.36909 4.45223C7.99691 4.57692 6.7397 4.76872 5.59755 5.02749C4.83107 5.20115 3.68296 5.59279 2.15725 6.20189Z"
          fill="#FFE017"
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.57042 3.83192L9.45698 4.19997C8.29112 3.83192 7.34141 3.67371 6.61139 3.72271L6.58618 3.33801C7.36998 3.2854 8.36354 3.45091 9.57042 3.83192Z"
          fill="#FFE017"
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.9738 5.88988L9.7583 6.20781C9.16396 5.79517 8.43174 5.5879 7.55664 5.5879V5.20236C8.50533 5.20236 9.31271 5.4309 9.9738 5.88988Z"
          fill="#FFE017"
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.65101 6.00528L9.7495 6.37771C8.46818 6.72478 7.26521 6.92169 6.14049 6.96806C5.00801 7.01475 3.68808 6.81714 2.17957 6.37671L2.28516 6.00628C3.75701 6.436 5.03733 6.62768 6.12498 6.58284C7.22039 6.53768 8.39577 6.34529 9.65101 6.00528Z"
          fill="#FFE017"
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.50578 7.81793L8.90361 8.43527C7.75926 7.29199 6.7518 6.4745 5.88742 5.98332C5.00826 5.48374 3.86177 4.97645 2.44971 4.4635L2.73928 3.64703C4.19298 4.17511 5.38165 4.70106 6.30706 5.22692C7.24724 5.76118 8.31142 6.62468 9.50578 7.81793Z"
          fill="#FFE017"
        />
        <path
          // eslint-disable-next-line max-len
          d="M2.72915 8.16763L2.50122 7.85871C2.53305 7.83466 2.58984 7.79454 2.66809 7.74371C2.79729 7.65977 2.94076 7.57597 3.09512 7.49767C3.52028 7.282 3.94675 7.14734 4.3535 7.13497C4.3734 7.13437 4.39321 7.13406 4.41293 7.13406V7.51961C4.39704 7.51961 4.38105 7.51985 4.36495 7.52034C4.02216 7.53076 3.64573 7.64962 3.26583 7.84234C3.12423 7.91417 2.99229 7.99123 2.87389 8.06815C2.83267 8.09493 2.7962 8.11969 2.76496 8.14176C2.74674 8.15463 2.73465 8.16348 2.72915 8.16763Z"
          fill="#FFE017"
        />
        <path
          // eslint-disable-next-line max-len
          d="M2.90601 8.30127L3.00194 7.92816C4.272 8.26263 5.25114 8.4293 5.93449 8.4293C6.61528 8.4293 7.62857 8.2327 8.96779 7.83813L9.07427 8.20831C7.70338 8.61221 6.65898 8.81485 5.93449 8.81485C5.21255 8.81485 4.20468 8.64328 2.90601 8.30127Z"
          fill="#FFE017"
        />
        <path
          d="M2.41125 4.8472L3.01479 4.23123L3.89845 5.11805L3.29492 5.73402L2.41125 4.8472Z"
          fill="#FFE017"
        />
        <path
          d="M7.41797 7.92385L7.86689 7.18488L9.2687 8.0571L8.81978 8.79608L7.41797 7.92385Z"
          fill="#FFE017"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.09521 5.99999C2.09521 8.1559 3.82212 9.9036 5.95236 9.9036C8.0826 9.9036 9.8095 8.1559 9.8095 5.99999C9.8095 3.84408 8.0826 2.09637 5.95236 2.09637C3.82212 2.09637 2.09521 3.84408 2.09521 5.99999ZM9.33331 6.04818C9.33331 7.91131 7.84093 9.42168 5.99998 9.42168C4.15903 9.42168 2.66664 7.91131 2.66664 6.04818C2.66664 4.18505 4.15903 2.67469 5.99998 2.67469C7.84093 2.67469 9.33331 4.18505 9.33331 6.04818Z"
        fill="#F9E813"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.82837 3.59262V6.72298C4.03444 7.96202 4.72867 8.58153 5.91106 8.58153C7.09346 8.58153 7.78769 7.96202 7.99376 6.72298V3.59262H3.82837Z"
        fill="white"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.0426 3H8.57942V6.71137L8.57479 6.73706C8.30732 8.21984 7.35914 9 5.81101 9C4.26288 9 3.3147 8.21984 3.04724 6.73706L3.0426 6.71137V3Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52378 3.953H3.62378V4.853H4.52378V3.953ZM8.02378 3.953H7.12378V4.853H8.02378V3.953Z"
        fill="#F9E813"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3.62378 5.953H4.52378V6.853H3.62378V5.953ZM7.17357 5.96717H8.07357V6.86717H7.17357V5.96717ZM7.18719 7.42463H6.28719V8.32463H7.18719V7.42463Z"
        fill="#F9E813"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.32422 7.42062H5.22422V8.32062H4.32422V7.42062Z"
        fill="#F9E813"
      />
      <path
        // eslint-disable-next-line max-len
        d="M4.42603 4.35535H7.39589V6.5758C7.39589 6.5758 7.1755 7.72245 5.91096 7.72245C4.64641 7.72245 4.42603 6.5758 4.42603 6.5758V4.35535Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.61902 4.60242H6.27918V5.08315C6.27918 5.08315 6.2302 5.33141 5.9491 5.33141C5.66801 5.33141 5.61902 5.08315 5.61902 5.08315V4.60242Z"
        fill="#013399"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.61902 5.66266H6.27918V6.1434C6.27918 6.1434 6.2302 6.39165 5.9491 6.39165C5.66801 6.39165 5.61902 6.1434 5.61902 6.1434V5.66266Z"
        fill="#013399"
      />
      <path
        // eslint-disable-next-line max-len
        d="M6.4762 5.66266H7.13636V6.1434C7.13636 6.1434 7.08737 6.39165 6.80628 6.39165C6.52519 6.39165 6.4762 6.1434 6.4762 6.1434V5.66266Z"
        fill="#013399"
      />
      <path
        // eslint-disable-next-line max-len
        d="M4.76184 5.66266H5.42201V6.1434C5.42201 6.1434 5.37302 6.39165 5.09192 6.39165C4.81083 6.39165 4.76184 6.1434 4.76184 6.1434V5.66266Z"
        fill="#013399"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.61902 6.7229H6.27918V7.20364C6.27918 7.20364 6.2302 7.45189 5.9491 7.45189C5.66801 7.45189 5.61902 7.20364 5.61902 7.20364V6.7229Z"
        fill="#013399"
      />
    </g>
  </svg>
);

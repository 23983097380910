/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const EmailLetterMore: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16667 15H5.83333C3.99238 15 2.5 13.5076 2.5 11.6667V5.83333C2.5 3.99238 3.99238 2.5 5.83333 2.5H14.1667C16.0076 2.5 17.5 3.99238 17.5 5.83333V8.33333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83331 6.25L9.33717 7.77772C9.76033 7.96223 10.2411 7.96295 10.6648 7.77973L14.1666 6.26552"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="15"
      cy="14.1666"
      rx="3.33333"
      ry="3.33333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0412 14.1667C16.0411 14.1897 16.0225 14.2083 15.9995 14.2083C15.9765 14.2083 15.9578 14.1897 15.9578 14.1667C15.9578 14.1437 15.9765 14.125 15.9995 14.125C16.0225 14.125 16.0411 14.1436 16.0412 14.1666V14.1667"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0412 14.1667C14.0411 14.1897 14.0225 14.2083 13.9995 14.2083C13.9765 14.2083 13.9578 14.1897 13.9578 14.1667C13.9578 14.1437 13.9765 14.125 13.9995 14.125C14.0225 14.125 14.0411 14.1436 14.0412 14.1666V14.1667"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import React from 'react';

import { SvgProps } from '../enums';

export const FlagAZ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55125"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55125)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#AF0100"
      />
      <mask
        id="mask1_270_55125"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55125)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#3CA5D9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#73BE4A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.58088 7.45333C7.90739 7.29804 7.38035 6.76856 7.38734 5.9916C7.39389 5.2643 7.86303 4.64905 8.60377 4.48185C9.34451 4.31465 9.99611 4.66724 9.99611 4.66724C9.79195 4.21328 9.08124 3.89496 8.49829 3.89649C7.41316 3.89934 6.25548 4.72775 6.2441 5.9928C6.2323 7.30432 7.48052 8.04993 8.57905 8.04705C9.4597 8.04474 9.87805 7.47795 9.96484 7.23694C9.96484 7.23694 9.25437 7.60862 8.58088 7.45333ZM9.00889 6.71035L9.59667 6.30133L10.1845 6.71035L9.97709 6.02494L10.5477 5.59231L9.83179 5.57772L9.59667 4.90133L9.36156 5.57772L8.64562 5.59231L9.21625 6.02494L9.00889 6.71035Z"
          fill="#F7FCFF"
        />
      </g>
    </g>
  </svg>
);

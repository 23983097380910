/* eslint-disable max-lines */
import { UserPreview } from '@main/access-control-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { BusinessEntitiesFiltersInput } from './businessEntity';
import { DataSiloFiltersInput } from './dataSilo';
import { DataSubCategoryFiltersInput } from './dataSubCategory';
import { DataReportExportableTables, DataReportRawOrderField } from './enums';
import { ProcessingPurposeCategoryFiltersInput } from './processingPurpose';
import { SubDataPointFiltersInput } from './subDataPoint';
import { VendorsFiltersInput } from './vendor';

export const DataReportFilterInput = mkInput({
  name: 'DataReportFilterInput',
  comment: 'The filters the applied to the exported data',
  fields: {
    [DataReportExportableTables.DataSilos]: {
      comment: 'The input for determining what data silos to filter',
      type: DataSiloFiltersInput,
    },
    [DataReportExportableTables.SubDataPoints]: {
      comment: 'The input for determining what subdatapoints to filter',
      type: SubDataPointFiltersInput,
    },
    [DataReportExportableTables.Vendors]: {
      comment: 'The input for determining what vendors to filter',
      type: VendorsFiltersInput,
    },
    [DataReportExportableTables.DataSubCategories]: {
      comment: 'The input for determining what data subcategories to filter',
      type: DataSubCategoryFiltersInput,
    },
    [DataReportExportableTables.ProcessingPurposeSubCategories]: {
      comment: 'The input for determining what processing purposes to filter',
      type: ProcessingPurposeCategoryFiltersInput,
    },
    [DataReportExportableTables.BusinessEntities]: {
      comment: 'The input for determining what business entities to filter',
      type: BusinessEntitiesFiltersInput,
    },
  },
});

/** Override type */
export type DataReportFilterInput = SchemaToType<typeof DataReportFilterInput>;

export const DataReportHiddenColumnsInput = mkInput({
  name: 'DataReportHiddenColumnsInput',
  comment: 'Interface for data report hidden columns',
  fields: {
    [DataReportExportableTables.DataSilos]: {
      comment: 'The data silos columns that were hidden',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.SubDataPoints]: {
      comment: 'The subdatapoints columns that were hidden',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.Vendors]: {
      comment: 'The vendors columns that were hidden',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.DataSubCategories]: {
      comment: 'The data subcategories columns that were hidden',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.ProcessingPurposeSubCategories]: {
      comment: 'The processing purpose columns that were hidden',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.BusinessEntities]: {
      comment: 'The business entities columns that were hidden',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type DataReportHiddenColumnsInput = SchemaToType<
  typeof DataReportHiddenColumnsInput
>;

export const DataReportInvalidFilters = mkType({
  name: 'DataReportInvalidFilters',
  comment: 'Record of which tables have invalid filter values stored',
  fields: {
    [DataReportExportableTables.DataSilos]: {
      comment: 'The data silos filter properties that are no longer valid',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.SubDataPoints]: {
      comment: 'The subdatapoints filter properties that are no longer valid',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.Vendors]: {
      comment: 'The vendors filter properties that are no longer valid',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.DataSubCategories]: {
      comment:
        'The data subcategories filter properties that are no longer valid',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.ProcessingPurposeSubCategories]: {
      comment:
        'The processing purpose filter properties that are no longer valid',
      type: 'string',
      list: true,
    },
    [DataReportExportableTables.BusinessEntities]: {
      comment:
        'The business entities filter properties that are no longer valid',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type DataReportInvalidFilters = SchemaToType<
  typeof DataReportInvalidFilters
>;

export const DataReportMissingAttributes = mkType({
  name: 'DataReportMissingAttributes',
  comment: 'Record of which tables have invalid filter values stored',
  fields: {
    [DataReportExportableTables.DataSilos]: {
      comment:
        'Count of the missing attribute values found in the data silos filter',
      type: 'string',
      list: true,
      optional: true,
    },
    [DataReportExportableTables.SubDataPoints]: {
      comment:
        'Count of the missing attribute values found in the subdatapoints filter',
      type: 'string',
      list: true,
      optional: true,
    },
    [DataReportExportableTables.Vendors]: {
      comment:
        'Count of the missing attribute values found in the vendors filter',
      type: 'string',
      list: true,
      optional: true,
    },
    [DataReportExportableTables.DataSubCategories]: {
      comment:
        'Count of the missing attribute values found in the data subcategories filter',
      type: 'string',
      list: true,
      optional: true,
    },
    [DataReportExportableTables.ProcessingPurposeSubCategories]: {
      comment:
        'Count of the missing attribute values found in the processing purpose filter',
      type: 'string',
      list: true,
      optional: true,
    },
    [DataReportExportableTables.BusinessEntities]: {
      comment:
        'Count of the missing attribute values found in the business entities filter',
      type: 'string',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type DataReportMissingAttributes = SchemaToType<
  typeof DataReportMissingAttributes
>;

export const DataReportRaw = mkType({
  name: 'DataReportRaw',
  comment: 'An exportable data inventory report',
  fields: {
    id: {
      comment: 'The id of the data report',
      modelName: 'dataReport',
      type: 'id',
    },
    title: {
      comment: 'The title of the data report',
      type: 'string',
    },
    tables: {
      comment: 'The names of the tables included in this data report',
      type: { DataReportExportableTables },
      list: true,
    },
    filters: {
      comment: 'A JSON blob of the filters applied to each table in the report',
      type: 'string',
    },
    hiddenColumns: {
      comment:
        'A JSON blob of the columns that the user hid from each table in the report',
      type: 'string',
    },
    creator: {
      comment: 'The user who created this data report',
      type: UserPreview,
      optional: true,
    },
    invalidFilters: {
      comment:
        'Whether the filters in the data report are out of sync with the current filter definition',
      type: DataReportInvalidFilters,
    },
    missingAttributes: {
      comment:
        'Count of missing attribute values per table found in the filters',
      type: DataReportMissingAttributes,
      optional: true,
    },
  },
});

/** Override type */
export type DataReportRaw = SchemaToType<typeof DataReportRaw>;

/** Type of the full, parsed Data Report */
export type DataReport = Omit<DataReportRaw, 'hiddenColumns' | 'filters'> &
  Pick<CreateDataReportInput, 'hiddenColumns' | 'filters'>;

export const CreateDataReportInput = mkInput({
  name: 'CreateDataReportInput',
  comment: 'Input for creating a data report',
  fields: {
    title: {
      comment: 'The title of the data report',
      type: 'string',
    },
    tables: {
      comment: 'The names of the tables included in this data report',
      type: { DataReportExportableTables },
      list: true,
    },
    filters: {
      comment: 'The filters the user applied to each table in the report',
      type: DataReportFilterInput,
    },
    hiddenColumns: {
      comment: 'The columns that the user hid from each table in the report',
      type: DataReportHiddenColumnsInput,
    },
    creatorId: {
      comment: 'The ID of the user who created this report',
      type: 'id',
      modelName: 'user',
    },
  },
});

/** Override type */
export type CreateDataReportInput = SchemaToType<typeof CreateDataReportInput>;

export const UpdateDataReportInput = mkInput({
  name: 'UpdateDataReportInput',
  comment: 'Input for updating a data report',
  fields: {
    id: {
      comment: 'The id of the data report to update',
      type: 'id',
      modelName: 'dataReport',
    },
    title: {
      comment: 'The title of the data report',
      type: 'string',
      optional: true,
    },
    tables: {
      comment: 'The names of the tables included in this data report',
      type: { DataReportExportableTables },
      list: true,
      optional: true,
    },
    filters: {
      comment: 'The filters the user applied to each table in the report',
      type: DataReportFilterInput,
      optional: true,
    },
    hiddenColumns: {
      comment: 'The columns that the user hid from each table in the report',
      type: DataReportHiddenColumnsInput,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateDataReportInput = SchemaToType<typeof UpdateDataReportInput>;

export const UpdateDataReportsInput = mkInput({
  name: 'UpdateDataReportsInput',
  comment: 'Input for bulk updating one or more data reports',
  fields: {
    dataReports: {
      comment:
        'List of data reports with the properties that should be updated for each',
      type: UpdateDataReportInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateDataReportsInput = SchemaToType<
  typeof UpdateDataReportsInput
>;

export const DataReportFiltersInput = mkInput({
  name: 'DataReportFiltersInput',
  comment: 'Input for filtering a list of data reports',
  fields: {
    ids: {
      comment: 'Filter by dataReport IDs',
      type: 'id',
      modelName: 'dataReport',
      list: true,
      optional: true,
    },
    text: {
      comment: 'Filter by text',
      optional: true,
      type: 'string',
    },
    creatorIds: {
      comment: 'Filter by IDs of users who created the data reports',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    title: {
      comment: 'Filter by exact match for title',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type DataReportFiltersInput = SchemaToType<
  typeof DataReportFiltersInput
>;

/** Order for a vendors query */
export const DataReportRawOrder = mkOrder(
  DataReportRaw.name,
  DataReportRawOrderField,
);

/** Override type */
export type DataReportRawOrder = SchemaToType<typeof DataReportRawOrder>;

export const DeleteDataReportsInput = mkInput({
  name: 'DeleteDataReportsInput',
  comment: 'Input for deleting data reports for an organization',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'dataReport',
      list: true,
    },
  },
});

/** Override type */
export type DeleteDataReportsInput = SchemaToType<
  typeof DeleteDataReportsInput
>;
/* eslint-enable max-lines */

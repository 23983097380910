import React, { ReactNode } from 'react';
import type { AlertProps as Props } from 'react-bootstrap';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '../GenericFormattedMessage';
import { Icon, IconType } from '../Icon';
import { FlexColumn, StyleUtils } from '../StyledWrappers';

/**
 * props for the alert
 */
export interface AlertProps extends Props {
  /** the icon to display for the alert */
  icon?: IconType | ReactNode;
  /** the icon to display for the alert */
  header?: GenericMessageDescriptor;
  /** the icon to display for the alert */
  description?: GenericMessageDescriptor;
  /** the link to navigate to when clicking the alert */
  href?: string;
}

const StyledBootstrapAlert = styled(BootstrapAlert)<AlertProps>`
  display: flex;
  align-items: center;
  gap: 1em;
  border-radius: 0.75em;
  opacity: 1;
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')}

  transition: opacity 0.3s ease-in-out;

  ${({ href, onClick }) =>
    href || onClick
      ? `
  :hover {
    // not really sure there's a better way to do this hover state without
    // re-implementing every variant color here
    opacity: .8;
  }
  `
      : ''}
`;

/**
 * Alert
 */
export const Alert: React.FC<AlertProps> = ({
  children,
  icon,
  header,
  description,
  href,
  ...rest
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const headerElement = header && (
    <h3 style={{ color: 'inherit' }}>{formatMessageGeneric(header)}</h3>
  );
  const descriptionElement = description && (
    <div style={{ color: 'inherit' }}>{formatMessageGeneric(description)}</div>
  );
  const alert = (
    <StyledBootstrapAlert {...rest} href={href}>
      {icon && typeof icon === 'string' ? (
        <span>
          <Icon type={icon as IconType} />
        </span>
      ) : (
        icon
      )}
      <FlexColumn style={{ color: 'inherit', flexGrow: 1 }}>
        {headerElement}
        {descriptionElement}
        {children}
      </FlexColumn>
    </StyledBootstrapAlert>
  );
  return href ? (
    <Link
      to={href}
      style={{ ...StyleUtils.Flex.Column.base, fontSize: 'unset' }}
    >
      {alert}
    </Link>
  ) : (
    alert
  );
};

import { isKnownLocale, LanguageKey } from '@transcend-io/internationalization';

/**
 * Returns true if the path ends with a locale
 *
 * @param pathname - The pathname in question
 * @returns True if its presenting localization
 */
export function isLocalePath(pathname: string): boolean {
  const splitPath = pathname.split('/').slice(0, -1);
  const potentialLocale = splitPath.pop();
  return isKnownLocale(potentialLocale);
}

/**
 * Get the underlying path from a pathname that may include localization
 *
 * @param pathname - The pathname to check
 * @returns The underlying path and localization to use
 */
export function getUnderlyingPath(pathname: string): {
  /** The underlying path */
  underlyingPath: string;
  /** The locale if one */
  locale?: LanguageKey;
} {
  // Determine the page side menu to display
  const normalizedPath = pathname.endsWith('/') ? pathname : `${pathname}/`;
  const splitPath = normalizedPath.split('/').slice(0, -1);
  const potentialLocale = splitPath.pop();
  const joined = splitPath.join('/');

  const underlyingPath = isKnownLocale(potentialLocale)
    ? joined.startsWith('/')
      ? joined
      : `/${joined}`
    : pathname;

  return {
    underlyingPath:
      // remove trailing slash if not root
      underlyingPath.endsWith('/') && underlyingPath.length > 1
        ? underlyingPath.slice(0, -1)
        : underlyingPath,
    locale: isKnownLocale(potentialLocale) ? potentialLocale : undefined,
  };
}

/**
 * Add localization to a route
 *
 * @param pathname - The raw pathname
 * @param locale - The locale to add
 * @returns The localized version of the route
 */
export function getLocalizedPath(
  pathname: string,
  locale: LanguageKey,
): string {
  // Determine the page side menu to display
  const { underlyingPath } = getUnderlyingPath(pathname);

  return `${underlyingPath}${
    underlyingPath.endsWith('/') ? '' : '/'
  }${locale}/`;
}

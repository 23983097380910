import * as t from 'io-ts';

import {
  DataCategoryType,
  ProcessingPurpose,
} from '@transcend-io/privacy-types';
import { valuesOf } from '@transcend-io/type-utils';

/**
 * The type of node for content of "Rich Text" type in Contentful
 */
export enum NodeType {
  /** The top level node has document type */
  Document = 'document',
  /** A paragraph type of node */
  Paragraph = 'paragraph',
  /** A hyperlink type of node */
  Hyperlink = 'hyperlink',
  /** A text node */
  Text = 'text',
  /** h1 */
  Heading1 = 'heading-1',
  /** h2 */
  Heading2 = 'heading-2',
  /** h3 */
  Heading3 = 'heading-3',
  /** h4 */
  Heading4 = 'heading-4',
  /** h5 */
  Heading5 = 'heading-5',
  /** h6 */
  Heading6 = 'heading-6',
  /** A list item node */
  ListItem = 'list-item',
  /** An unordered list node */
  UnorderedList = 'unordered-list',
  /** An ordered list node */
  OrderedList = 'ordered-list',
  /** A line break */
  Hr = 'hr',
}

/**
 * The type of metadata returned in "data" object for Contentful RichText
 */
export const RichData = t.partial({
  /** The uri (actually protocol + full url) of link */
  uri: t.string,
});

/**
 * Overload RichData codec as a type
 */
export type RichData = t.TypeOf<typeof RichData>;

/**
 * Rich text as a type.  Can't be defined as overload since it is input to the codec
 */
export interface RichText {
  /** The type of node */
  nodeType: NodeType;
  /** The metadata */
  data: RichData;
  /** The content (recursive!) */
  content?: RichText[];
  /** The value (usually string of text) */
  value?: string;
  /** The marks (e.g. italics or bold) */
  marks?: any[];
}

/**
 * A Rich Text type from Contentful
 */
export const RichText: t.RecursiveType<t.Type<RichText>> = t.recursion(
  'RichText',
  (RichTextHelper) =>
    t.intersection([
      t.type({
        /** The head node type */
        nodeType: valuesOf(NodeType),
        /** Metadata (not important for our use) */
        data: RichData,
      }),
      t.partial({
        /** The content to be rendered */
        content: t.array(RichTextHelper),
        /** The value of the node (usually text) */
        value: t.string,
        /** The marks (e.g. italics or bold) */
        marks: t.array(t.any),
      }),
    ]),
);

export const SubDatapointsMetadata = t.record(
  t.string,
  t.partial({
    /**
     * The default data category type for this datapoint. No key will fall back to DataCategoryType.Unspecified
     */
    defaultDataCategoryType: valuesOf(DataCategoryType),
    /**
     * The default Purpose of Processing for this datapoint. No key will fall back to ProcessingPurpose.Unspecified
     */
    defaultProcessingPurpose: t.array(valuesOf(ProcessingPurpose)),
  }),
);

/** Override type. */
export type SubDatapointsMetadata = t.TypeOf<typeof SubDatapointsMetadata>;

/**
 * The metadata on an integration that is stored in contentful
 */
export const SingleDataPointMetadata = t.intersection([
  t.type({
    /** The title of the data point */
    title: t.string,
    /** The description for the data point */
    description: t.string,
  }),
  t.partial({
    /**
     * The default data category type for this datapoint. No key will fall back to DataCategoryType.Unspecified
     */
    defaultDataCategoryType: valuesOf(DataCategoryType),
    /**
     * The default Purpose of Processing for this datapoint. No key will fall back to ProcessingPurpose.Unspecified
     */
    defaultProcessingPurpose: valuesOf(ProcessingPurpose),
    /** The link(s) to reference documentation */
    references: t.array(t.string),
    'sub-datapoints': SubDatapointsMetadata,
    /** Description to show for employee viewing the data request */
    employeeDescription: t.string,
  }),
]);

/** Override type. */
export type SingleDataPointMetadata = t.TypeOf<typeof SingleDataPointMetadata>;

/**
 * Mapping from datapoint name to metadata about the datapoint
 */
export const ContentfulDataPointMetadata = t.record(
  t.string,
  SingleDataPointMetadata,
);

/**
 * Overload ContentfulDataPointMetadata as a type
 */
export type ContentfulDataPointMetadata = t.TypeOf<
  typeof ContentfulDataPointMetadata
>;

import { hexToRgb } from '@main/theme-types';

/**
 * calculates the relative luminance value of a hex string. Relative luminance is
 * different than HSL luminance and is useful for visual contrast testing
 * See https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
 *
 * @param hexString - the hex string to parse
 * @returns the relative luminance of the provided color
 */
function calculateRelativeLuminanceForHex(hexString: string): number {
  const { r, g, b } = hexToRgb(hexString);

  const calcLum = (valueInt: number): number => {
    // convert from integer format (1...255) to decimal(0...1)
    const valueDec = valueInt / 255;

    return valueDec < 0.03928
      ? valueDec / 12.92
      : ((valueDec + 0.055) / 1.055) ** 2.4;
  };

  // calculate the relative luminance components
  const redLuminance = calcLum(r);
  const greenLuminance = calcLum(g);
  const blueLuminance = calcLum(b);

  // combine the luminance components
  return (
    0.2126 * redLuminance + 0.7152 * greenLuminance + 0.0722 * blueLuminance
  );
}

export interface ColorContrastReport {
  /** the optimal text color to use between the two options */
  optimalColor: string;
  /** the contrast ratio of the optimal option */
  contrastRatio: number;
  /** is the contrast ratio AA compliant */
  isWcagAACompliant: boolean;
  /** is the contrast ratio AAA compliant */
  isWcagAAACompliant: boolean;
}

/**
 * computes the contrast ratio between the two colors
 *
 * @param args - the args
 * @returns the contrast ratio between the two colors
 */
export function calculateContrastRatio({
  darkColorHex,
  lightColorHex,
}: {
  /** the hex of the dark color */
  darkColorHex: string;
  /** the hex of the light color */
  lightColorHex: string;
}): number {
  // parse the hex strings
  const darkLuminance = calculateRelativeLuminanceForHex(darkColorHex);
  const lightLuminance = calculateRelativeLuminanceForHex(lightColorHex);

  // compute the contrast ratios
  return (lightLuminance + 0.05) / (darkLuminance + 0.05);
}

/**
 * Determines an optimal text color given a background color and two text color options (light and dark)
 * See https://ux.stackexchange.com/a/107319
 *
 * @param args - the args
 * @returns the optimal color and other statistics about the comparison
 */
export function determineOptimalTextColorForBackground({
  backgroundHex,
  lightTextHex,
  darkTextHex,
}: {
  /** the background color hex string to use */
  backgroundHex: string;
  /** the light text color hex string to use */
  lightTextHex: string;
  /** the dark text color hex string to use */
  darkTextHex: string;
}): ColorContrastReport {
  // compute the contrast ratios
  const lightContrastRatio = calculateContrastRatio({
    darkColorHex: backgroundHex,
    lightColorHex: lightTextHex,
  });
  const darkContrastRatio = calculateContrastRatio({
    darkColorHex: darkTextHex,
    lightColorHex: backgroundHex,
  });

  // compare the contrast ratios, choose the higher one
  const optimalColor =
    lightContrastRatio > darkContrastRatio ? lightTextHex : darkTextHex;
  const contrastRatio = Math.max(lightContrastRatio, darkContrastRatio);

  return {
    optimalColor,
    contrastRatio,
    isWcagAACompliant: contrastRatio > 4.5,
    isWcagAAACompliant: contrastRatio > 7,
  };
}

// external
import React, { ReactNode } from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';

import { FeatureFlagKey } from './constants';
import { useFeatureFlag } from './FeatureFlagProvider';

interface FeatureFlagHideTruthyProps {
  /** the name of the field */
  flag: FeatureFlagKey;
  /** element to show when truthy */
  truthy?: ReactNode;
  /** element to show when falsy */
  falsy?: ReactNode;
  /** URL to redirect to when falsy */
  falsyRedirectTo?: NavigateProps['to'];
}

/**
 * shows its contents if the feature flag is truthy
 */
export const FeatureFlagGate: React.FC<FeatureFlagHideTruthyProps> = ({
  flag,
  truthy,
  falsy,
  falsyRedirectTo,
}) => {
  const value = useFeatureFlag(flag);

  if (falsyRedirectTo && !value) {
    return <Navigate to={falsyRedirectTo} />;
  }

  return <>{value ? truthy : falsy}</>;
};

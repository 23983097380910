import styled from 'styled-components';

export const StyledLabel = styled.label<{
  /** has an info text been specified */
  hasInfo?: boolean;
}>`
  font-size: 16px;
  display: block;
  line-height: 1.5;
  margin-bottom: ${({ hasInfo }) => (hasInfo ? '0.1em' : '0.4em')};
  ${({ theme }) => `color: ${theme.colors.textOnBg}`}
`;

export const StyledLegend = StyledLabel.withComponent('legend');

export const StyledInfo = styled.p<{
  /** if the StyledInfo is beneath the input content  */
  isBelowInput?: boolean;
}>`
  margin-top: ${({ isBelowInput }) => (isBelowInput ? '0.6em' : '-0.2em')};
  margin-bottom: ${({ isBelowInput }) => (isBelowInput ? '-0.2em' : '0.6em')};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  span > p {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 0px;
  }
`;

export const FormColumnWrapper = styled.div`
  width: 550px;
  margin-bottom: 5em;
`;

export const errorMarginBottom = (
  error: boolean,
  inlineErrors: boolean,
): string =>
  // form input is inline so should not add extra spacing
  inlineErrors
    ? '0.5em'
    : error
      ? 'calc(1.5em - 0.5rem)'
      : 'calc(1.5em + 0.8rem)';

/**
 * Override the border color and spacing based on the error status.
 * - The border override is necessary for when this is nested inside of an old
 *   form (can remove once all forms are migrated)
 * - The spacing override is necessary to ensure subsequent items don't jump
 *   around as much when errors appear
 */
export const FormItemFlexColumnErrorStyleWrapper = styled.div<{
  /** has an error */
  error: boolean;
  /** is full width */
  isFullWidth: boolean;
  /** inline errors */
  inlineErrors: boolean;
  /** Children on the side */
  inputOnTheSide: boolean;
}>`
  ${({ isFullWidth }) =>
    isFullWidth &&
    `
    display: flex;
    flex-direction: column;
  `}
  .ant-input,
  .ant-select-selection {
    // copied from ANT
    border-color: rgb(217, 217, 217) !important;
  }
  ${({ error, inlineErrors, inputOnTheSide }) =>
    !inputOnTheSide
      ? // form input is inline so should not add extra spacing
        `margin-bottom: ${errorMarginBottom(inlineErrors, error)};`
      : ``}
`;

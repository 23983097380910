import React from 'react';

import { SvgProps } from '../enums';

export const FlagER: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55155"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="17"
      height="12"
    >
      <rect width="17" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V6H16V0H0Z"
        fill="#43B764"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6V12H16V6H0Z"
        fill="#B4D7F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L16.5 6L0 12V0Z"
        fill="#BE0027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.5566 8.68384L4.07951 8.69483C4.04524 8.6501 4.01357 8.60739 3.9843 8.56639C3.74756 8.10431 3.62918 7.61804 3.62918 7.10757C3.62918 7.15254 3.62906 7.1956 3.62894 7.23697C3.62727 7.81863 3.62656 8.06527 3.9843 8.56639C4.08565 8.76421 4.2087 8.95759 4.35344 9.14654L4.9699 8.67432L4.5566 8.68384Z"
        fill="#F3E294"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M1 6.25C1 8.04493 2.45507 9.5 4.25 9.5C6.04493 9.5 7.5 8.04493 7.5 6.25C7.5 4.45507 6.04493 3 4.25 3C2.45507 3 1 4.45507 1 6.25ZM6.5 6.25C6.5 7.49264 5.49264 8.5 4.25 8.5C3.00736 8.5 2 7.49264 2 6.25C2 5.00736 3.00736 4 4.25 4C5.49264 4 6.5 5.00736 6.5 6.25Z"
        fill="#F3E294"
      />
    </g>
  </svg>
);

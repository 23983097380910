import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const CustomFunction = mkType({
  name: 'CustomFunction',
  comment: 'Custom Function',
  fields: {
    id: {
      comment: 'Custom Function ID',
      type: 'id',
      modelName: 'customFunction',
    },
    lastModifiedAt: {
      comment: 'The last time function code or context was modified',
      type: 'Date',
    },
    signedCodeJwt: {
      comment: 'Signed Code JWT',
      type: 'string',
    },
    signedCodeContextJwt: {
      comment: 'Signed Code Context JWT',
      type: 'string',
    },
  },
});

/** Override type */
export type CustomFunction = SchemaToType<typeof CustomFunction>;

export const CustomFunctionFilterInput = mkInput({
  name: 'CustomFunctionFilterInput',
  comment: 'Inputs to get and filter custom functions',
  fields: {
    id: {
      comment: 'Custom Function ID',
      type: 'id',
      modelName: 'customFunction',
      optional: true,
    },
  },
});

/** Override type */
export type CustomFunctionFilterInput = SchemaToType<
  typeof CustomFunctionFilterInput
>;

export const RunCustomFunctionInput = mkInput({
  name: 'RunCustomFunctionInput',
  comment:
    'Run a custom function with a payload. This endpoint is primarily for testing purposes when first' +
    'building out and testing a Custom Function integration',
  fields: {
    id: {
      comment: 'Custom Function ID',
      type: 'id',
      modelName: 'customFunction',
    },
    payload: {
      type: 'string',
      comment: 'Base64 stringified JSON payload',
    },
    isTest: {
      type: 'boolean',
      optional: true,
      comment:
        'If this is a testing run, and not meant to actually have legitimate information' +
        'like CEK contexts and request Ids.',
    },
  },
});

/** Override type */
export type RunCustomFunctionInput = SchemaToType<
  typeof RunCustomFunctionInput
>;

export const UpdateCustomFunctionInput = mkInput({
  name: 'UpdateCustomFunctionInput',
  comment: 'Update or create a custom function',
  fields: {
    id: {
      comment: 'Custom Function ID',
      type: 'id',
      modelName: 'customFunction',
      optional: true,
    },
    dataSiloId: {
      comment: 'Data Silo ID',
      type: 'id',
      modelName: 'dataSilo',
    },
  },
});

/** Override type */
export type UpdateCustomFunctionInput = SchemaToType<
  typeof UpdateCustomFunctionInput
>;

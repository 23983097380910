import React from 'react';

import { SvgProps } from '../enums';

export const FlagFK: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55177"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55177)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55177"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55177)">
        <mask
          id="mask2_270_55177"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="9"
          height="7"
        >
          <rect width="9" height="7" fill="white" />
        </mask>
        <g mask="url(#mask2_270_55177)">
          <path
            // eslint-disable-next-line max-len
            d="M-1.00208 6.4999L0.9784 7.3685L9.045 0.944361L10.0897 -0.346405L7.97182 -0.636717L4.68165 2.13163L2.0334 3.99685L-1.00208 6.4999Z"
            fill="#F7FCFF"
          />
          <path
            d="M-0.730957 7.10845L0.278004 7.61252L9.71451 -0.466339H8.29782L-0.730957 7.10845Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M10.0022 6.4999L8.02172 7.3685L-0.0448761 0.944361L-1.08953 -0.346405L1.0283 -0.636717L4.31847 2.13163L6.96673 3.99685L10.0022 6.4999Z"
            fill="#F7FCFF"
          />
          <path
            // eslint-disable-next-line max-len
            d="M9.93457 6.93667L8.92561 7.44073L4.90745 3.98176L3.71615 3.59529L-1.1901 -0.341973H0.226597L5.13009 3.50184L6.43256 3.96525L9.93457 6.93667Z"
            fill="#F50100"
          />
          <mask
            id="path-9-outside-1_270_55177"
            maskUnits="userSpaceOnUse"
            x="-1"
            y="-1"
            width="11"
            height="9"
            fill="black"
          >
            <rect fill="white" x="-1" y="-1" width="11" height="9" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M3.99226 0V-0.75H3.24226V0H3.99226ZM4.99226 0H5.74226V-0.75H4.99226V0ZM3.99226 3V3.75H4.74226V3H3.99226ZM0 3V2.25H-0.75V3H0ZM0 4H-0.75V4.75H0V4ZM3.99226 4H4.74226V3.25H3.99226V4ZM3.99226 7H3.24226V7.75H3.99226V7ZM4.99226 7V7.75H5.74226V7H4.99226ZM4.99226 4V3.25H4.24226V4H4.99226ZM9 4V4.75H9.75V4H9ZM9 3H9.75V2.25H9V3ZM4.99226 3H4.24226V3.75H4.99226V3ZM3.99226 0.75H4.99226V-0.75H3.99226V0.75ZM4.74226 3V0H3.24226V3H4.74226ZM0 3.75H3.99226V2.25H0V3.75ZM0.75 4V3H-0.75V4H0.75ZM3.99226 3.25H0V4.75H3.99226V3.25ZM4.74226 7V4H3.24226V7H4.74226ZM4.99226 6.25H3.99226V7.75H4.99226V6.25ZM4.24226 4V7H5.74226V4H4.24226ZM9 3.25H4.99226V4.75H9V3.25ZM8.25 3V4H9.75V3H8.25ZM4.99226 3.75H9V2.25H4.99226V3.75ZM4.24226 0V3H5.74226V0H4.24226Z"
            fill="#F7FCFF"
            mask="url(#path-9-outside-1_270_55177)"
          />
        </g>
        <path
          // eslint-disable-next-line max-len
          d="M12.9752 9.85701C12.4608 9.85701 12.1028 9.73558 11.8481 9.52389C11.5921 9.31127 11.4243 8.99439 11.317 8.57217C11.2096 8.14923 11.1656 7.63174 11.1492 7.03057C11.1376 6.60804 11.1398 6.14957 11.1421 5.6579C11.1428 5.48957 11.1436 5.31735 11.1439 5.14136H14.8519C14.9181 6.34507 14.9418 7.51812 14.7062 8.3979C14.5851 8.85 14.3983 9.21303 14.124 9.46285C13.8522 9.7103 13.4832 9.85701 12.9752 9.85701Z"
          fill="#6DC2FF"
          stroke="#F7FCFF"
          strokeWidth="0.25"
        />
        <mask
          id="mask3_270_55177"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="11"
          y="5"
          width="5"
          height="5"
        >
          <path
            // eslint-disable-next-line max-len
            d="M12.9752 9.85701C12.4608 9.85701 12.1028 9.73558 11.8481 9.52389C11.5921 9.31127 11.4243 8.99439 11.317 8.57217C11.2096 8.14923 11.1656 7.63174 11.1492 7.03057C11.1376 6.60804 11.1398 6.14957 11.1421 5.6579C11.1428 5.48957 11.1436 5.31735 11.1439 5.14136H14.8519C14.9181 6.34507 14.9418 7.51812 14.7062 8.3979C14.5851 8.85 14.3983 9.21303 14.124 9.46285C13.8522 9.7103 13.4832 9.85701 12.9752 9.85701Z"
            fill="white"
            stroke="white"
            strokeWidth="0.25"
          />
        </mask>
        <g mask="url(#mask3_270_55177)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12.2611 6.79351C12.2611 6.79351 12.0637 6.64756 11.9791 6.4111C11.8945 6.17465 11.7271 5.54518 11.7271 5.49052C11.7271 5.43586 11.5043 5.49052 11.5043 5.49052C11.5043 5.49052 11.5074 5.22433 11.5915 5.22433C11.6755 5.22433 11.6787 4.97752 11.8827 5.03967C12.0867 5.10182 12.2471 5.32976 12.455 5.32976C12.6629 5.32976 13.5319 5.39605 13.6715 5.39605C13.8112 5.39605 14.1794 5.69088 14.1794 5.91157C14.1794 6.13226 14.079 6.46106 14 6.46106C13.9211 6.46106 13.8217 6.65237 13.6715 6.58922C13.5214 6.52608 13.1629 6.39604 12.8419 6.46106C12.5208 6.52608 12.455 6.48521 12.455 6.58922C12.455 6.69324 12.2611 6.79351 12.2611 6.79351Z"
            fill="#E1E5E8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8 6.63937H14.3778L13.9338 7.21314H12.1125L11.8 6.63937Z"
            fill="#49801E"
          />
          <path
            // eslint-disable-next-line max-len
            d="M11.7666 9.14783C11.7666 9.14783 12.2121 9.30718 12.3983 9.28781C12.6045 9.26636 12.7871 9.03131 12.9249 9.03131C13.0624 9.03131 13.3273 9.26644 13.533 9.28781C13.7194 9.30718 14.0831 9.14783 14.0831 9.14783"
            stroke="white"
            strokeWidth="0.533333"
          />
          <path
            // eslint-disable-next-line max-len
            d="M11.2773 8.46814C11.4314 8.33769 11.7666 8.32816 11.7666 8.32816C11.7666 8.32816 12.2121 8.48751 12.3983 8.46814C12.6045 8.44669 12.7871 8.21164 12.9249 8.21164C13.0624 8.21164 13.3273 8.44677 13.533 8.46814C13.7194 8.48751 14.0831 8.32816 14.0831 8.32816C14.0831 8.32816 14.2864 8.09963 14.5416 8.34094"
            stroke="white"
            strokeWidth="0.533333"
          />
          <path
            // eslint-disable-next-line max-len
            d="M11.2305 7.76489L10.8933 7.35166C11.0255 7.24379 11.2475 7.13747 11.5716 7.01996L11.7288 7.01238C12.0648 7.09872 12.2894 7.13614 12.3717 7.12789C12.3988 7.12517 12.4442 7.10313 12.527 7.04817C12.7623 6.89211 12.7851 6.87939 12.9248 6.87939C13.0525 6.87939 13.0788 6.89164 13.3457 7.03576C13.3952 7.06231 13.4138 7.07197 13.4406 7.08484C13.497 7.11189 13.5373 7.12567 13.5595 7.12789C13.6236 7.13431 13.7526 7.10439 13.9331 7.03174C14.1679 6.81835 14.4653 6.84045 14.7212 7.07353C14.8918 7.22902 15.0288 7.35463 15.1322 7.45047L14.7697 7.84165C14.6676 7.747 14.5316 7.62234 14.362 7.46778C14.2945 7.40633 14.309 7.40672 14.279 7.4392L14.1868 7.50401C13.9067 7.62228 13.6855 7.67651 13.5064 7.65857C13.4102 7.64894 13.3172 7.61718 13.2098 7.56564C13.1739 7.54838 13.1482 7.53506 13.0922 7.50503C12.9635 7.43547 12.9145 7.41273 12.9248 7.41273C12.9473 7.41273 12.9161 7.43006 12.8218 7.49262C12.6701 7.59327 12.5645 7.64457 12.4249 7.65857C12.2705 7.67404 12.0296 7.63681 11.6774 7.54947C11.4486 7.63599 11.2966 7.71093 11.2305 7.76489Z"
            fill="white"
          />
        </g>
        <path
          // eslint-disable-next-line max-len
          d="M11.4816 9.19684C11.4816 9.19684 11.9736 9.66807 11.6765 9.98736C11.3794 10.3066 11.1071 10.1212 11.1071 10.1212"
          stroke="#B85F3C"
          strokeWidth="0.5"
        />
        <path
          d="M14.525 9.19684C14.525 9.19684 14.033 9.66807 14.3301 9.98736C14.6271 10.3066 14.8995 10.1212 14.8995 10.1212"
          stroke="#B85F3C"
          strokeWidth="0.5"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.1603 9.57657C11.1603 9.57657 12.0079 10.3865 13.0499 10.3865C14.0918 10.3865 14.9303 9.76775 14.9303 9.76775"
          stroke="#CB8B73"
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
);

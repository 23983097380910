import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const LogOut: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 12H3"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M3.94702 16C5.42002 18.961 8.46802 21 12 21C16.971 21 21 16.971 21 12C21 7.029 16.971 3 12 3C8.46802 3 5.42002 5.039 3.94702 8"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9L15 12L12 15"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

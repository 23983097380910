/**
 * We define this here as this file needs to be imported into Sombra.
 *
 * @param v - Object to be tested.
 * @returns If null or undefined.
 */
export function isNull(v: unknown): boolean {
  return v === null || v === undefined;
}

/**
 * Test if a string is an empty string
 *
 * @param value - Any value
 * @returns True if value is the empty string
 */
export function isEmptyStr(value: any): value is string {
  return typeof value === 'string' && value?.trim().length === 0;
}

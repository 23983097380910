import { MenuTabInterface } from '@main/internationalization';
import { mkType, SchemaToType } from '@main/schema-utils';

import { DataCollection } from './dataCollection';

// TODO: https://transcend.height.app/T-42672 - remove
export const DataCategory = mkType({
  name: 'DataCategory',
  comment: 'A category of data, rended to data subjected on the privacy center',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
    staticType: {
      comment:
        'The underlying static type from which this data category was created. Defaults to null',
      optional: true,
      type: 'string',
    },
    method: {
      comment: 'Data category method',
      optional: true,
      type: 'string',
    },
    children: {
      comment:
        'The data collection data categories that should be displayed as children of this data category',
      list: true,
      type: DataCollection,
    },
  },
});

/** Override type */
export type DataCategory = SchemaToType<typeof DataCategory>;

// TODO: https://transcend.height.app/T-42672 - remove
export const DataCategoryMethodMenu = mkType({
  name: 'DataCategoryMethodMenu',
  comment: 'A tab list of data categories grouped by method',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
    method: {
      comment: 'Data category method',
      type: 'string',
      deprecated: 'Removing this feature',
    },
    children: {
      comment:
        'The data categories that should be displayed as children of this method grouping',
      list: true,
      deprecated: 'Removing this feature',
      type: DataCategory,
    },
  },
});

/** Override type */
export type DataCategoryMethodMenu = SchemaToType<
  typeof DataCategoryMethodMenu
>;

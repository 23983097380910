import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ArrowLeftBackSquare: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9178 12.501L10.4167 10L12.9178 7.49896"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.08212 7.49896V12.501"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2.49683 13.3347V6.66529C2.49683 4.36314 4.36308 2.49689 6.66523 2.49689H13.3347C15.6368 2.49689 17.5031 4.36314 17.5031 6.66529V13.3347C17.5031 15.6369 15.6368 17.5031 13.3347 17.5031H6.66523C4.36308 17.5031 2.49683 15.6369 2.49683 13.3347Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { logger } from '@main/core-ui/src/logger';
import { Message } from '@main/internationalization';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { contentErrorBoundaryMessages } from './messages';
import { ContentErrorBoundaryWrapper } from './wrappers';

export * from './wrappers';

/**
 * Props for content error boundary
 */
export interface ContentErrorBoundaryProps {
  /** Intl */
  intl: IntlShape;
  /** The children to put the boundary around */
  children?: React.ReactNode;
  /** Override the default error message that is shown */
  errorMessage?: Message;
  /** Override the default Wrapper component */
  Wrapper?: typeof ContentErrorBoundaryWrapper;
}

/**
 * Error boundary state
 */
export interface ContentErrorBoundaryState {
  /** Whether a current error exists */
  error: Error | null;
}

/**
 * A standard UI for handling runtime errors in child components
 */
class ContentErrorBoundaryWithoutIntl extends Component<
  ContentErrorBoundaryProps,
  ContentErrorBoundaryState
> {
  /** Component state */
  public state: ContentErrorBoundaryState = { error: null };

  /**
   * Catch the unexpected error and set the state to hasError:true
   *
   * @param error - The error that occurred
   * @param info - The information associated with the error
   */
  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({ error });
    localStorage.clear(); // drop local storage in case something stored there is causing the unexpected error
    logger.error(error, info);
  }

  /**
   * Render this component
   */
  public render(): React.ReactNode {
    const {
      children,
      intl: { formatMessage },
      Wrapper = ContentErrorBoundaryWrapper,
      errorMessage = contentErrorBoundaryMessages.error,
    } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <Wrapper>
          {formatMessage(errorMessage)}:{error ? error?.message : ''}
        </Wrapper>
      );
    }
    return children;
  }
}

export const ContentErrorBoundary = injectIntl(ContentErrorBoundaryWithoutIntl);

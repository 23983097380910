import React from 'react';

import { SvgProps } from '../enums';

export const FlagPY: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55094"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55094)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55094"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55094)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6 6C6 7.10457 6.89543 8 8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6ZM9.5 6C9.5 6.82843 8.82843 7.5 8 7.5C7.17157 7.5 6.5 6.82843 6.5 6C6.5 5.17157 7.17157 4.5 8 4.5C8.82843 4.5 9.5 5.17157 9.5 6Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#F05234"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#3D58DB"
        />
        <path
          // eslint-disable-next-line max-len
          d="M7.28904 4.92791L7.57364 5.33901C7.28916 5.53595 7.20344 5.80835 7.31227 6.21449C7.41875 6.6119 7.65468 6.7982 8.07146 6.81267L8.0541 7.31237C7.41828 7.29029 6.99288 6.95437 6.82931 6.3439C6.66807 5.74217 6.82272 5.25074 7.28904 4.92791Z"
          fill="#73BE4A"
        />
        <path
          // eslint-disable-next-line max-len
          d="M8.7123 4.92791L8.4277 5.33901C8.71218 5.53595 8.7979 5.80835 8.68907 6.21449C8.58259 6.6119 8.34667 6.7982 7.92989 6.81267L7.94724 7.31237C8.58306 7.29029 9.00846 6.95437 9.17204 6.3439C9.33327 5.74217 9.17862 5.25074 8.7123 4.92791Z"
          fill="#73BE4A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.00122 6.37363C8.27736 6.37363 8.50122 6.14977 8.50122 5.87363C8.50122 5.59748 8.27736 5.37363 8.00122 5.37363C7.72508 5.37363 7.50122 5.59748 7.50122 5.87363C7.50122 6.14977 7.72508 6.37363 8.00122 6.37363Z"
          fill="#FBCD17"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagEG: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55000"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55000)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55000"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55000)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#BF2714"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#272727"
        />
        <path
          d="M6.13014 5.11011L6.62961 5.133L6.50424 7.86791L6.00476 7.84501L6.13014 5.11011Z"
          fill="#C09302"
        />
        <path
          d="M6.65199 5.27985L7.15119 5.30807L7.02581 7.52573L6.52661 7.49751L6.65199 5.27985Z"
          fill="#C09302"
        />
        <path
          d="M7.1742 5.44595L7.67253 5.48684L7.54715 7.01485L7.04883 6.97396L7.1742 5.44595Z"
          fill="#C09302"
        />
        <path
          d="M9.8698 5.11011L9.37033 5.133L9.4957 7.86791L9.99518 7.84501L9.8698 5.11011Z"
          fill="#C09302"
        />
        <path
          d="M9.34795 5.27985L8.84875 5.30807L8.97412 7.52573L9.47333 7.49751L9.34795 5.27985Z"
          fill="#C09302"
        />
        <path
          d="M8.82573 5.44595L8.32741 5.48684L8.45279 7.01485L8.95111 6.97396L8.82573 5.44595Z"
          fill="#C09302"
        />
        <path
          d="M7.98664 7.66336L7.49927 7.55169L7.70555 6.65134L8.19293 6.763L7.98664 7.66336Z"
          fill="#C09302"
        />
        <path
          d="M8.35974 7.66336L8.84711 7.55169L8.64082 6.65134L8.15345 6.763L8.35974 7.66336Z"
          fill="#C09302"
        />
        <path
          d="M8.23608 7.65511H7.73608V6.77115H8.23608V7.65511Z"
          fill="#C09302"
        />
        <path
          d="M7.31735 5.45663L7.8172 5.4689L7.788 6.65935L7.28815 6.64708L7.31735 5.45663Z"
          fill="#C09302"
        />
        <path
          d="M8.18692 5.45663L8.68677 5.4689L8.65756 6.65935L8.15771 6.64708L8.18692 5.45663Z"
          fill="#C09302"
        />
        <path
          // eslint-disable-next-line max-len
          d="M6.66895 7.96142L6.7988 7.47858C7.32932 7.62125 7.73668 7.69181 8.01445 7.69181C8.29223 7.69181 8.69958 7.62125 9.23011 7.47858L9.35996 7.96142C8.79077 8.11449 8.3444 8.19181 8.01445 8.19181C7.6845 8.19181 7.23814 8.11449 6.66895 7.96142Z"
          fill="#C09302"
        />
        <path
          // eslint-disable-next-line max-len
          d="M6.1411 5.11136C6.10777 4.78106 6.30109 4.55747 6.62872 4.58598C6.73645 4.59536 6.85745 4.62723 7.01861 4.68191C7.06569 4.69806 7.06569 4.69806 7.11145 4.71426C7.333 4.79306 7.39315 4.81331 7.40564 4.81598C7.35655 4.80486 7.50114 4.54348 7.5343 4.49942C7.53822 4.5274 7.52158 4.52342 7.48161 4.51542L7.03059 4.42515L7.35165 4.09578C7.46387 3.98065 7.61332 3.92355 7.78386 3.92355C7.80154 3.92355 7.81804 3.92273 7.87222 3.9194C7.91229 3.91696 7.91229 3.91696 7.95734 3.9151C8.26932 3.90499 8.49305 3.99321 8.49305 4.31404C8.49305 4.42882 8.50755 4.61339 8.53148 4.75342C8.53953 4.80052 8.55507 4.84461 8.50922 4.86397C8.51111 4.84726 8.62038 4.80414 8.79031 4.73308L8.81071 4.72455C9.30442 4.51848 9.36211 4.49936 9.52126 4.59608C9.62731 4.66054 9.7283 4.77648 9.84145 4.94933L9.42311 5.22318C9.35013 5.11169 9.29201 5.04404 9.26433 5.02514C9.32723 5.06884 9.11538 5.13919 9.0033 5.18597L8.98322 5.19436C8.59883 5.35512 8.54815 5.3743 8.43824 5.3743C8.13915 5.3743 8.03175 5.06792 8.00235 4.62914C7.91007 4.93293 7.63473 5.31631 7.40785 5.31631C7.30852 5.31631 7.35627 5.33202 6.9439 5.18535C6.90069 5.17006 6.90069 5.17006 6.85796 5.1554C6.73303 5.11301 6.64291 5.08924 6.58605 5.08416C6.5884 5.08148 6.59677 5.07247 6.60616 5.06443L6.1411 5.11136Z"
          fill="#C09302"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagBZ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54968"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54968)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#0168B4"
      />
      <mask
        id="mask1_270_54968"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54968)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V2H16V0H0Z"
          fill="#E93C43"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10V12H16V10H0Z"
          fill="#E93C43"
        />
        <path
          d="M8 9C6.34315 9 5 7.65685 5 6C5 4.34315 6.34315 3 8 3C9.65685 3 11 4.34315 11 6C11 7.65685 9.65685 9 8 9Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.5 6C4.5 7.933 6.067 9.5 8 9.5C9.933 9.5 11.5 7.933 11.5 6C11.5 4.067 9.933 2.5 8 2.5C6.067 2.5 4.5 4.067 4.5 6ZM11 6C11 7.65685 9.65685 9 8 9C6.34315 9 5 7.65685 5 6C5 4.34315 6.34315 3 8 3C9.65685 3 11 4.34315 11 6Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5 6C5 7.65685 6.34315 9 8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6ZM10.3 6C10.3 7.27026 9.27026 8.3 8 8.3C6.72974 8.3 5.7 7.27026 5.7 6C5.7 4.72974 6.72974 3.7 8 3.7C9.27026 3.7 10.3 4.72974 10.3 6Z"
          fill="#5B8C39"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 7.00001L7.96954 6.81238L9.5 7.00001V7.50001L7.96954 7.31238L6.5 7.50001V7.00001Z"
          fill="#5B8C39"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00002 5H9.00002C9.00002 5 9.14144 8 8.00002 8C6.85861 8 7.00002 5 7.00002 5Z"
          fill="#769DF1"
        />
        <rect x="8" y="5" width="1" height="1" fill="#FECA00" />
        <rect opacity="0.6" x="7" y="5" width="1" height="1" fill="#F6F7F8" />
        <rect
          opacity="0.66"
          x="7"
          y="4"
          width="2"
          height="1"
          rx="0.5"
          fill="#5B8C39"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6.90002 6.40002C7.17617 6.40002 7.40002 5.95231 7.40002 5.40002C7.40002 4.84774 7.17617 4.40002 6.90002 4.40002C6.62388 4.40002 6.40002 4.84774 6.40002 5.40002C6.40002 5.95231 6.62388 6.40002 6.90002 6.40002Z"
          fill="#E9AD35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M9.09998 6.40002C9.37612 6.40002 9.59998 5.95231 9.59998 5.40002C9.59998 4.84774 9.37612 4.40002 9.09998 4.40002C8.82383 4.40002 8.59998 4.84774 8.59998 5.40002C8.59998 5.95231 8.82383 6.40002 9.09998 6.40002Z"
          fill="#5C2216"
        />
      </g>
    </g>
  </svg>
);

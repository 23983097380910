import {
  FormatMessageArgs,
  GenericMessageDescriptor,
  useFormatMessageGeneric,
} from '@main/core-ui/src/GenericFormattedMessage';
import partition from 'lodash/partition';
import React from 'react';
import type { FieldErrors } from 'react-hook-form/dist/types/errors';
import { CSSProperties } from 'styled-components';

import { FlexColumn, FlexRow, StyleUtils } from '../StyledWrappers';
import { IWithClassName } from '../types';
import { getFormErrorsByName } from './helpers';
import { RequiredIndicator } from './RequiredIndicator';
import { IValidationErrorsProps, ValidationErrors } from './ValidationError';
import {
  errorMarginBottom,
  FormItemFlexColumnErrorStyleWrapper,
  StyledInfo,
  StyledLabel,
} from './wrappers';

export interface IFormItemWrapperProps extends IWithClassName {
  /** the internal name of the form item */
  name: string;
  /** label text */
  label?: GenericMessageDescriptor;
  /** optional id for label-input attribute matching for accessibility */
  id?: string;
  /** info text */
  info?: GenericMessageDescriptor;
  /** info text formatting arguments */
  infoArgs?: FormatMessageArgs;
  /** Info text to display below the content */
  infoBelowContent?: GenericMessageDescriptor | React.ReactElement;
  /** the errors */
  errors?: FieldErrors<any>;
  /** how to show the errors */
  errorDisplay?: IValidationErrorsProps;
  /** should we display a required asterisk next to the label */
  required?: boolean;
  /** should expand to full width of container */
  isFullWidth?: boolean;
  /** should show errors inline instead of below */
  inlineErrors?: boolean;
  /** Styles for the form item wrapper specifically */
  formItemWrapperStyle?: CSSProperties;
  /** Styles for the flex container that wraps the elements when `inputOnTheSide` is true */
  inlineInputWrapperStyle?: CSSProperties;
  /** Place input beside info */
  inputOnTheSide?: boolean;
}

const WRAPPER_PROP_NAMES: (keyof IFormItemWrapperProps)[] = [
  'label',
  'name',
  'info',
  'infoArgs',
  'required',
  'isFullWidth',
  'inlineErrors',
  'className',
  'errorDisplay',
  'infoBelowContent',
  'formItemWrapperStyle',
  'inputOnTheSide',
  'inlineInputWrapperStyle',
];

/**
 * extracts the form item wrapper props from the props object, to simplify overrides
 *
 * @param props - the props object to split
 * @returns an object with the passthrough props and form item wrapper props separated
 */
export function extractFormItemWrapperProps<
  T extends Pick<IFormItemWrapperProps, 'name'> = any,
>(
  props: IFormItemWrapperProps & T,
): {
  /** the props for FormItemWrapper */
  wrapperProps: IFormItemWrapperProps;
  /** the props to passthrough to the wrapped component */
  passthroughProps: T;
} {
  const [wrapperProps, passthroughProps] = partition(
    Object.entries(props),
    ([key]) => WRAPPER_PROP_NAMES.includes(key as keyof IFormItemWrapperProps),
  );
  return {
    passthroughProps: {
      ...(Object.fromEntries(passthroughProps) as any),
      // also passthrough the name too
      name: props.name,
    },
    wrapperProps: Object.fromEntries(wrapperProps) as any,
  };
}

export const FormItemWrapper: React.FC<IFormItemWrapperProps> = ({
  children,
  name,
  label,
  id,
  info,
  infoArgs,
  errors,
  errorDisplay = {},
  required,
  isFullWidth = true,
  formItemWrapperStyle,
  className,
  infoBelowContent = false,
  inlineErrors = false,
  inputOnTheSide = false,
  inlineInputWrapperStyle,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();
  const error = getFormErrorsByName(errors, name);

  return inputOnTheSide ? (
    <FlexColumn>
      <FlexRow
        style={{
          ...StyleUtils.Flex.SpaceBetween,
          ...StyleUtils.Flex.Row.CenterVertical,
          marginBottom: errorMarginBottom(inlineErrors, error),
          ...inlineInputWrapperStyle,
        }}
      >
        <FormItemFlexColumnErrorStyleWrapper
          error={error}
          isFullWidth={isFullWidth}
          style={formItemWrapperStyle}
          className={className}
          inlineErrors={inlineErrors}
          inputOnTheSide={inputOnTheSide}
        >
          {label && (
            <StyledLabel htmlFor={id ?? name} hasInfo={!!info}>
              {formatMessageGeneric(label)}
              {required && <RequiredIndicator />}
            </StyledLabel>
          )}
          {info && (
            <StyledInfo>{formatMessageGeneric(info, infoArgs)}</StyledInfo>
          )}
          {infoBelowContent && (
            <StyledInfo isBelowInput>
              {React.isValidElement(infoBelowContent)
                ? infoBelowContent
                : formatMessageGeneric(infoBelowContent)}
            </StyledInfo>
          )}
        </FormItemFlexColumnErrorStyleWrapper>
        {children}
      </FlexRow>

      <FlexRow>
        {error && <ValidationErrors errors={error.types} {...errorDisplay} />}
      </FlexRow>
    </FlexColumn>
  ) : (
    <FormItemFlexColumnErrorStyleWrapper
      error={error}
      isFullWidth={isFullWidth}
      style={formItemWrapperStyle}
      className={className}
      inlineErrors={inlineErrors}
      inputOnTheSide={inputOnTheSide}
    >
      {label && (
        <StyledLabel htmlFor={id ?? name} hasInfo={!!info}>
          {formatMessageGeneric(label)}
          {required && <RequiredIndicator />}
        </StyledLabel>
      )}
      {info && <StyledInfo>{formatMessageGeneric(info, infoArgs)}</StyledInfo>}
      {children}
      {error && <ValidationErrors errors={error.types} {...errorDisplay} />}
      {infoBelowContent && (
        <StyledInfo isBelowInput>
          {React.isValidElement(infoBelowContent)
            ? infoBelowContent
            : formatMessageGeneric(infoBelowContent)}
        </StyledInfo>
      )}
    </FormItemFlexColumnErrorStyleWrapper>
  );
};

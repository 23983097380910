import React from 'react';

import { SvgProps } from '../enums';

export const FlagHT: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55169"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55169)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55169"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55169)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 6V12H16V6H0Z"
          fill="#E31D1C"
        />
        <rect x="3" y="2" width="10" height="8" fill="white" />
        <mask
          id="mask2_270_55169"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="2"
          width="10"
          height="8"
        >
          <rect x="3" y="2" width="10" height="8" fill="white" />
        </mask>
        <g mask="url(#mask2_270_55169)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 8.3849C3 8.3849 5.40028 7.32124 8 7.38615C10.5997 7.45106 13 8.63459 13 8.63459V10H3L3 8.3849Z"
            fill="#279E19"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.71992 3.50933C5.69291 3.54133 5.69185 3.58583 5.7142 3.62125C5.87449 3.87522 6.57986 4.87805 7.72466 4.87805C8.82701 4.87805 9.64813 3.9482 9.88139 3.65249C9.92 3.60354 9.90849 3.53456 9.85525 3.50211C9.6853 3.39856 9.30185 3.2 8.86024 3.2C8.26286 3.2 7.72466 3.45514 7.72466 3.45514C7.72466 3.45514 6.92389 3.2 6.41388 3.2C6.0279 3.2 5.80534 3.40811 5.71992 3.50933Z"
            fill="#026A16"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.89467 7.407L6.99856 6.84026C6.47498 7.08252 6.2132 7.56225 6.2132 8.27943L6.90715 9.41875H8.32447H9.02381L9.52471 8.27943C9.52471 8.27943 9.71978 6.80621 9.02381 6.56854L7.89467 7.407Z"
            fill="#C51918"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.93407 5.81601C5.93407 5.81601 4.58142 6.2856 4.58142 8.01792L6.38558 8.37474C6.38558 8.37474 6.36445 7.38223 6.90374 6.91667L5.93407 5.81601Z"
            fill="#0A328C"
          />
          <path
            d="M5.26801 5.26381L5.48335 5.04623L8.00004 7.52333L7.7847 7.74091L5.26801 5.26381Z"
            fill="#FFD018"
          />
          <path
            d="M4.48126 5.84545L4.65823 5.59311L7.8946 8.0559L7.71763 8.30824L4.48126 5.84545Z"
            fill="#FFD018"
          />
          <path
            d="M3.94312 6.81096L4.08217 6.53406L7.75905 8.53766L7.61999 8.81456L3.94312 6.81096Z"
            fill="#FFD018"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M4.59076 8.00436L7.01128 8.32151L6.87501 8.71738H6.49126L6.75262 8.94105L7.34199 9.10268V8.71738H7.85814V9.53597H6.75262L6.32861 8.94105C6.32861 8.94105 5.97249 9.35243 5.62493 9.35243C5.27737 9.35243 4.93472 9.21882 4.93472 8.77016C4.93472 8.32151 4.93472 8.45803 4.93472 8.45803L4.59076 8.32151V8.00436Z"
            fill="#FECA00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9.80826 5.7227C9.80826 5.7227 11.3944 6.28561 11.3944 8.01793L9.57424 8.37431C9.57424 8.37431 9.49148 7.09337 8.92972 6.62781L9.80826 5.7227Z"
            fill="#0A328C"
          />
          <path
            d="M10.6792 5.26381L10.4549 5.04623L7.74335 7.32271L7.96766 7.54029L10.6792 5.26381Z"
            fill="#FFD018"
          />
          <path
            d="M11.4987 5.84545L11.3144 5.59311L7.94316 8.0559V8.51758L11.4987 5.84545Z"
            fill="#FFD018"
          />
          <path
            d="M12.0583 6.81145L11.9154 6.53357L7.85705 8.50532L8.00002 8.7832L12.0583 6.81145Z"
            fill="#FFD018"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M11.3846 8.00436L8.86326 8.32151L9.00521 8.71738H9.40495L9.1327 8.94105L8.51878 9.10268V8.71738H7.98112V9.53597H9.1327L9.57439 8.94105C9.57439 8.94105 9.94534 9.35243 10.3074 9.35243C10.6694 9.35243 11.0263 9.21882 11.0263 8.77016C11.0263 8.32151 11.0263 8.45803 11.0263 8.45803L11.3846 8.32151V8.00436Z"
            fill="#FECA00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.69154 4.67871C7.69154 4.67871 7.52814 5.88238 7.52814 6.37225C7.52814 6.86212 7.69154 9.46606 7.69154 9.46606H8.11179C8.11179 9.46606 8.11179 6.7812 8.11179 6.37225C8.11179 5.9633 7.8652 4.67871 7.8652 4.67871H7.69154Z"
            fill="#FECA00"
          />
        </g>
      </g>
    </g>
  </svg>
);

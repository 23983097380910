import React from 'react';

import { SvgProps } from '../enums';

export const FlagBE: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54966"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54966)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H10.5V12H5V0Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 0H16V12H10.5V0Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H5.5V12H0V0Z"
        fill="#1D1D1D"
      />
    </g>
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const MergeTableCells: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M5 9.66666H2.5C2.22386 9.66666 2 9.89051 2 10.1667V13.1667C2 13.4428 2.22386 13.6667 2.5 13.6667H13.5C13.7761 13.6667 14 13.4428 14 13.1667V10.1667C14 9.89051 13.7761 9.66666 13.5 9.66666H11"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9V11.6667"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6665 10.3333L7.99984 9L9.33317 10.3333"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M11 6.33334L13.5 6.33334C13.7761 6.33334 14 6.10949 14 5.83334L14 2.83334C14 2.5572 13.7761 2.33334 13.5 2.33334L2.5 2.33334C2.22386 2.33334 2 2.5572 2 2.83334L2 5.83334C2 6.10949 2.22386 6.33334 2.5 6.33334L5 6.33334"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 7L8 4.33333"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.3335 5.66667L8.00016 7L6.66683 5.66667"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

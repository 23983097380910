/**
 * Configuration for the graphql API
 */

import { fetch } from 'whatwg-fetch';

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  setContext,
} from '@main/core-ui';
import { getUnderlyingPath } from '@main/internationalization';

import { BACKEND_URL } from './args';
import possibleTypes from './fragmentTypes.json';

const httpLink = createHttpLink({
  uri: BACKEND_URL.transform({ pathname: '/privacy/graphql' }).href,
  // Requires whatwg-fetch to polyfill fetch in IE11
  // https://github.com/apollographql/apollo-client/issues/2780#issuecomment-388574352
  fetch,
});

const localizationLink = setContext((_, { headers }) => {
  // Note we are using window.location here, it requires a page reload
  // for a new language to kick in
  const { locale } = getUnderlyingPath(window.location.pathname);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      language: locale,
    },
  };
});

/**
 * Create the apollo client for making graphql requests
 */
export const privacyCenterApolloClient = new ApolloClient({
  cache: new InMemoryCache({ possibleTypes }),
  link: localizationLink.concat(httpLink),
});

import { IsoCountryCode } from '@transcend-io/privacy-types';

import { mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { BusinessEntityPreview } from './businessEntityPreview';
import { DataProcessingAgreementStatus } from './enums';
import { SaaSVendorPreview } from './saaSVendor';

export const VendorInterface = mkInterface({
  name: 'VendorInterface',
  comment: 'An interface for a vendor',
  fields: {
    id: {
      comment: 'The unique ID of the vendor',
      modelName: 'vendor',
      type: 'id',
    },
    createdAt: {
      comment: 'The time the vendor was created',
      type: 'Date',
    },
    title: {
      comment: 'The title of the vendor',
      type: 'string',
    },
    slug: {
      comment: 'The slug of the vendor for templating',
      type: 'string',
    },
    description: {
      comment: 'The description of the vendor',
      type: 'string',
    },
    dataProcessingAgreementLink: {
      comment: 'A link to the DPA for this vendor or a note about the status',
      optional: true,
      type: 'string',
    },
    contactName: {
      comment: 'Contact name of person related to email',
      optional: true,
      type: 'string',
    },
    contactEmail: {
      comment:
        'Contact email to use for this vendor (often a third party contact)',
      optional: true,
      type: 'string',
    },
    contactPhone: {
      comment: 'Phone number for the vendor',
      optional: true,
      type: 'string',
    },
    address: {
      comment: 'Address for the vendor',
      optional: true,
      type: 'string',
    },
    headquarterCountry: {
      comment: 'The ISO country where the vendor is headquartered',
      type: { IsoCountryCode },
      optional: true,
    },
    headquarterSubDivision: {
      comment: 'The ISO country subdivision where the vendor is headquartered',
      type: 'string',
      optional: true,
    },
    websiteUrl: {
      comment: 'The website URL of the vendor, if applicable',
      optional: true,
      type: 'string',
    },
    dataProcessingAgreementStatus: {
      comment: 'The status of a DPA with that vendor',
      optional: true,
      type: { DataProcessingAgreementStatus },
    },
    businessEntity: {
      comment: 'The business entity that the vendor is for',
      optional: true,
      type: BusinessEntityPreview,
    },
    saaSVendor: {
      comment:
        'The static SaaS vendor that this vendor is tied to, used to update metadata',
      type: SaaSVendorPreview,
      optional: true,
    },
  },
});

/** Override type */
export type VendorInterface = SchemaToType<typeof VendorInterface>;

export const VendorPreview = mkType({
  name: 'VendorPreview',
  comment: 'A preview of a vendor',
  interfaces: [VendorInterface],
  fields: {
    ...VendorInterface.fields,
  },
});

/** Override type */
export type VendorPreview = SchemaToType<typeof VendorPreview>;

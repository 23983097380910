import { UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const AssessmentSectionComment = mkType({
  name: 'AssessmentSectionComment',
  comment: 'A comment on an assessment section.',
  fields: {
    id: {
      comment: 'The id of the assessment section comment',
      type: 'id',
      modelName: 'assessmentSectionComment',
    },
    content: {
      comment: 'The content of the assessment section',
      type: 'string',
    },
    author: {
      comment: 'The user who wrote this comment',
      type: UserPreview,
      optional: true,
    },
    updatedAt: {
      comment: 'The date the comment was last updated',
      type: 'Date',
    },
    createdAt: {
      comment: 'The date the comment was created',
      type: 'Date',
    },
    assessmentSectionId: {
      comment: 'The ID of the section that the comment belongs to',
      type: 'id',
      modelName: 'assessmentSection',
    },
  },
});

/** Override type */
export type AssessmentSectionComment = SchemaToType<
  typeof AssessmentSectionComment
>;

export const AssessmentSectionCommentFiltersInput = mkInput({
  name: 'AssessmentSectionCommentFiltersInput',
  comment: 'Inputs for filtering a list of assessment section comments',
  fields: {
    ids: {
      comment: 'The ids of the assessment section comments',
      type: 'id',
      modelName: 'assessmentSectionComment',
      optional: true,
      list: true,
    },
    text: {
      type: 'string',
      comment: 'Filter the comments by the text present in its content',
      optional: true,
    },
    authorIds: {
      comment: 'The ids of users who wrote the comments',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    assessmentSectionIds: {
      comment: 'The IDs of sections that the comments refer to',
      type: 'id',
      modelName: 'assessmentSection',
      optional: true,
      list: true,
    },
    createdAfter: {
      comment: 'Filter the comments created after a specific date',
      type: 'Date',
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentSectionCommentFiltersInput = SchemaToType<
  typeof AssessmentSectionCommentFiltersInput
>;

export const AssessmentSectionCommentInput = mkInput({
  name: 'AssessmentSectionCommentInput',
  comment: 'Input for creating an assessment section comment',
  fields: {
    content: AssessmentSectionComment.fields.content,
  },
});

/** Override type */
export type AssessmentSectionCommentInput = SchemaToType<
  typeof AssessmentSectionCommentInput
>;

export const CreateAssessmentSectionCommentInput = mkInput({
  name: 'CreateAssessmentSectionCommentInput',
  comment: 'Input for creating an assessment section comment',
  fields: {
    content: AssessmentSectionCommentInput.fields.content,
    assessmentSectionId: {
      comment: 'The ID of the section that the comment refers to',
      type: 'id',
      modelName: 'assessmentSection',
    },
  },
});

/** Override type */
export type CreateAssessmentSectionCommentInput = SchemaToType<
  typeof CreateAssessmentSectionCommentInput
>;

export const CreateAssessmentSectionCommentsInput = mkInput({
  name: 'CreateAssessmentSectionCommentsInput',
  comment: 'Input for bulk creating assessment section comments',
  fields: {
    assessmentSectionComments: {
      comment: 'The list of assessment section comments to create',
      type: CreateAssessmentSectionCommentInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateAssessmentSectionCommentsInput = SchemaToType<
  typeof CreateAssessmentSectionCommentsInput
>;

export const UpdateAssessmentSectionCommentInput = mkInput({
  name: 'UpdateAssessmentSectionCommentInput',
  comment: 'Input for bulk updating assessment section comments',
  fields: {
    id: AssessmentSectionComment.fields.id,
    content: AssessmentSectionCommentInput.fields.content,
  },
});

/** Override type */
export type UpdateAssessmentSectionCommentInput = SchemaToType<
  typeof UpdateAssessmentSectionCommentInput
>;

export const DeleteAssessmentSectionCommentsInput = mkInput({
  name: 'DeleteAssessmentSectionCommentsInput',
  comment: 'Input for deleting multiple assessment section comments',
  fields: {
    ids: {
      comment: 'The ids of the assessment section comments to delete',
      type: 'id',
      modelName: 'assessmentSectionComment',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentSectionCommentsInput = SchemaToType<
  typeof DeleteAssessmentSectionCommentsInput
>;

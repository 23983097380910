import { RequestActionObjectResolver } from '@transcend-io/privacy-types';

import { mkInput, SchemaToType } from '@main/schema-utils';

export const GenerateSqlInput = mkInput({
  name: 'GenerateSqlInput',
  comment: 'Input for generating SQL statements',
  fields: {
    id: {
      comment: 'ID of data silo to generate SQL statements for',
      type: 'id',
      modelName: 'dataSilo',
    },
    actions: {
      comment: 'The actions to generate SQL statements for',
      type: { RequestActionObjectResolver },
      list: true,
    },
  },
});

/** Override type */
export type GenerateSqlInput = SchemaToType<typeof GenerateSqlInput>;

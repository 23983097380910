import {
  SOMBRA_AUTHENTICATION_VERIFICATION_FAILED_ERROR_MESSAGE,
  TRANSCEND_AUTHENTICATION_VERIFICATION_FAILED_ERROR_MESSAGE,
} from '@main/utils';

/** The errors that should prompt a re-login with sombra */
export const SOMBRA_LOGIN_ERRORS = [
  'Encryption Channel with Sombra is not set',
  SOMBRA_AUTHENTICATION_VERIFICATION_FAILED_ERROR_MESSAGE,
  TRANSCEND_AUTHENTICATION_VERIFICATION_FAILED_ERROR_MESSAGE,
  'Network error: Response not successful: Received status code 400',
  'Received invalid status code: 401',
  'Authorization failed - unable to decrypt secret',
  'Login session has expired! Please log in again',
];

/**
 * Determine if an error message is indicative of needing to re-login
 *
 * @param msg - The error message
 * @returns True if a re-login is needed
 */
export function isDiffieHellmanError(msg: string): boolean {
  return SOMBRA_LOGIN_ERRORS.some((x) => msg.includes(x));
}

import { ReactNode } from 'react';

import { GenericMessageDescriptor } from '../GenericFormattedMessage';

export interface Tab {
  /** The path to link to */
  path: string;
  /** The title to display */
  title: GenericMessageDescriptor;
}

export interface TabHookProp extends Tab {
  /** the contents of the tab */
  contents?: ReactNode;
}

export interface TabBarHookResult {
  /** the path with all params filled */
  filledActivePath: string;
  /** the tabs to pass to TabBar with all url params filled in */
  filledTabs: Tab[];
  /** the contents of the currently active tab */
  activeTabContents: ReactNode;
  /** the current active tab object */
  activeTab?: Tab;
}

/**
 * The style variant of the TabBar
 */
export enum TabBarStyleVariant {
  /** Display the tabs like tabs in a folder */
  Tabs = 'tabs',
  /** Display the tabs as simple links */
  Links = 'links',
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagCM: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54976"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54976)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H11V12H5V0Z"
        fill="#E11C1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.03771 7.24522L6.2949 8.45407L6.85179 6.3836L5.57239 5.06098L7.30478 4.98947L8.03771 2.94208L8.77065 4.98947H10.5001L9.22364 6.3836L9.86291 8.33187L8.03771 7.24522Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H16V12H11V0Z"
        fill="#FBCD17"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H5V12H0V0Z"
        fill="#0B9E7A"
      />
    </g>
  </svg>
);

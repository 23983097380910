/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ServerDatabaseCheckmark: React.FC<SvgProps> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.97169 17.5C13.2092 17.5 15.8334 16.5125 15.8334 15.2942V4.70583C15.8334 3.4875 13.2217 2.5 10 2.5C6.77835 2.5 4.16669 3.4875 4.16669 4.70583V15.2942C4.16669 16.5125 6.79085 17.5 10.0284 17.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2447 11.3169L9.68219 12.8794L8.74469 11.9419"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8334 4.70581C15.8334 5.92414 13.2217 6.91164 10 6.91164C6.77835 6.91164 4.16669 5.92414 4.16669 4.70581"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

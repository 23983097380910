import { makeEnum } from '@transcend-io/type-utils';

import { JoinModelName } from './joins';
import { MigrationModelName } from './migration';
import { StandardModelName } from './models';

/**
 * All database model names
 */
export const DatabaseModelName = makeEnum({
  ...MigrationModelName,
  ...JoinModelName,
  ...StandardModelName,
});

/**
 * Overload with type for all database model names
 */
export type DatabaseModelName =
  (typeof DatabaseModelName)[keyof typeof DatabaseModelName];

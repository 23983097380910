import api from "!../../.yarn/__virtual__/style-loader-virtual-c514109e2f/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-129f8d7124.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../.yarn/__virtual__/css-loader-virtual-1089cffea8/0/cache/css-loader-npm-5.2.6-118c6d409e-592df1dcc7.zip/node_modules/css-loader/dist/cjs.js!../../.yarn/__virtual__/sass-loader-virtual-21bec76824/0/cache/sass-loader-npm-12.1.0-6188089e12-1cf9404536.zip/node_modules/sass-loader/dist/cjs.js!./bootstrap-theme.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};
/**
 * Fields that a sombra can be ordered by
 */
export enum SombraOrderField {
  /** The hostedMethod of the sombra */
  HostedMethod = 'hostedMethod',
  /** The title of the sombra */
  Title = 'title',
  /** The url of the sombra */
  Url = 'url',
  /** The url of the sombra */
  PublicUrl = 'publicUrl',
  /** The time the scope was created */
  CreatedAt = 'createdAt',
  /** The time the scope was updated */
  UpdatedAt = 'updatedAt',
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const File: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.276 4.276L10.3907 2.39067C10.1407 2.14067 9.80137 2 9.44804 2H4.66671C3.93004 2 3.33337 2.59667 3.33337 3.33333V12.6667C3.33337 13.4033 3.93004 14 4.66671 14H11.3334C12.07 14 12.6667 13.4033 12.6667 12.6667V5.21867C12.6667 4.86533 12.526 4.526 12.276 4.276V4.276Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6667 5.33333H10C9.63204 5.33333 9.33337 5.03467 9.33337 4.66667V2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33337 7.33333H9.33337"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33337 9.33333H9.33337"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33337 11.3333H8.22004"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { parse as parseSync, parseAsync } from 'json2csv';
import flattenDeep from 'lodash/flattenDeep';
import partition from 'lodash/partition';

import { PenumbraFile } from '@transcend-io/penumbra';
import { Requirize } from '@transcend-io/type-utils';

import { logger } from '../logger';
import { isJSONFile } from '../utils';

export interface ConvertPenumbraJsonFilesToCsvOptions {
  /** Group JSON files by Path for conversion to CSV. */
  groupFilesByPath?: boolean;
}

/**
 * Convert the given Penumbra files to CSV.
 * We only attempt to convert JSON Penumbra files.
 *
 * @param files - Penumbra files.
 * @param opts - Options.
 * @returns Resultant penumbra files.
 */
export async function convertPenumbraJsonFilesToCsv(
  files: PenumbraFile[],
  { groupFilesByPath = false }: ConvertPenumbraJsonFilesToCsvOptions,
): Promise<PenumbraFile[]> {
  const filesToWrite: PenumbraFile[] = [];

  const [jsonFiles, nonJsonFiles] = partition(files, (file) =>
    isJSONFile(file),
  );
  filesToWrite.push(...nonJsonFiles);

  const [withPath, withoutPath] = partition(jsonFiles, ({ path }) => !!path);

  const withoutPathToCsv = await Promise.all(
    withoutPath.map(async (file) => {
      const fileBody = await new Response(file.stream).text();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parsedJson: any = {};
      let csv: string | undefined;
      try {
        parsedJson = JSON.parse(fileBody);
        csv = await parseAsync(parsedJson);
      } catch (e) {
        logger.warn(
          `JSON parsing error while converting to CSV in ${
            file.path || 'download'
          }`,
          { inner: e },
        );
        return {
          ...file,

          stream: new Response(fileBody).body!,
        };
      }

      return typeof csv === 'string'
        ? {
            ...file,

            stream: new Response(csv).body!,
            size: csv.length,
            // Always append .csv to the file name
            path: `${file.path || 'download'}.csv`.replace(
              // remove .json extension if present
              /\.json(\.csv)$/i,
              '$1',
            ),
          }
        : {
            ...file,
            path: file.path || 'download',

            stream: new Response(fileBody).body!,
          };
    }),
  );

  filesToWrite.push(...withoutPathToCsv);

  if (!groupFilesByPath) {
    return filesToWrite.concat(withPath);
  }

  const filesGroupedByPath = (
    withPath as Requirize<PenumbraFile, 'path'>[]
  ).reduce(
    (acc, file) => ({
      ...acc,
      [file.path]: file.path in acc ? acc[file.path].concat(file) : [file],
    }),
    {} as Record<string, PenumbraFile[]>,
  );

  const groupedByPathToCsv: PenumbraFile[][] = await Promise.all(
    Object.entries(filesGroupedByPath).map(async ([path, files]) => {
      const filesWithBodies = await Promise.all(
        files.map(async (file) => ({
          file,
          fileBody: await new Response(file.stream).text(),
        })),
      );

      // Solves the issue of double-nested (or more) arrays in JSON files.
      // For example, if we have a JSON file which is an array [{ a: 1 }, { a: 2 }],
      // without flattening the result of the `files.map(...)` would give us
      // [[{ a: 1 }, { a: 2 }]], which the parser translates into `"0"\n"[{ a: 1 }, { a: 2 }]"`.
      // Flattening the array sidesteps this issue, presenting an array one-level deep only
      // to the parser, giving us `"a"\n"1"\n"2"`.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parsedRows: any[];
      let csv: string | undefined;

      try {
        parsedRows = flattenDeep(
          filesWithBodies.map(({ fileBody }) => JSON.parse(fileBody)),
        );
        csv = parseSync(parsedRows);
      } catch (e) {
        logger.warn(
          `JSON parsing error while converting to CSV in ${filesWithBodies
            .map(({ file }) => file.path)
            .join(', ')}`,
          { inner: e },
        );
        return filesWithBodies.map(({ file, fileBody }) => ({
          ...file,

          stream: new Response(fileBody).body!,
        }));
      }

      return typeof csv === 'string'
        ? [
            {
              stream: new Response(csv).body!,
              size: csv.length,
              // Always append .csv to the file name
              path: `${path || 'download'}.csv`.replace(
                // remove .json extension if present
                /\.json(\.csv)$/i,
                '$1',
              ),
            } as PenumbraFile,
          ]
        : filesWithBodies.map(({ file, fileBody }) => ({
            ...file,

            stream: new Response(fileBody).body!,
          }));
    }),
  );

  return filesToWrite.concat(groupedByPathToCsv.flat());
}

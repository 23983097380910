/* eslint-disable max-lines */
import * as t from 'io-ts';

import { PreflightRequestStatus } from '@transcend-io/privacy-types';
import { HttpMethod, valuesOf } from '@transcend-io/type-utils';

import {
  dbModelId,
  mkMutation,
  mkQuery,
  mkRestEndpoint,
  schemaToCodec,
} from '@main/schema-utils';

import {
  AvcBulkManualNotification,
  AWSQuery,
  BigQueryAPIQuery,
  BigQueryAPIRequest,
  ClassifyFreeTextArrays,
  ClassifyTextArraysWithRegexBody,
  ClassifyTextOrTextArraysWithRegexHeaders,
  ClassifyTextRequest,
  ClassifyTextRequestWithLookBehind,
  ClassifyTextResult,
  ConfirmedSubDataPoints,
  ConfirmedSubDataPointsInput,
  ConnectAWSClientRegion,
  ConsentRecordsQueryResponse,
  CRedactIndices,
  CustomFunctionExecuteRequest,
  CustomFunctionExecuteResult,
  DataSubCategoriesForSombra,
  DataSubCategoryHeaders,
  DecryptConsentIdentifierOptions,
  DynamoDBQuery,
  EncryptConsentIdentifierOptions,
  EncryptConsentIdentifiersResponse,
  EncryptedClassifyTextResult,
  EncryptedClassifyTextResultSet,
  EncryptedDataPoint,
  EncryptedSampleGCSFileTypeRequest,
  EncryptedSampleResponse,
  EncryptedSampleS3FileTypeRequest,
  EncryptSampleRequestBody,
  EncryptSampleResponseBody,
  EnrichedIdentifiers,
  EvaluateClassifierInput,
  EvaluateClassifierOutput,
  ExecuteAWSCommandResponse,
  ExecuteBigQueryCommandResponse,
  ExecuteCdataOdbcCommandBody,
  ExecuteCdataOdbcCommandResponse,
  ExecuteDynamoDBCommandResponse,
  ExecuteSftpCommandResponse,
  FailingIdentifiers,
  FetchAmazonS3BucketsResponse,
  FetchAndChunkFileFromAzureBody,
  FetchAndChunkFileFromS3Body,
  FetchAndChunkFileFromSmbBody,
  FetchAndChunkTextFileBody,
  FetchAndChunkTextFileResponse,
  FetchAzureBlobsQuery,
  FetchAzureBlobsResponse,
  FetchAzureContainersQuery,
  FetchAzureContainersResponse,
  FetchFileMetadataResponse,
  FetchRedisKeysResponse,
  FetchSchemaAmazonS3Response,
  FetchSchemaFileType,
  FetchSchemaRequestBody,
  FetchSchemaResponseBody,
  GenerateCEKFromConsentIdentifiersRequest,
  GenerateCEKRequest,
  GenerateCEKsResponse,
  IdentifierMapping,
  IdentifierRegexes,
  LLMClassifierInput,
  LLMClassifierTemplate,
  MatchDict,
  MongoDBSchemaQueryResponse,
  PatchTenantParamsInput,
  PatchTenantSecretsInput,
  PreferenceRecordsQueryResponse,
  ProxyDatabaseRequestBody,
  ProxyDatabaseResponseBody,
  ProxyGoogleCloudSpannerRequest,
  ProxyGoogleCloudSpannerResponse,
  ProxyRequestEncryptedFields,
  ProxyTreasureDataRequest,
  ProxyTreasureDataResponse,
  QueryConsentRecords,
  QueryPreferenceRecords,
  SendEmailInput,
  SftpCommandBody,
  SignedIdentifiers,
  SmbCommandBody,
  SmbCommandResponseBody,
  SombraClassifierTemplateBody,
  SombraOptions,
  SubDataPointMetadata,
  SubDataPointsForSombra,
  SubDataPointWithSamples,
  TranscendWebhookIntegrationBasePayload,
  TransformedEncryptedIdentifiers,
  TypeGuessesWithConfidenceArrayOfArrays,
  UnstructuredCategoryGuessWithConfidenceArrayOfArrays,
  UpdateDataSubCategoriesRegexCache,
  UpsertPreferenceRecordsRequest,
  UpsertPreferenceRecordsResponse,
  WrappedKeyWithMetadata,
} from './codecs';
import {
  AllowedEnricherTransition,
  PluginType,
  SelfHostedLLMType,
  SombraEmployeeAuthMethod,
  SupportedGCSFileType,
  SupportedS3FileType,
} from './enums';
import {
  AdminUpsertPreferenceRecordsInput,
  ConsentPreferenceResponseSombra,
  ConsentRecordSchema,
  DecryptionContext,
  LogEmailReplyInput,
  LogSentRequestEmailInput,
  MCDConsentLookupInput,
  SignedIdentifiersInput,
  SombraKMSKeysMetadata,
  SombraSingleTenantConfigSyncInput,
  UpdateSombraJWTConfigInput,
  UpdateSombraOAuthConfigInput,
} from './schema';

export const rotateSombraRootKeys = mkMutation({
  name: 'rotateSombraRootKeys',
  comment: 'Rotate Sombra root keys',
  params: {
    dhEncrypted: {
      type: 'string',
      // Empty, as we need the DH channel only to return information.
      underlyingType: t.type({}),
    },
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

/**
 * @deprecated TODO: https://transcend.height.app/T-6572 - use updateSombraTenantConfig
 */
export const updateSombraOAuthConfig = mkMutation({
  name: 'updateSombraOAuthConfig',
  comment:
    'Update the OAuth configuration for data subject verification on Sombra',
  params: {
    input: UpdateSombraOAuthConfigInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({
        secrets: t.type({
          OAUTH_SECRETS: t.array(t.string),
        }),
      }),
    },
  },
  response: { success: 'boolean' },
});

/**
 * @deprecated TODO: https://transcend.height.app/T-6572 - use updateSombraTenantConfig
 */
export const updateSombraJWTConfig = mkMutation({
  name: 'updateSombraJWTConfig',
  comment:
    'Update the JWT configuration for data subject verification on Sombra',
  params: {
    input: UpdateSombraJWTConfigInput,
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
  },
  response: { success: 'boolean' },
});

export const updateSombraTenantConfig = mkMutation({
  name: 'updateSombraTenantConfig',
  comment: 'Update the sombra tenant configuration',
  params: {
    dhEncrypted: {
      type: 'string',
      underlyingType: t.partial({
        parameters: PatchTenantParamsInput,
        secrets: PatchTenantSecretsInput,
      }),
    },
  },
  response: { success: 'boolean' },
});

export const sombraSingleTenantConfigSync = mkMutation({
  name: 'sombraSingleTenantConfigSync',
  comment:
    "Sync a Single-Tenant Sombra instance's configuration to the database",
  params: {
    input: SombraSingleTenantConfigSyncInput,
  },
  response: {
    done: 'boolean',
    keysMetadata: SombraKMSKeysMetadata.optional(),
  },
});

export const logSentRequestEmail = mkMutation({
  name: 'logSentRequestEmail',
  comment:
    'Log the sending of a request email in our database with information on how to handle the response later',
  params: {
    input: LogSentRequestEmailInput,
  },
  response: {
    communicationId: {
      type: 'id',
      modelName: 'communication',
    },
  },
});

export const logEmailReply = mkMutation({
  name: 'logEmailReply',
  comment: 'Store the reply to a request email in the database.',
  params: {
    input: LogEmailReplyInput,
  },
  response: {
    done: 'boolean',
  },
});

/**
 * ReSign encrypted CEK contexts to extend their lifetime.
 * Useful when:
 *  1. A long-lived requests' encrypted CEK context JWT expires
 *  2. Sombra root keys are rotated.
 */
export const reSignEncryptedCekContexts = mkRestEndpoint({
  route: '/employee/reSign/encryptedCEKContexts',
  body: t.type({
    /** The actual CEK contexts to reSign. */
    encryptedCEKContexts: t.array(t.string),
  }),
  headers: t.type({
    /** Dh payload as authentication */
    'x-sombra-dh-encrypted': t.string,
  }),
  method: 'POST',
  responseBody: t.type({
    /** List of reSigned encrypted CEK contexts. */
    encryptedCEKContexts: t.array(t.string),
  }),
});

/**
 * Updates the SaaS Context of a connected integration.
 */
export const transformSaaSContexts = mkRestEndpoint({
  route: '/transform-saas-contexts',
  body: t.type({
    /** List of transformations and saaSContexts */
    transformations: t.array(
      t.intersection([
        t.type({
          /** the saaSContext to be migrated */
          originalSaaSContext: t.string,
          /** the label for the transformation to be used */
          transformation: t.string,
        }),
        t.partial({
          /** the key to operate on */
          keyName: t.string,
          /** the duplicate name for the keyName */
          dupName: t.string,
          /** the default value for when adding a new default to secret map */
          defaultValue: t.string,
        }),
      ]),
    ),
  }),
  // No extra headers are needed
  headers: t.type({}),
  method: 'POST',
  responseBody: t.array(
    t.type({
      /** The transformed saaSContext */
      newSaaSContext: t.string,
    }),
  ),
});

export const sendEmail = mkRestEndpoint({
  route: '/send-email',
  body: t.intersection([
    t.type({
      /** JWT encoding the email address to send from, signed by transcend */
      emailSenderContextJwt: t.string,
    }),
    SendEmailInput,
  ]),
  headers: t.partial({
    'x-sombra-identifier': t.string,
  }),
  method: 'POST',
  /** This method does not return data */
  responseBody: t.type({
    /** ID of the communication that was created */
    communicationId: dbModelId('communication'),
  }),
});

export const sendEncryptedEmail = mkRestEndpoint({
  route: '/send-encrypted-email',
  body: t.intersection([
    t.type({
      /** JWT encoding the email address to send from, signed by transcend */
      emailSenderContextJwt: t.string,
    }),
    SendEmailInput,
  ]),
  headers: t.intersection([
    t.type({
      'x-sombra-dh-encrypted': t.string,
    }),
    t.partial({ 'x-sombra-identifier': t.string }),
  ]),
  method: 'POST',
  /** This method does not return data */
  responseBody: t.type({
    /** ID of the communication that was created */
    communicationId: dbModelId('communication'),
  }),
});

export const revealRootEcdsaKey = mkRestEndpoint({
  route: '/on-premise/reveal-root-ecdsa-key',
  method: 'POST',
  body: t.partial({}),
  headers: t.type({
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: schemaToCodec(DecryptionContext),
});

export const generateConsentIdentifierEncryptionKey = mkRestEndpoint({
  route: '/configuration/consent-identifier-encryption-key',
  method: 'POST',
  body: t.partial({}),
  headers: t.type({
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: schemaToCodec(DecryptionContext),
});

export const revealConsentIdentifierEncryptionKey = mkRestEndpoint({
  route: '/public-keys/consent-identifier-encryption-key',
  method: 'GET',
  body: t.partial({}),
  headers: t.type({
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: schemaToCodec(DecryptionContext),
});

export const queryConsentPreferences = mkQuery({
  name: 'queryConsentPreferences',
  comment:
    'Lookup consent preferences (intended for use by Customer-Ingress Sombra)',
  params: {
    input: MCDConsentLookupInput,
  },
  response: ConsentPreferenceResponseSombra,
});

export const adminUpsertPreferences = mkMutation({
  name: 'adminUpsertPreferences',
  comment:
    'Upsert User Preferences (intended for use by Customer-Ingress Sombra)',
  params: {
    input: AdminUpsertPreferenceRecordsInput,
  },
  response: {
    success: 'boolean',
    nodes: ConsentRecordSchema.list(),
  },
});

const RequestIdentifierCodec = t.type({
  /** ID of request */
  id: t.string,
  /** Name of identifier */
  name: t.string,
  /** The underlying identifier value */
  value: t.string,
  /** Type of identifier */
  type: t.string,
  /** Whether request identifier has been verified at least one */
  isVerifiedAtLeastOnce: t.boolean,
  /** Whether request identifier has been verified */
  isVerified: t.union([t.boolean, t.undefined]),
});

export const getOrganizationIdentifierRegexes = mkRestEndpoint({
  route: '/identifiers/:sombraAudience/regexes',
  method: 'POST',
  headers: t.type({}),
  body: t.type({
    /** Identifier names to filter by */
    identifierNames: t.array(t.string),
  }),
  responseBody: t.type({
    /** Mapping of identifier names to validation regexes for specified org */
    identifierRegexes: IdentifierRegexes,
  }),
});

export const requestIdentifiers = mkRestEndpoint({
  route: '/request-identifiers',
  method: 'POST',
  body: t.intersection([
    // TODO: https://transcend.height.app/T-38797 - Get rid of `requestId`
    t.union([
      t.type({
        requestId: dbModelId('request'),
      }),
      t.type({
        requestIds: t.array(dbModelId('request')),
      }),
    ]),
    t.partial({
      first: t.number,
      offset: t.number,
      last: t.number,
    }),
  ]),
  headers: t.any,
  responseBody: t.type({
    identifiers: t.array(RequestIdentifierCodec),
  }),
});

/**
 * A structure of strings, array, or objects
 */
export type Primitives =
  | undefined
  | string
  | PrimitivesArray
  | { [key: string]: Primitives };

/**
 * Primitives array
 */
type PrimitivesArray = Primitives[];

const PrimitivesCodec: t.Type<Primitives> = t.recursion('Primitive', () =>
  t.union([
    t.undefined,
    t.string,
    t.array(PrimitivesCodec),
    t.record(t.string, PrimitivesCodec),
  ]),
);

// Encrypts data sent in an object using the key encryption base
export const encryptPrimitives = mkRestEndpoint({
  route: '/saas/encrypt-primitives',
  method: 'POST',
  body: t.type({ unencryptedPrimitives: PrimitivesCodec }),
  headers: t.record(t.string, t.string),
  responseBody: t.type({ encryptedPrimitives: PrimitivesCodec }),
});

// /////////////////////////////////// //
// HELPERS FOR SAAS REGISTER ENDPOINTS //
// /////////////////////////////////// //

/** The part of the POST body that is common regardless of dhEncrypted status */
const commonBody = t.type({
  /** The allowed domains to send sombra requests to */
  allowedHosts: t.array(t.string),
  /** The allowed plaintext paths for saas responses */
  allowedPlaintextPaths: t.array(t.string),
  /** Whether or not to include the plaintexted subdomain */
  includePlaintextedSubdomain: t.boolean,
  /** The original saas context */
  originalSaaSContext: t.string,
});

/**
 * A registration route used for refresh and other functions while secret map known by backend
 * TODO: https://github.com/transcend-io/main/issues/228 - remove when refresh through sombra
 */
export const secretMapEndpoint = mkRestEndpoint({
  route: '/saas/register',
  body: t.intersection([
    commonBody,
    t.type({
      secretMap: t.record(t.string, t.string),
    }),
  ]),
  headers: t.type({
    /** The data silo id */
    'x-data-silo-id': dbModelId('dataSilo'),
    /** The data silo type (e.g. integrationName) */
    'x-data-silo-type': t.string,
  }),
  method: 'POST',
  responseBody: t.string,
});

/**
 * Sends encrypted credentials over to sombra on saas tool registration
 */
export const dhEncryptedEndpoint = mkRestEndpoint({
  route: '/saas/register-encrypted',
  body: t.intersection([
    commonBody,
    t.type({
      secretMap: t.type({}),
    }),
  ]),
  headers: t.intersection([
    secretMapEndpoint.headers,
    t.type({
      'x-sombra-dh-encrypted': t.string,
    }),
  ]),
  method: 'POST',
  responseBody: t.string,
});

/** Get Google Access Token with specified scope using service account keyfile */
export const getGoogleAccessToken = mkRestEndpoint({
  route: '/saas/google/get-access-token-from-keyfile',
  method: 'POST',
  body: t.type({
    scopes: t.array(t.string),
  }),
  headers: t.type({ 'x-sombra-saas-context': t.string }),
  responseBody: t.type({
    accessToken: t.string,
    expirationTime: t.number,
  }),
});

/** Register a query for a database integration datapoint */
export const registerQuery = mkRestEndpoint({
  route: '/database/register-query',
  body: t.undefined,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-dh-encrypted': t.string,
  }),
  method: 'POST',
  responseBody: t.record(t.string, t.string),
});

/** Fetch the schema of subDataPoints from a Database */
export const fetchSchema = mkRestEndpoint({
  route: '/database/fetch-schema',
  body: FetchSchemaRequestBody,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchSchemaResponseBody,
});

export const fetchSchemaMongoDb = mkRestEndpoint({
  route: '/database/fetch-schema-mongodb',
  body: t.type({
    query: t.union([t.string, t.null]),
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: MongoDBSchemaQueryResponse,
});

export const fetchAmazonS3Buckets = mkRestEndpoint({
  route: '/database/fetch-amazonS3-buckets',
  body: t.type({}),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'GET',
  responseBody: FetchAmazonS3BucketsResponse,
});

export const fetchRedisKeys = mkRestEndpoint({
  route: '/database/fetch-redis-keys',
  body: t.partial({
    pageSize: t.number,
    cursor: t.string,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchRedisKeysResponse,
});

export const fetchSchemaAmazonS3 = mkRestEndpoint({
  route: '/database/fetch-schema-amazons3',
  body: t.intersection([
    t.type({
      bucketName: t.string,
    }),
    t.partial({
      prefix: t.string,
      delimiter: t.string,
      marker: t.string,
      maxKeys: t.number,
      supportedExtensions: t.array(t.string),
      supportedRegexes: t.array(t.string),
    }),
  ]),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchSchemaAmazonS3Response,
});

export const fetchS3FileMetadata = mkRestEndpoint({
  route: '/database/fetch-s3-file-metadata',
  body: t.type({
    bucketName: t.string,
    objectKey: t.string,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchFileMetadataResponse,
});

export const fetchSchemaS3Parquet = mkRestEndpoint({
  route: '/database/fetch-schema-s3parquet',
  body: t.type({
    bucketName: t.string,
    objectKey: t.string,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchSchemaFileType,
});

export const fetchSchemaS3JSONL = mkRestEndpoint({
  route: '/database/fetch-schema-s3jsonl',
  body: t.type({
    bucketName: t.string,
    objectKey: t.string,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchSchemaFileType,
});

export const fetchSchemaGCSParquet = mkRestEndpoint({
  route: '/gcs/fetch-schema-gcs-parquet',
  body: t.type({
    filePath: t.string,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchSchemaFileType,
});

export const fetchSchemaGCSJSONL = mkRestEndpoint({
  route: '/gcs/fetch-schema-gcs-jsonl',
  body: t.type({
    filePath: t.string,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchSchemaFileType,
});

export const testAzureConnection = mkRestEndpoint({
  route: '/database/test-azure-connection',
  body: t.undefined,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({}),
});

export const fetchAzureContainers = mkRestEndpoint({
  route: '/database/fetch-azure-containers',
  body: FetchAzureContainersQuery,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchAzureContainersResponse,
});

export const fetchAzureBlobs = mkRestEndpoint({
  route: '/database/fetch-azure-blobs',
  body: FetchAzureBlobsQuery,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: FetchAzureBlobsResponse,
});

export const fetchSchemaDynamoDb = mkRestEndpoint({
  route: '/database/fetch-schema-dynamodb',
  body: t.type({
    dataPoint: t.string,
    exclusiveStartKey: t.union([t.record(t.string, t.any), t.undefined]),
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.intersection([
    t.type({
      lastEvaluatedKey: t.union([t.record(t.string, t.any), t.undefined]),
    }),
    t.partial({
      schema: t.array(t.string),
      subDatapointMetaDatas: t.array(SubDataPointMetadata),
    }),
  ]),
});

export const executeDynamoDBCommand = mkRestEndpoint({
  route: '/database/execute-dynamodb-command',
  body: DynamoDBQuery,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: ExecuteDynamoDBCommandResponse,
});

export const executeAWSCommand = mkRestEndpoint({
  route: '/aws/execute-aws-command',
  body: AWSQuery,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: ExecuteAWSCommandResponse,
});

export const executeBigQueryAPI = mkRestEndpoint({
  route: '/bigquery/execute-bigquery-api',
  body: t.intersection([BigQueryAPIQuery, SombraOptions]),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
  }),
  method: 'POST',
  responseBody: ExecuteBigQueryCommandResponse,
});

export const executeSftpCommand = mkRestEndpoint({
  route: '/sftp/execute-sftp-command',
  body: SftpCommandBody,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
  }),
  method: 'POST',
  responseBody: ExecuteSftpCommandResponse,
});

export const executeSmbCommand = mkRestEndpoint({
  route: '/smb/execute-smb-command',
  body: SmbCommandBody,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
  }),
  method: 'POST',
  responseBody: SmbCommandResponseBody,
});

export const executeCdataOdbcCommand = mkRestEndpoint({
  route: '/concur/execute-cdata-odbc-command',
  body: ExecuteCdataOdbcCommandBody,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
  }),
  method: 'POST',
  responseBody: ExecuteCdataOdbcCommandResponse,
});

export const testAWSConnection = mkRestEndpoint({
  route: '/aws/test-aws-connection',
  body: ConnectAWSClientRegion,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({}),
});

/** Encrypt sample data for subDataPoint */
export const encryptSample = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /database/encrypt-sample
  route: '/database/vectorize-sample',
  body: EncryptSampleRequestBody,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: EncryptSampleResponseBody,
});

/** Classify text internally using a specific model */
export const classifyTextWithLlamaCPP = mkRestEndpoint({
  route: '/llama-cpp/classify-text',
  body: t.intersection([
    ClassifyTextRequest,
    t.partial({
      options: t.any,
    }),
  ]),
  headers: t.any,
  method: 'POST',
  responseBody: ClassifyTextResult,
});

// TODO: https://transcend.height.app/T-37554 - add support for rest of llm features
export const evaluateClassifierModel = mkRestEndpoint({
  route: '/classifier/evaluate-classifier-model',
  body: EvaluateClassifierInput,
  headers: t.any,
  method: 'POST',
  responseBody: EvaluateClassifierOutput,
});

export const classifyTextWithLLMInternal = mkRestEndpoint({
  route: '/llm/classify-text',
  body: t.intersection([
    t.type({
      inputList: t.array(t.string),
      labels: t.array(t.string),
    }),
    t.partial({
      model_type: t.string,
    }),
  ]),
  headers: t.any,
  method: 'POST',
  responseBody: t.type({
    guesses: TypeGuessesWithConfidenceArrayOfArrays,
  }),
});

export const classifyUnstructuredTextWithLLMInternal = mkRestEndpoint({
  route: '/classify/unstructured-text',
  body: t.intersection([
    t.type({
      inputList: t.array(t.string),
      labels: t.array(t.string),
    }),
    t.partial({
      modelType: valuesOf(SelfHostedLLMType),
    }),
  ]),
  headers: t.any,
  method: 'POST',
  responseBody: t.type({
    guesses: UnstructuredCategoryGuessWithConfidenceArrayOfArrays,
  }),
});

export const classifyTextWithLLM = mkRestEndpoint({
  route: '/llm/classify-text',
  body: t.intersection([
    LLMClassifierInput,
    UpdateDataSubCategoriesRegexCache,
    t.partial({
      // Classifier prompt template (used mainly for internal testing)
      llmTemplate: LLMClassifierTemplate,
    }),
  ]),
  headers: t.any,
  method: 'POST',
  responseBody: t.type({
    guesses: TypeGuessesWithConfidenceArrayOfArrays,
  }),
});

export const classifyUnstructuredTextWithLLM = mkRestEndpoint({
  route: '/llm/classify-unstructured-text',
  body: ClassifyFreeTextArrays,
  headers: t.any,
  method: 'POST',
  responseBody: EncryptedClassifyTextResultSet,
});

/** @deprecated - TODO: https://transcend.height.app/T-32293 - Deprecate old Sombra endpoint for only text and instead use array one */
export const classifyTextWithRegex = mkRestEndpoint({
  route: '/regex/classify-text',
  body: ClassifyTextRequestWithLookBehind,
  headers: ClassifyTextOrTextArraysWithRegexHeaders,
  method: 'POST',
  responseBody: t.array(EncryptedClassifyTextResult),
});

export const classifyTextArraysWithRegex = mkRestEndpoint({
  route: '/regex/classify-text-arrays',
  body: ClassifyTextArraysWithRegexBody,
  headers: ClassifyTextOrTextArraysWithRegexHeaders,
  method: 'POST',
  responseBody: EncryptedClassifyTextResultSet,
});

export const employeeLogin = mkRestEndpoint({
  route: '/employee/login',
  body: t.type({
    payload: t.string,
    authMethod: valuesOf(SombraEmployeeAuthMethod),
    /**
     * The public key to diffie-hellman encrypt the session
     * TODO: https://transcend.height.app/user/mike - move this in signed payload
     */
    publicKey: t.string,
  }),
  headers: t.type({}),
  method: 'POST',
  responseBody: schemaToCodec(DecryptionContext),
});

export const employeeGenerateCEK = mkRestEndpoint({
  route: '/employee/generateCEK',
  body: t.type({
    /** The dhEncrypted payload with attestedAuthContext, actionTypeinside */
    dhEncrypted: t.string,
    /** ID of the DSR request */
    requestId: dbModelId('request'),
  }),
  headers: t.type({}),
  method: 'POST',
  responseBody: t.type({
    /** The encrypted CEK */
    encryptedCEKContext: t.string,
  }),
});

export const containsContent = mkRestEndpoint({
  route: '/contains-content',
  body: t.intersection([
    t.type({
      regexList: t.array(t.string),
      encryptedBodies: t.array(EncryptedDataPoint),
      remoteIdPaths: t.array(t.string),
    }),
    t.partial({
      isRegex: t.boolean,
      embeddedPaths: t.array(t.string),
    }),
  ]),
  headers: t.intersection([
    t.type({
      'x-sombra-encrypted-cek-context': t.string,
    }),
    t.partial({
      'x-sombra-identifier': t.string,
    }),
  ]),
  method: 'POST',
  responseBody: t.type({
    matchDict: MatchDict,
    matchDictByBody: t.array(MatchDict),
  }),
});

export const encryptContent = mkRestEndpoint({
  route: '/encrypt-content',
  body: t.type({
    content: t.string,
  }),
  headers: t.type({
    'x-sombra-saas-context': t.string,
    'x-sombra-encrypted-cek-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({
    encryptedBody: EncryptedDataPoint,
  }),
});

/**
 * TODO: https://transcend.height.app/T-13013 - Remove once made obsolete by /proxy/request.
 *
 * @deprecated - Please use v2ProxyRequest instead.
 */
export const proxySaaS = mkRestEndpoint({
  route: '/proxy',
  body: t.any,
  headers: t.intersection([
    t.type({
      'x-sombra-saas-context': t.string,
      'x-sombra-saas-host': t.string,
      'x-sombra-saas-uri': t.string,
      'x-sombra-saas-plaintext-paths': t.string,
    }),
    t.partial({
      'x-sombra-saas-context-paths': t.string,
      'x-sombra-saas-strip-paths': t.string,
      'x-sombra-checksum-paths': t.string,
      'x-sombra-saas-embedded-path': t.string,
      'x-sombra-content-type': t.string,
      'x-sombra-encrypted-paths': t.string,
    }),
  ]),
  method: 'POST', // can be any method
  responseBody: t.union([
    // Undefined only allowed if statusCode = 204
    t.undefined,
    t.type({
      /** Plaintext fields */
      plaintextBody: t.record(t.string, t.array(t.any)),
      /** Context from json paths */
      contextBody: t.record(t.string, t.array(t.any)),
      /** The request body encrypted */
      encryptedBody: t.array(t.union([EncryptedDataPoint, t.null])),
      /** The response headers */
      responseHeaders: t.record(
        t.string,
        t.union([t.string, t.array(t.string)]),
      ),
      /** Encrypted fields - will be required after sombra version 7.26.0 */
      encryptedFields: t.record(t.string, t.array(t.any)),
    }),
  ]),
});

/**
 * TODO: https://transcend.height.app/T-17208 - Remove once made obsolete by /proxy/request.
 *
 * @deprecated - Please use v2ProxyRequest instead.
 */
export const proxySaaSTest = mkRestEndpoint({
  route: '/proxy/authentication-test',
  body: t.any,
  headers: t.intersection([
    t.type({
      'x-sombra-saas-context': t.string,
      'x-sombra-saas-host': t.string,
      'x-sombra-saas-uri': t.string,
    }),
    t.partial({
      'x-sombra-content-type': t.string,
      'x-sombra-saas-plaintext-paths': t.string,
    }),
  ]),
  method: 'POST', // can be any method
  responseBody: t.union([
    // Undefined only allowed if statusCode = 204
    t.undefined,
    t.type({
      /** Plaintext fields */
      plaintextBody: t.record(t.string, t.array(t.any)),
      /** The response headers */
      responseHeaders: t.record(
        t.string,
        t.union([t.string, t.array(t.string)]),
      ),
    }),
  ]),
});

/**
 * Proxy route to interact with a remote API.
 *
 * We return an encryptedBody from this function, only if
 * we're provided DSR Content Encryption Keys, else we return an
 * empty encryptedBody.
 *
 * @deprecated - Please use v2ProxyRequest instead.
 */
export const proxyRequest = mkRestEndpoint({
  route: '/proxy/request',
  body: t.unknown,
  method: 'POST',
  headers: t.intersection([
    t.type({
      /** Data silo SaaS context. */
      'x-sombra-saas-context': t.string,
      /** Remote host. */
      'x-sombra-saas-host': t.string,
      /** Remote URI. */
      'x-sombra-saas-uri': t.string,
    }),
    t.partial({
      /** sombra identifier to template */
      'x-sombra-identifier': t.string,
      /** authorization header */
      'x-sombra-authorization': t.string,
      /** Final request's Content-Type header. */
      'x-sombra-content-type': t.string,
      /** Plaintext paths to pull from the response from the remote API. */
      'x-sombra-saas-plaintext-paths': t.string,
      /** Context paths to pull from the response from the remote API. */
      'x-sombra-saas-context-paths': t.string,
      /** Paths to strip from the response from the remote API. */
      'x-sombra-saas-strip-paths': t.string,
      /**
       * Paths that should be signed as identifiers.
       * This is used for enrichment via integrations.
       * Eventually these identifiers should be encrypted
       * and this field should be removed
       * TODO: https://transcend.height.app/T-1793
       */
      'x-sombra-signed-identifiers-paths': t.string,
      /** Paths to compose a checksum from. */
      'x-sombra-checksum-paths': t.string,
      /** Path to embedded JSON array, to return as response. */
      'x-sombra-saas-embedded-path': t.string,
      /** Paths to return as encrypted entity from the response from the remote API. */
      'x-sombra-encrypted-paths': t.string,
      /** Use CEK key when true, base key if false */
      'x-sombra-use-dsr-cek': t.union([
        t.literal('true'),
        t.literal('false'),
        t.undefined,
      ]),
    }),
  ]),
  responseBody: t.union([
    // Undefined only allowed if statusCode = 204
    t.undefined,
    t.union([
      t.type({
        /** Signed identifiers corresponding to x-sombra-signed-identifiers-paths */
        signedIdentifiers: SignedIdentifiers,
        /** Plaintext fields */
        plaintextBody: t.record(t.string, t.array(t.unknown)),
        /** Context from json paths */
        contextBody: t.record(t.string, t.array(t.unknown)),
        /** The request body encrypted */
        encryptedBody: t.array(t.union([EncryptedDataPoint, t.null])),
        /** The response headers */
        responseHeaders: t.record(
          t.string,
          t.union([t.string, t.array(t.string)]),
        ),
        /** Encrypted fields. */
        encryptedFields: t.record(t.string, t.array(t.unknown)),
      }),
      t.partial({
        /** Error body, if exists. */
        errorBody: t.unknown,
      }),
    ]),
  ]),
});

export const proxyDatabase = mkRestEndpoint({
  route: '/proxy/database',
  body: ProxyDatabaseRequestBody,
  headers: t.intersection([
    t.type({
      'x-data-silo-id': dbModelId('dataSilo'),
      'x-data-silo-type': t.string,
      'x-sombra-saas-context': t.string,
      'x-sombra-use-transaction': t.union([
        t.literal('true'),
        t.literal('false'),
        t.undefined,
      ]),
      'x-sombra-encrypted-cek-context': t.string,
      'x-sombra-identifier': t.string,
    }),
    t.partial({
      /**
       * Paths that should be signed as identifiers.
       * This is used for enrichment via integrations.
       * Eventually these identifiers should be encrypted
       * and this field should be removed
       * TODO: https://transcend.height.app/T-1793
       */
      'x-sombra-signed-identifiers-paths': t.string,
    }),
  ]),
  method: 'POST',
  responseBody: ProxyDatabaseResponseBody,
});

export const proxyDatabaseTest = mkRestEndpoint({
  route: '/proxy/database-test',
  body: t.undefined,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'GET',
  responseBody: t.type({}),
});

export const proxyDatabasePost = mkRestEndpoint({
  route: '/proxy/database-test',
  body: t.type({
    bigQuery: BigQueryAPIRequest,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({}),
});

export const proxyTreasureData = mkRestEndpoint({
  route: '/proxy/treasureData',
  body: ProxyTreasureDataRequest,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
    'x-sombra-identifier': t.string,
  }),
  method: 'POST',
  responseBody: ProxyTreasureDataResponse,
});

export const proxyGoogleCloudSpanner = mkRestEndpoint({
  route: '/proxy/googleCloudSpanner',
  body: ProxyGoogleCloudSpannerRequest,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
    'x-sombra-identifier': t.string,
  }),
  method: 'POST',
  responseBody: ProxyGoogleCloudSpannerResponse,
});

export const testSftpConnection = mkRestEndpoint({
  route: '/proxy/test-sftp-connection',
  body: t.undefined,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({}),
});

export const testSmbConnection = mkRestEndpoint({
  route: '/proxy/test-smb-connection',
  body: t.undefined,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({}),
});

export const testCdataOdbcConnection = mkRestEndpoint({
  route: '/proxy/test-cdata-odbc-connection',
  body: t.type({
    connectionString: t.string,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({}),
});

export const proxyRedact = mkRestEndpoint({
  route: '/proxy/redact',
  body: t.intersection([
    t.type({
      /** The encrypted body to start redacting */
      encryptedBody: EncryptedDataPoint,
      /** Upstream body shape -- optional b/c GETs may lack bodies; */
      upstreamBody: t.any,
    }),
    t.partial({
      /** Indices to redact */
      redactIndices: CRedactIndices,
      /** Strings to redact */
      redactStrings: t.array(t.string),
      /** When set to true, redact everything possible */
      redactAll: t.boolean,
    }),
  ]),
  headers: t.intersection([
    t.type({
      'x-sombra-saas-context': t.string,
      'x-sombra-saas-host': t.string,
      'x-sombra-saas-uri': t.string,
      'x-sombra-saas-plaintext-paths': t.string,
      'x-sombra-encrypted-cek-context': t.string,
    }),
    t.partial({
      'x-sombra-content-type': t.string,
    }),
  ]),
  method: 'POST',
  responseBody: t.intersection([
    t.type({
      /** Plaintext fields */
      plaintextBody: t.record(t.string, t.array(t.any)),
      /** The response headers */
      responseHeaders: t.record(
        t.string,
        t.union([t.string, t.array(t.string)]),
      ),
    }),
    t.partial({
      /** Error body, if any. */
      errorBody: t.any,
    }),
  ]),
});

export const proxyIndex = mkRestEndpoint({
  route: '/proxy/index',
  body: t.intersection([
    t.type({
      /** List of regexes to index */
      regexList: t.array(t.string),
      /** Remote index paths */
      remoteIndexPaths: t.array(t.string), // TODO: https://transcend.height.app/T-6216 - rename to remoteId
    }),
    t.partial({
      /** HTTP method */
      method: valuesOf(HttpMethod),
      /** The upstream body */
      upstreamBody: t.any,
    }),
  ]),
  headers: t.intersection([
    t.type({
      'x-sombra-saas-context': t.string,
      'x-sombra-saas-host': t.string,
      'x-sombra-saas-uri': t.string,
      'x-sombra-saas-plaintext-paths': t.string,
    }),
    t.partial({
      'x-sombra-content-type': t.string,
      'x-sombra-saas-embedded-path': t.string,
    }),
  ]),
  method: 'POST',
  responseBody: t.type({
    /** Plaintext fields */
    plaintextBody: t.record(t.string, t.array(t.any)),
    /** Dictionary of indexed fields */
    matchDict: MatchDict,
  }),
});

// TODO: https://github.com/transcend-io/main/issues/8539 - type better
export const encryptIdentifier = mkRestEndpoint({
  route: '/saas/encrypt-identifier',
  body: t.any,
  headers: t.any,
  method: 'POST',
  responseBody: t.string,
});

export const webhook = mkRestEndpoint({
  route: '/webhook',
  body: t.union([
    TranscendWebhookIntegrationBasePayload,
    AvcBulkManualNotification,
  ]),
  headers: t.intersection([
    t.type({
      'x-sombra-encrypted-cek-context': t.string,
      'x-target': t.string,
    }),
    // TODO: https://transcend.height.app/T-13633 - make this required eventually
    t.partial({
      'x-sombra-request-id': dbModelId('request'),
      'x-sombra-identifier': t.string,
      'x-skip-core-identifier': t.union([
        t.literal('true'),
        t.literal('false'),
      ]),
    }),
  ]),
  method: 'POST',
  responseBody: t.union([
    t.undefined,
    t.string,
    t.void,
    t.partial({
      status: valuesOf(AllowedEnricherTransition),
      templateId: dbModelId('template'),
      templateTitle: t.string,
      signedRequestIdentifiers: t.record(t.string, t.array(t.string)),
    }),
  ]),
});

export const avcWebhook = mkRestEndpoint({
  route: '/webhook',
  body: AvcBulkManualNotification,
  headers: webhook.headers,
  method: 'POST',
  responseBody: t.any,
});

export const listPendingRequests = mkRestEndpoint({
  route: '/data-silo/:dataSiloId/pending-requests/:actionType',
  body: t.void,
  headers: t.type({
    authorization: t.string,
  }),
  method: 'GET',
  responseBody: t.type({
    items: t.array(
      t.type({
        /** Type of request */
        type: t.string,
        /** Identifier value */
        identifier: t.string,
        /** Core identifier */
        coreIdentifier: t.string,
        /** Time request was created */
        requestCreatedAt: t.string,
        /** Days until request is overdue */
        daysUntilOverdue: t.number,
        /** Request CEK context */
        encryptedCEKContext: t.string,
        /** Attributes */
        attributes: t.array(
          t.type({
            key: t.string,
            values: t.array(t.string),
          }),
        ),
        /** Request ID */
        requestId: dbModelId('request'),
        /** Data silo ID */
        dataSiloId: dbModelId('dataSilo'),
        /** Profile ID */
        profileId: dbModelId('profile'),
      }),
    ),
  }),
});

export const getDataSiloRedactionMetadata = mkRestEndpoint({
  route: '/data-silo/:dataSiloId/redaction-metadata',
  headers: t.type({
    authorization: t.string,
  }),
  method: 'POST',
  body: t.type({
    dataPointNames: t.record(t.string, t.array(t.string)),
  }),
  responseBody: t.type({
    /** Mapping from datapoint name -> subdatapoint names to redact for that datapoint */
    dataPointNameToRedactedFields: t.record(t.string, t.array(t.string)),
    /** The default access request visibility setting for the data silo */
    defaultAccessRequestVisibility: t.boolean,
    /** The new datapoints that does already exist */
    newDataPoints: t.array(t.string),
  }),
});

export const getOrganizationDataSubCategoriesMetadata = mkRestEndpoint({
  route: '/data-sub-categories/:sombraAudience/metadata',
  headers: DataSubCategoryHeaders,
  method: 'GET',
  body: t.type({}),
  responseBody: t.type({
    /** Data sub categories found for the specified organization */
    dataSubCategories: DataSubCategoriesForSombra,
  }),
});

export const getConfirmedSubDataPoints = mkRestEndpoint({
  route: '/subdatapoint/:sombraAudience/confirmed-subdatapoints',
  headers: DataSubCategoryHeaders,
  method: 'POST',
  body: ConfirmedSubDataPointsInput,
  responseBody: t.type({
    /** Data sub categories found for the specified organization */
    confirmedSubDataPoints: ConfirmedSubDataPoints,
  }),
});

export const getSubDataPointSamples = mkRestEndpoint({
  route: '/subdatapoint/:sombraAudience/samples',
  headers: DataSubCategoryHeaders,
  method: 'POST',
  body: SubDataPointsForSombra,
  responseBody: SubDataPointsForSombra,
});

export const refreshDataSubCategoriesCache = mkRestEndpoint({
  route: '/data-sub-categories/:sombraAudience/refresh-cache',
  headers: DataSubCategoryHeaders,
  method: 'POST',
  body: t.type({}),
  responseBody: t.type({
    /** Data sub categories found for the specified organization */
    dataSubCategories: DataSubCategoriesForSombra,
  }),
});

export const sombraLLMClassifierPrompt = mkRestEndpoint({
  route: '/llm-utils/:sombraAudience/prompt',
  headers: t.type({}),
  method: 'POST',
  body: SombraClassifierTemplateBody,
  responseBody: LLMClassifierTemplate,
});

export const proxyFileStream = mkRestEndpoint({
  route: '/proxy/file-stream',
  method: 'GET',
  body: t.union([t.null, t.undefined, t.record(t.string, t.unknown)]),
  headers: t.intersection([
    t.type({
      'x-transcend-token': t.string,
      'x-transcend-prompt-a-person-token': t.string,
      'x-transcend-request-id': t.string,
      'x-sombra-saas-context': t.string,
      'x-sombra-saas-host': t.string,
      'x-sombra-saas-uri': t.string,
      'x-sombra-encrypted-cek-context': t.string,
      'x-sombra-file-type': t.string,
    }),
    t.partial({
      'x-sombra-content-type': t.string,
      'x-sombra-should-unzip': t.string,
    }),
  ]),
  // This is a stream, so there's no `body` parameter as such
  // without getting too into the weeds.
  responseBody: t.unknown,
});

export const proxySftpFileStream = mkRestEndpoint({
  route: '/proxy/sftp-file-stream',
  method: 'GET',
  body: t.union([t.null, t.undefined, t.record(t.string, t.unknown)]),
  headers: t.type({
    'x-transcend-token': t.string,
    'x-transcend-prompt-a-person-token': t.string,
    'x-transcend-request-id': t.string,
    'x-transcend-remote-id': t.string,
    'x-sombra-saas-context': t.string,
    'x-sombra-encrypted-cek-context': t.string,
  }),
  responseBody: t.unknown,
});

export const uploadChunkToDataPointInput = mkRestEndpoint({
  route: '/v1/datapoint-chunked',
  method: 'POST',
  body: t.intersection([
    t.type({
      /** Datapoint to associate the data with. */
      dataPointName: t.string,
      /** input JSON gets encrypted */
      data: t.array(t.any),
    }),
    t.partial({
      /** Flag signifying if the current chunk is the last "page" of information. */
      isLastPage: t.boolean,
      /** File ID. */
      fileId: t.string,
    }),
  ]),
  headers: t.type({
    'x-transcend-nonce': t.string,
  }),
  responseBody: t.any,
});

export const uploadChunkToDataPoint = mkRestEndpoint({
  route: '/v1/datapoint-chunked',
  method: 'POST',
  body: t.intersection([
    t.type({
      dataPointName: t.string,
      // encrypted input
      data: t.union([EncryptedDataPoint, t.null]),
    }),
    t.partial({
      isLastPage: t.boolean,
      fileId: t.string,
    }),
  ]),
  headers: t.type({
    'x-transcend-nonce': t.string,
  }),
  responseBody: t.any,
});

export const enrichIdentifiers = mkRestEndpoint({
  route: '/v1/enrich-identifiers',
  method: 'POST',
  body: t.partial({
    status: valuesOf(PreflightRequestStatus),
    templateId: dbModelId('template'),
    templateTitle: t.string,
    enrichedIdentifiers: EnrichedIdentifiers,
  }),
  headers: t.partial({
    'x-transcend-nonce': t.string,
    'x-transcend-request-id': t.string,
    'x-transcend-enricher-id': t.string,
  }),
  responseBody: t.any,
});

export const signRequestIdentifiers = mkRestEndpoint({
  route: '/v1/sign-request-identifiers',
  method: 'POST',
  body: t.void,
  headers: t.type({
    /** Dh payload as authentication */
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: t.type({
    signedIdentifiers: SignedIdentifiers,
  }),
});

export const verifyCrossReferenceIdentifiers = mkRestEndpoint({
  route: '/v1/verify-cross-reference-identifiers',
  method: 'POST',
  body: t.type({
    identifierMapping: IdentifierMapping,
  }),
  headers: t.void,
  responseBody: t.type({
    crossReferencePassed: t.boolean,
    verifiedIdentifiers: t.array(
      t.type({
        name: t.string,
        jwts: t.array(t.string),
      }),
    ),
    failingIdentifiers: FailingIdentifiers,
  }),
});

export const addSignedIdentifiers = mkMutation({
  name: 'addSignedIdentifiers',
  comment: 'Add new signed and enriched identifiers.',
  params: {
    input: SignedIdentifiersInput,
  },
  response: {
    success: 'boolean',
  },
});

export const transformEncryptedIdentifiers = mkRestEndpoint({
  route: '/saas/transform-encrypted-identifiers',
  body: t.type({
    transformations: t.array(
      t.type({
        /** The encrypted identifier */
        identifier: t.string,
        /** The set of mutations to perform, in order. */
        mutators: t.array(t.string),
      }),
    ),
  }),
  headers: t.type({}),
  method: 'POST',
  responseBody: t.type({
    transformations: TransformedEncryptedIdentifiers,
  }),
});

export const encryptSampleMongoDb = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /database/encrypt-sample-mongodb
  route: '/database/vectorize-sample-mongodb',
  body: t.intersection([
    t.type({
      dataPointLevels: t.array(t.string),
      dataPoint: t.string,
      subDataPoints: t.array(t.string),
    }),
    t.partial({
      limit: t.number,
      aggregateLimit: t.number,
      queryTimeout: t.number,
      type: t.string,
    }),
  ]),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({
    subDataPoints: t.array(SubDataPointWithSamples),
  }),
});

export const encryptSampleDynamoDb = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /database/encrypt-sample-dynamodb
  route: '/database/vectorize-sample-dynamodb',
  body: t.type({
    dataPoint: t.string,
    attributes: t.array(t.string),
    exclusiveStartKey: t.union([t.record(t.string, t.any), t.undefined]),
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({
    subDataPoints: t.array(SubDataPointWithSamples),
    lastEvaluatedKey: t.union([t.record(t.string, t.any), t.undefined]),
  }),
});

export const encryptSampleRedis = mkRestEndpoint({
  route: '/database/vectorize-sample-redis',
  body: t.type({
    dataPoint: t.string,
  }),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({
    subDataPoints: t.array(SubDataPointWithSamples),
  }),
});

export const encryptSampleGoogleCloudSpanner = mkRestEndpoint({
  route: '/saas/vectorize-sample-googlecloudspanner',
  body: t.type({
    fields: t.array(t.string),
    encryptedSamples: t.array(t.string),
  }),
  headers: t.type({}),
  method: 'POST',
  responseBody: t.type({
    subDataPoints: t.array(SubDataPointWithSamples),
  }),
});

export const encryptSampleAmazonS3 = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /database/encrypt-sample-amazons3
  route: '/database/vectorize-sample-amazons3',
  body: t.intersection([
    t.type({
      dataPoint: t.string,
      subDataPoint: t.string,
    }),
    t.partial({
      range: t.string,
    }),
  ]),
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: t.type({
    subDataPoint: SubDataPointWithSamples,
  }),
});

export const encryptSampleS3Parquet = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /database/encrypt-sample-s3parquet
  route: '/database/vectorize-sample-s3parquet',
  body: EncryptedSampleS3FileTypeRequest,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: EncryptedSampleResponse,
});

export const encryptSampleS3JSONL = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /database/encrypt-sample-s3jsonl
  route: '/database/vectorize-sample-s3jsonl',
  body: EncryptedSampleS3FileTypeRequest,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: EncryptedSampleResponse,
});

export const encryptSampleJira = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-43882 - rename to more generic encryptFields
  route: '/saas/vectorize-sample-jira',
  body: t.type({
    encryptedSamples: t.array(t.string),
  }),
  headers: t.type({}),
  method: 'POST',
  responseBody: t.type({
    subDataPoints: t.array(SubDataPointWithSamples),
  }),
});

export const encryptSampleGCSParquet = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /gcs/encrypt-sample-gcs-parquet
  route: '/gcs/vectorize-sample-gcs-parquet',
  body: EncryptedSampleGCSFileTypeRequest,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: EncryptedSampleResponse,
});

export const encryptSampleGCSJSONL = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /gcs/encrypt-sample-gcs-jsonl
  route: '/gcs/vectorize-sample-gcs-jsonl',
  body: EncryptedSampleGCSFileTypeRequest,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  method: 'POST',
  responseBody: EncryptedSampleResponse,
});

export const restructureSubDataPointWithSamples = mkRestEndpoint({
  // TODO: https://transcend.height.app/T-39830 - reroute to /saas/restructure-sample
  route: '/saas/vectorize-sample',
  body: t.type({
    /**
     * the list of encrypted JSON stringified row objects (each column is a
     * field on the object)
     */
    encryptedSamples: t.array(t.string),
  }),
  headers: t.type({}),
  method: 'POST',
  responseBody: t.type({
    subDataPoints: t.array(SubDataPointWithSamples),
  }),
});

export const encryptSampleCdataOdbc = mkRestEndpoint({
  route: '/database/vectorize-sample-cdata',
  body: t.type({
    dataPoint: t.string,
    subDataPoints: t.array(t.string),
  }),
  headers: t.type({}),
  method: 'POST',
  responseBody: t.type({
    subDataPoints: t.array(SubDataPointWithSamples),
  }),
});

export const transformToClientEncryption = mkRestEndpoint({
  route: '/saas/transform-to-client-encryption',
  method: 'POST',
  body: t.type({
    content: t.array(t.string),
  }),
  headers: t.type({
    /** Dh payload as authentication */
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: t.type({
    content: t.array(WrappedKeyWithMetadata),
  }),
});

export const v2ProxyRequest = mkRestEndpoint({
  route: '/v2/proxy/request',
  method: 'POST',
  body: t.intersection([
    t.type({
      /**
       * The fully formed URL to target.
       * This includes the query params, hash parameters, and the entire base host, path, and protocol.
       */
      url: t.string,
      /** HTTP method. */
      method: valuesOf(HttpMethod),
      /** Request body. */
      requestBody: t.unknown,
      /** Final request's Content-Type header. */
      contentType: t.string,
      /** Request headers. */
      headers: t.record(t.string, t.string),
      /**
       * Options for query string stringification
       * This should match the IStringifyOptions interface from the qs package.
       */
      qsStringifyOptions: t.union([
        t.partial({
          /** Whether to encode the query string */
          encode: t.boolean,
          /** Format for array parameters */
          arrayFormat: t.union([
            t.literal('indices'),
            t.literal('brackets'),
            t.literal('repeat'),
            t.literal('comma'),
          ]),
          /** Whether to encode values only */
          encodeValuesOnly: t.boolean,
          /** Delimiter for array parameters */
          delimiter: t.string,
          /** Whether to include indices in array parameters */
          indices: t.boolean,
          /** Function to serialize Date objects */
          serializeDate: t.union([
            t.literal('toISOString'),
            t.literal('toJSON'),
            t.any, // Using t.any for the function type to match the expected signature
          ]),
          /** Whether to skip null values */
          skipNulls: t.boolean,
          /** Whether to skip undefined values */
          skipUndefined: t.boolean,
          /** Whether to handle null values strictly */
          strictNullHandling: t.boolean,
          /** Function to encode values */
          encoder: t.any,
          /** Function to filter values */
          filter: t.any,
          /** Function to sort keys */
          sort: t.any,
          /** Function to format values */
          format: t.any,
          /** Whether to add query prefix */
          addQueryPrefix: t.boolean,
          /** Whether to allow dots in keys */
          allowDots: t.boolean,
          /** Character set */
          charSet: t.union([t.literal('utf-8'), t.literal('iso-8859-1')]),
          /** Whether to use charset sentinel */
          charSetSentinel: t.boolean,
        }),
        t.undefined,
      ]),
      /**
       * Options for query string parsing
       * This should match the IParseOptions interface from the qs package.
       */
      qsParserOptions: t.union([
        t.partial({
          /** Whether to parse arrays */
          parseArrays: t.boolean,
          /** Whether to allow dots in keys */
          allowDots: t.boolean,
          /** Whether to allow prototypes */
          allowPrototypes: t.boolean,
          /** Whether to handle null values strictly */
          strictNullHandling: t.boolean,
          /** Whether to ignore query prefix */
          ignoreQueryPrefix: t.boolean,
          /** Whether to interpret numeric entities */
          interpretNumericEntities: t.boolean,
          /** Whether to use plain objects */
          plainObjects: t.boolean,
          /** Whether to use commas as delimiters */
          comma: t.boolean,
          /** Delimiter for array parameters */
          delimiter: t.union([t.string, t.any]),
          /** Maximum depth for nested objects */
          depth: t.union([t.number, t.literal(false)]),
          /** Function to decode values */
          decoder: t.any,
          /** Maximum number of parameters */
          parameterLimit: t.number,
          /** Maximum number of array elements to parse */
          arrayLimit: t.number,
          /** Character set */
          charset: t.union([t.literal('utf-8'), t.literal('iso-8859-1')]),
          /** Whether to use charset sentinel */
          charsetSentinel: t.boolean,
        }),
        t.undefined,
      ]),
      /** Log context. */
      logContext: t.partial({
        /** Data Silo Type. */
        dataSiloType: t.string,
        /** Data Silo Id. */
        dataSiloId: dbModelId('dataSilo'),
        /** Organisation URI. */
        organizationUri: t.string,
        /** Organisation Id. */
        organizationId: dbModelId('organization'),
        /** Request Id. */
        requestId: dbModelId('request'),
        /** Request Type. */
        requestType: t.string,
      }),
    }),
    SombraOptions,
  ]),
  headers: t.record(t.string, t.string),
  responseBody: t.union([
    // Undefined only allowed if statusCode = 204
    t.undefined,
    t.intersection([
      t.type({
        /** Signed identifiers corresponding to x-sombra-signed-identifiers-paths */
        signedIdentifiers: SignedIdentifiers,
        /** Plaintext fields */
        plaintextBody: t.record(t.string, t.array(t.any)),
        /** Context from json paths */
        contextBody: t.record(t.string, t.array(t.any)),
        /** The response headers */
        responseHeaders: t.record(
          t.string,
          t.union([t.string, t.array(t.string)]),
        ),
        /**
         * The request bodies encrypted. There will be
         * more than one body, if we use `embeddedPath`.
         */
        encryptedBodies: t.array(t.union([EncryptedDataPoint, t.null])),
        /** Encrypted fields. */
        encryptedFields: ProxyRequestEncryptedFields,
      }),
      t.partial({
        /** Error body, if exists. */
        errorBody: t.unknown,
      }),
    ]),
  ]),
});

export const infoJson = mkRestEndpoint({
  route: '/info-json',
  method: 'GET',
  body: t.null,
  headers: t.record(t.string, t.string),
  responseBody: t.intersection([
    t.type({
      SOMBRA_VERSION: t.string,
      serverType: t.union([t.literal('Internal'), t.literal('External')]),
    }),
    t.partial({
      BUILD_TIME: t.string,
      BUILD_VERSION: t.string,
      LLM_CLASSIFIER_URL: t.string,
    }),
  ]),
});

export const redactFileInPlace = mkRestEndpoint({
  route: '/redact-files-in-place',
  body: t.type({
    /** Presigned s3 put url */
    presignedPutUrl: t.string,
    /** Presigned s3 get url */
    presignedGetUrl: t.string,
    /** Indices to redact */
    redactIndices: CRedactIndices,
  }),
  headers: t.any,
  method: 'POST',
  responseBody: t.type({
    success: t.boolean,
    signature: t.string,
    length: t.number,
  }),
});

export const consentPreferences = mkRestEndpoint({
  route: '/v1/consent-preferences',
  method: 'GET',
  body: QueryConsentRecords,
  headers: t.type({
    authorization: t.string,
  }),
  responseBody: ConsentRecordsQueryResponse,
});

export const preferences = mkRestEndpoint({
  route: '/v1/preferences/:partition/query',
  method: 'POST',
  body: QueryPreferenceRecords,
  headers: t.type({
    authorization: t.string,
  }),
  responseBody: PreferenceRecordsQueryResponse,
});

export const upsertPreferences = mkRestEndpoint({
  route: '/v1/preferences',
  method: 'PUT',
  body: UpsertPreferenceRecordsRequest,
  headers: t.type({
    authorization: t.string,
  }),
  responseBody: UpsertPreferenceRecordsResponse,
});

// Available routes in the python server
export const PY_ROUTES = {
  [PluginType.SchemaDiscovery]: {
    [SupportedS3FileType.PARQUET]: 'fetch-schema-s3-parquet',
    [SupportedS3FileType.JSONL]: 'fetch-schema-s3-jsonl',
    [SupportedGCSFileType.PARQUET]: 'fetch-schema-gcs-parquet',
    [SupportedGCSFileType.JSONL]: 'fetch-schema-gcs-jsonl',
  },
  [PluginType.ContentClassification]: {
    [SupportedS3FileType.PARQUET]: 'get-samples-s3-parquet',
    [SupportedS3FileType.JSONL]: 'get-samples-s3-jsonl',
    [SupportedGCSFileType.PARQUET]: 'get-samples-gcs-parquet',
    [SupportedGCSFileType.JSONL]: 'get-samples-gcs-jsonl',
  },
};

export const decryptConsentIdentifiers = mkRestEndpoint({
  route: '/consent/decrypt-identifiers',
  method: 'POST',
  body: DecryptConsentIdentifierOptions,
  headers: t.type({
    /** Dh payload as authentication */
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: schemaToCodec(DecryptionContext),
});

export const encryptConsentIdentifiers = mkRestEndpoint({
  route: '/consent/encrypt-identifiers',
  method: 'POST',
  body: EncryptConsentIdentifierOptions,
  headers: t.type({
    /** Dh payload as authentication */
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: EncryptConsentIdentifiersResponse,
});

export const dataSubjectDecryptConsentIdentifiers = mkRestEndpoint({
  route: '/consent/data-subject/decrypt-identifiers',
  method: 'POST',
  body: DecryptConsentIdentifierOptions,
  headers: t.type({
    /** Dh payload as authentication */
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: schemaToCodec(DecryptionContext),
});

export const dataSubjectEncryptConsentIdentifiers = mkRestEndpoint({
  route: '/consent/data-subject/encrypt-identifiers',
  method: 'POST',
  body: EncryptConsentIdentifierOptions,
  headers: t.type({
    /** Dh payload as authentication */
    'x-sombra-dh-encrypted': t.string,
  }),
  responseBody: EncryptConsentIdentifiersResponse,
});

export const generateCEKFromConsentIdentifiers = mkRestEndpoint({
  route: '/consent/generateCEKs',
  method: 'POST',
  body: GenerateCEKFromConsentIdentifiersRequest,
  headers: t.type({}),
  responseBody: GenerateCEKsResponse,
});

export const dataSubjectGenerateCEKs = mkRestEndpoint({
  route: '/data-subject/generateCEKs',
  method: 'POST',
  body: GenerateCEKRequest,
  headers: t.type({}),
  responseBody: GenerateCEKsResponse,
});

export const employeeGenerateCEKs = mkRestEndpoint({
  route: '/employee/generateCEKs',
  method: 'POST',
  body: GenerateCEKRequest,
  headers: t.type({}),
  responseBody: GenerateCEKsResponse,
});

export const fetchAndChunkTextFile = mkRestEndpoint({
  route: '/saas/unstructured-discovery/fetch-and-chunk-text',
  method: 'POST',
  body: FetchAndChunkTextFileBody,
  headers: t.type({}),
  responseBody: FetchAndChunkTextFileResponse,
});

export const fetchAndChunkFileFromS3 = mkRestEndpoint({
  route: '/saas/unstructured-discovery/fetch-and-chunk-text/s3',
  method: 'POST',
  body: FetchAndChunkFileFromS3Body,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  responseBody: FetchAndChunkTextFileResponse,
});

export const fetchAndChunkFileFromAzure = mkRestEndpoint({
  route: '/saas/unstructured-discovery/fetch-and-chunk-text/azure',
  method: 'POST',
  body: FetchAndChunkFileFromAzureBody,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  responseBody: FetchAndChunkTextFileResponse,
});

export const fetchAndChunkFileFromSmb = mkRestEndpoint({
  route: '/saas/unstructured-discovery/fetch-and-chunk-text/smb',
  method: 'POST',
  body: FetchAndChunkFileFromSmbBody,
  headers: t.type({
    'x-data-silo-id': dbModelId('dataSilo'),
    'x-data-silo-type': t.string,
    'x-sombra-saas-context': t.string,
  }),
  responseBody: FetchAndChunkTextFileResponse,
});

export const sombraReverseTunnelAuth = mkRestEndpoint({
  route: '/sombra/tunnel/authenticate',
  headers: t.type({
    authorization: t.string,
  }),
  method: 'GET',
  body: t.UnknownRecord,
  responseBody: t.type({
    /** JWT string to use with the Piko agent. */
    token: t.string,
    /** The Sombra Piko proxy cluster to use. */
    clusterUrl: t.string,
  }),
});

export const sombraReverseTunnelKeyCheck = mkRestEndpoint({
  route: '/sombra/tunnel/key-check',
  headers: t.type({
    authorization: t.string,
  }),
  method: 'GET',
  body: t.UnknownRecord,
  responseBody: t.UnknownRecord,
});

export const pingTest = mkRestEndpoint({
  route: '/test',
  body: t.UnknownRecord,
  method: 'GET',
  responseBody: t.string,
  headers: t.UnknownRecord,
});

export const customFunctionSign = mkRestEndpoint({
  route: '/v2/custom/sign',
  method: 'POST',
  headers: t.record(t.string, t.string),
  body: t.type({
    /** DH encrypted payload containing the code and context to be signed. */
    dhEncrypted: t.string,
  }),
  responseBody: t.type({
    /** Signed code JWT. */
    signedCodeJwt: t.string,
    /** Signed code context JWT. */
    signedCodeContextJwt: t.string,
  }),
});

export const customFunctionExecute = mkRestEndpoint({
  route: '/v2/custom/execute',
  body: CustomFunctionExecuteRequest,
  headers: t.UnknownRecord,
  method: 'POST',
  responseBody: CustomFunctionExecuteResult,
});
/* eslint-enable max-lines */

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ArrowsMaximize: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1437_23280)">
      <path
        d="M0.72539 12.9036C0.72539 12.9884 0.741796 13.0732 0.777343 13.1552C0.842968 13.3138 0.946875 13.4423 1.13062 13.5092C1.21074 13.5424 1.29597 13.5599 1.38164 13.5599L5.3175 13.5615C5.6798 13.5615 5.97539 13.2646 5.97539 12.9036C5.97539 12.5427 5.68281 12.2474 5.31914 12.2474L2.96484 12.2474L6.21984 8.99348C6.47619 8.73699 6.47633 8.32178 6.21984 8.06543C5.96336 7.80908 5.54814 7.80908 5.2918 8.06543L2.03789 11.3193L2.03789 8.96613C2.03789 8.60383 1.74258 8.30988 1.38164 8.30988C1.0207 8.30988 0.722656 8.60547 0.722656 8.96777L0.72539 12.9036Z"
        fill={color}
      />
      <path
        d="M13.2824 1.96954C13.2824 1.88477 13.266 1.8 13.2305 1.71797C13.1648 1.55938 13.0609 1.43086 12.8772 1.36401C12.7971 1.3308 12.7118 1.31329 12.6262 1.31329L8.69031 1.31164C8.32801 1.31164 8.03242 1.6086 8.03242 1.96954C8.03242 2.33047 8.325 2.62579 8.68867 2.62579L11.043 2.62579L7.78797 5.87969C7.53162 6.13618 7.53148 6.55139 7.78797 6.80774C8.04445 7.06409 8.45967 7.06409 8.71602 6.80774L11.9699 3.55383L11.9699 5.90704C11.9699 6.26934 12.2652 6.56329 12.6262 6.56329C12.9871 6.56329 13.2852 6.2677 13.2852 5.9054L13.2824 1.96954Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1437_23280">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 14.2852 14)"
        />
      </clipPath>
    </defs>
  </svg>
);
/* eslint-enable max-len */

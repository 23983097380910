import { transparentize } from 'polished';
import Popover from 'react-bootstrap/Popover';
import styled, { css } from 'styled-components';

export interface StyledPopoverProps {
  /** Whether or not to show the popover arrow */
  $hideArrow?: boolean;
  /** The width of the popover contents */
  width?: string;
  /** The max height of the popover contents */
  height?: string;
  /** Whether or not to have default padding in the popover contents window */
  $noPadding?: boolean;
  /** Whether to allow for scrolling if the content overflow */
  $scrollable?: boolean;
  /** whether to allow pointer events */
  $disablePointerEvents?: boolean;
}

const PopoverContentsStyles = css`
  outline: 0;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.transcendNavy4};
  box-shadow: ${({ theme }) =>
    `0 5px 10px ${transparentize(0.95, theme.colors.black)}`};
`;

export const StyledPopover = styled(Popover)<StyledPopoverProps>`
  ${PopoverContentsStyles};
  font-family: inherit;
  font-weight: inherit;
  ${({ width }) => width && `width: ${width};`}
  /** Don't have max-width by default to prevent overflow */
  max-width: unset;
  ${({ height }) => height && `max-height: ${height};`}
  ${({ $noPadding }) => !$noPadding && 'padding: 8px;'}
    /** Note: overflow-y: auto will ALWAYS hide the arrow */
  ${({ $scrollable }) => $scrollable && 'overflow-y: scroll;'}
  ${({ $disablePointerEvents }) =>
    $disablePointerEvents && 'pointer-events: none;'}
  > .popover-arrow {
    /** Note: overflow-y: auto will ALWAYS hide the arrow */
    ${({ $hideArrow }) => $hideArrow && 'display: none;'}
  }
`;

export const StyledAccessiblePopover = styled.div`
  ${PopoverContentsStyles};
  position: absolute;
  /* Modals start at 1050 and increase by 10 for each additional nested modal */
  z-index: 1100;
  display: block;
  box-sizing: border-box;
  width: 300px;
  max-height: 425px;
  overflow-y: auto;
  transition: opacity 0.1s ease;
`;

import { cases } from '@transcend-io/handlebars-utils';
import {
  getEntries,
  getStringKeys,
  Gql,
  gql,
  Identity,
} from '@transcend-io/type-utils';

import { mkGql } from './mkGqls';
import { paramToGraphQLType } from './mkTypeDefs';
import { isSchema, isSchemaId } from './typeGuards';
import type {
  AnySchema,
  Endpoint,
  EndpointParams,
  GraphQLParameter,
  GraphQLResponse,
  ParamsToType,
  ResponseToPartialType,
  ResponseToType,
  SchemaField,
  SchemaFields,
} from './types';

/**
 * Extract response from schema
 */
type ExtractResponse<T> = T extends AnySchema
  ? GraphQLPartialIndex<T['fields']>
  : never;

/**
 * Utility to type the partial type of the GraphQL request
 */
type GraphQLPartialIndex<TResponse extends GraphQLResponse | SchemaFields> =
  Identity<{
    [paramName in keyof TResponse]?: TResponse[paramName] extends AnySchema
      ? GraphQLPartialIndex<TResponse[paramName]['fields']>
      : TResponse[paramName] extends SchemaField
        ? TResponse[paramName]['type'] extends AnySchema
          ? ExtractResponse<TResponse[paramName]['type']>
          : TResponse[paramName]['type'] extends () => infer R
            ? R extends AnySchema
              ? GraphQLPartialIndex<R['fields']>
              : never
            : null
        : null;
  }>;

/**
 * GraphQL error
 */
export interface GraphQLError {
  /** The error message */
  message: string;
}

/**
 * Shape of raw response body from server
 */
export interface GraphQLResponseBody<
  TName extends string,
  TResult extends GraphQLResponse | AnySchema,
> {
  /** Errors that may have happened  */
  errors?: GraphQLError[];
  /** GraphQL data */
  data:
    | null
    | {
        // TODO: https://github.com/transcend-io/main/issues/6322 - this response type should be partial if responseFields is provided
        [k in TName]: ResponseToType<TResult>;
      };
}

/**
 * Options for making a GraphQL request
 */
export interface AgentGraphQLOptions<
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
> {
  /** The GraphQL variables */
  variables?: ParamsToType<TParams>;
  /** The response fields to get, when undefined fetches the entire request */
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>;
  /** Provide additional headers */
  headers?: { [k in string]: string };
  /** The GraphQL endpoint to use (defaults to /graphql) */
  pathname?: string;
}

/**
 * Construct an object that is in the shape of the gql payload
 * requested by the client
 */
export type GqlObject<TResult extends GraphQLResponse | AnySchema> =
  TResult extends GraphQLResponse
    ? GraphQLPartialIndex<TResult>
    : TResult extends AnySchema
      ? GraphQLPartialIndex<TResult['fields']>
      : never;

/**
 * Constructs the response queries
 *
 * @param responseFields - The fields to include in the response
 * @returns The response queries
 */
function constructQueryResponse<
  TResponse extends GraphQLResponse | SchemaFields,
>(responseFields: GraphQLPartialIndex<TResponse>): string {
  return Object.entries(responseFields)
    .map(
      ([key, val]) =>
        `${key}${val === null ? '' : ` {\n ${constructQueryResponse(val)}\n}`}`,
    )
    .join('\n');
}

/**
 * Overload type for full
 *
 * @param endpoint - The endpoint to hit
 * @param operationName - The name of the GraphQL operation
 * @returns The query string
 */
export function mkQueryString<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
>(
  endpoint: Endpoint<TName, TParams, TResult>,
  operationName?: string,
): Gql<{
  [k in TName]: ResponseToType<TResult>;
}>;

/**
 * Overload type for partial
 *
 * @param endpoint - The endpoint to hit
 * @param operationName - The name of the GraphQL operation
 * @param responseFields - Fields to return in the response (returns all fields if empty)
 * @param includeTypeName - Whether to explicitly ask for the __typename field in the response
 * @returns The query string
 */
export function mkQueryString<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
  TPartial extends GqlObject<TResult>,
>(
  endpoint: Endpoint<TName, TParams, TResult>,
  operationName?: string,
  responseFields?: TPartial | Gql<ResponseToType<TResult>>,
  includeTypeName?: boolean,
): Gql<{
  [k in TName]: ResponseToPartialType<TResult, TPartial>;
}>;

/**
 * Construct the query string to POST to a GraphQL endpoint
 *
 * @param endpoint - The endpoint to hit
 * @param operationName - The name of the GraphQL operation
 * @param responseFields - Fields to return in the response (returns all fields if empty)
 * @param includeTypeName - Whether to explicitly ask for the __typename field in the response
 * @returns The query string
 */
export function mkQueryString<
  TName extends string,
  TParams extends EndpointParams,
  TResult extends GraphQLResponse | AnySchema,
>(
  endpoint: Endpoint<TName, TParams, TResult>,
  // eslint-disable-next-line default-param-last
  operationName = cases.pascalCase(endpoint.name),
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>,
  includeTypeName?: boolean,
): Gql<{ [k in string]: unknown }> {
  // construct the operation input parameters
  // i.e. $input: DemoInput!, dhEncrypted: String
  const operationInputArgs = getEntries(endpoint.params)
    .map(
      ([argName, argValue]) =>
        `$${argName.toString()}: ${paramToGraphQLType(argValue as any)}`,
    )
    .join(', ');
  const wrappedOperationInputArgs = operationInputArgs
    ? `(${operationInputArgs})`
    : '';

  // construct the route input parameters
  // i.e. input: $input, dhEncrypted: $dhEncrypted
  const inputArgs = Object.keys(endpoint.params)
    .map((argName) => `${argName}: $${argName}`)
    .join(', ');
  const wrappedInputArgs = inputArgs ? `(${inputArgs})` : '';

  // construct the response parameters
  // i.e. decryptionContext { ... }\n demo { ... }
  const responseArgs = responseFields
    ? typeof responseFields === 'string'
      ? // Explicit gql string
        responseFields
      : // Gql object
        constructQueryResponse(responseFields)
    : isSchema(endpoint.response)
      ? mkGql(endpoint.response, includeTypeName)
      : getStringKeys(endpoint.response)
          .map((argName) => {
            const value = (endpoint.response as GraphQLResponse)[
              argName
            ] as GraphQLParameter;
            return typeof value === 'string' || isSchemaId(value)
              ? argName
              : `${argName} { ${mkGql(value)} }`;
          })
          .join('\n');

  // Build the gql request fragment
  return gql`${endpoint.method} ${operationName}${wrappedOperationInputArgs} {
    ${endpoint.name}${wrappedInputArgs} { ${
      includeTypeName ? '__typename\n' : ''
    } ${responseArgs} } }`;
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Eye: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9.41452 6.58667C10.1952 7.36733 10.1952 8.63467 9.41452 9.41667C8.63386 10.1973 7.36652 10.1973 6.58452 9.41667C5.80386 8.636 5.80386 7.36867 6.58452 6.58667C7.36652 5.80467 8.63319 5.80467 9.41452 6.58667"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2 8C2 7.56066 2.10133 7.12599 2.29733 6.72533V6.72533C3.30733 4.66066 5.53933 3.33333 8 3.33333C10.4607 3.33333 12.6927 4.66066 13.7027 6.72533V6.72533C13.8987 7.12599 14 7.56066 14 8C14 8.43933 13.8987 8.87399 13.7027 9.27466V9.27466C12.6927 11.3393 10.4607 12.6667 8 12.6667C5.53933 12.6667 3.30733 11.3393 2.29733 9.27466V9.27466C2.10133 8.87399 2 8.43933 2 8Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DocumentContractEditPen: React.FC<SvgProps> = ({
  color,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49897 17.5031H4.16425C3.24339 17.5031 2.49689 16.7566 2.49689 15.8357V4.16419C2.49689 3.24333 3.24339 2.49683 4.16425 2.49683H14.1684C15.0893 2.49683 15.8358 3.24333 15.8358 4.16419V6.66523"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 17.5032L12.1876 17.2331C12.3415 17.2141 12.4847 17.1443 12.5944 17.0347L17.895 11.7342C18.482 11.1496 18.4843 10.1998 17.9 9.61246L17.895 9.60829V9.60829C17.3104 9.0212 16.3606 9.01896 15.7732 9.60329V9.60829L10.5211 14.8605C10.4142 14.9666 10.3451 15.1048 10.3243 15.254L10 17.5032Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66532 14.1685V14.1685C5.97467 14.1685 5.41479 13.6086 5.41479 12.918V12.918C5.41479 12.2274 5.97467 11.6675 6.66532 11.6675V11.6675C7.35596 11.6675 7.91584 12.2274 7.91584 12.918V12.918C7.91584 13.6086 7.35596 14.1685 6.66532 14.1685Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41479 5.83163H12.0842"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41479 8.7496H8.74952"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

/* eslint-disable max-lines */
import { RequestActionObjectResolver } from '@transcend-io/privacy-types';

import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';
import { PluginType } from '@main/sombra-types';

import {
  CatalogOrderField,
  ConnectorType,
  IntegrationStatus,
  PlaintextReasonStaticType,
} from './enums';
import { FormConfig, FormItem } from './formItem';
import { SaaSCategoryBase } from './saaSCategoryBase';

export const CatalogInput = mkInput({
  name: 'CatalogInput',
  comment: 'Input for fetching a catalog',
  fields: {
    integrationName: {
      comment: 'The name of the integration to fetch',
      type: 'string',
    },
    outerType: {
      comment:
        'The catalog name responsible for the cosmetics of the integration (name, description, logo, email fields)',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type CatalogInput = SchemaToType<typeof CatalogInput>;

export const CatalogFiltersInput = mkInput({
  name: 'CatalogFiltersInput',
  comment: 'Input for filtering catalogs',
  fields: {
    text: {
      comment: 'Filter catalogs by their title',
      optional: true,
      type: 'string',
    },
    type: {
      comment: 'Filter by the data silo type',
      optional: true,
      list: true,
      type: 'string',
    },
    connectorType: {
      comment: 'Filter by connector type',
      optional: true,
      type: { ConnectorType },
    },
  },
});

/** Override type */
export type CatalogFiltersInput = SchemaToType<typeof CatalogFiltersInput>;

export const PlaintextRequirement = mkType({
  name: 'PlaintextRequirement',
  comment:
    'The plaintext information that an integration will need to work, exposed to UI before connect',
  fields: {
    path: {
      comment: 'The path, in jsonpath notation',
      type: 'string',
    },
    types: {
      comment: 'The reason that transcend needs this information',
      list: true,
      type: { PlaintextReasonStaticType },
    },
  },
});

/** Override type */
export type PlaintextRequirement = SchemaToType<typeof PlaintextRequirement>;

export const DataPointMetadataInterface = mkInterface({
  name: 'DataPointMetadataInterface',
  comment:
    'Additional metadata collected by transcend about this particular datapoint',
  fields: {
    references: {
      comment: 'URL references to documentation about the datapoint',
      list: true,
      optional: true,
      type: 'string',
    },
    subjectivePaths: {
      comment: `When provided, the datapoint is known to be a subjective lookup and will
      require a person to manually verify the results.
  This is expected to be a list of JSON paths, encoding which fields in the request file should be previewed for redaction.`,
      list: true,
      optional: true,
      type: 'string',
    },
    employeeDescription: {
      comment: `When provided, there is some sort of limitation to the datapoint that employees
      should be aware of.`,
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type DataPointMetadataInterface = SchemaToType<
  typeof DataPointMetadataInterface
>;

export const DataPointMetadata = mkType({
  name: 'DataPointMetadata',
  comment:
    'Additional metadata collected by transcend about this particular datapoint',
  interfaces: [DataPointMetadataInterface],
  fields: {
    ...DataPointMetadataInterface.fields,
  },
});

/** Override type */
export type DataPointMetadata = SchemaToType<typeof DataPointMetadata>;

export const DefaultDataPoint = mkType({
  name: 'DefaultDataPoint',
  comment:
    'Metadata about a datapoint that can be viewed before connecting the datapoint',
  interfaces: [DataPointMetadataInterface],
  fields: {
    ...DataPointMetadataInterface.fields,
    title: {
      comment: 'Default title for the datapoint',
      type: 'string',
    },
    description: {
      comment: 'Default description for the datapoint',
      type: 'string',
    },
    name: {
      comment: 'The unique name of the datapoint',
      type: 'string',
    },
    allowedActions: {
      comment: 'The actions that are allowed on the datapoint',
      list: true,
      type: { RequestActionObjectResolver },
    },
  },
});

/** Override type */
export type DefaultDataPoint = SchemaToType<typeof DefaultDataPoint>;

export const BaseHosts = mkType({
  name: 'BaseHosts',
  comment: 'The Base Hosts of each env',
  fields: {
    PROD: {
      optional: true,
      comment: 'The prod environment base hosts',
      list: true,
      type: 'string',
    },
  },
});

/** Override type */
export type BaseHosts = SchemaToType<typeof BaseHosts>;

export const IntegrationConfig = mkType({
  name: 'IntegrationConfig',
  comment:
    'The JSON representing the integration config for newest version only',
  fields: {
    configuredBaseHosts: {
      comment: 'The base hosts per env',
      type: BaseHosts,
    },
  },
});

/** Override type */
export type IntegrationConfig = SchemaToType<typeof IntegrationConfig>;

export const CatalogPreviewInterface = mkInterface({
  name: 'CatalogPreviewInterface',
  comment: 'A small set of fields required for previewing the catalog',
  fields: {
    id: {
      comment: 'The Catalog id, from the database (not contentfulId)',
      type: 'id',
      modelName: 'catalog',
    },
    logo: {
      comment: 'The url for the full logo image',
      type: 'string',
      optional: true,
    },
    logoSquare: {
      comment: 'The url for the square logo image',
      type: 'string',
    },
  },
});

/** Override type */
export type CatalogPreviewInterface = SchemaToType<
  typeof CatalogPreviewInterface
>;

export const TcfVendorPreview = mkType({
  name: 'TcfVendorPreview',
  comment: 'A small set of fields required for previewing the TCF Vendor',
  fields: {
    id: {
      comment: 'The UUID, from the database (not contentfulId)',
      type: 'id',
      modelName: 'tcfVendor',
    },
    tcfId: {
      comment: 'The TCF Vendor ID from the IAB',
      type: 'int',
    },
    name: {
      type: 'string',
      comment: 'The name of the vendor, e.g. "Salesforce.com, Inc."',
    },
    usesCookies: {
      type: 'boolean',
      comment: 'Whether the vendor uses cookie storage',
    },
    cookieRefresh: {
      type: 'boolean',
      comment:
        'True indicates the vendor may refresh cookie(s). ' +
        'False indicates the vendor does not refresh any time the browser reloads.',
      optional: true,
    },
    usesNonCookieAccess: {
      type: 'boolean',
      comment:
        "Indicates the vendor's use of non-cookie storage and access to information already stored on a user's device.",
      optional: true,
    },
    deviceStorageDisclosureUrl: {
      type: 'string',
      comment: 'URL to Vendor Device Storage & Operational Disclosures',
      optional: true,
    },
  },
});

/** Override type */
export type TcfVendorPreview = SchemaToType<typeof TcfVendorPreview>;

export const CatalogPreview = mkType({
  name: 'CatalogPreview',
  interfaces: [CatalogPreviewInterface],
  comment: 'A small set of fields required for previewing the catalog',
  fields: {
    ...CatalogPreviewInterface.fields,
  },
});

/** Override type */
export type CatalogPreview = SchemaToType<typeof CatalogPreview>;

export const SelectableCatalog = mkType({
  name: 'SelectableCatalog',
  comment: 'A small set of fields required for consent catalog',
  fields: {
    ...CatalogPreviewInterface.fields,
    integrationName: {
      comment: 'The identifying name of the integration',
      type: 'string',
    },
    title: {
      comment: 'The title of the integration',
      type: 'string',
    },
    description: {
      comment: 'The description of the integration',
      type: 'string',
    },
    tcfVendorId: {
      comment:
        'If the integration belongs to a registered TCF Vendor, it will have a TCF Vendor ID',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type SelectableCatalog = SchemaToType<typeof SelectableCatalog>;

export const PluginFormConfiguration = mkType({
  name: 'PluginFormConfiguration',
  comment: 'The configuration for plugin forms.',
  fields: {
    [PluginType.ContentClassification]: {
      comment: 'The content classification form',
      optional: true,
      list: true,
      type: FormItem,
    },
    [PluginType.SchemaDiscovery]: {
      comment: 'The content classification form',
      optional: true,
      list: true,
      type: FormItem,
    },
    [PluginType.DataSiloDiscovery]: {
      comment: 'The content classification form',
      optional: true,
      list: true,
      type: FormItem,
    },
    [PluginType.UnstructuredDataDiscovery]: {
      comment: 'The content classification form',
      optional: true,
      list: true,
      type: FormItem,
    },
    [PluginType.UnstructuredDataSampling]: {
      comment: 'The content classification form',
      optional: true,
      list: true,
      type: FormItem,
    },
  },
});

/** Override type */
export type PluginFormConfiguration = SchemaToType<
  typeof PluginFormConfiguration
>;

export const Catalog = mkType({
  name: 'Catalog',
  comment: 'An integration in the transcend catalog',
  interfaces: [CatalogPreviewInterface],
  fields: {
    ...CatalogPreviewInterface.fields,
    alreadyConnected: {
      comment: 'How many data silos of this type the org already has connected',
      type: 'int',
    },
    allowedPluginTypes: {
      comment: 'The plugins that are supported by this integration',
      list: true,
      type: { PluginType },
    },
    aliasIntegrationName: {
      comment: 'The integration name of the underlying integration type',
      type: 'string',
      optional: true,
    },
    integrationName: {
      comment: 'The identifying name of the integration',
      type: 'string',
    },
    title: {
      comment: 'The title of the integration',
      type: 'string',
    },
    description: {
      comment: 'The default description for the integration',
      type: 'string',
    },
    heroImageUrl: {
      comment: 'Hero Image used to feature the catalog',
      optional: true,
      type: 'string',
    },
    dataPointsCustomizable: {
      comment: 'Indicates that datapoints are customizable',
      type: 'boolean',
    },
    integrationStatus: {
      comment:
        'The status of the integration (whether it is fully integrated or not)',
      type: { IntegrationStatus },
    },
    allowedActions: {
      comment: 'The action types that are allowed for this data silo type',
      list: true,
      type: { RequestActionObjectResolver },
    },
    dataPoints: {
      comment:
        'The default dataPoints that the integration supports, filtering out _default',
      list: true,
      type: DefaultDataPoint,
    },
    allDataPoints: {
      comment: 'The default dataPoints that the integration supports',
      list: true,
      type: DefaultDataPoint,
    },
    plaintextInformation: {
      comment:
        'Communicates information about unencrypted paths and why they are necessary',
      list: true,
      type: PlaintextRequirement,
    },
    introMessage: {
      comment:
        'A representation of the HTML used to explain how to connect the integration',
      type: 'string',
      optional: true,
    },
    businessUserOverview: {
      comment:
        'A representation of the HTML used to explain an overview of how the integration works',
      type: 'string',
      optional: true,
    },
    controllerManualInstructions: {
      comment:
        'A representation of the HTML used to explain how to manually process the integration',
      type: 'string',
      optional: true,
    },
    tcfVendor: {
      comment: 'The TCF Vendor associated with this integration',
      type: TcfVendorPreview,
      optional: true,
    },
    formConfigs: {
      comment: 'The connect silo forms for this catalog member',
      list: true,
      type: FormConfig,
    },
    pluginFormConfigs: {
      comment: 'The plugin configuration forms for this catalog member',
      optional: true,
      type: PluginFormConfiguration,
    },
    recipients: {
      comment: 'The pre-labeled recipient categories for this catalog.',
      list: true,
      type: SaaSCategoryBase,
    },
    isSuggested: {
      comment:
        'Whether or not the site-scanner recommends this integration for the given organization',
      type: 'boolean',
    },
    hasUspApiSupport: {
      comment:
        // eslint-disable-next-line max-len
        'Boolean indicating whether the tool supports the USP API. This is an API that relates to the do not sell/share requirement for US privacy laws.',
      type: 'boolean',
      optional: true,
    },
    minimumSombraVersion: {
      comment: 'The minimum sombra version compatible with the integration',
      type: 'string',
    },
    isComplete: {
      comment:
        'Indicates whether the integration is fully integrated, not just with prompt-a-vendor, but with an API integration',
      type: 'boolean',
    },
    isPromptAVendorCompatible: {
      comment:
        'Indicates whether a catalog entry is compatible with prompt-a-vendor',
      type: 'boolean',
    },
    promptAVendorEmailAddress: {
      comment: 'The email address to use in prompt-a-vendor integrations',
      optional: true,
      type: 'string',
    },
    promptEmailTemplateId: {
      comment: 'The prompt a person template id',
      optional: true,
      modelName: 'template',
      type: 'id',
    },
    disqualificationReasons: {
      comment: 'The reasons why the integration was disqualified',
      optional: true,
      list: true,
      type: 'string',
    },
    seeOtherIntegrations: {
      comment: 'Other alternative integrations',
      optional: true,
      list: true,
      type: 'string',
    },
    integrationConfig: {
      comment: 'Other alternative integrations',
      optional: true,
      type: IntegrationConfig,
    },
    isFeatured: {
      comment: 'Boolean indicating whether the integration is featured',
      optional: true,
      type: 'boolean',
    },
    consentDoNotSellIntegration: {
      comment:
        'Select "yes" if Transcend Consent has built-in functionality for do not sell (FB pixel, Google Ads, etc.)',
      optional: true,
      type: 'boolean',
    },
    heroBackgroundColor: {
      comment: 'String with the background color for the featured hero card',
      optional: true,
      type: 'string',
    },
    hasPrelabeledDataFlows: {
      comment:
        'When true, Transcend has pre-labeled data flow tags for this integration',
      type: 'boolean',
    },
    hasPrelabeledCookies: {
      comment:
        'When true, Transcend has pre-labeled cookie tags for this integration',
      type: 'boolean',
    },
    hasPrelabeledDatapoints: {
      comment:
        'When true, Transcend has pre-labeled datapoint metadata for this integration',
      type: 'boolean',
    },
    hasAvcFunctionality: {
      comment:
        'When true, the data silo has the ability to resolve requests via email',
      type: 'boolean',
    },
    hasApiFunctionality: {
      comment:
        'When true, the data silo has the ability to resolve requests via API',
      type: 'boolean',
    },
    hasSiloDiscoveryFunctionality: {
      comment:
        'When true, the data silo has the ability to discover new data silos via API',
      type: 'boolean',
    },
    hasContentClassificationFunctionality: {
      comment: 'When true, the data silo has the ability to classify content',
      type: 'boolean',
    },
    hasPlugins: {
      comment: 'When true, the data silo has plugins that can be enabled',
      type: 'boolean',
    },
    avcEmailVerified: {
      comment:
        'Whether the email address listed has been verified by the Vendor for use fulfilling DSRs',
      optional: true,
      type: 'boolean',
    },
    website: {
      comment: 'The website URL for the integration',
      optional: true,
      type: 'string',
    },
    gradleNamespace: {
      comment: 'Unique namespace for identifying this catalog entry in gradle',
      optional: true,
      type: 'string',
    },
    nugetNamespace: {
      comment: 'Unique namespace for identifying this catalog entry in nuget',
      optional: true,
      type: 'string',
    },
    npmNamespace: {
      comment: 'Unique namespace for identifying this catalog entry in npm',
      optional: true,
      type: 'string',
    },
    githubOrganizationName: {
      comment: 'Slug of the github organization that owns this catalog entry',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type Catalog = SchemaToType<typeof Catalog>;

export const CatalogSearchInput = mkInput({
  name: 'CatalogSearchInput',
  comment: 'Inputs for search catalogs by name',
  fields: {
    text: {
      comment: 'Filter by title or integration name of catalog',
      type: 'string',
    },
    limit: {
      comment: 'How many results to return (default is 10)',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type CatalogSearchInput = SchemaToType<typeof CatalogSearchInput>;

export const RecommendedCatalogsInput = mkInput({
  name: 'RecommendedCatalogsInput',
  comment: 'Input for fetching recommendedCatalogs',
  fields: {
    limit: {
      comment: 'The maximum number of results to return',
      type: 'int',
      optional: true,
    },
  },
});

/** Override type */
export type RecommendedCatalogsInput = SchemaToType<
  typeof RecommendedCatalogsInput
>;

/**
 * Order for a catalogs query
 */
export const CatalogOrder = mkOrder(Catalog.name, CatalogOrderField);

/** Override type */
export type CatalogOrder = SchemaToType<typeof CatalogOrder>;

/* eslint-enable max-lines */

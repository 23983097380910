/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Paperclip: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8791 8.37625L8.39309 13.8623C7.56709 14.6883 7.56709 16.0282 8.39309 16.8542V16.8542C9.21909 17.6802 10.5591 17.6802 11.3851 16.8542L18.6171 9.62225C20.1321 8.10725 20.1321 5.65125 18.6171 4.13625V4.13625C17.1021 2.62125 14.6461 2.62125 13.1311 4.13625L5.89909 11.3682C3.69509 13.5722 3.69509 17.1442 5.89909 19.3482V19.3482C8.10309 21.5522 11.6751 21.5522 13.8791 19.3482L18.2681 14.9593"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

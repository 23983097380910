/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ShieldMagnifyingGlass: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4993 15.0012L13.9746 13.4766"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 11.2083C8.5 12.506 9.28168 13.6759 10.4806 14.1725C11.6794 14.6691 13.0594 14.3946 13.977 13.477C14.8946 12.5594 15.1691 11.1794 14.6725 9.98056C14.1759 8.78168 13.006 8 11.7083 8C9.93647 8.00013 8.50013 9.43647 8.5 11.2083"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9409 20.8951V20.8951C6.32361 19.9717 2.99999 15.9175 3 11.2088V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V11.2088C21 15.9174 17.6765 19.9715 13.0593 20.8951H13.0591C12.36 21.0349 11.64 21.0349 10.9409 20.8951V20.8951Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

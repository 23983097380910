import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Junk: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0132 8.48242L10.5959 9.49176C10.8286 9.89442 10.5379 10.3984 10.0726 10.3984H8.90723"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.98782 8.48242L5.40515 9.49176C5.17249 9.89442 5.46315 10.3984 5.92849 10.3984H7.09382"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.89453 6.9129L7.4772 5.90356C7.70986 5.5009 8.2912 5.5009 8.52386 5.90356L9.10653 6.9129"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.4736 14H4.52691C3.84757 14 3.12024 13.4893 3.04557 12.814L2.00824 3.48067C1.92091 2.69067 2.53891 2 3.33357 2H12.6669C13.4616 2 14.0796 2.69067 13.9922 3.48067L12.9549 12.814C12.8796 13.4893 12.1529 14 11.4736 14V14Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

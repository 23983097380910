import type { OptionTypeBase, StylesConfig } from 'react-select';
import { CSSObject, DefaultTheme, useTheme } from 'styled-components';

import { MENU_Z_INDEX } from './constants';
import { ReactSelectOverflowOptions } from './types';

/**
 * The variants for the multi-select component
 */
export type MultiSelectVariant = 'primary' | 'secondary';

/**
 * The variants for the single-select component
 */
export type SingleSelectVariant = MultiSelectVariant | 'light';

/**
 * The color map to use to render the option labels
 */
export type ColorMap<T extends OptionTypeBase> = (option: T) => string;

/**
 * Styling config for select component.
 * Light variant is only available in single-select
 * other variants are available for both single-and-multi select
 */
export interface SelectStyleConfig<
  IsMulti extends boolean,
  T extends OptionTypeBase,
> {
  /** Whether to show the outline when not hovered */
  showOutline?: boolean;
  /** Whether the entire component is being hovered */
  hovered: boolean;
  /** Whether the entire component is focused */
  focused: boolean;
  /** Whether the labels should be filled or outlined, or "light" */
  variant?: IsMulti extends true ? MultiSelectVariant : SingleSelectVariant;
  /** The map of label values to colors */
  colorMap?: (theme: DefaultTheme) => ColorMap<T>;
  /** The default option color (if NOT using the color map) */
  defaultOptionColor?: string;
  /** Callback to resolve variant based on option data */
  getVariant?: (
    option: T,
  ) => IsMulti extends true ? MultiSelectVariant : SingleSelectVariant;
  /** Overflow style of the react select component */
  overflow?: ReactSelectOverflowOptions<IsMulti>;
  /** Override the default z index of the dropdown menu */
  menuZIndex?: number;
}

/**
 * Function to get the colors for a select component
 */
export type GetColorFunc<T extends OptionTypeBase = OptionTypeBase> = (
  dataValue: T,
) => Pick<CSSObject, 'borderColor' | 'backgroundColor' | 'color'>;

/**
 * Helper to get colors for the select component based on its color map and variant
 *
 * @param options - Options for the select component colors
 * @returns CSSObject containing the colors for a given select components
 */
export function useGetColors<IsMulti extends boolean, T extends OptionTypeBase>(
  options: SelectStyleConfig<IsMulti, T>,
): GetColorFunc<T> {
  const theme = useTheme();
  let { variant = 'primary' } = options;
  const { colorMap, defaultOptionColor, getVariant = () => variant } = options;
  const colorMapTheme = colorMap ? colorMap(theme) : undefined;
  return (dataValue) => {
    variant = getVariant(dataValue);

    // when the variant is light we don't care about the colorMap
    return variant === 'light'
      ? {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          color: defaultOptionColor || theme.colors.transcendNavy,
        }
      : // else we check to see if the color map is defined + apply primary/secondary styling
        colorMapTheme && colorMapTheme(dataValue)
        ? {
            borderColor: colorMapTheme(dataValue),
            backgroundColor:
              variant === 'primary'
                ? colorMapTheme(dataValue)
                : theme.colors.white,
            color:
              variant === 'secondary'
                ? colorMapTheme(dataValue)
                : theme.colors.white,
          }
        : {
            borderColor: defaultOptionColor || theme.colors.white,
            backgroundColor:
              variant === 'primary'
                ? defaultOptionColor || theme.colors.gray1
                : theme.colors.white,
            color:
              variant === 'primary'
                ? defaultOptionColor
                  ? theme.colors.white
                  : theme.colors.transcendNavy
                : defaultOptionColor || theme.colors.transcendNavy,
          };
  };
}

/**
 * Helper for styling the react-select component
 *
 * @param options - styles configuration for the select component
 * @returns a styles config object
 */
export function useStyles<IsMulti extends boolean, T extends OptionTypeBase>({
  overflow,
  menuZIndex,
  ...options
}: SelectStyleConfig<IsMulti, T>): {
  /** Function to fetch colors for a given option value */
  getColors: GetColorFunc<T>;
  /** The styles config for react-select */
  styles: StylesConfig<T, true>;
} {
  const getColors = useGetColors<IsMulti, T>(options);

  return {
    getColors,
    styles: {
      singleValue: (provided) => ({
        ...provided,
        // override react-select defaults so pill doesn't overflow and get cut off
        position: 'relative',
        top: 'auto',
        transform: 'none',
      }),
      multiValue: (provided, { data }) => ({
        ...provided,
        padding: '0px',
        borderRadius: '6px',
        ...getColors(data),
        transition: 'all 0.3s ease-in-out',
        ':hover':
          options.variant === 'light'
            ? {
                backgroundColor: 'white',
              }
            : {},
      }),
      multiValueRemove: (provided, { data }) => {
        const { backgroundColor, ...colors } = getColors(data);
        // For light, the backgroundColor is 'transparent' but the "X" needs a background
        // because it overlays the text, so we override that here
        const overrideBgColor =
          options.variant === 'light' ? 'white' : backgroundColor;
        return {
          ...provided,
          padding: '0px 6px',
          borderRadius: '6px',
          height: '100%',
          opacity: options.focused ? '1' : '0',
          transition: 'opacity 0.3s ease-in-out',
          ...colors,
          // override the background color for multi-value select if the variant is light
          backgroundColor: overrideBgColor,
          border: '0px',
          ':hover': {
            ...provided[':hover'],
            opacity: '1',
            ...colors,
            backgroundColor: overrideBgColor,
            border: '0px',
          },
        };
      },
      dropdownIndicator: (provided) => ({
        ...provided,
        opacity:
          options.hovered || options.focused || options.showOutline
            ? provided.opacity
            : '0',
      }),
      valueContainer: (provided) => ({
        ...provided,
        ...(overflow !== 'wrap'
          ? { flexWrap: undefined, overflowX: 'auto' }
          : {}),
      }),
      menuPortal: (provided) => ({
        ...provided,
        // Set portal styles here, not in wrappers, because portal is not necessarily a DOM
        // child of the select component, if it is appended to a different component
        zIndex: menuZIndex ?? MENU_Z_INDEX,
      }),
    },
  };
}

/**
 * All of the sizes
 */
import React from 'react';
import { CSSObject } from 'styled-components';

/**
 * Size names
 */
export enum Size {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  l = 'l',
  xl = 'xl',
}

/**
 * This is used with both styled components and react.css
 * getting weird type incompatiblities
 */
export interface StyleObject
  extends Omit<
      CSSObject,
      | 'MozAnimation'
      | 'appearance'
      | 'columnFill'
      | 'MozColumnFill'
      | 'WebkitColumnFill'
    >,
    Omit<
      React.CSSProperties,
      | 'MozAnimation'
      | 'appearance'
      | 'columnFill'
      | 'MozColumnFill'
      | 'WebkitColumnFill'
    > {
  /** does not override */
  appearance?: any;
  /** does not overlap */
  MozAnimation?: any;
  /** does not overlap */
  columnFill?: any;
  /** does not overlap */
  MozColumnFill?: any;
  /** does not overlap */
  WebkitColumnFill?: any;
}

/**
 * Size enum and default
 */
type SizeAndDefault = Size | 'default';

const FlexRowBase: StyleObject = { display: 'flex', flexDirection: 'row' };
const FlexColumnBase: StyleObject = {
  display: 'flex',
  flexDirection: 'column',
};

/**
 * style utils
 */
export class StyleUtils {
  static Spacing: Record<SizeAndDefault, string> = {
    [Size.xs]: '.3em',
    [Size.sm]: '.5em',
    [Size.md]: '1em',
    [Size.l]: '1.5em',
    [Size.xl]: '2em',
    default: '1em',
  };

  static BorderRadius = {
    default: { borderRadius: '.5em' },
    fullyRounded: { borderRadius: '50em' },
  } as const;

  static CommonMargins = {
    formItem: { marginBottom: StyleUtils.Spacing.l } as StyleObject,
    vertical: (size: Size): StyleObject => ({
      marginBottom: StyleUtils.Spacing[size],
      marginTop: StyleUtils.Spacing[size],
    }),
    horizontal: (size: Size): StyleObject => ({
      marginLeft: StyleUtils.Spacing[size],
      marginRight: StyleUtils.Spacing[size],
    }),
  } as const;

  static Flex = {
    Row: {
      base: FlexRowBase,
      AlignRight: {
        ...FlexRowBase,
        justifyContent: 'flex-end',
      } as StyleObject,
      AlignLeft: {
        ...FlexRowBase,
        justifyContent: 'flex-start',
      } as StyleObject,
      CenterVertical: {
        ...FlexRowBase,
        alignItems: 'center',
      } as StyleObject,
      CenterHorizontal: {
        ...FlexRowBase,
        justifyContent: 'center',
      } as StyleObject,
      CenterBoth: {
        ...FlexRowBase,
        alignItems: 'center',
        justifyContent: 'center',
      } as StyleObject,
    },
    Column: {
      base: FlexColumnBase,
      AlignRight: {
        ...FlexColumnBase,
        alignItems: 'flex-end',
      } as StyleObject,
      AlignLeft: {
        ...FlexColumnBase,
        alignItems: 'flex-start',
      } as StyleObject,
      CenterVertical: {
        ...FlexColumnBase,
        justifyContent: 'center',
      } as StyleObject,
      CenterHorizontal: {
        ...FlexColumnBase,
        alignItems: 'center',
      } as StyleObject,
      CenterBoth: {
        ...FlexColumnBase,
        alignItems: 'center',
        justifyContent: 'center',
      } as StyleObject,
    },
    /** Items have equal space between them and no space on either end */
    SpaceBetween: { justifyContent: 'space-between' } as StyleObject,
    /** Items have equal space between them and on either end */
    SpaceEvenly: { justifyContent: 'space-evenly' } as StyleObject,
  } as const;

  static Grid = {
    EvenColumns: (numCols: number) =>
      ({
        display: 'grid',
        gridTemplateColumns: `repeat(${numCols},minmax(0,1fr))`,
      }) as StyleObject,
  } as const;
}

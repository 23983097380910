import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Refresh: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M2.46328 4.74805C2.64375 4.23711 2.93672 3.75664 3.34922 3.34648C4.81406 1.88164 7.18828 1.88164 8.65313 3.34648L9.05391 3.74961H7.875C7.46016 3.74961 7.125 4.08477 7.125 4.49961C7.125 4.91445 7.46016 5.24961 7.875 5.24961H10.8633H10.8727C11.2875 5.24961 11.6227 4.91445 11.6227 4.49961V1.49961C11.6227 1.08477 11.2875 0.749609 10.8727 0.749609C10.4578 0.749609 10.1227 1.08477 10.1227 1.49961V2.69961L9.7125 2.28711C7.66172 0.236328 4.33828 0.236328 2.2875 2.28711C1.71562 2.85898 1.30312 3.53164 1.05 4.25117C0.911719 4.64258 1.11797 5.06914 1.50703 5.20742C1.89609 5.3457 2.325 5.13945 2.46328 4.75039V4.74805ZM0.914062 6.78008C0.796875 6.81523 0.684375 6.87852 0.592969 6.97227C0.499219 7.06602 0.435938 7.17852 0.403125 7.30039C0.396094 7.32852 0.389062 7.35898 0.384375 7.38945C0.377344 7.4293 0.375 7.46914 0.375 7.50898V10.4996C0.375 10.9145 0.710156 11.2496 1.125 11.2496C1.53984 11.2496 1.875 10.9145 1.875 10.4996V9.30195L2.2875 9.71211C4.33828 11.7605 7.66172 11.7605 9.71016 9.71211C10.282 9.14023 10.6969 8.46758 10.95 7.75039C11.0883 7.35898 10.882 6.93242 10.493 6.79414C10.1039 6.65586 9.675 6.86211 9.53672 7.25117C9.35625 7.76211 9.06328 8.24258 8.65078 8.65273C7.18594 10.1176 4.81172 10.1176 3.34688 8.65273L3.34453 8.65039L2.94375 8.24961H4.125C4.53984 8.24961 4.875 7.91445 4.875 7.49961C4.875 7.08477 4.53984 6.74961 4.125 6.74961H1.13438C1.09688 6.74961 1.05937 6.75195 1.02187 6.75664C0.984375 6.76133 0.949219 6.76836 0.914062 6.78008Z"
      fill={color}
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DsrTypeContactOptOut: React.FC<SvgProps> = ({
  color,
  color2,
  size,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M18.3326 30.0042H9.99582C7.23325 30.0042 4.99374 27.7647 4.99374 25.0021V11.6632C4.99374 8.90064 7.23325 6.66113 9.99582 6.66113H30.0042C32.7667 6.66113 35.0062 8.90064 35.0062 11.6632V18.3327"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.9415 11.0215L22.5569 18.2558C20.9982 19.1664 19.0697 19.1664 17.511 18.2558L5.06224 10.9841"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M30.0028 21.6667C33.6862 21.6667 36.6722 24.6528 36.6722 28.3362C36.6722 32.0196 33.6862 35.0056 30.0028 35.0056C26.3193 35.0056 23.3333 32.0196 23.3333 28.3362C23.3333 24.6528 26.3193 21.6667 30.0028 21.6667"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 32.7585L34.425 23.3335"
      stroke={color2 || color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import styled from 'styled-components';

import { StyleUtils } from './StyleUtils';

// flex
export const FlexColumn = styled.div(StyleUtils.Flex.Column.base);

export const FlexRow = styled.div(StyleUtils.Flex.Row.base);

export const FlexColumnCenterHorizontal = styled.div(
  StyleUtils.Flex.Column.CenterHorizontal,
);

export const FlexColumnCenterVertical = styled.div(
  StyleUtils.Flex.Column.CenterVertical,
);

export const FlexColumnCenterBoth = styled.div(
  StyleUtils.Flex.Column.CenterBoth,
);

export const FlexRowCenterVertical = styled.div(
  StyleUtils.Flex.Row.CenterVertical,
);

export const FlexRowCenterHorizontal = styled.div(
  StyleUtils.Flex.Row.CenterHorizontal,
);

export const FlexRowCenterBoth = styled.div(StyleUtils.Flex.Row.CenterBoth);

// grid
export const GridTwoEvenColumns = styled.div(StyleUtils.Grid.EvenColumns(2));

// legacy

/**
 * A simply div that centers its inner content.
 * This is normally sufficient if the desire is to just center
 * a component in the middle of its view.
 *
 * @deprecated use appropriate Flex components instead
 */
export const CenteredDiv = styled.div`
  text-align: center;
`;

/**
 * A div that is pulled right
 *
 * @deprecated use appropriate Flex components instead
 */
export const RightDiv = styled.div`
  float: right;
  padding-bottom: 5px;
`;

/**
 * A div that is pulled left
 *
 * @deprecated use appropriate Flex components instead
 */
export const LeftDiv = styled.div`
  float: left;
  padding-bottom: 5px;
`;

/**
 * Pull contents to the right
 *
 * @deprecated use appropriate Flex components instead
 */
export const RightSpan = styled.span`
  float: right;
`;

/**
 * Props for ScrollWrapper
 */
interface ScrollWrapperProps extends React.HtmlHTMLAttributes<Element> {
  /** The maximum scroll height, over is overflow */
  maxHeight?: string;
}

/**
 * Wrapper for vertical scroll
 */
export const ScrollWrapper = styled.div<ScrollWrapperProps>`
  ${({ maxHeight }) =>
    maxHeight
      ? `
    max-height: ${maxHeight};
    overflow-y: scroll;
  `
      : ''};
  overflow-x: hidden;
`;

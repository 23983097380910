import { defineMessages } from '@transcend-io/internationalization';

export const toastMessages = defineMessages('core-ui.message.toast', {
  fallback: {
    defaultMessage: 'Something happened',
  },
  fallbackSuccess: {
    defaultMessage: 'Success!',
  },
  fallbackLoading: {
    defaultMessage: 'Success!',
  },
  fallbackUnknownError: {
    defaultMessage: 'Unknown error',
  },
});

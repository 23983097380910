import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const TrashDeleteRecycle: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.98677 7.51733L5.40411 6.508C5.17144 6.10533 5.46211 5.60133 5.92744 5.60133L7.09277 5.60133"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0122 7.51733L10.5948 6.508C10.8275 6.10533 10.5368 5.60133 10.0715 5.60133L8.90618 5.60133"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.10547 9.08735L8.5228 10.0967C8.29014 10.4993 7.7088 10.4993 7.47614 10.0967L6.89347 9.08735"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4.52642 2L11.4731 2C12.1524 2 12.8798 2.51067 12.9544 3.186L13.9918 12.5193C14.0791 13.3093 13.4611 14 12.6664 14L3.33309 14C2.53843 14 1.92043 13.3093 2.00776 12.5193L3.04509 3.186C3.12043 2.51067 3.84709 2 4.52642 2V2Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

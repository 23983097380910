import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const FolderPlus: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00059 10L8.00006 8"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9.00006L7 9.00059"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M14 12.0001C14 12.7365 13.403 13.3334 12.6667 13.3334H3.33333C2.59695 13.3334 2 12.7365 2 12.0001V4.00008C2 3.2637 2.59695 2.66675 3.33333 2.66675H6.31371C6.53438 2.66675 6.74076 2.77595 6.86489 2.9584L7.82879 4.37507C7.95292 4.55754 8.15931 4.66675 8.38 4.66675H12.6667C13.403 4.66675 14 5.2637 14 6.00008V12.0001Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

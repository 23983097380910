/**
 * Fields to order an attribute key by when paginating
 */
export enum AttributeKeyOrderField {
  /** The name of the attribute key */
  Name = 'name',
  /** The created at date of the attribute key */
  CreatedAt = 'createdAt',
  /** The time the attribute key was updated */
  UpdatedAt = 'updatedAt',
  /** Whether this is a custom field */
  IsCustom = 'isCustom',
}

/* eslint-disable max-lines */
import {
  RegionDetectionMethod,
  RequestAction,
} from '@transcend-io/privacy-types';

import { Resource, UserPreview } from '@main/access-control-types';
import { DefinedMessage, Message } from '@main/internationalization';
import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';

import { DataSiloPreview } from './dataSiloPreview';
import { ActionOrderField, PostCompileStatus } from './enums';
import { FormItem } from './formItem';
import { Step } from './step';
import { Template } from './template';

export const ActionDelay = mkType({
  name: 'ActionDelay',
  comment: 'Configuration for delaying a request action',
  fields: {
    time: {
      comment:
        'The default delay time added to the request to give the data subject a chance to cancel',
      type: 'int',
    },
    // TODO: https://github.com/transcend-io/main/issues/2758 - split DSRs for access removing this
    previewAction: {
      comment:
        'The action to use to preview the request before true compilation',
      type: { RequestAction },
    },
  },
});

/** Override type */
export type ActionDelay = SchemaToType<typeof ActionDelay>;

export const ActionFiltersInput = mkInput({
  name: 'ActionFiltersInput',
  comment: 'Input for filtering an action',
  fields: {
    text: {
      comment: 'Filter text on action title/description/secondary description',
      optional: true,
      type: 'string',
    },
    requiresReview: {
      comment: 'Filter for required review',
      optional: true,
      type: 'boolean',
    },
    type: {
      comment: 'Filter by the action type',
      optional: true,
      type: { RequestAction },
      list: true,
    },
    dataSubjectIds: {
      comment: 'Filter by the data subject IDs that have this action enabled',
      optional: true,
      list: true,
      type: 'id',
      modelName: 'subject',
    },
  },
});

/** Override type */
export type ActionFiltersInput = SchemaToType<typeof ActionFiltersInput>;

/**
 * This really should be a basic type rather than an input but we use it below in
 * `UpdateActionInput` so we have to type it as an input
 */
export const ActionExpiryTimeInput = mkInput({
  name: 'ActionExpiryTimeInput',
  comment: 'The expiry times for an action',
  fields: {
    region: {
      comment:
        "The region code (or 'default') for the expiry time for the action",
      type: 'string',
    },
    value: {
      comment: 'The expiry time for the action',
      type: 'int',
    },
  },
});

/** Override type */
export type ActionExpiryTimeInput = SchemaToType<typeof ActionExpiryTimeInput>;

export const ActionConfirmationInput = mkInput({
  name: 'ActionConfirmationInput',
  comment: 'Input for a checkbox confirmation form item',
  fields: {
    id: {
      comment: 'The ID of an existing form item, if updating',
      type: 'id',
      modelName: 'formItem',
      optional: true,
    },
    message: {
      comment: 'The default message to display',
      type: 'string',
    },
  },
});

/** Override type */
export type ActionConfirmationInput = SchemaToType<
  typeof ActionConfirmationInput
>;

export const UpdateActionInput = mkInput({
  name: 'UpdateActionInput',
  comment: 'Update an action configuration',
  fields: {
    id: {
      comment: 'The unique id of the action to update',
      modelName: 'action',
      type: 'id',
    },
    title: {
      comment: 'The display title of the action',
      optional: true,
      type: 'string',
    },
    description: {
      comment: 'The display description of the action',
      optional: true,
      type: 'string',
    },
    secondaryDescription: {
      comment: 'The description to render on the confirmation modal',
      optional: true,
      type: 'string',
    },
    sendReceiptEmail: {
      comment: 'Whether an email receipt should be sent',
      optional: true,
      type: 'boolean',
    },
    regionDetectionMethod: {
      comment:
        'The different ways that regions can be detected at time of request submit',
      type: { RegionDetectionMethod },
      optional: true,
    },
    regionList: {
      comment:
        'The list of regions that should appear in the form on the privacy center for the data subjects region',
      list: true,
      optional: true,
      type: 'string',
    },
    skipSecondaryIfNoFiles: {
      comment:
        'Configuration option to skip running erasures when no data is found in initial access request.',
      optional: true,
      type: 'boolean',
    },
    skipDownloadableStep: {
      comment:
        'Configuration option to skip the ability to download files in an ACCESS request.',
      optional: true,
      type: 'boolean',
    },
    allowPostCompileChoice: {
      comment: 'Allow for choice in postCompile option.',
      optional: true,
      type: 'boolean',
    },
    postCompileStatus: {
      comment:
        'The status to transition the request into after a successful compilation',
      optional: true,
      type: { PostCompileStatus },
    },
    // TODO: https://github.com/transcend-io/main/issues/2989 - change these to direct template inputs
    successTemplateId: {
      comment: 'The id of the success template',
      optional: true,
      modelName: 'template',
      type: 'id',
    },
    receiptTemplateId: {
      comment: 'The id of the receipt template',
      optional: true,
      modelName: 'template',
      type: 'id',
    },
    notFoundTemplateId: {
      comment: 'The id of the not found template',
      optional: true,
      modelName: 'template',
      type: 'id',
    },
    secondaryTemplateId: {
      comment: 'The id of the secondary success template',
      optional: true,
      modelName: 'template',
      type: 'id',
    },
    waitingPeriod: {
      comment: 'The number of days to wait before starting to compile requests',
      optional: true,
      type: 'int',
    },
    delay: {
      comment:
        'The delay time in ms between the request being verified and compilation processing beginning',
      optional: true,
      type: 'int',
    },
    requiresReview: {
      comment: 'Whether the action should undergo a review step',
      optional: true,
      type: 'boolean',
    },
    requiresSecondaryReview: {
      comment: 'Whether the action should undergo a review step post deletion',
      optional: true,
      type: 'boolean',
    },
    icon: {
      comment: 'The custom icon image url',
      optional: true,
      type: 'string',
    },
    expiryTimeRegions: {
      comment: 'The expiry times for an action',
      optional: true,
      type: ActionExpiryTimeInput,
      list: true,
    },
    downloadAvailabilityDuration: {
      comment: 'The number of days the request files are downloadable.',
      optional: true,
      type: 'int',
    },
    confirmations: {
      comment: 'The confirmation messages for an action',
      type: ActionConfirmationInput,
      list: true,
      optional: true,
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateActionInput = SchemaToType<typeof UpdateActionInput>;

export const ActionInterface = mkInterface({
  name: 'ActionInterface',
  comment: 'A type of action that a data subject can make',
  fields: {
    id: {
      comment: 'The unique id of the action',
      modelName: 'action',
      type: 'id',
    },
    type: {
      comment: 'The type of action',
      type: { RequestAction },
    },
    title: {
      comment: 'The display title of the request action',
      type: DefinedMessage,
    },
    description: {
      comment: 'The display description of the request action',
      type: DefinedMessage,
    },
    secondaryDescription: {
      comment:
        'The display description of the request action on the confirmation page',
      type: DefinedMessage,
    },
    postCompileStatus: {
      comment:
        'The status to transition the request into after a successful compilation',
      type: { PostCompileStatus },
    },
    regionDetectionMethod: {
      comment:
        'The different ways that regions can be detected at time of request submit',
      type: { RegionDetectionMethod },
    },
    regionList: {
      comment:
        'The list of regions that should appear in the form on the privacy center for the data subjects region',
      list: true,
      type: 'string',
    },
    allowPostCompileChoice: {
      comment: 'Allow for choice in postCompile option.',
      optional: true,
      type: 'boolean',
    },
    formItems: {
      comment:
        'The form items that should be rendered when the data subject submits the request',
      list: true,
      type: FormItem,
    },
    icon: {
      comment: 'A custom icon for the action',
      optional: true,
      type: 'string',
    },
    delay: {
      comment: 'The delay configuration',
      optional: true,
      type: ActionDelay,
    },
  },
});

/** Override type */
export type ActionInterface = SchemaToType<typeof ActionInterface>;

export const Action = mkType({
  name: 'Action',
  comment: 'An action that the data subject can make on the privacy center',
  interfaces: [ActionInterface],
  fields: {
    ...ActionInterface.fields,
  },
});

/** Override type */
export type Action = SchemaToType<typeof Action>;

export const ActionPreview = mkType({
  name: 'ActionPreview',
  comment: 'Preview an action',
  fields: {
    id: Action.fields.id,
    title: {
      comment:
        'The display title of the request action with associated translations',
      type: Message,
    },
    type: Action.fields.type,
  },
});

/** Override type */
export type ActionPreview = SchemaToType<typeof ActionPreview>;

export const ActionExpiryTime = mkType({
  name: 'ActionExpiryTime',
  comment: 'The expiry times for an action',
  fields: ActionExpiryTimeInput.fields,
});

/** Override type */
export type ActionExpiryTime = SchemaToType<typeof ActionExpiryTime>;

export const ActionInternal = mkType({
  name: 'ActionInternal',
  comment: 'An action that the data subject can make on the privacy center',
  interfaces: [ActionInterface],
  fields: {
    ...ActionInterface.fields,
    skipSecondaryIfNoFiles: {
      comment:
        'Configuration option to skip running erasures when no data is found in initial access request.',
      type: 'boolean',
    },
    dataPointCount: {
      comment: 'The number of datapoints configured for this action',
      type: 'int',
    },
    integrationCount: {
      comment: 'The number of integrations configured for this action',
      type: 'int',
    },
    integrationPreviews: {
      comment:
        'Preview a set of up to 12 integrations configured to this workflow',
      list: true,
      type: Resource,
    },
    owners: {
      comment: 'Transcend users involved in the process',
      list: true,
      type: UserPreview,
    },
    skipDownloadableStep: {
      comment:
        'Configuration option to skip the ability to download files in an ACCESS request.',
      type: 'boolean',
    },
    requiresReview: {
      comment: 'Whether the action should undergo a review step',
      type: 'boolean',
    },
    requiresSecondaryReview: {
      comment: 'Whether the action should undergo a review step post deletion',
      type: 'boolean',
    },
    canResolve: {
      comment:
        'Indicates if the action is connected to any datapoints, and can thus be resolved if a request is made',
      type: 'boolean',
    },
    sendReceiptEmail: {
      comment: 'When a data subject submits a request, send them a receipt',
      type: 'boolean',
    },
    adminTitle: {
      comment: 'The display to display to an administrator',
      type: 'string',
    },
    dataSilos: {
      comment: 'The display to display to an administrator',
      list: true,
      type: DataSiloPreview,
    },
    requestCount: {
      comment: 'Total number of requests received',
      type: 'int',
    },
    successfulRequestCount: {
      comment: 'Total number of requests resolved successfully',
      type: 'int',
    },
    openRequestCount: {
      comment: 'Total number of open requests',
      type: 'int',
    },
    successTemplate: {
      comment: 'The report to use for a successful resolve of the action',
      type: Template,
      optional: true,
    },
    receiptTemplate: {
      comment: 'The report to use upon receipt of a new request',
      type: Template,
    },
    notFoundTemplate: {
      comment:
        'The report to use when the data subject was not found in the systems',
      optional: true,
      type: Template,
    },
    secondaryTemplate: {
      comment: 'The template used when secondary action is completed',
      optional: true,
      type: Template,
    },
    steps: {
      comment: 'The default steps for the action',
      list: true,
      type: Step,
    },
    expiryTimeRegions: {
      comment: 'The expiry times for the action',
      type: ActionExpiryTime,
      list: true,
    },
    downloadAvailabilityDuration: {
      comment: 'The number of days the request files are downloadable',
      type: 'int',
    },
    waitingPeriod: {
      comment: 'The number of days to wait before starting to compile requests',
      type: 'int',
    },
  },
});

/** Override type */
export type ActionInternal = SchemaToType<typeof ActionInternal>;

/**
 * Order for a action query
 */
export const ActionOrder = mkOrder(Action.name, ActionOrderField);

/** Override type */
export type ActionOrder = SchemaToType<typeof ActionOrder>;
/* eslint-enable max-lines */

import { defineMessages } from '@transcend-io/internationalization';

export const paginatedSelectMessages = defineMessages(
  'core-ui.RawPaginatedSelect.paginatedSelect',
  {
    notFound: {
      defaultMessage: 'No matches',
    },
    placeholder: {
      defaultMessage: 'Select',
    },
    loading: {
      defaultMessage: 'Loading...',
    },
    error: {
      defaultMessage: 'Failed to load options',
    },
  },
);

import { combineReducers } from '@reduxjs/toolkit';
import {
  createMigrate,
  MigrationManifest,
  persistReducer,
} from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage';

import { activeDataSubjectSlice } from './containers/ActiveDataSubject/slice';
import { appSlice } from './containers/App/slice';
import { requestHistoryPreviewSlice } from './containers/RequestHistoryPreview/slice';
import { takeControlSlice } from './containers/TakeControl/slice';
import { tokenLoginSlice } from './containers/TokenLogin/slice';
import { purposesSlice } from './pages/ManageYourPrivacy/slice';
import { policiesSlice } from './pages/Policies/slice';
import { localizedRouterSlice } from './routes/LangProvider/slice';

/** Combine all slices into one reducer  */
export const privacyCenterPreloadedReducers = {
  [appSlice.name]: appSlice.reducer,
  [takeControlSlice.name]: takeControlSlice.reducer,
  [activeDataSubjectSlice.name]: activeDataSubjectSlice.reducer,
  [policiesSlice.name]: policiesSlice.reducer,
  [tokenLoginSlice.name]: tokenLoginSlice.reducer,
  [requestHistoryPreviewSlice.name]: requestHistoryPreviewSlice.reducer,
  [localizedRouterSlice.name]: localizedRouterSlice.reducer,
  [purposesSlice.name]: purposesSlice.reducer,
};

/** The root reducer without persistance */
export const privacyCenterRootReducer = combineReducers(
  privacyCenterPreloadedReducers,
);

/** The shape of the privacy center reducer */
export type PrivacyCenterRootState = ReturnType<
  typeof privacyCenterRootReducer
>;

/** Migrate the shape of the store */
const migrations: MigrationManifest = {
  // internationalization changes converted strings
  // to DefinedMessage and that needs to be cleared out
  0: (previousState: any) => ({
    ...previousState,
    'privacy-center': {},
    DataPractices: {},
    ActiveDataSubject: { subjects: [] },
    TakeControl: { disabledActions: [] },
  }),
  // first attempt at versioning was invalid
  1: (previousState: any) => ({
    ...previousState,
    'privacy-center': {},
    DataPractices: {},
    ActiveDataSubject: { subjects: [] },
    TakeControl: { disabledActions: [] },
  }),
  // second attempt at versioning was invalid
  2: (previousState: any) => ({
    ...previousState,
    'privacy-center': {},
    DataPractices: {},
    ActiveDataSubject: { subjects: [] },
    TakeControl: { disabledActions: [] },
  }),
  // third attempt at versioning was invalid
  3: (previousState: any) => ({
    ...previousState,
    'privacy-center': {},
    DataPractices: {},
    ActiveDataSubject: { subjects: [] },
    TakeControl: { disabledActions: [] },
  }),
  // i swear fourth times the charm, just wipe it all
  4: (previousState: any) => ({
    ...previousState,
    'privacy-center': {},
    DataPractices: {},
    ActiveDataSubject: { subjects: [] },
    TakeControl: { disabledActions: [] },
    TokenLogin: { subjects: [] },
    RequestHistoryPreview: {
      activeRequests: [],
      dataSubjectActiveCount: 0,
      totalActiveRequests: 0,
    },
  }),
  // hims & hers was experiencing a situation where the privacy center showed a forever
  // loading indicator. localStorage.clear() fixed the problem
  5: (previousState: any) => ({
    ...previousState,
    'privacy-center': {},
    DataPractices: {},
    ActiveDataSubject: { subjects: [] },
    TakeControl: { disabledActions: [] },
    TokenLogin: { subjects: [] },
    RequestHistoryPreview: {
      activeRequests: [],
      dataSubjectActiveCount: 0,
      totalActiveRequests: 0,
    },
    LocalizedRouter: { translations: {} },
  }),
  // previous attempt did not work, attempting to work around by clearing local storage
  6: (previousState: any) => {
    if (localStorage) {
      localStorage.clear();
    }
    return {
      ...previousState,
      'privacy-center': {},
      DataPractices: {},
      ActiveDataSubject: { subjects: [] },
      TakeControl: { disabledActions: [] },
      TokenLogin: { subjects: [] },
      RequestHistoryPreview: {
        activeRequests: [],
        dataSubjectActiveCount: 0,
        totalActiveRequests: 0,
      },
      LocalizedRouter: { translations: {} },
    };
  },
};

/** Persist the store between page reloads */
export const privacyCenterPersistedReducer: typeof privacyCenterRootReducer =
  persistReducer(
    {
      // whenever the shape of the store changes, this version can be updated to
      // which will tell the browser to ignore the old cached values
      version: 2,
      key: 'root',
      // in theory localForage will be preferable for better perf, but
      // 1) https://github.com/rt2zz/redux-persist/issues/1148 &
      // 2) we're likely to see more significant perf gains streamlining what we
      // write to our store + persist independent of storage tooling choices
      // TODO: https://github.com/transcend-io/main/issues/2866 - remove this as a default once we're further along in
      storage,
      migrate: createMigrate(migrations),
      stateReconciler: autoMergeLevel1,
      blacklist: ['router'],
    },
    privacyCenterRootReducer as any,
  ) as any;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagAO: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54949"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54949)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#1D1D1D"
      />
      <mask
        id="mask1_270_54949"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54949)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V6H16V0H0Z"
          fill="#F50100"
        />
        <g filter="url(#filter0_d_270_54949)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.77496 6.69606C8.16257 6.21705 8.28398 5.59783 8.28398 5.21143C8.28398 3.15243 5.71501 2.01343 5.71501 2.01343C7.48121 2.01343 9.44835 3.44525 9.44835 5.21143C9.44835 5.95927 9.19166 6.65316 8.76159 7.20471C9.54868 7.59735 10.2149 7.90375 10.2149 7.90375C10.462 8.06581 10.5311 8.49799 10.3691 8.74525C10.2069 8.99237 9.87504 9.06138 9.62799 8.89943C9.62799 8.89943 8.99594 8.52564 8.61081 8.27312C8.43429 8.1574 8.23018 8.03198 8.01294 7.90349C7.50757 8.24369 6.90166 8.44299 6.25028 8.44299C6.25028 8.44299 3.90173 8.15532 3.96894 6.951C3.96894 6.951 4.5375 7.38592 6.21006 7.38592C6.47623 7.38592 6.71021 7.34864 6.91549 7.28312C6.19105 6.88781 5.57667 6.57447 5.57667 6.57447C5.32937 6.41244 4.68629 5.5909 4.84836 5.34363C5.01049 5.09648 5.99066 5.37586 6.23777 5.53784C6.23777 5.53784 6.58147 6.00986 7.06652 6.29845C7.28084 6.42594 7.52279 6.56082 7.77496 6.69606ZM6.59017 4.90121L6.08526 5.23764L6.24659 4.66141L5.87593 4.29331L6.37783 4.27341L6.59017 3.7036L6.80251 4.27341H7.30356L6.93375 4.66141L7.11895 5.20363L6.59017 4.90121Z"
            fill="#FCFF01"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.77496 6.69606C8.16257 6.21705 8.28398 5.59783 8.28398 5.21143C8.28398 3.15243 5.71501 2.01343 5.71501 2.01343C7.48121 2.01343 9.44835 3.44525 9.44835 5.21143C9.44835 5.95927 9.19166 6.65316 8.76159 7.20471C9.54868 7.59735 10.2149 7.90375 10.2149 7.90375C10.462 8.06581 10.5311 8.49799 10.3691 8.74525C10.2069 8.99237 9.87504 9.06138 9.62799 8.89943C9.62799 8.89943 8.99594 8.52564 8.61081 8.27312C8.43429 8.1574 8.23018 8.03198 8.01294 7.90349C7.50757 8.24369 6.90166 8.44299 6.25028 8.44299C6.25028 8.44299 3.90173 8.15532 3.96894 6.951C3.96894 6.951 4.5375 7.38592 6.21006 7.38592C6.47623 7.38592 6.71021 7.34864 6.91549 7.28312C6.19105 6.88781 5.57667 6.57447 5.57667 6.57447C5.32937 6.41244 4.68629 5.5909 4.84836 5.34363C5.01049 5.09648 5.99066 5.37586 6.23777 5.53784C6.23777 5.53784 6.58147 6.00986 7.06652 6.29845C7.28084 6.42594 7.52279 6.56082 7.77496 6.69606ZM6.59017 4.90121L6.08526 5.23764L6.24659 4.66141L5.87593 4.29331L6.37783 4.27341L6.59017 3.7036L6.80251 4.27341H7.30356L6.93375 4.66141L7.11895 5.20363L6.59017 4.90121Z"
            fill="#FFEA42"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_270_54949"
        x="3.96753"
        y="2.01343"
        width="6.48914"
        height="6.97357"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_270_54949"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_270_54949"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

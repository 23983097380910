import React from 'react';

import { DEPLOY_ENV } from '../args';
import { logger } from '../logger';
import { ICON_TYPES, IconType } from './constants';

export interface IconProps {
  /** The icon type, should match in `IconType` */
  type: IconType;
  /** The icon color, i.e. hexcode. Defaults to 'currentColor' */
  color?: string;
  /** The secondary icon color if applicable. Defaults to 'currentColor' */
  color2?: string;
  /** The icon size. Defaults to 18 */
  size?: number | string;
  /** The stroke width. Defaults to different thickness depending on the icon */
  strokeWidth?: number | string;
}

/**
 * The icon component
 * To add a new icon:
 *    1. Add the SVG file to /constants/assets as a functional component
 *    2. Replace any hexcodes with the `color` prop, and the height and width with `size`
 *    3. Add the icon type to `IconType` in /constants/enums
 *    4. Add the icon type to `ICON_TYPES` in /constants/index
 *    5. (Recommended) Check the `options` list in icon.stories.tsx to test render it
 */
export const Icon: React.FC<IconProps> = ({
  type,
  color = 'currentColor',
  color2 = 'currentColor',
  size = '1.3em',
  strokeWidth,
}) => {
  const IconWrapper = ICON_TYPES[type];
  if (!IconWrapper) {
    const errorMessage = `Icon ${type} not found`;
    if (DEPLOY_ENV === 'local') {
      throw new Error(errorMessage);
    } else {
      logger.error(errorMessage);
      return <></>;
    }
  }
  const strokeWidthProp = strokeWidth ? { strokeWidth: `${strokeWidth}` } : {};
  return (
    <IconWrapper
      color={color}
      color2={color2}
      size={`${size}`}
      {...strokeWidthProp}
    />
  );
};

export { IconType };

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const LockHexagon: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M18.2973 15.3334V13.6307C18.2973 12.4254 17.2693 11.3334 16 11.3334C14.7306 11.3334 13.7026 12.3614 13.7026 13.6307V15.3334"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M18.7013 20.6667H13.2987C12.5813 20.6667 12 20.0854 12 19.368V16.632C12 15.9147 12.5813 15.3334 13.2987 15.3334H18.7013C19.4187 15.3334 20 15.9147 20 16.632V19.368C20 20.0854 19.4187 20.6667 18.7013 20.6667Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4 18.5093V13.4919C4 11.3559 5.136 9.38128 6.98133 8.30661L12.9813 4.81461C14.8467 3.72928 17.152 3.72928 19.0173 4.81461L25.0173 8.30661C26.864 9.37994 28 11.3546 28 13.4906V18.5079C28 20.6439 26.864 22.6186 25.0187 23.6933L19.0187 27.1853C17.1533 28.2706 14.848 28.2706 12.9827 27.1853L6.98267 23.6933C5.136 22.6199 4 20.6453 4 18.5093Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const PluginAdd: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.44267 6.99815C3.15122 6.93879 2.83508 6.95013 2.50895 7.05884C1.95738 7.24292 1.52054 7.71712 1.38381 8.28269C1.10236 9.44517 1.97539 10.4829 3.09052 10.4829C3.21124 10.4829 3.32862 10.4703 3.44267 10.4476V12.5951C3.44267 13.3721 4.07293 14.0024 4.85059 14.0024H12.5945C13.3721 14.0024 14.0024 13.3721 14.0024 12.5945V4.85059C14.0024 4.07293 13.3721 3.44267 12.5945 3.44267H10.4469C10.5063 3.15122 10.4949 2.83508 10.3862 2.50895C10.2015 1.95738 9.72729 1.52054 9.16238 1.38381C7.9999 1.10236 6.96214 1.97539 6.96214 3.09052C6.96214 3.21124 6.97481 3.32862 6.99748 3.44267H4.84992C4.07227 3.44267 3.442 4.07293 3.442 4.85059V6.99815H3.44267Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66697 6.99951V9.66729"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0008 8.33347H7.33301"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagCL: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54975"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54975)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H7V7H0V0Z"
        fill="#3D58DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 -1H16V7H7V-1Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7H16V12H0V7Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3.5072 4.89166L1.60536 6.0274L2.54395 4.09497L0.881897 2.83987L2.80064 2.81933L3.52448 1.10571L3.99055 2.81933L5.80666 2.82789L4.42535 4.05547L5.14264 6.0274L3.5072 4.89166Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

import { mkType, SchemaToType } from '@main/schema-utils';

import { ResourceType } from './enums';

export const Resource = mkType({
  name: 'Resource',
  comment: 'Grant access to a particular resource, i.e. a data silo',
  fields: {
    id: {
      comment: 'The unique ID of the resource',
      modelName: 'any',
      type: 'id',
    },
    type: {
      comment: 'The type of resource',
      type: { ResourceType },
    },
    title: {
      comment: 'The display title of the resource',
      type: 'string',
    },
    description: {
      comment: 'A brief internal description of the data silo',
      type: 'string',
    },
    link: {
      comment: 'The link to the data silo on the admin dashboard',
      type: 'string',
    },
    logo: {
      comment: 'The avatar image URL',
      type: 'string',
    },
  },
});

/** Override type */
export type Resource = SchemaToType<typeof Resource>;

import { IdentifierType, RequestAction } from '@transcend-io/privacy-types';

import { Resource } from '@main/access-control-types';
import { AttributeValue } from '@main/attribute-types';
import { DefinedMessage } from '@main/internationalization';
import {
  mkInput,
  mkInterface,
  mkOrder,
  mkType,
  SchemaToType,
} from '@main/schema-utils';

import { DataSubject } from './dataSubject';
import { IdentifierOrderField } from './enums';

export const IdentifierInterface = mkInterface({
  name: 'IdentifierInterface',
  comment: `The interface for an identifier.
An identifier is a piece of personal data, verified by the organization, that can be used to lookup data for some person.`,
  fields: {
    id: {
      comment: 'The unique id of the identifier',
      type: 'string',
    },
    name: {
      comment: 'The name of the identifier',
      type: 'string',
    },
    regex: {
      comment: 'The regex string for testing if a value matches the identifier',
      type: 'string',
    },
    isRequiredInForm: {
      comment:
        'Whether the identifier should be required in the Privacy Center/Admin Dashboard DSR form.',
      type: 'boolean',
    },
    displayTitle: {
      comment: 'The privacy center display title',
      type: DefinedMessage,
      optional: true,
    },
    displayDescription: {
      comment: 'The privacy center display description',
      type: DefinedMessage,
      optional: true,
    },
    placeholder: {
      comment: 'The privacy center placeholder text',
      type: 'string',
    },
    isCommunicationModel: {
      comment:
        'Indicates if the model can be used to communicate with the data subject',
      type: 'boolean',
    },
    type: {
      comment: 'The type of identifier',
      type: { IdentifierType },
    },
    logo: {
      comment: 'The logo icon to display with the identifier',
      type: 'string',
    },
    selectOptions: {
      comment:
        'For multi select components, the set of options that should be exposed to the user',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type IdentifierInterface = SchemaToType<typeof IdentifierInterface>;

export const WiredIdentifier = mkType({
  name: 'WiredIdentifier',
  comment: `For the edges between data silo and identifier, this can be used to list out all
potential identifiers and whether they are connected to the data silo`,
  interfaces: [IdentifierInterface],
  fields: {
    ...IdentifierInterface.fields,
    id: {
      comment: 'The unique id of the identifier & data silo combination',
      type: 'string',
    },
    isConnected: {
      comment:
        'Indicates if the identifier has been connected to the data silo',
      type: 'boolean',
    },
  },
});

/** Override type */
export type WiredIdentifier = SchemaToType<typeof WiredIdentifier>;

export const IdentifierFiltersInput = mkInput({
  name: 'IdentifierFiltersInput',
  comment: 'The filters when querying for identifiers',
  fields: {
    text: {
      comment: 'The identifier text filter',
      optional: true,
      type: 'string',
    },
    type: {
      comment: 'The identifier type filter',
      list: true,
      optional: true,
      type: { IdentifierType },
    },
  },
});

/** Override type */
export type IdentifierFiltersInput = SchemaToType<
  typeof IdentifierFiltersInput
>;

export const DeleteIdentifiersInput = mkInput({
  name: 'DeleteIdentifiersInput',
  comment: 'Delete a set of identifiers',
  fields: {
    ids: {
      comment: 'The identifier text filter',
      modelName: 'identifier',
      type: 'id',
      list: true,
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type DeleteIdentifiersInput = SchemaToType<
  typeof DeleteIdentifiersInput
>;

export const IdentifierInput = mkInput({
  name: 'IdentifierInput',
  comment: 'The input for creating an identifier',
  fields: {
    name: {
      comment: 'The unique name of the identifier',
      type: 'string',
    },
    type: {
      comment: 'The static type of identifier',
      type: { IdentifierType },
    },
    displayTitle: {
      comment: 'The privacy center display title',
      type: 'string',
      optional: true,
    },
    displayDescription: {
      comment: 'The privacy center display description',
      type: 'string',
      optional: true,
    },
    placeholder: {
      comment: 'The privacy center placeholder text',
      type: 'string',
      optional: true,
    },
    dataSubjectIds: {
      comment:
        'The IDs of the explicit data subjects that the identifier should display for',
      optional: true,
      modelName: 'subject',
      list: true,
      type: 'id',
    },
    regex: {
      comment: 'A regex string for testing if a value matches the identifier',
      optional: true,
      type: 'string',
    },
    isRequiredInForm: {
      comment:
        'Whether the identifier should be required in the Privacy Center/Admin Dashboard DSR form.',
      type: 'boolean',
      optional: true,
    },
    selectOptions: {
      comment:
        'The set of options to show in the privacy center when shown as a select component.',
      list: true,
      type: 'string',
      optional: true,
    },
    privacyCenterVisibility: {
      comment:
        'The set of actions where this identifier should be shown for on the privacy center',
      list: true,
      optional: true,
      type: { RequestAction },
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
    displayOrder: {
      comment:
        'The order index for this identifier when displaying in the Privacy Center',
      type: 'int',
      optional: true,
    },
    sqlPriority: {
      comment:
        'The SQL priority to use when generating SQL statements. ' +
        'If two identifiers are found for a table/datapoint, the lookup key for the ' +
        "table's SQL statement will be the identifier with highest priority. " +
        'If no priorities are found, then all identifiers are queried. ',
      type: 'int',
      optional: true,
    },
    sqlVariable: {
      comment:
        'The SQL variable to use for this identifier in SQL statements. ' +
        'This defaults to just the identity function, but can be overridden ' +
        'to provide more complex SQL transformations. For example ' +
        'email does a LOWER({{ column }}) transformation. ',
      type: 'string',
      optional: true,
    },
    sqlLookupVariable: {
      comment:
        'The SQL variable to use for this identifier in SQL statements as a lookup key. ' +
        'This defaults to just the identity function @<identifierName>, but can be overridden ' +
        'to provide more complex SQL transformations. For example ' +
        'email UNHEX(@<identifierName>) transformation. ',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type IdentifierInput = SchemaToType<typeof IdentifierInput>;

export const UpdateIdentifierInput = mkInput({
  name: 'UpdateIdentifierInput',
  comment: 'The input for updating an existing identifier',
  fields: {
    id: {
      comment: 'The ID of the unique identifier',
      modelName: 'identifier',
      type: 'id',
    },
    selectOptions: IdentifierInput.fields.selectOptions,
    dataSubjectIds: IdentifierInput.fields.dataSubjectIds,
    isRequiredInForm: IdentifierInput.fields.isRequiredInForm,
    regex: IdentifierInput.fields.regex,
    placeholder: IdentifierInput.fields.placeholder,
    displayTitle: IdentifierInput.fields.displayTitle,
    displayDescription: IdentifierInput.fields.displayDescription,
    privacyCenterVisibility: IdentifierInput.fields.privacyCenterVisibility,
    skipPublish: IdentifierInput.fields.skipPublish,
    displayOrder: IdentifierInput.fields.displayOrder,
    sqlPriority: IdentifierInput.fields.sqlPriority,
    sqlVariable: IdentifierInput.fields.sqlVariable,
    sqlLookupVariable: IdentifierInput.fields.sqlLookupVariable,
  },
});

/** Override type */
export type UpdateIdentifierInput = SchemaToType<typeof UpdateIdentifierInput>;

export const Identifier = mkType({
  name: 'Identifier',
  comment:
    "The internal configuration of the identifier, view from the identifier's perspective on the /settings page.",
  interfaces: [IdentifierInterface],
  fields: {
    ...IdentifierInterface.fields,
    privacyCenterVisibility: {
      comment:
        'The set of actions where this identifier should be shown for on the privacy center',
      list: true,
      type: { RequestAction },
    },
    canResolve: {
      comment: 'Indicates if there is a way for the identifier to be resolved',
      type: 'boolean',
    },
    dataSubjects: {
      comment: 'The data subjects explicitly configured for this enricher',
      type: DataSubject,
      list: true,
    },
    dependentDataSilos: {
      comment: 'The data silos that can be keyed by the identifier',
      list: true,
      type: Resource,
    },
    displayOrder: IdentifierInput.fields.displayOrder,
    sqlPriority: IdentifierInput.fields.sqlPriority,
    sqlVariable: {
      comment: IdentifierInput.fields.sqlVariable.comment,
      type: 'string',
    },
    sqlLookupVariable: {
      comment: IdentifierInput.fields.sqlLookupVariable.comment,
      type: 'string',
    },
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
  },
});

/** Override type */
export type Identifier = SchemaToType<typeof Identifier>;

export const NewIdentifierType = mkType({
  name: 'NewIdentifierType',
  comment: 'A select option for an identifier type',
  fields: {
    logo: {
      comment: 'The logo icon',
      type: 'string',
    },
    name: {
      comment: 'The name of the option (its value)',
      type: { IdentifierType },
    },
  },
});

/** Override type */
export type NewIdentifierType = SchemaToType<typeof NewIdentifierType>;

/**
 * Fields that an identifier can be ordered by
 */
export const IdentifierOrder = mkOrder(Identifier.name, IdentifierOrderField);

/** Override type */
export type IdentifierOrder = SchemaToType<typeof IdentifierOrder>;

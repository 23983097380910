import { DefaultTheme } from 'styled-components';

import { IsoCountryCode } from '@transcend-io/privacy-types';

import { IconType } from '../Icon';

export const REGION_COLORS: (theme: DefaultTheme) => (option: {
  /** The country being selected */
  country?: IsoCountryCode;
  /** The country subdivision being selected */
  countrySubDivision?: string;
}) => string = (theme) => () => theme.colors.transcendNavy2;

export const REGION_COUNTRY_ICON_TYPES: {
  [country in IsoCountryCode]: IconType;
} = {
  [IsoCountryCode.US]: IconType.FlagUS,
  [IsoCountryCode.EU]: IconType.FlagEU,
  [IsoCountryCode.AF]: IconType.FlagAF,
  [IsoCountryCode.AX]: IconType.FlagAX,
  [IsoCountryCode.AL]: IconType.FlagAL,
  [IsoCountryCode.DZ]: IconType.FlagDZ,
  [IsoCountryCode.AS]: IconType.FlagAS,
  [IsoCountryCode.AD]: IconType.FlagAD,
  [IsoCountryCode.AO]: IconType.FlagAO,
  [IsoCountryCode.AI]: IconType.FlagAI,
  [IsoCountryCode.AQ]: IconType.FlagAQ,
  [IsoCountryCode.AG]: IconType.FlagAG,
  [IsoCountryCode.AR]: IconType.FlagAR,
  [IsoCountryCode.AM]: IconType.FlagAM,
  [IsoCountryCode.AW]: IconType.FlagAW,
  [IsoCountryCode.AU]: IconType.FlagAU,
  [IsoCountryCode.AT]: IconType.FlagAT,
  [IsoCountryCode.AZ]: IconType.FlagAZ,
  [IsoCountryCode.BS]: IconType.FlagBS,
  [IsoCountryCode.BH]: IconType.FlagBH,
  [IsoCountryCode.BD]: IconType.FlagBD,
  [IsoCountryCode.BB]: IconType.FlagBB,
  [IsoCountryCode.BY]: IconType.FlagBY,
  [IsoCountryCode.BE]: IconType.FlagBE,
  [IsoCountryCode.BZ]: IconType.FlagBZ,
  [IsoCountryCode.BJ]: IconType.FlagBJ,
  [IsoCountryCode.BM]: IconType.FlagBM,
  [IsoCountryCode.BT]: IconType.FlagBT,
  [IsoCountryCode.BO]: IconType.FlagBO,
  [IsoCountryCode.BA]: IconType.FlagBA,
  [IsoCountryCode.BW]: IconType.FlagBW,
  [IsoCountryCode.BV]: IconType.FlagBV,
  [IsoCountryCode.BR]: IconType.FlagBR,
  [IsoCountryCode.IO]: IconType.FlagIO,
  [IsoCountryCode.VG]: IconType.FlagVG,
  [IsoCountryCode.BN]: IconType.FlagBN,
  [IsoCountryCode.BG]: IconType.FlagBG,
  [IsoCountryCode.BF]: IconType.FlagBF,
  [IsoCountryCode.BI]: IconType.FlagBI,
  [IsoCountryCode.KH]: IconType.FlagKH,
  [IsoCountryCode.CM]: IconType.FlagCM,
  [IsoCountryCode.CA]: IconType.FlagCA,
  [IsoCountryCode.CV]: IconType.FlagCV,
  [IsoCountryCode.BQ]: IconType.FlagBQ,
  [IsoCountryCode.KY]: IconType.FlagKY,
  [IsoCountryCode.CF]: IconType.FlagCF,
  [IsoCountryCode.TD]: IconType.FlagTD,
  [IsoCountryCode.CL]: IconType.FlagCL,
  [IsoCountryCode.CN]: IconType.FlagCN,
  [IsoCountryCode.CX]: IconType.FlagCX,
  [IsoCountryCode.CC]: IconType.FlagCC,
  [IsoCountryCode.CO]: IconType.FlagCO,
  [IsoCountryCode.KM]: IconType.FlagKM,
  [IsoCountryCode.CG]: IconType.FlagCG,
  [IsoCountryCode.CD]: IconType.FlagCD,
  [IsoCountryCode.CK]: IconType.FlagCK,
  [IsoCountryCode.CR]: IconType.FlagCR,
  [IsoCountryCode.CI]: IconType.FlagCI,
  [IsoCountryCode.HR]: IconType.FlagHR,
  [IsoCountryCode.CU]: IconType.FlagCU,
  [IsoCountryCode.CW]: IconType.FlagCW,
  [IsoCountryCode.CY]: IconType.FlagCY,
  [IsoCountryCode.CZ]: IconType.FlagCZ,
  [IsoCountryCode.DK]: IconType.FlagDK,
  [IsoCountryCode.DJ]: IconType.FlagDJ,
  [IsoCountryCode.DM]: IconType.FlagDM,
  [IsoCountryCode.DO]: IconType.FlagDO,
  [IsoCountryCode.EC]: IconType.FlagEC,
  [IsoCountryCode.EG]: IconType.FlagEG,
  [IsoCountryCode.SV]: IconType.FlagSV,
  [IsoCountryCode.GQ]: IconType.FlagGQ,
  [IsoCountryCode.ER]: IconType.FlagER,
  [IsoCountryCode.EE]: IconType.FlagEE,
  [IsoCountryCode.SZ]: IconType.FlagSZ,
  [IsoCountryCode.ET]: IconType.FlagET,
  [IsoCountryCode.FK]: IconType.FlagFK,
  [IsoCountryCode.FO]: IconType.FlagFO,
  [IsoCountryCode.FJ]: IconType.FlagFJ,
  [IsoCountryCode.FI]: IconType.FlagFI,
  [IsoCountryCode.FR]: IconType.FlagFR,
  [IsoCountryCode.GF]: IconType.FlagGF,
  [IsoCountryCode.PF]: IconType.FlagPF,
  [IsoCountryCode.TF]: IconType.FlagTF,
  [IsoCountryCode.GA]: IconType.FlagGA,
  [IsoCountryCode.GM]: IconType.FlagGM,
  [IsoCountryCode.GE]: IconType.FlagGE,
  [IsoCountryCode.DE]: IconType.FlagDE,
  [IsoCountryCode.GH]: IconType.FlagGH,
  [IsoCountryCode.GI]: IconType.FlagGI,
  [IsoCountryCode.GR]: IconType.FlagGR,
  [IsoCountryCode.GL]: IconType.FlagGL,
  [IsoCountryCode.GD]: IconType.FlagGD,
  [IsoCountryCode.GP]: IconType.FlagGP,
  [IsoCountryCode.GU]: IconType.FlagGU,
  [IsoCountryCode.GT]: IconType.FlagGT,
  [IsoCountryCode.GG]: IconType.FlagGG,
  [IsoCountryCode.GN]: IconType.FlagGN,
  [IsoCountryCode.GW]: IconType.FlagGW,
  [IsoCountryCode.GY]: IconType.FlagGY,
  [IsoCountryCode.HT]: IconType.FlagHT,
  [IsoCountryCode.HM]: IconType.FlagHM,
  [IsoCountryCode.HN]: IconType.FlagHN,
  [IsoCountryCode.HK]: IconType.FlagHK,
  [IsoCountryCode.HU]: IconType.FlagHU,
  [IsoCountryCode.IS]: IconType.FlagIS,
  [IsoCountryCode.IN]: IconType.FlagIN,
  [IsoCountryCode.ID]: IconType.FlagID,
  [IsoCountryCode.IR]: IconType.FlagIR,
  [IsoCountryCode.IQ]: IconType.FlagIQ,
  [IsoCountryCode.IE]: IconType.FlagIE,
  [IsoCountryCode.IM]: IconType.FlagIM,
  [IsoCountryCode.IL]: IconType.FlagIL,
  [IsoCountryCode.IT]: IconType.FlagIT,
  [IsoCountryCode.JM]: IconType.FlagJM,
  [IsoCountryCode.JP]: IconType.FlagJP,
  [IsoCountryCode.JE]: IconType.FlagJE,
  [IsoCountryCode.JO]: IconType.FlagJO,
  [IsoCountryCode.KZ]: IconType.FlagKZ,
  [IsoCountryCode.KE]: IconType.FlagKE,
  [IsoCountryCode.KI]: IconType.FlagKI,
  [IsoCountryCode.KW]: IconType.FlagKW,
  [IsoCountryCode.KG]: IconType.FlagKG,
  [IsoCountryCode.LA]: IconType.FlagLA,
  [IsoCountryCode.LV]: IconType.FlagLV,
  [IsoCountryCode.LB]: IconType.FlagLB,
  [IsoCountryCode.LS]: IconType.FlagLS,
  [IsoCountryCode.LR]: IconType.FlagLR,
  [IsoCountryCode.LY]: IconType.FlagLY,
  [IsoCountryCode.LI]: IconType.FlagLI,
  [IsoCountryCode.LT]: IconType.FlagLT,
  [IsoCountryCode.LU]: IconType.FlagLU,
  [IsoCountryCode.MO]: IconType.FlagMO,
  [IsoCountryCode.MG]: IconType.FlagMG,
  [IsoCountryCode.MW]: IconType.FlagMW,
  [IsoCountryCode.MY]: IconType.FlagMY,
  [IsoCountryCode.MV]: IconType.FlagMV,
  [IsoCountryCode.ML]: IconType.FlagML,
  [IsoCountryCode.MT]: IconType.FlagMT,
  [IsoCountryCode.MH]: IconType.FlagMH,
  [IsoCountryCode.MQ]: IconType.FlagMQ,
  [IsoCountryCode.MR]: IconType.FlagMR,
  [IsoCountryCode.MU]: IconType.FlagMU,
  [IsoCountryCode.YT]: IconType.FlagYT,
  [IsoCountryCode.MX]: IconType.FlagMX,
  [IsoCountryCode.FM]: IconType.FlagFM,
  [IsoCountryCode.MD]: IconType.FlagMD,
  [IsoCountryCode.MC]: IconType.FlagMC,
  [IsoCountryCode.MN]: IconType.FlagMN,
  [IsoCountryCode.ME]: IconType.FlagME,
  [IsoCountryCode.MS]: IconType.FlagMS,
  [IsoCountryCode.MA]: IconType.FlagMA,
  [IsoCountryCode.MZ]: IconType.FlagMZ,
  [IsoCountryCode.MM]: IconType.FlagMM,
  [IsoCountryCode.NA]: IconType.FlagNA,
  [IsoCountryCode.NR]: IconType.FlagNR,
  [IsoCountryCode.NP]: IconType.FlagNP,
  [IsoCountryCode.NL]: IconType.FlagNL,
  [IsoCountryCode.NC]: IconType.FlagNC,
  [IsoCountryCode.NZ]: IconType.FlagNZ,
  [IsoCountryCode.NI]: IconType.FlagNI,
  [IsoCountryCode.NE]: IconType.FlagNE,
  [IsoCountryCode.NG]: IconType.FlagNG,
  [IsoCountryCode.NU]: IconType.FlagNU,
  [IsoCountryCode.NF]: IconType.FlagNF,
  [IsoCountryCode.KP]: IconType.FlagKP,
  [IsoCountryCode.MK]: IconType.FlagMK,
  [IsoCountryCode.MP]: IconType.FlagMP,
  [IsoCountryCode.NO]: IconType.FlagNO,
  [IsoCountryCode.OM]: IconType.FlagOM,
  [IsoCountryCode.PK]: IconType.FlagPK,
  [IsoCountryCode.PW]: IconType.FlagPW,
  [IsoCountryCode.PS]: IconType.FlagPS,
  [IsoCountryCode.PA]: IconType.FlagPA,
  [IsoCountryCode.PG]: IconType.FlagPG,
  [IsoCountryCode.PY]: IconType.FlagPY,
  [IsoCountryCode.PE]: IconType.FlagPE,
  [IsoCountryCode.PH]: IconType.FlagPH,
  [IsoCountryCode.PN]: IconType.FlagPN,
  [IsoCountryCode.PL]: IconType.FlagPL,
  [IsoCountryCode.PT]: IconType.FlagPT,
  [IsoCountryCode.PR]: IconType.FlagPR,
  [IsoCountryCode.QA]: IconType.FlagQA,
  [IsoCountryCode.RE]: IconType.FlagRE,
  [IsoCountryCode.RO]: IconType.FlagRO,
  [IsoCountryCode.RU]: IconType.FlagRU,
  [IsoCountryCode.RW]: IconType.FlagRW,
  [IsoCountryCode.WS]: IconType.FlagWS,
  [IsoCountryCode.SM]: IconType.FlagSM,
  [IsoCountryCode.ST]: IconType.FlagST,
  [IsoCountryCode.SA]: IconType.FlagSA,
  [IsoCountryCode.SN]: IconType.FlagSN,
  [IsoCountryCode.RS]: IconType.FlagRS,
  [IsoCountryCode.SC]: IconType.FlagSC,
  [IsoCountryCode.SL]: IconType.FlagSL,
  [IsoCountryCode.SG]: IconType.FlagSG,
  [IsoCountryCode.SX]: IconType.FlagSX,
  [IsoCountryCode.SK]: IconType.FlagSK,
  [IsoCountryCode.SI]: IconType.FlagSI,
  [IsoCountryCode.SB]: IconType.FlagSB,
  [IsoCountryCode.SO]: IconType.FlagSO,
  [IsoCountryCode.ZA]: IconType.FlagZA,
  [IsoCountryCode.GS]: IconType.FlagGS,
  [IsoCountryCode.KR]: IconType.FlagKR,
  [IsoCountryCode.SS]: IconType.FlagSS,
  [IsoCountryCode.ES]: IconType.FlagES,
  [IsoCountryCode.LK]: IconType.FlagLK,
  [IsoCountryCode.BL]: IconType.FlagBL,
  [IsoCountryCode.SH]: IconType.FlagSH,
  [IsoCountryCode.KN]: IconType.FlagKN,
  [IsoCountryCode.LC]: IconType.FlagLC,
  [IsoCountryCode.MF]: IconType.FlagMF,
  [IsoCountryCode.PM]: IconType.FlagPM,
  [IsoCountryCode.VC]: IconType.FlagVC,
  [IsoCountryCode.SD]: IconType.FlagSD,
  [IsoCountryCode.SR]: IconType.FlagSR,
  [IsoCountryCode.SJ]: IconType.FlagSJ,
  [IsoCountryCode.SE]: IconType.FlagSE,
  [IsoCountryCode.CH]: IconType.FlagCH,
  [IsoCountryCode.SY]: IconType.FlagSY,
  [IsoCountryCode.TW]: IconType.FlagTW,
  [IsoCountryCode.TJ]: IconType.FlagTJ,
  [IsoCountryCode.TZ]: IconType.FlagTZ,
  [IsoCountryCode.TH]: IconType.FlagTH,
  [IsoCountryCode.TL]: IconType.FlagTL,
  [IsoCountryCode.TG]: IconType.FlagTG,
  [IsoCountryCode.TK]: IconType.FlagTK,
  [IsoCountryCode.TO]: IconType.FlagTO,
  [IsoCountryCode.TT]: IconType.FlagTT,
  [IsoCountryCode.TN]: IconType.FlagTN,
  [IsoCountryCode.TR]: IconType.FlagTR,
  [IsoCountryCode.TM]: IconType.FlagTM,
  [IsoCountryCode.TC]: IconType.FlagTC,
  [IsoCountryCode.TV]: IconType.FlagTV,
  [IsoCountryCode.UM]: IconType.FlagUM,
  [IsoCountryCode.VI]: IconType.FlagVI,
  [IsoCountryCode.UG]: IconType.FlagUG,
  [IsoCountryCode.UA]: IconType.FlagUA,
  [IsoCountryCode.AE]: IconType.FlagAE,
  [IsoCountryCode.GB]: IconType.FlagGB,
  [IsoCountryCode.UY]: IconType.FlagUY,
  [IsoCountryCode.UZ]: IconType.FlagUZ,
  [IsoCountryCode.VU]: IconType.FlagVU,
  [IsoCountryCode.VA]: IconType.FlagVA,
  [IsoCountryCode.VE]: IconType.FlagVE,
  [IsoCountryCode.VN]: IconType.FlagVN,
  [IsoCountryCode.WF]: IconType.FlagWF,
  [IsoCountryCode.EH]: IconType.FlagEH,
  [IsoCountryCode.YE]: IconType.FlagYE,
  [IsoCountryCode.ZM]: IconType.FlagZM,
  [IsoCountryCode.ZW]: IconType.FlagZW,
};

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Stopwatch: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3337_37221)">
      <path
        d="M12.3094 5.75L13.0156 5.04375C13.1625 4.89687 13.1625 4.65937 13.0156 4.5125L12.4844 3.98125C12.3375 3.83438 12.1 3.83438 11.9531 3.98125L11.3062 4.62813C10.3344 3.76875 9.10625 3.19375 7.75 3.04063V1.5H8.625C8.83125 1.5 9 1.33125 9 1.125V0.375C9 0.16875 8.83125 0 8.625 0H5.375C5.16875 0 5 0.16875 5 0.375V1.125C5 1.33125 5.16875 1.5 5.375 1.5H6.25V3.04375C3.0125 3.41563 0.5 6.1625 0.5 9.5C0.5 13.0906 3.40937 16 7 16C10.5906 16 13.5 13.0906 13.5 9.5C13.5 8.10312 13.0594 6.80937 12.3094 5.75ZM7 14.5C4.2375 14.5 2 12.2625 2 9.5C2 6.7375 4.2375 4.5 7 4.5C9.7625 4.5 12 6.7375 12 9.5C12 12.2625 9.7625 14.5 7 14.5ZM7.375 11H6.625C6.41875 11 6.25 10.8313 6.25 10.625V6.375C6.25 6.16875 6.41875 6 6.625 6H7.375C7.58125 6 7.75 6.16875 7.75 6.375V10.625C7.75 10.8313 7.58125 11 7.375 11Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_3337_37221">
        <rect width="14" height="16" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);
/* eslint-enable max-len */

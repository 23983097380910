import { makeEnum } from '@transcend-io/type-utils';

/**
 * The fields that vendors can be ordered by
 */
export enum VendorOrderField {
  /** The title of the vendors */
  Title = 'title',
  /** The time the vendor was created */
  CreatedAt = 'createdAt',
  /** The time the vendor was updated */
  UpdatedAt = 'updatedAt',
}

/**
 * The status of a DPA with a particular data silo
 */
export const DataProcessingAgreementStatus = makeEnum({
  /** This vendor has a DPA made available online that is being used */
  OnlineDpa: 'ONLINE_DPA',
  /** A custom DPA is in place with this vendor */
  CustomDpa: 'CUSTOM_DPA',
  /** The DPA in place needs to be updated */
  RequiresUpdate: 'REQUIRES_UPDATE',
  /** There is no DPA required for this data silo */
  NotRequired: 'NOT_REQUIRED',
  /** The DPA for this tool is missing */
  Missing: 'MISSING',
});

/** Type override */
export type DataProcessingAgreementStatus =
  (typeof DataProcessingAgreementStatus)[keyof typeof DataProcessingAgreementStatus];

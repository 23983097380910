import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestAction } from '@transcend-io/privacy-types';

/**
 *take control state
 */
export interface TakeControlState {
  /** The list of disabled actions whose requests have already recently been submitted */
  disabledActions: RequestAction[];
}

export const takeControlSlice = createSlice({
  name: 'TakeControl',
  initialState: {
    disabledActions: [],
  } as TakeControlState,
  reducers: {
    setDisabledActions: (
      state,
      { payload }: PayloadAction<TakeControlState>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setDisabledActions } = takeControlSlice.actions;

import { endpoints, Enricher } from '@main/datamap-types';
import { GqlObject } from '@main/schema-utils';

export const SELECT_ENRICHERS_FIELDS: GqlObject<
  typeof endpoints.enrichers.response.nodes
> = {
  id: null,
  title: null,
  logo: null,
} as const;

/**
 * Object representing the enricher key that we're querying from the backend
 */
export type EnricherExported = Pick<
  Enricher,
  keyof typeof SELECT_ENRICHERS_FIELDS
>;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import { RequestHistory } from '@main/dsr-types';
import { ID } from '@main/schema-utils';
import { ONE_SECOND } from '@main/utils';

/**
 *request history preview state
 */
export interface RequestHistoryPreviewState {
  /** The requests actively being show */
  activeRequests: RequestHistory[];
  /** The number of active data subject requests */
  dataSubjectActiveCount: number;
  /** The id of the DSAR to preview inline */
  requestId?: ID<'request'>;
}

export const requestHistoryPreviewSlice = createSlice({
  name: 'RequestHistoryPreview',
  initialState: {
    activeRequests: [],
    dataSubjectActiveCount: 0,
    totalActiveRequests: 0,
  } as RequestHistoryPreviewState,
  reducers: {
    setActiveRequests: (
      state,
      {
        payload: { activeRequests },
      }: PayloadAction<{
        /** The requests actively being show */
        activeRequests: RequestHistory[];
      }>,
    ) => {
      state.activeRequests = uniqBy(
        [
          // always show requests that have recently been modified (i.e. a revoked request)
          // this allows for the success message to be rendered even when only active requests
          // should be shown
          ...state.activeRequests.filter(
            (request) =>
              +new Date() - +new Date(request.updatedAt) < ONE_SECOND * 7,
          ),
          ...activeRequests,
        ],
        'id',
      );
    },
    updateRequest: (
      state,
      {
        payload: { request },
      }: PayloadAction<{
        /** The request to update */
        request: RequestHistory;
      }>,
    ) => {
      state.activeRequests = state.activeRequests.map((r) =>
        r.id === request.id ? request : r,
      );
    },
    setActiveCount: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** Total number of active requests  */
        dataSubjectActiveCount: number;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
    setRequestId: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** The id of the DSAR to preview inline */
        requestId?: ID<'request'>;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
    clearRequestHistory: (state) => ({
      ...state,
      activeRequests: [],
      dataSubjectActiveCount: 0,
      requestId: undefined,
    }),
  },
});

export const {
  setActiveRequests,
  updateRequest,
  setActiveCount,
  setRequestId,
  clearRequestHistory,
} = requestHistoryPreviewSlice.actions;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Book: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12 9.1668C10.7628 8.45421 9.37091 8.05319 7.94427 7.99832C7.69793 7.98159 7.45577 8.06837 7.27613 8.23775C7.09648 8.40714 6.99564 8.64379 6.99784 8.89069V15.0012C7.00509 15.4745 7.37755 15.8614 7.85023 15.8866C9.31444 15.9046 10.7472 16.314 12 17.0721C13.2527 16.314 14.6855 15.9046 16.1497 15.8866C16.6224 15.8614 16.9948 15.4745 17.0021 15.0012V8.89069C17.0043 8.64379 16.9034 8.40714 16.7238 8.23775C16.5441 8.06837 16.302 7.98159 16.0556 7.99832C14.629 8.05319 13.2371 8.45421 12 9.1668Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2.99622"
      y="2.99625"
      width="18.0075"
      height="18.0075"
      rx="5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9.1658V17.0691"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Underline: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 13.3333H12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M11.3332 2.66669V7.33335C11.3332 9.17402 9.8405 10.6667 7.99984 10.6667C6.15917 10.6667 4.6665 9.17402 4.6665 7.33335V2.66669"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { defineMessages } from '@transcend-io/internationalization';

export const errorTextMessages = defineMessages('core-ui.ErrorText.errorText', {
  moreDetails: {
    defaultMessage: 'More Details',
  },
  lessDetails: {
    defaultMessage: 'Less Details',
  },
});

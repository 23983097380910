import { AssessmentQuestionSubType } from '@transcend-io/privacy-types';
import { makeEnum } from '@transcend-io/type-utils';

/**
 * The supported file types of the assessment question
 * Symbols like '.', '/', '-' are not supported by GQL schema
 * so we get the actual mime types when doing validation
 */
export const AssessmentQuestionFileType = makeEnum({
  /** json */
  json: 'json',
  /** pdf */
  pdf: 'pdf',
  /** xml */
  xml: 'xml',
  /** rtf */
  rtf: 'rtf',
  /** jpeg */
  jpeg: 'jpeg',
  /** csv */
  csv: 'csv',
  /** png */
  png: 'png',
  /** txt */
  txt: 'txt',
  /** html */
  html: 'html',
  /** docx */
  docx: 'docx',
});

/**
 * Subset of AssessmentQuestionSubTypes whose answer do not require a dynamic lookup
 * Important: must not overlap with DynamicAssessmentQuestionSubType
 */
export type StaticAssessmentQuestionSubType = Extract<
  AssessmentQuestionSubType,
  | typeof AssessmentQuestionSubType.Region
  | typeof AssessmentQuestionSubType.DataProcessingAgreementStatus
  | typeof AssessmentQuestionSubType.DeprecationState
  | typeof AssessmentQuestionSubType.RecommendedForConsent
  | typeof AssessmentQuestionSubType.RecommendedForPrivacy
  | typeof AssessmentQuestionSubType.RetentionType
  | typeof AssessmentQuestionSubType.Controllership
  | typeof AssessmentQuestionSubType.HasPersonalData
>;

/** Merge the declaration of the DynamicAssessmentQuestionSubType type with a value */
export const StaticAssessmentQuestionSubType = {
  [AssessmentQuestionSubType.Region]: AssessmentQuestionSubType.Region,
  [AssessmentQuestionSubType.DataProcessingAgreementStatus]:
    AssessmentQuestionSubType.DataProcessingAgreementStatus,
  [AssessmentQuestionSubType.DeprecationState]:
    AssessmentQuestionSubType.DeprecationState,
  [AssessmentQuestionSubType.RecommendedForConsent]:
    AssessmentQuestionSubType.RecommendedForConsent,
  [AssessmentQuestionSubType.RecommendedForPrivacy]:
    AssessmentQuestionSubType.RecommendedForPrivacy,
  [AssessmentQuestionSubType.RetentionType]:
    AssessmentQuestionSubType.RetentionType,
  [AssessmentQuestionSubType.Controllership]:
    AssessmentQuestionSubType.Controllership,
  [AssessmentQuestionSubType.HasPersonalData]:
    AssessmentQuestionSubType.HasPersonalData,
};

/**
 * Subset of AssessmentQuestionSubTypes whose answer options require a dynamic lookup
 * Important: must not overlap with StaticAssessmentQuestionSubType
 */
export type DynamicAssessmentQuestionSubType = Extract<
  AssessmentQuestionSubType,
  | typeof AssessmentQuestionSubType.BusinessEntity
  | typeof AssessmentQuestionSubType.DataSilo
  | typeof AssessmentQuestionSubType.DataSubCategory
  | typeof AssessmentQuestionSubType.Identifier
  | typeof AssessmentQuestionSubType.ProcessingPurposeSubCategory
  | typeof AssessmentQuestionSubType.SaaSCategory
  | typeof AssessmentQuestionSubType.Subject
  | typeof AssessmentQuestionSubType.Team
  | typeof AssessmentQuestionSubType.User
  | typeof AssessmentQuestionSubType.Vendor
  | typeof AssessmentQuestionSubType.AttributeKey
>;

/** Merge the declaration of the DynamicAssessmentQuestionSubType type with a value */
export const DynamicAssessmentQuestionSubType = {
  [AssessmentQuestionSubType.BusinessEntity]:
    AssessmentQuestionSubType.BusinessEntity,
  [AssessmentQuestionSubType.DataSilo]: AssessmentQuestionSubType.DataSilo,
  [AssessmentQuestionSubType.DataSubCategory]:
    AssessmentQuestionSubType.DataSubCategory,
  [AssessmentQuestionSubType.Identifier]: AssessmentQuestionSubType.Identifier,
  [AssessmentQuestionSubType.ProcessingPurposeSubCategory]:
    AssessmentQuestionSubType.ProcessingPurposeSubCategory,
  [AssessmentQuestionSubType.Subject]: AssessmentQuestionSubType.Subject,
  [AssessmentQuestionSubType.SaaSCategory]:
    AssessmentQuestionSubType.SaaSCategory,
  [AssessmentQuestionSubType.Team]: AssessmentQuestionSubType.Team,
  [AssessmentQuestionSubType.User]: AssessmentQuestionSubType.User,
  [AssessmentQuestionSubType.Vendor]: AssessmentQuestionSubType.Vendor,
  [AssessmentQuestionSubType.AttributeKey]:
    AssessmentQuestionSubType.AttributeKey,
};

/* eslint-disable max-lines */
import {
  CodePackageType,
  DataCategoryType,
  DefaultDataSubCategoryType,
  EnricherType,
} from '@transcend-io/privacy-types';
import { StringValues } from '@transcend-io/type-utils';

import { ID } from '@main/schema-utils';
import { ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_SECOND } from '@main/utils';

import {
  DataCollectionStaticType,
  DataSubjectClass,
  DefaultTemplateType,
} from '../schema/enums';
import { DefaultDataSubCategory } from '../types';

export const DATABASE_IDENTIFIER_ERROR_MESSAGES = {
  missingAsClauseError:
    'Functions inside column names should come with an "AS" clause',
};

/**
 * List of plugins that can support no-null filter,
 * to enable better data sampling for sparse columns
 */
export const PLUGIN_SUPPORT_NO_NULL_FILTER = new Set([
  'snowflake',
  'salesforce',
]);

/**
 * Icons (for admin-dashboard suggestion result)
 */
export const DATA_COLLECTION_STATIC_TYPE_TO_ICON: {
  [key in DataCollectionStaticType]: string;
} = {
  [DataCollectionStaticType.AppUsage]: 'spreadsheet',
  [DataCollectionStaticType.CallHistory]: 'phone',
  [DataCollectionStaticType.ContactInfo]: 'user',
  [DataCollectionStaticType.Location]: 'globe-cube',
  [DataCollectionStaticType.Messages]: 'comment',
  [DataCollectionStaticType.ProfilePicture]: 'user-card',
  [DataCollectionStaticType.SearchHistory]: 'search',
  [DataCollectionStaticType.StoreVisits]: 'store-modern-style',
  [DataCollectionStaticType.SocialMedia]: 'globe-cube',
  [DataCollectionStaticType.Survey]: 'document-contract-edit-pen',
  [DataCollectionStaticType.Tracking]: 'browser-web-eye',
  [DataCollectionStaticType.UserProfile]: 'profile',
  [DataCollectionStaticType.Unassigned]: 'lock',
  [DataCollectionStaticType.Connection]: 'circle-add',
  [DataCollectionStaticType.Demographic]: 'document-legal-users',
  [DataCollectionStaticType.Financial]: 'system-status',
  [DataCollectionStaticType.Health]: 'stopwatch',
  [DataCollectionStaticType.OnlineActivity]: 'list-tree',
  [DataCollectionStaticType.PersonalIdentifier]: 'view-details',
  [DataCollectionStaticType.Sites]: 'sidebar-data-map',
  [DataCollectionStaticType.DeviceInformation]: 'monitor',
  [DataCollectionStaticType.Interests]: 'user-exclamation',
  [DataCollectionStaticType.IpAddress]: 'monitor-cable-connection-add-plus',
};

export const DATA_CATEGORY_TYPE_TO_LEGACY_TYPE: {
  [k in DataCategoryType]: DataCollectionStaticType;
} = {
  [DataCategoryType.Financial]: DataCollectionStaticType.Financial,
  [DataCategoryType.Health]: DataCollectionStaticType.Health,
  [DataCategoryType.Contact]: DataCollectionStaticType.ContactInfo,
  [DataCategoryType.Location]: DataCollectionStaticType.Location,
  [DataCategoryType.Demographic]: DataCollectionStaticType.Demographic,
  [DataCategoryType.Id]: DataCollectionStaticType.PersonalIdentifier,
  [DataCategoryType.OnlineActivity]: DataCollectionStaticType.OnlineActivity,
  [DataCategoryType.UserProfile]: DataCollectionStaticType.UserProfile,
  [DataCategoryType.SocialMedia]: DataCollectionStaticType.SocialMedia,
  [DataCategoryType.Connection]: DataCollectionStaticType.DeviceInformation,
  [DataCategoryType.Tracking]: DataCollectionStaticType.Tracking,
  [DataCategoryType.Device]: DataCollectionStaticType.DeviceInformation,
  [DataCategoryType.Survey]: DataCollectionStaticType.Survey,
  [DataCategoryType.Other]: DataCollectionStaticType.Unassigned,
  [DataCategoryType.Unspecified]: DataCollectionStaticType.Unassigned,
  [DataCategoryType.NotPersonalData]: DataCollectionStaticType.Unassigned,
  [DataCategoryType.IntegrationIdentifier]: DataCollectionStaticType.Unassigned,
};

/**
 * Lookup from subject type to icon
 * TODO: https://transcend.height.app/T-36770/subtasks - replace these ant icons with new ones when ready
 */
export const SUBJECT_ICON: { [key in DataSubjectClass]: string } = {
  [DataSubjectClass.EndUser]: 'user',
  [DataSubjectClass.Employee]: 'employee-badge',
  [DataSubjectClass.BusinessContact]: 'high-rise-building',
  [DataSubjectClass.Other]: 'circle-dotted-line',
};

/**
 * All select options for a data subject type
 */
export const SUBJECT_CLASS_OPTIONS = Object.values(DataSubjectClass)
  // Dynamic
  .map((subjectClass) => ({
    name: subjectClass,
    icon: SUBJECT_ICON[subjectClass],
  }));

/**
 * Lookup from enricher type to icon
 */
export const ENRICHER_ICON: { [type in EnricherType]: string } = {
  [EnricherType.Sombra]: 'plugin',
  [EnricherType.Person]: 'user',
  [EnricherType.Server]: 'plugin',
  [EnricherType.Email]: 'envelope',
  [EnricherType.Phone]: 'phone',
  [EnricherType.GovernmentId]: 'user-card',
  [EnricherType.Database]: 'database',
  [EnricherType.Looker]: 'plugin',
  [EnricherType.LegalHold]: 'stop-hand',
  [EnricherType.RegexMatch]: 'search',
  [EnricherType.AutoApprove]: 'flag-checkmark',
  [EnricherType.RegionMatcher]: 'globe-cube',
  [EnricherType.WaitPeriod]: 'globe-cube',
};

/**
 * The names of the default templates
 */
export const DEFAULT_TEMPLATE_NAME: { [k in DefaultTemplateType]: string } = {
  /** The cancelation email template */
  [DefaultTemplateType.Cancelation]: 'Request Canceled',
  /** The no report email template */
  [DefaultTemplateType.NotFound]: 'No Report',
  /** The additional time */
  [DefaultTemplateType.AdditionalTime]: 'Additional Time Needed',
  /** Data subject canceled */
  [DefaultTemplateType.Revoked]: 'Data Subject Canceled Request',
  /** Receipt email */
  [DefaultTemplateType.Receipt]: 'New Request Receipt',
  /** The email login template */
  [DefaultTemplateType.EmailLogin]: 'Privacy Center Email Login',
  /**
   * The email to send to an external vendor, about all the outstanding requests
   * they must fulfill.
   */
  [DefaultTemplateType.ExternalBulkRequestVendorCoordination]:
    'Automated Vendor Coordination',
  [DefaultTemplateType.GovernmentIdMessage]: 'Government ID Verification',
};

/**
 * Get the template id by name of default
 */
export type GetTemplateIdByName = (
  name: StringValues<typeof DEFAULT_TEMPLATE_NAME>,
) => ID<'template'>;

/**
 * The number of times an errored request must be allowed to
 * retry, before stopping permanently.
 */
export const REQUEST_RETRY_ERROR_THRESHOLD = 5;

export const REQUEST_RETRY_ERROR_COUNT_RESET_LIMIT = ONE_DAY;

export const FALLBACK_CATEGORY_NAME = 'Other';

export const FALLBACK_PURPOSE_NAME = 'Other';

/**
 * The sub categories we support in the backend
 */
export type SupportedDefaultSubCategory = Exclude<
  DefaultDataSubCategoryType,
  | 'TITLE'
  | 'DEBIT_CARD_NUMBER'
  | 'PHYSICAL_ADDRESS'
  | 'PRECISE_GEOLOCATION'
  | 'APPROXIMATE_LOCATION'
  | 'INSURANCE'
  | 'BIOMETRICS'
  | 'GENETICS'
>;

export const DATA_CATEGORY_TYPE_DEFAULT_SUBCATEGORIES: Record<
  SupportedDefaultSubCategory,
  DefaultDataSubCategory
> = {
  /**
   * Permanent (e.g., device IDs, MAC address) or temporary (e.g., IP address)
   * identifiers needed to establish a connection for the current browsing session.
   */
  [DefaultDataSubCategoryType.MACAddress]: {
    name: 'MAC Address',
    category: DataCategoryType.Connection,
    description:
      'A Media Access Control address used to uniquely identify devices on a network',
  },
  [DefaultDataSubCategoryType.IPAddress]: {
    name: 'IP Address',
    category: DataCategoryType.Connection,
    description: 'An internet protocol address',
    regex: '([0-2]?[0-9]{1,2}\\.){3}[0-2]?[0-9]{1,2}',
  },
  [DefaultDataSubCategoryType.UserAgent]: {
    name: 'User Agent',
    category: DataCategoryType.Connection,
    description:
      'The User-Agent request header is a characteristic string that lets servers and network peers ' +
      'identify the application, operating system, vendor, and/or version of the requesting user agent.',
    regex:
      // eslint-disable-next-line max-len
      ' (MSIE |Trident|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari(?!.+Edge)|(?!AppleWebKit.+)Chrome(?!.+Edge)|(?!AppleWebKit.+Chrome.+Safari.+)Edge|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?:|\\/)([\\d\\.apre]+)',
  },
  [DefaultDataSubCategoryType.Connection]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Connection,
    description: 'Generic connection information',
  },
  /** Contact */
  [DefaultDataSubCategoryType.Email]: {
    name: 'Email',
    category: DataCategoryType.Contact,
    description: 'Email address',
    regex: '[A-Z0-9a-z._%+-]+@[A-Z0-9a-z.-]+\\.[A-Za-z]{2,}',
  },
  [DefaultDataSubCategoryType.Phone]: {
    name: 'Phone',
    category: DataCategoryType.Contact,
    description: 'Phone number',
  },
  [DefaultDataSubCategoryType.Contact]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Contact,
    description: 'Generic contact information',
  },
  /** IP Address and Device IDs */
  [DefaultDataSubCategoryType.SerialNumber]: {
    name: 'Serial Number',
    category: DataCategoryType.Device,
    description: 'A unique device identifier',
  },
  [DefaultDataSubCategoryType.Device]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Device,
    description: 'Generic computer or device information',
  },
  /** Financial */
  [DefaultDataSubCategoryType.AccountNumber]: {
    name: 'Account Number',
    category: DataCategoryType.Financial,
    description: "An account number relating to an individual's finances",
  },
  [DefaultDataSubCategoryType.RoutingNumber]: {
    name: 'Routing Number',
    category: DataCategoryType.Financial,
    description: "A bank's routing number",
  },
  [DefaultDataSubCategoryType.CreditCardNumber]: {
    name: 'Credit Card Number',
    category: DataCategoryType.Financial,
    description: 'Credit card number',
  },
  [DefaultDataSubCategoryType.Income]: {
    name: 'Income',
    category: DataCategoryType.Financial,
    description: "Information about an individual's income",
  },
  [DefaultDataSubCategoryType.Tax]: {
    name: 'Tax Information',
    category: DataCategoryType.Financial,
    description: "Information about an individual's taxes",
  },
  [DefaultDataSubCategoryType.Financial]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Financial,
    description: 'Generic financial information',
  },
  /** Health */
  [DefaultDataSubCategoryType.Health]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Health,
    description: 'Generic health information',
  },
  /** Personal Identifiers */
  [DefaultDataSubCategoryType.Username]: {
    name: 'Username',
    category: DataCategoryType.Id,
    description: 'A username associated with an individual',
  },
  [DefaultDataSubCategoryType.Name]: {
    name: 'Name',
    category: DataCategoryType.Id,
    description: "A person's name",
  },
  [DefaultDataSubCategoryType.SocialSecurityNumber]: {
    name: 'Social Security Number',
    category: DataCategoryType.Id,
    description: "An individual's social security number",
    regex:
      // eslint-disable-next-line max-len
      '([12345789][0-9][0-9]|[0-9][12345789][0-9]|[0-9][0-9][12345789]|006|060|600|660|606|066)-([123456789][0-9]|[0-9][123456789])-([123456789][0-9][0-9][0-9]|[0-9][123456789][0-9][0-9]|[0-9][0-9][123456789][0-9]|[0-9][0-9][0-9][123456789])',
  },
  [DefaultDataSubCategoryType.DriversLicense]: {
    name: "Driver's License",
    category: DataCategoryType.Id,
    description: "An individual's Driver's License number",
  },
  [DefaultDataSubCategoryType.PassportNumber]: {
    name: 'Passport Number',
    category: DataCategoryType.Id,
    description: "An individual's passport number",
  },
  [DefaultDataSubCategoryType.BiometricIdentifier]: {
    name: 'Biometric Identifier',
    category: DataCategoryType.Id,
    description:
      "A digital representation of an individual's unique physical characteristics " +
      '(e.g. fingerprint, retina or iris image, etc.)',
  },
  [DefaultDataSubCategoryType.UserId]: {
    name: 'User ID',
    category: DataCategoryType.Id,
    description: 'An identifier for an individual',
  },
  [DefaultDataSubCategoryType.Id]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Id,
    description: 'Some other form of personal identifier',
  },
  /** Location */
  [DefaultDataSubCategoryType.Location]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Location,
    description: 'Generic location information',
  },
  /** Online activities */
  [DefaultDataSubCategoryType.DeclaredInterests]: {
    name: 'Declared Interests',
    category: DataCategoryType.OnlineActivity,
    description: "A individual's declared interests",
  },
  [DefaultDataSubCategoryType.InteractionEvents]: {
    name: 'Interaction Events',
    category: DataCategoryType.OnlineActivity,
    description:
      "A individual's interaction with a website (mouse position, mouse clicks, keypresses)",
  },
  [DefaultDataSubCategoryType.PageViews]: {
    name: 'Page views',
    category: DataCategoryType.OnlineActivity,
    description: 'The number of times an individual views a page',
  },
  [DefaultDataSubCategoryType.OnlineActivity]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.OnlineActivity,
    description: 'Generic user online activity',
  },
  /** Social Media */
  [DefaultDataSubCategoryType.ProfileURL]: {
    name: 'Profile URL',
    category: DataCategoryType.SocialMedia,
    description: "A link to an individual's social media profile",
  },
  [DefaultDataSubCategoryType.SocialMedia]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.SocialMedia,
    description: 'Generic social media information',
  },
  /** Survey */
  [DefaultDataSubCategoryType.Survey]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Survey,
    description: 'Generic survey data',
  },
  /** Tracking - Cookies and Tracking Elements */
  [DefaultDataSubCategoryType.Tracking]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Tracking,
    description: 'Generic tracking information',
  },
  /** User profile */
  [DefaultDataSubCategoryType.ProfilePictureURL]: {
    name: 'Profile Picture URL',
    category: DataCategoryType.UserProfile,
    description: "A link to an individual's profile picture",
  },
  [DefaultDataSubCategoryType.ProfilePictureImage]: {
    name: 'Profile Picture Image',
    category: DataCategoryType.UserProfile,
    description: "An individual's profile picture",
  },
  [DefaultDataSubCategoryType.UserPreferences]: {
    name: 'User Preferences',
    category: DataCategoryType.UserProfile,
    description: "The user's stated preferences in an app",
  },
  [DefaultDataSubCategoryType.UserProfile]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.UserProfile,
    description: 'Generic user profile information',
  },
  /** Demographic  */
  [DefaultDataSubCategoryType.RaceOrEthnicity]: {
    name: 'Race/Ethnicity',
    category: DataCategoryType.Demographic,
    description: "Information about an individual's racial or ethnic origin",
  },
  [DefaultDataSubCategoryType.Gender]: {
    name: 'Gender',
    category: DataCategoryType.Demographic,
    description: "An individual's gender",
  },
  [DefaultDataSubCategoryType.DateOfBirth]: {
    name: 'Date of Birth',
    category: DataCategoryType.Demographic,
    description: "An individual's date of birth",
  },
  [DefaultDataSubCategoryType.Region]: {
    name: 'Region',
    category: DataCategoryType.Demographic,
    description:
      "An individual's country, city, or other static location information",
  },
  [DefaultDataSubCategoryType.Industry]: {
    name: 'Industry',
    category: DataCategoryType.Demographic,
    description: 'The industry in which an individual works',
  },
  [DefaultDataSubCategoryType.SexualOrientation]: {
    name: 'Sexual Orientation',
    category: DataCategoryType.Demographic,
    description: "An individual's sexual orientation",
  },
  [DefaultDataSubCategoryType.UnionMembership]: {
    name: 'Union Membership',
    category: DataCategoryType.Demographic,
    description: "Information about an individual's union membership",
  },
  [DefaultDataSubCategoryType.Religion]: {
    name: 'Religion',
    category: DataCategoryType.Demographic,
    description: "Information about an individual's religious beliefs",
  },
  [DefaultDataSubCategoryType.PoliticalAffiliation]: {
    name: 'Political Affiliation',
    category: DataCategoryType.Demographic,
    description:
      "Information about an individual's political beliefs or party membership",
  },
  [DefaultDataSubCategoryType.Demographic]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.Demographic,
    description: 'Generic personal information',
  },
  [DefaultDataSubCategoryType.NotPersonalData]: {
    name: FALLBACK_CATEGORY_NAME,
    category: DataCategoryType.NotPersonalData,
    description:
      'Data that does not identify an individual and is not about an individual',
  },
};

/**
 * Sensitive personal information categories in the European Union, as defined by GDPR (as of March 2024).
 *
 * Processing of personal data revealing
 *
 * racial or
 * ethnic origin,
 * political opinions,
 * religious or
 * philosophical beliefs, or
 * trade union membership, and the processing of
 * genetic data,
 * biometric data for the purpose of uniquely identifying a natural person,
 * data concerning health or
 * data concerning a natural person’s sex life or
 * sexual orientation
 *
 * shall be prohibited
 *
 * @see https://gdpr-info.eu/art-9-gdpr/
 */
export const GDPR_SENSITIVE_CATEGORIES = [
  DefaultDataSubCategoryType.BiometricIdentifier,
  DefaultDataSubCategoryType.Health,
  DefaultDataSubCategoryType.RaceOrEthnicity,
  DefaultDataSubCategoryType.PoliticalAffiliation,
  DefaultDataSubCategoryType.Religion,
  DefaultDataSubCategoryType.UnionMembership,
  DefaultDataSubCategoryType.SexualOrientation,
];

/* eslint-disable max-len */
/**
 * Sensitive personal information categories in California, as defined by CCPA (as of March 2024).
 *
 * (ae) “Sensitive personal information” means:
 *   (1) Personal information that reveals:
 *     (A) A consumer’s social security, driver’s license, state identification card, or passport number.
 *     (B) A consumer’s account log-in, financial account, debit card, or credit card number in combination with any required security or access code, password, or credentials allowing access to an account.
 *     (C) A consumer’s precise geolocation.
 *     (D) A consumer’s racial or ethnic origin, citizenship or immigration status, religious or philosophical beliefs, or union membership.
 *     (E) The contents of a consumer’s mail, email, and text messages unless the business is the intended recipient of the communication.
 *     (F) A consumer’s genetic data.
 *   (2)
 *     (A) The processing of biometric information for the purpose of uniquely identifying a consumer.
 *     (B) Personal information collected and analyzed concerning a consumer’s health.
 *     (C) Personal information collected and analyzed concerning a consumer’s sex life or sexual orientation.
 * (3) Sensitive personal information that is “publicly available” pursuant to paragraph (2) of subdivision (v) shall not be considered sensitive personal information or personal information.
 *
 * @see https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=202320240AB947
 */
/* eslint-enable max-len */
export const CCPA_SENSITIVE_CATEGORIES = [
  ...GDPR_SENSITIVE_CATEGORIES,
  DefaultDataSubCategoryType.PassportNumber,
  DefaultDataSubCategoryType.SocialSecurityNumber,
  DefaultDataSubCategoryType.DriversLicense,
  DefaultDataSubCategoryType.CreditCardNumber,
  DefaultDataSubCategoryType.AccountNumber,
  DefaultDataSubCategoryType.Financial,
];

/** Rate limit types. */
export const RateLimitType = {
  PerSecond: 'PER_SECOND',
  PerTenSeconds: 'PER_TEN_SECONDS',
  PerMinute: 'PER_MINUTE',
  PerHour: 'PER_HOUR',
  PerDay: 'PER_DAY',
};

/** Override type */
export type RateLimitType = (typeof RateLimitType)[keyof typeof RateLimitType];

export const RATE_LIMIT_PRECEDENCE: Array<
  (typeof RateLimitType)[keyof typeof RateLimitType]
> = [
  RateLimitType.PerSecond,
  RateLimitType.PerTenSeconds,
  RateLimitType.PerMinute,
  RateLimitType.PerHour,
  RateLimitType.PerDay,
];

export const RATE_LIMIT_TYPE_TO_DEFAULT_WAIT_TIME_MS: Record<
  RateLimitType,
  number
> = {
  [RateLimitType.PerSecond]: ONE_SECOND,
  [RateLimitType.PerTenSeconds]: 10 * ONE_SECOND,
  [RateLimitType.PerMinute]: ONE_MINUTE,
  [RateLimitType.PerHour]: ONE_HOUR,
  [RateLimitType.PerDay]: ONE_DAY,
};

export const HIDDEN_HEADER_VALUE = '************';

/**
 * Code packages that support mobile consent SDK tagging
 */
export const MOBILE_SDK_CODE_PACKAGE_TYPES = [
  CodePackageType.Gradle,
  CodePackageType.CocoaPods,
];

/* eslint-enable max-lines */

import React from 'react';

import { SvgProps } from '../enums';

export const FlagCC: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54969"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54969)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#5EAA22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12 3.5L11.5 3.86603L11.567 3.25L11 3L11.567 2.75L11.5 2.13397L12 2.5L12.5 2.13397L12.433 2.75L13 3L12.433 3.25L12.5 3.86603L12 3.5Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M11.4814 6.83935L10.9814 7.20538L11.0484 6.58935L10.4814 6.33935L11.0484 6.08935L10.9814 5.47333L11.4814 5.83935L11.9814 5.47333L11.9145 6.08935L12.4814 6.33935L11.9145 6.58935L11.9814 7.20538L11.4814 6.83935Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13 9.5L12.5 9.86603L12.567 9.25L12 9L12.567 8.75L12.5 8.13397L13 8.5L13.5 8.13397L13.433 8.75L14 9L13.433 9.25L13.5 9.86603L13 9.5Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.5 4.75L13.25 4.93301L13.2835 4.625L13 4.5L13.2835 4.375L13.25 4.06699L13.5 4.25L13.75 4.06699L13.7165 4.375L14 4.5L13.7165 4.625L13.75 4.93301L13.5 4.75Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 7C4.88071 7 6 5.88071 6 4.5C6 3.11929 4.88071 2 3.5 2C2.11929 2 1 3.11929 1 4.5C1 5.88071 2.11929 7 3.5 7Z"
        fill="#FECA00"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.24472 5.99375L3.06531 5.86736C3.46218 5.34743 3.35724 4.62083 3.24472 4.14333L3.97472 3.97131C4.14231 4.68252 3.77026 5.30525 3.24472 5.99375Z"
        fill="#915E2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9.94887 7.47248C9.61978 8.03725 9.09681 8.29359 8.39679 8.29359C7.34922 8.29359 6.5 7.44436 6.5 6.39679C6.5 5.34922 7.34922 4.5 8.39679 4.5C9.19606 4.5 9.83378 4.77964 10.1264 5.5413C9.89475 5.31162 9.53627 5.15407 9 5.17404C8.17157 5.17404 7.63267 5.81943 7.63267 6.37171C7.63267 6.924 8.17157 7.67474 9 7.67474C9.41667 7.66851 9.72446 7.59232 9.94887 7.47248Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3.50001 4.36975C4.32844 4.36975 4.85651 4.14589 4.85651 3.86975C4.85651 3.59361 4.32844 3.36975 3.50001 3.36975C2.67158 3.36975 2.24219 3.59361 2.24219 3.86975C2.24219 4.14589 2.67158 4.36975 3.50001 4.36975Z"
        fill="#5EAA22"
      />
    </g>
  </svg>
);

/* eslint-disable max-lines */
import {
  ActionItemCode,
  ActionItemPriorityOverride,
  TranscendProduct,
} from '@transcend-io/privacy-types';

import { AttributeValue } from '@main/attribute-types';
import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { TeamPreview } from './teamPreview';
import { UserPreview } from './userPreview';

export const GlobalActionItemCollection = mkType({
  name: 'GlobalActionItemCollection',
  comment: 'A collection of action items',
  fields: {
    id: {
      comment: 'A unique identifier for the actionItem/s',
      modelName: 'any',
      type: 'id',
    },
    title: {
      comment: 'A unique identifier for the actionItem/s',
      type: 'string',
    },
    description: {
      comment: 'A unique identifier for the actionItem/s',
      type: 'string',
    },
    hidden: {
      comment: 'A unique identifier for the actionItem/s',
      type: 'boolean',
    },
    productLine: {
      comment: 'The product line that the collection relates to',
      type: { TranscendProduct },
    },
  },
});

/** Override type */
export type GlobalActionItemCollection = SchemaToType<
  typeof GlobalActionItemCollection
>;

// If you update these fields, make sure to update them on the frontend here:
// frontend-services/admin-dashboard/src/Auth/ActionItems/ActionItemsTable/columns.tsx
export const GlobalActionItemContext = mkType({
  name: 'GlobalActionItemContext',
  comment: 'Additional context needed to render a global action item',
  fields: {
    iconOverride: {
      comment:
        'The icon to use instead of the default icon for this action item code',
      type: 'string',
      optional: true,
    },
    requestId: {
      comment: 'The associated request id',
      type: 'id',
      modelName: 'request',
      optional: true,
    },
    dataSiloId: {
      comment: 'The associated data silo id',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    requestType: {
      comment: 'The type of an associated request',
      type: 'string',
      optional: true,
    },
    latestAirgapVersion: {
      comment: 'The latest available airgap version',
      type: 'string',
      optional: true,
    },
    parentTitle: {
      comment: 'The title of the logical parent of this object',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type GlobalActionItemContext = SchemaToType<
  typeof GlobalActionItemContext
>;

export const GlobalActionItemInterface = mkInterface({
  name: 'GlobalActionItemInterface',
  comment: 'Interface for the new (global) action item',
  fields: {
    ids: {
      comment: 'A unique identifier for the actionItem/s',
      modelName: 'actionItem',
      type: 'id',
      list: true,
    },
    count: {
      comment:
        'The number of action items of this type. Will be 1 for non-rollup',
      type: 'int',
    },
    teams: {
      comment: 'The team/s that the actionItem item is assigned to',
      type: TeamPreview,
      list: true,
    },
    users: {
      comment: 'The user/s that the actionItem item is assigned to',
      type: UserPreview,
      list: true,
    },
    customerExperienceActionItemIds: {
      comment:
        'The id of the customer experience action item, used for syncing of onboarding checklists',
      type: 'string',
      list: true,
    },
    dueDate: {
      comment: 'The due date of the action item',
      type: 'Date',
      optional: true,
    },
    priority: {
      comment: 'The priority of the action item',
      optional: true,
      type: { ActionItemPriorityOverride },
    },
    titles: {
      comment:
        'The title/s for the action item. Includes things like the Data Silo name',
      type: 'string',
      optional: true,
      list: true,
    },
    additionalContexts: {
      comment: 'List of extra contexts needed to render the actionItems',
      type: GlobalActionItemContext,
      list: true,
      optional: true,
    },
    resolved: {
      comment: 'Is the action item resolved?',
      type: 'boolean',
    },
    sourceIds: {
      comment: 'The base model ids',
      type: 'string',
      list: true,
    },
    notes: {
      comment: 'The user specified notes about the action item',
      type: 'string',
      list: true,
    },
    links: {
      comment: 'The user specified link for the action item',
      type: 'string',
      list: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this action item',
      type: AttributeValue,
      list: true,
      doubleList: true,
    },
  },
});

/** Override type */
export type GlobalActionItemInterface = SchemaToType<
  typeof GlobalActionItemInterface
>;

export const GlobalActionItem = mkType({
  name: 'GlobalActionItem',
  comment: 'A new (global) action item',
  interfaces: [GlobalActionItemInterface],
  fields: {
    ...GlobalActionItemInterface.fields,
    type: {
      comment: 'The action item type',
      type: { ActionItemCode },
    },
    collections: {
      comment:
        'The collections that the action item belongs to - these are the onboarding checklist sections',
      list: true,
      type: GlobalActionItemCollection,
    },
  },
});

/** Override type */
export type GlobalActionItem = SchemaToType<typeof GlobalActionItem>;

export const GlobalActionItemFiltersInput = mkInput({
  name: 'GlobalActionItemFiltersInput',
  comment: 'Filter action items',
  fields: {
    priority: {
      comment: 'The priority of the action item',
      optional: true,
      type: { ActionItemPriorityOverride },
      list: true,
    },
    type: {
      comment: 'The type of action item',
      optional: true,
      type: { ActionItemCode },
      list: true,
    },
    assigneeUserIds: {
      comment: 'The ids of assigned users',
      optional: true,
      type: 'string',
      list: true,
    },
    assigneeTeamIds: {
      comment: 'The ids of assigned teams',
      optional: true,
      type: 'string',
      list: true,
    },
    startDueDate: {
      comment: 'Show only action items due after this date.',
      optional: true,
      type: 'Date',
    },
    endDueDate: {
      comment: 'Show only action items due before this date.',
      optional: true,
      type: 'Date',
    },
    resolved: {
      comment: 'Is the action item marked as resolved',
      optional: true,
      type: 'boolean',
    },
    collectionId: {
      comment: 'Filter to only items in a specific collection',
      optional: true,
      type: 'id',
      modelName: 'actionItemCollection',
    },
    attributeValueIds: {
      comment: 'Filter by the attribute values used to label the action item',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    dataSiloId: {
      comment: 'Show only action items for a specific data silo',
      type: 'id',
      modelName: 'dataSilo',
      optional: true,
    },
    requestId: {
      comment: 'Show only action items for a specific request',
      type: 'id',
      modelName: 'request',
      optional: true,
    },
  },
});

/** Override type */
export type GlobalActionItemFiltersInput = SchemaToType<
  typeof GlobalActionItemFiltersInput
>;

export const GlobalActionItemCollectionFiltersInput = mkInput({
  name: 'GlobalActionItemCollectionFiltersInput',
  comment: 'Filter action item collections',
  fields: {
    productLine: {
      comment: 'The current location of the action item collection',
      type: { TranscendProduct },
      optional: true,
    },
  },
});

/** Override type */
export type GlobalActionItemCollectionFiltersInput = SchemaToType<
  typeof GlobalActionItemCollectionFiltersInput
>;

export const CreateActionItemCollectionInput = mkInput({
  name: 'CreateActionItemCollectionInput',
  comment: 'Input needed to create or update action item collections',
  fields: {
    title: {
      comment: 'The title of the action item collection',
      type: 'string',
    },
    description: {
      comment: 'The description of the action item collection',
      type: 'string',
    },
    hidden: {
      comment: 'The hidden state of the action item collection',
      type: 'boolean',
    },
    productLine: {
      comment: 'The product line that the action item collection relates to',
      type: { TranscendProduct },
    },
  },
});

/** Override type */
export type CreateActionItemCollectionInput = SchemaToType<
  typeof CreateActionItemCollectionInput
>;

export const UpdateActionItemCollectionInput = mkInput({
  name: 'UpdateActionItemCollectionInput',
  comment: 'Input needed to create or update action item collections',
  fields: {
    title: {
      ...CreateActionItemCollectionInput.fields.title,
      optional: true,
    },
    description: {
      ...CreateActionItemCollectionInput.fields.description,
      optional: true,
    },
    hidden: {
      ...CreateActionItemCollectionInput.fields.hidden,
      optional: true,
    },
    productLine: {
      ...CreateActionItemCollectionInput.fields.productLine,
      optional: true,
    },
    id: {
      comment:
        'The list of locations where the action item collection is visible',
      type: 'id',
      modelName: 'actionItemCollection',
    },
  },
});

/** Override type */
export type UpdateActionItemCollectionInput = SchemaToType<
  typeof UpdateActionItemCollectionInput
>;
/* eslint-enable max-lines */

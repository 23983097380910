import {
  defineMessages,
  LanguageKey,
} from '@transcend-io/internationalization';

export const localeDescriptorMessages = defineMessages<LanguageKey>(
  'internationalization.src.localeDescriptor',
  {
    [LanguageKey.En]: {
      defaultMessage: 'English',
    },
    [LanguageKey.Ar]: {
      defaultMessage: 'Arabic',
    },
    [LanguageKey.Fr]: {
      defaultMessage: 'French',
    },
    [LanguageKey.Es]: {
      defaultMessage: 'Spanish',
    },
    [LanguageKey.De]: {
      defaultMessage: 'German',
    },
    [LanguageKey.It]: {
      defaultMessage: 'Italian',
    },
    [LanguageKey.Ja]: {
      defaultMessage: 'Japanese',
    },
    [LanguageKey.Ru]: {
      defaultMessage: 'Russian',
    },

    [LanguageKey.ArAe]: {
      defaultMessage: 'Arabic UAE',
    },
    [LanguageKey.FrFr]: {
      defaultMessage: 'French',
    },
    [LanguageKey.DeDe]: {
      defaultMessage: 'German (Germany)',
    },
    [LanguageKey.ItIt]: {
      defaultMessage: 'Italian',
    },
    [LanguageKey.AfZz]: {
      defaultMessage: 'Afrikaans',
    },
    [LanguageKey.BgBg]: {
      defaultMessage: 'Bulgarian',
    },
    [LanguageKey.ZhCn]: {
      defaultMessage: 'Chinese (Simplified)',
    },
    [LanguageKey.HrHr]: {
      defaultMessage: 'Croatian',
    },
    [LanguageKey.CsCz]: {
      defaultMessage: 'Czech',
    },
    [LanguageKey.DaDk]: {
      defaultMessage: 'Danish',
    },
    [LanguageKey.EnGb]: {
      defaultMessage: 'English (United Kingdom)',
    },
    [LanguageKey.EnCa]: {
      defaultMessage: 'English (Canada)',
    },
    [LanguageKey.EnAe]: {
      defaultMessage: 'English (UAE)',
    },
    [LanguageKey.FiFi]: {
      defaultMessage: 'Finnish',
    },
    [LanguageKey.ElGr]: {
      defaultMessage: 'Greek',
    },
    [LanguageKey.HiIn]: {
      defaultMessage: 'Hindi',
    },
    [LanguageKey.HuHu]: {
      defaultMessage: 'Hungarian',
    },
    [LanguageKey.IdId]: {
      defaultMessage: 'Indonesian',
    },
    [LanguageKey.JaJp]: {
      defaultMessage: 'Japanese',
    },
    [LanguageKey.KoKr]: {
      defaultMessage: 'Korean',
    },
    [LanguageKey.LtLt]: {
      defaultMessage: 'Lithuanian',
    },
    [LanguageKey.MsMy]: {
      defaultMessage: 'Malay',
    },
    [LanguageKey.MrIn]: {
      defaultMessage: 'Marathi',
    },
    [LanguageKey.NbNi]: {
      defaultMessage: 'Norwegian Bokmål',
    },
    [LanguageKey.PlPl]: {
      defaultMessage: 'Polish',
    },
    [LanguageKey.PtBr]: {
      defaultMessage: 'Portuguese (Brazil)',
    },
    [LanguageKey.PtPt]: {
      defaultMessage: 'Portuguese (Portugal)',
    },
    [LanguageKey.RoRo]: {
      defaultMessage: 'Romanian',
    },
    [LanguageKey.RuRu]: {
      defaultMessage: 'Russian',
    },
    [LanguageKey.SrLatnRs]: {
      defaultMessage: 'Latn-Serbian (Latin)',
    },
    [LanguageKey.SvSe]: {
      defaultMessage: 'Swedish',
    },
    [LanguageKey.TaIn]: {
      defaultMessage: 'Tamil',
    },
    [LanguageKey.ThTh]: {
      defaultMessage: 'Thai',
    },
    [LanguageKey.TrTr]: {
      defaultMessage: 'Turkish',
    },
    [LanguageKey.UkUa]: {
      defaultMessage: 'Ukrainian',
    },
    [LanguageKey.ViVn]: {
      defaultMessage: 'Vietnamese',
    },
    [LanguageKey.ZuZa]: {
      defaultMessage: 'Zulu',
    },
    [LanguageKey.EnUS]: {
      defaultMessage: 'English (United States)',
    },
    [LanguageKey.EnAu]: {
      defaultMessage: 'Australia',
    },
    [LanguageKey.FrBe]: {
      defaultMessage: 'Belgium',
    },
    [LanguageKey.FrCa]: {
      defaultMessage: 'French (Canada)',
    },
    [LanguageKey.EnIe]: {
      defaultMessage: 'Ireland',
    },
    [LanguageKey.NlNl]: {
      defaultMessage: 'Dutch',
    },
    [LanguageKey.NlBe]: {
      defaultMessage: 'Dutch (Belgium)',
    },
    [LanguageKey.EsEs]: {
      defaultMessage: 'Spanish (Castilian)',
    },
    [LanguageKey.Es419]: {
      defaultMessage: 'Spanish (Latin America & Caribbean)',
    },
    [LanguageKey.ZhHk]: {
      defaultMessage: 'Chinese (Traditional)',
    },
    [LanguageKey.DeAt]: {
      defaultMessage: 'Austrian German',
    },
    [LanguageKey.DeCh]: {
      defaultMessage: 'Swiss German',
    },
    [LanguageKey.ItCh]: {
      defaultMessage: 'Swiss Italian',
    },
    [LanguageKey.FrCh]: {
      defaultMessage: 'French (Switzerland)',
    },
    [LanguageKey.HeIl]: {
      defaultMessage: 'Hebrew (Israel)',
    },
    [LanguageKey.EnNz]: {
      defaultMessage: 'English (New Zealand)',
    },
    [LanguageKey.EtEe]: {
      defaultMessage: 'Estonian (Estonia)',
    },
    [LanguageKey.IsIs]: {
      defaultMessage: 'Icelandic (Iceland)',
    },
    [LanguageKey.LvLv]: {
      defaultMessage: 'Latvian (Latvia)',
    },
    [LanguageKey.MtMt]: {
      defaultMessage: 'Maltese (Malta)',
    },
    [LanguageKey.SkSk]: {
      defaultMessage: 'Slovak (Slovakia)',
    },
    [LanguageKey.SlSl]: {
      defaultMessage: 'Slovenian (Slovenia)',
    },
  },
);

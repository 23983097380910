import { mkInput, SchemaToType } from '@main/schema-utils';

export const PossibleDataSiloDependencyFilterInput = mkInput({
  name: 'PossibleDataSiloDependencyFilterInput',
  comment: 'Input for filtering possible dependencies of a data silo',
  fields: {
    dataSiloId: {
      comment: 'The id of the data silo to search for dependencies of',
      modelName: 'dataSilo',
      type: 'id',
    },
    text: {
      comment: 'Filter by text (title of data silo)',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type PossibleDataSiloDependencyFilterInput = SchemaToType<
  typeof PossibleDataSiloDependencyFilterInput
>;

import { makeEnum } from '@transcend-io/type-utils';

/**
 * The tiers that differentiate what an organization is allowed to do
 *
 * @deprecated https://transcend.height.app/T-7884 - use `subscriptions` table
 */
export const OrganizationTier = makeEnum({
  /** The organization is not paying (free tier) */
  Free: 'FREE',
  /** The organization is paying for our services */
  Paid: 'PAID',
  /** The organization is a part of the partnerships referral program */
  Partnerships: 'PARTNERSHIPS',
  /** A production internal account such as e-shop-it */
  Internal: 'INTERNAL',
});

/** Override type */
export type OrganizationTier =
  (typeof OrganizationTier)[keyof typeof OrganizationTier];

/**
 * The status of a Test Email request step
 */
export const TestEmailFailurePoint = makeEnum({
  InitialBackend: 'INITIAL_BACKEND',
  Sombra: 'SOMBRA',
  SombraInternal: 'SOMBRA_INTERNAL',
  EmailSender: 'EMAIL_SENDER',
  FinalBackend: 'FINAL_BACKEND',
});

/** Type override */
export type TestEmailFailurePoint =
  (typeof TestEmailFailurePoint)[keyof typeof TestEmailFailurePoint];

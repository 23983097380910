import { defineMessages } from '@transcend-io/internationalization';

export const avatarMessages = defineMessages('core-ui.Avatar.avatar', {
  defaultIconAlt: {
    defaultMessage: 'Your logo icon',
  },
  iconAlt: {
    defaultMessage: '{name} logo icon',
  },
});

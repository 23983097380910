import React from 'react';

import { SvgProps } from '../enums';

export const FlagSZ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55061"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55061)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#3D58DB"
      />
      <path
        d="M0 2.5H-0.5V3V9V9.5H0H16H16.5V9V3V2.5H16H0Z"
        fill="#C51918"
        stroke="#FFD018"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.57466 4.66556C3.51469 4.66556 3.46606 4.52996 3.46606 4.36269C3.46606 4.19542 3.51469 4.05981 3.57466 4.05981H12.28C12.34 4.05981 12.3886 4.19542 12.3886 4.36269C12.3886 4.52996 12.34 4.66556 12.28 4.66556H3.57466Z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0476 4.30114L12.5531 3.65625L13.4476 4.30114L12.5531 4.963L12.0476 4.30114Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.57466 6.66556C3.51469 6.66556 3.46606 6.52996 3.46606 6.36269C3.46606 6.19542 3.51469 6.05981 3.57466 6.05981H12.28C12.34 6.05981 12.3886 6.19542 12.3886 6.36269C12.3886 6.52996 12.34 6.66556 12.28 6.66556H3.57466Z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0476 6.30114L12.5531 5.65625L13.4476 6.30114L12.5531 6.963L12.0476 6.30114Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M2.17271 7.91093C2.09229 7.91093 2.0271 7.75696 2.0271 7.56704C2.0271 7.37711 2.09229 7.22314 2.17271 7.22314H13.8448C13.9252 7.22314 13.9904 7.37711 13.9904 7.56704C13.9904 7.75696 13.9252 7.91093 13.8448 7.91093H2.17271Z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.40869 6.02639C4.40869 6.02639 5.82936 3.03638 7.86132 3.03638C9.89328 3.03638 11.3617 6.02639 11.3617 6.02639C11.3617 6.02639 9.56865 8.97711 7.88521 8.97711C6.20177 8.97711 4.40869 6.02639 4.40869 6.02639Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55061"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="3"
        width="8"
        height="6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.40869 6.02639C4.40869 6.02639 5.82936 3.03638 7.86132 3.03638C9.89328 3.03638 11.3617 6.02639 11.3617 6.02639C11.3617 6.02639 9.56865 8.97711 7.88521 8.97711C6.20177 8.97711 4.40869 6.02639 4.40869 6.02639Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55061)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.96215 2.84009C7.96215 2.84009 7.49852 3.40319 7.65306 3.94015C7.80761 4.47711 8.16768 4.42039 8.16768 4.89327C8.16768 5.36615 8.00134 5.84843 8.16768 5.84843C8.33402 5.84843 8.39166 6.17475 8.23964 6.39598C8.08761 6.61721 7.95314 6.62404 7.96215 6.98409C7.97116 7.34414 8.75817 7.69759 8.37692 7.69759C7.99567 7.69759 7.49319 8.06763 7.65306 8.06763C7.81294 8.06763 8.64937 8.35594 8.64937 8.73432C8.64937 9.1127 8.64937 9.41551 8.64937 9.41551H4.28564V2.84009H7.96215Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.90958 4.96155H5.69238V5.84616H5.90958V4.96155ZM6.5503 4.96155H6.33311V5.84616H6.5503V4.96155ZM6.01274 4.96155H6.22994V5.84616H6.01274V4.96155ZM6.87066 4.96155H6.65347V5.84616H6.87066V4.96155ZM6.97383 4.96155H7.19103V5.84616H6.97383V4.96155ZM7.51139 4.96155H7.29419V5.84616H7.51139V4.96155ZM5.69238 6.23078H5.90958V7.11539H5.69238V6.23078ZM6.5503 6.23078H6.33311V7.11539H6.5503V6.23078ZM6.01274 6.23078H6.22994V7.11539H6.01274V6.23078ZM6.87066 6.23078H6.65347V7.11539H6.87066V6.23078ZM6.97383 6.23078H7.19103V7.11539H6.97383V6.23078ZM7.51139 6.23078H7.29419V7.11539H7.51139V6.23078Z"
          fill="#F7FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.48868 4.96155H8.27148V5.84616H8.48868V4.96155ZM9.1294 4.96155H8.91221V5.84616H9.1294V4.96155ZM8.59185 4.96155H8.80904V5.84616H8.59185V4.96155ZM9.44976 4.96155H9.23257V5.84616H9.44976V4.96155ZM9.55293 4.96155H9.77013V5.84616H9.55293V4.96155ZM10.0905 4.96155H9.87329V5.84616H10.0905V4.96155ZM8.27148 6.23078H8.48868V7.11539H8.27148V6.23078ZM9.1294 6.23078H8.91221V7.11539H9.1294V6.23078ZM8.59185 6.23078H8.80904V7.11539H8.59185V6.23078ZM9.44976 6.23078H9.23257V7.11539H9.44976V6.23078ZM9.55293 6.23078H9.77013V7.11539H9.55293V6.23078ZM10.0905 6.23078H9.87329V7.11539H10.0905V6.23078Z"
          fill="#272727"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6.61582 3.53579C6.31157 3.63005 6.17234 4.42796 6.20635 4.62082C6.24036 4.81368 6.34358 4.59662 6.34358 4.59662C6.34358 4.59662 6.55411 5.05417 6.57581 5.17727C6.59752 5.30037 6.70839 4.99119 6.70839 4.99119C6.70839 4.99119 7.07354 5.24275 7.12297 5.52306C7.1724 5.80336 7.24674 5.2023 7.24674 5.2023L7.32945 5.18771L7.27712 4.89091L7.43487 5.0258L7.3862 4.74978C7.3862 4.74978 7.505 4.90504 7.47487 4.73415C7.44473 4.56326 7.42791 4.46785 7.42791 4.46785L7.38673 4.23427L7.54287 4.20674C7.54287 4.20674 6.92007 3.44152 6.61582 3.53579Z"
          fill="#3D58DB"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.0442 6.84797C12.7282 6.88796 12.4525 7.64957 12.4525 7.84541C12.4525 8.04125 12.5919 7.84541 12.5919 7.84541C12.5919 7.84541 12.7197 8.33257 12.7197 8.45757C12.7197 8.58257 12.8826 8.29733 12.8826 8.29733C12.8826 8.29733 13.1985 8.60848 13.1985 8.89311C13.1985 9.17774 13.3761 8.59872 13.3761 8.59872H13.4601V8.29733L13.592 8.45757V8.17729C13.592 8.17729 13.6821 8.35082 13.6821 8.17729C13.6821 8.00376 13.6821 7.90689 13.6821 7.90689V7.66971H13.8406C13.8406 7.66971 13.3602 6.80797 13.0442 6.84797Z"
        fill="#3D58DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.7964 6.73255C3.11239 6.77255 3.38806 7.53416 3.38806 7.72999C3.38806 7.92583 3.24871 7.72999 3.24871 7.72999C3.24871 7.72999 3.12084 8.21715 3.12084 8.34215C3.12084 8.46715 2.95797 8.18192 2.95797 8.18192C2.95797 8.18192 2.64204 8.49306 2.64204 8.7777C2.64204 9.06233 2.46445 8.4833 2.46445 8.4833H2.38046V8.18192L2.24853 8.34215V8.06188C2.24853 8.06188 2.15849 8.2354 2.15849 8.06188C2.15849 7.88835 2.15849 7.79147 2.15849 7.79147V7.55429H1.99995C1.99995 7.55429 2.4804 6.69255 2.7964 6.73255Z"
        fill="#3D58DB"
      />
    </g>
  </svg>
);

import owasp from 'owasp-password-strength-test';

import { MAXIMUM_PASSWORD_LENGTH, MINIMUM_PASSWORD_LENGTH } from './constants';

// Setup our configuration for password complexity requirements. This can be used
// both on the frontend and the backend
owasp.config({
  // If a password is 20 chars or longer, do not subject it to some of the other requirements
  allowPassphrases: true,
  minPhraseLength: 20,

  maxLength: MAXIMUM_PASSWORD_LENGTH,
  minLength: MINIMUM_PASSWORD_LENGTH,
  minOptionalTestsToPass: 3,
});

/**
 * Checks the strength of a password
 *
 * @param password - The password to check against
 * @returns a summary of the password complexity
 */
export function checkPasswordStrength(password: string): owasp.TestResult {
  return owasp.test(password);
}

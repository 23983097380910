import React from 'react';

import { SvgProps } from '../enums';

export const FlagBO: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54961"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54961)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#FECA00"
      />
      <mask
        id="mask1_270_54961"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54961)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#DB501C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#5EAA22"
        />
        <path
          // eslint-disable-next-line max-len
          d="M5.8237 5.63C5.8237 5.63 5.63776 7.53456 7.33052 7.53456C9.02327 7.53456 8.59238 7.53456 8.59238 7.53456C8.59238 7.53456 10.3731 7.41839 10.1663 5.63"
          stroke="#DB501C"
          strokeWidth="0.75"
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.125 5.90002C9.125 6.52134 8.62132 7.02502 8 7.02502C7.37868 7.02502 6.875 6.52134 6.875 5.90002C6.875 5.2787 7.37868 4.77502 8 4.77502C8.62132 4.77502 9.125 5.2787 9.125 5.90002Z"
          fill="#FECA00"
          stroke="#68B9E8"
          strokeWidth="0.75"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8 6C8.27614 6 8.5 5.77614 8.5 5.5C8.5 5.22386 8.27614 5 8 5C7.72386 5 7.5 5.22386 7.5 5.5C7.5 5.77614 7.72386 6 8 6Z"
          fill="#DB501C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.05001 6.8C8.51945 6.8 8.90001 6.62091 8.90001 6.4C8.90001 6.17909 8.51945 6 8.05001 6C7.58057 6 7.20001 6.17909 7.20001 6.4C7.20001 6.62091 7.58057 6.8 8.05001 6.8Z"
          fill="#5EAA22"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.05004 5.00001C8.71278 5.00001 9.25004 4.82093 9.25004 4.60001C9.25004 4.3791 8.71278 4.20001 8.05004 4.20001C7.38729 4.20001 6.85004 4.3791 6.85004 4.60001C6.85004 4.82093 7.38729 5.00001 8.05004 5.00001Z"
          fill="#674F28"
        />
      </g>
    </g>
  </svg>
);

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const XBubble: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.36321 14.4896C3.63669 13.4474 2.55812 11.5992 2.5 9.58337C2.5 6.13158 5.85785 3.33337 10 3.33337C14.1421 3.33337 17.5 6.13158 17.5 9.58337C17.5 13.0352 14.1421 15.8334 10 15.8334C9.41864 15.8319 8.83882 15.7736 8.26879 15.6593C7.47829 16.2515 6.59993 16.7162 5.66564 17.0366C5.323 17.146 4.94887 17.0222 4.73909 16.7301C4.5293 16.4379 4.53164 16.0438 4.74487 15.7541C5.02102 15.3706 5.23009 14.9431 5.36321 14.4896Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 8.125L11.25 10.625M8.75 10.625L11.25 8.125"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
/* eslint-enable max-len */

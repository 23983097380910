import { getEntries, Gql } from '@transcend-io/type-utils';

import { isSchema } from './typeGuards';
import type { AnySchema, SchemaToType } from './types';

/**
 * Convert a schema definition into a gql fragment
 *
 * @param schema - The schema definition to convert
 * @param includeTypeName - Whether to explicitly ask for the __typename field in the response
 * @returns The frontend gql fragment to request
 */
export function mkGql<TSchema extends AnySchema>(
  schema: TSchema,
  includeTypeName?: boolean,
): Gql<SchemaToType<TSchema>> {
  return getEntries(schema.fields)
    .filter(([, { deprecated }]) => !deprecated)
    .map(([fieldName, { type }]) => {
      // Determine the GraphQLType
      const nonFuncType = typeof type === 'function' ? type() : type;

      // Catch recursion errors
      if (!nonFuncType) {
        throw new Error(
          `Failed to get type for field: "${schema.name}.${fieldName}"`,
        );
      }
      return `${fieldName}${
        isSchema(nonFuncType)
          ? ` {\n ${includeTypeName ? '__typename\n' : ''} ${mkGql(
              nonFuncType,
              includeTypeName,
            )}\n }`
          : ''
      }`;
    })
    .join('\n') as Gql<SchemaToType<TSchema>>;
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const LinesTriple: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 5 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" width="1" height="6" rx="0.5" fill={color} />
    <rect x="2" width="1" height="6" rx="0.5" fill={color} />
    <rect width="1" height="6" rx="0.5" fill={color} />
  </svg>
);

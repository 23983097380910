import { ContextType, useCallback, useContext, useEffect } from 'react';
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';

// TODO: https://github.com/remix-run/react-router/issues/8139 - remove this file

/**
 * Blocker
 */
type Blocker = any;

/**
 * Transition
 */
type Transition = any;

interface Navigator extends BaseNavigator {
  /** Block */
  block: any;
}

/**
 * Navigator with block
 */
type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  /** Navigator */
  navigator: Navigator;
};

/**
 * Use blocker
 *
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(blocker: Blocker, when = true): void {
  const { navigator } = useContext(
    NavigationContext,
  ) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) {
      return undefined;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        /**
         *
         */
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * Message to display when navigation is blocked
 */
export type PromptMessage =
  | string
  | ((
      location: Transition['location'],
      action: Transition['action'],
    ) => string | boolean);

/**
 * Use prompt
 *
 * @param message - Message
 * @param when - When to show
 * @param onConfirm - Callback when the user confirms the decision to navigate away
 */
export function usePrompt(
  message: PromptMessage,
  when = true,
  onConfirm: (() => void) | undefined = undefined,
): void {
  const blocker = useCallback(
    (tx: Transition) => {
      let response;
      if (typeof message === 'function') {
        response = message(tx.location, tx.action);
        if (typeof response === 'string') {
          response = window.confirm(response);
          if (response) {
            onConfirm?.();
          }
        }
      } else {
        response = window.confirm(message);
        if (response) {
          onConfirm?.();
        }
      }

      if (response) {
        tx.retry();
      }
    },
    [message],
  );
  useBlocker(blocker, when);
}

export const Prompt = ({
  message,
  when,
  onConfirm,
}: {
  /** Message to prompt */
  message: PromptMessage;
  /** When to prompt */
  when: boolean;
  /** Callback when the user confirms the decision to navigate away  */
  onConfirm?: () => void;
}): null => {
  usePrompt(message, when, onConfirm);

  return null;
};

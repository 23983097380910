/**
 * The identity verification status (mirrors SES verification status)
 * see https://docs.aws.amazon.com/ses/latest/APIReference/API_GetIdentityVerificationAttributes.html
 */
export enum EmailDomainVerificationStatus {
  Success = 'Success',
  Failed = 'Failed',
  Pending = 'Pending',
  TemporaryFailure = 'TemporaryFailure',
  NotStarted = 'NotStarted',
}

import { defineMessages } from '@transcend-io/internationalization';

export const selectEnrichersMessages = defineMessages(
  'core-ui.SelectEnrichers.selectEnrichers',
  {
    enricherSelect: {
      defaultMessage: 'Select enrichers...',
    },
    enricherSelectNoMatches: {
      defaultMessage: 'No possible enricher found',
    },
    enricherSelectLoading: {
      defaultMessage: 'Loading possible enrichers',
    },
  },
);

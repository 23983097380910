import * as t from 'io-ts';
import { date, DateC } from 'io-ts-types/lib/date';

import { apply, SubNotType, valuesOf } from '@transcend-io/type-utils';

import { SchemaFieldTypes } from './enums';
import { isSchema } from './typeGuards';
import type { Schema, SchemaField, SchemaFields, SchemaType } from './types';

const FIELD_MAP = {
  [SchemaFieldTypes.boolean]: t.boolean,
  [SchemaFieldTypes.int]: t.number,
  [SchemaFieldTypes.float]: t.number,
  [SchemaFieldTypes.string]: t.string,
  [SchemaFieldTypes.Date]: date,
  id: t.string,
};

/**
 * Mapping from SchemaFieldType -> typescript type
 */
interface SchemaFieldToCodec {
  /** String */
  string: t.StringC;
  /** Floating point */
  float: t.NumberC;
  /** Integer */
  int: t.NumberC;
  /** Boolean */
  boolean: t.BooleanC;
  /** Date */
  Date: DateC;
}

/**
 * Convert a schema field to codec
 */
type FieldToCodec<TFieldType extends SchemaField['type']> =
  TFieldType extends keyof SchemaFieldToCodec
    ? SchemaFieldToCodec[TFieldType]
    : t.Any;

/**
 * Convert field definitions to a codec
 */
type FieldsToCodec<TFields extends SchemaFields> = t.IntersectionC<
  [
    t.TypeC<
      SubNotType<
        {
          [k in keyof TFields]: TFields[k]['optional'] extends true
            ? never
            : TFields[k]['list'] extends true
              ? t.ArrayC<FieldToCodec<TFields[k]['type']>>
              : FieldToCodec<TFields[k]['type']>;
        },
        never
      >
    >,
    t.PartialC<{
      [k in keyof TFields]: TFields[k]['list'] extends true
        ? t.ArrayC<FieldToCodec<TFields[k]['type']>>
        : FieldToCodec<TFields[k]['type']>;
    }>,
  ]
>;

/**
 * Convert a schema to a codec type
 */
type SchemaToCodec<
  TFields extends SchemaFields,
  TIsOptional extends boolean,
  TIsList extends boolean,
> = TIsOptional extends true
  ? t.UnionC<
      [
        TIsList extends true
          ? t.ArrayC<FieldsToCodec<TFields>>
          : FieldsToCodec<TFields>,
        t.UndefinedC,
      ]
    >
  : TIsList extends true
    ? t.ArrayC<FieldsToCodec<TFields>>
    : FieldsToCodec<TFields>;

/**
 * Convert a schema definition to an io-ts codec
 *
 * @param schema - The schema
 * @returns The io-ts codec
 */
export function schemaToCodec<
  TFields extends SchemaFields,
  TIsOptional extends boolean,
  TIsList extends boolean,
>(
  schema: Schema<string, TFields, SchemaType, TIsOptional, TIsList>,
): SchemaToCodec<TFields, TIsOptional, TIsList> {
  // Construct the codec
  let codec: t.Any = t.type(
    apply(schema.fields, (field) => {
      // standard schema type
      if (typeof field.type === 'string') {
        let fieldCodec: t.Any = FIELD_MAP[field.type];
        // If a list, wrap in an array
        if (field.list) {
          fieldCodec = t.array(fieldCodec);
        }

        // If optional, allow undefined
        if (field.optional) {
          fieldCodec = t.union([fieldCodec, t.undefined]) as any; // difficult to type
        }

        return fieldCodec;
      }

      // another schema
      if (isSchema(field.type)) {
        let fieldCodec: t.Any = schemaToCodec(field.type);

        if (field.list) {
          fieldCodec = t.array(fieldCodec);
        }

        if (field.optional) {
          fieldCodec = t.union([fieldCodec, t.undefined]) as any;
        }
        return fieldCodec;
      }

      // function schema
      if (typeof field.type === 'function') {
        return schemaToCodec(field.type() as any);
      }

      // enum
      let fieldCodec: t.Any = valuesOf(Object.values(field.type)[0]);

      if (field.list) {
        fieldCodec = t.array(fieldCodec);
      }

      if (field.optional) {
        fieldCodec = t.union([fieldCodec, t.undefined]);
      }
      return fieldCodec;
    }),
  );

  // If a list, wrap in an array
  if (schema.isList) {
    codec = t.array(codec);
  }

  // If optional, allow undefined
  if (schema.isOptional) {
    return t.union([codec, t.undefined]) as any; // difficult to type
  }

  // Return codec, required type
  return codec as any; // difficult to type
}

import { mkType, SchemaToType } from '@main/schema-utils';

import { WiredIdentifier } from './identifier';

export const EnricherCrossReferenceIdentifier = mkType({
  name: 'EnricherCrossReferenceIdentifier',
  comment: 'The identifier/cross reference details for an enricher',
  fields: {
    id: {
      comment: 'The unique id of the enricher',
      modelName: 'EnricherCrossReferenceIdentifier',
      type: 'id',
    },
    crossReferenceKey: {
      type: 'string',
      comment: 'The key to cross reference the identifier against',
    },
    identifier: {
      type: WiredIdentifier,
      comment: 'The identifier to cross reference',
    },
  },
});

/** Override type */
export type EnricherCrossReferenceIdentifier = SchemaToType<
  typeof EnricherCrossReferenceIdentifier
>;

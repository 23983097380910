import { transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

/**
 * RequestCanceled icon
 */
export const RequestCanceled: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="RequestStatusIcon"
        stroke={theme.colors.secondary}
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M3 5H21V19H3z"
          fill={transparentize(0.7, theme.colors.secondary)}
          fillRule="nonzero"
        />
        <path
          d="M3,18V6c0-0.552,0.448-1,1-1h16 c0.552,0,1,0.448,1,1v12c0,0.552-0.448,1-1,1H4C3.448,19,3,18.552,3,18z"
          fill="none"
          fillRule="nonzero"
        />
        <path
          // eslint-disable-next-line max-len
          d="M14 10A2 2 0 1 0 14 14 2 2 0 1 0 14 10zM10.75 15.25L12.375 13.625M15.625 10.375L17.25 8.75M10.75 8.75L12.375 10.375M15.625 13.625L17.25 15.25M19 21L19 19M5 21L5 19M7 9L7 15"
          fill="none"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

/**
 * A utility function to get the default value of a frontend env variable
 * (or any env var that may end up on the frontend)
 * We use a find and replace on envs to speed up builds.
 *
 * @param envName - Name of env variable
 * @param value - The value of the env, or the replacement string
 * @param defaultValue - The default value to set when not defined
 * @returns The default env value
 */
export function getEnvDefault(
  envName: string,
  value: string,
  defaultValue: string,
): string {
  // If the env var has underscores, it has not been filled by `extend_static`
  const isNotFilled = value.startsWith('___') && value.endsWith('___');
  if (!isNotFilled) {
    return value;
  }

  // Take from process.env
  const env = process.env[envName];
  if (env) {
    return env;
  }

  // Return default if exists
  if (defaultValue !== null && defaultValue !== undefined) {
    return defaultValue;
  }

  throw new Error(`Could not determine env "${envName}"`);
}

import { ComparisonOperator } from '@transcend-io/privacy-types';

import { mkInput, SchemaToType } from '@main/schema-utils';

export const AssessmentRuleInput = mkInput({
  name: 'AssessmentRuleInput',
  comment: 'The rule to evaluate in some assessment logic.',
  fields: {
    questionReferenceId: {
      comment:
        'The reference id of the question whose answer is compared by this rule',
      type: 'string',
    },
    comparisonOperator: {
      comment:
        'The operator to use when comparing the question answer to the operands',
      type: { ComparisonOperator },
    },
    comparisonOperands: {
      comment: 'The values to compare the question answer to',
      type: 'string',
      list: true,
    },
  },
});

/** Override type */
export type AssessmentRuleInput = SchemaToType<typeof AssessmentRuleInput>;

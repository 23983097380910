/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Save: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0032 10.9999V7.55884C20.0032 7.0575 19.804 6.57669 19.4495 6.22219L16.7778 3.55042C16.4231 3.1958 15.9422 2.99658 15.4407 2.99658H4.99693C3.8919 2.99658 2.99609 3.89239 2.99609 4.99742V18.0028C2.99609 19.1079 3.8919 20.0037 4.99693 20.0037H10.9994"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9951 2.99658V7.1233C14.9949 7.60668 14.6031 7.99849 14.1197 7.99867H8.86755C8.38417 7.99849 7.99236 7.60668 7.99219 7.1233V2.99658"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99813 20.003V14.3267C6.99035 13.6335 7.52194 13.0533 8.21317 13.0005H12.0002"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="17.5029"
      cy="17.5024"
      r="4.50187"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9029 16.8037L17.1516 18.5549L16.1016 17.5038"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

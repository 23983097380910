import { Skeleton } from '@main/core-ui/src/Skeleton';
import { ASSET_BASE_URL } from '@main/utils';
import React from 'react';

import { ImgWrapper } from './wrappers';

/**
 * Props
 */
export interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  /** The image alt */
  alt: string;
  /** The height of the image */
  height?: string;
  /** Indicates if the image should show a loading indicator */
  loadable?: boolean;
}

/**
 * Image state
 */
export interface ImgState {
  /** Indicates if the image is loading */
  loading: boolean;
}

/**
 * Renders an image, enforcing the usage of the alt="" tag
 */
export class Img extends React.Component<ImgProps, ImgState> {
  /** The state */
  public state = { loading: true };

  /**
   * Callback that occurs when images loads successfully
   */
  public handleImageLoaded = (): void => this.setState({ loading: false });

  /**
   * Render the image
   *
   * @returns The JSX for the Image
   */
  public render(): JSX.Element {
    const { loading } = this.state;
    const { alt, height, loadable, ...imgProps } = this.props;
    return (
      <ImgWrapper>
        {loading && loadable && <Skeleton />}
        <img
          alt={alt}
          height={height}
          {...(imgProps as any)}
          onLoad={this.handleImageLoaded}
        />
      </ImgWrapper>
    );
  }
}

export const ErrorImg: React.FC = () => (
  <Img
    src={`${ASSET_BASE_URL}/fatal.svg`}
    alt="fatal error image with frowning faces"
    width="100%"
    style={{ maxHeight: '404px' }}
  />
);

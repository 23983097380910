import React, { ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

// TODO: https://github.com/transcend-io/main/issues/8367 - migrate to common core package

/**
 * a message that can be conditionally translated
 */
export type GenericMessageDescriptor = string | MessageDescriptor | ReactNode;

/**
 * message translation args
 */
export type FormatMessageArgs = Record<string, string | ReactNode>;

/**
 * a hook to get the conditional translation function
 */
export interface FormatMessageGenericHook {
  /** function to conditionally translate a message */
  formatMessageGeneric: (
    message: GenericMessageDescriptor,
    args?: FormatMessageArgs,
  ) => string | ReactNode;
}

/**
 * formats a message that can be either a string, descriptor, or ReactElement
 *
 * @returns hook with the generic translation function
 */
export function useFormatMessageGeneric(): FormatMessageGenericHook {
  const { formatMessage } = useIntl();

  return {
    formatMessageGeneric: (
      message: GenericMessageDescriptor,
      args?: FormatMessageArgs,
    ): string | ReactNode =>
      !message ||
      ['string', 'number'].includes(typeof message) ||
      Array.isArray(message) ||
      React.isValidElement(message)
        ? message
        : formatMessage(message as MessageDescriptor, args as any),
  };
}

interface IGenericFormattedMessageProps {
  /** the message to translate */
  message: GenericMessageDescriptor;
  /** the message translation args */
  args?: FormatMessageArgs;
}

/**
 * A formatted message wrapper component that will conditionally translate
 * the message based on its type
 */
export const GenericFormattedMessage: React.FC<
  IGenericFormattedMessageProps
> = ({ message, args }) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  return <>{formatMessageGeneric(message, args)}</>;
};

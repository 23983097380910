import { useMemo } from 'react';

import { CalculatedListOverflow, UseListOverflowProps } from './types';

/**
 * A helper hook to facilitate generating an overflowed list of items
 *
 * @param args - the function args
 * @returns the calculated list overflow
 */
export function useListOverflow<T>({
  list,
  limit = 3,
  countAndMoreAsSegment,
}: UseListOverflowProps<T>): CalculatedListOverflow<T> {
  const nonNullList = list ?? [];
  const isOverflowing = nonNullList.length > limit;
  const andMoreOffset = countAndMoreAsSegment ? 1 : 0;
  const listBeforeOverflow = useMemo(
    () =>
      isOverflowing ? nonNullList.slice(0, limit - andMoreOffset) : nonNullList,
    [list, limit],
  );
  const listAfterOverflow = useMemo(
    () => (isOverflowing ? nonNullList.slice(limit - andMoreOffset) : []),
    [list, limit],
  );

  return {
    isOverflowing,
    listBeforeOverflow,
    listAfterOverflow,
  };
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ArrowRotate: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9919 3.09229V5.44962H10.6353H12.9919"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M12.6845 5.44984C11.7798 3.79184 10.0212 2.6665 7.99984 2.6665C5.0545 2.6665 2.6665 5.0545 2.6665 7.99984C2.6665 10.9452 5.0545 13.3332 7.99984 13.3332C10.9452 13.3332 13.3332 10.9452 13.3332 7.99984"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

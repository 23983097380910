import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const SidebarConsentManager: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4.99996 20H3.61796C3.30896 20 3.05896 19.75 3.05896 19.441V15.059C3.05896 14.75 3.30896 14.5 3.61796 14.5H4.99996C5.30896 14.5 5.55896 14.75 5.55896 15.101V19.483C5.55896 19.75 5.30896 20 4.99996 20V20Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.55896 15.685L7.40496 12.664C8.11596 11.501 9.90696 12.004 9.90696 13.368V14.963H10.797H11.417C11.87 14.963 12.293 15.19 12.545 15.569C12.768 15.904 12.833 16.321 12.722 16.708L11.99 19.27C11.866 19.702 11.471 20 11.022 20H7.43896C7.14796 20 6.86496 19.898 6.64096 19.712L5.55896 18.815"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M19.059 4.05901H20.441C20.75 4.05901 21 4.30901 21 4.61801V9.00001C21 9.30901 20.75 9.55901 20.441 9.55901H19.059C18.75 9.55901 18.5 9.30901 18.5 8.95801V4.57601C18.5 4.30901 18.75 4.05901 19.059 4.05901V4.05901Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M18.5 8.37401L16.654 11.395C15.943 12.558 14.152 12.055 14.152 10.691V9.09601H13.262H12.642C12.189 9.09601 11.766 8.86901 11.514 8.49001C11.291 8.15501 11.226 7.73801 11.337 7.35101L12.069 4.78901C12.193 4.35701 12.588 4.05901 13.037 4.05901H16.62C16.911 4.05901 17.194 4.16101 17.418 4.34701L18.5 5.24401"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 15V19C21 20.105 20.105 21 19 21H15"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9V5C3 3.895 3.895 3 5 3H9"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { makeEnum } from '@transcend-io/type-utils';

/**
 * Enum with all flag icons
 */
export const FlagIcons = makeEnum({
  FlagUS: 'flag-us',
  FlagAF: 'flag-af',
  FlagAX: 'flag-ax',
  FlagAL: 'flag-al',
  FlagDZ: 'flag-dz',
  FlagAS: 'flag-as',
  FlagAD: 'flag-ad',
  FlagAO: 'flag-ao',
  FlagAI: 'flag-ai',
  FlagAQ: 'flag-aq',
  FlagAG: 'flag-ag',
  FlagAR: 'flag-ar',
  FlagAM: 'flag-am',
  FlagAW: 'flag-aw',
  FlagAU: 'flag-au',
  FlagAT: 'flag-at',
  FlagAZ: 'flag-az',
  FlagBS: 'flag-bs',
  FlagBH: 'flag-bh',
  FlagBD: 'flag-bd',
  FlagBB: 'flag-bb',
  FlagBY: 'flag-by',
  FlagBE: 'flag-be',
  FlagBZ: 'flag-bz',
  FlagBJ: 'flag-bj',
  FlagBM: 'flag-bm',
  FlagBT: 'flag-bt',
  FlagBO: 'flag-bo',
  FlagBA: 'flag-ba',
  FlagBW: 'flag-bw',
  FlagBV: 'flag-bv',
  FlagBR: 'flag-br',
  FlagIO: 'flag-io',
  FlagVG: 'flag-vg',
  FlagBN: 'flag-bn',
  FlagBG: 'flag-bg',
  FlagBF: 'flag-bf',
  FlagBI: 'flag-bi',
  FlagKH: 'flag-kh',
  FlagCM: 'flag-cm',
  FlagCA: 'flag-ca',
  FlagCV: 'flag-cv',
  FlagBQ: 'flag-bq',
  FlagKY: 'flag-ky',
  FlagCF: 'flag-cf',
  FlagTD: 'flag-td',
  FlagCL: 'flag-cl',
  FlagCN: 'flag-cn',
  FlagCX: 'flag-cx',
  FlagCC: 'flag-cc',
  FlagCO: 'flag-co',
  FlagKM: 'flag-km',
  FlagCG: 'flag-cg',
  FlagCD: 'flag-cd',
  FlagCK: 'flag-ck',
  FlagCR: 'flag-cr',
  FlagCI: 'flag-ci',
  FlagHR: 'flag-hr',
  FlagCU: 'flag-cu',
  FlagCW: 'flag-cw',
  FlagCY: 'flag-cy',
  FlagCZ: 'flag-cz',
  FlagDK: 'flag-dk',
  FlagDJ: 'flag-dj',
  FlagDM: 'flag-dm',
  FlagDO: 'flag-do',
  FlagEC: 'flag-ec',
  FlagEG: 'flag-eg',
  FlagSV: 'flag-sv',
  FlagGQ: 'flag-gq',
  FlagER: 'flag-er',
  FlagEE: 'flag-ee',
  FlagSZ: 'flag-sz',
  FlagET: 'flag-et',
  FlagFK: 'flag-fk',
  FlagFO: 'flag-fo',
  FlagFJ: 'flag-fj',
  FlagFI: 'flag-fi',
  FlagFR: 'flag-fr',
  FlagGF: 'flag-gf',
  FlagPF: 'flag-pf',
  FlagTF: 'flag-tf',
  FlagGA: 'flag-ga',
  FlagGM: 'flag-gm',
  FlagGE: 'flag-ge',
  FlagDE: 'flag-de',
  FlagGH: 'flag-gh',
  FlagGI: 'flag-gi',
  FlagGR: 'flag-gr',
  FlagGL: 'flag-gl',
  FlagGD: 'flag-gd',
  FlagGP: 'flag-gp',
  FlagGU: 'flag-gu',
  FlagGT: 'flag-gt',
  FlagGG: 'flag-gg',
  FlagGN: 'flag-gn',
  FlagGW: 'flag-gw',
  FlagGY: 'flag-gy',
  FlagHT: 'flag-ht',
  FlagHM: 'flag-hm',
  FlagHN: 'flag-hn',
  FlagHK: 'flag-hk',
  FlagHU: 'flag-hu',
  FlagIS: 'flag-is',
  FlagIN: 'flag-in',
  FlagID: 'flag-id',
  FlagIR: 'flag-ir',
  FlagIQ: 'flag-iq',
  FlagIE: 'flag-ie',
  FlagIM: 'flag-im',
  FlagIL: 'flag-il',
  FlagIT: 'flag-it',
  FlagJM: 'flag-jm',
  FlagJP: 'flag-jp',
  FlagJE: 'flag-je',
  FlagJO: 'flag-jo',
  FlagKZ: 'flag-kz',
  FlagKE: 'flag-ke',
  FlagKI: 'flag-ki',
  FlagKW: 'flag-kw',
  FlagKG: 'flag-kg',
  FlagLA: 'flag-la',
  FlagLV: 'flag-lv',
  FlagLB: 'flag-lb',
  FlagLS: 'flag-ls',
  FlagLR: 'flag-lr',
  FlagLY: 'flag-ly',
  FlagLI: 'flag-li',
  FlagLT: 'flag-lt',
  FlagLU: 'flag-lu',
  FlagMO: 'flag-mo',
  FlagMG: 'flag-mg',
  FlagMW: 'flag-mw',
  FlagMY: 'flag-my',
  FlagMV: 'flag-mv',
  FlagML: 'flag-ml',
  FlagMT: 'flag-mt',
  FlagMH: 'flag-mh',
  FlagMQ: 'flag-mq',
  FlagMR: 'flag-mr',
  FlagMU: 'flag-mu',
  FlagYT: 'flag-yt',
  FlagMX: 'flag-mx',
  FlagFM: 'flag-fm',
  FlagMD: 'flag-md',
  FlagMC: 'flag-mc',
  FlagMN: 'flag-mn',
  FlagME: 'flag-me',
  FlagMS: 'flag-ms',
  FlagMA: 'flag-ma',
  FlagMZ: 'flag-mz',
  FlagMM: 'flag-mm',
  FlagNA: 'flag-na',
  FlagNR: 'flag-nr',
  FlagNP: 'flag-np',
  FlagNL: 'flag-nl',
  FlagNC: 'flag-nc',
  FlagNZ: 'flag-nz',
  FlagNI: 'flag-ni',
  FlagNE: 'flag-ne',
  FlagNG: 'flag-ng',
  FlagNU: 'flag-nu',
  FlagNF: 'flag-nf',
  FlagKP: 'flag-kp',
  FlagMK: 'flag-mk',
  FlagMP: 'flag-mp',
  FlagNO: 'flag-no',
  FlagOM: 'flag-om',
  FlagPK: 'flag-pk',
  FlagPW: 'flag-pw',
  FlagPS: 'flag-ps',
  FlagPA: 'flag-pa',
  FlagPG: 'flag-pg',
  FlagPY: 'flag-py',
  FlagPE: 'flag-pe',
  FlagPH: 'flag-ph',
  FlagPN: 'flag-pn',
  FlagPL: 'flag-pl',
  FlagPT: 'flag-pt',
  FlagPR: 'flag-pr',
  FlagQA: 'flag-qa',
  FlagRE: 'flag-re',
  FlagRO: 'flag-ro',
  FlagRU: 'flag-ru',
  FlagRW: 'flag-rw',
  FlagWS: 'flag-ws',
  FlagSM: 'flag-sm',
  FlagST: 'flag-st',
  FlagSA: 'flag-sa',
  FlagSN: 'flag-sn',
  FlagRS: 'flag-rs',
  FlagSC: 'flag-sc',
  FlagSL: 'flag-sl',
  FlagSG: 'flag-sg',
  FlagSX: 'flag-sx',
  FlagSK: 'flag-sk',
  FlagSI: 'flag-si',
  FlagSB: 'flag-sb',
  FlagSO: 'flag-so',
  FlagZA: 'flag-za',
  FlagGS: 'flag-gs',
  FlagKR: 'flag-kr',
  FlagSS: 'flag-ss',
  FlagES: 'flag-es',
  FlagLK: 'flag-lk',
  FlagBL: 'flag-bl',
  FlagSH: 'flag-sh',
  FlagKN: 'flag-kn',
  FlagLC: 'flag-lc',
  FlagMF: 'flag-mf',
  FlagPM: 'flag-pm',
  FlagVC: 'flag-vc',
  FlagSD: 'flag-sd',
  FlagSR: 'flag-sr',
  FlagSJ: 'flag-sj',
  FlagSE: 'flag-se',
  FlagCH: 'flag-ch',
  FlagSY: 'flag-sy',
  FlagTW: 'flag-tw',
  FlagTJ: 'flag-tj',
  FlagTZ: 'flag-tz',
  FlagTH: 'flag-th',
  FlagTL: 'flag-tl',
  FlagTG: 'flag-tg',
  FlagTK: 'flag-tk',
  FlagTO: 'flag-to',
  FlagTT: 'flag-tt',
  FlagTN: 'flag-tn',
  FlagTR: 'flag-tr',
  FlagTM: 'flag-tm',
  FlagTC: 'flag-tc',
  FlagTV: 'flag-tv',
  FlagUM: 'flag-um',
  FlagVI: 'flag-vi',
  FlagUG: 'flag-ug',
  FlagUA: 'flag-ua',
  FlagAE: 'flag-ae',
  FlagGB: 'flag-gb',
  FlagUY: 'flag-uy',
  FlagUZ: 'flag-uz',
  FlagVU: 'flag-vu',
  FlagVA: 'flag-va',
  FlagVE: 'flag-ve',
  FlagVN: 'flag-vn',
  FlagWF: 'flag-wf',
  FlagEH: 'flag-eh',
  FlagYE: 'flag-ye',
  FlagZM: 'flag-zm',
  FlagZW: 'flag-zw',
  FlagEU: 'flag-eu',
});

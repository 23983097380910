import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const NotJunk: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 6V9.5" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.4736 14H4.52691C3.84757 14 3.12024 13.4893 3.04557 12.814L2.00824 3.48067C1.92091 2.69067 2.53891 2 3.33357 2H12.6669C13.4616 2 14.0796 2.69067 13.9922 3.48067L12.9549 12.814C12.8796 13.4893 12.1529 14 11.4736 14V14Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 7.5L8 6L9.5 7.5"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagPN: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55198"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55198)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_55198"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55198)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M10.308 5.83454C10.308 5.83454 10.3574 6.35452 10.2492 6.64916C10.1409 6.9438 10.0859 7.11483 10.0859 6.92649C10.0859 6.73816 10.0099 6.58891 9.94877 6.58891C9.88763 6.58891 9.93589 6.77567 9.87445 6.77567C9.81301 6.77567 9.60133 6.80778 9.60133 6.69834C9.60133 6.58891 9.57348 6.99193 9.71083 7.02797C9.84819 7.06401 9.64414 7.17585 9.55304 7.14121C9.55304 7.14121 9.92776 7.25692 10.0747 7.35552C10.0747 7.35552 10.5152 7.87795 10.9272 7.81422C10.9272 7.81422 10.3662 7.63023 10.465 7.2787C10.5639 6.92717 10.9272 6.87632 10.9272 6.87632C10.9272 6.87632 11.0977 6.93049 11.0926 7.07239C11.0875 7.21429 11.2173 7.02797 11.2173 7.02797C11.2173 7.02797 11.2382 6.84869 11.2152 6.80494C11.1921 6.76119 11.6057 6.6765 11.6318 6.49188C11.6318 6.49188 11.2092 6.51023 11.1802 6.46281C11.1512 6.41538 11.311 6.34102 11.261 6.24857C11.211 6.15611 10.465 6.77567 10.465 6.77567C10.465 6.77567 10.7226 6.35397 10.7226 6.12752C10.7226 5.90106 10.6572 5.74631 10.465 5.74631C10.2729 5.74631 10.308 5.83454 10.308 5.83454Z"
          fill="#5EAA22"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M14.1917 5.95814C14.1912 5.95892 14.1922 5.95963 14.1929 5.95901C14.2204 5.93324 14.3918 5.78331 14.5353 5.9017C14.6919 6.03084 14.4564 6.15669 14.4564 6.15669C14.4564 6.15669 14.5665 6.25813 14.6924 6.25813C14.8183 6.25813 14.8492 6.15669 14.8492 6.15669C14.8492 6.15669 14.8588 6.38244 15.2694 6.46792C15.2694 6.46792 15.4159 6.26809 15.2694 6.15869C15.2694 6.15869 15.4425 6.12458 15.4299 5.99141C15.4299 5.99141 15.2877 6.03746 15.1862 5.93164C15.0847 5.82581 14.887 5.77701 14.7432 5.80141C14.3782 5.67635 14.2139 5.9214 14.1917 5.95814Z"
          fill="#5EAA22"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M11.7018 7.09713C11.7018 7.09713 12.1264 7.85587 11.8611 8.01309C11.5957 8.17031 13.1229 8.05847 13.2668 8.01309C13.4107 7.96771 13.2378 7.72848 13.0782 7.66207C12.9186 7.59565 12.2625 7.37469 12.088 7.20804C11.9135 7.04139 12.02 6.98225 12.1606 6.81774C12.3012 6.65323 12.8216 6.58745 12.8216 6.43369C12.8216 6.27992 13.1517 5.64578 13.3824 5.59793C13.6131 5.55009 13.4885 5.47474 13.4354 5.47474C13.3824 5.47474 13.0634 5.78707 12.9425 5.73072C12.8216 5.67437 12.8068 5.67833 12.9425 5.54213C13.0782 5.40592 13.1804 5.24915 13.001 5.36195C12.8216 5.47474 12.5258 5.65326 12.3837 5.65326C12.2415 5.65326 11.7354 5.59237 11.7982 5.73072C11.8611 5.86907 11.8877 6.10921 12.1606 6.27145C12.4335 6.43369 12.3149 6.55241 12.088 6.70874C11.8611 6.86508 11.7018 7.09713 11.7018 7.09713Z"
          fill="#96877D"
        />
        <path
          // eslint-disable-next-line max-len
          d="M14.5193 6.09378L13.7102 7.63532C13.7102 7.63532 13.6866 8.07077 14.2651 7.78061C14.8435 7.49045 14.8207 7.28465 14.9875 7.28465C15.1543 7.28465 15.2072 7.19667 15.2072 7.19667L15.0961 7.11368C15.0961 7.11368 15.0833 6.96231 15.1536 6.92832C15.1536 6.92832 15.0156 6.85607 14.9959 6.91639C14.9762 6.97671 14.8788 6.79023 14.9339 6.79023C14.9339 6.79023 14.8122 6.97744 14.7865 7.05049C14.7865 7.05049 14.6104 6.98246 14.6188 6.66729C14.6273 6.35212 14.5193 6.09378 14.5193 6.09378Z"
          fill="#FFD018"
        />
        <path
          // eslint-disable-next-line max-len
          d="M10.6682 5.84967C10.6682 5.84967 10.2562 5.94078 10.3006 6.0356C10.3451 6.13042 10.4105 6.03304 10.2088 6.14731C10.2088 6.14731 9.85447 6.4076 9.77724 6.3794C9.7 6.35119 9.83194 6.44496 9.83194 6.14731C9.83194 5.84967 9.62616 6.09195 9.83194 5.89326C10.0377 5.69456 10.1099 5.84967 10.2088 5.73533C10.3078 5.621 10.2188 5.6818 10.4873 5.6818C10.7557 5.6818 10.6682 5.84967 10.6682 5.84967Z"
          fill="#FFD018"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.9253 7.38171C11.9253 7.38171 10.7718 8.24289 10.5412 8.30717C10.3107 8.37146 11.0154 8.28949 11.1876 8.1758C11.3597 8.06211 11.9253 7.38171 11.9253 7.38171Z"
          fill="#FFD018"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M14.2123 8.89243C14.2123 8.89243 14.323 9.57094 14.034 9.50416C13.7449 9.43739 13.625 9.40285 13.625 9.40285C13.625 9.40285 13.731 9.71888 13.8825 9.71888C13.8825 9.71888 14.1406 10.1967 14.0413 10.3476C14.0413 10.3476 14.4859 10.005 14.414 9.73251C14.3421 9.46006 14.5289 9.77734 14.5289 9.77734C14.5289 9.77734 14.6926 9.8524 14.8108 9.76858C14.9291 9.68476 14.8093 9.72202 14.6911 9.56243C14.5728 9.40285 14.602 8.95467 14.2422 8.72239C13.8825 8.49012 13.7987 8.49754 13.7987 8.49754C13.7987 8.49754 13.5338 8.08601 13.6925 8.11322C13.6925 8.11322 14.8301 8.48369 14.8597 8.57864C14.8892 8.67359 15.1909 8.65619 15.0401 8.38471C14.8892 8.11322 14.0761 7.94961 13.9793 7.87305C13.8825 7.79648 13.9725 7.5061 13.7987 7.44284C13.625 7.37958 13.1502 6.9307 13.1505 6.71438C13.1508 6.49806 12.9273 6.39624 12.733 6.39624C12.5388 6.39624 12.173 6.34293 12.0185 6.34293C11.8639 6.34293 11.7199 6.58851 11.8692 6.58851C12.0185 6.58851 11.891 7.04829 11.641 7.14878C11.391 7.24926 11.1978 7.39369 10.9123 7.39369C10.6269 7.39369 11.3164 7.6275 11.4263 7.53517C11.5362 7.44284 11.5001 7.30526 11.8692 7.14878C12.2383 6.9923 12.5905 6.89965 12.5459 7.02421C12.5013 7.14878 12.6151 7.35713 12.789 7.28172C12.9628 7.20631 13.0186 7.01583 13.0186 7.14878C13.0186 7.28172 13.2852 7.96454 13.4889 8.23104C13.6925 8.49754 14.2123 8.89243 14.2123 8.89243Z"
          fill="#5EAA22"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.7856 6.39914C11.7856 6.39914 11.6503 6.60385 11.7856 6.60385C11.9208 6.60385 12.1384 6.60385 12.0058 6.5015C11.8733 6.39914 11.7856 6.39914 11.7856 6.39914Z"
          fill="#FFD018"
        />
        <path
          // eslint-disable-next-line max-len
          d="M12.2375 6.39421C12.2375 6.39421 12.2381 6.53032 12.3319 6.53032C12.4256 6.53032 12.509 6.66642 12.509 6.53032C12.509 6.39421 12.6393 6.43935 12.4384 6.39421C12.2375 6.34907 12.2375 6.39421 12.2375 6.39421Z"
          fill="#FFD018"
        />
        <path
          // eslint-disable-next-line max-len
          d="M12.8148 6.42538C12.8148 6.42538 12.7244 6.49002 12.7696 6.60531C12.8148 6.7206 12.917 6.94875 12.7696 6.94875C12.6222 6.94875 12.501 7.031 12.6161 7.031C12.7312 7.031 12.9361 7.11326 12.9361 7.031C12.9361 6.94875 13.0194 7.16664 13.0194 7.031C13.0194 6.89537 13.0194 6.83056 13.0194 6.75102C13.0194 6.67148 13.1027 6.64861 13.0194 6.537C12.9361 6.42538 12.8148 6.42538 12.8148 6.42538Z"
          fill="#FFD018"
        />
        <path
          // eslint-disable-next-line max-len
          d="M13.0236 7.21909C13.0236 7.21909 13.2119 7.78217 13.3143 7.85477C13.4167 7.92736 13.4627 8.53887 13.5076 8.60536C13.5525 8.67185 14.1649 9.00742 14.1649 9.1875C14.1649 9.36759 14.3535 9.04912 14.2592 8.8708C14.1649 8.69247 13.8681 8.3128 13.6546 8.23C13.4411 8.1472 13.4682 7.94671 13.4233 7.80581C13.3784 7.6649 13.6889 7.91966 13.4233 7.60004C13.1577 7.28042 13.1647 7.45194 13.2395 7.45194C13.3143 7.45194 13.3891 7.41417 13.3143 7.3645C13.2395 7.31484 13.0236 7.21909 13.0236 7.21909Z"
          fill="#FFD018"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.0271 8.38031C11.0271 8.38031 10.4153 9.40661 10.3062 9.45963C10.197 9.51265 10.1157 9.52343 10.0599 9.45963C10.0041 9.39583 9.99837 9.63085 10.0599 9.6952C10.1214 9.75955 10.3062 9.75955 10.4087 9.64528C10.4087 9.64528 10.425 9.934 10.5847 10.1517C10.7445 10.3693 10.811 10.3092 10.811 10.4323C10.811 10.5554 10.8488 10.7184 10.8976 10.7184C10.9463 10.7184 10.9841 11.1058 10.8976 11.1058C10.811 11.1058 11.0981 11.1376 11.2232 11.0407C11.3483 10.9438 11.4018 10.87 11.375 10.87C11.3483 10.87 11.0271 10.7094 11.0271 10.5957C11.0271 10.4821 10.811 10.3931 10.811 10.2243C10.811 10.0556 10.6988 9.85498 10.6988 9.77509C10.6988 9.6952 10.7948 9.80592 10.8976 9.73027C11.0004 9.65462 11.0271 9.45963 11.0271 9.45963C11.0271 9.45963 10.8652 9.5628 10.811 9.51121C10.7569 9.45963 10.5866 9.3578 10.6988 9.18309C10.811 9.00838 11.0271 8.38031 11.0271 8.38031Z"
          fill="#FFD018"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M12.0871 5.14355C12.0871 5.14355 11.8681 5.23611 11.9411 5.37362C12.0141 5.51114 12.0404 5.57203 12.0871 5.54159C12.1339 5.51114 12.4621 5.45358 12.3886 5.29857C12.315 5.14355 12.161 5.14355 12.161 5.14355H12.0871Z"
          fill="#5EAA22"
        />
        <path
          // eslint-disable-next-line max-len
          d="M12.847 5.0692C12.847 5.0692 12.5577 4.94758 12.6541 5.0692C12.7505 5.19082 12.8739 5.25494 12.9627 5.25494C13.0514 5.25494 13.2359 5.2298 13.1437 5.18073C13.0514 5.13166 12.847 5.0692 12.847 5.0692Z"
          fill="#FFD018"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M9.99069 8.23029C9.99215 8.22277 9.98327 8.21735 9.97759 8.22249C9.91763 8.27674 9.73617 8.45916 9.81303 8.6178C9.90452 8.80661 9.996 8.87217 9.996 8.87217C9.996 8.87217 10.0517 9.16125 10.1669 9.23992C10.1669 9.23992 10.3215 9.2324 10.3292 9.00739C10.3292 9.00739 10.5919 8.93418 10.6691 9.08999C10.6691 9.08999 10.6182 9.10752 10.6182 9.23992C10.6182 9.37232 10.737 9.51575 10.737 9.51575C10.737 9.51575 10.7914 9.0591 11.036 8.76468C11.2806 8.47027 11.3506 8.41216 11.3506 8.41216V8.08087C11.3506 8.08087 10.8445 8.4726 10.737 8.79831C10.737 8.79831 10.4822 8.80639 10.3786 8.76468C10.2751 8.72298 10.5079 8.65457 10.3786 8.50634C10.2493 8.35811 10.3086 8.71505 10.1669 8.6178C9.94527 8.55925 9.97535 8.309 9.99069 8.23029Z"
          fill="#5EAA22"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M11.0934 7.9425L13.6857 7.9425V9.44714C13.6857 9.44714 13.4744 10.6766 12.3454 11.2823C12.3454 11.2823 11.2519 10.6682 11.0934 9.44714V7.9425Z"
          fill="#5EAA22"
        />
        <mask
          id="mask2_270_55198"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="11"
          y="7"
          width="3"
          height="5"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M11.0934 7.9425L13.6857 7.9425V9.44714C13.6857 9.44714 13.4744 10.6766 12.3454 11.2823C12.3454 11.2823 11.2519 10.6682 11.0934 9.44714V7.9425Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_270_55198)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3258 7.86121L11.1932 10.094L12.7292 8.76895L13.7863 10.4335V7.86121L12.7292 8.68446L12.3258 7.86121Z"
            fill="#58A5FF"
          />
          <path
            // eslint-disable-next-line max-len
            d="M12.2901 9.47571C12.2901 9.42904 12.3279 9.3912 12.3746 9.3912C12.4213 9.3912 12.4591 9.42904 12.4591 9.47571V10.901C12.4591 10.9476 12.4213 10.9855 12.3746 10.9855C12.3279 10.9855 12.2901 10.9476 12.2901 10.901L12.2901 9.47571Z"
            fill="#FFD018"
          />
          <path
            // eslint-disable-next-line max-len
            d="M11.9921 9.82498C11.9485 9.82498 11.9132 9.78967 11.9132 9.74611C11.9132 9.70255 11.9485 9.66724 11.9921 9.66724H12.764C12.8076 9.66724 12.8429 9.70255 12.8429 9.74611C12.8429 9.78967 12.8076 9.82498 12.764 9.82498H11.9921Z"
            fill="#FFD018"
          />
          <path
            // eslint-disable-next-line max-len
            d="M12.5318 10.7952L12.4458 10.8655C12.319 10.7105 12.2034 10.636 12.1013 10.636C11.9509 10.636 11.8286 10.5096 11.7288 10.2701L11.8314 10.2274C11.9158 10.43 12.0076 10.5248 12.1013 10.5248C12.2433 10.5248 12.386 10.6169 12.5318 10.7952Z"
            fill="#FFD018"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M11.629 9.88834C11.6285 9.88787 11.6277 9.88823 11.6277 9.88892V10.43C11.6277 10.4306 11.6283 10.431 11.6288 10.4308L12.0331 10.2632C12.0336 10.263 12.0337 10.2623 12.0333 10.2619L11.629 9.88834Z"
            fill="#FFD018"
          />
          <path
            // eslint-disable-next-line max-len
            d="M12.2542 10.7952L12.3402 10.8655C12.4669 10.7105 12.5825 10.636 12.6847 10.636C12.8351 10.636 12.9574 10.5096 13.0571 10.2701L12.9546 10.2274C12.8702 10.43 12.7784 10.5248 12.6847 10.5248C12.5427 10.5248 12.3999 10.6169 12.2542 10.7952Z"
            fill="#FFD018"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M13.1569 9.88834C13.1574 9.88787 13.1583 9.88823 13.1583 9.88892V10.43C13.1583 10.4306 13.1577 10.431 13.1572 10.4308L12.7529 10.2632C12.7524 10.263 12.7522 10.2623 12.7527 10.2619L13.1569 9.88834Z"
            fill="#FFD018"
          />
          <rect
            x="12.2042"
            y="8.82785"
            width="0.338028"
            height="0.478481"
            fill="white"
          />
        </g>
      </g>
    </g>
    <mask
      id="mask3_270_55198"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="9"
      height="7"
    >
      <rect width="9" height="7" fill="white" />
    </mask>
    <g mask="url(#mask3_270_55198)">
      <path
        // eslint-disable-next-line max-len
        d="M-1.00214 6.4999L0.978339 7.3685L9.04494 0.944361L10.0896 -0.346405L7.97176 -0.636717L4.68159 2.13163L2.03334 3.99685L-1.00214 6.4999Z"
        fill="#F7FCFF"
      />
      <path
        d="M-0.731018 7.10845L0.277943 7.61252L9.71445 -0.466339H8.29775L-0.731018 7.10845Z"
        fill="#F50100"
      />
      <path
        // eslint-disable-next-line max-len
        d="M10.0021 6.4999L8.02166 7.3685L-0.0449371 0.944361L-1.08959 -0.346405L1.02824 -0.636717L4.31841 2.13163L6.96666 3.99685L10.0021 6.4999Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M9.93457 6.93667L8.92561 7.44073L4.90745 3.98176L3.71615 3.59529L-1.1901 -0.341973H0.226597L5.13009 3.50184L6.43256 3.96525L9.93457 6.93667Z"
        fill="#F50100"
      />
      <mask
        id="path-34-outside-1_270_55198"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-1"
        width="11"
        height="9"
        fill="black"
      >
        <rect fill="white" x="-1" y="-1" width="11" height="9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
        fill="#F50100"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.99226 0V-0.75H3.24226V0H3.99226ZM4.99226 0H5.74226V-0.75H4.99226V0ZM3.99226 3V3.75H4.74226V3H3.99226ZM0 3V2.25H-0.75V3H0ZM0 4H-0.75V4.75H0V4ZM3.99226 4H4.74226V3.25H3.99226V4ZM3.99226 7H3.24226V7.75H3.99226V7ZM4.99226 7V7.75H5.74226V7H4.99226ZM4.99226 4V3.25H4.24226V4H4.99226ZM9 4V4.75H9.75V4H9ZM9 3H9.75V2.25H9V3ZM4.99226 3H4.24226V3.75H4.99226V3ZM3.99226 0.75H4.99226V-0.75H3.99226V0.75ZM4.74226 3V0H3.24226V3H4.74226ZM0 3.75H3.99226V2.25H0V3.75ZM0.75 4V3H-0.75V4H0.75ZM3.99226 3.25H0V4.75H3.99226V3.25ZM4.74226 7V4H3.24226V7H4.74226ZM4.99226 6.25H3.99226V7.75H4.99226V6.25ZM4.24226 4V7H5.74226V4H4.24226ZM9 3.25H4.99226V4.75H9V3.25ZM8.25 3V4H9.75V3H8.25ZM4.99226 3.75H9V2.25H4.99226V3.75ZM4.24226 0V3H5.74226V0H4.24226Z"
        fill="#F7FCFF"
        mask="url(#path-34-outside-1_270_55198)"
      />
    </g>
  </svg>
);

import { PageSkeleton } from '@main/core-ui/src/PageSkeleton';
import type { DefinedMessage } from '@main/internationalization';
import React, { lazy, Suspense } from 'react';
import { MessageDescriptor } from 'react-intl';

/**
 * Messages that come baked into Loadable component by default
 */
export interface AttachMessages {
  /** Title of component (used as page title or tab title) */
  title?: DefinedMessage;
  /** Header message of the component */
  header: DefinedMessage;
  /** Descriptor message */
  info?: DefinedMessage;
}

/**
 * Messages that come baked into Loadable component by default
 */
export type LoadableMessages<K extends string> = AttachMessages & {
  [key in K]: MessageDescriptor;
};

/**
 * Function to load a component
 *
 * React.lazy requires default export for tree shaking:
 *
 * @see https://reactjs.org/docs/code-splitting.html#named-exports
 */
type Loader<TProps> = () => Promise<{
  /** React.FC is fine but fails typing */
  default: React.FC<TProps> | React.ComponentClass<TProps>;
}>;

/**
 * Configuration to create a loadable component
 */
export interface LoadingConfig<
  M extends LoadableMessages<AllK>,
  AllK extends string,
  TProps,
> {
  /** Import the react component */
  loader: Loader<TProps>;
  /** The messages to expose on the returning loadable instance, often used to display a page header before load */
  messages?: M;
  /** Dynamic message */
  dynamic?: M;
  /** Expose a logo */
  logo?: string;
  /** Display children */
  children?: DefinedMessage[];
  /** Fallback to render when loading */
  fallback?: React.ReactNode | null;
}

/**
 * Returning loadable instance
 */
export type LoadableResult<T> = React.FC<T> &
  AttachMessages & {
    /** Display children */
    children: DefinedMessage[];
    /** Logo */
    logo?: string;
  };

/**
 * Create a loadable component
 *
 * @param config - The loadable config
 * @returns The loadable component
 */
export function createLoadable<
  M extends LoadableMessages<AllK>,
  AllK extends string,
  TProps,
>(config: LoadingConfig<M, AllK, TProps>): LoadableResult<TProps> {
  const {
    fallback = <PageSkeleton />,
    loader,
    messages,
    dynamic,
    logo,
    children = [],
  } = config;

  const getMessage = (
    msg: keyof AttachMessages,
  ): DefinedMessage | undefined => {
    if (dynamic && dynamic[msg]) {
      return dynamic[msg];
    }

    if (messages && messages[msg]) {
      return messages[msg];
    }
    return undefined;
  };

  // Create the loadable component
  const LazyComponent = lazy(loader);
  const LoadableComponent = ((props: TProps): React.ReactNode => (
    <Suspense fallback={fallback}>
      <LazyComponent {...(props as any)} />
    </Suspense>
  )) as LoadableResult<TProps>;

  // Copy over messages
  LoadableComponent.title = getMessage('title');
  LoadableComponent.header = getMessage('header')!;
  LoadableComponent.info = getMessage('info');

  // Copy over children
  LoadableComponent.children = children;

  // Set the logo
  LoadableComponent.logo = logo;

  return LoadableComponent;
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const BrowserWebEye: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      // eslint-disable-next-line max-len
      d="M26.004 28.005H5.996a2.668 2.668 0 01-2.668-2.668V6.663a2.668 2.668 0 012.668-2.668h20.008a2.668 2.668 0 012.668 2.668v18.674a2.668 2.668 0 01-2.668 2.668z"
      clipRule="evenodd"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      // eslint-disable-next-line max-len
      d="M3.328 10.664h25.344M14.183 7.33a.067.067 0 10-.134 0 .067.067 0 00.134 0M10.798 7.33a.067.067 0 10-.134 0 .067.067 0 00.134 0M7.403 7.33a.067.067 0 10-.133 0 .067.067 0 00.133 0M17.415 18.086a2.001 2.001 0 11-2.832 2.829 2.001 2.001 0 012.832-2.829"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.25 19.5s2.25-5.25 6.75-5.25 6.75 5.25 6.75 5.25-2.25 5.25-6.75 5.25-6.75-5.25-6.75-5.25z"
    />
  </svg>
);

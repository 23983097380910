import { defineMessages } from '@transcend-io/internationalization';

export const validatorMessages = defineMessages(
  'core-ui.Form.validation.validator',
  {
    dateStr: { defaultMessage: 'Must be a valid date' },
    email: { defaultMessage: 'The input is not valid E-mail' },
    invalidEmail: { defaultMessage: 'Enter a valid email address' },
    invalidEmailTrailing: {
      defaultMessage:
        'The email address has leading or trailing spaces that should be removed',
    },
    invalidEmailChars: {
      defaultMessage:
        'Enter a valid email address. Invalid characters detected: {chars}',
    },
    invalidEmailMulti: { defaultMessage: 'Enter valid email address(es)' },
    maxLength: { defaultMessage: '{name} must be less than {sz} characters' },
    minLength: { defaultMessage: '{name} must be longer than {sz} characters' },
    lessThan: { defaultMessage: 'Must be less than {nm}' },
    lessOrEqualTo: {
      defaultMessage: 'Must be less or equal to {nm}',
    },
    greaterThan: { defaultMessage: 'Must be greater than {nm}' },
    greaterOrEqualTo: {
      defaultMessage: 'Must be greater or equal to {nm}',
    },
    password: { defaultMessage: 'Password strength is too weak' },
    positiveInteger: { defaultMessage: 'Must be a valid positive number' },
    integer: { defaultMessage: 'Must be a whole number' },
    required: { defaultMessage: 'Required' },
    socialSecurity: {
      defaultMessage: 'Must be a valid social security number',
    },
    telephone: { defaultMessage: 'Must be a valid international number' },
    uri: { defaultMessage: 'Invalid uri (a-Z),(0,9)' },
    url: { defaultMessage: 'Must be over TLS (starts with https://)' },
    uuidv4: { defaultMessage: 'Must be a UUID v4' },
    anyUrl: {
      defaultMessage: 'Must be a a valid url starting with http:// or https://',
    },
    domain: {
      defaultMessage: 'Must be a valid domain (with or without a port)',
    },
    domainWithoutPort: {
      defaultMessage: 'Must be a valid domain (without a port)',
    },
    notOneOf: {
      defaultMessage: 'Already exists',
    },
    oneOf: {
      defaultMessage: 'Must be one of the options',
    },
    host: {
      defaultMessage: 'Must be a valid host (i.e. transcend.io)',
    },
    htmlStringHasContents: {
      defaultMessage: 'Required',
    },
    customRegex: {
      defaultMessage:
        'Must be a valid string according to custom regex {customRegex}',
    },
  },
);

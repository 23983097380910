/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const SearchRadarLocation: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1786 8.82141C11.8294 9.47228 11.8294 10.5276 11.1786 11.1784C10.5277 11.8293 9.4724 11.8293 8.82153 11.1784C8.17066 10.5276 8.17066 9.47228 8.82153 8.82141C9.4724 8.17053 10.5277 8.17053 11.1786 8.82141"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3034 4.69663C12.3742 1.76746 7.62586 1.76746 4.69669 4.69663C1.76753 7.6258 1.76753 12.3741 4.69669 15.3033C7.62586 18.2325 12.3742 18.2325 15.3034 15.3033C16.7675 13.8391 17.4834 11.9191 17.4817 9.99996"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3 4.69995L11.175 8.82495"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2409 6.75925C12.4117 5.93008 11.2659 5.41675 10 5.41675C7.46835 5.41675 5.41669 7.46841 5.41669 10.0001C5.41669 12.5317 7.46835 14.5834 10 14.5834C12.5317 14.5834 14.5834 12.5317 14.5834 10.0001"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const FloppyDisk: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M10.776 2H3.33801C2.59801 2 1.99934 2.60267 2.00467 3.34333L2.07401 12.6767C2.07934 13.4093 2.67467 14 3.40734 14H12.6613C13.398 14 13.9947 13.4033 13.9947 12.6667V5.21867C13.9947 4.86533 13.854 4.526 13.604 4.276L11.7187 2.39067C11.4687 2.14067 11.13 2 10.776 2Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.6619 2V4.606C10.6619 4.974 10.3633 5.27267 9.99528 5.27267H5.99528C5.62728 5.27267 5.32861 4.974 5.32861 4.606V2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66675 14V8.85733C4.66675 8.384 5.05075 8 5.52408 8H10.4767C10.9494 8 11.3334 8.384 11.3334 8.85733V14"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';
import type { GroupTypeBase, OptionTypeBase } from 'react-select';
import { components, MultiValueProps } from 'react-select';
import { useTheme } from 'styled-components';

import { Icon } from '../Icon';
import { MultiValueRemoveWrapper } from './wrappers';

/**
 * Our own remove icon
 */
export function MultiValueRemove<
  T extends OptionTypeBase,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
>(props: MultiValueProps<T, G>): JSX.Element {
  const theme = useTheme();
  return (
    <MultiValueRemoveWrapper
      isFixed={props.data.isFixed}
      menuIsOpen={props.selectProps.menuIsOpen}
    >
      <components.MultiValueRemove {...props}>
        <Icon type="close-thick" size={8} color={theme.colors.black} />
      </components.MultiValueRemove>
    </MultiValueRemoveWrapper>
  );
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagGQ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55059"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55059)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12.288H16.384V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55059"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="13"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12.288H16.384V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55059)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4.096H16.384V0H0Z"
          fill="#009D00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.09599V8.19199H16.384V4.09599H0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8.192V12.288H16.384V8.192H0Z"
          fill="#F80000"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12.288L4.096 6.2218L0 0Z"
        fill="#0075D5"
      />
      <mask
        id="mask2_270_55059"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="5"
        height="13"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12.288L4.096 6.2218L0 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_270_55059)" />
      <path
        // eslint-disable-next-line max-len
        d="M7.46044 4.5502L7.46297 4.56138L7.44696 4.54659L7.34441 4.45185L7.3316 4.59087L7.34301 4.59192L7.32399 4.60258L7.20221 4.67083L7.33046 4.72598L7.33499 4.71545L7.33924 4.73683L7.36652 4.87374L7.4586 4.76881L7.44999 4.76125L7.47164 4.76381L7.61028 4.78017L7.53894 4.66017L7.52909 4.66603L7.53821 4.64623L7.59662 4.51943L7.46044 4.5502Z"
        fill="#FFD500"
        stroke="black"
        strokeWidth="0.03"
      />
      <path
        // eslint-disable-next-line max-len
        d="M7.97241 4.5502L7.97493 4.56138L7.95892 4.54659L7.85638 4.45185L7.84356 4.59087L7.85497 4.59192L7.83595 4.60258L7.71417 4.67083L7.84242 4.72598L7.84695 4.71545L7.85121 4.73683L7.87849 4.87374L7.97057 4.76881L7.96195 4.76125L7.9836 4.76381L8.12225 4.78017L8.0509 4.66017L8.04105 4.66603L8.05017 4.64623L8.10858 4.51943L7.97241 4.5502Z"
        fill="#FFD500"
        stroke="black"
        strokeWidth="0.03"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.48449 4.5502L8.48702 4.56138L8.47101 4.54659L8.36846 4.45185L8.35564 4.59087L8.36705 4.59192L8.34804 4.60258L8.22625 4.67083L8.35451 4.72598L8.35903 4.71545L8.36329 4.73683L8.39057 4.87374L8.48265 4.76881L8.47404 4.76125L8.49569 4.76381L8.63433 4.78017L8.56299 4.66017L8.55314 4.66603L8.56226 4.64623L8.62067 4.51943L8.48449 4.5502Z"
        fill="#FFD500"
        stroke="black"
        strokeWidth="0.03"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.99645 4.5502L8.99898 4.56138L8.98297 4.54659L8.88042 4.45185L8.86761 4.59087L8.87902 4.59192L8.86 4.60258L8.73822 4.67083L8.86647 4.72598L8.871 4.71545L8.87526 4.73683L8.90253 4.87374L8.99462 4.76881L8.986 4.76125L9.00765 4.76381L9.14629 4.78017L9.07495 4.66017L9.0651 4.66603L9.07422 4.64623L9.13263 4.51943L8.99645 4.5502Z"
        fill="#FFD500"
        stroke="black"
        strokeWidth="0.03"
      />
      <path
        // eslint-disable-next-line max-len
        d="M9.50842 4.5502L9.51094 4.56138L9.49493 4.54659L9.39239 4.45185L9.37957 4.59087L9.39098 4.59192L9.37197 4.60258L9.25018 4.67083L9.37843 4.72598L9.38296 4.71545L9.38722 4.73683L9.4145 4.87374L9.50658 4.76881L9.49797 4.76125L9.51961 4.76381L9.65826 4.78017L9.58691 4.66017L9.57706 4.66603L9.58618 4.64623L9.64459 4.51943L9.50842 4.5502Z"
        fill="#FFD500"
        stroke="black"
        strokeWidth="0.03"
      />
      <path
        // eslint-disable-next-line max-len
        d="M10.0205 4.5502L10.023 4.56138L10.007 4.54659L9.90447 4.45185L9.89165 4.59087L9.90306 4.59192L9.88405 4.60258L9.76226 4.67083L9.89052 4.72598L9.89504 4.71545L9.8993 4.73683L9.92658 4.87374L10.0187 4.76881L10.0101 4.76125L10.0317 4.76381L10.1703 4.78017L10.099 4.66017L10.0891 4.66603L10.0983 4.64623L10.1567 4.51943L10.0205 4.5502Z"
        fill="#FFD500"
        stroke="black"
        strokeWidth="0.03"
      />
      <path
        // eslint-disable-next-line max-len
        d="M7.71005 5.15H9.69805V6.65599C9.69805 7.20497 9.25302 7.64999 8.70405 7.64999C8.15508 7.64999 7.71005 7.20497 7.71005 6.65599V5.15Z"
        stroke="#373737"
        strokeWidth="0.06"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.56389 6.12343C8.56389 6.12343 8.46061 7.33445 8.35752 7.40784C8.25442 7.48124 8.85156 7.51789 8.90862 7.46286C8.96568 7.40784 8.64821 6.42101 8.75742 6.12343C8.86663 5.82585 9.04253 5.80173 9.04253 5.80173C9.04253 5.80173 8.82079 5.75486 8.75742 5.84416C8.69406 5.93345 8.50507 5.80173 8.50507 5.80173L8.56389 6.12343Z"
        fill="#6F3E1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.54131 6.055H8.46185C8.46185 6.055 8.40805 6.2653 8.28466 6.16015C8.16127 6.055 7.98718 6.09207 7.98718 6.09207C7.98718 6.09207 8.00279 5.96622 8.19225 5.91619C8.3817 5.86617 8.46185 5.45993 8.46185 5.45993C8.46185 5.45993 8.72995 5.36013 8.89305 5.56464C9.05615 5.76915 9.28592 5.6819 9.28592 5.6819C9.28592 5.6819 9.45386 5.77739 9.36989 5.91619C9.28592 6.055 9.14268 6.15654 9.01786 6.055C8.89305 5.95346 8.54131 6.055 8.54131 6.055Z"
        fill="#009D29"
      />
    </g>
  </svg>
);

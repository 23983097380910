import { SQLDriverWithDataMapping } from '@transcend-io/privacy-types';
import { createEnum } from '@transcend-io/type-utils';

// The valid drivers indexed by type
const DATA_MAP_DRIVERS = createEnum(Object.values(SQLDriverWithDataMapping));

/**
 * Test if a string is a driver that supports data mapping
 *
 * @param driver - The driver to test
 * @returns True if a valid driver that supports data mapping
 */
export function databaseDriverHasDataMappingSupport(
  driver: string,
): driver is SQLDriverWithDataMapping {
  return !!driver && driver in DATA_MAP_DRIVERS;
}

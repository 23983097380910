import { mkType, SchemaToType } from '@main/schema-utils';

export const ClassificationConfidence = mkType({
  name: 'ClassificationConfidence',
  comment: 'The percent of guesses in the categories high, medium,and low',
  fields: {
    high: {
      comment: 'The percent of guesses in the high category',
      type: 'int',
    },
    medium: {
      comment: 'The percent of guesses in the medium category',
      type: 'int',
    },
    low: {
      comment: 'The percent of guesses in the low category',
      type: 'int',
    },
  },
});

/** Override type */
export type ClassificationConfidence = SchemaToType<
  typeof ClassificationConfidence
>;

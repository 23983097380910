/**
 * Sombra Job Queue codecs.
 */
import * as t from 'io-ts';

import { valuesOf } from '@transcend-io/type-utils';

import { dbModelId } from '@main/schema-utils';

import {
  AsyncJobQueueStatus,
  ClassificationMethod,
  ContentClassificationJobStatus,
  SombraJobType,
} from '../enums';
import { LLMGuesses, RegexClassificationResult } from './classification';

export const SombraPollingBody = t.partial({ limit: t.number });

/** Override type. */
export type SombraPollingBody = t.TypeOf<typeof SombraPollingBody>;

const JobResult = t.intersection([
  t.type({
    /** Organization Id. */
    organizationId: dbModelId('organization'),
    /** jobId */
    id: t.string,
    /** Foreign key to data silo */
    dataSiloId: dbModelId('dataSilo'),
  }),
  t.partial({
    /** Unix epoch when job sent to processor */
    dequeuedAt: t.number,
  }),
]);

export const ClassificationJobResult = t.intersection([
  JobResult,
  t.type({
    /** The type of job to be run */
    jobType: valuesOf(ClassificationMethod),
    /** Foreign key to dataPoint */
    dataPointId: dbModelId('dataPoint'),
    /** Foreign key to subDataPoint related to job */
    subDataPointId: dbModelId('subDataPoint'),
    /** subDataPoint name */
    subDataPoint: t.string,
    /** fully qualified identifier (including dataPointLevels) */
    subDataPointFQId: t.string,
    /** Unix epoch when job enqueued for processing */
    scheduledAt: t.number,
    /** Run status. */
    classificationStatus: valuesOf(ContentClassificationJobStatus),
    /** Error count */
    errorCount: t.number,
    /** Context for classification job */
    context: t.UnknownRecord,
    /** If we've performed a non-null check when sampling data for this column. */
    nonNullCheckCompleted: t.boolean,
  }),
  t.partial({
    /** optional description */
    description: t.union([t.string, t.null]),
    /** Unix epoch when job completed processing */
    completedAt: t.number,
    /** Error description, if any. */
    error: t.string,
    /** S3 Key for encrypted samples */
    encryptedSamplesS3Key: t.string,
  }),
]);

/** Override type. */
export type ClassificationJobResult = t.TypeOf<typeof ClassificationJobResult>;

export const LLMClassificationJobResult = t.intersection([
  ClassificationJobResult,
  t.type({
    /** The type of job to be run */
    jobType: t.literal(ClassificationMethod.TRANSCEND_LLM_CLASSIFY),
  }),
  t.partial({
    /** The result of the LLM classification */
    result: LLMGuesses,
  }),
]);

/** Override type. */
export type LLMClassificationJobResult = t.TypeOf<
  typeof LLMClassificationJobResult
>;

export const RegexClassificationJobResult = t.intersection([
  ClassificationJobResult,
  t.type({
    /** The type of job to be run */
    jobType: t.literal(ClassificationMethod.CUSTOM_REGEX_CLASSIFY),
  }),
  t.partial({
    /** The result of the LLM classification */
    result: RegexClassificationResult,
  }),
]);

/** Override type. */
export type RegexClassificationJobResult = t.TypeOf<
  typeof RegexClassificationJobResult
>;

export const AllClassificationResultType = t.intersection([
  t.union([LLMClassificationJobResult, RegexClassificationJobResult]),
  t.partial({
    /** Created Date, stored in DynamoDB as epoch in seconds */
    createdAt: t.string,
    /** Updated Date, stored in DynamoDB as epoch in seconds */
    updatedAt: t.string,
  }),
]);

/** Override type. */
export type AllClassificationResultType = t.TypeOf<
  typeof AllClassificationResultType
>;

/**
 * Array of possible results from Sombra (currently only AllClassificationResultType)
 */
export const SombraResultsArray = t.array(AllClassificationResultType);

/** Override type. */
export type SombraResultsArray = t.TypeOf<typeof SombraResultsArray>;

export const CSombraJobQueueResult = t.intersection([
  t.type({
    /** Organisation Id. */
    organizationId: dbModelId('organization'),
    /** jobId */
    id: t.string,
    /* Integration specific schedule for next plugin run - Unix epoch */
    scheduledAt: t.number,
    /** composite range key for secondary index */
    'organizationId#scheduledAt': t.string,
    /** Run status. */
    status: valuesOf(AsyncJobQueueStatus),
    /** The type of job to be run */
    jobType: valuesOf(SombraJobType),
    /** Foreign key to dataSilo */
    dataSiloId: dbModelId('dataSilo'),
    /** Context. */
    context: t.UnknownRecord,
    /** Error count */
    errorCount: t.number,
    /** Unix epoch in SECONDS to allow for automatic DynamoDB cleanup. */
    expiresAt: t.number,
  }),
  t.partial({
    /** Error description, if any. */
    error: t.string,
    /** Sparse foreign key to dataPoint related to job (if applicable) */
    dataPointId: dbModelId('dataPoint'),
    /** Sparse foreign key to subDataPoint related to job (if applicable) */
    subDataPointId: dbModelId('subDataPoint'),
    /** fully qualified identifier (including dataPointLevels) */
    subDataPointFQId: t.string,
    /** optional description */
    description: t.union([t.string, t.null]),
    /** subDataPoint name (if applicable) */
    subDataPoint: t.string,
    /** If we've performed a non-null check when sampling data for this column. */
    nonNullCheckCompleted: t.boolean,
  }),
]);

/** Override types. */
export type CSombraJobQueueResult = t.TypeOf<typeof CSombraJobQueueResult>;

export const SombraJob = t.intersection([
  t.type({
    /** The actual job */
    jobs: t.array(CSombraJobQueueResult),
  }),
  t.partial({
    /** Organization secrets for MTS */
    secrets: t.type({
      tenantParameters: t.string,
      tenantSecrets: t.string,
    }),
  }),
]);

/** Override types. */
export type SombraJob = t.TypeOf<typeof SombraJob>;

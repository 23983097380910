import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CircleDottedLine: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2.34949C8.7072 1.88454 7.2928 1.88454 6 2.34949"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.10449 6.90531C2.35446 5.5555 3.06068 4.33258 4.10485 3.44141"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.10485 12.5577C3.0607 11.6665 2.35448 10.4436 2.10449 9.09375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 13.6512C7.2928 14.1161 8.7072 14.1161 10 13.6512"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8956 6.90531C13.6457 5.5555 12.9394 4.33258 11.8953 3.44141"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8953 12.5577C12.9394 11.6665 13.6456 10.4436 13.8956 9.09375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

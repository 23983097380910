/* eslint max-len: 0 */
import { SIDEBAR_TRANSITION } from '@main/theme';
import React, { SVGAttributes } from 'react';
import { useTheme } from 'styled-components';

/**
 * TranscendLogo Props
 */
export interface TranscendLogoProps extends SVGAttributes<SVGSVGElement> {
  /** Override circle color as transcend */
  transcendSolid?: boolean;
  /** Hide workmark and show logo only? */
  hideWordmark?: boolean;
}

/**
 * The svg of the transcend logo
 */
export const TranscendLogo: React.FC<TranscendLogoProps> = ({
  transcendSolid,
  hideWordmark = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <svg
      width="154"
      height="37"
      viewBox="0 0 228 55"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Transcend Horizontal Logo (Color)"
        fill={
          transcendSolid ? theme.colors.transcend : theme.colors.transcendNavy
        }
      >
        <g id="Logomark">
          <path d="M3.92739 31.5813C3.88851 31.3675 3.86907 31.1731 3.83018 30.9594C3.69408 29.9682 2.76085 29.2685 1.76928 29.4046C0.777714 29.5406 0.0777856 30.4735 0.213883 31.4647C0.252768 31.6979 0.291653 31.9505 0.330538 32.1837C0.816599 35.0406 1.74984 37.7809 3.07193 40.3074C3.40245 40.9293 4.02461 41.2791 4.68565 41.2791C4.97729 41.2791 5.26892 41.2208 5.54112 41.0654C6.43547 40.5989 6.78544 39.4912 6.29938 38.5972C5.15227 36.4399 4.35513 34.0689 3.92739 31.5813V31.5813Z" />
          <path d="M9.04055 11.5636C9.50717 11.5636 9.99323 11.3692 10.3432 11.0194C12.2486 9.07595 14.4261 7.48231 16.8564 6.27736C17.7702 5.83037 18.1396 4.74203 17.6925 3.8286C17.2453 2.91517 16.1565 2.54591 15.2427 2.99291C12.443 4.37277 9.91546 6.21906 7.71846 8.45404C7.01854 9.17312 7.03798 10.3392 7.75735 11.0388C8.12676 11.3887 8.59338 11.5636 9.04055 11.5636V11.5636Z" />
          <path d="M23.3116 4.19787L23.4088 4.17843C26.0724 3.73144 28.7749 3.73144 31.3996 4.19787C31.4969 4.2173 31.6135 4.2173 31.7107 4.2173C32.5856 4.2173 33.3633 3.59539 33.5189 2.7014C33.6939 1.71024 33.0328 0.757938 32.0218 0.583027C28.9693 0.0582917 25.8196 0.0582917 22.7477 0.563592L22.6894 0.583027C21.7173 0.777373 21.0757 1.6908 21.2312 2.66253C21.3868 3.67313 22.32 4.35335 23.3116 4.19787V4.19787Z" />
          <path d="M5.57967 14.0512C4.68532 13.5848 3.5771 13.9152 3.11048 14.8092C1.63285 17.5883 0.660725 20.5618 0.213548 23.6519C0.0580086 24.6431 0.757938 25.576 1.7495 25.7314C1.84672 25.7509 1.92449 25.7509 2.0217 25.7509C2.91605 25.7509 3.69375 25.0901 3.82985 24.1961C4.2187 21.5141 5.07417 18.9488 6.33793 16.5389C6.82399 15.6254 6.47402 14.5177 5.57967 14.0512V14.0512Z" />
          <path d="M50.7832 23.6131C50.8221 23.8074 50.8416 24.0212 50.8804 24.2155C51.0165 25.129 51.7942 25.7897 52.6886 25.7897C52.7664 25.7897 52.8636 25.7897 52.9608 25.7703C53.9524 25.6343 54.6523 24.7014 54.5162 23.7102C54.4773 23.477 54.4384 23.2438 54.3995 23.0106C53.9135 20.1537 52.9802 17.4134 51.6387 14.8675C51.1721 13.9735 50.0639 13.6237 49.1695 14.1095C48.2751 14.576 47.9252 15.6837 48.4112 16.5777C49.5389 18.7544 50.3555 21.1254 50.7832 23.6131Z" />
          <path d="M44.3674 44.1555C42.462 46.0989 40.2844 47.6926 37.8541 48.8975C36.9403 49.3445 36.5709 50.4328 37.0181 51.3463C37.3292 51.9876 37.9902 52.3569 38.6513 52.3569C38.9235 52.3569 39.1957 52.2986 39.4484 52.1625C42.2481 50.7827 44.7756 48.9364 46.9726 46.682C47.6726 45.9629 47.6531 44.7968 46.9338 44.0972C46.2144 43.4169 45.0673 43.4364 44.3674 44.1555Z" />
          <path d="M39.487 3.03179C38.5732 2.58479 37.4844 2.95405 37.0372 3.86748C36.59 4.7809 36.9594 5.86924 37.8732 6.31624C40.3035 7.52119 42.5005 9.11483 44.4059 11.0583C44.7559 11.4275 45.2419 11.6025 45.7085 11.6025C46.1752 11.6025 46.6223 11.4275 46.9917 11.0777C47.7111 10.3781 47.7306 9.212 47.0306 8.49292C44.8142 6.25794 42.2867 4.41165 39.487 3.03179V3.03179Z" />
          <path d="M31.3409 51.0159C28.6578 51.4629 25.9164 51.4629 23.2722 50.9964C22.2807 50.8215 21.328 51.4823 21.153 52.4735C20.978 53.4646 21.6391 54.4169 22.6306 54.5919C24.1666 54.8639 25.7414 55 27.3163 55C28.8522 55 30.4076 54.8639 31.9242 54.6113L32.0019 54.5919C32.9935 54.4169 33.6351 53.4841 33.4796 52.4735C33.3046 51.5017 32.3324 50.841 31.3409 51.0159Z" />
          <path d="M16.8174 48.8781C14.3871 47.6731 12.1901 46.0795 10.3042 44.136C9.60427 43.417 8.43772 43.3975 7.71835 44.0972C6.99898 44.7968 6.97953 45.9629 7.67946 46.682C9.85702 48.917 12.3845 50.7632 15.2037 52.1625C15.4564 52.2986 15.7481 52.3569 16.0203 52.3569C16.7008 52.3569 17.3424 51.9876 17.6534 51.3463C18.1006 50.4134 17.7312 49.3251 16.8174 48.8781V48.8781Z" />
          <path d="M52.9416 29.4629C51.95 29.3074 51.0168 30.0071 50.8612 30.9982C50.4724 33.6802 49.6364 36.2456 48.3531 38.6555C47.8865 39.5495 48.2171 40.6572 49.1114 41.1237C49.3836 41.2597 49.6752 41.3375 49.9669 41.3375C50.6279 41.3375 51.2501 40.9876 51.5806 40.3657C53.0388 37.5866 54.0109 34.6131 54.4775 31.523C54.6331 30.5318 53.9331 29.5989 52.9416 29.4629V29.4629Z" />
          <path d="M16.234 15.47C18.8199 13.0989 22.0084 11.6413 25.4886 11.2526C26.7135 11.1166 27.9773 11.1166 29.1827 11.2526C30.1937 11.3693 31.0881 10.6502 31.2047 9.63957C31.3214 8.62897 30.602 7.73497 29.591 7.61837C28.1134 7.44345 26.5774 7.44345 25.0803 7.61837C20.8224 8.10423 16.9145 9.89222 13.7648 12.788C13.026 13.4682 12.9677 14.6343 13.6482 15.3728C14.0176 15.7615 14.5036 15.9558 14.9897 15.9558C15.4563 15.9558 15.8841 15.8003 16.234 15.47V15.47Z" />
          <path d="M27.3361 47.6926C28.0944 47.6926 28.8526 47.6537 29.6109 47.5565H29.6498C30.6608 47.4399 31.3802 46.5265 31.2441 45.5353C31.1274 44.5247 30.2136 43.8251 29.222 43.9417H29.2026C28.5804 44.0194 27.9583 44.0389 27.3361 44.0389C23.2338 44.0389 19.2869 42.5035 16.2539 39.7049C15.5151 39.0247 14.3486 39.083 13.6681 39.8215C12.9876 40.5601 13.0459 41.7261 13.7847 42.4063C17.4982 45.8268 22.32 47.6926 27.3361 47.6926V47.6926Z" />
          <path d="M35.4435 13.2933C39.6042 15.6449 42.5205 19.6678 43.4732 24.2933C43.6482 25.1678 44.4065 25.7509 45.2619 25.7509C45.3786 25.7509 45.5147 25.7314 45.6313 25.712C46.6229 25.5177 47.2645 24.5459 47.0506 23.5548C45.8841 17.8799 42.3067 12.9823 37.2516 10.106C36.3767 9.6007 35.2491 9.91166 34.763 10.7862C34.2575 11.6802 34.5686 12.788 35.4435 13.2933Z" />
          <path d="M11.4705 18.3657C10.5567 17.9381 9.46794 18.3463 9.0402 19.2597C7.83477 21.8834 7.23206 24.682 7.23206 27.5583C7.23206 28.3162 7.27094 29.0742 7.36815 29.8321C7.60146 31.9505 8.16529 33.9912 9.0402 35.8957C9.35128 36.576 10.0123 36.9647 10.7123 36.9647C10.965 36.9647 11.2178 36.9063 11.4705 36.7897C12.3843 36.3622 12.7926 35.2933 12.3843 34.3604C11.6649 32.7862 11.1983 31.1148 11.0039 29.4046C10.9261 28.7827 10.9067 28.1608 10.9067 27.5389C10.9067 25.1873 11.4122 22.894 12.3843 20.7562C12.7926 19.8816 12.4038 18.7933 11.4705 18.3657V18.3657Z" />
          <path d="M47.0503 31.5813C47.2447 30.5901 46.6031 29.6184 45.631 29.424C44.6394 29.2297 43.6673 29.871 43.4729 30.8427C42.5202 35.5071 39.6038 39.53 35.4626 41.8816C34.5877 42.3869 34.2766 43.4947 34.7821 44.3692C35.1126 44.9523 35.7348 45.2827 36.3764 45.2827C36.6875 45.2827 36.9985 45.2049 37.2707 45.0495C42.3452 42.1926 45.9032 37.2756 47.0503 31.5813Z" />
          <path
            clipRule="evenodd"
            d="M36.6098 18.7738C38.9624 21.242 40.2261 24.4876 40.1484 27.8887C40.0706 31.3092 38.6513 34.477 36.221 36.8286C33.8101 39.1219 30.6993 40.3657 27.3941 40.3657H27.083C20.0254 40.2102 14.426 34.341 14.5815 27.2862C14.6593 23.8851 16.0786 20.6979 18.5478 18.3463C21.017 15.9947 24.2444 14.7314 27.6663 14.8092C31.0687 14.8869 34.2573 16.3056 36.6098 18.7738ZM18.2173 27.3834C18.1007 32.4169 22.1058 36.6148 27.1414 36.7314C32.177 36.848 36.3766 32.8445 36.4932 27.8109C36.5516 25.3816 35.6572 23.0689 33.9657 21.3003C32.2742 19.5318 29.9995 18.5212 27.5691 18.4629C25.1388 18.4046 22.8252 19.2986 21.0559 20.9894C19.2867 22.6802 18.2756 24.954 18.2173 27.3834Z"
          />
        </g>
        <g
          id="Wordmark"
          fill={theme.colors.transcendNavy}
          opacity={hideWordmark ? 0 : 1}
          style={{ transitionProperty: 'opacity', ...SIDEBAR_TRANSITION }}
        >
          <path d="M61.8729 14.6667H82.518V19.0466H74.7986V40.3378H69.6048V19.0466H61.8729V14.6667Z" />
          <path d="M85.8202 24.8654H85.9293C87.041 22.7678 88.2996 21.7063 90.4518 21.7063C90.8405 21.6831 91.2301 21.7315 91.6013 21.849V26.1198H91.4923C88.2996 25.7968 86.0006 27.4833 86.0006 31.364V40.3378H81.1172V21.9203H85.8202V24.8654Z" />
          <path d="M104.741 38.2905H104.665C103.73 39.6917 102.404 40.7699 99.2447 40.7699C95.4689 40.7699 92.8175 38.7939 92.8175 35.1314C92.8175 31.0745 96.1192 29.7824 100.214 29.2076C103.264 28.7881 104.665 28.528 104.665 27.1099C104.665 25.78 103.625 24.92 101.577 24.92C99.2783 24.92 98.1665 25.759 98.0239 27.5043H93.6817C93.8244 24.2739 96.2325 21.4379 101.615 21.4379C107.145 21.4379 109.372 23.9131 109.372 28.2217V37.615C109.372 39.0121 109.586 39.8385 110.018 40.1616V40.342H105.315C105.026 39.977 104.845 39.1169 104.741 38.2905ZM104.774 33.8392V31.0745C103.914 31.578 102.584 31.8632 101.363 32.1527C98.8168 32.7275 97.5582 33.3022 97.5582 35.0265C97.5582 36.7508 98.7077 37.3591 100.432 37.3591C103.23 37.3591 104.774 35.6348 104.774 33.8392Z" />
          <path d="M117.511 24.4333H117.616C118.874 22.4573 120.552 21.4169 123.07 21.4169C126.875 21.4169 129.425 24.2907 129.425 28.3098V40.3378H124.546V29.0104C124.546 27.0386 123.397 25.6541 121.349 25.6541C119.197 25.6541 117.616 27.3784 117.616 29.8914V40.3378H112.732V21.9203H117.511V24.4333Z" />
          <path d="M131.876 34.3762H136.403C136.726 36.4949 138.232 37.4304 140.422 37.4304C142.612 37.4304 143.778 36.5913 143.778 35.3118C143.778 33.5539 141.446 33.3735 138.933 32.8701C135.631 32.224 132.401 31.3262 132.401 27.3071C132.401 23.288 135.702 21.4337 139.902 21.4337C144.752 21.4337 147.453 23.8418 147.802 27.5378H143.401C143.187 25.5619 141.932 24.8445 139.847 24.8445C137.947 24.8445 136.688 25.5619 136.688 26.8918C136.688 28.4734 139.13 28.616 141.752 29.153C144.84 29.7991 148.251 30.6634 148.251 34.9678C148.251 38.668 145.091 40.8915 140.493 40.8915C135.144 40.8748 132.166 38.2905 131.876 34.3762Z" />
          <path d="M150.076 31.1458C150.076 25.6541 153.667 21.4169 159.268 21.4169C164.042 21.4169 166.987 24.1816 167.633 28.1671H162.855C162.729 27.3699 162.321 26.6444 161.705 26.1235C161.089 25.6027 160.305 25.3214 159.498 25.3311C156.562 25.3311 155.047 27.5924 155.047 31.1458C155.047 34.6279 156.448 36.9647 159.427 36.9647C161.403 36.9647 162.783 35.9201 163.123 33.8392H167.826C167.503 37.7534 164.47 40.8748 159.536 40.8748C153.7 40.8748 150.076 36.6417 150.076 31.1458Z" />
          <path d="M169.629 31.1123C169.629 25.6583 173.325 21.4169 178.712 21.4169C184.636 21.4169 187.795 25.9394 187.795 32.5135H174.437C174.798 35.3495 176.38 37.1074 179.14 37.1074C181.045 37.1074 182.157 36.2431 182.698 34.8461H187.506C186.826 38.11 183.919 40.8748 179.178 40.8748C173.074 40.8748 169.629 36.6039 169.629 31.1123ZM174.513 29.1363H182.698C182.551 26.8037 181.02 25.1885 178.784 25.1885C176.199 25.1885 174.941 26.7323 174.513 29.1363Z" />
          <path d="M194.965 24.4333H195.07C196.329 22.4573 198.007 21.4169 200.524 21.4169C204.329 21.4169 206.88 24.2907 206.88 28.3098V40.3378H201.997V29.0104C201.997 27.0386 200.847 25.6541 198.8 25.6541C196.648 25.6541 195.066 27.3784 195.066 29.8914V40.3378H190.187V21.9203H194.965V24.4333Z" />
          <path d="M209.23 31.1459C209.23 25.4025 212.498 21.4169 217.092 21.4169C218.123 21.3715 219.149 21.6004 220.063 22.0801C220.978 22.5599 221.749 23.2734 222.298 24.1481H222.407V14.6667H227.29V40.3378H222.587V37.9339H222.512C221.438 39.6917 219.605 40.8706 217.02 40.8706C212.389 40.8748 209.23 37.1074 209.23 31.1459ZM222.512 31.2549C222.512 27.7351 221.367 25.3815 218.241 25.3815C215.548 25.3815 214.184 27.7477 214.184 31.1584C214.184 34.7161 215.586 36.7969 218.099 36.7969C221.006 36.776 222.512 34.628 222.512 31.2549Z" />
        </g>
      </g>
    </svg>
  );
};

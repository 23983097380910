import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Gear: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4.16688 9.37947L3.07021 8.14281C2.83223 7.87443 2.79267 7.48388 2.97202 7.17324L4.03803 5.32677C4.21737 5.01614 4.57537 4.85512 4.92677 4.92702L6.54618 5.25838L7.62091 4.63786L8.14361 3.06981C8.25703 2.72952 8.57548 2.5 8.93417 2.5H11.0663C11.4249 2.5 11.7434 2.72952 11.8568 3.06981L12.3795 4.63786L13.4542 5.25838L15.0737 4.92702C15.4251 4.85512 15.7831 5.01614 15.9624 5.32677L17.0284 7.17321C17.2078 7.48385 17.1682 7.87441 16.9302 8.14277L15.8335 9.37947V10.6205L16.9302 11.8572C17.1682 12.1256 17.2078 12.5161 17.0284 12.8268L15.9624 14.6732C15.783 14.9838 15.425 15.1448 15.0736 15.0729L13.4542 14.7416L12.3795 15.362L11.8568 16.9301C11.7434 17.2704 11.4249 17.5 11.0662 17.5H8.93418C8.57549 17.5 8.25704 17.2705 8.14361 16.9302L7.62091 15.362L6.54623 14.7416L4.92676 15.073C4.57536 15.1449 4.21737 14.9839 4.03803 14.6732L2.972 12.8268C2.79266 12.5161 2.83222 12.1256 3.07021 11.8572L4.16688 10.6205V9.37947Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="10"
      cy="10"
      r="2.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React, { PropsWithChildren, ReactElement } from 'react';
import {
  FormProvider,
  useForm as rhUseForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types';

/**
 * Copied from react-hook form type definition
 *
 * @param opts - useForm params
 */
export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>(
  opts?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext> => {
  const formMethods = rhUseForm<TFieldValues, TContext>({
    criteriaMode: 'all',
    mode: 'onChange',
    // unregister values once form items are unmounted
    shouldUnregister: true,
    ...(opts ?? {}),
  });

  /**
   * `formState` must be read or invoked in order to subscribe to changes.
   * See https://react-hook-form.com/api/useform/formstate
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isValid, isDirty } = formMethods.formState; // eslint-disable-line @typescript-eslint/no-unused-vars
  return formMethods;
};

interface IFormProps<TFieldValues extends FieldValues> {
  /** on submit handler, copied from react hook form to avoid type issues */
  onSubmit?: (
    data: TFieldValues,
    event?: React.BaseSyntheticEvent,
  ) => any | Promise<any>;
  /** the methods returned from useForm */
  useFormMethods: UseFormReturn<TFieldValues>;
  /** html id */
  id?: string;
  /** Style */
  style?: React.CSSProperties;
  /** Is autocomplete disabled for this form? */
  autoComplete?: 'off';
}

/**
 * The common form wrapper that handles the weirdness around react-hook-form and
 * simplifies the form field wrappers
 */
export const Form = <TFieldValues extends FieldValues = FieldValues>({
  children,
  onSubmit = () => {
    /* do nothing */
  },
  useFormMethods,
  ...rest
}: PropsWithChildren<IFormProps<TFieldValues>>): ReactElement => (
  <FormProvider {...useFormMethods}>
    <form
      onSubmit={(e) => {
        // Stop submissions from propagating from any React-nested forms to parent forms
        // Note that forms should NOT be nested in the UI, but forms may be rendered in modals, for example,
        // causing them to be React children but not DOM children.
        // https://github.com/orgs/react-hook-form/discussions/3704#discussioncomment-265857
        e.stopPropagation();
        return useFormMethods.handleSubmit(onSubmit as any)(e);
      }}
      {...rest}
    >
      {children}
    </form>
  </FormProvider>
);

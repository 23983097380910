import { mkType, SchemaToType } from '@main/schema-utils';

export const PromptGroupPreview = mkType({
  name: 'PromptGroupPreview',
  comment: 'A preview of a prompt group',
  fields: {
    id: {
      comment: 'The id of the prompt',
      modelName: 'promptGroup',
      type: 'id',
    },
    title: {
      comment: 'The title of the prompt',
      type: 'string',
    },
  },
});

/** Override type */
export type PromptGroupPreview = SchemaToType<typeof PromptGroupPreview>;

import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';

import type { StringKeys } from '@transcend-io/type-utils';

/**
 * This is basically `pickBy` with a simplified type
 *
 * ```typescript
 * filterObjectBy({ dog: 2, cat: 3 }, (v) => v === 2);
 * // Returns { dog: 2 }
 * ```
 *
 * @param obj - The object to filter
 * @param by - The function to filter by
 * @returns A copy of the object with only key/values matching `by` clause
 */
export function filterObjectBy<T extends {}>(
  obj: T,
  by: (v: T[keyof T], k: StringKeys<T>) => boolean,
): Partial<T> {
  // Loop over and copy each attribute if it exists
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const picked = pickBy(obj, by as any);

  // We know this will always be true
  return picked as Partial<T>;
}

/**
 * Filter an object so that it only contains certain attributes.
 *
 * If the object did not have one of the attributes in the first place, the resulting object will not have a key for that attribute.
 *
 * ```typescript
 * // Returns { dog: 2 }
 * filterObject({ dog: 2, cat: 3 }, ['dog']);
 * ```
 *
 * @param obj - The object to filter
 * @param attributes - The attributes to keep
 * @returns A copy of the object containing only attributes in `attributes`
 */
export function filterObject<T extends object, K extends keyof T>(
  obj: T,
  attributes: K[],
): Pick<T, K> {
  const hasAttr = keyBy(attributes);
  // Loop over and copy each attribute if it exists
  const picked = pickBy(obj, (_, k) => hasAttr[k]);

  // We know this will always be true
  return picked as Pick<T, K>;
}

/**
 * The inverse of filter object
 *
 * ```typescript
 * // Returns { cat: 3 }
 * filterObjectInverse({ dog: 2, cat: 3 }, ['dog']);
 * ```
 *
 * @param obj - The object to filter
 * @param attributes - The attributes to remove
 * @returns A copy of the object containing all but the attributes in `attributes`
 */
export function filterObjectInverse<T extends object, K extends keyof T>(
  obj: T,
  attributes: K[],
): Exclude<T, K> {
  const hasAttr = keyBy(attributes);
  // Loop over and copy each attribute if it exists
  const picked = pickBy(obj, (_, k) => !hasAttr[k]);

  // We know this will always be true
  return picked as Exclude<T, K>;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Location, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';

import type { ObjByString } from '@transcend-io/type-utils';

import { useRedirect } from './useRedirect';

export const persistedFiltersSlice = createSlice({
  name: 'PersistedFilters',
  initialState: {} as { [key in string]: any },
  reducers: {
    // set the filters to persist
    setFilters: (
      state,
      {
        payload: { newFilters, filterKey },
      }: PayloadAction<{
        /** the new filters to set */
        newFilters: ObjByString;
        /** the filter key to use */
        filterKey: string;
      }>,
    ) => ({
      ...state,
      [filterKey]: newFilters,
    }),
  },
});
export const filtersSelector = createSelector(
  (state: any) => state.PersistedFilters,
  (state) => state,
);

/**
 * Parse a location object until table filters
 *
 * TODO: https://transcend.height.app/T-6329 - add support for pagination/sort
 *
 * @param location - Location to parse filters from
 * @param expectedKeys - Expected keys in filters to be parsed
 * @param validatePersistedFilter - A function to validate/filter the filters that are persisted to the url
 * @returns The filter input
 */
function locationToFilters<TFilters extends ObjByString>(
  location: Location,
  expectedKeys: string[],
  validatePersistedFilter?: (key: string, value: any) => boolean,
): {
  /** the current searchText, used for change detection */
  searchText?: string;
  /** the current filters, used for change detection */
  filters: FiltersAsStrings<TFilters>;
} {
  // parse the url query params
  const params = new URLSearchParams(location.search);
  const parsedFilters = {} as TFilters;

  // store the parsed state
  const searchText = params.get('searchText') ?? undefined;

  expectedKeys.forEach((key) => {
    if (params.has(key)) {
      try {
        parsedFilters[key as keyof TFilters] = JSON.parse(
          params.get(key)!,
        ) as TFilters[keyof TFilters];
      } catch (e) {
        // ignore failure
      }
    }

    // default to empty list if none
    if (!parsedFilters[key]) {
      parsedFilters[key as keyof TFilters] = [] as TFilters[keyof TFilters];
    }
  });

  return {
    filters: !validatePersistedFilter
      ? parsedFilters
      : // only load valid filters
        (Object.fromEntries(
          Object.entries(parsedFilters).filter(([key, value]) =>
            validatePersistedFilter(key, value),
          ),
        ) as TFilters),
    searchText,
  };
}

/**
 * Filters all get parsed to strings in order to be preserved in URL
 */
export type FiltersAsStrings<TFilters extends ObjByString> = {
  [k in keyof TFilters]: TFilters[k] extends string[] ? TFilters[k] : string[];
};

/**
 * The returned state of this hook
 */
export interface PersistedFilters<TFilters extends ObjByString> {
  /** The current set of filters */
  filters: FiltersAsStrings<TFilters>;
  /** Callback function to update filters in state and in URL */
  setFilters: (newFilters: FiltersAsStrings<TFilters>) => void;
  /** Function to clear all filters */
  resetFilters: () => void;
  /** Current search text */
  searchText: string;
  /** Callback to set search text, will be persisted to URL */
  setSearchText: (newSearchText: string) => void;
}
export const RESTORE_FILTERS_URL_PARAM = 'restoreFilters';

/**
 * hook to persist table filter state to the url
 *
 * @deprecated use useQueryParamJson instead
 * @param args - hook args
 * @returns hook result
 */
export const usePersistFiltersToUrl = <TFilters extends ObjByString>({
  defaultFilters,
  validatePersistedFilter,
  filterCacheKey,
}: {
  /** The default filters to set when none are provided */
  defaultFilters: FiltersAsStrings<TFilters>;
  /** A function to validate/filter the filters that are persisted to the url */
  validatePersistedFilter?: (key: string, value: any) => boolean;
  /** The key to use in localStorage for the cached filters */
  filterCacheKey?: string;
}): PersistedFilters<TFilters> => {
  const redirect = useRedirect();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');

  const cachedFilters = useSelector(filtersSelector);
  const dispatch = useDispatch();
  const currentParams = new URLSearchParams(location.search);
  const areUrlFiltersEmpty = Object.keys(defaultFilters).every(
    (key) => !currentParams.has(key),
  );
  // load last filters if present and url has the restore filters flag
  const shouldRestoreFilters =
    areUrlFiltersEmpty &&
    filterCacheKey &&
    cachedFilters[filterCacheKey] &&
    currentParams.has(RESTORE_FILTERS_URL_PARAM);
  const [filters, setFilters] = useState<FiltersAsStrings<TFilters>>(
    shouldRestoreFilters ? cachedFilters[filterCacheKey!] : defaultFilters,
  );

  // Helper to set filters to URL and store in local state
  const onFiltersChange = (
    newFilters: FiltersAsStrings<TFilters>,
    newSearchText: string,
  ): void => {
    // use the existing query params as a base to avoid overriding any
    // extraneous params
    const newQuery = new URLSearchParams(location.search);
    // add/update/remove the known filters from the existing query params
    Object.entries(newFilters ?? {}).forEach(([key, value]) => {
      if (
        value.length > 0 &&
        // don't keep invalid values in the url
        (validatePersistedFilter?.(key, value) ?? true)
      ) {
        // stringify the query param values
        newQuery.set(key, JSON.stringify(value));
      } else if (newQuery.has(key)) {
        newQuery.delete(key);
      }
    });
    if (newSearchText) {
      newQuery.set('searchText', newSearchText);
    } else if (newQuery.has('searchText')) {
      newQuery.delete('searchText');
    }
    newQuery.delete(RESTORE_FILTERS_URL_PARAM);

    const currentQuery = new URLSearchParams(location.search);
    newQuery.sort();
    currentQuery.sort();

    const newQueryString = newQuery.toString();
    const currentQueryString = currentQuery.toString();
    // only navigate if the query string changes
    if (newQueryString !== currentQueryString) {
      if (filterCacheKey) {
        // update cached copy
        dispatch(
          persistedFiltersSlice.actions.setFilters({
            newFilters,
            filterKey: filterCacheKey,
          }),
        );
      }
      const newUrl = {
        ...location,
        key: location.key || location.pathname,
        search: newQueryString,
      };
      redirect(newUrl, false, !shouldRestoreFilters);
    }
    setFilters(newFilters);
    setSearchText(newSearchText);
  };

  // on first render & location change
  useEffect(() => {
    const { filters: parsedFilters, searchText: parsedSearchText } =
      locationToFilters(
        location,
        Object.keys(defaultFilters),
        validatePersistedFilter,
      );

    // if either state has changed, call the change handler
    if (!isEqual(parsedFilters, filters) || parsedSearchText !== searchText) {
      onFiltersChange(
        areUrlFiltersEmpty ? filters : { ...defaultFilters, ...parsedFilters },
        parsedSearchText || '',
      );
    }
  }, [location]);

  return {
    filters,
    searchText,
    setSearchText: (newSearchText) => {
      onFiltersChange(filters, newSearchText);
    },
    setFilters: (newFilters) => {
      onFiltersChange(newFilters, searchText);
    },
    resetFilters: () => {
      onFiltersChange(defaultFilters, '');
    },
  };
};

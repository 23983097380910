/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const PencilGrid: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4995 18.0028H4.99717C3.89214 18.0028 2.99634 17.107 2.99634 16.002V4.99742C2.99634 3.89239 3.89214 2.99658 4.99717 2.99658H16.0018C17.1068 2.99658 18.0026 3.89239 18.0026 4.99742V10.4997"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0026 10.4997H2.99634"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4995 2.99658V18.0028"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2958 20.7106L21.7113 17.2951C22.1018 16.9045 22.1018 16.2712 21.7113 15.8805L20.1246 14.2939C19.7339 13.9033 19.1007 13.9033 18.71 14.2939L15.2946 17.7093C15.107 17.8969 15.0015 18.1513 15.0015 18.4166V21.0037H17.5885C17.8538 21.0037 18.1083 20.8982 18.2958 20.7106Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

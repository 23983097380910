import React from 'react';

import { SvgProps } from '../enums';

export const FlagYT: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="12" fill="white" />
    <path
      // eslint-disable-next-line max-len
      d="M5.54518 10.6145C5.21224 10.7151 4.73258 10.9214 4.54072 11.0371C4.47865 11.0774 4.48429 11.1226 4.58022 11.2937C4.64794 11.4044 4.71565 11.5 4.72694 11.5C4.74387 11.5 4.93009 11.4245 5.13888 11.3289C5.34768 11.2384 5.61446 11.1189 5.73705 11.0975C5.8308 11.0811 5.91642 11.0656 5.96941 11.046C5.99075 11.0922 5.97763 11.0665 6.01651 11.1391C6.0724 11.2434 6.03761 11.241 6.13428 11.2695C6.2362 11.2996 6.48758 11.178 6.48758 11.178C6.78102 11.0874 6.94466 11.0774 7.99992 11.0774C9.05517 11.0774 9.21882 11.0874 9.51226 11.183C9.51226 11.183 9.7484 11.2546 9.77875 11.2633C9.8089 11.2719 9.82483 11.2787 9.8567 11.2719C9.88576 11.2657 9.91603 11.226 9.92942 11.2021C9.92942 11.2021 9.99432 11.0631 10.011 11.0143C10.0672 11.0384 10.1697 11.0698 10.2684 11.0975C10.3869 11.1327 10.6522 11.2384 10.861 11.3289C11.0697 11.4245 11.256 11.5 11.2729 11.5C11.2842 11.5 11.3519 11.4044 11.4196 11.2937C11.555 11.0572 11.5663 11.0774 11.0754 10.8509C10.4208 10.5491 9.7662 10.4132 9.70412 10.5591C9.69284 10.5868 9.68326 10.603 9.66777 10.6389C9.60216 10.6254 9.43043 10.5893 9.25832 10.5642C8.72787 10.4786 7.27196 10.4786 6.74151 10.5642C6.5694 10.5893 6.45304 10.6313 6.3558 10.6553C6.30075 10.5571 6.31227 10.5962 6.30098 10.5685C6.25584 10.4679 5.95712 10.4836 5.54518 10.6145H5.54518Z"
      fill="#DFDFDF"
    />
    <path
      // eslint-disable-next-line max-len
      d="M6.02473 4.3965C6.01336 4.56046 6.04747 4.67643 6.13653 4.76441C6.17632 4.8064 6.21043 4.85639 6.21043 4.85639C6.21043 4.85639 6.16495 4.90638 6.12705 4.94437C6.03799 5.03235 6.00199 5.14632 6.00199 5.26629C6.00199 5.39026 6.03799 5.47624 6.13463 5.58822C6.13463 5.58822 6.21621 5.62927 6.21422 5.68019C6.21223 5.73112 6.12705 5.78217 6.12705 5.78217C5.95272 5.98612 5.95841 6.22006 6.14032 6.41402L6.21801 6.5H8.00301H9.78611L9.87328 6.40202C10.0419 6.21207 10.0419 6.00612 9.87517 5.78817C9.87517 5.78817 9.79126 5.72832 9.7899 5.6782C9.78855 5.62807 9.86949 5.57822 9.86949 5.57822C10.0438 5.35827 10.04 5.15632 9.86001 4.94437C9.77853 4.85039 9.77664 4.8324 9.84106 4.78841C9.94528 4.71442 10.0097 4.46448 9.96234 4.31252C9.89223 4.08457 9.59094 3.93261 9.40713 4.03258C9.37302 4.05058 9.30481 4.10057 9.25364 4.14256C9.20248 4.18655 9.15511 4.22054 9.14564 4.22054C9.13806 4.22054 9.09258 4.18455 9.04521 4.14056C8.8614 3.9746 8.64007 3.9546 8.45626 4.14256C8.45626 4.14256 8.43006 4.24311 8.39147 4.24653C8.35287 4.24996 8.29862 4.15655 8.29862 4.15655C8.11102 3.9706 7.87605 3.9786 7.68467 4.17455C7.68467 4.17455 7.65783 4.25641 7.61456 4.24653C7.57128 4.23666 7.5357 4.14056 7.5357 4.14056C7.4239 4.03258 7.35306 4.03458 7.22421 4.03258C7.15004 4.03138 7.01566 4.04943 6.9345 4.1101C6.85334 4.17077 6.86767 4.24346 6.84902 4.23854C6.80212 4.22616 6.75048 4.15056 6.75048 4.15056C6.56857 3.98659 6.42266 3.9586 6.24644 4.05658C6.1081 4.13256 6.0361 4.24454 6.02473 4.3965Z"
      fill="#171796"
    />
    <path
      // eslint-disable-next-line max-len
      d="M6.14243 7.24595C6.14243 7.24595 6.21507 7.28133 6.21256 7.32665C6.21005 7.37198 6.13295 7.41719 6.13295 7.41719C6.08935 7.4664 6.04196 7.53725 6.0268 7.57268C5.96614 7.72423 6.01164 7.92696 6.13105 8.04308C6.20308 8.11591 6.20498 8.14543 6.13674 8.23203C6.10641 8.26943 6.0704 8.32257 6.05713 8.35013C5.91497 8.62961 6.12726 8.97996 6.44191 8.97996C6.56702 8.97996 6.66179 8.93862 6.74709 8.84415C6.81533 8.76936 6.85134 8.76739 6.91199 8.83431C7.00108 8.93666 7.1186 8.99964 7.21717 8.99964C7.33279 8.99964 7.40482 8.96224 7.52234 8.84612C7.52234 8.84612 7.56892 8.75509 7.61143 8.75558C7.65394 8.75607 7.69294 8.84809 7.69294 8.84809C7.86922 9.04491 8.04739 9.05081 8.28433 8.86383C8.28433 8.86383 8.33639 8.78806 8.381 8.78904C8.42561 8.79001 8.4644 8.86777 8.4644 8.86777C8.56866 8.96224 8.64637 8.99964 8.74873 8.99964C8.85109 8.99964 8.9269 8.95831 9.04632 8.83628C9.04632 8.83628 9.10797 8.7437 9.14489 8.73787C9.1818 8.73203 9.22071 8.80872 9.22071 8.80872C9.36666 8.94453 9.44627 8.98783 9.55242 8.98783C9.66236 8.98783 9.74007 8.95043 9.85001 8.84809C9.95237 8.75164 10.0073 8.63946 9.99407 8.54695C9.9808 8.44657 9.89361 8.26353 9.82916 8.20054C9.82916 8.20054 9.76454 8.19491 9.77419 8.14543C9.78384 8.09596 9.85949 8.04505 9.85949 8.04505C10.0452 7.81871 10.0471 7.64354 9.86328 7.41916C9.86328 7.41916 9.78648 7.37482 9.78746 7.32469C9.78843 7.27455 9.86707 7.22431 9.86707 7.22431C10.0452 7.00583 10.0434 6.80311 9.86328 6.60432L9.76661 6.5L7.99243 6.50197L6.21825 6.50394L6.13864 6.59644C5.95288 6.81098 5.95477 7.02945 6.14243 7.24595Z"
      fill="#D42E12"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8.91029 6.81085C8.9962 6.81085 9.06765 6.86935 9.08247 6.94649L9.08529 6.97621V7.28362L9.45307 7.08836C9.53447 7.04515 9.63591 7.07533 9.67963 7.15578C9.7185 7.22729 9.69869 7.31445 9.63664 7.36329L9.61142 7.3797L9.30314 7.54337L9.61142 7.70704L9.63664 7.72346C9.69869 7.77229 9.7185 7.85945 9.67963 7.93096C9.63591 8.01141 9.53447 8.0416 9.45307 7.99838L9.08529 7.80312V8.1455C9.08529 8.23682 9.00694 8.31085 8.91029 8.31085C8.82438 8.31085 8.75293 8.25236 8.73811 8.17522L8.73529 8.1455V7.84485L8.4461 7.99838C8.3647 8.0416 8.26326 8.01141 8.21954 7.93096C8.18067 7.85945 8.20048 7.77229 8.26253 7.72346L8.28775 7.70704L8.59603 7.54337L8.28775 7.3797L8.26253 7.36329C8.20048 7.31445 8.18067 7.22729 8.21954 7.15578C8.26326 7.07533 8.3647 7.04515 8.4461 7.08836L8.73529 7.2419V6.97621C8.73529 6.88488 8.81364 6.81085 8.91029 6.81085Z"
      fill="#E9D315"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M7.2107 6.81085C7.29661 6.81085 7.36807 6.86935 7.38288 6.94649L7.3857 6.97621V7.28362L7.75348 7.08836C7.83488 7.04515 7.93632 7.07533 7.98005 7.15578C8.01892 7.22729 7.9991 7.31445 7.93705 7.36329L7.91183 7.3797L7.60355 7.54337L7.91183 7.70704L7.93705 7.72346C7.9991 7.77229 8.01892 7.85945 7.98005 7.93096C7.93632 8.01141 7.83488 8.0416 7.75348 7.99838L7.3857 7.80312V8.1455C7.3857 8.23682 7.30735 8.31085 7.2107 8.31085C7.12479 8.31085 7.05334 8.25236 7.03852 8.17522L7.0357 8.1455V7.84485L6.74652 7.99838C6.66512 8.0416 6.56368 8.01141 6.51995 7.93096C6.48108 7.85945 6.5009 7.77229 6.56295 7.72346L6.58817 7.70704L6.89645 7.54337L6.58817 7.3797L6.56295 7.36329C6.5009 7.31445 6.48108 7.22729 6.51995 7.15578C6.56368 7.07533 6.66512 7.04515 6.74652 7.08836L7.0357 7.2419V6.97621C7.0357 6.88488 7.11405 6.81085 7.2107 6.81085Z"
      fill="#E9D315"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.40146 6.42825H5.50146V6.42826C5.50147 6.42868 5.50147 6.4442 5.50149 6.47265C5.44487 6.47091 5.40264 6.42947 5.40149 6.42834L5.40146 6.42832L5.40146 6.42839L5.40147 6.42868L5.40147 6.42984L5.40147 6.43442L5.40148 6.45235L5.40152 6.52097L5.40168 6.77059L5.40239 7.57027C5.40306 8.17812 5.40413 8.86353 5.40575 9.17604C5.4024 9.24543 5.42228 9.29953 5.46261 9.34451C5.48004 9.36395 5.49968 9.37989 5.51646 9.39283C5.52483 9.39929 5.53181 9.40453 5.53856 9.40959C5.54792 9.41661 5.55681 9.42328 5.56829 9.43238C5.6176 9.47146 5.6992 9.50055 5.7878 9.52344C5.88143 9.54763 5.99931 9.56881 6.13412 9.58479C6.40282 9.61665 6.7449 9.62859 7.10762 9.60015C7.25664 9.60174 7.36457 9.63071 7.44632 9.67023C7.52897 9.71019 7.58991 9.76336 7.64195 9.81968C7.66835 9.84825 7.69197 9.87706 7.71597 9.9064L7.71762 9.90841C7.74032 9.93616 7.76451 9.96572 7.78937 9.99169C7.84041 10.045 7.9098 10.1 8.00934 10.1C8.10947 10.1 8.17818 10.0445 8.22805 9.99028C8.25282 9.96338 8.27654 9.9328 8.2986 9.90436L8.29992 9.90265C8.32234 9.87375 8.3442 9.84556 8.36863 9.81755C8.41769 9.7613 8.47467 9.70877 8.55232 9.66938C8.62954 9.63021 8.73256 9.60133 8.87691 9.60012C9.2865 9.60639 9.63604 9.59645 9.89934 9.57175C10.0309 9.55941 10.1432 9.54318 10.2317 9.52274C10.3137 9.50379 10.3924 9.47771 10.4422 9.43535L10.4456 9.43252L10.4578 9.42216C10.4677 9.41353 10.4811 9.40121 10.4955 9.38636C10.5215 9.35963 10.5618 9.31339 10.5804 9.25899C10.597 9.21039 10.6013 9.16636 10.6015 9.13426C10.6015 9.11811 10.6006 9.10465 10.5995 9.09467C10.5992 9.092 10.5989 9.08956 10.5986 9.08739V6.42825V3.5V3.4H10.4986H8.00005H5.50146H5.40146V3.5V6.42825Z"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      // eslint-disable-next-line max-len
      d="M7.43441 4.93289C7.3002 5.18278 7.29453 5.29491 7.40605 5.49514C7.49301 5.64892 7.59508 5.74663 7.73496 5.80911C8.09979 5.9741 8.56669 5.85235 8.75005 5.5448C8.86346 5.35578 8.85023 5.04022 8.72547 4.90246L8.69145 4.86561L8.65931 4.97454C8.61395 5.12992 8.56858 5.20841 8.47785 5.29171C8.30772 5.44709 8.07332 5.48233 7.83137 5.38621C7.64423 5.31093 7.56862 5.20681 7.49868 4.91687L7.48166 4.84479L7.43441 4.93289L7.43441 4.93289Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.02197 1.88601C9.02197 2.25201 8.78597 2.52001 8.45397 2.52001C8.12197 2.52001 7.88797 2.25201 7.88797 1.88601C7.88797 1.51801 8.12197 1.25201 8.45397 1.25201C8.78597 1.25201 9.02197 1.51801 9.02197 1.88601ZM4.49597 2.50001V1.64601L4.83397 2.50001H4.98197L5.31197 1.64601V2.50001H5.51197V1.27201H5.27997L4.90997 2.21601L4.53597 1.27201H4.29797V2.50001H4.49597ZM5.89797 2.50001L6.02397 2.16001H6.49397L6.61997 2.50001H6.83197L6.35997 1.27201H6.15997L5.68797 2.50001H5.89797ZM6.25797 1.52601L6.08997 1.98001H6.42597L6.25797 1.52601ZM7.42197 2.50001V2.01001L7.86797 1.27201H7.64797L7.32397 1.82201L6.99797 1.27201H6.77197L7.21597 2.01001V2.50001H7.42197ZM8.45397 2.33401C8.23797 2.33401 8.09797 2.14601 8.09797 1.88601C8.09797 1.62601 8.23797 1.43801 8.45397 1.43801C8.66997 1.43801 8.81197 1.62601 8.81197 1.88601C8.81197 2.14601 8.66997 2.33401 8.45397 2.33401ZM9.69797 1.45601V2.50001H9.48997V1.45601H9.08197V1.27201H10.106V1.45601H9.69797ZM10.842 2.50001V1.45601H11.25V1.27201H10.226V1.45601H10.634V2.50001H10.842ZM12.248 2.31401V2.50001H11.446V1.27201H12.248V1.45601H11.65V1.78001H12.192V1.96201H11.65V2.31401H12.248Z"
      fill="#FF2500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.8882 9.40007C12.8882 9.40007 12.7182 9.0652 13.1482 8.89992C13.5781 8.73465 13.7883 8.9503 13.9777 9.15045C14.167 9.3506 14.1462 9.95472 14.1462 9.95472L13.6783 10.7032L12.2427 11L11.6707 10.7032L11 9.40007L11.0501 8.1207C11.0501 8.1207 11.118 7.63065 11.118 7.5292C11.118 7.42775 11.4295 6.64147 11.4295 6.64147L11.7246 6.34918L12.559 5.90527L12.952 5.709L13.4399 5.09231L13.3859 4.60137L12.8298 5.30964H12.3589L11.8686 5.51502L11.6707 5.7016L11.4295 5.709L11.3351 5.51502L11.5983 5.09231L11.996 4.69196L11.8131 4.3534L12.0838 4.23006L12.0317 4.04344L12.3322 4.23006L12.559 4.04344L12.6022 3.8718L12.0838 3.59397C12.0838 3.59397 12.5911 3.38113 12.8298 3.40334C13.0686 3.42556 13.2939 3.70653 13.2939 3.70653L13.9777 3.59397C13.9777 3.59397 13.7946 4.04344 13.8538 4.04344C13.9129 4.04344 14.2843 4.04344 14.2843 4.04344L14.1462 4.60137H14.6757L14.2843 5.0312L14.5898 5.19876L14.1249 5.51502L14.5898 5.82784L14.1576 6.11178L14.4011 6.58264L14.7844 6.34918L15 6.64147L14.6757 7.36122L14.1462 7.73682L13.6418 7.86391L13.1482 7.9922L12.952 8.1207L12.8298 7.62825L11.996 8.20012L11.5628 9.09685L11.6903 9.91166L11.996 10.3289L12.559 10.5305L13.2939 10.3924L13.6783 9.9248L13.77 9.44761C13.77 9.44761 13.5474 9.12355 13.3859 9.15045C13.2244 9.17735 12.8882 9.40007 12.8882 9.40007Z"
      fill="#DFDFDF"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      d="M12.0434 6.20967C12.0434 6.20967 12.9328 6.29216 13.1703 6.10335"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      d="M11.3741 6.78676C11.3741 6.78676 12.2635 6.86924 12.501 6.68044"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      d="M11.2248 7.31639C11.2248 7.31639 11.7048 7.39709 11.7523 7.23005"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.375 4.75C12.306 4.75 12.25 4.69404 12.25 4.625C12.25 4.55596 12.306 4.5 12.375 4.5C12.444 4.5 12.5 4.55596 12.5 4.625C12.5 4.69404 12.444 4.75 12.375 4.75Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.11176 9.40007C3.11176 9.40007 3.28179 9.0652 2.85183 8.89992C2.42188 8.73465 2.21173 8.9503 2.02234 9.15045C1.83296 9.3506 1.85377 9.95472 1.85377 9.95472L2.32174 10.7032L3.75731 11L4.32932 10.7032L5 9.40007L4.94988 8.1207C4.94988 8.1207 4.88205 7.63065 4.88205 7.5292C4.88205 7.42775 4.57047 6.64147 4.57047 6.64147L4.27542 6.34918L3.44096 5.90527L3.04803 5.709L2.56014 5.09231L2.61412 4.60137L3.17015 5.30964H3.64113L4.13138 5.51502L4.32932 5.7016L4.57047 5.709L4.66495 5.51502L4.40168 5.09231L4.00403 4.69196L4.18694 4.3534L3.91619 4.23006L3.96827 4.04344L3.66775 4.23006L3.44096 4.04344L3.39776 3.8718L3.91619 3.59397C3.91619 3.59397 3.40894 3.38113 3.17015 3.40334C2.93136 3.42556 2.70607 3.70653 2.70607 3.70653L2.02234 3.59397C2.02234 3.59397 2.20539 4.04344 2.14623 4.04344C2.08708 4.04344 1.71573 4.04344 1.71573 4.04344L1.85377 4.60137H1.32431L1.71573 5.0312L1.41017 5.19876L1.87509 5.51502L1.41017 5.82784L1.84244 6.11178L1.59889 6.58264L1.21559 6.34918L1 6.64147L1.32431 7.36122L1.85377 7.73682L2.35816 7.86391L2.85183 7.9922L3.04803 8.1207L3.17015 7.62825L4.00403 8.20012L4.43721 9.09685L4.30969 9.91166L4.00403 10.3289L3.44096 10.5305L2.70607 10.3924L2.32174 9.9248L2.22997 9.44761C2.22997 9.44761 2.45264 9.12355 2.61412 9.15045C2.77561 9.17735 3.11176 9.40007 3.11176 9.40007Z"
      fill="#DFDFDF"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      d="M3.95662 6.20967C3.95662 6.20967 3.06715 6.29216 2.82966 6.10335"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      d="M4.62593 6.78676C4.62593 6.78676 3.73646 6.86924 3.49897 6.68044"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      d="M4.77524 7.31639C4.77524 7.31639 4.29525 7.39709 4.24774 7.23005"
      stroke="black"
      strokeWidth="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.625 4.75C3.69404 4.75 3.75 4.69404 3.75 4.625C3.75 4.55596 3.69404 4.5 3.625 4.5C3.55596 4.5 3.5 4.55596 3.5 4.625C3.5 4.69404 3.55596 4.75 3.625 4.75Z"
      fill="black"
    />
  </svg>
);

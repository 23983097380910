import React from 'react';
import { useTheme } from 'styled-components';

import { FlexRow } from '../StyledWrappers';
import { DarkColorOption, LightColorOption } from './types';
import { StyledTagSection } from './wrappers';

export interface SingleTagProps extends JSX.IntrinsicAttributes {
  /** Label for the tag */
  label: string | JSX.Element;
  /** The color for the tag */
  color: keyof DarkColorOption | keyof LightColorOption;
  /** The tag variant, either filled or outline */
  variant?: 'filled' | 'outline';
  /** style */
  style?: React.CSSProperties;
  /** the icon to appear at the rightmost point in the tag (usually an X) */
  rightIcon?: JSX.Element;
  /** the icon to appear at the leftmost point in the tag  */
  leftIcon?: JSX.Element;
}

export const SingleTag: React.FC<SingleTagProps> = ({
  label,
  color,
  variant = 'filled',
  style = {},
  rightIcon,
  leftIcon,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <span style={{ display: 'inline-flex' }}>
      <StyledTagSection
        {...rest}
        style={{
          display: 'flex',
          alignItems: 'center',
          color:
            variant === 'outline' ? theme.colors[color] : theme.colors.white,
          background:
            variant === 'outline' ? theme.colors.white : theme.colors[color],
          borderRadius: '4px',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.colors[color],
          gap: '7px',
          ...style,
        }}
      >
        {leftIcon && (
          <FlexRow as="span" style={{ marginRight: 7 }}>
            {leftIcon}
          </FlexRow>
        )}
        {label}
        {rightIcon}
      </StyledTagSection>
    </span>
  );
};

import React from 'react';

import { SvgProps } from '../enums';

export const FlagEC: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54987"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54987)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_54987"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54987)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V6H16V0H0Z"
          fill="#FECA00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9V12H16V9H0Z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4.60638 2.89596C4.54617 2.76016 6.86168 2.07297 7.03518 2.07297C7.20868 2.07297 7.42814 2.48447 7.42814 2.48447L8.83633 2.59837C8.83633 2.59837 8.66997 2.07297 8.83633 2.07297C9.00269 2.07297 11.5521 2.95855 11.5521 2.95855C11.5521 2.95855 8.36509 3.34272 8.36509 3.42922C8.36509 3.51572 8.57386 3.9906 8.57386 3.9906L7.98448 4.18373C7.98448 4.18373 8.01909 3.61144 7.92299 3.61144C7.82689 3.61144 7.54161 4.29593 7.54161 4.29593L7.26185 3.42922C7.26185 3.42922 4.66659 3.03176 4.60638 2.89596Z"
          fill="#7B2900"
        />
        <path
          // eslint-disable-next-line max-len
          d="M4.61629 5.00897L5.24667 5.15971C4.58712 7.91787 5.19674 9.24061 7.07389 9.36353L7.03154 10.0103C4.67914 9.85625 3.87461 8.11061 4.61629 5.00897Z"
          fill="#FECA00"
        />
        <path
          // eslint-disable-next-line max-len
          d="M5.00016 4.63718L5.64052 4.73738C5.18884 7.62374 5.55056 8.92885 6.56439 8.89315L6.58721 9.54089C4.96744 9.59794 4.49219 7.88322 5.00016 4.63718Z"
          fill="#07138E"
        />
        <path
          // eslint-disable-next-line max-len
          d="M5.64836 4.37247L6.28871 4.47268C5.83703 7.35903 6.19875 8.66414 7.21259 8.62844L7.2354 9.27618C5.61564 9.33323 5.14039 7.61851 5.64836 4.37247Z"
          fill="#E10001"
        />
        <path
          // eslint-disable-next-line max-len
          d="M6.85108 9.6367L7.49618 9.57397C7.54929 10.1202 7.50907 10.5912 7.37069 10.9866L6.75891 10.7725C6.86336 10.4741 6.89569 10.0955 6.85108 9.6367Z"
          fill="#07138E"
        />
        <path
          d="M10.7499 5.08435C10.7499 5.08435 11.783 9.73074 8.59973 9.65032"
          stroke="#FECA00"
          strokeWidth="0.648148"
        />
        <path
          // eslint-disable-next-line max-len
          d="M10.6812 4.63718L10.0409 4.73738C10.4926 7.62374 10.1308 8.92885 9.117 8.89315L9.09419 9.54089C10.714 9.59794 11.1892 7.88322 10.6812 4.63718Z"
          fill="#07138E"
        />
        <path
          // eslint-disable-next-line max-len
          d="M10.033 4.37247L9.39269 4.47268C9.84437 7.35903 9.48264 8.66414 8.46881 8.62844L8.446 9.27618C10.0658 9.33323 10.541 7.61851 10.033 4.37247Z"
          fill="#E10001"
        />
        <path
          // eslint-disable-next-line max-len
          d="M8.70432 9.64029L8.05996 9.57037C8.00584 10.0692 8.04702 10.5012 8.18909 10.8651L8.79285 10.6294C8.69209 10.3713 8.66072 10.0421 8.70432 9.64029Z"
          fill="#07138E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.93323 8.75H8.87767V9.67647H6.93323V8.75Z"
          fill="#908F89"
        />
        <path
          // eslint-disable-next-line max-len
          d="M7.90553 9C8.45376 9 8.92729 8.69098 9.25417 8.23709C9.58131 7.78283 9.7759 7.16787 9.7759 6.5C9.7759 5.83213 9.58131 5.21717 9.25417 4.76291C8.92729 4.30902 8.45376 4 7.90553 4C7.3573 4 6.88376 4.30902 6.55688 4.76291C6.22974 5.21717 6.03516 5.83213 6.03516 6.5C6.03516 7.16787 6.22974 7.78283 6.55688 8.23709C6.88376 8.69098 7.3573 9 7.90553 9Z"
          stroke="#FEE901"
          strokeWidth="0.5"
        />
        <mask
          id="mask2_270_54987"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="6"
          height="7"
        >
          <path
            // eslint-disable-next-line max-len
            d="M7.90553 9C8.45376 9 8.92729 8.69098 9.25417 8.23709C9.58131 7.78283 9.7759 7.16787 9.7759 6.5C9.7759 5.83213 9.58131 5.21717 9.25417 4.76291C8.92729 4.30902 8.45376 4 7.90553 4C7.3573 4 6.88376 4.30902 6.55688 4.76291C6.22974 5.21717 6.03516 5.83213 6.03516 6.5C6.03516 7.16787 6.22974 7.78283 6.55688 8.23709C6.88376 8.69098 7.3573 9 7.90553 9Z"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
          />
        </mask>
        <g mask="url(#mask2_270_54987)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9.07902 7.70251C9.07902 7.70251 8.29128 7.27385 8.06519 7.12105C7.83911 6.96825 7.96962 6.62736 7.58023 6.71847C7.19083 6.80958 6.878 6.96825 6.878 7.40324C6.878 7.83823 6.57228 8.09648 6.46809 7.79928C6.3639 7.50208 5.87397 6.11234 6.46809 6.11234C7.06221 6.11234 8.45093 6.42401 8.85973 6.42401C9.26852 6.42401 9.5065 6.63612 9.5065 7.01968C9.5065 7.53559 9.07902 7.70251 9.07902 7.70251Z"
            fill="#8DDD61"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.90549 5.44118C8.08448 5.44118 8.22957 5.29304 8.22957 5.1103C8.22957 4.92756 8.08448 4.77942 7.90549 4.77942C7.72651 4.77942 7.58142 4.92756 7.58142 5.1103C7.58142 5.29304 7.72651 5.44118 7.90549 5.44118Z"
            fill="#FEE901"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M6.83467 5.47026C6.83467 5.47026 7.21165 5.37909 7.73254 5.73011C8.25343 6.08113 9.51634 6.28102 9.51634 6.00557C9.51634 5.73011 9.51634 6.54214 9.51634 6.54214C9.51634 6.54214 6.71408 6.36766 6.56416 6.54214C6.41424 6.71662 6.39928 5.56777 6.56416 5.47026C6.72904 5.37275 6.83467 5.47026 6.83467 5.47026Z"
            fill="#F7FCFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

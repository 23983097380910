import React from 'react';
import { useIntl } from 'react-intl';

import { partnershipLogoMessages } from './messages';
import TranscendBlack from './TranscendBlack.svg';
import TranscendColor from './TranscendColor.svg';
import TranscendWhite from './TranscendWhite.svg';
import { LogoWrapper, PartnershipLogoWrapper, PlusWrapper } from './wrappers';

export { TranscendBlack, TranscendColor, TranscendWhite };
export * from './TranscendLogo';

/**
 * The PartnershipLogo props
 */
export interface PartnershipLogoProps {
  /** Company X name for alt text */
  companyName?: string;
  /** Company X logo */
  companyLogo: string;
  /** Height css attribute */
  height?: string;
  /** Flag to include transcend logo */
  includeTranscendLogo?: boolean;
}

/**
 * The Page PartnershipLogo
 */
export function PartnershipLogo({
  companyName,
  companyLogo,
  height,
  includeTranscendLogo = true,
}: PartnershipLogoProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <PartnershipLogoWrapper height={height}>
      {companyLogo && (
        <>
          <LogoWrapper
            src={companyLogo}
            alt={
              companyName
                ? formatMessage(partnershipLogoMessages.organizationLogoAlt, {
                    companyName,
                  })
                : formatMessage(partnershipLogoMessages.fallbackLogoAlt)
            }
          />
          {includeTranscendLogo && <PlusWrapper>+</PlusWrapper>}
        </>
      )}
      {includeTranscendLogo && (
        <LogoWrapper
          src={TranscendColor}
          alt={formatMessage(partnershipLogoMessages.transcendLogoAlt)}
        />
      )}
    </PartnershipLogoWrapper>
  );
}

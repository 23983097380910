import {
  DATABASE_PREFERENCE_PARAMS,
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';

import {
  AttributeKey,
  AttributeKeyOrder,
  AttributeKeysFiltersInput,
  AttributeValue,
  AttributeValueOrder,
  AttributeValuesFiltersInput,
  CreateAttributeKeyInput,
  CreateAttributeValuesInput,
  DeleteAttributeKeyInput,
  SetResourceAttributesInput,
  UpdateAttributeKeyInput,
  UpdateAttributeValueInput,
} from './schema';

export const attributeKeys = mkQuery({
  name: 'attributeKeys',
  comment:
    'List all of the attributeKeys an organization uses to house attributeValues',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AttributeKeysFiltersInput.optional(),
    orderBy: AttributeKeyOrder,
  },
  response: {
    nodes: AttributeKey.list(),
    totalCount: 'int',
  },
});

export const attributeValues = mkQuery({
  name: 'attributeValues',
  comment:
    'List all of the attributeValues an organization uses to label resources',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: AttributeValuesFiltersInput.optional(),
    orderBy: AttributeValueOrder,
  },
  response: {
    nodes: AttributeValue.list(),
    totalCount: 'int',
  },
});

export const createAttributeKey = mkMutation({
  name: 'createAttributeKey',
  comment: 'Create an attribute key for an organization',
  params: {
    input: CreateAttributeKeyInput,
  },
  response: { attributeKey: AttributeKey },
});

export const deleteAttributeKey = mkMutation({
  name: 'deleteAttributeKey',
  comment: 'Delete an attribute key',
  params: {
    input: DeleteAttributeKeyInput,
  },
  response: { success: 'boolean' },
});

export const updateAttributeKey = mkMutation({
  name: 'updateAttributeKey',
  comment: 'Update an attribute key',
  params: {
    input: UpdateAttributeKeyInput,
  },
  response: {
    attributeKey: AttributeKey,
  },
});

export const deleteAttributeValue = mkMutation({
  name: 'deleteAttributeValue',
  comment: 'Delete an attribute value',
  params: {
    id: {
      comment: 'The ID of the attribute value to delete',
      modelName: 'attributeValue',
      type: 'id',
    },
  },
  response: { success: 'boolean' },
});

export const updateAttributeValues = mkMutation({
  name: 'updateAttributeValues',
  comment: 'Update attribute values',
  params: {
    input: UpdateAttributeValueInput.list(),
  },
  response: {
    attributeValues: AttributeValue.list(),
  },
});

export const createAttributeValues = mkMutation({
  name: 'createAttributeValues',
  comment: 'Create an new attribute value',
  params: {
    input: CreateAttributeValuesInput.list(),
  },
  response: { attributeValues: AttributeValue.list() },
});

export const setResourceAttributes = mkMutation({
  name: 'setResourceAttributes',
  comment: 'Set the attributeValues associated with a given resource',
  params: {
    input: SetResourceAttributesInput,
  },
  response: {
    attributeValues: AttributeValue.list(),
  },
});

export const attributeKey = mkQuery({
  name: 'attributeKey',
  comment: 'An attribute key',
  params: {
    id: {
      comment: 'attribute key id',
      modelName: 'attributeKey',
      type: 'id',
    },
  },
  response: AttributeKey,
});

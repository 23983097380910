/* eslint-disable max-lines */
import { RequestAction } from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeValue } from '@main/attribute-types';
import { DefinedMessage } from '@main/internationalization';
import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { Action } from './action';
import { DataSubjectClass } from './enums';

// TODO: https://github.com/transcend-io/main/issues/new - rename Subject -> DataSubject

export const InternalSubjectsFiltersInput = mkInput({
  name: 'InternalSubjectsFiltersInput',
  comment: 'Input for filtering the internal subjects',
  fields: {
    text: {
      comment: 'Filter by text',
      optional: true,
      type: 'string',
    },
    active: {
      comment: 'Filter by active',
      optional: true,
      type: 'boolean',
      list: true,
    },
    subjectClass: {
      comment: 'Filter by subject class',
      optional: true,
      type: { DataSubjectClass },
      list: true,
    },
  },
});

/** Override type */
export type InternalSubjectsFiltersInput = SchemaToType<
  typeof InternalSubjectsFiltersInput
>;

/**
 * Input for creating a new data subject
 */
export const CreateSubjectInput = mkInput({
  name: 'CreateSubjectInput',
  comment: 'Input for creating a data subject',
  fields: {
    type: {
      comment:
        'The unique name of the data subject classification within the organization',
      type: 'string',
    },
    title: {
      comment: 'The display message used on the privacy center',
      type: 'string',
    },
    subjectClass: {
      comment: 'The classification of data subject',
      type: { DataSubjectClass },
    },
    adminDashboardDefaultSilentMode: {
      comment:
        ' The admin dashboard form for this data subject should result all ' +
        'requests in silent mode when true. This is useful for admin-only workflows.',
      type: 'boolean',
      optional: true,
    },
    actions: {
      comment: 'The actions to allow for this data subject',
      type: { RequestAction },
      list: true,
      optional: true,
    },
    loginInstructions: {
      comment:
        'The instructions that should be shown in the modal as the data subject attempts to login',
      optional: true,
      type: 'string',
    },
    disabledInstructions: {
      comment:
        'The instructions that should be shown in the modal to instruct the data subject to make the request another way',
      optional: true,
      type: 'string',
    },
    redirectUrl: {
      comment: 'The URL where the data subject can login',
      optional: true,
      type: 'string',
    },
    oauthClientId: {
      comment:
        "The organization's oauthClientId associated with subjects of this type",
      optional: true,
      type: 'string',
    },
    oauthScope: {
      comment:
        'The oauthScope used to get context for data subjects of this type.',
      optional: true,
      type: 'string',
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this data subject and requests made to this data subject.',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this data subject and requests made to this data subject.',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type CreateSubjectInput = SchemaToType<typeof CreateSubjectInput>;

export const ToggleSubjectInput = mkInput({
  name: 'ToggleSubjectInput',
  comment: 'Input for toggling a data subject on or off',
  fields: {
    id: {
      comment: 'The unique id of the data subject config to toggle',
      modelName: 'subject',
      type: 'id',
    },
    active: {
      comment:
        'Whether to allow for data subjects of this type to submit requests on your privacy center',
      type: 'boolean',
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type ToggleSubjectInput = SchemaToType<typeof ToggleSubjectInput>;

export const UpdateSubjectInput = mkInput({
  name: 'UpdateSubjectInput',
  comment: 'Input for updating a data subject',
  fields: {
    id: {
      comment: 'The unique id of the data subject to update',
      modelName: 'subject',
      type: 'id',
    },
    title: {
      comment: 'The display message used on the privacy center',
      optional: true,
      type: 'string',
    },
    actions: {
      comment: 'The actions to allow for this data subject',
      type: { RequestAction },
      optional: true,
      list: true,
    },
    loginInstructions: {
      comment:
        'The instructions that should be shown in the modal as the data subject attempts to login',
      optional: true,
      type: 'string',
    },
    disabledInstructions: {
      comment:
        'The instructions that should be shown in the modal to instruct the data subject to make the request another way',
      optional: true,
      type: 'string',
    },
    adminDashboardDefaultSilentMode:
      CreateSubjectInput.fields.adminDashboardDefaultSilentMode,
    redirectUrl: {
      comment: 'The URL where the data subject can login',
      optional: true,
      type: 'string',
    },
    oauthClientId: {
      comment:
        "The organization's oauthClientId associated with subjects of this type",
      optional: true,
      type: 'string',
    },
    oauthScope: {
      comment:
        'The oauthScope used to get context for data subjects of this type.',
      optional: true,
      type: 'string',
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this data subject and requests made to this data subject.',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this data subject and requests made to this data subject.',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    skipPublish: {
      comment: 'Should we skip publishing the module to the CDN?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateSubjectInput = SchemaToType<typeof UpdateSubjectInput>;

export const DataSubjectInterface = mkInterface({
  name: 'DataSubjectInterface',
  comment:
    'The configuration for a data subject that the organization supports, displayed on the privacy center',
  fields: {
    id: {
      comment: 'The UUID for the data subject configuration',
      modelName: 'subject',
      type: 'id',
    },
    type: {
      comment:
        'The unique name of the data subject classification within the organization',
      type: 'string',
    },
    icon: {
      comment: 'The icon to display with the data subject',
      type: 'string',
    },
    subjectClass: {
      comment: 'The classification of data subject',
      type: { DataSubjectClass },
    },
    title: {
      comment: 'The display title for the data subject class',
      type: DefinedMessage,
    },
    loginInstructions: {
      comment:
        'The instructions that should be shown in the modal as the data subject attempts to login',
      optional: true,
      type: DefinedMessage,
    },
    disabledInstructions: {
      comment:
        'The instructions that should be shown in the modal to instruct the data subject to make the request another way',
      optional: true,
      type: DefinedMessage,
    },
    redirectUrl: {
      comment: 'The URL where the data subject can login',
      optional: true,
      type: DefinedMessage,
    },
    oauthClientId: {
      comment:
        "The organization's oauthClientId associated with subjects of this type",
      optional: true,
      type: 'string',
    },
    oauthScope: {
      comment:
        'The oauthScope used to get context for data subjects of this type.',
      optional: true,
      type: 'string',
    },
    responseType: {
      comment: 'The oauth response type',
      optional: true,
      type: 'string',
    },
    actions: {
      comment: 'The actions allowed for this data subject',
      type: Action,
      list: true,
    },
  },
});

/** Override type */
export type DataSubjectInterface = SchemaToType<typeof DataSubjectInterface>;

export const DataSubject = mkType({
  name: 'DataSubject',
  comment:
    'A data subject classification that the organization will accept requests for',
  interfaces: [DataSubjectInterface],
  fields: {
    ...DataSubjectInterface.fields,
  },
});

/** Override type */
export type DataSubject = SchemaToType<typeof DataSubject>;

export const DataSubjectInternal = mkType({
  name: 'DataSubjectInternal',
  comment: 'The internal configuration for a data subject',
  interfaces: [DataSubjectInterface],
  fields: {
    ...DataSubjectInterface.fields,
    attributeValues: {
      comment: 'The attribute values used to label this assessment',
      type: AttributeValue,
      list: true,
    },
    owners: {
      comment:
        'The list of users that are assigned access to view requests for this data subject',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams that are assigned access to view requests for this data subject',
      list: true,
      type: TeamPreview,
    },
    active: {
      comment: 'Whether the data subject is currently turned on',
      type: 'boolean',
    },
    adminDashboardDefaultSilentMode: {
      comment:
        ' The admin dashboard form for this data subject should result all ' +
        'requests in silent mode when true. This is useful for admin-only workflows.',
      type: 'boolean',
    },
    openRequestCount: {
      comment: 'The number of open requests for this data subject type',
      type: 'int',
    },
    fulFilledRequestCount: {
      comment:
        'The number of requests that were fulfilled of this data subject type',
      type: 'int',
    },
  },
});

/** Override type */
export type DataSubjectInternal = SchemaToType<typeof DataSubjectInternal>;
/* eslint-enable max-lines */

import 'antd/lib/row/style/index';

import { Col } from '@main/core-ui/src/Col';
import { ScrollWrapper } from '@main/core-ui/src/StyledWrappers';
import { RowProps as AntRowProps } from 'antd/lib/row';
import React from 'react';

import { RowWrapper } from './wrappers';

/**
 * Props for a row
 */
export interface RowProps extends AntRowProps {
  /** If true also add Col 24 */
  full?: boolean;
  /** Padding for the row */
  padding?: string;
  /** The scroll height of the content (overflow scrolls) */
  scrollHeight?: string;
}

/**
 * A layout Row
 *
 * @deprecated TODO: https://transcend.height.app/T-19886 - remove ant
 */
export function Row({
  children,
  scrollHeight,
  full = false,
  gutter = 24,
  justify = 'center',
  type = 'flex',
  padding = '0',
  ...rowProps
}: RowProps): JSX.Element {
  let child = children;
  if (scrollHeight) {
    child = <ScrollWrapper maxHeight={scrollHeight}>{child}</ScrollWrapper>;
  }
  if (full) {
    child = <Col span={24}>{child}</Col>;
  }
  return (
    <RowWrapper
      padding={padding}
      gutter={gutter}
      justify={justify}
      type={type}
      {...rowProps}
    >
      {child}
    </RowWrapper>
  );
}

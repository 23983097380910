import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const EnvelopeClock: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 18H7C4.79086 18 3 16.2091 3 14V7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 7.5L11.2046 9.33327C11.7124 9.55467 12.2893 9.55554 12.7978 9.33568L17 7.51861"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="18"
      cy="17"
      r="4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7109 15.5234V17.2734H19.2109"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { ReactElement } from 'react';
import type { FieldErrors } from 'react-hook-form/dist/types/errors';

import { getEntries } from '@transcend-io/type-utils';

import { ValidatorErrors, ValidatorMap } from './types';
import { ValidatorKey } from './validation/enums';

/**
 * validates a value given the map of validators
 *
 * @param validators - the map of validators to use
 * @param value - the value to test
 * @returns a map of errors and boolean if any errors are present
 */
export function validatorsToValidationErrors<T extends string = ValidatorKey>(
  validators: ValidatorMap<T> | undefined,
  value: string | undefined,
): {
  /** all errors or undefined if no errors */
  errors?: ValidatorErrors<T>;
  /** if any errors returned anything */
  anyErrors: boolean;
} {
  if (!validators) {
    return { errors: undefined, anyErrors: false };
  }
  const errors = Object.fromEntries(
    getEntries(validators)
      .map(([key, validator]) => [key, validator?.((value || '') as string)])
      .filter(([, val]) => !!val) as [string, string | ReactElement][],
  ) as ValidatorErrors<T>;

  return { errors, anyErrors: Object.values(errors).some((val) => !!val) };
}

/**
 * navigates through the error object to resolve potentially nested errors by
 * the form item name
 *
 * @param errors - the current errors
 * @param name - the name of the form item
 * @returns the error
 */
export function getFormErrorsByName(
  errors: FieldErrors<any>,
  name: string,
): FieldErrors<any>[string] {
  const nameParts = name.split('.');
  let error = errors ?? {};
  nameParts.forEach((part) => {
    error = error?.[part];
  });

  return error;
}

import { LargeLanguageModelClient } from '@transcend-io/privacy-types';

export interface LargeLanguageModelConfig {
  /** What this model is good for */
  description: string;
  /** The size of the context window, in tokens */
  contextLength: number;
  /** The price in USD per token inputted */
  inputTokenPrice: number;
  /** The price in USD per token outputted */
  outputTokenPrice: number;
}

/**
 * The language models available for a specific client
 */
export type LargeLanguageModels = Record<
  /** The model name or model ID, like 'gpt-4' */
  string,
  LargeLanguageModelConfig
>;

/** Input for creating a database row for a LLM */
export interface LargeLanguageModelInput extends LargeLanguageModelConfig {
  /** Name of the large language model */
  name: string;
  /** Name of the  */
  client: LargeLanguageModelClient;
  /** Whether Transcend has a hosting option */
  isTranscendHosted: boolean;
}

export const CLAUDE_MODELS: LargeLanguageModels = {
  'anthropic.claude-instant-v1': {
    description: `Good for everyday use. Fast and cheap.`,
    contextLength: 100000,
    inputTokenPrice: 0.0008 / 1000,
    outputTokenPrice: 0.0024 / 1000,
  },
  'anthropic.claude-v2': {
    description: `Good for complex reasoning. Slower and more expensive.`,
    contextLength: 100000,
    inputTokenPrice: 0.008 / 1000,
    outputTokenPrice: 0.024 / 1000,
  },
  'anthropic.claude-v2:1': {
    description:
      `Claude 2.1 is a large language model (LLM) by Anthropic with a 200K token context window, ` +
      `reduced hallucination rates, and improved accuracy over long documents.`,
    contextLength: 200000,
    inputTokenPrice: 0.008 / 1000,
    outputTokenPrice: 0.024 / 1000,
  },
  'anthropic.claude-3-sonnet-20240229-v1:0': {
    description: `Claude 3 Sonnet is engineered to be dependable for scaled AI deployments across a variety of use cases.`,
    contextLength: 200000,
    inputTokenPrice: 0.003 / 1000,
    outputTokenPrice: 0.015 / 1000,
  },
  'anthropic.claude-3-5-sonnet-20240620-v1:0': {
    description:
      `Anthropic's most intelligent and advanced model, Claude 3.5 Sonnet, ` +
      `demonstrates exceptional capabilities across a diverse range of tasks and evaluations ` +
      `while also outperforming Claude 3 Opus.`,
    contextLength: 200000,
    inputTokenPrice: 0.003 / 1000,
    outputTokenPrice: 0.015 / 1000,
  },
  'anthropic.claude-3-haiku-20240307-v1:0': {
    description:
      `Anthropic's fastest, most compact model for near-instant responsiveness. ` +
      `It answers simple queries and requests with speed.`,
    contextLength: 200000,
    inputTokenPrice: 0.00025 / 1000,
    outputTokenPrice: 0.00125 / 1000,
  },
  'anthropic.claude-3-opus-20240229-v1:0': {
    description:
      `Claude 3 Opus is a powerful and reliable AI model, with top-level performance on ` +
      `highly complex tasks. It can navigate open-ended prompts and sight-unseen scenarios  ` +
      `with remarkable fluency and human-like understanding.`,
    contextLength: 200000,
    inputTokenPrice: 0.015 / 1000,
    outputTokenPrice: 0.075 / 1000,
  },
};

/**
 * @see https://openai.com/pricing
 */
export const OPENAI_MODELS: LargeLanguageModels = {
  'gpt-3.5-turbo': {
    description: `Good for everyday use. Fast and cheap.`,
    contextLength: 4096,
    inputTokenPrice: 0.0000015,
    outputTokenPrice: 0.000002,
  },
  'gpt-3.5-turbo-16k': {
    description: `Good for everyday use on large prompts. Fast and cheap.`,
    contextLength: 16384,
    inputTokenPrice: 0.000003,
    outputTokenPrice: 0.000004,
  },
  'gpt-4': {
    description: `Good for complex reasoning. Slower and more expensive.`,
    contextLength: 8192,
    inputTokenPrice: 0.00003,
    outputTokenPrice: 0.00006,
  },
  'gpt-4-32k': {
    description: `Good for complex reasoning on large prompts. Slower and very expensive.`,
    contextLength: 32768,
    inputTokenPrice: 0.00006,
    outputTokenPrice: 0.00012,
  },
  'gpt-4-1106-preview': {
    description: `(Preview version) Good for complex reasoning on large prompts. Faster and cheaper than gpt-4.`,
    contextLength: 128000,
    inputTokenPrice: 0.00001,
    outputTokenPrice: 0.00003,
  },
  'gpt-4-0125-preview': {
    description:
      `(Preview version) Good for complex reasoning on large prompts. Faster and cheaper than gpt-4. ` +
      `Fixes "laziness" problem`,
    contextLength: 128000,
    inputTokenPrice: 0.00001,
    outputTokenPrice: 0.00003,
  },
  'gpt-4-turbo-preview': {
    description: `(Preview version) Good for complex reasoning on large prompts. Faster and cheaper than gpt-4.`,
    contextLength: 128000,
    inputTokenPrice: 0.00001,
    outputTokenPrice: 0.00003,
  },
  'gpt-4-turbo': {
    description: `Good for complex reasoning on large prompts. Faster and cheaper than gpt-4.`,
    contextLength: 128000,
    inputTokenPrice: 0.00001,
    outputTokenPrice: 0.00003,
  },
};

export const LLAMA_MODELS = {
  'llama-2-7b': {
    description: 'Good for everyday use. Fast and cheap.',
    contextLength: 4096,
    inputTokenPrice: NaN,
    outputTokenPrice: NaN,
  },
  'meta.llama3-1-8b-instruct-v1:0': {
    description: 'Takes instruction, good for classification.',
    contextLength: 8192,
    inputTokenPrice: NaN,
    outputTokenPrice: NaN,
  },
};

export const MAXIMUM_CONTEXT_LENGTH = Math.max(
  ...Object.values({
    ...LLAMA_MODELS,
    ...OPENAI_MODELS,
    ...CLAUDE_MODELS,
  }).map(({ contextLength }) => contextLength),
);

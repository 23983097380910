import { PrivacyCenterConfigurableColorPalette } from '@transcend-io/privacy-types';
import { apply } from '@transcend-io/type-utils';

import {
  DEFAULT_CUSTOMIZABLE_COLORS,
  FIXED_COLORS,
  OPTIONAL_COLOR_DEFAULTS,
  SHADE_TRANSFORMATIONS,
} from './constants';
import type { ColorPalette } from './theme';

/**
 * Determine the full color palette from the customizable palette
 *
 * @param colors - The custom color palette for an organization
 * @returns The full calculated color palette with all fields defined
 */
export function calculateColorPalette(
  colors: PrivacyCenterConfigurableColorPalette,
): ColorPalette {
  // Convert the mapped colors to their defaults
  const appliedDefaults = apply(OPTIONAL_COLOR_DEFAULTS, (mappedColorKey) =>
    typeof mappedColorKey === 'string'
      ? colors[mappedColorKey]
      : mappedColorKey(colors),
  );

  // Construct all colors with various levels of priority
  const allColorsExceptShades = {
    ...DEFAULT_CUSTOMIZABLE_COLORS, // We initialize the color palette with the defaults
    ...appliedDefaults, // If a color is optional, we calculate it from the required customizable colors
    ...colors, // If a color is defined, it is taken as truth
    ...FIXED_COLORS, // Cannot override admin
  };

  return {
    // Calculate the shade transformations
    ...apply(SHADE_TRANSFORMATIONS, (colorTransform) =>
      colorTransform(allColorsExceptShades),
    ),
    // Merges
    ...allColorsExceptShades,
  };
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagHR: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55017"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55017)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55017"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55017)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#3D58DB"
        />
        <rect x="5.03833" y="3.19865" width="5.7" height="2" fill="#E31D1C" />
        <path
          // eslint-disable-next-line max-len
          d="M5.32616 4.4799H10.3904C10.399 4.86438 10.4016 5.53508 10.4015 6.18075C10.4015 6.59771 10.4004 6.99913 10.3993 7.29636L10.3978 7.65284L10.3973 7.75161L10.3972 7.76583L10.397 7.76747C10.3947 7.78078 10.391 7.80172 10.3852 7.82921C10.3737 7.88425 10.3543 7.96505 10.3235 8.06297C10.2616 8.25963 10.1551 8.52037 9.97765 8.77946C9.63043 9.2864 8.99997 9.80763 7.82493 9.80763C6.65147 9.80763 6.05153 9.2871 5.73219 8.78502C5.56822 8.52721 5.47458 8.26735 5.42217 8.07085C5.39609 7.97307 5.38059 7.89236 5.37173 7.83736C5.3673 7.80991 5.36455 7.78899 5.36297 7.77571L5.36202 7.7671L5.36175 7.74507L5.36056 7.64692L5.35631 7.29264C5.3528 6.9972 5.34812 6.59789 5.34343 6.18236C5.33627 5.54746 5.32914 4.87667 5.32616 4.4799ZM5.36119 7.75927C5.36117 7.75913 5.36117 7.75912 5.36118 7.75925L5.36119 7.75927Z"
          fill="#F7FCFF"
          stroke="#E31D1C"
          strokeWidth="0.5625"
        />
        <mask
          id="mask2_270_55017"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="4"
          width="6"
          height="7"
        >
          <path
            // eslint-disable-next-line max-len
            d="M5.32616 4.4799H10.3904C10.399 4.86438 10.4016 5.53508 10.4015 6.18075C10.4015 6.59771 10.4004 6.99913 10.3993 7.29636L10.3978 7.65284L10.3973 7.75161L10.3972 7.76583L10.397 7.76747C10.3947 7.78078 10.391 7.80172 10.3852 7.82921C10.3737 7.88425 10.3543 7.96505 10.3235 8.06297C10.2616 8.25963 10.1551 8.52037 9.97765 8.77946C9.63043 9.2864 8.99997 9.80763 7.82493 9.80763C6.65147 9.80763 6.05153 9.2871 5.73219 8.78502C5.56822 8.52721 5.47458 8.26735 5.42217 8.07085C5.39609 7.97307 5.38059 7.89236 5.37173 7.83736C5.3673 7.80991 5.36455 7.78899 5.36297 7.77571L5.36202 7.7671L5.36175 7.74507L5.36056 7.64692L5.35631 7.29264C5.3528 6.9972 5.34812 6.59789 5.34343 6.18236C5.33627 5.54746 5.32914 4.87667 5.32616 4.4799ZM5.36119 7.75927C5.36117 7.75913 5.36117 7.75912 5.36118 7.75925L5.36119 7.75927Z"
            fill="white"
            stroke="white"
            strokeWidth="0.5625"
          />
        </mask>
        <g mask="url(#mask2_270_55017)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M5.24927 4.51994H6.30396V5.59089H5.24927V4.51994ZM7.35864 5.59089H6.30396V6.66184H5.24927V7.73279H6.30396V8.80375H5.24927V9.8747H6.30396V8.80375H7.35864V9.8747H8.41333V8.80375H9.46802V9.8747H10.5227V8.80375H9.46802V7.73279H10.5227V6.66184H9.46802V5.59089H10.5227V4.51994H9.46802V5.59089H8.41333V4.51994H7.35864V5.59089ZM7.35864 6.66184V5.59089H8.41333V6.66184H7.35864ZM7.35864 7.73279V6.66184H6.30396V7.73279H7.35864ZM8.41333 7.73279V8.80375H7.35864V7.73279H8.41333ZM8.41333 7.73279V6.66184H9.46802V7.73279H8.41333Z"
            fill="#F50100"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.22657 4.0813C5.22657 4.0813 5.55279 3.90706 5.83103 3.81726C6.10927 3.72746 6.32919 3.6784 6.32919 3.6784L5.91522 2.15235L5.08606 1.84256L4.61646 2.61661L5.22657 4.0813Z"
          fill="#56C6F5"
          stroke="white"
          strokeWidth="0.15"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.27041 3.53789C7.27041 3.53789 7.54861 3.51306 7.84092 3.51874C8.13324 3.52442 8.38053 3.5265 8.38053 3.5265L8.52331 2.02176L7.89715 1.45607L7.20112 2.03506L7.27041 3.53789Z"
          fill="#56C6F5"
          stroke="white"
          strokeWidth="0.15"
        />
        <path
          // eslint-disable-next-line max-len
          d="M6.23426 3.66595L6.25587 3.73936L6.32882 3.71628L6.32883 3.71628L6.32886 3.71627L6.32901 3.71622L6.32965 3.71602L6.33227 3.7152L6.3426 3.71199L6.38124 3.70023C6.41413 3.69035 6.45999 3.67691 6.5118 3.66265C6.61649 3.63383 6.74173 3.60271 6.83369 3.58993C6.92813 3.57681 7.04504 3.5673 7.13936 3.56106C7.1863 3.55795 7.22723 3.55568 7.25641 3.55419L7.29059 3.55253L7.29969 3.55212L7.30201 3.55202L7.30258 3.552L7.30271 3.55199L7.30274 3.55199L7.30274 3.55199L7.38113 3.54877L7.37439 3.47061L7.24825 2.0084L7.24526 1.97381L7.21695 1.9537L6.53559 1.4698L6.48298 1.43243L6.43818 1.47888L5.81895 2.12083L5.78866 2.15223L5.80098 2.19408L6.23426 3.66595Z"
          fill="#2E42A5"
          stroke="white"
          strokeWidth="0.15"
        />
        <mask
          id="mask3_270_55017"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="1"
          width="3"
          height="3"
        >
          <path
            // eslint-disable-next-line max-len
            d="M6.23426 3.66595L6.25587 3.73936L6.32882 3.71628L6.32883 3.71628L6.32886 3.71627L6.32901 3.71622L6.32965 3.71602L6.33227 3.7152L6.3426 3.71199L6.38124 3.70023C6.41413 3.69035 6.45999 3.67691 6.5118 3.66265C6.61649 3.63383 6.74173 3.60271 6.83369 3.58993C6.92813 3.57681 7.04504 3.5673 7.13936 3.56106C7.1863 3.55795 7.22723 3.55568 7.25641 3.55419L7.29059 3.55253L7.29969 3.55212L7.30201 3.55202L7.30258 3.552L7.30271 3.55199L7.30274 3.55199L7.30274 3.55199L7.38113 3.54877L7.37439 3.47061L7.24825 2.0084L7.24526 1.97381L7.21695 1.9537L6.53559 1.4698L6.48298 1.43243L6.43818 1.47888L5.81895 2.12083L5.78866 2.15223L5.80098 2.19408L6.23426 3.66595Z"
            fill="white"
            stroke="white"
            strokeWidth="0.15"
          />
        </mask>
        <g mask="url(#mask3_270_55017)" />
        <path
          // eslint-disable-next-line max-len
          d="M9.47005 3.66431L9.44966 3.73989L9.37503 3.71628L9.37502 3.71628L9.37499 3.71627L9.37484 3.71622L9.3742 3.71602L9.37158 3.7152L9.36125 3.71199L9.32261 3.70023C9.28972 3.69035 9.24386 3.67691 9.19205 3.66265C9.08735 3.63383 8.96212 3.60271 8.87016 3.58993C8.77572 3.57681 8.6588 3.5673 8.56449 3.56106C8.51755 3.55795 8.47662 3.55568 8.44744 3.55419L8.41326 3.55253L8.40416 3.55212L8.40184 3.55202L8.40127 3.552L8.40114 3.55199L8.40111 3.55199L8.40111 3.55199L8.32272 3.54877L8.32946 3.47061L8.4556 2.0084L8.45859 1.97381L8.4869 1.9537L9.16826 1.4698L9.22235 1.43138L9.26707 1.48038L9.85092 2.11991L9.87866 2.1503L9.86794 2.19002L9.47005 3.66431Z"
          fill="#2E42A5"
          stroke="white"
          strokeWidth="0.15"
        />
        <mask
          id="mask4_270_55017"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="1"
          width="2"
          height="3"
        >
          <path
            // eslint-disable-next-line max-len
            d="M9.47005 3.66431L9.44966 3.73989L9.37503 3.71628L9.37502 3.71628L9.37499 3.71627L9.37484 3.71622L9.3742 3.71602L9.37158 3.7152L9.36125 3.71199L9.32261 3.70023C9.28972 3.69035 9.24386 3.67691 9.19205 3.66265C9.08735 3.63383 8.96212 3.60271 8.87016 3.58993C8.77572 3.57681 8.6588 3.5673 8.56449 3.56106C8.51755 3.55795 8.47662 3.55568 8.44744 3.55419L8.41326 3.55253L8.40416 3.55212L8.40184 3.55202L8.40127 3.552L8.40114 3.55199L8.40111 3.55199L8.40111 3.55199L8.32272 3.54877L8.32946 3.47061L8.4556 2.0084L8.45859 1.97381L8.4869 1.9537L9.16826 1.4698L9.22235 1.43138L9.26707 1.48038L9.85092 2.11991L9.87866 2.1503L9.86794 2.19002L9.47005 3.66431Z"
            fill="white"
            stroke="white"
            strokeWidth="0.15"
          />
        </mask>
        <g mask="url(#mask4_270_55017)" />
        <path
          // eslint-disable-next-line max-len
          d="M10.6304 4.07306L10.5981 4.14768L10.5263 4.10936L10.5263 4.10936L10.5263 4.10936L10.5263 4.10934L10.5261 4.10927L10.5255 4.10893L10.5229 4.10754L10.5124 4.10203C10.5031 4.0972 10.4896 4.09017 10.4724 4.08144C10.438 4.06397 10.3892 4.03972 10.3318 4.0127C10.2165 3.95843 10.0688 3.89398 9.93415 3.85053C9.79666 3.80616 9.67351 3.77183 9.58477 3.74861C9.54042 3.737 9.50473 3.72818 9.4802 3.72228L9.45211 3.71565L9.44495 3.714L9.44319 3.71361L9.44278 3.71351L9.4427 3.71349L9.44269 3.71349L9.44268 3.71349L9.44268 3.71349L9.36516 3.6962L9.38688 3.61978L9.80988 2.13184L9.82025 2.09536L9.85577 2.08209L10.6849 1.7723L10.7431 1.75057L10.7753 1.80366L11.2449 2.57771L11.265 2.61088L11.2496 2.64647L10.6304 4.07306Z"
          fill="#56C6F5"
          stroke="white"
          strokeWidth="0.15"
        />
        <mask
          id="mask5_270_55017"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="1"
          width="3"
          height="4"
        >
          <path
            // eslint-disable-next-line max-len
            d="M10.6304 4.07306L10.5981 4.14768L10.5263 4.10936L10.5263 4.10936L10.5263 4.10936L10.5263 4.10934L10.5261 4.10927L10.5255 4.10893L10.5229 4.10754L10.5124 4.10203C10.5031 4.0972 10.4896 4.09017 10.4724 4.08144C10.438 4.06397 10.3892 4.03972 10.3318 4.0127C10.2165 3.95843 10.0688 3.89398 9.93415 3.85053C9.79666 3.80616 9.67351 3.77183 9.58477 3.74861C9.54042 3.737 9.50473 3.72818 9.4802 3.72228L9.45211 3.71565L9.44495 3.714L9.44319 3.71361L9.44278 3.71351L9.4427 3.71349L9.44269 3.71349L9.44268 3.71349L9.44268 3.71349L9.36516 3.6962L9.38688 3.61978L9.80988 2.13184L9.82025 2.09536L9.85577 2.08209L10.6849 1.7723L10.7431 1.75057L10.7753 1.80366L11.2449 2.57771L11.265 2.61088L11.2496 2.64647L10.6304 4.07306Z"
            fill="white"
            stroke="white"
            strokeWidth="0.15"
          />
        </mask>
        <g mask="url(#mask5_270_55017)" />
      </g>
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagCG: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54973"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" rx="0.25" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54973)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0V12H0L16 0Z"
        fill="#FA1111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12V0H16L0 12Z"
        fill="#07A907"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7459 -2.90002L-0.5 11.788L2.52582 13.294L17.3203 -0.106018L14.7459 -2.90002Z"
        fill="#FBCD17"
      />
      <mask
        id="mask1_270_54973"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-3"
        width="19"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7459 -2.90002L-0.5 11.788L2.52582 13.294L17.3203 -0.106018L14.7459 -2.90002Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54973)" />
    </g>
  </svg>
);

import { AssessmentFormStatus } from '@transcend-io/privacy-types';

import { mkType, SchemaToType } from '@main/schema-utils';

export const AssessmentFormPreview = mkType({
  name: 'AssessmentFormPreview',
  comment: 'Assessment form that can be filled out by users',
  fields: {
    id: {
      comment: 'The id of the assessment form',
      type: 'id',
      modelName: 'assessmentForm',
    },
    title: {
      comment: 'The internal label of the assessment form',
      type: 'string',
    },
    status: {
      comment: 'The status of the assessment form',
      type: { AssessmentFormStatus },
    },
  },
});

/** Override type */
export type AssessmentFormPreview = SchemaToType<typeof AssessmentFormPreview>;

import { AttributeSupportedResourceType } from '@transcend-io/privacy-types';

import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { AssessmentFormPreview } from './assessmentFormPreview';
import { AttributeKey } from './attributeKey';
import { AssessmentSecondaryType, AttributeValueOrderField } from './enums';

export const AttributeValue = mkType({
  name: 'AttributeValue',
  comment: 'An attribute value to label a resource',
  fields: {
    id: {
      comment: 'The id for the attribute value',
      modelName: 'attributeValue',
      type: 'id',
    },
    attributeKey: {
      comment: 'The attribute key the attribute value belongs to',
      type: AttributeKey,
    },
    name: {
      comment: 'The name for the attribute value',
      type: 'string',
    },
    description: {
      comment: 'The description for the attribute value',
      type: 'string',
    },
    color: {
      comment: 'The color label for the attribute value',
      type: 'string',
      optional: true,
    },
    canDelete: {
      comment: 'Whether attribute value can be deleted',
      type: 'boolean',
    },
    assessmentForm: {
      comment: 'The assessment form assigned to this attribute',
      type: AssessmentFormPreview,
      optional: true,
    },
    assessmentSecondaryType: {
      comment:
        'A secondary classification for attribute values of type "ASSESSMENT"',
      type: { AssessmentSecondaryType },
      optional: true,
    },
  },
});

/** Override type */
export type AttributeValue = SchemaToType<typeof AttributeValue>;

export const AttributeValuesFiltersInput = mkInput({
  name: 'AttributeValuesFiltersInput',
  comment: 'Input for filtering the list of attribute values',
  fields: {
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
    ids: {
      comment: 'Filter by the attribute value IDs',
      modelName: 'attributeValue',
      list: true,
      type: 'id',
      optional: true,
    },
    attributeKeys: {
      comment: 'Filter by the attribute keys the attribute values belong to',
      type: 'id',
      modelName: 'attributeKey',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type AttributeValuesFiltersInput = SchemaToType<
  typeof AttributeValuesFiltersInput
>;

export const CreateAttributeValuesInput = mkInput({
  name: 'CreateAttributeValuesInput',
  comment: 'Input for creating attribute values',
  fields: {
    name: {
      comment: 'Name of the attribute value',
      type: 'string',
    },
    description: {
      comment: 'The description for the attribute value',
      type: 'string',
      optional: true,
    },
    attributeKeyId: {
      comment: 'The attribute key the attribute value belongs to',
      type: 'id',
      modelName: 'attributeKey',
    },
    color: {
      comment: 'The color label for the attribute value',
      type: 'string',
      optional: true,
    },
    canDelete: {
      comment: 'Whether attribute value can be deleted',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type CreateAttributeValuesInput = SchemaToType<
  typeof CreateAttributeValuesInput
>;

/** Override type */
export type CreateAttributeValueInput = SchemaToType<
  typeof CreateAttributeValuesInput
>;

export const UpdateAttributeValueInput = mkInput({
  name: 'UpdateAttributeValueInput',
  comment: 'Input for updating an attribute Value',
  fields: {
    id: {
      comment: 'The id of the attribute Value to update',
      modelName: 'attributeValue',
      type: 'id',
    },
    name: {
      comment: 'Name of the attribute value',
      type: 'string',
    },
    description: {
      comment: 'The description for the attribute value',
      type: 'string',
    },
    attributeKeyId: {
      comment: 'The attribute key the attribute value belongs to',
      type: 'id',
      modelName: 'attributeKey',
    },
    color: {
      comment: 'Color used for this attribute value',
      type: 'string',
      optional: true,
    },
    canDelete: {
      comment: 'Whether attribute value can be deleted',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateAttributeValueInput = SchemaToType<
  typeof UpdateAttributeValueInput
>;

export const SetResourceAttributesInput = mkInput({
  name: 'SetResourceAttributesInput',
  comment: 'Input for updating an attribute Value',
  fields: {
    // TODO: https://transcend.height.app/T-28322 - REMOVE WHEN SAFE
    resourceId: {
      comment: 'The id of the resource',
      type: 'string',
      optional: true,
    },
    resourceIds: {
      comment: 'The ids of the resources',
      type: 'string',
      list: true,
      optional: true,
    },
    resourceType: {
      comment: 'The resource type',
      type: { AttributeSupportedResourceType },
    },
    attributeKeyId: {
      comment: 'The id of the attribute key',
      type: 'id',
      modelName: 'attributeKey',
    },
    attributeValueIds: {
      comment:
        'The ids of the attribute values to associate with this resource',
      type: 'id',
      list: true,
      optional: true,
      modelName: 'attributeValue',
    },
    attributeValueNames: {
      comment:
        'The names of the attribute values to associate with this resource',
      type: 'string',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type SetResourceAttributesInput = SchemaToType<
  typeof SetResourceAttributesInput
>;

/**
 * Order for a attributeValues query
 */
export const AttributeValueOrder = mkOrder(
  AttributeValue.name,
  AttributeValueOrderField,
);

/** Override type */
export type AttributeValueOrder = SchemaToType<typeof AttributeValueOrder>;

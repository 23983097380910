/**
 * Compare two versions
 *
 * @param v1 - The first version
 * @param v2 - The second version
 * @returns 0 if same, 1 if v1 > v2, -1 if v2 > v1
 */
export function compareVersions(v1: string, v2: string): 0 | -1 | 1 {
  const [v1Parts, v1PreRelease] = v1.split('-');
  const [v2Parts, v2PreRelease] = v2.split('-');
  const v1Split = v1Parts.replace('v', '').split('.');
  const v2Split = v2Parts.replace('v', '').split('.');

  for (let i = 0; i < v1Split.length; i += 1) {
    const v1Value = parseInt(v1Split[i], 10);
    const v2Value = parseInt(v2Split[i], 10);

    if (v1Value > v2Value) {
      return 1;
    }
    if (v2Value > v1Value) {
      return -1;
    }
  }

  // When major, minor, and patch are equal, a pre-release version has lower precedence than a normal version, e.g. 1.0.0-alpha < 1.0.0
  // Source: https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
  if (!v1PreRelease && v2PreRelease) {
    return 1;
  }
  if (!v2PreRelease && v1PreRelease) {
    return -1;
  }

  if (v1PreRelease && v2PreRelease) {
    return v1PreRelease.localeCompare(v2PreRelease) as any;
  }

  return 0;
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagAI: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54946"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54946)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#2E42A5"
      />
      <mask
        id="mask1_270_54946"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54946)">
        <path
          // eslint-disable-next-line max-len
          d="M12.3702 10.5623C11.7672 10.5623 11.3617 10.424 11.0789 10.1955C10.7947 9.96593 10.6022 9.61948 10.4769 9.14018C10.3512 8.65895 10.2986 8.06518 10.279 7.36592C10.2652 6.87484 10.2677 6.34276 10.2705 5.77004C10.2713 5.60636 10.272 5.43936 10.2725 5.26904H14.5279C14.603 6.63976 14.6223 7.95176 14.3513 8.93551C14.2093 9.45075 13.9934 9.85343 13.684 10.1273C13.3793 10.397 12.9608 10.5623 12.3702 10.5623Z"
          fill="white"
          stroke="#E6E617"
          strokeWidth="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M10.2418 8.96726C10.2418 9.99832 11.0952 11.0164 12.3581 11.0164C13.6209 11.0164 14.5899 10.078 14.7053 8.93219L10.2418 8.96726Z"
          fill="#82E5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M10.8671 7.09995C10.8671 7.87408 11.5154 8.49995 12.3131 8.49995C13.1107 8.49995 13.7591 7.87408 13.7591 7.09995C13.7591 6.32582 13.1107 5.69995 12.3131 5.69995C11.5154 5.69995 10.8671 6.32582 10.8671 7.09995ZM13.1591 7.09995C13.1591 7.55098 12.7803 7.91662 12.3131 7.91662C11.8458 7.91662 11.4671 7.55098 11.4671 7.09995C11.4671 6.64892 11.8458 6.28328 12.3131 6.28328C12.7803 6.28328 13.1591 6.64892 13.1591 7.09995Z"
          fill="#E18600"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M12.069 5.50494C12.069 5.50494 11.5428 5.73699 11.657 6.17322C11.7712 6.60946 11.9002 6.67161 11.9002 6.67161C11.9002 6.67161 12.0861 5.72721 12.8366 5.72721L12.069 5.50494Z"
          fill="#E18600"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M11.1265 7.86197C11.1265 7.86197 11.5592 8.24083 11.9063 7.95296C12.2534 7.66509 12.2551 7.52186 12.2551 7.52186C12.2551 7.52186 11.3207 7.75252 11.0035 7.07234L11.1265 7.86197Z"
          fill="#E18600"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M13.7294 7.8044C13.7294 7.8044 13.7515 7.2297 13.3094 7.14112C12.8672 7.05254 12.7548 7.14129 12.7548 7.14129C12.7548 7.14129 13.5221 7.72235 13.1931 8.39689L13.7294 7.8044Z"
          fill="#E18600"
        />
        <mask
          id="mask2_270_54946"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="9"
          height="7"
        >
          <rect width="9" height="7" fill="white" />
        </mask>
        <g mask="url(#mask2_270_54946)">
          <path
            // eslint-disable-next-line max-len
            d="M-1.00208 6.49987L0.9784 7.36847L9.045 0.94433L10.0897 -0.346436L7.97182 -0.636747L4.68165 2.1316L2.0334 3.99682L-1.00208 6.49987Z"
            fill="#F7FCFF"
          />
          <path
            d="M-0.730957 7.10849L0.278004 7.61255L9.71451 -0.466309H8.29782L-0.730957 7.10849Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M10.0022 6.49987L8.02172 7.36847L-0.0448761 0.94433L-1.08953 -0.346436L1.0283 -0.636747L4.31847 2.1316L6.96673 3.99682L10.0022 6.49987Z"
            fill="#F7FCFF"
          />
          <path
            // eslint-disable-next-line max-len
            d="M9.93457 6.93667L8.92561 7.44073L4.90745 3.98176L3.71615 3.59529L-1.1901 -0.341973H0.226597L5.13009 3.50184L6.43256 3.96525L9.93457 6.93667Z"
            fill="#F50100"
          />
          <mask
            id="path-15-outside-1_270_54946"
            maskUnits="userSpaceOnUse"
            x="-1"
            y="-1"
            width="11"
            height="9"
            fill="black"
          >
            <rect fill="white" x="-1" y="-1" width="11" height="9" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99226 0H3.99226V3H0V4H3.99226V7H4.99226V4H9V3H4.99226V0Z"
            fill="#F50100"
          />
          <path
            // eslint-disable-next-line max-len
            d="M3.99226 0V-0.75H3.24226V0H3.99226ZM4.99226 0H5.74226V-0.75H4.99226V0ZM3.99226 3V3.75H4.74226V3H3.99226ZM0 3V2.25H-0.75V3H0ZM0 4H-0.75V4.75H0V4ZM3.99226 4H4.74226V3.25H3.99226V4ZM3.99226 7H3.24226V7.75H3.99226V7ZM4.99226 7V7.75H5.74226V7H4.99226ZM4.99226 4V3.25H4.24226V4H4.99226ZM9 4V4.75H9.75V4H9ZM9 3H9.75V2.25H9V3ZM4.99226 3H4.24226V3.75H4.99226V3ZM3.99226 0.75H4.99226V-0.75H3.99226V0.75ZM4.74226 3V0H3.24226V3H4.74226ZM0 3.75H3.99226V2.25H0V3.75ZM0.75 4V3H-0.75V4H0.75ZM3.99226 3.25H0V4.75H3.99226V3.25ZM4.74226 7V4H3.24226V7H4.74226ZM4.99226 6.25H3.99226V7.75H4.99226V6.25ZM4.24226 4V7H5.74226V4H4.24226ZM9 3.25H4.99226V4.75H9V3.25ZM8.25 3V4H9.75V3H8.25ZM4.99226 3.75H9V2.25H4.99226V3.75ZM4.24226 0V3H5.74226V0H4.24226Z"
            fill="#F7FCFF"
            mask="url(#path-15-outside-1_270_54946)"
          />
        </g>
      </g>
    </g>
  </svg>
);

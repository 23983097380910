import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Copy: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M10.0008 12.9354V7.24168C10.0008 6.95823 9.8881 6.68678 9.68802 6.4867L8.17939 4.97807C7.97931 4.77799 7.70786 4.66528 7.42441 4.66528H3.73154C3.14196 4.66528 2.66443 5.14281 2.66443 5.73239V12.9354C2.66443 13.525 3.14196 14.0025 3.73154 14.0025H8.93371C9.52329 14.0025 10.0008 13.525 10.0008 12.9354Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.0008 11.3347H12.2684C12.858 11.3347 13.3355 10.8572 13.3355 10.2676V4.5739C13.3355 4.29045 13.2228 4.01901 13.0227 3.81892L11.5141 2.31029C11.314 2.11021 11.0426 1.9975 10.7591 1.9975H7.06626C6.47668 1.9975 5.99915 2.47503 5.99915 3.06461V4.66528"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3355 4.66528H11.2013C10.9065 4.66528 10.6677 4.42651 10.6677 4.13172V1.9975"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0008 7.33305H7.86656C7.57177 7.33305 7.33301 7.09429 7.33301 6.7995V4.66528"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

/**
 * RequestProcessing icon
 */
export const RequestProcessing: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="RequestStatusIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          // eslint-disable-next-line max-len
          d="M16 2H18V5H16zM6 12c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6S9.3 12 6 12zM6 2C3.8 2 2 3.8 2 6s1.8 4 4 4 4-1.8 4-4S8.2 2 6 2zM8.3 7.3L6.8 5.9 8 3.8 6.9 3.2 5.2 6.1l2.2 2.2L8.3 7.3z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          // eslint-disable-next-line max-len
          d="M19,4h-5.262C13.903,4.64,14,5.308,14,6h5v2h-5.262c-0.183,0.71-0.458,1.381-0.817,2H19l0.001,10H5v-6.069c-0.699-0.087-1.369-0.262-2-0.518V20c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V6C21,4.897,20.103,4,19,4z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M13.931,5C13.972,5.328,14,5.661,14,6c0,1.062-0.211,2.073-0.587,3H20V5H13.931z"
          fill={transparentize(0.7, theme.colors.secondary)}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

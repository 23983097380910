import { DefinedMessage, MenuTabInterface } from '@main/internationalization';
import { mkType, SchemaToType } from '@main/schema-utils';

// TODO: https://transcend.height.app/T-42672 - remove
export const DataApplication = mkType({
  name: 'DataApplication',
  comment: 'A way in which the organization applies the data they collect',
  interfaces: [MenuTabInterface],
  fields: {
    ...MenuTabInterface.fields,
    id: {
      comment: 'The unique id',
      modelName: 'any',
      type: 'id',
    },
    description: {
      comment: 'A brief overview of the data application',
      type: DefinedMessage,
    },
    method: {
      comment: 'The method in which the data application is gathered',
      type: 'string',
    },
    staticType: {
      comment: 'The static type underlying the data application',
      optional: true,
      type: 'string',
    },
    color: {
      comment: 'The color to display in the admin dashboard select input',
      type: 'string',
    },
  },
});

/** Override type */
export type DataApplication = SchemaToType<typeof DataApplication>;

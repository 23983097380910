import { transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

/**
 * Erasing icon
 */
export const RequestErasing: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="RequestStatusIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M19,14c-2.761,0-5,2.239-5,5s2.239,5,5,5s5-2.239,5-5S21.761,14,19,14z M22,20h-2v2h-2v-2h-2v-2h2v-2h2v2h2V20z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          // eslint-disable-next-line max-len
          d="M12.08,20H7V5h10v7.294C17.634,12.105,18.305,12,19,12V5h1V3h-1h-4l-1-1h-4L9,3H5H4v2h1v15c0,1.103,0.897,2,2,2h5.683 C12.387,21.378,12.181,20.707,12.08,20z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M13 15.408c.51-.849 1.19-1.583 2-2.148V7h-2V15.408zM9 7H11V18H9z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M12,19c0-3.526,2.609-6.434,6-6.92V4H6v17h6.294C12.105,20.366,12,19.695,12,19z"
          fill={transparentize(0.7, theme.colors.secondary)}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

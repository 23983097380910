import { useEffect, useState } from 'react';

import { TABLET_MIN_WIDTH } from '@main/theme';

import { useWindowSize } from './useWindowSize';

export interface UseBreakpoint {
  /** before tablet */
  isBeforeTablet: boolean;
  /** at least tablet */
  isAtLeastTablet: boolean;
}

/**
 * A hook that notifies when mobile/desktop breakpoints are reached.
 *
 * @returns UseBreakpoint
 */
export function useBreakpoint(): UseBreakpoint {
  const windowSize = useWindowSize();
  const [isBeforeTablet, setIsBeforeTablet] = useState(
    windowSize.width < TABLET_MIN_WIDTH,
  );
  const [isAtLeastTablet, setIsAtLeastTablet] = useState(
    windowSize.width >= TABLET_MIN_WIDTH,
  );

  useEffect(() => {
    if (windowSize.width) {
      if (windowSize.width >= TABLET_MIN_WIDTH) {
        setIsAtLeastTablet(true);
        setIsBeforeTablet(false);
      } else {
        setIsBeforeTablet(true);
        setIsAtLeastTablet(false);
      }
    }
  }, [windowSize]);

  return { isBeforeTablet, isAtLeastTablet };
}

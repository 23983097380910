import { useEffect } from 'react';

/**
 * Hook to detect whether you clicked outside an element, and call a function if so
 * https://usehooks.com/useOnClickOutside/
 *
 * @param element - The element to check the click event against
 * @param onClickOutside - Callback function to fire when you click outside the element
 */
export const useOnClickOutside = (
  element: HTMLElement | undefined | null,
  onClickOutside: (e: MouseEvent | TouchEvent) => void,
): void => {
  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent): void => {
      if (!element || element.contains(e.target as Node)) {
        return;
      }

      onClickOutside(e);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [element, onClickOutside]);
};

import React from 'react';
import { useIntl } from 'react-intl';

import { formMessages } from './messages';

export const RequiredIndicator: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <span
      style={{ color: 'red', marginLeft: '.3em' }}
      aria-label={formatMessage(formMessages.isRequired)}
    >
      *
    </span>
  );
};

import React from 'react';

import { SvgProps } from '../enums';

export const FlagGT: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55158"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55158)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H16V12H11V0Z"
        fill="#58A5FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H6V12H0V0Z"
        fill="#58A5FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H11V12H5V0Z"
        fill="white"
      />
      <path
        d="M6.38231 8.51869L6.01904 8.17512L9.86277 4.11107L10.226 4.45464L6.38231 8.51869Z"
        fill="#C6C6C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.49241 4.0527C6.49241 4.0527 5.26196 4.79529 5.26196 6.65688C5.26196 8.51847 8.25761 8.77032 8.25761 8.77032C8.25761 8.77032 6.46492 7.67688 6.23029 6.89408C5.99566 6.11129 6.49241 4.0527 6.49241 4.0527Z"
        fill="#5AB92D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9.52718 4.0527C9.52718 4.0527 10.7576 4.79529 10.7576 6.65688C10.7576 8.51847 7.76198 8.77032 7.76198 8.77032C7.76198 8.77032 9.64588 7.68991 9.90991 6.65688C10.1739 5.62385 9.52718 4.0527 9.52718 4.0527Z"
        fill="#5AB92D"
      />
      <path
        // eslint-disable-next-line max-len
        d="M7.40387 3.79977L7.90183 3.84497C7.88545 4.02539 7.98401 4.20902 8.22769 4.40611C9.01202 5.04048 9.43359 5.83179 9.10322 6.73102C8.86349 7.38353 8.53788 7.93447 8.12588 8.38196L7.75804 8.04329C8.12388 7.64595 8.41599 7.15168 8.63389 6.55859C8.87118 5.91273 8.55 5.30986 7.91326 4.79487C7.55046 4.50144 7.3706 4.16632 7.40387 3.79977Z"
        fill="#5AB92D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.08691 4.90446L7.61155 6.38755H7.2259C7.2259 6.38755 7.08691 6.85558 7.34923 6.85558C7.61155 6.85558 8.96335 6.85558 8.96335 6.85558C8.96335 6.85558 9.13909 6.73822 9.05122 6.38755C8.96335 6.03687 8.51936 5.0688 8.51936 5.0688C8.51936 5.0688 8.70908 4.90814 8.70908 4.78905C8.70908 4.66996 8.51936 4.65685 8.51936 4.65685H7.3218C7.16894 4.68327 7.08691 4.90446 7.08691 4.90446Z"
        fill="#EFE298"
      />
    </g>
  </svg>
);

import { transparentize } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

/**
 * RequestSent icon
 */
export const RequestSent: React.FC = (props) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g
        id="RequestStatusIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M3 10.636L3 13.364 13 12z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M2,3v18l20-9L2,3z M4,6.093L17.126,12L4,17.907V6.093z"
          fill={theme.colors.secondary}
          fillRule="nonzero"
        />
        <path
          d="M2.908 4.541L19.725 11.849 2.991 19.364z"
          fill={transparentize(0.7, theme.colors.secondary)}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

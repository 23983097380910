/**
 * Different methods of redacting data in slack
 */
export enum ErasureRedactionMethod {
  /** An administrator must review every redaction */
  RequiredReview = 'REQUIRED_REVIEW',
  /** Mapped identifiers should be auto-redacted */
  AutoRedactIdentifiersOnly = 'AUTO_REDACT_IDENTIFIERS_ONLY',
  /** Any message that contains the identifiers should be redacted in full */
  AutoRedactEntireMessage = 'AUTO_REDACT_ENTIRE_MESSAGE',
}

/**
 * Different modes to export data to user
 */
export enum DataPointExportMode {
  /** Gives all of the information found */
  FullData = 'FULL_DATA',
  /** Gives only the number of files/objects found */
  CountsOnly = 'COUNTS_ONLY',
  /** All information found, with manual redactions applied */
  Redacted = 'REDACTED',
}

/**
 * Fields to order datapoints by
 */
export enum DataPointOrderField {
  CreatedAt = 'createdAt',
  /** The time the datapoint was updated */
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Name = 'name',
}

/**
 * Datapoint is created by...
 */
export enum DataPointCreatedBy {
  SchemaDiscovery = 'SCHEMA_DISCOVERY',
  Manual = 'MANUAL',
  HardCoded = 'HARD_CODED',
}

/** Order fields for content classification datapoints */
export enum ContentClassificationDataPointOrderField {
  TotalSubDataPointsCount = 'totalSubDataPointsCount',
  ConfirmedSubDataPointsPercent = 'confirmedSubDataPointsPercent',
  Name = 'name',
}

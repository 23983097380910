/**
 * The arguments to be passed to each case
 */
export type Args = any[]; // eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * A specific case input. Can be the value to return or a function that returns the value and is only executed at runtime
 */
export type FunctionCase<TResult, TArgs extends Args> = (
  ...args: TArgs
) => TResult;

/**
 * A specific case input. Can be the value to return or a function that returns the value and is only executed at runtime
 */
export type Case<TResult, TArgs extends Args> =
  | FunctionCase<TResult, TArgs>
  | TResult;

/**
 * The cases to switch on. This is an object where the key is the case index, and the values are what should be executed
 * or returned when that caseKey is presented.
 */
export type Cases<
  TResult,
  TArgs extends Args,
  TCaseKeys extends string = string,
> = { [caseKey in TCaseKeys]: Case<TResult, TArgs> };

/**
 * The cases to switch on, but not all case keys need to be defined
 */
export type OCases<
  TResult,
  TArgs extends Args,
  TCaseKeys extends string = string,
> = { [caseKey in TCaseKeys]?: Case<TResult, TArgs> };

/**
 * A function that is curried with previously defined cases
 */
export type CurriedCases<
  TResult,
  TArgs extends Args,
  TCaseKeys extends string = string,
> = (
  defaultCase: Case<TResult, TArgs>,
) => (key: TCaseKeys, ...props: TArgs) => TResult;

/**
 * Check if a case is a function case
 *
 * @param checkCase - The case to check if its a function
 * @returns True if a function case
 */
export function isFunctionCase<TResult, TArgs extends Args>(
  checkCase: Case<TResult, TArgs>,
): checkCase is FunctionCase<TResult, TArgs> {
  return typeof checkCase === 'function';
}

/**
 * Helper function for switchCase that comes in handy sometimes
 *
 * @param cases - The object to switch
 * @returns The curried functions
 */
export function switchCaseRaw<
  TResult,
  TArgs extends Args,
  TCaseKeys extends string = string,
>(
  cases: Cases<TResult, TArgs, TCaseKeys>,
): (
  defaultCase: Case<TResult, TArgs>,
) => (k: TCaseKeys) => Case<TResult, TArgs> {
  return (defaultCase) => (key: TCaseKeys) =>
    key in cases ? cases[key] : defaultCase;
}

/**
 * Type guard to ensure a case
 *
 * @param caseInstance - The case to check
 * @returns True if a case
 */
function isCase<TResult, TArgs extends Args>(
  caseInstance?: Case<TResult, TArgs>,
): caseInstance is Case<TResult, TArgs> {
  return caseInstance !== undefined;
}

/**
 * Same as switchCaseRaw but optional arguments allowed
 *
 * @param cases - The object to switch
 * @returns The curried functions
 */
export function switchCaseRawOptional<
  TResult,
  TArgs extends Args,
  TCaseKeys extends string = string,
>(
  cases: OCases<TResult, TArgs, TCaseKeys>,
): (
  defaultCase: Case<TResult, TArgs>,
) => (k: TCaseKeys) => Case<TResult, TArgs> {
  return (defaultCase: Case<TResult, TArgs>) =>
    (key: TCaseKeys): Case<TResult, TArgs> => {
      const result = cases[key];
      return isCase<TResult, TArgs>(result) ? result : defaultCase;
    };
}

/**
 * Switch statement as object
 *
 * ```typescript
 * // Returns null
 * const getByKey = switchCase<string, string>({
 *   case1: 'my case',
 *   case2: 'case 2',
 * })('nothing');
 *
 * // 'my case'
 * getByKey('case1');
 * ```
 *
 * ```typescript
 * // Returns null
 * const args = [{ isTest: true }];
 * const getByKey = switchCase({
 *   case1: ({ isTest }) => isTest ? 'my test case' : 'my case',
 *   case2: 'case 2',
 * })('nothing');
 *
 * // 'my test case'
 * getByKey('case1', ...args);
 * ```
 *
 * @param cases - The object to switch
 * @returns The curried functions
 */
export function switchCase<
  TResult,
  TArgs extends Args,
  TCaseKeys extends string = string,
>(
  cases: Cases<TResult, TArgs, TCaseKeys>,
): CurriedCases<TResult, TArgs, TCaseKeys> {
  // Create the switcher
  const switcher = switchCaseRaw<TResult, TArgs, TCaseKeys>(cases);

  // Return another function that takes in the default case and returns the switch
  return (defaultCase) => {
    const switcherWithDefault = switcher(defaultCase);

    return (key: TCaseKeys, ...props) => {
      // Execute the raw switch case
      const result = switcherWithDefault(key);
      return isFunctionCase(result) ? result(...props) : result;
    };
  };
}

/**
 * Same as switch case but `TCaseKeys` will not enforce every type to be defined
 *
 * @param cases - The object to switch
 * @returns The curried functions
 */
export function switchCaseOptional<
  TResult,
  TArgs extends Args,
  TCaseKeys extends string = string,
>(
  cases: OCases<TResult, TArgs, TCaseKeys>,
): CurriedCases<TResult, TArgs, TCaseKeys> {
  // Create the switcher
  const switcher = switchCaseRawOptional<TResult, TArgs, TCaseKeys>(cases);

  // Return another function that takes in the default case and returns the switch
  return (defaultCase) => {
    const switcherWithDefault = switcher(defaultCase);

    return (key: TCaseKeys, ...props) => {
      // Execute the raw switch case
      const result = switcherWithDefault(key);
      return isFunctionCase(result) ? result(...props) : result;
    };
  };
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagAD: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54942"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54942)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0H12V12H4V0Z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H4V12H0V0Z"
        fill="#2E42A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0H16V12H12V0Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.90002 3.60712C4.90002 3.05206 5.35208 2.6032 5.90712 2.60714L10.3017 2.63832C10.8512 2.64222 11.2947 3.08878 11.2947 3.6383V7.64537C11.2947 7.64537 10.8505 9.64537 8.14899 9.64537C5.44743 9.64537 4.90002 7.6 4.90002 7.6V3.60712Z"
        fill="#BF9937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5.15002 4.20001C5.56424 4.20001 5.90002 3.86423 5.90002 3.45001C5.90002 3.0358 5.56424 2.70001 5.15002 2.70001C4.73581 2.70001 4.40002 3.0358 4.40002 3.45001C4.40002 3.86423 4.73581 4.20001 5.15002 4.20001Z"
        fill="#BF9937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5.15002 6.40002C5.56424 6.40002 5.90002 6.06424 5.90002 5.65002C5.90002 5.23581 5.56424 4.90002 5.15002 4.90002C4.73581 4.90002 4.40002 5.23581 4.40002 5.65002C4.40002 6.06424 4.73581 6.40002 5.15002 6.40002Z"
        fill="#BF9937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5.15002 8.20001C5.56424 8.20001 5.90002 7.86423 5.90002 7.45001C5.90002 7.0358 5.56424 6.70001 5.15002 6.70001C4.73581 6.70001 4.40002 7.0358 4.40002 7.45001C4.40002 7.86423 4.73581 8.20001 5.15002 8.20001Z"
        fill="#BF9937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.85 4.20001C11.2642 4.20001 11.6 3.86423 11.6 3.45001C11.6 3.0358 11.2642 2.70001 10.85 2.70001C10.4358 2.70001 10.1 3.0358 10.1 3.45001C10.1 3.86423 10.4358 4.20001 10.85 4.20001Z"
        fill="#BF9937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.85 6.40002C11.2642 6.40002 11.6 6.06424 11.6 5.65002C11.6 5.23581 11.2642 4.90002 10.85 4.90002C10.4358 4.90002 10.1 5.23581 10.1 5.65002C10.1 6.06424 10.4358 6.40002 10.85 6.40002Z"
        fill="#BF9937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M10.85 8.20001C11.2642 8.20001 11.6 7.86423 11.6 7.45001C11.6 7.0358 11.2642 6.70001 10.85 6.70001C10.4358 6.70001 10.1 7.0358 10.1 7.45001C10.1 7.86423 10.4358 8.20001 10.85 8.20001Z"
        fill="#BF9937"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.91187 8.33519L5.96441 8.20813C6.56826 8.46697 7.25543 8.59657 8.02636 8.59657C8.7969 8.59657 9.46558 8.4671 10.0328 8.20874L10.0881 8.33458C9.50246 8.60133 8.81505 8.73442 8.02636 8.73442C7.23806 8.73442 6.53308 8.60147 5.91187 8.33519Z"
        fill="#805440"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M6.11487 3.8092H7.97642H8.0235H9.88505V7.32411C9.88505 7.32411 9.88439 7.63434 9.5564 7.76998C9.22841 7.90562 8.84263 7.85708 8.84263 7.85708C8.84263 7.85708 8.4735 7.77771 8.27861 7.85708C8.15915 7.90572 8.0726 7.92979 8.0235 7.94114V7.95039C8.0235 7.95039 8.01552 7.94935 7.99996 7.94623C7.9844 7.94935 7.97642 7.95039 7.97642 7.95039V7.94114C7.92731 7.92979 7.84076 7.90572 7.72131 7.85708C7.52642 7.77771 7.15729 7.85708 7.15729 7.85708C7.15729 7.85708 6.7715 7.90562 6.44352 7.76998C6.11553 7.63434 6.11487 7.32411 6.11487 7.32411V3.8092Z"
        fill="#BF9937"
      />
      <mask
        id="mask1_270_54942"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="3"
        width="4"
        height="5"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M6.11487 3.8092H7.97642H8.0235H9.88505V7.32411C9.88505 7.32411 9.88439 7.63434 9.5564 7.76998C9.22841 7.90562 8.84263 7.85708 8.84263 7.85708C8.84263 7.85708 8.4735 7.77771 8.27861 7.85708C8.15915 7.90572 8.0726 7.92979 8.0235 7.94114V7.95039C8.0235 7.95039 8.01552 7.94935 7.99996 7.94623C7.9844 7.94935 7.97642 7.95039 7.97642 7.95039V7.94114C7.92731 7.92979 7.84076 7.90572 7.72131 7.85708C7.52642 7.77771 7.15729 7.85708 7.15729 7.85708C7.15729 7.85708 6.7715 7.90562 6.44352 7.76998C6.11553 7.63434 6.11487 7.32411 6.11487 7.32411V3.8092Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_54942)">
        <rect
          x="6.06213"
          y="3.76508"
          width="1.82812"
          height="1.95"
          fill="#D00F3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.09338 3.76508H9.98922V5.71508H8.09338V3.76508Z"
          fill="#FEDF00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.09338 5.93176H9.92151V7.95398H8.09338V5.93176Z"
          fill="#FEDF00"
        />
        <rect
          x="6.06213"
          y="5.93176"
          width="1.82812"
          height="2.02222"
          fill="#FEDF00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M9.06213 7.26508C9.33828 7.26508 9.56213 7.04122 9.56213 6.76508C9.56213 6.48893 9.33828 6.26508 9.06213 6.26508C8.78599 6.26508 8.56213 6.48893 8.56213 6.76508C8.56213 7.04122 8.78599 7.26508 9.06213 7.26508Z"
          fill="#D00F3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M7.01493 5.21771V4.10431L7.48564 4.63679L7.24386 5.21771C7.15475 5.18667 7.12354 5.25243 7.16946 5.36953C7.19682 5.43931 7.21842 5.48956 7.2395 5.52838C7.15934 5.44711 7.12592 5.39628 7.12592 5.34167C7.12592 5.29749 7.12182 5.26711 7.11111 5.24713C7.10607 5.23773 7.06379 5.22867 7.03665 5.22285C7.02609 5.22059 7.01782 5.21882 7.01493 5.21771ZM6.79501 5.21771L6.55322 4.63679L7.0125 4.1084V5.2218C7.0095 5.22295 7.0017 5.22447 6.99195 5.22636C6.96813 5.23098 6.93273 5.23786 6.92775 5.24713C6.91704 5.26711 6.91294 5.29749 6.91294 5.34167C6.91294 5.39628 6.87953 5.44711 6.79937 5.52838C6.82044 5.48956 6.84205 5.43931 6.86941 5.36953C6.91532 5.25243 6.88412 5.18667 6.79501 5.21771Z"
          fill="#FEDF00"
        />
        <rect x="8.50452" y="4.28961" width="1" height="1" fill="#D00F3A" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.56213 6.38031H7.56213V7.38031H6.56213V6.38031Z"
          fill="#D00F3A"
        />
      </g>
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagSY: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55201"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55201)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M4.5009 6.93534L3.56556 7.5L3.77891 6.39812L3 5.57347L4.05468 5.52888L4.5009 4.5L4.94711 5.52888H6L5.22289 6.39812L5.45685 7.5L4.5009 6.93534Z"
        fill="#409100"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M11.5009 6.93534L10.5656 7.5L10.7789 6.39812L10 5.57347L11.0547 5.52888L11.5009 4.5L11.9471 5.52888H13L12.2229 6.39812L12.4568 7.5L11.5009 6.93534Z"
        fill="#409100"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4H16V0H0Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V12H16V8H0Z"
        fill="#272727"
      />
    </g>
  </svg>
);

import { sp } from '@main/theme';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

import { BootstrapModal } from './BootstrapModal';

export const BootstrapModalWrapper = styled(BootstrapModal)`
  .modal-dialog {
    width: ${({ $width }) =>
      typeof $width === 'string' ? $width : `${$width}px`};
    // modals should never be wider than the window
    max-width: 95vw;
    transition:
      width 0.5s ease-in-out,
      transform 0.3s ease-out !important;
    position: relative;
  }

  .modal-content {
    border: none;
    border-radius: 0.7em;
  }

  .modal-header {
    border: none;
    padding: ${sp(8)} ${sp(8)} 0 ${sp(8)};
  }

  .modal-title {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
  }

  .modal-body {
    padding: ${({ $noPadding }) => ($noPadding ? 0 : sp(8))};
    ${({ $footer }) => $footer && `padding-bottom: ${sp(4)}`};
  }

  .modal-footer {
    border: none;
    justify-content: center;
    padding: 0 ${sp(8)} ${sp(7)} ${sp(8)};
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 4px;
  border-radius: 6px;
  /** To prevent off-centered skewing of icon */
  display: flex;
  padding: 12px;
  /** Needs this for the button to be clickable */
  z-index: 1000;
`;

export const ModalHeader = styled.h2<{
  /** whether to center-align the header text */
  centerHeader?: boolean;
}>`
  ${({ centerHeader }) =>
    centerHeader
      ? `
         text-align: center;
        `
      : ''}
`;

import React from 'react';

import { SvgProps } from '../enums';

export const FlagZW: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55076"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55076)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55076"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55076)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 1V11H16V1H0Z"
          fill="#FECA00"
        />
        <path
          d="M0 4.125H-0.875V5V7V7.875H0H16H16.875V7V5V4.125H16H0Z"
          fill="#272727"
          stroke="#E31D1C"
          strokeWidth="1.75"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2V0H16V2H0ZM0 12V10H16V12H0Z"
          fill="#5EAA22"
        />
      </g>
      <path
        // eslint-disable-next-line max-len
        d="M0.298481 -0.401135L-0.5 -0.995277V0V12V12.9805L0.293611 12.4047L8.45926 6.48069L9.01008 6.08108L8.46413 5.67484L0.298481 -0.401135Z"
        fill="#F7FCFF"
        stroke="#272727"
      />
      <mask
        id="mask2_270_55076"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-2"
        width="11"
        height="16"
      >
        <path
          // eslint-disable-next-line max-len
          d="M0.298481 -0.401135L-0.5 -0.995277V0V12V12.9805L0.293611 12.4047L8.45926 6.48069L9.01008 6.08108L8.46413 5.67484L0.298481 -0.401135Z"
          fill="white"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask2_270_55076)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.73922 7.13914L1.84976 8.66436L2.71644 6.62606L1.22723 5.19195H2.98504L3.70633 3.46252L4.47242 5.19195H6.22723L4.65936 6.62606L5.44404 8.66436L3.73922 7.13914Z"
          fill="#E31D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M2.5088 4.21075C2.5088 4.21075 2.48137 4.14463 2.64328 4.08616C2.80519 4.02769 2.85458 3.90379 2.9815 3.99497C3.10842 4.08616 3.18783 3.90284 3.22424 4.16577C3.26066 4.4287 3.36103 4.84331 3.36103 4.84331L5.00482 6.07864H4.69598C4.69598 6.07864 4.21506 6.78523 4.29446 7.40521C4.29446 7.40521 3.96264 7.35473 3.51288 7.35473C3.06313 7.35473 2.76855 7.46252 2.76855 7.46252L2.5088 6.50068C2.5088 6.50068 2.60285 6.3391 2.64328 6.20887C2.68371 6.07864 2.85111 6.0546 2.85111 5.951C2.85111 5.84741 2.68599 5.76056 2.76855 5.49239C2.85111 5.22422 2.85111 4.42203 2.85111 4.42203C2.85111 4.42203 2.59018 4.30728 2.64328 4.30728C2.69638 4.30728 2.81742 4.21075 2.73035 4.21075C2.64328 4.21075 2.5088 4.21075 2.5088 4.21075Z"
          fill="#FECA00"
        />
      </g>
    </g>
  </svg>
);

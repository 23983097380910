import React from 'react';

import { SvgProps } from '../enums';

export const FlagTJ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55103"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55103)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4H16V0H0Z"
        fill="#C51918"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V12H16V8H0Z"
        fill="#409100"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.97263 7.78867C7.76631 7.79458 7.62888 7.57663 7.62888 7.57663L7.84041 7.52213C7.85357 7.38265 7.94923 7.30847 7.94923 7.30847C8.01248 7.34911 8.08084 7.53227 8.08084 7.53227L8.26207 7.55774C8.26207 7.55774 8.17895 7.78276 7.97263 7.78867ZM6.96523 7.851C6.76175 7.88556 6.59532 7.68886 6.59532 7.68886L6.79721 7.60545C6.79082 7.46549 6.87523 7.37873 6.87523 7.37873C6.94352 7.41017 7.03671 7.58203 7.03671 7.58203H7.21972C7.21972 7.58203 7.16872 7.81643 6.96523 7.851ZM6.24146 8.04909L6.33362 8.37728C6.81168 8.24792 7.36657 8.18288 7.99818 8.18288C8.62978 8.18288 9.18467 8.24792 9.66273 8.37728L9.75489 8.04909C9.24487 7.91109 8.65934 7.84245 7.99818 7.84245C7.33701 7.84245 6.75148 7.91109 6.24146 8.04909ZM9.37001 7.7229C9.37001 7.7229 9.20358 7.9196 9.00009 7.88504C8.79661 7.85048 8.74561 7.61607 8.74561 7.61607H8.92862C8.92862 7.61607 9.02181 7.44421 9.09009 7.41277C9.09009 7.41277 9.17451 7.49954 9.16812 7.63949L9.37001 7.7229Z"
        fill="#FFBB00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.97927 6.84931C7.97927 6.84931 7.74477 7.38596 7.37545 7.42746C7.00612 7.46897 6.81854 7.12225 6.81854 7.12225C6.81854 7.12225 6.79435 7.48281 6.35573 7.62179C5.91712 7.76077 5.68616 7.20548 5.68616 7.20548C5.68616 7.20548 5.91451 7.40974 6.26868 7.32073C6.62284 7.23173 6.76264 6.90302 6.76264 6.90302C6.76264 6.90302 7.02475 7.24571 7.37545 7.07437C7.72614 6.90302 7.92016 6.69361 7.92016 6.49267V5.89176C7.92016 5.89176 7.82968 5.73594 7.67273 5.73594C7.51577 5.73594 7.47197 5.8464 7.47197 5.8464C7.47197 5.8464 7.4107 5.22124 7.9752 5.1826L7.97517 5.18109L7.99078 5.18169L8.00639 5.18109L8.00637 5.1826C8.57086 5.22124 8.5096 5.8464 8.5096 5.8464C8.5096 5.8464 8.4658 5.73594 8.30884 5.73594C8.15189 5.73594 8.06141 5.89176 8.06141 5.89176V6.49267C8.06141 6.69361 8.25543 6.90302 8.60612 7.07437C8.95682 7.24571 9.21892 6.90302 9.21892 6.90302C9.21892 6.90302 9.35872 7.23173 9.71289 7.32073C10.0671 7.40974 10.2954 7.20548 10.2954 7.20548C10.2954 7.20548 10.0644 7.76077 9.62583 7.62179C9.18722 7.48281 9.16303 7.12225 9.16303 7.12225C9.16303 7.12225 8.97545 7.46897 8.60612 7.42746C8.2368 7.38596 8.0023 6.84931 8.0023 6.84931L7.99078 6.141L7.97927 6.84931ZM7.97407 5.35093C7.71366 5.35093 7.66091 5.57139 7.66091 5.57139C7.89021 5.57139 7.97407 5.67134 7.97407 5.67134C7.97407 5.67134 8.14384 5.57139 8.31477 5.57139C8.31477 5.57139 8.23449 5.35093 7.97407 5.35093Z"
        fill="#FFBB00"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.95025 6.49922L4.9539 6.58452C4.88098 5.73278 5.23154 4.927 5.96829 4.18865C7.05207 3.1025 9.24675 3.2083 10.1809 4.19874C10.7147 4.76462 10.9881 5.54872 11.0144 6.52846L10.0147 6.55528C9.99457 5.80479 9.80307 5.25553 9.45348 4.88489C8.89619 4.29404 7.35016 4.21951 6.67616 4.89498C6.13412 5.43821 5.90459 5.9658 5.95025 6.49922Z"
        fill="#FFBB00"
      />
    </g>
  </svg>
);

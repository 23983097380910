/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DocumentQuestion: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5019 22.0042C14.0158 22.0042 12 19.9884 12 17.5024C12 15.0163 14.0158 13.0005 16.5019 13.0005C18.9889 13.0005 21.0037 15.0163 21.0037 17.5024C21.0037 19.9884 18.9889 22.0042 16.5019 22.0042"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5007 19.8883C16.4767 19.8883 16.4557 19.9093 16.4557 19.9333C16.4557 19.9573 16.4767 19.9783 16.5007 19.9783C16.5247 19.9783 16.5457 19.9573 16.5457 19.9333C16.5457 19.9093 16.5257 19.8883 16.5007 19.8883V19.8883"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5007 18.0652C17.278 18.0652 17.9083 17.4349 17.9083 16.6576C17.9083 15.8803 17.279 15.25 16.5007 15.25C15.7424 15.25 15.1291 15.8492 15.1001 16.6006"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0024 9.99901V7.82611C18.0024 7.29588 17.7913 6.78667 17.4162 6.41152L14.587 3.58234C14.2118 3.20718 13.7026 2.99609 13.1724 2.99609H5.99742C4.89195 2.99609 3.99658 3.89147 3.99658 4.99693V19.0028C3.99658 20.1082 4.89195 21.0036 5.99742 21.0036H9.99908"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0023 7.99818H14.0007C13.4484 7.99818 13.0002 7.54999 13.0002 6.99776V2.99609"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

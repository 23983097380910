import { ComparisonOperator } from '@transcend-io/privacy-types';

import { contains } from './contains';
import { isEqualTo } from './isEqualTo';
import { isNotEqualTo } from './isNotEqualTo';
import { isNotOneOf } from './isNotOneOf';
import { isNotShown } from './isNotShown';
import { isOneOf } from './isOneOf';
import { isShown } from './isShown';
import { ConditionalLogicHelperOptions } from './types';

export const DISPLAY_LOGIC_COMPARISON_OPERATOR_TO_FUNCTION_MAP: Record<
  ComparisonOperator,
  (options: ConditionalLogicHelperOptions) => boolean
> = {
  [ComparisonOperator.IsEqualTo]: isEqualTo,
  [ComparisonOperator.IsNotEqualTo]: isNotEqualTo,
  [ComparisonOperator.IsOneOf]: isOneOf,
  [ComparisonOperator.IsNotOneOf]: isNotOneOf,
  [ComparisonOperator.Contains]: contains,
  [ComparisonOperator.IsShown]: isShown,
  [ComparisonOperator.IsNotShown]: isNotShown,
};

export const RISK_LOGIC_COMPARISON_OPERATOR_TO_FUNCTION_MAP: Partial<
  Record<
    ComparisonOperator,
    (
      options: Pick<
        ConditionalLogicHelperOptions,
        'questionToCheck' | 'comparisonOperands'
      >,
    ) => boolean
  >
> = {
  [ComparisonOperator.IsEqualTo]: isEqualTo,
  [ComparisonOperator.IsNotEqualTo]: isNotEqualTo,
  [ComparisonOperator.IsOneOf]: isOneOf,
  [ComparisonOperator.IsNotOneOf]: isNotOneOf,
  [ComparisonOperator.Contains]: contains,
};

import { useFormatMessageGeneric } from '@main/core-ui/src/GenericFormattedMessage';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ValidatorErrors } from './types';
import { ValidatorKey } from './validation/enums';

export const ValidationError = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.error};
  margin: 0.4rem 0;
  font-size: 14px;
`;

export interface IValidationErrorsProps {
  /** the errors to display */
  errors?: ValidatorErrors;
  /**
   * the order in which to display the errors, all unspecified errors will be
   * appended after the order specified
   */
  displayOrder?: ValidatorKey[];
  /** whether to show multiple errors or not. Defaults to true */
  showMultiple?: boolean;
}

/**
 * displays multiple validation errors
 */
export const ValidationErrors: React.FC<IValidationErrorsProps> = ({
  errors,
  displayOrder = [],
  showMultiple = true,
}) => {
  const { formatMessageGeneric } = useFormatMessageGeneric();

  // build a list of errors in the specified order
  const errorEntries = useMemo(() => {
    const orderSet = new Set<string | ValidatorKey>(displayOrder);
    return (
      displayOrder
        .map((id) => [id, errors?.[id]])
        .filter(([, error]) => !!error)
        // concat any unspecified errors (all if displayOrder unspecified)
        .concat(
          Object.entries(errors ?? {}).filter(([key]) => !orderSet.has(key)),
        )
    );
  }, [displayOrder, errors]);

  if (errorEntries.length === 0) {
    return null;
  }

  return (
    <ValidationError id="form-input-validation-errors">
      {errorEntries.length === 1 || !showMultiple ? (
        formatMessageGeneric(errorEntries[0][1])
      ) : (
        <ul style={{ paddingInlineStart: '2em' }}>
          {errorEntries.map((entry) => (
            <li key={entry[0] as string}>{formatMessageGeneric(entry[1])}</li>
          ))}
        </ul>
      )}
    </ValidationError>
  );
};

import React from 'react';

import { SvgProps } from '../enums';

export const FlagAF: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_21681"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_21681)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H16V12H11V0Z"
        fill="#67BD38"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H11V12H5V0Z"
        fill="#D51700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H5V12H0V0Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5 6.05003C5 7.7345 6.36553 9.10003 8.05 9.10003C9.73447 9.10003 11.1 7.7345 11.1 6.05003C11.1 5.35726 10.869 4.71843 10.4799 4.20635L10.037 5.54382C10.0781 5.70573 10.1 5.87533 10.1 6.05003C10.1 6.71169 9.78653 7.30014 9.3 7.67497V5.30003H9C9 4.74775 8.55228 4.30003 8 4.30003C7.44772 4.30003 7 4.74775 7 5.30003H6.7V7.59279C6.27094 7.21704 6 6.66518 6 6.05003C6 5.91512 6.01303 5.78326 6.0379 5.65563H6L5.62948 4.19409C5.23468 4.70821 5 5.35172 5 6.05003ZM7.7 6.25425V8.07027C7.81374 8.08984 7.93068 8.10003 8.05 8.10003C8.13463 8.10003 8.21806 8.0949 8.3 8.08494V6.25425C8.2053 6.284 8.10452 6.30003 8 6.30003C7.89548 6.30003 7.7947 6.284 7.7 6.25425Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

import { makeEnum } from '@transcend-io/type-utils';

export const AdminDashboardAdministrationPath = makeEnum({
  Billing: '/admin/billing',
  Organization: '/admin/organization',
  Users: '/admin/users',
  Teams: '/admin/teams',
  Scopes: '/admin/scopes',
  Sso: '/admin/sso',
});

/** Override type */
export type AdminDashboardAdministrationPath =
  (typeof AdminDashboardAdministrationPath)[keyof typeof AdminDashboardAdministrationPath];

export const INFRASTRUCTURE_PATH_PREFIX = '/infrastructure';
export const SOMBRA_PATH_PREFIX = `${INFRASTRUCTURE_PATH_PREFIX}/sombra`;

export const AdminDashboardInfrastructurePath = makeEnum({
  Sombras: `${SOMBRA_PATH_PREFIX}/sombras`,
  SecuritySettings: `${SOMBRA_PATH_PREFIX}/security-settings`,
  SombraRotateKeys: `${SOMBRA_PATH_PREFIX}/rotate-keys`,
  SombraChangelog: `${SOMBRA_PATH_PREFIX}/changelog`,
  SombraApiKeys: `${SOMBRA_PATH_PREFIX}/api-keys`,
  EmailDomains: `${INFRASTRUCTURE_PATH_PREFIX}/email-domains`,
  ApiKeys: `${INFRASTRUCTURE_PATH_PREFIX}/api-keys`,
  Integrations: `${INFRASTRUCTURE_PATH_PREFIX}/integrations`,
  ConnectNewService: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/new`,
  ServiceConfiguration: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId`,
  DataSiloActivities: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/activities`,
  DataSiloConnection: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/connection`,
  DataSiloSiloDiscovery: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/silo-discovery`,
  DataSiloStructuredDiscovery: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/structured-discovery`,
  DataSiloUnstructuredDiscovery: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/unstructured-discovery`,
  DataSiloDsrAutomation: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/dsr-automation`,
  DataSiloLookups: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/lookups`,
  DataSiloAuditTrail: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/audit-trail`,
  DataSiloManageDatapoints: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/manage-datapoints`,
  DataSiloProcessedRequests: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/processed-requests`,
  DataSiloActionItems: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/:dataSiloId/action-items`,
  OAuthFailure: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/failure`,
  OAuthSuccess: `${INFRASTRUCTURE_PATH_PREFIX}/integrations/success/:dataSiloId`,
  OAuthLanding: `${INFRASTRUCTURE_PATH_PREFIX}/oauth-landing`,
  AttributesManagement: `${INFRASTRUCTURE_PATH_PREFIX}/attributes`,
  AttributeManagement: `${INFRASTRUCTURE_PATH_PREFIX}/attributes/:attributeKeyId`,
  CreateNewAttribute: `${INFRASTRUCTURE_PATH_PREFIX}/attributes/new`,
});

/** Override type */
export type AdminDashboardInfrastructurePath =
  (typeof AdminDashboardInfrastructurePath)[keyof typeof AdminDashboardInfrastructurePath];

/**
 * These are routes that are available from a logged in
 * or logged out view and they should not show a side menu.
 */
export const AdminDashboardPublicOrPrivatePath = makeEnum({
  /**
   * This path is more related to privacy-requests but unique in that
   * it can be accessible via a token from a user without a session
   */
  BulkRequests: '/bulk-requests',
  /**
   * This assessment form path is accessed via a token from an
   * external user that was assigned to fill out the form
   */
  AssessmentForm: '/view-assessment-form/:assessmentFormId',
  Login: '/login',
  LoginFailure: '/login-error',
  SamlLoginRedirect: '/login-redirect',
  InvalidateUserEmail: '/noverify/:token',
  CreateAssessmentForm: '/assessments/groups/:assessmentGroupId/createNew',
});

/** Override type */
export type AdminDashboardPublicOrPrivatePath =
  (typeof AdminDashboardPublicOrPrivatePath)[keyof typeof AdminDashboardPublicOrPrivatePath];

/**
 * These are routes on the admin dashboard that are private routes (should show the side menu)
 * But are not really related to any grouping of routes on the admin dashboard side menu.
 */
export const AdminDashboardPrivateMiscPath = makeEnum({
  AssumeRole: '/assume-role/:roleId',
  ActionItems: '/action-items',
  ActionItemsMine: '/action-items/mine',
  ActionItemsMyTeam: '/action-items/my-team',
  ActionItemsAll: '/action-items/all',
  Profile: '/account/profile',
  ReLogin: '/re-login',
  CtaPage: '/cta/:ctaId',
  CreateOrganizationLoggedIn: '/anotha-one',
});

/** Override type */
export type AdminDashboardPrivateMiscPath =
  (typeof AdminDashboardPrivateMiscPath)[keyof typeof AdminDashboardPrivateMiscPath];

export const AdminDashboardPublicMiscPath = makeEnum({
  BulkRequests: '/bulk-requests',
  ChangePassword: '/change-password',
  ResetPassword: '/reset-password',
  Demo: '/demo',
  CreateOrganization: '/signup',
});

/** Override type */
export type AdminDashboardPublicMiscPath =
  (typeof AdminDashboardPublicMiscPath)[keyof typeof AdminDashboardPublicMiscPath];

/**
 * These are routes on the admin dashboard that are redirect to other routes
 * on the admin dashboard. See the admin dashboard router for the locations
 * where these are mapped to.
 */
export const AdminDashboardRedirectPath = makeEnum({
  Base: '/',
  DataMap: '/data-map',
  DataMapSilos: '/data-map/silos',
  DataMapSiloConfig: '/data-map/silo/:dataSiloId',
  PrivacyCenter: '/privacy-center',
  Requests: '/requests',
  RequestBase: '/request',
  Request: '/request/:requestId',
  RequestDataSilo: '/request/:requestId/:dataSiloId',
  Settings: '/settings',
  WellKnownChangePassword: '/.well-known/change-password',
  ConnectedServices: `/privacy-requests/connected-services`,
  ConnectNewService: `/privacy-requests/connected-services/new`,
  ServiceConfiguration: `/privacy-requests/connected-services/configuration/:dataSiloId`,
  DataSiloActivities: `/privacy-requests/connected-services/configuration/:dataSiloId/activities`,
  DataSiloConfiguration: `/privacy-requests/connected-services/configuration/:dataSiloId/configuration`,
  DataSiloConnection: `/privacy-requests/connected-services/configuration/:dataSiloId/connection`,
  DataSiloEmailAutomation: `/privacy-requests/connected-services/configuration/:dataSiloId/email-automation`,
  DataSiloLookups: `/privacy-requests/connected-services/configuration/:dataSiloId/lookups`,
  DataSiloAuditTrail: `/privacy-requests/connected-services/configuration/:dataSiloId/audit-trail`,
  DataSiloManageDatapoints: `/privacy-requests/connected-services/configuration/:dataSiloId/manage-datapoints`,
  DataSiloProcessedRequests: `/privacy-requests/connected-services/configuration/:dataSiloId/processed-requests`,
  OAuthFailure: `/privacy-requests/connected-services/failure`,
  OAuthSuccess: `/privacy-requests/connected-services/success/:dataSiloId`,
  PrivacyRequestsPrivacyCenter: `/privacy-requests/privacy-center`,
  DataSiloDsrAutomation: `/infrastructure/integrations/:dataSiloId/email-automation`,
  DataSiloConfiguration2023: `/infrastructure/integrations/:dataSiloId/configuration`,
  EmailSettings: `/privacy-center/email-settings`,
  EmailTemplates: `/privacy-requests/email-templates`,
  EmailSettingsGeneral: `/privacy-center/email-settings/general`,
  RequestSettingsAuthenticationMethods: `/privacy-requests/settings/authentication-methods`,
  PreferenceStoreSettings: `/preference-store/user-preferences/settings`,
  PreferenceOptions: `/preference-store/preference-options`,
  DataInventoryAudit: `/data-map/data-inventory/audit`,
});

/** Override type */
export type AdminDashboardRedirectPath =
  (typeof AdminDashboardRedirectPath)[keyof typeof AdminDashboardRedirectPath];

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const User: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M13.591 7.65901C14.4697 8.53769 14.4697 9.96231 13.591 10.841C12.7123 11.7197 11.2877 11.7197 10.409 10.841C9.53033 9.96231 9.53033 8.53769 10.409 7.65901C11.2877 6.78033 12.7123 6.78033 13.591 7.65901"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.321 16.541C16.1 15.152 14.906 14.088 13.456 14.088H10.544C9.09296 14.088 7.89996 15.152 7.67896 16.541"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

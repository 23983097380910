import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const LinkAssessment: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M8.66668 13H9.66668C10.5872 13 11.3333 12.2538 11.3333 11.3333V4.58111C11.3333 4.05068 11.1226 3.54197 10.7476 3.1669L9.16644 1.58579C8.79137 1.21071 8.28266 1 7.75223 1H3.00001C2.07954 1 1.33334 1.74619 1.33334 2.66667V6.33333"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66666 9C5.77094 9.00068 6.66598 9.89571 6.66666 11V11C6.66598 12.1043 5.77094 12.9993 4.66666 13"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66666 13C1.56237 12.9993 0.667337 12.1043 0.666656 11H0.666656C0.667337 9.89571 1.56237 9.00068 2.66666 9"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66666 11.0001H2.66666"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3176 4.33321H9C8.44772 4.33321 8 3.8855 8 3.33321V1.01562"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { mkType } from './mkSchema';
import type { SchemaToType } from './types';

export const PageInfo = mkType({
  name: 'PageInfo',
  comment: 'Information to aid in pagination',
  fields: {
    endCursor: {
      comment: 'When paginating forwards, the cursor to continue',
      optional: true,
      type: 'string',
    },
    hasNextPage: {
      comment: 'When paginating forwards, are there more items?',
      type: 'boolean',
    },
    hasPreviousPage: {
      comment: 'When paginating backwards, are there more items?',
      type: 'boolean',
    },
    startCursor: {
      comment: 'When paginating backwards, the cursor to continue',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type PageInfo = SchemaToType<typeof PageInfo>;

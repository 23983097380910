import { mkType, SchemaToType } from '@main/schema-utils';

import { FeatureGate, SupportTier } from './enums';

/**
 *Set of Features an organization can make use of
 */
export const FeatureSet = mkType({
  name: 'FeatureSet',
  comment: 'Features allowed for an organization',
  fields: {
    allowPrivateDockerRegistryAccess: {
      comment:
        'If true, an organization can pull from our private docker registry. ' +
        'This is useful for companies not on AWS',
      type: 'boolean',
    },
    whiteLabelPrivacyRequestEmails: {
      comment:
        'If true, an organization can white label the emails that are sent to the data subject during a privacy request',
      type: 'boolean',
    },
    whiteLabelPrivacyCenter: {
      comment:
        'If true, an organization can white label the emails that are sent to the data subject during a privacy request',
      type: 'boolean',
    },
    disabledPrivacyCenterAboutTranscend: {
      comment:
        'If true, the "about transcend" page will be disabled on the Privacy Center. ' +
        'The "show me" side menu item will also be disabled',
      type: 'boolean',
    },
    isSandbox: {
      comment: 'Whether or not the organization is a sandbox-only account',
      type: 'boolean',
    },
    assessments: {
      comment: 'The assessments feature',
      type: { FeatureGate },
    },
    prompts: {
      comment: 'The prompts feature',
      type: { FeatureGate },
    },
    privacyCenter: {
      comment: 'The privacy center feature',
      type: { FeatureGate },
    },
    selfHostedSombra: {
      comment: 'Access to the selfHostedSombra feature',
      type: { FeatureGate },
    },
    supportTier: {
      comment: 'The level of support that the customer has purchased',
      type: { SupportTier },
    },
    auditorMonthlyUsageLimit: {
      comment: 'The monthly usage limit for auditor runs',
      type: 'int',
    },
    transcendHostedPromptManagerMonthlyUsageLimit: {
      comment:
        'The monthly usage limit for Transcend hosted prompt manager runs',
      type: 'int',
    },
    dataLineage: {
      comment: 'Access to the dataLineage feature',
      type: { FeatureGate },
    },
    siloDiscovery: {
      comment: 'Access to the siloDiscovery feature',
      type: { FeatureGate },
    },
    structuredDataDiscovery: {
      comment: 'Access to the structuredDataDiscovery feature',
      type: { FeatureGate },
    },
    unstructuredDataDiscovery: {
      comment: 'Access to the unstructuredDataDiscovery feature',
      type: { FeatureGate },
    },
    dsrAutomation: {
      comment: 'Access to the dsrAutomation feature',
      type: { FeatureGate },
    },
    preferenceStore: {
      comment: 'Access to the preferenceStore feature',
      type: { FeatureGate },
    },
    webConsent: {
      comment: 'Access to the webConsent feature',
      type: { FeatureGate },
    },
    mobileConsentApps: {
      comment: 'The number of mobile consent apps allowed',
      type: 'int',
    },
    contractScanning: {
      comment: 'Access to the contractScanning feature',
      type: { FeatureGate },
    },
    pathfinder: {
      comment: 'Access to the pathfinder feature',
      type: { FeatureGate },
    },
    llmClassifier: {
      comment:
        'Access to large language model (LLM) for content classification feature',
      type: { FeatureGate },
    },
    llmUnstructured: {
      comment:
        'Access to large language model (LLM) for unstructured classification feature',
      type: { FeatureGate },
    },
    actionItemChecklists: {
      comment:
        'Whether to show action item onboarding checklists in the account',
      type: { FeatureGate },
    },
    airgapAllowedVersionTags: {
      comment:
        "The version tags that are allowed for the org (e.g. 'beta', 'verizon-test')",
      type: 'string',
      list: true,
    },
    maxSyncDomainCount: {
      comment: 'The maximum number of sync domains allowed for the org',
      type: 'int',
    },
    customDomainUI: {
      comment: 'Access to the customDomain feature',
      type: { FeatureGate },
    },
    consentWorkflows: {
      comment: 'Access to the consent workflows feature',
      type: { FeatureGate },
    },
    workflowsv2: {
      comment: 'Access to Workflows V2',
      type: 'boolean',
    },
  },
});

/** Override type */
export type FeatureSet = SchemaToType<typeof FeatureSet>;

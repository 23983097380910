import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Team: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16.0017"
      cy="11.5058"
      r="2.00083"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2.99622"
      y="2.99625"
      width="18.0075"
      height="18.0075"
      rx="4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="8.99872"
      cy="11.0056"
      r="2.50104"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M14.501 21.0037V20.0033C14.501 18.0695 12.9334 16.5019 10.9996 16.5019H7.05291C5.43322 16.5043 4.02745 17.6193 3.65649 19.196"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0017 16.5079H17.5083C18.8822 16.5117 20.1246 17.3256 20.6767 18.5837"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

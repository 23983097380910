import { UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import {
  CreateRiskCategoryInput,
  RiskCategory,
  UpdateRiskCategoryWithinFrameworkInput,
} from './riskCategory';
import { CreateRiskLevelInput, RiskLevel } from './riskLevel';
import {
  CreateRiskMatrixColumnInput,
  RiskMatrixColumn,
} from './riskMatrixColumn';
import { CreateRiskMatrixRowInput, RiskMatrixRow } from './riskMatrixRow';

export const RiskFramework = mkType({
  name: 'RiskFramework',
  comment: 'The risk methodology used to evaluate systems',
  fields: {
    id: {
      comment: 'The id of the risk framework',
      type: 'id',
      modelName: 'riskFramework',
    },
    title: {
      comment: 'The display name of the risk framework',
      type: 'string',
    },
    description: {
      comment: 'A description of what this risk framework represents',
      type: 'string',
    },
    riskLevels: {
      comment:
        'The risk levels associated with this risk framework, sorted lowest to highest risk',
      type: RiskLevel,
      list: true,
    },
    riskCategories: {
      comment: 'The risk categories associated with this risk framework',
      type: RiskCategory,
      list: true,
    },
    riskMatrixColumns: {
      comment:
        'The risk matrix columns associated with this risk framework, sorted lowest to highest',
      type: RiskMatrixColumn,
      list: true,
    },
    riskMatrixRows: {
      comment:
        'The risk matrix rows associated with this risk framework, sorted lowest to highest',
      type: RiskMatrixRow,
      list: true,
    },
    riskMatrix: {
      comment:
        'The risk levels that correspond to a particular risk matrix column and row combination. ' +
        'The first index is the risk matrix row index, and the second index is the risk matrix column index.',
      type: RiskLevel,
      list: true,
      doubleList: true,
      optional: true,
    },
    creator: {
      comment: 'The user who created this risk framework',
      type: UserPreview,
      optional: true,
    },
    riskMatrixRowTitle: {
      comment: 'The title of the risk matrix columns in this framework',
      type: 'string',
    },
    riskMatrixColumnTitle: {
      comment: 'The title of the risk matrix columns in this framework',
      type: 'string',
    },
  },
});

/** Override type */
export type RiskFramework = SchemaToType<typeof RiskFramework>;

export const CreateRiskFrameworkInput = mkInput({
  name: 'CreateRiskFrameworkInput',
  comment: 'Input for creating a risk framework',
  fields: {
    title: RiskFramework.fields.title,
    description: {
      ...RiskFramework.fields.description,
      optional: true,
    },
    riskLevels: {
      ...RiskFramework.fields.riskLevels,
      type: CreateRiskLevelInput,
    },
    riskCategories: {
      ...RiskFramework.fields.riskCategories,
      type: CreateRiskCategoryInput,
    },
    riskMatrixColumns: {
      ...RiskFramework.fields.riskMatrixColumns,
      type: CreateRiskMatrixColumnInput,
      optional: true,
    },
    riskMatrixRows: {
      ...RiskFramework.fields.riskMatrixRows,
      type: CreateRiskMatrixRowInput,
      optional: true,
    },
    riskMatrix: {
      ...RiskFramework.fields.riskMatrix,
      comment:
        'The titles of risk levels that correspond to a particular risk matrix column and row combination' +
        'The first index is the risk matrix row index, and the second index is the risk matrix column index.',
      type: 'string',
    },
    riskMatrixRowTitle: {
      ...RiskFramework.fields.riskMatrixRowTitle,
      optional: true,
    },
    riskMatrixColumnTitle: {
      ...RiskFramework.fields.riskMatrixColumnTitle,
      optional: true,
    },
  },
});

/** Override type */
export type CreateRiskFrameworkInput = SchemaToType<
  typeof CreateRiskFrameworkInput
>;

export const CreateRiskFrameworksInput = mkInput({
  name: 'CreateRiskFrameworksInput',
  comment: 'Input for creating multiple risk frameworks',
  fields: {
    riskFrameworks: {
      comment: 'The risk frameworks to create.',
      type: CreateRiskFrameworkInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateRiskFrameworksInput = SchemaToType<
  typeof CreateRiskFrameworksInput
>;

export const UpdateRiskLevelWithinFrameworkInput = mkInput({
  name: 'UpdateRiskLevelWithinFrameworkInput',
  comment:
    'Input for updating a risk level within the context of a risk framework',
  fields: {
    id: {
      comment: 'The id of the risk level',
      type: 'id',
      modelName: 'riskLevel',
      // Must be optional because you can also create new ones as you update the whole list
      optional: true,
    },
    title: CreateRiskLevelInput.fields.title,
    color: CreateRiskLevelInput.fields.color,
  },
});

/** Override type */
export type UpdateRiskLevelWithinFrameworkInput = SchemaToType<
  typeof UpdateRiskLevelWithinFrameworkInput
>;

export const UpdateRiskMatrixRowWithinFrameworkInput = mkInput({
  name: 'UpdateRiskMatrixRowWithinFrameworkInput',
  comment:
    'Input for updating a risk matrix row within the context of a risk framework',
  fields: {
    id: {
      comment: 'The id of the risk matrix row',
      type: 'id',
      modelName: 'riskMatrixRow',
      // Must be optional because you can also create new ones as you update the whole list
      optional: true,
    },
    title: CreateRiskMatrixRowInput.fields.title,
  },
});

/** Override type */
export type UpdateRiskMatrixRowWithinFrameworkInput = SchemaToType<
  typeof UpdateRiskMatrixRowWithinFrameworkInput
>;

export const UpdateRiskMatrixColumnWithinFrameworkInput = mkInput({
  name: 'UpdateRiskMatrixColumnWithinFrameworkInput',
  comment:
    'Input for updating a risk matrix column within the context of a risk framework',
  fields: {
    id: {
      comment: 'The id of the risk matrix column',
      type: 'id',
      modelName: 'riskMatrixColumn',
      // Must be optional because you can also create new ones as you update the whole list
      optional: true,
    },
    title: CreateRiskMatrixColumnInput.fields.title,
  },
});

/** Override type */
export type UpdateRiskMatrixColumnWithinFrameworkInput = SchemaToType<
  typeof UpdateRiskMatrixColumnWithinFrameworkInput
>;

export const UpdateRiskFrameworkInput = mkInput({
  name: 'UpdateRiskFrameworkInput',
  comment: 'Input for updating a risk framework',
  fields: {
    id: {
      comment: 'The id of the risk framework',
      type: 'id',
      modelName: 'riskFramework',
    },
    title: {
      ...CreateRiskFrameworkInput.fields.title,
      optional: true,
    },
    description: CreateRiskFrameworkInput.fields.description,
    riskLevels: {
      ...CreateRiskFrameworkInput.fields.riskLevels,
      type: UpdateRiskLevelWithinFrameworkInput,
      optional: true,
    },
    riskCategories: {
      ...CreateRiskFrameworkInput.fields.riskCategories,
      type: UpdateRiskCategoryWithinFrameworkInput,
      optional: true,
    },
    riskMatrixColumns: {
      ...CreateRiskFrameworkInput.fields.riskMatrixColumns,
      type: UpdateRiskMatrixColumnWithinFrameworkInput,
    },
    riskMatrixRows: {
      ...CreateRiskFrameworkInput.fields.riskMatrixRows,
      type: UpdateRiskMatrixRowWithinFrameworkInput,
    },
    riskMatrix: CreateRiskFrameworkInput.fields.riskMatrix,
    riskMatrixRowTitle: {
      ...RiskFramework.fields.riskMatrixRowTitle,
      optional: true,
    },
    riskMatrixColumnTitle: {
      ...RiskFramework.fields.riskMatrixColumnTitle,
      optional: true,
    },
  },
});

/** Override type */
export type UpdateRiskFrameworkInput = SchemaToType<
  typeof UpdateRiskFrameworkInput
>;

export const UpdateRiskFrameworksInput = mkInput({
  name: 'UpdateRiskFrameworksInput',
  comment: 'Input for updating multiple risk frameworks',
  fields: {
    riskFrameworks: {
      comment: 'The risk frameworks to update',
      type: UpdateRiskFrameworkInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateRiskFrameworksInput = SchemaToType<
  typeof UpdateRiskFrameworksInput
>;

export const DeleteRiskFrameworksInput = mkInput({
  name: 'DeleteRiskFrameworksInput',
  comment: 'Input for deleting risk frameworks',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'riskFramework',
      list: true,
    },
  },
});

/** Override type */
export type DeleteRiskFrameworksInput = SchemaToType<
  typeof DeleteRiskFrameworksInput
>;

export const RiskFrameworksFiltersInput = mkInput({
  name: 'RiskFrameworksFiltersInput',
  comment: 'Inputs for filtering a list of risk frameworks',
  fields: {
    ids: {
      comment: 'The ids of risk frameworks',
      type: 'id',
      modelName: 'riskFramework',
      optional: true,
      list: true,
    },
    text: {
      comment: 'The titles or descriptions of risk frameworks',
      optional: true,
      type: 'string',
    },
  },
});

/** Override type */
export type RiskFrameworksFiltersInput = SchemaToType<
  typeof RiskFrameworksFiltersInput
>;

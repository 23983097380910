import React from 'react';

import { SvgProps } from '../enums';

export const FlagGS: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55012"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55012)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#2B409A"
      />
      <path
        d="M11.7518 3.4121C11.7518 3.4121 11.5305 3.12109 11.5305 3.05467"
        stroke="#CDAD56"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
      <path
        d="M11.9305 3.4121C11.9305 3.4121 12.1518 3.12109 12.1518 3.05467"
        stroke="#CDAD56"
        strokeWidth="0.25"
        strokeLinecap="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.159 9.27571C12.1282 9.27571 12.0168 9.21565 12.0168 9.21565C12.0168 9.21565 11.936 9.34347 11.8937 9.35086C11.8514 9.3584 11.6323 9.27209 11.6323 9.27209C11.6323 9.27209 11.4785 9.39221 11.4439 9.38466C11.4093 9.37727 11.0556 9.30212 11.0556 9.30212L10.8633 9.18185C10.8633 9.18185 10.7557 9.35463 10.7518 9.35086C10.748 9.34708 10.4289 9.28703 10.4289 9.28703C10.4289 9.28703 10.525 9.1556 10.525 9.15182C10.525 9.14805 10.4789 9.13296 10.4789 9.13296L10.4097 9.1894L10.1252 9.21565L10.1944 9.02023L10.3059 8.855L11.6707 7.55876L12.3435 8.77231L12.2436 9.13673C12.2436 9.13673 12.1897 9.27571 12.159 9.27571Z"
        fill="#6A4C2D"
      />
      <path
        // eslint-disable-next-line max-len
        d="M10.8552 9.03043C10.9028 9.08791 11.529 9.72805 11.6305 10.0309C11.732 10.3338 10.807 9.94691 10.807 9.94691C10.807 9.94691 11.9718 9.19034 12.5537 9.19034C13.1355 9.19034 14.2791 9.94691 14.2791 9.94691C14.2791 9.94691 13.5117 10.4873 13.5196 10.1466C13.5275 9.80581 13.8798 9.13453 14.3003 9.03043"
        stroke="#FFBB00"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8902 9.05835V8.67789L13.7088 8.73286L13.0693 8.55835L12.7996 8.7382L12.9448 8.92781L13.2996 9.05835H14.8902Z"
        fill="#F7FCFF"
      />
      <g filter="url(#filter0_d_270_55012)">
        <path
          // eslint-disable-next-line max-len
          d="M11.9694 4.93305C11.9759 4.94943 12.119 4.92038 12.1494 5.00607C12.3033 4.77873 12.484 4.99553 12.484 4.99553L12.636 4.81552L12.8077 4.97808L12.9652 4.96582L13.1298 5.3521L11.831 5.32773C11.831 5.32773 11.9629 4.91667 11.9694 4.93305Z"
          fill="#656263"
        />
      </g>
      <path
        // eslint-disable-next-line max-len
        d="M13.3157 6.57696C13.3052 6.45465 13.1968 6.44034 13.1363 6.44443C13.0757 6.44852 13.0215 6.49946 13.0215 6.49946L12.8212 6.3751C12.8212 6.3751 12.9214 5.75724 13.0277 5.71857C13.1288 5.64735 13.1466 5.61654 13.1466 5.60034C13.1466 5.58399 13.1091 5.5178 13.1091 5.5178L12.456 5.44234L11.8383 5.51371C11.8383 5.51371 11.7904 5.6104 11.7967 5.63902C11.8029 5.66747 11.805 5.69813 11.9156 5.78177C12.038 5.87374 12.1221 6.40167 12.1221 6.40167C12.1221 6.40167 11.949 6.48515 11.9386 6.47493C11.9281 6.46487 11.876 6.45465 11.8488 6.45874C11.8218 6.46283 11.732 6.50763 11.732 6.62381C11.732 6.74015 11.8218 6.80744 11.8218 6.80744C11.8218 6.80744 12.4122 7.24015 12.4999 7.3991C12.5855 7.20745 13.1466 6.77474 13.2134 6.74204C13.2406 6.72585 13.3262 6.69928 13.3157 6.57696Z"
        fill="#656263"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.905 6.00077C13.9541 5.96885 14.2809 5.95281 14.3594 6.00077C14.4377 6.04872 14.6404 6.12575 14.6436 6.18644C14.6469 6.24712 14.7807 6.35736 14.7807 6.35736C14.7807 6.35736 15.0113 6.84301 15.0145 7.14314C15.044 7.34752 15.0374 7.89353 14.9558 8.10105C14.9524 8.35983 14.6208 8.54907 14.6208 8.54907C14.6208 8.54907 14.6469 8.59372 14.6436 8.64167C14.6403 8.68962 14.6142 8.73427 14.6142 8.73427L14.3692 8.8396L14.0979 8.79495L13.7906 7.89757L13.8626 6.38075L13.8822 6.30419L13.8495 6.09981C13.8495 6.09981 13.8561 6.03268 13.905 6.00077Z"
        fill="black"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.131 6.79706C12.131 6.79706 12.4616 6.79706 12.4973 6.90192C12.5599 6.76216 12.8458 6.77096 12.8458 6.77096C13.0186 6.76514 13.1914 6.75933 13.364 6.75351C13.367 7.16101 13.3699 7.56851 13.373 7.97601C13.2718 8.45033 12.9203 8.7852 12.533 8.9278C12.0713 8.78221 11.7795 8.40097 11.6842 7.96721C11.6662 7.58015 11.6484 7.19293 11.6305 6.80586C11.7973 6.80288 11.9642 6.80005 12.131 6.79706Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.8088 6.80131L12.5377 8.69953L13.1962 6.75996C12.9919 6.77286 12.5537 6.72852 12.4993 6.91058C12.415 6.7477 11.9539 6.81342 11.8088 6.80131V6.80131Z"
        fill="#006B00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12.6537 6.93898C12.6537 6.93898 12.72 7.11195 12.6537 7.19756C12.5874 7.28318 12.6592 7.35333 12.6992 7.35333C12.7392 7.35333 12.8765 7.48928 12.7651 7.48928C12.6537 7.48928 12.5908 7.44696 12.5908 7.56646C12.5908 7.68595 12.7303 8.14461 12.7303 8.00431C12.7303 7.86402 12.7651 7.70787 12.7651 7.70787C12.7651 7.70787 12.8236 7.75174 12.782 7.91191C12.7404 8.07207 12.5492 8.30444 12.5014 8.31355C12.4536 8.32266 12.3802 8.39718 12.3704 8.25969C12.3606 8.1222 12.3213 8.05784 12.3529 8.03108C12.3846 8.00431 12.4267 7.90866 12.4402 7.98388C12.4538 8.05909 12.3943 8.13097 12.404 8.15806C12.4137 8.18515 12.4191 8.24656 12.4603 8.19513C12.5014 8.1437 12.5256 8.05891 12.5564 8.13751C12.5872 8.21612 12.5878 8.15409 12.5241 8.00431C12.4603 7.85454 12.4061 7.77123 12.4137 7.63974C12.4214 7.50825 12.411 7.459 12.4214 7.41674C12.4317 7.37448 12.3071 7.16865 12.3387 7.10937C12.3704 7.0501 12.3825 6.85875 12.4214 6.8932C12.4603 6.92765 12.4803 6.97389 12.4603 7.0254C12.4402 7.07691 12.4202 7.1281 12.4402 7.16283C12.4603 7.19756 12.529 7.29788 12.5427 7.27265C12.5564 7.24741 12.6027 7.27182 12.6027 7.12896C12.6027 6.98609 12.5511 6.93587 12.5836 6.90196C12.6161 6.86804 12.6537 6.93898 12.6537 6.93898Z"
        fill="#FFD100"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.2004 6.76028L13.2987 6.75792L13.1232 6.97519L13.3338 7.21542L12.9195 7.72542L13.3104 8.18512C13.2675 8.28809 13.2176 8.3818 13.1512 8.46653L12.9266 8.22395L13.3338 7.72778L12.9991 7.35722L13.2004 6.76028Z"
        fill="#1E5AA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.6907 6.80602L11.8873 7.01402L11.6791 7.26792L12.112 7.72778L11.7539 8.19659C11.7969 8.29957 11.8585 8.41386 11.9248 8.49844L12.1378 8.23542L11.7002 7.75969L12.0208 7.36178L11.7961 6.80367L11.6907 6.80602Z"
        fill="#1E5AA6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M14.1927 6.42556C14.1927 6.45747 14.2275 8.3416 14.2657 8.32362C14.3604 8.27881 14.3822 8.3766 14.3822 8.3766C14.3822 8.3766 14.4149 8.68647 14.3332 8.72798C14.1861 8.80454 14.1218 8.82142 14.1218 8.82142L13.9804 8.71839C13.9804 8.71839 13.8365 8.51715 13.8039 8.42769C13.7712 8.3384 13.732 7.93923 13.745 7.85292C13.758 7.76676 13.7678 7.2621 13.7614 7.23663C13.7548 7.21101 13.7287 6.78951 13.745 6.72568C13.7614 6.66185 13.8626 6.38075 13.8594 6.38075C13.8594 6.38075 14.1927 6.39364 14.1927 6.42556Z"
        fill="url(#paint0_linear_270_55012)"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.8659 6.10295C13.9345 6.10295 13.9868 6.16049 14.0032 6.21159C14.0064 6.23391 13.9966 6.24995 13.9998 6.29145C14.0032 6.33296 13.5032 6.30088 13.4999 6.29774C13.4999 6.29774 13.7319 6.07748 13.8659 6.10295Z"
        fill="#FF7000"
      />
      <path
        // eslint-disable-next-line max-len
        d="M14.2045 6.25517C14.2045 6.28278 14.1821 6.30517 14.1545 6.30517C14.1269 6.30517 14.1045 6.28278 14.1045 6.25517C14.1045 6.22756 14.1269 6.20517 14.1545 6.20517C14.1821 6.20517 14.2045 6.22756 14.2045 6.25517Z"
        fill="black"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.9103 5.95439C12.9103 5.95439 13.1706 6.22968 13.339 6.22669C13.3696 6.3075 13.2625 6.38265 13.2197 6.41849C13.1339 6.39161 13.0564 6.42336 12.8574 6.18408C12.8697 6.03143 12.9133 5.96037 12.9103 5.95439Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.9452 4.569C13.9697 4.74555 14.3845 4.9622 14.5038 4.81551C14.4916 4.88139 14.514 5.08679 13.8831 5.0838C14.1221 5.30532 13.6452 5.54452 13.5104 5.4906C13.7584 5.30508 13.4401 5.1944 13.2625 5.31404C13.2961 5.21232 13.5442 4.70075 13.9452 4.569Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M13.5251 5.80832C13.4778 5.61388 13.4549 5.39049 13.5595 5.30508C13.4216 5.21233 13.2716 5.30807 13.2716 5.30807C13.2706 5.31039 13.2678 5.3143 13.2638 5.31984C13.2317 5.36413 13.1246 5.51221 13.2716 5.77817C12.8797 5.67645 13.0437 6.0322 13.0726 6.05909C13.0879 6.00217 13.2961 5.94841 13.3482 6.23863C13.3657 6.29743 13.2602 6.38225 13.2263 6.40947C13.2188 6.4155 13.2148 6.41871 13.2165 6.41817C13.2165 6.41817 13.5481 6.48549 13.591 6.04732C13.6478 6.01354 13.9229 5.83011 13.9145 5.45176C13.6617 5.45696 13.55 5.73545 13.5251 5.80832Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.0438 6.03221C12.0438 6.03221 11.7898 6.2476 11.6458 6.17874C11.5387 6.24162 11.4131 6.13094 11.4131 6.13094C11.4131 6.13094 11.5601 6.66044 12.0868 6.27747C12.0776 6.16081 12.0499 6.04117 12.0438 6.03221Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.6427 6.16978C11.6641 6.0591 11.7529 6.00517 11.8234 6.10988C11.9183 6.12796 12.0132 5.74797 11.6763 5.8107C11.7713 5.31405 11.4866 5.12854 11.4866 5.12854C11.4866 5.12854 11.3856 5.67911 11.4315 5.78083C11.4774 5.88254 11.3641 5.59232 10.9998 5.51152C10.9936 5.92751 11.407 6.12198 11.407 6.12198C11.407 6.12198 11.5233 6.22669 11.6427 6.16978Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.2983 5.56942C11.1911 5.60228 10.9305 5.44517 11.0958 5.24158C10.5539 5.22963 10.2589 4.77177 10.4395 4.85908C10.6201 4.94638 10.7492 4.76035 10.8839 4.85908C10.7185 4.70957 10.9416 4.58992 10.9416 4.58992C10.9416 4.58992 11.4039 4.72465 11.4866 5.12555C11.459 5.20337 11.4468 5.34094 11.4407 5.33496C11.4407 5.33198 11.1544 5.32998 11.2983 5.56942Z"
        fill="#F7FCFF"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.3481 3.4343C12.2796 3.65957 12.0326 3.5741 12.0326 3.5741C12.0326 3.5741 12.0408 3.68684 12.0162 3.74093C12.0365 3.75772 12.1825 3.8842 12.3358 3.91682C12.5563 3.96374 13.0078 3.83538 13.1594 4.02504C13.311 4.21469 13.303 4.36776 13.303 4.36776C13.303 4.36776 13.1057 4.39313 13.1606 4.47135C13.1962 4.52214 13.2943 4.55988 13.303 4.61238C13.3307 4.77947 13.2105 5.08011 13.2537 5.19305C13.1854 5.19992 13.1801 5.27859 13.1801 5.27859C13.1762 5.36792 12.9792 5.31484 12.9792 5.31484C12.9792 5.31484 13.1021 4.96748 13.1103 4.88644C13.1185 4.80517 13.0242 4.66086 12.9505 4.66086C12.8767 4.66086 12.8235 4.91795 12.8235 4.91795C12.8235 4.91795 12.7867 5.11178 12.7989 5.12979C12.8112 5.14779 12.7496 5.06676 12.7496 5.06676C12.7496 5.06676 12.7303 5.14549 12.6986 5.19305C12.6629 5.24655 12.6144 5.26959 12.6144 5.26959C12.6144 5.26959 12.5776 5.1523 12.5899 5.10728C12.6021 5.06226 12.7908 4.88644 12.7744 4.76015C12.7671 4.70441 12.7606 4.60712 12.6986 4.57484C12.6201 4.53398 12.4762 4.56378 12.3892 4.54362C12.3414 4.63527 12.3194 5.13879 12.3521 5.17054C12.3851 5.20206 12.2703 5.26959 12.2703 5.26959L12.1351 5.27409C12.1329 5.20585 12.2148 5.01937 12.2148 4.86156C12.2148 4.70375 12.2251 4.53462 12.2251 4.53462C12.2251 4.53462 12.1342 4.56038 12.0858 4.66086C12.0452 4.74512 12.0452 4.90437 12.049 4.92696C12.0572 4.97648 12.0981 5.11628 12.0858 5.15703C12.0735 5.19755 11.8606 5.25158 11.8606 5.25158L11.8522 5.12078C11.8522 5.12078 11.9424 5.05775 11.9424 4.89995C11.9424 4.74214 11.9506 4.51656 11.9465 4.50306C11.944 4.49494 11.8727 4.1496 11.7788 3.91682C11.7162 3.76157 11.6196 3.69044 11.6147 3.67781C11.6024 3.64625 11.6024 3.61016 11.6024 3.61016C11.6024 3.61016 11.467 3.7184 11.3072 3.48842C11.4536 3.35092 11.6106 3.45233 11.6106 3.45233C11.6106 3.45233 11.6515 3.32606 11.8399 3.33509C12.0285 3.34411 12.0653 3.46136 12.0572 3.44783C12.0572 3.44783 12.1965 3.31253 12.3481 3.4343Z"
        fill="#923F00"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.8139 5.68177L12.4581 6.09367L13.0382 5.71243C13.0382 5.71243 13.1592 5.64718 13.1487 5.60033C13.1384 5.55332 13.0966 5.57172 13.0757 5.57989C13.0549 5.58807 12.4685 5.99384 12.4685 5.99384L11.8446 5.60835C11.8446 5.60835 11.7988 5.5942 11.7904 5.62266C11.7821 5.65127 11.8076 5.66746 11.8139 5.68177Z"
        fill="#B4B6B9"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.9734 8.71367C13.9734 8.71367 13.7337 8.69759 13.7703 8.767C13.7907 8.80552 14.1081 8.80108 14.1081 8.80108L13.9734 8.71367Z"
        fill="#FF7000"
      />
      <path
        // eslint-disable-next-line max-len
        d="M14.0875 8.80673L13.8375 8.81381C13.8375 8.81381 13.7746 8.86962 13.7706 8.88298C13.7667 8.8965 13.8119 8.90028 13.8119 8.90028C13.8119 8.90028 14.0698 8.96584 14.2649 8.92339C14.3634 8.88974 14.3806 8.84934 14.3116 8.82481C14.2427 8.80029 14.0894 8.80768 14.0875 8.80673Z"
        fill="#FF7000"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.1953 6.49489C11.1953 6.49489 11.1628 6.59487 11.1953 6.64487C11.2279 6.69486 11.4325 7.09938 11.4325 7.09938C11.4325 7.09938 11.5721 6.93116 11.6233 6.9266C11.6744 6.9222 11.6511 7.36303 11.6511 7.36303C11.6511 7.36303 11.5674 7.44022 11.5163 7.43566C11.4651 7.4311 11.6418 7.60844 11.6371 7.75842C11.6324 7.90841 11.4046 8.65377 11.3209 8.66729C11.2372 8.68096 11.1649 8.52694 11.1649 8.39381C11.1649 8.26069 11.2511 8.04927 11.2465 7.99016C11.2419 7.93105 10.8305 8.62364 10.8056 8.73178C10.8009 8.75228 10.8279 8.82639 10.8279 8.82639C10.8279 8.82639 10.6667 8.83959 10.7024 9.0446C10.228 8.88094 9.46888 8.78473 9.47353 8.49844C9.47532 8.38869 9.65602 7.68413 9.75888 7.39909C9.92447 6.94016 10.505 6.66832 10.5078 6.61789C10.5125 6.53598 10.545 6.49967 10.5497 6.45423C10.5543 6.4088 10.6 6.1858 10.6465 6.14493C10.6931 6.10405 10.9349 6.00406 10.9953 6.00862C11.0558 6.01318 11.107 6.05405 11.107 6.05405C11.107 6.05405 11.3349 6.05405 11.3488 6.14037C11.3628 6.22683 11.3023 6.25859 11.3023 6.25859C11.3023 6.25859 11.3349 6.38138 11.1953 6.49489Z"
        fill="#8A9396"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.331 9.07276C11.4079 9.1259 11.5853 9.15734 11.5853 9.15734C11.5853 9.15734 11.1494 9.27478 10.7064 9.04682C10.6659 8.86932 10.8014 8.8297 10.835 8.82829C10.9132 8.80785 11.2542 9.01946 11.331 9.07276Z"
        fill="#2B2B2B"
      />
      <mask
        id="mask1_270_55012"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="9"
        height="8"
      >
        <rect y="0.199997" width="9" height="7" fill="white" />
      </mask>
      <g mask="url(#mask1_270_55012)">
        <path
          // eslint-disable-next-line max-len
          d="M-1.00214 6.69991L0.978339 7.56851L9.04494 1.14437L10.0896 -0.146393L7.97176 -0.436705L4.68159 2.33164L2.03334 4.19686L-1.00214 6.69991Z"
          fill="#F7FCFF"
        />
        <path
          d="M-0.731018 7.30847L0.277943 7.81253L9.71445 -0.266327H8.29775L-0.731018 7.30847Z"
          fill="#F50100"
        />
        <path
          // eslint-disable-next-line max-len
          d="M10.0021 6.69991L8.02166 7.56851L-0.0449371 1.14437L-1.08959 -0.146393L1.02824 -0.436705L4.31841 2.33164L6.96666 4.19686L10.0021 6.69991Z"
          fill="#F7FCFF"
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.93457 7.13667L8.92561 7.64073L4.90745 4.18175L3.71615 3.79529L-1.1901 -0.141976H0.226597L5.13009 3.70184L6.43256 4.16525L9.93457 7.13667Z"
          fill="#F50100"
        />
        <mask
          id="path-36-outside-1_270_55012"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="-0.800003"
          width="11"
          height="9"
          fill="black"
        >
          <rect fill="white" x="-1" y="-0.800003" width="11" height="9" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99226 0.199997H3.99226V3.2H0V4.2H3.99226V7.2H4.99226V4.2H9V3.2H4.99226V0.199997Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99226 0.199997H3.99226V3.2H0V4.2H3.99226V7.2H4.99226V4.2H9V3.2H4.99226V0.199997Z"
          fill="#F50100"
        />
        <path
          // eslint-disable-next-line max-len
          d="M3.99226 0.199997V-0.550003H3.24226V0.199997H3.99226ZM4.99226 0.199997H5.74226V-0.550003H4.99226V0.199997ZM3.99226 3.2V3.95H4.74226V3.2H3.99226ZM0 3.2V2.45H-0.75V3.2H0ZM0 4.2H-0.75V4.95H0V4.2ZM3.99226 4.2H4.74226V3.45H3.99226V4.2ZM3.99226 7.2H3.24226V7.95H3.99226V7.2ZM4.99226 7.2V7.95H5.74226V7.2H4.99226ZM4.99226 4.2V3.45H4.24226V4.2H4.99226ZM9 4.2V4.95H9.75V4.2H9ZM9 3.2H9.75V2.45H9V3.2ZM4.99226 3.2H4.24226V3.95H4.99226V3.2ZM3.99226 0.949997H4.99226V-0.550003H3.99226V0.949997ZM4.74226 3.2V0.199997H3.24226V3.2H4.74226ZM0 3.95H3.99226V2.45H0V3.95ZM0.75 4.2V3.2H-0.75V4.2H0.75ZM3.99226 3.45H0V4.95H3.99226V3.45ZM4.74226 7.2V4.2H3.24226V7.2H4.74226ZM4.99226 6.45H3.99226V7.95H4.99226V6.45ZM4.24226 4.2V7.2H5.74226V4.2H4.24226ZM9 3.45H4.99226V4.95H9V3.45ZM8.25 3.2V4.2H9.75V3.2H8.25ZM4.99226 3.95H9V2.45H4.99226V3.95ZM4.24226 0.199997V3.2H5.74226V0.199997H4.24226Z"
          fill="#F7FCFF"
          mask="url(#path-36-outside-1_270_55012)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_270_55012"
        x="11.831"
        y="4.81552"
        width="1.29877"
        height="0.786575"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.25" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_270_55012"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_270_55012"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_270_55012"
        x1="14.1774"
        y1="7.80354"
        x2="14.7495"
        y2="7.79392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5DFFF" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

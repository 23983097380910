import { logger } from '@main/core-ui/src/logger';

// Accepted errors
const allowed = {
  Unauthorized: 'Unauthorized',
  'Gateway Timeout': 'Cannot connect to the server. Try refreshing the page.',
  'Network error: Response not successful:':
    'An unexpected error occurred with our server. We are working on it!',
  'Network error:': 'Cannot connect to the server. Try refreshing the page.',
  'GraphQL error: ': '',
  'Client error: ': '',
  'Validation error: ': '',
  '400 - "Client error: ': '',
};

// TODO: https://transcend.height.app/T-19077 - Update to parse error lookup for validation errors
/**
 * Make an error message readable
 *
 * @param msg - The full message
 * @param notAllowedMessages - Some error messages should be hidden in certain contexts
 * @returns The formatted error message
 */
export function formatErrorMessage(
  msg: string,
  notAllowedMessages: string[] = [],
): string {
  // Check if the error is known
  const isAccepted =
    Object.keys(allowed).filter((start) => msg.startsWith(start)).length > 0;
  const isNotAllowed =
    notAllowedMessages.filter((err: string) => msg.includes(err)).length > 0;
  if (!isAccepted || isNotAllowed) {
    logger.error(`Unexpected Error Message: ${msg}`);
    return 'An unexpected error occurred. Try reloading the page. If the issue persists, reach out to support.';
  }

  // Remove any labels
  let final = msg;
  Object.entries(allowed).forEach(([start, instead]) => {
    if (final.startsWith(start)) {
      if (instead) {
        final = instead;
      } else {
        final = final.replace(start, '');
      }
      logger.warn(`Replacing error "${start}" with "${final}"`);
    }
  });

  return final;
}

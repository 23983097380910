import { AssessmentQuestion } from '@main/datamap-types';

export interface ConditionalLogicHelperOptions {
  /** The question we are comparing against */
  questionToCheck: Pick<AssessmentQuestion, 'type' | 'referenceId'> & {
    /** Subset of the fields we need from `selectedAnswers` to evaluate the logic */
    selectedAnswers: Pick<AssessmentQuestion['selectedAnswers'][0], 'value'>[];
  };
  /** The target values to look for in the selected answers */
  comparisonOperands: string[];
  /** Questions currently evaluated that will be shown in the assessment */
  questionsToKeep: AssessmentQuestion[];
}

/**
 * Type guard to make sure that a line in code is never reached.
 *
 * Used here to make sure that the default case in switch statements is never reached,
 * while allowing fall-through cases, which can't be done with the `switchCase` util.
 *
 * @param value - the value to validate
 */
export const exhaustiveGuard = (value: never): never => {
  throw Error(`${value} is not supported in this context.`);
};

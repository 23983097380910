import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Bold: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M11.138 7.6761C11.5998 7.14516 11.8891 6.4666 11.8891 5.71424C11.8891 4.03356 10.4934 2.66669 8.77794 2.66669L4.11127 2.66693C3.68179 2.66693 3.3335 3.00763 3.3335 3.42881C3.3335 3.84999 3.68179 4.1907 4.11127 4.1907H4.50016V11.8096H4.11127C3.68179 11.8096 3.3335 12.1503 3.3335 12.5715C3.3335 12.9926 3.68179 13.3334 4.11127 13.3334H9.55572C11.2712 13.3334 12.6668 11.9665 12.6668 10.2858C12.6668 9.17368 12.0495 8.20942 11.138 7.6761ZM6.05572 4.1907H8.77794C9.63593 4.1907 10.3335 4.87449 10.3335 5.71448C10.3335 6.55446 9.63593 7.23825 8.77794 7.23825H6.05572V4.1907ZM9.55572 11.8093H6.05572V8.76179H9.55572C10.4137 8.76179 11.1113 9.44534 11.1113 10.2641C11.1113 11.0829 10.4137 11.8093 9.55572 11.8093Z"
      fill={color}
    />
  </svg>
);

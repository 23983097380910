/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DotsVertical: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 6 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.14278 10.8356C3.60302 10.8356 3.97612 10.4625 3.97612 10.0023C3.97612 9.54204 3.60302 9.16895 3.14278 9.16895C2.68254 9.16895 2.30945 9.54204 2.30945 10.0023C2.30945 10.4625 2.68254 10.8356 3.14278 10.8356Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.14278 16.6667C3.60302 16.6667 3.97612 16.2936 3.97612 15.8333C3.97612 15.3731 3.60302 15 3.14278 15C2.68254 15 2.30945 15.3731 2.30945 15.8333C2.30945 16.2936 2.68254 16.6667 3.14278 16.6667Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.14278 5.00114C3.60302 5.00114 3.97612 4.62804 3.97612 4.16781C3.97612 3.70757 3.60302 3.33447 3.14278 3.33447C2.68254 3.33447 2.30945 3.70757 2.30945 4.16781C2.30945 4.62804 2.68254 5.00114 3.14278 5.00114Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

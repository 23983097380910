import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DsrTypeRectification: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 25H11.6667C7.98477 25 5 27.9848 5 31.6667V33.3333"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.3333 35.0001H23.3333"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M23.8215 25.5574L28.8907 20.4882C29.2032 20.1756 29.6272 20 30.0692 20C30.5112 20 30.9352 20.1756 31.2477 20.4882L32.8452 22.0857C33.4961 22.7366 33.4961 23.7918 32.8452 24.4427L27.776 29.5118C27.4634 29.8244 27.0395 30 26.5975 30H24.1667C23.7064 30 23.3333 29.6269 23.3333 29.1667V26.7359C23.3333 26.2938 23.5089 25.8699 23.8215 25.5574Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="18.3333"
      cy="11.6667"
      rx="6.66667"
      ry="6.66667"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

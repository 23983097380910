import { ConditionalLogicHelperOptions } from './types';

export const isShown = ({
  questionToCheck,
  questionsToKeep,
}: Pick<
  ConditionalLogicHelperOptions,
  'questionToCheck' | 'questionsToKeep'
>): boolean =>
  !!questionsToKeep.find(
    ({ referenceId }) => referenceId === questionToCheck.referenceId,
  );

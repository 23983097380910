import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const UserAdd: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7523 4.9971H20.2534"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0029 6.24762V3.74658"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M19.0029 8.99876C16.793 8.99876 15.0012 7.20701 15.0012 4.99709C15.0012 2.78717 16.793 0.995422 19.0029 0.995422C21.2128 0.995422 23.0046 2.78717 23.0046 4.99709C23.0046 7.20701 21.2128 8.99876 19.0029 8.99876"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M11.4997 9.02377C12.7272 9.02377 13.7226 10.0192 13.7226 11.2467C13.7226 12.4742 12.7272 13.4696 11.4997 13.4696C10.2721 13.4696 9.27673 12.4742 9.27673 11.2467C9.27673 10.0192 10.2721 9.02377 11.4997 9.02377"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M15.5014 17.0271C15.3853 16.734 15.2043 16.4719 14.9732 16.2578V16.2578C14.582 15.8956 14.0718 15.6935 13.5386 15.6935C12.6492 15.6935 10.3512 15.6935 9.46186 15.6935C8.92864 15.6935 8.41743 15.8956 8.02627 16.2578V16.2578C7.79517 16.4719 7.6151 16.735 7.49805 17.0271"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M15.0072 4.76199C13.9368 4.27579 12.7523 3.99667 11.4998 3.99667C6.8038 3.99667 2.99622 7.80426 2.99622 12.5002C2.99622 17.1962 6.8038 21.0038 11.4998 21.0038C16.1957 21.0038 20.0033 17.1962 20.0033 12.5002C20.0033 11.2477 19.7242 10.0632 19.238 8.99275"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

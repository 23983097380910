import uniqBy from 'lodash/uniqBy';

import { findAllWithRegex } from '@transcend-io/type-utils';

import { DATABASE_IDENTIFIER_ERROR_MESSAGES } from '../constants';

const COLUMN_NAMES_REGEX = /(SELECT|select)\s+([\s\S]+?)\s+(FROM|from)/i;
const AS_REGEX = /[ ]+(as|AS)[ ]+(.+)/;
const FUNCTION_REGEX = /[\w\d]+\(.*\)/;

/**
 * Parse column name to match expected result
 *
 * @param name - Name of original column
 * @returns Parsed name and update function counter if necessary
 */
function parseColumnName(name: string): {
  /** Parsed name */
  parsedName: string;
  /** Label for parsed name */
  label: string;
  /** Error message if any */
  error?: string;
} {
  // Matches any '.... as test' and use the test as the column name
  const asMatch = name.match(AS_REGEX);
  if (asMatch) {
    return {
      parsedName: asMatch[2],
      label: asMatch[2],
    };
  }

  const functionMatch = name.match(FUNCTION_REGEX);
  if (functionMatch) {
    return {
      parsedName: '',
      label: '',
      error: DATABASE_IDENTIFIER_ERROR_MESSAGES.missingAsClauseError,
    };
  }

  return {
    parsedName: name,
    label: name,
  };
}

const trimColumnName = (name: string): string =>
  name.replaceAll('"', '').replaceAll('`', '').trim();

/**
 * Custom function to split by commas outside of any function parentheses.
 *
 * @param input - The input string to split
 * @returns Array of split values
 */
function splitByCommasOutsideParentheses(input: string): string[] {
  const result: string[] = [];
  let buffer = '';
  let openParens = 0;

  [...input].forEach((char) => {
    if (char === '(') openParens += 1;
    if (char === ')') openParens -= 1;

    if (char === ',' && openParens === 0) {
      // If we encounter a comma and we are not inside parentheses
      result.push(buffer.trim());
      buffer = '';
    } else {
      buffer += char;
    }
  });

  if (buffer) result.push(buffer.trim());
  return result;
}

/**
 * Get column names from a Select SQL query
 *
 * @param query - SQL query to parse
 * @returns Column names from SQL query
 */
export function getColumnNamesFromSql(query: string): {
  /** the option's value */
  value: string;
  /** the option's label */
  label: string;
}[] {
  const globalRegex = new RegExp(COLUMN_NAMES_REGEX, 'igu');
  const matches = findAllWithRegex(
    { value: globalRegex, matches: ['select', 'fields', 'from'] },
    query,
  );

  return uniqBy(
    matches
      .map((match) => {
        const columnNames = splitByCommasOutsideParentheses(match.fields);
        return columnNames.map((name) => {
          const { parsedName, label, error } = parseColumnName(name);
          if (error) {
            throw new Error(error);
          }
          return {
            value: trimColumnName(parsedName),
            label: trimColumnName(label),
          };
        });
      })
      .flat()
      .filter(({ value }) => value !== '*' && !value.includes('.')),
    'value',
  );
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagCA: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55097"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55097)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0H12.5V12H4V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.16447 4.20127L7.98652 3L8 10H7.65742L7.86761 8.2677C7.86761 8.2677 5.56179 8.69124 5.75241 8.4776C5.94304 8.26397 6.05283 7.87204 6.05283 7.87204L4 6.47393C4 6.47393 4.32382 6.46978 4.58726 6.30984C4.8507 6.1499 4.32382 5.20134 4.32382 5.20134L5.36027 5.35527L5.75241 4.92023L6.53439 5.7557H6.8859L6.53439 3.842L7.16447 4.20127ZM8 10V3L8.83553 4.20127L9.46561 3.842L9.1141 5.7557H9.46561L10.2476 4.92023L10.6397 5.35527L11.6762 5.20134C11.6762 5.20134 11.1493 6.1499 11.4127 6.30984C11.6762 6.46978 12 6.47393 12 6.47393L9.94717 7.87204C9.94717 7.87204 10.057 8.26397 10.2476 8.4776C10.4382 8.69124 8.13239 8.2677 8.13239 8.2677L8.34258 10H8Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0H16V12H12V0Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H4V12H0V0Z"
        fill="#E31D1C"
      />
    </g>
  </svg>
);

/**
 * Fields that an identifier can be ordered by
 */
export enum IdentifierOrderField {
  /** The name of the identifier */
  Name = 'name',
  /** When the identifier was created */
  CreatedAt = 'createdAt',
  /** The time the identifier was updated */
  UpdatedAt = 'updatedAt',
}

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const LanguageTranslate: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M6.66611 2.33092C6.66611 1.77841 7.11401 1.33051 7.66652 1.33051H13.669C14.2215 1.33051 14.6694 1.77841 14.6694 2.33092V6.33259C14.6694 6.8851 14.2215 7.33301 13.669 7.33301H13.0256V8.66623C13.0256 8.8011 12.9443 8.9227 12.8197 8.97431C12.6951 9.02593 12.5517 8.9974 12.4563 8.90203L10.8873 7.33301H7.66652C7.11401 7.33301 6.66611 6.8851 6.66611 6.33259V2.33092Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M8.66696 9.33388V12.0445C8.66696 12.597 8.21905 13.0449 7.66654 13.0449H5.09105L3.56483 14.5711C3.46946 14.6665 3.32603 14.695 3.20142 14.6434C3.07682 14.5918 2.99557 14.4702 2.99557 14.3353V13.0449H2.33098C1.77847 13.0449 1.33057 12.597 1.33057 12.0445V7.99999C1.33057 7.44747 1.77847 6.99957 2.33098 6.99957H4.66529"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.52213 5.11771H11.806"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0017 5.65099L11.0413 2.98322H10.2943L9.33389 5.65099"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.99876 9.5373V8.68829"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.99877 9.53735C4.99805 10.2713 4.62704 10.9553 4.01227 11.3561"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.98525 11.3561C5.37047 10.9553 4.99947 10.2713 4.99875 9.53735"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.16591 9.53733H3.8316"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import {
  RequestAction,
  RequestActionObjectResolver,
} from '@transcend-io/privacy-types';

/**
 * The datapoint resolver steps for each request action
 */
export const REQUEST_ACTION_TO_REQUEST_ACTION_DATAPOINT: {
  [action in RequestAction]: RequestActionObjectResolver[];
} = {
  /** No change */
  [RequestAction.Access]: [RequestActionObjectResolver.Access],
  [RequestAction.AutomatedDecisionMakingOptOut]: [
    RequestActionObjectResolver.AutomatedDecisionMakingOptOut,
  ],
  [RequestAction.ContactOptOut]: [RequestActionObjectResolver.ContactOptOut],
  [RequestAction.Rectification]: [RequestActionObjectResolver.Rectification],
  [RequestAction.Restriction]: [RequestActionObjectResolver.Restriction],
  [RequestAction.SaleOptOut]: [RequestActionObjectResolver.SaleOptOut],
  [RequestAction.SaleOptIn]: [RequestActionObjectResolver.SaleOptIn],
  [RequestAction.PlaceOnLegalHold]: [
    RequestActionObjectResolver.PlaceOnLegalHold,
  ],
  [RequestAction.RemoveFromLegalHold]: [
    RequestActionObjectResolver.RemoveFromLegalHold,
  ],
  [RequestAction.ContactOptIn]: [RequestActionObjectResolver.ContactOptIn],
  [RequestAction.CustomOptIn]: [RequestActionObjectResolver.CustomOptIn],
  [RequestAction.CustomOptOut]: [RequestActionObjectResolver.CustomOptOut],
  [RequestAction.UseOfSensitiveInformationOptOut]: [
    RequestActionObjectResolver.UseOfSensitiveInformationOptOut,
  ],
  [RequestAction.UseOfSensitiveInformationOptIn]: [
    RequestActionObjectResolver.UseOfSensitiveInformationOptIn,
  ],
  [RequestAction.AutomatedDecisionMakingOptIn]: [
    RequestActionObjectResolver.AutomatedDecisionMakingOptIn,
  ],
  [RequestAction.TrackingOptIn]: [RequestActionObjectResolver.TrackingOptIn],
  [RequestAction.TrackingOptOut]: [RequestActionObjectResolver.TrackingOptOut],
  [RequestAction.BusinessPurpose]: [
    RequestActionObjectResolver.BusinessPurpose,
  ],
  /** Erasure first requires access */
  [RequestAction.Erasure]: [
    RequestActionObjectResolver.Access,
    RequestActionObjectResolver.Erasure,
  ],
};

/**
 * Go from action to request types supported for that action
 *
 * @param type - Type of request
 * @param skipDownloadableStep - Whether skipDownloadableStep is enabled
 * @returns List of request actions
 */
export function getActionLookupTypeFromAction(
  type: RequestAction,
  skipDownloadableStep: boolean,
): RequestAction[] {
  // Determine the action types to lookup by
  let lookupTypes = REQUEST_ACTION_TO_REQUEST_ACTION_DATAPOINT[type];
  // TODO: https://transcend.height.app/T-2562 - remove special case once access and erasure are not coupled
  // for now, this workaround allows for customers to only queue up data silos specified for erasure.
  // the default behavior is to queue up all access and erasure requests, then skip the erasure portion
  if (type === RequestAction.Erasure && skipDownloadableStep) {
    lookupTypes = [RequestAction.Erasure];
  }
  return lookupTypes;
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagPR: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55089"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55089)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#EF0000"
      />
      <mask
        id="mask1_270_55089"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55089)">
        <path
          d="M0 4H-1V5V7V8H0H16H17V7V5V4H16H0Z"
          fill="#EF0000"
          stroke="#F7FCFF"
          strokeWidth="2"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12L9 6L0 0Z"
        fill="#3D58DB"
      />
      <mask
        id="mask2_270_55089"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="9"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12L9 6L0 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_270_55089)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.34394 7.10841L1.50798 8.07741L2.40181 6.13044L1.26282 5.09038L2.66906 5.03848L3.34394 3.27814L3.85897 5.03848H5.26282L4.32712 6.13044L5.07139 8.07741L3.34394 7.10841Z"
          fill="#F7FCFF"
        />
      </g>
    </g>
  </svg>
);

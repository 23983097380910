import { makeEnum } from '@transcend-io/type-utils';

/**
 * The names of database models
 */
export const MigrationModelName = makeEnum({
  /** A migration that has been applied to the db */
  Migration: 'migration',
  /** Lock to acquire during migrations to ensure only one system is running them at a time */
  MigrationLock: 'migrationLock',
});

/**
 * Overload with type for all database model names
 */
export type MigrationModelName =
  (typeof MigrationModelName)[keyof typeof MigrationModelName];

import { DataReportFilterInput, DataReportHiddenColumnsInput } from '../schema';
import { DataReportExportableTables } from '../schema/enums';

export const createDefaultDataReportFilters = (): DataReportFilterInput => ({
  [DataReportExportableTables.BusinessEntities]: {},
  [DataReportExportableTables.DataSilos]: {},
  [DataReportExportableTables.DataSubCategories]: {},
  [DataReportExportableTables.ProcessingPurposeSubCategories]: {},
  [DataReportExportableTables.SubDataPoints]: {},
  [DataReportExportableTables.Vendors]: {},
});

export const createDefaultDataReportHiddenColumns =
  (): DataReportHiddenColumnsInput => ({
    [DataReportExportableTables.BusinessEntities]: [],
    [DataReportExportableTables.DataSilos]: [],
    [DataReportExportableTables.DataSubCategories]: [],
    [DataReportExportableTables.ProcessingPurposeSubCategories]: [],
    [DataReportExportableTables.SubDataPoints]: [],
    [DataReportExportableTables.Vendors]: [],
  });

import { UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const AssessmentQuestionComment = mkType({
  name: 'AssessmentQuestionComment',
  comment: 'A comment to an assessment question.',
  fields: {
    id: {
      comment: 'The id of the assessment question comment',
      type: 'id',
      modelName: 'assessmentQuestionComment',
    },
    content: {
      comment: 'The content of the assessment question',
      type: 'string',
    },
    author: {
      comment: 'The user who wrote this comment',
      type: UserPreview,
      optional: true,
    },
    updatedAt: {
      comment: 'The date the comment was last updated',
      type: 'Date',
    },
    createdAt: {
      comment: 'The date the comment was created',
      type: 'Date',
    },
  },
});

/** Override type */
export type AssessmentQuestionComment = SchemaToType<
  typeof AssessmentQuestionComment
>;

export const AssessmentQuestionCommentFiltersInput = mkInput({
  name: 'AssessmentQuestionCommentFiltersInput',
  comment: 'Inputs for filtering a list of assessment question comments',
  fields: {
    ids: {
      comment: 'The ids of the assessment question comments',
      type: 'id',
      modelName: 'assessmentQuestionComment',
      optional: true,
      list: true,
    },
    text: {
      type: 'string',
      comment: 'Filter the comments by the text present in its content',
      optional: true,
    },
    authorIds: {
      comment: 'The ids of users wrote the comments',
      type: 'id',
      modelName: 'user',
      optional: true,
      list: true,
    },
    assessmentQuestionIds: {
      comment: 'The IDs of questions that the comments refer to',
      type: 'id',
      modelName: 'assessmentQuestion',
      optional: true,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentQuestionCommentFiltersInput = SchemaToType<
  typeof AssessmentQuestionCommentFiltersInput
>;

export const AssessmentQuestionCommentInput = mkInput({
  name: 'AssessmentQuestionCommentInput',
  comment: 'Input for creating an assessment question comment',
  fields: {
    content: AssessmentQuestionComment.fields.content,
  },
});

/** Override type */
export type AssessmentQuestionCommentInput = SchemaToType<
  typeof AssessmentQuestionCommentInput
>;

export const CreateAssessmentQuestionCommentInput = mkInput({
  name: 'CreateAssessmentQuestionCommentInput',
  comment: 'Input for creating an assessment question comment',
  fields: {
    content: AssessmentQuestionCommentInput.fields.content,
    assessmentQuestionId: {
      comment: 'The ID of the question that the comment refers to',
      type: 'id',
      modelName: 'assessmentQuestion',
    },
  },
});

/** Override type */
export type CreateAssessmentQuestionCommentInput = SchemaToType<
  typeof CreateAssessmentQuestionCommentInput
>;

export const CreateAssessmentQuestionCommentsInput = mkInput({
  name: 'CreateAssessmentQuestionCommentsInput',
  comment: 'Input for bulk creating assessment question comments',
  fields: {
    assessmentQuestionComments: {
      comment: 'The list of assessment question comments to create',
      type: CreateAssessmentQuestionCommentInput,
      list: true,
    },
  },
});

/** Override type */
export type CreateAssessmentQuestionCommentsInput = SchemaToType<
  typeof CreateAssessmentQuestionCommentsInput
>;

export const UpdateAssessmentQuestionCommentInput = mkInput({
  name: 'UpdateAssessmentQuestionCommentInput',
  comment: 'Input for bulk updating assessment question comments',
  fields: {
    id: AssessmentQuestionComment.fields.id,
    content: AssessmentQuestionCommentInput.fields.content,
  },
});

/** Override type */
export type UpdateAssessmentQuestionCommentInput = SchemaToType<
  typeof UpdateAssessmentQuestionCommentInput
>;

export const DeleteAssessmentQuestionCommentsInput = mkInput({
  name: 'DeleteAssessmentQuestionCommentsInput',
  comment: 'Input for deleting multiple assessment question comments',
  fields: {
    ids: {
      comment: 'The ids of the assessment question comments to delete',
      type: 'id',
      modelName: 'assessmentQuestionComment',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentQuestionCommentsInput = SchemaToType<
  typeof DeleteAssessmentQuestionCommentsInput
>;

/**
 * A regex for checking if a string is a UUID.
 */
export const UUID_REGEX =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

/**
 * Determine if a string is a valid UUID
 *
 * ```typescript
 * // Returns true
 * isUuid('3dec1814-37ed-4973-9a62-e87e3f63b430');
 * ```
 *
 * ```typescript
 * // Returns false
 * isUuid('dog');
 * ```
 *
 * @param text - The string to test.
 * @returns True if the text is a uuid.
 */
export function isUuid(text: string): boolean {
  return UUID_REGEX.test(text);
}

/**
 * UUIDv4 compatible with the browser.
 *
 * Note: use the uuid package on the backend, e.g. `import { v4 as uuidv4 } from 'uuid';`
 *
 * @returns A uuid v4 random string. Falls back to a random-ish string if createFrontendUuidv4 is unsupported on the user's browser
 */
export function createFrontendUuidv4(): string {
  return (
    (typeof crypto !== 'undefined' && crypto?.randomUUID?.()) ||
    /** @see https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523 */
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
  );
}

import libSlugify from 'slugify';

/**
 * Create a URL safe version of an input string.
 *
 * NOTE: this function should be used instead of the underlying
 * slugify library directly so that all options are centralized and
 * therefore the same input string will produce the same slugified
 * string across the codebase.
 *
 * @param msg - UTF-8 string to slugify
 * @param options - options for modifying the slug
 * @returns URL safe string
 */
export function slugify(
  msg: string,
  options?:
    | {
        /** replace spaces with replacement character, defaults to `-` */
        replacement?: string;
        /** remove characters that match regex, defaults to `undefined` */
        remove?: RegExp;
        /** convert to lower case, defaults to `false` */
        lower?: boolean;
        /** strip special characters except replacement, defaults to `false` */
        strict?: boolean;
        /** language code of the locale to use */
        locale?: string;
        /** trim leading and trailing replacement chars, defaults to `true` */
        trim?: boolean;
      }
    | string,
): string {
  return libSlugify(msg, options);
}

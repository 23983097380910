import * as t from 'io-ts';

import { HttpMethod } from '@transcend-io/type-utils';

/**
 * A rest endpoint
 */
export interface RestEndpoint<
  TBody extends t.Any,
  TResponseBody extends t.Any,
  THeaders extends t.Any,
> {
  /** Route path */
  route: string;
  /** Codec validation for response body */
  responseBody: TResponseBody;
  /** The http method */
  method: HttpMethod;
  /** The shape of the headers */
  headers: THeaders;
  /** The shape of the input body */
  body: TBody;
}

/**
 * Define a rest endpoint
 *
 * @param endpoint - the endpoint definition
 * @returns The endpoint type enforced
 */
export function mkRestEndpoint<
  TBody extends t.Any,
  TResponseBody extends t.Any,
  THeaders extends t.Any,
>(
  endpoint: RestEndpoint<TBody, TResponseBody, THeaders>,
): RestEndpoint<TBody, TResponseBody, THeaders> {
  return endpoint;
}

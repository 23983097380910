import styled, { css } from 'styled-components';

import { layoutSizes, responsive } from '@main/theme';

// Default styles
export const DEFAULT_STYLES = css`
  ${responsive.beforeTablet} {
    width: ${layoutSizes.mobile.mainWidth()};
    margin-left: auto;
    margin-right: auto;
  }
  ${responsive.justTablet} {
    width: ${layoutSizes.tablet.mainWidth()};
    margin-left: auto;
    margin-right: auto;
  }
  ${responsive.atLeastDesktop} {
    width: ${layoutSizes.desktop.mainWidth()};
    max-width: ${layoutSizes.desktop.mainMaxWidth()};
    margin-left: auto;
    margin-right: auto;
  }
`;

/**
 * ContentWrapper
 *
 * This component is primarily style oriented. It is intended to be used
 * to wrap the content in standard page, such as the Take Control
 * pages, and to provide a standard set of styling for them.
 *
 * If you want to change the component being used, leverage styled-components
 * `withComponent(ELEMENT)` function to keep the styles.
 *
 * If you want to extend the styles being applied, leverage styled-components
 * `extend(...)` function.
 *
 * It's expected that this component will be used in conjunction w/ PageWrapper
 * and PageTitle, like:
 *
 * ```
 *  <PageWrapper>
 *    <PageTitle>{localizedPageTitle}</PageTitle>
 *    <ContentWrapper>
 *      {...}
 *    </ContentWrapper>
 *  </PageWrapper>
 * ```
 */
export const ContentWrapper = styled.section`
  ${DEFAULT_STYLES}
`;

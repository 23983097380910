import {
  MessageValues,
  ReactIntlMessageDescriptor,
} from '@transcend-io/internationalization';

import { isLocalizedMessage } from './typeGuards';
import type { MessageOrString } from './types';

/**
 * Helper function to determine the message from a MessageOrString type
 *
 * @param message - The message to extract the string from
 * @param formatMessage - formatMessage function that will translate the field if its a message descriptor
 * @param values - Message values
 * @returns The string version of the message
 */
export function getMessageAsString(
  message: MessageOrString,
  formatMessage: (
    message: ReactIntlMessageDescriptor,
    values: MessageValues,
  ) => string,
  values: MessageValues = {},
): string {
  if (typeof message === 'string') {
    return message;
  }

  if (isLocalizedMessage(message)) {
    return message.localizedMessage || '';
  }

  return formatMessage(message, values);
}

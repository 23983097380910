import styled from 'styled-components';

import { ColorPalette } from '@main/theme';

import { SHADE_MAP } from './constants';

export const StyledTagSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
`;

export const StyledTagOverflowButton = styled.button<{
  /** Color of the tag */
  color: keyof ColorPalette;
  /** Variant of the tag */
  variant: string;
  /** Use inverted color palette */
  useInvertedColor?: boolean;
}>`
  ${({ theme, color, variant, useInvertedColor = false }) => {
    // Default color filled variant
    let colorValue = theme.colors.white;
    let backgroundValue = theme.colors[color];
    // outline variant
    if (variant === 'outline') {
      colorValue = theme.colors[color];
      backgroundValue = 'unset';
    }
    // inverted color palette filled variant
    else if (useInvertedColor) {
      colorValue = theme.colors.black;
      backgroundValue = theme.colors[SHADE_MAP[color]];
    }
    return `
    color: ${colorValue};
    background: ${backgroundValue};
    border: none;
    border-radius: 0 4px 4px 0;
    font-size: 12px;
    font-weight: 600;
    min-width: 27px;
    border-style: solid;
    border-width: 1px;
    border-left: none;
    padding: 4px 8px;
    border-color: ${variant !== 'outline' && useInvertedColor ? theme.colors[SHADE_MAP[color]] : theme.colors[color]}}
  `;
  }}
`;

export const PopoverListItem = styled.li<{
  /** Whether the item is last in the list */
  last: boolean;
}>`
  overflow: hidden;
  max-width: 250px;
  margin-bottom: ${({ last }) => (last ? '0' : '4px')};
`;

export const PopoverList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const PopoverListContainer = styled.div`
  padding: 4;
  max-width: 300px;
  overflow: 'auto';
`;

export const PopoverButtonContainer = styled.div<{
  /** Whether the popover button should be hidden */
  hide: boolean;
}>`
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
  position: ${({ hide }) => (hide ? 'absolute' : '')};
`;

export const LeftLabelTagContainer = styled(StyledTagSection)<{
  /** The variant of the tag */
  variant: string;
  /** The color of the tag */
  color: keyof ColorPalette;
  /** inverted color palette */
  useInvertedColor?: boolean;
}>`
  ${({ theme, variant, color, useInvertedColor = false }) => {
    // Default color filled variant
    let colorValue = theme.colors.white;
    let background = theme.colors[SHADE_MAP[color]];
    let borderColor = theme.colors[SHADE_MAP[color]];
    // outline variant
    if (variant === 'outline') {
      colorValue = theme.colors[color];
      background = 'unset';
      borderColor = theme.colors[color];
    }
    // inverted color palette filled variant
    else if (useInvertedColor) {
      colorValue = theme.colors.black;
      background = theme.colors[color];
      borderColor = theme.colors[color];
    }
    return `
      color: ${colorValue};
      background: ${background};
      border-color: ${borderColor};
      border-width: 1px;
      border-style: solid;
    `;
  }}
`;
export const PartialRightTagContainer = styled(StyledTagSection)<{
  /** The variant of the tag */
  variant: string;
  /** The color of the tag */
  color: keyof ColorPalette;
  /** Whether the tag is the last in the list */
  last: boolean;
  /** Inverted color palette */
  useInvertedColor?: boolean;
}>`
  ${({ theme, variant, color, last, useInvertedColor = false }) => {
    // Default color filled variant
    let colorValue = theme.colors.white;
    let background = theme.colors[color];
    let borderColor = theme.colors[color];
    let borderRightColor = last
      ? theme.colors[color]
      : theme.colors[SHADE_MAP[color]];

    // outline variant
    if (variant === 'outline') {
      colorValue = theme.colors[color];
      background = 'unset';
    }
    // inverted color palette filled variant
    else if (useInvertedColor) {
      colorValue = theme.colors.black;
      background = theme.colors[SHADE_MAP[color]];
      borderColor = theme.colors[SHADE_MAP[color]];
      borderRightColor = last
        ? theme.colors[SHADE_MAP[color]]
        : theme.colors[color];
    }

    return `
      color: ${colorValue};
      background: ${background};
      border-width: 1px;
      border-style: solid;
      border-color: ${borderColor};
      border-radius: ${last ? '0 4px 4px 0' : ''};
      border-right-color: ${borderRightColor};
      border-left: none;
    `;
  }}
`;

/**
 * Fields that a scope can be ordered by
 */
export enum ScopeOrderField {
  /** The name of the scope */
  Name = 'name',
  /** The time the scope was created */
  CreatedAt = 'createdAt',
  /** The time the scope was updated */
  UpdatedAt = 'updatedAt',
}

import { defineMessages } from '@transcend-io/internationalization';
import { ActionItemCode } from '@transcend-io/privacy-types';

export const messages = defineMessages('access-control-types.src.messages', {
  lastAdministratorError: {
    defaultMessage:
      'You are the last administrator in your organization. Please contact us if you wish to delete your organization.',
  },
  emailPasswordIncorrect: {
    defaultMessage: 'Your email or password was entered incorrectly.',
  },
});

export const actionItemFilterMessages = defineMessages<ActionItemCode>(
  'access-control-types.src.actionItemFilter',
  {
    [ActionItemCode.ActionMissingSilos]: {
      defaultMessage: 'DSR Automation Workflow Missing Integrations',
    },
    [ActionItemCode.ApiKeyStale]: { defaultMessage: 'Api Key Stale' },
    [ActionItemCode.AssessmentFormAssigned]: {
      defaultMessage: 'Assessment Form Assigned',
    },
    [ActionItemCode.AssessmentFormNeedsReview]: {
      defaultMessage: 'Assessment Form Needs Review',
    },
    [ActionItemCode.RequestDataSiloMarkedActionable]: {
      defaultMessage: 'DSR Integration Job Marked Actionable',
    },
    [ActionItemCode.RequestDataSiloFilesNeedReview]: {
      defaultMessage: 'DSR Integration Job Files Need Review',
    },
    [ActionItemCode.CommunicationUnread]: {
      defaultMessage: 'DSR Email Unread',
    },
    [ActionItemCode.DataSiloCommunicationUnread]: {
      defaultMessage: 'Integration Email Unread',
    },
    [ActionItemCode.DataPoint]: {
      defaultMessage: 'Datapoint Missing Privacy Center Labeling',
    },
    [ActionItemCode.DataPointMissingFields]: {
      defaultMessage: 'Datapoint Missing Data Categories',
    },
    [ActionItemCode.DataSiloNeedsReconnect]: {
      defaultMessage: 'Integration Needs Reconnect (Without Owner)',
    },
    [ActionItemCode.DataSiloNeedsReconnectAssigned]: {
      defaultMessage: 'Integration Needs Reconnect (With Owner)',
    },
    [ActionItemCode.DataSiloNotConfigured]: {
      defaultMessage: 'Integration Not Configured (Without Owner)',
    },
    [ActionItemCode.DataSiloNotConfiguredAssigned]: {
      defaultMessage: 'Integration Not Configured (With Owner)',
    },
    [ActionItemCode.DataSiloMissingIdentifiers]: {
      defaultMessage: 'Integration Identifiers Not Configured',
    },
    [ActionItemCode.LookupProcessesWithErrors]: {
      defaultMessage: 'Integration Indexing Errors',
    },
    [ActionItemCode.DataSilosNeedingManualEntry]: {
      defaultMessage: 'Integration Needs Manual Action',
    },
    [ActionItemCode.ProfileDataPointStatus]: {
      defaultMessage: 'Integration Job Error - Datapoint Execution',
    },
    [ActionItemCode.RequestExpiry]: { defaultMessage: 'Request Expiry' },
    [ActionItemCode.RequestDataSiloError]: {
      defaultMessage: 'Integration Job Error - Profile Lookup',
    },
    [ActionItemCode.RequestEnricherError]: {
      defaultMessage: 'DSR Enrichment Error',
    },
    [ActionItemCode.RequestIdentifierNeedsVerification]: {
      defaultMessage: 'DSR Identifier Needs Verification',
    },
    [ActionItemCode.RequestEnricherPersonNeedsManualEntry]: {
      defaultMessage: 'DSR Preflight Check Manual Action Required',
    },
    [ActionItemCode.RequestActionableStatus]: {
      defaultMessage: 'DSR Needs Approval',
    },
    [ActionItemCode.RequestOnHold]: {
      defaultMessage: 'DSR On Hold',
    },
    [ActionItemCode.UserAwaitingNotification]: {
      defaultMessage: 'Transcend User Awaiting Notification',
    },
    [ActionItemCode.UserNeedsConfiguration]: {
      defaultMessage: 'Transcend User Missing Permissions',
    },
    [ActionItemCode.SombraVersionUpgrade]: {
      defaultMessage: 'Sombra Version Upgrade',
    },
    [ActionItemCode.SombraNeedsKeyRotation]: {
      defaultMessage: 'Sombra Needs Key Rotation',
    },
    [ActionItemCode.DataFlowNeedsReview]: {
      defaultMessage: 'Consent Management Data Flow Needs Review',
    },
    [ActionItemCode.DataFlowAssignedForReview]: {
      defaultMessage: 'Consent Management  Data Flow Assigned For Review',
    },
    [ActionItemCode.CookieNeedsReview]: {
      defaultMessage: 'Consent Management Cookie Needs Review',
    },
    [ActionItemCode.CookieAssignedForReview]: {
      defaultMessage: 'Consent Management Cookie Assigned For Review',
    },
    [ActionItemCode.ConsentManagerVersionUpgrade]: {
      defaultMessage: 'Consent Management Version Upgrade',
    },
    [ActionItemCode.PluginsWithErrors]: {
      defaultMessage: 'Data Mapping Plugins With Errors',
    },
    [ActionItemCode.Onboarding]: {
      defaultMessage: 'Action Item Checklist Items',
    },
    [ActionItemCode.RequestAssignedToUser]: {
      defaultMessage: 'DSR Assigned To User',
    },
    [ActionItemCode.DataSiloNeedsTraining]: {
      defaultMessage: 'Data Mapping Integration Needs Training',
    },
    [ActionItemCode.BusinessEntityNeedsDocumentation]: {
      defaultMessage: 'Business Entity Needs Documentation',
    },
    [ActionItemCode.DataPointDatabaseQueryNeedsApproval]: {
      defaultMessage: 'DSR Automation Database Query Needs Approval',
    },
  },
);

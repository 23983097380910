import { RequestAction } from '@transcend-io/privacy-types';
import { apply, Optionalize } from '@transcend-io/type-utils';

import { DefinedMessage, TranslatedMessage } from '@main/internationalization';
import { FormAutoComplete, FormFormat, ONE_DAY } from '@main/utils';

import {
  accessMessages,
  automatedDecisionMakingOptInMessages,
  automatedDecisionMakingOptOutMessages,
  businessPurposeMessages,
  contactOptInMessages,
  contactOptOutMessages,
  customOptInMessages,
  customOptOutMessages,
  erasureMessages,
  placeOnLegalHoldMessages,
  rectificationMessages,
  removeFromLegalHoldMessages,
  restrictionMessages,
  saleOptInMessages,
  saleOptOutMessages,
  trackingOptInMessages,
  trackingOptOutMessages,
  useOfSensitiveInformationOptInMessages,
  useOfSensitiveInformationOptOutMessages,
} from './messages';
import { ActionDelay } from './schema';
import { FormType } from './schema/enums';

/** A form item to display in the Data Subject Request form on the privacy center */
export interface FormItemInput {
  /** The name of the form item value */
  name: string;
  /** The form label message */
  label: Omit<DefinedMessage, 'id'>;
  /** The HTML form type */
  type?: FormType;
  /** When true, input must be required before submission */
  isRequired?: boolean;
  /** The format type of the form item i.e. email */
  format?: FormFormat;
  /** The minimum length required of the input when a number */
  minLength?: number;
  /** When an input, make it a text area with this many lines */
  multiline?: number;
  /** The browser autocomplete parameter for the form item */
  autoComplete?: FormAutoComplete;
  /** Validator for form input */
  validator?: {
    /** Regex pattern */
    pattern: string;
    /** Error message */
    message: string;
  };
}

/**
 * A request DSR action configuration
 */
export interface ActionDefinition {
  /** The required messages for an action */
  messages: {
    /** The default display title on the privacy center Take Control modal */
    subjectTitle: DefinedMessage;
    /** Email template of DSR response */
    report: DefinedMessage;
    /** Description of action in take control modal */
    description: DefinedMessage;
    /** Description of action on confirmation screen of take control modal */
    secondaryDescription: DefinedMessage;
    /** Title to be shown to admins on dashboard */
    adminTitle: DefinedMessage;
    /** secondary report is optional and will go away */
    secondaryReport?: DefinedMessage;
  };
  /** The extra form items to display to display with that action */
  formItems: { [name in string]: FormItemInput };
  /** Allows for a potential delay step before the action is processed */
  delay?: ActionDelay;
  /** The number of days to wait before compiling requests of this type */
  waitingPeriod?: number;
}

/**
 * The step configurations that can be overwritten
 */
export interface StepOverride {
  /** Override the display icon */
  icon?: string;
  /** Override the step title */
  title?: TranslatedMessage;
  /** Override the step description */
  description?: TranslatedMessage;
}

/**
 * The parameters that must be specified when defining an action config
 */
export type ActionDefinitionInput = Optionalize<ActionDefinition, 'formItems'>;

/**
 * The configuration from an action definition passed through GraphQL
 */
export type ActionConfig = Pick<ActionDefinition, 'delay'>;

/**
 * The raw action input definitions
 */
export const ACTION_INPUTS: {
  [key in RequestAction]: ActionDefinitionInput;
} = {
  [RequestAction.Access]: {
    messages: accessMessages,
  },
  [RequestAction.AutomatedDecisionMakingOptOut]: {
    messages: automatedDecisionMakingOptOutMessages,
  },
  [RequestAction.Rectification]: {
    formItems: {
      details: {
        type: FormType.TextArea,
        multiline: 4,
        name: 'details',
        label: rectificationMessages.details,
        isRequired: true,
      },
    },
    messages: rectificationMessages,
  },
  [RequestAction.Restriction]: {
    formItems: {
      details: {
        type: FormType.TextArea,
        name: 'details',
        multiline: 4,
        label: restrictionMessages.details,
        isRequired: true,
      },
    },
    messages: restrictionMessages,
  },
  [RequestAction.Erasure]: {
    messages: erasureMessages,
    delay: {
      time: ONE_DAY * 14,
      previewAction: RequestAction.Access,
    },
  },
  [RequestAction.ContactOptOut]: {
    messages: contactOptOutMessages,
  },
  [RequestAction.ContactOptIn]: {
    messages: contactOptInMessages,
  },
  [RequestAction.SaleOptOut]: {
    messages: saleOptOutMessages,
  },
  [RequestAction.TrackingOptOut]: {
    messages: trackingOptOutMessages,
  },
  [RequestAction.SaleOptIn]: {
    messages: saleOptInMessages,
  },
  [RequestAction.TrackingOptIn]: {
    messages: trackingOptInMessages,
  },
  [RequestAction.CustomOptIn]: {
    messages: customOptInMessages,
  },
  [RequestAction.CustomOptOut]: {
    messages: customOptOutMessages,
  },
  [RequestAction.UseOfSensitiveInformationOptIn]: {
    messages: useOfSensitiveInformationOptInMessages,
  },
  [RequestAction.UseOfSensitiveInformationOptOut]: {
    messages: useOfSensitiveInformationOptOutMessages,
  },
  [RequestAction.AutomatedDecisionMakingOptIn]: {
    messages: automatedDecisionMakingOptInMessages,
  },
  [RequestAction.BusinessPurpose]: {
    messages: businessPurposeMessages,
  },
  [RequestAction.PlaceOnLegalHold]: {
    messages: placeOnLegalHoldMessages,
  },
  [RequestAction.RemoveFromLegalHold]: {
    messages: removeFromLegalHoldMessages,
  },
};

/**
 * The action definitions with all required parameters filled out
 */
export const ACTIONS = apply(
  ACTION_INPUTS,
  ({ formItems = {}, ...input }): ActionDefinition => ({
    formItems,
    ...input,
  }),
);

/**
 * The request action configuration defaults, regardless of turned on or not
 */
export const ACTION_INDEX = apply(ACTIONS, (action, type, all, ind) => ind);

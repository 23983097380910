import { CodePackageType } from '@transcend-io/privacy-types';

import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { SelectableCatalog } from './catalog';
import { SdkOrderField } from './enums';

export const Sdk = mkType({
  name: 'Sdk',
  comment:
    'A software development kit that is registered to a particular contentful catalog',
  fields: {
    id: {
      comment: 'The id of the SDK',
      type: 'id',
      modelName: 'sdk',
    },
    catalog: {
      comment: 'The catalog that the SDK is registered to',
      type: SelectableCatalog,
    },
    name: {
      comment: 'The default name for the SDK',
      type: 'string',
    },
    description: {
      comment: 'The default description for the SDK',
      optional: true,
      type: 'string',
    },
    repositoryUrl: {
      comment: 'The id of the git repository for this SDK',
      optional: true,
      type: 'string',
    },
    documentationLinks: {
      comment: 'List of documentation links for the SDK',
      type: 'string',
      optional: true,
      list: true,
    },
    codePackageType: {
      comment: 'The type of code package the SDK is for',
      type: { CodePackageType },
    },
  },
});

/** Override type */
export type Sdk = SchemaToType<typeof Sdk>;

/**
 * Order for a sdks query
 */
export const SdkOrder = mkOrder(Sdk.name, SdkOrderField);

/** Override type */
export type SdkOrder = SchemaToType<typeof SdkOrder>;

export const SdkFiltersInput = mkInput({
  name: 'SdkFiltersInput',
  comment: 'Input for filtering sdks',
  fields: {
    text: {
      comment: 'Filter sdks by their name, description',
      optional: true,
      type: 'string',
    },
    names: {
      comment: 'Filter sdks by their exact name',
      optional: true,
      list: true,
      type: 'string',
    },
    codePackageTypes: {
      comment: 'Filter by the code package type',
      optional: true,
      list: true,
      type: { CodePackageType },
    },
  },
});

/** Override type */
export type SdkFiltersInput = SchemaToType<typeof SdkFiltersInput>;

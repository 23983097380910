/* eslint-disable max-len */

import React from 'react';

import { SvgProps } from '../enums';

export const Grid: React.FC<SvgProps> = (props) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.33819 2.33806V5.4263H5.42642V2.33806H2.33819ZM0.794067 2.33806C0.794067 1.48558 1.4857 0.793945 2.33819 0.793945H5.42642C6.2789 0.793945 6.97054 1.48558 6.97054 2.33806V5.4263C6.97054 6.27878 6.2789 6.97042 5.42642 6.97042H2.33819C1.4857 6.97042 0.794067 6.27878 0.794067 5.4263V2.33806ZM2.33819 10.5734V13.6616H5.42642V10.5734H2.33819ZM0.794067 10.5734C0.794067 9.72088 1.4857 9.02924 2.33819 9.02924H5.42642C6.2789 9.02924 6.97054 9.72088 6.97054 10.5734V13.6616C6.97054 14.5141 6.2789 15.2057 5.42642 15.2057H2.33819C1.4857 15.2057 0.794067 14.5141 0.794067 13.6616V10.5734ZM13.6617 2.33806H10.5735V5.4263H13.6617V2.33806ZM10.5735 0.793945H13.6617C14.5142 0.793945 15.2058 1.48558 15.2058 2.33806V5.4263C15.2058 6.27878 14.5142 6.97042 13.6617 6.97042H10.5735C9.721 6.97042 9.02936 6.27878 9.02936 5.4263V2.33806C9.02936 1.48558 9.721 0.793945 10.5735 0.793945ZM10.5735 10.5734V13.6616H13.6617V10.5734H10.5735ZM9.02936 10.5734C9.02936 9.72088 9.721 9.02924 10.5735 9.02924H13.6617C14.5142 9.02924 15.2058 9.72088 15.2058 10.5734V13.6616C15.2058 14.5141 14.5142 15.2057 13.6617 15.2057H10.5735C9.721 15.2057 9.02936 14.5141 9.02936 13.6616V10.5734Z"
      fill={props.color}
    />
  </svg>
);

/* eslint-enable max-len */

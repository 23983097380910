import React from 'react';

import { SvgProps } from '../enums';

export const FlagHN: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55015"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55015)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V12H16V0H0Z"
        fill="#F7FCFF"
      />
      <mask
        id="mask1_270_55015"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V12H16V0H0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55015)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V4H16V0H0Z"
          fill="#4564F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8V12H16V8H0Z"
          fill="#4564F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M8.40906 6.56595L7.82128 6.87497L7.93354 6.22046L7.45801 5.70919H8.11517L8.40906 5.06595L8.70296 5.70919H9.36012L8.88459 6.22046L8.99685 6.87497L8.40906 6.56595Z"
          fill="#4564F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.40906 5.56595L4.82128 5.87497L4.93354 5.22046L4.45801 4.70919H5.11517L5.40906 4.06595L5.70296 4.70919H6.36012L5.88459 5.22046L5.99685 5.87497L5.40906 5.56595Z"
          fill="#4564F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5.40906 7.56595L4.82128 7.87497L4.93354 7.22046L4.45801 6.70919H5.11517L5.40906 6.06595L5.70296 6.70919H6.36012L5.88459 7.22046L5.99685 7.87497L5.40906 7.56595Z"
          fill="#4564F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M11.4091 5.56595L10.8213 5.87497L10.9335 5.22046L10.458 4.70919H11.1152L11.4091 4.06595L11.703 4.70919H12.3601L11.8846 5.22046L11.9968 5.87497L11.4091 5.56595Z"
          fill="#4564F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M11.4091 7.56595L10.8213 7.87497L10.9335 7.22046L10.458 6.70919H11.1152L11.4091 6.06595L11.703 6.70919H12.3601L11.8846 7.22046L11.9968 7.87497L11.4091 7.56595Z"
          fill="#4564F9"
        />
      </g>
    </g>
  </svg>
);

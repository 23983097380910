import { QueueStatus } from '@transcend-io/privacy-types';
import { makeEnum } from '@transcend-io/type-utils';

/**
 * Fields that a lookup process can be ordered by
 */
export enum LookupProcessOrderField {
  /** Name of the lookup process. */
  Name = 'name',
  /** The time the lookup process was created */
  CreatedAt = 'createdAt',
  /** The time the lookup process was updated */
  UpdatedAt = 'updatedAt',
  /** Config name of the lookup process. */
  ProcessConfigName = 'processConfigName',
  /** Indexed through prop. */
  IndexedThrough = 'indexedThrough',
}

/**
 * The request enrichers are a standard queue.
 */
export const LookupProcessStatus = makeEnum({
  ...QueueStatus,
  /**
   * When a lookup process must not be run.
   * This is meant to be a manual kill-switch, to turn off offending processes.
   */
  Inactive: 'INACTIVE',
});

/**
 * Overload type
 */
export type LookupProcessStatus =
  (typeof LookupProcessStatus)[keyof typeof LookupProcessStatus];

/**
 * The statuses that indicate a successful completion
 */
export const LookupProcessStatusSuccess = makeEnum({
  /** Successfully resolved */
  Resolved: 'RESOLVED',
  /** Skipped processing because already completed */
  Skipped: 'SKIPPED',
});

/**
 * Overload type
 */
export type LookupProcessStatusSuccess =
  (typeof LookupProcessStatusSuccess)[keyof typeof LookupProcessStatusSuccess];

/**
 * Lookup process algorithms
 */
export const LookupProcessAlgorithm = makeEnum({
  Chronological: 'CHRONOLOGICAL',
  ReverseChronological: 'REVERSE_CHRONOLOGICAL',
});

/**
 * Overload type
 */
export type LookupProcessAlgorithm =
  (typeof LookupProcessAlgorithm)[keyof typeof LookupProcessAlgorithm];

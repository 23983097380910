import React from 'react';

import { SvgProps } from '../enums';

export const FlagSS: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55135"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" rx="-1" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55135)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V12H0V0Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V4H16V0H0Z"
        fill="#272727"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8V12H16V8H0Z"
        fill="#4E8B1D"
      />
      <path
        d="M0 3.5H-0.5V4V8V8.5H0H16H16.5V8V4V3.5H16H0Z"
        fill="#E31D1C"
        stroke="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L10 6L0 12V0Z"
        fill="#3D58DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M3.32396 7.20434L2.3142 8.25435L2.20872 6.76161L0.920984 5.96834L2.26392 5.54695L2.48354 4.06787L3.41328 5.23898L4.7693 4.87564L4.06842 6.26331L4.74999 7.60168L3.32396 7.20434Z"
        fill="#FECA00"
      />
    </g>
  </svg>
);

import { AssessmentFormStatus } from '@transcend-io/privacy-types';

import { mkType, SchemaToType } from '@main/schema-utils';

import {
  SelectedAssessmentAnswer,
  SelectedAssessmentAnswerRaw,
} from './assessmentAnswer';

export const AssessmentEvent = mkType({
  name: 'AssessmentEvent',
  comment: 'An event associated with an assessment form',
  fields: {
    id: {
      comment: 'The id of the assessment event',
      type: 'id',
      modelName: 'assessmentEvent',
    },
    updatedAt: {
      comment: 'The time the event was last updated',
      type: 'Date',
    },
    status: {
      comment: 'The status of the event',
      type: { AssessmentFormStatus },
    },
  },
});

/** Override type */
export type AssessmentEvent = SchemaToType<typeof AssessmentEvent>;

export const AssessmentAnswerSubmissionRaw = mkType({
  name: 'AssessmentAnswerSubmission',
  comment: 'A previous response to an assessment question.',
  fields: {
    id: AssessmentEvent.fields.id,
    updatedAt: AssessmentEvent.fields.updatedAt,
    // AssessmentAnswerSubmission is a field returned on AssessmentQuestion, which would seem to make this
    // unnecessary, but it's needed so the GQL cache knows which AssessmentAnswerSubmissions are unique
    assessmentQuestionId: {
      comment: 'The id of the question related to this event',
      type: 'id',
      modelName: 'assessmentQuestion',
    },
    answers: {
      comment: 'The assessment answers submitted with this event',
      type: SelectedAssessmentAnswerRaw,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentAnswerSubmissionRaw = SchemaToType<
  typeof AssessmentAnswerSubmissionRaw
>;

/** Type of the full, parsed AssessmentAnswerSubmission; raw and full types are helpful when using `buildUseTransformQuery` */
export type AssessmentAnswerSubmission = Omit<
  AssessmentAnswerSubmissionRaw,
  'answers'
> & {
  /** Parsed answers for this submission */
  answers: SelectedAssessmentAnswer[];
};

/**
 * validate a date string
 */
export const DATE_REGEX =
  /^((0?[13578]|10|12)([-/])(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))([-/])((19)([2-9])(\d)|(20)([01])(\d)|([8901])(\d))|(0?[2469]|11)([-/])(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))([-/])((19)([2-9])(\d)|(20)([01])(\d)|([8901])(\d)))$/;

/**
 * validate a social security number
 */
export const SOCIAL_SECURITY_NUMBER_REGEX =
  /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$|^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;

/**
 * validate a domain with an optional port
 */
export const DOMAIN_REGEX = /^\s*((?:[\w.-]+)(?:\.(?:[\w.-]+))+)(:[0-9]*)?\s*$/;

/**
 * validate a domain without a port
 */
export const DOMAIN_WITHOUT_PORT_REGEX =
  /^\s*((?:[\w.-]+)(?:\.(?:[\w.-]+))+)\s*$/;

/**
 * validate an email doesn't have super weird characters
 */
export const EMAIL_EXTRA_INVALID_CHARACTERS = /([/])/g;

import uniq from 'lodash/uniq';

import type { StringKeys } from '@transcend-io/type-utils';

/** A generic node for extending types */
type AnyNodeInterface = TreeNodeInterface<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * Interface for a tree mode
 */
export interface TreeNodeInterface<TSelf extends AnyNodeInterface> {
  /** Id of the node */
  id: string;
  /** Its children */
  children?: TSelf[];
}

/**
 * The type of the tree lookup
 */
export type TreeInstanceLookup<TTreeTab extends TreeNodeInterface<TTreeTab>> = {
  /** Mapping from id to tree instance */
  [key in string]: TTreeTab;
};

/**
 * The type of parent lookups
 */
export type ParentLookup = {
  /** Mapping to ordered parent list */
  [key in string]: string[];
};

/**
 * The type of the path lookup
 */
export type PathLookup = {
  /** The path (list format) to a node */
  [k in string]: (string | number)[];
};

/**
 * The function output object
 */
export interface IndexTreeOutput<TTreeTab extends TreeNodeInterface<TTreeTab>> {
  /** An index of parent paths */
  parentLookup: ParentLookup;
  /** All of the menu keys */
  allMenuKeys: string[];
  /** The tree node lookup instance */
  lookupTreeTab: TreeInstanceLookup<TTreeTab>;
  /** The path to a node through the entire data structure */
  pathLookup: PathLookup;
}

export const DEFAULT_TREE_OUTPUT: IndexTreeOutput<AnyNodeInterface> = {
  parentLookup: {},
  lookupTreeTab: {},
  pathLookup: {},
  allMenuKeys: [],
};

/**
 * Index a tree by an attribute
 *
 * ```ts
 * indexTree([{ id: "parent", children:[{id: "item1"}, {id: "item2"}] }], 'id');
 * ```
 *
 * Returns:
 * ```
 * {
 *     allMenuKeys: ['parent', 'item1', 'item2'],
 *     lookupTreeTab: {parent: ['item1', 'item2']},
 *     parentLookup: { 'item1': ['parent'], 'item2': ['parent']},
 *     pathLookup: {parent: ['prefix', 0], item1: ['prefix', 0, 'children', 0], item2: ['prefix', 0, 'children', 1]}
 *  }
 * ```
 *
 * @param tabs - The list of tree tabs to index
 * @param idKey - The name of the id key, common for each tab
 * @returns Indexed lookups for parent keys, and keys as well as a list
 */
export function indexTree<
  // the type of the tree tab
  TTreeTab extends TreeNodeInterface<TTreeTab> & { [k in TIdName]: string }, // Enforce that the id is a string
  // The type of the id for identifying nodes
  TIdName extends StringKeys<TTreeTab>,
>(
  tabs: TTreeTab[],
  idKey: TIdName = 'id' as TIdName,
): IndexTreeOutput<TTreeTab> {
  // An index from child id to all of its parents
  const parentLookup: ParentLookup = {};
  const lookupTreeTab: TreeInstanceLookup<TTreeTab> = {};
  const pathLookup: PathLookup = {};

  // Add or append the id to the parentLookup
  const addParentId = (childId: string, parentId: string): void => {
    // If the lookup exists already, append the id
    if (!!parentLookup && parentLookup[childId]) {
      if (!parentLookup[childId].includes(parentId)) {
        parentLookup[childId].push(parentId);
      }
    } else {
      // Create a new list of parents
      parentLookup[childId] = [parentId];
    }

    // Give the child all of the parents of its parents
    if (parentLookup[parentId]) {
      parentLookup[childId] = uniq([
        ...parentLookup[childId],
        ...parentLookup[parentId],
      ]);
    }
  };

  // Recurse through to find
  const recurse = (child: TTreeTab, idx: number, parent?: TTreeTab): void => {
    // The tab is at the top level, so can be found by just its index
    const childId = child[idKey];
    if (!parent) {
      pathLookup[childId] = [idx];
    } else {
      // lookup parent path
      const parentPath = pathLookup[parent[idKey]];

      pathLookup[childId] = [...parentPath, 'children', idx];
    }
    if (child.children) {
      child.children.forEach((grandChild, grandChildIndex) => {
        recurse(grandChild, grandChildIndex, child);
      });
    }
  };

  // Construct the path lookup object
  tabs.forEach((tab, tabIdx) => {
    // Initially parent is null
    recurse(tab, tabIdx);
  });

  // Index a menu tab
  const indexTab = (parentTab: TTreeTab, child: TTreeTab): void => {
    const childId = child[idKey];
    lookupTreeTab[childId] = child;
    addParentId(childId, parentTab[idKey]);
    if (child.children) {
      child.children.forEach((innerChild) => indexTab(child, innerChild));
    }
  };

  // Index all tabs other than the top level tabs
  tabs.forEach((tab) => {
    lookupTreeTab[tab[idKey]] = tab;
    (tab.children || []).map((tabChild) => indexTab(tab, tabChild));
  });

  return {
    parentLookup,
    allMenuKeys: uniq(
      Object.values(parentLookup).reduce(
        (acc, val) => [...acc, ...val],
        [] as string[],
      ),
    ),
    lookupTreeTab,
    pathLookup,
  };
}

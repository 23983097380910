import {
  gql,
  QueryHookOptions,
  QueryResult,
  useQuery as useApolloQuery,
} from '@apollo/client';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { Requirize } from '@transcend-io/type-utils';
import { Gql } from '@transcend-io/type-utils';

import {
  AnySchema,
  EndpointParams,
  GqlObject,
  GraphQLResponse,
  mkQueryString,
  ParamsToType,
  QueryEndpoint,
  ResponseToType,
} from '@main/schema-utils';

export const INFINITE_SCROLL_CHUNK = 12;
/**
 * Create a GraphQL query hook from an endpoint definition, to be used for
 * components that require infinite-scrolling pagination.
 *
 * This hook caches the results in local state so as to avoid unnecessary renders,
 * as well as handles fetching the next "chunk" of data that can be appended to the full list of items.
 *
 * If you use this hook, make sure to update the field policy for your endpoint
 * so that merges are handled correctly:
 * frontend-services/admin-dashboard/src/graphql.ts
 *
 * This follows the Apollo recommendation pagination strategy:
 * https://www.apollographql.com/docs/react/pagination/core-api/#paginated-read-functions
 *
 * @param endpoint - The GraphQL query endpoint definition
 * @param operationName - The GraphQL operation name
 * @param responseFields - Return a partial response
 * @param chunkSize - How big is each "chunk" that we will grab when fetchMore is called
 * @returns A function that returns an Apollo query hook. The
 * returned hook is documented here:
 * https://www.apollographql.com/docs/react/api/react/hooks/#options
 */
export function buildUseInfiniteScroll<
  TName extends string,
  TParams extends Requirize<EndpointParams, 'first'>,
  TResult extends GraphQLResponse | AnySchema,
>(
  endpoint: QueryEndpoint<TName, TParams, TResult>,
  operationName?: string,
  responseFields?: GqlObject<TResult> | Gql<ResponseToType<TResult>>,
  chunkSize = INFINITE_SCROLL_CHUNK,
): (
  options?: QueryHookOptions<ResponseToType<TResult>, ParamsToType<TParams>>,
  {
    listName,
    uniqByFields,
  }?: {
    /** Property name of the list of items the query returns. In most cases, this will be the default, "nodes" */
    listName?: keyof ResponseToType<TResult>;
    /** Property name(s) that establish which items in the list are unique. In most cases, this will be the default, "id" */
    uniqByFields?: string[] | string;
  },
) => QueryResult<ResponseToType<TResult>> {
  const gqlTagInput = gql`
    ${mkQueryString(endpoint, operationName, responseFields as any)}
  `;

  return (options, { listName = 'nodes', uniqByFields = 'id' } = {}) => {
    const uniqByComparator = useMemo(
      () =>
        Array.isArray(uniqByFields)
          ? (item: any) =>
              uniqByFields.map((field: string) => item[field]).join(',')
          : uniqByFields,
      [uniqByFields],
    );
    const previousVariables = useRef<object | null>(null);
    // Need to use refs because ReactSelect stores its fetchMore in a ref,
    // causing it to not get up-to-date values from state.
    const cachedNodesRef = useRef([] as any[]);
    const originalDataRef = useRef<ResponseToType<TResult> | undefined>();
    const [cachedNodes, setCachedNodes] = useState<any[]>([]);
    const { data, refetch, fetchMore, ...response } = useApolloQuery(
      gqlTagInput,
      {
        ...options,
        variables: {
          first: chunkSize,
          ...(options?.variables ?? {}),
        } as any,
      },
    );

    // Clear the cached nodes when variables changes (e.g. text search, order)
    useEffect(() => {
      const variables = options?.variables;

      // Check when the variables change. The `variables` object frequently gets recreated, even if it did not
      // actually change, so we can't rely on the dependency array alone to detect changes.
      if (!isEqual(variables, previousVariables.current)) {
        if (previousVariables.current !== null) {
          // Clear the cached nodes
          setCachedNodes([]);
          cachedNodesRef.current = [];
        }
        previousVariables.current = variables as object;
      }
    }, [options]);

    useEffect(() => {
      if (data) {
        const unnestedData = (data as any)?.[endpoint.name] ?? [];
        // set in state so components using this will rerender
        setCachedNodes((prev) =>
          uniqBy([...prev, ...unnestedData[listName]], uniqByComparator),
        );
        // set in ref so that we can use it in the fetchMore callback
        originalDataRef.current = data;
        cachedNodesRef.current = uniqBy(
          [...cachedNodesRef.current, ...unnestedData[listName]],
          uniqByComparator,
        );
      }
    }, [data, listName, uniqByComparator]);

    // Grab the next "chunk" to add to our infinite scroll list
    const fetchMoreCallback = useCallback(
      (options?: any) =>
        fetchMore({
          ...options,
          variables: {
            // Other variables are copied over from the original query by default
            offset: cachedNodesRef.current.length,
          },
        }).then((res) => {
          const unnestedData = (res.data as any)?.[endpoint.name];
          const newCachedNodes = uniqBy(
            [...cachedNodesRef.current, ...unnestedData[listName]],
            uniqByComparator,
          );
          setCachedNodes(newCachedNodes);
          cachedNodesRef.current = newCachedNodes;
          return {
            ...res,
            data: unnestedData,
          };
        }),
      [fetchMore, listName, uniqByComparator],
    );

    return {
      ...response,
      refetch: (options?: Partial<ParamsToType<TParams>>) =>
        refetch(options).then((res: any) => ({
          ...res,
          data: (res.data as any)?.[endpoint.name],
        })),
      fetchMore: fetchMoreCallback,
      data: { ...data, [listName]: cachedNodes } as ResponseToType<TResult>,
    };
  };
}

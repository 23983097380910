import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Approved: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2.33 8.97668L3.12667 10.0193L3.29867 11.3173C3.394 12.0373 3.96 12.604 4.68 12.7L5.98133 12.874L7.02333 13.67C7.6 14.1107 8.39933 14.1107 8.976 13.67L10.0187 12.8733H10.0173L11.316 12.7013C12.036 12.606 12.6027 12.04 12.6987 11.32L12.872 10.0187C12.872 10.0193 13.2747 9.49202 13.6687 8.97668C14.1093 8.40002 14.1087 7.60068 13.6687 7.02402L12.8733 5.98068L12.7013 4.68268C12.606 3.96268 12.04 3.39602 11.32 3.30002L10.018 3.12668L8.976 2.33068C8.39933 1.89002 7.6 1.89002 7.02333 2.33068L5.98067 3.12668H5.982L4.68333 3.29935C3.96333 3.39468 3.39667 3.96068 3.30067 4.68068L3.12667 5.98202C3.12667 5.98135 2.724 6.50868 2.33 7.02402C1.89 7.60002 1.89 8.40002 2.33 8.97668V8.97668Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.86881 7.06799L7.53481 9.40199L6.13281 8.00133"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

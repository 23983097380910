import React from 'react';

import { SvgProps } from '../enums';

export const FlagMQ: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="12" fill="#21428E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0H9V5H16V7H9V12H7V7H0V5H7V0Z"
      fill="#F7FCFF"
    />
    <path
      // eslint-disable-next-line max-len
      d="M1.27775 3.17003C1.26454 3.1679 1.25191 3.16579 1.23999 3.16373C1.25299 3.16373 1.2655 3.16594 1.27775 3.17003C1.4995 3.20575 1.88787 3.24683 1.9248 3.16373C1.97373 3.05367 2.15716 2.54005 2.81753 2.55228C3.47789 2.56451 4.02819 2.43 4.02819 2.00198C4.02819 1.56652 3.83253 1.54951 3.4412 1.65957C3.04988 1.76963 2.40641 1.6913 2.38951 1.43945C2.3783 1.27226 1.63547 1.36808 1.64147 1.2352C1.64374 1.18496 2.31951 1.17824 2.40695 1.07693C2.47145 0.913734 2.64749 0.820464 3.06211 0.828002C3.7347 0.840231 4.61518 1.06035 4.70079 1.65957C4.79862 2.34439 4.79862 2.77241 4.02819 2.94361C3.29989 3.10545 3.06211 3.0659 3.17217 3.16373C3.40519 3.37087 4.70079 2.95583 5.17772 3.16373C5.45819 3.28599 5.55681 4.06867 4.62741 4.0809C3.69801 4.09313 3.03765 4.22765 2.56072 3.86078C2.08379 3.49391 2.36504 3.64065 1.9248 3.56728C1.52057 3.4999 1.41534 3.21601 1.27775 3.17003Z"
      fill="white"
    />
    <path
      // eslint-disable-next-line max-len
      d="M1.27775 10.2478C1.26454 10.2457 1.25191 10.2436 1.23999 10.2415C1.25299 10.2415 1.2655 10.2437 1.27775 10.2478C1.4995 10.2835 1.88787 10.3246 1.9248 10.2415C1.97373 10.1315 2.15716 9.61784 2.81753 9.63007C3.47789 9.6423 4.02819 9.50779 4.02819 9.07977C4.02819 8.64431 3.83253 8.6273 3.4412 8.73736C3.04988 8.84742 2.40641 8.76909 2.38951 8.51724C2.3783 8.35005 1.63547 8.44587 1.64147 8.31299C1.64374 8.26275 2.31951 8.25603 2.40695 8.15472C2.47145 7.99152 2.64749 7.89825 3.06211 7.90579C3.7347 7.91802 4.61518 8.13814 4.70079 8.73736C4.79862 9.42218 4.79862 9.8502 4.02819 10.0214C3.29989 10.1832 3.06211 10.1437 3.17217 10.2415C3.40519 10.4487 4.70079 10.0336 5.17772 10.2415C5.45819 10.3638 5.55681 11.1465 4.62741 11.1587C3.69801 11.1709 3.03765 11.3054 2.56072 10.9386C2.08379 10.5717 2.36504 10.7184 1.9248 10.6451C1.52057 10.5777 1.41534 10.2938 1.27775 10.2478Z"
      fill="white"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.4499 3.17003C10.4367 3.1679 10.4241 3.16579 10.4122 3.16373C10.4252 3.16373 10.4377 3.16594 10.4499 3.17003C10.6717 3.20575 11.06 3.24683 11.097 3.16373C11.1459 3.05367 11.3293 2.54005 11.9897 2.55228C12.6501 2.56451 13.2004 2.43 13.2004 2.00198C13.2004 1.56652 13.0047 1.54951 12.6134 1.65957C12.2221 1.76963 11.5786 1.6913 11.5617 1.43945C11.5505 1.27226 10.8076 1.36808 10.8137 1.2352C10.8159 1.18496 11.4917 1.17824 11.5791 1.07693C11.6436 0.913734 11.8197 0.820464 12.2343 0.828002C12.9069 0.840231 13.7874 1.06035 13.873 1.65957C13.9708 2.34439 13.9708 2.77241 13.2004 2.94361C12.4721 3.10545 12.2343 3.0659 12.3443 3.16373C12.5774 3.37087 13.873 2.95583 14.3499 3.16373C14.6304 3.28599 14.729 4.06867 13.7996 4.0809C12.8702 4.09313 12.2098 4.22765 11.7329 3.86078C11.256 3.49391 11.5372 3.64065 11.097 3.56728C10.6928 3.4999 10.5875 3.21601 10.4499 3.17003Z"
      fill="white"
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.4499 10.2478C10.4367 10.2457 10.4241 10.2436 10.4122 10.2415C10.4252 10.2415 10.4377 10.2437 10.4499 10.2478C10.6717 10.2835 11.06 10.3246 11.097 10.2415C11.1459 10.1315 11.3293 9.61784 11.9897 9.63007C12.6501 9.6423 13.2004 9.50779 13.2004 9.07977C13.2004 8.64431 13.0047 8.6273 12.6134 8.73736C12.2221 8.84742 11.5786 8.76909 11.5617 8.51724C11.5505 8.35005 10.8076 8.44587 10.8137 8.31299C10.8159 8.26275 11.4917 8.25603 11.5791 8.15472C11.6436 7.99152 11.8197 7.89825 12.2343 7.90579C12.9069 7.91802 13.7874 8.13814 13.873 8.73736C13.9708 9.42218 13.9708 9.8502 13.2004 10.0214C12.4721 10.1832 12.2343 10.1437 12.3443 10.2415C12.5774 10.4487 13.873 10.0336 14.3499 10.2415C14.6304 10.3638 14.729 11.1465 13.7996 11.1587C12.8702 11.1709 12.2098 11.3054 11.7329 10.9386C11.256 10.5717 11.5372 10.7184 11.097 10.6451C10.6928 10.5777 10.5875 10.2938 10.4499 10.2478Z"
      fill="white"
    />
  </svg>
);

import { PenumbraFile } from '@transcend-io/penumbra';

/**
 * Detect if a PenumbraFile is JSON
 *
 * @param file - PenumbraFile
 * @returns true if JSON, false otherwise
 */
export function isJSONFile(file: PenumbraFile): boolean {
  return (
    !!(
      file.mimetype &&
      // match application/json, text/json, and */*+json
      /^\s*(?:application\/json|text\/json|\\S*\/\\S*\+json)\s*(?:$|;)/i.test(
        file.mimetype,
      )
    ) || !!(file.path && /\.json$/i.test(file.path))
  );
}

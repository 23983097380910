/* eslint-disable max-lines */
import {
  DataCategoryType,
  IsoCountryCode,
  ProcessingPurpose,
} from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeInput, AttributeValue } from '@main/attribute-types';
import { mkInput, mkInterface, mkType, SchemaToType } from '@main/schema-utils';

import { BusinessEntityPreview } from './businessEntityPreview';
import { DiscoveredByDataSiloPreview } from './dataSiloPreview';
import { DataSubCategoryPreview } from './dataSubCategory';
import { DataSubject } from './dataSubject';
import { Controllership, RetentionType } from './enums';
import { SaaSCategoryBase } from './saaSCategoryBase';
import { VendorPreview } from './vendorPreview';

export const ProcessingPurposeInterface = mkInterface({
  name: 'ProcessingPurposeInterface',
  comment: 'Metadata about a purpose of processing',
  fields: {
    purpose: {
      comment: 'The purpose of processing',
      type: { ProcessingPurpose },
    },
    description: {
      comment: 'A description of this purpose of processing',
      type: 'string',
    },
    slug: {
      comment: 'The slug of the processing purpose for templating',
      type: 'string',
    },
  },
});

/** Override type */
export type ProcessingPurposeInterface = SchemaToType<
  typeof ProcessingPurposeInterface
>;

export const PurposeOfProcessing = mkType({
  name: 'PurposeOfProcessing',
  comment: 'Metadata on a top-level data processing category',
  interfaces: [ProcessingPurposeInterface],
  fields: {
    ...ProcessingPurposeInterface.fields,
    subDataPointCount: {
      comment: 'The number of sub-datapoints related to the processing purpose',
      type: 'int',
    },
    vendors: {
      comment: 'The number of vendors related to the processing purpose',
      type: VendorPreview,
      list: true,
    },
    businessEntities: {
      comment: 'The business entities that the processing purpose belongs to',
      type: BusinessEntityPreview,
      list: true,
    },
  },
});

/** Override type */
export type PurposeOfProcessing = SchemaToType<typeof PurposeOfProcessing>;

export const ProcessingPurposeFiltersInput = mkInput({
  name: 'ProcessingPurposeFiltersInput',
  comment: 'Input for filtering the list of processing purposes',
  fields: {
    text: {
      comment: 'Filter processing purposes by text',
      type: 'string',
      optional: true,
    },
    teamIds: {
      comment: 'Filter purposes by ids of the teams assigned as owners',
      modelName: 'team',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerIds: {
      comment: 'Filter purposes by the ids of the users assigned as owners',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    attributeValueIds: {
      comment: 'Filter by the attribute values used to label the purposes',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type ProcessingPurposeFiltersInput = SchemaToType<
  typeof ProcessingPurposeFiltersInput
>;

export const ProcessingPurposeSubCategoryInterface = mkInterface({
  name: 'ProcessingPurposeSubCategoryInterface',
  comment: 'Metadata about a subcategory of a purpose of processing',
  // interfaces: [ProcessingPurposeInterface],
  fields: {
    ...ProcessingPurposeInterface.fields,
    id: {
      comment: 'The ID of the subpurpose',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
    },
    name: {
      comment: 'The purpose of processing subcategory',
      type: 'string',
    },
    isDefault: {
      comment: 'Whether this is a default processing subcategory',
      type: 'boolean',
    },
  },
});

/** Override type */
export type ProcessingPurposeSubCategoryInterface = SchemaToType<
  typeof ProcessingPurposeSubCategoryInterface
>;

export const PurposeSubCategoryPreview = mkType({
  name: 'PurposeSubCategoryPreview',
  comment: 'Details about a subcategory of a purpose of processing',
  interfaces: [ProcessingPurposeSubCategoryInterface],
  fields: {
    ...ProcessingPurposeInterface.fields,
    ...ProcessingPurposeSubCategoryInterface.fields,
  },
});

/** Override type */
export type PurposeSubCategoryPreview = SchemaToType<
  typeof PurposeSubCategoryPreview
>;

export const PurposeSubCategory = mkType({
  name: 'PurposeSubCategory',
  comment: 'Details about a subcategory of a purpose of processing',
  interfaces: [
    ProcessingPurposeInterface,
    ProcessingPurposeSubCategoryInterface,
  ],
  fields: {
    ...PurposeSubCategoryPreview.fields,
    subDataPointCount: PurposeOfProcessing.fields.subDataPointCount,
    vendors: PurposeOfProcessing.fields.vendors,
    businessEntities: PurposeOfProcessing.fields.businessEntities,
    attributeValues: {
      comment: 'The attribute values used to label this processing purpose',
      type: AttributeValue,
      list: true,
    },
    owners: {
      comment:
        'The list of individual users who are responsible for managing this processing purpose subcategory',
      list: true,
      type: UserPreview,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this processing purpose subcategory',
      list: true,
      type: TeamPreview,
    },
    vendorHeadquarterLocations: {
      comment:
        // eslint-disable-next-line max-len
        'The headquarter regions assigned to vendors whose data silos or their subdatapoints have this processing purpose subcategory',
      list: true,
      type: 'string',
    },
    dataSiloRegions: {
      comment:
        'The regions assigned to data silos that have or whose subdatapoints have this processing purpose subcategory',
      list: true,
      type: 'string',
    },
    dataSiloOwners: {
      comment:
        // eslint-disable-next-line max-len
        'The users assigned as owners to data silos that have or whose subdatapoints have this processing purpose subcategory',
      list: true,
      type: UserPreview,
    },
    vendorOwners: {
      comment:
        // eslint-disable-next-line max-len
        'The users assigned as owners to vendors whose data silos or their subdatapoints have this processing purpose subcategory',
      list: true,
      type: UserPreview,
    },
    dataSubCategories: {
      comment:
        'The data subcategories assigned to data silos and subdatapoints with this processing purpose subcategory',
      list: true,
      type: DataSubCategoryPreview,
    },
    superCategories: {
      comment:
        'The super categories associated data silos and subdatapoints that have this processing purpose subcategory',
      list: true,
      type: AttributeValue,
    },
    subDataPointRetentionSchedules: {
      comment:
        // eslint-disable-next-line max-len
        'The type of retention schedule for subdatapoints with this processing purpose subcategory, or the number of days data is retained for, if type is stated period',
      list: true,
      type: 'string',
    },
    catalogRecipients: {
      comment:
        'The recipient categories for data silos that have or whose subdatapoints have this processing purpose subcategory',
      type: SaaSCategoryBase,
      list: true,
    },
    dataSilos: {
      comment:
        'The data silos that have or whose subdatapoints have this processing purpose',
      type: DiscoveredByDataSiloPreview,
      list: true,
    },
    dataSiloDataSubjects: {
      comment:
        'The data subjects for data silos that have or whose subdatapoints have this processing purpose subcategory',
      type: DataSubject,
      list: true,
    },
    controllerships: {
      comment:
        'The controllerships assigned to data silos and subdatapoints with this processing purpose subcategory',
      type: { Controllership },
      list: true,
    },
  },
});

/** Override type */
export type PurposeSubCategory = SchemaToType<typeof PurposeSubCategory>;

export const PurposeSubCategoryInput = mkInput({
  name: 'PurposeSubCategoryInput',
  comment: 'Details about a subcategory of a purpose of processing',
  fields: {
    name: PurposeSubCategory.fields.name,
    purpose: PurposeSubCategory.fields.purpose,
  },
});

/** Override type */
export type PurposeSubCategoryInput = SchemaToType<
  typeof PurposeSubCategoryInput
>;

export const ProcessingPurposeCategoryFiltersInput = mkInput({
  name: 'ProcessingPurposeCategoryFiltersInput',
  comment:
    'Input for filtering the list of purpose of processing sub categories',
  fields: {
    ids: {
      comment: 'Filter by list of processingPurposeSubCategory ids',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      optional: true,
      list: true,
    },
    text: {
      comment: 'Filter by text',
      type: 'string',
      optional: true,
    },
    teamIds: {
      comment: 'Filter purposes by ids of the teams assigned as owners',
      modelName: 'team',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerIds: {
      comment: 'Filter purposes by the ids of the users assigned as owners',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    attributeValueIds: {
      comment: 'Filter by the attribute values used to label the purposes',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    vendorOwnerIds: {
      comment:
        'Filter by the ids of the owners of the vendors associated to this purpose',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    dataSiloOwnerIds: {
      comment:
        'Filter by the ids of the owners of the silos associated to this purpose',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    dataCategories: {
      comment:
        'Filter by the categories of personal data assigned to sub-datapoints with this processing purpose',
      type: { DataCategoryType },
      list: true,
      optional: true,
    },
    dataSubCategoryIds: {
      comment:
        'Filter by the ids of the subcategories assigned to subdatapoints with this processing purpose',
      modelName: 'dataSubCategory',
      type: 'id',
      optional: true,
      list: true,
    },
    vendorHeadquarterCountry: {
      comment:
        // eslint-disable-next-line max-len
        'Filter by the ISO country assigned to vendors whose data silos have subdatapoints with this processing purpose subcategory',
      type: { IsoCountryCode },
      list: true,
      optional: true,
    },
    vendorHeadquarterSubDivision: {
      comment:
        // eslint-disable-next-line max-len
        'Filter by the ISO country sub-division assigned to vendors whose data silos have subdatapoints with this processing purpose subcategory',
      type: 'string',
      list: true,
      optional: true,
    },
    dataSiloCountry: {
      comment:
        'Filter by the countries assigned to data silos that have subdatapoints with this processing purpose subcategory',
      list: true,
      optional: true,
      type: { IsoCountryCode },
    },
    dataSiloCountrySubDivision: {
      comment:
        // eslint-disable-next-line max-len
        'Filter by the country sub-divisions assigned to data silos that have subdatapoints with this processing purpose subcategory',
      type: 'string',
      list: true,
      optional: true,
    },
    saaSCategoryIds: {
      comment:
        // eslint-disable-next-line max-len
        'Filter by IDs of recipient categories associated with the catalogs of silos whose subdatapoints have this processing purpose subcategory',
      optional: true,
      type: 'id',
      modelName: 'saaSCategory',
      list: true,
    },
    retentionTypes: {
      comment:
        'Filter by the type of retention schedule for subdatapoints with this processing purpose subcategory',
      optional: true,
      type: { RetentionType },
      list: true,
    },
    dataSiloIds: {
      comment:
        'Filter by data silos whose subdatapoints have this processing purpose subcategory',
      optional: true,
      type: 'id',
      modelName: 'dataSilo',
      list: true,
    },
    controllerships: {
      comment:
        'Filter by controllerships set on associated data silos or subdatapoints',
      type: { Controllership },
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type ProcessingPurposeCategoryFiltersInput = SchemaToType<
  typeof ProcessingPurposeCategoryFiltersInput
>;

export const CreateProcessingPurposeCategoryInput = mkInput({
  name: 'CreateProcessingPurposeCategoryInput',
  comment: 'Input for creating a new Purpose of Processing subcategory',
  fields: {
    name: {
      comment: 'The name of the subcategory',
      type: 'string',
    },
    purpose: {
      comment: 'The parent purpose of processing the subcategory is nested in',
      type: { ProcessingPurpose },
    },
    description: {
      comment: 'Description of the Purpose of Processing subcategory',
      type: 'string',
      optional: true,
    },
    ownerIds: {
      comment:
        'The unique ids of the users to assign as owners of this processing purpose',
      modelName: 'user',
      type: 'id',
      optional: true,
      list: true,
    },
    ownerEmails: {
      comment:
        'The email addresses of the users to assign as owners of this processing purpose',
      type: 'string',
      optional: true,
      list: true,
    },
    teamIds: {
      comment:
        'The ids of the teams that should be responsible for this processing purpose',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    teamNames: {
      comment:
        'The names of the teams that should be responsible for this processing purpose',
      type: 'string',
      list: true,
      optional: true,
    },
    attributes: {
      comment: 'The attribute values used to label this vendor',
      type: AttributeInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateProcessingPurposeCategoryInput = SchemaToType<
  typeof CreateProcessingPurposeCategoryInput
>;

export const DeletePurposeSubCategoriesInput = mkInput({
  name: 'DeletePurposeSubCategoriesInput',
  comment:
    "Input for deleting Processing Purpose Subcategories from an organization's Data Inventory",
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      list: true,
    },
  },
});

/** Override type */
export type DeletePurposeSubCategoriesInput = SchemaToType<
  typeof DeletePurposeSubCategoriesInput
>;

export const UpdateProcessingPurposeSubCategoryInput = mkInput({
  name: 'UpdateProcessingPurposeSubCategoryInput',
  comment: 'Input for updating a purpose of processing subcategory',
  fields: {
    id: {
      comment: 'The ID of the subcategory to update',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
    },
    description: {
      comment: 'The text to use as the new description of the subcategory',
      type: 'string',
      optional: true,
    },
    ownerIds: CreateProcessingPurposeCategoryInput.fields.ownerIds,
    ownerEmails: CreateProcessingPurposeCategoryInput.fields.ownerEmails,
    teamIds: CreateProcessingPurposeCategoryInput.fields.teamIds,
    teamNames: CreateProcessingPurposeCategoryInput.fields.teamNames,
    attributes: CreateProcessingPurposeCategoryInput.fields.attributes,
  },
});

/** Override type */
export type UpdateProcessingPurposeSubCategoryInput = SchemaToType<
  typeof UpdateProcessingPurposeSubCategoryInput
>;

export const UpdateProcessingPurposeSubCategoriesInput = mkInput({
  name: 'UpdateProcessingPurposeSubCategoriesInput',
  comment:
    'Input for bulk updating one or more  purpose of processing  subcategories',
  fields: {
    processingPurposeSubCategories: {
      comment:
        'List of purpose of processing subcategories with the properties that should be updated for each',
      type: UpdateProcessingPurposeSubCategoryInput,
      list: true,
    },
  },
});

/** Override type */
export type UpdateProcessingPurposeSubCategoriesInput = SchemaToType<
  typeof UpdateProcessingPurposeSubCategoriesInput
>;
/* eslint-enable max-lines */

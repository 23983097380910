import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const LightningBox: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.99634"
      y="3.99625"
      width="18.0075"
      height="18.0075"
      rx="5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.2749 8.29804L9.08658 13.7153C8.9726 13.9056 8.96873 14.1422 9.07642 14.3361C9.18411 14.5301 9.38702 14.6519 9.6088 14.6557H12.594V17.3798C12.5877 17.6566 12.7694 17.9027 13.0358 17.978C13.3022 18.0534 13.5859 17.939 13.7255 17.7L16.9138 12.2847C17.0278 12.0944 17.0317 11.8578 16.924 11.6639C16.8163 11.4699 16.6134 11.3482 16.3916 11.3443H13.4064V8.62018C13.4137 8.343 13.2323 8.09608 12.9656 8.02015C12.6989 7.94423 12.4147 8.05859 12.2749 8.29804Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const CopyItemCheckmark: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="9"
      width="12"
      height="12"
      rx="2"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9V5C9 3.89543 9.89543 3 11 3H19C20.1046 3 21 3.89543 21 5V13C21 14.1046 20.1046 15 19 15H15"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.46561 14.8L8.66668 16L10.6656 14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Power: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.657 6.34277C20.781 9.46677 20.781 14.5328 17.657 17.6568C14.533 20.7808 9.467 20.7808 6.343 17.6568C3.219 14.5328 3.219 9.46677 6.343 6.34277"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4V12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import React from 'react';

import { SvgProps } from '../enums';

export const FlagMM: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55056"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55056)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8H16V12H0V8Z"
        fill="#E31D1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4H16V8H0V4Z"
        fill="#5EAA22"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V4H0V0Z"
        fill="#FFD018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M8.03103 7.98775L5.45559 9.62522L6.31944 6.7587L4.48248 4.88587L7.01506 4.83054L8.13554 2.00063L9.15657 4.86792L11.6831 4.91202L9.78448 6.81918L10.6711 9.54618L8.03103 7.98775Z"
        fill="#F7FCFF"
      />
    </g>
  </svg>
);

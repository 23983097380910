import {
  IsoCountryCode,
  IsoCountrySubdivisionCode,
} from '@transcend-io/privacy-types';
import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '../Icon';
import {
  DarkColorOption,
  DoubleTag,
  LightColorOption,
  SingleTag,
} from '../Tag';
import { REGION_COUNTRY_ICON_TYPES } from './constants';
import { iso31662Messages, isoCountryCodeMessages } from './isoMessages';

/** Props for Region Title component */
interface RegionTitleProps {
  /** The parent country */
  country: IsoCountryCode;
  /** The sub division of this country */
  countrySubDivision?: IsoCountrySubdivisionCode;
  /** The color for the tag */
  color?: keyof LightColorOption | keyof DarkColorOption;
  /** Additional CSS styles */
  style?: CSSProperties;
}

export const RegionTitle: React.FC<RegionTitleProps> = ({
  country,
  countrySubDivision,
  color,
  style,
}) => {
  const { formatMessage } = useIntl();

  const subDivisionCountry = (
    countrySubDivision ? countrySubDivision.split('-')[0] : ''
  ) as IsoCountryCode;
  const countryText =
    country in isoCountryCodeMessages
      ? formatMessage(isoCountryCodeMessages[country])
      : subDivisionCountry in isoCountryCodeMessages
        ? formatMessage(isoCountryCodeMessages[subDivisionCountry])
        : '';
  const subDivisionText =
    countrySubDivision && countrySubDivision in iso31662Messages
      ? formatMessage(iso31662Messages[countrySubDivision])
      : '';

  const countryToUser = country || subDivisionCountry;

  const icon =
    countryToUser in REGION_COUNTRY_ICON_TYPES ? (
      <Icon type={REGION_COUNTRY_ICON_TYPES[countryToUser]} size={16} />
    ) : undefined;

  return countrySubDivision ? (
    <DoubleTag
      variant="filled"
      leftIcon={icon}
      leftLabel={countryText}
      rightLabel={subDivisionText}
      color={color ?? 'transcendNavy2'}
      style={style}
    />
  ) : country ? (
    <SingleTag
      leftIcon={icon}
      label={countryText}
      color={color ?? 'transcendNavy2'}
      style={style}
    />
  ) : (
    <></>
  );
};

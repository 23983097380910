/**
 * Data collection static defaults
 */
export enum DataCollectionStaticType {
  /** App usage */
  AppUsage = 'APP_USAGE',
  /** Call history */
  CallHistory = 'CALL_HISTORY',
  /** Contact info */
  ContactInfo = 'CONTACT_INFO',
  /** Connection information for the current browsing session, e.g. device IDs, MAC addresses, IP addresses, etc. */
  Connection = 'CONNECTION',
  /** Demographic */
  Demographic = 'DEMOGRAPHIC',
  /** Device information */
  DeviceInformation = 'DEVICE_INFORMATION',
  /** Financial */
  Financial = 'FINANCIAL',
  /** Interests */
  Interests = 'INTERESTS',
  /** IP address */
  IpAddress = 'IP_ADDRESS',
  /** Location */
  Location = 'LOCATION',
  /** Health */
  Health = 'HEALTH',
  /** Messages */
  Messages = 'MESSAGES',
  /** Online activity */
  OnlineActivity = 'ONLINE_ACTIVITY',
  /** Profile picture */
  ProfilePicture = 'PROFILE_PICTURE',
  /** Personal Identifier */
  PersonalIdentifier = 'PERSONAL_IDENTIFIER',
  /** Social Media */
  SocialMedia = 'SOCIAL_MEDIA',
  /** Search history */
  SearchHistory = 'SEARCH_HISTORY',
  /** Survey */
  Survey = 'SURVEY',
  /** Sites */
  Sites = 'SITES',
  /** Store visits */
  StoreVisits = 'STORE_VISITS',
  /** Tracking */
  Tracking = 'TRACKING',
  /** User Profile */
  UserProfile = 'USER_PROFILE',
  /** Special static type */
  Unassigned = 'UNASSIGNED',
}

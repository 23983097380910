import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Strikethrough: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9.35339 9.52123C9.73456 9.81095 9.84767 10.1523 9.76533 10.6689C9.69823 11.0877 9.52449 11.3674 9.21814 11.5495C8.43366 12.0197 6.81645 11.8343 5.10095 11.081C4.73926 10.9219 4.32555 11.1085 4.17746 11.4984C4.02938 11.8882 4.20376 12.3328 4.56549 12.4913C5.26829 12.7991 6.68363 13.3334 8.05404 13.3334C8.70969 13.3334 9.35582 13.2106 9.90721 12.882C10.5892 12.4751 11.0237 11.7997 11.1627 10.9272C11.2512 10.3733 11.2107 9.91356 11.0855 9.52147H9.35339V9.52123ZM12.8021 7.61672L8.89518 7.61598C8.54434 7.48236 8.19061 7.36982 7.86146 7.26793C5.96954 6.68182 5.40664 6.33829 5.56602 5.33175C5.62135 4.98585 5.79091 4.71374 6.06893 4.52162C6.52204 4.20857 7.48693 3.95408 9.50878 4.52981C9.88707 4.63767 10.2744 4.39521 10.3749 3.98845C10.4752 3.5816 10.2497 3.16498 9.87135 3.05714C7.85017 2.4815 6.31419 2.54245 5.30482 3.23936C4.69609 3.66057 4.29323 4.31239 4.17126 5.07419C3.97581 6.29784 4.38841 7.07869 5.03698 7.61672H2.53125C2.23796 7.61672 2 7.87264 2 8.16664C2 8.48208 2.23796 8.71657 2.53125 8.71657H12.8021C13.0954 8.71657 13.3333 8.46065 13.3333 8.16664C13.3333 7.87383 13.0965 7.61672 12.8021 7.61672Z"
      fill={color}
    />
  </svg>
);

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const ViewFile: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8356 5.99923V3.33145C13.8356 2.59476 13.2384 1.99756 12.5017 1.99756H3.83139C3.0947 1.99756 2.4975 2.59476 2.4975 3.33145V12.6687C2.4975 13.4054 3.0947 14.0026 3.83139 14.0026H7.16611"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16528 4.66526H11.1678"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16528 7.33311H8.50001"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16528 10.0008H7.16612"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5025 14.0025L13.4094 12.9094"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6506 8.66699C13.0223 8.66699 14.1343 9.77898 14.1343 11.1507C14.1343 12.5224 13.0223 13.6344 11.6506 13.6344C10.2789 13.6344 9.16693 12.5224 9.16693 11.1507C9.16703 9.77902 10.279 8.66709 11.6506 8.66699"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Comment: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 5.75H8"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M1.25 3.5C1.25 2.25736 2.25736 1.25 3.5 1.25H12.5C13.7426 1.25 14.75 2.25736 14.75 3.5V14.3744C14.75 14.5107 14.676 14.6363 14.5568 14.7024C14.4376 14.7685 14.2919 14.7647 14.1763 14.6924L11.8684 13.25H3.5C2.25736 13.25 1.25 12.2426 1.25 11V3.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 5.75H10.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 8.75H8"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 8.75H5.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const MovingMail: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M15.0215 15.0223C21.8883 8.15545 32.2154 6.10125 41.1873 9.81754C50.1593 13.5338 56.0091 22.2888 56.0091 31.9999C56.0091 41.711 50.1593 50.4659 41.1873 54.1822C32.2154 57.8985 21.8883 55.8443 15.0215 48.9775"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M41.334 22.6628H26.7999C25.0328 22.6628 23.4748 23.822 22.967 25.5146L20.1659 34.8518C19.8024 36.0634 20.0332 37.3756 20.7883 38.3904C21.5434 39.4053 22.7338 40.0033 23.9988 40.0033H38.5328C40.3 40.0033 41.858 38.8441 42.3657 37.1515L45.1669 27.8143C45.5304 26.6027 45.2996 25.2906 44.5445 24.2757C43.7894 23.2609 42.5989 22.6628 41.334 22.6628V22.6628Z"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.0688 29.9992L31.7332 32.6669L29.0654 29.9992"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9926 23.9965L7.98926 23.9968"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3248 40.0033H7.98926"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3256 31.9999L5.32227 32.0001"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

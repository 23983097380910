import { AssessmentQuestionType } from '@transcend-io/privacy-types';

import { ConditionalLogicHelperOptions, exhaustiveGuard } from './types';

export const isOneOf = ({
  questionToCheck,
  comparisonOperands,
}: Pick<
  ConditionalLogicHelperOptions,
  'questionToCheck' | 'comparisonOperands'
>): boolean => {
  const { type, selectedAnswers } = questionToCheck;
  switch (type) {
    case AssessmentQuestionType.LongAnswer:
    case AssessmentQuestionType.ShortAnswer:
    case AssessmentQuestionType.SingleSelect:
      // Only one answer, which must match one of the operands
      if (comparisonOperands.includes(selectedAnswers[0].value)) {
        return true;
      }
      break;
    case AssessmentQuestionType.MultiSelect:
      // At least one of the answers must match at least one of the operands
      if (
        comparisonOperands.some((operand) =>
          selectedAnswers.map(({ value }) => value).includes(operand),
        )
      ) {
        return true;
      }
      break;
    case AssessmentQuestionType.File:
    case AssessmentQuestionType.Description:
      // File and Description questions do not support this operator
      break;
    default:
      // type should always fall into the cases above
      exhaustiveGuard(type);
      break;
  }
  return false;
};

import { mkInput, SchemaToType } from '@main/schema-utils';

export const TokenInput = mkInput({
  name: 'TokenInput',
  comment:
    'When using a temporary password (AKA a "token"), the is an attempted login with said token',
  fields: {
    token: {
      comment: 'The token to validate',
      type: 'string',
    },
  },
});

/** Override type */
export type TokenInput = SchemaToType<typeof TokenInput>;

export const RequestNewDataSiloTokenInput = mkInput({
  name: 'RequestNewDataSiloTokenInput',
  comment: 'Input for requesting a new data silo token',
  fields: {
    token: {
      comment: 'the raw secret to use to find the token',
      type: 'string',
    },
  },
});

/** Override type */
export type RequestNewDataSiloTokenInput = SchemaToType<
  typeof RequestNewDataSiloTokenInput
>;

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const DocumentLegalUsers: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.49689 15.8357C2.49689 16.7566 3.24339 17.5031 4.16425 17.5031"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49692 4.16431V15.8358"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.49897 17.503H4.16425"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5031 4.16419C17.5031 3.24333 16.7566 2.49683 15.8358 2.49683"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5031 6.66535V4.16431"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16425 2.49692H15.8358"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49689 4.16419C2.49689 3.24333 3.24339 2.49683 4.16425 2.49683"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.08212 14.22V14.22C6.39148 14.22 5.8316 13.6602 5.8316 12.9695V12.9695C5.8316 12.2789 6.39148 11.719 7.08212 11.719V11.719C7.77277 11.719 8.33265 12.2789 8.33265 12.9695V12.9695C8.33265 13.6602 7.77277 14.22 7.08212 14.22V14.22Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8316 5.88339H12.501"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8316 8.80112H10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2505 17.0864V16.7479C11.2533 15.7849 12.0333 15.0049 12.9963 15.0022H15.7574C16.7204 15.0049 17.5004 15.7849 17.5032 16.7479V17.0864C17.5032 17.3166 17.3165 17.5032 17.0863 17.5032H11.6674C11.4372 17.5032 11.2505 17.3166 11.2505 17.0864Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7095 11.2439C12.7132 10.324 13.4613 9.58081 14.3813 9.58326C15.3013 9.58571 16.0454 10.3328 16.0442 11.2528C16.043 12.1728 15.2968 12.918 14.3769 12.918C13.9335 12.918 13.5084 12.7414 13.1955 12.4273C12.8826 12.1131 12.7077 11.6873 12.7095 11.2439V11.2439Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

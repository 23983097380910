import { ScopeName } from '@transcend-io/privacy-types';

import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { UserOrderField } from './enums';
import { Role } from './login';
import { Organization } from './organization';
import { Resource } from './resource';
import { ScopePreview } from './scopePreview';
import { TeamPreview } from './teamPreview';
import { UserInterface, UserPreview } from './userPreview';

export const AddUserInput = mkInput({
  name: 'AddUserInput',
  comment: 'Input for adding a user to the organization',
  fields: {
    name: {
      comment: 'The name of the new user',
      type: 'string',
    },
    email: {
      comment: 'The email of the new user',
      type: 'string',
    },
    isAdmin: {
      comment: 'Whether the user should be an administrator',
      type: 'boolean',
      optional: true,
    },
    teams: {
      comment: 'The teams in the organization the user should have',
      modelName: 'team',
      type: 'id',
      optional: true,
      list: true,
    },
    dataSilos: {
      comment: 'The dataSilos in the organization the user should have',
      modelName: 'dataSilo',
      type: 'id',
      optional: true,
      list: true,
    },
    scopes: {
      comment: 'The scopes the user should have',
      type: { ScopeName },
      optional: true,
      list: true,
    },
    sendInviteEmail: {
      comment: 'Send invitation email now?',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type AddUserInput = SchemaToType<typeof AddUserInput>;

export const SendUserInviteEmailInput = mkInput({
  name: 'SendUserInviteEmailInput',
  comment: 'Input for sending invitation email to a user for the organization',
  fields: {
    id: {
      comment: 'The ID of the user to invite',
      modelName: 'user',
      type: 'id',
    },
  },
});

/** Override type */
export type SendUserInviteEmailInput = SchemaToType<
  typeof SendUserInviteEmailInput
>;

export const UpdateCurrentUserInput = mkInput({
  name: 'UpdateCurrentUserInput',
  comment: "Input for updating the current user's attributes",
  fields: {
    name: {
      comment: 'The name of the user',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type UpdateCurrentUserInput = SchemaToType<
  typeof UpdateCurrentUserInput
>;

export const RemoveUserInput = mkInput({
  name: 'RemoveUserInput',
  comment: 'Input for removing a user',
  fields: {
    email: {
      comment: 'The email of the user to remove',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type RemoveUserInput = SchemaToType<typeof RemoveUserInput>;

export const UpdateUserInput = mkInput({
  name: 'UpdateUserInput',
  comment: "Input for updating the current user's attributes",
  fields: {
    id: {
      comment: 'The id of the user to update',
      type: 'id',
      modelName: 'user',
    },
    name: {
      comment: 'The name of the user',
      type: 'string',
      optional: true,
    },
    isAdmin: AddUserInput.fields.isAdmin,
    teams: AddUserInput.fields.teams,
    scopes: AddUserInput.fields.scopes,
    dataSilos: AddUserInput.fields.dataSilos,
  },
});

/** Override type */
export type UpdateUserInput = SchemaToType<typeof UpdateUserInput>;

export const UserFiltersInput = mkInput({
  name: 'UserFiltersInput',
  comment: 'Inputs for filtering the list of users',
  fields: {
    ids: {
      comment: 'Filter by a list of user ids',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    text: {
      comment: 'Miscellaneous text (lookup by name)',
      type: 'string',
      optional: true,
    },
    isAdmin: {
      comment: 'Filter by admin only',
      type: 'boolean',
      optional: true,
    },
    canRevealMultiTenantSombraSecret: {
      comment: 'Filter by users that can reveal multi tenant sombra secrets',
      type: 'boolean',
      optional: true,
    },
    isInvited: {
      comment: 'Filter by whether the user is invited or not',
      type: 'boolean',
      optional: true,
    },
  },
});

/** Override type */
export type UserFiltersInput = SchemaToType<typeof UserFiltersInput>;

export const DestroyUserInput = mkInput({
  name: 'DestroyUserInput',
  comment: 'Input for destroying a user',
  fields: {
    password: {
      comment: 'The password of the user',
      type: 'string',
    },
  },
});

/** Override type */
export type DestroyUserInput = SchemaToType<typeof DestroyUserInput>;

export const CurrentUser = mkType({
  name: 'CurrentUser',
  comment: 'The currently logged in user',
  interfaces: [UserInterface],
  fields: {
    ...UserInterface.fields,
    isAdmin: {
      comment: 'Indicates if the user is an admin of the organization',
      type: 'boolean',
    },
    onboarded: {
      comment: 'Indicates if the user has been successfully onboarded',
      type: 'boolean',
    },
    locale: {
      comment: 'The language that is selected for the current user',
      type: 'string', // Should be LanguageKey enum but GraphQL does not allow hyphens in enums
    },
    organization: {
      comment: 'The organization that the user belongs to',
      type: Organization,
    },
    roles: {
      comment: 'The roles that the user can assume in the other organizations',
      type: Role,
      list: true,
    },
    // TODO: https://transcend.height.app/T-41340 - remove deprecated fields
    hasSombraTransferAbility: {
      comment: 'Indicates if user has permissions to initiate a sombra deploy',
      type: 'boolean',
      deprecated: 'This field is deprecated',
      optional: true,
    },
    parentRole: {
      comment: 'The id of the parent role if applicable',
      optional: true,
      type: UserPreview,
    },
    teams: {
      comment: 'The teams the user is associated with',
      type: TeamPreview,
      list: true,
    },
    intercomId: {
      comment: 'The hashed user ID for intercom identity verification',
      type: 'string',
      optional: true,
    },
    analyticsId: {
      comment: 'A hash of the primary user email address, used for analytics',
      type: 'string',
      optional: true,
    },
  },
});

/** Override type */
export type CurrentUser = SchemaToType<typeof CurrentUser>;

export const User = mkType({
  name: 'User',
  comment: 'Another user in the organization.',
  interfaces: [UserInterface],
  fields: {
    ...UserInterface.fields,
    onboarded: CurrentUser.fields.onboarded,
    loginMethod: CurrentUser.fields.loginMethod,
    isAdmin: CurrentUser.fields.isAdmin,
    isInvited: {
      comment: 'Indicates if the user has an outstanding invitation',
      type: 'boolean',
    },
    isInviteSent: {
      comment: 'Has the invitation email been sent?',
      type: 'boolean',
    },
    teams: {
      comment: 'The teams the user is associated with',
      type: () => TeamPreview,
      list: true,
    },
    scopes: {
      comment:
        'The direct scopes the user has. The user also takes on team scopes and/or admin privileges.',
      type: () => ScopePreview,
      list: true,
    },
    allScopes: {
      comment: 'The derived set of all scopes that the user has',
      type: () => ScopePreview,
      list: true,
    },
    dataSilos: {
      comment: 'The data silos connected to the user',
      type: Resource,
      list: true,
    },
  },
});

/** Override type */
export type User = SchemaToType<typeof User>;

/**
 * Order for a users query
 */
export const UserOrder = mkOrder(User.name, UserOrderField);

/** Override type */
export type UserOrder = SchemaToType<typeof UserOrder>;

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const Database: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.33325 11.9999C3.33325 12.9206 5.42259 13.6666 7.99992 13.6666C10.5773 13.6666 12.6666 12.9206 12.6666 11.9999V3.99992C12.6666 3.07925 10.5773 2.33325 7.99992 2.33325C5.42259 2.33325 3.33325 3.07925 3.33325 3.99992V11.9999Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6666 4C12.6666 4.92067 10.5773 5.66667 7.99992 5.66667C5.42259 5.66667 3.33325 4.92067 3.33325 4"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6666 9.33325C12.6666 10.2539 10.5773 10.9999 7.99992 10.9999C5.42259 10.9999 3.33325 10.2539 3.33325 9.33325"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6666 6.6665C12.6666 7.58717 10.5773 8.33317 7.99992 8.33317C5.42259 8.33317 3.33325 7.58717 3.33325 6.6665"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { makeEnum } from '@transcend-io/type-utils';

/** Keys to map admin dashboard tables to preferences */
export const TableId = makeEnum({
  DataInventoryDataSilos: 'DATA_INVENTORY_DATA_SILOS',
  DataInventoryDataPoints: 'DATA_INVENTORY_DATA_POINTS',
  DataInventoryVendors: 'DATA_INVENTORY_VENDORS',
  DataInventoryDataCategories: 'DATA_INVENTORY_DATA_CATEGORIES',
  DataInventoryPurposes: 'DATA_INVENTORY_PURPOSES',
  DataInventoryBusinessEntities: 'DATA_INVENTORY_BUSINESS_ENTITIES',
  ConsentDataFlowsTriage: 'CONSENT_DATA_FLOWS_TRIAGE',
  ConsentDataFlowsApproved: 'CONSENT_DATA_FLOWS_APPROVED',
  ConsentDataFlowsJunk: 'CONSENT_DATA_FLOWS_JUNK',
  ConsentCookiesTriage: 'CONSENT_COOKIES_TRIAGE',
  ConsentCookiesApproved: 'CONSENT_COOKIES_APPROVED',
  ConsentCookiesJunk: 'CONSENT_COOKIES_JUNK',
  DataMapBrowseList: 'DATA_MAP_BROWSE_LIST',
  IntegrationsManageDataPoints: 'INTEGRATIONS_MANAGE_DATA_POINTS',
  PreferenceStore: 'CONSENT_USER_PREFERENCES',
  RiskIntelligenceAssessments: 'RISK_INTELLIGENCE_ASSESSMENTS',
  DsrAutomationIncomingRequests: 'DSR_AUTOMATION_INCOMING_REQUESTS',
});

/** Type overrides */
export type TableId = (typeof TableId)[keyof typeof TableId];

/**
 * Column names for the data silos table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const DataSilosColumnName = makeEnum({
  BusinessEntities: 'businessEntities',
  ConnectedActions: 'connectedActions',
  ConsentDoNotSellIntegration: 'consentDoNotSellIntegration',
  ContactEmail: 'contactEmail',
  ContactName: 'contactName',
  Controllerships: 'controllerships',
  CookieCountApproved: 'cookieCountApproved',
  CookieCountTriage: 'cookieCountTriage',
  Country: 'country',
  DataFlowCountApproved: 'dataFlowCountApproved',
  DataFlowCountTriage: 'dataFlowCountTriage',
  DataProcessingAgreementLink: 'dataProcessingAgreementLink',
  DataProcessingAgreementStatus: 'dataProcessingAgreementStatus',
  DataRetentionNote: 'dataRetentionNote',
  DataSubCategories: 'dataSubCategories',
  DeprecationState: 'deprecationState',
  Description: 'description',
  DiscoveredBy: 'discoveredBy',
  HasPersonalData: 'hasPersonalData',
  HasUspApiSupport: 'hasUspApiSupport',
  Identifiers: 'identifiers',
  IntegrationDocs: 'integrationDocs',
  IsLive: 'isLive',
  ManualWorkRetryFrequency: 'manualWorkRetryFrequency',
  Notes: 'notes',
  NotifyEmailAddress: 'notifyEmailAddress',
  NotifyWebhookUrl: 'notifyWebhookUrl',
  Owners: 'owners',
  PrivacyRequestIntegrationType: 'privacyRequestIntegrationType',
  ProcessingPurposeSubCategories: 'processingPurposeSubCategories',
  PromptAVendorEmailCompletionLinkType: 'promptAVendorEmailCompletionLinkType',
  PromptAVendorEmailIncludeIdentifiersAttachment:
    'promptAVendorEmailIncludeIdentifiersAttachment',
  PromptAVendorEmailSendFrequency: 'promptAVendorEmailSendFrequency',
  PromptAVendorEmailSendType: 'promptAVendorEmailSendType',
  PromptEmailTemplate: 'promptEmailTemplate',
  ReceiverDataSilos: 'receiverDataSilos',
  Recipients: 'recipients',
  RecommendedForConsent: 'recommendedForConsent',
  RecommendedForPrivacy: 'recommendedForPrivacy',
  SilosDiscovered: 'silosDiscovered',
  SubDataPointCount: 'subDataPointCount',
  SubjectBlocklist: 'subjectBlocklist',
  SuperCategories: 'superCategories',
  Teams: 'teams',
  Title: 'title',
  TransferRegions: 'transferRegions',
  Vendor: 'vendor',
  WebsiteUrl: 'websiteUrl',
});

/** Type overrides */
export type DataSilosColumnName =
  (typeof DataSilosColumnName)[keyof typeof DataSilosColumnName];

/**
 * Column names for the subdatapoints table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const SubDataPointsColumnName = makeEnum({
  AccessRequestVisibilityEnabled: 'accessRequestVisibilityEnabled',
  Categories: 'categories',
  Column: 'column',
  ContentClassificationStatus: 'contentClassificationStatus',
  Controllership: 'controllership',
  CreatedAt: 'createdAt',
  DataPointOwners: 'dataPointOwners',
  DataPointTeams: 'dataPointTeams',
  DataPointTitle: 'dataPointTitle',
  DataSilo: 'dataSilo',
  DataSiloOwners: 'dataSiloOwners',
  DataSiloTeams: 'dataSiloTeams',
  DataSiloVendor: 'dataSiloVendor',
  Description: 'description',
  ErasureRequestRedactionEnabled: 'erasureRequestRedactionEnabled',
  Name: 'name',
  Purposes: 'purposes',
  Table: 'table',
  Subjects: 'subjects',
  RetentionType: 'retentionType',
});

/** Type overrides */
export type SubDataPointsColumnName =
  (typeof SubDataPointsColumnName)[keyof typeof SubDataPointsColumnName];

/**
 *
 * Column names for the data categories table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const DataCategoriesColumnName = makeEnum({
  CatalogRecipients: 'catalogRecipients',
  Category: 'category',
  DataPointCount: 'dataPointCount',
  DataSiloRegions: 'dataSiloRegions',
  DataSilos: 'dataSilos',
  Description: 'description',
  Owners: 'owners',
  Identifier: 'identifier',
  SubDataPointRetentionSchedules: 'subDataPointRetentionSchedules',
  Teams: 'teams',
  VendorHeadquarterLocations: 'vendorHeadquarterLocations',
  Regex: 'regex',
});

/** Type overrides */
export type DataCategoriesColumnName =
  (typeof DataCategoriesColumnName)[keyof typeof DataCategoriesColumnName];

/**
 *
 * Column names for the purposes table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const PurposesColumnName = makeEnum({
  BusinessEntities: 'businessEntities',
  CatalogRecipients: 'catalogRecipients',
  Controllerships: 'controllerships',
  DataSilos: 'dataSilos',
  DataSiloDataSubjects: 'dataSiloDataSubjects',
  DataSiloOwners: 'dataSiloOwners',
  DataSiloRegions: 'dataSiloRegions',
  DataSubCategories: 'dataSubCategories',
  Description: 'description',
  Owners: 'owners',
  Purpose: 'purpose',
  SubDataPointCount: 'subDataPointCount',
  SubDataPointRetentionSchedules: 'subDataPointRetentionSchedules',
  SuperCategories: 'superCategories',
  Teams: 'teams',
  Vendors: 'vendors',
  VendorHeadquarterLocations: 'vendorHeadquarterLocations',
  VendorOwners: 'vendorOwners',
});

/** Type overrides */
export type PurposesColumnName =
  (typeof PurposesColumnName)[keyof typeof PurposesColumnName];

/**
 *
 * Column names for the vendors table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const VendorsColumnName = makeEnum({
  Address: 'address',
  BusinessEntity: 'businessEntity',
  CatalogRecipients: 'catalogRecipients',
  ContactEmail: 'contactEmail',
  ContactName: 'contactName',
  ContactPhone: 'contactPhone',
  Controllerships: 'controllerships',
  DataProcessingAgreementLink: 'dataProcessingAgreementLink',
  DataProcessingAgreementStatus: 'dataProcessingAgreementStatus',
  DataSiloRegions: 'dataSiloRegions',
  DataSilos: 'dataSilos',
  DataSubCategories: 'dataSubCategories',
  Description: 'description',
  HeadquarterCountry: 'headquarterCountry',
  Owners: 'owners',
  ProcessingPurposeSubCategories: 'processingPurposeSubCategories',
  SubDataPointCount: 'subDataPointCount',
  Teams: 'teams',
  Title: 'title',
  WebsiteUrl: 'websiteUrl',
});

/** Type overrides */
export type VendorsColumnName =
  (typeof VendorsColumnName)[keyof typeof VendorsColumnName];

/**
 *
 * Column names for the business entities table that are saved in the preferences table config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const BusinessEntitiesColumnName = makeEnum({
  Address: 'address',
  CatalogRecipients: 'catalogRecipients',
  DataProtectionOfficerEmail: 'dataProtectionOfficerEmail',
  DataProtectionOfficerName: 'dataProtectionOfficerName',
  DataSilos: 'dataSilos',
  DataSubCategories: 'dataSubCategories',
  Description: 'description',
  HeadquarterCountry: 'headquarterCountry',
  ProcessingPurposeSubCategories: 'processingPurposeSubCategories',
  Title: 'title',
  Vendors: 'vendors',
  Teams: 'teams',
  Owners: 'owners',
});

/** Type overrides */
export type BusinessEntitiesColumnName =
  (typeof BusinessEntitiesColumnName)[keyof typeof BusinessEntitiesColumnName];

/**
 *
 * Column names for the cookies table that are saved in the preferences table config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const CookiesColumnName = makeEnum({
  Activity: 'activity',
  ConsentDoNotSellIntegration: 'consentDoNotSellIntegration',
  Domains: 'domains',
  Encounters: 'encounters',
  HasUspApiSupport: 'hasUspApiSupport',
  IsRegex: 'isRegex',
  CreatedAt: 'createdAt',
  LastDiscoveredAt: 'lastDiscoveredAt',
  Name: 'name',
  Notes: 'notes',
  Owners: 'owners',
  Purpose: 'purpose',
  ServiceCategoriesOfRecipients: 'serviceCategoriesOfRecipients',
  ServiceDescription: 'serviceDescription',
  ServiceName: 'serviceName',
  ServiceWebsite: 'serviceWebsite',
  Status: 'status',
  Teams: 'teams',
  Occurrences: 'occurrences',
});

/** Type overrides */
export type CookiesColumnName =
  (typeof CookiesColumnName)[keyof typeof CookiesColumnName];

/**
 *
 * Column names for the data flows table that are saved in the preferences table config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const DataFlowsColumnName = makeEnum({
  Actions: 'actions',
  Activity: 'activity',
  ConnectionsMadeTo: 'connectionsMadeTo',
  ConsentDoNotSellIntegration: 'consentDoNotSellIntegration',
  Encounters: 'encounters',
  HasUspApiSupport: 'hasUspApiSupport',
  CreatedAt: 'createdAt',
  LastDiscoveredAt: 'lastDiscoveredAt',
  Notes: 'notes',
  Owners: 'owners',
  Purpose: 'purpose',
  ServiceCategoriesOfRecipients: 'serviceCategoriesOfRecipients',
  ServiceDescription: 'serviceDescription',
  ServiceName: 'serviceName',
  ServiceWebsite: 'serviceWebsite',
  Status: 'status',
  Teams: 'teams',
  Type: 'type',
  Occurrences: 'occurrences',
});

/** Type overrides */
export type DataFlowsColumnName =
  (typeof DataFlowsColumnName)[keyof typeof DataFlowsColumnName];

/**
 *
 * Column names for the assessment form templates table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const AssessmentFormTemplatesColumnName = makeEnum({
  Creator: 'creator',
  Description: 'description',
  LastEditor: 'lastEditor',
  Source: 'source',
  Status: 'status',
  Title: 'title',
  UpdatedAt: 'updatedAt',
});

/** Type overrides */
export type AssessmentFormTemplatesColumnName =
  (typeof AssessmentFormTemplatesColumnName)[keyof typeof AssessmentFormTemplatesColumnName];

/**
 *
 * Column names for the assessment forms table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const AssessmentFormsColumnName = makeEnum({
  Assignees: 'assignees',
  SectionAssignees: 'sectionAssignees',
  CreatedAt: 'createdAt',
  AssignedAt: 'assignedAt',
  SubmittedAt: 'submittedAt',
  ApprovedAt: 'approvedAt',
  RejectedAt: 'rejectedAt',
  Description: 'description',
  DueDate: 'dueDate',
  Resources: 'resources',
  Reviewers: 'reviewers',
  Status: 'status',
  SyncedRows: 'syncedRows',
  Template: 'assessmentFormTemplate',
  Title: 'title',
  TitleIsInternal: 'titleIsInternal',
});

/** Type overrides */
export type AssessmentFormsColumnName =
  (typeof AssessmentFormsColumnName)[keyof typeof AssessmentFormsColumnName];

/**
 *
 * Column names for the assessment groups table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const AssessmentGroupsColumnName = makeEnum({
  CreatedAt: 'createdAt',
  Description: 'description',
  DueDate: 'dueDate',
  Creator: 'creator',
  LastEditor: 'lastEditor',
  Status: 'status',
  Template: 'assessmentFormTemplate',
  Title: 'title',
  UpdatedAt: 'updatedAt',
});

/** Type overrides */
export type AssessmentGroupsColumnName =
  (typeof AssessmentGroupsColumnName)[keyof typeof AssessmentGroupsColumnName];

/**
 *
 * Column names for the user preferences table that are saved in the preferences tables config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const ConsentUserPreferencesColumnName = makeEnum({
  PartitionType: 'partitionType',
  PartitionName: 'partitionName',
  DecryptionStatus: 'decryptionStatus',
  UpdatedAt: 'timestamp',
});

/** Type overrides */
export type ConsentUserPreferencesColumnName =
  (typeof ConsentUserPreferencesColumnName)[keyof typeof ConsentUserPreferencesColumnName];

/**
 *
 * Column names for the DSR incoming requests table that are saved in the preferences table config
 *
 * IMPORTANT: if these names change a migration needs to be written to update any
 * current columns configurations using this hidden column. Additions to and
 * removals from this list do not need migrations.
 *
 */
export const DsrIncomingRequestColumnName = makeEnum({
  Email: 'email',
  CoreIdentifier: 'coreIdentifier',
  DaysRemaining: 'daysRemaining',
  Id: 'id',
  Status: 'status',
  IsOpened: 'isOpened',
  IsArchived: 'isArchived',
  IsSilent: 'isSilent',
  IsTest: 'isTest',
  Type: 'type',
  Origin: 'origin',
  SubjectType: 'subjectType',
  Locale: 'locale',
  UpdatedAt: 'updatedAt',
  LastTransitionTime: 'lastTransitionTime',
  CreatedAt: 'createdAt',
  AttributeValues: 'attributeValues',
  Country: 'country',
  Owners: 'owners',
  Teams: 'teams',
  Partition: 'partition',
  Purpose: 'purpose',
  EnrichedPreferences: 'enrichedPreferences',
});

/** Type overrides */
export type DsrIncomingRequestColumnName =
  (typeof DsrIncomingRequestColumnName)[keyof typeof DsrIncomingRequestColumnName];

/**
 * Global Styling
 */

import type { ColorPalette, PrivacyCenterTheme } from './theme';
import { isKnownColor } from './typeGuards';

// ///////////////////////// //
// Standard Sizes/Dimensions //
// ///////////////////////// //

/**
 * Covert the project's standard unit size into a pixel string.
 *
 * The project is currently using an 8px unit size, so `sp(2)` would return
 * '16px' string.
 *
 * @param scalar - The number of standard units
 * @returns A pixel string (such as '16px')
 */
export const sp = (scalar = 1): string => `${scalar * 8}px`;

/**
 * Standard line-height to use for any paragraph content (including things like
 * line items).
 */
export const paragraphLineHeight = 1.8;

/**
 * A set of standard timing durations for use in animations.
 */
export const timing = {
  /** Short animation duration (string format) */
  short: '0.25s',
  /** Short animation duration (millisecond number) */
  shortMs: 250,
  /**
   * Return a timing string to be used w/ `transition: $TIMING_STR` for a set of
   * css properties.
   *
   * @param properties - Names of the CSS styles to create a timing string for
   * @returns Timing string
   */
  shortEase(...properties: string[]) {
    return properties.map((p) => `${timing.short} ${p} ease`).join(', ');
  },
};

export const layoutSizes = {
  mobile: {
    /**
     * The standard page margin size of each side of the page
     *
     * @param scalar - Width multiplier
     * @returns The vw string
     */
    mainSideMargin: (scalar = 1) => `${scalar * 6}vw`,
    /**
     * The standard page width (this is expected to be paired w/ margin: auto for left and right)
     *
     * @param scalar - Width multiplier
     * @returns The vw string
     */
    mainWidth: (scalar = 1) => `${scalar * 88}vw`,
    /**
     * A standard spacing unit, similar to sp, but varying b/w layout groups and intended to be used w/ margins
     *
     * @param x - Scaling factor to multiply the base unit by
     * @returns pixel string
     */
    msp: (x = 1): string => sp(2 * x),
  },
  tablet: {
    /**
     * The standard page margin size of each side of the page
     *
     * @param scalar - Width multiplier
     * @returns The vw string
     */
    mainSideMargin: (scalar = 1) => `${scalar * 18}vw`,
    /**
     * The standard page width (this is expected to be paired w/ margin: auto for left and right)
     *
     * @param scalar - Width multiplier
     * @returns The vw string
     */
    mainWidth: (scalar = 1) => `${scalar * 64}vw`,
    /**
     * A standard spacing unit, similar to sp, but varying b/w layout groups and intended to be used w/ margins
     *
     * @param x - Scaling factor to multiply the base unit by
     * @returns pixel string
     */
    msp: (x = 1): string => sp(3 * x),
  },
  desktop: {
    /**
     * The standard page margin size of each side of the page
     *
     * @param scalar - Width multiplier
     * @returns The vw string
     */
    mainSideMargin: (scalar = 1) => `${scalar * 14}vw`,
    /**
     * The minimum margins to allow on the sides, to be used by content exceeding the standard main width
     *
     * @param scalar - Width multiplier
     * @returns The vw string
     */
    minSideMargin: (scalar = 1) => `${scalar * 10}vw`,
    /**
     * The standard page width (this is expected to be paired w/ margin: auto for left and right)
     *
     * @param scalar - Width multiplier
     * @returns The vw string
     */
    mainWidth: (scalar = 1) => `${scalar * 72}vw`,
    /**
     * The maximum width the page can expand to before being capped
     *
     * @param scalar - Width multiplier
     * @returns The vw string
     */
    mainMaxWidth: (scalar = 1) => sp(scalar * 145),
    /**
     * A standard spacing unit, similar to sp, but varying b/w layout groups and intended to be used w/ margins
     *
     * @param x - Scaling factor to multiply the base unit by
     * @returns pixel string
     */
    msp: (x = 1): string => sp(4 * x),
  },
};

export const TABLET_MIN_WIDTH = 768;
export const TABLET_MAX_WIDTH = 1024;

/**
 * A collection of responsive breakpoints for usage across the project
 * in CSS styling.
 */
export const responsive = {
  /** media-query for something that is smaller than tablet sized */
  beforeTablet: `@media (max-width: ${TABLET_MIN_WIDTH - 1}px) `,
  /** media-query for something that is at least tablet sized */
  atLeastTablet: `@media (min-width: ${TABLET_MIN_WIDTH}px) `,
  /** media-query for something that is only tablet sized (not mobile, not desktop) */
  justTablet: `@media (min-width: ${TABLET_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH}px) `,
  /** media-query for something that is less than desktop sized */
  beforeDesktop: `@media (max-width: ${TABLET_MAX_WIDTH}px) `,
  /** media-query for something that is at least desktop sized */
  atLeastDesktop: `@media (min-width: ${TABLET_MAX_WIDTH + 1}px) `,
  /** media-query for something that is super large screen sized */
  atLeastLargeScreen: '@media (min-width: 2500px) ',
};

/**
 * Style props to center in parent div
 */
export const MARGIN_CENTER = { margin: '0 auto', display: 'block' };

/**
 * Options for resolving a color from a theme
 */
export interface ResolveColorOptions {
  /** A theme instance */
  theme: PrivacyCenterTheme;
  /** A potential color */
  color?: string;
}

/**
 * A function to resolve a color from the theme
 */
type ColorResolver = (props: ResolveColorOptions) => string;

/**
 * Resolve a color by:
 *
 * 1. Checking if it is a theme color
 * 2. Returning the color itself (could be a color string/hex ....)
 *
 * @param getDefaultColor - A function that gets the color when none provided
 * @returns The color resolver
 */
export const resolveColor =
  (getDefaultColor?: (colors: ColorPalette) => string): ColorResolver =>
  ({ color, theme }: ResolveColorOptions): string =>
    isKnownColor(color)
      ? theme.colors[color]
      : color || (getDefaultColor ? getDefaultColor(theme.colors) : '');

import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

/**
 * Glasses icon
 */
export const Glasses: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66699 8.00862C7.40366 7.11996 8.59699 7.11996 9.33366 8.00862"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.88627 6.11436C6.92767 7.15576 6.92767 8.8442 5.88627 9.8856C4.84487 10.927 3.15643 10.927 2.11503 9.8856C1.07363 8.8442 1.07363 7.15576 2.11503 6.11436C3.15643 5.07296 4.84487 5.07296 5.88627 6.11436"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M13.8863 6.11436C14.9277 7.15576 14.9277 8.8442 13.8863 9.8856C12.8449 10.927 11.1564 10.927 10.115 9.8856C9.07363 8.8442 9.07363 7.15576 10.115 6.11436C11.1564 5.07296 12.8449 5.07296 13.8863 6.11436"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33366 7.99996H0.666992"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3337 7.99996H14.667"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

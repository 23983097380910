import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const FileExport: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M10.6666 14H11.3333C12.4379 14 13.3333 13.1046 13.3333 12V5.67287C13.3333 5.14244 13.1226 4.63374 12.7475 4.25867L11.0746 2.58579C10.6996 2.21072 10.1908 2 9.66042 2H4.66663C3.56206 2 2.66663 2.89543 2.66663 4V6"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 5.66667H11C10.2636 5.66667 9.66663 5.06971 9.66663 4.33333V2"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.99996 8H7.33329C8.06967 8 8.66663 8.59695 8.66663 9.33333V12.6667C8.66663 13.403 8.06967 14 7.33329 14H3.99996C3.26358 14 2.66663 13.403 2.66663 12.6667V9.33333C2.66663 8.59695 3.26358 8 3.99996 8Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66663 11.0001H2.66663"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66659 8V14"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagML: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55050"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" rx="-1" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55050)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H11V12H5V0Z"
        fill="#FBCD17"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H16V12H11V0Z"
        fill="#E11C1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H5V12H0V0Z"
        fill="#0B9E7A"
      />
    </g>
  </svg>
);

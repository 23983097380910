import React from 'react';

import { SvgProps } from '../enums';

export const FlagBB: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_54979"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_54979)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H16V12H11V0Z"
        fill="#2E42A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0H11V12H5V0Z"
        fill="#FECA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H5V12H0V0Z"
        fill="#2E42A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9.21711 7.63651C9.53351 6.982 9.8985 5.78766 9.8985 5.78766L10.1754 4.96535L9.07787 5.36279L9.33761 5.51364C9.33761 5.51364 8.88493 6.41853 8.65694 6.86058C8.42894 7.30263 8.40637 7.01059 8.40637 7.01059L8.44538 3.92623L8.69729 3.85793L8.06948 2.65198L7.26852 3.87465L7.62145 3.88416C7.62145 3.88416 7.59211 7.13847 7.54306 7.01059C7.51568 6.93795 7.44284 7.16245 7.40734 7.07549C7.18483 6.53039 6.88051 5.47392 6.88051 5.47392L7.05858 5.30733L5.86444 5.06165L6.22095 5.79578C6.22095 5.79578 6.53533 7.10397 6.88051 7.74287C6.95824 7.945 7.33381 8.03123 7.33381 8.03123C7.33381 8.03123 7.51719 7.84509 7.56262 8.03123C7.60804 8.21737 7.56262 8.96872 7.56262 8.96872H8.40637C8.40637 8.96872 8.34696 8.25991 8.40637 8.03123C8.46578 7.80255 8.64992 8.03123 8.64992 8.03123C8.64992 8.03123 9.13951 7.83864 9.21711 7.63651Z"
        fill="black"
      />
    </g>
  </svg>
);

/* eslint-disable max-len */
import { SvgProps } from '@main/core-ui/src/Icon/constants/enums';
import React from 'react';

export const GlobeCube: React.FC<SvgProps> = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0001 21.0041C7.02745 21.0041 2.99634 16.973 2.99634 12.0003C2.99634 7.0277 7.02745 2.99658 12.0001 2.99658C16.9727 2.99658 21.0038 7.0277 21.0038 12.0003"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.50952 8.99874H20.4236"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5105 15.0012H12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0018 12.0003C16.0094 9.20541 15.2602 6.46057 13.8339 4.05701C13.4565 3.40098 12.7574 2.99658 12.0006 2.99658C11.2438 2.99658 10.5447 3.40098 10.1673 4.05701C7.27625 8.95831 7.27625 15.0433 10.1673 19.9446C10.5465 20.5985 11.2443 21.0018 12.0001 21.0041"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7883 19.6324V16.8752C14.7899 16.5227 14.9809 16.1982 15.2885 16.0259L17.7786 14.6253C18.0751 14.4582 18.4374 14.4582 18.734 14.6253L21.224 16.0259C21.5319 16.1979 21.7231 16.5225 21.7242 16.8752V19.6324C21.7229 19.985 21.5318 20.3096 21.224 20.4817L18.733 21.8823C18.4362 22.0484 18.0744 22.0484 17.7776 21.8823L15.2875 20.4817C14.9797 20.3098 14.7888 19.9849 14.7883 19.6324V19.6324Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2526 18.2539V22.0045"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9214 16.3828L18.2528 18.2536L21.5842 16.3828"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
/* eslint-enable max-len */

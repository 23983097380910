import { useMemo } from 'react';
import type { GroupTypeBase, OptionTypeBase } from 'react-select';
import { SelectComponents } from 'react-select/src/components';

import { DropdownIndicator } from './DropdownIndicator';
import { MultiValue } from './MultiValue';
import { MultiValueRemove } from './MultiValueRemove';
import { Option } from './Option';
import { DefaultOptionType, ReactSelectExtendedProps } from './types';

export interface UseDefaultComponentsProps<
  T extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
> extends Pick<
    ReactSelectExtendedProps<IsMulti, T, G>,
    'overflow' | 'overflowLimit'
  > {
  /** Whether to hide the drop-down indicator */
  hideIndicator: boolean;
  /** Disable functional button */
  isDisabled: boolean;
  /** Any additional/custom components */
  components?: Partial<SelectComponents<T, IsMulti, G>>;
}

/**
 * Hook to replace some react-select components with our own assets
 *
 * @param useDefaultComponents - options
 * @returns our overrides of the select components
 */
export function useDefaultComponents<
  T extends OptionTypeBase = DefaultOptionType,
  IsMulti extends boolean = false,
  G extends GroupTypeBase<T> = GroupTypeBase<T>,
>({
  overflow,
  hideIndicator,
  isDisabled,
  components = {},
}: UseDefaultComponentsProps<T, IsMulti, G>): Partial<
  SelectComponents<T, IsMulti, G>
> {
  const memoizedComponents = useMemo<Partial<SelectComponents<T, IsMulti, G>>>(
    () => ({
      IndicatorSeparator: () => null,
      MultiValueRemove: isDisabled ? () => null : MultiValueRemove,
      DropdownIndicator:
        hideIndicator && isDisabled ? () => null : DropdownIndicator,
      Option,
      ...(overflow === 'badge' ? { MultiValue } : {}),
      ...components,
    }),
    [hideIndicator, isDisabled, overflow, components],
  );
  return memoizedComponents;
}

import React from 'react';

import { SvgProps } from '../enums';

export const FlagMT: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="12" fill="#F7FCFF" />
    <mask
      id="mask0_270_55065"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55065)">
      <mask id="path-3-inside-1_270_55065" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M5 3H3V4.49627C2.77971 4.54852 2.61584 4.74651 2.61584 4.98278L2.61613 5H1V7H2.66537C2.72995 7.13369 2.8523 7.23424 3 7.26928V9H5V7.27571C5.1618 7.24829 5.29715 7.14313 5.3663 7H7V5H5.41554L5.41583 4.98278C5.41583 4.73532 5.23606 4.52985 5 4.48984V3Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M5 3H3V4.49627C2.77971 4.54852 2.61584 4.74651 2.61584 4.98278L2.61613 5H1V7H2.66537C2.72995 7.13369 2.8523 7.23424 3 7.26928V9H5V7.27571C5.1618 7.24829 5.29715 7.14313 5.3663 7H7V5H5.41554L5.41583 4.98278C5.41583 4.73532 5.23606 4.52985 5 4.48984V3Z"
        fill="#A0A0A0"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3 3V2.5H2.5V3H3ZM5 3H5.5V2.5H5V3ZM3 4.49627L3.1154 4.98277L3.5 4.89154V4.49627H3ZM2.61584 4.98278H2.11577L2.11591 4.99123L2.61584 4.98278ZM2.61613 5V5.5H3.12465L3.11606 4.99155L2.61613 5ZM1 5V4.5H0.5V5H1ZM1 7H0.5V7.5H1V7ZM2.66537 7L3.11558 6.7825L2.9791 6.5H2.66537V7ZM3 7.26928H3.5V6.87401L3.1154 6.78278L3 7.26928ZM3 9H2.5V9.5H3V9ZM5 9V9.5H5.5V9H5ZM5 7.27571L4.91643 6.78275L4.5 6.85334V7.27571H5ZM5.3663 7V6.5H5.05257L4.91609 6.7825L5.3663 7ZM7 7V7.5H7.5V7H7ZM7 5H7.5V4.5H7V5ZM5.41554 5L4.91561 4.99156L4.90702 5.5H5.41554V5ZM5.41583 4.98278L5.91583 4.99123V4.98278H5.41583ZM5 4.48984H4.5V4.91221L4.91643 4.9828L5 4.48984ZM3 3.5H5V2.5H3V3.5ZM3.5 4.49627V3H2.5V4.49627H3.5ZM3.11584 4.98278L3.1154 4.98277L2.8846 4.00977C2.44401 4.11428 2.11584 4.5096 2.11584 4.98278H3.11584ZM3.11606 4.99155L3.11577 4.97433L2.11591 4.99123L2.1162 5.00845L3.11606 4.99155ZM1 5.5H2.61613V4.5H1V5.5ZM1.5 7V5H0.5V7H1.5ZM2.66537 6.5H1V7.5H2.66537V6.5ZM3.1154 6.78278L3.11558 6.7825L2.21516 7.2175C2.34404 7.48428 2.58795 7.68541 2.8846 7.75578L3.1154 6.78278ZM3.5 9V7.26928H2.5V9H3.5ZM5 8.5H3V9.5H5V8.5ZM4.5 7.27571V9H5.5V7.27571H4.5ZM5.08357 7.76868C5.40868 7.71357 5.6786 7.50297 5.81651 7.2175L4.91609 6.7825L4.91643 6.78275L5.08357 7.76868ZM7 6.5H5.3663V7.5H7V6.5ZM6.5 5V7H7.5V5H6.5ZM5.41554 5.5H7V4.5H5.41554V5.5ZM4.9159 4.97434L4.91561 4.99156L5.91547 5.00845L5.91576 4.99123L4.9159 4.97434ZM4.91643 4.9828L4.91583 4.98278H5.91583C5.91583 4.48737 5.55607 4.07697 5.08357 3.99687L4.91643 4.9828ZM4.5 3V4.48984H5.5V3H4.5Z"
        fill="#FECA00"
        mask="url(#path-3-inside-1_270_55065)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0H16V12H8V0Z"
        fill="#E31D1C"
      />
      <mask
        id="mask1_270_55065"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="0"
        width="8"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0H16V12H8V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_270_55065)" />
    </g>
  </svg>
);

import React from 'react';

import { SvgProps } from '../enums';

export const FlagLS: React.FC<SvgProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_270_55141"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="12"
    >
      <rect width="16" height="12" fill="white" />
    </mask>
    <g mask="url(#mask0_270_55141)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8H16V12H0V8Z"
        fill="#55BA07"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4H16V8H0V4Z"
        fill="#F7FCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V4H0V0Z"
        fill="#3D58DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.625 4.12015C7.36366 4.19032 7.22566 4.37093 7.22566 4.69048C7.22566 5.05568 7.40497 5.39846 7.625 5.57524V4.12015ZM8.25 5.56546C8.46392 5.38625 8.63588 5.04985 8.63588 4.69142C8.63588 4.38105 8.5 4.20125 8.25 4.12664V5.56546ZM8.22893 3.86614C8.63814 3.95704 8.93428 4.23572 8.93428 4.6885C8.93428 5.01781 8.77762 5.41815 8.53474 5.69288L9.7516 6.88639L10.0518 6.78756L10.5 7.46739C10.5 7.46739 9.36704 8 7.96671 8C6.56639 8 5.5 7.46739 5.5 7.46739L5.88158 6.88639L6.18974 6.98741L7.31872 5.71358C7.06522 5.43855 6.9005 5.02618 6.9005 4.6885C6.9005 4.21971 7.21796 3.93754 7.64969 3.8572C7.69721 3.74522 7.80818 3.66667 7.9375 3.66667C8.07023 3.66667 8.18363 3.74942 8.22893 3.86614Z"
        fill="#1D1D1D"
      />
    </g>
  </svg>
);

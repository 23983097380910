/**
 * Configuration for the LaunchDarkly API
 */

import {
  useFlags as useFlagsLd,
  useLDClient as useLDClientOrig,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';
import React, { useContext } from 'react';

import { LAUNCH_DARKLY_CLIENT_ID } from '../args';
import { FeatureFlagKey, FeatureFlags } from './constants';

/**
 * Identifying information about a user.
 *
 * TODO: We can make use of Private User Attributes to target on some fields,
 * while not sharing those fields values with LaunchDarkly.
 *
 * Until we have Private User Attributes, we will mark all users
 * as Anonymous, so that their data is kept private.
 *
 * @see https://docs.launchdarkly.com/docs/js-sdk-reference#section-users
 * @see https://docs.launchdarkly.com/docs/anonymous-users
 * @see https://docs.launchdarkly.com/docs/private-user-attributes
 */
export interface LaunchDarklyContext {
  /** context type */
  kind: 'user';
  /** The primary key that uniquely identifies an individual */
  key: string;
  /** The user's first name */
  firstName?: string;
  /** The user's last name */
  lastName?: string;
  /** The user's email address */
  email?: string;
  /** Any custom metadata about a user to target on */
  custom?: Record<string, string>;
  /** All data should be kept private */
  anonymous: true;
}

/**
 * LaunchDarkly has a concept of "Users" that enables us to target
 * feature flags at certain subsets of users. As an example,
 * we could target a allowlisted subset of our users to be
 * able to use some feature, while everyone else would not see
 * the new feature.
 *
 * Initially, we don't know anything about our user, so we
 * set the user to have generic metadata saying that we don't
 * know who they are.
 *
 * @see https://docs.launchdarkly.com/docs/users-index
 */
const initialLaunchDarklyContext: LaunchDarklyContext = {
  kind: 'user',
  key: 'unknown_user',
  anonymous: true,
};

// stores the mocked flags. LD doesn't support mock flag data for testing so we
// need to hack around it
const FeatureFlagMockContext = React.createContext<FeatureFlags | undefined>(
  undefined,
);

// needed to turn the HOC composition from LD into a usable component
const FeatureFlagPassthrough: React.FC = ({ children }) => <>{children}</>;

// Wraps the passthrough component with the real feature flag provider
const LDFeatureFlagProvider = withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_ID,
  context: initialLaunchDarklyContext,
  reactOptions: {
    // use the flag keys as-is in the returned flag object
    useCamelCaseFlagKeys: false,
  },
  options: {
    // allow the flag values to update even if the page hasn't reloaded
    streaming: true,
  },
})(FeatureFlagPassthrough);

interface FeatureFlagProviderArgs {
  /** the mocked flags to use */
  mockFlags?: Partial<FeatureFlags>;
}

const FF_PARAM_NAME = 'ff_override';
const FLAG_OVERRIDES_LOCAL_STORAGE_NAME = 'temporaryFeatureFlagOverrides';

// wrap the LD Provider with a mock data provider
export const FeatureFlagProvider: React.FC<FeatureFlagProviderArgs> = ({
  mockFlags,
  children,
}) => {
  // fetch the ff query params
  const queryParams = new URLSearchParams(window.location.search);
  // default to temporary localstorage values
  let ffOverrides: FeatureFlags = JSON.parse(
    localStorage.getItem(FLAG_OVERRIDES_LOCAL_STORAGE_NAME) || '{}',
  );
  if (queryParams.has(FF_PARAM_NAME)) {
    // temporarily persist the overrides (cleared in logout)
    ffOverrides = JSON.parse(
      decodeURIComponent(queryParams.get(FF_PARAM_NAME)!),
    );
    localStorage.setItem(
      FLAG_OVERRIDES_LOCAL_STORAGE_NAME,
      JSON.stringify(ffOverrides),
    );
  }
  return (
    <FeatureFlagMockContext.Provider
      value={{ ...mockFlags, ...ffOverrides } as FeatureFlags}
    >
      <LDFeatureFlagProvider>{children}</LDFeatureFlagProvider>
    </FeatureFlagMockContext.Provider>
  );
};

// wrap the provided flag hook with some logic to enable mocking
export const useFeatureFlags = (): FeatureFlags => {
  const mockedFlags = useContext(FeatureFlagMockContext);
  const realFlags = useFlagsLd() as FeatureFlags;
  // prefer mocked flags over real ones
  return { ...realFlags, ...mockedFlags };
};

// helper hook to simplify single-flag fetches
export const useFeatureFlag = <T extends FeatureFlagKey>(
  flag: T,
): FeatureFlags[T] => useFeatureFlags()[flag];

export const useLDClient = useLDClientOrig;

import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

export const RiskMatrixColumn = mkType({
  name: 'RiskMatrixColumn',
  comment: 'A representation of how likely a risk is to occur',
  fields: {
    id: {
      comment: 'The id of the risk matrix column',
      type: 'id',
      modelName: 'riskMatrixColumn',
    },
    title: {
      comment: 'The display name of the risk matrix column',
      type: 'string',
    },
  },
});

/** Override type */
export type RiskMatrixColumn = SchemaToType<typeof RiskMatrixColumn>;

export const CreateRiskMatrixColumnInput = mkInput({
  name: 'CreateRiskMatrixColumnInput',
  comment: 'Input for creating a risk matrix column',
  fields: {
    title: RiskMatrixColumn.fields.title,
  },
});

/** Override type */
export type CreateRiskMatrixColumnInput = SchemaToType<
  typeof CreateRiskMatrixColumnInput
>;
